import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {
  DanceEventPreviewCardModule,
  DfSharedButtonModule,
  DmEventPreviewCardModule,
  PaginatorModule,
  PassInfoFormModule,
  PassPriceFormModule,
  PassTargetsTableModule,
  SharedCancelDialogModule
} from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { CreatePassStepperPage } from './create-pass-stepper.page';
import { CourseRulesFormModule } from '../../components/course-rules-form/course-rules-form.module';
import { CourseAppointmentsFormModule } from '../../components/course-appointments-form/course-appointments-form.module';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

const routes: Routes = [
  {
    path: '',
    component: CreatePassStepperPage,
  },
];

@NgModule({
  declarations: [CreatePassStepperPage],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    PaginatorModule,
    TranslocoModule,
    MatTooltipModule,
    MatTabsModule,
    DanceEventPreviewCardModule,
    DmEventPreviewCardModule,
    RouterModule.forChild(routes),
    MatStepperModule,
    SharedCancelDialogModule,
    CourseRulesFormModule,
    CourseAppointmentsFormModule,
    PassInfoFormModule,
    DfSharedButtonModule,
    PassTargetsTableModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    PassPriceFormModule
  ]
})
export class CreatePassStepperPageModule {}
