<ng-container *transloco="let t">
  <div class="flex-column align-center" [formGroup]="createUserGroupFormGroup">
    <div class="dfm-create-user-group-page-container w-100 flex-column">
      <div class="flex-row">
        <mat-card class="dfm-create-user-group-page-card-container round-corners mat-elevation-z3 w-100 no-shadow-mat-card">
          <div class="p-40">
            <div class="dfm-create-user-group-page-header">
              <span>{{ t('DFM_USER_GROUP.NEW_USER_GROUP') }}</span>
            </div>
            <mat-divider class="mt-20 mb-40"></mat-divider>
            <div class="flex-column dfm-create-user-group-page-content">
              <div class="dfm-create-user-group-name-container">
                <span>{{ t('NAME') }}</span>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>{{ t('NAME') }}</mat-label>
                  <input
                    matInput
                    formControlName="name"
                    autocomplete="off"
                    #name
                    id="create-user-group-name-input"
                    [placeholder]="t('NAME')"
                  />
                  <mat-error *ngIf="createUserGroupFormGroup.controls.name.hasError('required')">
                    {{ t('DFM_USER_GROUP.NAME_REQUIRED') }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="dfm-create-user-group-name-container">
                <span>{{ t('DFM_USER_GROUP.USER') }}</span>
                <df-shared-lib-user-search-autocomplete
                    class="w-100"
                    (optionSelected)="onUserSelected($event)"
                    (inputChange)="onInputChange($event)"
                    [label]="t('GENERIC_WRAPPER.SEARCH_USER')"
                    [resetFormAfterSelection]="true"
                    [showUserSearchLoading]="showUserSearchLoadingSpinner"
                    [userOptions]="userOptions">
                </df-shared-lib-user-search-autocomplete>
                <mat-chip-listbox aria-label="Enter fruits">
                  @for (selectedUser of selectedUsers; track selectedUser) {
                    <mat-chip-row
                      (removed)="onUserRemoved(selectedUser)"
                      [editable]="false"
                      [removable]="true">
                      <div style="display: flex; flex-direction: row; gap: 8px;">
                        <img [src]="selectedUser.imageUrl && selectedUser.imageUrl !== '' ? selectedUser.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" alt="User Avatar" class="avatar">
                        {{selectedUser.value + ' (' + selectedUser.data.email + ')'}}
                      </div>
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-row>
                  }
                </mat-chip-listbox>
              </div>
              <div class="dfm-create-user-group-button-container">
                <df-shared-lib-button 
                  [isDisabled]="isWaitingForCreateUserGroupResponse" 
                  [loading]="isWaitingForCreateUserGroupResponse" 
                  [materialButtonStyleType]="'outlined'" 
                  [buttonLabel]="t('CANCEL')" 
                  (buttonClicked)="onCancel()"
                  class="w-100-lt-sm"
                ></df-shared-lib-button>
                <df-shared-lib-button 
                  [isDisabled]="isWaitingForCreateUserGroupResponse" 
                  [loading]="isWaitingForCreateUserGroupResponse" 
                  [buttonLabel]="t('SAVE')" 
                  (buttonClicked)="onSubmit()"
                  class="w-100-lt-sm"
                  id="user-group-save-button"
                ></df-shared-lib-button>
              </div>
              <div *ngIf="createFailedNoConnection" style="height: 20px; align-self: center;">
                <p class="dfm-create-location-no-connection-error">{{ t('NO_CONNECTION_TRY_LATER') }}</p>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
