import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserSocialLinksPreviewComponent } from './user-social-links-preview.component';
import { ExternalLinkModule } from '../../../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [UserSocialLinksPreviewComponent],
  exports: [UserSocialLinksPreviewComponent],
  imports: [
    CommonModule,
    MatIconModule,
    ExternalLinkModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslocoDirective
  ]
})
export class UserSocialLinksPreviewModule {}
