import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CourseInterface, DanceSchoolInterface, DEFAULT_EVENT_PICTURE_PLACEHOLDER, UserPassCollapsedByDanceSchoolInterface, UserPassStatusEnum, UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-course-user-pass-subscription-lib-preview-card',
  templateUrl: './dance-course-user-pass-subscription-preview-card.component.html',
  styleUrls: ['./dance-course-user-pass-subscription-preview-card.component.scss']
})
export class DanceCourseUserPassSubscriptionPreviewCardComponent implements OnInit {
  @Input() danceManager$: Observable<DanceSchoolInterface>;
  @Input() danceManager: DanceSchoolInterface;
  @Input() courses: CourseInterface[];
  @Input() loading: boolean;
  @Input() small = false;
  @Input() routerLink;
  @Input() userPassCollapsed: UserPassCollapsedByDanceSchoolInterface;
  @Input() clickOpensNewWindow = false;

  // @Output() downloadPasses = new EventEmitter<{
  //   danceCourseId: string;
  //   passOrderId: string;
  // }>();

  currentUser: UsersInterface;
  shownStamps: number[] = [];
  
  subscriptions: Subscription = new Subscription();

  currentLang: string;
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;
  
  userPassStatusEnum = UserPassStatusEnum;

  constructor(private readonly translocoService: TranslocoService) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    this.createStampsShownArray();
  }

  createStampsShownArray(): void {
    const usageCount: number = this.userPassCollapsed.userPassSubscription.weeklyUsage;
    const usedCount: number = this.userPassCollapsed.usedCount;

    const displayCount = usageCount > 10 ? 10 : usageCount;
    let start = 0;
    if (usedCount > 5) {
      start = usedCount - (usedCount % 5);
    }
    if (start + 10 > usageCount) {
      start = usageCount - 10;
    }
    start = Math.max(start, 0);
    for (let i = 0; i < displayCount; i++) {
      this.shownStamps[i] = start + 1 + i;
    }
  }
}
