import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EventTicketOrderSummaryComponent } from './event-ticket-order-summary.component';
import { PipesModule } from '../../pipes';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [EventTicketOrderSummaryComponent],
  exports: [EventTicketOrderSummaryComponent],
    imports: [
      CommonModule,
      FormsModule,
      PipesModule,
      MatIconModule,
      MatDividerModule,
      TranslocoDirective,
    ],
})
export class EventTicketOrderSummaryModule {}
