import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { LoginFormModule } from '../../components';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [LoginPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: '',
      component: LoginPageComponent
    }]),
    LoginFormModule,
    MatDividerModule,
    NgOptimizedImage,
    TranslocoModule
  ],
  exports: [LoginPageComponent]
})
export class LoginPageModule { }
