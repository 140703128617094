import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DanceManagerSubscriptionInterface, DanceManagerSubscriptionInvoiceInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dm-subscription-payment-history-card',
  templateUrl: './dm-subscription-payment-history-card.component.html',
  styleUrls: ['./dm-subscription-payment-history-card.component.scss'],
})
export class DmSubscriptionPaymentHistoryCardComponent implements OnInit {
  @Input() danceManagerSubscription: DanceManagerSubscriptionInterface;
  @Output() showPaymentHistoryClick = new EventEmitter<boolean>();

  displayedColumns = ['date', 'total', 'downloadInvoice'];
  dataSource: MatTableDataSource<DanceManagerSubscriptionInvoiceInterface>;

  currentLang: string;

  constructor(private readonly translocoService: TranslocoService) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    // @ts-ignore
    this.dataSource = new MatTableDataSource(
      this.danceManagerSubscription.danceManagerSubscriptionInvoices!
    );
  }
}
