<ng-container *transloco="let t">
  <ng-container *ngIf="passedFormGroup">
    <div
      class="step-body-container container-content-gap flex-column"
      [formGroup]="passedFormGroup"
    >
      <div>
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.event-ticket-pricing.price') }}
        </h4>
        <p>{{ t('GENERIC_WRAPPER.event-ticket-pricing.ticket-price') }}</p>
        <mat-form-field appearance="outline" class="w-25">
          <mat-label>{{
            t('GENERIC_WRAPPER.event-ticket-pricing.price-label')
          }}</mat-label>
          <span
            style="cursor: default"
            matSuffix
            class="mat-icon-button"
            aria-label="Clear"
          >
            <mat-icon>euro_symbol</mat-icon>
          </span>
          <input
            dfNoScroll
            [formatNumber]="2"
            [step]="0.01"
            formControlName="price"
            id="price"
            matInput
            type="number"
            [title]="passedFormGroup.controls.price.disabled ? t('GENERIC_WRAPPER.NO_CHANGES_POSSIBLE') : ''"
          />
          <mat-error *ngIf="passedFormGroup.controls.price.hasError('min')">
            {{
              t('GENERIC_WRAPPER.event-ticket-pricing.min-price-error')
            }}</mat-error
          >
          <mat-error *ngIf="passedFormGroup.controls.price.hasError('max')">
            {{
              t('GENERIC_WRAPPER.event-ticket-pricing.max-price-error')
            }}</mat-error
          >
        </mat-form-field>
      </div>
      <div formGroupName="tax">
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.event-ticket-pricing.vat-amount') }}
        </h4>
        <p>{{ t('GENERIC_WRAPPER.event-ticket-pricing.vat-in-price') }}</p>
        <mat-form-field appearance="outline" class="w-25">
          <mat-label>{{
            t('GENERIC_WRAPPER.event-ticket-pricing.vat-label')
          }}</mat-label>
          <input
            dfNoScroll
            matInput
            type="number"
            id="vat"
            formControlName="percent"
            [title]="passedFormGroup.controls.price.disabled ? t('GENERIC_WRAPPER.NO_CHANGES_POSSIBLE') : ''"
          />
          <mat-error
            *ngIf="passedFormGroup.controls.tax.get('percent').hasError('min')"
          >
            {{
              t('GENERIC_WRAPPER.event-ticket-pricing.min-vat-error')
            }}</mat-error
          >

          <mat-error
            *ngIf="passedFormGroup.controls.tax.get('percent').hasError('max')"
          >
            {{
              t('GENERIC_WRAPPER.event-ticket-pricing.max-vat-error')
            }}</mat-error
          >

          <button
            style="cursor: default"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            type="button"
            [disabled]="passedFormGroup.controls.tax.disabled"
          >
            <mat-icon>percent</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div
        class="ticket-fees"
      >
        <div>
          <div class="flex-row align-start-center" style="gap: 10px;" >
            <h4 class="step-body-title">
              {{ t('GENERIC_WRAPPER.event-ticket-pricing.ticket-fees') }}
            </h4>
            <mat-icon
              style="cursor: pointer"
              class="material-icons-round"
              #tooltip="matTooltip"
              matTooltip="{{
                t('GENERIC_WRAPPER.event-ticket-pricing.tool-tip-ticket-fees')
              }}"
              matTooltipPosition="above"
              >help
            </mat-icon>
          </div>
          <p>
            {{ t('GENERIC_WRAPPER.event-ticket-pricing.specify-ticket-fees') }}
          </p>
          <div class="ml-10">
            <mat-radio-group
              disabled
              class="flex-column"
              formControlName="feesPaidBy"
            >
              <mat-radio-button class="mb-10" [value]="FeesPaidByEnum.CREATOR">
                {{ t('GENERIC_WRAPPER.event-ticket-pricing.owner-pay-fees') }}
              </mat-radio-button>
              <mat-radio-button class="mb-10" [value]="FeesPaidByEnum.CUSTOMER">
                {{ t('GENERIC_WRAPPER.event-ticket-pricing.customer-pay-fees') }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
