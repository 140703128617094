import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';

import { DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, MessagePartnerInterface } from '@platri/df-common-core';
import { MessageHttpService } from '../services';

export interface IAddToPartner {
  toPartner: MessagePartnerInterface;
  add: boolean;
}

@Component({
  selector: 'df-search-conversation-partners',
  styleUrls: ['./search-conversation-partners.component.scss'],
  templateUrl: './search-conversation-partners.component.html'
})
export class SearchConversationPartnersComponent {
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;

  @Input()
  currentPartnerId: string;

  username = '';
  partners: MessagePartnerInterface[] = [];
  toPartners: MessagePartnerInterface[] = [];
  error = '';
  partnerSearchLoading = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private readonly messageHttpService: MessageHttpService) {}

  addToPartner(addToPartner: IAddToPartner): boolean {
    if (addToPartner.add) {
      if (this.toPartners.length < 7) {
        if (!this.toPartners.find((toPartnerEntry) => toPartnerEntry.id === addToPartner.toPartner.id)) {
          this.toPartners.push(addToPartner.toPartner);
        }
        this.error = '';
        return true;
      } else {
        this.error = 'Maximal 7 Partner können ausgewählt werden';
        return false;
      }
    } else {
      this.error = '';
      this.removeToPartner(addToPartner.toPartner);
    }

    return false;
  }

  openConversation(): void {
    this.router.navigate([], {
      queryParams: {
        partnerIds: this.toPartners.map((toPartner) => toPartner.id).join(',')
      },
      relativeTo: this.activatedRoute
    });
    this.username = '';
    this.toPartners = [];
    this.partners = [];
  }

  removeToPartner(toPartner: MessagePartnerInterface): void {
    const index = this.toPartners.findIndex((partner) => partner.id === toPartner.id);
    if (index > -1) {
      this.toPartners.splice(index, 1);
    }
  }

  findPartners(): void {
    if (this.username === '') {
      this.partners = [];
    } else {
      this.partnerSearchLoading = true;

      this.messageHttpService
        .searchPartners(this.username)
        .pipe(
          tap(() => {
            this.partnerSearchLoading = false;
          })
        )
        .subscribe((result) => {
          this.partners = result;
        });
    }
  }

  hasToPartner(partner: MessagePartnerInterface): boolean {
    return !!this.toPartners.find((toPartner) => toPartner.id === partner.id);
  }
}
