<ng-container *transloco="let t">
  <ng-container>
    <div [class]="ratio.width === ratio.height ? 'flex-row-start-center' : 'flex-column align-start'" style="gap: 20px;">
      <div
        class="placeholder relative limitSizeOnMobile flex-row-center-center"
        style="min-width: 120px; position: relative;"
        (click)="openAddImageDialog()"
        [ngClass]="[
          ratio.width === ratio.height ? 'circle' : '',
          croppedImageBaseAs64 || existingImage ? 'preview-hide-bg' : ''
        ]"
        *ngIf="showEmptyPreview || existingImage || isOpenAddImage"
        (mouseenter)="isHovering = true" 
        (mouseleave)="isHovering = false"
      >
        <mat-icon *ngIf="!(croppedImageBaseAs64 || existingImage)">
          {{ratio.width === ratio.height ? 'person' : 'image'}}
        </mat-icon>
        <img
          *ngIf="croppedImageBaseAs64 || existingImage"
          class="w-100"
          src="{{ croppedImageBaseAs64 ? croppedImageBaseAs64 : existingImage }}"
          [alt]="t('GENERIC_WRAPPER.PROFILE_PICTURE')"
        />
        <div class="overlay" *ngIf="isHovering"></div> 
        <mat-icon
          *ngIf="isOpenAddImage && croppedImageBaseAs64"
          color="warn"
          class="delete-icon"
          (click)="cancelPicture($event)"
        >cancel</mat-icon>
        <div *ngIf="isHovering" class="add-pic-overlay flex-row-center-center">
          <mat-icon>photo_camera</mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
