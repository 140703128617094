<ng-container *transloco="let t">
  <div class="flex-container">
    <div *ngIf="showSocialButtons" style="display: flex; flex-direction: column; text-align: center; align-items: center; justify-content: center; gap: 10px;">
        <elab-google-sign-in-button (accessTokenEmitter)="signInWithGoogle($event)"></elab-google-sign-in-button>
        <elab-facebook-sign-in-button (accessTokenEmitter)="signInWithFacebook($event)"></elab-facebook-sign-in-button>
    </div>
    <div *ngIf="showSocialButtons" style="display: flex; flex-direction: row; justify-content: center; width: 350px; margin: auto">
      <mat-divider class="divider-left"></mat-divider>
        <h4 style="text-align: center; margin: 28px 0; color: #808080">{{t('GENERIC_WRAPPER.OR')}}</h4>
      <mat-divider class="divider-right"></mat-divider>
    </div>
    <form class="form" id="login-form" [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
      <div class="flex-container-row">
        <div style="margin-bottom: -20px" class="form-step-container flex-container-column">
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.EMAIL_OR_USERNAME')}}</mat-label>
            <input matInput type="text" id="emailOrUsername-input" [formControl]="loginFormGroup.controls.emailOrUsername" [placeholder]="t('GENERIC_WRAPPER.EMAIL_OR_USERNAME')" data-cy="emailOrUsername-input">
            <mat-error *ngIf="loginFormGroup.controls.emailOrUsername.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.EMAIL_OR_USERNAME_REQUIRED') }}
            </mat-error>
            <mat-error *ngIf="loginFormGroup.controls.emailOrUsername.hasError('maxlength')" class="errorTextSpaceBetween" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.INPUT_IS_TOO_LONG') }}
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline" style="margin-top: 5px;">
            <mat-label>{{ t('GENERIC_WRAPPER.PASSWORD') }}</mat-label>
            <input matInput id="password-input" [formControl]="loginFormGroup.controls.password" [placeholder]="t('GENERIC_WRAPPER.PASSWORD')" [type]="hidePassword ? 'password' : 'text'" data-cy="password-input">
            <mat-icon class="hide-show-icon" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="loginFormGroup.controls.password.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.PASSWORD_REQUIRED') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; align-items: center; margin-top: 10px;">
      
        <mat-checkbox color="primary">{{ t('GENERIC_WRAPPER.STAY_LOGGED_IN') }}</mat-checkbox>
        <button type="button" id="forgot-password-button" style="align-self: flex-end; padding: 0; height: 40px" mat-button color="primary" (click)="routeToForgotPassword()" data-cy="forgot-password-button">{{t('GENERIC_WRAPPER.FORGOT_PASSWORD')}}?</button>
      </div>
        
      <div style="width: 100%">
        <button class="round" id="login-button" [disabled]="isWaitingForLoginResponse || !loginFormGroup.valid" [class.spinner]="isWaitingForLoginResponse" style="width: 100%; border-radius: 10px; color: white" mat-raised-button color="primary" type="submit" data-cy="login-button">{{t('GENERIC_WRAPPER.LOGIN')}}</button>
  
      </div>
      
      <div style="width: 100%; padding-top : 10px">
        <button class="round" id="magic-link-button" [disabled]="isWaitingForLoginResponse" style="width: 100%; border-radius: 10px; color: black;" mat-stroked-button color="primary" (click)="routeToLoginWithoutPassword()" data-cy="login-without-password-button">{{t('GENERIC_WRAPPER.LOGIN_WITHOUT_PASSWORD')}}</button>
      </div>
  
      <div style="margin-top: 16px;">
        <p class="hind-mtr" style="margin-top: 5px; margin-block-start: 0; margin-block-end: 0;"><span style="margin-right: 12px;">{{t('GENERIC_WRAPPER.YOU_HAVE_NO_ACCOUNT')}}?</span> <span class="hind-mtr link" (click)="routeToRegister()" data-cy="sign-up-button">{{t('GENERIC_WRAPPER.REGISTER')}}</span></p>
      </div>
      
      <div style="height: 60px; text-align: center;">
        <p class="login-error wrong-data" *ngIf="signInFailedWrongData" data-cy="wrong-data-error">{{t('GENERIC_WRAPPER.PROVIDED_DATA_WRONG')}}</p>
     <p class="login-error" *ngIf="signInFailedNoConnection" data-cy="connection-error">{{t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED')}}</p>
     <p class="login-error" *ngIf="signInFailedInternalServer" data-cy="internal-server-error">{{t('GENERIC_WRAPPER.SERVER_ERROR')}}</p>
     <p class="login-error" *ngIf="loginFormGroup.controls.emailOrUsername.hasError('whitespace')" data-cy="internal-server-error">{{t('GENERIC_WRAPPER.WHITESPACE_NOT_ALLOWED_EMAIL_USERNAME')}}</p> </div>
      
      
    </form>
  </div>
  
  <p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">{{t('GENERIC_WRAPPER.FATAL_ERROR_LOADING_DATA')}}</p>
</ng-container>
