import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { DmSubscriptionInfoCardComponent } from './dm-subscription-info-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { SharedCancelDialogModule } from '../cancel-dialog';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DmSubscriptionInfoCardComponent],
    imports: [
      CommonModule,
      MatChipsModule,
      MatDividerModule,
      ReactiveFormsModule,
      PipesModule,
      MatProgressSpinnerModule,
      SharedCancelDialogModule,
      MatIconModule,
      TranslocoDirective,
    ],
  exports: [DmSubscriptionInfoCardComponent],
})
export class DmSubscriptionInfoCardModule {}
