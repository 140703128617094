import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmUserGroupComponent } from './dfm-user-group.component';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import {
  CourseListTableModule,
  CoursePassesTableModule,
  CourseSubscriptionTableModule,
  DfSharedButtonModule,
  PipesModule,
  SharedEmptyPageModule
} from '@platri/dfx-angular-core';
import { MatCard } from '@angular/material/card';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatTableModule } from '@angular/material/table';
import { MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DfmUserGroupComponent],
  imports: [
    CommonModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    CourseListTableModule,
    CoursePassesTableModule,
    CourseSubscriptionTableModule,
    DfSharedButtonModule,
    MatCard,
    MatIcon,
    MatTab,
    MatTabGroup,
    SharedEmptyPageModule,
    TranslocoDirective,
    MatTableModule,
    PipesModule,
    MatProgressSpinner,
    MatIconModule,
    MatIconButton
  ]
})
export class DfmUserGroupModule {}
