import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InviteToDmPageComponent } from './invite-to-dm-page.component';
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CardWithBackgroundModule, DfSharedButtonModule } from '../../components';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [InviteToDmPageComponent],
    imports: [
      RouterModule.forChild([
          {
              path: '',
              component: InviteToDmPageComponent
          }
      ]),
      CommonModule,
      CardWithBackgroundModule,
      MatCardModule,
      MatIconModule,
      MatButtonModule,
      MatProgressSpinnerModule,
      DfSharedButtonModule,
      MatButtonModule,
      TranslocoDirective,
    ]
})
export class InviteToDmPageModule {
}
