import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthStateService, UserHttpService } from '../../services';
import { TranslocoService } from '@jsverse/transloco';
import { DateAdapter } from '@angular/material/core';
import { DEFAULT_LANGUAGE, ENGLISH_LANGUAGE, SystemLanguageEnum, UserAppLangEnum, UsersInterface } from '@platri/df-common-core';
import { dfLoginRoute } from '../../shared';

@Component({
  selector: 'df-shared-lib-header-language-selection',
  templateUrl: './header-language-selection.component.html',
  styleUrls: ['./header-language-selection.component.scss'],
})
export class HeaderLanguageSelectionComponent implements OnDestroy {
  currentUser: UsersInterface;
  currentUserLanguage: string;
  languages = Object.values(SystemLanguageEnum);
  defaultLanguage = DEFAULT_LANGUAGE;

  isMenuOpen = false;

  subscription: Subscription = new Subscription();

  constructor(
    private userHttpService: UserHttpService,
    private authService: AuthStateService,
    protected translocoService: TranslocoService,
    private dateAdapter: DateAdapter<Date>
  ) {
    
  }

  changeAndSaveLanguage(lang: string): void {
    this.translocoService.setActiveLang(lang.toLowerCase());
    this.dateAdapter.setLocale(lang);
    this.currentUserLanguage = lang;
    if (this.currentUser) {
      this.userHttpService
        .updateUser({ appLang: lang.toUpperCase() as UserAppLangEnum })
        .subscribe((user) => {
          this.authService.sendCurrentUserChanges({
            ...this.authService.getSyncCurrentUser(),
            appLang: user.appLang
          });
        });
    }
  }

  checkAppLanguage(): void {
    this.subscription.add(
      this.authService
        .getAsyncCurrentUser()
        .subscribe((currentUser) => {
          if (currentUser) {
            this.currentUser = currentUser;
            this.currentUserLanguage = currentUser.appLang.toUpperCase();
            this.dateAdapter.setLocale(this.currentUserLanguage);
          } else {
            this.useBrowserLanguageIfExist();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  useBrowserLanguageIfExist(): void {
    const userLang = navigator.language;
    if (userLang) {
      if (userLang.includes('en')) {
        this.defaultLanguage = ENGLISH_LANGUAGE;
      }
    }
    this.translocoService.setActiveLang(this.defaultLanguage.toLowerCase());
    this.dateAdapter.setLocale(this.defaultLanguage);
    this.currentUserLanguage = this.defaultLanguage;
  }

  protected readonly dfLoginRoute = dfLoginRoute;
}
