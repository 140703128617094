import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordPageComponent } from './reset-password-page.component';
import { ResetPasswordFormModule } from '../../components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ResetPasswordPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: '',
      component: ResetPasswordPageComponent
    }]),
    ResetPasswordFormModule
  ],
  exports: [ResetPasswordPageComponent]
})
export class ResetPasswordPageModule { }
