import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavResponsivePersonalComponent } from './sidenav-responsive-personal.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [SidenavResponsivePersonalComponent],
  exports: [SidenavResponsivePersonalComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive,
    MatBadgeModule,
    TranslocoDirective
  ],
  providers: []
})
export class SidenavResponsivePersonalModule {}
