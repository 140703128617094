import { Component, Input, OnInit } from '@angular/core';
import { AuthStateService, UserFollowStateService } from '../../services';
import { filter } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FollowInterface, FollowTargetTypeEnum, FollowUserInterface, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-follow-info-card',
  templateUrl: './follow-info-card.component.html',
  styleUrls: ['./follow-info-card.component.scss']
})
export class FollowInfoCardComponent implements OnInit {
  @Input() following: boolean;
  @Input() followUser: FollowUserInterface;

  currentUser: UsersInterface;
  localFollowStatus: boolean;
  localFollowId: string;

  constructor(private readonly userFollowStateService: UserFollowStateService, private authService: AuthStateService, private matSnackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.localFollowStatus = this.followUser.isCurrentUserFollowing;
  }

  doFollowUser(): void {
    this.userFollowStateService
      .followUser(this.followUser.user ? this.followUser.user.id : this.followUser.danceSchool.id, this.followUser.user ? FollowTargetTypeEnum.USER : FollowTargetTypeEnum.DANCE_SCHOOL)
      .pipe(filter((v) => v !== null))
      .subscribe((data: FollowInterface) => {
        if (data) {
          this.localFollowId = data.id;
        }
      });
    this.localFollowStatus = true;
  }

  doUnfollowUser(): void {
    this.userFollowStateService
      .unfollowUser(this.localFollowId ? this.localFollowId : this.followUser.currentUserFollowId)
      .pipe(filter((v) => v !== null))
      .subscribe();
    this.localFollowStatus = false;
  }

  follow(): void {
    if (this.localFollowStatus) {
      this.doUnfollowUser();
    } else {
      this.doFollowUser();
    }
  }
}
