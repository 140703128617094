<ng-container *transloco="let t">
  <button (click)="onNotificationIconClick()" mat-icon-button class="border-radius-20 no-border mat-badge-icon" [matMenuTriggerFor]="multipleUserButtonBox" matTooltip="{{ t('GENERIC_WRAPPER.NOTIFICATIONS') }}">
    <mat-icon class="material-icons-outlined" [style.color]="'#4D4D4D'">notifications</mat-icon>
    <span>
      <span *ngIf="unreadCount?.userHasUnseenNotifications" [matBadge]="unreadCount?.count" [matBadgeHidden]="unreadCount?.count === 0" matBadgeColor="accent" class="badge-content"></span>
    </span>
  </button>
  
  <mat-menu #multipleUserButtonBox="matMenu" 
            data-cy="notification-window"
            [xPosition]="'before'" 
            [hasBackdrop]="true">
    
    <ng-container *ngIf="(loading$ | async) === true; else notificationsList">
      <div class="spinner-container">
        <mat-spinner [diameter]="35"></mat-spinner>
      </div>
    </ng-container>
  
    <ng-template #notificationsList>
      <div *ngIf="notifications.length > 0; else emptyNotifications" class="flex-column">
        <ng-container *ngFor="let notification of notifications; let last = last">
          <df-shared-lib-user-notification-item [notification]="notification"></df-shared-lib-user-notification-item>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </div>
    </ng-template>
  
  <ng-template #emptyNotifications>
    <div class="no-notifications">
      <div class="notification-header"><span>{{ t('DANCEFLAVORS_HEADER.NO_NOTIFICATIONS_TITLE') }}</span></div>
      <div class="notification-body"><span>{{ t('DANCEFLAVORS_HEADER.NO_NOTIFICATIONS_TEXT') }}</span></div>
    </div>
  </ng-template>
  </mat-menu>
</ng-container>
