import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryTicketListComponent } from './inventory-ticket-list.component';
import { MatChip } from '@angular/material/chips';
import { DanceEventPreviewCardModule, DfSharedButtonModule } from '@platri/dfx-angular-core';
import { InventoryTicketListItemModule } from '../inventory-ticket-list-item';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [InventoryTicketListComponent],
    imports: [
      CommonModule,
      MatChip,
      DanceEventPreviewCardModule,
      DfSharedButtonModule,
      InventoryTicketListItemModule,
      TranslocoDirective
    ],
  exports: [InventoryTicketListComponent]
})
export class InventoryTicketListModule {}
