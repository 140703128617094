import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfilePage } from './pages';
import { NameResolver } from '@platri/dfx-angular-core';

const routes: Routes = [
  {
    path: '',
    resolve: {
      targetObject: NameResolver,
    },
    component: ProfilePage
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfUserAppRoutingModule {
  constructor() {
    console.log('FrontendDfUserAppRoutingModule initialized');
  }
}
