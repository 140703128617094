import { UserStatusEnum } from '../enums';

export interface CheckUserExistsResultInterface {
  exists: boolean;
  userStatus?: UserStatusEnum;
  existingLoginInformation?: ExistingLoginInformation;
}

export interface ExistingLoginInformation {
  profilePictureUrl?: string;
  welcomeName?: string;
  // TODO as soon as mobile has changed the value, please remove firstName
  firstName?: string;
  suggestedAuthMethod?: string; // FACEBOOK, GOOGLE, EMAIL
}
