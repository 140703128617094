import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePassRequestDto, DanceManagerPassesPublicResponseDto, DfmCoursePassesTableDto, environmentForWeb, PassDto, PassSaleSelectionPageResponseDto, PassStatusEnum, UpdatePassRequestDto } from '@platri/df-common-core';

@Injectable({providedIn: 'root',})
export class PassHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiTicketUrl}/pass`;

  constructor(private readonly http: HttpClient) {}

  create(data: CreatePassRequestDto): Observable<PassDto> {
    return this.http.post<PassDto>(`${this.SERVICE_URL}`, data);
  }
  
  update(id: string, data: UpdatePassRequestDto): Observable<PassDto> {
    return this.http.put<PassDto>(`${this.SERVICE_URL}/${id}`, data);
  }
  
  getById(id: string): Observable<PassDto> {
    return this.http.get<PassDto>(`${this.SERVICE_URL}/${id}`);
  }
  
  getAllForDfmCoursePassesByDanceManagerId(danceManagerId: string, status?: PassStatusEnum): Observable<DfmCoursePassesTableDto[]> {
    let params: HttpParams = new HttpParams();
    if (status) {
      params = params.set('status', status);
    }
    return this.http.get<DfmCoursePassesTableDto[]>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}`, {params});
  }
  
  getAllByTargetId(targetId: string): Observable<PassDto[]> {
    return this.http.get<PassDto[]>(`${this.SERVICE_URL}/target/${targetId}`);
  }

  getAllByDanceManagerIdPublicView(danceManagerId: string): Observable<DanceManagerPassesPublicResponseDto[]> {
    return this.http.get<DanceManagerPassesPublicResponseDto[]>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/public`);
  }
  
  deletePassById(id: string): Observable<void> {
    return this.http.delete<void>(`${this.SERVICE_URL}/${id}`);
  }

  updatePassStatusById(id: string, status: PassStatusEnum): Observable<PassDto> {
    return this.http.patch<PassDto>(`${this.SERVICE_URL}/${id}/status`, {status});
  }

  deactivatePassById(id: string): Observable<PassDto> {
    return this.http.patch<PassDto>(`${this.SERVICE_URL}/${id}/deactivate`, {});
  }
  
  getAllForSaleSelectionPageByTargetId(targetId): Observable<PassSaleSelectionPageResponseDto[]> {
    return this.http.get<PassSaleSelectionPageResponseDto[]>(`${this.SERVICE_URL}/target/${targetId}/sale-selection-page`);
  }

  updateSequenceNumber(danceManagerId: string, newSequence: {id: string; sequenceNumber: number}[]): Observable<void> {
    return this.http.patch<void>(this.SERVICE_URL + '/sequence/' + danceManagerId , newSequence);
  }
  
  getCourseMinPriceById(danceCourseId: string): Observable<number> {
    return this.http.get<number>(this.SERVICE_URL + '/courses-min-price/' + danceCourseId);
  }
}
