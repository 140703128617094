import {Component, Input, OnInit} from '@angular/core';
import { MessageInterface, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit{
  @Input() currentPartnerId: string;
  @Input() conversationPartner: UsersInterface;
  @Input() message: MessageInterface;
  @Input() nextMessage: MessageInterface | undefined;
  @Input() showUsername: boolean;

  isUser = true;

  ngOnInit(): void {
    if (this.conversationPartner.id.includes('dance-school')) {
      this.isUser = false;
    }
  }

  showAvatar(): boolean {
    return (
      this.message.fromPartnerId !== this.currentPartnerId &&
      (this.nextMessage === undefined ||
        this.nextMessage.toPartnerId !== this.message.toPartnerId)
    );
  }

  private getUnixTime(date: Date): number {
    return Math.round(new Date(date).getTime() / 1000);
  }
}
