<ng-container *transloco="let t">
  <div class="w-100 outer-flex-container" id="top-column">
    <div id="left-column" class="w-100 flex-column inner-flex-container">
      <div *ngIf="danceCourse" class="course-header relative">
        <div [ngClass]="isOwnerOrAdmin ? 'editable' : 'read-only'" class="course-header-highlighter" (click)="openTitleDialog()">
          <div class="h-100 flex-row-center-center">
            <mat-icon fontSet="material-icons-outlined">photo_camera</mat-icon>
          </div>
        </div>
        
        <button mat-icon-button *ngIf="isOwnerOrAdmin && danceCourse.mainImage && danceCourse.mainImage.url" class="delete-button" (click)="confirmDelete()">
          <mat-icon>clear</mat-icon>
        </button>
        <img 
          [src]=" danceCourse.mainImage ? danceCourse.mainImage.url : 'assets/images/event_header.png' " 
          alt="" 
          [ngClass]="isOwnerOrAdmin ? 'editable' : 'read-only'" 
          class="course-title-image"
        />
      </div>
    </div>
    <div id="right-column" class="w-100 flex-column right-column inner-flex-container px2">
      <div *ngIf="isOwnerOrAdmin && showEditButton" class="flex-row">
         <a [href]="'/app/dance-manager/' + danceCourse.danceManager.id + '/courses/preview/' + danceCourse.id" class="flex-row">
          <mat-icon>edit</mat-icon>
          <span class="pl-5 align-start-center">{{ t('GENERIC_WRAPPER.EDIT_COURSE')}}</span>
       </a> 
      </div>
      <div class="w-100 flex-row align-space-between-start">
        <h1>{{ danceCourse.name }}</h1>
        <button mat-icon-button (click)="openShareComponentDialog()" class="share-like-button bg-glassmorph flex-hide flex-hide-gt-xs" type="button">
          <mat-icon>share</mat-icon>
        </button>
      </div>
  
      <span *ngIf="danceCourse.nextAppointmentDate ||(danceCourse.appointments && danceCourse.appointments[0])"><strong>{{ t('GENERIC_WRAPPER.NEXT_APPOINTMENT') }}: </strong>
        {{danceCourse.nextAppointmentDate ?? danceCourse.appointments[0].date | dateTimezoned : 'UTC' : currentLang : danceCourse.nextAppointmentDuration ?? danceCourse.appointments[0].duration }}
      </span>
      <df-shared-lib-level-slider-view 
        [fromLevel]="danceCourse.startDifficulty" 
        [toLevel]="danceCourse.endDifficulty">
      </df-shared-lib-level-slider-view>
      
      <div *ngIf="danceCourse.status === CourseStatusEnum.ARCHIVED" class="align-center archived-course-box">
        <mat-icon style="color: #3377FF; margin-right: 10px;">event_busy</mat-icon>
        {{ t('GENERIC_WRAPPER.ARCHIVED_COURSE') }}
      </div>
      
      <div *ngIf="danceCourse.isCheckInActive" class="align-start-center flex-row" style="margin: 5px 0 5px 0;">
        <mat-icon style="min-width: 30px;">info</mat-icon>
        <span>{{t('GENERIC_WRAPPER.CHECKIN_INFO')}}</span>
      </div>
      
      <div class="flex-row align-start-center gap-15 hide-on-mobile">
        <div class="flex-column w-100">
          <df-shared-lib-button
            *ngIf="passMinPrice > -1"
            [buttonLabel]="(t('GENERIC_WRAPPER.PARTICIPATE')) + (passMinPrice > -1 ? ' ' + (t('GENERIC_WRAPPER.PRICE_FROM')) + ' ' + (passMinPrice | centsToCurrencyString: 'EUR') : '')"
            [height]="'40px'"
            [minWidth]="'160px'"
            [isDisabled]="danceCourse.status === CourseStatusEnum.ARCHIVED"
            (buttonClicked)="navigateToCoursePasses()"
            [matIconName]="'shopping_cart'"
          ></df-shared-lib-button>
          <df-shared-lib-button
            *ngIf="passMinPrice === -1"
            [buttonLabel]="t('GENERIC_WRAPPER.NO_SALE')"
            [height]="'40px'"
            [minWidth]="'160px'"
            [isDisabled]="true"
          ></df-shared-lib-button>
          <span *ngIf="danceCourse.pageViews > 5" style="color: orangered;text-align: center;display: flex;flex-direction: row;place-content: center;margin-top: 4px;">{{t('GENERIC_WRAPPER.ALREADY_SEEN_PAGE_VIEW_TEXT', {views: danceCourse.pageViews.webViews + danceCourse.pageViews.mobileViews}) }}</span>
        </div>
        <button mat-icon-button (click)="openShareComponentDialog()"><mat-icon>share</mat-icon></button>
      </div>
      <div class="flex-column hide-on-web w-100">
        <df-shared-lib-button 
          class="w-100" 
          [buttonLabel]="t('GENERIC_WRAPPER.PARTICIPATE')" 
          [height]="'48px'" 
          [minWidth]="'100%'" 
          (buttonClicked)="navigateToCoursePasses()" 
          [matIconName]="'shopping_cart'"
        ></df-shared-lib-button>
        <span *ngIf="pageViews && pageViews > 5" style="color: orangered;text-align: center;display: flex;flex-direction: row;place-content: center;margin-top: 4px;">{{t('GENERIC_WRAPPER.ALREADY_SEEN_PAGE_VIEW_TEXT', {views: pageViews}) }}</span>
      </div>
    </div>
  </div>
</ng-container>
