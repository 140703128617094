import { PassTargetTypeEnum, UserPassScanInterface } from '../../..';

export class UserPassScanDto implements UserPassScanInterface {
  id: string;
  userPassId: string;
  scannedByUserId: string;
  targetId: string;
  scannedAt: Date;
  targetType: PassTargetTypeEnum;
}
