import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DfCheckoutCompleteComponent, PipesModule } from '@platri/dfx-angular-core';
import { EventTicketsCheckoutCompletePage } from './event-tickets-checkout-complete.page';
import { TranslocoModule } from '@jsverse/transloco';

const routes = [
  {
    path: '',
    component: EventTicketsCheckoutCompletePage,
  },
];

@NgModule({
  declarations: [EventTicketsCheckoutCompletePage],
  imports: [
    CommonModule, 
    RouterModule.forChild(routes), 
    PipesModule,
    DfCheckoutCompleteComponent,
    TranslocoModule,
  ]
})
export class EventTicketsCheckoutCompletePageModule {}
