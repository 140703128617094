import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import { Pagination } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-shared-lib-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() startLimit = 25;
  @Input() entries;

  @Input() data: Record<string, unknown>[];

  @Output() paginationChanged: EventEmitter<Pagination> = new EventEmitter<Pagination>();

  url: string;

  queryParams: Pagination;

  destroy$: Subject<void> = new Subject<void>();

  //---------------------Mat Table Data Source -----------------------------//

  @Input() set matTableData(matTableData: MatTableDataSource<Record<string, unknown>>) {
    this.matTableDataSource = matTableData;
    matTableData.paginator = this.paginator;
  }

  get matTableData(): MatTableDataSource<Record<string, unknown>> {
    return this.matTableDataSource;
  }

  matTableDataSource: MatTableDataSource<Record<string, unknown>>;

  //---------------------Mat Table Data Source -----------------------------//

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.url = this.router.url;
    this.url = encodeURI(this.url);
    this.getQueryParams();
  }

  getQueryParams(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        this.queryParams = {
          limit: queryParams.limit || this.startLimit,
          page: queryParams.page || 0
        };
        this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
      });
  }

  setPaginatorQueries(): void {
    if (this.matTableDataSource) {
      this.matTableDataSource.paginator = this.paginator;
    }

    this.paginator.page
      .pipe(takeUntil(this.destroy$))
      .subscribe((paginator) => {
        this.queryParams.limit = this.paginator.pageSize;
        this.queryParams.page = this.paginator.pageIndex;
        if (this.matTableDataSource) {
          this.paginator.length = this.matTableDataSource.data.length;
        } else {
          this.paginator.length = this.entries? this.entries : this.data.length;
        }
        this.setQueryParams();
      });
  }

  setQueryParams(): void {
    this.router.navigate([], {
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
    this.paginationChanged.emit(this.queryParams);
  }

  ngAfterViewInit(): void {
    this.setPaginatorQueries();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
