import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageHttpService } from '../services';
import { Subscription } from 'rxjs';
import { AuthStateService, DanceSchoolStateService, MessageStateService, WebsocketService } from '../../../services';
import { MatDialog } from '@angular/material/dialog';
import { PartnerService } from '../services';

import { ConfirmationDialogComponent } from '../../confirmation-dialog';
import { ConfirmationDialogInterface, ConversationInterface, DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER, DEFAULT_GROUP_MESSAGE_PLACEHOLDER, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, MessageInterface, MessagePartnerInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('textInput', { read: ElementRef }) textInput: ElementRef;
  @ViewChild('messageText', { read: ElementRef }) messageText: ElementRef;
  @Input() conversationPartnerIds: string;
  @Input() currentPartnerId: string;
  @Output() messageCreated = new EventEmitter<void>();
  @Output() conversationDeleted = new EventEmitter<void>();

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  DEFAULT_GROUP_MESSAGE_PLACEHOLDER = DEFAULT_GROUP_MESSAGE_PLACEHOLDER;
  
  resetContent = false;

  showEmojis = false;

  message = '';
  username = '';
  settedEmojis = '';
  loading = true;
  conversation: ConversationInterface | null;
  // Extra subscriptions are needed because of the ngOnChanges()
  loadConversationSubscription: Subscription | null = null;
  reloadConversationSubscription: Subscription | null = null;
  errors = [];
  
  subscriptions = new Subscription();
  
  constructor(
    private messageHttpService: MessageHttpService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private authService: AuthStateService, 
    private dialog: MatDialog, 
    private websocketService: WebsocketService, 
    private danceSchoolService: DanceSchoolStateService, 
    private partnerService: PartnerService, 
    private readonly translocoService: TranslocoService,
    private readonly messageService: MessageStateService
  ) {}
  
  readonly updateConversation = (partnerId: string): void => {
    if (this.conversationPartnerIds === partnerId) {
      this.reload();
    }
  };
  ngOnInit(): void {
    this.subscriptions.add(this.authService.getAsyncCurrentUser().subscribe(() => this.setCurrentPartner()));

    this.subscriptions.add(this.danceSchoolService.getAsyncCurrentDanceSchool().subscribe(() => this.setCurrentPartner()));

    const socket = this.websocketService.getSocket();
    socket.on('updateConversation', this.updateConversation);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['conversationPartnerIds']) {
      this.loadConversationSubscription?.unsubscribe();
      this.reloadConversationSubscription?.unsubscribe();
      this.loadInitConversation(this.conversationPartnerIds);
    }
  }
  setMessengerContent(content: string): void {
    this.resetContent = false;
    this.message = content;
  }

  setCurrentPartner(): void {
    this.currentPartnerId = this.partnerService.getCurrentPartnerId(this.authService.getSyncCurrentUser(), this.danceSchoolService.getSyncCurrentDanceSchool());
  }

  toggleEmojis(): void {
    this.showEmojis = !this.showEmojis;
  }
  setScrollTop(): void {
    if (this.messageText && this.messageText.nativeElement) {
      const messageText = this.messageText.nativeElement;
      messageText.scrollTop = messageText.scrollHeight;
    }
  }

  onEnterSendMessage(): void {
    const defaultQuillText = '\n\n';
    if (this.message && this.message.trim() !== '' && this.message !== defaultQuillText) {
      this.sendMessage();
      this.resetContent = true;
      this.message = '';
    }
  }


  onClickSendMessage(): void {
    const defaultQuillText = '\n\n';
    if (this.message !== '') {
      // Workaround - will be hopefully fixed with new quill version
      if (this.message !== defaultQuillText) {
        this.sendMessage();
      }
      this.resetContent = true;
      this.message = '';
    }
  }

  sendMessage(): void {
    this.subscriptions.add(
      this.messageHttpService.createMessage(this.conversationPartnerIds, this.message, this.currentPartnerId).subscribe((conversation: ConversationInterface) => {
        for (const message of conversation.messages) {
          this.conversation.messages.push(message);
        }
        this.conversation.readAt = conversation.readAt;

        setTimeout(() => {
          this.setScrollTop();
          this.messageCreated.emit();
        });
      })
    );
  }

  getLastMessage(): MessageInterface | null {
    if (this.conversation !== null && this.conversation.messages.length > 0) {
      return this.conversation.messages[this.conversation.messages.length - 1];
    } else {
      return null;
    }
  }

  // reload ist zuständig für:
  //   - Eine Nachricht wird gesendet und bei einem anderen Benutzer wird die Nachricht nachgeladen
  //   - Eine Nachricht wird von Benutzer gelesen, der andere Benutzer sieht das "gelesen am ..."
  reload(): void {
    const lastMessageCreated = this.getLastMessage()?.createdAt;

    this.reloadConversationSubscription = this.messageHttpService
      .getConversationByIdWithMessageCreatedGreater(this.conversation.id, lastMessageCreated)
      .subscribe((conversation: ConversationInterface) => {
        if (this.conversation !== null) {
          for (const message of conversation.messages) {
            const messageIndex = this.conversation?.messages.findIndex((it) => it.id === message.id);

            if (messageIndex === -1) {
              this.conversation?.messages.push(message);
            }
          }
          this.conversation.readAt = conversation.readAt;

          setTimeout(() => {
            this.setScrollTop();
          });
        }
      });
  }

  delete(): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.CONVERSATION.MORE_OPTIONS_DELETE_CHAT'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.CONVERSATION.MORE_OPTIONS_DELETE_CHAT'),
      text: this.translocoService.translate('GENERIC_WRAPPER.CONVERSATION.DIALOG_TEXT'),
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.subscriptions.add(
            this.messageHttpService.delete(this.conversationPartnerIds, this.currentPartnerId).subscribe(() => {
              this.router.navigate([], { relativeTo: this.route });
              this.conversationDeleted.emit();
            })
          );
        }
      });
  }

  showConversationReadInformation(): boolean {
    if (!this.conversation || !this.conversation.readAt) {
      return false;
    }

    const lastMessage = this.getLastMessage();
    return lastMessage !== null && lastMessage.fromPartnerId === this.currentPartnerId && new Date(this.conversation.readAt).getTime() > new Date(lastMessage.createdAt).getTime();
  }

  loadInitConversation(partnerId: string): void {
    this.loading = true;

    this.loadConversationSubscription = this.messageHttpService.getConversationByPartnerId(this.currentPartnerId, partnerId)
      .subscribe({
        next: (conversation: ConversationInterface) => {
          this.conversation = conversation;
          this.loading = false;

          setTimeout(() => {
            this.setScrollTop();

            if (this.textInput && this.textInput.nativeElement) {
              this.textInput.nativeElement.focus();
            }
          });

          this.messageService.loadAllMessagesCountOfCurrentUser();
        },
        error: () => {
          this.loading = false;
          this.router.navigateByUrl('/404');
        }
      });
  }

  appendChars(chars: string): void {
    this.message += chars;
    this.setEmojis(chars);
  }
  setEmojis(chars): void {
    this.settedEmojis = chars;
  }

  getConversationPartnerByMessage(message: MessageInterface): MessagePartnerInterface | null {
    return this.conversation?.partners.find((partner) => partner.id === message.fromPartnerId) ?? null;
  }

  ngOnDestroy(): void {
    const socket = this.websocketService.getSocket();
    if (socket) {
      socket.off('updateConversation', this.updateConversation);
    }
    this.loadConversationSubscription?.unsubscribe();
    this.reloadConversationSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  protected readonly DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;
}
