import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFormSettingsComponent } from './name-form-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [NameFormSettingsComponent],
  exports: [NameFormSettingsComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatSlideToggleModule,
      DfSharedButtonModule,
      TranslocoDirective
    ],
})
export class NameFormSettingsModule {}
