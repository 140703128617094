<ng-container *transloco="let t">
  <div class="flex-column" [formGroup]="formGroup">
    <div
      class="flex-column formContainerWidth"
    >
      <div class="flex-column">
        <span class="m0"><strong>{{ t('GENERIC_WRAPPER.NAME') }}</strong></span>
        <mat-form-field
          class="mt2 w-75"
          appearance="outline"
        >
          <mat-label>{{ t('GENERIC_WRAPPER.NAME') }}</mat-label>
          <input 
            matInput 
            type="text" 
            formControlName="name" 
            required 
            #name
            id="general-name-input-field"
            value="{{ danceSchool?.name }}"
          />
          <mat-hint align="end">{{ name.value.length }} / 32</mat-hint>
          <mat-error *ngIf="nameControl.hasError('required')">
            {{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.DANCESCHOOL_ERROR') }}
          </mat-error>
          <mat-error
            *ngIf="nameControl.hasError('maxlength')"
            class="errorTextSpaceBetween"
          >
            <div>
              {{ t('GENERIC_WRAPPER.ERROR_NAME_MAX_LENGTH') }}
            </div>
            <div style="margin-left: auto">
              {{ nameControl.errors?.maxlength.actualLength }} /
              {{ nameControl.errors?.maxlength.requiredLength }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>
  
      <div class="flex-column">
        <span class="m0"><strong>{{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.URL_TITLE') }}</strong></span>
        <mat-form-field
          class="mt2 w-75"
          appearance="outline"
        >
          <mat-label>{{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.URL_TITLE') }}</mat-label>
          <input matInput type="text" formControlName="urlName" required #urlName id="general-url-input-field"
          />
          <mat-error *ngIf="urlNameControl.hasError('required')">
            {{t('GENERIC_WRAPPER.URL_NAME_ERROR_REQUIRED')}}
          </mat-error>
          <mat-error *ngIf="urlNameControl.hasError('invalidCharacters')">
            {{t('GENERIC_WRAPPER.URL_NAME_ERROR_INVALID')}}
          </mat-error>
          <mat-error *ngIf="urlNameControl.hasError('notUnique')">
            {{t('GENERIC_WRAPPER.URL_NAME_ERROR_UNAVAILABLE')}}
          </mat-error>
        </mat-form-field>
      </div>
  
           <!--  Dance Styles  -->
      
        <div class="flex-column">
          <span><strong>{{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.DANCE_STYLES') }}</strong></span>
          <span class="fs14 my1">{{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.DANCE_STYLES_DESCRIPTION') }}</span>
          <df-shared-lib-search-dance-style
            class="w-75"
            ngClass.gt-sm="pad-matformfield"
            [previouslySelectedOptions]="danceStylesControl?.value"
            (selectedOption)="selectDanceStyle($event)"
          >
         </df-shared-lib-search-dance-style>
          
         <div class="request-dance-style mb2">
           <span>{{(t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE')) + " "}}</span>
           <span class="df-link" (click)="openDanceManagerDanceStyleRequestForm()">{{t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE_LINK')}}.</span>
         </div>
          
          <div *ngIf="danceStylesControl?.value?.length > 0" class="w-75">
            <mat-chip-listbox>
              <mat-chip-option
                class="chip-bg"
                [selectable]="false"
                [removable]="true"
                (removed)="removeSelectedDanceStyle(danceStyle)"
                *ngFor="let danceStyle of danceStylesControl.value"
              >
                {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>
      
      
         <!--   Dance Styles   -->
      
      
      <!-- CATEGORY -->
      <div class="flex-column">
        <span class="mb-10">
          <strong>
            {{ t('GENERIC_WRAPPER.CATEGORY') }}
          </strong>
        </span>
        <div class="request-category mb-10">
          <span>{{ (t('DANCE_MANAGER_ADMINISTRATION.GENERAL.SELECT_MANY')) + " " }}</span>
        </div>
        <mat-form-field
          appearance="outline"
          class="w-75"
          ngClass.gt-sm="pad-matformfield"
        >
          <mat-label>
            {{ t('GENERIC_WRAPPER.CATEGORY') }}
          </mat-label>
          <mat-chip-grid #chipList>
            <input
              #danceManagerCategory
              formControlName="danceManagerCategory" 
              [placeholder]="t('GENERIC_WRAPPER.CATEGORY')"
              autocomplete="off"
              (click)="matAutocompleteTrigger.openPanel()"
              [formControl]="searchCategoryInput"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
            >
          </mat-chip-grid>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectCategory($event.option.value)"
            (closed)="checkCategoryClosed()"
            hideSingleSelectionIndicator
          >
            <mat-option    
              *ngFor="let category of danceManagerCategoryEnum | keyvalue"
              [value]="category.value"
              [disabled]="checkOptionSelected(category.value)"
              >{{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category.key) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="request-category mb2">
          <span>{{(t('DANCE_MANAGER_ADMINISTRATION.GENERAL.REQUEST_CATEGORY')) + " "}}</span>
          <span class="df-link" (click)="openDanceManagerCategoryRequestForm()">{{t('DANCE_MANAGER_ADMINISTRATION.GENERAL.REQUEST_CATEGORY_LINK')}}</span>
        </div>
  
        <div *ngIf="selectedCategories.length > 0" class="w-75" >
          <mat-chip-listbox  #chipList aria-label="Fruit selection">
            <mat-chip-option
              *ngFor="let category of selectedCategories"
              aria-label="Fruit selection"
              [removable]="true"
              [selectable]="false"
              (removed)="removeSelectedCategory(category)"
            >
              {{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category) }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <mat-error *ngIf="displayErrorCategorySelection" style="min-width: 225px;">{{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.SEARCH_CATEGORY_ERROR') }}</mat-error>
      </div>
  
        <div class="flex-column">
          <span><strong>{{t('GENERIC_WRAPPER.DESCRIPTION')}}</strong></span>
          <div class="mt2 w-75">
            <df-shared-lib-quill-editor
              [enableMentions]="true"
              [id]="'description'"
              [placeholder]="'GENERIC_WRAPPER.DESCRIPTION'"
              [customFormControlName]="'description'"
              [maxLength]="2000"
            ></df-shared-lib-quill-editor>
          </div>
           <div *ngIf="descriptionControl.hasError('maxlength')" class="w-100 flex-row">
             <div class="max-length-error" id="enableMaxLengthError"> 
                <span>
                   {{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.ERROR_DESCRIPTION_MAX_LENGTH') }}
                </span>
             </div>
           </div>
          
          <mat-error *ngIf="descriptionControl.hasError('required') && descriptionControl.touched">
            {{t('DANCE_MANAGER_ADMINISTRATION.GENERAL.ERROR_DESCRIPTION')}}
          </mat-error>
  
          <span class="mt3">
            <strong>
               {{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.DANCESCHOOL_LOGO_TITLE') }}
            </strong>
          </span>
          <div class="logo-container relative mt2">
            <div
              class="logo-highlighter w-100 h-100 absolute circle flex-row align-center"
              (click)="openDanceSchoolLogoDialogComponent()"
            >
              <mat-icon fontSet="material-icons-outlined">photo_camera</mat-icon>
            </div>
            <img
              [ngClass]="'editable'"
              class="profile-image circle"
              [src]="danceSchool.imageUrl ?? 'assets/images/studio_logo.png'"
              [alt]="t('DANCE_MANAGER_ADMINISTRATION.GENERAL.DANCE_SCHOOL_PICTURE_ALT')"
            />
            <button
              *ngIf="danceSchool.imageUrl"
              mat-icon-button
              class="edit-button delete-button-icon"
              (click)="confirmDeleteIcon()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
  
          <span class="mt3">
            <strong>
              {{ t('DANCE_MANAGER_ADMINISTRATION.GENERAL.DANCESCHOOL_BACKGROUND_TITLE') }}
            </strong>
          </span>
          <div class="course-header relative mt2">
            <div
              [ngClass]="'editable'"
              class="image-highlighter"
              (click)="openTitleDialog()"
            >
              <div class="h-100 flex-row align-center">
                <mat-icon fontSet="material-icons-outlined">photo_camera</mat-icon>
              </div>
            </div>
  
            <button *ngIf="danceSchool.titleImage?.url"
              mat-icon-button
              class="delete-button"
              (click)="confirmDeleteBackground()"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <img
              [src]="
                danceSchool.titleImage
                  ? danceSchool.titleImage.url
                  : 'assets/images/studio_header.png'
              "
              alt=""
              [ngClass]="'editable'"
              class="course-title-image w-100"
              ngClass.gt-xs="title-image-border-radius"
              id="header-image"
            />
          </div>
        </div>
      </div>
      <div class="buttonRowContainer">
        <div class="buttonContainer">
          <df-shared-lib-button
            [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
            [isDisabled]="formGroup.invalid"
            (buttonClicked)="updateDanceManager()"
          >
          </df-shared-lib-button>
        </div>
      </div>
  </div>
</ng-container>
