import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FeesPaidByEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-pass-price-form',
  templateUrl: './pass-price-form.component.html',
  styleUrls: ['./pass-price-form.component.scss']
})
export class PassPriceFormComponent {
  @Input() formGroup: UntypedFormGroup;
  
  feesPaidByEnum = FeesPaidByEnum;
  
  get price(): AbstractControl {
    return this.formGroup.get('price');
  }
  get taxPercentage(): AbstractControl {
    return this.formGroup.get('taxPercentage');
  }
}
