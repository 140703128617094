import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPassInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-transfer-dialog',
  templateUrl: './select-check-in-pass-dialog.component.html',
  styleUrls: ['./select-check-in-pass-dialog.component.scss'],
})
export class SelectCheckInPassDialogComponent implements OnInit {
  userPasses: UserPassInterface[];
  selectedUserPass: UserPassInterface;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userPasses: UserPassInterface[] },
    private readonly dialogRef: MatDialogRef<SelectCheckInPassDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.userPasses = this.data.userPasses;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSendClick(): void {
    this.dialogRef.close({
      result: true,
      selectedUserPass: this.selectedUserPass,
    });
  }
}
