import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NotificationInterface, NotificationOverviewInterface } from '@platri/df-common-core';
import { NotificationHttpService } from '../http-services';
import { getSubscription } from '../../helpers';

@Injectable({providedIn: 'root'})
export class NotificationStateService implements OnDestroy {
  isLoadingNotifications = new BehaviorSubject<boolean>(false);
  availableNotificationsForCurrentUser = new BehaviorSubject<NotificationInterface[]>([]);
  availableNotificationsCountForCurrentUser = new BehaviorSubject<NotificationOverviewInterface>(null);

  subscriptions = new Subscription();

  constructor(private readonly notificationHttpService: NotificationHttpService) {
    console.log('Initializing NotificationStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying NotificationStateService');
    this.subscriptions.unsubscribe();
    this.availableNotificationsForCurrentUser.unsubscribe();
  }

  clear(): void {
    this.availableNotificationsForCurrentUser.next([]);
  }

  loadAllNotificationsForCurrentUser(): void {
    this.sendIsLoadingNotifications(true);
    this.subscriptions.add(getSubscription(this.notificationHttpService.getAllByCurrentUser(), this.onLoadedAllNotificationsForCurrentUser.bind(this), () => {
      console.log(`Couldn't load Notifications`);
      this.sendIsLoadingNotifications(false);
    }));
  }

  onLoadedAllNotificationsForCurrentUser(notifications: NotificationInterface[]): void {
    this.sendIsLoadingNotifications(false);   
    this.sendAvailableNotificationsForCurrentUser(notifications);
    this.getUnreadNotificationCountOfCurrentUser();
  }

  private sendAvailableNotificationsForCurrentUser(notifications: NotificationInterface[]): void {
    this.availableNotificationsForCurrentUser.next(notifications);
  }

  getAsyncAvailableNotificationsForCurrentUser(): Observable<NotificationInterface[]> {
    return this.availableNotificationsForCurrentUser.asObservable();
  }

  getUnreadNotificationCountOfCurrentUser(): void {
    this.subscriptions.add(getSubscription(this.notificationHttpService.getCountOfUnreadNotificationOfCurrentUser(), this.onLoadedNotificationCountForCurrentUser.bind(this), () => {console.log(`Couldn't load Notifications Count`);}));
  }

  onLoadedNotificationCountForCurrentUser(count: NotificationOverviewInterface): void {
    this.sendAvailableNotificationCountForCurrentUser(count);
  }

  private sendAvailableNotificationCountForCurrentUser(count: NotificationOverviewInterface): void {
    this.availableNotificationsCountForCurrentUser.next(count);
  }

  getAsyncAvailableNotificationsCountForCurrentUser(): Observable<NotificationOverviewInterface> {
    return this.availableNotificationsCountForCurrentUser.asObservable();
  }

  private sendIsLoadingNotifications(isLoading: boolean): void {
    this.isLoadingNotifications.next(isLoading);
  }

  getAsyncIsLoadingNotifications(): Observable<boolean> {
    return this.isLoadingNotifications.asObservable();
  }

}
