import { Component } from '@angular/core';

@Component({
  selector: 'df-monorepo-dfm-edit-user-group-page',
  templateUrl: './dfm-edit-user-group-page.component.html',
  styleUrls: ['./dfm-edit-user-group-page.component.scss']
})
export class DfmEditUserGroupPageComponent {
  
}
