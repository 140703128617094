import { FollowInterface, FollowTargetTypeEnum } from '../../..';

export class FollowDto implements FollowInterface{
  createdAt: Date;
  danceClassId?: string;
  danceEventId?: string;
  danceSchoolId?: string;
  deletedAt?: Date;
  followedUserId?: string;
  id: string;
  isFollowing: boolean;
  locationId?: string;
  roomRentalId?: string;
  targetType: FollowTargetTypeEnum;
  updatedAt: Date;
  userId: string;
}
