import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocationInterface, UpdateLocationRequestDto } from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValue } from '@angular/common';
import { Subscription } from 'rxjs';
import { TimeInformationToHoursMinutesPipe } from '../../pipes';
import { DanceSchoolStateService, LocationHttpService, LocationsStateService } from '../../services';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { DanceflavorsDanceManagerDetailUrlNameRouteHelper } from '../../shared';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-locations-card',
  templateUrl: './df-locations-card.component.html',
  styleUrls: ['./df-locations-card.component.scss'],
})
export class DfLocationsCardComponent implements OnInit, OnDestroy {
  @Input() locations: LocationInterface[] = [];
  @Input() isOwnerOrAdmin = false;
  @Input() isLocationsPage = false;

  closesAt: Date[] = [];
  opensAt: Date[] = [];
  opensAtDay: Date[] = [];  
  openStatus: boolean[] = [];
  currentLang: string;
  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',];
  panelOpenState: boolean[] = [];
  cardOpenState: boolean[] = [];
  today: string = this.days[new Date().getDay() - 1];

  private subscription = new Subscription();
  
  ascOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    if (this.days.indexOf(a.key) < this.days.indexOf(b.key)) {
      return -1;
    }
    return this.days.indexOf(b.key) < this.days.indexOf(a.key) ? 1 : 0;
  };
  
  constructor(
    private readonly TimePipe: TimeInformationToHoursMinutesPipe,
    private readonly translocoService: TranslocoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly snackBar: MatSnackBar,
    private readonly locationsStateService: LocationsStateService,
    private readonly locationHttpService: LocationHttpService,
  ) {
    this.currentLang = translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
        this.locations.forEach((location, index) => {
          this.cardOpenState.push(false);
          this.panelOpenState.push(false);
          this.closesAt.push(null);
          this.opensAt.push(null);
          this.opensAtDay.push(null);
          this.openStatus.push(this.checkOpenStatus(location, index));
          
    });
    }

  checkOpenStatus(location: LocationInterface, index: number): boolean {
    let status = false;
    const d = new Date();
    const day = d.getDay() - 1;
    const currentTime = (d.getHours() < 10 ? '0' : '') + d.getHours() + ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    if (location.openingHours && Object.prototype.hasOwnProperty.call(location.openingHours, this.days[day])) {
      const todaysOpeningHours = location.openingHours[this.days[day]];
      if (todaysOpeningHours[0]) {
        const todaysOpenTime = this.TimePipe.transform(todaysOpeningHours[0].timeStart);
        const todaysCloseTime = this.TimePipe.transform(todaysOpeningHours[0].timeEnd);
        if (currentTime >= todaysOpenTime && currentTime <= todaysCloseTime) {
          status = true;
        }
      }
    }
    if (status) {
      this.closesAt[index] = this.createTempDate(
        location.openingHours[this.days[day]][0].timeEnd.hours,
        location.openingHours[this.days[day]][0].timeEnd.minutes
      );
    } else {
      let openTimeFound = false;
      let i = 0;
      if (location.openingHours && location.openingHours[this.days[day]] && location.openingHours[this.days[day]].length > 0 && location.openingHours[this.days[day]][0].timeStart > currentTime) {
        this.opensAt[index] = this.createTempDate(location.openingHours[this.days[day + i]][0].timeStart.hours, location.openingHours[this.days[day + i]][0].timeStart.minutes);
        openTimeFound = true;
      }
      while (!openTimeFound && i < 8) {
        if (location.openingHours && location.openingHours[this.days[day + i]] && location.openingHours[this.days[day + i]].length > 0) {
          this.opensAtDay[index] = this.translocoService.translate('GENERIC_WRAPPER.WEEKDAYS.FULL.' + this.days[day + i].toUpperCase());
          this.opensAt[index] = this.createTempDate(location.openingHours[this.days[day + i]][0].timeStart.hours, location.openingHours[this.days[day + i]][0].timeStart.minutes);
          openTimeFound = true;
        } else {
          i++;
        }
      }
    }
    return status;
  }

  createTempDate(hours: number, minutes: number): Date {
    const temp = new Date();
    temp.setHours(hours);
    temp.setMinutes(minutes);
    temp.setSeconds(0);
    temp.setTime(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
    return temp;
  }

  routeToLocationEdit(id: string): void {
    this.router.navigate([DanceflavorsDanceManagerDetailUrlNameRouteHelper(this.danceSchoolService.getSyncCurrentDanceSchool().id) + '/locations/' + id + '/edit']);
  }

  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToLocationPage(location?: LocationInterface): void {
    if (location) {
      this.router.navigate([location.id, 'edit'], { relativeTo: this.route });
    } else {
      this.router.navigate(['create'], { relativeTo: this.route });
    }
  }
  
  deleteLocation(location: LocationInterface): void {
    this.dialog.open(ConfirmationDialogComponent, {
        data: {
          cancelButtonColor: '',
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
          submitButtonColor: 'warn',
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
          title: this.translocoService.translate('GENERIC_WRAPPER.LOCATION_WRAPPER.DELETION_DIALOG_TITLE'),
          text: this.translocoService.translate('GENERIC_WRAPPER.LOCATION_WRAPPER.DELETION_DIALOG_DESCRIPTION'),
        },
        maxWidth: '300px' 
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.locationsStateService.deleteLocation(location.id, this.danceSchoolService.getSyncCurrentDanceSchool().id).subscribe(
            () => {
              this.snackBar.open( this.translocoService.translate('GENERIC_WRAPPER.LOCATION_WRAPPER.DELETION_SUCCESSFUL'));
            },
            (error) => {
              switch (error.error.message) {
                case 'NO_RIGHTS_TO_DELETE':
                  this.snackBar.open(this.translocoService.translate('GENERIC_WRAPPER.LOCATION_WRAPPER.DELETION_FAILED_MISSING_RIGHTS'));
                  break;
                case 'UPCOMING_EVENTS_OR_COURSES': //THIS ERROR DOES NOT EXIST YET
                  this.snackBar.open(this.translocoService.translate('GENERIC_WRAPPER.LOCATION_WRAPPER.DELETION_FAILED'));
                  break;
                default:
                  this.snackBar.open(this.translocoService.translate('GENERIC_WRAPPER.LOCATION_WRAPPER.DELETION_FAILED_DEFAULT'));
                  break;
              }
            }
          );
        }
      });
  }
  
  updateVisibility(location: LocationInterface, isPublic: boolean): void {
    const {address, name, openingHours, amenities, showOpeningHours, email, phoneNumber, phonePrefix, phonePrefixAfter} = location;
    const updateLocationRequestDto: UpdateLocationRequestDto = {address, name, openingHours, amenities, showOpeningHours, isPublic: isPublic, email, phoneNumber, phonePrefix, phonePrefixAfter};
    this.subscription.add(this.locationHttpService.updateLocation(location.id, this.danceSchoolService.getSyncCurrentDanceSchool().id, updateLocationRequestDto).subscribe({
      next: () => {
        this.locationsStateService.loadAllLocationsByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id);
      },
      error: (err) => {
        console.log(err);
      }
    }));
  }
}
