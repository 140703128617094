import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmEditRoleDialogComponent } from './dfm-edit-role-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmEditRoleDialogComponent],
  exports: [DfmEditRoleDialogComponent],
  imports: [
    CommonModule, 
    MatDialogModule, 
    MatFormFieldModule, 
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    TranslocoDirective
  ]
})
export class DfmEditRoleDialogModule {}
