<ng-container *transloco="let t">
  <div *ngIf="danceManager && (completed | async) === false"
       class="dm-following-container gap-12">
    
    <div class="flex-row-center relative">
      <img class="profile-image circle" [src]="danceManager.imageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER" [alt]="t('GENERIC_WRAPPER.DANCE_SCHOOL_PICTURE_ALT')" imageFallback fallbackSrc="DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER"/>
    </div>
    
    <div class="dance-manager-label">
      <span class="dance-manager-name">{{ danceManager.name }}</span>
        <div
          *ngIf="userFollowStateService.getAsyncAvailableFollowCount() | async as followStats"
        >
          <div class="follow-content" (click)="openFollowInfoDialog(1)">
            <span class="follow-count">
              {{ followStats.followerCount }}
            </span>
            <span class="follow-text">
              {{ t('GENERIC_WRAPPER.FOLLOWERS') }}
            </span>
          </div>
        </div>
    </div>
    
    <ng-container *ngIf="userFollowStateService.getAsyncAvailableFollowCount() | async as followStats">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [borderRadius]="'25px'"
        [minWidth]="'92px'"
        [height]="'40px'"
        [buttonLabel]="t('GENERIC_WRAPPER.' + (followStats.isCurrentUserFollowing ? 'FOLLOWING' : 'FOLLOW'))"
        (buttonClicked)="followTarget()"/>
    </ng-container>
  </div>
</ng-container>
