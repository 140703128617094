import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursePassesPage } from './course-passes.page';
import { MatIconModule } from '@angular/material/icon';
import { ContactUrlDirectiveModule, DfSharedButtonModule, PassCheckoutEmptyListModule, PassCheckoutListItemModule, PassCheckoutListSummaryModule, PassSubscriptionCheckoutListItemModule, PaymentMethodsViewModule, PipesModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [CoursePassesPage],
  imports: [
    CommonModule,
    MatIconModule,
    PipesModule,
    MatDividerModule,
    ReactiveFormsModule,
    PassCheckoutListItemModule,
    PassCheckoutEmptyListModule,
    PaymentMethodsViewModule,
    TranslocoModule,
    DfSharedButtonModule,
    ContactUrlDirectiveModule,
    PassCheckoutListSummaryModule,
    MatFormField,
    MatError,
    MatInput,
    MatLabel,
    FormsModule,
    MatTabGroup,
    MatTab,
    PassSubscriptionCheckoutListItemModule
  ]
})
export class CoursePassesPageModule {
  
  constructor() {
    console.log('Init CoursePassesPageModule');
  }
}
