import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialButtonStyleType } from '../buttons';

@Component({
  selector: 'df-shared-empty-page',
  templateUrl: './shared-empty-page.component.html',
  styleUrls: ['./shared-empty-page.component.scss'],
})
export class SharedEmptyPageComponent {
  @Input() buttonLabel = 'Submit';
  @Input() materialButtonStyleType: MaterialButtonStyleType = 'flat';
  @Input() imagePath = 'assets/images/no_search.svg';
  @Input() emptyTitle = '';
  @Input() emptyDescription = 'Empty description';
  @Input() hideBtn = false;
  @Input() buttonId = '' ;
  
  @Output() buttonClicked = new EventEmitter<void>();
}
