import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'df-empty-courses-list',
  templateUrl: './empty-courses-list.component.html',
  styleUrls: ['./empty-courses-list.component.scss'],
})
export class EmptyCoursesListComponent {
  constructor(private route: ActivatedRoute, private router: Router) {}

  navigateToCreatePage(): void {
    this.router.navigate(['create'], { relativeTo: this.route });
  }
}
