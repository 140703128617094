
export interface ParticipantRestrictionInterface {
  isAgeRestricted?: boolean;
  isGenderRestricted?: boolean;
  ageRestriction?: AgeRestrictionInterface;
  genderRestriction?: GenderRestrictionInterface;
}

export interface AgeRestrictionInterface {
  fromAge?: number;
  toAge?: number;
}

export interface GenderRestrictionInterface {
  isMaleRestricted?: boolean;
  isFemaleRestricted?: boolean;
  isDiverseRestricted?: boolean;
}
