import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthHttpService, UserHttpService } from '../http-services';
import jwt_decode from 'jwt-decode';
import { catchError, delay, map, retryWhen, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as _ from 'lodash';
import { CheckUserExistsResultInterface, LoginTokenResponseInterface, LoginUserInterface, UsersInterface, UserStatusEnum } from '@platri/df-common-core';
import { dfAppRoute, dfLoginRoute, dfRegisterRoute } from '../../shared';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly CURRENT_USER = 'currentUser';

  private accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(localStorage.getItem(this.ACCESS_TOKEN));
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(localStorage.getItem(this.REFRESH_TOKEN));
  // @ts-ignore
  private currentUserSubject: BehaviorSubject<UsersInterface> = new BehaviorSubject<UsersInterface>(JSON.parse(localStorage.getItem(this.CURRENT_USER)));

  private currentLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private checkUserExistsResult: CheckUserExistsResultInterface;

  private loginUser: LoginUserInterface = {
    password: 'placeholder'
  };

  constructor(
    private readonly authHttpService: AuthHttpService, 
    private readonly userHttpService: UserHttpService, 
    private readonly router: Router, 
    private readonly activatedRoute: ActivatedRoute
  ) {}

  public getAsyncCurrentUser(): Observable<UsersInterface> {
    return this.currentUserSubject.asObservable();
  }

  public getSyncCurrentUser(): UsersInterface {
    const accessToken = this.getSyncAccessToken();
    const currentUser = this.currentUserSubject.getValue();
    if (currentUser && accessToken) {
      const userFromToken: UsersInterface = jwt_decode(this.getSyncAccessToken());
      currentUser.roles = userFromToken.roles;
    }
    return currentUser;
  }

  public sendAccessToken(accessToken: string | null): void {
    if (accessToken !== null) {
      localStorage.setItem(this.ACCESS_TOKEN, accessToken);
    } else {
      localStorage.removeItem(this.ACCESS_TOKEN);
    }

    this.accessTokenSubject.next(accessToken);
  }

  public getSyncRefreshToken(): string | null {
    return this.refreshTokenSubject.getValue();
  }

  public getAsyncRefreshToken(): Observable<string | null> {
    return this.refreshTokenSubject.asObservable();
  }

  public sendRefreshToken(refreshToken: string | null): void {
    if (refreshToken !== null) {
      localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    } else {
      localStorage.removeItem(this.REFRESH_TOKEN);
    }

    this.refreshTokenSubject.next(refreshToken);
  }

  public sendCurrentUserChanges(user: UsersInterface | null): void {
    if (user) {
      const userData = {
        ...this.getSyncCurrentUser(),
        ...user
      };
      if (!_.isEqual(userData, this.getSyncCurrentUser())) {
        this.currentUserSubject.next(userData);
        this.setCurrentUserToLocalStorage(user);
      }
    } else {
      this.currentUserSubject.next(null);
      this.removeCurrentUserFromLocalStorage();
    }
  }

  public getSyncAccessToken(): string | null {
    return this.accessTokenSubject.getValue();
  }

  public getAsyncAccessToken(): Observable<string | null> {
    return this.accessTokenSubject.asObservable();
  }

  public getAsyncCurrentLoading(): Observable<boolean> {
    return this.currentLoadingSubject.asObservable();
  }

  public sendCurrentLoadingChanges(loading: boolean): void {
    this.currentLoadingSubject.next(loading);
  }

  // BUSINESS LOGIC

  public signIn(loginUser: LoginUserInterface, returnUrl?: string): Observable<void> {
    this.currentLoadingSubject.next(true);
    // todo!
    // @ts-ignore
    return this.authHttpService.signIn(loginUser).pipe(
      map((res: LoginTokenResponseInterface) => {
        this.currentLoadingSubject.next(false);
        return this.decodeJwtAndStoreUser(res.accessToken, res.refreshToken);
      }),
      map((user: UsersInterface) => {
        if (user) {
          returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
          this.routeAfterLogin(user, returnUrl);
        }
      }),
      catchError((error) => {
        console.log('error');
        this.currentLoadingSubject.next(false);
        throw error;
      })
    );
  }

  public signInGoogle(jwt: string, returnUrl?: string): Observable<void> {
    this.currentLoadingSubject.next(true);
    return this.authHttpService.signInGoogle(jwt).pipe(
      map((res: LoginTokenResponseInterface) => {
        this.currentLoadingSubject.next(false);
        return this.decodeJwtAndStoreUser(res.accessToken, res.refreshToken);
      }),
      map((user: UsersInterface) => {
        if (user) {
          this.routeAfterLogin(user, returnUrl);
        }
      }),
      retryWhen((errors) =>
        errors.pipe(
          tap(() => this.userHttpService.signUpWithGoogle(jwt).subscribe()),
          delay(2000),
          take(2)
        )
      ),
      catchError((error) => {
        this.currentLoadingSubject.next(false);
        throw error;
      })
    );
  }

  public signInFacebook(token: LoginTokenResponseInterface, returnUrl?: string): Observable<void> {
    this.currentLoadingSubject.next(true);
    return this.authHttpService.signInFacebook(token).pipe(
      map((res: LoginTokenResponseInterface) => {
        this.currentLoadingSubject.next(false);
        return this.decodeJwtAndStoreUser(res.accessToken, res.refreshToken);
      }),
      map((user: UsersInterface) => {
        if (user) {
          this.routeAfterLogin(user, returnUrl);
        }
      }),
      retryWhen((errors) =>
        errors.pipe(
          tap(() => this.userHttpService.signUpWithFacebook(token).subscribe()),
          delay(2000),
          take(2)
        )
      ),
      catchError((error) => {
        this.currentLoadingSubject.next(false);
        throw error;
      })
    );
  }
//todo: DTO Entfernt
  public introspectToken(accessToken: string): Observable<any> {
    return this.authHttpService.introspectToken(accessToken);
  }

  public refreshTokens(): Observable<UsersInterface> {
    this.currentLoadingSubject.next(true);
    return this.authHttpService.refreshTokens(this.getSyncRefreshToken()).pipe(
      map((res: LoginTokenResponseInterface) => {
        this.currentLoadingSubject.next(false);
        return this.decodeJwtAndStoreUser(res.accessToken, res.refreshToken);
      }),
      catchError((error) => {
        this.currentLoadingSubject.next(false);
        throw error;
      })
    );
  }

  createMagicLink(usernameOrEmail: { username?: string; email?: string }): Observable<void> {
    const emailOrUsername = usernameOrEmail.email ?? usernameOrEmail.username;
    return this.authHttpService.createMagicLink(emailOrUsername);
  }

  public loginWithMagicLink(token: string, returnUrl?: string): Observable<void> {
    return this.authHttpService.loginWithMagicLink(token).pipe(
      map((res) => {
        this.decodeJwtAndStoreUser(res.accessToken, res.refreshToken);
        this.currentLoadingSubject.next(false);
        return this.getSyncCurrentUser();
      }),
      map((user: UsersInterface) => {
        this.routeAfterLogin(user, returnUrl);
      }),
      catchError((error) => {
        this.currentLoadingSubject.next(false);
        throw error;
      })
    );
  }

  private decodeJwtAndStoreUser(accessToken: string, refreshToken: string): UsersInterface {
    const userFromToken: UsersInterface = jwt_decode(accessToken);
    this.storeTokens(accessToken, refreshToken);
    this.sendCurrentUserChanges(userFromToken);
    this.userHttpService.getCurrentUser().subscribe((user) => {
      user.roles = userFromToken.roles;
      this.sendCurrentUserChanges({
        ...this.getSyncCurrentUser(),
        ...user
      });
      if (user.status === UserStatusEnum.FIRST_TIME || user.status === UserStatusEnum.GUEST) {
        this.sendUserStatusToStandardUpdate().subscribe();
      }
    });
    return userFromToken;
  }

  public logout(): void {
    this.authHttpService.logoutInHttp(this.getSyncRefreshToken()).subscribe({
      next: () => {
        console.log('Logout successful.');
        this.logoutInWebapp();
      },
      error: (err) => {
        console.log('Logout successful, but error in backend.');
        this.logoutInWebapp();
      }
    });
  }

  sendUserStatusToStandardUpdate(): Observable<UsersInterface> {
    return this.userHttpService.updateUser({
      status: UserStatusEnum.STANDARD
    });
  }

  public logoutInWebapp(): void {
    this.sendAccessToken(null);
    this.sendRefreshToken(null);
    this.sendCurrentUserChanges(null);
  }

  private storeTokens(accessToken: string, refreshToken: string): void {
    this.sendAccessToken(accessToken);
    this.sendRefreshToken(refreshToken);
  }

  private setCurrentUserToLocalStorage(user: UsersInterface): void {
    localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
  }

  private removeCurrentUserFromLocalStorage(): void {
    localStorage.removeItem(this.CURRENT_USER);
  }

  // DIALOG LOGIC

  routeToLogin(returnUrl?: string): void {
    const queryParams: Params = new HttpParams();
    if (returnUrl) {
      queryParams.returnUrl = returnUrl;
    }
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams});
  }
  
  routeToRegister(): void {
    this.router.navigate(['/' + dfAppRoute, dfRegisterRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }
  
  public setloginUser(target: LoginUserInterface): void {
    if (target.email) {
      this.loginUser.email = target.email;
      delete this.loginUser.username;
    } else {
      this.loginUser.username = target.username;
      delete this.loginUser.email;
    }
    this.loginUser.password = target.password;
  }

  public resetloginUser(): void {
    delete this.loginUser.username;
    delete this.loginUser.email;
    this.loginUser = { password: 'placeholder' };
  }

  public getloginUser(): LoginUserInterface {
    return this.loginUser;
  }

  public setAuthMethod(result: CheckUserExistsResultInterface): void {
    this.checkUserExistsResult = result;
  }

  public getAuthMethod(): CheckUserExistsResultInterface {
    return this.checkUserExistsResult;
  }

  // ROUTING LOGIC

  routeAfterLogin(user: UsersInterface, url?: string): void {
    if ((this.router.url === '/' || this.router.url === '/' + dfRegisterRoute) && user.status === UserStatusEnum.FIRST_TIME) {
      this.router.navigate(['@' + user.username]);
    } else {
      if (url) {
        this.router.navigateByUrl(url);
      }
    }
  }
}
