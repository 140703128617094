import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb, LocationDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DanceSchoolLocationHttpService {
  private SERVICE_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-schools`;

  constructor(private readonly http: HttpClient) {}

  getLocationsOfDanceSchool(danceSchoolId: string): Observable<LocationDto[]> {
    return this.http.get<LocationDto[]>(
      `${this.SERVICE_URL}/${danceSchoolId}/locations`
    );
  }

  createDanceSchoolLocation(
    danceSchoolId: string,
    locationOrFormData: LocationDto | FormData
  ): Observable<LocationDto> {
    return this.http.post<LocationDto>(
      `${this.SERVICE_URL}/${danceSchoolId}/locations`,
      locationOrFormData
    );
  }

  updateDanceSchoolLocation(
    danceSchoolId: string,
    danceSchoolLocationId: string,
    locationOrFormData: LocationDto | FormData
  ): Observable<LocationDto> {
    return this.http.patch<LocationDto>(
      `${this.SERVICE_URL}/${danceSchoolId}/locations/${danceSchoolLocationId}`,
      locationOrFormData
    );
  }

  deleteDanceSchoolLocation(
    danceSchoolId: string,
    danceSchoolLocationId: string
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.SERVICE_URL}/${danceSchoolId}/locations/${danceSchoolLocationId}`
    );
  }

  getLocationById(id: string, danceSchoolId: string): Observable<LocationDto> {
    return this.http.get<LocationDto>(
      `${this.SERVICE_URL}/${danceSchoolId}/locations/${id}`
    );
  }

  updateVisibilityById(
    id: string,
    isPublic: boolean,
    danceSchoolId
  ): Observable<LocationDto> {
    return this.http.put<LocationDto>(
      `${this.SERVICE_URL}/${danceSchoolId}/locations/${id}/visibility`,
      { isPublic }
    );
  }
}
