import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-shared-lib-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent {
  //todo: APPOINTMENTINTERFACE
  @Input() appointment: any;
}
