import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError, debounceTime, delay, tap } from 'rxjs/operators';
import { AuthStateService, MessageHttpService, noWhitespaceValidator, UserHttpService } from '@platri/dfx-angular-core';
import { MessagePartnerInterface, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-username-form-settings',
  templateUrl: './username-form-settings.component.html',
  styleUrls: ['./username-form-settings.component.scss']
})
export class UsernameFormSettingsComponent implements OnInit {
  @Input() currentUser: UsersInterface;
  @Output() userNameSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  isSubmitting = true;
  formGroup: UntypedFormGroup;
  showCheckIcon = false;
  regExUserName = /^[a-zA-Z0-9]{2,}(([_]?[a-zA-Z0-9]?)*[a-zA-Z0-9]+)$/g;

  loadingCheckUsername: boolean;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly userHttpService: UserHttpService, private readonly messageHttpService: MessageHttpService, private authService: AuthStateService) {}

  ngOnInit(): void {
    this.initForm();
    this.checkAvailabilityOfUserName();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      username: [this.currentUser.username, [Validators.required, noWhitespaceValidator(), Validators.maxLength(30)]]
    });
  }

  saveUserNameData(): void {
    if (this.formGroup.valid) {
      const user = { username: this.formGroup.value.username };
      this.userNameSaved.emit(user);
      this.isSubmitting = true;
      this.formGroup.markAsPristine();

      this.authService.sendCurrentUserChanges(user);
      this.authService.refreshTokens().subscribe(() => {
        // email in tokens need to be updated
      });


      this.closeEditMode.emit();
    }
  }

  get usernameControl(): AbstractControl {
    return this.formGroup.get('username');
  }

  checkAvailabilityOfUserName(): void {
    this.usernameControl.valueChanges
      .pipe(
        tap(() => (this.showCheckIcon = false)),
        debounceTime(300)
      )
      .subscribe((inputText: string) => {
        if (!!this.usernameControl.value.match(this.regExUserName) && this.formGroup.valid) {
          this.checkUsernameAvailability(inputText).subscribe({
            next: () => {
              this.usernameControl.setErrors({
                unavailable: true
              });
              this.showCheckIcon = false;
            },
            error: () => {
              this.usernameControl.setErrors(null);
              this.showCheckIcon = true;
            }
          });
        } else {
          if (this.formGroup.valid) {
            this.usernameControl.setErrors({ invalid: true });
          }
        }
      });
  }

  checkUsernameAvailability(username: string): Observable<MessagePartnerInterface> {
    this.loadingCheckUsername = true;
    return this.messageHttpService.searchPartner(username).pipe(
      delay(500),
      tap(() => {
        this.loadingCheckUsername = false;
      }),
      catchError((error) => {
        this.loadingCheckUsername = false;
        throw error;
      })
    );
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
