import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { SliderGradientComponent } from './slider-gradient.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [SliderGradientComponent],
    imports: [CommonModule, MatSliderModule, FormsModule],
  exports: [SliderGradientComponent],
})
export class SliderGradientModule {}
