import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatRadioModule} from "@angular/material/radio";
import {ReactiveFormsModule} from "@angular/forms";
import { MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {GalleriaModule} from "primeng/galleria";
import { ImageGalleryComponent } from './image-gallery.component';

@NgModule({
  declarations: [ImageGalleryComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    GalleriaModule,
  ],
  exports: [ImageGalleryComponent],
})
export class ImageGalleryModule {
}
