<ng-container *transloco="let t">
  <mat-tab-group
    style="height: 100%; min-height: 100%"
    [selectedIndex]="tabIndex"
    id="follow-info-dialog"
  >
    <ng-container *ngIf="!hideFollowers">
    <mat-tab
      bodyClass="follow-info-tab-content"
      labelClass="follow-info-label-content"
      label="{{ t('GENERIC_WRAPPER.FOLLOWERS') }} ({{
        (userFollowStateService?.getAsyncAvailableFollowCount() | async)?.followerCount
      }})"
    >
      <div class="content w-100 h-100 pb1 follow-container-respon">
      
      <div *ngIf="(userFollowStateService.getAsyncAvailableFollowers() | async)?.length > 0; else noFollower" class="content w-100 pb1 follow-container-respon">
        <df-follow-info-card
          *ngFor="let follower of userFollowStateService.getAsyncAvailableFollowers() | async"
          [followUser]="follower"
          class="w-100-lt-sm"
          style="display: flex;"
        ></df-follow-info-card>
      </div>
      
      <ng-template #noFollower>
        <div class="w-100 h-100 align-center">
          <df-shared-empty-page
            [emptyTitle]="t('GENERIC_WRAPPER.NO_FOLLOWER.TITLE')"
            [emptyDescription]="t('GENERIC_WRAPPER.NO_FOLLOWER.DESCRIPTION')"
            [imagePath]="'assets/images/no_search.svg'"
            [hideBtn]="true"
          ></df-shared-empty-page>
        </div>
      </ng-template>
      
  </div>
    </mat-tab>
    </ng-container>
    <ng-container *ngIf="!hideFollowing">
    <mat-tab
      *ngIf="!hideFollowing"
      bodyClass="follow-info-tab-content"
      labelClass="follow-info-label-content"
      label="{{ t('GENERIC_WRAPPER.FOLLOWING') }} ({{
        (userFollowStateService?.getAsyncAvailableFollowCount() | async)?.followCount
      }})"
      ><div
        class="content w-100 pb1 follow-container-respon"
      >
        <df-follow-info-card
          *ngFor="let follow of userFollowStateService.getAsyncAvailableFollows() | async"
          [followUser]="follow"
          class="w-100-lt-sm"
          style="display: flex;"
        ></df-follow-info-card>
      </div>
    </mat-tab>
      </ng-container>
  </mat-tab-group>
</ng-container>
