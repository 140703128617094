import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CreateTicketOrderRequestDto, TicketOrderInterface, TicketOrderStatusEnum } from '@platri/df-common-core';
import { TicketOrderHttpService } from '../http-services';

@Injectable({
  providedIn: 'root'
})
export class TicketOrderService {
  private ticketOrderSubject: BehaviorSubject<TicketOrderInterface | null> = new BehaviorSubject<TicketOrderInterface | null>(null);
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private tempCreateTicketOrderSubject: BehaviorSubject<CreateTicketOrderRequestDto | null> = new BehaviorSubject<CreateTicketOrderRequestDto | null>(null);
  private tempCreateTicketOrderTargetId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private tempCreateTicketOrderDanceManagerUrlName: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private readonly ticketOrderHttpService: TicketOrderHttpService, private readonly router: Router) {}

  getAsyncTicketOrder(): Observable<TicketOrderInterface | null> {
    return this.ticketOrderSubject.asObservable();
  }

  getSyncTicketOrder(): TicketOrderInterface | null {
    return this.ticketOrderSubject.value;
  }

  sendTicketOrderChanges(ticketOrder: TicketOrderInterface | null): void {
    this.ticketOrderSubject.next(ticketOrder);
  }

  getAsyncLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  sendLoadingChanges(loading: boolean): void {
    this.loadingSubject.next(loading);
  }

  getSyncTempCreateTicketOrderData(): CreateTicketOrderRequestDto | null {
    return this.tempCreateTicketOrderSubject.value;
  }

  getSyncTempCreateTicketTargetId(): string | null {
    return this.tempCreateTicketOrderTargetId.value;
  }

  sendTempCreateTicketOrderData(data: CreateTicketOrderRequestDto, targetId?: string, danceManagerUrlName?: string): void {
    this.tempCreateTicketOrderSubject.next(data);
    if (targetId) {
      this.tempCreateTicketOrderTargetId.next(targetId);
    } else {
      this.tempCreateTicketOrderDanceManagerUrlName.next(danceManagerUrlName);
    }
  }

  // Business Logic

  createTicketOrderForTickets(data: CreateTicketOrderRequestDto, danceEventId: string): void {
    this.sendLoadingChanges(true);
    this.ticketOrderHttpService.create(data).subscribe({
      next: (result) => {
        this.sendTicketOrderChanges(result);
        this.sendLoadingChanges(false);
        if (danceEventId) {
          if (result.totalPrice === 0 && result.status === TicketOrderStatusEnum.PAID) {
            this.router.navigate(['dance-event', danceEventId, 'tickets', 'checkout-complete', result.id]);
          } else {
            this.router.navigate(['dance-event', danceEventId, 'tickets', 'checkout']);
          }
        } 
      },
      error: (err) => {
        console.error(err);
        this.sendLoadingChanges(false);
        throw err;
      }
    });
  }

  createTicketOrder(data: CreateTicketOrderRequestDto, courseId?: string, danceManagerUrlName?: string): void {
    this.sendLoadingChanges(true);
    this.sendTempCreateTicketOrderData(data, null, danceManagerUrlName);
    this.ticketOrderHttpService.create(data).subscribe({
      next: (result) => {
        this.sendTicketOrderChanges(result);
        this.sendLoadingChanges(false);
        if (courseId) {
          if (result.totalPrice === 0 && result.status === TicketOrderStatusEnum.PAID) {
            this.router.navigate(['dance-class', courseId, 'passes', 'checkout-complete', result.id]);
          } else {
            this.router.navigate(['dance-class', courseId, 'passes', 'checkout']);
          }
        } else {
          if (result.totalPrice === 0 && result.status === TicketOrderStatusEnum.PAID) {
            this.router.navigate(['@' + danceManagerUrlName, 'passes', 'checkout-complete', result.id]);
          } else {
            this.router.navigate(['@' + danceManagerUrlName, 'passes', 'checkout']);
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.sendLoadingChanges(false);
        throw err;
      }
    });
  }

  updateTicketOrderStripePaymentIntent(ticketOrderId: string): void {
    this.sendLoadingChanges(true);
    this.ticketOrderHttpService.updateStripePaymentIntentById(ticketOrderId).subscribe({
      next: (result) => {
        this.sendTicketOrderChanges(result);
        this.sendLoadingChanges(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoadingChanges(false);
        throw err;
      }
    });
  }

  updateTicketOrderPaypalOrder(ticketOrderId: string): void {
    this.sendLoadingChanges(true);
    this.ticketOrderHttpService.updatePaypalOrderById(ticketOrderId).subscribe({
      next: (result) => {
        this.sendTicketOrderChanges(result);
        this.sendLoadingChanges(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoadingChanges(false);
        throw err;
      }
    });
  }

  updateTicketOrderPaypalOrderCapture(ticketOrderId: string, returnUrl: string): void {
    this.sendLoadingChanges(true);
    this.ticketOrderHttpService.updatePaypalOrderCaptureById(ticketOrderId).subscribe({
      next: (result) => {
        this.sendTicketOrderChanges(result);
        this.sendLoadingChanges(false);
        window.location.href = returnUrl;
      },
      error: (err) => {
        console.error(err);
        this.sendLoadingChanges(false);
        throw err;
      }
    });
  }

  getTicketOrderById(id: string): void {
    this.sendLoadingChanges(true);
    this.ticketOrderHttpService.getById(id).subscribe({
      next: (result) => {
        this.sendTicketOrderChanges(result);
        this.sendLoadingChanges(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoadingChanges(false);
        throw err;
      }
    });
  }

  deleteCurrentTicketOrder(): void {
    if (this.ticketOrderSubject.value !== null) {
      this.ticketOrderHttpService.delete(this.ticketOrderSubject.value.id!).subscribe({
        next: () => {},
        error: (err) => {
          console.error(err);
          throw err;
        }
      });
    }
  }

  clear(): void {
    this.sendLoadingChanges(false);
    this.sendTicketOrderChanges(null);
    this.sendTempCreateTicketOrderData(null, null);
  }

  navigateToCheckout(danceEventId: string): void {
    this.router.navigate(['dance-event', danceEventId, 'tickets', 'checkout']);
  }
}
