export interface BillingOrShippingAddressInterface {
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  state?: string;
  isDefault?: boolean;
  addressLine1?: string;
}
