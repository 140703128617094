import { DateTime } from 'luxon';
import { AppointmentsInterface } from '../interfaces';

export function isAppointmentActive(
  appointment: AppointmentsInterface
): boolean {
  // @ts-ignore
  const jsDate = new Date(appointment.startDate);
  jsDate.setTime(jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000);
  // @ts-ignore
  if (+appointment.duration === -1) {
    let dateTimeStartDate = DateTime.now()
      .setZone('UTC')
      .set({
        day: jsDate.getDate(),
        month: jsDate.getMonth() + 1,
        year: jsDate.getFullYear(),
        hour: jsDate.getHours(),
        minute: jsDate.getMinutes(),
        second: 0,
        millisecond: 0,
      });
    dateTimeStartDate = dateTimeStartDate.plus({
      hour: 10,
      millisecond: jsDate.getTimezoneOffset() * 60 * 1000
    });
    return DateTime.now().setZone('UTC') <= dateTimeStartDate;
  } else {
    let dateTimeStartDate = DateTime.now()
      .setZone('UTC')
      .set({
        day: jsDate.getDate(),
        month: jsDate.getMonth() + 1,
        year: jsDate.getFullYear(),
        hour: jsDate.getHours(),
        minute: jsDate.getMinutes(),
        second: 0,
        millisecond: 0,
      });
    dateTimeStartDate = dateTimeStartDate.plus({
      // @ts-ignore
      minute: appointment.duration,
      millisecond: jsDate.getTimezoneOffset() * 60 * 1000
    });
    return DateTime.now().setZone('UTC') <= dateTimeStartDate;
  }
}
