import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule, PipesModule, TranslocoStateConst } from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { PriceUpdateSubscriptionPage } from './price-update-subscription.page';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatMenu, MatMenuItem } from '@angular/material/menu';

const routes = [
  {
    path: ':subscriptionId',
    component: PriceUpdateSubscriptionPage,
  },
];

@NgModule({
  declarations: [PriceUpdateSubscriptionPage],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    DfSharedButtonModule,
    MatChipsModule,
    TranslocoDirective,
    TranslocoModule,
    MatGridList,
    MatGridTile,
    MatMenu,
    MatMenuItem,
    PipesModule,
  ]
})
export class PriceUpdateSubscriptionModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-user', alias: 'USER' });
  }
}
