import { Component, Input, OnInit } from '@angular/core';
import {
  CourseInterface,
  DanceSchoolInterface,
  DEFAULT_EVENT_PICTURE_PLACEHOLDER,
  DelayDialogInterface,
  DmPassSubscriptionIntervalEnum,
  UserPassInterface, UserPassScanStatusEnum,
  UserPassSubscriptionStatusEnum,
  UsersInterface
} from '@platri/df-common-core';
import {
  AuthStateService,
  DelayDialogComponent,
  UserPassSubscriptionHttpService
} from '@platri/dfx-angular-core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
  selector: 'df-user-inventory-list-subscription-item',
  templateUrl: './inventory-subscription-list-item.component.html',
  styleUrls: ['./inventory-subscription-list-item.component.scss']
})
export class InventorySubscriptionListItemComponent implements OnInit{
  // @Input() userTicketCollapsed: UserTicketCollapsedByEventAndOrderInterface;
  @Input() danceManager: DanceSchoolInterface;
  @Input() courses: CourseInterface[];
  @Input() userPass: UserPassInterface;

  // @Output() download = new EventEmitter<{
  //   danceEventId: string;
  //   ticketOrderId?: string;
  //   freeUserTicketIds?: string[];
  // }>();

  currentUser: UsersInterface;
  shownStamps: number[] = [];

  userPassSubscriptionStatusEnum = UserPassSubscriptionStatusEnum;
  subscriptionIntervalEnum = DmPassSubscriptionIntervalEnum;

  dateNextMonday = DateTime.now().setZone('UTC').startOf('week').plus({week: 1}).toJSDate();

  constructor(
    private authService: AuthStateService, 
    private matDialog: MatDialog,
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private router: Router
  ) {
    
  }
  
  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.createStampsShownArray();
    console.log(this.userPass.id);
  }
  
  navigateToDetailPage(): void {
    console.log(this.userPass.id);
    this.router.navigate(['/inventory', 'subscriptions', this.userPass.id]);
  }

  openStripeBillingPortalSession(): void {
    this.userPassSubscriptionHttpService.getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser().subscribe({
      next: (url) => {
        if (url !== null) {
          const delayDialogData: DelayDialogInterface = {
            delayTime: 3000,
            title: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.STARTING_SOON',
            text: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.HAS_SUBSCRIPTION'
          };
          const dialogRef = this.matDialog.open(DelayDialogComponent, {
            maxWidth: '400px',
            data: delayDialogData
          });
          dialogRef.afterClosed().subscribe(confirm => {
            if (confirm) {
              window.open(url, '_blank');
            }
          });
        } else {
          // TODO: Show dialog that the user has no subscriptions yet
        }
      }
    });
  }

  createStampsShownArray(): void {
    const usageCount: number = this.userPass.userPassSubscription.weeklyUsage;
    const usedCount: number = this.userPass.userPassScans.length;

    const displayCount = usageCount > 10 ? 10 : usageCount;
    let start = 0;
    if (usedCount > 5) {
      start = usedCount - (usedCount % 5);
    }
    if (start + 10 > usageCount) {
      start = usageCount - 10;
    }
    start = Math.max(start, 0);
    for (let i = 0; i < displayCount; i++) {
      this.shownStamps[i] = start + 1 + i;
    }
  }

  get showNextPaymentInfo(): boolean {
    const subscription = this.userPass.userPassSubscription;
    return (
      subscription.status === this.userPassSubscriptionStatusEnum.PAID ||
      subscription.status === this.userPassSubscriptionStatusEnum.PAYMENT_PENDING ||
      (
        subscription.status === this.userPassSubscriptionStatusEnum.TERMINATED_PERIOD_END &&
        new Date(subscription.currentPeriodEndAt).toISOString().split("T")[0] !== new Date(subscription.endedAt).toISOString().split("T")[0]
      )
    );
  }

  protected readonly DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;

  getNextPaymentPrice(): number {
    const userPassSubscription = this.userPass.userPassSubscription;
    const isSameDay = userPassSubscription.currentPeriodEndAt.toString().split('T')[0] ===
      userPassSubscription.priceChangeDate?.toString().split('T')[0];
    return isSameDay ? userPassSubscription.newPrice : userPassSubscription.price;
  }
  
  numberOfScans(): number {
    return this.userPass?.userPassScans?.filter((scan) => scan.status === UserPassScanStatusEnum.FINAL).length;
  }
}
