import { DanceSchoolRoomDto } from './dance-school-room.dto';
import { ImageDto } from './image.dto';
import { DanceSchoolRoomTypeEnum, RoomInterface } from '../../..';
import { AmenityDto } from './amenity.dto';

export class CreateDanceSchoolRoomRequestDto implements RoomInterface {
  name: string;
  size: number;
  type: DanceSchoolRoomTypeEnum;
  images: ImageDto[];
  amenities: AmenityDto[];
  locationId: string;
  rooms: Required<DanceSchoolRoomDto[]>;
}
