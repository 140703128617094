import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateDanceEventRequestDto, DanceEventCoHostStatus, DanceEventsDto, DanceEventTimePhaseEnum, environmentForWeb, PageDto, UpdateDanceEventAppointmentAndLocationRequestDto, UpdateDanceEventRulesRequestDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class DanceEventHttpService {
  private SERVICE_URL = `${environmentForWeb.apiDanceEventsUrl}/dance-events`;
  private SERVICE_URL_IMAGES = `${environmentForWeb.apiDanceEventsUrl}/dance-event-images`;

  constructor(private readonly http: HttpClient) {}

  createDanceEvent(danceEvent: CreateDanceEventRequestDto): Observable<DanceEventsDto> {
    return this.http.post<DanceEventsDto>(`${this.SERVICE_URL}`, danceEvent);
  }
  
  getByIdsBulk(ids: string[]): Observable<DanceEventsDto[]> {
    return this.http.post<DanceEventsDto[]>(`${this.SERVICE_URL}/bulk`, {
      eventIds: ids,
      withDeleted: false
    }, {
      headers: {
        'X-API-KEY': environmentForWeb.apiKey,
      }
    });
  }

  createDanceEventNew(formData: FormData): Observable<DanceEventsDto> {
    return this.http.post<DanceEventsDto>(`${this.SERVICE_URL}`, formData);
  }

  duplicateDanceEventById(id: string, name: string, startDate: Date, duration: number, duplicateTickets: boolean): Observable<DanceEventsDto> {
    return this.http.post<DanceEventsDto>(`${this.SERVICE_URL}/${id}/duplicate`, {
      name,
      startDate,
      duration,
      duplicateTickets
    });
  }

  getEventPageViewsById(id: string): Observable<number> {
    return this.http.get<number>(`${this.SERVICE_URL}/${id}/page-views`);
  }

  updateDanceEvent(id: string, updateDanceEvent: CreateDanceEventRequestDto): Observable<DanceEventsDto> {
    return this.http.put<DanceEventsDto>(`${this.SERVICE_URL}/${id}`, updateDanceEvent);
  }

  updateGeneralInfoDanceEventById(id: string, formData: FormData): Observable<DanceEventsDto> {
    return this.http.put<DanceEventsDto>(`${this.SERVICE_URL}/${id}/general-info`, formData);
  }

  updateAppointmentAndLocationDanceEventById(id: string, data: UpdateDanceEventAppointmentAndLocationRequestDto): Observable<DanceEventsDto> {
    return this.http.put<DanceEventsDto>(`${this.SERVICE_URL}/${id}/appointment-location`, data);
  }

  updateRulesDanceEventById(id: string, data: UpdateDanceEventRulesRequestDto): Observable<DanceEventsDto> {
    return this.http.put<DanceEventsDto>(`${this.SERVICE_URL}/${id}/rules`, data);
  }

  updateStatusDanceEventById(id: string, isActive: boolean): Observable<DanceEventsDto> {
    return this.http.put<DanceEventsDto>(`${this.SERVICE_URL}/${id}/status`, {isActive});
  }

  deleteById(id: string): Observable<void> {
    return this.http.delete<void>(`${this.SERVICE_URL}/${id}`);
  }

  updateMainImageById(id: string, formData: FormData): Observable<DanceEventsDto> {
    return this.http.post<DanceEventsDto>(`${this.SERVICE_URL_IMAGES}/dance-event/${id}/main-image`, formData);
  }

  deleteMainImageById(id: string): Observable<void> {
    return this.http.delete<void>(`${this.SERVICE_URL_IMAGES}/dance-event/${id}/main-image`);
  }

  getAllByDanceManagerIdAndTimePhase(danceManagerId: string, timePhase: DanceEventTimePhaseEnum): Observable<DanceEventsDto[]> {
    return this.http.get<DanceEventsDto[]>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/${timePhase}`);
  }

  getAllByDanceManagerId(danceManagerId: string, onlyWithAppointments = false, onlyUpcoming = false): Observable<DanceEventsDto[]> {
    return this.http.get<DanceEventsDto[]>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}?onlyWithAppointments=${onlyWithAppointments}&onlyUpcoming=${onlyUpcoming}`);
  }

  getById(id: string, checkHasSoldTickets: boolean = false, fromDm: boolean = false): Observable<DanceEventsDto> {
    const params = new HttpParams().set('checkHasSoldTickets', checkHasSoldTickets ? 'true' : 'false').set('fromDm', fromDm ? 'true' : 'false');
    return this.http.get <DanceEventsDto>(`${this.SERVICE_URL}/${id}`, {params});
  }

  getAllByFilter(args: {page: number; limit: number; sortColumn: string; latitude?: number; longitude?: number; radius?: number; danceStyles?: string[]; musicStyles?: string[]; categories?: string[]; name?: string; startDate?: Date; endDate?: Date; clientDateUtc?: Date}): Observable<PageDto<DanceEventsDto>> {
    let params = new HttpParams();
    
    if (args.latitude) {
      params = params.set('latitude', args.latitude);
    }

    if (args.longitude) {
      params = params.set('longitude', args.longitude);
    }

    if (args.longitude && args.latitude) {
      if (args.radius) {
        params = params.set('radius', args.radius);
        params = params.set('radiusUnit', 'KM');
      }else {
        params = params.set('radius', 250);
        params = params.set('radiusUnit', 'KM');
      }
    }

    params = params.set('page', args.page);
    params = params.set('limit', args.limit);
    params = params.set('sortColumn', args.sortColumn);
    
    if (args.danceStyles?.length > 0) {
      params = params.set('danceStyles', args.danceStyles.toString());
    }

    if (args.musicStyles?.length > 0) {
      params = params.set('musicStyles', args.musicStyles.toString());
    }

    if (args.categories?.length > 0) {
      params = params.set('categories', args.categories.toString());
    }

    if (args.name) {
      params = params.set('name', args.name);
    }

    if (args.startDate) {
      params = params.set('startDate', args.startDate.toISOString());
    }

    if (args.endDate) {
      params = params.set('endDate', args.endDate.toISOString());
    }

    if (args.clientDateUtc) {
      params = params.set('clientDateUtc', args.clientDateUtc.toISOString());
    }
    
    return this.http.get<PageDto<DanceEventsDto>>(this.SERVICE_URL, { params });
  }
  acceptOrDeclineCoHostInvitation(danceEventId: string, danceManagerId: string, status: DanceEventCoHostStatus):Observable<any>{
    return this.http.patch<any>(`${this.SERVICE_URL}/${danceEventId}/co-host/status`, {
      danceManagerId,
      status
    });
  }
}
