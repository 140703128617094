import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsTableComponent } from './appointments-table.component';
import { MatChip } from '@angular/material/chips';
import {
  DfSharedButtonModule,
  PipesModule,
  SharedEmptyPageModule
} from '@platri/dfx-angular-core';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AppointmentsTableComponent],
  imports: [
    CommonModule,
    MatChip,
    DfSharedButtonModule,
    TranslocoDirective,
    MatTooltip,
    MatTableModule,
    PipesModule,
    MatIcon,
    MatIconButton,
    SharedEmptyPageModule,
    MatProgressSpinner
  ],
  exports: [AppointmentsTableComponent]
})
export class AppointmentsTableModule {}
