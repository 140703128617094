import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { loadConnectAndInitialize, StripeConnectInstance } from '@stripe/connect-js';
import { environmentForWeb } from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { DanceManagerHttpService, DfmStateService } from '@platri/dfx-angular-core';

@Component({
  selector: 'dfm-stripe-onboarding',
  templateUrl: './dfm-stripe-onboarding.component.html',
  styleUrls: ['./dfm-stripe-onboarding.component.scss'],
})
export class DfmStripeOnboardingComponent implements OnInit, OnDestroy {
  @ViewChild('stripeDiv') divBinder: ElementRef<HTMLElement>;
  
  instance: StripeConnectInstance;
  
  subscriptions = new Subscription();
  
  constructor(private readonly elementRef: ElementRef, private renderer:Renderer2, private danceManagerHttpService: DanceManagerHttpService, private dfmStateService: DfmStateService) {}

  ngOnInit(): void {
    this.loadStripeOnboardingSession();
  }

  loadStripeOnboardingSession(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getStripeAccountOnboardingSession(this.dfmStateService.selectedDm.value.id).subscribe({
        next: (data) => {
          this.instance = loadConnectAndInitialize({
            publishableKey: environmentForWeb.stripePublishableKey,
            fetchClientSecret: (): Promise<string> => Promise.resolve(data.clientSecret)
          });
          this.renderAccountOnboarding();
        }
      })
    );
  }

  renderAccountOnboarding(): void {
    if (this.instance) {
      const accountOnboarding = this.instance.create('account-onboarding');
      accountOnboarding.setOnExit(() => {
        console.log('User exited the onboarding flow');
      });
      this.renderer.appendChild(this.divBinder.nativeElement, accountOnboarding);
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
