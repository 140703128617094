import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DanceSchoolInterface, SettingsPageUrlFragmentEnum } from '@platri/df-common-core';
import { DanceSchoolStateService, DfmStateService } from '@platri/dfx-angular-core';
import * as _ from 'lodash';

@Component({
  selector: 'dfm-settings-page',
  templateUrl: './dfm-settings.page.html',
  styleUrls: ['./dfm-settings.page.scss']
})
export class DfmSettingsPage implements OnInit, OnDestroy {
  activeTabIndex = 0;
  danceSchool: DanceSchoolInterface;

  private subscription = new Subscription();
  showPaymentHistory = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private danceSchoolService: DanceSchoolStateService,
    private dfmStateService: DfmStateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUrlFragment();
    this.getDanceSchool();
  }

  getDanceSchool(): void {
    this.subscription.add(this.dfmStateService.getAsyncSelectedDm().subscribe((danceSchool) => (this.danceSchool = danceSchool)));
  }

  getUrlFragment(): void {
    this.activatedRoute.fragment.subscribe((fragment) => {
      if (!_.isNil(fragment) && !_.isEmpty(fragment)) {
        switch (fragment.toLowerCase()) {
          case 'subscription': {
            this.activeTabIndex = 0;
            break;
          }
          case 'general': {
            this.activeTabIndex = 1;
            break;
          }
          case 'onboarding': {
            this.activeTabIndex = 2;
            break;
          }
        }
      }
    });
  }

  changeRouteToFragment(index: number): void {
    let fragment: SettingsPageUrlFragmentEnum;
    switch (index) {
      case 0:
      default:
        fragment = SettingsPageUrlFragmentEnum.SUBSCRIPTION;
        break;
      case 1:
        fragment = SettingsPageUrlFragmentEnum.GENERAL;
        break;
      case 2:
        fragment = SettingsPageUrlFragmentEnum.ONBOARDING;
        break;
    }
    this.router.navigate(['dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'settings',], {fragment});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeTitle(value: boolean): void {
    this.showPaymentHistory = value;
  }
}
