import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DanceManagerSocialLinkTypeEnum } from '@platri/df-common-shared-graphql';
import { DanceManagerSocialLinkInterface, DanceSchoolInterface } from '@platri/df-common-core';

@Component({
  selector: 'dfm-social-links-edit',
  templateUrl: './dfm-social-links-edit.component.html',
  styleUrls: ['./dfm-social-links-edit.component.scss'],
})
export class DfmSocialLinksEditComponent implements OnInit, OnChanges {
  @Input() danceSchool: DanceSchoolInterface;
  @Output() saveClick = new EventEmitter<DanceManagerSocialLinkInterface[]>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();
  @Input() formGroup: UntypedFormGroup;

  public danceManagerSocialLinkTypeEnum = DanceManagerSocialLinkTypeEnum;

  public availableDanceSchoolSocialLinkTypeEnum = [];

  //formGroup: FormGroup;
  isSubmitting = true;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get urlControl(): AbstractControl {
    return this.formGroup.get('url');
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.danceSchool) {
      this.checkAvailableEnumSelections();
    }
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [null, Validators.required],
      url: [null, [Validators.required, this.urlValidator.bind(this)]],
    });
  }

  updateUrlControlValidators(): void {
    const urlControl = this.formGroup.get('url');
    urlControl.updateValueAndValidity();
  }

  urlValidator(control: AbstractControl): { [key: string]: any } | null {
    const url = control.value;
    const type = this.formGroup?.get('type')?.value;

    if (!url || !type) {
      return null;
    }
    let regex: RegExp;

    switch (type) {
      case 'FACEBOOK':
        regex = /^(https?:\/\/)?(www\.)?(m\.)?facebook.com\//;
        break;
      case 'INSTAGRAM':
        regex = /^(https?:\/\/)?(www\.)?instagram.com\//;
        break;
      case 'YOUTUBE':
        regex = /^(https?:\/\/)?(www\.)?(m\.)?youtube.com\//;
        break;
      case 'TIKTOK':
        regex = /^(https?:\/\/)?(www\.)?tiktok.com\//;
        break;
      case 'LINKEDIN':
        regex = /^(https?:\/\/)?(www\.)?linkedin.com\//;
        break;
      case 'XING':
        regex = /^(https?:\/\/)?(www\.)?xing.com\//;
        break;
      case 'WEBSITE':
        regex = /^(https?:\/\/)?/;
        break;
      case 'TWITTER':
        regex = /^(https?:\/\/)?(www\.)?twitter.com\//;
        break;
      default:
        return null;
    }
    console.log(regex.test(url) ? null : { invalidUrl: true });
    return regex.test(url) ? null : { invalidUrl: true };
  }
  

  checkAvailableEnumSelections(): void {
    this.availableDanceSchoolSocialLinkTypeEnum = [];
    Object.keys(this.danceManagerSocialLinkTypeEnum).forEach((obj) => {
      if (!this.danceSchool.socialLinks?.find((socialLink) => socialLink.type === obj)) {
        this.availableDanceSchoolSocialLinkTypeEnum.push(obj);
      }
    });
  }

  saveSocialLink(): void {
    if(this.urlControl.errors?.invalidUrl) return;
    const index = this.danceSchool?.socialLinks?.findIndex(
      (socialLink) => socialLink.type === this.formGroup.value.type
    );
    if (!index || index === -1) {
      const newSocialLink: DanceManagerSocialLinkInterface = {
        type: this.formGroup.value.type,
        url: this.formGroup.value.url,
      };
      if (this.danceSchool?.socialLinks?.length > 0) {
        this.danceSchool?.socialLinks.push(newSocialLink);
      } else {
        this.danceSchool.socialLinks = [newSocialLink];
      }
      this.saveClick.emit(this.danceSchool.socialLinks);
      this.formGroup.reset();
    }
    this.closeEditMode.emit();
    this.checkAvailableEnumSelections();
  }
}
