import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatCardModule} from "@angular/material/card";
import { MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {MatDividerModule} from "@angular/material/divider";
import {TranslocoModule} from "@jsverse/transloco";
import { MatTooltipModule} from "@angular/material/tooltip";
import { PipesModule } from '../../pipes';
import { EventTicketShoppingCardComponent } from './event-ticket-shopping-card.component';

@NgModule({
  declarations: [EventTicketShoppingCardComponent],
  exports: [EventTicketShoppingCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    PipesModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatDividerModule,
    TranslocoModule,
    MatTooltipModule,
  ],
})
export class EventTicketShoppingCardModule {
}
