import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule, Routes } from '@angular/router';
import {
  DanceEventListTableModule,
  DanceEventPreviewCardModule,
  dfDanceEventIdRouterParam,
  DfFabButtonModule,
  DfmDanceEventResolver,
  DfSharedButtonModule,
  DmEventPreviewCardModule,
  PaginatorModule,
  SharedEmptyPageModule,
  TranslocoStateConst
} from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { EmptyDanceEventListModule } from '../../components';
import { MatCardModule } from '@angular/material/card';
import { CreateDanceEventStepperPage, CreateDanceEventStepperPageModule } from '../create-dance-event-stepper-page';
import { DfmEventPreviewPage, DfmEventPreviewPageModule } from '../dfm-event-preview-page';
import { DfmEventPage } from './dfm-event.page';

const routes: Routes = [
  {
    path: '',
    component: DfmEventPage,
  },
  {
    path: 'create',
    component: CreateDanceEventStepperPage,
  },
  {
    path: 'preview/:' + dfDanceEventIdRouterParam,
    component: DfmEventPreviewPage,
    data: { breadcrumb: { alias: 'eventName' } },
    resolve: {
      targetObject: DfmDanceEventResolver,
    },
  },
];

@NgModule({
  declarations: [DfmEventPage],
  imports: [
    CommonModule,
    MatDividerModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    PaginatorModule,
    TranslocoModule,
    MatTooltipModule,
    MatTabsModule,
    DanceEventPreviewCardModule,
    DmEventPreviewCardModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    DfmEventPreviewPageModule,
    EmptyDanceEventListModule,
    CreateDanceEventStepperPageModule,
    DfSharedButtonModule,
    DfFabButtonModule,
    SharedEmptyPageModule,
    DanceEventListTableModule
  ]
})
export class DfmEventPageModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'dfm-event', alias: 'DANCE_MANAGER_EVENT' });
  }
}
