<ng-container *transloco="let t">
  <div class="align-center">
    <mat-card class="mb2 w-100" [class.container-item-card]="!isDmCard" [class.container-item-card-dm-preview]="isDmCard" (click)="clicked.emit()">
      <a [routerLink]="isDm ? 'preview/' + course.id : '/dance-class/' + course.id" style="text-decoration: none; color: inherit">
        <div class="content-container-flex w-100">
          <div class="left-column flex-column-center">
            <div class="preview-image-container flex-column">
              <img class="preview-image w-100 mb0 block" [src]="course?.mainImage?.url ?? 'assets/images/course_card_image.png'" [alt]="">
            </div>
          </div>
          <div class="w-100 right-column flex-column align-start" style="flex: 1;">
            <span class="next-appointment" *ngIf="course.nextAppointmentDate || course.appointments">
              <strong>Next:</strong>
  <!--            {{-->
  <!--              course.nextAppointmentDate ?? course.appointments[0].date | dateTimezoned : 'UTC' : currentLanguage : course.nextAppointmentDuration ?? course.appointments[0].duration-->
  <!--            }}-->
  
              {{
                course.nextAppointmentDate | dateTimezoned : 'UTC' : currentLanguage : course.nextAppointmentDuration 
              }}
            </span>
            <div class="flex-column">
              <span class="course-title">{{ course.name }}</span>
              <span class="dm-name">{{ course.danceManager?.name }}</span>
            </div>
            <div class="course-desc">
              <span [innerHtml]="course.description | stripHtmlTags | truncate:190"></span>
            </div>
            <span *ngIf="course.cheapestPrice !== null && course.cheapestPrice !== -1" class="mt1">
              <b>{{ t('GENERIC_WRAPPER.SALE_START_AT') }}</b>
              {{ course.cheapestPrice | centsToCurrencyString: 'EUR' }}
            </span>
            <div class="w-100 h-100 flex-row align-space-between">
              <div class="mat-chip-container align-end-start">
                <mat-chip-listbox class="flex-row" [selectable]="false">
                  <ng-container *ngFor="let danceStyle of course.danceStyles.slice(0, isDmCard ? 2 : 5); let index = index">
                    <mat-chip-option class="mat-chip-looking-for" [disabled]="true">
                      {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
                    </mat-chip-option>
                  </ng-container>
                </mat-chip-listbox>
              </div>
              <div class="end-row-distance flex-column align-end">
                <span *ngIf="bottomInformation === 'distance' && course.distance !== null && course.distance >= 0" id="distance-span">
                  <mat-icon class="mat-icon-size material-icons-round vSub">place</mat-icon>
                  {{ course.distance | roundDistancePipe }}
                  {{ course.radiusUnit }}
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="isDm" style="width: 10%;" class="pr2 flex-column-center-end">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.preventDefault(); $event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteDanceCourse()">
                <span>{{ t('GENERIC_WRAPPER.DELETE') }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </a>
    </mat-card>
  </div>
</ng-container>
