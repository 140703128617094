<ng-container *transloco="let t">
  <div class="pt2 flex-column align-center">
    <div class="loading-box" [class.spinner-big]="true" *ngIf="isLoading"></div>
    <mat-card style="padding: 16px;" class="passes-container w-100 flex-column" *ngIf="!isLoading">
      <div class="px1 header-title-container">
        <div class="flex-row align-start-center">
          <button mat-icon-button (click)="onBackButtonClick()" style="margin-right: 5px;">
            <mat-icon class="back-btn-icon">arrow_back_rounded</mat-icon>
          </button>
          <span class="course-title">{{ userPassSubscription.name }}</span>
        </div>
      </div>
      
      <div class="p2">
        <mat-divider style="position: initial"></mat-divider>
      </div>
      
      <div style="overflow: auto;" class="p2 flex-row-center-center">
        <div class="user-pass-box p3 flex-column">
          <button *ngIf="currentUserPass.userPassSubscription.status !== userPassSubscriptionStatusEnum.TERMINATED" mat-icon-button [matMenuTriggerFor]="menu2" (click)="$event.stopPropagation()">
            <mat-icon class="material-icons-round">more_vert</mat-icon>
          </button>
          
          <mat-menu #menu2="matMenu" xPosition="before">
            <button mat-menu-item (click)="openStripeBillingPortalSession()">
              <mat-icon class="material-icons-round">settings</mat-icon>
              <span>{{ t('GENERIC_WRAPPER.MANAGE_SUBSCRIPTIONS') }}</span>
            </button>
            <button *ngIf="canBeWithdrawn()" mat-menu-item (click)="openRefundPassDialog()">
              <mat-icon class="material-icons-round">undo</mat-icon>
              <span>{{ t('USER.WITHDRAWAL_ORDER') }}</span>
            </button>
          </mat-menu>
          
          <div *ngIf="currentUserPass.userPassSubscription.status !== userPassSubscriptionStatusEnum.TERMINATED" class="flex-row align-center">
            <qrcode [qrdata]="currentUserPass?.id" [width]="150"></qrcode>
          </div>
          <div *ngIf="userPassSubscription?.weeklyUsage !== null && currentUserPass.userPassSubscription.status !== userPassSubscriptionStatusEnum.TERMINATED" class="mt1 flex-column" style="justify-content: flex-start">
            <div class="align-space-between-center">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.STAMPS') }}</span>
              <span>{{ numberOfScans() + '/' + this.userPassSubscription?.weeklyUsage }}</span>
            </div>
            <mat-grid-list cols="5" rowHeight="40px" gutterSize="10px" style="margin-top: 10px; margin-bottom: 10px;">
              <mat-grid-tile *ngFor="let stamp of shownStamps" class="stamp-container">
                <div [class]="stamp <= numberOfScans() ? 'align-center stamp used' : 'align-center stamp unused'">
                  <mat-icon *ngIf="stamp <= numberOfScans()" style="color: #00A1DF">check</mat-icon>
                  <span *ngIf="stamp > numberOfScans()">{{ stamp }}</span>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <div class="flex-row">
              <span class="stamp-reset-label">{{ t('USER.STAMPS_RESET_AT') }} {{ dateNextMonday | dateTimezoned:'UTC' : currentUser.appLang : null: true: false : 'shortDate' }}</span>
            </div>
          </div>
          
          <div class="mt2 flex-row">
            <div style="flex: 1;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.NAME') }}</span>
              <span class="pass-detail-info">{{ currentUser?.firstName + ' ' + currentUser?.lastName }}</span>
            </div>
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.PRICE') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.price | centsToCurrencyString : 'EUR' }}</span>
            </div>
          </div>
          
          <div class="mt2 flex-row w-100">
            <div style="flex: 1;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.SUBSCRIPTION') }}</span>
              <span class="pass-detail-info">{{ userPassSubscription?.name }}</span>
            </div>
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('USER.SUBSCRIBED_ON') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.createdAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
            </div>
          </div>
          
          <div class="mt2 flex-row">
            <div style="flex: 1;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION') }}</span>
              <span class="pass-detail-info">{{
                  currentUserPass?.userPassSubscription?.durationValue + ' ' +
                  t('GENERIC_WRAPPER.' + currentUserPass?.userPassSubscription?.durationUnit +
                  (currentUserPass?.userPassSubscription?.durationValue > 1 ? '_PLURAL' : '_SINGULAR'))
                }}</span>
            </div>
          
            <div style="flex: 1; padding-left: 20px;" *ngIf="currentUserPass.userPassSubscription.status === userPassSubscriptionStatusEnum.PAID || currentUserPass.userPassSubscription.status === userPassSubscriptionStatusEnum.PAYMENT_PENDING" class="flex-column">
              <span class="pass-detail-label">{{ t('USER.NEXT_PAYMENT_ON') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.currentPeriodEndAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
            </div>
            <div style="flex: 1; padding-left: 20px;" *ngIf="currentUserPass.userPassSubscription.status === userPassSubscriptionStatusEnum.TERMINATED_PERIOD_END" class="flex-column">
              <span class="pass-detail-label">{{ t('USER.TERMINATED_AS_OF_TEXT') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.endedAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
            </div>
            <div style="flex: 1; padding-left: 20px;" *ngIf="currentUserPass.userPassSubscription.status === userPassSubscriptionStatusEnum.TERMINATED" class="flex-column">
              <span class="pass-detail-label">{{ t('USER.EXPIRED_ON_TEXT') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.currentPeriodEndAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
            </div>
          </div>
          
          <div *ngIf="currentUserPass.userPassSubscription.isPaidMonthly && terminatedPaymentsLeft" class="mt2 flex-row">
            <div style="flex: 1;" class="flex-column">
              <span class="pass-detail-label">{{ t('USER.NEXT_PAYMENT_ON') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.currentPeriodEndAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
            </div>
          </div>
          <div *ngIf="currentUserPass.userPassSubscription.priceAccepted && currentUserPass.userPassSubscription.newPrice" class="mt2 flex-row">
            <div style="flex: 1;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.NEW_PRICE_START_DATE') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.priceChangeDate | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
            </div>
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.NEW_PRICE') }}</span>
              <span class="pass-detail-info">{{ currentUserPass?.userPassSubscription?.newPrice | centsToCurrencyString : 'EUR' }}</span>
            </div>
          </div>
          
          <div class="mt2 flex-row">
              <div style="flex: 1;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.ORGANIZER') }}</span>
              <a class="pass-detail-info" [href]="'/app/@' + currentPassOrganizer?.urlName">{{ currentPassOrganizer?.name }}</a>
            </div>
          </div>
          
          <mat-divider style="margin-top: 20px; margin-bottom: 20px;" class="dashed-divider"></mat-divider>
  
          <div *ngIf="currentUserPass?.userPassSubscription?.description" class="mb2 flex-column">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.DESCRIPTION') }}</span>
            <div class="description" [innerHTML]="!isDescriptionTruncated ? (currentUserPass.userPassSubscription.description | dynamicTag | truncate: descriptionTruncateLength) : (currentUserPass.userPassSubscription.description | dynamicTag)"></div>
                <span *ngIf="hasTruncateDescription && !isDescriptionTruncated" class="read-more-text" (click)="isDescriptionTruncated = true">{{ t('GENERIC_WRAPPER.READ_MORE') }}</span>
            <span *ngIf="hasTruncateDescription && isDescriptionTruncated" class="read-more-text" (click)="isDescriptionTruncated = false">{{ t('GENERIC_WRAPPER.READ_LESS') }}</span>
          </div>
          
          <div class="flex-column" *ngIf="activePassCourses?.length > 0">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.COURSES') }}</span>
            <ng-container *ngIf="!isCoursesTruncated">
              <a *ngFor="let course of activePassCourses | slice:0:coursesTruncateLength" class="pass-detail-info" [href]="'/app/dance-class/' + course?.id">{{ course?.name }}</a>
            </ng-container>
            <ng-container *ngIf="isCoursesTruncated">
              <a *ngFor="let course of activePassCourses" class="pass-detail-info" [href]="'/app/dance-class/' + course?.id">{{ course?.name }}</a>
            </ng-container>
            <span *ngIf="hasTruncateCourses && !isCoursesTruncated" class="read-more-text" style="margin-top: 12px;" (click)="isCoursesTruncated = true">{{ t('GENERIC_WRAPPER.SHOW_MORE') }}</span>
            <span *ngIf="hasTruncateCourses && isCoursesTruncated" class="read-more-text" style="margin-top: 12px;" (click)="isCoursesTruncated = false">{{ t('GENERIC_WRAPPER.SHOW_LESS') }}</span>
          </div>  
          
          <div class="button-container" *ngIf="canBeReactivated">
            <button mat-button class="reactivate-button" (click)="reactivateSubscription()">
              {{ t('USER.REACTIVATE_SUBSCRIPTION') }}
            </button>
          </div>
          <div class="button-container" *ngIf="canBeCancelled">
            <button mat-button class="cancel-button" (click)="cancelSubscription()">
              {{ t('GENERIC_WRAPPER.CANCEL_SUBSCRIPTION') }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
