import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DanceManagerInterface, DanceManagerSubscriptionInterface, DanceManagerSubscriptionStatusEnum, UsersInterface } from '@platri/df-common-core';
import { AuthStateService, DanceManagerService, TicketHttpService } from '../../services';
import { SharedCancelDialogComponent } from '../cancel-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dm-subscription-info-card',
  templateUrl: './dm-subscription-info-card.component.html',
  styleUrls: ['./dm-subscription-info-card.component.scss'],
})
export class DmSubscriptionInfoCardComponent implements OnInit {
  @Input() loading: boolean;
  @Input() danceManager: DanceManagerInterface;
  @Input() danceManagerSubscription: DanceManagerSubscriptionInterface;

  @Output() onCancelSubscriptionClick =
    new EventEmitter<DanceManagerSubscriptionInterface>();
  @Output() showPaymentHistoryClick = new EventEmitter<boolean>();

  danceManagerSubscriptionStatus = DanceManagerSubscriptionStatusEnum;
  hasTicketsLeft = false;
  
  currentUser: UsersInterface;

  constructor(
    public readonly translocoService: TranslocoService,
    private readonly matDialog: MatDialog,
    private readonly danceManagerService: DanceManagerService,
    private readonly ticketHttpService: TicketHttpService,
    private authStateService: AuthStateService
  ) {}
  
  ngOnInit(): void {
    this.currentUser = this.authStateService.getSyncCurrentUser();
    this.checkHasSoldTickets();
  }

  openCancelDialog(): void {
    const cancelDialogRef = this.matDialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '450px',
      disableClose: true,
      data: {
        title: this.hasTicketsLeft
        ? this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG_REMAINING_TICKETS.TITLE')
        : this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG.TITLE'),
        subtitle: this.hasTicketsLeft
        ? this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG_REMAINING_TICKETS.SUBTITLE')
        : this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG.SUBTITLE'),
        infoText: this.hasTicketsLeft
        ? this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG_REMAINING_TICKETS.STAY')
        : this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG.STAY'),
        cancelText: this.hasTicketsLeft
        ? this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG_REMAINING_TICKETS.CANCEL')
        : this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_CANCEL_DIALOG.CANCEL'),
      },
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.onCancelSubscriptionClick.emit(this.danceManagerSubscription);
      }
    });
  }

  checkHasSoldTickets(): void {
    if(this.danceManager.id){
      this.ticketHttpService.getDanceManagerHasSoldTickets(this.danceManager.id).subscribe((result) => {
        this.hasTicketsLeft = result;
      });
    }

  }
  
  isExpired(date: string): boolean {
    const currentDate = new Date();
    const expirationDate = new Date(date);
    return expirationDate < currentDate;
  }
  
  reactiveSubscription():void{
    if(this.danceManager.id){
      this.danceManagerService.reactivateSubscriptionByDanceManagerId(this.danceManager.id);
    }
  }
  
}
