<ng-container *transloco="let t">
  <div *ngIf="dataSource; else emptyPage" class="w-100 flex-column" style="gap: 1rem;">
    <div class="flex-row" style="padding-top: 10px;">
      <mat-chip (click)="toggleCourseChip(0)" [class]="this.selectedCourseStatus === CourseAppointmentStatusEnum.UPCOMING ? 'active-chip' : ''" style="margin-right: 10px;">
        {{ t('USER.APPOINTMENTS.FILTER.UPCOMING') }}
      </mat-chip>
      <mat-chip (click)="toggleCourseChip(1)" [class]="this.selectedCourseStatus === CourseAppointmentStatusEnum.PAST ? 'active-chip' : ''">
        {{ t('USER.APPOINTMENTS.FILTER.PAST') }}
      </mat-chip>
    </div>
    
    <table *ngIf="appointments?.length > 0; else emptyPage" mat-table [dataSource]="dataSource" class="w-100">
      <ng-container matColumnDef="courseName">
        <th mat-header-cell *matHeaderCellDef scope="col">{{ t('USER.APPOINTMENTS.TABLE.COURSE_NAME') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center" style="gap: 10px;">
            <span>{{ element.appointment?.course?.name }}</span>
          </div>
        </td> 
      </ng-container>
      
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef scope="col">{{ t('USER.APPOINTMENTS.TABLE.DATE') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center" style="gap: 10px;">
            <span>{{ element.appointment?.date | dateTimezoned: 'UTC' : currentUser.appLang : element.appointment?.duration }}</span>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef scope="col"> {{ t('USER.APPOINTMENTS.TABLE.STATUS') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center" style="gap: 10px;">
            <span>{{ element.status === AppointmentParticipantStatusEnum.REGISTERED ? 'Registered' : 'Scanned' }}</span>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="danceManagerName">
        <th mat-header-cell *matHeaderCellDef scope="col"> {{ t('USER.APPOINTMENTS.TABLE.DM_NAME') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center" style="gap: 10px;">
            <span>{{ element.appointment?.course?.danceManager?.name }}</span>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef scope="col"></th>
        <td mat-cell *matCellDef="let element">
          <div class="pr2 flex-row align-center-end">
            <ng-container *ngIf="this.selectedCourseStatus === CourseAppointmentStatusEnum.UPCOMING">
              <button mat-icon-button (click)="$event.stopPropagation(); checkOutAppointment(element)" matTooltip="{{ t('USER.APPOINTMENTS.TABLE.CHECKOUT_BUTTON_TOOLTIP') }}">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>    
    </table>
  </div>

  <ng-template #emptyPage>
    <df-shared-empty-page
      [hideBtn]="true"
      [emptyTitle]="t('USER.APPOINTMENTS.EMPTY_PAGE.TITLE')"
      [emptyDescription]="t('USER.APPOINTMENTS.EMPTY_PAGE.DESCRIPTION')"
    ></df-shared-empty-page>
  </ng-template>
</ng-container>
