import { PaginationInterface, SearchFilterInterface } from '../interfaces';

export class SearchFilterClass<T extends object & (keyof T extends string ? {} : "T must only have string keys")> implements SearchFilterInterface {
  filter: T;
  pagination: PaginationInterface = {
    page: 0,
    limit: 10
  };
  
  // @ts-ignore
  constructor(defaultFilter: T = {}) {
    this.filter = defaultFilter;
  }

  
}
