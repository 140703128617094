<div class="p2">
  <div class="marginb05 flex-row align-center align-space-between">
    <div><span class="fs16 bold">{{ data.title }}</span></div>
    <div><mat-icon (click)="close()">close</mat-icon></div>
  </div>
  <div mat-dialog-content class="marginb15">
    <span class="fs14"> {{ data.subtitle }}</span>
  </div>
  <div class="flex-row align-center">
    <df-shared-lib-button
      style="min-width: 175px; margin-right: 6px;"
      *ngIf="data.infoText"
      [materialButtonStyleType]="'outlined'" 
      [buttonLabel]="data.infoText"
      (buttonClicked)="close()">
    </df-shared-lib-button>
    <df-shared-lib-button 
      style="min-width: 175px; margin-left: 6px"
      *ngIf="data.cancelText"
      [materialButtonStyleType]="'flat'" 
      [buttonLabel]="data.cancelText"
      (buttonClicked)="close(false)">
    </df-shared-lib-button>
  </div>
</div>
