import { CourseTeacherInterface } from './course-teacher.interface';
import { AddressInterface } from '../address.interface';
import { LocationInterface } from '../location.interface';
import { RoomInterface } from '../room.interface';
import { CourseAppointmentStatusEnum } from '../../enums';
import { AppointmentParticipantInterface } from '../appointment-participants';

export interface CourseAppointmentInterface {
  id: string;
  date: Date;
  duration: number;
  status: CourseAppointmentStatusEnum;
  description?: string;
  location?: LocationInterface;
  room?: RoomInterface;
  address?: AddressInterface;
  teacherUserIds?: string[];
  teachers?: CourseTeacherInterface[];
  maxParticipants?: number;
  participants?: AppointmentParticipantInterface[];
}
