import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'transformMonthYearLabel',
})
export class TransformMonthYearLabelPipe implements PipeTransform {
  
  constructor(
    private readonly translocoService: TranslocoService
  ) {}


  transform(month: number, short: boolean = false): string {
    if (isNaN(month)) {
      return 0 + ' ' + (short ? this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.MONTH_SHORT') : this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.MONTH_PLURAL'));
    }
    if (month > 131) {
      return '10+ ' + (short ? this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.YEAR_SHORT') : this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.YEAR_PLURAL'));
    }
    if (month >= 12) {
      if (month >= 24) {
        return Math.floor(month / 12) + ' ' + (short ? this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.YEAR_SHORT') : this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.YEAR_PLURAL'));
      } else {
        return 1 + ' ' + this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.YEAR');
      }

    }
    return month + ' ' + (short ? this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.MONTH_SHORT') : (month > 1 ? this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.MONTH_PLURAL') : this.translocoService.translate('GENERIC_WRAPPER.SHORT_DATE_FORMATS.MONTH')));
  }
}
