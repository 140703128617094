import { NgModule } from '@angular/core';
import { LandingPageComponent } from './landing-page.component';
import { SearchModule } from '../../components';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    SearchModule
  ],
  exports: [LandingPageComponent]
})
export class LandingPageModule {}
