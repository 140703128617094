import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainContentModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfAppRoutingModule } from './df-app-routing.module';
import { DfAppComponent } from './df-app.component';
import { DfmMainContentModule, DfmSidenavModule } from '@platri/df-angular-dfm-core';

const MATERIAL_MODULES = [MatProgressSpinnerModule];

@NgModule({
  declarations: [DfAppComponent],
  imports: [
    CommonModule,
    DfmMainContentModule,
    DfAppRoutingModule,
    MainContentModule,
    ...MATERIAL_MODULES,
    DfmSidenavModule
  ],
  exports: [DfAppComponent,]
})
export class DfAppModule {
  constructor() {
    console.log('DfAppModule initialized');
  }
}
