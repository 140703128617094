import {
  AddressDto,
  AppointmentLocationDto,
  RoomDto
} from '../../..';
import { CourseTeacherDto } from './course-teacher.dto';
import {
  CourseAppointmentInterface,
  CourseAppointmentStatusEnum
} from '../../..';
import { AppointmentParticipantDto } from './appointment-participant.dto';
import { CoursePublicViewDto } from './course-public-view.dto';
import { DanceManagerDto } from './dance-manager.dto';

export class CourseAppointmentDto implements CourseAppointmentInterface {
  id: string;
  date: Date;
  duration: number;
  status: CourseAppointmentStatusEnum;
  description?: string;
  location: AppointmentLocationDto;
  room: RoomDto;
  address?: AddressDto;
  teacherUserIds: string[];
  teachers: CourseTeacherDto[];
  maxParticipants?: number;
  participants: AppointmentParticipantDto[];
  course?: CoursePublicViewDto;
}
