import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CreateDmPassSubscriptionRequestDto,
  DmPassSubscriptionDto,
  DmPassSubscriptionInterface,
  environmentForWeb
} from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DmPassSubscriptionHttpService {
  private apiBaseUrl = `${environmentForWeb.apiTicketUrl}/dm-pass-subscription`;

  constructor(private readonly http: HttpClient) {}

  getAllByDanceManagerId(danceManagerId: string): Observable<DmPassSubscriptionInterface[]> {
    return this.http.get<DmPassSubscriptionInterface[]>(`${this.apiBaseUrl}/dance-manager/${danceManagerId}`);
  }

  getPassSubscriptionById(id: string): Observable<DmPassSubscriptionDto> {
    return this.http.get<DmPassSubscriptionDto>(`${this.apiBaseUrl}/${id}`);
  }

  createPassSubscription(data: CreateDmPassSubscriptionRequestDto): Observable<string> {
    // @ts-ignore
    return this.http.post<string>(`${this.apiBaseUrl}`, data, { responseType: 'text' });
  }

  updatePassSubscriptionById(id: string, data: DmPassSubscriptionInterface): Observable<DmPassSubscriptionInterface> {
    return this.http.patch<DmPassSubscriptionInterface>(`${this.apiBaseUrl}/${id}`, data);
  }

  deletePassSubscriptionById(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiBaseUrl}/${id}`);
  }

  getMinPriceByTargetId(targetId: string): Observable<number> {
    return this.http.get<number>(`${this.apiBaseUrl}/target-min-price/${targetId}`);
  }

  updateSequenceNumber(danceManagerId: string, newSequence: {id: string; sequenceNumber: number}[]): Observable<void> {
    return this.http.patch<void>(this.apiBaseUrl + '/sequence/' + danceManagerId , newSequence);
  }
}
