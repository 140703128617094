import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceEventPage } from './dance-event.page';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoHostNotificationModule, DanceEventCoHostsModule, DfSharedButtonModule, PipesModule, ShareModule, UnauthorizedClickCheckElementModule } from '@platri/dfx-angular-core';
import { GoogleMapsModule } from '@angular/google-maps';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventTicketsModule } from '../event-tickets-page';
import { DanceEventHeaderModule } from '../../components';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceEventPage],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    ShareModule,
    DanceEventHeaderModule,
    MatChipsModule,
    MatDividerModule,
    MatListModule,
    ReactiveFormsModule,
    EventTicketsModule,
    PipesModule,
    MatTooltipModule,
    GoogleMapsModule,
    UnauthorizedClickCheckElementModule,
    DanceEventCoHostsModule,
    CoHostNotificationModule,
    DfSharedButtonModule,
    RouterModule,
    TranslocoDirective
  ]
})
export class DanceEventPageModule {}
