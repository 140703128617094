<div class="p2">
  <div class="mb-10">
    <span class="title" [innerHTML]="data.title"></span>
  </div>
  <div class="mb-10 flex-column">
    <mat-icon
      *ngIf="data.textColor"
      style="font-size: 80px; height: 80px; width: 80px; align-self: center"
      [style.color]="data.textColor"
    >
      warning_amber
    </mat-icon>
  
    <div class="text">
      <div class="text" [innerHTML]="data.text"></div>
    </div>
  </div>
  <form [formGroup]="textAreaForm" (ngSubmit)="confirm()">
    <div *ngIf="data.withTextArea" class="mb-10 flex-column">
      <mat-form-field>
        <textarea
          matInput
          name="textAreal"
          id="textAreal"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          placeholder="{{ data.placeholder }}"
          formControlName="textareaFormInput"
        ></textarea>
        <mat-hint></mat-hint>
        <mat-error *ngIf="textAreaForm.controls.textareaFormInput.hasError('required')">
          {{ data.requiredText }}
        </mat-error>
      </mat-form-field>
    </div>
  
    <div class="flex-row align-end" style="gap: 10px;">
      <df-shared-lib-button
        *ngIf="data.cancelButtonText"
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="data.cancelButtonText"
        [mat-dialog-close]="false"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [materialButtonStyleType]="'flat'"
        [buttonLabel]="data.submitButtonText"
        (buttonClicked)="confirm()"
        id="confirm-button"
      ></df-shared-lib-button>
    </div>
  </form>
</div>
