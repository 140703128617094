import { ImageDto } from './image.dto';
import { DanceClassRulesDto } from './dance-class-rules.dto';
import { DanceClassCommentEnum, DanceClassGenderEnum, RadiusUnitEnum } from '../../..';
import { DanceClassToUserDto } from './dance-class-to-user.dto';
import { DanceClassRestrictionsDto } from './dance-class-restrictions.dto';
import { DanceStyleDto } from './dance-style.dto';
import { SchedulerDto } from './scheduler.dto';
import { UserDto } from './user.dto';
import { AppointmentsDto } from './appointments.dto';
import { DanceSchoolDto } from './dance-school.dto';

export class DanceClassDto{
  description?: string;
  startDifficulty?: number;
  endDifficulty?: number;
  tags?: string[];
  rules?: DanceClassRulesDto;
  genderRules?: DanceClassGenderEnum[];
  commentOption?: DanceClassCommentEnum;
  fromAge?: number;
  toAge?: number;
  danceStyles?: DanceStyleDto[];
  schedulers?: SchedulerDto[];
  danceClassToUsers?: DanceClassToUserDto[];
  clientTimezone?: string;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  images?: ImageDto[];
  previewImage?: ImageDto;
  titleImage?: ImageDto;
  restrictions?: DanceClassRestrictionsDto;
  teachers?: UserDto[];
  students?: UserDto[];
  appointments?: AppointmentsDto[];
  maxParticipants?: number;
  createdAt?: Date;
  updatedAt?: Date;
  id?: string;
  name?: string;
  danceSchool?: DanceSchoolDto;
}
