<ng-container *transloco="let t">
  <div class="pt2 flex-column align-center h-100">
    <div class="loading-box" [class.spinner-big]="true" *ngIf="isLoading"></div>
    <mat-card style="padding: 16px; flex: 1;" class="w-100 flex-column" *ngIf="!isLoading">
      <div style="overflow: auto;" class="p2 flex-row-center-center">
        <div class="user-pass-box p3 flex-column">
          <div class="px1 header-title-container">
            <div class="flex-row align-start-center">
              <span class="course-title">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_PRICE_CHANGE') }}</span> 
            </div>
          </div>
          <div class="px1">
            <span>{{ t('GENERIC_WRAPPER.THE_DANCEMANAGER') }} </span>
            <a class="pass-detail-info" [href]="'/app/@' + danceManager?.urlName">{{ danceManager?.name }}</a>
            <span> {{ t('GENERIC_WRAPPER.IS_CHANGING_THE_PRICE_OF_THE') }} {{userPassSubscription.name}}.</span>
          </div>
          
          <div class="mt2 flex-row">
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.OLD_PRICE') }}:</span>
              <span class="pass-detail-info">{{ userPassSubscription?.price | centsToCurrencyString : 'EUR' }}</span>
            </div>
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.NEW_PRICE') }}:</span>
              <span class="pass-detail-info ">
                <strong>{{ userPassSubscription?.newPrice | centsToCurrencyString : 'EUR' }}
                </strong>
              </span>
            </div>
          </div>
          
          <div class="mt2 flex-row">
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.NEW_PRICE_START_DATE') }}:</span>
              <span class="pass-detail-info">{{ userPassSubscription?.endedAt  | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate'  }}</span>
            </div>
            
            <div style="flex: 1; padding-left: 20px;" class="flex-column">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION') }}:</span>
              <span class="pass-detail-info">{{
                  userPassSubscription?.durationValue + ' ' +
                  t('GENERIC_WRAPPER.' + userPassSubscription?.durationUnit +
                    (userPassSubscription?.durationValue > 1 ? '_PLURAL' : '_SINGULAR'))
                }}</span>
            </div>
          </div>
          
          <mat-divider style="margin-top: 20px; margin-bottom: 20px;" class="dashed-divider"></mat-divider>
  
          <div *ngIf="userPassSubscription?.description" class="mb2 flex-column pl-20">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.DESCRIPTION') }}</span>
            <div class="description" [innerHTML]="!isDescriptionTruncated ? (userPassSubscription.description | dynamicTag | truncate: descriptionTruncateLength) : (userPassSubscription.description | dynamicTag)"></div>
                <span *ngIf="hasTruncateDescription && !isDescriptionTruncated" class="read-more-text" (click)="isDescriptionTruncated = true">{{ t('GENERIC_WRAPPER.READ_MORE') }}</span>
            <span *ngIf="hasTruncateDescription && isDescriptionTruncated" class="read-more-text" (click)="isDescriptionTruncated = false">{{ t('GENERIC_WRAPPER.READ_LESS') }}</span>
          </div>
          
          <div class="flex-column pl-20 mb2" *ngIf="activePassCourses?.length > 0">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.COURSES') }}</span>
            <ng-container *ngIf="!isCoursesTruncated">
              <a *ngFor="let course of activePassCourses | slice:0:coursesTruncateLength" class="pass-detail-info" [href]="'/app/dance-class/' + course?.id">{{ course?.name }}</a>
            </ng-container>
            <ng-container *ngIf="isCoursesTruncated">
              <a *ngFor="let course of activePassCourses" class="pass-detail-info" [href]="'/app/dance-class/' + course?.id">{{ course?.name }}</a>
            </ng-container>
            <span *ngIf="hasTruncateCourses && !isCoursesTruncated" class="read-more-text" style="margin-top: 12px;" (click)="isCoursesTruncated = true">{{ t('GENERIC_WRAPPER.SHOW_MORE') }}</span>
            <span *ngIf="hasTruncateCourses && isCoursesTruncated" class="read-more-text" style="margin-top: 12px;" (click)="isCoursesTruncated = false">{{ t('GENERIC_WRAPPER.SHOW_LESS') }}</span>
          </div>  
          
          <div class="button-container a pt-15">
            <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.AGREE')" (buttonClicked)="reactivateSubscription()">
            </df-shared-lib-button>
          </div>
          <div class="button-container pt-15">
            <button mat-button class="cancel-button" (click)="routeToSubscriptionDetails()">
              {{t('GENERIC_WRAPPER.DONT_ACCEPT_THE_NEW_PRICE')}}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
