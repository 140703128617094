import { Component, Input, OnInit } from '@angular/core';
import { CourseDto, CreateTicketOrderRequestDto, DanceManagerInterface, DanceManagerPassesPublicResponseDto, UsersInterface } from '@platri/df-common-core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { CoursesHttpService, dfAppRoute, dfLoginRoute, TicketOrderService } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-monorepo-dance-manager-passes-list',
  templateUrl: './dance-manager-passes-list.component.html',
  styleUrls: ['./dance-manager-passes-list.component.scss']
})
export class DanceManagerPassesListComponent implements OnInit {
  @Input() passes: DanceManagerPassesPublicResponseDto[];
  @Input() isOwnerOrAdmin: boolean;
  @Input() danceManager: DanceManagerInterface;
  @Input() currentUser: UsersInterface;

  formGroup: UntypedFormGroup;
  loadedCourses: { [key: string]: CourseDto } = {};
  
  subscriptions = new Subscription();
  isLoading = true;
  isCreatingTicketOrder = false;
  
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly ticketOrderService: TicketOrderService,
    private readonly coursesHttpService: CoursesHttpService
  ) {}
  
  ngOnInit(): void {
    this.loadData();
    this.initializeForm();
  }
  
  loadData(): void {
    this.isLoading = true;
    const targetIds: string[] = [...new Set(this.passes.flatMap(pass => pass.passTargets.map(passTarget => passTarget.targetId)))];
    this.coursesHttpService.getCoursesBulkById(targetIds).subscribe(courses => {
      courses.forEach(course => {
        this.loadedCourses[course.id] = course;
      });
      this.isLoading = false;
    });
  }

  initializeForm(): void {
    this.formGroup = this.fb.group({});
    this.passes.forEach((pass) => {
      if (pass?.id) {
        this.formGroup.addControl(pass.id, this.fb.control(0));
      }
    });
  }

  routeToPassBuyout(pass: DanceManagerPassesPublicResponseDto): void {
    if (pass.trial) {
      if (!this.currentUser) {
        this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url }});
      }
      else if (!this.isCreatingTicketOrder) {
        this.isCreatingTicketOrder = true;
        const createTicketOrderDto: CreateTicketOrderRequestDto = {
          passOrderItems: [{passId: pass.id, quantity: 1}],
          ticketOrderItems: []
        };
        this.ticketOrderService.createTicketOrder(createTicketOrderDto, null, this.danceManager.urlName);
      }
    }
    else if (!this.isCreatingTicketOrder) {
      this.isCreatingTicketOrder = true;
      const createTicketOrderDto: CreateTicketOrderRequestDto = {
        passOrderItems: [],
        ticketOrderItems: []
      };
      
      for (const control in this.formGroup.controls) {
        const foundPass = this.passes.find((obj) => obj.id === control);
        if (foundPass && this.formGroup.controls[control].value > 0) {
          createTicketOrderDto.passOrderItems.push({
            passId: foundPass.id,
            quantity: this.formGroup.controls[control].value
          });
        }
      }
      
      if (this.currentUser && createTicketOrderDto.passOrderItems.length === 1) {
        this.ticketOrderService.createTicketOrder(createTicketOrderDto, null, this.danceManager.urlName);
      }
      else if (!this.currentUser || createTicketOrderDto.passOrderItems.length > 1) {
        this.ticketOrderService.sendTempCreateTicketOrderData(createTicketOrderDto, pass.passTargets[0].targetId);
        this.router.navigate(['@' + this.danceManager.urlName + '/passes']);
      }
    }
  }
  
  routeToPassEdit(passId: string): void {
    this.router.navigate(['/dance-manager', this.danceManager.id, 'courses', 'pass', passId]);
  }
}
