<h2 class="text-center">
  <span (click)="navigateToEvent()" class="link">Event Tickets </span>
  <span>/ {{ eventTicket.name }} / Edit</span>
</h2>
<div *ngIf="!loadingDataError">
  <ng-container *ngIf="parentFormGroup">
    <form *ngIf="parentFormGroup && isDataInjected">
      <df-custom-stepper (submit)="submitForm()" #stepper [linear]="true">
        <cdk-step
          label="Eventticket settings"
          errorMessage="Some settings are required"
          [stepControl]="ticketSettingsFormGroup"
        >
          <df-event-ticket-settings-step
            [danceEvents]="danceEvents"
            [passedFormGroup]="ticketSettingsFormGroup"
          >
          </df-event-ticket-settings-step>
        </cdk-step>
        <cdk-step
          label="Pricing"
          errorMessage="Some pricing informations are missing"
          [stepControl]="pricingFormGroup"
        >
          <df-event-ticket-pricing-step
            [passedFormGroup]="pricingFormGroup"
          ></df-event-ticket-pricing-step>
        </cdk-step>
        <cdk-step
          label="Sales settings (Optional)"
          [optional]="false"
          [stepControl]="salesSettingsFormGroup"
          errorMessage="Some settings are required"
        >
          <df-event-ticket-sales-settings-step
            [passedFormGroup]="salesSettingsFormGroup"
            [dfmUserGroups]="dfmUserGroups"
          ></df-event-ticket-sales-settings-step>
        </cdk-step>
        <cdk-step
          label="Submit"
          [stepControl]="parentFormGroup"
          errorMessage="Some settings are required"
        >
          <df-event-ticket-submit-step
            [passedFormGroup]="parentFormGroup"
            [dfmUserGroups]="dfmUserGroups"
          ></df-event-ticket-submit-step>
        </cdk-step>
      </df-custom-stepper>
    </form>
  </ng-container>
</div>
