<ng-container *transloco="let t">
  <div class="w-100 flex-column" style="gap: 1rem;">
    <div class="flex-row" style="padding-top: 10px;">
      <mat-chip (click)="toggleCourseChip(0)" [class]="this.activeCourseChipIndex === 0 ? 'active-chip' : ''" style="margin-right: 10px;">
        {{ t('GENERIC_WRAPPER.ACTIVE') }}
      </mat-chip>
      <mat-chip (click)="toggleCourseChip(1)" [class]="this.activeCourseChipIndex === 1 ? 'active-chip' : ''">
        {{ t('GENERIC_WRAPPER.ARCHIVED') }}
      </mat-chip>
    </div>
  
    <table *ngIf="courses?.length !== 0" mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.NAME') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center" style="gap: 10px;">
            <span>
              {{ element.name }}
            </span>
          </div>
        </td> 
      </ng-container>
      
      <ng-container matColumnDef="danceStyles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let danceStyle of element.danceStyles; let last = last">
                {{ danceStyle.translationKey | titlecase }}
              {{ !last ? ', ' : '' }}
            </ng-container>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="difficultyRange">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ t('GENERIC_WRAPPER.LEVEL') }}</th>
        <td mat-cell *matCellDef="let element">
            {{ t('GENERIC_WRAPPER.' + (element.startDifficulty | skillLevelTranslation)) }}
          -
          {{ t('GENERIC_WRAPPER.' + (element.endDifficulty | skillLevelTranslation)) }}
        </td>
      </ng-container>
      
      <!-- <ng-container matColumnDef="teacher">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ t('GENERIC_WRAPPER.COURSES_TABLE.TEACHER') }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
             {{ element.teacherId }}
          </div>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="nextAppointment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.NEXT_APPOINTMENT') }} </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.nextAppointmentDate ? (element.nextAppointmentDate | date:'dd.MM.yyyy') : '-' }}
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="pageViews">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
          <mat-icon fontSet="material-icons-outlined" [matTooltip]="t('GENERIC_WRAPPER.NUMBER_OF_PAGE_VIEWS')">visibility</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.pageViews?.webViews + element.pageViews?.mobileViews}}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef scope="col"></th>
        <td mat-cell *matCellDef="let element">
          <div class="pr2 flex-row align-center-end">
            <button mat-icon-button (click)="editDanceCourse(element)" matTooltip="{{ t('GENERIC_WRAPPER.EDIT') }}">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); duplicateDanceCourse(element)" matTooltip="{{ t('GENERIC_WRAPPER.DUPLICATE') }}">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button *ngIf="activeCourseChipIndex === 0" mat-icon-button (click)="$event.stopPropagation(); archiveDanceCourse(element, CourseStatusEnum.ARCHIVED)" matTooltip="{{ t('GENERIC_WRAPPER.ARCHIVE') }}">
              <mat-icon>archive</mat-icon>
            </button>
            <button *ngIf="activeCourseChipIndex === 1" mat-icon-button (click)="$event.stopPropagation(); archiveDanceCourse(element, CourseStatusEnum.ACTIVE)" matTooltip="{{ t('GENERIC_WRAPPER.REACTIVATE') }}">
              <mat-icon>public</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); deleteDanceCourse(element)" matTooltip="{{ t('GENERIC_WRAPPER.REMOVE') }}">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="editDanceCourse(row)"></tr>
    </table>
  </div>
</ng-container>
