import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BirthDateFormSettingsComponent } from './birth-date-form-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [BirthDateFormSettingsComponent],
  exports: [BirthDateFormSettingsComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatDatepickerModule,
      MatButtonModule,
      PipesModule,
      MatSlideToggleModule,
      DfSharedButtonModule,
      TranslocoDirective,
    ],

})
export class BirthDateFormSettingsModule {}
