import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule} from "@angular/material/radio";
import {ReactiveFormsModule} from "@angular/forms";
import { MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {TranslocoModule} from "@jsverse/transloco";
import { MatTooltipModule} from "@angular/material/tooltip";
import { ImageCarouselComponent } from './image-carousel.component';

@NgModule({
  declarations: [ImageCarouselComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    MatTooltipModule,
  ],
  exports: [ImageCarouselComponent],
})
export class ImageCarouselModule {}
