import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { UserPassSubscriptionCheckoutCompletePage } from './user-pass-subscription-checkout-complete.page';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfCheckoutCompleteComponent, DfDmFollowContainerModule, DfSharedButtonModule, MultiSwitchCasesModule } from '@platri/dfx-angular-core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [UserPassSubscriptionCheckoutCompletePage],
  imports: [
    CommonModule, 
    MatDividerModule, 
    MatProgressSpinnerModule, 
    DfDmFollowContainerModule,
    DfSharedButtonModule,
    MatButtonModule, 
    MultiSwitchCasesModule, 
    DfCheckoutCompleteComponent,
    TranslocoModule
  ]
})
export class UserPassSubscriptionCheckoutCompletePageModule {}
