import {
  AfterContentInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DfmEditLocationFormInterface } from './dfm-edit-location-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DanceSchoolStateService,
  DfmDanceManagerLocationIdRouterParam,
  dfmDanceManagerRoute,
  DfmDanceManagerUrlNameRouterParam,
  dfmLocationRoute,
  getSubscription,
  LocationHttpService,
  LocationsStateService,
  OpenHoursDialogComponent
} from '@platri/dfx-angular-core';
import {
  AddressInterface,
  AmenityInterface,
  DanceSchoolInterface,
  emailRegex,
  LocationInterface,
  OpeningHoursInterface,
  TimeRangeInterface,
  UpdateLocationRequestDto,
  UserAppLangEnum
} from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dfm-edit-location-form',
  templateUrl: './dfm-edit-location-form.component.html',
  styleUrls: ['./dfm-edit-location-form.component.scss']
})
export class DfmEditLocationFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulEditLocationEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  editLocationFormGroup!: FormGroup<DfmEditLocationFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForEditLocationResponse = false;
  
  editFailedNoConnection = false;

  isLoadingLocation = false;
  hasFailedLoadingLocationNoConnection = false;
  
  loadedLocation: LocationInterface;

  routerParams!: Params;
  locationIdFromParam: string;
  danceManagerUrlNameFromParam: string;
  
  danceManager: DanceSchoolInterface;
  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',];
  currentLang = UserAppLangEnum.DE;
  openingHoursArray: { key: string; value: TimeRangeInterface[] }[] = [];
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationHttpService: LocationHttpService,
    private locationsStateService: LocationsStateService,
    private danceSchoolService: DanceSchoolStateService,
    private matDialog: MatDialog,
    private translocoService: TranslocoService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.loadCurrentDanceManager();
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.editLocationFormGroup = this.createEditLocationFormGroup();
    this.editLocationFormGroup.disable();
  }

  createEditLocationFormGroup(): FormGroup<DfmEditLocationFormInterface> {
    return this.fb.group<DfmEditLocationFormInterface>({
      name: this.fb.control('',  [Validators.required, Validators.maxLength(50)]),
      address: this.fb.control(null,  Validators.required),
      phonePrefix: this.fb.control('+49'),
      phonePrefixAfter: this.fb.control(null),
      phoneNumber: this.fb.control(null),
      email: this.fb.control(null, [Validators.pattern(emailRegex)]),
      openingHours: this.fb.control(null),
      showOpeningHours: this.fb.control(false),
      amenities: this.fb.control(null),
      isPublic: this.fb.control(false),
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.editLocationFormGroup.valueChanges.subscribe(res => {
      this.editFailedNoConnection = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.locationIdFromParam = this.routerParams[DfmDanceManagerLocationIdRouterParam];
    this.danceManagerUrlNameFromParam = this.routerParams[DfmDanceManagerUrlNameRouterParam];
    if (!this.locationIdFromParam) {
      this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmLocationRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoadingLocation = true;
    this.hasFailedLoadingLocationNoConnection = false;
    this.subscriptions.add(
      this.locationHttpService.getLocationById(this.locationIdFromParam, this.danceSchoolService.getSyncCurrentDanceSchool().id).subscribe({
        next: (locationDto) => {
          this.loadedLocation = locationDto;
          this.patchEditLocationFormGroup(locationDto);
          this.isLoadingLocation = false;
          this.editLocationFormGroup.enable();
        },
        error: (err) => {
          this.isLoadingLocation = false;
          if (err.status === 0) {
            this.onLoadLocationConnectionLost();
          }
        }
      })
    );
  }

  patchEditLocationFormGroup(location: LocationInterface): void {
    this.editLocationFormGroup.patchValue(location);
    this.updateMappingOfOpeningHours();
  }
  
  loadCurrentDanceManager(): void {
    this.danceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
  }

  onSubmit(): void {
    if (this.editLocationFormGroup.valid) {
      this.isWaitingForEditLocationResponse = true;
      this.editFailedNoConnection = false;
      const {address, name, openingHours, amenities, showOpeningHours, isPublic, email, phoneNumber, phonePrefix, phonePrefixAfter} = this.editLocationFormGroup.getRawValue();
      const updateLocationRequestDto: UpdateLocationRequestDto = {address, name, openingHours, amenities, showOpeningHours, isPublic, email, phoneNumber, phonePrefix, phonePrefixAfter};
      this.subscriptions.add(this.locationHttpService.updateLocation(this.locationIdFromParam, this.danceSchoolService.getSyncCurrentDanceSchool().id, updateLocationRequestDto).subscribe({
        next: () => {
          this.successfulEditLocationEmitter.next(true);
          this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmLocationRoute]);
          this.isWaitingForEditLocationResponse = false;
        },
        error: (err) => {
          console.log(err);
          if (err.status === 0) {
            this.onConnectionLost();
          }
          this.isWaitingForEditLocationResponse = false;
        }
      }));
    }
  }

  onConnectionLost(): void {
    this.editFailedNoConnection = true;
  }
  
  onLoadLocationConnectionLost(): void {
    this.hasFailedLoadingLocationNoConnection = true;
  }

  onCancel(): void {
    this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmLocationRoute]);
  }

  setSelectedAddress(address?: AddressInterface): void {
    this.editLocationFormGroup.controls.address.setValue(address ?? null);
  }

  onChangeOpeningHours(openingHours: OpeningHoursInterface): void {
    this.editLocationFormGroup.controls.openingHours.patchValue(openingHours);
  }

  openHoursDialog(): void {
    const dialogRef = this.matDialog.open(OpenHoursDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      minHeight: '500px',
      minWidth: '650px',
      data: {
        openingHoursData: this.editLocationFormGroup.controls.openingHours.value ?? null
      }
    });
    dialogRef.afterClosed().subscribe((result: OpeningHoursInterface) => {
      if (result) {
        this.onChangeOpeningHours(result);
        this.editLocationFormGroup.controls.showOpeningHours.setValue(true);
        this.updateMappingOfOpeningHours();
      }
    });
  }

  updateMappingOfOpeningHours(): void {
    this.openingHoursArray = [];
    for (const key in this.editLocationFormGroup.controls.openingHours.value) {
      if (this.editLocationFormGroup.controls.openingHours.value[key]) {
        this.openingHoursArray.push({ key: key, value: this.editLocationFormGroup.controls.openingHours.value[key as keyof OpeningHoursInterface] });
      }
    }
  }

  onChangeAmenities(amenities: AmenityInterface[]): void {
    this.editLocationFormGroup.controls.amenities.patchValue(amenities);
  }
  
  onEmailLinkTextClick(): void {
    this.editLocationFormGroup.patchValue({email: this.danceSchoolService.getSyncCurrentDanceSchool().email});
  }
}
