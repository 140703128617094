<div class="p2">
  <div class="mb-10">
    <div><span class="title bold"> {{ data.title }}</span></div>
  </div>
  <div class="text mb-10">
    <span class="text"> {{ data.infoText }}</span>
  </div>
  <div class="flex-row align-end" style="gap: 10px;">
    <df-shared-lib-button
      *ngIf="data.cancelText"
      [materialButtonStyleType]="'outlined'" 
      [buttonLabel]="data.cancelText"
      (buttonClicked)="close()">
    </df-shared-lib-button>
    
      <df-shared-lib-button
        *ngIf="data.confirmText"
        [materialButtonStyleType]="'flat'"
        [buttonLabel]="data.confirmText"
        (buttonClicked)="confirmAction()"
        >
    </df-shared-lib-button>
  </div>
</div>
