<ng-container *transloco="let t">
  <div class="mat-typography">
    <button class="profile-button" [disableRipple]="true" mat-button [matMenuTriggerFor]="menu"  [ngClass]="{'open': isOpen}" (click)="toggleMenu()" data-cy="header-menu-button">
      <mat-icon class="profile-menu-icon">{{ isOpen ? 'close' : 'menu' }}</mat-icon>
      <div class="profile-circle">
        <img imageFallback style="height: 100%; width: 100%; border-radius: 100%; object-fit: cover; box-shadow: 0 0 20px 1px #ffffff5c;" [src]="currentUser?.imageUrl && currentUser?.imageUrl !== '' ? currentUser.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
      </div>
    </button>
    <mat-menu #menu="matMenu" (closed)="menuClosed()" class="mat-menu-example-2">
      <ng-container *ngIf="currentUser else guestMenu" >
        <button class="greeting" style="margin-bottom: 2px" [routerLink]="['@' + currentUser.username]" routerLinkActive="active-link" mat-menu-item id="username-mat-menu-button">
          <div style="display: flex; flex-direction: row; align-items: center; gap: 10px;">
            <img imageFallback style="height: 30px; width: 30px; border-radius: 100%; object-fit: cover;" [src]="currentUser.imageUrl && currentUser.imageUrl !== '' ? currentUser.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER" />
         <span>{{currentUser.firstName ?? currentUser.username}}</span>
          </div>
       </button> 
        <button style="margin-bottom: 2px" routerLink="inventory" routerLinkActive="active-link" mat-menu-item>
          <mat-icon>inventory_2</mat-icon>
          <span>{{ t('GENERIC_WRAPPER.MY_INVENTORY') }}</span>
        </button> 
        <button style="margin-bottom: 2px" routerLink="appointments" routerLinkActive="active-link" mat-menu-item>
          <mat-icon>calendar_month</mat-icon>
          <span>{{ t('GENERIC_WRAPPER.MY_COURSE_APPOINTMENTS') }}</span>
        </button> 
        <button *ngIf="dmPassSubscriptionFeature" style="margin-bottom: 2px" (click)="openStripeBillingPortalSession();" mat-menu-item>
           <mat-icon>subscriptions</mat-icon>
           <span>{{ t('GENERIC_WRAPPER.MANAGE_SUBSCRIPTIONS')}}</span>
        </button> 
        <button style="margin-bottom: 2px" (click)="openBugReport();" mat-menu-item>
           <mat-icon>bug_report</mat-icon>
           <span>{{t('GENERIC_WRAPPER.REPORT_BUG')}}</span>
        </button>
        <button style="margin-bottom: 2px" (click)="openFeatureRequest();" mat-menu-item>
           <mat-icon>science</mat-icon>
           <span>{{t('GENERIC_WRAPPER.FEATURE_REQUEST')}}</span>
        </button>
        <button style="margin-bottom: 2px" routerLink="settings" routerLinkActive="active-link" mat-menu-item>
           <mat-icon>settings</mat-icon>
           <span>{{t('GENERIC_WRAPPER.SETTINGS')}}</span>
        </button>
        <button id="create-dance-manager-button" style="margin-bottom: 2px" [routerLink]="dfCreateDanceManagerRoute" routerLinkActive="active-link" mat-menu-item>
           <mat-icon>star_rate</mat-icon>
           <span>{{t('GENERIC_WRAPPER.CREATE_DANCE_MANAGER')}}</span>
        </button> 
        <button (click)="onLogout($event)" mat-menu-item>
          <mat-icon>logout</mat-icon>
          <span>{{ t('GENERIC_WRAPPER.LOGOUT') }}</span>
        </button>
      </ng-container>
      <div class="mat-body-2" style="display: flex; flex-direction: column; margin-top: 8px; padding: 0 16px; margin-bottom: 4px">
        <div class="flex-row light-gray-text imprint-links" style="gap: 5px; align-self: center;">
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.danceflavors.com/impressum/">{{t('GENERIC_WRAPPER.IMPRINT')}}</a>
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.danceflavors.com/richtlinien-nutzung/">AGB</a>
          <a style="text-decoration: none; color: inherit;" target="_blank" href="https://www.danceflavors.com/datenschutz/">{{t('GENERIC_WRAPPER.PRIVACY_AND_POLICY')}}</a>
        </div>
      </div>
    </mat-menu>
  </div>
  
  <ng-template #guestMenu>
    <button style="margin-bottom: 2px" [routerLink]="['/', dfLoginRoute]" routerLinkActive="active-link" mat-menu-item id="login-mat-menu-button">
      <mat-icon>login</mat-icon>
      <span>{{t('GENERIC_WRAPPER.LOGIN')}}</span> 
    </button> 
    <button [routerLink]="['/', dfRegisterRoute]" routerLinkActive="active-link" mat-menu-item>
      <mat-icon>person_edit</mat-icon>
      <span>{{ t('GENERIC_WRAPPER.SIGNUP') }}</span>
    </button>
  </ng-template>
</ng-container>
