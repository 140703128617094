import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentProviderEnum } from '@platri/df-common-shared-graphql';
import { TicketOrderService } from '../../services';
import { TicketOrderInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-ticket-checkout-stripe',
  templateUrl: './ticket-checkout-stripe-payment.component.html',
  styleUrls: ['./ticket-checkout-stripe-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCheckoutStripePaymentComponent implements OnInit, OnDestroy {
  @Input() ticketOrder: TicketOrderInterface;

  stripePaymentIntentClientSecret: string;
  stripePaymentAccountId: string | null;
  returnUrl: string;

  constructor(
    public readonly translocoService: TranslocoService,
    private readonly _ticketOrderService: TicketOrderService,
    private readonly _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createStripePaymentIntentToTicketOrder();
  }

  createStripePaymentIntentToTicketOrder(): void {
    this._ticketOrderService.getAsyncTicketOrder().subscribe({
      next: (ticketOrder) => {
        if (
          ticketOrder !== null &&
          // @ts-ignore
          ticketOrder.ticketOrderTransactions?.length > 0 &&
          // @ts-ignore
          ticketOrder.ticketOrderTransactions[0]?.paymentProvider ===
            PaymentProviderEnum.STRIPE
        ) {
          this.stripePaymentIntentClientSecret =
            // @ts-ignore
            ticketOrder.ticketOrderTransactions[0]?.paymentProviderData.client_secret;
          this.stripePaymentAccountId =
            // @ts-ignore
            ticketOrder.ticketOrderTransactions[0]?.paymentProviderAccountId ??
            null;
          this.initReturnUrl();
          this._cd.detectChanges();
        }
      },
    });


    
    if(this.ticketOrder.totalPrice !== 0){
      this._ticketOrderService.updateTicketOrderStripePaymentIntent(
        this.ticketOrder.id
      );
    }
  }

  initReturnUrl(): void {
    this.returnUrl = window.location.href + '-complete/' + this.ticketOrder.id;
  }

  ngOnDestroy(): void {
    /**
     * TODO: Ticket Order Transaction entfernen inklusive Stripe Payment Intent
     */
  }
}
