import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CourseInterface,
  DanceSchoolInterface,
  UserPassSubscriptionInterface,
  UserPassSubscriptionStatusEnum,
  UsersInterface,
  wait
} from '@platri/df-common-core';
import {
  AuthStateService,
  CoursesHttpService,
  DanceManagerHttpService,
  dfAppRoute,
  getSubscription,
  UserPassSubscriptionHttpService
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { Location } from '@angular/common';

@Component({
  selector: 'df-user-price-update-subscription',
  templateUrl: './price-update-subscription.page.html',
  styleUrls: ['./price-update-subscription.page.scss']
})
export class PriceUpdateSubscriptionPage implements OnInit, OnDestroy {
  currentUser: UsersInterface;
  userPassSubscription: UserPassSubscriptionInterface;
  routerParams!: Params;
  subscriptionIdFromParam: string;
  subscriptions = new Subscription();
  isLoading = false;
  hasFailedLoadingNoConnection = false;
  danceManager: DanceSchoolInterface = null;
  
  activePassCourses: CourseInterface[] = [];
  hasTruncateCourses = false;
  isCoursesTruncated = false;
  coursesTruncateLength = 3;
  hasTruncateDescription = false;
  isDescriptionTruncated = false;
  descriptionTruncateLength = 20;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private coursesHttpService: CoursesHttpService,
    private authService: AuthStateService,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.subscriptionIdFromParam = this.routerParams['subscriptionId'];
    if (!this.subscriptionIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoading = true;
    this.hasFailedLoadingNoConnection = false;

    this.subscriptions.add(
      this.userPassSubscriptionHttpService.getUserPassSubscriptionByIdAndCurrentUser(this.subscriptionIdFromParam).subscribe(userPassSubscription => {
        const subscriptionIsTerminated = !userPassSubscription?.newPrice || userPassSubscription?.priceAccepted || userPassSubscription.status === UserPassSubscriptionStatusEnum.TERMINATED;
        if(!userPassSubscription || subscriptionIsTerminated) {
          this.router.navigate(['/inventory'], {fragment: 'subscriptions'}).then(r => {
            if (!userPassSubscription?.newPrice || userPassSubscription?.priceAccepted) {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_PRICE_ALREADY_ACCEPTED'));
            } else if(subscriptionIsTerminated) {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_EXPIRED_TAKE_NEW'));
            } else {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_DOES_NOT_EXIST'));
            }
          });
        }
        this.userPassSubscription = userPassSubscription;
        this.loadOrganizer();

        const targets = this.userPassSubscription.targets;
        const targetIds: string[] = Array.from(new Set(targets?.map(pt => pt.targetId) ?? []));
        this.loadLinkedCourses(targetIds);

        this.hasTruncateDescription = this.userPassSubscription?.description?.split(' ').length > this.descriptionTruncateLength;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        } else {
          this.router.navigate(['/inventory'], {fragment: 'subscriptions'}).then(r => {
            this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_DOES_NOT_EXIST'));
          });
        }
      })
    );
  }

  loadOrganizer(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceManagerById(this.userPassSubscription.danceManagerId).subscribe(organizer => {
        this.danceManager = organizer;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
      })
    );
  }

  loadLinkedCourses(targetIds: string[]): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(targetIds).subscribe(courses => {
        this.activePassCourses = courses;
        this.hasTruncateCourses = this.activePassCourses.length > this.coursesTruncateLength;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  reactivateSubscription(): void {
    this.userPassSubscriptionHttpService.acceptNewSubscriptionPrice(this.userPassSubscription.id, true).subscribe({
      next: () => {
        wait(500).then(() => {
          this.router.navigate(['/inventory'], {fragment: 'subscriptions'}).then(r => {
            this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_PRICE_UPDATE_ACCEPTED'));
          });
        });
      },
      error: () => {
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SOMETHING_WENT_WRONG_TRY_LATER_AGAIN'));
      }
    });
  }

  routeToSubscriptionDetails(): void {
    this.location.back();
  }
}
