import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription, take, takeWhile } from 'rxjs';
import { AuthStateService, CheckoutCompleteErrorTextInterface, CoursesHttpService, DanceEventStateService, dfCourseIdRouterParam, FooterStateService, getSubscription, PurchaseConfirmationTextInterface, TicketOrderHttpService, TicketOrderService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CourseInterface, TicketOrderInterface, TicketOrderStatusEnum, UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-passes-checkout-complete',
  templateUrl: './course-passes-checkout-complete.page.html',
  styleUrls: ['./course-passes-checkout-complete.page.scss']
})
export class CoursePassesCheckoutCompletePage implements OnInit, OnDestroy {
  currentLang: string;
  currentUser: UsersInterface;

  ticketOrder: TicketOrderInterface | null;

  isMobileResponsive: boolean;
  showMobileSummary: boolean;

  subscriptions: Subscription = new Subscription();

  isIntervalActive = false;

  confirmationText: PurchaseConfirmationTextInterface;
  errorText: CheckoutCompleteErrorTextInterface;

  routerParams!: Params;
  courseIdFromParam: string;
  course: CourseInterface;
  readonly ticketOrderStatusEnum = TicketOrderStatusEnum;

  constructor(
    private translocoService: TranslocoService,
    private readonly danceEventService: DanceEventStateService, 
    private readonly ticketOrderService: TicketOrderService, 
    private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    private readonly breakpointObserver: BreakpointObserver, 
    private readonly footerService: FooterStateService, 
    private readonly authService: AuthStateService, 
    private readonly activatedRoute: ActivatedRoute, 
    private readonly coursesHttpService: CoursesHttpService, 
    private ticketOrderHttpService: TicketOrderHttpService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
    this.currentUser = this.authService.getSyncCurrentUser();
    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result: BreakpointState) => {
        this.isMobileResponsive = result.matches;
        this.footerService.showFooter$.next(!result.matches);
        if (this.showMobileSummary && !result.matches) {
          this.showMobileSummary = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.listenOnTicketOrderChanges();
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.courseIdFromParam = this.routerParams[dfCourseIdRouterParam];
    this.loadCourse();
  }

  loadCourse(): void {
    this.subscriptions.add(
      this.coursesHttpService.getById(this.courseIdFromParam).subscribe((course) => {
        if (course) {
          this.course = course;
        }
      })
    );
  }

  private listenOnTicketOrderChanges(): void {
    this.subscriptions.add(
      this.ticketOrderService.getAsyncTicketOrder().subscribe((ticketOrder) => {
        this.ticketOrder = ticketOrder;

        if (ticketOrder && this.ticketOrder.status !== null && this.ticketOrder.status === TicketOrderStatusEnum.PAYMENT_INITIALIZED && !this.isIntervalActive) {
          this.activateIntervalCheckingTicketOrder(this.ticketOrder.id);
        }

        if ([TicketOrderStatusEnum.PAID, TicketOrderStatusEnum.PAYMENT_PENDING].includes(ticketOrder?.status)) {
          this.setConfirmationText(ticketOrder.userPasses[0].trial);
        }

        if ([TicketOrderStatusEnum.PAYMENT_ERROR].includes(ticketOrder?.status)) {
          this.setErrorText();
        }
      })
    );
    this.ticketOrderService.getTicketOrderById(this.route.snapshot.data.targetObject.id);
  }

  private activateIntervalCheckingTicketOrder(ticketOrderId: string): void {
    if (!this.isIntervalActive) {
      this.isIntervalActive = true;
      const timer = interval(3000);

      const takeTwelve = timer.pipe(take(10));

      this.subscriptions.add(
        takeTwelve.pipe(takeWhile(() => this.ticketOrder.status === TicketOrderStatusEnum.PAYMENT_INITIALIZED)).subscribe(() => {
          this.ticketOrderService.getTicketOrderById(ticketOrderId);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.ticketOrderService.clear();
    this.subscriptions.unsubscribe();
  }

  private setConfirmationText(trial = false): void {
    const currentUser = this.authService.getSyncCurrentUser();
    const firstName = currentUser?.username ?? this.translocoService.translate('GENERIC_WRAPPER.GUEST');
    const email = currentUser?.email ?? this.ticketOrder.userEmail;
    this.confirmationText = {
      title: trial ? 'GENERIC_WRAPPER.PASSES_CHECKOUT.TRIAL_PASSES_TITLE' : 'GENERIC_WRAPPER.THANKS_FOR_ORDER',
      message: 'GENERIC_WRAPPER.PASSES_CHECKOUT.' + (currentUser ? 'TEXT' : 'TEXT_GUEST'),
      messageParams: {firstName, email},
      navButton: {
        label: 'GENERIC_WRAPPER.PASSES_CHECKOUT.BUTTON',
        navUrl: '/inventory#passes'
      },
      downloadText: 'GENERIC_WRAPPER.PASSES_CHECKOUT.DOWNLOAD_PDF'
    };
  }

  private setErrorText(): void {
    this.errorText = {
      title: 'GENERIC_WRAPPER.SOMETHING_WENT_WRONG_SHORT',
      text: 'GENERIC_WRAPPER.PASSES_CHECKOUT.ERROR_TEXT',
      navButton: {
        label: 'GENERIC_WRAPPER.PASSES_CHECKOUT.TO_COURSE',
        navUrl: '/dance-class/' + this.route.snapshot.params[dfCourseIdRouterParam]
      }
    };
  }
}
