import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CourseInterface, DanceSchoolInterface, PassTargetInterface, UserPassInterface, UserPassScannedFilterEnum } from '@platri/df-common-core';
import { CoursesHttpService, DanceManagerHttpService, UserPassHttpService, UserTicketHttpService } from '@platri/dfx-angular-core';
import { Router } from '@angular/router';
import { SearchCategoryEnum } from '@platri/df-angular-search';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-inventory-pass-list',
  templateUrl: './inventory-pass-list.component.html',
  styleUrls: ['./inventory-pass-list.component.scss']
})
export class InventoryPassListComponent implements OnInit, OnDestroy {
  activeChipIndex = 0;

  userPassesCollapsed: UserPassInterface[] = [];

  userPassesDanceManagers: DanceSchoolInterface[] = [];
  userPassesCourses: CourseInterface[] = [];
  
  private subscriptions = new Subscription();

  constructor(
    private userTicketHttpService: UserTicketHttpService,
    private translocoService: TranslocoService,
    private router: Router,
    private userPassHttpService: UserPassHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private coursesHttpService: CoursesHttpService,
  ) {}

  ngOnInit(): void {
    this.loadUserPassesOfCurrentUser(UserPassScannedFilterEnum.ACTIVE);
  }

  loadUserPassesOfCurrentUser(status: UserPassScannedFilterEnum): void {
    this.userPassHttpService.getAllByCurrentUserV2(status).subscribe(userPasses => {
      const danceManagerIds: string[] = Array.from(new Set(userPasses?.map(pt => pt.danceManagerId) ?? []));
      this.getUserPassDanceManagers(danceManagerIds);

      const targets = userPasses?.flatMap(pt => pt.targets ?? []);

      const courseIds: string[] = Array.from(new Set(targets.map(target => target.targetId)) ?? []);
      this.getUserPassCourses(courseIds);

      this.userPassesCollapsed = userPasses;
    });
  }

  getUserPassDanceManagers(danceManagerIds: string[]): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceManagersBulkById(danceManagerIds).subscribe( danceManagers => {
        this.userPassesDanceManagers = danceManagers;
      }, error => {
        console.log(error);
      })
    );
  }

  getUserPassCourses(courseIds: string[]): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(courseIds).subscribe(courses => {
        this.userPassesCourses = courses;
      }, error => {
        console.log(error);
      })
    );
  }

  downloadTickets(data: { danceEventId: string; ticketOrderId?: string; freeUserTicketIds?: string[] }): void {
    const ticketWindow = window.open();
    ticketWindow.document.write(`${this.translocoService.translate('USER.TICKETS_LOADING')}...`);

    this.userTicketHttpService
      .downloadTickets(data.danceEventId, data.ticketOrderId, data.freeUserTicketIds)
      .subscribe({
        next: (blob) => {
          const url = URL.createObjectURL(blob);
          ticketWindow.location.href = url;
        },
        error: (err) => {
          console.error(err);
          ticketWindow.close();
        },
      });
  }

  filterUserPassDanceManagersByPassId(danceManagerId: string): DanceSchoolInterface {
    return this.userPassesDanceManagers.find(dm => dm.id === danceManagerId);
  }

  filterUserPassCoursesByPassId(passTargets: PassTargetInterface[]): CourseInterface[] {
    const targetIds: string[] = Array.from(new Set(passTargets.map(target => target.targetId) ?? []));
    return this.userPassesCourses.filter(course => targetIds.includes(course.id));
  }

  toggleChip(value: number): void {
    this.activeChipIndex = value;
    this.loadUserPassesOfCurrentUser(value === 0 ? UserPassScannedFilterEnum.ACTIVE : UserPassScannedFilterEnum.USED);
  }

  navigateToCoursesSearch(): void {   
    this.router.navigate(['/', 'search'], {queryParams: {search_type: SearchCategoryEnum.COURSES}});
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
}
