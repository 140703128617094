import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CURRENT_LANGUAGE } from '../helpers';

@Pipe({
  name: 'centsToCurrencyString',
})
export class CentsToCurrencyStringPipe implements PipeTransform {
  constructor(@Inject(CURRENT_LANGUAGE) private currentLanguage: string) {}
  transform(value: number | string, currency: string = 'USD'): string | null {
    if (value === null) {
      return null;
    }
    
    const dollars = +value / 100;
    const symbolIsAfter = this.currentLanguage === 'DE';
    
    const formattedString = dollars.toLocaleString(currency === 'USD' ? 'en-US' : 'de-DE', {
      style: 'currency',
      currency: currency,
    });
    
    const [numberParts, symbol] = formattedString.trim().split(/\s+/);

    const [integerPart, decimalPart] = numberParts.split(/,/) || [];
    const number = decimalPart && !symbolIsAfter ? `${integerPart}.${decimalPart}` : numberParts;

    return symbolIsAfter
           ? `${number} ${symbol}`
           : `${symbol}${number}`;
  }

  getBrowserLocales(options = {}) {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map((locale) => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/-|_/)[0]
        : trimmedLocale;
    });
  }
}
