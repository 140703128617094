import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientTypeEnum } from '@platri/df-common-core';

@Injectable()
export class CustomHeaderInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('maps.googleapis.com')) {
      return next.handle(request);
    }
    
    request = request.clone({
      setHeaders: {
        'X-Client-Type': ClientTypeEnum.WEB,
      },
    });

    return next.handle(request);
  }

}

export const customHeaderInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CustomHeaderInterceptor, multi: true }
];

