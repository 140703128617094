import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Ticket } from '@platri/df-common-shared-graphql';
import { AuthStateService, DanceEventStateService, LogoAndTitleUploadDialogComponent, SharedCancelDialogComponent } from '@platri/dfx-angular-core';
import { DanceEventsInterface, DEFAULT_EVENT_PICTURE_PLACEHOLDER, LogoAndTitleUploadDialogTypeEnum, UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-event-header',
  templateUrl: './dance-event-header.component.html',
  styleUrls: ['./dance-event-header.component.scss']
})
export class DanceEventHeaderComponent implements OnInit, OnDestroy {
  @Input() danceEvent: DanceEventsInterface;
  @Input() isOwnerOrAdmin: boolean;
  @Input() tickets: Ticket[];
  subscription: Subscription = new Subscription();
  currentUser: UsersInterface;
  
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;

  constructor(
    private readonly dialog: MatDialog,
    private readonly authService: AuthStateService, 
    private readonly translocoService: TranslocoService, 
    private readonly danceEventService: DanceEventStateService
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  openTitleDialog(): void {
    const dialogRef = this.dialog.open(LogoAndTitleUploadDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      width: '100%',
      maxWidth: '1140px',
      data: {
        id: this.danceEvent.id,
        mode: LogoAndTitleUploadDialogTypeEnum.EVENT_TITLE
      }
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.danceEvent.mainImage.url = result.mainImage.url;
        }
      })
    );
  }

  confirmDelete(): void {
    const cancelDialogRef = this.dialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COVER_PICTURE'),
        subtitle: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COVER_PICTURE_SUBTITLE'),
        infoText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
        cancelText: this.translocoService.translate('GENERIC_WRAPPER.DELETE')
      }
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.deleteMainImage();
      }
    });
  }

  deleteMainImage() {
    this.danceEventService.deleteMainImageById(this.danceEvent.id).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
