import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './phone-input.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { MaterialPhonePrefixDropdownModule } from '../../material-phone-prefix-dropdown';

@NgModule({
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MaterialPhonePrefixDropdownModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    ReactiveFormsModule,
  ],
})
export class PhoneInputModule {}
