import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CourseDto, DanceManagerPassesPublicResponseDto, UsersInterface } from '@platri/df-common-core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'df-monorepo-dance-manager-passes-list-item',
  templateUrl: './dance-manager-passes-list-item.component.html',
  styleUrls: ['./dance-manager-passes-list-item.component.scss']
})
export class DanceManagerPassesListItemComponent implements OnChanges {
  @Input() pass: DanceManagerPassesPublicResponseDto;
  @Input() isOwnerOrAdmin: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() loadedCourses: { [key: string]: CourseDto } = {};
  @Input() isLoading: boolean;
  @Input() danceManagerUrlName: string;
  @Input() currentUser: UsersInterface;
  @Input() isCreatingTicketOrder = false;
  
  @Output() routeToPassEdit: EventEmitter<string> = new EventEmitter<string>();
  @Output() routeToPassBuyout: EventEmitter<DanceManagerPassesPublicResponseDto> = new EventEmitter<DanceManagerPassesPublicResponseDto>();

  hasTruncateLength = false;
  isDescriptionTruncated = false;
  descriptionTruncateLength = 20;
  
  hasTruncateElements = false;
  isCoursesTruncated = false;
  coursesTruncateLength = 3;
  
  ngOnChanges(changes: SimpleChanges): void {
    this.hasTruncateLength = this.pass?.description?.split(' ').length > this.descriptionTruncateLength;
    this.hasTruncateElements = this.pass?.passTargets?.length > this.coursesTruncateLength;
  }

  get currentQuantity(): AbstractControl<number> {
    return this.formGroup.get(this.pass?.id);
  }

  get currentQuantityCanBeIncreased(): boolean {
    const currentValue = +this.currentQuantity.value;
    return (currentValue < this.pass.availableQuantity) && (currentValue < 10);
  }
  
  changeValue(number: number): void {
    const currentValue = +this.currentQuantity.value;
    if ((number === 1 && this.currentQuantityCanBeIncreased) || (number === -1 && currentValue >= 1)) {
      this.formGroup.get(this.pass?.id).setValue(currentValue + number);
    }
  }
  
  emitRouteToPassBuyout(): void {
    this.routeToPassBuyout.emit(this.pass);
  }
  
  emitRouteToPassEdit(): void {
    this.routeToPassEdit.emit(this.pass.id);
  }
}
