import { ImageUploadCropperModule, PipesModule, ShareModule } from '@platri/dfx-angular-core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { DanceEventHeaderComponent } from './dance-event-header.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceEventHeaderComponent],
  exports: [DanceEventHeaderComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    ShareModule,
    ImageUploadCropperModule,
    PipesModule,
    MatListModule,
    RouterModule,
    TranslocoModule
  ],
  providers: []
})
export class DanceEventHeaderModule {}
