<div class="statistics-card" >
  <div class="flex-column-center-center gap-10 container w-100 h-100">
    <div class="flex-row align-space-between w-100 title-container">
      <span class="title">{{ cardTitle }}</span>
      <button mat-icon-button (click)="removeCardEvent.emit()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    
    <mat-divider></mat-divider>
    <div class="statistics-container">
      <df-shared-lib-statistics-box
        class="tickets-box"
        [icon]="leftStatBox.icon"
        [StatBoxTitle]="leftStatBox.title"
        [StatBoxMainStats]="leftStatBox.mainStats"
        [StatBoxSubTitle]="leftStatBox.subTitle"
        [StatBoxSubStats]="leftStatBox.subStats"
        [indicatorTitles]="indicatorTitles"
        [showSubStats]="showSubStats">
      </df-shared-lib-statistics-box>
      <mat-divider vertical class="hide-on-mobile"></mat-divider>
      <mat-divider class="hide-on-web"></mat-divider>
      <df-shared-lib-statistics-box
        class="checked-in-box"
        [icon]="rightStatBox.icon"
        [StatBoxTitle]="rightStatBox.title"
        [StatBoxMainStats]="rightStatBox.mainStats"
        [StatBoxSubTitle]="rightStatBox.subTitle"
        [StatBoxSubStats]="rightStatBox.subStats"
        [indicatorTitles]="indicatorTitles"
        [showSubStats]="showSubStats">
      </df-shared-lib-statistics-box>
    </div>
  </div>
</div>
