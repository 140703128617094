import { AddressInterface } from './address.interface';
import { AmenityInterface } from './amenity.interface';
import { RoomInterface } from './room.interface';
import { ImageInterface } from './image.interface';
import { OpeningHoursInterface } from './opening-hours.interface';
import { DanceManagerInterface } from './dance-manager';
import { RadiusUnitEnum } from '../enums';

export interface LocationInterface {
  id?: string;
  name?: string;
  address?: AddressInterface;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  email?: string;
  amenities?: AmenityInterface[];
  openingHours?: OpeningHoursInterface;
  showOpeningHours?: boolean;
  rooms?: RoomInterface[];
  danceSchool?: DanceManagerInterface;
  images?: ImageInterface[];
  previewImage?: ImageInterface;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  isPublic?: boolean;
  danceManagerId?: string;
}
