<ng-container *transloco="let t">
  <div class="flex-column" style="margin-top: 20px; gap: 10px;" [formGroup]="formGroup">
    <span>
      <strong>{{ t(label) }}</strong>
    </span>
    <span *ngIf="showQuickLinkAddPhone" class="blue-clickable-span" (click)="loadPhoneNumberQuickLink()">
      {{ t('GENERIC_WRAPPER.PHONE_INPUT.PHONE_LINK') }}<strong>{{(quickLinkPhonePrefix ? ' (' + quickLinkPhonePrefix + ') ' : '') + (quickLinkPhoneNumber ?? '') + (quickLinkPhonePrefixAfter ? ' ' + quickLinkPhonePrefixAfter : '')}}</strong>
    </span>
    
    <div class="flex-row-wrap" style="gap: 10px;">
      <df-shared-lib-material-phone-prefix-dropdown
        [phonePrefix]="formGroup.controls.phonePrefix.value"
        [phonePrefixAfter]="formGroup.controls.phonePrefixAfter.value"
        (phonePrefixSelected)="onPhonePrefixSelected($event)"
      ></df-shared-lib-material-phone-prefix-dropdown>
      <mat-form-field style="flex: auto" appearance="outline" class="limitWidthOnMobile" data-cy="number-field">
        <mat-label>{{ t(placeholder) }}</mat-label>
        <input
          type="text"
          matInput
          autocomplete="off"
          formControlName="phoneNumber"
        >
        <button
          *ngIf="showSuffix"
          mat-icon-button
          matSuffix
          type="button"
          (click)="loadPhoneNumberQuickLink()"
        >
          <mat-icon>business</mat-icon>
        </button>
        <mat-hint *ngIf="showSuffix">{{t('GENERIC_WRAPPER.PHONE_INPUT.PHONE_HINT')}}</mat-hint>
        <mat-error *ngIf="phoneNumberCtrl.hasError('required')">
          {{ t('GENERIC_WRAPPER.PHONE_INPUT.ERROR.REQUIRED') }}
        </mat-error>
        <mat-error *ngIf="phoneNumberCtrl.hasError('pattern')">
          {{ t('GENERIC_WRAPPER.PHONE_INPUT.ERROR.ONLY_DIGITS') }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-container>
