import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoursesStateService, DanceEventStateService, DanceSchoolHttpService } from '../../services';
import { LogoAndTitleUploadDialogTypeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-logo-and-title-upload-dialog',
  templateUrl: './logo-and-title-upload-dialog.component.html',
  styleUrls: ['./logo-and-title-upload-dialog.component.scss']
})
export class LogoAndTitleUploadDialogComponent implements OnDestroy{
  subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      mode: LogoAndTitleUploadDialogTypeEnum;
      ratio: number;
    },
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly danceEventService: DanceEventStateService,
    public readonly coursesService: CoursesStateService,
    private readonly matDialogRef: MatDialogRef<LogoAndTitleUploadDialogComponent>
  ) {}

  upload(formData: FormData): void {
    switch (this.data.mode) {
      case LogoAndTitleUploadDialogTypeEnum.STUDIO_LOGO:
        this.uploadStudioLogo(formData);
        break;
      case LogoAndTitleUploadDialogTypeEnum.STUDIO_TITLE:
        this.uploadStudioTitleImage(formData);
        break;
      case LogoAndTitleUploadDialogTypeEnum.EVENT_TITLE:
        this.uploadEventTitleImage(formData);
        break;
      case LogoAndTitleUploadDialogTypeEnum.COURSE_TITLE:
        this.uploadCourseTitleImage(formData);
        break;
    }
  }

  uploadStudioLogo(formData: FormData): void {
    this.subscription.add(
      this.danceSchoolHttpService.uploadLogoByDanceSchoolId(this.data.id, formData).subscribe((updatedDanceSchool) => {
        if (updatedDanceSchool) {
          this.matDialogRef.close(updatedDanceSchool);
        }
      })
    );
  }

  uploadStudioTitleImage(formData: FormData): void {
    this.subscription.add(
      this.danceSchoolHttpService.uploadTitleByDanceSchoolId(this.data.id, formData).subscribe((updatedDanceSchool) => {
        if (updatedDanceSchool) {
          this.matDialogRef.close(updatedDanceSchool);
        }
      })
    );
  }

  uploadEventTitleImage(formData: FormData): void {    
    this.subscription.add(
      this.danceEventService.updateMainImageById(this.data.id, formData).subscribe((updatedDanceSchool) => {
        if (updatedDanceSchool) {
          this.matDialogRef.close(updatedDanceSchool);
        }
      })
    );
  }

  uploadCourseTitleImage(formData: FormData): void {
    this.subscription.add(
      this.coursesService.updateMainImageById(this.data.id, formData).subscribe((updatedCourse) => {
        this.matDialogRef.close(updatedCourse);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
