import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { DanceEventsInterface } from '@platri/df-common-core';
import { DanceEventHttpService, DanceEventStateService } from '../services';
import { dfDanceEventIdRouterParam } from '../shared';

@Injectable({ providedIn: 'root' })
export class DanceEventResolver  {
  constructor(private readonly danceEventHttpService: DanceEventHttpService, private readonly router: Router, private readonly danceEventService: DanceEventStateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DanceEventsInterface> | Promise<DanceEventsInterface> | DanceEventsInterface {
    const searchDanceEventId = route.paramMap.get(dfDanceEventIdRouterParam);
    return this.danceEventHttpService.getById(searchDanceEventId,true).pipe(
      tap((res) => {
        this.danceEventService.sendDanceEvent(res);
        return res;
      }),
      catchError((error) => {
        this.router.navigateByUrl('/404');
        return EMPTY;
      })
    );
  }
}
