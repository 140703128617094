import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DanceManagerSubscriptionTypeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-dm-packages-card-item',
  templateUrl: './dm-packages-card-item.component.html',
  styleUrls: ['./dm-packages-card-item.component.scss'],
})
export class DmPackagesCardItemComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() imgUrl: string;
  @Input() showToggleForAction = false;
  @Input() isFeatureActive = false;
  @Input() isFeatureComingSoon = false;
  @Input() danceManagerSubscriptionType: DanceManagerSubscriptionTypeEnum = DanceManagerSubscriptionTypeEnum.FREE;

  @Output() toggleClickedEvent = new EventEmitter<MatSlideToggleChange>();

  protected readonly DanceManagerSubscriptionTypeEnum = DanceManagerSubscriptionTypeEnum;
}
