<ng-container *transloco="let t">
  <button mat-icon-button [matMenuTriggerFor]="menu" [matTooltip]="t('GENERIC_WRAPPER.LANGUAGE')" (menuClosed)="isMenuOpen = false" (menuOpened)="isMenuOpen = true">
    <mat-icon [style.color]="isMenuOpen ? '#3377FF' : '#4D4D4D'">language</mat-icon>
  </button>
  <mat-menu #menu="matMenu" (closed)="isMenuOpen = false" class="mat-menu-example-3">
    <ng-container style="padding: 8px;">
      <button *ngFor="let lang of translocoService.getAvailableLangs()" style="margin-bottom: 2px; border-radius: 99999px !important;" (click)="changeAndSaveLanguage(lang)" mat-menu-item value="lang">
        <span class="align-center">{{ t('GENERIC_WRAPPER.PROFILE_LANGUAGE.' + lang.toUpperCase()) }}</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
