import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { DfDanceStyleSearchInputComponent } from './df-dance-style-search-input.component';
import { SliderGradientModule } from '../slider-gradient';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfDanceStyleSearchInputComponent],
    imports: [
      CommonModule,
      SliderGradientModule,
      MatCheckboxModule,
      ReactiveFormsModule,
      FormsModule,
      MatFormFieldModule,
      MatAutocompleteModule,
      MatInputModule,
      TranslocoDirective,
    ],
  exports: [DfDanceStyleSearchInputComponent],
})
export class DfDanceStyleSearchInputModule {}
