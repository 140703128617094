import {
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import {
  AppointmentParticipantDto,
  AppointmentParticipantStatusEnum,
  CourseAppointmentStatusEnum,
  CourseStatusEnum,
  UsersInterface
} from '@platri/df-common-core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthStateService } from '@platri/dfx-angular-core';

@Component({
  selector: 'df-user-appointments-table',
  templateUrl: './appointments-table.component.html',
  styleUrls: ['./appointments-table.component.scss']
})
export class AppointmentsTableComponent implements OnInit, OnChanges{
  @Input() appointments: AppointmentParticipantDto[] = [];
  @Input() selectedCourseStatus: CourseAppointmentStatusEnum = CourseAppointmentStatusEnum.UPCOMING;
  @Output() filterChangeEmitter: EventEmitter<CourseAppointmentStatusEnum> = new EventEmitter<CourseAppointmentStatusEnum>;
  @Output() checkOutEmitter: EventEmitter<string> = new EventEmitter<string>;

  displayedColumns = ['courseName', 'date', 'status', 'danceManagerName', 'actions'];
  dataSource: MatTableDataSource<AppointmentParticipantDto>;

  currentUser: UsersInterface;
  
  constructor(
    private readonly authStateService: AuthStateService
  ) {
    this.currentUser = this.authStateService.getSyncCurrentUser();
  }
  
  ngOnInit(): void {
    this.updateData();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appointments) {
      this.updateData();
    }
  }

  updateData(): void {
    this.dataSource = new MatTableDataSource(this.appointments);
  }

  toggleCourseChip(value: number): void {
    if (value === 0) {
      this.filterChangeEmitter.emit(CourseAppointmentStatusEnum.UPCOMING);
    } else if (value === 1) {
      this.filterChangeEmitter.emit(CourseAppointmentStatusEnum.PAST);
    } else {
      console.log(`"${value}" is not implemented in appointments-table component when switching filter chips.`);
    }
  }

  checkOutAppointment(appointment: AppointmentParticipantDto): void {
    this.checkOutEmitter.emit(appointment.id);
  }

  protected readonly CourseAppointmentStatusEnum = CourseAppointmentStatusEnum;
  protected readonly AppointmentParticipantDto = AppointmentParticipantDto;
  protected readonly AppointmentParticipantStatusEnum = AppointmentParticipantStatusEnum;
}
