import { DanceStyleDto } from '../../..';
import { DpsPostingsToDanceStylesInterface } from '../../..';
import { DancePartnerSearchPostingDto } from './dance-partner-search-posting.dto';

export class DpsPostingToDanceStyleDto implements DpsPostingsToDanceStylesInterface {
  dancePartnerSearchPosting: DancePartnerSearchPostingDto;
  danceStyle: DanceStyleDto;
  experienceFrom: number;
  experienceTo: number;
}
