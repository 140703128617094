import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassCheckoutListSummaryComponent } from './pass-checkout-list-summary.component';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../pipes';
import { MatDividerModule } from '@angular/material/divider';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [PassCheckoutListSummaryComponent],
  exports: [PassCheckoutListSummaryComponent],
  imports: [
    CommonModule,
    MatIconModule,
    PipesModule,
    MatDividerModule,
    TranslocoDirective
  ]
})
export class PassCheckoutListSummaryModule {}
