import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AppointmentParticipantInterface,
  ConfirmationDialogInterface,
  CourseAppointmentInterface,
  UserPassInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  environmentForWeb, AppointmentParticipantStatusEnum
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';
import {
  AppointmentParticipantsHttpService,
  AuthStateService, TicketCoursesHttpService
} from '../../services';
import { InfoDialogComponent } from '../info-dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectCheckInPassDialogComponent } from '../select-check-in-pass-dialog';

@Component({
  selector: 'df-dance-course-appointment-card',
  templateUrl: './dance-course-appointment-card.component.html',
  styleUrls: ['./dance-course-appointment-card.component.scss']
})
export class DanceCourseAppointmentCardComponent implements OnInit {
  showAppointmentDescription = false;
  currentLang: string;
  freeSpots: number | null;
  // @ts-ignore
  @Input() appointment: CourseAppointmentInterface;
  @Input() courseId: string;
  @Input() danceManagerId: string;
  @Input() isCheckInActive: boolean;
  @Input() isUserEnrolled: boolean;
  @Input() isOwnerOrAdmin = false;
  @Output() cardLoaded: EventEmitter<void> = new EventEmitter<void>();
  
  appointmentParticipant: AppointmentParticipantInterface;
  openForParticipation = false;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  private subscriptions = new Subscription();
  
  featureParticipate = environmentForWeb.participateClasses;
  
  constructor(
    private matDialog: MatDialog, 
    private translocoService: TranslocoService, 
    private authStateService: AuthStateService, 
    private appointmentParticipantsHttpService: AppointmentParticipantsHttpService,
    private ticketCoursesHttpService: TicketCoursesHttpService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    this.cardLoaded.emit();
    this.freeSpots = this.appointment.maxParticipants ? this.appointment.maxParticipants - this.appointment.participants?.filter(obj => obj.status !== AppointmentParticipantStatusEnum.NON_REGISTERED_SCANNED)?.length : null;
    this.appointmentParticipant = this.appointment.participants?.find((obj) => obj.userId === this.authStateService.getSyncCurrentUser().id);
    this.openForParticipation = this.isMoreThanThreeDaysAway(this.appointment.date);
  }
  
  isMoreThanThreeDaysAway(appointmentDate: Date): boolean {
    const diffInMs = new Date(appointmentDate).getTime() - new Date().getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays > 3;
  }

  participate(): void {
    if (this.isMoreThanThreeDaysAway(this.appointment.date)) {
      this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.APPOINTMENT_MORE_THAN_THREE_DAYS'));
    } else if (this.isLoggedIn()) {
      this.ticketCoursesHttpService.getAvailableUserPasses(this.courseId, this.appointment.date).subscribe({
        next: (userPasses) => {
          if (!userPasses || userPasses.length === 0) {
            this.openNoValidAccessDialog();
          } else if(userPasses.length === 1) {
            this.openSubmitDialog(userPasses[0]);
          } else {
            this.openSelectPassDialog(userPasses);
          }
        },
        error: (err) => this.handleError(err)
      });
    }
  }

  private handleError(err): void {
    if (err.status === 409) {
      this.openNoValidAccessDialog();
    } else {
      this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SOMETHING_WENT_WRONG'));
    }
  }

  openSelectPassDialog(userPasses: UserPassInterface[]): void {
    this.subscriptions.add(
      this.matDialog.open(SelectCheckInPassDialogComponent, {panelClass: 'responsive-dialog', data: { userPasses: userPasses }})
        .afterClosed().subscribe(result => {
          if(result?.result) this.checkInWithPass(result.selectedUserPass);
      })
    );
  }
  
  openSubmitDialog(userPass: UserPassInterface): void {
    const data: ConfirmationDialogInterface = {
      text: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.PARTICIPATE_DIALOG.TEXT'),
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: 'primary',
      cancelButtonColor: 'warn',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.PARTICIPATE_DIALOG.PARTICIPATE')
    };
    this.subscriptions.add(
      this.matDialog.open(ConfirmationDialogComponent, {data})
        .afterClosed().subscribe(result => {
            if (result) this.checkInWithPass(userPass);
        })
    );
  }
  
  checkInWithPass(userPass: UserPassInterface): void {
    const successfulText: string = this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.PARTICIPATE_DIALOG.SUCCESS_TEXT');

    this.appointmentParticipantsHttpService.createHttp(this.appointment.id, userPass.id).subscribe({
      next: (appointmentParticipant) => {
        this.appointmentParticipant = appointmentParticipant;
        this.freeSpots = this.freeSpots - 1;
        this.matSnackBar.open(successfulText);
      },
      error: (err) => this.handleError(err)
    });
  }
  
  openNoValidAccessDialog(): void {
    this.matDialog.open(InfoDialogComponent, {
      width: '400px',
      data: {
        title: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.NO_VALID_ACCESS.TITLE'),
        description: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.NO_VALID_ACCESS.TEXT'),
        buttonText: this.translocoService.translate('GENERIC_WRAPPER.CONTINUE'),
      }
    });
  }
  
  removeParticipation(): void {
    if (this.isLoggedIn()) {
      this.appointmentParticipantsHttpService.deleteHttp(this.appointmentParticipant.id).subscribe({
        next: () => {
          this.appointmentParticipant = null;
          this.freeSpots = this.freeSpots + 1;
        },
        error: (err) => {
          if (err.status === 409) {
            this.matDialog.open(InfoDialogComponent, {
              width: '400px',
              data: {
                title: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.APPOINTMENT_UNDER_3_HOURS.TITLE'),
                description: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.APPOINTMENT_UNDER_3_HOURS.TEXT'),
                buttonText: this.translocoService.translate('GENERIC_WRAPPER.CONTINUE'),
              }
            });
          }
        }
      });
    }
  }
  
  isLoggedIn(): boolean {
    if (!this.authStateService.getSyncCurrentUser()) {
      this.authStateService.routeToLogin(this.router.url);
      return false;
    }
    return true;
  }

  openGoogleMapsInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  routeToAppointmentParticipantList(): void {
    if (this.isOwnerOrAdmin) {
      this.router.navigate([`/dance-manager/${this.danceManagerId}/courses/preview/${this.courseId}/appointment/${this.appointment.id}`]);
    }
  }
}
