import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DanceManagerUserRoleEnum, DanceSchoolToUserDto, DanceSchoolToUserInterface, environmentForWeb } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DanceSchoolToUserHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-schools`;

  constructor(private readonly http: HttpClient) {}

  //TODO: Change Interface to DTO
  updateDanceSchoolToUsers(
    danceSchoolToUser: DanceSchoolToUserInterface
  ): Observable<DanceSchoolToUserInterface> {
    return this.http.put<DanceSchoolToUserInterface>(
      `${this.SERVICE_URL}/${danceSchoolToUser.danceSchoolId}/users/${danceSchoolToUser.id}`,
      {
        userId: danceSchoolToUser.user.id,
        danceSchoolUserRole: danceSchoolToUser.danceSchoolUserRole,
        isPublic: danceSchoolToUser.isPublic
      }
    );
  }

  //TODO: Change Interface to DTO
  deleteDanceSchoolToUsers(
    danceSchoolToUser: DanceSchoolToUserInterface
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.SERVICE_URL}/${danceSchoolToUser.danceSchoolId}/users/${danceSchoolToUser.id}`
    );
  }
  
  createDanceSchoolToUsers(
    danceSchoolId: string,
    userId: string,
    danceSchoolUserRole: DanceManagerUserRoleEnum
  ): Observable<DanceSchoolToUserDto> {
    return this.http.post<DanceSchoolToUserDto>(
      `${this.SERVICE_URL}/${danceSchoolId}/users`,
      {
        userId,
        danceSchoolUserRole,
      }
    );
  }
}
