import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appConvertLink]'
})
export class ConvertLinkDirective implements OnChanges {
  @Input() appConvertLink: string;

  constructor(private el: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    if (this.appConvertLink) {
      this.convertLinks();
    }
  }

  private convertLinks(): void {
    const text = this.appConvertLink;
    const replacedText = text.replace(/(https?:\/\/[^\s]+)/g, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    // Hier wird das sanitisierte HTML direkt ins Element eingefügt
    this.el.nativeElement.innerHTML = this.sanitizer.bypassSecurityTrustHtml(replacedText) as any;
  }
}
