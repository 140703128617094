import { Pipe, PipeTransform } from '@angular/core';
import { FeesPaidByEnum } from '@platri/df-common-core';

@Pipe({
  name: 'ticketFeeCalculate',
})
export class TicketFeeCalculatePipe implements PipeTransform {
  transform(price: number, feesPaidBy: FeesPaidByEnum): number {
    if (feesPaidBy === FeesPaidByEnum.CUSTOMER) {
      //todo: Class not found
      //return Math.round((+price + +calculateFeeForTicketPrice(price, feesPaidBy) + Number.EPSILON) * 100) / 100;
      return 0;
    } else {
      return price;
    }
  }
}
