import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatIconModule } from '@angular/material/icon';
import { DmSubscriptionPaymentHistoryCardComponent } from './dm-subscription-payment-history-card.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SharedCancelDialogModule } from '../cancel-dialog';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DmSubscriptionPaymentHistoryCardComponent],
    imports: [
      CommonModule,
      MatChipsModule,
      MatDividerModule,
      ReactiveFormsModule,
      PipesModule,
      MatProgressSpinnerModule,
      SharedCancelDialogModule,
      MatIconModule,
      MatTableModule,
      MatSortModule,
      PipesModule,
      TranslocoDirective,
    ],
  exports: [DmSubscriptionPaymentHistoryCardComponent],
})
export class DmSubscriptionPaymentHistoryCardModule {}
