import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DanceManagerSubscriptionStatus, DanceManagerSubscriptionType } from '@platri/df-common-shared-graphql';
import { DanceManagerService, DanceSchoolStateService } from '../../services';
import { DanceManagerInterface, DanceManagerSubscriptionInterface, environmentForWeb } from '@platri/df-common-core';
import { getSubscription } from '../../helpers';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dm-subscription-card',
  templateUrl: './dm-subscription-card.component.html',
  styleUrls: ['./dm-subscription-card.component.scss'],
})
export class DmSubscriptionCardComponent implements OnInit, OnDestroy {
  danceManagerSubscriptionType = DanceManagerSubscriptionType;
  danceManagerSubscriptionStatus = DanceManagerSubscriptionStatus;

  danceManager: DanceManagerInterface;
  stripePublishableKey = environmentForWeb.stripePublishableKey;
  stripePricingTableId: string;
  @Input() showPaymentHistory = false;
  @Output() showPaymentHistoryEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscription = new Subscription();

  constructor(
    public readonly danceManagerService: DanceManagerService,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly translocoService: TranslocoService
  ) {
    this.stripePricingTableId =
      this.translocoService.getActiveLang().toUpperCase() === 'DE'
        ? environmentForWeb.stripePricingTableIdGerman
        : environmentForWeb.stripePricingTableIdEnglish;
  }

  ngOnInit(): void {
    this.subscription.add(
      getSubscription(
        this.danceSchoolService.getAsyncCurrentDanceSchool(),
        this.onDanceManagerChange.bind(this)
      )
    );
  }

  onDanceManagerChange(danceManager: DanceManagerInterface): void {
    this.danceManager = danceManager;
    this.danceManagerService.getLatestSubscriptionByDanceManagerId(
      danceManager.id
    );
  }

  onCancelSubscriptionClick(
    danceManagerSubscription: DanceManagerSubscriptionInterface
  ): void {
    this.danceManagerService.cancelSubscriptionByDanceManagerIdAndDanceManagerSubscriptionId(
      this.danceManager.id,
      danceManagerSubscription.id
    );
  }

  onShowPaymentHistoryClick(showPaymentHistory: boolean): void {
    this.showPaymentHistory = showPaymentHistory;
    this.showPaymentHistoryEmitter.next(showPaymentHistory);
  }

  ngOnDestroy(): void {
    this.danceManagerService.sendClearLatestDanceManagerSubscription();
    this.subscription.unsubscribe();
  }
}
