<div class="link flex-row">
  <a href="tel:{{ phone.prefix }}{{ phone.prefixAfter }}{{ phone.number }}">
    <div class="flex-row">
      <mat-icon class="icon">phone</mat-icon>
      <div>
        <p class="text text-link">
          {{ phone.prefix }} {{ phone.prefixAfter }} {{ phone.number }}
        </p>
      </div>
    </div>
  </a>
</div>
