import {DanceManagerCategoryEnum, RadiusUnitEnum} from "@platri/df-common-core";

export interface SearchDanceSchoolsFilterInterface{
    search?: string;
    danceStyles?: string[];
    longitude?: number;
    latitude?: number;
    radius?: number;
    radiusUnit?: RadiusUnitEnum;
    categories?: DanceManagerCategoryEnum[];
}
