import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DanceEventPreviewCardModule, DmEventPreviewCardModule, PaginatorModule, SharedCancelDialogModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreateDanceEventStepperPage } from './create-dance-event-stepper.page';
import { MatStepperModule } from '@angular/material/stepper';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { DfmEventGuestListTableModule } from '../../components';
import { CreateDanceEventInfoGeneralPageModule } from '../create-dance-event-info-general-page';
import { CreateDanceEventAppointmentLocationPageModule } from '../create-dance-event-appointment-location-page';
import { CreateDanceEventRulesPageModule } from '../create-dance-event-rules-page';

@NgModule({
  declarations: [CreateDanceEventStepperPage],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    PaginatorModule,
    TranslocoModule,
    MatTooltipModule,
    MatTabsModule,
    DanceEventPreviewCardModule,
    DmEventPreviewCardModule,
    DfmEventGuestListTableModule,
    RouterModule,
    MatStepperModule,
    CreateDanceEventInfoGeneralPageModule,
    CreateDanceEventAppointmentLocationPageModule,
    CreateDanceEventRulesPageModule,
    SharedCancelDialogModule,
    BreadcrumbComponent, 
    BreadcrumbItemDirective
  ],
})
export class CreateDanceEventStepperPageModule {}
