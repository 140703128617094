<ng-container *transloco="let t">
  <div class="w-100 h-100 p2 mt4-lt-sm align-items">
    <!-- No TicketOrder -->
    <div *ngIf="!ticketOrder" class="w-100 h-100 flex-column">
      <div
        class="pt1 align-space-between hide-on-web"
        style="font-size: 20px"
      >
        <span>
          <strong>{{ t('GENERIC_WRAPPER.ORDER_SUMMARY') }}</strong>
        </span>
        <span class="close-btn" (click)="close()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
      <div class="w-100 h-100 flex-column-center-center">
        <span><mat-icon class="shopping-cart-icon">shopping_cart</mat-icon></span>
        <span>{{ t('GENERIC_WRAPPER.YOUR_ORDER_SUMMARY') }}</span>
        <span>{{ t('GENERIC_WRAPPER.WILL_APPEAR_HERE') }}</span>
      </div>
    </div>
  
    <!-- TicketOrder -->
    <div *ngIf="ticketOrder" class="w-100 flex-column" style="gap: 0.5rem;">
      <!-- Header -->
      <div class="pt1 align-space-between" style="font-size: 20px">
        <span
          ><b>{{ t('GENERIC_WRAPPER.ORDER_SUMMARY') }}</b></span
        >
        <span class="close-btn hide-on-web" (click)="close()"
          ><mat-icon>close</mat-icon></span
        >
      </div>
  
      <!-- Content -->
      <div
        *ngFor="let ticket of summarizedTickets"
        class="w-100 flex-row align-space-between"
        style="flex: 1 1 100%;"
      >
        <div class="ticket-name-container-order-summary">
          <span
          >{{ ticket.ticketName }}
            {{
            ticket.ticketDanceRole
              ? '(' + t('GENERIC_WRAPPER.' + ticket.ticketDanceRole) + ')'
              : ''
            }}</span>
        </div>
        <span
          >{{ ticket.quantity }} x
          {{ ticket.ticketPrice | centsToCurrencyString: 'EUR' }}</span
        >
      </div>
      <mat-divider></mat-divider>
      <div *ngIf="ticketOrder.customerApplicationFee > 0" class="flex-row-wrap-space-between">
        <span>Gebühr:</span>
        <span>{{ ticketOrder.customerApplicationFee | centsToCurrencyString: 'EUR'}}</span>
      </div>
      <mat-divider *ngIf="ticketOrder.customerApplicationFee > 0"></mat-divider>
      <div class="flex-row align-space-between">
        <strong>{{ t('GENERIC_WRAPPER.TOTAL') }}</strong>
        <strong>{{
          this.ticketOrder.totalPrice | centsToCurrencyString: 'EUR'
        }}</strong>
      </div>
    </div>
  </div>
</ng-container>
