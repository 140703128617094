import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/ban-types
export function getSubscription(observable: Observable<any>, nextFunction: Function, errorFunction?: Function, completeFunction?: Function): any {
  return observable.subscribe({
    next: (res) => nextFunction(res),
    error: (err) => {
      if (errorFunction) {
        errorFunction(err);
      } else {
      }
    },
    complete: () => {
      if (completeFunction) {
        completeFunction();
      } else {
        console.log('Abgeschlossen');
      }
    },
  });
}
