import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogInterface, CourseInterface, CourseStatusEnum, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesHttpService, CoursesStateService, DanceSchoolStateService, PassHttpService } from '../../services';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { DuplicateCourseDialogComponent } from '../duplicate-course-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-list-table',
  templateUrl: './course-list-table.component.html',
  styleUrls: ['./course-list-table.component.scss']
})
export class CourseListTableComponent implements OnChanges, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input() courses: CourseInterface[] = [];

  @Output() danceCourseReload = new EventEmitter<void>();

  displayedColumns = ['name', 'danceStyles', 'difficultyRange', 'nextAppointment', 'pageViews', 'options'];
  dataSource: MatTableDataSource<CourseInterface>;

  activeCourseChipIndex = 0;
  selectedCourseStatus: CourseStatusEnum = CourseStatusEnum.ACTIVE;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  subscription = new Subscription();

  constructor(
    private readonly matDialog: MatDialog,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
    private readonly coursesHttpService: CoursesHttpService,
    private readonly coursesStateService: CoursesStateService,
    private readonly passHttpService: PassHttpService,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.courses) {
      this.updateDataSource();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.courses);
    this.dataSource.sort = this.sort;
  }

  archiveDanceCourse(course: CourseInterface, changeStatusTo: CourseStatusEnum): void {
    const dialogTranslation = 'GENERIC_WRAPPER.' + (changeStatusTo === CourseStatusEnum.ARCHIVED ? 'ARCHIVE_COURSE_DIALOG' : 'REACTIVATE_COURSE_DIALOG');
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate(`${dialogTranslation}.TITLE`),
      text: this.translocoService.translate(`${dialogTranslation}.MESSAGE`),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate(`GENERIC_WRAPPER.CANCEL`),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate(`${dialogTranslation}.CONFIRM`)
    };
    this.matDialog.open(ConfirmationDialogComponent, {data: confirmationDialogData, panelClass: 'dialog_radius'}).afterClosed().subscribe((result: boolean) => {
      if (result && changeStatusTo === CourseStatusEnum.ARCHIVED) {
        this.checkCourseArchivable(course).then(archivable => {
          if (archivable) {
            this.changeCourseStats(course.id, changeStatusTo);
          } else {
            this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.COURSE_ARCHIVE_ERROR_HAS_PASSES'));
          }
        });
      } else if (result && changeStatusTo === CourseStatusEnum.ACTIVE) {
        this.changeCourseStats(course.id, changeStatusTo);
      }
    });
  }

  async checkCourseArchivable(course: CourseInterface): Promise<boolean> {
    try {
      const coursePasses = await this.passHttpService.getAllForSaleSelectionPageByTargetId(course.id).toPromise();
      for (const pass of coursePasses) {
        if (pass.passTargets.length === 1) {
          return false;
        }
      }
      return true;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  }

  changeCourseStats(courseId: string, changeStatusTo: CourseStatusEnum): void {
    this.coursesHttpService.updateCourseStatusById(courseId, changeStatusTo).subscribe({
      next: () => {
        this.coursesStateService.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 100, this.selectedCourseStatus);
      }
    });
  }

  deleteDanceCourse(courses: CourseInterface): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COURSE_DIALOG.TITLE'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COURSE_DIALOG.DESCRIPTION')
    };
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.coursesHttpService.deleteCourseById(courses.id).subscribe({
            next: () => {
              this.danceCourseReload.emit();
            },
            error: (err) => {
              switch (err.error.error) {
                case 'Bad Request':
                  this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.COURSE_DELETE_ERROR_HAS_PASSES'));
                  break;
                default:
                  this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.COURSE_DELETE_ERROR'));
                  break;
              }
            }
          });
        }
      });
  }

  editDanceCourse(course: CourseInterface): void {
    const route = `../courses/preview/${course.id}`;
    this.router.navigate([route], {relativeTo: this.route});
  }

  toggleCourseChip(value: number): void {
    this.activeCourseChipIndex = value;
    if (value === 0) {
      this.selectedCourseStatus = CourseStatusEnum.ACTIVE;
    } else {
      this.selectedCourseStatus = CourseStatusEnum.ARCHIVED;
    }
    this.coursesStateService.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 100, this.selectedCourseStatus);
  }

  duplicateDanceCourse(course: CourseInterface): void {
    const dialogRef = this.matDialog.open(DuplicateCourseDialogComponent, {
      data: course,
      panelClass: 'br-20',
      maxHeight: '100vh',
      maxWidth: '390px',
      minWidth: '390px',
      width: '25vw',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const danceStyleIds = result.danceStyles.map((danceStyle) => danceStyle.id);
        this.coursesStateService.duplicateCourseById(course.id, result.name, danceStyleIds, result.startDifficulty, result.endDifficulty, result.takeoverPasses);
        this.danceCourseReload.emit();
        this.coursesStateService.clear();
      }
    });
  }

  protected readonly CourseStatusEnum = CourseStatusEnum;
}
