<ng-container *transloco="let t">
  <div class="flex-column-center-start p3" style="min-height: calc(100vh - 68px);">
     <div class="flex-column w-100" style="max-width: 1140px;">
        <!-- Breadcrumbs -->
        <div>
           <xng-breadcrumb [separator]="iconTemplate">
              <ng-container *xngBreadcrumbItem="let breadcrumb">
                 <ng-container>{{ t(breadcrumb) }}</ng-container>
              </ng-container>
           </xng-breadcrumb>
           <ng-template #iconTemplate>
              <mat-icon>chevron_right</mat-icon>
           </ng-template>
        </div>
        <div class="flex-column w-100">
           <mat-card class="main-content round-corners my2" style="padding: 40px;">
              <span class="page-title">{{t('DANCE_MANAGER_COURSE.EDIT_COURSE')}}</span>
              <div class="pt-20 pb-40">
                 <mat-divider style="position: unset"></mat-divider>
              </div>
              <div [formGroup]="courseEditAppointmentFormGroup" class="flex-column" style="max-width: 50%; gap: 20px;">
                 <div class="flex-column w-75" style="gap: 1rem;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.DATE_AND_TIME')}}</span>
                   <div class="flex-column">
                     <mat-form-field appearance="outline">
                       <mat-label>{{'Start date'}}</mat-label>
                       <input matInput [matDatepicker]="picker" readonly id="date-start" (click)="picker.open()" formControlName="startDate" [min]="today"/>
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                       <button
                         matSuffix
                         mat-icon-button
                         aria-label="Clear"
                         type="button"
                         (click)="courseEditAppointmentFormGroup.controls.startDate.reset()">
                          <mat-icon>close</mat-icon>
                       </button>
                       <mat-error *ngIf="courseEditAppointmentFormGroup.controls.startDate.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_DATE_ERROR_REQUIRED')}}</mat-error>
                    </mat-form-field>
                    <df-shared-lib-time-picker
                      [label]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_TIME')"
                      [requiredErrorMessage]="'Start time is required'"
                      class="w-100"
                      [passedFormGroup]="courseEditAppointmentFormGroup"
                      passedFormControlName="startTime"
                    ></df-shared-lib-time-picker>
                    <df-shared-lib-time-picker
                      [label]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_TIME')"
                      [requiredErrorMessage]="'End time is required'"
                      class="w-100"
                      [passedFormGroup]="courseEditAppointmentFormGroup"
                      passedFormControlName="endTime"
                    ></df-shared-lib-time-picker>
                     <mat-error *ngIf="!endTimeIsLater">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_TIME_BEFORE_START_TIME')}}</mat-error>
                   </div>
                 </div>
  
                 <div class="flex-column pt-20 w-75" style="gap: 10px;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.LOCATION')}}</span>
                    <mat-radio-group (change)="changeAddressInput($event.value)" class="flex-column" style="gap: 16px;" formControlName="addressSelectionType">
                       <mat-radio-button color="primary" value="location" [checked]="danceManager.locations.length !== 0" [disabled]="danceManager.locations.length === 0"><span>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.EXISTING_LOCATION')}}</span></mat-radio-button>
                       
                       <mat-form-field appearance="outline" *ngIf="courseEditAppointmentFormGroup.controls.addressSelectionType?.value === 'location' || danceManager.locations.length === 0" hideRequiredMarker="{{danceManager.locations.length === 0}}">
                          <mat-label>{{ t(danceManager.locations.length === 0 ? 'NO_LOCATIONS' : 'SELECT')}}</mat-label>
                          <mat-select  [disableControl]="courseEditAppointmentFormGroup.controls.addressSelectionType?.value !== 'location'"  formControlName="locationId" [compareWith]="compareById">
                             <mat-option *ngFor="let location of danceManager?.locations" [value]="location.id" (click)="onLocationSelected(location)">{{ location.name }}</mat-option>
                          </mat-select>
                       </mat-form-field>
  
                       <mat-radio-button color="primary" value="manual"  [checked]="danceManager.locations.length === 0" >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MANUAL_ADDRESS')}}</mat-radio-button>
                       <df-shared-lib-google-address-search-autocomplete
                         class="pt-10"
                         *ngIf="courseEditAppointmentFormGroup.controls.addressSelectionType?.value === 'manual'"
                         [alternativeDesign]="true"
                         [address]="courseEditAppointmentFormGroup.controls.address.value"
                         (addressSelected)="onAddressSelected($event)"
                         [types]="['address']">
                       </df-shared-lib-google-address-search-autocomplete>
                    </mat-radio-group>
                 </div>
                <!-- Teachers -->
                 <div class="flex-column pt-20 w-75" style="gap: 10px;">
                    <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.TEACHERS')}}</span>
                    <mat-form-field appearance="outline">
                       <mat-label>{{t('GENERIC_WRAPPER.SELECT')}}</mat-label>
                      <mat-select formControlName="teacherUserIds" multiple>
                        @for (teacher of teachers; track teacher) {
                          <mat-option [value]="teacher.id">{{ teacher.firstName ? teacher.firstName + ' ' + teacher.lastName : teacher.username}}</mat-option>
                        }
                      </mat-select>
                       <mat-error *ngIf="courseEditAppointmentFormGroup.controls.teacherUserIds.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TEACHERS_ERROR_REQUIRED')}}</mat-error>
                    </mat-form-field>
                 </div>
                <!-- Participants -->
                 <div class="flex-column" style="gap: 10px;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.PARTICIPANTS')}}</span>
                    <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MAX_PARTICIPANTS_SUBTEXT')}}</span>
                    <mat-form-field appearance="outline" class="w-25">
                       <input dfNoScroll placeholder="1" min="1" matInput type="number" formControlName="maxParticipants"/>
                       <mat-error *ngIf="courseEditAppointmentFormGroup.controls.maxParticipants.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}</mat-error>
                    </mat-form-field>
                 </div>
                <!-- Additional information -->
                 <div *ngIf="courseEditAppointmentFormGroup.controls.description as editorControl" class="flex-column" style="gap: 10px;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.ADDITIONAL_INFO')}}</span>
                    <df-shared-lib-quill-editor
                      [hasError]="editorControl.hasError('maxlength') && editorControl.touched"
                      [disableRichText]="true"
                      [enableMentions]="true"
                      [enableCounter]="true"
                      [maxLength]="1000"
                      [id]="'description'"
                      [placeholder]="'DANCE_MANAGER_COURSE.APPOINTMENTS.ADDITIONAL_INFO'"
                      customFormControlName="description"></df-shared-lib-quill-editor>
                    <mat-error *ngIf="editorControl.hasError('maxlength')" class="custom-error">{{t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH')}}</mat-error>
                 </div>
              </div>
              <div class="flex-row-end-center pt-20" style="gap: 16px;">
                 <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" [materialButtonStyleType]="'outlined'" (buttonClicked)="onCancelClick()"></df-shared-lib-button>
                <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" [isDisabled]="courseEditAppointmentFormGroup.invalid || !endTimeIsLater" (buttonClicked)="onSubmit()"></df-shared-lib-button>
              </div>
           </mat-card>
        </div>
     </div>
  </div>
</ng-container>
