<ng-container *transloco="let t">
  <section class="example-container">
    <div class="flex-column-center-center" style="gap: 40px;">
      <mat-card
        class="example-step"
        id="step-{{ i }}"
        [class.example-step-error]="step.hasError"
        [class.example-active]="selectedIndex === i"
        *ngFor="let step of steps; let i = index"
      >
        <div
          (click)="selectStepByClick(i)"
          [class]="
            selectedIndex === i
              ? 'title-container title-container-active'
              : 'title-container'
          "
          [ngClass]="
            step.hasError
              ? 'step-error'
              : step.completed
              ? 'step-completed'
              : ''
          "
        >
          <span [class]="selectedIndex === i ? 'step-active' : ''"
            >{{ step.label }} {{ step.completed ? '✔' : '' }}</span
          >
          <span class="step-error-description">{{
            step.hasError ? '(' + step.errorMessage + ')' : ''
          }}</span>
        </div>
        <div
          class="example-step-body"
          [class.example-step-body-inactive]="selectedIndex !== i"
          [class.example-step-body-active]="selectedIndex === i"
        >
          <div
            [ngTemplateOutlet]="
              selectedIndex === i ? step.content : step.content
            "
          ></div>

          <div class="pl-20 flex-row" style="gap: 15px;">
            <df-shared-lib-button
              *ngIf="i > 0"
              materialButtonStyleType="outlined"
              [buttonLabel]="t('GENERIC_WRAPPER.back')"
              (buttonClicked)="selectStepByButton(i - 1)"
            ></df-shared-lib-button>
            <df-shared-lib-button
              *ngIf="i < steps.length - 1"
              [buttonLabel]="t('GENERIC_WRAPPER.next')"
              (buttonClicked)="selectStepByButton(i + 1)"
            ></df-shared-lib-button>
            <df-shared-lib-button
              *ngIf="!showSubmitButtonSaveAsDraft && i === steps.length - 1"
              [buttonLabel]="t('GENERIC_WRAPPER.save')"
              (buttonClicked)="submit.emit()"
            ></df-shared-lib-button>
            <df-shared-lib-button
              *ngIf="showSubmitButtonSaveAsDraft && i === steps.length - 1"
              [buttonLabel]="'Save as draft'"
              (click)="submit.emit({ saveAsDraft: true })"
            ></df-shared-lib-button>
            <df-shared-lib-button
              *ngIf="showSubmitButtonSaveAsDraft && i === steps.length - 1"
              [buttonLabel]="'Save and publish'"
              (buttonClicked)="submit.emit()"
            ></df-shared-lib-button>
          </div>
        </div>
      </mat-card>
    </div>
  </section>
</ng-container>
