import { Pipe, PipeTransform } from '@angular/core';
import { Conversation } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'conversationGetPartnerUsername'
})
export class ConversationGetPartnerUsernamePipe implements PipeTransform {
  transform(conversation: Conversation, currentPartnerId: string): string {
    return conversation.partners
      .filter((partner) => partner.id !== currentPartnerId)
      .map((user) => user.username)
      .join(', ');
  }
}
