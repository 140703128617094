import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GoogleAddressSearchAutocompleteComponent } from '@platri/dfx-angular-core';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-from-form-settings',
  templateUrl: './from-form-settings.component.html',
  styleUrls: ['./from-form-settings.component.scss'],
})
export class FromFormSettingsComponent {
  @Input() currentUser: UsersInterface;
  @Output() userSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('addressInput')
  addressInput: GoogleAddressSearchAutocompleteComponent;
  valid: boolean = false;
  newPlace: string = null;

  constructor() {}

  saveFormerData(): void {
    const user = { formerPlace: this.newPlace };
    this.userSaved.emit(user);
    this.cancel();
  }

  onAddressSelected($event): void {
    if (this.addressInput.addressControl.valid) {
      this.newPlace = $event.city + ', ' + $event.country;
    } else {
      this.newPlace = null;
    }
  }

  cancel(): void {
    this.addressInput.searchAddressForm.reset(this.currentUser);
    this.newPlace = null;
    this.closeEditMode.emit();
  }
}
