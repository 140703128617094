import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DanceManagerFeatureInterface, DanceManagerFeatureNameEnum, DanceManagerUserRoleEnum, DanceSchoolInterface, LocationInterface, UsersInterface } from '@platri/df-common-core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DanceManagerService, DanceSchoolStateService, LocationsStateService, MessageStateService } from '../../services';
import { Subscription } from 'rxjs';
import { DanceManagerFeature } from '@platri/df-common-shared-graphql';
import { getSubscription } from '../../helpers';
import { dfmAdministrationRoute, dfmMarketPlaceRoute } from '../../shared/routes/danceflavors.routes';

@Component({
  selector: 'df-shared-lib-sidenav-responsive-dm',
  templateUrl: './sidenav-responsive-dm.component.html',
  styleUrls: ['./sidenav-responsive-dm.component.scss'],
})
export class SidenavResponsiveDmComponent implements OnInit{
  @Input() user: UsersInterface;
  @Input() unreadMessagesCount = 0;
  
  @Input() danceManagers: DanceSchoolInterface[]; 
  
  @Output() logoutClicked = new EventEmitter<void>();
  @Output() bugReportClicked = new EventEmitter<void>();
  @Output() featureRequestClicked = new EventEmitter<void>();
  @Output() closeMenu = new EventEmitter<void>();
  danceManagerControl: UntypedFormControl = new UntypedFormControl(null);

  public showLocationInfo: boolean;
  public showNewLocationText: boolean;
  location: LocationInterface | null;
  locations: LocationInterface[];

  private subscriptions = new Subscription();
  DanceManagerUserRoleEnum = DanceManagerUserRoleEnum;
  danceManagerFeatures: DanceManagerFeatureInterface[] = [];
  danceManagerFeatureName = DanceManagerFeatureNameEnum;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private danceSchoolService: DanceSchoolStateService,
    private readonly locationService: LocationsStateService,
    private readonly danceManagerService: DanceManagerService,
    private readonly messageService: MessageStateService
    ) {
  }
  
  ngOnInit(): void {
    this.listenOnDanceManagerChanges();
    this.checkUrls(this.router.url);
    this.initializeSubscriptions();
  }

 initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.danceManagerService.getAsyncDanceManagerFeatures(), this.onDanceManagerFeaturesChange.bind(this)));
    this.getLocationChanges();
    this.getLocationsChanges();
    this.getDanceSchoolMessagesUnreadCountChanges();
  }

  onDanceManagerFeaturesChange(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.danceManagerFeatures = danceManagerFeatures;
  }

  compareById(danceManager1: DanceSchoolInterface, danceManager2: DanceSchoolInterface): boolean {
    return danceManager1?.id === danceManager2?.id;
  }
  
  listenOnDanceManagerChanges(): void {
    this.danceSchoolService.getAsyncCurrentDanceSchool()
      .subscribe((danceManager) => {
        if (danceManager) {
          this.danceManagerControl.patchValue(danceManager);
        } else {
          this.danceManagerControl.patchValue(this.danceManagers[0]);
        }
      });
  }

  getLocationChanges(): void {
    this.subscriptions.add(
      this.locationService.getAsyncSelectedLocation().subscribe((location) => {
        this.location = location;
        this.checkUrls(this.router.url);
      })
    );
  }

  getLocationsChanges(): void {
    this.subscriptions.add(
      this.locationService.getAsyncAvailableLocations().subscribe((locations) => {
        if (locations) {
          this.locations = locations;
          this.checkUrls(this.router.url);
        }
      })
    );
  }

  checkUrls(url: string): void {
    this.showLocationInfo = url.includes('locations');
    this.showNewLocationText = url.includes('locations/edit') && !this.location;
  }

  routeToChats(): void {
    this.closeMenu.emit();
    this.router.navigate([`dance-manager/${this.danceManagerControl.value?.id}/messages`], { queryParams: { technicalUserId: this.danceManagerControl.value?.id }, relativeTo: this.activatedRoute });
  }

  private getDanceSchoolMessagesUnreadCountChanges() {
    this.subscriptions.add(
      this.messageService.getAsyncAvailableMessagesCounts().subscribe((result) => {
        const dmUnreadMessages = result.amountOfUnreadDmMessageDetails.find((obj) => obj.danceManagerId === this.danceManagerControl.value.id);
        if (dmUnreadMessages) {
          this.unreadMessagesCount = Number(dmUnreadMessages.amountOfUnreadMessages);
        } else {
          this.unreadMessagesCount = 0;
        }
      })
    );
  }

  protected readonly dfmMarketPlaceRoute = dfmMarketPlaceRoute;
  protected readonly dfmAdministrationRoute = dfmAdministrationRoute;
}
