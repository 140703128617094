<ng-container *transloco="let t">
  <ng-container *ngIf="passedFormGroup">
    <div
      class="step-body-container flex-column gap-20"
      [formGroup]="passedFormGroup"
    >
      <div>
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.event-tickets-settings.ticket-scope') }}
        </h4>
        <p>
          {{ t('GENERIC_WRAPPER.event-tickets-settings.ticket-valid-for-event') }}
        </p>
        <mat-form-field appearance="outline" class="w-25">
          <mat-label>{{
            t('GENERIC_WRAPPER.event-tickets-settings.event-name')
          }}</mat-label>
          <mat-select
            formControlName="event"
            disableOptionCentering
            [compareWith]="compareDanceEvent"
          >
            <mat-option [value]="null" disabled
              >Choose your dance event</mat-option
            >
            <mat-option
              *ngFor="let danceEvent of danceEvents"
              [value]="danceEvent"
              >{{ danceEvent.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.event-tickets-settings.ticket-name') }}
        </h4>
        <p>
          {{ t('GENERIC_WRAPPER.event-tickets-settings.ticket-name-explanation') }}
        </p>
        <mat-form-field appearance="outline" class="w-75">
          <mat-label>{{
            t('GENERIC_WRAPPER.event-tickets-settings.ticket-name-label')
          }}</mat-label>
          <input
            [title]="passedFormGroup.controls.name.disabled ? t('GENERIC_WRAPPER.NO_CHANGES_POSSIBLE') : ''"
            matInput
            type="text"
            id="name"
            formControlName="name"
            #name
          />
          <mat-hint align="end">{{ name.value.length }} / 100</mat-hint>
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            type="button"
            (click)="passedFormGroup.controls.name.reset()"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="nameControl.hasError('required')">{{
              t('GENERIC_WRAPPER.ERROR_TICKET_NAME')
          }}</mat-error>
          <mat-error
            *ngIf="nameControl.hasError('maxlength')"
            class="errorTextSpaceBetween"
          >
            <div>
              {{ t('GENERIC_WRAPPER.ERROR_NAME_MAX_LENGTH') }}
            </div>
            <div style="margin-left: auto">
              {{ nameControl.errors?.maxlength.actualLength }} /
              {{ nameControl.errors?.maxlength.requiredLength }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <h4 class="step-body-title">
          {{
            t('GENERIC_WRAPPER.event-tickets-settings.ticket-available-quantity')
          }}
        </h4>
        <p>
          {{
            t('GENERIC_WRAPPER.event-tickets-settings.maximum-ticket-available')
          }}
        </p>
        <mat-form-field appearance="outline" class="w-30">
          <mat-label>{{
            t('GENERIC_WRAPPER.event-tickets-settings.quantity-label')
          }}</mat-label>
          <input
            dfNoScroll
            matInput
            type="number"
            id="availableQuantity"
            formControlName="availableQuantity"
          />
          <mat-error
            *ngIf="
              passedFormGroup.controls.availableQuantity.hasError('min') &&
              !passedFormGroup.controls.name.disabled
            "
          >
            {{
              t('GENERIC_WRAPPER.event-tickets-settings.min-quantity-error')
            }}</mat-error
          >
          <mat-error
            *ngIf="
              passedFormGroup.controls.availableQuantity.hasError('min') &&
              passedFormGroup.controls.name.disabled
            "
            style="margin-bottom: 20px"
          >
            {{ t('GENERIC_WRAPPER.VALUE_NOT_LOWER_THAN_TICKET_SOLD') }}
          </mat-error>
          <mat-error
            *ngIf="passedFormGroup.controls.availableQuantity.hasError('max')"
          >
            {{
              t('GENERIC_WRAPPER.event-tickets-settings.max-quantity-error')
            }}</mat-error
          >

          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            type="button"
            (click)="passedFormGroup.controls.availableQuantity.reset()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div>
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.event-tickets-settings.ticket-description') }}
        </h4>
        <p>
          {{ t('GENERIC_WRAPPER.event-tickets-settings.ticket-description-text') }}
        </p>
        <df-shared-lib-quill-editor
          [enableMentions]="true"
          [enableCounter]="true"
          [isRequired]="true"
          [id]="'description'"
          [placeholder]="'GENERIC_WRAPPER.DESCRIPTION'"
          [customFormControlName]="'description'"
          [maxLength]="1000"
          [hasError]="!passedFormGroup.get('description').valid"
        ></df-shared-lib-quill-editor>
      </div>
    </div>
  </ng-container>
</ng-container>
