import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { startOfDay } from 'date-fns';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-birth-date-form-settings',
  templateUrl: './birth-date-form-settings.component.html',
  styleUrls: ['./birth-date-form-settings.component.scss'],
})

export class BirthDateFormSettingsComponent implements OnInit {

  @Input() currentUser: UsersInterface;
  @Output() birthDateSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  currentDate = new Date();
  isSubmitting = true;
  formGroup: UntypedFormGroup;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }


  initForm(): void {
    this.formGroup = this.formBuilder.group({
      birthDate: [
        this.currentUser.birthday,
        Validators.required,
      ],
      isAgePublic:[this.currentUser.isAgePublic ?? false]
    });

  }

  saveBirthDateData(): void {
    if (this.formGroup.valid) {
      const birthDate = startOfDay(new Date(this.formGroup.value.birthDate));
      const isAgePublic = this.formGroup.value.isAgePublic ?? false;
      const user = {birthday: birthDate,isAgePublic:isAgePublic};
      this.birthDateSaved.emit(user);
      this.closeEditMode.emit();
    }


    this.isSubmitting = true;
    this.formGroup.markAsPristine();

  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }

}
