import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { DmPackagesCardHeaderModule, DmPackagesCardListModule, DmSubscriptionCardModule, PipesModule } from '@platri/dfx-angular-core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DfmMarketPlacePage } from './dfm-market-place.page';
import { RouterModule, Routes } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DfmMarketPlacePage,
  },
];

@NgModule({
  declarations: [DfmMarketPlacePage],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatCardModule,
      MatIconModule,
      MatMenuModule,
      MatButtonModule,
      MatFormFieldModule,
      MatChipsModule,
      MatDividerModule,
      ReactiveFormsModule,
      PipesModule,
      MatSlideToggleModule,
      DmPackagesCardListModule,
      DmSubscriptionCardModule,
      DmPackagesCardHeaderModule,
      BreadcrumbComponent,
      BreadcrumbItemDirective,
      TranslocoDirective,
    ],
  providers: [],
})
export class DfmMarketPlacePageModule {}
