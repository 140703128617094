import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhoneInputModule } from '@platri/dfx-angular-core';
import { MatIconModule } from '@angular/material/icon';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [AccountComponent],
  exports: [AccountComponent],
    imports: [
      CommonModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatRadioModule,
      FormsModule,
      MatExpansionModule,
      MatProgressSpinnerModule,
      PhoneInputModule,
      MatProgressSpinnerModule,
      MatIconModule,
      TranslocoDirective,
    ],
})
export class AccountModule {}
