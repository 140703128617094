<ng-container *transloco="let t">
  <div style="display: flex; flex-direction: row; width: 100%; height: 100%">
    <div class="chat" style="width: 100%">
      <div class="chat-header">
        <button *ngIf="isMobile" mat-icon-button (click)="routeToChatOverview()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="chat-recipent" style="cursor: pointer" [routerLink]="['/@' + recipientUser.username]">
          <elab-list-picture-chat [imageUrl]="recipientUser && recipientUser.imageUrl ? recipientUser.imageUrl : ''" [isProfilePicture]="true"></elab-list-picture-chat> 
          <span class="mat-headline-6" style="margin-bottom: 0">{{ recipientUser.username }}</span>
        </div>
        <div class="chat-header-actions">
          <button mat-icon-button style="cursor: pointer" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="deleteChat()" mat-menu-item>{{ t('GENERIC_WRAPPER.DELETE') }}</button>
          </mat-menu>
         <!-- <mat-icon>close</mat-icon> -->
        </div>
      </div>
      
      <div style="position: relative">
        <div (click)="scrollMessageIntoViewViaUnreadCounter(unreadMessageId)" *ngIf="unreadCounter && showUnreadCounter" style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); background-color: grey; z-index: 9999999; color: white; padding: 8px; text-align: center; width: 200px; cursor: pointer">
          {{unreadCounter + ' ' + t('GENERIC_WRAPPER.UNREAD') + ' ' + t(unreadCounter > 1 ? 'GENERIC_WRAPPER.MESSAGES_LOWER_CASE' : 'GENERIC_WRAPPER.MESSAGE_LOWER_CASE') }}
        </div>
      </div>
      
      <div id="chat-content" #chatContent class="chat-content" (scroll)="onChatScroll($event)">
        <ng-container *ngFor="let chatDataOfADay of chatData; let last = last;">
          <div class="chat-messages-from-date">
            <mat-chip style="align-self: center;"  [ngStyle]="{'margin-top.px': (last && !isLoadingMoreMessages && !allMessagesLoaded) ? '48' : allMessagesLoaded ? '16' : '0' }" >{{ formatDate(chatDataOfADay.date) }}</mat-chip>
            
            <ng-container *ngFor="let chatMessage of chatDataOfADay.chatMessages; trackBy: trackByChatMessage">
              <ng-container *ngIf="chatMessage.type !== MessageTypeEnum.SYSTEM">
                <ng-container *ngIf="chatMessage.status === MessageStatusEnum.IS_RECEIVED && chatMessage.senderId === recipientId || chatMessage.status === MessageStatusEnum.IS_SENT && chatMessage.senderId === recipientId">
                  <div *ngIf="checkIsRendered(chatMessage)" class="message-unread-ribbon" >
                    <div class="message-unread-ribbon-left"></div>
                    <span class="message-unread-ribbon-label">{{ t('GENERIC_WRAPPER.NEW') }}</span>
                    <div class="message-unread-ribbon-right"></div>
                  </div>
                </ng-container>
           
                <ng-container [ngSwitch]="chatMessage.senderId">
                  <div id="message-{{chatMessage.id}}" class="chat-message-from-current-user" [ngClass]="chatMessage.senderDeleted ? 'message-deleted' : chatMessage.recipientReaction !== MessageReactionEnum.NONE ? 'has-reactions' : ''" *ngSwitchCase="senderId">
                    <ng-container *ngIf="chatMessage.repliedToMessageId">
                      <div class="fill-available" style="display: flex; position: relative; justify-self: stretch; border-left: green 4px solid; padding-left: 12px; width: calc(100%); background-color: #8080800d;">
                        <a (click)="scrollMessageIntoView(chatMessage.repliedToMessageId)" style="position: absolute; top: 0; left:0; height: 100%; width: 100%;" routerLink="." [fragment]="'message-' + chatMessage.repliedToMessageId" skipLocationChange></a>
                        <div style="display: flex; flex-direction: row; justify-content: space-between; place-items: center;">
                          <div>
                            <div style="color: green"> {{chatMessage.repliedToMessage?.senderId === this.senderId ? 'Du' :  this.recipientUser.username}}</div>
                            <div [ngClass]="chatMessage.repliedToMessage?.senderDeleted || chatMessage.repliedToMessage?.recipientDeleted && chatMessage.repliedToMessage?.recipientId === this.senderId ? 'message-deleted-text' : ''" style="line-break: anywhere;">{{chatMessage.repliedToMessage?.senderDeleted || chatMessage.repliedToMessage?.recipientDeleted && chatMessage.repliedToMessage?.recipientId === this.senderId ? 'Diese Nachricht wurde gelöscht.' : chatMessage.repliedToMessage?.value ?? 'Klicken zum anzeigen'}}</div>
                          </div>
                        </div> 
                      </div> 
                    </ng-container>
                    
                    <div style="display: flex; width: 100%; justify-content: space-between;"> 
                      <p *ngIf="chatMessage.type === MessageTypeEnum.TEXT" style="word-break: break-all; place-self: normal; padding-right: 12px;" class="message-value p-no-margin">
                        <span [innerHTML]="chatMessage.senderDeleted ? t('GENERIC_WRAPPER.DELETED') : chatMessage.type === MessageTypeEnum.TEXT ? (chatMessage.value | linkify) :  t('GENERIC_WRAPPER.FILE_UPLOADED')"></span>
                      </p>
  <!--                    <p (click)="openFile(chatMessage.value)" *ngIf="chatMessage.type === MessageTypeEnum.MEDIA" class="message-value p-no-margin file-message"><mat-icon style="padding: 0 5px;" matTextSuffix>download</mat-icon>-->
  <!--                      <span style="vertical-align: super;">Datei hochgeladen</span>-->
  <!--                    </p>-->
                      <p class="p-no-margin description message-extra-information">
                        <span>{{ getTimeHelper(chatMessage.date)}}</span>
                        <mat-icon [ngClass]="chatMessage.status === MessageStatusEnum.IS_READ ? 'message-read' : ''">{{ chatMessage.status !== MessageStatusEnum.IS_SENT ? 'done_all' : 'done' }}</mat-icon>
                      </p>
                    </div>
                    
                    <div style="display: block" *ngIf="chatMessage.recipientReaction !== MessageReactionEnum.NONE" class="emoji-menu">
                      <div class="emoji-reactions current-user-bg bubble-action">
                        <span *ngIf="chatMessage.recipientReaction === MessageReactionEnum.THUMBS_UP">👍</span>
                        <span *ngIf="chatMessage.recipientReaction === MessageReactionEnum.LAUGH">😂</span>
                        <span *ngIf="chatMessage.recipientReaction === MessageReactionEnum.LOVE">❤️</span>
                      </div>
                    </div>
                    
                    <div class="emoji-menu">
                      <div (click)="deleteMessage(chatMessage)" [style.margin-left]="chatMessage.recipientReaction !== MessageReactionEnum.NONE ? '32px' : '0px'" class="emoji-reactions bubble-action current-user-bg">
                        <span><mat-icon>delete_forever</mat-icon></span>
                      </div>
                    </div> 
                    
                    <div class="menu" *ngIf="true">
                      <div class="menu-actions">
                        <mat-icon (click)="replyToMessage(chatMessage)">reply</mat-icon> 
                      </div>
                    </div>
                  </div>
                  
                  <div *ngSwitchCase="recipientId" id="message-{{chatMessage.id}}" (mouseover)="showMenu = true" (mouseleave)="onOtherMessageMouseLeave()" [ngClass]="chatMessage.recipientDeleted ? 'message-deleted' : chatMessage.recipientReaction !== MessageReactionEnum.NONE ? 'has-reactions' : ''" class="chat-message-from-other-user">
                    <ng-container *ngIf="chatMessage.repliedToMessageId">
                      <div class="fill-available" style="display: flex; position: relative; justify-self: stretch; border-left: green 4px solid; padding-left: 12px; background-color: #8080800d; width: calc(100% - 14px);">
                        <a (click)="scrollMessageIntoView(chatMessage.repliedToMessageId)" style="position: absolute; top: 0; left:0; height: 100%; width: 100%;" routerLink="." [fragment]="'message-' + chatMessage.repliedToMessageId" skipLocationChange></a>
                        <div style="display: flex; flex-direction: row; justify-content: space-between; place-items: center;">
                          <div>
                            <div style="color: green"> {{chatMessage.repliedToMessage?.senderId === this.senderId ? 'Du' : this.recipientUser.username}}</div>
                            <div [ngClass]="chatMessage.repliedToMessage?.senderDeleted || chatMessage.repliedToMessage?.recipientDeleted && chatMessage.repliedToMessage?.recipientId === this.senderId ? 'message-deleted-text' : ''" style="line-break: anywhere;">
                              {{chatMessage.repliedToMessage?.senderDeleted || chatMessage.repliedToMessage?.recipientDeleted && chatMessage.repliedToMessage?.recipientId === this.senderId ? t('GENERIC_WRAPPER.MESSAGE_WAS_DELETED') : chatMessage.repliedToMessage?.value ?? t('GENERIC_WRAPPER.CLICK_TO_SHOW')}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    
                    <div style="display: flex; width: 100%; justify-content: space-between;">
                      <p #observableMessages [id]="'message-' + chatMessage.id" [ngClass]="chatMessage.status === MessageStatusEnum.IS_READ ? 'message-received-read message-value' : 'message-received-unread message-value'" style="word-break: break-all; padding-right: 12px; place-self: normal" class="p-no-margin" data-cy="chat-message">
                        <span [ngClass]="chatMessage.senderDeleted ? 'message-deleted-text' : ''">
                          <mat-icon *ngIf="chatMessage.senderDeleted">block</mat-icon>
                          <span *ngIf="chatMessage.senderDeleted; else linkifiedMessage">{{ t('GENERIC_WRAPPER.MESSAGE_WAS_DELETED') }}</span>
                          <ng-template #linkifiedMessage>
  <!--                          <span *ngIf="chatMessage.type === MessageTypeEnum.MEDIA" (click)="openFile(chatMessage.value)" class=" file-message"><mat-icon style="padding: 0 5px;" matTextSuffix>download</mat-icon> <span style="vertical-align: super;">Datei herunterladen</span></span>-->
                            <span *ngIf="chatMessage.type === MessageTypeEnum.TEXT" [innerHTML]="chatMessage.value | linkify"></span>
                          </ng-template>
                        </span>
                      </p>
                      <p class="p-no-margin description message-extra-information">
                        <span>{{ getTimeHelper(chatMessage.date)}}</span>
                      </p>
                    </div>
                    
                    <div style="display: block" class="emoji-menu" *ngIf="!chatMessage.senderDeleted && chatMessage.recipientReaction !== MessageReactionEnum.NONE">
                      <div (click)="reactToMessage(chatMessage, MessageReactionEnum.NONE)" class="emoji-reactions bubble-action">
                        <span *ngIf="chatMessage.recipientReaction === MessageReactionEnum.THUMBS_UP">👍</span>
                        <span *ngIf="chatMessage.recipientReaction === MessageReactionEnum.LAUGH">😂</span>
                        <span *ngIf="chatMessage.recipientReaction === MessageReactionEnum.LOVE">❤️</span>
                      </div>
                    </div>
                    
                    <div class="emoji-menu" *ngIf="true">
                      <ng-template [ngIf]="isInReactionMenu && chatMessage.recipientReaction === MessageReactionEnum.NONE" [ngIfElse]="actionMenu">
                        <div class="emoji-reactions bubble-action">
                          <span (click)="reactToMessage(chatMessage, MessageReactionEnum.THUMBS_UP)">👍</span>
                        </div>                        
                        <div (click)="reactToMessage(chatMessage, MessageReactionEnum.LAUGH)" style="margin-left: 32px" class="emoji-reactions bubble-action">
                          <span>😂</span>
                        </div>
                        <div (click)="reactToMessage(chatMessage, MessageReactionEnum.LOVE)" style="margin-left: 64px" class="emoji-reactions bubble-action">
                          <span>️❤️</span>
                        </div>
                      </ng-template>
                      <ng-template #actionMenu>
                        <ng-container *ngIf="chatMessage.recipientReaction === MessageReactionEnum.NONE">
                          <div (click)="openReactionMenu()" class="emoji-reactions bubble-action">
                            <span><mat-icon>add_reaction</mat-icon></span>
                          </div>
                        </ng-container>
                        <div [style.margin-left]="chatMessage.senderDeleted ? '0px' : '32px'" (click)="deleteMessage(chatMessage)" class="emoji-reactions bubble-action">
                          <span><mat-icon>delete_forever</mat-icon></span>
                        </div>
                      </ng-template>
                    </div>
                    
                    <div class="menu" *ngIf="true">
                      <div class="menu-actions">
                        <mat-icon (click)="replyToMessage(chatMessage)">reply</mat-icon>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="chatMessage.type === MessageTypeEnum.SYSTEM">
                <div #observableMessages [id]="'message-' + chatMessage.id" [ngClass]="chatMessage.status === MessageStatusEnum.IS_READ ? 'message-received-read' : 'fill-class message-received-unread'" style="place-self: center; background-color: #ff7c41; padding: 12px 12px 12px 12px; border-radius: 2px; color: #FFFFFF">{{chatMessage.value}}</div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="isLoadingMoreMessages">
          <mat-spinner style="margin: 10px auto 0 auto" [diameter]="24"></mat-spinner>
        </div>
      </div>
      
      <ng-container *ngIf="selectedMessageForReply">
        <div style="border-left: green 4px solid;margin-left: -8px; padding-left: 8px; background-color: #8080800d">
          <div style="display: flex; flex-direction: row; justify-content: space-between; place-items: center;">
            <div style="width: calc(100% - 30px);">
              <div style="color: green">{{selectedMessageForReply.senderId === this.senderId ? t('GENERIC_WRAPPER.YOU') : this.recipientUser.username}}</div>
              <div>{{selectedMessageForReply?.value}}</div>
            </div>
            <mat-icon style="color: blue; cursor: pointer; height: 30px; width: 30px; font-size: 30px; padding-right: 8px" (click)="replyToMessage(null)">cancel</mat-icon>
          </div>
        </div>
      </ng-container>
      
      <div class="chat-input-container">
        <form>
          <div class="chat-form">
  <!--          <div class="chat-actions" style="align-self: flex-end">-->
  <!--            <input type="file" (change)="onFileSelected($event)" accept="*" hidden #fileInput>-->
  <!--            <mat-icon (click)="fileInput.click()" style="margin-bottom: 17px">attach_file</mat-icon>-->
  <!--          </div>-->
  <!--          <div id="chat-input" appContenteditableFormatter class="chat-input" contenteditable="true">-->
  <!--            <p class="psen"><span style="font-size: 16px; user-select: text; white-space: pre-wrap; word-break: break-word;" class="spansen"></span></p>-->
  <!--          </div>-->
            <textarea
              #chatInput
              id="chat-input"
              class="chat-input"
              [disabled]="(isSendingMessage | async)"
              (input)="onInput()"
              (keydown.enter)="sendMessage($event, chatInput)"
              (keyup.enter)="chatInput.value = ''"
            ></textarea>
            <div class="chat-actions" style="align-self: flex-end">
              <mat-icon style="cursor:pointer; margin-bottom: 17px" (click)="sendMessage($event, chatInput)" data-cy="send-message-icon-button">send</mat-icon>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
