import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerPassesListComponent } from './dance-manager-passes-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CourseListItemCardModule, PassCheckoutListItemModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DanceManagerPassesListItemModule } from '../dance-manager-passes-list-item';

@NgModule({
  declarations: [DanceManagerPassesListComponent],
  imports: [CommonModule, InfiniteScrollModule, CourseListItemCardModule, MatProgressSpinnerModule, PassCheckoutListItemModule, DanceManagerPassesListItemModule],
  exports: [DanceManagerPassesListComponent],
})
export class DanceManagerPassesListModule {}
