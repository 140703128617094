import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { dfAppRoute, DfmDanceManagerPassIdRouterParam } from '../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CourseDmOverviewInterface, CourseInterface, DfmCoursePassesTableInterface, FeesPaidByEnum, PassInterface, PassTargetTypeEnum, PassValidityDto, UpdatePassRequestDto } from '@platri/df-common-core';
import { CoursesStateService, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService, PassHttpService } from '../../services';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { atLeastOneOrAllInGroupRequiredValidator, optionalMinMaxValidator, quillCharacterCountValidator } from '../../validators';
import { map } from 'rxjs/operators';

@Component({
  selector: 'df-shared-lib-dfm-pass-detail',
  templateUrl: './dfm-pass-detail.page.html',
  styleUrls: ['./dfm-pass-detail.page.scss']
})
export class DfmPassDetailPage implements OnInit, OnDestroy, AfterContentInit{
  infoFormGroup: FormGroup;
  priceFormGroup: FormGroup;

  enabledUsesOption = false;
  enabledValidDateOption = false;
  
  subscriptions: Subscription =  new Subscription();

  isInitialized = false;
  currenTabIndex = 0;
  
  isLoadingPass = false;
  hasFailedLoadingPassNoConnection = false;
  
  isWaitingForUpdateResponse = false;
  updateFailedNoConnection = false;
  updateFailedInternalServer = false;
  updateFailedServerNotAvailable = false;
  
  routerParams!: Params;
  passIdFromParam: string;

  loadedPass: Readonly<PassInterface>;
  assignedTargetIds: string[];
  courses: CourseDmOverviewInterface[];
  selectedCourses: DfmCoursePassesTableInterface[];
  selectedCoursesChanged = false;
  taxPercentage : number;
  isVatChanged = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private passHttpService: PassHttpService,
    private breadcrumbService: BreadcrumbService,
    private coursesService: CoursesStateService,
    private danceSchoolService: DanceSchoolStateService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private matSnackBar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeFormGroup(): void {
    this.infoFormGroup = this.createInfoFormGroup();
    this.priceFormGroup = this.createPriceFormGroup();
  }

  createInfoFormGroup(): FormGroup {
    return this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      quantity: [null, [optionalMinMaxValidator(0, 1000)]],
      usageCount: [null, [optionalMinMaxValidator(1, 100)]],
      description: [null, [quillCharacterCountValidator(1000)]],
      trial: [false],
      validity: this.fb.group({
        value: [null, [optionalMinMaxValidator(1, 100)]],
        interval: [null]
      })
    }, {
      validators: atLeastOneOrAllInGroupRequiredValidator(['validity.value', 'validity.interval'], ['usageCount'])
    });
  }

  createPriceFormGroup(): FormGroup {
    return this.fb.group({
      price: [null, [Validators.required, Validators.min(0), Validators.max(10000)]],
      taxPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      feesPaidBy: [FeesPaidByEnum.CREATOR]
    });
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.coursesService.coursesSubject$, this.onCourseChange.bind(this)));
  }

  onCourseChange(courses: CourseInterface[]): void {
    if (courses) {
      this.courses = courses;
      this.selectedCourses = this.courses?.filter((course) => this.loadedPass?.passTargets?.map((target) => target.targetId).includes(course.id));
    }
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.passIdFromParam = this.routerParams[DfmDanceManagerPassIdRouterParam];
    if (!this.passIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
    this.coursesService.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 100);
  }

  loadData(): void {
    this.isLoadingPass = true;
    this.hasFailedLoadingPassNoConnection = false;
    this.subscriptions.add(
      this.passHttpService.getById(this.passIdFromParam).subscribe({
        next: (value) => {
          if (value) {
            this.loadedPass = value;
            this.assignedTargetIds = value.passTargets?.map((target) => target.targetId);
            this.breadcrumbService.set('@passName', this.loadedPass.name);
            this.patchEditPassFormGroups(value);
            this.isLoadingPass = false;
            this.infoFormGroup.enable();
            this.priceFormGroup.enable();
          }
        },
        error: (err) => {
          console.log(err);
          this.isLoadingPass = false;
          if (err.status === 0) {
            this.onLoadPassConnectionLost();
          }
        }
      })
    );
  }

  patchEditPassFormGroups(pass: PassInterface): void {
    if (pass.usageCount) {
      this.enabledUsesOption = true;
    }
    if (pass.validity?.value || pass.validity?.interval) {
      this.enabledValidDateOption = true;
    }
    this.infoFormGroup.patchValue({
      name: pass.name,
      quantity: pass.quantity,
      usageCount: pass.usageCount,
      description: pass.description,
      trial: pass.trial,
      validity: {
        value: pass.validity?.value,
        interval: pass.validity?.interval
      }
    });
    this.taxPercentage = pass.taxPercentage;
    this.priceFormGroup.get('taxPercentage')?.valueChanges.subscribe(value => {
      if (value !==this.taxPercentage){
        this.taxPercentage = value;
        this.isVatChanged = true;
      }
    });
    this.priceFormGroup.patchValue({
      feesPaidBy: pass.feesPaidBy,
      taxPercentage: this.taxPercentage,
      price: pass.price / 100
    });
  }

  onLoadPassConnectionLost(): void {
    this.hasFailedLoadingPassNoConnection = true;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currenTabIndex = event.index;
  }
  
  onSubmit(): void {
    if (this.infoFormGroup.valid && this.priceFormGroup.valid) {
      this.isWaitingForUpdateResponse = true;
      this.updateFailedNoConnection = false;
      this.updateFailedInternalServer = false;

      const { name, description, quantity, usageCount, trial } = this.infoFormGroup.getRawValue();
      const { value, interval } = this.infoFormGroup.get('validity').getRawValue();
      const { price, taxPercentage, feesPaidBy} = this.priceFormGroup.getRawValue();

      const passTargets = [];
      if (this.selectedCourses && this.selectedCourses.length > 0) {
        for (const course of this.selectedCourses) {
          const index = this.loadedPass.passTargets.findIndex((obj) => obj.targetId === course.id);
          if (index === -1) {
            passTargets.push({targetId: course.id, targetType: PassTargetTypeEnum.COURSE});
          } else {
            passTargets.push(this.loadedPass.passTargets[index]);
          }
        }
      }

      let editUsageCount = null;
      if (this.enabledUsesOption) {
        editUsageCount = usageCount;
      }

      let updateValidity: PassValidityDto = null;
      if (this.enabledValidDateOption && value && interval) {
        updateValidity = {
          value,
          interval
        };
      }

      const updatePassRequestDto: UpdatePassRequestDto = new UpdatePassRequestDto({
        name: name!, 
        description: description!, 
        trial: trial!, 
        quantity: quantity!, 
        passTargets, 
        price: Math.round(+price! * 100),
        taxPercentage: +taxPercentage!, 
        feesPaidBy: feesPaidBy!, 
        usageCount: editUsageCount!, 
        validity: updateValidity!
      });
      this.subscriptions.add(this.passHttpService.update(this.passIdFromParam, updatePassRequestDto).subscribe(  {
        next: (res) => {
          this.isWaitingForUpdateResponse = false;
          if (this.isVatChanged){
            this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id, ({vat: this.taxPercentage})).pipe(
              map((result) => {
                this.danceSchoolService.sendCurrentDanceSchool(result);
                this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
                  map((danceSchools) => danceSchools),
                  map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
                ).subscribe();
              })
            ).subscribe();
          }
          this.matSnackBar.open('Successfully updated pass!');
        },
        error:(error) => {
          console.log(error);
          if (error.status === 0) {
            this.onConnectionLost();
          }
          if (error.status === 500) {
            this.onInternalServerError();
          }
          if (error.status === 504) {
            this.onServiceNotAvailable();
          }
          if (error.error.error === 'update_pass_error_price_tax_at_least_one_sold') {
            this.matSnackBar.open('Update failed! Price or tax is not updatable because at least one pass is sold.');
          }
          if (error.error.error === 'update_pass_error_pass_targets_at_least_one_sold') {
            this.matSnackBar.open('Update failed! At least one target needs to stay assigned because at least one pass is sold.');
          }
          this.isWaitingForUpdateResponse = false;
        }}
      ));
    }
  }

  onConnectionLost(): void {
    this.updateFailedNoConnection = true;
  }

  onInternalServerError(): void {
    this.updateFailedInternalServer = true;
  }

  onServiceNotAvailable(): void {
    this.updateFailedServerNotAvailable = true;
  }

  selectedTargets(data: DfmCoursePassesTableInterface[]): void {
    this.selectedCourses = data;
    this.selectedCoursesChanged = true;
  }
  
}
