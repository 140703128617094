import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dfAppRoute, dfConfirmDeleteAccountTokenRouterParam, dfLoginRoute, UserHttpService } from '@platri/dfx-angular-core';

@Component({
  selector: 'elab-delete-account-page',
  templateUrl: './delete-account-page.component.html',
  styleUrls: ['./delete-account-page.component.scss']
})
export class DeleteAccountPageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription =  new Subscription();

  isWaitingForDeleteAccountResponse = false;

  deleteAccountFailedWrongData = false;
  deleteAccountFailedNoConnection = false;
  deleteAccountFailedInternalServer = false;

  hasSuccessfullyDeleteAccount = false;
  
  routerParams!: Params;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userHttpService: UserHttpService,
    private matSnackbar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[dfConfirmDeleteAccountTokenRouterParam]) {
      this.router.navigate([dfAppRoute, dfLoginRoute]);
    } else {
      this.deleteAccount();
    }
  }
  
  deleteAccount(): void {
    this.isWaitingForDeleteAccountResponse = true;
    this.deleteAccountFailedWrongData = false;
    this.deleteAccountFailedNoConnection = false;
    this.deleteAccountFailedInternalServer = false;
    // this.subscriptions.add(this.userHttpService.deleteUserHttp((this.routerParams[dfConfirmDeleteAccountTokenRouterParam]).subscribe(res => {
    //   this.hasSuccessfullyDeleteAccount = true;
    //   this.isWaitingForDeleteAccountResponse = false;
    //   this.matSnackbar.open("Successfully deleted account!");
    // }, error => {
    //   console.log(error);
    //   if (error.status === 0) {
    //     this.onConnectionLost();
    //   }
    //   if (error.status === 0) {
    //     this.onInternalServerError();
    //   }
    //   this.isWaitingForDeleteAccountResponse = false;
    // }));
  }

  onConnectionLost(): void {
    (this.deleteAccountFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.deleteAccountFailedInternalServer = true;
  }

}
