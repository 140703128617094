<ng-container *transloco="let t">
  <mat-form-field appearance="outline">
    <mat-label>{{ t('GENERIC_WRAPPER.SELECT_LANGUAGE') }}</mat-label>
    <mat-select
      disableOptionCentering
      #langSelect
      (valueChange)="changeAndSaveLanguage(langSelect.value)"
      [value]="currentUserLanguage"
    >
      <mat-option *ngFor="let lang of translocoService.getAvailableLangs()" [value]="lang.toUpperCase()">
        {{ t('GENERIC_WRAPPER.PROFILE_LANGUAGE.' + lang.toUpperCase()) }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
