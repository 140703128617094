import { NgModule } from '@angular/core';
import { CoHostSelectionComponent } from './co-host-selection.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { SearchCategoryModule } from '../search-category-component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchDanceManagersModule } from '../search-dance-managers';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CoHostSelectionComponent],
    imports: [
      CommonModule,
      MatFormFieldModule,
      MatInputModule,
      MatAutocompleteModule,
      ReactiveFormsModule,
      PipesModule,
      MatChipsModule,
      MatIconModule,
      MatSelectModule,
      SearchCategoryModule,
      MatProgressSpinnerModule,
      SearchDanceManagersModule,
      TranslocoDirective,
    ],
  exports: [CoHostSelectionComponent],
})
export class CoHostSelectionModule {}
