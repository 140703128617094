<div class="link flex-row">
  <a href="mailto:{{ email }}">
    <div class="flex-row">
      <mat-icon class="icon">email</mat-icon>
      <div>
        <p class="text text-link">{{ email }}</p>
      </div>
    </div>
  </a>
</div>
