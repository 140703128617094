import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appIntegerOnly]'
})
export class IntegerOnlyDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = this.el.nativeElement.value;
    const sanitizedInput = input.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
    if (input !== sanitizedInput) {
      this.el.nativeElement.value = sanitizedInput;
      this.control.control.setValue(sanitizedInput, { emitEvent: false });
    }
  }

  @HostListener('blur')
  onBlur(): void {
    const input = this.el.nativeElement.value;
    const integerValue = parseInt(input, 10);
    if (!isNaN(integerValue)) {
      this.control.control.setValue(integerValue);
    } else {
      this.control.control.setValue(null);
    }
  }
}
