import { UserDto } from '../../..';
import { UserTokenInterface, UserTokenTypeEnum } from '../../..';

export class UserTokenDto implements UserTokenInterface {
  created: Date;
  validUntil: Date;
  id: string;
  token: string;
  user: UserDto;
  userTokenType: UserTokenTypeEnum;
}
