import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { DfDanceStyleEditItemComponent } from './df-dance-style-edit-item.component';
import { DfDanceStyleRoleModule } from '../df-dance-style-role';
import { PipesModule } from '../../pipes';
import { SliderGradientModule } from '../slider-gradient';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfDanceStyleEditItemComponent],
    imports: [
      CommonModule,
      SliderGradientModule,
      MatExpansionModule,
      MatIconModule,
      DfDanceStyleRoleModule,
      PipesModule,
      TranslocoDirective,
    ],
  exports: [DfDanceStyleEditItemComponent],
})
export class DfDanceStyleEditItemModule {}
