<ng-container *transloco="let t">
  <div [formGroup]="infoFormGroup" class="flex-column gap-40 h-100" style="max-width: 720px;">
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.PASS_NAME') }}</span>
      <mat-form-field appearance="outline" style="max-width: 375px;">
        <mat-label>{{t('GENERIC_WRAPPER.PASSES_MANAGER.PASS_NAME')}}</mat-label>
        <input matInput type="text" formControlName="name" required id="pass-info-name-input">
        <mat-hint [class.hint-disabled]="infoFormGroup.get('name')?.disabled" align="end"> {{ name.value?.length ?? 0 }} / 64 </mat-hint>
        <mat-error *ngIf="name.hasError('required')">{{ t('GENERIC_WRAPPER.NAME_REQUIRED') }}</mat-error>
        <mat-error *ngIf="name.hasError('maxlength')">{{ t('GENERIC_WRAPPER.NAME_TOO_LONG') }}</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.TRIAL_PASS') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.TRIAL_PASS_DESCRIPTION') }}</span>
      <mat-checkbox formControlName="trial" (change)="onTrialCheckboxClicked($event)">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.DECLARE_TRIAL_PASS') }}</mat-checkbox>
    </div>
    <div class="flex-column gap-12" *ngIf="!trial.value">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.QUANTITY') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.QUANTITY_DESC') }}</span>
      <mat-form-field appearance="outline" style="max-width: 125px;">
        <mat-label>{{t('GENERIC_WRAPPER.PASSES_MANAGER.QUANTITY_INFO')}}</mat-label>
        <input dfNoScroll matInput type="number" formControlName="quantity" id="pass-info-id-input">
        <mat-error *ngIf="quantity.hasError('min')">{{ t('GENERIC_WRAPPER.MINIMUM_1') }}</mat-error>
        <mat-error *ngIf="quantity.hasError('max')">{{ t('GENERIC_WRAPPER.MAXIMUM_1000') }}</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.VALIDITY') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.VALIDITY_DESC') }}</span>
      
      <mat-checkbox [checked]="enabledUsesOption" (change)="onUsesOptionCheckboxClicked()">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.USES_DESC') }}</mat-checkbox>
      <mat-form-field *ngIf="enabledUsesOption" appearance="outline" style="max-width: 150px; margin-left: 44px;">
        <mat-label>{{t('GENERIC_WRAPPER.PASSES_MANAGER.USES')}}</mat-label>
        <input dfNoScroll matInput type="number" formControlName="usageCount" id="pass-info-uses-input">
        <mat-error *ngIf="usageCount.hasError('required')">{{ t('GENERIC_WRAPPER.USES_REQUIRED') }}</mat-error>
        <mat-error *ngIf="usageCount.hasError('min')">{{ t('GENERIC_WRAPPER.MINIMUM_1') }}</mat-error>
        <mat-error *ngIf="usageCount.hasError('max')">{{ t('GENERIC_WRAPPER.MAXIMUM_100') }}</mat-error>
      </mat-form-field>
      
      <mat-checkbox [checked]="enabledValidDateOption" (change)="onValidOptionCheckboxClicked()">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.VALID_DATE_DESC') }}</mat-checkbox>
      <div *ngIf="enabledValidDateOption" [formGroupName]="'validity'" class="flex-row gap-10" style="margin-left: 44px;">
        <mat-form-field appearance="outline" style="max-width: 150px;">
          <mat-label>{{t('GENERIC_WRAPPER.PASSES_MANAGER.VALID_DATE_INPUT_VALUE')}}</mat-label>
          <input dfNoScroll matInput type="number" step="1" appIntegerOnly formControlName="value">
          <mat-error *ngIf="validity.get('value').hasError('min')">{{ t('GENERIC_WRAPPER.MINIMUM_1') }}</mat-error>
          <mat-error *ngIf="validity.get('value').hasError('max')">{{ t('GENERIC_WRAPPER.MAXIMUM_100') }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 150px;">
          <mat-label>{{t('GENERIC_WRAPPER.PASSES_MANAGER.VALID_DATE_SELECT_LABEL')}}</mat-label>
          <mat-select formControlName="interval">
            <mat-option *ngFor="let interval of (passValidityIntervalEnum | keyvalue)" [value]="interval.value">{{ t(interval.value + '_PLURAL') }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-column gap-12 pb2">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.DESCRIPTION') }}</span>
      <df-shared-lib-quill-editor
        [hasError]="description.hasError('characterCountExceeded') || (description.hasError('required') && description.touched)"
        [enableMentions]="true"
        [enableCounter]="true"
        [maxLength]="1000"
        [id]="'description'"
        [placeholder]="'GENERIC_WRAPPER.PASSES_DESCRIPTION_PLACEHOLDER'"
        [customFormControlName]="'description'"
      ></df-shared-lib-quill-editor>
    </div>
  </div>
</ng-container>
