<ng-container *transloco="let t">
  <div class="flex-column" style="gap: 40px;" [formGroup]="infoFormGroup">
    <div class="flex-column" style="gap: 12px;">
      <span class="input-description-text">{{t('DANCE_MANAGER_COURSE.INFO.NAME')}}</span>
      <mat-form-field appearance="outline" class="pad-matformfield">
        <mat-label>{{t('DANCE_MANAGER_COURSE.INFO.NAME')}}</mat-label>
        <input matInput type="text" id="course-name-mat-input" formControlName="name" required #name/>
        <mat-hint align="end">{{ name.value.length }} / 64</mat-hint>
        <mat-error *ngIf="infoFormGroup.controls.name.hasError('required')">
          {{t('GENERIC_WRAPPER.NAME_IS_REQUIRED')}}
        </mat-error>
        <mat-error *ngIf="infoFormGroup.controls.name.hasError('maxlength')" class="flex-row-wrap-space-between">
          <div>{{t('GENERIC_WRAPPER.NAME_ERROR_MAX_LENGTH')}}</div>
          <div>{{ infoFormGroup.controls.name.errors?.maxlength.actualLength }} /{{ infoFormGroup.controls.name.errors?.maxlength.requiredLength }}</div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-column" style="gap: 8px;">
      <span class="input-description-text">{{t('GENERIC_WRAPPER.DESCRIPTION')}}</span>
      <span class="input-description-sub-text">{{t('DANCE_MANAGER_COURSE.INFO.DESCRIPTION_SUBTEXT')}}</span>
      <div class="flex-column" style="gap: 10px;">
        <df-shared-lib-quill-editor
          [hasError]="infoFormGroup.controls.description.hasError('characterCountExceeded') || (infoFormGroup.controls.description.hasError('required') && infoFormGroup.controls.description.touched)"
          [enableMentions]="true"
          [enableCounter]="true"
          [isRequired]="true"
          [maxLength]="2000"
          [id]="'description'"
          [placeholder]="'GENERIC_WRAPPER.DESCRIPTION'"
          customFormControlName="description"
          class="pad-matformfield-description"></df-shared-lib-quill-editor>
        <mat-error *ngIf="infoFormGroup.controls.description.hasError('required') && infoFormGroup.controls.description.touched" class="custom-error">{{t('GENERIC_WRAPPER.DESCRIPTION_REQUIRED')}}</mat-error>
        <mat-error *ngIf="infoFormGroup.controls.description.hasError('characterCountExceeded')" class="custom-error">  {{t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH')}} </mat-error>
      </div>
    </div>
    <div class="flex-column" style="gap: 12px;">
      <span class="input-description-text">{{t('GENERIC_WRAPPER.DANCE_STYLES_TEXT')}}</span>
      <span class="input-description-sub-text">{{t('DANCE_MANAGER_COURSE.INFO.DANCE_STYLES_SUBTEXT')}}</span>
      <div class="pad-matformfield">
      <df-shared-lib-search-dance-style
        [required]="true"
        [selectedFilters]="infoFormGroup.controls.danceStyles.value"
        [previouslySelectedOptions]="infoFormGroup.controls.danceStyles.value"
        (selectedOption)="selectDanceStyle($event)"
      ></df-shared-lib-search-dance-style>
      </div>
      <div class="request-dance-style mb2">
       <span>{{(t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE')) + " "}}</span>
       <span class="df-link" (click)="openDanceManagerDanceStyleRequestForm()">{{t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE_LINK')}}</span>
      </div>
      
      <div *ngIf="infoFormGroup.controls.danceStyles?.value?.length > 0" class="compensate-form-field-padding pb-20">
          <mat-chip-listbox>
            <mat-chip-option *ngFor="let danceStyle of infoFormGroup.controls.danceStyles.value" [removable]="true" [selectable]="false" (removed)="removeSelectedDanceStyle(danceStyle)">
              {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
    </div>
    <div class="flex-column" style="gap: 16px;">
      <span class="input-description-text">{{t('DANCE_MANAGER_COURSE.INFO.SKILL_LEVEL')}}</span>
      <div class="pad-matformfield">
        <df-shared-lib-level-slider-material
          [showRange]="false"
          [showLevels]="true"
          [fromLevel]="course?.startDifficulty ?? 0"
          [step]="10"
          [toLevel]="course?.endDifficulty ?? 100"
          (setLevelEvent)="setCourseLevel($event)"
        ></df-shared-lib-level-slider-material>
      </div>
    </div>
    <div class="flex-column" style="gap: 16px;">
      <span class="input-description-text">{{t('DANCE_MANAGER_COURSE.INFO.TAGS')}}</span>
      <span class="input-description-sub-text">{{t('DANCE_MANAGER_COURSE.INFO.TAGS_SUBTEXT')}}</span>
      <div class="flex-column">
        <mat-form-field
          color="primary"
          appearance="outline"
          class="pad-matformfield"
        >
          <mat-label>{{t('DANCE_MANAGER_COURSE.INFO.TAGS')}}</mat-label>
          <mat-chip-grid #tagList>
            <input
              autocomplete="off"
              [matChipInputFor]="tagList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addTag($event)"
            />
          </mat-chip-grid>
          <mat-hint align="end"
          >{{ infoFormGroup.controls.tags?.value?.length ?? 0 }} / 10</mat-hint
          >
        </mat-form-field>
  
        <mat-chip-listbox class="compensate-form-field-padding pad-matformfield" style="padding-top: 24px; padding-bottom: 20px;">
          <mat-chip-option
            *ngFor="let tag of infoFormGroup.controls.tags.value"
            class="chip-bg"
            [selectable]="false"
            [removable]="true"
            (removed)="removeTag(tag)"
          >
            {{ tag }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
    <div class="flex-column" style="gap: 16px;">
      <span class="input-description-text">{{t('DANCE_MANAGER_COURSE.INFO.PICTURE')}}</span>
      <span class="input-description-sub-text">{{t('DANCE_MANAGER_COURSE.INFO.PICTURE_SUBTEXT')}}</span>
      <df-add-image
        class="pad-matformfield"
        (selectedUploadImage)="onImageUpload($event)"
        (imageDeleted)="onImageDelete()"
        [ratio]="{ width: 2, height: 1 }"
        [showEmptyPreview]="true"
        [isOpenAddImage]="!!(course && course.mainImage)"
        [croppedImageBaseAs64]="course && course.mainImage? course.mainImage?.url: null">
      </df-add-image>
      </div>
    <div class="flex-row align-end">
      <df-shared-lib-button 
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" 
        (buttonClicked)="onSaveClick.emit()" 
        [isDisabled]="!infoFormGroup.valid" 
        id="save-edited-course-button"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
