import { SearchCategoriesEnum, SortDirectionEnum } from '../../enums';

export interface SearchResultInterface<T> {
  elements: T[];
  totalElements: number;
  totalPages?: number;
  pageSize?: number;
  pageNumber?: number;
  sortColumn: string;
  sortDirection: SortDirectionEnum;
  searchCategory?: SearchCategoriesEnum;
}
