import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../../pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslocoModule } from '@jsverse/transloco';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { DanceCourseUserPassSubscriptionPreviewCardComponent } from './dance-course-user-pass-subscription-preview-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserSearchAutocompleteModule } from '../../user-search-autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
  declarations: [DanceCourseUserPassSubscriptionPreviewCardComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    PipesModule,
    NgxSkeletonLoaderModule,
    TranslocoModule,
    RouterModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UserSearchAutocompleteModule,
    MatInputModule,
    MatDividerModule,
    MatGridListModule
  ],
  exports: [DanceCourseUserPassSubscriptionPreviewCardComponent],
})
export class DanceCourseUserPassSubscriptionPreviewCardModule {}
