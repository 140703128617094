import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmTeamComponent } from './dfm-team.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DfSharedButtonModule, DfQuillEditorModule, UserSearchAutocompleteModule, DanceManagerUserInviteDialogModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { DfmRolesTableModule } from '../dfm-roles-table';
import {TranslocoDirective} from "@jsverse/transloco";


const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatSelectModule,
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatAutocompleteModule,
];

@NgModule({
  declarations: [DfmTeamComponent],
  exports: [DfmTeamComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UserSearchAutocompleteModule,
    ...MATERIAL_MODULES,
    DfmRolesTableModule,
    DfSharedButtonModule,
    DfQuillEditorModule,
    MatDividerModule,
    DanceManagerUserInviteDialogModule,
    MatDialogModule,
    TranslocoDirective,
  ],
})
export class DfmTeamModule {}
