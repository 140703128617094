import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DanceEventCoHostStatus, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';
import { forkJoin, map, Subscription } from 'rxjs';
import { DanceSchoolHttpService } from '../../services';



export interface DanceManagerCoHostInterface {
  danceManagerId?: string;
  danceEventId?: string;
  status?: DanceEventCoHostStatus;
  danceManager?: string;
  imageUrl?: string;
  urlName?:string;
}

@Component({
  selector: 'df-shared-lib-co-host-selection',
  templateUrl: './co-host-selection.component.html',
  styleUrls: ['./co-host-selection.component.scss'],
})
export class CoHostSelectionComponent implements OnInit{
 
  @Output() optionSelected = new EventEmitter<any>();
  @Output() optionRemoved = new EventEmitter<any>();

  @Input() customFormGroup: UntypedFormGroup;
  @Input() existedDanceManagers : DanceManagerCoHostInterface[];
  
  danceEventCoHostStatus = DanceEventCoHostStatus;
  
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  subscriptions: Subscription = new Subscription();
  mappedDanceManagers: DanceManagerCoHostInterface[]=[];
  availableDanceManager: DanceManagerCoHostInterface[]=[];
  

  coHostStatus = [DanceEventCoHostStatus.PENDING, DanceEventCoHostStatus.ACCEPTED, DanceEventCoHostStatus.DECLINED];
  selectedStatus = DanceEventCoHostStatus.PENDING;
  filteredDanceManagers = [];
  numberOfPending = 0;
  numberOfAccepted = 0;
  numberOfDeclined = 0;

  constructor(
    private readonly danceSchoolHttpService: DanceSchoolHttpService
  ) {}
  
  ngOnInit(): void {
    if(this.existedDanceManagers){
      this.getExistedDanceManagers();
    }
  }

  getExistedDanceManagers(): void {
    const coHostsWithNames = this.existedDanceManagers.map(coHost => this.danceSchoolHttpService.getDanceSchoolById(coHost.danceManagerId)
        .pipe(
          map(value => ({
            danceManagerId: coHost.danceManagerId,
            danceEventId: coHost.danceEventId,
            status: coHost.status,
            danceManager: value.name,
            imageUrl:value.imageUrl
          }))
        ));
    forkJoin(coHostsWithNames).subscribe(mappedCoHosts => {
      this.availableDanceManager = mappedCoHosts;
      this.filteredDanceManagers = this.availableDanceManager.filter((obj) => obj.status === this.selectedStatus);
      const statusCounts = this.calculateStatusCounts(this.availableDanceManager);
      this.numberOfPending = statusCounts.pending;
      this.numberOfAccepted = statusCounts.accepted;
      this.numberOfDeclined = statusCounts.declined;
    });
  }

  selectDanceManager(option: DanceManagerCoHostInterface): void {
    this.mappedDanceManagers = [];
    this.availableDanceManager.push(option);
    this.optionSelected.emit(option);
    this.filteredDanceManagers = this.availableDanceManager.filter((obj) => obj.status === this.selectedStatus);
    const statusCounts = this.calculateStatusCounts(this.availableDanceManager);
    this.numberOfPending = statusCounts.pending;
    this.numberOfAccepted = statusCounts.accepted;
    this.numberOfDeclined = statusCounts.declined;
  }

  
  selectStatus(status: DanceEventCoHostStatus): void {
    this.selectedStatus = status;
    this.filteredDanceManagers = this.availableDanceManager.filter((obj) => obj.status === status);
    const statusCounts = this.calculateStatusCounts(this.availableDanceManager);
    this.numberOfPending = statusCounts.pending;
    this.numberOfAccepted = statusCounts.accepted;
    this.numberOfDeclined = statusCounts.declined;
  }

  removeDanceManager(event : DanceManagerCoHostInterface):void{
    this.optionRemoved.emit(event);
    const index = this.availableDanceManager.indexOf(event);
    this.availableDanceManager?.splice(index,1);
    this.existedDanceManagers=[...this.availableDanceManager];
    this.filteredDanceManagers = this.availableDanceManager.filter((obj) => obj.status === this.selectedStatus);
    const statusCounts = this.calculateStatusCounts(this.availableDanceManager);
    this.numberOfPending = statusCounts.pending;
    this.numberOfAccepted = statusCounts.accepted;
    this.numberOfDeclined = statusCounts.declined;
  }

  calculateStatusCounts(danceManagers: DanceManagerCoHostInterface[]): { pending: number; accepted: number; declined: number } {
    const counts = {
      pending: danceManagers.filter(obj => obj.status === DanceEventCoHostStatus.PENDING).length,
      accepted: danceManagers.filter(obj => obj.status === DanceEventCoHostStatus.ACCEPTED).length,
      declined: danceManagers.filter(obj => obj.status === DanceEventCoHostStatus.DECLINED).length,
    };
    return counts;
  }

}
