import { DanceManagerSubscriptionInterface, DanceManagerSubscriptionPaymentStatusEnum, DanceManagerSubscriptionStatusEnum } from '../../..';
import { DanceSchoolDto } from './dance-school.dto';
import { DanceManagerSubscriptionInvoiceDto } from './dance-manager-subscription-invoice.dto';

export class DanceManagerSubscriptionDto implements DanceManagerSubscriptionInterface{
  id: string;
  created: Date;
  updated: Date;
  danceSchool: DanceSchoolDto;
  subscriptionStatus: DanceManagerSubscriptionStatusEnum;
  subscriptionPaymentStatus: DanceManagerSubscriptionPaymentStatusEnum;
  stripePriceId: string;
  stripeSubscriptionId: string;
  subscriptionPlan: string;
  subscriptionInterval: string;
  subscriptionPaymentMethod: string;
  startedAt: Date;
  currentPeriodStartAt: Date;
  currentPeriodEndAt: Date;
  endedAt: Date | null;
  canceledAt?: Date | null;
  danceManagerSubscriptionInvoices: DanceManagerSubscriptionInvoiceDto[];
  totalPrice: number;
  isCanceled: boolean;
}
