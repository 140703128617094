import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { danceManagerRolesWithAccessOnDm, DanceManagerUserRoleEnum } from '@platri/df-common-core';
import {
  AuthGuard,
  dfmAdministrationRoute,
  dfmCourseRoute,
  dfmEventRoute,
  dfmEventTicketRoute,
  dfmLocationRoute,
  dfmMarketPlaceRoute,
  dfmMessageRoute,
  dfmNotificationRoute,
  dfmPayoutRoute,
  dfmSettingsRoute,
  dfmUserGroupsRoute,
  PackageGuard
} from '@platri/dfx-angular-core';
import { DfmAppComponent } from './dfm-app.component';
import { DfmAuthGuard } from '@platri/df-angular-dfm-core';

const routes: Routes = [
  {
    path: ':id',
    component: DfmAppComponent,
    canActivate: [AuthGuard, DfmAuthGuard],
    data: {
      roles: danceManagerRolesWithAccessOnDm,
      breadcrumb: 'GENERIC_WRAPPER.HOME',
    },
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('@platri/df-angular-dance-manager').then((m) => m.DanceManagerPublicPageModule),
      },
      {
        path: dfmNotificationRoute,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'GENERIC_WRAPPER.NOTIFICATIONS' },
        loadChildren: () => import('@platri/dfm-angular-notification').then((m) => m.FrontendDfmNotificationAppModule),
      },
      {
        path: dfmLocationRoute,
        canActivate: [AuthGuard],
        data: {
          roles: [
            DanceManagerUserRoleEnum.ADMIN,
            DanceManagerUserRoleEnum.OWNER
          ],
        },
        loadChildren: () =>
          import('@platri/dfm-angular-location').then((m) => m.FrontendDfmLocationAppModule),
      },
      {
        path: dfmMessageRoute,
        canActivate: [AuthGuard],
        loadChildren: () => import('@platri/df-angular-chat').then((m) => m.MessagesModule)
      },
      {
        path: dfmAdministrationRoute,
        canActivate: [AuthGuard],
        data: {
          roles: [
            DanceManagerUserRoleEnum.ADMIN,
            DanceManagerUserRoleEnum.OWNER,
            DanceManagerUserRoleEnum.MANAGER,
          ],
          breadcrumb:
            'GENERIC_WRAPPER.ADMINISTRATION',
        },
        loadChildren: () =>
          import('@platri/dfm-angular-administration').then((m) => m.FrontendDfmAdministrationAppModule),
      },

      {
        path: dfmSettingsRoute,
        canActivate: [AuthGuard],
        data: {
          roles: [
            DanceManagerUserRoleEnum.ADMIN,
            DanceManagerUserRoleEnum.OWNER,
            DanceManagerUserRoleEnum.MANAGER,
          ],
          breadcrumb: 'GENERIC_WRAPPER.SETTINGS',
        },
        loadChildren: () => import('@platri/dfm-angular-settings').then((m) => m.FrontendDfmSettingsAppModule),
      },
      {
        path: dfmEventRoute,
        canActivate: [AuthGuard, PackageGuard],
        data: { breadcrumb: 'GENERIC_WRAPPER.EVENTS' },
        loadChildren: () => import('@platri/dfm-angular-event').then((m) => m.FrontendDfmEventAppModule),
      },
      {
        path: dfmEventTicketRoute,
        canActivate: [AuthGuard, PackageGuard],
        data: { breadcrumb: 'GENERIC_WRAPPER.EVENTS' },
        loadChildren: () => import('@platri/dfm-angular-event').then((m) => m.EventTicketsModule),
      },
      {
        path: dfmMarketPlaceRoute,
        canActivate: [AuthGuard],
        data: {
          roles: [
            DanceManagerUserRoleEnum.ADMIN,
            DanceManagerUserRoleEnum.OWNER,
            DanceManagerUserRoleEnum.MANAGER,
          ],
          breadcrumb: 'GENERIC_WRAPPER.MARKET_PLACE',
        },
        loadChildren: () => import('@platri/dfm-angular-market-place').then((m) => m.FrontendDfmMarketPlaceAppModule)
      },
      {
        path: dfmPayoutRoute,
        canActivate: [AuthGuard],
        data: {
          roles: [DanceManagerUserRoleEnum.OWNER],
          breadcrumb: 'GENERIC_WRAPPER.PAYOUTS',
        },
        loadChildren: () =>
          import('@platri/dfm-angular-payout').then((m) => m.FrontendDfmPayoutAppModule),
      },
      {
        path: dfmCourseRoute,
        canActivate: [AuthGuard, PackageGuard],
        data: {
          breadcrumb: 'GENERIC_WRAPPER.COURSES',
        },
        loadChildren: () =>
          import('@platri/dfm-angular-course').then((m) => m.FrontendDfmCourseAppModule),
      },
      {
        path: dfmUserGroupsRoute,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'GENERIC_WRAPPER.USER_GROUPS',
        },
        loadChildren: () =>
          import('@platri/dfm-angular-user-groups').then((m) => m.FrontendDfmUserGroupAppModule),
      },
    ],
  },

  {
    path: '404',
    loadChildren: () =>
      import('@platri/dfx-angular-core').then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DfmAppRoutingModule {
  constructor() {
    console.log("DfmAppRoutingModule initialized");
  }
}
