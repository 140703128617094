import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDanceEventInfoGeneralPage } from './create-dance-event-info-general.page';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { DanceEventPreviewCardModule, SearchDanceStyleModule } from '@platri/dfx-angular-core';
import {AddEditDanceEventGeneralInfoModule, AddEditEventAppointmentLocationModule} from '../../components';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  exports: [CreateDanceEventInfoGeneralPage],
  declarations: [CreateDanceEventInfoGeneralPage],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatSelectModule,
        SearchDanceStyleModule,
        MatChipsModule,
        MatExpansionModule,
        MatListModule,
        MatTableModule,
        RouterModule,
        MatCardModule,
        DanceEventPreviewCardModule,
        AddEditDanceEventGeneralInfoModule,
        TranslocoDirective,
        AddEditEventAppointmentLocationModule
    ]
})
export class CreateDanceEventInfoGeneralPageModule {}
