import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CourseDto, DanceEventsDto, DanceSchoolDto, PageDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {
  eventSearchItemsSubject: BehaviorSubject<PageDto<DanceEventsDto>> = new BehaviorSubject<PageDto<DanceEventsDto>>(null);
  courseSearchItemsSubject: BehaviorSubject<PageDto<CourseDto>> = new BehaviorSubject<PageDto<CourseDto>>(null);
  danceSchoolSearchItemsSubject: BehaviorSubject<PageDto<DanceSchoolDto>> = new BehaviorSubject<PageDto<DanceSchoolDto>>(null);
  
  // Event Search Items

  getAsyncEventSearchItems(): Observable<PageDto<DanceEventsDto>> {
    return this.eventSearchItemsSubject.asObservable();
  }
  
  getSyncEventSearchItems(): PageDto<DanceEventsDto> {
    return this.eventSearchItemsSubject.getValue();
  }
  
  sendEventSearchItems(data: PageDto<DanceEventsDto>): void {
    this.eventSearchItemsSubject.next(data);
  }
  
  // Course Search Items

  getAsyncCourseSearchItems(): Observable<PageDto<CourseDto>> {
    return this.courseSearchItemsSubject.asObservable();
  }

  getSyncCourseSearchItems(): PageDto<CourseDto> {
    return this.courseSearchItemsSubject.getValue();
  }

  sendCourseSearchItems(data: PageDto<CourseDto>): void {
    this.courseSearchItemsSubject.next(data);
  }
  
  // Studios Search Items

  getAsyncDanceSchoolSearchItems(): Observable<PageDto<DanceSchoolDto>> {
    return this.danceSchoolSearchItemsSubject.asObservable();
  }

  getSyncDanceSchoolSearchItems(): PageDto<DanceSchoolDto> {
    return this.danceSchoolSearchItemsSubject.getValue();
  }

  sendDanceSchoolSearchItems(data: PageDto<DanceSchoolDto>): void {
    this.danceSchoolSearchItemsSubject.next(data);
  }
  
  // Clears
  
  clear(): void {
    this.eventSearchItemsSubject.next(null);
    this.courseSearchItemsSubject.next(null);
    this.danceSchoolSearchItemsSubject.next(null);
  }
}
