<ng-container *transloco="let t">
  <ng-container [formGroup]="customFormGroup">
     <df-shared-lib-search-dance-managers
       class="co-host-input-flex"
       [label]="t('GENERIC_WRAPPER.CO_HOSTS_SEARCH')"
       [placeholder]="t('GENERIC_WRAPPER.CO_HOSTS_SEARCH')"
       (optionSelected)="selectDanceManager($event)"
       [existedDanceManagers] = existedDanceManagers
       [customFormGroup]="customFormGroup"
       [customFormControlName]="'coHosts'"
       [resetFormAfterSelection]="true"
     ></df-shared-lib-search-dance-managers>
    <div>
      <mat-chip-listbox>
        <mat-chip-option         
          *ngFor="let status of coHostStatus"
          [selected]="selectedStatus === status"
          (click)="selectStatus(status)">
          {{ (status | titlecase) + " (" + (status === danceEventCoHostStatus.PENDING ? numberOfPending : status === danceEventCoHostStatus.ACCEPTED ? numberOfAccepted : numberOfDeclined) + ")" }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div class="flex-column align-start" style="gap: 12px; padding-top: 20px">
        <div *ngFor="let obj of filteredDanceManagers" class="flex-row-start-center" style="gap: 16px; align-self: stretch;">
          <img
            height="44px"
            width="44px"
            class="profilePicture flex-hide"
            [src]="obj.imageUrl && obj.imageUrl !== '' ? obj.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
          />
          <span class="dance-manager-name">{{obj?.danceManager}}</span>
           <mat-icon style="width: 24px; height: 24px; color: #808080; cursor: pointer;" (click)="removeDanceManager(obj)">cancel</mat-icon>
        </div>
    </div>
  </ng-container>
</ng-container>
