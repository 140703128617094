import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeactivateAccountPageComponent } from './deactivate-account-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DeactivateAccountPageComponent],
    imports: [
      CommonModule,
      MatProgressSpinnerModule,
      TranslocoDirective,
    ],
  exports: [DeactivateAccountPageComponent]
})
export class DeactivateAccountPageModule { }
