import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@jsverse/transloco';

interface LevelSliderFormInterface {
  start: FormControl<number | null>;
  end: FormControl<number | null>;
}

const DEFAULT_MAX = 100;
const DEFAULT_MIN = 0;

@Component({
  selector: 'df-shared-lib-level-slider-material',
  templateUrl: './level-slider-material.component.html',
  styleUrls: ['./level-slider-material.component.scss'],
})
export class LevelSliderMaterialComponent implements OnChanges {
  @Input() fromLevel: number;
  @Input() toLevel: number;
  @Input() step: number;
  @Input() disabled = false;
  @Input() min = DEFAULT_MIN;
  @Input() max = DEFAULT_MAX;
  @Input() unit: string;
  @Input() showRange = true;
  @Input() showLevels = false;
  @Output() setLevelEvent = new EventEmitter<{
    fromLevel: number;
    toLevel: number;
  }>();
  
  formGroup: FormGroup;
  
  textFrom: string;
  textTo: string;

  constructor(private readonly translocoService: TranslocoService, private fb: FormBuilder) {
    this.initializeFormGroups();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toLevel || changes.fromlevel) {
      this.formGroup.patchValue({
        start: this.fromLevel || DEFAULT_MIN,
        end: this.toLevel || DEFAULT_MAX
      });
    }
  }

  initializeFormGroups(): void {
    this.initializeRangeFormGroup();
  }
  
  initializeRangeFormGroup(): void {
    this.formGroup = this.fb.group<LevelSliderFormInterface>({
      start: this.fb.control(0, Validators.required),
      end: this.fb.control(100, Validators.required),
    }, { validators: this.rangeValidator });
    this.textFrom = this.switchCaseForEnum(DEFAULT_MIN);
    this.textTo =  this.switchCaseForEnum(DEFAULT_MAX);

    this.formGroup.valueChanges.pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe(data => {
        this.textFrom = this.switchCaseForEnum(data.start);
        this.textTo =  this.switchCaseForEnum(data.end);
        this.setLevelEvent.emit({
          fromLevel: data.start,
          toLevel: data.end
        });
      });
  }

  rangeValidator(group: FormGroup): { [key: string]: any } | null {
    const start = group.get('start').value;
    const end = group.get('end').value;
    return start !== end ? null : { rangeInvalid: true };
  }

  formatLabel = (value: number): string => `${value} ${this.unit}`;

  switchCaseForEnum(value: number): string {
    const enumValue = this.skillLevelDefinition(value);
    switch (enumValue) {
      case 0:
        return this.translocoService.translate('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.BEGINNER');
      case 1:
        return this.translocoService.translate('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.IMPROVER');
      case 2:
        return this.translocoService.translate('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.INTERMEDIATE');
      case 3:
        return this.translocoService.translate('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.ADVANCED');
      case 4:
        return this.translocoService.translate('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.MASTER');
      default:
        return this.translocoService.translate('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.BEGINNER');
    }
  }

  skillLevelDefinition(value: number): number {
    if (value === DEFAULT_MAX) {
      return 4;
    }
    return Math.floor(value / 20);
  }

}
