import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAddToPartner } from '../search-conversation-partners';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER, MessagePartnerInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-search-conversation-partners-entry',
  styleUrls: ['./search-conversation-partners-entry.component.scss'],
  templateUrl: './search-conversation-partners-entry.component.html',
})
export class SearchConversationPartnersEntryComponent {
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  @Input() partner: MessagePartnerInterface;
  @Input() hasToPartner: boolean;
  @Output() addToPartner = new EventEmitter<IAddToPartner>();

  onAddToPartner(toPartner: MessagePartnerInterface, checked: boolean): void {
    this.addToPartner.emit({
      toPartner,
      add: checked,
    });
  }

  addToPartnerWithTrue(toPartner: MessagePartnerInterface): void {
    this.addToPartner.emit({ toPartner, add: true });
  }
}
