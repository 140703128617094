<button 
  [style]="{
    width: size,
    height: size,
    'background-color': backgroundColor,
    color: color
  }"
  class="fab"
  (click)="buttonClicked.emit()" [disabled]="isDisabled">
  <mat-icon>{{matIconName}}</mat-icon>
</button>
