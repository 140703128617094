import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import { NumberInputComponent } from './number-input.component';
import {NoScrollDirective} from "../../directives";

@NgModule({
  declarations: [NumberInputComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        NoScrollDirective,
    ],
  exports: [NumberInputComponent],
})
export class NumberInputModule {}
