<ng-container *transloco="let t">
  <div class="flex-column-center-center">
    <img width="88" height="64" src="assets/images/checkout-complete/check.svg"
         alt="check_svg"/>
  
    <span class="pt-20 mt-20 title">
      {{ t(confirmationText.title) }}
    </span>
  
    <span class="pt-20 text" [innerHTML]="t(confirmationText.message, confirmationText.messageParams)"></span>
    @if (currentUser$ | async) {
      @if (confirmationText.checkboxText) {
        <span class="pt-20 text">
          <mat-checkbox [checked]="sendNotifyToFollowers"
                    (change)="sendNotifyToFollowers = $event.checked"
                    [color]="'primary'">
          {{ t(confirmationText.checkboxText) }}
          </mat-checkbox>
        </span>
      }
      <div class="pt-20">
     <df-shared-lib-button
       [buttonLabel]="t(confirmationText.navButton.label)"
       [materialButtonStyleType]="'flat'"
       [borderRadius]="'25px'"
        [height]="'40px'"
       (buttonClicked)="navigateToMyTickets(confirmationText.navButton.navUrl)"/>
    </div>
    }
    
    @if (confirmationText.downloadText) {
      <div class="pt-20 w-100 center">
      <span class="pdf-download-btn flex-row-center-center"
            (click)="downloadTicket()">
        <mat-icon>download</mat-icon>
        <span>{{ t(confirmationText.downloadText) }}</span>
      </span>
    </div>
    }
   
  </div>
  
  @if (danceManagerId && currentUser$ | async) {
    <div class="w-100 mt-20">
    <df-shared-lib-dm-follow-container
      [danceManagerId]="danceManagerId"/>
  </div>
  }
</ng-container>
