import {BehaviorSubject, Observable} from "rxjs";

export abstract class SimpleStateManagementStateService<T> {

  //todo: evaluieren ob sich das lohnt oder nicht
  private readonly stateSubject: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  sendNewState(state: T ): void {
    this.stateSubject.next(state);
  }

  getSyncState(): T {
    return this.stateSubject.getValue();
  }

  getAsyncState(): Observable<T> {
    return this.stateSubject.asObservable();
  }

  clear() :void {

  }

}
