<ng-container *transloco="let t">
  <div *ngIf="danceEvent" class="flex-column">
    <div class="main-container h-100">
      <div class="flex-column w-100 gap-15">
        <df-dance-event-header [isOwnerOrAdmin]="isOwnerOrAdmin" [danceEvent]="danceEvent"></df-dance-event-header>
        <div class="flex-column gap-5">
          <h3 class="event-title-description">{{ danceEvent.name }}</h3>
          <div class="w-100 flex-row">
            <span class="categories-list" *ngFor="let category of danceEvent.categories; let last = last">
              {{ category | titlecase }}<span *ngIf="!last">, </span>
            </span>
          </div>
        </div>
        <mat-divider class="hide-on-web"></mat-divider>
        <div *ngIf="appointment" class="flex-row hide-on-web gap-10 pt1">
            <mat-icon>calendar_today</mat-icon>
            <div class="flex-column">
              <span style="font-weight: bold !important">
                {{ appointment.startDate | dateRange : appointment.duration : currentLang ?? null}}
              </span>
            </div>
        </div>
        <div *ngIf="appointment" class="flex-row hide-on-web gap-10">
            <mat-icon>room</mat-icon>
            <span style="white-space: pre-line" (click)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)">{{ appointment | googleAddressReadable }}</span>
        </div>
        <mat-divider class="hide-on-web"></mat-divider>
        <div *ngIf="danceEvent.danceStyles && danceEvent.danceStyles.length > 0" class="flex-column gap-5 hide-on-web pt1">
          <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h4>
          <mat-chip-listbox aria-label="Dancestyles list">
            <mat-chip-option class="chip" selectable="false" *ngFor="let danceStyle of danceEvent.danceStyles">
              {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div *ngIf="danceEvent.musicStyles && danceEvent.musicStyles.length > 0" class="flex-column gap-5 hide-on-web pt1">
          <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT') }}</h4>
          <mat-chip-listbox aria-label="Musicstyles list">
            <mat-chip-option class="chip" selectable="false" *ngFor="let musicStyle of danceEvent.musicStyles">
              {{t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase())}}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
        <mat-divider class="hide-on-web"></mat-divider>
        <div class="ql-container" style="border-width: 0;">
          <div style="padding: 0;" class="ql-editor description-text" [innerHTML]="danceEvent.description | dynamicTag"></div>
        </div>
        <mat-divider class="hide-on-web"></mat-divider>
        <div *ngIf="danceEvent.restrictions.clothingRestriction.isRestricted && danceEvent.restrictions.clothingRestriction.restriction">
          <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.DRESSCODE') }}</h4>
          <p [innerHTML]="danceEvent.restrictions.clothingRestriction.restriction"></p>
        </div>
        <div *ngIf="(danceEvent.rules.participationRestriction.isAgeRestricted && danceEvent.rules.participationRestriction.ageRestriction) || (danceEvent.rules.participationRestriction.isGenderRestricted && danceEvent.rules.participationRestriction.genderRestriction)">
          <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.OTHER_RESTRICTIONS') }}</h4>
          <div class="flex-row gap-15">
            <div *ngIf="danceEvent.rules.participationRestriction.isAgeRestricted && (danceEvent.rules.participationRestriction.ageRestriction.toAge || danceEvent.rules.participationRestriction.ageRestriction.fromAge) && danceEvent.rules.participationRestriction.ageRestriction as restriction" class="flex-row-center-center gap-10 age-restriction-display">
              <div class="flex-row-center-center gap-5">
                <mat-icon>check_circle</mat-icon>
                <span>{{ t('GENERIC_WRAPPER.AGE') }}</span>
                <span *ngIf="restriction.fromAge && restriction.toAge">{{restriction.fromAge}} - {{restriction.toAge}}</span>
                <span *ngIf="restriction.fromAge && !restriction.toAge">{{ t('GENERIC_WRAPPER.OVER') }} {{restriction.fromAge}}</span>
                <span *ngIf="!restriction.fromAge && restriction.toAge">{{ t('GENERIC_WRAPPER.UNDER') }} {{restriction.toAge}}
                </span>
              </div>
            </div>
            <div *ngIf="(danceEvent.rules.participationRestriction.genderRestriction.isMaleRestricted || danceEvent.rules.participationRestriction.genderRestriction.isFemaleRestricted || danceEvent.rules.participationRestriction.genderRestriction.isDiverseRestricted) && danceEvent.rules.participationRestriction.genderRestriction as restriction" class="flex-row-center-center gap-10 gender-restriction-display">
              <div class="flex-row-center-center">
                <mat-icon *ngIf="restriction?.isMaleRestricted">male</mat-icon>
                <mat-icon *ngIf="restriction?.isFemaleRestricted">female</mat-icon>
                <mat-icon *ngIf="restriction?.isDiverseRestricted">more_horiz</mat-icon>
              </div>
              <div class="flex-row-center-center gap-5">
                <span>{{ t('GENERIC_WRAPPER.ONLY') }}</span>
                <span *ngIf="restriction.isMaleRestricted">{{ t('GENERIC_WRAPPER.MALE') }}{{restriction.isMaleRestricted && (restriction.isFemaleRestricted || restriction.isDiverseRestricted ? ',' : '') }}</span>
                <span *ngIf="restriction.isFemaleRestricted">{{ t('GENERIC_WRAPPER.FEMALE') }}{{restriction.isFemaleRestricted && restriction.isDiverseRestricted ? ',' : '' }}</span>
                <span *ngIf="restriction.isDiverseRestricted">{{ t('GENERIC_WRAPPER.OTHER') }}</span>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div class="flex-column w-100 hide-on-mobile" style="max-width: 35%;">
        <div class="flex-column w-100 gap-30">
          <h1 class="hide-on-mobile" style="margin: 0;">{{ danceEvent.name }}</h1>
          <div *ngIf="appointment" class="flex-row hide-on-mobile gap-10">
            <mat-icon>calendar_today</mat-icon>
            <span style="font-weight: bold !important">
              {{ appointment.startDate | dateRange : appointment.duration: currentLang ?? null}}
            </span>
          </div>
          <div class="flex-row hide-on-mobile gap-15 hide-on-mobile">
            <df-shared-lib-button [buttonLabel]=" t('GENERIC_WRAPPER.TICKETS')" [isDisabled]="!isEventActive" [height]="'48px'" [minWidth]="'160px'" (buttonClicked)="onClick()"></df-shared-lib-button>
            <df-shared-lib-button [matIconName]="'share'" [materialButtonStyleType]="'outlined'" [isMatIconButton]="true" (buttonClicked)="openShareComponentDialog()"></df-shared-lib-button>
          </div>
          <div class="flex-column">
            <div *ngIf="danceSchool" class="flex-column gap-5 hide-on-mobile">
              <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.ORGANIZER') }}</h4>
              <div class="flex-column link" [routerLink]="['/', '@' + danceSchool.urlName]">
                <img class="circle" style="width: 64px; height: 64px" [src]="danceSchool.imageUrl ?? 'assets/images/studio_logo.png'" alt=""/>
                <span>{{danceSchool.name}}</span>
              </div>
            </div>
            <div *ngIf="appointment" class="flex-column gap-5 pt-30 hide-on-mobile">
              <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.EVENT_LOCATION') }}</h4>
              <div class="flex-row gap-5">
                <div class="w-100">
                  <span class="link" target="_blank" style="white-space: pre-line" (click)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)">
                    {{ appointment | googleAddressReadable }}
                  </span>
                </div>
              </div>
              <div id="google-maps-container" class="h-100 w-100 mt-20 align-top pt-30 hide-on-mobile">
                <google-map
                  width="350px"
                  (mapClick)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)"
                  [height]="200"
                  [options]="googleMapsOptions"
                  [center]="[appointment.address.latitude, appointment.address.longitude] | googleLatLngLiteralPipe">
                  <map-marker [clickable]="false" [position]="[appointment.address.latitude, appointment.address.longitude] | googleLatLngLiteralPipe">
                  </map-marker>
                </google-map>
              </div>
            </div>
            <div *ngIf="danceEvent.danceStyles && danceEvent.danceStyles.length > 0" class="flex-column gap-5 pt-30 hide-on-mobile">
              <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h4>
              <mat-chip-listbox aria-label="Dancestyles list">
                <mat-chip-option class="chip" selectable="false" *ngFor="let danceStyle of danceEvent.danceStyles">
                  {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <div *ngIf="danceEvent.musicStyles && danceEvent.musicStyles.length > 0" class="flex-column gap-5 pt-30 hide-on-mobile">
              <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT') }}</h4>
              <mat-chip-listbox aria-label="Musicstyles list">
                <mat-chip-option class="chip" selectable="false" *ngFor="let musicStyle of danceEvent.musicStyles">
                  {{ t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase())}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <div *ngIf="danceEvent.tags && danceEvent.tags.length > 0" class="flex-column gap-5 pt-30 hide-on-mobile">
              <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.TAGS') }}</h4>
              <mat-chip-listbox aria-label="Tags list">
                <mat-chip-option class="chip" selectable="false" *ngFor="let tag of danceEvent.tags">
                  {{ tag }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <a *ngIf="danceEvent.url" href="{{ danceEvent.url }}" target="_blank" rel="noopener" class="flex-row align-start-center url-style link pt-30 gap-10">
              <mat-icon class="mat-icon-font-size">language</mat-icon>
              {{ t('GENERIC_WRAPPER.FIND_OUT_MORE_ABOUT_THIS_EVENT') }}
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="danceEvent.tags && danceEvent.tags.length > 0" class="flex-column gap-5 pt-30 hide-on-web">
        <h4 style="margin: 0;">{{ t('GENERIC_WRAPPER.TAGS') }}</h4>
        <mat-chip-listbox aria-label="Tags list">
          <mat-chip-option class="chip" selectable="false" *ngFor="let tag of danceEvent.tags">
            {{ tag }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <a *ngIf="danceEvent.url" href="{{ danceEvent.url }}" target="_blank" rel="noopener" class="flex-row align-start-center url-style link pt-30 gap-10  hide-on-web">
              <mat-icon class="mat-icon-font-size">language</mat-icon>
              {{ t('GENERIC_WRAPPER.FIND_OUT_MORE_ABOUT_THIS_EVENT') }}
            </a>
      <div class="flex-row-center-center hide-on-web gap-15 pt-30 hide-on-web">
        <df-shared-lib-button [buttonLabel]=" t('GENERIC_WRAPPER.TICKETS')" [isDisabled]="!isEventActive" [height]="'48px'" [minWidth]="'200px'" (buttonClicked)="onClick()"></df-shared-lib-button>
        <df-shared-lib-button [matIconName]="'share'" [materialButtonStyleType]="'outlined'" [isMatIconButton]="true" (buttonClicked)="openShareComponentDialog()"></df-shared-lib-button>
      </div>
    </div>
  </div>
</ng-container>
