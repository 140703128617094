import { LocationInterface } from '../../..';
import { AmenityDto, DanceSchoolAddressDto, DanceSchoolLocationOpeningHoursDto, ImageDto } from '../../..';

export class CreateDanceSchoolLocationRequestDto implements LocationInterface {
  name: string;
  phonePrefix: string;
  phonePrefixAfter: string;
  phoneNumber: string;
  email: string;
  amenities: AmenityDto[];
  openingHours: DanceSchoolLocationOpeningHoursDto;
  address: DanceSchoolAddressDto;
  showOpeningHours: boolean;
  images: ImageDto[];
  previewImage: ImageDto;
  isPublic: boolean;
}
