<ng-container *transloco="let t">
  <div class="flex-column" *ngIf="orderItemsCollapsed && ticket && danceEvent" [ngClass]="atLeastOneTicketInvalid ? 'blurred' : ''">
    <img class="cart-title-image"
         [src]="danceEvent.mainImage?.url ?? DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER" alt="">
    <mat-card class="flex-column">
      <div class="p2 flex-row align-space-between">
        <div class="flex-column gap-5">
          <span class="cart-item-title">{{orderItemsCollapsed.elements.length}}
            x {{orderItemsCollapsed.elements[0].productSpecificData['ticketName'] ?? ''}}</span>
          <span class="event-name-text">{{danceEvent.name}}</span>
        </div>
        <span class="cart-item-price">{{orderItemsCollapsed.totalGross | centsToCurrencyString: 'EUR'}}</span>
      </div>
      <div class="p2 container-mobile-view">
        <div class="flex-row gap-10">
          <div class="flex-column">
            <mat-icon>place</mat-icon>
          </div>
          <div class="flex-column">
            <span class="dance-school-name">{{danceEvent.danceSchool.name}}</span>
            <p class="address-text">
              {{danceEvent.appointments[0].address.street + ' ' + danceEvent.appointments[0].address.streetNumber}}<br/>
              {{danceEvent.appointments[0].address.city + ', ' + danceEvent.appointments[0].address.zipCode}}<br/>
              {{danceEvent.appointments[0].address.state + ', ' + danceEvent.appointments[0].address.country}}
            </p>
          </div>
        </div>
        <div class="flex-row gap-10">
          <div class="flex-column">
            <mat-icon>schedule</mat-icon>
          </div>
          <div class="flex-column">
            <span>{{danceEvent.appointments[0].startDate |  localizedDate: 'shortDate':'UTC' }} </span>
            <span>
              {{danceEvent.appointments[0].startDate | dateTimezoned : 'shortTime' : 'UTC' : appLang : danceEvent.appointments[0].duration === -1}}
              {{danceEvent.appointments[0].duration !== -1 ? '- ' + (endDate | dateTimezoned : 'shortTime' : 'UTC' : appLang : true) : ''}}
            </span>
          </div>
        </div>
      </div>
      <div class="p2" class="flex-column">
        <ng-container *ngIf="showTicketDetails">
          <div class="pb2 flex-row align-space-between gap-20" *ngFor="let orderItem of orderItemsCollapsed.elements">
            <span class="cart-item-price">{{ticket.name}}</span>
            <span class="cart-item-price">{{orderItem.priceGross | centsToCurrencyString: 'EUR'}}</span>
          </div>
        </ng-container>
        <a style="cursor: pointer; color: #3377FF" (click)="showTicketDetails = !showTicketDetails">
          <mat-icon style="vertical-align: top;" class="vSub"
                    fontSet="material-icons-round">{{ showTicketDetails ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          <span>{{ showTicketDetails ? 'Ticketdetails ausblenden' : 'Ticketdetails anzeigen'}}</span>
        </a>
      </div>
      <mat-divider *ngIf="editable || deletable"></mat-divider>
      <div class="flex-row align-end">
        <div style="width: 10%;" class="flex-row align-center">
          <button *ngIf="editable" (click)="onEditClick()" mat-icon-button [matTooltip]="t('mat-icons.edit')">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="deletable" (click)="onDeleteClick()" mat-icon-button [matTooltip]="t('mat-icons.delete')">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
