import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TicketOrderInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-dance-event-ticket-order-summary',
  templateUrl: './event-ticket-order-summary.component.html',
  styleUrls: ['./event-ticket-order-summary.component.scss'],
})
export class EventTicketOrderSummaryComponent implements OnInit {
  @Input() ticketOrder: TicketOrderInterface;
  @Output() public closeSummary = new EventEmitter<void>();

  summarizedTickets: {
    ticketId: string | null;
    passId: string | null;
    ticketName: string | null;
    ticketPrice: number | null;
    ticketDanceRole: string | null;
    quantity: number;
  }[] = [];

  constructor() {}

  ngOnInit(): void {
    this.summarize();
  }

  summarize(): void {
    this.ticketOrder?.userTickets?.forEach((userTicket) => {
      const index = this.summarizedTickets.findIndex(
        (sumTicket) =>
          sumTicket.ticketId === userTicket?.ticket?.id &&
          sumTicket.ticketDanceRole === userTicket.userTicketDanceRoleType
      );
      if (index >= 0) {
        this.summarizedTickets[index].quantity =
          this.summarizedTickets[index].quantity + 1;
      } else {
        this.summarizedTickets.push({
          ticketId: userTicket?.ticket?.id ?? null,
          passId: null,
          ticketName: userTicket?.ticket?.name ?? null,
          ticketPrice: userTicket?.ticket?.price ?? null,
          ticketDanceRole: userTicket?.userTicketDanceRoleType ?? null,
          quantity: 1,
        });
      }
    });
    this.ticketOrder?.userPasses?.forEach((userPass) => {
      const index = this.summarizedTickets.findIndex(
        (sumTicket) =>
          sumTicket.passId === userPass?.passId
      );
      if (index >= 0) {
        this.summarizedTickets[index].quantity =
          this.summarizedTickets[index].quantity + 1;
      } else {
        this.summarizedTickets.push({
          passId: userPass?.passId ?? null,
          ticketId: null,
          ticketName: userPass?.pass?.name ?? null,
          ticketPrice: userPass?.pass?.price ?? null,
          ticketDanceRole: null,
          quantity: 1,
        });
      }
    });
  }

  public close(): void {
    this.closeSummary.emit();
  }
}
