import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from '../../pipes';
import { DfTimePickerComponent } from './df-time-picker.component';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";

@NgModule({
  declarations: [DfTimePickerComponent],
    imports: [
      CommonModule,
      QuillModule,
      PipesModule,
      ReactiveFormsModule,
      MatInputModule,
      MatSelectModule,
      NgxMaterialTimepickerModule,
      MatDatepicker,
      MatDatepickerInput,
      MatDatepickerToggle,
      MatIcon,
      MatIconButton,
    ],
  exports: [DfTimePickerComponent],
})
export class DfTimePickerModule {}
