import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import { FeesPaidByEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-event-ticket-pricing-step',
  templateUrl: './event-ticket-pricing-step.component.html',
  styleUrls: ['./event-ticket-pricing-step.component.scss']
})
export class EventTicketPricingStepComponent {
  @Input() passedFormGroup: UntypedFormGroup;

  public FeesPaidByEnum = FeesPaidByEnum;

}
