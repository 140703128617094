import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GenderEnum, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-height-form-settings',
  templateUrl: './height-form-settings.component.html',
  styleUrls: ['./height-form-settings.component.scss']
})
export class HeightFormSettingsComponent implements OnInit , OnDestroy{
  @Input() currentUser: UsersInterface;
  @Output() heightSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  isSubmitting=true;
  public Gender = GenderEnum;
  subscription: Subscription = new Subscription();
  formGroup: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
  }
  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({

      height: [
        this.currentUser.height,
        [Validators.max(280),Validators.min(50)],

      ],
    });
    this.subscription.add(this.formGroup.controls.height.valueChanges.subscribe(value => {
      this.formGroup.markAsDirty();
    }));
  }

  saveHeightData() {
    if (this.formGroup.valid) {
      const user = {

        height: this.formGroup.value.height,
      };
      this.heightSaved.emit(user);
      this.isSubmitting=true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
