import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DfmPayoutOverviewPageComponent } from './pages';

const routes: Routes = [
  { 
    path: '' ,
    component: DfmPayoutOverviewPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfmPayoutAppRoutingModule {
  constructor() {
    console.log('FrontendDfmPayoutAppRoutingModule initialized');
  }
}
