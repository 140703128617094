import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DmNotificationsResponseDto, environmentForWeb, GetUnreadNotificationCountsForDanceManagersResponseDto, NotificationDto, NotificationOverviewResponseDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {
  private apiUrl = `${environmentForWeb.apiNotificationsUrl}/permanent-notifications`;

  constructor(private readonly http: HttpClient) {}

  getAllByCurrentUser(): Observable<NotificationDto[]> {
    return this.http.get<NotificationDto[]>(`${this.apiUrl}/user`);
  }

  getAllByDanceManagerId(danceManagerId: string): Observable<DmNotificationsResponseDto> {
    return this.http.get<DmNotificationsResponseDto>(`${this.apiUrl}/dance-manager/${danceManagerId}`);
  }

  markAsReadById(id: string): Observable<NotificationDto> {
    return this.http.get<NotificationDto>(`${this.apiUrl}/read/${id}`);
  }

  markAsNotReadById(id: string): Observable<NotificationDto> {
    return this.http.get<NotificationDto>(`${this.apiUrl}/unread/${id}`);
  }

  markAllReadByTargetId(targetId: string): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/all-read/${targetId}`);
  }

  getCountOfUnreadNotificationOfCurrentUser(): Observable<NotificationOverviewResponseDto> {
    return this.http.get<NotificationOverviewResponseDto>(`${this.apiUrl}/user/unread/count`); 
  }

  getCountOfUnreadNotificationsOfDancemanagers(danceManagerIds: string[]): Observable<GetUnreadNotificationCountsForDanceManagersResponseDto[]> {
    return this.http.post<GetUnreadNotificationCountsForDanceManagersResponseDto[]>(`${this.apiUrl}/dance-manager/unread/counts`, {danceManagerIds});
  }

}
