import { DanceClassGenderEnum, DanceClassRulesEnum, DanceClassToUserTypeEnum, DanceSchoolTypeEnum, RadiusUnitEnum } from '../../enums';
import { UsersInterface } from '../users';

export interface DanceClassCardInfoInterface {
  userToDanceClassId?: string;
  danceStyles?: string[];
  city?: string;
  danceClassToUserType?: DanceClassToUserTypeEnum;
  danceClassId?: string;
  danceClassDescription?: string;
  danceClassName?: string;
  startDifficulty?: number;
  endDifficulty?: number;
  formattedAddress?: string;
  danceClassRules?: DanceClassRulesEnum;
  danceClassGenderRules?: DanceClassGenderEnum;
  fromAge?: number;
  toAge?: number;
  danceManagerId?: string;
  danceManagerName?: string;
  danceManagerPhonePrefix?: string;
  danceManagerPhoneNumber?: string;
  danceSchoolType?: DanceSchoolTypeEnum;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  students?: UsersInterface[];
  teachers?: UsersInterface[];
  favoriteByUsers?: UsersInterface[];
}
