import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UserProfileStateService } from '@platri/dfx-angular-core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { LanguageLevelEnum, UsersInterface, UsersLanguagesInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-language-edit',
  templateUrl: './language-edit.component.html',
  styleUrls: ['./language-edit.component.scss']
})
export class LanguageEditComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  @Input() user: UsersInterface;

  @Output() closeEditMode = new EventEmitter<any>();

  public languageLevelsSorted = [LanguageLevelEnum.BEGINNER, LanguageLevelEnum.MODERATE, LanguageLevelEnum.FLUENT, LanguageLevelEnum.FIRST_LANGUAGE];

  public onEditMode = false;
  public editModeClosed = true;
  public userProfileLanguageSkillsSaved: UsersLanguagesInterface[] = [];
  public userProfileLanguageSkillsEdited: UsersLanguagesInterface[] = [];
  public editedLanguageTranslations: string[] = [];

  public languageControl = new UntypedFormControl();

  public plainTranslatedLanguageList: any;
  public translatedLanguageList: string[] = [];
  public translatedFilteredLanguageList: Observable<string[]> = of([]);

  public subscription: Subscription = new Subscription();

  constructor(private readonly userProfileService: UserProfileStateService, private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (this.user?.languageSkills) {
      this.userProfileLanguageSkillsEdited = JSON.parse(JSON.stringify(this.user.languageSkills));
    }
    this.userProfileLanguageSkillsSaved = this.userProfileLanguageSkillsEdited;
    this.initializeFormControl();
    this.translateLanguages();
  }

  translateLanguages(): void {
    this.subscription.add(
      this.translocoService.langChanges$.subscribe((lang) => {
        const translations = Object.fromEntries(
          Object.entries(this.translocoService.getTranslation().get(this.translocoService.getActiveLang())).map(([key, value]) => {
              const newKey = key.replace("PROFILE_LANGUAGE.", ""); // Remove the prefix
              return key.startsWith("PROFILE_LANGUAGE.") ? [newKey, value] : [];
            })
        );

        this.plainTranslatedLanguageList = translations;
        this.editedLanguageTranslations = this.userProfileLanguageSkillsEdited.map((languageSkill) => this.plainTranslatedLanguageList[languageSkill.key]);

        const languages = [];
        for (const key in translations) {
          languages.push(translations[key]);
        }
        this.translatedLanguageList = languages.sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          } else {
            return 0;
          }
        });
      })
    );
  }

  initializeFormControl(): void {
    this.translatedFilteredLanguageList = this.languageControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterLanguageList(value))
    );
  }

  private _filterLanguageList(value: string): string[] {
    if (value) {
      return this.translatedLanguageList?.filter((language) => language?.toLowerCase().includes(value?.toLowerCase()));
    } else {
      return this.translatedLanguageList;
    }
  }

  save(): void {
    this.userProfileLanguageSkillsSaved = this.userProfileLanguageSkillsEdited;
    this.userProfileService.updateUserProfileLanguages(this.userProfileLanguageSkillsSaved);
    this.editModeClosed = true;
    this.toggleEditMode();
    this.closeEditMode.emit();
  }

  cancel(): void {
    this.userProfileLanguageSkillsEdited = this.userProfileLanguageSkillsSaved;
    this.editedLanguageTranslations = this.userProfileLanguageSkillsSaved.map((languageSkill) => this.plainTranslatedLanguageList[languageSkill.key]);
    this.toggleEditMode();
    this.closeEditMode.emit();
  }

  add(event: string): void {
    const key = Object.keys(this.plainTranslatedLanguageList).find((a) => this.plainTranslatedLanguageList[a] === event);

    if (key) {
      const languageData: UsersLanguagesInterface = {
        key,
        languageLevel: LanguageLevelEnum.BEGINNER
      };
      this.userProfileLanguageSkillsEdited = [...this.userProfileLanguageSkillsEdited, languageData];
      this.editedLanguageTranslations = this.userProfileLanguageSkillsEdited.map((languageSkill) => this.plainTranslatedLanguageList[languageSkill.key]);
      this.editModeClosed = false;
      this.languageControl.reset();
    }
  }

  delete(index: number, language: UsersLanguagesInterface): void {
    this.userProfileLanguageSkillsEdited.splice(index, 1);
    const indexOfTranslatedLanguage = this.editedLanguageTranslations.findIndex((translatedLanguage) => translatedLanguage === this.plainTranslatedLanguageList[language.key]);
    this.editedLanguageTranslations.splice(indexOfTranslatedLanguage, 1);
    this.editedLanguageTranslations = [...this.editedLanguageTranslations];
    this.editModeClosed = false;
  }

  toggleEditMode(): void {
    this.onEditMode = !this.onEditMode;
  }

  onLevelChange(): void {
    this.editModeClosed = false;
  }

  getLanguageLevel(level: string): string {
    return level;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
