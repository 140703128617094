import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { environmentForWeb } from '@platri/df-common-core';


@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private readonly _meta: Meta
  ) {}

  /**
   * Adding Link to header with attributes;
   * rel=“canonical” href=“<ur>”
   * @param url?: string
   */
  public setCanonicalURL(url?: string): void {
    if (environmentForWeb.env === 'prod') {
      const canURL = url === undefined ? this._doc.URL : url;
      const link: HTMLLinkElement = this._doc.createElement('link');

      this.destroyLinkForCanonicalURL(); // remove exist one
      link.setAttribute('rel', 'canonical');
      this._doc.head.appendChild(link);
      link.setAttribute('href', canURL);
    }
  }

  /**
   * Remove link for canonical from header
   */
  public destroyLinkForCanonicalURL(): void {
    const els = this._doc.querySelectorAll("link[rel='canonical']");
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }

  /**
   * Adding Title Tag
   * <title>="{{title}}”</title>
   * @param title?: string
   */
  public setPageTitle(title?: string): void {
    this._doc.title = title ?? 'Danceflavors';
  }

  /**
   * Adding, updating or deleting meta description
   * <meta name="{{selector}}" content="{{content}}>
   * @param selector?: string
   * @param content?: string
   */
  public setMetaTagName(selector?: string, content?: string): void {
    if (selector && content) {
      if (this._meta.getTag(`name="${selector}"`)) {
        this._meta.updateTag({
          name: `${selector}`,
          content,
        });
      } else {
        this._meta.updateTag({
          name: `${selector}`,
          content,
        });
      }
    } else {
      this._meta.removeTag(`name="${selector}"`);
    }
  }

  /**
   * Adding, updating or deleting meta description
   * <meta property="{{selector}}" content="{{content}}>
   * @param selector?: string
   * @param content?: string
   */
  public setMetaTagProperty(selector?: string, content?: string): void {
    if (selector && content) {
      if (this._meta.getTag(`property="${selector}"`)) {
        this._meta.updateTag({
          property: `${selector}`,
          content,
        });
      } else {
        this._meta.updateTag({
          property: `${selector}`,
          content,
        });
      }
    } else {
      this._meta.removeTag(`property="${selector}"`);
    }
  }

  updateStructuredData(structuredData: any): void {
    const className = 'structured-data-website';
    const script = this._doc.createElement('script');
    script.setAttribute('class', className);
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    this._doc.head.appendChild(script);
  }

  removeStructuredData(): void {
    const els: any[] = [];
    ['structured-data-website', 'structured-data-org'].forEach((c) => {
      els.push(...Array.from(this._doc.head.getElementsByClassName(c)));
    });
    els.forEach((el) => this._doc.head.removeChild(el));
  }

  public resetAll(): void {
    this.setPageTitle();
    this.destroyLinkForCanonicalURL();
    this.setMetaTagName('description');
    this.setMetaTagProperty('og:title');
    this.setMetaTagProperty('og:description');
    this.setMetaTagProperty('og:image');
    this.setMetaTagProperty('og:url');
    this.removeStructuredData();
  }
}
