<ng-container *transloco="let t">
  <div class="flex-column align-center pl3 pr3 pb3 p0" style="padding-top: 18px;">
    <div class="w-100 flex-column" style="max-width: 1140px; margin-bottom: -16px;">
      <div>
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="flex-row">
        <mat-card class="round-corners mat-elevation-z3 mt2 w-100 no-shadow-mat-card responsive-card" style="max-width: 1140px; padding: 16px;">
          <div class="px3 pt3 flex-column">
            <span class="title">{{ t('DANCE_MANAGER_MARKET_PLACE.MARKET_PLACE') }}</span>
<!--            <df-monorepo-dm-packages-card-header [danceManagerSubscription]="(dfmStateService.selectedDmSubscription | async)"></df-monorepo-dm-packages-card-header>-->
            <df-monorepo-dm-packages-card-list></df-monorepo-dm-packages-card-list>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
