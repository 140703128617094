import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  ConfirmationDialogComponent,
  DanceManagerHttpService,
  DanceSchoolStateService,
  getSubscription,
  UserHttpService
} from '@platri/dfx-angular-core';
import { DanceSchool } from '@platri/df-common-shared-graphql';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationDialogInterface,
  DanceManagerUserGroupsInterface,
  UserDto
} from '@platri/df-common-core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@jsverse/transloco';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'dfm-user-group',
  templateUrl: './dfm-user-group.component.html',
  styleUrls: ['./dfm-user-group.component.scss']
})
export class DfmUserGroupComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns = ['name', 'users', 'actions'];
  dataSource: MatTableDataSource<DanceManagerUserGroupsInterface>;
  
  danceSchool: DanceSchool;
  userGroups: DanceManagerUserGroupsInterface[];

  loadedUsers: { [key: string]: UserDto } = {};
  hasLoadedUsers = false;
  
  subscriptions: Subscription = new Subscription();
  
  constructor(
    private danceSchoolService: DanceSchoolStateService,
    private router: Router,
    private route: ActivatedRoute,
    private danceManagerHttpService: DanceManagerHttpService,
    private userHttpService: UserHttpService,
    private translocoService: TranslocoService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngAfterViewInit(): void {
    this.loadData();
  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.danceSchoolService.getAsyncCurrentDanceSchool(), this.onDanceSchoolChangeSuccessful.bind(this)));
  }

  onDanceSchoolChangeSuccessful(danceSchool: DanceSchool): void {
    this.danceSchool = danceSchool;
  }
  
  loadData(): void {
    this.danceManagerHttpService.getDmUserGroupsByDanceManagerId({danceManagerId: this.danceSchool.id}).subscribe({
      next: (response) => {
        this.userGroups = response;
        this.dataSource = new MatTableDataSource(this.userGroups);
        this.loadUsers();
      }
    });
  }

  loadUsers(): void {
    const userIds = [];

    for (const userGroup of this.userGroups) {
      for (const usersToLoadKey of userGroup.userIds) {
        userIds.push(usersToLoadKey);
      }
    }

    if (userIds?.length > 0) {
      this.subscriptions.add(
        this.userHttpService.getUsersByIds(userIds).subscribe({
          next: (data) => {
            data.forEach((dto) => {
              this.loadedUsers[dto.id] = dto;
            });
            this.hasLoadedUsers = true;
          }
        })
      );
    }
  }

  getFirstThreeUsers(userGroup: DanceManagerUserGroupsInterface): string {
    const userIds = userGroup.userIds || [];
    const visibleUsers = userIds.slice(0, 3).map(id => this.loadedUsers[id]?.username || 'Unbekannt');
    const remainingCount = userIds.length - visibleUsers.length;
    const moreTranslated = this.translocoService.translate('GENERIC_WRAPPER.FURTHER_MORE');

    return `${visibleUsers.join(', ')}${remainingCount > 0 ? ` <strong>(+${remainingCount} ${moreTranslated})</strong>` : ''}`;
  }

  navigateToCreatePage(): void { 
    this.router.navigate(['create'], { relativeTo: this.route });
  }

  navigateToEditPage(element: DanceManagerUserGroupsInterface): void {
    this.router.navigate(['edit', element.id], { relativeTo: this.route });
  }
  
  deleteUserGroup(element: DanceManagerUserGroupsInterface): void {
    const data: ConfirmationDialogInterface = {
      title: this.translocoService.translate('DFM_USER_GROUP.DELETE_USER_GROUP_DIALOG.TITLE'),
      text: this.translocoService.translate('DFM_USER_GROUP.DELETE_USER_GROUP_DIALOG.TEXT'),
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: 'primary',
      cancelButtonColor: 'warn',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE')
    };
    const successfulText: string = this.translocoService.translate('DFM_USER_GROUP.DELETE_USER_GROUP_DIALOG.SUCCESS_MSG');
    this.subscriptions.add(
      this.matDialog.open(ConfirmationDialogComponent, { data })
      .afterClosed()
      .subscribe({
        next: (result: boolean) => {
          if (result) {
            this.danceManagerHttpService.deleteDmUserGroupById(element.id).subscribe({
              next: () => {
                this.loadData();
                this.matSnackBar.open(successfulText);
              }
            });
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
