<ng-container *transloco="let t">
  <div class="flex-column gap-10 p2" style="max-width: 450px;">
    <span class="title">{{ t('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.PARTICIPATE_DIALOG.SELECT_PASS_OR_SUBSCRIPTION') }}</span> 
    
    <mat-radio-group [(ngModel)]="selectedUserPass" class="flex-column">
      <mat-radio-button *ngFor="let userPass of userPasses" [value]="userPass">
        {{ userPass?.name ?? userPass.pass?.name ?? userPass.userPassSubscription?.name }}
      </mat-radio-button>
    </mat-radio-group>
    
    
    <div class="description mb-10">
      <span [innerHTML]="t('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.PARTICIPATE_DIALOG.TEXT')"></span>
    </div>
    
    <div class="flex-row align-end-center gap-20 mt-10 flex w-100">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        (buttonClicked)="onCancelClick()"
        [isDisabled]="false"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
      <df-shared-lib-button
        (buttonClicked)="onSendClick()"
        [isDisabled]="!selectedUserPass"
        [buttonLabel]="t('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.PARTICIPATE_DIALOG.PARTICIPATE')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
