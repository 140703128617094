<ng-container *transloco="let t">
  <div class="flex-column p1">
    <div class="flex-row align-space-between">
      <span class="select-dm-dialog-title">{{ t('GENERIC_WRAPPER.SELECT_DM_DIALOG.TITLE') }}</span>
      <button mat-icon-button class="cursor-pointer gray-color" mat-dialog-close="true">
        <mat-icon class="delete-icon">clear</mat-icon>
      </button>
    </div>
    <div class="select-dm-dialog-body flex-column">
      <span class="select-dm-dialog-text">{{ t('GENERIC_WRAPPER.SELECT_DM_DIALOG.DESCRIPTION') }}</span>
      
      <div class="select-dm-view flex-column">
        <ng-container *ngFor="let dm of danceSchools | orderByPipe:'name'; let i = index">
          
          <label class="flex-row dance-manager-tile">
            <img class="dance-manager-picture" [src]="dm.imageUrl && dm.imageUrl !== '' ? dm.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER">
            <span class="dance-manager-name"> {{dm.name}} </span>
            <input 
              type="radio" 
              name="dance-manager" 
              value="{{dm.id}}" 
              (change)="handleRadioClick($event)">
          </label>
        </ng-container>
      </div>
      
      <div class="flex-row align-space-between">
        <df-shared-lib-button
          class="cancelBtn"
          [minWidth]="'120px'"
          [materialButtonStyleType]="'outlined'"
          [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
          (buttonClicked)="closeDialog()"
        ></df-shared-lib-button>
        <df-shared-lib-button
          isDisabled="{{!continueIsEnabled.value}}"
          class="submitBtn"
          [minWidth]="'120px'"
          [buttonLabel]="t('GENERIC_WRAPPER.CONTINUE')"
          (buttonClicked)="continue()"
        ></df-shared-lib-button> 
      </div>
    </div>
  </div>
</ng-container>
