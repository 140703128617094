<ng-container *transloco="let t">
  <div class="w-100 pb1 flex-column-center-center">
    <div
      *ngIf="userFollowStateService.getAsyncAvailableFollowCount() | async as followStats"
      class="follow-info mt2 flex-row-center-center"
      style="gap: 20px;"
    >
      <div *ngIf="!hideFollowers">
        <span (click)="openFollowInfoDialog(1)">
          <b>{{ followStats.followerCount }}</b>
          {{ t('GENERIC_WRAPPER.FOLLOWERS') }}
        </span>
      </div>
      <div *ngIf="!hideFollowing">
        <span (click)="openFollowInfoDialog(0)">
          <b>{{ followStats.followCount }}</b>
          {{ t('GENERIC_WRAPPER.FOLLOWING') }}
        </span>
      </div>
      <div *ngIf="pageViews">
        <span style="display: inline-flex; align-items: center; color: #4D4D4D;" matTooltip="{{t('GENERIC_WRAPPER.NUMBER_OF_PAGE_VIEWS')}}">
          <mat-icon aria-hidden="true" fontIcon="visibility"></mat-icon>
          <span style="margin-left: 4px;">{{ pageViews }}</span>
        </span>
      </div>
    </div>
    <div
      *ngIf="currentUser && !isCurrentUser"
      id="Buttons Div"
      class="w-100 pt2 flex-row"
      style="gap: 10px;"
    >
      <button
        *ngIf="userFollowStateService.getAsyncAvailableFollowCount() | async as followStats"
        mat-raised-button
        class="send-button follow-button"
        [ngClass]="followStats.isCurrentUserFollowing ? 'outline' : ''"
        color="primary"
        (click)="followTarget()"
      >
        <span [ngClass]="followStats.isCurrentUserFollowing ? 'follow' : ''">
          {{
            followStats.isCurrentUserFollowing
              ? (t('GENERIC_WRAPPER.FOLLOWING'))
              : (t('GENERIC_WRAPPER.FOLLOW'))
          }}
        </span>
        <span
          *ngIf="followStats.isCurrentUserFollowing"
          [ngClass]="followStats.isCurrentUserFollowing ? 'unfollow' : ''"
        >
          {{ t('GENERIC_WRAPPER.UNFOLLOW') }}
        </span>
      </button>
      <button
        mat-raised-button
        class="send-button outline"
        color="primary"
        (click)="openChat()"
      >
        <span>
          {{ t('GENERIC_WRAPPER.SEND_MESSAGE') }}
        </span>
      </button>
    </div>
    <div
      *ngIf="!currentUser"
      class="w-100 pt2 flex-row"
      style="gap: 10px;"
    >
      <button
        mat-raised-button
        class="send-button follow-button"
        color="primary"
        (click)="followTarget()">
        <span>{{t('GENERIC_WRAPPER.FOLLOW')}}</span>
      </button>
      <button mat-raised-button class="send-button outline" color="primary" (click)="openChat()">
        <span>{{ t('GENERIC_WRAPPER.SEND_MESSAGE') }}</span>
      </button>
    </div>
  </div>
</ng-container>
