import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterDialogComponent } from '../../dialogs';

@Component({
  selector: 'elab-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent {
  
  constructor(
    private matDialog: MatDialog
  ) {
  }

  openRegisterDialog(): void {
    const dialogRef = this.matDialog.open(RegisterDialogComponent, {
      minWidth: '75vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  
}
