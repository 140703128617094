import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { OpeningHoursViewComponent } from './opening-hours-view.component';
import { PipesModule } from '../../pipes';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [OpeningHoursViewComponent],
  imports: [
    CommonModule,
    PipesModule,
    TranslocoDirective
  ],
  exports: [OpeningHoursViewComponent],
})
export class OpeningHoursViewModule {
}
