import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPage } from './settings.page';
import { SettingsRoutingModule } from './settings-routing.module';
import { GeneralModule } from '../../components';
import { AccountModule } from '../../components';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileSettingsModule } from '../../components';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';
import { TranslocoStateConst } from '@platri/dfx-angular-core';

@NgModule({
  declarations: [SettingsPage],
    imports: [
      CommonModule, 
      SettingsRoutingModule, 
      MatTabsModule, 
      GeneralModule,
      AccountModule, 
      ProfileSettingsModule, 
      TranslocoDirective, 
      TranslocoModule
    ],
  providers: []
})
export class SettingsPageModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-user', alias: 'USER' });
  }
}
