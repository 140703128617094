import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  DanceEventsInterface,
  DanceManagerUserGroupsInterface, FeesPaidByEnum
} from '@platri/df-common-core';
import { addMinutes } from 'date-fns';

@Component({
  selector: 'df-event-ticket-submit-step',
  templateUrl: './event-ticket-submit-step.component.html',
  styleUrls: ['./event-ticket-submit-step.component.scss']
})
export class EventTicketSubmitStepComponent implements OnInit {
  @Input() passedFormGroup: UntypedFormGroup;
  @Input() danceEvent: DanceEventsInterface;
  @Input() dfmUserGroups: DanceManagerUserGroupsInterface[] = [];

  public FeesPaidByEnum = FeesPaidByEnum;

  saleStartDate: Date;
  saleEndDate: Date;

  ngOnInit(): void {
    this.setSaleDateValues();
    this.listenOnSaleDateChanges();
  }

  setSaleDateValues(): void {
    if (this.passedFormGroup.controls.salesSettings.value.salesStartDate && this.passedFormGroup.controls.salesSettings.value.salesStartTime) {
      this.setSaleStartDate(this.passedFormGroup.controls.salesSettings.value.salesStartDate, this.passedFormGroup.controls.salesSettings.value.salesStartTime);
    } else {
      this.saleStartDate = new Date();
      // this.saleStartDate.setTime(this.saleStartDate.getTime() + this.saleStartDate.getTimezoneOffset() * 60 * 1000);
      this.passedFormGroup.patchValue({
        saleSettings: {
          salesStartDate: this.saleStartDate
        }
      }, {emitEvent: false});
    }
    if (this.passedFormGroup.controls.salesSettings.value.salesEndDate && this.passedFormGroup.controls.salesSettings.value.salesEndTime) {
      this.setSaleEndDate(this.passedFormGroup.controls.salesSettings.value.salesEndDate, this.passedFormGroup.controls.salesSettings.value.salesEndTime);
    } else {
      const saleStartDate = new Date(this.danceEvent.appointments[0].startDate);
      saleStartDate.setTime(saleStartDate.getTime() + saleStartDate.getTimezoneOffset() * 60 * 1000);
      this.saleEndDate = addMinutes(saleStartDate, this.danceEvent.appointments[0].duration);
      this.passedFormGroup.patchValue({
        saleSettings: {
          salesEndDate: this.saleEndDate
        }
      }, {emitEvent: false});
    }
  }

  listenOnSaleDateChanges(): void {
    this.passedFormGroup.controls.salesSettings.valueChanges.subscribe((data) => {
      if (data.salesStartDate && data.salesStartTime) {
        this.setSaleStartDate(data.salesStartDate, data.salesStartTime);
      }
      if (data.salesEndDate && data.salesEndTime) {
        this.setSaleEndDate(data.salesEndDate, data.salesEndTime);
      }
      if (data.salesStartDate === null) {
        this.saleStartDate = null;
        this.saleStartDate = new Date();
        // this.saleStartDate.setTime(this.saleStartDate.getTime() + this.saleStartDate.getTimezoneOffset() * 60 * 1000);
        this.passedFormGroup.patchValue({
          saleSettings: {
            salesStartDate: this.saleStartDate
          }
        }, {emitEvent: false});
      }
      if (data.salesEndDate === null) {
        this.saleEndDate = null;
        const saleStartDate = new Date(this.danceEvent.appointments[0].startDate);
        saleStartDate.setTime(saleStartDate.getTime() + saleStartDate.getTimezoneOffset() * 60 * 1000);
        this.saleEndDate = addMinutes(saleStartDate, this.danceEvent.appointments[0].duration);
        this.passedFormGroup.patchValue({
          saleSettings: {
            salesEndDate: this.saleEndDate
          }
        }, {emitEvent: false});
      }
    });
  }

  private setSaleStartDate(date: Date, time: string): void {
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    this.saleStartDate = new Date(date);
    this.saleStartDate.setHours(0, 0, 0, 0);
    this.saleStartDate = new Date(this.saleStartDate.getTime() - userTimezoneOffset);
    this.saleStartDate.setHours(+time.substr(0, 2), +time.substr(3, 2));
  }

  private setSaleEndDate(date: Date, time: string): void {
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
    this.saleEndDate = new Date(date);
    this.saleEndDate.setHours(0, 0, 0, 0);
    this.saleEndDate = new Date(this.saleEndDate.getTime() - userTimezoneOffset);
    this.saleEndDate.setHours(+time.substr(0, 2), +time.substr(3, 2));
  }
  
  getSaleGroups(): string {
    // @ts-ignore
    const groupNames: string[] = this.passedFormGroup.controls.salesSettings.controls.danceManagerUserGroupIds.value.map((groupId: string) => {
      const group = this.dfmUserGroups.find((g) => g.id === groupId); // Find the matching group
      return group?.name || ""; // Return name or a fallback value
    });
    return groupNames.join(', ');
  }
}
