<ng-container *transloco="let t">
  <div class="flex-column w-100" style="gap: 1rem;">
    <table 
      *ngIf="passSubscriptions?.length > 0; else noPasses" 
      mat-table 
      [dataSource]="dataSource"
      multiTemplateDataRows
      class="w-100" 
      cdkDropList 
      cdkDropListData="dataSource" 
      [cdkDropListDisabled]="dragDisabled" 
      (cdkDropListDropped)="drop($event)"
    >
      <ng-container matColumnDef="position" sticky>
        <mat-header-cell *matHeaderCellDef class="no-dividers"></mat-header-cell>
        <mat-cell *matCellDef="let element" class="no-dividers">
          <mat-icon class="dragCursor" (mousedown)="dragDisabled = false;">drag_indicator</mat-icon>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="no-dividers">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_TABLE.NAME') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="no-dividers">
          <div class="flex-row align-start-center">
            <span>{{ element.name }}</span>
          </div>
        </mat-cell> 
      </ng-container>
      
      <ng-container matColumnDef="durationValue">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_TABLE.DURATION') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
          <span>{{ element.durationValue + ' ' + t('GENERIC_WRAPPER.' + element.durationUnit + (element.durationValue > 1 ? '_PLURAL' : '_SINGULAR')) }}</span>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="weeklyUsage">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_TABLE.WEEKLY_USAGE') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
          <span *ngIf="element.weeklyUsage">{{ element.weeklyUsage !== 0 ? element.weeklyUsage : '-'}}</span>
          <span *ngIf="!element.weeklyUsage">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_TABLE.WEEKLY_USAGE_UNLIMITED') }}</span>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_TABLE.PRICE') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
          <span>{{ element.price | centsToCurrencyString: 'EUR' }}</span>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="targets">
        <mat-header-cell *matHeaderCellDef class="no-dividers">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_TABLE.COURSES') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="no-dividers">
          <div class="ellipsis-1 gap-4">
            <span *ngFor="let target of getFilteredTargetNames(element); let isLast = last;">{{ target + (!isLast ? ', ' : '') }}</span>
          </div>
        </mat-cell>
      </ng-container>
      
  <!--    TODO: missing "sold" in Interface-->
  <!--    <ng-container matColumnDef="sold">-->
  <!--      <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.SUB_MANAGER.TABLE.SOLD') }}</mat-header-cell>-->
  <!--      <mat-cell *matCellDef="let element" class="center-content no-dividers">-->
  <!--        {{ element.quantity !== null ? element.soldQuantity + '/' + element.quantity : element.soldQuantity}}-->
  <!--      </mat-cell>-->
  <!--    </ng-container>-->
      
      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef class="no-dividers"></mat-header-cell>
  
        <mat-cell *matCellDef="let element" class="no-dividers">
          <div class="flex-row" style="justify-content: flex-end !important;">
            <button mat-icon-button (click)="expandItem(element); $event.stopPropagation()">
              <mat-icon>{{ expendedCoursesElement === element ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="flex-row-end-center">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editSubscriptionById(element.id)">
              <span>{{t('GENERIC_WRAPPER.EDIT') }}</span>
            </button>
            <button mat-menu-item (click)="duplicateSubscriptionById(element.id)">
              <span>{{t('GENERIC_WRAPPER.DUPLICATE') }}</span>
            </button>
            <button mat-menu-item (click)="deleteSubscriptionById(element.id)">
              <span>{{t('GENERIC_WRAPPER.DELETE') }}</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="expandedPassSubscription">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div *ngIf="element === expandedElement">
            <div class="pass-subscription-description"  [innerHTML]="element.description"></div>
          </div>
        </td>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>    
      <mat-row 
        cdkDrag 
        [cdkDragData]="row" 
        class="clickable-row" 
        [ngClass]="{'expanded-row': row === expandedElement}" 
        mat-row 
        *matRowDef="let row; columns: displayedColumns" 
        (click)="editSubscriptionById(row.id)"
      ></mat-row>
      <mat-row 
        *matRowDef="let row; columns: ['expandedPassSubscription']" 
        [ngClass]="row === expandedElement ? 'expanded-pass-subscription-description' :  'unexpanded-pass-subscription-description'" 
        class="clickable-row" 
        (click)="editSubscriptionById(row.id)"
      ></mat-row>
    </table>
  </div>
  
  <ng-template #noPasses>
    <div class="align-center" style="padding-top: 113px;">
      <df-shared-empty-page
        [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_SUBSCRIPTION')"
        [emptyTitle]="t('GENERIC_WRAPPER.NO_SUBSCRIPTION')"
        [emptyDescription]="t('GENERIC_WRAPPER.NO_SUBSCRIPTIONS_TEXT')"
        [imagePath]="'assets/images/courses-manager/no_courses.svg'"
        (buttonClicked)="navigateToCreateSubscriptionPage()"
      ></df-shared-empty-page>
    </div>
  </ng-template>
</ng-container>
