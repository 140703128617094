import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DanceEventStateService, FooterStateService, TicketOrderHttpService, TicketOrderService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CourseInterface, TicketOrderInterface, TicketOrderStatusEnum, UserAppLangEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-passes-checkout-page',
  templateUrl: './course-passes-checkout.page.html',
  styleUrls: ['./course-passes-checkout.page.scss']
})
export class CoursePassesCheckoutPage implements OnInit, OnDestroy {
  currentLang: UserAppLangEnum;
  currentDate: Date;
  endDate: Date;

  course: CourseInterface;
  ticketOrder: TicketOrderInterface | null;

  isMobileResponsive: boolean;
  showMobileSummary: boolean;
  loading = true;

  subscriptions: Subscription = new Subscription();

  constructor(
    private translocoService: TranslocoService,
    private readonly danceEventService: DanceEventStateService,
    private readonly ticketOrderService: TicketOrderService,
    private readonly router: Router, 
    private readonly activatedRoute: ActivatedRoute, 
    private readonly breakpointObserver: BreakpointObserver,
    private readonly footerService: FooterStateService, 
    private readonly ticketOrderHttpService: TicketOrderHttpService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;

    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result: BreakpointState) => {
        this.isMobileResponsive = result.matches;
        this.footerService.showFooter$.next(!result.matches);
        if (this.showMobileSummary && !result.matches) {
          this.showMobileSummary = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.getCourseFromResolver();
    this.getTicketOrder();
  }

  private getCourseFromResolver(): void {
    this.course = this.activatedRoute.snapshot.data.targetObject;
  }

  getTicketOrder(): void {
    if (!this.ticketOrderService.getSyncTicketOrder() && this.ticketOrderService.getSyncTempCreateTicketOrderData()) {
      this.ticketOrderHttpService.create(this.ticketOrderService.getSyncTempCreateTicketOrderData()).subscribe({
        next: (data) => {
          this.ticketOrderService.sendTicketOrderChanges(data);
          this.ticketOrder = data;
          if (data.totalPrice === 0 && data.status === TicketOrderStatusEnum.PAID) {
            this.router.navigate(['dance-class', this.course.id, 'passes', 'checkout-complete', data.id]);
          } else {
            if (!this.ticketOrder) {
              if (!this.course) {
                this.router.navigate([]);
              } else {
                this.navigateToTicketsPage();
              }
            }
            this.loading = false;
          }
        }
      });
    } else {
      this.ticketOrder = this.ticketOrderService.getSyncTicketOrder();
      if (!this.ticketOrder) {
        if (!this.course) {
          this.router.navigate([]);
        } else {
          this.navigateToTicketsPage();
        }
      }
      this.loading = false;
    }
  }

  navigateToEventPage(): void {
    this.router.navigate(['dance-class', this.course.id]);
  }

  navigateToTicketsPage(): void {
    this.router.navigate(['dance-class', this.course.id, 'passes']);
  }

  ngOnDestroy(): void {
    this.ticketOrderService.clear();
    this.subscriptions.unsubscribe();
  }

}
