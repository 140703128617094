<ng-container *transloco="let t">
  <div class="flex-row-center-center">
    <div class="preview flex-row preview-card w-100">
      <div class="container-flex-row-responsive-col w-100 h-100" style="gap: 1rem;">
        <div class="flex-column-center-center" id="left-column">
          <div class="flex-column preview-image-container">
            <img [src]="DF_SEARCH_EVENT_PICTURE" alt="" class="preview-image w-100 mb0 block" />
          </div>
        </div>
        <div class="flex-column align-start gap-15" id="right-column">
          <div class="flex-column gap-4">
            <span class="title">{{t('GENERIC_WRAPPER.EVENT_SEARCH_AD_TITLE')}}</span>
            <span class="subtitle">{{t('GENERIC_WRAPPER.EVENT_SEARCH_AD_SUBTITLE')}}</span>
          </div>
          <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.ENTER_NOW_FOR_FREE')" [borderRadius]="'24px'" [minWidth]="'50%'" (buttonClicked)="onButtonClicked()"></df-shared-lib-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
