import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAddressSearchAutocompleteComponent } from './google-address-search-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslocoModule } from '@jsverse/transloco';
import { DisableControlModule } from '../../directives';
import { FrontendGoogleAutocompleteModule } from '@platri/elab-angular-google-autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [GoogleAddressSearchAutocompleteComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        // GooglePlaceModule,
        DisableControlModule,
        TranslocoModule,
        FrontendGoogleAutocompleteModule,
        MatTooltipModule
    ],
  exports: [GoogleAddressSearchAutocompleteComponent],
})
export class GoogleAddressSearchAutocompleteModule {}
