<ng-container *transloco="let t">
  <div class="flex-column gap-20">
    <div class="flex-column-center-center gap-15 w-100">
      <div *ngFor="let language of userProfileLanguageSkillsEdited; let i = index" class="flex-row-center-center gap-20 w-100">
        <!--Language Level Bar-->
        <div class="flex-column language-bar" style="max-width: 75%">
          <div class="flex-column" [ngClass]="getLanguageLevel(language.languageLevel)">
            <span class="text">{{ t('GENERIC_WRAPPER.PROFILE_LANGUAGE.' + language.key.toUpperCase()) }}</span>
          </div>
        </div>

        <div class="flex-row">
          <!--Language Level Select-->
          <mat-form-field appearance="outline">
            <mat-select [(value)]="language.languageLevel" disableOptionCentering (selectionChange)="onLevelChange($event)">
              <mat-option *ngFor="let languageLevel of languageLevelsSorted | keyvalue" [value]="languageLevel.value">
                {{ t('GENERIC_WRAPPER.LANGUAGE_LEVEL_ENUM.' + languageLevel.value) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!--Delete button-->
          <button mat-icon-button color="warn" title="Delete" (click)="delete(i, language)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--Add New Language-->
  
      <div class="flex-row gap-10 w-100">
        <h4>  {{ t('GENERIC_WRAPPER.ADD_LANGUAGE') }}</h4>
          <mat-form-field floatLabel="never" appearance="outline">
            <mat-label> {{ t('GENERIC_WRAPPER.SELECT_LANGUAGE') }}</mat-label>
            <input
              autocomplete="off"
              type="text"
              matInput
              [formControl]="languageControl"
              [matAutocomplete]="auto"
              [minlength]="4"
              [maxlength]="24"
              (click)="matAutocompleteTrigger.openPanel()"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="add($event.option.value)" hideSingleSelectionIndicator>
              <mat-option
                *ngFor="let language of translatedFilteredLanguageList | async"
                [value]="language"
                [disabled]="language | isDisabled : editedLanguageTranslations"
              >
                {{language}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
      </div>
  </div>

  <div class="flex-row align-end-center gap-20 action-section">
    <df-shared-lib-button
      [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
      (click)="cancel()"
      [isDisabled]="editModeClosed "
    ></df-shared-lib-button>
    <df-shared-lib-button
      [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
      (click)="save()"
      [isDisabled]="editModeClosed"
    ></df-shared-lib-button>
  </div>
</ng-container>
