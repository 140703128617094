import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStateService } from '@platri/dfx-angular-core';
import { UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class DfmAuthGuard  {
  constructor(
    private router: Router, 
    private snackBar: MatSnackBar, 
    private authService: AuthStateService,
    private translocoService: TranslocoService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.authService.getSyncCurrentUser();
    if (currentUser) {
      if (route.data && route.data.roles) {
        if (this.checkForDanceSchoolRoles(currentUser, route)) {
          return true;
        } else {
          const notEnoughRightsText = this.translocoService.translate('GENERIC_WRAPPER.DM_NOT_ENOUGH_RIGHTS');
          this.snackBar.open(notEnoughRightsText);
          this.router.navigate(['/'], {queryParams: { returnUrl: state.url }});
          return false;
        }
      } else {
        return true;
      }
    } else {
      const sessionExpiredTranslation = this.translocoService.translate('GENERIC_WRAPPER.SESSION_EXPIRED');
      this.snackBar.open(sessionExpiredTranslation);
      this.router.navigate(['/'], {queryParams: { returnUrl: state.url }});
      return false;
    }
  }

  checkForDanceSchoolRoles(currentUser: UsersInterface, route: ActivatedRouteSnapshot): boolean {
    let isAllowed = false;
    const userDanceSchoolRoles = currentUser.roles.danceSchool;
    route.pathFromRoot.forEach((path) => {
      if (path.paramMap.get('id')) {
        const id = path.paramMap.get('id');
        route.data.roles.forEach((allowedRole) => {
          if (userDanceSchoolRoles[allowedRole]?.length> 0 && userDanceSchoolRoles[allowedRole]?.map((obj) => obj.toLowerCase()).includes(id.toLowerCase())) {
            isAllowed = true;
            return;
          }
        });
      }
      if (isAllowed) {
        return;
      }
    });
    return isAllowed;
  }
}
