import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DanceManagerUserGroupsInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-event-ticket-sales-settings-step',
  templateUrl: './event-ticket-sales-settings-step.component.html',
  styleUrls: ['./event-ticket-sales-settings-step.component.scss'],
})
export class EventTicketSalesSettingsStepComponent {
  @Input() passedFormGroup: UntypedFormGroup;
  @Input() dfmUserGroups: DanceManagerUserGroupsInterface[] = [];

  currentDate = new Date();

  subscription: Subscription = new Subscription();
}
