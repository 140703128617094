import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmRolesTableComponent } from './dfm-roles-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogModule, ImageFallbackDirectiveModule } from '@platri/dfx-angular-core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfmEditRoleDialogModule } from '../dfm-edit-role-dialog';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmRolesTableComponent],
  exports: [DfmRolesTableComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatDialogModule,
    ConfirmationDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatMenuModule,
    ImageFallbackDirectiveModule,
    MatProgressSpinnerModule,
    DfmEditRoleDialogModule,
    TranslocoDirective
  ]
})
export class DfmRolesTableModule {}
