import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CdkStepper} from "@angular/cdk/stepper";

@Component({
  selector: 'df-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: CustomStepperComponent}]
})
export class CustomStepperComponent extends CdkStepper {

  @Input() showSubmitButtonSaveAsDraft = false;

  @Output() submit: EventEmitter<{saveAsDraft?: boolean}> = new EventEmitter<{saveAsDraft?: boolean}>();

  selectStepByButton(selectedIndex) {
    // @ts-ignore
    if(this.steps._results[this.selectedIndex].hasError && selectedIndex > this.selectedIndex) {
      this.shakeStep(this.selectedIndex);
    } else {
      this.selectedIndex = selectedIndex;
    }
  }

  selectStepByClick(selectedIndex) {
    this.selectedIndex = selectedIndex;
    for(let i = 0; i < selectedIndex; i++) {
      // @ts-ignore
      if (this.steps._results[i].hasError) {
        this.shakeStep(i);
      }
    }
  }

  shakeStep(stepIndex) {
    const stepDom = document.getElementById('step-' + stepIndex);
    stepDom.classList.toggle('example-step-shake');
    setTimeout(() => (stepDom.classList.toggle("example-step-shake")), 1600);
  }

}
