import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CourseListTableModule,
  CoursePassesTableModule,
  CourseSubscriptionTableModule,
  DfSharedButtonModule,
  PassPriceFormModule,
  SharedEmptyPageModule
} from '@platri/dfx-angular-core';
import { DfmCoursePage } from './dfm-course.page';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmCoursePage],
  exports: [
    DfmCoursePage
  ],
  imports: [
    CommonModule,
    PassPriceFormModule,
    CourseSubscriptionTableModule,
    SharedEmptyPageModule,
    DfSharedButtonModule,
    TranslocoDirective,
    CoursePassesTableModule,
    CourseListTableModule
  ]
})
export class DfmCoursePageModule {}
