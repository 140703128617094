import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environmentForWeb, UserDto, UsersDanceStylesDto, UserSearchDto, UsersInterface, UsersLanguagesInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class ProfileHttpService {
  private SERVICE_URL = `${environmentForWeb.apiUsersUrl}/users`;

  constructor(private router: Router, private http: HttpClient) {}

  public postProfilePicture(formData: FormData): Observable<UserSearchDto> {
    return this.http.post<UserSearchDto>(`${this.SERVICE_URL}/profile/images/profile-picture`, formData);
  }

  //TODO: Change to UserDto
  public putProfileLanguagesByUser(languages: UsersLanguagesInterface[]): Observable<UsersLanguagesInterface[]> {
    return this.http.put<UsersLanguagesInterface[]>(`${this.SERVICE_URL}/profile/languages`, languages);
  }

  public putProfileDanceStylesByUser(danceStyles: UsersDanceStylesDto[]): Observable<UsersDanceStylesDto[]> {
    return this.http.put<UsersDanceStylesDto[]>(`${this.SERVICE_URL}/profile/dance-style`, danceStyles);
  }

  //TODO: Change to UserDto
  public updateUserInformation(user: UsersInterface): Observable<UsersInterface> {
    return this.http.patch<UsersInterface>(`${this.SERVICE_URL}`, user);
  }

  public deleteProfilePicture(): Observable<UserDto> {
    return this.http.delete<UserDto>(`${this.SERVICE_URL}` + '/profile/images/profile-picture');
  }
}
