import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'danceSkillRolePipe',
})
export class DanceSkillRolePipe implements PipeTransform {
  transform(
    isPairDance: boolean,
    isFollower?: boolean,
    isLeader?: boolean
  ): string {
    if (!isPairDance) {
      return '';
    }
    if (isFollower && isLeader) {
      return 'GENERIC_WRAPPER.DANCE_SKILL_ROLE.BOTH';
    } else if (!isFollower && isLeader) {
      return 'GENERIC_WRAPPER.DANCE_SKILL_ROLE.LEADER';
    } else if (isFollower && !isLeader) {
      return 'GENERIC_WRAPPER.DANCE_SKILL_ROLE.FOLLOWER';
    } else {
      return '';
    }
  }
}
