import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DfmSidenavAdministrationComponent } from './dfm-sidenav-administration.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DfmSidenavAdministrationComponent],
  exports: [DfmSidenavAdministrationComponent],
    imports: [
        CommonModule,
        MatListModule,
        RouterModule,
        OverlayModule,
        MatIconModule,
        MatTooltipModule,
        TranslocoDirective,
    ],
})
export class DfmSidenavAdministrationModule {}
