import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SocialLinkInterface, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-social-links',
  templateUrl: './user-social-links-preview.component.html',
  styleUrls: ['./user-social-links-preview.component.scss'],
})
export class UserSocialLinksPreviewComponent implements OnInit {
  @Input() currentUser: UsersInterface;
  @Output() saveClick = new EventEmitter<UsersInterface>();

  temporarySocialLinks: SocialLinkInterface[] = [];

  ngOnInit(): void {
    if (this.currentUser?.socialLinks) {
      this.temporarySocialLinks = JSON.parse(
        JSON.stringify(this.currentUser.socialLinks)
      );
    }
  }

  deleteSocialLinks(index): void {
    this.temporarySocialLinks.splice(index, 1);
    this.currentUser.socialLinks = this.temporarySocialLinks;
    this.saveClick.emit(this.currentUser);
  }
}
