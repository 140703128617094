import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullscreenDialogComponent } from './fullscreen-dialog';

@Component({
  selector: 'df-shared-lib-gallery',
  templateUrl: './df-gallery.component.html',
  styleUrls: ['./df-gallery.component.scss'],
})
export class DfGalleryComponent {
  @Input() imageUrls: string[];

  constructor(private readonly dialog: MatDialog) {}

  openFullscreenDialog(source): void {
    const dialogRef = this.dialog.open(FullscreenDialogComponent, {
      height: '95vvh',
      width: '95vw',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: 'fullscreen-dialog-container',
      backdropClass: 'fullscreen-dialog-backdrop',
      data: source,
    });
  }
}
