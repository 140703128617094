import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DanceSchoolLocationService, DfmStateService } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import { DanceManagerInterface, DanceSchoolInterface, LocationInterface } from '@platri/df-common-core';
import { getSubscription } from '@platri/elab-angular-core';

enum DfmAdministrationTabsEnum {
  GENERAL = 'general',
  CONTACT = 'contact',
  TEAM = 'team',
  GALLERY = 'gallery',
  FAQ = 'faq',
}

@Component({
  selector: 'dfm-administration',
  templateUrl: './dfm-administration.page.html',
  styleUrls: ['./dfm-administration.page.scss'],
})
export class DfmAdministrationPage implements OnInit, OnDestroy {
  danceSchool: DanceSchoolInterface;
  activeTabIndex: number;

  locations: LocationInterface[];

  private subscriptions = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly danceSchoolLocationService: DanceSchoolLocationService,
    private readonly dfmStateService: DfmStateService
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.dfmStateService.getAsyncSelectedDm(), this.loadDanceSchool.bind(this)));
  }

  loadDanceSchool(danceSchool: DanceManagerInterface): void {
    this.danceSchool = danceSchool;
    this.getLocationsOfCurrentDanceManager();
    this.getHashtag();
  }

  getHashtag(): void {
    this.route.fragment.subscribe((hashtag) => {
      if (hashtag != null) {
        hashtag = hashtag.toLowerCase();
        switch (hashtag) {
          case DfmAdministrationTabsEnum.GENERAL: {
            this.activeTabIndex = 0;
            break;
          }
          case DfmAdministrationTabsEnum.CONTACT: {
            this.activeTabIndex = 1;
            break;
          }
          case DfmAdministrationTabsEnum.TEAM: {
            this.activeTabIndex = 2;
            break;
          }
          case DfmAdministrationTabsEnum.FAQ: {
            this.activeTabIndex = 3;
            break;
          }
          case DfmAdministrationTabsEnum.GALLERY: {
            this.activeTabIndex = 4;
            break;
          }
          default: {
            this.activeTabIndex = 0;
            break;
          }
        }
      }
    });
  }

  changeHashTag(index: number): void {
    let hash = '#';
    switch (index) {
      case 0: {
        hash += DfmAdministrationTabsEnum.GENERAL;
        break;
      }
      case 1: {
        hash += DfmAdministrationTabsEnum.CONTACT;
        break;
      }
      case 2: {
        hash += DfmAdministrationTabsEnum.TEAM;
        break;
      }
      case 3: {
        hash += DfmAdministrationTabsEnum.FAQ;
        break;
      }
      case 4: {
        hash += DfmAdministrationTabsEnum.GALLERY;
        break;
      }
    }
    window.location.hash = hash;
  }

  getLocationsOfCurrentDanceManager(): void {
    this.danceSchoolLocationService.getAsyncCurrentDanceSchoolLocations().subscribe((locations) => {
      this.locations = locations;
    });
    this.danceSchoolLocationService.getAllDanceSchoolLocationsByDanceSchoolId(this.danceSchool?.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
