export const dfAppRoute = '';
export const dfLoginRoute = 'login';
export const dfMagicLoginRoute = 'magic-login';
export const dfRegisterRoute = 'register';
export const dfSignUpRoute = 'signup'; //für wordpress
export const dfForgotPasswordRoute = 'forgot-password';
export const dfResetPasswordRoute = 'reset-password';
export const dfMagicLinkLoginRoute = 'magic-link-login';
export const dfConfirmEmailRoute = 'confirm-email';
export const dfCourseRoute = 'dance-class';
export const dfDanceEventRoute = 'dance-event';
export const dfSearchRoute = 'search';
export const dfUserRoute = 'user';

export const dfmDanceManagerRoute = 'dance-manager';
export const dfmAdministrationRoute = 'administration';
export const dfmMarketPlaceRoute = 'market-place';
export const dfmPayoutRoute = 'payouts';
export const dfmLocationRoute = 'locations';
export const dfmEventRoute = 'events';
export const dfmEventTicketRoute = 'event-tickets';
export const dfmMessageRoute = 'messages';
export const dfmNotificationRoute = 'notifications';
export const dfmCourseRoute = 'courses';
export const dfmSettingsRoute = 'settings';
export const dfmUserGroupsRoute = 'user-groups';

export const dfCreateDanceManagerRoute = 'create-dance-manager';
export const DanceflavorsDanceManagersDetailUrlNameRoute = ((urlName: string): string => DanceflavorsDanceManagerDetailUrlNameRouteHelper(urlName));

export function DanceflavorsDanceManagerDetailUrlNameRouteHelper(urlName: string = ':' + DanceflavorsDanceManagersDetailUrlNameRoute): string {
  return `/${dfAppRoute}/${dfmDanceManagerRoute}/${urlName}`;
}
