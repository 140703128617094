import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionChat2Component } from './auction-chat2.component';
import { RouterLink } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { ChatModule } from '../../components';

@NgModule({
  declarations: [AuctionChat2Component],
  imports: [
    CommonModule, 
    RouterLink,
    ChatModule,
    MatDialogModule,
  ],
  providers: [
  ],
  exports: [AuctionChat2Component]
})
export class AuctionChat2Module {
  constructor() {
    console.log('AuctionChatModule initialized');
  }
}
