import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthStateService, ConfirmationDialogComponent, CoursesHttpService, DanceManagerHttpService, dfAppRoute, getSubscription, TicketOrderService, TransferDialogComponent, TransferTypeEnum, UserPassHttpService } from '@platri/dfx-angular-core';
import {
  CourseInterface,
  CourseStatusEnum,
  CreateTicketOrderRequestDto,
  DanceSchoolInterface,
  PassStatusEnum,
  UserPassInterface,
  UserPassStatusEnum,
  UsersInterface,
  wait
} from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime, Interval } from 'luxon';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-inventory-pass-detail',
  templateUrl: './inventory-pass-detail.page.html',
  styleUrls: ['./inventory-pass-detail.page.scss']
})
export class InventoryPassDetailPage implements OnInit, OnDestroy {
  currentUser: UsersInterface;
  currentUserPass: UserPassInterface = null;
  currentPassOrganizer: DanceSchoolInterface = null;
  activePassCourses: CourseInterface[] = [];
  shownStamps: number[] = [];

  routerParams!: Params;
  passIdFromParam: string;
  isLoading = false;
  hasFailedLoadingNoConnection = false;

  hasTruncateDescription = false;
  isDescriptionTruncated = false;
  descriptionTruncateLength = 20;
  
  hasTruncateCourses = false;
  isCoursesTruncated = false;
  coursesTruncateLength = 3;
  
  subscriptions = new Subscription();
  
  userPassStatusEnum = UserPassStatusEnum;
  passStatusEnum = PassStatusEnum;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthStateService,
    private readonly userPassHttpService: UserPassHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly coursesHttpService: CoursesHttpService,
    private readonly ticketOrderService: TicketOrderService,
    private readonly translocoService: TranslocoService,
    private readonly matDialog: MatDialog,
    private readonly matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.passIdFromParam = this.routerParams['passId'];
    if (!this.passIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoading = true;
    this.hasFailedLoadingNoConnection = false;
          
    this.subscriptions.add(
      this.userPassHttpService.getUserPassByIdV2(this.passIdFromParam).subscribe(userPass => {
        if (!userPass) {
          this.router.navigate(['/inventory']);
        }
        this.currentUserPass = userPass;
        this.createStampsShownArray();
        this.loadOrganizer();
        
        const targetIds: string[] = Array.from(new Set(this.currentUserPass.targets?.map(pt => pt.targetId) ?? []));
        this.loadLinkedCourses(targetIds);

        this.hasTruncateDescription = this.currentUserPass?.description?.split(' ').length > this.descriptionTruncateLength;
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
        this.router.navigate(['/inventory']);
      })
    );
  }

  createStampsShownArray(): void {
    const usageCount = this.currentUserPass.usageCount;
    const usedCount = this.currentUserPass.userPassScans?.length;
    
    const displayCount = usageCount > 10 ? 10 : usageCount;
    let start = 0;
    if (usedCount > 5) {
      start = usedCount - (usedCount % 5);
    }
    if (start + 10 > usageCount) {
      start = usageCount - 10;
    }
    start = Math.max(start, 0);
    for (let i = 0; i < displayCount; i++) {
      this.shownStamps[i] = start + 1 + i;
    }
  }
  
  loadOrganizer(): void {
    console.log(this.currentUserPass.danceManagerId);
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceManagerById(this.currentUserPass.danceManagerId).subscribe(organizer => {
        this.currentPassOrganizer = organizer;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
      })
    );
  }
  
  loadLinkedCourses(targetIds: string[]): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(targetIds).subscribe(courses => {
        this.activePassCourses = courses;
        this.hasTruncateCourses = this.activePassCourses.length > this.coursesTruncateLength;
      }, error => {
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
      })
    );
  }

  onBackButtonClick(): void {
    this.router.navigate(['inventory'], {fragment: 'passes'});
  }

  routeToPassBuyout(): void {
    const createTicketOrderDto: CreateTicketOrderRequestDto = {
      passOrderItems: [],
      ticketOrderItems: []
    };

    createTicketOrderDto.passOrderItems.push({
      passId: this.currentUserPass.id,
      quantity: 1
    });
    
    this.ticketOrderService.createTicketOrder(createTicketOrderDto, this.currentUserPass.targets[0].targetId);
  }
  
  openRefundPassDialog(): void {
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: {
          cancelButtonColor: '',
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.NO'),
          submitButtonColor: 'warn',
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.YES'),
          text: this.translocoService.translate('USER.WITHDRAWAL_ORDER_DIALOG_TEXT')
        }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.userPassHttpService.refundById(this.currentUserPass.id)
            .subscribe({
              next: () => {
                wait(500).then(() => {
                  this.router.navigate(['/inventory'], {fragment: 'passes'});
                  this.matSnackBar.open(this.translocoService.translate('USER.WITHDRAWAL_ORDER_SUCCESS_TEXT'));
                });
              },
              error: () => {
                this.matSnackBar.open(this.translocoService.translate('USER.WITHDRAWAL_ORDER_ERROR_TEXT'));
              }
            });
        }
      });
  }
  
  openTransferPassDialog(): void {
    const dialogRef = this.matDialog.open(TransferDialogComponent, {
      panelClass: 'responsive-dialog',
      data: {
        transferType: TransferTypeEnum.PASS
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result;
      if (dialogResult) {
        this.userPassHttpService.transferById(this.currentUserPass.id, dialogResult.selectedUser.id).subscribe({
          next: () => {
            this.matSnackBar.open(this.translocoService.translate('USER.TRANSFER_PASS_SNACKBAR'));
            this.loadData();
          }
        });
      }
    });
  }
  
  canBeTransferred(): boolean {
    return !this.currentUserPass?.trial && this.currentUserPass?.status === UserPassStatusEnum.PAID && this.currentUserPass?.userPassScans?.length === 0;
  }
  
  canBeWithdrawn(): boolean {
    if (this.currentUserPass.trial) {
      return false;
    }
    
    const dateTimeNow = DateTime.now().setZone('UTC');
    const dateTimePurchase = DateTime.fromJSDate(new Date(this.currentUserPass.createdAt)).setZone('UTC');
    const diffInDays = Interval.fromDateTimes(dateTimePurchase, dateTimeNow).length('day');
    if (diffInDays >= 14) {
      return false;
    }
    return this.currentUserPass?.userPassScans?.length === 0;
  }
  
  protected readonly CourseStatusEnum = CourseStatusEnum;
  protected readonly UserPassStatusEnum = UserPassStatusEnum;
}
