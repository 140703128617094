<div class="flex-column-center-center h-100">
  <h1>Timed out!</h1>
  <h2>
    It seems like either our Server is not responding
    <br />
    or there may be some addons in your browser blocking the connection.
    <br />
    Please try again later.
  </h2>
  <p>
    Here is no place to dance.
    <br />
    <br />
    If you want to continue looking for places to dance at or persons to dance
    with,
    <br />
    <br />
    you can use the link to go back:
    <br />
    <br />
    <a href="https://www.danceflavors.com">www.danceflavors.com</a>
  </p>
</div>
