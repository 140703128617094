
export class UnreadDmMessageDetailDto {
  danceManagerId: string;
  amountOfUnreadMessages: number;
}
export class UnreadMessagesDto {
  amountOfUnreadDmMessages: number;
  amountOfUnreadUserMessages: number;
  amountOfUnreadDmMessageDetails: UnreadDmMessageDetailDto[];
}
