import { Directive, ElementRef, Renderer2, AfterViewInit, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[richTextEllipsis]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class RichTextEllipsisPipe implements AfterViewInit {
  @Input() ellipsis = true;
  @Input() maxLines = 3;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.ellipsis) {
      this.applyEllipsis();
      this.checkEllipsis();
    }
  }

  private applyEllipsis(): void {
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
    this.renderer.setStyle(this.el.nativeElement, 'display', '-webkit-box');
    this.renderer.setStyle(this.el.nativeElement, '-webkit-line-clamp', this.maxLines.toString());
    this.renderer.setStyle(this.el.nativeElement, '-webkit-box-orient', 'vertical');
    this.renderer.setStyle(this.el.nativeElement, 'text-overflow', 'ellipsis');
  }

  private checkEllipsis(): void {
    setTimeout(() => {
      const hasEllipsis = this.el.nativeElement.scrollHeight > this.el.nativeElement.clientHeight;
      if (hasEllipsis) {
        this.truncateText();
      }
    });
  }

  private truncateText(): void {
    const element = this.el.nativeElement;
    const originalText = element.innerHTML;
    const words = originalText.split(' ');

    element.innerHTML = '';
    let truncatedText = '';

    for (let i = 0; i < words.length; i++) {
      element.innerHTML = truncatedText + ' ' + words[i];
      if (element.scrollHeight > element.clientHeight) {
        element.innerHTML = truncatedText.trim() + '...';
        break;
      }
      truncatedText += ' ' + words[i];
    }
  }
}
