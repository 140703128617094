import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'dateTimeStringDanceEvent',
  pure: false
})
export class DateTimeStringDanceEventPipe implements PipeTransform {

  constructor(private transloco: TranslocoService) {}

  transform(
    startDate: Date,
    durationInMinutes: number
  ): string {
    const activeLang = this.transloco.getActiveLang();
    const localeStr = activeLang === 'de' ? 'de' : 'en';
    const now = DateTime.now().setLocale(localeStr);
    
    const startDateTime = DateTime.fromJSDate(new Date(startDate), { zone: 'UTC' })
      .setLocale(localeStr);

    const endDateTime = startDateTime.plus({ minutes: durationInMinutes });
    const isMultiDay = this.isDifferentDayAfter5am(startDateTime, endDateTime);

    if (isMultiDay) {
      return this.formatMultiDayEvent(startDateTime, endDateTime, durationInMinutes, localeStr, now);
    }

    return this.formatSingleDayEvent(startDateTime, endDateTime, localeStr, now);
  }

  private formatMultiDayEvent(
    start: DateTime,
    end: DateTime,
    duration: number,
    locale: string,
    now: DateTime
  ): string {
    const startFormat = this.formatDatePart(start, locale, now);
    const endFormat = this.formatDatePart(end, locale, now);

    return duration === -1
           ? startFormat
           : this.transloco.translate('GENERIC_WRAPPER.DATE_RANGE', { start: startFormat, end: endFormat });
  }

  private formatDatePart(date: DateTime, locale: string, now: DateTime): string {
    const yearFormat = date.year === now.year ? '' : locale === 'de' ? ' yyyy' : ', yyyy';
    return locale === 'de'
           ? date.toFormat(`d. LLL${yearFormat}`)
           : date.toFormat(`LLL d${yearFormat}`);
  }

  private formatSingleDayEvent(
    start: DateTime,
    end: DateTime,
    locale: string,
    now: DateTime
  ): string {
    if (this.isNowBetween(start, end, now)) {
      return this.transloco.translate('GENERIC_WRAPPER.CURRENTLY_TAKING_PLACE');
    }

    const timeFormat = locale === 'de' ? 'HH:mm' : 'hh:mm a';
    const timeString = `${start.toFormat(timeFormat)}${locale === 'de' ? ' Uhr' : ''}`;

    if (this.isToday(start, now)) {
      return this.transloco.translate('GENERIC_WRAPPER.TODAY_AT', { time: timeString });
    }

    if (this.isTomorrow(start, now)) {
      return this.transloco.translate('GENERIC_WRAPPER.TOMORROW_AT', { time: timeString });
    }

    if (this.isThisWeek(start, now)) {
      const dayName = start.toFormat('cccc');
      return this.transloco.translate('GENERIC_WRAPPER.THIS_DAY_AT', { day: dayName, time: timeString });
    }

    return this.formatDefaultCase(start, locale, timeString, now);
  }

  private formatDefaultCase(
    date: DateTime,
    locale: string,
    timeString: string,
    now: DateTime
  ): string {
    const isGerman = locale === 'de';
    const yearPart = date.year === now.year ? '' : isGerman ? ' yyyy' : ', yyyy';
    const dateFormat = isGerman
                       ? `ccc, d. LLL${yearPart}`
                       : `ccc, LLL d${yearPart}`;

    const formattedDate = date.toFormat(dateFormat);
    return isGerman
           ? `${formattedDate} um ${timeString}`
           : `${formattedDate} at ${timeString}`;
  }

  private isDifferentDayAfter5am(start: DateTime, end: DateTime): boolean {
    const adjustDate = (date: DateTime) =>
      date.hour < 5
      ? date.minus({ days: 1 }).startOf('day').plus({ hours: 5 })
      : date.startOf('day').plus({ hours: 5 });

    return !adjustDate(start).hasSame(adjustDate(end), 'day');
  }

  private isToday(date: DateTime, now: DateTime): boolean {
    return date.hasSame(now, 'day');
  }

  private isTomorrow(date: DateTime, now: DateTime): boolean {
    return date.hasSame(now.plus({ days: 1 }), 'day');
  }

  private isThisWeek(date: DateTime, now: DateTime): boolean {
    const weekStart = now.startOf('week');
    const weekEnd = now.endOf('week');
    return date >= weekStart && date <= weekEnd;
  }

  private isNowBetween(start: DateTime, end: DateTime, now: DateTime): boolean {
    return now >= start && now <= end;
  }
  
}
