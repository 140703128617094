import { Routes } from '@angular/router';
import { AuthGuard } from '@platri/dfx-angular-core';

export const dfmAppRoutes: Routes = [
  {
    path: 'dance-manager/process-subscription',
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.ProcessSubscriptionPageModule),
  },
  {
    path: 'dance-manager',
    canActivate: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('../').then((m) => m.FrontendDfmAppModule),
  },
  {
    path: 'create-dance-manager',
    canActivate: [AuthGuard],
    loadChildren: (): any => import('@platri/df-angular-create-dance-manager').then((m) => m.FrontendCreateDanceManagerModule)
  }
];
