import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FacebookSignInButtonComponent } from './facebook-sign-in-button.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [FacebookSignInButtonComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        MatCheckboxModule,
        TranslocoDirective,
    ],
  exports: [FacebookSignInButtonComponent]
})
export class FacebookSignInButtonModule { }
