<ng-container *transloco="let t">
  <div *ngIf="isOwnerOrAdmin" (click)="initializeFormGroupVatSubscriptions()">
    <form [formGroup]="editFormGroup" (ngSubmit)="onSubmit()">
      <div class="pt3 mobile-align">
        <div class="w-100 flex-column align-start">
          <strong><span class="title">
            {{ t('DANCE_MANAGER_SETTINGS.VAT_TITLE') }}
          </span></strong>
          <span class="subtitle">
            {{ t('DANCE_MANAGER_SETTINGS.VAT_DESCRIPTION') }}
          </span>
        </div>
      </div>
      <div class="pt3 mobile-align" style="align-items: baseline; gap: 20px;">
        <mat-form-field appearance="outline" style="max-width: 100px;">
          <div class="mobile-align" style="align-items: center;">
            <input matInput id="vat-input" type="numericInput" [formControl]="editFormGroup.controls.vat">
            <span style="padding-top: 3px;">%</span>
          </div>
        </mat-form-field>
      </div>
    </form>
  </div>
  
  <div class="pt3 mobile-align">
    <div class="w-100 flex-column align-start">
      <strong><span class="title">
        {{ t('DANCE_MANAGER_SETTINGS.DELETE_MANAGER_TITLE') }}
      </span></strong>
      <span class="subtitle">
        {{ t('DANCE_MANAGER_SETTINGS.DELETE_MANAGER_DESCRIPTION') }}
      </span>
    </div>
    <div class="align-end stretch-on-mobile">
      <df-shared-lib-button
        class="w-100"
        [minWidth]="'220px'"
        [buttonLabel]="t('DANCE_MANAGER_SETTINGS.DELETE_MANAGER_TITLE')"
        (buttonClicked)="delete()"
        [materialButtonStyleType]="'outlined'"
      ></df-shared-lib-button>
    </div>
  </div>
  
  <div #unsavedChangesBar class="unsaved-changes-bar" *ngIf="isVatChanged">
    <span class="unsaved-changes-span">{{ t('GENERIC_WRAPPER.UNSAVED_CHANGES') }}</span>
    <div style="display: flex; gap: 10px; align-items: center;">
      <button style="color: white;" mat-button type="button" (click)="resetChanges()">{{ t('GENERIC_WRAPPER.RESET') }}</button>
      <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" (buttonClicked)="saveChanges()"></df-shared-lib-button>
    </div>
    
  </div>
</ng-container>
