<ng-container *transloco="let t">
  <div class="flex-column" [formGroup]="formGroup">
    <div
      class="px3 flex-column"
      style="gap: 2rem;"
    >
      <div [formGroup]="participationFormGroup">
        <div class="flex-column pt2">
          <mat-checkbox
            formControlName="isAgeRestricted"
            color="primary"
            [title]="
              t((participationFormGroup.disabled
                ? 'DANCE_MANAGER_EVENT.NO_CHANGES_POSSIBLE'
                : ''
              ))
            "
          >
            {{ t('DANCE_MANAGER_EVENT.AGE_RESTRICTION') }}
          </mat-checkbox>
          <div
            *ngIf="participationFormGroup.value.isAgeRestricted"
            [formGroup]="ageRestrictionFormGroup"
            class="mt1 mb2 flex-column"
          >
            <df-shared-lib-level-slider-material
              class="w-35"
              [disabled]="
                !participationFormGroup.controls.isAgeRestricted.value ||
                participationFormGroup.disabled
              "
              [showLevels]="false"
              [unit]="t('GENERIC_WRAPPER.YEAR_PLURAL')"
              [fromLevel]="ageRestrictionFormGroup.getRawValue().fromAge"
              [toLevel]="ageRestrictionFormGroup.getRawValue().toAge"
              [min]="0"
              [max]="100"
              [showRange]="true"
              (setLevelEvent)="setAgeLevel($event)"
            ></df-shared-lib-level-slider-material>
          </div>
        </div>
        <div class="mt3 flex-column">
          <mat-checkbox
            formControlName="isGenderRestricted"
            color="primary"
            [title]="
              t((participationFormGroup.disabled
                ? 'DANCE_MANAGER_EVENT.NO_CHANGES_POSSIBLE'
                : ''
              ))
            "
          >
            {{ t('DANCE_MANAGER_EVENT.GENDER_RESTRICTION') }}
          </mat-checkbox>
          <span
            *ngIf="participationFormGroup.value.isGenderRestricted"
            class="fs14"
            >{{ t('DANCE_MANAGER_EVENT.ONLY_ALLOWED_ARE') }}</span
          >
          <div
            *ngIf="participationFormGroup.value.isGenderRestricted"
            [formGroup]="genderRestrictionFormGroup"
            class="pt2 flex-column"
          >
            <mat-checkbox
              formControlName="isMaleRestricted"
              class="mb-10"
              color="primary"
              [disableControl]="
                !genderRestrictionFormGroup.value ||
                participationFormGroup.disabled
              "
              [title]="
                t((participationFormGroup.disabled
                  ? 'DANCE_MANAGER_EVENT.NO_CHANGES_POSSIBLE'
                  : ''
                ))
              "
            >
              {{ t('GENERIC_WRAPPER.MALE') }}
            </mat-checkbox>
  
            <mat-checkbox
              formControlName="isFemaleRestricted"
              class="mb-10"
              color="primary"
              [disableControl]="
                !genderRestrictionFormGroup.value ||
                participationFormGroup.disabled
              "
              [title]="
                t((participationFormGroup.disabled
                  ? 'DANCE_MANAGER_EVENT.NO_CHANGES_POSSIBLE'
                  : ''
                ))
              "
            >
              {{ t('GENERIC_WRAPPER.FEMALE') }}
            </mat-checkbox>
  
            <mat-checkbox
              formControlName="isDiverseRestricted"
              class="mb-10"
              color="primary"
              [disableControl]="
                !genderRestrictionFormGroup.value ||
                participationFormGroup.disabled
              "
              [title]="
                t((participationFormGroup.disabled
                  ? 'DANCE_MANAGER_EVENT.NO_CHANGES_POSSIBLE'
                  : ''
                ))
              "
            >
              {{ t('GENERIC_WRAPPER.OTHER') }}
            </mat-checkbox>
          </div>
        </div>
        <div></div>
      </div>
      <div [formGroup]="clothingRestrictionFormGroup" class="mb2">
        <mat-checkbox
          formControlName="isRestricted"
          color="primary"
          class="mb2"
          >{{ t('DANCE_MANAGER_EVENT.SPECIAL_CLOTHING') }}</mat-checkbox
        >
        <div
          *ngIf="clothingRestrictionFormGroup.value.isRestricted"
          class="flex-column"
        >
          <df-shared-lib-quill-editor
            [enableMentions]="true"
            [id]="'clothing-restriction'"
            [placeholder]="'DANCE_MANAGER_EVENT.SPECIAL_CLOTHING_SUBTEXT'"
            [customFormControlName]="'restriction'"
          ></df-shared-lib-quill-editor>
        </div>
      </div>
    </div>
    <div
      class="flex-responsive-container"
      style="padding-bottom: 10px;"
    >
      <div
        class="px3 flex-responsive-button-container"
        style="gap: 1rem;"
      >
        <df-shared-lib-button
          *ngIf="creationStepper"
          [buttonLabel]="t('GENERIC_WRAPPER.BACK')"
          (buttonClicked)="loading || onCancelClick.emit()"
          materialButtonStyleType="outlined"
        ></df-shared-lib-button>
        <df-shared-lib-button
          *ngIf="creationStepper"
          [buttonLabel]="t('GENERIC_WRAPPER.SAVE_AS_DRAFT')"
          [loading]="draftButtonClicked && loading"
          [isDisabled]="loading || formGroup.invalid"
          (buttonClicked)="draftButtonClicked = true; onSubmitClick.emit(false)"
        ></df-shared-lib-button>
        <df-shared-lib-button
          *ngIf="creationStepper"
          [buttonLabel]="t('GENERIC_WRAPPER.SAVE_AND_PUBLISH')"
          [loading]="publishButtonClicked && loading"
          [isDisabled]="loading || formGroup.invalid"
          (buttonClicked)="publishButtonClicked = true; onSubmitClick.emit(true)"
          id="save-publish-button"
        ></df-shared-lib-button>
        <df-shared-lib-button
          *ngIf="!creationStepper"
          [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
          [isDisabled]="loading || formGroup.invalid"
          [loading]="loading"
          (buttonClicked)="onSubmitClick.emit(true)"
        ></df-shared-lib-button>
      </div>
    </div>
  </div>
</ng-container>
