import { Component, Input } from '@angular/core';
import { OpeningHoursInterface } from '@platri/df-common-shared-graphql';
import { ENGLISH_LANGUAGE } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-opening-hours-view',
  templateUrl: './opening-hours-view.component.html',
  styleUrls: ['./opening-hours-view.component.scss'],
})
export class OpeningHoursViewComponent {

  @Input() openingHours: OpeningHoursInterface;

  public objectKeys = Object.keys;

  showTimeInEnglishFormat = false;

  constructor(
    private translocoService: TranslocoService
  ) {
    this.showTimeInEnglishFormat = this.translocoService.getActiveLang().toUpperCase() === ENGLISH_LANGUAGE;
  }

}
