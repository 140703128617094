export const environmentForWeb = {
  production: true,
  env: 'prod',
  host: 'https://danceflavors.com/app',
  apiAppointmentUrl: '/api-appointment',
  apiDanceClassUrl: '/api-dance-class',
  apiDanceSchoolUrl: '/api-dance-school',
  apiAdministrationUrl: '/api-administration',
  apiNotificationsUrl: '/api-notification',
  apiRoomRentalsUrl: '/api-room-rental',
  apiOrdersUrl: '/api-order',
  apiPaymentsUrl: '/api-payment',
  apiWebsocketsUrl: '/api-websocket',
  apiMessageUrl: '/api-message',
  apiChatUrl: '/api-chat',
  apiChatUrlWS: 'wss://www.danceflavors.com/chat-websocket',
  apiUsersUrl: '/api-user',
  apiAuthUrl: '/api-auth',
  apiDanceEventsUrl: '/api-dance-event',
  apiCoursesUrl: '/api-course',
  apiTicketUrl: '/api-ticket',
  apiFollowUrl: '/api-follow',
  redisHost: 'websocket-redis',
  mobileAppLinks: {
    android:
      'https://play.google.com/store/apps/details?id=com.danceflavors_app',
    ios: 'https://apps.apple.com/de/app/danceflavors/id1583746248',
  },
  googleMapsApiKey: 'AIzaSyDandXL1T0gKonQ63p4qxVbZSu4SfC87ug',
  stripePublishableKey:
    'pk_live_51LfLOkD1fTq5Z6HuFf3hfsVcCpFHrCCUd9KAfvYiclpHBeps7Klpo0hgMMaICzQaBFOKkmI5QZVL5eQ8N8pQvbj700Aiaf6xhq',
  stripePricingTableIdGerman: 'prctbl_1MLouxD1fTq5Z6HuyL5a1WLS',
  stripePricingTableIdEnglish: 'prctbl_1MQnpAD1fTq5Z6HukVmJzLkV',
  multiChatEnabled: false,
  paypalClientId:
    'AST363GTp0f4DIpaYwSKsGD8LY-ZxYvR1zbxskAnA2awYX9mEzb7UwQMfu9uPN2IyZuzCGGTUnN5OZTZ',
  enableStripeSubscription: true,
  googleAuthClientId:
    '694808709391-6jrnen0g02gtps37ksu8c8b6ldhc3ksh.apps.googleusercontent.com',
  apiKey: 'ABCDEFG123434oisojdfoiasdjfoiasjo',
  apiOmniWs: 'wss://www.danceflavors.com',
  dmPassSubscriptionFeature: true,
  participateClasses: true
};

export const environmentForAdmin = {
  production: true,
  apiUrl: '/api',
  apiNotificationsUrl: '/api-notification',
  apiRoomRentalsUrl: '/api-room-rental',
  apiWebsocketsUrl: '/api-websocket',
  apiOrdersUrl: '/api-order',
};
