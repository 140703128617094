import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddImageDialogComponent } from './add-image-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule } from '../buttons';
import { ImageUploadCropperModule } from '../image-cropper';

@NgModule({
  exports: [AddImageDialogComponent],
  declarations: [AddImageDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    DfSharedButtonModule,
    ImageUploadCropperModule,
  ],
})
export class AddImageDialogModule {}
