import { Component, Input } from '@angular/core';
import { DanceSchoolDto, DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER, DEFAULT_STUDIO_PICTURE_PLACEHOLDER } from '@platri/df-common-core';

@Component({
  selector: 'df-search-studios-list-item',
  templateUrl: './search-studios-list-item.component.html',
  styleUrls: ['./search-studios-list-item.component.scss']
})
export class SearchStudiosListItemComponent {
  @Input() danceSchool: DanceSchoolDto;

  protected readonly DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;
  protected readonly DEFAULT_DANCE_MANAGER_TITLE_PICTURE_PLACEHOLDER = DEFAULT_STUDIO_PICTURE_PLACEHOLDER;
}
