import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AmenityDto, environmentForWeb } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class AmenityHttpService {
  private apiBaseUrl = environmentForWeb.apiAdministrationUrl;

  constructor(private http: HttpClient) {
  }

  getAllAmenities(): Observable<AmenityDto[]> {
    return this.http.get<AmenityDto[]>(
      this.apiBaseUrl + '/amenities'
    );
  }

  deleteAmenityByIconKey(
    iconKey: string
  ): Observable<void> {
    return this.http.delete<void>(
      this.apiBaseUrl + '/amenities/' + iconKey
    );
  }

  postAmenity(
    amenity: AmenityDto
  ): Observable<AmenityDto> {
    return this.http.post<AmenityDto>(
      this.apiBaseUrl + '/amenities',
      amenity
    );
  }

  getAmenityByIconKey(
    iconKey: string
  ): Observable<AmenityDto> {
    return this.http.get<AmenityDto>(
      this.apiBaseUrl + '/amenities/' + iconKey
    );
  }

  putAmenityByIconKey(
    iconKey: string,
    amenity: AmenityDto
  ): Observable<AmenityDto> {
    return this.http.put<AmenityDto>(
      this.apiBaseUrl + '/amenities/' + iconKey,
      amenity
    );
  }
}
