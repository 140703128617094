<ng-container *transloco="let t">
  <div *ngIf="coHostsToDisplay?.length > 0" class="dance-event-co-hosts-container pt-32">
    <span class="dance-event-co-hosts-title">{{ t('GENERIC_WRAPPER.CO_HOSTS')}}</span>
    <div class="dance-event-co-hosts-list-container">
      <ng-container *ngFor="let coHost of coHostsToDisplay; let i = index">
          <div *ngIf="i < 2 || showAll" class="link dance-event-co-hosts-list-item" [routerLink]="['/', '@' + coHost.danceManager.urlName]">
            <img [src]="coHost.danceManager.imageUrl && coHost.danceManager.imageUrl !== '' ? coHost.danceManager.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER">
            <span>{{coHost.danceManager.name}}</span>
          </div>
      </ng-container>
    </div>
    <span *ngIf="coHostsToDisplay.length > 2" class="dance-event-co-hosts-show-more-less-text" (click)="showAll = !showAll">{{ showAll ? ( t('GENERIC_WRAPPER.SHOW_LESS')) : ( t('GENERIC_WRAPPER.SHOW_ALL'))}}</span>
  </div>
</ng-container>
