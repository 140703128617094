import { DanceManagerCategoryEnum, DanceSchoolInterface, DanceSchoolStatusEnum, DanceSchoolTypeEnum } from '../../..';
import { AddressDto, DanceManagerSocialLinkDto, ImageDto } from '../../..';

export class UpdateDanceSchoolRequestDto implements Partial<DanceSchoolInterface> {
  name?: string;
  isPublic?: boolean;
  urlName?: string;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  danceSchoolType?: DanceSchoolTypeEnum;
  danceSchoolStatus?: DanceSchoolStatusEnum;
  imageUrl?: string;
  imageGalleryUrls?: string[];
  email?: string;
  socialLinks?: DanceManagerSocialLinkDto[];
  description?: string;
  teamDescription?: string;
  titleImage?: ImageDto;
  danceManagerCategory?: DanceManagerCategoryEnum[];
  businessAddress?: AddressDto;
  danceStyleIds?: string[];
  vat: number;
}
