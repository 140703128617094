import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environmentForWeb, UserPassDto } from '@platri/df-common-core';

@Injectable({ providedIn: 'root' })
export class TicketCoursesHttpService {
  private SERVICE_URL = `${environmentForWeb.apiTicketUrl}/courses`;

  constructor(private http: HttpClient) {}

  getAvailableUserPasses(targetId: string, appointmentDate: Date): Observable<UserPassDto[]> {
    return this.http.post<UserPassDto[]>(`${this.SERVICE_URL}/scannable-user-passes`, {targetId, appointmentDate});
  }
}
