<ng-container *transloco="let t">
    <form [formGroup]="formGroup" (ngSubmit)="saveSocialLink()">
        <div style="flex-direction: row; box-sizing: border-box; display: flex; gap: 25px;">
            <mat-form-field appearance="outline">
                <mat-select formControlName="type" [placeholder]="t('GENERIC_WRAPPER.SELECT')" disableOptionCentering (selectionChange)="updateUrlControlValidators()" required>
                    <ng-container *ngFor="let socialLink of availableUserSocialLinkTypeEnum | keyvalue">
                        <mat-option [value]="socialLink.value">{{ socialLink.value | titlecase }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline">
                <input size="500" formControlName="url" autocomplete="off" matInput [placeholder]="'Url'"/>
                <mat-error *ngIf="urlControl.errors?.invalidUrl">
                  {{t('GENERIC_WRAPPER.INVALID_URL') }}
                </mat-error>
            </mat-form-field>
            <div style="place-content: stretch center; align-items: stretch; flex-direction: row; box-sizing: border-box; display: flex;">
                <df-shared-lib-button [height]="'60px'" [buttonLabel]="t('GENERIC_WRAPPER.ADD')" [materialButtonStyleType]="'flat'" (buttonClicked)="saveSocialLink()" [isDisabled]="formGroup.invalid"></df-shared-lib-button>
            </div>
        </div>
    </form>
</ng-container>
