import { Component, OnDestroy, OnInit } from '@angular/core';
import { CourseInterface, CreateTicketOrderRequestDto, DanceManagerInterface, DanceManagerPassesPublicResponseDto, DEFAULT_STUDIO_PICTURE_PLACEHOLDER, emailRegex, PassTargetInterface, UserAppLangEnum, UsersInterface } from '@platri/df-common-core';
import { AuthStateService, CoursesHttpService, DanceManagerHttpService, dfAppRoute, dfLoginRoute, mustMatch, PassHttpService, TicketOrderService } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'df-dance-manager-passes-page',
  templateUrl: './dance-manager-passes.page.html',
  styleUrls: ['./dance-manager-passes.page.scss']
})
export class DanceManagerPassesPage implements OnInit, OnDestroy {
  danceManager: DanceManagerInterface;
  danceManagerPasses: DanceManagerPassesPublicResponseDto[];
  passCourses: CourseInterface[];
  formGroup: UntypedFormGroup;

  selectedPasses: DanceManagerPassesPublicResponseDto[] = [];
  subtotal: number;
  fees: number;
  
  currentLang: UserAppLangEnum;
  currentUser: UsersInterface;

  guestEmailForm = this.fb.group(
    {
      email: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]],
      confirmEmail: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]]
    },
    {
      validators: mustMatch('email', 'confirmEmail')
    }
  );
  DEFAULT_DANCE_MANAGER_TITLE_PICTURE_PLACEHOLDER = DEFAULT_STUDIO_PICTURE_PLACEHOLDER;

  creationTicketOrderLoading = false;
  showGuestInputFields = false;
  
  subscriptions = new Subscription();
  isLoading = true;
  
  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly authService: AuthStateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly passHttpService: PassHttpService,
    private readonly coursesHttpService: CoursesHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly ticketOrderService: TicketOrderService
  ) {}
  
  ngOnInit(): void {
    this.isLoading = true;
    this.currentUser = this.authService.getSyncCurrentUser();
    this.loadDanceManager(this.activatedRoute.snapshot.params['url-name']);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadDanceManager(urlName: string): void {
    if (urlName) {
      this.danceManagerHttpService.getDanceManagerByUrlNameForPublicView(urlName.replace('@', '')).subscribe({
        next: (value) => {
          this.danceManager = value;
          this.loadPassesOfDanceManager();
        }
      });
    }
  }

  loadPassesOfDanceManager(): void {
    this.passHttpService.getAllByDanceManagerIdPublicView(this.danceManager.id).subscribe(passes => {
      this.danceManagerPasses = passes;
      const targetIds: string[] = Array.from(new Set(this.danceManagerPasses?.flatMap(pass =>pass.passTargets?.map(target => target.targetId) ?? [])));
      this.getPassCourses(targetIds);
      this.initializeForm();
      this.initializeFormSubscription();
      this.loadTicketOrder();
    });
  }

  getPassCourses(targetIds: string[]): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(targetIds).subscribe(courses => {
        this.passCourses = courses;
        this.isLoading = false;
      }, error => {
        console.log(error);
      })
    );
  }
  
  initializeForm(): void {
    this.formGroup = this.fb.group({});
    this.danceManagerPasses.forEach((pass) => {
      if (pass?.id) {
        this.formGroup.addControl(pass.id, this.fb.control('0'));
      }
    });
  }

  initializeFormSubscription(): void {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((form) => {
        this.selectedPasses = [];
        this.subtotal = 0;
        this.fees = 0;
        this.danceManagerPasses.forEach((pass) => {
          if (form[pass.id] > 0) {
            this.selectedPasses.push(pass);
            this.subtotal += pass.price * form[pass.id];
          }
        });
      })
    );
  }

  selectedPassesContainTrialPass(): boolean {
    let containsTrialPass = false;
    for (const pass of this.selectedPasses) {
      if (pass.trial === true) {
        containsTrialPass = true;
      }
    }
    return containsTrialPass;
  }
  
  loadTicketOrder(): void {
    const ticketOrderData = this.ticketOrderService.getSyncTempCreateTicketOrderData();
    if (ticketOrderData?.passOrderItems?.length > 0) {
      ticketOrderData.passOrderItems.forEach(item => {
        if (this.formGroup.contains(item.passId)) {
          this.formGroup.patchValue({
            [item.passId]: item.quantity
          });
        }
      });
    }
  }

  filterUserPassCoursesByPassId(passTargets: PassTargetInterface[]): CourseInterface[] {
    const targetIds: string[] = Array.from(new Set(passTargets.map(target => target.targetId) ?? []));
    return this.passCourses.filter(course => targetIds.includes(course.id));
  }

  navigateToDanceManagerPage(): void  {
    this.router.navigate(['@' + this.danceManager.urlName]);
  }

  continueToCheckout(withLogin = true): void {
    const createTicketOrderDto: CreateTicketOrderRequestDto = {
      passOrderItems: [],
      ticketOrderItems: []
    };
    for (const control in this.formGroup.controls) {
      const pass = this.danceManagerPasses.find((obj) => obj.id === control);
      if (pass && this.formGroup.controls[control].value > 0) {
        createTicketOrderDto.passOrderItems.push({
          passId: pass.id,
          quantity: this.formGroup.controls[control].value
        });
      }
    }

    if (createTicketOrderDto.passOrderItems.length > 0) {
      if (withLogin && !this.authService.getSyncCurrentUser()) {
        this.creationTicketOrderLoading = true;
        this.ticketOrderService.sendTempCreateTicketOrderData(createTicketOrderDto, null, this.danceManager.urlName);
        if (this.selectedPassesContainTrialPass()) {
          this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
        }
        else {
          this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: `/@${this.danceManager.urlName}/passes/checkout`}});
        }
      }
      else {
        if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && !this.showGuestInputFields) {
          this.showGuestInputFields = true;
        }
        else {
          if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && this.guestEmailForm.valid) {
            createTicketOrderDto.userGuestEmail = this.guestEmailForm.get('email').value;
            this.creationTicketOrderLoading = true;
            this.ticketOrderService.createTicketOrder(createTicketOrderDto, null, this.danceManager.urlName);
          }
          else if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && this.guestEmailForm.invalid) {
            console.log('SHOW ERROR');
          }
          else {
            this.creationTicketOrderLoading = true;
            this.ticketOrderService.createTicketOrder(createTicketOrderDto, null, this.danceManager.urlName);
          }
        }
      }
    }
  }
}
