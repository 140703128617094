import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuplicateCourseDialogComponent } from './duplicate-course-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DfTimePickerModule } from '../df-time-picker';
import { DfSharedButtonModule } from '../buttons';
import { SearchDanceStyleModule } from '../search-dance-style';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import {LevelSliderMaterialModule} from "../level-slider-material";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DuplicateCourseDialogComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatInputModule,
      MatDatepickerModule,
      DfTimePickerModule,
      MatCheckboxModule,
      DfSharedButtonModule,
      SearchDanceStyleModule,
      MatChipListbox,
      MatChipOption,
      MatChipRemove,
      MatIcon,
      LevelSliderMaterialModule,
      TranslocoDirective,
    ],
  exports: [DuplicateCourseDialogComponent],
})
export class DuplicateCourseDialogModule {}
