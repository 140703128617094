import { TicketInterface } from './ticket.interface';
import {
  FeesPaidByEnum,
  UserTicketDanceRoleTypeEnum,
  UserTicketStatusEnum
} from '../enums';
import { TicketOrderInterface } from './ticket-order.interface';

export interface UserTicketInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  ticket?: TicketInterface;
  userId?: string;
  invitedUserEmail?: string;
  orderId?: string;
  quantity?: number;
  status?: UserTicketStatusEnum;
  ticketOrder?: TicketOrderInterface;
  userTicketDanceRoleType?: UserTicketDanceRoleTypeEnum;
  ticketOrderId?: string;
  feesPaidBy?: FeesPaidByEnum;
}
