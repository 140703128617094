import { Injectable, OnDestroy } from '@angular/core';
import { AbstractStateV2Service, getSubscription } from '@platri/elab-angular-core';
import { ChatHttpService } from './chat-http.service';
import { map } from 'rxjs';
import { ChatWebsocketService } from './chat-websocket.service';
import { MessageDto, MessageInterface, MessageReactionEnum, MessageStatusEnum } from '@platri/df-common-chat-plug-play';
import { AuthTechnicalUserStateService } from './auth-technical-user-state.service';

@Injectable()
export class ChatStateService extends AbstractStateV2Service<MessageDto> implements OnDestroy {
  public isTechnicalUserState = false;
  
  private recipientId: string;
  
  constructor(
    private chatHttpService: ChatHttpService,
    private chatWebsocketService: ChatWebsocketService,
    private authTechnicalUserStateService: AuthTechnicalUserStateService
  ) {
    console.log('Initializing ' + ChatStateService.name);
    super();
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    console.log("Destroying " + ChatStateService.name);
    this.subscriptions.unsubscribe();
    this.allData.unsubscribe();
    this.selectedData.unsubscribe();

  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.chatWebsocketService.getAsyncEventBus(), this.onEventBusMessage.bind(this)));
  }
  
  onEventBusMessage(event: any): void {
    switch (event.event) {
      case "receivedMessage": {
        this.pushNewMessage(event.data);
        break;
      }
      case "messageStatusChanged": {
        this.updateMessageStatus(event.data.status, event.data.id);
        break;
      }
      case "messageReactionChanged": {
        this.updateMessageReaction(event.data.recipientReaction, event.data.id);
        break;
      }
      case "messageDeletedChanged": {
        this.updateMessageDeleted(event.data);
        break;
      }
      default: {
        break;
      }
    }
  }
  
  resetState(): void {
    this.resetAllStates();
  }

  loadAvailableData(passedData: {otherUserId: string; offset: number}): void {
    this.recipientId = passedData.otherUserId;
    this.subscriptions.add(this.allData.getSubscription(this.chatHttpService.getAllMessagesFromUserWithOtherUser(passedData.otherUserId, passedData.offset, 30, this.isTechnicalUserState ? this.authTechnicalUserStateService.getSyncAccessToken() : '').pipe(map(messages => {
      messages.map(message => {
        if (message.repliedToMessageId) {
          message.repliedToMessage = messages.find(messageArr => messageArr.id === message.repliedToMessageId);
          if (!message.repliedToMessage) {
            this.chatHttpService.getMessageById(message.repliedToMessageId, this.isTechnicalUserState ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
              message.repliedToMessage = res;
            })
          }
        }
      });
      return messages;
    }))));
  }

  pushNewMessages(messages: MessageDto[]): void {
    messages = messages.map(message => {
      if (message.repliedToMessageId) {
        message.repliedToMessage = this.allData.availableData.getValue().find(messageFromData => messageFromData.id === message.repliedToMessageId);
        if (!message.repliedToMessage) {
          this.chatHttpService.getMessageById(message.repliedToMessageId, this.isTechnicalUserState ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
            message.repliedToMessage = res;
          })
        }
      }
      return message;
    });
    this.allData.availableData.next([...messages, ...this.allData.availableData.getValue()]);
  }
  
  pushNewMessage(message: MessageDto): void {
    if (message.senderId !== this.recipientId && message.recipientId !== this.recipientId) return;
    if (message.repliedToMessageId) {
      message.repliedToMessage = this.allData.availableData.getValue().find(messageFromData => messageFromData.id === message.repliedToMessageId);
    }
    this.allData.availableData.next([...this.allData.availableData.getValue(), message]);
  }

  updateMessageStatus(status: MessageStatusEnum, messageId: number ): void {
    const messageToBeUpdated = this.allData.availableData.getValue().find(message => message.id === messageId);
    if (messageToBeUpdated) {
      messageToBeUpdated.status = status;
    }
  }

  updateMessageReaction(reaction: MessageReactionEnum, messageId: number ): void {
    const messageToBeUpdated = this.allData.availableData.getValue().find(message => message.id === messageId);
    if (messageToBeUpdated) {
      messageToBeUpdated.recipientReaction = reaction;
    }
  }

  updateMessageDeleted(updatedMessage: MessageInterface): void {
    const messageToBeUpdated = this.allData.availableData.getValue().find(message => message.id === updatedMessage.id);
    if (messageToBeUpdated) {
      messageToBeUpdated.recipientDeleted = updatedMessage.recipientDeleted!;
      messageToBeUpdated.senderDeleted = updatedMessage.senderDeleted!;
      messageToBeUpdated.value = "Diese Nachricht wurde gelöscht";
    }
  }

}
