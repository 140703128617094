import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { FrontendDfDanceEventAppRoutingModule } from './frontend-df-dance-event-app-routing.module';
import { DanceEventPageModule } from './pages/app';
import { EventTicketsCheckoutCompletePageModule } from './pages/event-tickets-checkout-complete-page/event-tickets-checkout-complete-page.module';
import { EventTicketsCheckoutPageModule } from './pages/event-tickets-checkout-page/event-tickets-checkout-page.module';
import { EventTicketsModule } from './pages/event-tickets-page/event-tickets.module';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoStateConst } from '@platri/dfx-angular-core';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfDanceEventAppRoutingModule,
    DanceEventPageModule,
    EventTicketsCheckoutCompletePageModule,
    EventTicketsCheckoutPageModule,
    EventTicketsModule,
    MatSnackBarModule,
    TranslocoModule
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }}
  ],
  exports: []
})
export class FrontendDfDanceEventAppModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-dance-event', alias: 'DANCE_EVENT' });
  }
}
