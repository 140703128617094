import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmFaqsPage } from './dfm-faqs.page';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DfmAddEditFaqModule } from '../dfm-add-edit-faq';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmFaqsPage],
  exports: [DfmFaqsPage],
    imports: [
        CommonModule,
        MatExpansionModule,
        DfmAddEditFaqModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        DfSharedButtonModule,
        MatProgressSpinnerModule,
        CdkStepperModule,
        CdkScrollableModule,
        DragDropModule,
        PipesModule,
        TranslocoDirective
    ],
})
export class DfmFaqsModule {}
