import { Component, Input, OnInit } from '@angular/core';
import { danceManagerRolesWithAccessOnDm, DF_SEARCH_COURSE_AD_PICTURE, DF_SEARCH_DM_AD_PICTURE, DF_SEARCH_EVENT_AD_PICTURE, getDanceManagerIdsFromTokenRolesByRoles, UserAppLangEnum } from '@platri/df-common-core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthStateService, dfAppRoute, dfCreateDanceManagerRoute, dfLoginRoute, SelectDmDialogComponent } from '@platri/dfx-angular-core';
import { SearchCategoryEnum } from '../../enums';

@Component({
  selector: 'df-search-list-ad',
  templateUrl: './search-list-ad.component.html',
  styleUrls: ['./search-list-ad.component.scss']
})
export class SearchListAdComponent implements OnInit {
  @Input() adType: SearchCategoryEnum;
  @Input() titleTranslateKey: string;
  @Input() descriptionTranslateKey: string;
  @Input() imageSourceKey: string;
  
  danceManagerIds: string[];
  currentLang: UserAppLangEnum;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthStateService,
    private dialog: MatDialog,
  ) {}
  
  ngOnInit(): void {
    if(this.authService.getSyncCurrentUser()) {
      this.danceManagerIds = getDanceManagerIdsFromTokenRolesByRoles(this.authService.getSyncCurrentUser().roles?.danceSchool, danceManagerRolesWithAccessOnDm);
    }
  }

  onButtonClicked(): void {
    if(!this.authService.getSyncCurrentUser()) {
      this.routeToLogin();
    }
    
    if (this.adType === SearchCategoryEnum.EVENTS || this.adType === SearchCategoryEnum.FESTIVALS || this.adType === SearchCategoryEnum.COURSES) {
      if(this.danceManagerIds.length > 1) {
        this.dialog.open(SelectDmDialogComponent, {
          maxHeight: '512px',
          minWidth: '304px',
          data: {
            linkType: this.adType === SearchCategoryEnum.EVENTS || this.adType === SearchCategoryEnum.FESTIVALS ? 'events' : 'course'
          },
          autoFocus: false
        });
      } else if (this.danceManagerIds.length === 1) {
        this.router.navigate(this.adType === SearchCategoryEnum.EVENTS || this.adType === SearchCategoryEnum.FESTIVALS
          ? ['dance-manager', this.danceManagerIds[0], 'events', 'create']
          : ['dance-manager', this.danceManagerIds[0], 'courses', 'create']
        );
      }
    }
    
    if (this.adType === SearchCategoryEnum.STUDIOS || this.danceManagerIds.length === 0) {
      this.router.navigate(['/' + dfAppRoute, dfCreateDanceManagerRoute]);
    }
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
  }

  protected readonly DF_SEARCH_EVENT_AD_PICTURE = DF_SEARCH_EVENT_AD_PICTURE;
  protected readonly DF_SEARCH_COURSE_AD_PICTURE = DF_SEARCH_COURSE_AD_PICTURE;
  protected readonly DF_SEARCH_DM_AD_PICTURE = DF_SEARCH_DM_AD_PICTURE;
  protected readonly SearchCategoryEnum = SearchCategoryEnum;
}
