import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StripeElementLocale, StripeElementsOptions, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { StripeFactoryService, StripeInstance, StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import { emailRegex, environment, UsersInterface } from '@platri/df-common-core';
import { mustMatch } from '../../../validators';
import { AuthStateService } from '../../../services';

@Component({
  selector: 'df-shared-lib-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
})
export class StripePaymentComponent implements OnInit {
  @Input() paymentIntentClientSecret: string;
  @Input() returnUrl: string;
  @Input() lang: StripeElementLocale = 'de';
  @Input() firstNameLabel = 'First name';
  @Input() lastNameLabel = 'Last name';
  @Input() payNowButtonLabel = 'Pay now';
  @Input() paymentInProgressLabel = 'Payment in progress ...';
  @Input() connectAccountId: string;
  @Input() infoText: string;

  isValid = false;
  isProcessing = false;
  isFailed = false;
  
  isStripeValid = false;

  @ViewChild(StripePaymentElementComponent)
  stripePaymentElementComponent: StripePaymentElementComponent;

  formGroup: UntypedFormGroup;
  stripe: StripeInstance;

  isNameRequired = true;
  isEmailRequired = true;
  isStripeReady = false;

  elementsOptions: StripeElementsOptions;
  
  currentUser: UsersInterface;

  constructor(
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private stripeFactory: StripeFactoryService,
    private cd: ChangeDetectorRef,
    private authStateService: AuthStateService
  ) {}

  ngOnInit(): void {
    if (this.connectAccountId) {
      this.stripe = this.stripeFactory.create(
        environment.stripePublishableKey,
        {
          stripeAccount: this.connectAccountId,
        }
      );
    }
    this.elementsOptions = {
      locale: this.lang,
    };
    this.initFormGroup();
    
    this.authStateService.getAsyncCurrentUser().subscribe({
      next: (user) => {
        this.currentUser = user;

        if (this.currentUser === null) {
          this.formGroup.addControl('email', this.fb.control('', [Validators.required, Validators.email, Validators.pattern(emailRegex)]));
          this.formGroup.addControl('confirmEmail', this.fb.control('', Validators.required));
          this.formGroup.setValidators(mustMatch('email', 'confirmEmail'));
        } else {
          this.formGroup.removeControl('email');
          this.formGroup.removeControl('confirmEmail');
          this.formGroup.removeValidators(mustMatch('email', 'confirmEmail'));
        }
      }
    });
  }

  initFormGroup(): void {
    this.formGroup = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      acceptInfoText: [false, [Validators.requiredTrue]]
    });
  }

  stripeFormChanged(event: StripePaymentElementChangeEvent): void {
    this.isNameRequired = ['card', 'sofort'].includes(event.value.type);
    if (this.isNameRequired) {
      this.formGroup.addControl('firstName', this.fb.control('', Validators.required));
      this.formGroup.addControl('lastName', this.fb.control('', Validators.required));
    } else {
      this.formGroup.removeControl('firstName');
      this.formGroup.removeControl('lastName');
    }
    this.isEmailRequired = ['card', 'sofort', 'ideal', 'google_pay'].includes(event.value.type);
    if (this.currentUser === null && this.isEmailRequired) {
      this.formGroup.addControl('email', this.fb.control('', [Validators.required, Validators.email, Validators.pattern(emailRegex)]));
      this.formGroup.addControl('confirmEmail', this.fb.control('', Validators.required));
      this.formGroup.setValidators(mustMatch('email', 'confirmEmail'));
    } else {
      this.formGroup.removeControl('email');
      this.formGroup.removeControl('confirmEmail');
      this.formGroup.removeValidators(mustMatch('email', 'confirmEmail'));
    }
    this.isValid = event.complete;
    this.isFailed = false;
  }

  stripeElementReady(): void {
    this.isStripeReady = true;
  }

  confirmPayment(): void {
    this.isProcessing = true;
    if (this.connectAccountId) {
      this.stripe
        .confirmPayment({
          elements: this.stripePaymentElementComponent.elements,
          confirmParams: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            payment_method_data: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              billing_details: {
                name: this.formGroup.get('firstName')?.value + ' ' + this.formGroup.get('lastName')?.value,
                email: this.currentUser?.email ?? this.formGroup.get('email').value
              },
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return_url: this.returnUrl,
          },
          redirect: 'always',
        })
        .subscribe({
          next: (result) => {
            this.isProcessing = false;
            if (result.error) {
              this.isFailed = true;
            }
            this.cd.detectChanges();
          },
          error: (err) => {
            this.isProcessing = false;
            this.isFailed = true;
            this.isValid = false;
            this.cd.detectChanges();
          },
        });
    } else {
      this.stripeService
        .confirmPayment({
          elements: this.stripePaymentElementComponent.elements,
          confirmParams: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            payment_method_data: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              billing_details: {
                name: this.formGroup.get('firstName') && this.formGroup.get('lastName') ?
                  this.formGroup.get('firstName')?.value +
                  ' ' +
                  this.formGroup.get('lastName')?.value : null,
                email: this.currentUser?.email ?? this.formGroup.get('email')?.value
              },
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return_url: this.returnUrl,
          },
          redirect: 'always',
        })
        .subscribe({
          next: (result) => {
            this.isProcessing = false;
            if (result.error) {
              this.isProcessing = false;
              this.isFailed = true;
              this.cd.detectChanges();
            }
          },
          error: (err) => {
            this.isProcessing = false;
            this.isFailed = true;
            this.cd.detectChanges();
          },
        });
    }
  }
}
