import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  
  constructor() {
    console.log('TranslocoHttpLoader - Constructor');
  }

  getTranslation(lang: string): Observable<Translation> {
    console.log('getTranslation Funktion');
    return this.http.get<Translation>(`assets/i18nTransloco/${lang}.json?v=${new Date().getTime()}`); 
  }
}
