<mat-card class="w-100 p2 card flex-column" *ngIf="appointment">
  <span style="color: #e33691">
    {{appointment.startDate | date: 'hh:mm':'UTC'}}
  </span>
  <span *ngIf="appointment.scheduler && appointment.scheduler.danceClass">
    <strong>{{ appointment.scheduler.danceClass.name }}</strong>
  </span>
  <span *ngIf="appointment.appointmentToUsers" style="color: #9c9c9c">
    {{ appointment.appointmentToUsers[0]?.user.firstName }} &#9679; from 8
    euro
  </span>
  <div class="flex-column">
    <span>
      {{ appointment.address.street }} {{ appointment.address.streetNumber }},
      {{ appointment.address.zipCode }} {{ appointment.address.city}}
      {{ appointment.room ? ',' : '' }}
    </span>
    <span *ngIf="appointment.room">Room: {{ appointment.room?.name }}</span>
  </div>
</mat-card>
