<ng-container *transloco="let t">
  <ng-container *ngIf="paypalOrderId">
    <df-shared-lib-paypal-payment
      paymentInProgressLabel="{{ t('GENERIC_WRAPPER.PAYMENT_IN_PROGRESS_DESC')}}"
      lang="{{ translocoService.getActiveLang().toLowerCase() }}"
      [returnUrl]="returnUrl"
      [currency]="currency"
      [paypalClientId]="paypalClientId"
      [paypalOrderId]="paypalOrderId"
      (capturePaymentEvent)="capturePaymentEvent()"
    ></df-shared-lib-paypal-payment>
  </ng-container>
  <div *ngIf="!paypalOrderId" class="mt2 flex-column-center-center" style="gap: 10px;">
    <mat-spinner [diameter]="35"></mat-spinner>
    <span>{{ t('GENERIC_WRAPPER.LOADING')}}</span>
  </div>
</ng-container>
