import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DanceSchool } from '@platri/df-common-shared-graphql';
import {
  CanDeactivateComponent,
  ConfirmationDialogComponent,
  DanceManagerService,
  DanceSchoolFaqHttpService,
  DfmStateService,
  getSubscription
} from '@platri/dfx-angular-core';
import { skipWhile, Subscription } from 'rxjs';
import {
  ConfirmationDialogInterface,
  DanceManagerFaqDto
} from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DfmAddEditFaqComponent } from '../dfm-add-edit-faq';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-administration-faqs',
  templateUrl: './dfm-faqs.page.html',
  styleUrls: ['./dfm-faqs.page.scss'],
})
export class DfmFaqsPage extends CanDeactivateComponent implements OnInit, OnDestroy {
  faqs: DanceManagerFaqDto[] = [];
  danceSchool: DanceSchool;
  @ViewChild(DfmAddEditFaqComponent) addEditFaqComponent: DfmAddEditFaqComponent;
  @ViewChild('newFaq') newFaq: DfmAddEditFaqComponent;
  showNewFaq = false;
  loading = true;

  subscription = new Subscription();

  constructor(
    private danceSchoolFaqHttpService: DanceSchoolFaqHttpService,
    public dialog: MatDialog,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private dfmStateService: DfmStateService,
    private danceManagerService: DanceManagerService
  ) {
    super();
  }

  canDeactivate(): boolean {
    return !this.addEditFaqComponent?.faqForm.dirty;
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscription.add(getSubscription(this.dfmStateService.getAsyncSelectedDm(), this.onDanceSchoolChangeSuccessful.bind(this)));
  }

  onDanceSchoolChangeSuccessful(danceSchool: DanceSchool): void {
    this.danceSchool = danceSchool;
    this.getFaqs();
  }

  getFaqs(): void {
    this.loading = true;
    this.subscription.add(
      this.danceSchoolFaqHttpService
        .getAllByDanceSchoolId(this.danceSchool.id!)
        .pipe(skipWhile((val) => val === null))
        .subscribe((result) => {
          this.faqs = result;
          this.loading = false;
        })
    );
  }

  refreshFaqList(): void {
    this.showNewFaq = false;
    this.getFaqs();
  }

  deleteFaq(faq: DanceManagerFaqDto): void {
    this.subscription.add(
      this.danceSchoolFaqHttpService
        .deleteById(this.danceSchool.id!, faq.id!)
        .subscribe(() => {
          this.refreshFaqList();
        })
    );
  }

  deleteFaqConfirmation(faq: DanceManagerFaqDto): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.FAQ_REMOVE_SUBTITLE'),
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '300px',
      data: confirmationDialogData,
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((confirmationRes: boolean) => {
        if (confirmationRes) {
          this.deleteFaq(faq);
        }
      })
    );
  }

  onMouseDropEvent(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.faqs, event.previousIndex, event.currentIndex);
      this.updateSequenceNumber();
    }
  }

  updateSequenceNumber(): void {
    const newSequence: { id: string; sequenceNumber: number }[] = [];
    this.faqs.forEach((faq, index) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newSequence.push({ id: faq.id!, sequenceNumber: index });
    });
    this.subscription.add(
      this.danceSchoolFaqHttpService
        .updateSequenceNumber(this.danceSchool.id!, newSequence)
        .subscribe({
          next: () => {
            const message = this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.FAQ.FAQS_SEQUENCE_UPDATED');
            this.matSnackBar.open(message);
            this.refreshFaqList();
            this.danceManagerService.getDanceManagerById(this.danceSchool.id);
          },
          error: (err) => {
            const message = this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.FAQ.FAQS_SEQUENCE_NOT_UPDATED');
            this.matSnackBar.open(message);
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
