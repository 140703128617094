import { ProfileRoutingModule } from './profile-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePage } from './profile.page';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import {
  LanguageEditModule,
  LevelBarModule,
  PipesModule, TranslocoStateConst
} from '@platri/dfx-angular-core';
import { MatCardModule } from '@angular/material/card';
import { LookingForEditModule, ProfileHeaderModule } from '../../components';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [ProfilePage],
  exports: [ProfilePage],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    LookingForEditModule,
    MatDividerModule,
    ProfileHeaderModule,
    LanguageEditModule,
    PipesModule,
    LevelBarModule,
    MatCardModule,
    TranslocoDirective,
    TranslocoModule
  ]
})
export class ProfileModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-user', alias: 'USER' });
  }
}
