import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserSocialLinkInterface, UserSocialLinkTypeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-user-social-links-profile-icons',
  templateUrl: './user-social-links-profile-icons.html',
  styleUrls: ['./user-social-links-profile-icons.scss'],
})
export class UserSocialLinksProfileIconsComponent implements OnInit {
  @Input() socialLinks: UserSocialLinkInterface[];
  @Output() saveClick = new EventEmitter<UserSocialLinkInterface[]>();

  public userSocialLinkTypeEnum = UserSocialLinkTypeEnum;
  temporarySocialLinks: UserSocialLinkInterface[] = [];

  ngOnInit(): void {
    if (this.socialLinks) {
      const formattedSocialLinks = this.socialLinks.map((socialLink) => {
        if (socialLink.url.startsWith('www.')) {
          socialLink.url = 'https://' + socialLink.url;
        }
      });
      this.temporarySocialLinks = JSON.parse(JSON.stringify(formattedSocialLinks));
    }
  }

  protected readonly UserSocialLinkTypeEnum = UserSocialLinkTypeEnum;
}
