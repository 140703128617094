import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { dfAppRoute, dfLoginRoute } from '../../shared';

@Directive({
  selector: '[dfUnauthorizedClickCheck]',
})
export class UnauthorizedClickCheckElementDirective
  implements OnInit, OnDestroy
{
  @Input() set dfUnauthorizedClickCheck(condition: boolean) {
    this.isUserLoggedIn = condition;
  }

  private isUserLoggedIn: boolean;
  private subscription = new Subscription();

  constructor(private elRef: ElementRef, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    const el = this.elRef.nativeElement;
    this.subscription = fromEvent(el.parentNode, 'click', {
      capture: true,
    }).subscribe((e: any) => {
      if (e.target === el && !this.isUserLoggedIn) {
        e.stopPropagation();
        this.routeToLogin();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
  }
}
