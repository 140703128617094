import { PaginationInterface, SortDirectionEnum } from '../../..';

export class PaginationDto implements PaginationInterface {
  limit: number;
  page: number;
  sortDirection?: SortDirectionEnum;
  sortColumn?: string;
  
  constructor(limit: number, page: number, sortDirection: SortDirectionEnum, sortColumn: string) {
    this.limit = limit;
    this.page = page;
    this.sortDirection = sortDirection;
    this.sortColumn = sortColumn;
  }
}
