import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  DanceEventCategoryEnum,
  DanceEventCoHost,
  DanceStyle,
  MusicStyle
} from '@platri/df-common-shared-graphql';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE,
  DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN,
  DANCE_MANAGER_MUSIC_STYLE_REQUEST_FORM_DE,
  DANCE_MANAGER_MUSIC_STYLE_REQUEST_FORM_EN,
  DanceEventsInterface
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-add-edit-event-general-info',
  templateUrl: './add-edit-event-general-info.component.html',
  styleUrls: ['./add-edit-event-general-info.component.scss'],
})
export class AddEditEventGeneralInfoComponent {
  constructor(
    private readonly translocoService: TranslocoService
  ) {}
  
  @Input() formGroup: UntypedFormGroup;
  @Input() showCancelButton = true;
  @Input() cancelButtonLabel: string;
  @Input() submitButtonLabel: string;
  @Input() danceEvent: DanceEventsInterface;

  @Output() onCancelClick = new EventEmitter<void>();
  @Output() onSubmitClick = new EventEmitter<void>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsMaxReached = false;

  setTagsMaxReached(): boolean {
    if (this.tagsControl.value?.length >= 10) {
      this.tagsMaxReached = true;
      return this.tagsMaxReached;
    }
    else {
      this.tagsMaxReached = false;
      return this.tagsMaxReached;
    }
  }
  
  get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  get danceStylesControl(): AbstractControl {
    return this.formGroup.get('danceStyles');
  }

  get musicStylesControl(): AbstractControl {
    return this.formGroup.get('musicStyles');
  }

  get tagsControl(): AbstractControl {
    return this.formGroup.get('tags');
  }

  get urlControl(): AbstractControl {
    return this.formGroup.get('url');
  }

  get categoryControl(): AbstractControl {
    return this.formGroup.get('categories');
  }

  get coHostControl(): AbstractControl {
    return this.formGroup.get('coHosts');
  }
  
  selectCategory(categories: DanceEventCategoryEnum[]): void {
    this.formGroup.patchValue({
      categories: categories,
    });
  }

  selectDanceStyle(danceStyles: DanceStyle[]): void {
    this.formGroup.patchValue({
      danceStyles: this.danceStylesControl?.value
        ? this.danceStylesControl.value.concat([danceStyles])
        : [danceStyles],
    });
  }

  selectMusicStyle(musicStyle: MusicStyle): void {
    this.formGroup.patchValue({
      musicStyles: this.musicStylesControl?.value
        ? this.musicStylesControl.value.concat([musicStyle])
        : [musicStyle],
    });
  }

  removeSelectedDanceStyle(danceStyle: DanceStyle): void {
    let selectedDanceStyles = this.danceStylesControl?.value;
    const index = selectedDanceStyles.indexOf(danceStyle);
    if (index >= 0) {
      selectedDanceStyles.splice(index, 1);
      selectedDanceStyles =
        selectedDanceStyles.length === 0 ? null : [...selectedDanceStyles];
    }
    this.formGroup.patchValue({
      danceStyles: selectedDanceStyles,
    });
  }

  removeSelectedMusicStyle(musicStyle: MusicStyle): void {
    let selectedMusicStyles = this.musicStylesControl?.value;
    const index = selectedMusicStyles.indexOf(musicStyle);
    if (index >= 0) {
      selectedMusicStyles.splice(index, 1);
      selectedMusicStyles =
        selectedMusicStyles.length === 0 ? null : [...selectedMusicStyles];
    }
    this.formGroup.patchValue({
      musicStyles: selectedMusicStyles,
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.chipInput?.inputElement;
    const value = event.value;
    this.setTagsMaxReached();
    if (this.tagsMaxReached === false) {
      if (value.trim() !== '') {
        if (this.tagsControl?.value && this.tagsControl?.value?.length > 0) {
          if (!this.tagsControl?.value?.includes(value)) {
            if ((value || '').trim()) {
              const existingTags: string[] = JSON.parse(
                JSON.stringify(this.tagsControl.value)
              );
              existingTags.push(value);
              this.formGroup.patchValue({
                tags: existingTags,
              });
            }
            if (input) {
              input.value = '';
            }
          }
        } else {
          this.formGroup.patchValue({
            tags: [value],
          });
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.tagsControl.disable();
    }
    
  }

  removeTag(tag: string): void {
    const index = this.tagsControl?.value.indexOf(tag);
    if (index >= 0) {
      this.tagsControl.value.splice(index, 1);
    }
    this.setTagsMaxReached();
    if (!this.tagsMaxReached) {
      this.tagsControl.enable();
    }
  }

  onImageUpload(formData: FormData):void {
    if (formData) {
      this.formGroup.patchValue({mainImage: formData,});
    }
  }

  onImageDelete(): void {
    this.formGroup.get('mainImage').setValue(null);
  }
  
  setSelectedCoHost(coHost: DanceEventCoHost): void {
    this.formGroup.patchValue({
      coHosts: this.coHostControl?.value ? this.coHostControl.value.concat([coHost]) : [coHost]
    });
  }

  removeSelectedCoHost(event: DanceEventCoHost): void {
    let selectedDanceManager = this.coHostControl?.value;
    const index = selectedDanceManager.findIndex(coHost => coHost.danceManagerId === event.danceManagerId);
    if (index >= 0) {
      selectedDanceManager.splice(index, 1);
      selectedDanceManager = selectedDanceManager.length === 0 ? null : [...selectedDanceManager];
    }
    this.formGroup.patchValue({coHosts: selectedDanceManager});
  }

  openDanceManagerDanceStyleRequestForm(): void {
    const url =
      this.translocoService.getActiveLang().toUpperCase() === 'DE'
      ? DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE
      : DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }

  openDanceManagerMusicStyleRequestForm(): void {
    const url =
      this.translocoService.getActiveLang().toUpperCase() === 'DE'
      ? DANCE_MANAGER_MUSIC_STYLE_REQUEST_FORM_DE
      : DANCE_MANAGER_MUSIC_STYLE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }
}
