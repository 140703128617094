import { GetPassesCurrentUserInterface, UserPassStatusEnum } from '../../..';
import { PassDto } from './pass.dto';
import { UserPassSubscriptionDto } from './user-pass-subscription.dto';

export class GetPassesByCurrentUserResponseDto implements GetPassesCurrentUserInterface{
  userPassId: string;
  pass: PassDto;
  userPassSubscription: UserPassSubscriptionDto;
  usageCount: number;
  usedCount: number;
  createdAt: Date;
  validUntil: Date;
  status: UserPassStatusEnum;
}
