<ng-container *transloco="let t">
  <div class="p2">
    <!-- Close -->
    <div class="flex-row-end-center" (click)="close()">
      <mat-icon class="cursor-pointer">close</mat-icon>
    </div>
  
    <div>
      <!-- Title -->
      <div class="fs16 bold">
        <span> {{ t('GENERIC_WRAPPER.UNAVAILABLE_FOR_WEB.TITLE') }}</span>
      </div>
  
      <!-- Description -->
      <div class="fs14 regular mt1">
        {{ t('GENERIC_WRAPPER.UNAVAILABLE_FOR_WEB.TEXT') }}
      </div>
      <!-- Mobile app links-->
      <div class="flex-row align-space-between-start mt3">
        <div style="flex: 0 0 48%">
          <a
            href="https://play.google.com/store/apps/details?id=com.danceflavors_app"
            target="_blank"
          >
            <img src="assets/svg/google-play-store.svg" alt="" width="100%" />
          </a>
        </div>
        <div style="flex: 0 0 48%">
          <a
            href="https://apps.apple.com/app/danceflavors/id1583746248"
            target="_blank"
          >
            <img src="assets/svg/apple-store.svg" alt="" width="100%" />
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
