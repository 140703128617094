import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { EmptyDanceEventListComponent } from './empty-dance-event-list.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [EmptyDanceEventListComponent],
  exports: [EmptyDanceEventListComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    DfSharedButtonModule,
  ],
})
export class EmptyDanceEventListModule {}
