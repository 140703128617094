import { AddressInterface } from './address.interface';

export interface UserAppointmentEmailInterface {
  appointmentId?: string;
  courseName?: string;
  courseId?: string;
  date?: Date; 
  danceManagerName?: string;
  userIds?: string[];
  fromTargetImageUrl?: string;
  address?: AddressInterface;
  duration?: number;
}
