import { Component } from '@angular/core';
import { DfmStateService } from '../../services';
import { Router } from '@angular/router';
import { dfmDanceManagerRoute, dfmSettingsRoute } from '../../shared';

@Component({
  selector: 'df-monorepo-dm-onboarding-required',
  templateUrl: './dm-onboarding-required-dialog.component.html',
  styleUrls: ['./dm-onboarding-required-dialog.component.scss'],
})
export class DmOnboardingRequiredDialogComponent {
  
  constructor(private dfmStateService: DfmStateService, private router: Router) {}

  onClickStartOnboarding(): void {
    this.router.navigate([dfmDanceManagerRoute, this.dfmStateService.getSyncSelectedDm().id, dfmSettingsRoute], { fragment: 'onboarding' });
  }
}
