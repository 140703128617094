<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveNameData()">
    <div class="input-container">
      <mat-form-field appearance="outline">
        <mat-label>{{t('GENERIC_WRAPPER.FIRST_NAME') }}</mat-label>
        <input matInput formControlName="firstName" required autocomplete="off"/>
        <mat-hint align="end">{{ formGroup.controls.firstName.value?.length }} / 30</mat-hint>
        <mat-error *ngIf="formGroup.controls.firstName.hasError('required')" data-cy="internal-server-error">
          {{ t('GENERIC_WRAPPER.FIRST_NAME_REQUIRED') }}
        </mat-error>
        <mat-error *ngIf="formGroup.controls.firstName.hasError('maxlength')" class="errorTextSpaceBetween" data-cy="internal-server-error">
          <div>{{ t('GENERIC_WRAPPER.FIRST_NAME_LENGTH') }}</div>
          <div style="margin-left: auto">
            {{ formGroup.controls.firstName.errors?.maxlength.actualLength }} /
            {{ formGroup.controls.firstName.errors?.maxlength.requiredLength }}
          </div>
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>{{t('GENERIC_WRAPPER.LAST_NAME') }}</mat-label>
        <input matInput formControlName="lastName" required autocomplete="off"/>
        <mat-hint align="end">{{ formGroup.controls.lastName.value?.length }} / 30</mat-hint>
        <mat-error *ngIf="formGroup.controls.lastName.hasError('required')" data-cy="internal-server-error">
          {{ t('GENERIC_WRAPPER.LAST_NAME_REQUIRED') }}
        </mat-error>
        <mat-error *ngIf="formGroup.controls.lastName.hasError('maxlength')" class="errorTextSpaceBetween" data-cy="internal-server-error">
          <div>{{ t('GENERIC_WRAPPER.LAST_NAME_LENGTH') }}</div>
          <div style="margin-left: auto">
            {{ formGroup.controls.lastName.errors?.maxlength.actualLength }} /
            {{ formGroup.controls.lastName.errors?.maxlength.requiredLength }}
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex-row gap-10">
      <span>{{t('USER.SHOW_NAME_PUBLIC') }}</span>
      <mat-slide-toggle
        formControlName="isNamePublic"
        [disabled]="formGroup.controls.firstName.value === null || formGroup.controls.lastName.value === null"
        [checked]="currentUser.isNamePublic"
      >
      </mat-slide-toggle>
    </div>
    <div class="flex-row-end-center gap-20 mt-10">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveNameData()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
