import { RadiusUnitEnum } from '../../../enums';
import { ImageInterface } from '../../image.interface';

export interface SearchDanceSchoolsResultInterface {
  danceSchoolId: string;
  danceSchoolName: string;
  locationName: string;
  locationImages: ImageInterface[];
  locationPreviewImage: ImageInterface;
  locationAmenitiesKeys: string[];
  danceSchoolUrlName: string;
  danceSchoolImageUrl: string;
  formattedAddress: string;
  distance: number;
  radiusUnit: RadiusUnitEnum;
  danceSchoolDescription: string;
  danceStyles: string[];
}
