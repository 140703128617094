import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseListTableComponent } from './course-list-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmationDialogModule } from '../confirmation-dialog';
import { ImageFallbackDirectiveModule } from '../../directives';
import { PipesModule } from '../../pipes';
import {MatChip} from "@angular/material/chips";
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [CourseListTableComponent],
  exports: [CourseListTableComponent],
    imports: [
      CommonModule,
      MatFormFieldModule,
      MatSelectModule,
      ReactiveFormsModule,
      MatTableModule,
      MatButtonModule,
      MatIconModule,
      MatSortModule,
      MatDialogModule,
      ConfirmationDialogModule,
      MatTooltipModule,
      MatSlideToggleModule,
      MatMenuModule,
      ImageFallbackDirectiveModule,
      PipesModule,
      MatChip,
      TranslocoDirective,
    ],
})
export class CourseListTableModule {}
