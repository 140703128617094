import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DanceSchool } from '@platri/df-common-shared-graphql';
import { CardFollowInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-dance-school-lib-dance-school-preview-card',
  templateUrl: './dance-school-location-preview-card.component.html',
  styleUrls: ['./dance-school-location-preview-card.component.scss']
})
export class DanceSchoolLocationPreviewCardComponent {
  @Input() loading: boolean;
  @Input() danceSchool: DanceSchool;
  @Input() location$: Observable<Location>;
  @Input() isFavorite = false;

  @Output() addToFollowsClicked: EventEmitter<CardFollowInterface> = new EventEmitter<CardFollowInterface>();
  @Output() removeFromFollowsClicked: EventEmitter<CardFollowInterface> = new EventEmitter<CardFollowInterface>();
}
