<ng-container *transloco="let t">
  <div class="flex-row-center-start" style="height: 100%;">
    <div class="w-100 flex-column" style="max-width: 1140px; min-height: calc(100% + 340px)">
      <div *ngIf="loadedData" >
        <df-dance-manager-header-image [danceManager]="danceManager" [isOwnerOrAdmin]="isOwnerOrAdmin"></df-dance-manager-header-image>
      </div>
      <div class="w-100 mb4 flex-sub-container">
        <div class="dance-manager-header">
          <df-dance-manager-header 
            [danceManager]="danceManager" 
            [isOwnerOrAdmin]="isOwnerOrAdmin"
            [isDmPreview]="isDmPreview"
            [pageViews]="pageViews"
          ></df-dance-manager-header>
        </div>
  
        <div *ngIf="loadedData" class="w-100 flex-column">
          <mat-tab-group id="dance-manager-tabs" style="margin-top: 10px" [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChange($event)" >
            <mat-tab *ngFor="let matTab of matTabsToDisplay" [label]="(t('DANCE_MANAGER.STUDIO_PAGE_TABS.' + matTab | uppercase))">
              <ng-template matTabContent>
                <ng-container *ngIf="matTab === 'events'; then eventsContent"></ng-container>
                <ng-container *ngIf="matTab === 'courses'; then coursesContent"></ng-container>
                <ng-container *ngIf="matTab === 'passes'; then passesContent"></ng-container>
                <ng-container *ngIf="matTab === 'subscriptions'; then subscriptionsContent"></ng-container>
                <ng-container *ngIf="matTab === 'gallery'; then galleryContent"></ng-container>
                <ng-container *ngIf="matTab === 'faqs'; then faqsContent"></ng-container>
                <ng-container *ngIf="matTab === 'team'; then teamContent"></ng-container>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #eventsContent>
    <div class="studio-page-preview-cards-narrow pt2">
       <div *ngIf="isOwnerOrAdmin" class="mb2 align-end">
         <a
           [routerLink]="'/dance-manager/' + this.danceManager.id + '/events/create'"
           style="text-decoration: none; color: inherit;">
         <button
           mat-button
           class="create-button"
           (click)="routeToNew('events')"
         >
           <mat-icon>add</mat-icon>
           {{t('GENERIC_WRAPPER.CREATE_NEW_EVENT')}}
         </button>
       </a>
       </div>
       <div class="flex-column">
         <div class="dance-event-preview-card-center-container" id="dance-event-preview-card">
           <df-dance-event-lib-dance-event-preview-card
             *ngFor="let danceEvent of danceEvents"
             [danceEvent]="danceEvent"
             [clickable]="true"
             [hideDistance]="true"
             [isDmCard]="true"
           ></df-dance-event-lib-dance-event-preview-card>
         </div>
       </div>
     </div>
     <!-- add events button if no events -->
     <div
       class="w-100 no-elements-card flex-column-center-center"
       style="gap: 25px;"
       *ngIf="isOwnerOrAdmin && (!danceEvents || danceEvents.length === 0)"
     >
       <span>{{t('GENERIC_WRAPPER.NO_EVENTS')}}</span>
       <button
         mat-flat-button
         color="primary"
         (click)="routeToNew('events')"
       >
         {{t('GENERIC_WRAPPER.CREATE_NEW_EVENT')}}
       </button>
     </div>
  </ng-template>
  
  <ng-template #coursesContent>
    <div>
       <div *ngIf="isOwnerOrAdmin" class="mb2 align-end">
         <a
           [routerLink]="'/dance-manager/' + this.danceManager.id + '/courses/create'"
           style="text-decoration: none; color: inherit;">
         <button
           mat-button
           class="create-button"
           (click)="routeToNew('courses')"
         >
           <mat-icon>add</mat-icon>
           {{t('GENERIC_WRAPPER.CREATE_NEW_COURSE')}}
         </button>
       </a>
       </div>
      <div [class.mt2]="!isOwnerOrAdmin">
       <df-monorepo-dance-manager-course-list [courses]="courses"></df-monorepo-dance-manager-course-list>
      </div>
    </div>
  </ng-template>
  
  <ng-template #passesContent>
    <div>
       <div *ngIf="isOwnerOrAdmin" class="mb2 align-end">
         <a [routerLink]="'/dance-manager/' + this.danceManager.id + '/courses'" style="text-decoration: none; color: inherit;">
           <button mat-button class="create-button">{{ t('DANCE_MANAGER.MANAGE_PASSES') }}</button>
         </a>
       </div>
      <div [class.mt2]="!isOwnerOrAdmin">
        <df-monorepo-dance-manager-passes-list
          [passes]="passes"
          [isOwnerOrAdmin]="isOwnerOrAdmin"
          [danceManager]="danceManager"
          [currentUser]="currentUser"
        ></df-monorepo-dance-manager-passes-list>
      </div>
    </div>
  </ng-template>
  
  <ng-template #subscriptionsContent>
    <div>
       <div *ngIf="isOwnerOrAdmin" class="mb2 align-end">
         <a [routerLink]="'/dance-manager/' + this.danceManager.id + '/courses'" style="text-decoration: none; color: inherit;">
           <button mat-button class="create-button">{{ t('DANCE_MANAGER.MANAGE_SUBSCRIPTIONS') }}</button>
         </a>
       </div>
      <div [class.mt2]="!isOwnerOrAdmin">
        <div *ngFor="let subscription of dmPassSubscriptions">
          <mat-card *ngIf="filterUserPassSubscriptionCoursesByPassId(subscription.targets).length > 0" class="pass-subscription-list-item">
            <df-pass-subscription-checkout-list-item
              [sub]="subscription"
              [isOwnerOrAdmin]="isOwnerOrAdmin"
              [danceManager]="danceManager"
              [currentUser]="currentUser"
              [userPassSubscriptions]="currentUserPassSubscriptions"
              [courses]="filterUserPassSubscriptionCoursesByPassId(subscription.targets)"
            ></df-pass-subscription-checkout-list-item>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #galleryContent>
    <div class="w-100 flex-row align-end" *ngIf="isOwnerOrAdmin">
      <button mat-icon-button (click)="routeToSettings(dfmAdministrationRoute,'gallery')">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <df-shared-lib-gallery [imageUrls]="danceManager.imageGalleryUrls"></df-shared-lib-gallery>
  </ng-template>
  
  <ng-template #faqsContent>
    <div style="margin-top: 18px">
      <df-monorepo-faqs-card [danceManagerId]="danceManager.id" [danceManagerUrlName] = "danceManager.urlName" [isOwnerOrAdmin] = "isOwnerOrAdmin"></df-monorepo-faqs-card>
    </div>
  </ng-template>
  
  <ng-template #teamContent>
    <div *ngIf="publicDanceSchoolToUsers?.length > 0; else emptyTeamPage">
     <div class="w-100 flex-row align-space-between-start">
      <span *ngIf="danceManager.teamDescription" [innerHTML]="danceManager.teamDescription | dynamicTag"></span>
      <button mat-icon-button *ngIf="isOwnerOrAdmin" (click)="routeToSettings(dfmAdministrationRoute,'team')">
        <mat-icon>edit</mat-icon>
      </button>
     </div>
     <div class="my2 flex-row-wrap" style="gap: 10px;">
       <df-shared-lib-team-member-card
         *ngFor="let teamMember of publicDanceSchoolToUsers"
         [danceSchoolToUser]="teamMember"
       ></df-shared-lib-team-member-card>
     </div>
    </div>
    <ng-template #emptyTeamPage>
      <div class="gap-15 flex-column align-center w-100 my4 py4">
        <df-shared-empty-page
          [hideBtn]="!isOwnerOrAdmin"
          [buttonLabel]="t('DANCE_MANAGER.MANAGE_TEAM')"
          [emptyTitle]="t('DANCE_MANAGER.MANAGE_YOUR_TEAM_NOW')"
          [emptyDescription]="t('DANCE_MANAGER.NO_TEAM_DESCRIPTION')"
          [imagePath]="'assets/images/events-manager/no_guests.svg'"
          (buttonClicked)="routeToEditTeam()"
        ></df-shared-empty-page>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
