<ng-container *transloco="let t">
  <a
    [routerLink]="['/', '@' + danceSchoolToUser.user.username]"
    style="text-decoration: none; color: inherit;">
    <mat-card
      class="card flex-column-center-center"
      *ngIf="danceSchoolToUser"
    >
      <img
        class="profilePicture circle"
        [src]="
          danceSchoolToUser.user.imageUrl && danceSchoolToUser.user.imageUrl !== '' ? danceSchoolToUser.user.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER
        "
        [alt]="t('GENERIC_WRAPPER.PROFILE_PICTURE')"
        imageFallback
        [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
      />
      <p style="text-align: center">
        <strong>{{
          danceSchoolToUser.user.firstName
            ? danceSchoolToUser.user.firstName
            : danceSchoolToUser.user.username
        }}</strong>
      </p>
    </mat-card>
  </a>
</ng-container>
