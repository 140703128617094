<ng-container *transloco="let t">
  <h2 style="margin-block-start: 0 !important; margin-block-end: 0 !important;" mat-dialog-title>{{ t('DANCE_MANAGER_EVENT.FREE_TICKET.TITLE') }}</h2>
  
  <mat-dialog-content class="mat-typography">
    <p>{{ t('DANCE_MANAGER_EVENT.FREE_TICKET.ENTER_NAME') }}<br/></p>
  
    <df-shared-lib-user-search-autocomplete
      (optionSelected)="onUserSelected($event)"
      (inputChange)="onInputChange($event)"
      [label]="t('DANCE_MANAGER_EVENT.FREE_TICKET.NAME_LABEL')"
      [resetFormAfterSelection]="true"
      [showUserSearchLoading]="showUserSearchLoadingSpinner"
      [userOptions]="userOptions">
    </df-shared-lib-user-search-autocomplete>
  
    <p class="pt2">{{ t('DANCE_MANAGER_EVENT.FREE_TICKET.EMAIL') }}</p>
  
    <div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{ t('DANCE_MANAGER_EVENT.FREE_TICKET.ENTER_MAIL') }}</mat-label>
        <input matInput placeholder="pat@example.com"
               [formControl]="emailInput"
               (keyup.enter)="onEmailSelected()"
        >
        <mat-error *ngIf="emailInput.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    
    <p class="pt1">{{ t('DANCE_MANAGER_EVENT.FREE_TICKET.USER_GROUPS_INFO') }}</p>
    
    <div>
      <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{ selectedDfmUserGroups?.value?.length === 0 ?
            t('DANCE_MANAGER_EVENT.FREE_TICKET.USER_GROUPS') :
            t('DANCE_MANAGER_EVENT.FREE_TICKET.SELECTED_USER_GROUPS')}}</mat-label>
          <mat-select [formControl]="selectedDfmUserGroups" multiple>
            @for (dfmUserGroup of dfmUserGroups; track dfmUserGroup) {
              <mat-option [value]="dfmUserGroup.id">{{dfmUserGroup.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
    </div>
    
    <p class="mt-10">{{ t('DANCE_MANAGER_EVENT.FREE_TICKET.SELECT_USERS') }}</p>
    <div class="chip-box">
      <mat-chip-listbox aria-label="Selected Users">
        <mat-chip-option
          *ngFor="let user of selectedUsers" 
          (removed)="removeSelectedUser(user)">
          <mat-chip-avatar *ngIf="user.imageurl">
            <img src="{{user.imageUrl}}" alt="Photo of {{user.displayValue}}"/>
          </mat-chip-avatar>
          {{user.value}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
        <mat-chip-option 
          *ngFor="let email of selectedEmails" 
          (removed)="removeSelectedEmail(email)">
          {{email}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
      </mat-chip-listbox>
  
    </div>
  
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <div class="invite-button-container">
      <df-shared-lib-button
        [buttonLabel]= "t('GENERIC_WRAPPER.CANCEL')"
        [materialButtonStyleType]="'outlined'"
        [minWidth]="'100%'"
        mat-dialog-close>
      </df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]= "t('DANCE_MANAGER_EVENT.FREE_TICKET.INVITE') | titlecase"
        [materialButtonStyleType]="'flat'"
        [minWidth]="'100%'"
        cdkFocusInitial
        [isDisabled]="isInviteDisabled" 
        (click)="invite()">
      </df-shared-lib-button>
    </div>
  </mat-dialog-actions>
</ng-container>
