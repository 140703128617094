import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowInfoCardComponent } from './follow-info-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [FollowInfoCardComponent],
    imports: [
      CommonModule,
      MatIconModule,
      MatButtonModule,
      RouterModule,
      TranslocoDirective,
    ],
  exports: [FollowInfoCardComponent],
})
export class FollowInfoCardModule {}
