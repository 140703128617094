import { LocationInterface } from '../../..';
import { AmenityDto, DanceSchoolAddressDto, DanceSchoolLocationOpeningHoursDto, ImageDto } from '../../..';
import { UpdateRoomDto } from './update-room.dto';

export class UpdateDanceSchoolLocationRequestDto
  implements Partial<LocationInterface> {
  name?: string;
  address?: DanceSchoolAddressDto;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  email?: string;
  amenities: AmenityDto[];
  openingHours?: DanceSchoolLocationOpeningHoursDto;
  rooms?: UpdateRoomDto[];
  showOpeningHours?: boolean;
  googleMapsPlaceId?: string;
  images?: ImageDto[];
  previewImage?: ImageDto;
}
