import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { FrontendDfCourseAppRoutingModule } from './frontend-df-course-app-routing.module';
import { CoursePageModule } from './pages/app';
import { CoursePassesPageModule } from './pages/course-passes';
import { CoursePassesCheckoutPageModule } from './pages/course-passes-checkout/course-passes-checkout-page.module';
import { CoursePassesCheckoutCompletePageModule } from './pages/course-passes-checkout-complete/course-passes-checkout-complete-page.module';
import { UserPassSubscriptionCheckoutCompletePageModule } from './pages/user-pass-subscription-checkout-complete/user-pass-subscription-checkout-complete-page.module';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoStateConst } from '@platri/dfx-angular-core';

@NgModule({
  declarations: [],
  imports: [CommonModule, FrontendDfCourseAppRoutingModule, CoursePageModule, CoursePassesPageModule, CoursePassesCheckoutPageModule, CoursePassesCheckoutCompletePageModule, UserPassSubscriptionCheckoutCompletePageModule, MatSnackBarModule, TranslocoModule],
  providers: [
    CustomMatSnackbarService, 
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 } },
  ],
  exports: []
})
export class FrontendDfCourseAppModule {
  
  constructor() {
    TranslocoStateConst.push({ scope: 'df-course', alias: 'DANCE_COURSE' });
  }
}
