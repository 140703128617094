import 'quill';

export interface Config {
  container: string;
  unit: 'word' | 'char';
  limit: number;
}

export interface QuillInstance {
  on: any;
  getText: any;
}

export default class Counter {
  quill: QuillInstance;
  options: Config;
  limit: number;

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    const container = document.querySelector(this.options.container);

    this.quill.on('editor-change', () => {
      const length = this.calculate();

      //container.innerHTML = length + ' ' + this.options.unit + 's';
      container.innerHTML = length + ' / ' + this.options.limit;
      if (length > this.options.limit) {
        container.setAttribute('style', 'color:red;');
      } else {
        container.setAttribute('style', 'color:inherit;');
      }
    });
  }

  calculate(): number {
    const text = this.quill.getText().trim();

    if (this.options.unit === 'word') {
      return !text ? 0 : text.split(/\s+/).length;
    }
    return text.length;
  }
}
