import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddImageModule, DfQuillEditorModule, DfSharedButtonModule, LevelSliderMaterialModule, PipesModule, SearchDanceStyleModule } from '@platri/dfx-angular-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CourseInfoFormComponent } from './course-info-form.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CourseInfoFormComponent],
  exports: [CourseInfoFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        MatCardModule,
        MatDividerModule,
        DfSharedButtonModule,
        MatFormFieldModule,
        MatInputModule,
        DfQuillEditorModule,
        ReactiveFormsModule,
        SearchDanceStyleModule,
        MatChipsModule,
        AddImageModule,
        LevelSliderMaterialModule,
        TranslocoDirective,
    ],
})
export class CourseInfoFormModule {}
