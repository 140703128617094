<ng-container *transloco="let t">
  <div class="w-100 flex-column" style="gap: 8px;">
    <span class="add-location-title">
        {{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.TITLE') }}
    </span>
    <span class="add-location-description">
        {{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.DESCRIPTION') }}
    </span>
    <ng-container *ngFor="let location of locations.value; let i = index">
        <mat-card class="optionalAddressCard round-corners mat-elevation-z1 flex-row-start-center column-lt-lg" style="gap: 20px; flex: 1 1 100%; padding: 10px;">
            <div class="w-100 flex-row">
                <div class="align-center" style="width: 20%;">
                    <button mat-button [matMenuTriggerFor]="privacyMenu">
                        <mat-icon>{{ location.isPublic ? 'public' : 'lock' }}</mat-icon>
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-menu #privacyMenu="matMenu">
                        <div class="p1 flex-column-center-start">
                            <button mat-menu-item (click)="setPublic(i)">
                                <mat-icon class="vSub material-icons-round">public</mat-icon>
                                <span>{{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.PRIVACY_PUBLIC') }}</span>
                            </button>
                            <button mat-menu-item (click)="setPrivate(i)">
                                <mat-icon class="vSub material-icons-round">lock</mat-icon>
                                <span>{{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.PRIVACY_PRIVATE') }}</span>
                            </button>
                        </div>
                    </mat-menu>
                </div>
                <div style="min-width: 70%; padding-left: 10px; align-self: center;" class="flex-column">
                    <span class="location-card-name">{{ location.name }}</span>
                    <span class="location-card-address">{{ location.address.formattedAddress }}</span>
                </div>
                <div class="flex-column-center-end" style="align-self: center;">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openAddLocationDialog(i)">
                            <span>{{ t('GENERIC_WRAPPER.EDIT') }}</span>
                        </button>
                        <button mat-menu-item (click)="deleteLocation(i)">
                            <span>{{ t('GENERIC_WRAPPER.DELETE') }}</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </mat-card>
    </ng-container> 

    <div><span class="add-location-text-button link" (click)="openAddLocationDialog(null)">{{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.ADD_LOCATION') }}</span></div>
  </div>
</ng-container>
