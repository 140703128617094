import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Address, AppointmentRecurrenceTypeEnum, CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, SchedulerTypeEnum } from '@platri/df-common-shared-graphql';
import { CoursesStateService, DanceSchoolStateService } from '@platri/dfx-angular-core';
import { CourseInterface, DanceSchoolInterface, LocationInterface, UsersInterface, WeekdaysStringEnum } from '@platri/df-common-core';
import { WeekDay } from '@angular/common';

@Component({
  selector: 'df-course-appointments-form',
  templateUrl: './course-appointments-form.component.html',
  styleUrls: ['./course-appointments-form.component.scss']
})
export class CourseAppointmentsFormComponent implements OnInit {
  @Input() appointmentFormGroup: UntypedFormGroup;
  @Input() loading = false;

  @Input() mode = 'EDIT';

  SchedulerTypeEnum = SchedulerTypeEnum;
  WeekdaysStringEnum = WeekdaysStringEnum;
  AppointmentRecurrenceTypeEnum = AppointmentRecurrenceTypeEnum;
  WeekDayEnumAngular = WeekDay;
  CustomRecurrenceTypeEnum = CustomRecurrenceTypeEnum;
  CustomRecurrenceEndTypeEnum = CustomRecurrenceEndTypeEnum;

  today = new Date();

  @Output() onBackClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSkipClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onContinueClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onNewAppointmentClick: EventEmitter<void> = new EventEmitter<void>();

  danceSchool: DanceSchoolInterface;
  danceSchoolUsers: UsersInterface[] = [];
  course: CourseInterface;

  addressInputType = 'location';
  selectedLocation: LocationInterface = { rooms: [] };

  constructor(private readonly danceSchoolService: DanceSchoolStateService, private readonly coursesService: CoursesStateService) {}

  ngOnInit(): void {
    this.coursesService.courseSubject$.subscribe((course) => {
      this.course = course;
    });

    this.getDanceSchool();
    this.appointmentFormGroup.valueChanges.subscribe((change) => {
      if (change.schedulerType === SchedulerTypeEnum.SERIES) {
        this.appointmentFormGroup.controls.endDate.addValidators(Validators.required);
      } else {
        this.appointmentFormGroup.controls.endDate.removeValidators(Validators.required);
      }
      if (change.recurrenceType === AppointmentRecurrenceTypeEnum.CUSTOM) {
        this.appointmentFormGroup.controls.customRecurrenceNumber.addValidators([Validators.required, Validators.min(1)]);
      } else {
        this.appointmentFormGroup.controls.customRecurrenceNumber.removeValidators([Validators.required, Validators.min(1)]);
      }
      if (change.customRecurrenceEnd === 'NUMBER_OF_APPOINTMENTS') {
        this.appointmentFormGroup.controls.numberOfAppointments.addValidators([Validators.required, Validators.min(1)]);
        this.appointmentFormGroup.controls.numberOfAppointments.updateValueAndValidity({ emitEvent: false });
        this.appointmentFormGroup.controls.endDate.removeValidators(Validators.required);
      } else {
        this.appointmentFormGroup.controls.numberOfAppointments.clearValidators();
        this.appointmentFormGroup.controls.numberOfAppointments.updateValueAndValidity({ emitEvent: false });
        this.appointmentFormGroup.controls.endDate.addValidators(Validators.required);
      }

      if (change.customRecurrenceType === CustomRecurrenceTypeEnum.WEEK) {
        this.appointmentFormGroup.controls.customRecurrenceWeekDays.addValidators(Validators.required);
      } else {
        this.appointmentFormGroup.controls.customRecurrenceWeekDays.removeValidators(Validators.required);
      }
    });
  }

  getDanceSchool(): void {
    this.danceSchool = this.danceSchoolService.getSyncCurrentDanceSchool();
    this.danceSchoolUsers = this.danceSchool.danceSchoolToUsers.map((danceSchoolUser) => danceSchoolUser.user);
    if (this.danceSchool.locations.length === 0) {
      this.addressInputType = 'manual';
    }
  }

  onAddressSelected(address: Address): void {
    this.appointmentFormGroup.controls.address.setValue(address);
    this.selectedLocation = { rooms: [] };
    this.appointmentFormGroup.controls.locationId.reset();
    this.appointmentFormGroup.controls.roomId.reset();
    this.appointmentFormGroup.controls.roomId.disable();
  }

  onLocationSelected(location: LocationInterface): void {
    this.selectedLocation = location;
    this.appointmentFormGroup.controls.address.setValue(location.address);
    if (location.rooms.length > 0) {
      this.appointmentFormGroup.controls.roomId.enable();
    }
  }

  changeAddressInput(addressInputType: string): void {
    if (addressInputType) {
      this.addressInputType = addressInputType;
    }
  }
}
