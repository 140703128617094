import { DanceIntervalEnum, GenderEnum, UserAppLangEnum, UsersInterface, UserStatusEnum } from '../../..';
import { BillingOrShippingAddressDto, UserSocialLinkDto } from '../../..';
import { UserToDanceStyleDto } from './user-to-dance-style.dto';
import { UserToLanguageDto } from './user-to-language.dto';

export class UpdateUserDto implements UsersInterface {
  firstName: string;
  lastName: string;
  jobTitle: string;
  description: string;
  email: string;
  height: number;
  gender: GenderEnum | null;
  status: UserStatusEnum;
  birthday: Date;
  addresses: BillingOrShippingAddressDto[];
  formerPlace: string;
  dancingArea: string;
  username: string;
  appLang?: UserAppLangEnum;
  lookingFor?: string[];
  socialLinks?: UserSocialLinkDto[];
  monthsDancing?: number;
  danceInterval?: DanceIntervalEnum;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  danceStyles?: UserToDanceStyleDto[];
  languageSkills?: UserToLanguageDto[];
  isAgePublic?: boolean;
  isNamePublic?: boolean;
  showCompleteProfileDialog?: boolean;
}
