import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DmAddUserErrorEnum } from '@platri/df-common-core';
import { AuthStateService, DanceManagerHttpService } from '../../services';

@Component({
  selector: 'df-invite-to-dm-page',
  templateUrl: './invite-to-dm-page.component.html',
  styleUrls: ['./invite-to-dm-page.component.scss'],
})
export class InviteToDmPageComponent implements OnInit{
  loading = false;
  error: DmAddUserErrorEnum;

  DmAddUserErrorEnum = DmAddUserErrorEnum;
  
  constructor(
    private readonly route: ActivatedRoute,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly router: Router,
    private readonly authStateService: AuthStateService
  ) {}
 
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => this.validateToken(params));
  }

  validateToken(params: ParamMap): void {
    const token = params.get('token');
    if (token) {
      this.loading = true;
      this.danceManagerHttpService.validateUserInviteToken(token)
        .subscribe({
          next: (res) => {
            this.loading = false;
            this.authStateService.refreshTokens().subscribe({
              next: () => {
                this.loading = false;
              }
            });
          },
          error: (err) => {
            this.error = err.error.error;
            this.loading = false;
          }
        });
    }
  }

  close(): void {
    this.router.navigate(['']);
  }
}
