import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DelayDialogInterface, environmentForWeb, UsersInterface } from '@platri/df-common-core';
import { UserPassSubscriptionHttpService } from '../../services';
import { DelayDialogComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'df-shared-lib-sidenav-responsive-personal',
  templateUrl: './sidenav-responsive-personal.component.html',
  styleUrls: ['./sidenav-responsive-personal.component.scss'],
})
export class SidenavResponsivePersonalComponent {
  @Input() user: UsersInterface;
  @Input() unreadMessagesCount = 0;
  
  @Output() logoutClicked = new EventEmitter<void>();
  @Output() bugReportClicked = new EventEmitter<void>();
  @Output() featureRequestClicked = new EventEmitter<void>();
  @Output() imprintClicked = new EventEmitter<void>();
  @Output() closeMenu = new EventEmitter<void>();

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;
  
  constructor(
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private matDialog: MatDialog
  ) {}

  openStripeBillingPortalSession(): void {
    this.userPassSubscriptionHttpService.getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser().subscribe({
      next: (url) => {
        if (url !== null) {
          const delayDialogData: DelayDialogInterface = {
            delayTime: 3000,
            title: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.STARTING_SOON',
            text: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.HAS_SUBSCRIPTION'
          };
          const dialogRef = this.matDialog.open(DelayDialogComponent, {
            maxWidth: '400px',
            data: delayDialogData
          });
          dialogRef.afterClosed().subscribe(confirm => {
            if (confirm) {
              window.open(url, '_blank');
            }
          });
        } else {
          // TODO: Show dialog that the user has no subscriptions yet
        }
      }
    });
  }
  
}
