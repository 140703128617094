export enum BookMePostingCategoryEnum {
  DJ = "DJ",
  LIVE_MUSICIANS_SINGERS_BANDS = "LIVE_MUSICIANS_SINGERS_BANDS",
  DANCERS_SHOW_DANCERS = "DANCERS_SHOW_DANCERS",
  CHOREOGRAPHERS = "CHOREOGRAPHERS",
  ACROBATS_FIRE_ARTISTS_AERIAL_ACROBATS = "ACROBATS_FIRE_ARTISTS_AERIAL_ACROBATS",
  DANCE_TEACHERS_COACHES = "DANCE_TEACHERS_COACHES",
  PHOTOGRAPHER_VIDEOGRAPHER = "PHOTOGRAPHER_VIDEOGRAPHER",
  OTHER = "OTHER"
}
