import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { UsersDanceStylesInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-dance-style-edit-item',
  templateUrl: './df-dance-style-edit-item.component.html',
  styleUrls: ['./df-dance-style-edit-item.component.scss'],
})
export class DfDanceStyleEditItemComponent implements OnInit {
  @Input() profileDanceStyle: UsersDanceStylesInterface;
  @Output() onProfileDanceStyleChange = new EventEmitter<UsersDanceStylesInterface>();
  @Output() onProfileDanceStyleDelete = new EventEmitter<UsersDanceStylesInterface>();

  isDetailOpened = false;

  formGroup: UntypedFormGroup;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    if (this.profileDanceStyle) {
      this.injectData();
    }
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      experience: [1],
      isLeader: [false],
      isFollower: [false],
      translationKey: [''],
      danceStyle: [false],
      isPairDance: [false],
    });

    this.formGroup.valueChanges.pipe(debounceTime(300)).subscribe((data) => {
      this.onProfileDanceStyleChange.emit(this.formGroup.value);
    });
  }

  injectData(): void {
    this.formGroup.patchValue(
      {
        experience: this.profileDanceStyle.experience ?? 1,
        isLeader: this.profileDanceStyle.isLeader ?? false,
        isFollower: this.profileDanceStyle.isFollower ?? false,
        translationKey: this.profileDanceStyle.translationKey ?? '',
        isPairDance: this.profileDanceStyle.danceStyle.isPairDance ?? false,
        danceStyle: this.profileDanceStyle.danceStyle ?? false,
      },
      {
        emitEvent: false,
      }
    );
  }

  get experience(): number {
    return this.formGroup.get('experience').value;
  }

  onDeleteClick(): void {
    this.onProfileDanceStyleDelete.emit(this.formGroup.value);
  }

  onSkillLevelChange(experience: number): void {
    this.formGroup.patchValue({
      experience,
    });
  }
}
