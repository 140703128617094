import { Component, Input, OnInit } from '@angular/core';
import { LevelSliderEnum, SliderSizeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-level-slider-view',
  templateUrl: './level-slider-view.component.html',
  styleUrls: ['./level-slider-view.component.scss'],
})
export class LevelSliderViewComponent implements OnInit {
  @Input() fromLevel = 33;
  @Input() toLevel = 66;
  @Input() disableText = false;
  @Input() size = SliderSizeEnum.SMALL;

  textFrom: string;
  textTo: string;

  ngOnInit(): void {
    this.textFrom = this.switchCaseForEnum(this.fromLevel);
    this.textTo = this.switchCaseForEnum(this.toLevel);
  }
  switchCaseForEnum(value: number): string {
    const enumValue = this.skillLevelDefinition(value);
    switch (enumValue) {
      case 0:
        return LevelSliderEnum.BEGINNER.toUpperCase();
      case 1:
        return LevelSliderEnum.IMPROVER.toUpperCase();
      case 2:
        return LevelSliderEnum.INTERMEDIATE.toUpperCase();
      case 3:
        return LevelSliderEnum.ADVANCED.toUpperCase();
      case 4:
        return LevelSliderEnum.MASTER.toUpperCase();
      default:
        return LevelSliderEnum.BEGINNER.toUpperCase();
    }
  }

  skillLevelDefinition(value: number): number {
    if (value === 100) {
      return 4;
    }
    return Math.floor(value / 20);
  }
}
