import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { DmMessageCountInterface } from '@platri/df-common-chat-plug-play';
import { ChatHttpService } from './chat-http.service';
import { getSubscription, OmniWebsocketService } from '@platri/dfx-angular-core';


@Injectable({
  providedIn: 'root'
})
export class MessageCountStateService implements OnDestroy {
  dmUnreadMessageCountData = new BehaviorSubject<DmMessageCountInterface[]>([]);
  userUnreadMessageCountData = new BehaviorSubject<number>(0);
  
  subscriptions: Subscription = new Subscription();

  constructor(
    private chatHttpService: ChatHttpService,
    private omniWebsocketService: OmniWebsocketService
  ) {
    console.log('Initializing ' + MessageCountStateService.name);
    this.initializeSubscriptions();
    this.loadAvailableData();
  }

  ngOnDestroy(): void {
    console.log("Destroying " + MessageCountStateService.name);
    this.subscriptions.unsubscribe();
    this.dmUnreadMessageCountData.unsubscribe();
    this.userUnreadMessageCountData.unsubscribe();
  }

  getSyncUserUnreadMessageCountData(): number {
    return this.userUnreadMessageCountData.getValue();
  }

  getAsyncUserUnreadMessageCountData(): Observable<number> {
    return this.userUnreadMessageCountData.asObservable();
  }

  getSyncDmUnreadMessageCountData(): DmMessageCountInterface[] {
    return this.dmUnreadMessageCountData.getValue();
  }

  getAsyncDmUnreadMessageCountData(): Observable<DmMessageCountInterface[]> {
    return this.dmUnreadMessageCountData.asObservable();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.omniWebsocketService.getAsyncEventBus(), this.onEventBusMessage.bind(this)));
  }

  onEventBusMessage(event: any): void {
    switch (event.event) {
      case "unreadMessageCountChanged": {
        this.loadAvailableData();
        break;
      }
      default: {
        break;
      }
    }
  }

  resetState(): void {
    this.dmUnreadMessageCountData.next([]);
    this.userUnreadMessageCountData.next(0);
  }

  loadAvailableData(): void {
    const request1 = this.chatHttpService.unreadCountCurrentUser();
    const request2 = this.chatHttpService.unreadCountCurrentUserDms();

    this.subscriptions.add(
      forkJoin([request1, request2]).subscribe({
          next: ([res1, res2]) => {
            this.userUnreadMessageCountData.next(res1);
            if (res1 > 0) {
              this.playNewMessageSound();
            }
            this.dmUnreadMessageCountData.next(res2);
          }
        }
      )
    );
  }
  
  private playNewMessageSound():void {
    const audio = new Audio();
    audio.src = '/app/assets/appointed-529.mp3';
    audio.load();
    audio.play();
  }

}
