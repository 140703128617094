import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavResponsiveDmComponent } from './sidenav-responsive-dm.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { SecuredDanceSchoolElementModule } from '../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [SidenavResponsiveDmComponent],
  exports: [SidenavResponsiveDmComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive,
    MatBadgeModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    PipesModule,
    ReactiveFormsModule,
    MatListModule,
    SecuredDanceSchoolElementModule,
    TranslocoDirective
  ],
  providers: []
})
export class SidenavResponsiveDmModule {}
