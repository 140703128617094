import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CourseInterface, DanceSchoolInterface, DEFAULT_EVENT_PICTURE_PLACEHOLDER, UserPassCollapsedByDanceSchoolInterface, UserPassInterface, UserPassStatusEnum, UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-inventory-pass-list-item>',
  templateUrl: './inventory-pass-list-item.component.html',
  styleUrls: ['./inventory-pass-list-item.component.scss']
})
export class InventoryPassListItemComponent implements OnInit {
  @Input() danceManager: DanceSchoolInterface;
  @Input() courses: CourseInterface[];
  @Input() userPassCollapsed: UserPassInterface;

  // @Output() downloadPasses = new EventEmitter<{
  //   danceCourseId: string;
  //   passOrderId: string;
  // }>();

  currentUser: UsersInterface;
  shownStamps: number[] = [];
  
  subscriptions: Subscription = new Subscription();

  currentLang: string;
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;
  
  userPassStatusEnum = UserPassStatusEnum;

  constructor(private readonly translocoService: TranslocoService) {
    this.currentLang = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.createStampsShownArray();
  }

  createStampsShownArray(): void {
    const usageCount: number = this.userPassCollapsed.usageCount;
    const usedCount: number = this.userPassCollapsed.userPassScans?.length;

    const displayCount = usageCount > 10 ? 10 : usageCount;
    let start = 0;
    if (usedCount > 5) {
      start = usedCount - (usedCount % 5);
    }
    if (start + 10 > usageCount) {
      start = usageCount - 10;
    }
    start = Math.max(start, 0);
    for (let i = 0; i < displayCount; i++) {
      this.shownStamps[i] = start + 1 + i;
    }
  }
}
