import { UsersDanceStylesInterface } from '../../..';
import { DanceStyleDto, UserDto } from '../../..';

export class UsersToDanceStylesDto implements UsersDanceStylesInterface {
  userId: string;
  danceStyleId: string;
  translationKey: string;
  experience: number;
  user: UserDto;
  danceStyle: DanceStyleDto;
  isLeader: boolean;
  isFollower: boolean;
}
