import { NgModule } from '@angular/core';
import { DfmContactComponent } from './dfm-contact.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DfSharedButtonModule, GoogleAddressSearchAutocompleteModule, PhoneInputModule } from '@platri/dfx-angular-core';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { DfmSocialLinksPreviewModule } from '../dfm-social-links-preview';
import { DfmSocialLinksEditModule } from '../dfm-social-links-edit';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmContactComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    PhoneInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DfSharedButtonModule,
    DfmSocialLinksEditModule,
    DfmSocialLinksPreviewModule,
    GoogleAddressSearchAutocompleteModule,
    MatSelectModule,
    TranslocoDirective
  ],
  exports: [DfmContactComponent],
})
export class DfmContactModule {}
