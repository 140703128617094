import { Pipe, PipeTransform } from '@angular/core';
import { Conversation } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'conversationIsRead'
})
export class ConversationIsReadPipe implements PipeTransform {
  transform(conversation: Conversation): boolean {
    if (!conversation.readAt) {
      return false;
    }
    return (new Date(conversation.messages[0].createdAt).getTime() < new Date(conversation.readAt).getTime());
  }
}
