import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription, take, takeWhile } from 'rxjs';
import {
  AuthStateService,
  CheckoutCompleteErrorTextInterface,
  DanceEventStateService,
  dfDanceEventIdRouterParam,
  FooterStateService,
  getSubscription,
  PurchaseConfirmationTextInterface,
  TicketOrderService
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Params } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  DanceEventsInterface,
  TicketOrderInterface,
  TicketOrderStatusEnum
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-event-tickets-checkout-complete',
  templateUrl: './event-tickets-checkout-complete.page.html',
  styleUrls: ['./event-tickets-checkout-complete.page.scss']
})
export class EventTicketsCheckoutCompletePage implements OnInit, OnDestroy {
  ticketOrder: TicketOrderInterface | null;

  isMobileResponsive = false;
  showMobileSummary = false;
  isIntervalActive = false;

  subscriptions = new Subscription();

  confirmationText: PurchaseConfirmationTextInterface;
  errorText: CheckoutCompleteErrorTextInterface;

  routerParams!: Params;
  danceEventIdFromParam: string;
  danceEvent: DanceEventsInterface;

  readonly ticketOrderStatusEnum = TicketOrderStatusEnum;

  constructor(private readonly ticketOrderService: TicketOrderService, private readonly danceEventService: DanceEventStateService, private readonly route: ActivatedRoute, private readonly activatedRoute: ActivatedRoute, private readonly breakpointObserver: BreakpointObserver, private readonly footerService: FooterStateService, private readonly authService: AuthStateService, private readonly translocoService: TranslocoService) {
    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result: BreakpointState) => {
        this.isMobileResponsive = result.matches;
        this.footerService.showFooter$.next(!result.matches);
        if (this.showMobileSummary && !result.matches) {
          this.showMobileSummary = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.listenOnTicketOrderChanges();
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.danceEventIdFromParam = this.routerParams[dfDanceEventIdRouterParam];
    this.loadDanceEvent();
  }

  private listenOnTicketOrderChanges(): void {
    this.subscriptions.add(
      this.ticketOrderService.getAsyncTicketOrder().subscribe((ticketOrder) => {
        this.ticketOrder = ticketOrder;

        if (ticketOrder && this.ticketOrder!.status !== null && (this.ticketOrder!.status === TicketOrderStatusEnum.PAYMENT_INITIALIZED || this.ticketOrder!.status === TicketOrderStatusEnum.PAYMENT_IN_PROGRESS) && !this.isIntervalActive) {
          console.log('here');
          this.activateIntervalCheckingTicketOrder(this.ticketOrder!.id!);
        }

        if ([TicketOrderStatusEnum.PAID, TicketOrderStatusEnum.PAYMENT_PENDING].includes(ticketOrder?.status)) {
          this.setConfirmationText();
        }

        if ([TicketOrderStatusEnum.PAYMENT_ERROR].includes(ticketOrder?.status)) {
          this.setErrorText();
        }
      })
    );
    this.ticketOrderService.getTicketOrderById(this.route.snapshot.params.ticketOrderId);
  }

  private activateIntervalCheckingTicketOrder(ticketOrderId: string): void {
    if (!this.isIntervalActive) {
      this.isIntervalActive = true;
      const timer = interval(3000);

      const takeTwelve = timer.pipe(take(10));

      this.subscriptions.add(
        takeTwelve.pipe(takeWhile(() => (this.ticketOrder!.status === TicketOrderStatusEnum.PAYMENT_INITIALIZED || this.ticketOrder!.status === TicketOrderStatusEnum.PAYMENT_IN_PROGRESS))).subscribe(() => {
          this.ticketOrderService.getTicketOrderById(ticketOrderId);
        })
      );
    }
  }

  loadDanceEvent(): void {
    this.subscriptions.add(
      this.danceEventService.danceEventSubject$.subscribe((danceEvent) => {
        if (danceEvent) {
          this.danceEvent = danceEvent;
        }
      })
    );
    this.danceEventService.getById(this.danceEventIdFromParam);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.ticketOrderService.clear();
  }

  private setConfirmationText(): void {
    const currentUser = this.authService.getSyncCurrentUser();
    const firstName = currentUser?.username ?? this.translocoService.translate('GENERIC_WRAPPER.GUEST');
    const email = currentUser?.email ?? this.ticketOrder.userEmail;
    this.confirmationText = {
      title: 'GENERIC_WRAPPER.THANKS_FOR_ORDER',
      message: 'GENERIC_WRAPPER.TICKETS_CHECKOUT.PAID.' + (currentUser ? 'TEXT' : 'TEXT_GUEST'),
      messageParams: {firstName, email},
      checkboxText: 'GENERIC_WRAPPER.TICKETS_CHECKOUT.CHECKBOX_TEXT',
      navButton: {
        label: 'GENERIC_WRAPPER.TICKETS_CHECKOUT.BUTTON',
        navUrl: '/inventory'
      },
      downloadText: 'GENERIC_WRAPPER.TICKETS_CHECKOUT.PAID.DOWNLOAD_PDF'
    };
  }

  private setErrorText(): void {
    this.errorText = {
      title: 'GENERIC_WRAPPER.SOMETHING_WENT_WRONG_SHORT',
      text: 'GENERIC_WRAPPER.TICKETS_CHECKOUT.ERROR_DESCRIPTION',
      navButton: {
        label: 'GENERIC_WRAPPER.TICKETS_CHECKOUT.BACK_TO_EVENT',
        navUrl: '/dance-event/' + this.route.snapshot.params[dfDanceEventIdRouterParam]
      }
    };
  }
}
