import { Injectable, OnDestroy } from '@angular/core';
import { AbstractStateV2Service, getSubscription } from '@platri/elab-angular-core';
import { ChatHttpService } from './chat-http.service';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { ChatWebsocketService } from './chat-websocket.service';
import { ChatInterface } from '../interfaces';
import { AuthStateService, UserHttpService } from '@platri/dfx-angular-core';
import { AuthTechnicalUserStateService } from './auth-technical-user-state.service';

@Injectable()
export class ChatsOverviewStateService extends AbstractStateV2Service<ChatInterface> implements OnDestroy {
  public isTechnicalUserState = false;
  isInitialMessage = true;
  constructor(
    private chatHttpService: ChatHttpService,
    private chatWebsocketService: ChatWebsocketService,
    private userHttpService: UserHttpService,
    private authStateService: AuthStateService,
    private authTechnicalUserStateService: AuthTechnicalUserStateService
  ) {
    console.log('Initializing ' + ChatsOverviewStateService.name);
    super();
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    console.log("Destroying " + ChatsOverviewStateService.name);
    this.subscriptions.unsubscribe();
    this.allData.unsubscribe();
    this.selectedData.unsubscribe();

  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.chatWebsocketService.getAsyncEventBus(), this.onEventBusMessage.bind(this)));
  }
  
  onEventBusMessage(event: any): void {
    switch (event.event) {
      case "receivedMessage": {
        const currentChatData = JSON.parse(JSON.stringify(this.getSyncAvailableData()))
        // if (currentChatData.length === 0) {return;}
        const foundChat = currentChatData.find((chat: ChatInterface) => chat.user!.userId === event.data.recipientId || chat.user!.userId === event.data.senderId || chat.user!.danceSchoolId === event.data.recipientId || chat.user!.danceSchoolId === event.data.senderId);
        if (foundChat) {
          foundChat.lastMessage = event.data;
          this.updateAvailableData(currentChatData);
        } else {
          const currentUserId = this.isTechnicalUserState ? this.authTechnicalUserStateService.currentTechnicalUser.getValue()?.id : this.authStateService.getSyncCurrentUser()?.id;
          const otherUserId = event.data.senderId !== currentUserId ? event.data.senderId : event.data.recipientId;
          this.userHttpService.getOtherUserById(otherUserId).subscribe((otherUser): void => {
            const newChat: ChatInterface = {user: otherUser, lastMessage: event.data};
            currentChatData.push(newChat);
            this.updateAvailableData(currentChatData);
          });
        }
        break;
      }
      case "messageStatusChanged": {
        const currentChatData = this.getSyncAvailableData();
        const foundChat = currentChatData.find(chat => chat.lastMessage.id === event.data.id);
        if (foundChat) {
          foundChat.lastMessage.status = event.data.status;
        }
        break;
      }
      case "messageReactionChanged": {
       
        break;
      }
      case "messageDeletedChanged": {
        const currentChatData = this.getSyncAvailableData();
        const foundChat = currentChatData.find(chat => chat.lastMessage.id === event.data.id);
        if (foundChat) {
          foundChat.lastMessage.value = "Diese Nachricht wurde gelöscht.";
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  
  updateAvailableData(currentChatData: any): void {
    currentChatData = currentChatData.map((chat: ChatInterface) => {
      chat.lastMessage.date = new Date(chat.lastMessage.date!);
      return chat;
    }).sort((a: ChatInterface, b: ChatInterface) => b.lastMessage.date!.getTime() - a.lastMessage.date!.getTime());
    this.allData.availableData.next(currentChatData);
  }
  
  resetState(): void {
    this.resetAllStates();
  }

  loadAvailableData(): void {
    this.subscriptions.add(this.allData.getSubscription(this.loadDataSubscriptionNew()));
  }
  
  // loadDataSubscription(): Observable<ChatInterface[]> {
  //   const currentUser = this.isTechnicalUserState ? this.authTechnicalUserStateService.currentTechnicalUser.getValue() : this.authStateService.getSyncCurrentUser();
  //   return this.chatHttpService.getAllChatsFromUserForOverview(this.isTechnicalUserState ? this.authTechnicalUserStateService.getSyncAccessToken() : '').pipe(
  //     switchMap(lastMessages => {
  //       const requests = lastMessages.map(lastMessage => {
  //         lastMessage.date = new Date(lastMessage.date);
  //         // @ts-ignore
  //         const otherUserId = lastMessage.senderId !== currentUser?.id ? lastMessage.senderId : lastMessage.recipientId;
  //         return this.userHttpService.getOtherUserById(otherUserId).pipe(map(otherUser => ({user: otherUser, lastMessage: lastMessage})));
  //       });
  //       return forkJoin(requests).pipe(map(chats => chats.sort((a, b) => b.lastMessage.date!.getTime() - a.lastMessage.date!.getTime())));
  //     })
  //   );
  // }

  loadDataSubscriptionNew(): Observable<ChatInterface[]> {
    const currentUser = this.isTechnicalUserState ?
                        this.authTechnicalUserStateService.currentTechnicalUser.getValue() :
                        this.authStateService.getSyncCurrentUser();

    return this.chatHttpService.getAllChatsFromUserForOverview(this.isTechnicalUserState ? this.authTechnicalUserStateService.getSyncAccessToken() : '').pipe(
      switchMap(lastMessages => {
        const otherUserIds = lastMessages.map(lastMessage => lastMessage.senderId !== currentUser?.id ? lastMessage.senderId : lastMessage.recipientId);
        return this.userHttpService.getOtherUsersByIds(otherUserIds).pipe(
          map(users => lastMessages.map(lastMessage => {
              lastMessage.date = new Date(lastMessage.date);
              const otherUserId = lastMessage.senderId !== currentUser?.id ? lastMessage.senderId : lastMessage.recipientId;
              const otherUser = users.find((user) => (user.id === 'dance-school-' + otherUserId || user.id ===  'user-' + otherUserId));
              return { user: otherUser, lastMessage };
            }).filter((obj) => obj.user !== undefined).sort((a, b) => b.lastMessage.date!.getTime() - a.lastMessage.date!.getTime()))
        );
      })
    );
  }
  
  removeChat(userId: string): void {
    let currentChatData = this.getSyncAvailableData();
    const foundChatIndex = currentChatData.findIndex(chat => chat.user!.id.replace('user-', '').replace('dance-school-', '') === userId);
    currentChatData = currentChatData.splice(foundChatIndex, 1);
  }
  

}
