import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  constructor(
  ) {
    console.log('Initializing AuthStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying AuthStateService');
  }
  
  saveAccessTokenToLocalStorage(accessToken: string): void {
    localStorage.setItem('accessToken', accessToken);
  }
  
  getAccessTokenFromLocalStorage(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  saveRefreshTokenToLocalStorage(refreshToken: string): void {
    localStorage.setItem('refreshToken', refreshToken);
  }

  getRefreshTokenFromLocalStorage(): string {
    return localStorage.getItem('refreshToken') ?? '';
  }

  saveExpireDateToLocalStorage(expireDateNumber: number): void {
    localStorage.setItem('expireDate', expireDateNumber + '');
  }

  getExpireDateFromLocalStorage(): number {
    const expireDateNumber = localStorage.getItem('expireDate')
    return expireDateNumber ? +expireDateNumber : 0;
  }
  
  

}
