import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CourseInterface, CourseStatusEnum, LogoAndTitleUploadDialogTypeEnum, SliderSizeEnum, UserAppLangEnum } from '@platri/df-common-core';
import { CoursesStateService } from '../../services';
import { ShareComponent } from '../share';
import { Router } from '@angular/router';
import { LogoAndTitleUploadDialogComponent } from '../logo-and-title-upload-dialog';
import { SharedCancelDialogComponent } from '../cancel-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-course-header',
  templateUrl: './dance-course-header.component.html',
  styleUrls: ['./dance-course-header.component.scss']
})
export class DanceCourseHeaderComponent implements OnDestroy {
  @Input() danceCourse: CourseInterface;
  @Input() isOwnerOrAdmin: boolean;
  @Input() isUserEnrolled: boolean;
  @Input() showEditButton = false;
  @Input() passMinPrice = -1;

  sliderSizeEnum = SliderSizeEnum;

  subscription: Subscription = new Subscription();
  currentLang: UserAppLangEnum;

  constructor(
    private readonly dialog: MatDialog, 
    private readonly translocoService: TranslocoService, 
    public readonly coursesService: CoursesStateService,
    private router: Router) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

 
  openTitleDialog(): void {
    const dialogRef = this.dialog.open(LogoAndTitleUploadDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      width: '100%',
      maxWidth: '1140px',
      data: {
        id: this.danceCourse.id,
        mode: LogoAndTitleUploadDialogTypeEnum.COURSE_TITLE,
        ratio: 2 / 1
      }
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log(result);
          this.danceCourse.mainImage.url = result.titleImage.url;
        }
      })
    );
  }

  confirmDelete(): void {
    const cancelDialogRef = this.dialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COVER_PICTURE'),
        subtitle: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COVER_PICTURE_SUBTITLE'),
        infoText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
        cancelText: this.translocoService.translate('GENERIC_WRAPPER.DELETE')
      }
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.coursesService.deleteMainImageById(this.danceCourse.id).subscribe();
      }
    });
  }

  openShareComponentDialog(): void{
    this.dialog.open(ShareComponent, {
      autoFocus: false,
      panelClass: ['br-20', 'bg-glassmorph'],
      maxHeight: '100vh',
      maxWidth: '311px',
      minWidth: '310px',
      width: '25vw'
    });
  }

  navigateToCoursePasses(): void {
    this.router.navigate(['dance-class', this.danceCourse.id, 'passes']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected readonly CourseStatusEnum = CourseStatusEnum;
}
