import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  CoordinatesStateService,
  CoursesHttpService,
  DanceEventHttpService,
  DanceManagerHttpService,
  FilterSortTypeEnum,
  SearchHttpService
} from '@platri/dfx-angular-core';
import {
  AddressInterface,
  CoordinatesInterface,
  CourseInterface,
  DanceEventCategoryEnum,
  DanceEventsInterface,
  DanceManagerCategoryEnum,
  DanceManagerInterface, DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER,
  DF_SEARCH_COURSE_AD_PICTURE,
  DF_SEARCH_DM_AD_PICTURE,
  DF_SEARCH_EVENT_AD_PICTURE,
  PageDto,
  SortDirectionEnum
} from '@platri/df-common-core';
import {
  CoursesFilterFormInterface,
  EventsFilterFormInterface,
  FilterFormInterface,
  StudiosFilterFormInterface
} from '../../interfaces';
import { getSubscription } from '@platri/elab-angular-core';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin, fromEvent,
  map,
  Observable,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import { SearchFilterDialogComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { FilterDialogDataInterface } from '../../interfaces/filter-dialog-data.interface';
import { SearchCategoryEnum } from '../../enums';
import {
  Address as ElabGoogleAddress,
  FrontendGoogleAutocompleteDirective
} from '@platri/elab-angular-google-autocomplete';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

const SEARCH_LOCATION_LOCAL_STORAGE_KEY = 'searchLocation';

@Component({
  selector: 'df-search-component',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('address') addressInput: ElementRef;
  @ViewChild('addressAlternative') addressInputAlternative: ElementRef;
  @ViewChild('placesRef') placesRef: FrontendGoogleAutocompleteDirective;
  @ViewChildren('shownElementCards') shownElementCards: QueryList<ElementRef>;
  
  coordinates: CoordinatesInterface;
  searchOptions: Observable<SearchOptionInterface[]>;
  currentAutocompleteOptions: SearchOptionInterface[] = [];
  searchFormGroup: UntypedFormGroup;
  
  shownElements: PageDto<DanceEventsInterface | CourseInterface | DanceManagerInterface>;
  filterFormGroup: FormGroup<FilterFormInterface> | undefined = undefined;
  scrollItemLimit = 20;
  
  isLoading = true;
  isScrollLoading = false;
  hasFailedLoadingNoConnection = false;
  hasFailedLoadingInternalServer = false;

  featureIsUnavailable = false;

  private subscriptions: Subscription = new Subscription();

  trackByIndex = (index: number): number => index;

  constructor(
    private danceEventHttpService: DanceEventHttpService,
    private coursesHttpService: CoursesHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private searchHttpService: SearchHttpService,
    private coordinatesStateService: CoordinatesStateService,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}
  
  // Life Cycle Hooks
  
  ngOnInit(): void {
    // this.setupCoordinationSearch();
    this.initializeForms();
    this.initializeSubscriptions();
  }

  ngAfterViewInit(): void {
    this.initializeInputElement();
    this.initializeCategoryBarScrollListener();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeCategoryBarScrollListener(): void {
    const categoryBar = document.getElementById('category-bar');
    const pageBody = document.getElementById('pageBody');
    this.subscriptions.add(
      fromEvent(pageBody, 'scroll')
      .subscribe(() => {
        if (pageBody.scrollTop >= 108) {
          categoryBar.classList?.add('category-bar-border-bottom');
        } else {
          categoryBar.classList?.remove('category-bar-border-bottom');
        }
      })
    );
  }
  
  // Loading

  loadData(scrollLoad: boolean = false): void {
    if (!this.filterFormGroup?.valid || (scrollLoad && this.shownElements.results.length === this.shownElements.totalElements)) {
      return;
    }
    
    if(!this.coordinatesStateService.getSyncCurrentCoordinates()) {
      this.updateLocationQueryParams(null);
    }
    
    const searchCategory = this.filterFormGroup.controls.searchCategory.value;
    const getElementsByFilterRequestDto = this.createRequestDto(searchCategory, scrollLoad);
    
    // Set search location data to local Storage
    const locationData: CoordinatesInterface = this.getLocationQueryParams(this.coordinatesStateService.getSyncCurrentCoordinates());
    const isLocationDataExist: boolean = !!locationData.location && !!locationData.latitude && !!locationData.longitude;
    if (isLocationDataExist) {
      const jsonData = JSON.stringify(locationData);
      localStorage.setItem(SEARCH_LOCATION_LOCAL_STORAGE_KEY, jsonData);
    } else {
      localStorage.removeItem(SEARCH_LOCATION_LOCAL_STORAGE_KEY);
    }
    
    this.loadElements(getElementsByFilterRequestDto, searchCategory, scrollLoad);
  }

  createRequestDto(searchCategory: SearchCategoryEnum, scrollLoad: boolean = false): GetElementsByFilterRequestDto {
    const eventFilterValue = this.filterFormGroup.controls.eventsFilter.getRawValue();
    const courseFilterValue = this.filterFormGroup.controls.coursesFilter.getRawValue();
    const studioFilterValue = this.filterFormGroup.controls.studiosFilter.getRawValue();

    const {courseName, courseDanceStyles, courseStartDifficulty, courseEndDifficulty, courseSortType} = courseFilterValue;
    const {studioName, studioDanceStyles, studioCategories} = studioFilterValue;
    const {eventName, eventMusicStyles, eventDanceStyles, eventSortType} = eventFilterValue;
    let {eventCategories} = eventFilterValue;
    if (searchCategory === SearchCategoryEnum.EVENTS && eventCategories.length === 0) {
      eventCategories = Object.values(DanceEventCategoryEnum);
    }
    if (searchCategory === SearchCategoryEnum.FESTIVALS) {
      eventCategories = [DanceEventCategoryEnum.FESTIVAL];
    }

    const getElementsByFilterRequestDto = new GetElementsByFilterRequestDto();
    getElementsByFilterRequestDto.page = scrollLoad ? this.shownElements.pagination.page + 1 : 1;
    getElementsByFilterRequestDto.limit = scrollLoad ? this.shownElements.pagination.limit : this.scrollItemLimit;
    getElementsByFilterRequestDto.latitude = +this.coordinatesStateService.getSyncCurrentCoordinates()?.latitude ?? null;
    getElementsByFilterRequestDto.longitude = +this.coordinatesStateService.getSyncCurrentCoordinates()?.longitude ?? null;
    getElementsByFilterRequestDto.radius = null;
    getElementsByFilterRequestDto.musicStyles = eventMusicStyles.length > 0 ? eventMusicStyles : null;
    getElementsByFilterRequestDto.startDate = null;
    getElementsByFilterRequestDto.endDate = null;
    getElementsByFilterRequestDto.clientDateUtc = null;
    getElementsByFilterRequestDto.withAppointments = false;
    getElementsByFilterRequestDto.startDifficulty = courseStartDifficulty;
    getElementsByFilterRequestDto.endDifficulty = courseEndDifficulty;
    getElementsByFilterRequestDto.danceManagerId = null;
    getElementsByFilterRequestDto.danceManagerIds = null;

    switch (searchCategory) {
      case SearchCategoryEnum.EVENTS:
        getElementsByFilterRequestDto.sortColumn = eventSortType === FilterSortTypeEnum.START_DATE ? 'startDate' : eventSortType.toLowerCase();
        getElementsByFilterRequestDto.danceStyles = eventDanceStyles.length > 0 ? eventDanceStyles : null;
        getElementsByFilterRequestDto.categories = eventCategories.length > 0 ? eventCategories : null;
        getElementsByFilterRequestDto.name = eventName;
        break;
      case SearchCategoryEnum.FESTIVALS:
        getElementsByFilterRequestDto.sortColumn = eventSortType === FilterSortTypeEnum.START_DATE ? 'startDate' : eventSortType.toLowerCase();
        getElementsByFilterRequestDto.danceStyles = eventDanceStyles.length > 0 ? eventDanceStyles : null;
        getElementsByFilterRequestDto.categories = eventCategories.length > 0 ? eventCategories : null;
        getElementsByFilterRequestDto.name = eventName;
        break;
      case SearchCategoryEnum.COURSES:
        getElementsByFilterRequestDto.sortColumn = courseSortType === FilterSortTypeEnum.START_DATE ? 'startDate' : courseSortType.toLowerCase();
        getElementsByFilterRequestDto.danceStyles = courseDanceStyles.length > 0 ? courseDanceStyles : null;
        getElementsByFilterRequestDto.name = courseName;
        getElementsByFilterRequestDto.sortDirection = SortDirectionEnum.ASC;
        break;
      case SearchCategoryEnum.STUDIOS:
        getElementsByFilterRequestDto.sortColumn = 'distance';
        getElementsByFilterRequestDto.danceStyles = studioDanceStyles.length > 0 ? studioDanceStyles : null;
        getElementsByFilterRequestDto.categories = studioCategories.length > 0 ? studioCategories : null;
        getElementsByFilterRequestDto.search = studioName;
        getElementsByFilterRequestDto.sortDirection = null;
        break;
    }
    
    return getElementsByFilterRequestDto;
  }
  
  loadElements(getElementsByFilterRequestDto: GetElementsByFilterRequestDto, searchCategory: SearchCategoryEnum, scrollLoad: boolean = false): void {
    if (scrollLoad) {
      this.isScrollLoading = true;
    } else {
      this.isLoading = true;
    }
    this.hasFailedLoadingNoConnection = false;
    this.hasFailedLoadingInternalServer = false;
    this.featureIsUnavailable = false;
    
    if (searchCategory === SearchCategoryEnum.EVENTS || searchCategory === SearchCategoryEnum.FESTIVALS) {
      this.subscriptions.add(getSubscription(
        this.danceEventHttpService.getAllByFilter(getElementsByFilterRequestDto),
        response => {
          this.handleLoadingResponse(response, scrollLoad);
        }, error => {
          this.errorLoadingResponse(error);
        }
      ));
    } 
    else if (searchCategory === SearchCategoryEnum.COURSES) {
      this.subscriptions.add(getSubscription(
        this.coursesHttpService.getAllByFilter(getElementsByFilterRequestDto),
        response => {
          this.handleLoadingResponse(response, scrollLoad);
        },error => {
          this.errorLoadingResponse(error);
        }
      ));
    } 
    else if (searchCategory === SearchCategoryEnum.STUDIOS) {
      this.subscriptions.add(getSubscription(
        this.danceManagerHttpService.getAllByFilter(getElementsByFilterRequestDto),
        response => {
          this.handleLoadingResponse(response, scrollLoad);
        }, error => {
          this.errorLoadingResponse(error);
        }
      ));
    }
    else {
      this.featureIsUnavailable = true;
      this.isLoading = false;
      this.isScrollLoading = false;
    }
  }
  
  handleLoadingResponse(response: PageDto<DanceEventsInterface | CourseInterface | DanceManagerInterface>, scrollLoad: boolean = false): void {
    if (scrollLoad) {
      this.mergeScrollLoadResults(response);
      this.isScrollLoading = false;
    } else {
      this.shownElements = response;
    }
    this.addMarketingItems();
    this.initializeIntersectionObserver();
    this.isLoading = false;
  }
  
  mergeScrollLoadResults(response: PageDto<DanceEventsInterface | CourseInterface | DanceManagerInterface>): void {
    this.shownElements.results = this.shownElements.results.filter(element => element);
    this.shownElements.results = this.shownElements?.results?.length > 0 ? this.shownElements.results.concat(response.results) : response.results;
    this.shownElements.pagination = response.pagination;
    this.shownElements.totalElements = response.totalElements;
  }
  
  errorLoadingResponse(error): void {
    this.shownElements.results = [];
    this.isLoading = false;
    if (error.status === 0) {
      this.onConnectionLost();
    }
    if (error.status === 500) {
      this.onInternalServerError();
    }
  }

  addMarketingItems(): void {
    if (this.shownElements.results.length >= 10) {
      for (let i = this.shownElements.results.length - 2; i > 0; i--) {
        if ((i + 1) % 10 === 0) {
          this.shownElements.results.splice(i + 1, 0, null);
        }
      }
    } else {
      this.shownElements.results.push(null);
    }
  }

  initializeIntersectionObserver(): void {
    this.shownElementCards.changes.subscribe((shownElementCards: QueryList<ElementRef>) => {
      if (shownElementCards && shownElementCards.length > 0) {
        this.setUpObserver(shownElementCards);
      }
    });
  }

  setUpObserver(shownElementCards: QueryList<ElementRef>): void {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.01
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show-element');
        }
      });
    }, observerOptions);

    shownElementCards.forEach(shownElementCard => {
      if (shownElementCard && shownElementCard.nativeElement) {
        observer.observe(shownElementCard.nativeElement);
      }
    });
  }
  
  onConnectionLost(): void {
    this.hasFailedLoadingNoConnection = true;
  }

  onInternalServerError(): void {
    this.hasFailedLoadingInternalServer = true;
  }
  
  initializeSubscriptions(): void {
    this.initializeValueChangesSubscriptions();
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteQueryParamsChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.coordinatesStateService.getAsyncCurrentCoordinates(), this.onCoordinateChanges.bind(this)));
  }

  onActivatedRouteQueryParamsChanges(params: Params): void {
    if (params['search_type']) {
      this.filterFormGroup.patchValue({
        searchCategory: params['search_type']
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          search_type: SearchCategoryEnum.EVENTS
        },
        queryParamsHandling: 'merge'
      });
      return;
    }
    
    const currentCategory = this.filterFormGroup.controls.searchCategory.value;
    const musicStyles = this.getFormControlListFormat(params['musicStyles']);
    const danceStyles = this.getFormControlListFormat(params['danceStyles']);
    const categories = this.getFormControlListFormat(params['categories']);
    
    if (currentCategory === SearchCategoryEnum.EVENTS || currentCategory === SearchCategoryEnum.FESTIVALS) {
      const eventFilterControls = this.filterFormGroup.controls.eventsFilter.controls;
      this.setValueToFormArray(eventFilterControls.eventMusicStyles, musicStyles);
      this.setValueToFormArray(eventFilterControls.eventDanceStyles, danceStyles);
      this.setValueToFormArray(eventFilterControls.eventCategories, categories);
    } else if (currentCategory === SearchCategoryEnum.COURSES) {
      const coursesFilterControls = this.filterFormGroup.controls.coursesFilter.controls;
      this.setValueToFormArray(coursesFilterControls.courseDanceStyles, danceStyles);
    } else if (currentCategory === SearchCategoryEnum.STUDIOS) {
      const studiosFilterControls = this.filterFormGroup.controls.studiosFilter.controls;
      this.setValueToFormArray(studiosFilterControls.studioDanceStyles, danceStyles);
      this.setValueToFormArray(studiosFilterControls.studioCategories, categories);
      if(params['name']) {
        studiosFilterControls.studioName.setValue(params['name']);
      }
    }

    const createAddress = (location: string, latitude: number, longitude: number): AddressInterface | null => {
      if(!location || !latitude || !longitude) return null;
      const [city, country] = location.split(',');
      return { city, country, latitude, longitude };
    };
  
    if (params['location'] && params['latitude'] && params['longitude']) {
      const address = createAddress(params['location'], params['latitude'], params['longitude']);
      if(address) {
        this.updateAddress(address, false); // LoadData will be triggered by onAddressSelected()
        return;
      }
    }

    // Check local storage for search location data
    const storedLocationData = localStorage.getItem(SEARCH_LOCATION_LOCAL_STORAGE_KEY);
    if (storedLocationData) {
      const storedLocation = JSON.parse(storedLocationData) as AddressInterface;
      const address = createAddress(storedLocation.location, storedLocation.latitude, storedLocation.longitude);
      if(address) {
        this.updateLocationQueryParams(address);
        return;
      }
    }
    
    this.loadData();
  }
  
  private getFormControlListFormat(list: string) :FormControl<string>[] {
    const uniqueList = Array.from(new Set(list?.split(',') || [])); // Prevent duplicated items
    return uniqueList.map(entry => this.fb.control(entry, { nonNullable: true }));
  }
  
  private setValueToFormArray(formArray: FormArray, controlList: FormControl<string>[]): void {
    if(controlList.length < 1) {
      // Don't let to remove filter value on category change
      return;
    }
    
    formArray.clear();
    controlList.forEach(control => {
      formArray.push(control);
    });
  }

  onCoordinateChanges(data: CoordinatesInterface): void {
    if (data) {
      this.coordinates = data;
      this.loadData();
    }
  }

  initializeForms(): void {
    this.searchFormGroup = this.initializeSearchAddressFormGroup();
    this.filterFormGroup = this.createFilterFormGroup();
  }

  initializeSearchAddressFormGroup(): FormGroup {
    return this.fb.group({
      address: ['', Validators.required]
    });
  }

  createFilterFormGroup(): FormGroup {
    return this.fb.group<FilterFormInterface>({
      eventsFilter: this.createEventsFilterFormGroup(),
      coursesFilter: this.createCoursesFilterFormGroup(),
      studiosFilter: this.createStudiosFilterFormGroup(),
      searchCategory: this.fb.control<SearchCategoryEnum>(SearchCategoryEnum.EVENTS) as FormControl<SearchCategoryEnum>
    });
  }
  
  createEventsFilterFormGroup(): FormGroup<EventsFilterFormInterface> {
    return this.fb.group<EventsFilterFormInterface>({
      eventName: this.fb.control<string>('') as FormControl<string>,
      eventMusicStyles: this.fb.array<string>([]) as FormArray<FormControl<string>>,
      eventDanceStyles: this.fb.array<string>([]) as FormArray<FormControl<string>>,
      eventCategories: this.fb.array<DanceEventCategoryEnum>([]) as FormArray<FormControl<DanceEventCategoryEnum>>,
      eventSortType: this.fb.control<FilterSortTypeEnum>(FilterSortTypeEnum.START_DATE) as FormControl<FilterSortTypeEnum>
    });
  }

  createCoursesFilterFormGroup(): FormGroup<CoursesFilterFormInterface> {
    return this.fb.group<CoursesFilterFormInterface>({
      courseName: this.fb.control<string>('') as FormControl<string>,
      courseDanceStyles: this.fb.array<string>([]) as FormArray<FormControl<string>>,
      courseStartDifficulty: this.fb.control<number>(0) as FormControl<number>,
      courseEndDifficulty: this.fb.control<number>(100) as FormControl<number>,
      courseSortType: this.fb.control<FilterSortTypeEnum>(FilterSortTypeEnum.START_DATE) as FormControl<FilterSortTypeEnum>
    });
  }

  createStudiosFilterFormGroup(): FormGroup<StudiosFilterFormInterface> {
    return this.fb.group<StudiosFilterFormInterface>({
      studioName: this.fb.control<string>('') as FormControl<string>,
      studioDanceStyles: this.fb.array<string>([]) as FormArray<FormControl<string>>,
      studioCategories: this.fb.array<DanceManagerCategoryEnum>([]) as FormArray<FormControl<DanceManagerCategoryEnum>>
    });
  }
  
  // Functionality Methods

  updateQueryParamsOnCategoryChanges(tabValue: SearchCategoryEnum): void {
    this.router.navigate([], {
      queryParams: {
        search_type: tabValue,
        ...this.getQueryParamsByFilter(tabValue),
        ...this.getLocationQueryParams(this.coordinatesStateService.getSyncCurrentCoordinates())
      },
    });
  }

  openFilterDialog(): void {
    const filterDialogData: FilterDialogDataInterface = {
      filterFormGroup: this.filterFormGroup
    };
    
    const dialogRef = this.matDialog.open(SearchFilterDialogComponent, {
      maxWidth: '600px',
      panelClass: 'round-panel',
      data: filterDialogData,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.changed) {
        if (result.reset) {
          this.filterFormGroup = this.createFilterFormGroup();
        }
        this.adjustQueryParamsWithFilterUpdate();
      }
    });
  }
  
  private getQueryParamsByFilter(category: SearchCategoryEnum): any {
    let queryParams;

    if (category === SearchCategoryEnum.EVENTS || category === SearchCategoryEnum.FESTIVALS) {
      const eventFilterValue = this.filterFormGroup.controls.eventsFilter.getRawValue();
      queryParams = {
        musicStyles: eventFilterValue.eventMusicStyles.length ? eventFilterValue.eventMusicStyles.join(',') : null,
        danceStyles: eventFilterValue.eventDanceStyles.length ? eventFilterValue.eventDanceStyles.join(',') : null,
        categories: eventFilterValue.eventCategories.length ? eventFilterValue.eventCategories.join(',') : null,
        sortColumn: eventFilterValue.eventSortType ?? null,
        name: eventFilterValue.eventName && eventFilterValue.eventName !== '' ? eventFilterValue.eventName : null
      };
    } else if (category === SearchCategoryEnum.COURSES) {
      const courseFilterValue = this.filterFormGroup.controls.coursesFilter.getRawValue();
      queryParams = {
        danceStyles: courseFilterValue.courseDanceStyles.length ? courseFilterValue.courseDanceStyles.join(',') : null,
        name: courseFilterValue.courseName && courseFilterValue.courseName !== '' ? courseFilterValue.courseName : null
      };
    } else if (category === SearchCategoryEnum.STUDIOS) {
      const studioFilterValue = this.filterFormGroup.controls.studiosFilter.getRawValue();
      queryParams = {
        danceStyles: studioFilterValue.studioDanceStyles.length ? studioFilterValue.studioDanceStyles.join(',') : null,
        categories: studioFilterValue.studioCategories.length ? studioFilterValue.studioCategories.join(',') : null,
        name: studioFilterValue.studioName && studioFilterValue.studioName !== '' ? studioFilterValue.studioName : null
      };
    }
    
    return queryParams;
  }
  
  adjustQueryParamsWithFilterUpdate(): void {
    const queryParams = this.getQueryParamsByFilter(this.filterFormGroup.controls.searchCategory.value);
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  resetSearch(): void {
    this.addressControl.setValue('');
  }

  onScrollDown(): void {
    if (this.isScrollLoading || this.shownElements?.results.length >= this.shownElements?.totalElements) {
      return;
    }
    this.loadData(true);
  }

  getTitleTranslateKey(): string {
    switch (this.filterFormGroup.controls.searchCategory.value) {
      case SearchCategoryEnum.EVENTS:
        return 'GENERIC_WRAPPER.EVENT_SEARCH_AD_TITLE';
      case SearchCategoryEnum.FESTIVALS:
        return 'GENERIC_WRAPPER.EVENT_SEARCH_AD_TITLE';
      case SearchCategoryEnum.COURSES:
        return 'GENERIC_WRAPPER.COURSE_SEARCH_AD_TITLE';
      case SearchCategoryEnum.STUDIOS:
        return 'GENERIC_WRAPPER.DM_SEARCH_AD_TITLE';
    }
    return '';
  }

  getDescriptionTranslateKey(): string {
    switch (this.filterFormGroup.controls.searchCategory.value) {
      case SearchCategoryEnum.EVENTS:
        return 'GENERIC_WRAPPER.EVENT_SEARCH_AD_SUBTITLE';
      case SearchCategoryEnum.FESTIVALS:
        return 'GENERIC_WRAPPER.EVENT_SEARCH_AD_SUBTITLE';
      case SearchCategoryEnum.COURSES:
        return 'GENERIC_WRAPPER.COURSE_SEARCH_AD_SUBTITLE';
      case SearchCategoryEnum.STUDIOS:
        return 'GENERIC_WRAPPER.DM_SEARCH_AD_SUBTITLE';
    }
    return '';
  }

  getImageSourceKey(): string {
    switch (this.filterFormGroup.controls.searchCategory.value) {
      case SearchCategoryEnum.EVENTS:
        return DF_SEARCH_EVENT_AD_PICTURE;
      case SearchCategoryEnum.FESTIVALS:
        return DF_SEARCH_EVENT_AD_PICTURE;
      case SearchCategoryEnum.COURSES:
        return DF_SEARCH_COURSE_AD_PICTURE;
      case SearchCategoryEnum.STUDIOS:
        return DF_SEARCH_DM_AD_PICTURE;
    }
    return '';
  }
  
  // Search Bar with Google Autocomplete below

  setupCoordinationSearch(): void {
    // this.options = {
    //   types: [],
    // };
    // TODO: Talk with team, if this is even needed
    // this.getBrowserPermissionForLocationOfUser();
    // this.initializeInputElement();
  }

  getBrowserPermissionForLocationOfUser(): void {
    navigator.geolocation.getCurrentPosition(userPosition => {
      this.searchHttpService.getAddressFromCoordinate({latitude: userPosition.coords.latitude, longitude: userPosition.coords.longitude}).subscribe({
        next: (data) => {
          const location = data.results.map(a => this.getAddressComponent(a, 'locality')).filter(b => b?.long_name).map(c => c.long_name)[0];
          this.coordinatesStateService.sendCoordinates({
            latitude: userPosition.coords.latitude,
            longitude: userPosition.coords.longitude,
            location: location
          });
        }
      });
    }, null, { enableHighAccuracy: true });
  }

  initializeInputElement(): void {
    const element: HTMLInputElement = this.addressInput?.nativeElement || this.addressInputAlternative?.nativeElement;
    if (element) {
      this.selectFirstOnEnter(element);
    }
  }

  selectFirstOnEnter(input: HTMLInputElement): void {
    const addEventListener = input.addEventListener;
    input.addEventListener = (type, eventListener): void => {
      if (type === 'keydown') {
        const listener = eventListener;
        eventListener = (event): void => {
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;
          if (event.key === 'Enter' && !suggestionSelected) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown'
            });
            listener.apply(input, [e]);
          }
          listener.apply(input, [event]);
        };
      }
      addEventListener.apply(input, [type, eventListener]);
    };
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const eventValue = event.option.value;
    const selectedOption = this.currentAutocompleteOptions.find(
      option => option.description === eventValue
    );
    if (selectedOption.typeEnum === 'ADDRESS') {
      this.searchHttpService.getAddressDetailsFromPlaceId(selectedOption.value).subscribe(res => {
        this.handleAddressChange(res.result);
      });
    } else if (selectedOption.typeEnum === 'STUDIO') {
      this.router.navigate(['/', '@' + selectedOption.value]);
    } else {
      console.error('selectedOption has unknown Type');
    }
  }

  updateAddress(address: AddressInterface, updateUrlQueryParams = true): void {
    this.setAddress(address);
    
    if(updateUrlQueryParams) {
      this.updateLocationQueryParams(address);
    }

    this.coordinatesStateService.sendCoordinates({
      latitude: address.latitude,
      longitude: address.longitude,
      location: address.city + ',' + address.country,
      city: address.city,
      country: address.country,
    });
  }
  
  private updateLocationQueryParams(address?: AddressInterface | null): void {
    this.router.navigate([], {
      queryParams: this.getLocationQueryParams(address),
      queryParamsHandling: 'merge'
    });
  }
  
  private getLocationQueryParams(address: AddressInterface | null): any {
    return {
      latitude: address ? address.latitude : null,
      longitude: address ? address.longitude : null,
      location: address ? ( address.city + ',' + address.country ): null
    };
  }
  
  setAddress(address: AddressInterface): void {
    if (address.city && address.country) {
      this.addressControl.patchValue(address.city + ', ' + address.country);
    } else if (address.city) {
      this.addressControl.patchValue(address.city);
    }
  }

  initializeValueChangesSubscriptions(): void {
    this.searchOptions = this.searchFormGroup.valueChanges.pipe(
      map(formValue => formValue.address?.trim()),
      distinctUntilChanged(),
      debounceTime(300),
      filter(searchString => !!searchString),
      switchMap(searchString =>
        forkJoin([
          this.danceManagerHttpService.getAllByFilter({ page: 1, limit: 3, search: searchString }),
          this.searchHttpService.getAddressFromSearchKey(searchString)
        ]).pipe(
          map(([danceManagerHttpResponse, searchHttpResponse]) => {
            const predictions = searchHttpResponse?.predictions || [];
            const addressOptions = predictions.map(option => ({
              typeEnum: 'ADDRESS',
              description: option.description || '',
              value: option.place_id || ''
            })).filter(option => !!option.description);

            const danceManagerResults = danceManagerHttpResponse?.results || [];
            const studioOptions = danceManagerResults.map(result => ({
              typeEnum: 'STUDIO',
              description: result.name || '',
              value: result.urlName || '',
              imageUrl: result.imageUrl || ''
            })).filter(option => !!option.description);

            return [...studioOptions, ...addressOptions];
          })
        )
      ),
      tap(options => {
        this.currentAutocompleteOptions = options;
      })
    );
  }
  
  handleKeyDown(event: any): void {
    if (event.key === 'Enter') {
      if (this.currentAutocompleteOptions.length > 0) {
        const firstOption = this.currentAutocompleteOptions[0];
        if (firstOption.typeEnum === 'ADDRESS') {
          this.searchHttpService.getAddressDetailsFromPlaceId(firstOption.value).subscribe(res => {
            this.handleAddressChange(res.result);
          });
        } else if (firstOption.typeEnum === 'STUDIO') {
          this.router.navigate(['/', '@' + firstOption.value]);
        }
      }
    }
  }

  handleAddressChange(inputAddress: any): void {
    if (!inputAddress || !inputAddress.address_components) {
      this.addressControl.setErrors({invalid: true});
      return;
    }
    this.addressControl.setErrors(null);
    const address: AddressInterface = {
      city: this.getAddressComponent(inputAddress, 'locality') || this.getAddressComponent(inputAddress, 'administrative_area_level_1') || '',
      country: this.getAddressComponent(inputAddress, 'country') || '',
      formattedAddress: inputAddress.formatted_address,
      latitude: inputAddress.geometry.location.lat,
      longitude: inputAddress.geometry.location.lng,
      googleMapsUrl: inputAddress.url,
      googleMapsPlaceId: inputAddress.place_id,
      streetNumber: this.getAddressComponent(inputAddress, 'street_number') || '',
      zipCode: this.getAddressComponent(inputAddress, 'postal_code') || '',
      street: this.getAddressComponent(inputAddress, 'route') || '',
      state: this.getAddressComponent(inputAddress, 'administrative_area_level_1') || '',
      timezoneOffsetMinutes: inputAddress['utc_offset_minutes'] || 0,
      name: inputAddress['name'] || '',
    };
    this.updateAddress(address);
  }
  
  getAddressComponent(inputAddress: ElabGoogleAddress, type: string): string {
    const component = inputAddress.address_components.find(a => a.types.includes(type));
    return component ? component.long_name : '';
  }
  
  get addressControl(): AbstractControl {
    return this.searchFormGroup?.get('address');
  }
  
  protected readonly SearchCategoryEnum = SearchCategoryEnum;
  protected readonly DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;
}

export interface SearchOptionInterface {
  typeEnum: 'ADDRESS' | 'STUDIO'; 
  description: string; 
  value: string; 
  imageUrl: string;
}
export class GetElementsByFilterRequestDto {
  page: number;
  limit: number;
  sortColumn: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
  danceStyles?: string[];
  musicStyles?: string[];
  categories?: string[];
  name?: string;
  startDate?: Date;
  endDate?: Date;
  clientDateUtc?: Date;
  withAppointments: boolean;
  startDifficulty?: number;
  endDifficulty?: number;
  sortDirection?: SortDirectionEnum;
  danceManagerId?: string;
  danceManagerIds?: string[];
  search?: string;
}
