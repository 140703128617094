import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'df-shared-lib-redirect-wordpress-dialog',
  templateUrl: './redirect-wordpress-dialog.component.html',
  styleUrls: ['./redirect-wordpress-dialog.component.scss'],
})
export class RedirectWordpressDialogComponent {
  constructor(
    private readonly _matDialogRef: MatDialogRef<RedirectWordpressDialogComponent>
  ) {}

  closeClicked(): void {
    this._matDialogRef.close(false);
  }
}
