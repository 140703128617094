import { CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, CustomRecurrenceWeekDaysTypeEnum, SchedulerRecurrenceTypeEnum, SchedulerTypeEnum } from '../../enums';
import { AddressInterface } from '../address.interface';
import { LocationInterface } from '../location.interface';
import { RoomInterface } from '../room.interface';
import { AppointmentsInterface, AppointmentUsersInterface } from '../appointments';

export interface SchedulersInterface {
  additionalInformation?: string;
  address?: AddressInterface;
  appointmentToUsers?: AppointmentUsersInterface[];
  appointments?: AppointmentsInterface;
  createdAt?: Date;
  customRecurrenceEndType?: CustomRecurrenceEndTypeEnum;
  customRecurrenceNumber?: number;
  customRecurrenceType?: CustomRecurrenceTypeEnum;
  customRecurrenceWeekDaysTypes?: CustomRecurrenceWeekDaysTypeEnum[];
  duration?: number;
  endDate?: Date;
  id?: string;
  location?: LocationInterface;
  numberOfAppointments?: number;
  recurrenceType?: SchedulerRecurrenceTypeEnum;
  room?: RoomInterface;
  schedulerType: SchedulerTypeEnum;
  startDate?: Date;
  updatedAt?: Date;
}
