<ng-container *transloco="let t">
  <div class="mt2 flex-column" style="gap: 10px;">
    <span *ngIf="!amenitiesTypeRoom" [ngClass]="amenitiesTypeRoom ? 'mb0' : ''">
      <b> {{t ('GENERIC_WRAPPER.AMENITIES.TITLE') + (amenitiesTypeRoom ? '' : ' (optional)')}}</b>
    </span>
      <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{t ('GENERIC_WRAPPER.AMENITIES.TITLE')}}</mat-label>
      <input
        autocomplete="off"
        type="text"
        matInput
        [matAutocomplete]="autocomplete"
        (click)="matAutocompleteTrigger.openPanel()"
        [formControl]="filterInput"
      >
      <mat-autocomplete
        #autocomplete="matAutocomplete"
        (optionSelected)="onAmenitySelected($event)"
        hideSingleSelectionIndicator
      >
        <mat-option [value]="amenity" *ngFor="let amenity of filteredAmenitiesList | async">
          <mat-icon [ngClass]="amenity.iconKey | amenityIcon : true">{{ amenity.iconKey}}</mat-icon>
          {{(t('GENERIC_WRAPPER.AMENITIES.KEYS.' + amenity.iconKey | uppercase))}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  
    <div class="flex-row-wrap" style="gap: 20px;">
      <mat-chip-listbox>
        <div class="flex-column">
          <ng-container *ngIf="locationAmenities">
            <div class="flex-row-wrap">
              <mat-chip-option
                style="background-color: #f5f5f5"
                [matTooltip]="t('GENERIC_WRAPPER.AMENITIES.TITLE')"
                *ngFor="let locationAmenity of locationAmenities"
                [removable]="false"
                [selectable]="false"
              >
                <mat-icon [ngClass]="locationAmenity.iconKey | amenityIcon: true">{{ locationAmenity.iconKey }}</mat-icon>
                <span class="ml1">
                  {{(t('GENERIC_WRAPPER.AMENITIES.KEYS.' + locationAmenity.iconKey | uppercase))}}
                </span>
              </mat-chip-option>
            </div>
          </ng-container>
  
          <ng-container *ngIf="amenities">
            <div class="flex-row-wrap">
              <mat-chip-option               
                *ngFor="let amenity of amenities; let i = index"
                [selectable]="false"
                [removable]="true"
                (removed)="deleteAmenity(i)"
              >
                <div class="flex-row h-100 align-center">
                  <mat-icon [ngClass]="amenity.iconKey | amenityIcon: true">{{ amenity.iconKey }}</mat-icon>
                  <span class="ml1">
                    {{(t('GENERIC_WRAPPER.AMENITIES.KEYS.' + amenity.iconKey | uppercase))}}
                  </span>
                </div>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-option>
            </div>
          </ng-container>
        </div>
      </mat-chip-listbox>
    </div>
  </div>
</ng-container>
