<ng-container *transloco="let t">
  <div class="h-100 flex-column align-center p3 p0" style="background-color: #fafafa">
      <ng-container *ngIf="isIntervalActive">
          <mat-spinner [diameter]="70"></mat-spinner>
          <span class="pt-20">{{t('GENERIC_WRAPPER.THIS_COULD_TAKE_A_FEW_SECONDS')}}</span>
      </ng-container>
      <ng-container *ngIf="!isIntervalActive">
          <img src="assets/images/check_circle.png" />
          <span class="pt-20">{{ t('GENERIC_WRAPPER.SUBSCRIPTION_SUCCESSFUL')}}</span>
          <span>{{ t('GENERIC_WRAPPER.REDIRECT_TO_DANCE_MANAGER')}}</span>
      </ng-container>
  </div>
</ng-container>
