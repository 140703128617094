export const DfmLocationCreateRouterParam = 'reset-password-token';

export const DfmDanceManagerUrlNameRouterParam = 'urlName';

export const DanceflavorsDanceManagerIdRouterParam = 'dance-manager-id';

export const DfmDanceManagerLocationIdRouterParam = 'location-id';

export const DfmDanceManagerPassIdRouterParam = 'passId';
export const DfmDanceManagerPassSubscriptionIdRouterParam = 'passSubscriptionId';

export const dfResetPasswordTokenRouterParam = 'reset-password-token';
export const dfConfirmDeleteAccountTokenRouterParam = 'delete-account-token';
export const dfConfirmDeactivateAccountTokenRouterParam = 'deactivate-account-token';
export const dfConfirmEmailTokenRouterParam = 'confirm-email-token';
export const dfMagicLinkLoginTokenRouterParam = 'magic-link-token';

export const dfCourseIdRouterParam = 'course-id';
export const dfAppointmentIdRouterParam = 'appointment-id';
export const dfDanceEventIdRouterParam = 'dance-event-id';
export const dfUrlNameRouterParam = 'url-name';

export const dfmPayoutId = 'dfmPayoutId';

export const dfmUserGroupId = 'dfmUserGroupId';
