import { DanceEventsDto } from './dance-events.dto';
import { DanceEventCoHostsInterface, DanceEventCoHostStatus } from '../../..';
import { DanceSchoolDto } from './dance-school.dto';

export class DanceEventCoHostsDto implements DanceEventCoHostsInterface{
  danceManager: DanceSchoolDto;
  danceEvent: DanceEventsDto;
  danceManagerId: string;
  danceEventId: string;
  status: DanceEventCoHostStatus;
}
