import { DanceStyleInterface } from '../dance-style.interface';
import { UsersInterface } from './users.interface';

export interface UsersDanceStylesInterface {
  userId?: string;
  danceStyleId?: string;
  translationKey?: string;
  experience?: number;
  isLeader?: boolean;
  isFollower?: boolean;
  danceStyle?: DanceStyleInterface;
  user?: UsersInterface;
}
