import { FollowInterface } from './follow.interface';

export interface FollowUserInterface {
  follow: FollowInterface;
  user?: FollowedUserInterface;
  isCurrentUserFollowing: boolean;
  currentUserFollowId: string;
  danceSchool?: FollowedDanceSchoolInterface;
}

export interface FollowedUserInterface {
  id: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  username: string;
} 

export interface FollowedDanceSchoolInterface {
  id: string;
  name: string;
  imageUrl?: string;
  urlName: string;
}
