import { LocationInterface } from '../../..';
import { DanceSchoolAddressDto, DanceSchoolLocationOpeningHoursDto } from '../../..';

export class LocationDmOverviewDto implements LocationInterface {
  id: string;
  name: string;
  address: DanceSchoolAddressDto;
  phonePrefix: string;
  phonePrefixAfter: string;
  phoneNumber: string;
  email: string;
  openingHours: DanceSchoolLocationOpeningHoursDto;
  showOpeningHours: boolean;
  isPublic: boolean;
}
