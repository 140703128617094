import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'skillLevelTranslation'
})
export class SkillLevelTranslationPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 0 && value <= 20) {
      return 'LEVEL_SLIDER_ENUM.BEGINNER';
    }
    if (value > 20 && value <= 40) {
      return 'LEVEL_SLIDER_ENUM.IMPROVER';
    }
    if (value > 40 && value <= 60) {
      return 'LEVEL_SLIDER_ENUM.INTERMEDIATE';
    }
    if (value > 60 && value <= 80) {
      return 'LEVEL_SLIDER_ENUM.ADVANCED';
    }
    if (value > 80 && value <= 100) {
      return 'LEVEL_SLIDER_ENUM.MASTER';
    }
    return '';
  }
}
