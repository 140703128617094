<ng-container *transloco="let t">
  <ng-container *ngIf="passedFormGroup">
    <div [formGroup]="passedFormGroup">
      <div *ngIf="passedFormGroup.valid" class="flex-column step-body-container gap-12">
        <div class="flex-column">
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.TICKET_NAME') }}:</h4>
            <span>{{passedFormGroup.controls.ticketSettings.controls.name.value}}</span>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.EVENT') }}:</h4>
            <span>{{passedFormGroup.controls.ticketSettings.controls.event.value.name}}</span>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.DESCRIPTION') }}:</h4>
            <div style="border-width: 0;">
              <div style="padding: 0;" class="description-text" [innerHTML]="passedFormGroup.controls.ticketSettings.controls.description.value | dynamicTag"></div>
            </div>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.QUANTITY') }}:</h4>
            <span>{{ passedFormGroup.controls.ticketSettings.controls.availableQuantity.value}} {{t('GENERIC_WRAPPER.PIECE')}}</span>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.PRICE') }}:</h4>
            <span>{{ passedFormGroup.controls.pricing.controls.price.value | currencyPosition}}</span>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.TAXES') }}:</h4>
            <span>{{ passedFormGroup.controls.pricing.controls.tax.controls.percent.value}} %</span>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.FEES') }}:</h4>
            <span>{{ t('GENERIC_WRAPPER.' + (passedFormGroup.controls.pricing.controls.feesPaidBy.value === FeesPaidByEnum.CREATOR ? 'PAID_BY_CREATOR' : 'PAID_BY_CUSTOMER')) }}</span>
          </div>
        </div>
        <div class="flex-column">
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.SALE_START') }}:</h4>
            <span *ngIf="saleStartDate">{{saleStartDate | date: 'dd.MM.yyyy - HH:mm':null:'de-DE'}}</span>
            <span *ngIf="!saleStartDate">-</span>
          </div>
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.SALE_END') }}:</h4>
            <span *ngIf="saleEndDate">{{saleEndDate | date: 'dd.MM.yyyy - HH:mm':null:'de-DE'}}</span>
            <span *ngIf="!saleEndDate">-</span>
          </div>
        </div>
        <div class="flex-column gap-5 mb-10">
          <div class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.FOLLOWER_LEADER_OPTION') }}:</h4>
            <span>{{t('GENERIC_WRAPPER.' + passedFormGroup.controls.salesSettings.controls.sellAsFollowerLeader.value ? 'ACTIVE' : 'INACTIVE')}}</span>
          </div>
          <div *ngIf="passedFormGroup.controls.salesSettings.controls.sellAsFollowerLeader.value === true" class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.SELL_AS_FOLLOWER_ACTIVE') }}:</h4>
            <span>{{t('GENERIC_WRAPPER.' + passedFormGroup.controls.salesSettings.controls.sellAsFollowerActive.value ? 'ACTIVE' : 'INACTIVE')}}</span>
          </div>
          <div *ngIf="passedFormGroup.controls.salesSettings.controls.sellAsFollowerLeader.value === true" class="flex-row gap-5">
            <h4 style="margin: 0">{{t('GENERIC_WRAPPER.SELL_AS_LEADER_ACTIVE') }}:</h4>
            <span>{{t('GENERIC_WRAPPER.' + passedFormGroup.controls.salesSettings.controls.sellAsLeaderActive.value ? 'ACTIVE' : 'INACTIVE')}}</span>
          </div>
          <div class="flex-row gap-5">
            <span *ngIf="passedFormGroup.controls.salesSettings.controls.danceManagerUserGroupIds?.value !== null && passedFormGroup.controls.salesSettings.controls.danceManagerUserGroupIds.value.length > 0; else forEveryone">
              {{ t('GENERIC_WRAPPER.FOR_SALE_TO') + ' ' + getSaleGroups() }}
            </span>
            <ng-template #forEveryone>
              {{ t('GENERIC_WRAPPER.USER_GROUP_AVAILABLE_FOR_SALE_TO_EVERYONE') }}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
