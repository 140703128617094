import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventorySubscriptionListComponent } from './inventory-subscription-list.component';
import { MatChip } from '@angular/material/chips';
import { DanceEventPreviewCardModule, DfSharedButtonModule } from '@platri/dfx-angular-core';
import {
    InventorySubscriptionListItemModule
} from "libs/frontend/df-user/src/lib/components/inventory/inventory-subscription-list-item";
import { InventoryPassListItemModule } from '../inventory-pass-list-item';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [InventorySubscriptionListComponent],
    imports: [
      CommonModule,
      MatChip,
      DanceEventPreviewCardModule,
      DfSharedButtonModule,
      InventorySubscriptionListItemModule,
      InventoryPassListItemModule,
      TranslocoDirective
    ],
  exports: [InventorySubscriptionListComponent]
})
export class InventorySubscriptionListModule {}
