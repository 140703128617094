import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-shared-lib-phone-link',
  templateUrl: './phone-link.component.html',
  styleUrls: ['./phone-link.component.scss'],
})
export class PhoneLinkComponent {
  @Input() phone: { number: string; prefix: string; prefixAfter: string };
}
