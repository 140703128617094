import { DanceManagerPayoutTransactionInterface, DanceManagerPayoutTransactionStatusEnum } from '../../..';

export class DanceManagerPayoutTransactionDto implements DanceManagerPayoutTransactionInterface{
  id: string;
  stripeTransactionId: string;
  stripePaymentIntentId: string;
  amount: number;
  fee: number;
  netAmount: number;
  type: DanceManagerPayoutTransactionStatusEnum;
}
