import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordPageComponent } from './forgot-password-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { ForgotPasswordFormModule, LoginFormModule, RegisterFormModule } from '../../components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ForgotPasswordPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: '',
      component: ForgotPasswordPageComponent
    }]),
    LoginFormModule,
    MatDividerModule,
    RegisterFormModule,
    ForgotPasswordFormModule
  ],
  exports: [ForgotPasswordPageComponent]
})
export class ForgotPasswordPageModule { }
