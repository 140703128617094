<ng-container *transloco="let t">
  <div class="relative h-100 page-layout" id="main">
    <div class="event-background-header">
      <img class="hide-on-mobile" [src]="danceEvent?.mainImage?.url ?? DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER" alt=""/>
    </div>
    
    <div class="w-100 flex-column" style="max-width: 1140px;">
         <!-- CoHosts Invitation Component-->
      <df-shared-lib-co-host-notification
        [invitedCoHosts]="invitedCoHosts"
        [danceEvent]="danceEvent"
        (acceptInvitation)="acceptCoHostInvitation($event)"
        (declineInvitation)="declineCoHostInvitation($event)">
      </df-shared-lib-co-host-notification>
         <!--end of CoHosts Invitation Component-->
      
      <mat-card class="event-content-card flex-column" id="dance-event-content" style="margin-bottom: 16px;">
        <div *ngIf="danceEvent" class="w-100" id="top-column">
          <div *ngIf="isOwnerOrAdmin && danceSchool" class="flex-row hide-on-web" style="padding-bottom: 16px; padding-left: 16px;">
            <a
              [href]="'/app/dance-manager/' + danceSchool?.id + '/events/preview/' + danceEvent?.id + '#info'"
              class="flex-row"
              >
              <mat-icon>edit</mat-icon>
              <span class="pl-5 start-center">{{ t('DANCE_EVENT.EDIT_EVENT') }}</span>
            </a>
          </div>
          <div class="header-image-mobile hide-on-web">
            <df-dance-event-header
              [isOwnerOrAdmin]="isOwnerOrAdmin"
              [danceEvent]="danceEvent"
            ></df-dance-event-header>
          </div>
          <div
            class="hide-on-web"
            id="right-column"
            style="width: -webkit-fill-available;"
          >
          <h1 class="event-title-description hide-on-web pt-20">{{danceEvent.name}}</h1>
          <div *ngIf="appointment" class="pt-20 flex-row-start-center gap-10">
            <mat-icon style="min-width: 24px;">calendar_today</mat-icon>
            <span>{{ danceEvent.appointments[0].startDate | dateRange : danceEvent.appointments[0].duration : currentUser?.appLang ?? null}} {{appointment.address.timezoneId |timezoneAbbreviation:appointment.startDate}}</span>
          </div>
          <div
            class="relative flex-column hide-on-web"
          > 
            <div
              class="max-width-65 text-overflow left-column-text-container"
            >
              <div *ngIf="danceEvent.danceStyles && danceEvent.danceStyles.length > 0">
              <h4 class="mb15 mt-15">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h4>
              <mat-chip-listbox aria-label="Dancestyles list">
                <mat-chip-option
                  selectable="false"
                  *ngFor="let danceStyle of danceEvent.danceStyles"
                >
                  {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <div *ngIf="danceEvent.musicStyles && danceEvent.musicStyles.length > 0">
              <h4 class="mb15 mt25">{{ t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT') }}</h4>
              <mat-chip-listbox aria-label="Musicstyles list">
                <mat-chip-option
                  selectable="false"
                  *ngFor="let musicStyle of danceEvent.musicStyles"
                >
                  {{t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase())}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
              <div class="pt-20">
<!--                <h1 class="event-title-description">{{danceEvent.name}}</h1>-->
<!--                <span class="categories-list" *ngFor="let category of danceEvent.categories; last as isLast">{{ category | titlecase }}<span *ngIf="!isLast">, </span></span>-->
                <div class="pt2 ql-container" style="border-width: 0;">
                  <div style="padding: 0;" class="ql-editor description-text" [innerHTML]="danceEvent.description | dynamicTag"></div>
                </div>
              </div>
              <div
                class="mb0"
                *ngIf="(danceEvent.restrictions.clothingRestriction.isRestricted &&
                danceEvent.restrictions.clothingRestriction.restriction)"
              >
                <div
                  *ngIf="danceEvent.restrictions.clothingRestriction && danceEvent.restrictions.clothingRestriction.isRestricted"
                >
                  <h4 class="mb0 mt25">
                    {{ t('GENERIC_WRAPPER.DRESSCODE') }}
                  </h4>
                  <p
                    class="mb0"
                    [innerHTML]="danceEvent.restrictions.clothingRestriction.restriction"
                  ></p>
                </div>
              </div>
            </div>
            
            <div
              class="hide-on-web"
              *ngIf="danceEvent.tags && danceEvent.tags.length > 0"
            >
              <h4 class="mb15">{{ t('GENERIC_WRAPPER.TAGS') }}</h4>
              <mat-chip-listbox aria-label="Tags list">
                <mat-chip-option
                  selectable="false"
                  *ngFor="let tag of danceEvent.tags"
                >
                  {{tag}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            
            <div *ngIf="danceSchool && danceSchool?.id !== '5fb56fa1-c04c-4d4a-997b-1ac416f54b72'" class="mb0" style="padding-top: 16px;">
            <h4 *ngIf="danceSchool && danceSchool?.id !== '5fb56fa1-c04c-4d4a-997b-1ac416f54b72'" class="mb1">{{ t('GENERIC_WRAPPER.ORGANIZER') }}</h4>
            <a
              *ngIf="danceSchool && danceSchool?.id !== '5fb56fa1-c04c-4d4a-997b-1ac416f54b72'"
              [routerLink]="['/@' + danceSchool.urlName]"
              style="text-decoration: none; color: inherit;">
              <mat-list>
                <div
                  class="link organizer flex-row gap-10"
                >
                  <img
                    class="circle"
                    style="width: 35px; height: 35px"
                    [src]="danceSchool.imageUrl ?? 'assets/images/studio_logo.png'"
                    alt=""
                  />
                  <mat-list-item><b>{{danceSchool.name}}</b></mat-list-item>
                </div>
              </mat-list>
            </a>
              <!-- need the function for validation before routing -->
            <div *ngIf="danceSchool && danceSchool?.id !== '5fb56fa1-c04c-4d4a-997b-1ac416f54b72'">
              <button
                [dfUnauthorizedClickCheck]="this.authService.getSyncCurrentUser() !== null"
                class="contact-organizer-style"
                (click)="openChatWithDanceSchool()"
              >
                {{ t('GENERIC_WRAPPER.CONTACT_ORGANIZER') }}
              </button>
            </div>
          </div>
          <df-event-co-hosts [coHosts]="danceEvent.coHosts"></df-event-co-hosts>
            
            <div class="hide-on-web mt2" *ngIf="danceEvent.url">
              <div class="flex-row w-100 align-start-center">
                <button
                  [routerLink]="['https://' + this.danceEvent.url]"
                  [routerLinkActive]="'active-link'"
                  mat-icon-button
                  class="border-radius-20 no-border align-self-center"
                >
                  <mat-icon class="mat-icon-font-size">language</mat-icon>
                </button>
                <a
                  [href]="'https://' + this.danceEvent.url"
                  target="_blank"
                  rel="noopener"
                  class="url-style"
                >
                  {{ t('GENERIC_WRAPPER.FIND_OUT_MORE_ABOUT_THIS_EVENT') }}
                </a>
              </div>
            </div>

            <div *ngIf="appointment" style="margin-bottom: 50px;"> 
              <h4 class="mb15 mt25">{{ t('GENERIC_WRAPPER.EVENT_LOCATION') }}</h4>
              <div class="flex-row">
                <div class="w-100">
                  <span
                    class="link"
                    target="_blank"
                    style="white-space: pre-line"
                    (click)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)"
                  >
                    {{ appointment | googleAddressReadable}}
                  </span>
                </div>
              </div>
              <div
                id="google-maps-container"
                class="w-100 mt-20 top-align maps-container" 
              >
                <google-map
                  [height]="200"
                  width="100%"
                  [options]="googleMapsOptions"
                  [center]="[appointment.address.latitude, appointment.address.longitude] | googleLatLngLiteralPipe"
                  (mapClick)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)"
                >
                  <map-marker
                    #markerElement="mapMarker"
                    [position]="[appointment.address.latitude, appointment.address.longitude] | googleLatLngLiteralPipe"
                    [clickable]="false"
                  >
                  </map-marker>
                </google-map>
              </div>
            </div>
            
          </div>
        </div>
          
          <div
            id="left-column1"
            class="relative pr4 flex-column hide-on-mobile"
          >
            <div class="hide-on-mobile">
              <df-dance-event-header
                [isOwnerOrAdmin]="isOwnerOrAdmin"
                [danceEvent]="danceEvent"
              ></df-dance-event-header>
            </div>
            <div
              class="pr2 text-overflow left-column-text-container"
            >
            <div>
<!--              <h1 class="event-title-description">{{danceEvent.name}}</h1>-->
<!--              <span class="categories-list" *ngFor="let category of danceEvent.categories; last as isLast">{{ category | titlecase }}<span *ngIf="!isLast">, </span></span>-->
<!--              <h3>About this Event</h3>-->
              <div class="ql-container" style="border-width: 0;">
                <div style="padding: 0;" class="ql-editor description-text" [innerHTML]="danceEvent.description | dynamicTag"></div>
              </div>
            </div>
            <div
              class="mb0"
              *ngIf="(danceEvent.restrictions.clothingRestriction.isRestricted &&
             danceEvent.restrictions.clothingRestriction.restriction)"
            >
              <div
                *ngIf="danceEvent.restrictions.clothingRestriction && danceEvent.restrictions.clothingRestriction.isRestricted"
              >
                <h4 class="mb0 mt25">
                  {{ t('GENERIC_WRAPPER.DRESSCODE') }}
                </h4>
                <p
                  class="mb0"
                  [innerHTML]="danceEvent.restrictions.clothingRestriction.restriction"
                ></p>
              </div>
            </div>

            <div
              class="hide-on-mobile"
              *ngIf="danceEvent.tags && danceEvent.tags.length > 0"
            >
              <h4 class="mb15 mt25">{{ t('GENERIC_WRAPPER.TAGS') }}</h4>
              <mat-chip-listbox aria-label="Tags list">
                <mat-chip-option
                  selectable="false"
                  *ngFor="let tag of danceEvent.tags"
                >
                  {{tag}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            <div class="hide-on-mobile" *ngIf="danceEvent.url"> 
              <i class="circle-icon"
                ><mat-icon class="mat-icon-font-size">language</mat-icon></i
              >
              <a
                [href]="this.danceEvent.url"
                target="_blank"
                rel="noopener"
                class="url-style"
              >
                {{ t('GENERIC_WRAPPER.FIND_OUT_MORE_ABOUT_THIS_EVENT') }}</a
              >
            </div>
          </div>
          </div>
          <div
            id="right-column"
            style="width: -webkit-fill-available;"
            class="hide-on-mobile"
            >
            <div *ngIf="isOwnerOrAdmin && danceSchool" class="flex-row" style="padding-bottom: 10px;">
              <a
                [href]="'/app/dance-manager/' + danceSchool.id + '/events/preview/' + danceEvent.id + '#info'"
                class="flex-row"
                >
                <mat-icon>edit</mat-icon>
                <span class="pl-5 start-center">{{ t('DANCE_EVENT.EDIT_EVENT') }}</span>
              </a>
            </div>
<!--            <div class="event-title">-->
<!--              <span>{{danceEvent.name}}</span>-->
<!--            </div>-->
            <h1 class="event-title-description">{{danceEvent.name}}</h1>
            <span class="categories-list" *ngFor="let category of danceEvent.categories; last as isLast">{{ category | titlecase }}<span *ngIf="!isLast">, </span></span>
                
            <div *ngIf="appointment && (danceEvent.status === DanceEventStatusEnum.ACTIVE || danceEvent.status === DanceEventStatusEnum.DRAFT)" class="flex-row gap-10 pt-20">
              <mat-icon style="min-width: 24px;">calendar_today</mat-icon>
              <span>{{ danceEvent.appointments[0].startDate | dateRange : danceEvent.appointments[0].duration: currentLang ?? null}} {{appointment.address.timezoneId |timezoneAbbreviation:appointment.startDate}}</span>
            </div>
            <div *ngIf="danceEvent.status === DanceEventStatusEnum.CANCELLED">
              <span>{{ t('DANCE_EVENT.CANCELLED_INFO') }}</span>
            </div>
            
            <div *ngIf="!isEventActive" class="align-center past-event-box">
              <mat-icon style="color: #3377FF; margin-right: 10px;">event_busy</mat-icon>
              {{ t('DANCE_EVENT.PAST_EVENT') }}
            </div>
            
            <div *ngIf="danceEvent.status === DanceEventStatusEnum.ACTIVE || danceEvent.status === DanceEventStatusEnum.DRAFT" style="align-items: center;" class="tickets-button-container flex-row pt-20">
              <a class="w-100 tickets-button" (click)="onClickTicketsButton()" style="text-decoration: none; color: inherit; height: 40px;">
                 <df-shared-lib-button
                   [buttonLabel]="t('GENERIC_WRAPPER.TICKETS') + (ticketMinPrice > -1 ? ' ' + t('GENERIC_WRAPPER.PRICE_FROM') + ' ' + (ticketMinPrice | centsToCurrencyString: 'EUR') : '')"
                   [materialButtonStyleType]="'flat'"
                   [height]="'37px'"
                   [minWidth]="'100%'"
                   [isDisabled]="!isEventActive"
                   [matIconName]="'shopping_cart'"
                 ></df-shared-lib-button>
                  <span *ngIf="pageViews && pageViews > 5" style="color: orangered;text-align: center;display: flex;flex-direction: row;place-content: center;margin-top: 4px;">{{t('GENERIC_WRAPPER.ALREADY_SEEN_PAGE_VIEW_TEXT', {views: pageViews}) }}</span>
              </a>
              <div class="w-25 hide-on-mobile flex-row">
                <button mat-icon-button (click)="openShareComponentDialog()">
                  <mat-icon>share</mat-icon>
                </button>
              </div>
            </div>
            
            <div
              *ngIf="danceEvent.danceStyles && danceEvent.danceStyles.length > 0"
            >
              <h4 class="mb15 mt25">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h4>
              <mat-chip-listbox aria-label="Dancestyles list">
                <mat-chip-option
                  selectable="false"
                  *ngFor="let danceStyle of danceEvent.danceStyles"
                >
                  {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
            
            <div
              *ngIf="danceEvent.musicStyles && danceEvent.musicStyles.length > 0"
            >
              <h4 class="mb15 mt25">{{ t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT') }}</h4>
              <mat-chip-listbox aria-label="Musicstyles list">
                <mat-chip-option
                  selectable="false"
                  *ngFor="let musicStyle of danceEvent.musicStyles"
                >
                  {{t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase())}}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>

            <div *ngIf="danceSchool && danceSchool?.id !== '5fb56fa1-c04c-4d4a-997b-1ac416f54b72'" class="mb0" style="padding-top: 30px">
              <h4 class="mb1 mt0">
                {{ t('GENERIC_WRAPPER.ORGANIZER') }}
              </h4>
              <a 
                [routerLink]="['/@' + danceSchool.urlName]"
                style="text-decoration: none; color: inherit;">
                  <div
                    class="link organizer flex-row gap-10"
                  >
                    <img
                      class="circle"
                      style="width: 35px; height: 35px"
                      [src]="danceSchool.imageUrl ?? 'assets/images/studio_logo.png'"
                      alt=""
                    />
                    <b class="organizer-style">{{danceSchool.name}}</b>
                  </div>
              </a>
              <!-- need the function for validation before routing -->
              <div *ngIf="danceSchool">
                <button
                  [dfUnauthorizedClickCheck]="this.authService.getSyncCurrentUser() !== null"
                  class="contact-organizer-style"
                  (click)="openChatWithDanceSchool()"
                >
                  {{ t('GENERIC_WRAPPER.CONTACT_ORGANIZER') }}
                </button>
              </div>
            </div>
            <df-event-co-hosts [coHosts]="danceEvent.coHosts"></df-event-co-hosts>
            <div *ngIf="appointment">
              <h4 class="mb15 mt25">{{ t('GENERIC_WRAPPER.EVENT_LOCATION') }}</h4>
              <div class="flex-row">
                <div class="w-100">
                  <span
                    class="link"
                    target="_blank"
                    style="white-space: pre-line"
                    (click)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)"
                  >
                    {{ appointment | googleAddressReadable}}
                  </span>
                </div>
              </div>
              <div
                id="google-maps-container"
                class="h-100 w-100 mt-20 top-align"
              >
                <google-map
                  [height]="200"
                  width="100%"
                  [options]="googleMapsOptions"
                  [center]="[appointment.address.latitude, appointment.address.longitude] | googleLatLngLiteralPipe"
                  (mapClick)="openGoogleMapsInNewTab(appointment.address.googleMapsUrl)"
                >
                  <map-marker
                    #markerElement="mapMarker"
                    [position]="[appointment.address.latitude, appointment.address.longitude] | googleLatLngLiteralPipe"
                    [clickable]="false"
                  >
                  </map-marker>
                </google-map>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div
    class="sticky-tickets-button-container"
  >
    
      <df-shared-lib-button
        class="w-100"
        [buttonLabel]="t('GENERIC_WRAPPER.TICKETS') + (ticketMinPrice > -1 ? ' ' + t('GENERIC_WRAPPER.PRICE_FROM') + ' ' + (ticketMinPrice | centsToCurrencyString: 'EUR') : '')"
        [materialButtonStyleType]="'flat'"
        [height]="'37px'"
        [minWidth]="'100%'"
        [isDisabled]="!isEventActive"
        [matIconName]="'shopping_cart'"
        (buttonClicked)="onClickTicketsButton()"
      ></df-shared-lib-button>
    
    <span *ngIf="pageViews && pageViews > 5" style="color: orangered;text-align: center;display: flex;flex-direction: row;place-content: center;">{{ t('GENERIC_WRAPPER.ALREADY_SEEN_PAGE_VIEW_TEXT', {views: pageViews}) }}</span>
    
  </div>
</ng-container>
