import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfLogoComponent } from './df-logo.component';

@NgModule({
  declarations: [DfLogoComponent],
  imports: [CommonModule],
  exports: [DfLogoComponent],
})
export class DfLogoModule {}
