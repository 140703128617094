
import { DanceManagerSocialLinkInterface } from './dance-manager-social-link.interface';
import { DanceSchoolToUserInterface } from './dance-school-to-user.interface';
import { DanceManagerSubscriptionInterface } from './dance-manager-subscription.interface';
import { DanceManagerFaqInterface } from './dance-manager-faq.interface';
import { DanceManagerCategoryEnum, DanceManagerSubscriptionTypeEnum, DanceSchoolStatusEnum, DanceSchoolTypeEnum, RadiusUnitEnum } from '../enums';
import { LocationInterface } from './location.interface';
import { ImageInterface } from './image.interface';
import { AddressInterface } from './address.interface';
import { DanceStyleInterface } from './dance-style.interface';

export interface DanceSchoolInterface {
  id?: string;
  name?: string;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  urlName?: string;
  danceSchoolType?: DanceSchoolTypeEnum;
  imageUrl?: string;
  imageGalleryUrls?: string[];
  danceSchoolToUsers?: DanceSchoolToUserInterface[];
  email?: string;
  socialLinks?: DanceManagerSocialLinkInterface[];
  danceSchoolStatus?: DanceSchoolStatusEnum;
  description?: string;
  teamDescription?: string;
  locations?: LocationInterface[];
  faqs?: DanceManagerFaqInterface[];
  titleImage?: ImageInterface;
  danceManagerCategory?: DanceManagerCategoryEnum[];
  danceManagerSubscriptionType?: DanceManagerSubscriptionTypeEnum;
  danceManagerSubscriptionId?: string;
  danceManagerSubscriptions?: DanceManagerSubscriptionInterface[];
  stripePayoutsEnabled?: boolean;
  stripeTotalSaldo?: number;
  businessAddress?: AddressInterface;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  danceStyles?: DanceStyleInterface[];
  vat?: number;
}
