import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StandaloneAppComponent } from './standalone-app.component';
import { dfmAppRoutes } from '@platri/dfm-angular-app';
import { dfAppRoutes } from '@platri/df-angular-app';

const routes: Routes = [
  {
    path: '',
    component: StandaloneAppComponent,
    children: [
      ...dfmAppRoutes,
      ...dfAppRoutes,
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StandaloneRoutingModule {
  constructor() {
    console.log("Dfx StandaloneRoutingModule initialized");
  }
}
