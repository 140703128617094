import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { PassValidityIntervalEnum } from '@platri/df-common-core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'df-pass-info-form',
  templateUrl: './pass-info-form.component.html',
  styleUrls: ['./pass-info-form.component.scss']
})
export class PassInfoFormComponent implements OnInit {
  @Input() infoFormGroup: UntypedFormGroup;
  @Input() priceFormGroup: UntypedFormGroup;
  @Input() enabledUsesOption = false;
  @Input() enabledValidDateOption = false;
  @Output() enabledUsesOptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() enabledValidDateOptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  passValidityIntervalEnum = PassValidityIntervalEnum;
  
  ngOnInit(): void {
    if (this.trial.value) {
      this.usageCount.patchValue(1);
      this.usageCount.disable();
      this.priceFormGroup.get('price').patchValue(0);
      this.priceFormGroup.get('price').disable();
      this.priceFormGroup.get('taxPercentage').disable();
    }
  }

  onTrialCheckboxClicked(event: MatCheckboxChange): void {
    if (event.checked) {
      this.usageCount.patchValue(1);
      this.usageCount.disable();
      this.priceFormGroup.get('price').patchValue(0);
      this.priceFormGroup.get('price').disable();
      this.priceFormGroup.get('taxPercentage').disable();
    }
    else {
      this.usageCount.enable();
      this.priceFormGroup.get('price').enable();
      this.priceFormGroup.get('taxPercentage').enable();
    }
  }
  
  onUsesOptionCheckboxClicked(): void {
    this.enabledUsesOption = !this.enabledUsesOption;
    this.enabledUsesOptionChange.emit(this.enabledUsesOption);
  }

  onValidOptionCheckboxClicked(): void {
    this.enabledValidDateOption = !this.enabledValidDateOption;
    this.enabledValidDateOptionChange.emit(this.enabledValidDateOption);
  }
  
  get name(): AbstractControl {
    return this.infoFormGroup.get('name');
  }
  get quantity(): AbstractControl {
    return this.infoFormGroup.get('quantity');
  }
  get usageCount(): AbstractControl {
    return this.infoFormGroup.get('usageCount');
  }
  get validity(): AbstractControl {
    return this.infoFormGroup.get('validity');
  }
  get description(): AbstractControl {
    return this.infoFormGroup.get('description');
  }
  get trial(): AbstractControl {
    return this.infoFormGroup.get('trial');
  }
}
