import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceCourseHeaderComponent } from './dance-course-header.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ShareModule } from '../share';
import { ImageUploadCropperModule } from '../image-cropper';
import { LevelSliderViewModule } from '../level-slider-view';
import { PipesModule } from '../../pipes';
import { RouterModule } from '@angular/router';
import { DfSharedButtonModule } from '../buttons';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceCourseHeaderComponent],
  exports: [DanceCourseHeaderComponent],
    imports: [
      CommonModule,
      MatCardModule,
      MatIconModule,
      MatButtonModule,
      MatMenuModule,
      ShareModule,
      ImageUploadCropperModule,
      LevelSliderViewModule,
      PipesModule,
      RouterModule,
      DfSharedButtonModule,
      TranslocoDirective,
    ],
})
export class DanceCourseHeaderModule {}
