<ng-container *transloco="let t">
  <mat-card style="margin-top: 10px;">
    <div class="flex-column align-space-between" style="gap: 1em;">
      <div class="flex-row align-space-between center w-100">
        <span class="ticket-name">{{ pass.name }}</span>      
        <span> {{ pass.trial ? ( t('GENERIC_WRAPPER.FREE') ) : pass.price | centsToCurrencyString : 'EUR' }}</span>
      </div>
      <span class="usage-text">
        {{ pass.usageCount !== null ? pass.usageCount + ' ' + t('GENERIC_WRAPPER.STAMPS') + (pass.validity !== null ? '. ' : '') : '' }}
        {{ pass.validity !== null ? t('GENERIC_WRAPPER.VALID_FOR') + ' ' + pass.validity.value + ' ' + t(pass.validity.interval +  (pass.validity.value > 1 ? '_PLURAL' : '_SINGULAR')) + ' ' + t('GENERIC_WRAPPER.AFTER_PURCHASE') : '' }}
      </span>
      <div *ngIf="pass.description">    
        <div class="flex-row flex-column description-text w-100">
          <div style="width: 100%; margin-bottom: 0" [innerHTML]="!isDescriptionTruncated ? (pass.description | dynamicTag | truncate: descriptionTruncateLength) : (pass.description | dynamicTag)"></div>
        </div>
        <div *ngIf="hasTruncateLength && !isDescriptionTruncated">
          <span class="read-more-text" (click)="isDescriptionTruncated = true">{{ t('GENERIC_WRAPPER.SHOW_MORE')}}</span>
        </div>
        <div *ngIf="hasTruncateLength && isDescriptionTruncated">
          <span class="read-more-text" (click)="isDescriptionTruncated = false">{{ t('GENERIC_WRAPPER.SHOW_LESS')}}</span>
        </div>
      </div>
      <div *ngIf="!isLoading" class="flex-row align-space-between">
        <div>
          <span class="bold">{{ t('GENERIC_WRAPPER.COURSES_YOU_CAN_ACCESS')}}:</span>
          <mat-list>
            <ng-container *ngIf="!isCoursesTruncated; else truncatedList">
              <ng-container *ngFor="let passTarget of pass.passTargets; let i = index">
                <ng-container *ngIf="i < coursesTruncateLength && loadedCourses[passTarget.targetId]?.name">
                  <mat-list-item style="max-height: 20px;">
                    <a class="bullet-list" [href]="'/app/dance-class/' + loadedCourses[passTarget.targetId]?.id">
                      <span class="pass-detail-info">{{ loadedCourses[passTarget.targetId]?.name }}</span>
                    </a>
                  </mat-list-item>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #truncatedList>
              <ng-container *ngFor="let passTarget of pass.passTargets">
                <ng-container *ngIf="loadedCourses[passTarget.targetId]?.name">
                  <mat-list-item style="max-height: 20px;">
                    <a class="bullet-list" [href]="'/app/dance-class/' + loadedCourses[passTarget.targetId]?.id">
                      <span class="pass-detail-info">{{ loadedCourses[passTarget.targetId]?.name }}</span>
                    </a>
                  </mat-list-item>
                </ng-container>
              </ng-container>
            </ng-template>
          </mat-list>
          <div *ngIf="hasTruncateElements && !isCoursesTruncated">
            <span class="read-more-text" (click)="isCoursesTruncated = true">{{ t('GENERIC_WRAPPER.SHOW_MORE') }}</span>
          </div>
          <div *ngIf="hasTruncateElements && isCoursesTruncated">
            <span class="read-more-text" (click)="isCoursesTruncated = false">{{ t('GENERIC_WRAPPER.SHOW_LESS') }}</span>
          </div>
        </div>
        <div class="align-end">
          <div class="align-center gap-10" *ngIf="!isOwnerOrAdmin">
            <div *ngIf="pass.availableQuantity > 0 && !pass.trial" class="flex-row-end-center w-100 row-center">
              <button (click)="changeValue(-1)" class="cursor-pointer" [ngClass]="currentQuantity.value > 0 ? 'quantity-button' : 'disabled-quantity-button'" ><mat-icon style="margin-top: 2px">remove</mat-icon></button>
              <span class="ml-10 mr-10">{{ currentQuantity.value }}</span>
              <button (click)="changeValue(1)" class="cursor-pointer" [ngClass]="currentQuantityCanBeIncreased ? 'quantity-button' : 'disabled-quantity-button'"><mat-icon style="margin-top: 2px">add</mat-icon></button>
            </div>
            <df-shared-lib-button
              [buttonLabel]="pass.trial ? t('DANCE_MANAGER.TRY_OUT') : t('GENERIC_WRAPPER.BUY')"
              [loading]="isLoading"
              [isDisabled]="isCreatingTicketOrder || (currentQuantity.value === 0 && !pass.trial)"
              (buttonClicked)="emitRouteToPassBuyout()"
            ></df-shared-lib-button>
          </div>
          <button mat-icon-button *ngIf="isOwnerOrAdmin && currentUser" (click)="emitRouteToPassEdit()">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
