import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthStateService, HeaderStatusEnum } from '@platri/dfx-angular-core';
import { UsersInterface } from '@platri/df-common-core';
import { Router } from '@angular/router';

@Component({
  selector: 'df-header-app',
  templateUrl: './header-app.component.html',
  styleUrls: ['./header-app.component.scss'],
})
export class HeaderAppComponent implements OnInit, OnDestroy {
  headerStatusEnum = HeaderStatusEnum;
  headerStatus = HeaderStatusEnum.BEFORE_LOGGED_IN;
  currentUser: UsersInterface;

  subscription: Subscription = new Subscription();

  constructor(
    private readonly authService: AuthStateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.listenOnCurrentUserChanges();
  }

  listenOnCurrentUserChanges(): void {
    this.subscription.add(
      this.authService
        .getAsyncCurrentUser()
        .subscribe((user: UsersInterface) => {
          if (user) {
            this.currentUser = user;
            this.headerStatus = HeaderStatusEnum.AFTER_LOGGED_IN;
          } else {
            this.headerStatus = HeaderStatusEnum.BEFORE_LOGGED_IN;
          }
        })
    );
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
