import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { loadScript } from '@paypal/paypal-js';
import { CurrencyTypeEnum } from '@platri/df-common-shared-graphql';
import { PaypalIntentEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-paypal-payment',
  templateUrl: './paypal-payment.component.html',
  styleUrls: ['./paypal-payment.component.scss'],
})
export class PaypalPaymentComponent implements OnInit {
  @Input() currency: CurrencyTypeEnum = CurrencyTypeEnum.EUR;
  @Input() paypalClientId: string;
  @Input() paypalOrderId: string;
  @Input() paypalIntent = PaypalIntentEnum.CAPTURE;
  @Input() returnUrl: string;

  @Input() paymentInProgressLabel = 'Payment in progress ...';

  @Output() capturePaymentEvent = new EventEmitter<void>();

  isProcessing = false;
  isFailed = false;
  paypalButtonsInitialized = false;

  constructor(private readonly _cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initPaypalScript();
  }

  initPaypalScript(): void {
    loadScript({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'client-id': this.paypalClientId,
      currency: this.currency,
      intent: this.paypalIntent.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'disable-funding':
        'card,credit,bancontact,sepa,paylater,blik,eps,giropay,ideal,mercadopago,mybank,p24,sofort,venmo',
      components: 'buttons,funding-eligibility',
    })
      .then((paypal) => {
        // @ts-ignore
        paypal
          .Buttons({
            createOrder: async (data, actions) => this.paypalOrderId,
            onApprove: async (data, actions) => {
              this.capturePaymentEvent.emit();
            },
            onError: async () => {
              this.isFailed = true;
            },
          })
          .render('#paypal-buttons')
          .then(() => {
            this.paypalButtonsInitialized = true;
            this._cd.detectChanges();
          })
          .catch((error) => {
            console.error('Failed to render the PayPal Buttons', error);
          });
      })
      .catch((error) => {
        console.error('Failed to load the PayPal JS SDK script', error);
      });
  }
}
