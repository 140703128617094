import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DfmSettingsPage } from './pages/app';
import { TranslocoStateConst } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

const routes: Routes = [
  { 
    path: '' ,
    component: DfmSettingsPage
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), TranslocoModule],
  exports: [RouterModule]
})
export class FrontendDfmSettingsAppRoutingModule {
  constructor() {
    console.log('FrontendDfmSettingsAppRoutingModule initialized');
    TranslocoStateConst.push({ scope: 'dfm-settings', alias: 'DANCE_MANAGER_SETTINGS' });
  }
}
