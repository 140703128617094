import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CourseListTableModule, CoursePassesTableModule, CourseSubscriptionTableModule, DfSharedButtonModule, PaginatorModule, PipesModule, SharedEmptyPageModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EmptyCoursesListModule } from '../../components/empty-courses-list/empty-courses-list.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CoursePreviewModule } from '../course-preview/course-preview.module';
import { AppointmentParticipantsPageModule } from '../appointment-participants/appointment-participants-page.module';
import { CreateCourseStepperPageModule } from '../create-course-stepper-page/create-course-stepper-page.module';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { DfmCoursesComponent } from './dfm-courses.component';
import {TranslocoDirective} from "@jsverse/transloco";
import { DfmCourseSubscriptionsPageModule } from '../dfm-course-subscriptions-page/dfm-course-subscriptions-page.module';
import {DfmCoursePassesPageModule} from "../dfm-course-passes-page/dfm-course-passes-page.module";
import {DfmCoursePageModule} from "../dfm-course-page/dfm-course-page.module";

@NgModule({
  declarations: [DfmCoursesComponent],
    imports: [
        CommonModule,
        MatDividerModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        MatListModule,
        MatSlideToggleModule,
        PaginatorModule,
        MatTooltipModule,
        MatTabsModule,
        EmptyCoursesListModule,
        InfiniteScrollModule,
        PipesModule,
        CoursePreviewModule,
        AppointmentParticipantsPageModule,
        CreateCourseStepperPageModule,
        DfSharedButtonModule,
        SharedEmptyPageModule,
        CourseListTableModule,
        CoursePassesTableModule,
        BreadcrumbComponent,
        BreadcrumbItemDirective,
        CourseSubscriptionTableModule,
        TranslocoDirective,
        DfmCourseSubscriptionsPageModule,
        DfmCoursePassesPageModule,
        DfmCoursePageModule
    ]
})
export class DfmCoursesModule {}
