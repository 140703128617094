import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environmentForWeb } from '@platri/df-common-core';

@Component({
  selector: 'df-user-inventory',
  templateUrl: './inventory.page.html',
  styleUrls: ['./inventory.page.scss']
})
export class InventoryPage implements OnInit, OnDestroy {
  activeTabIndex = 0;

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;

  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.checkCurrentUrlFragment();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkCurrentUrlFragment(): void {
    this.subscriptions.add(
      this.route.fragment.subscribe((hashtag) => {
        if (hashtag != null) {
          hashtag = hashtag.toLowerCase();
          switch (hashtag) {
            case 'tickets': {
              this.activeTabIndex = 0;
              break;
            }
            case 'passes': {
              this.activeTabIndex = 1;
              break;
            }
            case 'subscriptions': {
              this.activeTabIndex = 2;
              break;
            }
          }
        } else {
          this.changeUrlFragment(0);
        }
      })
    );
  }

  changeUrlFragment(index: number): void {
    switch (index) {
      case 0: {
        window.location.hash = '#tickets';
        break;
      }
      case 1: {
        window.location.hash = '#passes';
        break;
      }
      case 2: {
        window.location.hash = '#subscriptions';
        break;
      }
    }
  }
}
