import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { FrontendDfDanceManagerRoutingModule } from './frontend-df-dance-manager-routing.module';
import {
  DanceManagerPassesCheckoutCompletePageModule,
  DanceManagerPassesCheckoutPageModule,
  DanceManagerPassesPageModule,
  DanceManagerPublicPageModule
} from './pages';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfDanceManagerRoutingModule,
    DanceManagerPublicPageModule,
    DanceManagerPassesPageModule,
    DanceManagerPassesCheckoutPageModule,
    DanceManagerPassesCheckoutCompletePageModule,
    MatSnackBarModule,
    TranslocoModule,
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendDfDanceManagerModule {
}
