<ng-container *transloco="let t">
  <div class="flex-column-center-center">
    <div class="flex-column w-100 pb2" style="max-width: 1140px;">
      <mat-card class="inventory-container round-corners mat-elevation-z3 mt2 w-100 no-shadow-mat-card" style="max-width: 1140px; padding: 16px;">
        <div class="p2 no-padding-mobile">
          <span class="my-inventory-title">{{ t('GENERIC_WRAPPER.MY_INVENTORY') }}</span>
          <mat-tab-group [selectedIndex]="activeTabIndex" (selectedIndexChange)="changeUrlFragment($event)">
            <mat-tab label="{{ t('GENERIC_WRAPPER.TICKETS') }}">
              <df-user-inventory-ticket-list *ngIf="activeTabIndex === 0"></df-user-inventory-ticket-list>
            </mat-tab>
            <mat-tab label="{{ t('GENERIC_WRAPPER.PASSES') }}">
              <df-user-inventory-pass-list *ngIf="activeTabIndex === 1"></df-user-inventory-pass-list>
            </mat-tab>
            <mat-tab *ngIf="dmPassSubscriptionFeature" label="{{ t('GENERIC_WRAPPER.SUBSCRIPTIONS') }}">
              <df-user-inventory-subscription-list *ngIf="activeTabIndex === 2"></df-user-inventory-subscription-list>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card>
    </div>
  </div>
</ng-container>
