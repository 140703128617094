import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { CustomMatSnackbarService } from '@platri/dfx-angular-core';


declare const FB: any;

@Component({
  selector: 'elab-facebook-sign-in-button',
  templateUrl: './facebook-sign-in-button.component.html',
  styleUrls: ['./facebook-sign-in-button.component.scss']
})
export class FacebookSignInButtonComponent {
  @ViewChild('facebookButton') facebookButton: ElementRef;
  
  @Output() accessTokenEmitter = new EventEmitter<string>();
  
  constructor(private matSnackbar: CustomMatSnackbarService) {}

  async facebookLogin(): Promise<void> {
    await this.initializeFacebook();
    if (this.facebookButton) {
      FB.login(async (response: any) => {
        if (response.status === 'connected') {
          const scopes = await this.getFacebookPermissions(response.authResponse.userID);
          this.handleFacebookPermissions(scopes);
        } else if (response.status === 'not_authorized') {
          console.error('User did not authorize the app.');
        } else {
          console.error('Facebook login error:', response);
        }
      }, {
        scope: 'public_profile,email',
      });
    } else {
      console.error('Custom Facebook button element not found.');
    }
  }

  private initializeFacebook(): Promise<void> {
    return new Promise((resolve) => {
      FB.init({
        appId: '716652238980640',
        cookie: true,
        xfbml: true,
        version: 'v9.0',
        status: true,
      });
      resolve();
    });
  }

  private async getFacebookPermissions(userId: string): Promise<any> {
    return new Promise((resolve) => {
      FB.api(`${userId}/permissions`, (scopes: any) => {
        resolve(scopes);
      });
    });
  }

  private handleFacebookPermissions(scopes: any): void {
    scopes.data.forEach((permission: any) => {
      if (permission.permission === 'email') {
        if (permission.status === 'declined') {
          this.handleDeclinedEmailPermission();
        } else {
          this.handleAcceptedEmailPermission();
        }
      }
    });
  }

  private handleDeclinedEmailPermission(): void {
    this.matSnackbar.openError('The email permission from facebook is required to create an account for you');
    FB.login(() => {}, {
      scope: 'email',
      auth_type: 'rerequest',
    });
  }

  private handleAcceptedEmailPermission(): void {
    this.accessTokenEmitter.emit(FB.getAuthResponse().accessToken);
  }
  
}
