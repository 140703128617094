import { DanceManagerPayoutDetailInterface } from '../../..';
import { DanceManagerPayoutDetailElementResponseDto } from '../../..';

export class DanceManagerPayoutDetailDto implements DanceManagerPayoutDetailInterface {
  id: string;
  totalAmount: number;
  totalFee: number;
  totalNetAmount: number;
  elements: DanceManagerPayoutDetailElementResponseDto[];
}
