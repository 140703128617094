<ng-container *transloco="let t">
  <div *ngIf="profile" class="flex-column container w-100">
    <div class="flex-column-center-center profile-image-container w-100 px2 pt2 mb2">
      <div id="more-button-container" class="absolute top-0 right-0 mt1">
        <button *ngIf="!isCurrentUser" class="vert" mat-icon-button color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item (click)="openReportUserConfirmDialog()">
            <mat-icon>report</mat-icon>
            <span>{{ t('USER.REPORT_USER') }}</span>
          </button>
        </mat-menu>
      </div>
      <mat-icon 
        *ngIf="isCurrentUser && profile.imageUrl" 
        color="warn" 
        class="delete-icon" 
        [ngClass]="isCurrentUser ? 'clickable-icon' : 'read-only'" 
        (click)="deleteProfilePicture()"
      >cancel</mat-icon>
        
      <img
        imageFallback
        #profileImage
        [ngClass]="isCurrentUser ? 'editable-transform' : 'read-only'"
        class="profile-image w-100 my2"
        [src]="profile.imageUrl && profile.imageUrl !== '' ? profile.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
        [alt]="t('GENERIC_WRAPPER.PROFILE_PICTURE')"
        (click)="openProfilePictureDialog()"
        [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
      />
      
      <span class="username">
        <strong>{{ profile.username }}</strong>
        <span class="bold" *ngIf="profile.birthday && !(profile.firstName || profile.lastName)">, {{ age }}</span>
      </span>
      <div class="flex-row name">
        <span *ngIf="profile.firstName || profile.lastName">{{ profile.firstName }} {{ profile.lastName }}</span>
        <span *ngIf="(profile.firstName || profile.lastName) && profile.birthday">, {{ age }}</span>
      </div>
      <div *ngIf="userFollowStateService.availableFollowCount | async as followStats" class="flex-row-center-center gap-20 follow-info mt2">
        <span (click)="openFollowInfoDialog(0)">
          <b>{{ followStats.followerCount }}</b>
          {{ t('GENERIC_WRAPPER.FOLLOWERS') }}
        </span>
        <span (click)="openFollowInfoDialog(1)">
          <b>{{ followStats.followCount }}</b>
          {{ t('GENERIC_WRAPPER.FOLLOWING') }}
        </span>
      </div>
      
       <df-shared-lib-button
         *ngIf="isCurrentUser"
         class="mt-10 w-100"
         [buttonLabel]="t(buttonLabel)"
         [matIconName]="'mode_edit'"
         [materialButtonStyleType]="'outlined'"
         (buttonClicked)="routeToSettings()"
       ></df-shared-lib-button>
  
      <div *ngIf="!isCurrentUser" id="Buttons Div" class="flex-row gap-10 w-100 pt3">
        <button
          *ngIf="userFollowStateService.availableFollowCount | async as followStats"
          mat-raised-button
          class="send-button follow-button"
          [ngClass]="followStats.isCurrentUserFollowing ? 'outline' : ''"
          color="primary"
          (click)="currentUser ? followUser() : routeToLogin()"
        >
          <span [ngClass]="followStats.isCurrentUserFollowing ? 'follow' : ''">
            {{
              followStats.isCurrentUserFollowing
                ? t('GENERIC_WRAPPER.FOLLOWING')
                : t('GENERIC_WRAPPER.FOLLOW')
            }}
          </span>
          <span *ngIf="followStats.isCurrentUserFollowing" [ngClass]="followStats.isCurrentUserFollowing ? 'unfollow' : ''">
            {{ t('GENERIC_WRAPPER.UNFOLLOW') }}
          </span>
        </button>
        <button mat-raised-button class="send-button outline" color="primary" (click)="currentUser ? openChat() : routeToLogin()">
          <span>
            {{ t('GENERIC_WRAPPER.SEND_MESSAGE') }}
          </span>
        </button>
      </div>
    </div>
  
    <div *ngIf="showAboutMe" class="flex-column content-container pt2 px2">
      <div class="flex-column align-start w-50">
        <span class="flex-column fs18 bold">
          {{ t('USER.ABOUT_ME') }}
        </span>
      </div>
      
      <div class="py1">
        <mat-divider></mat-divider>
      </div>
      
      <div class="pb3">
        <div class="card-description-text">
          <div [innerHTML]="displayDescription | dynamicTag | newLineBreak"></div>
        </div>
        <div *ngIf="truncatedDescription?.length > 150 && !showMoreDescription">
          <span class="read-more-text" (click)="toggleDescription()">{{ t('GENERIC_WRAPPER.READ_MORE') }}</span>
        </div>
        <div *ngIf="showMoreDescription">
          <span class="read-more-text" (click)="toggleDescription()">{{ t('GENERIC_WRAPPER.READ_LESS') }}</span>
        </div>
      </div>
    </div>
    
    <div *ngIf="showDetail" class="flex-column content-container p2">
      <span class="fs18 bold">
        {{ t('USER.DETAILS') }}
      </span>
      
      <div class="py1">
        <mat-divider></mat-divider>
      </div>
      
      <div *ngIf="profile.dancingArea" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">place</mat-icon>
        <span>{{ profile.dancingArea }}</span>
      </div>
      
      <div *ngIf="profile.height" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">height</mat-icon>
        <span>{{ profile.height }} cm {{ t('USER.HEIGHT_TALL') }}</span>
      </div>
      
      <div *ngIf="profile.monthsDancing" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">calendar_today</mat-icon>
        <span>{{t('USER.DANCING_FOR') + ' ' + (profile.monthsDancing | transformMonthYearLabel: false)}}</span>
      </div>
      
      <div *ngIf="profile.danceInterval && profile.danceInterval !== danceIntervalEnum.I_DONT_DANCE" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">update</mat-icon>
        <span>{{t('USER.DANCE_INTERVAL_ENUM.' + profile.danceInterval)}}</span>
      </div>
      
      <div *ngIf="profile.lookingFor && profile.lookingFor.length > 0" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">search</mat-icon>
        <span>{{t('USER.LOOKING_FOR', { lookingFor: profile.lookingFor.join(', ') }) }}</span>
      </div>
      
      <div *ngIf="profile.jobTitle" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">work_outline</mat-icon>
        <span>{{ profile.jobTitle }}</span>
      </div>
      
      <div *ngIf="profile.formerPlace" class="py1 fs16">
        <mat-icon class="vSub mr1 material-icons-round icon-color">public</mat-icon>
        <span>{{t('USER.FROM') + " " + profile.formerPlace}}</span>
      </div>
    </div>
    
    <div *ngIf="showDetail && profile.socialLinks && profile.socialLinks?.length > 0" class="flex-column content-container p2">
      <span class="fs18 bold">
        {{ t('USER.FIND_ME') }}
      </span>
      <div class="py1">
        <mat-divider></mat-divider>
      </div>
  
      <div class="py1 fs16">
        <span>
          <df-shared-lib-user-social-links-profile-icons 
            [socialLinks]="profile.socialLinks!"
          ></df-shared-lib-user-social-links-profile-icons>
        </span>
      </div>
    </div>
  </div>
  
  <ng-template #loadingSpinner>
    <mat-spinner [diameter]="100"></mat-spinner>
  </ng-template>
</ng-container>
