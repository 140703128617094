<ng-container *transloco="let t">
  <mat-toolbar style="padding: 0; background: none;">
    <div class="header-box">
      <div class="border-radius-20 no-border hide-when-large" style="position: absolute; left: 0">
        <button
          mat-icon-button
          [matMenuTriggerFor]="userMenu"
          (menuClosed)="toggleMenuIcon()"
          (menuOpened)="toggleMenuIcon()">
          <mat-icon>{{ this.isMenuOpen ? 'close' : 'menu'}}</mat-icon>
        </button>
      </div>
      
      <div class="web-mobile-alignment w-100">
        <df-header-logo data-cy="header-danceflavor-logo"></df-header-logo>
      </div>
      
      <div class="flex-row gap-20 adjust-postion">
        <df-header-dance-manager-selection 
          *ngIf="danceSchools?.length > 0" 
          class="hide-when-small" style="align-self: center;" [danceSchools]="danceSchools" 
          [unreadMessagesInterface]="countUnreadMessageDmSubject | async"
          [unreadNotificationsCountsByDanceManager]="unreadNotificationsCountsByDanceManager$ | async">
        </df-header-dance-manager-selection>
        
        <button
          data-cy="header-search-button"
          [routerLink]="['']"
          [routerLinkActive]="'active-link'"
          [routerLinkActiveOptions]="{ exact: false }"
          #searchRouterLinkActive="routerLinkActive"
          mat-icon-button
          [ngStyle]="{ opacity: this.isMenuOpen ? '0' : '1' }"
          class="border-radius-20 no-border align-self-center"
          matTooltip="{{ t('GENERIC_WRAPPER.SEARCH') }}">
          <mat-icon style="color: #4D4D4D">search</mat-icon>
        </button>
  
        <button
          data-cy="chats-header-button"
          routerLink="messages"
          routerLinkActive
          #messageRouterLinkActive="routerLinkActive"
          mat-icon-button
          class="border-radius-20 no-border align-self-center hide-when-small"
          matTooltip="{{ t('GENERIC_WRAPPER.MESSAGES') }}">
          <mat-icon
            [matBadge]="unreadMessagesCount"
            [matBadgeHidden]="unreadMessagesCount === 0"
            matBadgeColor="accent"
            [style.color]="messageRouterLinkActive.isActive ? '#3377FF' : '#4D4D4D'">
            chat_bubble_outline
          </mat-icon>
        </button>
        
        <df-header-user-notification [notifications]="userNotifications | async" data-cy="header-notification-button" [unreadCount]="unreadUserNotificationsCount | async"></df-header-user-notification>
       
  <!--      <div class="profile-image hide-when-small" [matMenuTriggerFor]="userMenu">-->
  <!--        <img imageFallback class="profile-picture" [src]="currentUser.imageUrl && currentUser.imageUrl !== '' ? currentUser.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" [alt]="currentUser.username" [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>-->
  <!--      </div>-->
        <df-header-user-button class="hide-when-small" [currentUser]="currentUser" (logout)="logout()"></df-header-user-button>
      </div>
    </div>
  </mat-toolbar>
  
  <mat-menu #userMenu="matMenu" class="header-menu my1">
    <div class="hide-when-large" (click)="$event.stopPropagation()">
      <div *ngIf="danceSchools?.length >= 1" class="align-center">
        <div style="padding: 16px 16px 0;">
          <label class='toggle-label'>
            <input type='checkbox' (click)="switchToggle();"/>
              <span class='back'>
              <span class='toggle'></span>
                <span class='label on'>Account</span>
                <span class='label off' 
                      [matBadge]="(unreadMessagesCount$ | async)?.amountOfUnreadDmMessages" 
                      [matBadgeHidden]="(unreadMessagesCount$ | async)?.amountOfUnreadDmMessages === 0" 
                      matBadgeColor="accent">
                  Dancemanager
                </span>  
            </span>
          </label>
        </div>
      </div>
    
      <df-shared-lib-sidenav-responsive-personal 
        *ngIf="!isDmSidenavOpen"
        [user]="currentUser" 
        [unreadMessagesCount]="(unreadMessagesCount$ | async)?.amountOfUnreadUserMessages" 
        (logoutClicked)="logout()" 
        (bugReportClicked)="openBugReport()"
        (featureRequestClicked)="openFeatureRequest()"
        (imprintClicked)="openImprint()"
        (closeMenu)="userMenu.close.emit()">
      </df-shared-lib-sidenav-responsive-personal>
      
      <df-shared-lib-sidenav-responsive-dm *ngIf="isDmSidenavOpen" [danceManagers]="danceSchools" (closeMenu)="userMenu.close.emit()"></df-shared-lib-sidenav-responsive-dm>
    </div>
    <div class="hide-when-small">
        <a mat-menu-item class="flex-row-start-center" [routerLink]="['@' + currentUser.username]" [routerLinkActive]="'active-link'">
          <mat-icon fontSet="material-icons-round" class="hide-when-large">person_outline</mat-icon>
          <span class="fs16">{{ t('GENERIC_WRAPPER.PROFILE') }}</span>
        </a>
        <a mat-menu-item class="flex-row-start-center" [routerLink]="'/inventory'" [routerLinkActive]="'active-link'">
          <mat-icon fontSet="material-icons-outlined" class="hide-when-large">inventory_2</mat-icon>
          <span class="fs16">{{ t('GENERIC_WRAPPER.MY_INVENTORY') }}</span>
        </a>
        <div class="px2 py1">
          <mat-divider></mat-divider>
        </div>
        <button mat-menu-item (click)="openStripeBillingPortalSession()">
          <span class="fs16">{{ t('GENERIC_WRAPPER.MANAGE_SUBSCRIPTIONS') }}</span>
        </button>
      
        <button mat-menu-item (click)="openBugReport()">
          <span class="fs16">{{ t('GENERIC_WRAPPER.REPORT_BUG') }}</span>
        </button>
      
        <button mat-menu-item (click)="openFeatureRequest()">
          <span class="fs16">{{ t('GENERIC_WRAPPER.FEATURE_REQUEST') }}</span>
        </button>
      
        <div class="px2 py1">
          <mat-divider></mat-divider>
        </div>
        <a mat-menu-item [routerLink]="['/settings']" [routerLinkActive]="'active-link'">
          <span class="fs16">{{ t('GENERIC_WRAPPER.SETTINGS') }}</span>
        </a>
        <button mat-menu-item (click)="logout()">
          <span class="fs16">{{ t('GENERIC_WRAPPER.LOGOUT') }}</span>
        </button>
    </div>
  </mat-menu>
</ng-container>
