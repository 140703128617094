import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConversationInterface, ConversationStreamInterface, environmentForWeb, MessageInterface, MessagePartnerInterface, UnreadMessagesInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class MessageHttpService {
  private apiUrl = environmentForWeb.apiMessageUrl;

  constructor(private http: HttpClient) {}

  createMessage(conversationPartnerId: string, message: string, currentPartnerId: string): Observable<ConversationInterface> {
    const body: MessageInterface = { text: message };

    return this.http.post<ConversationInterface>(`${this.apiUrl}/messages/conversations/${conversationPartnerId}/messages?currentPartnerId=${currentPartnerId}`, body);
  }

  delete(conversationPartnerId: string, currentPartnerId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/conversations/${currentPartnerId}/${conversationPartnerId}`);
  }

  getUnreadMessagesCount(): Observable<UnreadMessagesInterface> {
    return this.http.get<UnreadMessagesInterface>(`${this.apiUrl}/messages/unread`);
  }
  
  getConversationsWithLastMessage(currentPartnerId: string, limit?: number, lastMessageBefore?: Date): Observable<ConversationStreamInterface> {
    let params = new HttpParams();
    if (limit) {
      params = params.set('limit', limit);
    }
    if (lastMessageBefore) {
      params = params.set('lastMessageBefore', lastMessageBefore.toString());
    }
    return this.http.get<ConversationStreamInterface>(this.apiUrl + '/conversations/' + currentPartnerId, {params});
  }
  
  getConversationByPartnerId(currentPartnerId: string, partnerId: string): Observable<ConversationInterface> {
    return this.http.get<ConversationInterface>(this.apiUrl + '/conversations/' + currentPartnerId + '/' + partnerId);
  }
  
  getConversationByIdWithMessageCreatedGreater(id: string, messageCreatedGreater: Date): Observable<ConversationInterface> {
    const params = new HttpParams().set('messageCreatedGreater', messageCreatedGreater.toString());
    return this.http.get<ConversationInterface>(this.apiUrl + '/conversations/conversation/' + id, {params});
  }
  
  searchPartners(key: string): Observable<MessagePartnerInterface[]> {
    return this.http.get<MessagePartnerInterface[]>(this.apiUrl + '/message-partners/search/' + key);
  }
  
  searchPartner(username: string): Observable<MessagePartnerInterface> {
    return this.http.get<MessagePartnerInterface>(this.apiUrl + '/message-partners/search/one/' + username);
  }
}
