<ng-container *transloco="let t">
  <div class="flex-column-center-start bg-dark-white h-100 px2" style="min-height: 300px">
    <div class="flex-column w-100 mt2" style="max-width: 1140px;">
      <h3 style="font-size: 20px">
        <span class="clickable" [routerLink]="['/@' + currentUser.username]">{{currentUser.username}}</span> / {{ t('GENERIC_WRAPPER.SETTINGS') }}
      </h3>
      <mat-tab-group class="w-100" [selectedIndex]="activeTabIndex" (selectedIndexChange)="changeHashTag($event)">
          <mat-tab label="{{ t('GENERIC_WRAPPER.GENERAL') }}"><df-general-settings></df-general-settings></mat-tab>
          <mat-tab label="{{ t('GENERIC_WRAPPER.PROFILE') }}"><df-profile-settings></df-profile-settings></mat-tab>
          <mat-tab label="{{ t('USER.ACCOUNT') }}"><df-account-settings></df-account-settings></mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-container>
