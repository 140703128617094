<ng-container *transloco="let t">
  <div class="align-center pl3 pr3">
    <div class="flex-column pt2 w-100" style="min-height: calc(100vh - 56px); max-width: 1140px;">
      <div class="w-100 flex-column" style="flex: 0 0 70%">
        <div style="margin-bottom: 2px;">
          <xng-breadcrumb [separator]="iconTemplate">
            <ng-container *xngBreadcrumbItem="let breadcrumb">
              <ng-container>{{ t(breadcrumb) }}</ng-container>
            </ng-container>
          </xng-breadcrumb>
          <ng-template #iconTemplate>
            <mat-icon>chevron_right</mat-icon>
          </ng-template>
        </div>
      
        <div class="flex-row">
           <mat-card class="round-corners mat-elevation-z3 mt2 event-show event-show-respon w-100">
            <div class="px3 pt3">
              <div class="appointment-title">{{ appointmentTitle }}</div>
              
              <div *ngIf="appointment?.participants?.length > 0; else emptyView" class="w-100 flex-column" style="gap: 1rem;">
                <ng-container *ngIf="isInitialized else spinner">
                  
                  <span class="participants-title"> 
                    {{t('DANCE_MANAGER_COURSE.PARTICIPANTS')}} ({{appointment?.participants?.length}})
                  </span>
                  <table *ngIf="appointment?.participants?.length > 0; else emptyView" mat-table [dataSource]="participantListDataSource" matSort class="w-100 flex-table">
                  
                    <!-- Username/Email Column -->
                    <ng-container matColumnDef="username_email">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{t('DANCE_MANAGER_COURSE.PARTICIPANTS_TAB.USERNAME')}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="flex-row align-start-center">
                          <a *ngIf="loadedUsers[element.userId]" style="text-decoration: none; color: inherit;" [href]="'/app/@' + loadedUsers[element.userId]?.username">
                            {{ loadedUsers[element.userId]?.username }}
                          </a>
                        </div>
                      </td>
                    </ng-container>
                    
                    <!-- First Name Column -->
                    <ng-container matColumnDef="firstName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{t('GENERIC_WRAPPER.FIRST_NAME')}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="flex-row align-start-center">
                          <a *ngIf="loadedUsers[element.userId]?.firstName" [href]="'/app/@' + element.username">
                            {{ loadedUsers[element.userId]?.firstName }}
                          </a>
                          <span *ngIf="!loadedUsers[element.userId]?.firstName"> - </span>
                        </div>
                      </td>
                    </ng-container>
                    
                    <!-- Last Name Column -->
                    <ng-container matColumnDef="lastName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{t('GENERIC_WRAPPER.LAST_NAME')}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div class="flex-row align-start-center">
                         <a *ngIf="loadedUsers[element.userId]?.lastName" [href]="'/app/@' + element?.username">
                          {{ loadedUsers[element.userId]?.lastName }}
                        </a>
                        <span *ngIf="!loadedUsers[element.userId]?.lastName"> - </span>
                        </div>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{t('GENERIC_WRAPPER.STATUS')}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <span>
                          {{ t('GENERIC_WRAPPER.' + element.status) }}
                        </span>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="passes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{t('GENERIC_WRAPPER.PASSES')}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <span *ngIf="loadedUserPasses[element.userId]"> 
                          {{ loadedUserPasses[element.userId].pass ?
                          loadedUserPasses[element.userId].pass.name :
                          loadedUserPasses[element.userId].userPassSubscription.name}} 
                        </span>
                      </td>
                    </ng-container>
                    
                    
                    <ng-container matColumnDef="openChat">
                      <th mat-header-cell *matHeaderCellDef scope="col"></th>
                      <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.id">
                          <div class="flex-column align-center">
                            <div>
                              <mat-icon *ngIf="element.status === AppointmentParticipantStatusEnum.REGISTERED" style="cursor: pointer;" fontSet="material-icons-round" (click)="scan(element)">qr_code_scanner</mat-icon>
                            </div>
                          </div>
                        </span>
                      </td> 
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    
                  </table>
                </ng-container>
              </div>
  
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  
  
  
  <ng-template #spinner>
    <div class="loading-box w-100">
      <mat-spinner style="margin: auto" diameter="100"></mat-spinner>
    </div>
  </ng-template>
  
  <ng-template #emptyView>
    <div class="w-100 h-100 flex-column-center-center" style="min-height: 450px;">
      <df-shared-empty-page
        [emptyTitle]="t('DANCE_MANAGER_COURSE.PARTICIPANTS_TAB.NO_PARTICIPANTS')"
        [emptyDescription]="t('DANCE_MANAGER_COURSE.PARTICIPANTS_TAB.NO_PARTICIPANTS_DESC')"
        [imagePath]="'assets/images/events-manager/no_guests.svg'"
        [hideBtn]="true"
      ></df-shared-empty-page>
    </div>
  </ng-template>
</ng-container>
