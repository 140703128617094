import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DanceEventCategoryEnum, DanceManagerCategoryEnum } from '@platri/df-common-core';
import { FilterSortTypeEnum } from '@platri/dfx-angular-core';
import { SearchCategoryEnum } from '../enums';

export interface FilterFormInterface {
  eventsFilter: FormGroup<EventsFilterFormInterface>;
  coursesFilter: FormGroup<CoursesFilterFormInterface>;
  studiosFilter: FormGroup<StudiosFilterFormInterface>;
  searchCategory: FormControl<SearchCategoryEnum>;
}

export interface EventsFilterFormInterface {
  eventName: FormControl<string>;
  eventMusicStyles: FormArray<FormControl<string>>;
  eventDanceStyles: FormArray<FormControl<string>>;
  eventCategories: FormArray<FormControl<DanceEventCategoryEnum>>;
  eventSortType: FormControl<FilterSortTypeEnum>;
}

export interface CoursesFilterFormInterface {
  courseName: FormControl<string>;
  courseDanceStyles: FormArray<FormControl<string>>;
  courseStartDifficulty: FormControl<number>;
  courseEndDifficulty: FormControl<number>;
  courseSortType: FormControl<FilterSortTypeEnum>;
}

export interface StudiosFilterFormInterface {
  studioName: FormControl<string>;
  studioDanceStyles: FormArray<FormControl<string>>;
  studioCategories: FormArray<FormControl<DanceManagerCategoryEnum>>;
}
