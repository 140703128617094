import { CurrencyTypeEnum, TicketOrderInterface, TicketOrderStatusEnum } from '../../..';
import { UserTicketDto } from './user-ticket.dto';
import { UserPassDto } from './user-pass.dto';
import { BillingOrShippingAddressDto } from '../../..';
import { TicketOrderTransactionDto } from './ticket-order-transaction.dto';

export class TicketOrderDto implements TicketOrderInterface{
  id: string;
  invoiceId: string;
  userId: string;
  userEmail: string;
  userTickets: UserTicketDto[];
  userPasses: UserPassDto[];
  currencyType: CurrencyTypeEnum;
  totalPrice: number;
  totalTax: number;
  totalRefund: number;
  status: TicketOrderStatusEnum;
  billingAddress: BillingOrShippingAddressDto;
  shippingAddress: BillingOrShippingAddressDto;
  confirmationMailSent: boolean;
  ticketOrderTransactions: TicketOrderTransactionDto[];
}
