/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @angular-eslint/component-selector */
import { AfterViewInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TicketInterface, TicketSaleStatusEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-event-ticket-list-item',
  templateUrl: './event-ticket-list-item.component.html',
  styleUrls: ['./event-ticket-list-item.component.scss']
})
export class EventTicketListItemComponent implements AfterViewInit {
  @Input() ticket: TicketInterface;
  @Input() form: UntypedFormGroup;
  @ViewChild('descriptionEl') elementView: ElementRef;
  public currentLang;
  public showAllDescription = false;
  public isDescriptionMoreThenTwoLines = false;
  public readonly ticketSaleStatusEnum = TicketSaleStatusEnum;
  public readonly amounts = Array(10)
    .fill(10)
    .map((x, i) => i + 1);
  
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkDescriptionElMoreThenTwoLines();
  }

  constructor(
    private readonly translocoService: TranslocoService , 
    public dialog: MatDialog
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }
  ngAfterViewInit(): void{
    this.checkDescriptionElMoreThenTwoLines();
  }

  private checkDescriptionElMoreThenTwoLines(): void {
    this.isDescriptionMoreThenTwoLines = this.ticket.description?.length > 200;
  }

  get leaderQuantity(): AbstractControl<number> {
    return this.form.get(this.ticket?.id + '_leader');
  }
  
  get leaderQuantityCanBeIncreased(): boolean {
    const leaderQuantity = +this.leaderQuantity.value;
    const followerQuantity = +this.followerQuantity.value;
    return (leaderQuantity < (this.ticket.availableQuantity - (followerQuantity))) && (leaderQuantity < (this.ticket?.sellAsFollowerActive && this.ticket?.sellAsLeaderActive? 20 : 10) - followerQuantity);
  }

  setLeaderQuantity(value: number): void {
    this.form.get(this.ticket?.id + '_leader').setValue(value);
  }
  
  get followerQuantity(): AbstractControl<number> {
    return this.form.get(this.ticket?.id + '_follower');
  }
  
  get followerQuantityCanBeIncreased(): boolean {
    const leaderQuantity = +this.leaderQuantity.value;
    const followerQuantity = +this.followerQuantity.value;
    return (followerQuantity < (this.ticket.availableQuantity - leaderQuantity)) && (followerQuantity < ((this.ticket.sellAsFollowerActive && this.ticket.sellAsLeaderActive ? 20 : 10) - leaderQuantity));
  }

  setFollowerQuantity(value: number): void {
    this.form.get(this.ticket?.id + '_follower').setValue(value);
  }
  
  get currentQuantity(): AbstractControl<number> {
    return this.form.get(this.ticket?.id);
  }
  
  get currentQuantityCanBeIncreased(): boolean {
    const currentQuantity = +this.currentQuantity.value;
    return (currentQuantity < this.ticket.availableQuantity) && (currentQuantity < 10);
  }

  setQuantity(value: number): void {
    this.form.get(this.ticket?.id).setValue(value);
  }
  
  changeValue(number: number, isLeader: boolean = false, isFollower: boolean = false): void {
    if (isLeader ) {             // leader tickets
      const leaderQuantity = +this.leaderQuantity.value;
      if (((number === 1) && this.leaderQuantityCanBeIncreased) || ((number === -1) && leaderQuantity >= 1)) {
        this.setLeaderQuantity(leaderQuantity + number);
      }
    } else if (isFollower) {    // follower tickets
      const followerQuantity = +this.followerQuantity.value;
      if (((number === 1) && this.followerQuantityCanBeIncreased) || (number === -1 && followerQuantity >= 1)) {
        this.setFollowerQuantity(followerQuantity + number);
      }
    } else {                    // normal tickets
      const currentQuantity = +this.currentQuantity.value;
      if (((number === 1) && this.currentQuantityCanBeIncreased) || (number === -1 && currentQuantity >= 1)) {
        this.setQuantity(currentQuantity + number);
      }
    }
  }
  
}
