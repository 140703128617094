import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-shared-lib-email-link',
  templateUrl: './email-link.component.html',
  styleUrls: ['./email-link.component.scss'],
})
export class EmailLinkComponent {
  @Input() email: string;
}
