import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginFormInterface } from './login-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getSubscription } from '@platri/elab-angular-core';
import { AuthStateService, CustomMatSnackbarService, dfAppRoute, dfForgotPasswordRoute, dfMagicLoginRoute, dfRegisterRoute, noWhitespaceValidator } from '@platri/dfx-angular-core';

@Component({
  selector: 'elab-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Input() showSocialButtons = true;
  
  @Output() successfulLoginEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  loginFormGroup!: FormGroup<LoginFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForLoginResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  signInFailedWrongData = false;
  signInFailedNoConnection = false;
  signInFailedInternalServer = false;
  
  hidePassword = true;
  
  routerParams!: Params;
  returnUrlFromParam: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthStateService,
    private router: Router,
    private matSnackbar: CustomMatSnackbarService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.loginFormGroup = this.createLoginFormGroup();
  }

  createLoginFormGroup(): FormGroup<LoginFormInterface> {
    return this.fb.group<LoginFormInterface>({
      emailOrUsername: this.fb.control('', [Validators.required, noWhitespaceValidator(), Validators.maxLength(60)]),
      password: this.fb.control('', [Validators.required])
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.loginFormGroup.valueChanges.subscribe(res => {
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }

  onSubmit(): void {
    if (this.loginFormGroup.valid || (this.loginFormGroup.controls.emailOrUsername.hasError('password_wrong') || this.loginFormGroup.controls.password.hasError('password_wrong'))) {
      this.isWaitingForLoginResponse = true;
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
      const {emailOrUsername, password} = this.loginFormGroup.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const isEmail = emailOrUsername!.includes('@');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const body = isEmail ? {email: emailOrUsername!, password: password!} : {username: emailOrUsername!, password: password!};
      this.subscriptions.add(this.authService.signIn(body).subscribe(res => {
        this.successfulLoginEmitter.next(true);
        this.matSnackbar.openSuccess("Successfully logged in!");
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        } else {
          this.router.navigate([dfAppRoute]);
        }
        this.isWaitingForLoginResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        } else {
          this.onWrongPasswordEntered();
        }
        this.isWaitingForLoginResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.signInFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.signInFailedInternalServer = true;
  }
  
  onWrongPasswordEntered(): void {
    this.signInFailedWrongData = true;
    //this.loginFormGroup.controls.email.setErrors({'password_wrong': true});
    //this.loginFormGroup.controls.password.setErrors({'password_wrong': true});
  }
  
  routeToRegister(): void {
    this.router.navigate(['/', dfRegisterRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }
  
  routeToForgotPassword(): void {
    this.router.navigate(['/', dfForgotPasswordRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }
  
  routeToLoginWithoutPassword():void {
    this.router.navigate(['/', dfMagicLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: {email: this.loginFormGroup.getRawValue().emailOrUsername?.includes('@') ? this.loginFormGroup.getRawValue().emailOrUsername : null}});
  }

  signInWithGoogle(token: string): void {
    this.authService.signInGoogle(token).subscribe({
      next: () => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        } else {
          this.router.navigate([dfAppRoute]);
        }
      }
    });
  }
  signInWithFacebook(token: string): void {
    this.authService.signInFacebook({accessToken: token}).subscribe({
      next: () => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        } else {
          this.router.navigate([dfAppRoute]);
        }
      }
    });
  }

}
