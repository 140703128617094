import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DanceSchool } from '@platri/df-common-shared-graphql';
import { environmentForWeb, UserAppLangEnum } from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { DanceManagerService, DanceSchoolStateService } from '../../services';
import { getSubscription } from '../../helpers';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dm-upgrade-required',
  templateUrl: './dm-upgrade-required-dialog.component.html',
  styleUrls: ['./dm-upgrade-required-dialog.component.scss'],
})
export class DmUpgradeRequiredDialogComponent implements OnInit {
  danceManager: DanceSchool;
  stripePublishableKey = environmentForWeb.stripePublishableKey;
  stripePricingTableId: string;

  private subscription = new Subscription();

  constructor(private readonly matDialogRef: MatDialogRef<DmUpgradeRequiredDialogComponent>, private readonly danceSchoolService: DanceSchoolStateService, public readonly danceManagerService: DanceManagerService, private readonly translocoService: TranslocoService) {
    this.stripePricingTableId = this.translocoService.getActiveLang().toUpperCase() === UserAppLangEnum.DE ? environmentForWeb.stripePricingTableIdGerman : environmentForWeb.stripePricingTableIdEnglish;
  }


  ngOnInit(): void {
    this.listenOnDanceManagerChanges();
  }
  
  listenOnDanceManagerChanges(): void {
    this.subscription.add(getSubscription(this.danceSchoolService.getAsyncCurrentDanceSchool(), this.onDanceManagerChange.bind(this)));
  }

  onDanceManagerChange(danceManager: DanceSchool): void {
    this.danceManager = danceManager;
    this.danceManagerService.getLatestSubscriptionByDanceManagerId(danceManager.id);
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }
}
