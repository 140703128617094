import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb } from '@platri/df-common-core';
import { CreateMessageRequestDto, DmMessageCountInterface, GetMessagesWithUserRequestDto, MessageDto, ReactMessageRequestDto, ReadMessageRequestDto } from '@platri/df-common-chat-plug-play';

@Injectable({
  providedIn: 'root',
})
export class ChatHttpService implements OnDestroy {
  baseUrl = `${environmentForWeb.apiChatUrl}/`;

  constructor(
    private httpClient: HttpClient
  ) {
    console.log('Initializing ChatHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying ChatHttpService');
  }

  getAllChatsFromUser(): Observable<MessageDto[]> {
    return this.httpClient.get<MessageDto[]>(this.baseUrl + 'message/user');
  }

  getAllMessagesFromUserWithOtherUser(otherUserId: string, offset: number, take: number, jwt?: string): Observable<MessageDto[]> {
    const getMessagesWithUserRequestDto: GetMessagesWithUserRequestDto = {otherUserId, offset, take};
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.post<MessageDto[]>(this.baseUrl + 'message/other-user/', getMessagesWithUserRequestDto, {headers: headers});
  }

  getAllChatsFromUserForOverview(jwt?: string): Observable<MessageDto[]> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.get<MessageDto[]>(this.baseUrl + 'message/user/overview', { headers: headers});
  }

  getMessageById(id: number, jwt?: string): Observable<MessageDto> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.get<MessageDto>(this.baseUrl + 'message/' + id, { headers: headers});

  }
  
  createMessage(createMessageRequestDto: CreateMessageRequestDto, jwt?: string): Observable<number> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.post<number>(this.baseUrl + 'message', createMessageRequestDto, { headers: headers});
  }

  readMessage(readMessageRequestDto: ReadMessageRequestDto, jwt?: string): Observable<number> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.post<number>(this.baseUrl + 'message/read', readMessageRequestDto, { headers: headers});
  }

  reactMessage(reactMessageRequestDto: ReactMessageRequestDto, jwt?: string): Observable<number> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.post<number>(this.baseUrl + 'message/react', reactMessageRequestDto, { headers: headers});
  }
  
  deleteMessage(messageId: number, jwt?: string): Observable<void> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.delete<void>(this.baseUrl + 'message/' + messageId, { headers: headers});
  }

  deleteAllMessagesToOtherUser(otherUserId: string, jwt?: string): Observable<void> {
    const headers = jwt && jwt.length > 0 ? new HttpHeaders({ 'Authorization': `Bearer ${jwt}` }) : new HttpHeaders();
    return this.httpClient.delete<void>(this.baseUrl + 'message/other-user/' + otherUserId, { headers: headers});
  }

  unreadCountCurrentUser(): Observable<number> {
    return this.httpClient.get<number>(this.baseUrl + 'message/user/count');
  }

  unreadCountCurrentUserDms(): Observable<DmMessageCountInterface[]> {
    return this.httpClient.get<DmMessageCountInterface[]>(this.baseUrl + 'message/user/count/dm');
  }


}
