import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthStateService, CoursesHttpService, DanceManagerHttpService, dfUrlNameRouterParam, SeoService, UserHttpService, UserProfileStateService } from '@platri/dfx-angular-core';
import { CourseDto, DanceSchoolDto, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UserDto, UsersInterface } from '@platri/df-common-core';

enum LevelBarColorEnum {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PRIMARY = 'primary',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SECONDARY = 'secondary',
}

@Component({
  selector: 'df-user-app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnChanges, OnDestroy {
  @Input() userUrlName: string;

  profile: UsersInterface;
  isCurrentUser = false;
  
  levelBarColorEnum = LevelBarColorEnum;

  activeAtDanceManagers: DanceSchoolDto[] = [];
  teachesForCourses: CourseDto[] = [];


  subscription = new Subscription();

  constructor(
    private snackBar: MatSnackBar, 
    private activatedRoute: ActivatedRoute, 
    private userProfileService: UserProfileStateService, 
    private readonly authService: AuthStateService, 
    private readonly seoService: SeoService, 
    private readonly userHttpService: UserHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private readonly courseHttpService: CoursesHttpService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userUrlName) {
      if (this.userUrlName) {
        this.userProfileService.clear();
        this.userHttpService.getUserByUsername(this.userUrlName).subscribe(profile => {
          this.loadData(profile);
          this.loadCurrentUserActiveAt(profile.id);
          this.loadCurrentUserTeachesFor(profile.id);
        });
      }
      else {
        this.activatedRoute.params.subscribe((params) => {
          this.userProfileService.clear();
          this.userHttpService.getUserByUsername(params[dfUrlNameRouterParam]).subscribe(profile => {
            this.loadData(profile);
            this.loadCurrentUserActiveAt(profile.id);
            this.loadCurrentUserTeachesFor(profile.id);
          });
        });
      }
    }
  }
  
  loadData(profile: UserDto): void {
    if (profile) {
      if (this.authService.getSyncCurrentUser()) {
        this.isCurrentUser = profile.id === this.authService.getSyncCurrentUser().id;
      }
      this.userProfileService.sendUserProfileChanges(profile);
      this.loadProfileChanges();
      this.loadCurrentUserChanges();
    }
  }
  
  loadProfileChanges(): void {
    this.subscription.add(
      this.userProfileService.getAsyncUserProfile().subscribe({
        next: (profile: UsersInterface) => this.onProfileChangeSuccessful(profile),
        error: (error) => this.onProfileChangeError(error),
        complete: () => console.log('Observer got a complete notification')
      })
    );
  }

  loadCurrentUserChanges(): void {
    this.subscription.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        this.isCurrentUser = user?.id === this.profile?.id;
      })
    );
  }

  onProfileChangeSuccessful(profile: UsersInterface): void {
    if (profile) {
      this.profile = profile;
      this.isCurrentUser = this.userProfileService.getIsCurrentUser();
      this.setSeoData();
    }
  }

  onProfileChangeError(error: string): void {
    this.snackBar.open('Profil konnte nicht geladen werden', 'X');
  }

  loadCurrentUserActiveAt(profileId: string): void {
    this.subscription.add(
      this.danceManagerHttpService.getDanceManagersByUserIdWhereActiveAt(profileId).subscribe(
        danceManagers => {
          this.activeAtDanceManagers = danceManagers;
        }, error => {
          console.log(error);
        })
    );
  }
  loadCurrentUserTeachesFor(profileId: string): void {
    this.subscription.add(
      this.courseHttpService.getAllWhereTeacherByUserId(profileId).subscribe(
        danceManagers => {
          this.teachesForCourses = danceManagers;
        }, error => {
          console.log(error);
        })
    );
  }

  stripHtmlTags(input: string): string {
    return input.replace(/<[^>]+>/g, '');
  }
  
  setSeoData(): void {
    this.seoService.setMetaTagProperty('og:locale', 'de_DE');
    this.seoService.setMetaTagProperty('og:type', 'website');
    this.seoService.setPageTitle(`${this.profile.username} - Danceflavors`);
    this.seoService.setMetaTagProperty('og:title', `${this.profile.username.toLowerCase()} - Danceflavors`);
    if (this.profile.description) {
      this.seoService.setMetaTagProperty('og:description', this.profile.description.length > 155 ? this.stripHtmlTags(this.profile.description.substring(0, 155) + '...') : this.stripHtmlTags(this.profile.description));
    }
    this.seoService.setMetaTagProperty('og:url', window.location.href);
    this.seoService.setMetaTagProperty('og:site_name', 'Danceflavors');
    if (this.profile.imageUrl) {
      this.seoService.setMetaTagProperty('og:image', this.profile.imageUrl);
      this.seoService.setMetaTagProperty('og:image:width', '1200');
      this.seoService.setMetaTagProperty('og:image:height', '630');
      this.seoService.setMetaTagProperty('og:image:type', 'image/jpg');
    }
    this.seoService.setCanonicalURL();
  }

  ngOnDestroy(): void {
    this.userProfileService.clear();
    this.subscription.unsubscribe();
  }
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
}
