<ng-container *transloco="let t">
  <div class="flex-column gap-10 p2" style="width: 450px;">
    <span class="title">{{t('GENERIC_WRAPPER.TRIAL_LESSON_INVITE')}}</span>
    <div class="description mb-10">
      {{t('GENERIC_WRAPPER.TRIAL_LESSON_INVITE_DESC')}}
    </div>
    
    <!--  todo: email variant-->
    <df-shared-lib-user-search-autocomplete
      (optionSelected)="onUserSelected($event)"
      (inputChange)="onInputChange($event)"
      [showUserSearchLoading]="showUserSearchLoadingSpinner"
      [userOptions]="userOptions">
    </df-shared-lib-user-search-autocomplete>
    
    <mat-form-field appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.APPOINTMENT')}}</mat-label>
      <mat-select [(value)]="selectedCourseAppointment">
        <mat-option *ngFor="let appointment of appointments" [value]="appointment">
          {{ appointment.date | dateTimezoned : 'UTC' : currentLang : appointment.duration : false : false }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    <div *ngIf="selectedUser">
      <div class="flex-row align-start-center first-name-cell">
        <img class="circle-icon mr1" [src]="selectedUser.imageUrl && selectedUser.imageUrl !== '' ? selectedUser.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
        <span class="username-text">{{ selectedUsername }}</span>
        <div style="flex-grow: 1"></div>
        <button (click)="onRemovingSelectedUser()" mat-icon-button style="margin-left: 10px">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    
    <div class="flex-row align-end-center gap-20 mt-10 flex w-100">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        (buttonClicked)="onCancelClick()"
        [isDisabled]="false"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
      <df-shared-lib-button
        (buttonClicked)="onSendClick()"
        [isDisabled]="!selectedUser || !selectedCourseAppointment"
        [buttonLabel]="t('GENERIC_WRAPPER.SEND')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
