import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StripePaymentModule } from '../payment-components';
import { TicketCheckoutStripePaymentComponent } from './ticket-checkout-stripe-payment.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  exports: [TicketCheckoutStripePaymentComponent],
  declarations: [TicketCheckoutStripePaymentComponent],
  imports: [
    CommonModule,
    StripePaymentModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslocoDirective
  ]
})
export class TicketCheckoutStripePaymentModule {}
