import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthStateService } from '@platri/dfx-angular-core';
import {
  DEFAULT_LANGUAGE,
  ENGLISH_LANGUAGE,
  SystemLanguageEnum
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  subscriptions: Subscription = new Subscription();
  languages = Object.values(SystemLanguageEnum);

  constructor(
    private authService: AuthStateService,
    private translocoService: TranslocoService
  ) {
    this.translocoService.setAvailableLangs(this.languages.map((lang) => lang.toLowerCase()));
    this.initializeUserSubscription();
  }

  clear(): void {
    this.subscriptions.unsubscribe();
  }

  initializeUserSubscription(): void {
    this.subscriptions.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        if (user) {
          this.translocoService.setActiveLang(user.appLang.toLowerCase());
        } else {
          this.useBrowserLanguageIfExist();
        }
      })
    );
  }

  useBrowserLanguageIfExist(): void {
    const userLang = navigator.language;
    let languageToSet = DEFAULT_LANGUAGE;
    if (userLang) {
      if (userLang.includes('en')) {
        languageToSet = ENGLISH_LANGUAGE;
      } 
    }
    this.translocoService.setActiveLang(languageToSet.toLowerCase());
  }
}
