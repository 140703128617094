import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DanceManagerHttpService, TicketOrderHttpService, TicketOrderService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { DanceManagerInterface, TicketOrderInterface, TicketOrderStatusEnum, UserAppLangEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-manager-passes-checkout-page',
  templateUrl: './dance-manager-passes-checkout.page.html',
  styleUrls: ['./dance-manager-passes-checkout.page.scss']
})
export class DanceManagerPassesCheckoutPage implements OnInit, OnDestroy {
  currentLang: UserAppLangEnum;
  currentDate: Date;
  endDate: Date;

  danceManager: DanceManagerInterface;
  ticketOrder: TicketOrderInterface | null;

  subscriptions: Subscription = new Subscription();

  constructor(
    private readonly translocoService: TranslocoService, 
    private readonly ticketOrderService: TicketOrderService, 
    private readonly router: Router, 
    private readonly activatedRoute: ActivatedRoute,
    private readonly ticketOrderHttpService: TicketOrderHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnInit(): void {
    this.loadDanceManager(this.activatedRoute.snapshot.params['url-name']);
    this.getTicketOrder();
  }

  loadDanceManager(urlName: string): void {
    if (urlName) {
      this.danceManagerHttpService.getDanceManagerByUrlNameForPublicView(urlName.replace('@', '')).subscribe(danceManager => {
        this.danceManager = danceManager;
      });
    }
  }

  getTicketOrder(): void {
    if (this.ticketOrderService.getSyncTempCreateTicketOrderData()) {
      this.ticketOrderHttpService.create(this.ticketOrderService.getSyncTempCreateTicketOrderData()).subscribe({
        next: (data) => {
          this.ticketOrderService.sendTicketOrderChanges(data);
          this.ticketOrder = data;
          if (data.totalPrice === 0 && data.status === TicketOrderStatusEnum.PAID) {
            this.router.navigate(['@' + this.danceManager.urlName + '/passes/checkout-complete', data.id]);
          }
          
          if (!this.ticketOrder) {
            if (!this.danceManager) {
              this.router.navigate([]);
            }
            else {
              this.navigateToPassesPage();
            }
          }
        }
      });
    }
    else {
      this.ticketOrder = this.ticketOrderService.getSyncTicketOrder();
      if (!this.ticketOrder) {
        if (!this.danceManager) {
          this.router.navigate([]);
        }
        else {
          this.navigateToPassesPage();
        }
      }
    }
  }

  navigateToDanceManagerPage(): void {
    this.router.navigate(['@' + this.danceManager.urlName]);
  }

  navigateToPassesPage(): void {
    this.router.navigate(['@' + this.danceManager.urlName + '/passes']);
  }

  ngOnDestroy(): void {
    this.ticketOrderService.clear();
    this.subscriptions.unsubscribe();
  }
}
