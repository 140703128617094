import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { DanceCourseAppointmentCardModule } from '../dance-course-appointment-card';
import { DanceCourseHeaderModule } from '../dance-course-header';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { NgModule } from '@angular/core';
import { CoursePageContentComponent } from './dance-course-page-content.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { ShareModule } from '../share';
import { PipesModule } from '../../pipes';
import { DfSharedButtonModule } from '../buttons';
import { MatListModule } from '@angular/material/list';
import {MatTooltip} from "@angular/material/tooltip";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CoursePageContentComponent],
  exports: [CoursePageContentComponent],
    imports: [
      CommonModule,
      MatCardModule,
      MatIconModule,
      MatButtonModule,
      ShareModule,
      MatChipsModule,
      MatDividerModule,
      PipesModule,
      GoogleMapsModule,
      MatChipsModule,
      DfSharedButtonModule,
      RouterModule,
      DanceCourseAppointmentCardModule,
      DanceCourseHeaderModule,
      MatChipsModule,
      MatListModule,
      MatTooltip,
      TranslocoDirective,
    ]
})
export class CoursePageContentModule {}
