import { Pipe, PipeTransform } from '@angular/core';
import { DanceSchoolInterface, UnreadDmNotificationInterface, } from '@platri/df-common-core';
import { DmMessageCountInterface } from '@platri/df-common-chat-plug-play';

@Pipe({
  name: 'unreadCountDmPipe'
})
export class UnreadCountDmPipePipe implements PipeTransform {
  transform(danceManager: DanceSchoolInterface, unreadMessagesInterface: DmMessageCountInterface[], unreadDmNotificationInterfaces: UnreadDmNotificationInterface[]): number {
    return this.getUnreadCountMessages(danceManager.id, unreadMessagesInterface) + this.getUnreadCountNotifications(danceManager.id, unreadDmNotificationInterfaces);
  }
  
  getUnreadCountMessages(danceManagerId: string, unreadMessagesInterface: DmMessageCountInterface[]): number {
    return unreadMessagesInterface?.find((obj) => obj.danceManagerId === danceManagerId)?.count ?? 0;
  }
  
  getUnreadCountNotifications(danceManagerId: string, unreadDmNotificationInterfaces: UnreadDmNotificationInterface[]): number {
    return unreadDmNotificationInterfaces?.find((obj) => obj.danceManagerId === danceManagerId)?.count ?? 0;
  }
}
