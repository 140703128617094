import { DanceManagerFaqInterface } from '../../..';
import { DanceSchoolDto } from './dance-school.dto';

export class DanceManagerFaqDto implements DanceManagerFaqInterface{
  id: string;
  question: string;
  answer: string;
  danceSchool: DanceSchoolDto;
  sequenceNumber: number;
}
