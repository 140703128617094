import { Component, OnDestroy, OnInit } from '@angular/core';
import { skipWhile, Subscription } from 'rxjs';
import { UserTicketCollapsedByEventAndOrderInterface, UserTicketCollapsedUpcomingPastFilteredInterface } from '@platri/df-common-core';
import { UserTicketHttpService, UserTicketStateService } from '@platri/dfx-angular-core';
import { Router } from '@angular/router';
import { SearchCategoryEnum } from '@platri/df-angular-search';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-inventory-ticket-list',
  templateUrl: './inventory-ticket-list.component.html',
  styleUrls: ['./inventory-ticket-list.component.scss']
})
export class InventoryTicketListComponent implements OnInit, OnDestroy {
  activeChipIndex = 0;

  upcomingUserTicketsCollapsed: UserTicketCollapsedByEventAndOrderInterface[] = [];
  pastUserTicketsCollapsed: UserTicketCollapsedByEventAndOrderInterface[] = [];
  
  private subscriptions = new Subscription();

  constructor(
    private userTicketService: UserTicketStateService,
    private userTicketHttpService: UserTicketHttpService,
    private translocoService: TranslocoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadUserTicketsOfCurrentUser();
  }

  loadUserTicketsOfCurrentUser(): void {
    this.subscriptions.add(
      this.userTicketService.getAsyncUserTicketsUpcomingPastCollapsed()
        .pipe(skipWhile((val) => val === null))
        .subscribe((data: UserTicketCollapsedUpcomingPastFilteredInterface) => {
          this.upcomingUserTicketsCollapsed = data.upcoming;
          this.pastUserTicketsCollapsed = data.past;
        })
    );

    this.userTicketService.getCurrentUserTickets();
  }
  
  download(data: { danceEventId: string; ticketOrderId: string }): void {
    
  }

  downloadTickets(data: { danceEventId: string; ticketOrderId?: string; freeUserTicketIds?: string[] }): void {
    const ticketWindow = window.open();
    ticketWindow.document.write(`${this.translocoService.translate('USER.TICKETS_LOADING')}...`);

    this.userTicketHttpService
      .downloadTickets(data.danceEventId, data.ticketOrderId, data.freeUserTicketIds)
      .subscribe({
        next: (blob) => {
          const url = URL.createObjectURL(blob);
          ticketWindow.location.href = url;
        },
        error: (err) => {
          console.error(err);
          ticketWindow.close();
        },
      });
  }

  navigateToDanceEventsSearch(): void {
    this.router.navigate(['/', 'search'], {queryParams: {search_type: SearchCategoryEnum.EVENTS}});

  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
}
