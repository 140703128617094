import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { PhonePrefixInterface } from '../../material-phone-prefix-dropdown';

@Component({
  selector: 'df-monorepo-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() showSuffix = true;
  @Input() showQuickLinkAddPhone = false;
  @Input() quickLinkPhonePrefix;
  @Input() quickLinkPhonePrefixAfter;
  @Input() quickLinkPhoneNumber;
  @Input() label = 'GENERIC_WRAPPER.PHONE_INPUT.PHONE_LABEL';
  @Input() placeholder = 'GENERIC_WRAPPER.PHONE_INPUT.PHONE_LABEL';
  @Output() phonePrefixSelected: EventEmitter<PhonePrefixInterface> = new EventEmitter<PhonePrefixInterface>();

  loadPhoneNumberQuickLink(): void {
    this.formGroup.patchValue({
      phonePrefix: this.quickLinkPhonePrefix ?? '+49',
      phonePrefixAfter: this.quickLinkPhonePrefixAfter ?? '',
      phoneNumber: this.quickLinkPhoneNumber ?? '',
    });
  }

  get phoneNumberCtrl(): AbstractControl {
    return this.formGroup.controls.phoneNumber;
  }

  onPhonePrefixSelected(phonePrefix: PhonePrefixInterface): void {
    this.formGroup.patchValue({
      phonePrefix: phonePrefix.dial_code,
      phonePrefixAfter: phonePrefix?.after_dial_code ?? null,
    });
    this.phonePrefixSelected.emit(phonePrefix);
  }
}
