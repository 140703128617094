import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private readonly isDocumentAccessible: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Record<string, unknown>>
  ) {
    this.isDocumentAccessible = isPlatformBrowser(this.platformId);
  }

  /**
   * @param name Cookie name
   * @returns boolean - cookie with specified name exists
   */
  check(name: string): boolean {
    if (!this.isDocumentAccessible) {
      return false;
    }

    name = encodeURIComponent(name);

    const regExp: RegExp = this.getCookieRegExp(name);
    return regExp.test(this.document.cookie);
  }

  /**
   * @param name Cookie name
   * @returns property value
   */
  get(name: string): string {
    if (this.isDocumentAccessible && this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(this.document.cookie);

      return this.safeDecodeURIComponent(result[1]);
    } else {
      return '';
    }
  }

  /**
   * @returns all the cookies in json
   */
  getAll(): { [key: string]: string } {
    if (!this.isDocumentAccessible) {
      return {};
    }

    const cookies: { [key: string]: string } = {};
    const document: Document = this.document;

    if (document.cookie && document.cookie !== '') {
      document.cookie.split(';').forEach((currentCookie) => {
        const [cookieName, cookieValue] = currentCookie.split('=');
        cookies[
          this.safeDecodeURIComponent(cookieName.replace(/^ /, ''))
        ] = this.safeDecodeURIComponent(cookieValue);
      });
    }

    return cookies;
  }

  /**
   * @param name Cookie name
   * @param value Cookie value
   * @param expires Number of days until the cookies expires or an actual `Date`
   * @param path Cookie path
   * @param domain Cookie domain
   * @param secure Secure flag
   * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   */
  set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void;

  /**
   * Cookie's parameters:
   * <pre>
   * expires expires Number of days until the cookies expires or an actual `Date`
   * path path Cookie path
   * domain domain Cookie domain
   * secure secure Secure flag
   * sameSite sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   * </pre>
   * @param name Cookie name
   * @param value Cookie value
   * @param options Body with cookie's params
   */
  set(
    name: string,
    value: string,
    options?: {
      expires?: number | Date;
      path?: string;
      domain?: string;
      secure?: boolean;
      sameSite?: 'Lax' | 'None' | 'Strict';
    }
  ): void;

  set(
    name: string,
    value: string,
    expiresOrOptions?: number | Date | any,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    if (!this.isDocumentAccessible) {
      return;
    }

    if (
      typeof expiresOrOptions === 'number' ||
      expiresOrOptions instanceof Date ||
      path ||
      domain ||
      secure ||
      sameSite
    ) {
      const optionsBody = {
        expires: expiresOrOptions,
        path,
        domain,
        secure,
        sameSite: sameSite ?? 'Lax',
      };

      this.set(name, value, optionsBody);
      return;
    }

    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
      value
    )};`;

    const options = expiresOrOptions ?? {};

    if (options.expires) {
      if (typeof options.expires === 'number') {
        const dateExpires: Date = new Date(
          new Date().getTime() + options.expires * 1000 * 60 * 60 * 24
        );
        cookieString += `expires=${dateExpires.toUTCString()};`;
      } else {
        cookieString += `expires=${options.expires.toUTCString()};`;
      }
    }

    if (options.path) {
      cookieString += `path=${options.path};`;
    }

    if (options.domain) {
      cookieString += `domain=${options.domain};`;
    }

    if (options.secure === false && options.sameSite === 'None') {
      options.secure = true;
      console.warn(
        `Cookie ${name} was forced with secure flag because sameSite=None.`
      );
    }

    if (options.secure) {
      cookieString += 'secure;';
    }

    if (!options.sameSite) {
      options.sameSite = 'Lax';
    }

    cookieString += `sameSite=${options.sameSite};`;

    this.document.cookie = cookieString;
  }

  /**
   * @param name   Cookie name
   * @param path   Cookie path
   * @param domain Cookie domain
   * @param secure Secure flag
   * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   */
  delete(
    name: string,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite: 'Lax' | 'None' | 'Strict' = 'Lax'
  ): void {
    if (!this.isDocumentAccessible) {
      return;
    }
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
    this.set(name, '', {
      expires: expiresDate,
      path,
      domain,
      secure,
      sameSite,
    });
  }

  /**
   * @param path   Cookie path
   * @param domain Cookie domain
   * @param secure Secure flag
   * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
   */
  deleteAll(
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite: 'Lax' | 'None' | 'Strict' = 'Lax'
  ): void {
    if (!this.isDocumentAccessible) {
      return;
    }

    const cookies: any = this.getAll();

    for (const cookieName in cookies) {
      if (cookies.hasOwnProperty(cookieName)) {
        this.delete(cookieName, path, domain, secure, sameSite);
      }
    }
  }

  /**
   * @param name Cookie name
   * @returns property RegExp
   */
  private getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(
      /([[\]{}()|=;+?,.*^$])/gi,
      '\\$1'
    );
    return new RegExp(
      '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)',
      'g'
    );
  }

  private safeDecodeURIComponent(encodedURIComponent: string): string {
    try {
      return decodeURIComponent(encodedURIComponent);
    } catch {
      return encodedURIComponent;
    }
  }
}
