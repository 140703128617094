import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddImageDialogComponent } from '../add-image-dialog';
import { LogoAndTitleUploadDialogTypeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit {
  @Input() ratio = { width: 2, height: 1 };
  @Input() showEmptyPreview = true;
  @Input() isOpenAddImage = false;
  @Input() croppedImageBaseAs64: string;
  @Input() keepExistingImage = false;
  @Input() mode: LogoAndTitleUploadDialogTypeEnum;

  @Output() selectedUploadImage: EventEmitter<{
    formData: FormData;
    imageAsBase64: string;
  }> = new EventEmitter<{
    formData: FormData;
    imageAsBase64: string;
  }>();
  @Output() imageDeleted: EventEmitter<void> = new EventEmitter<void>();

  // toolTipLabel: string;
  
  formData: FormData;
  existingImage: string;
  isHovering: boolean;

  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.croppedImageBaseAs64 && this.keepExistingImage) {
      this.existingImage = this.croppedImageBaseAs64;
    }
    // this.toolTipLabel = 'ADD_EDIT_EVENT_INFO.HTML.' + (this.croppedImageBaseAs64 || this.existingImage ? 'CHANGE_PICTURE' : 'ADD_PICTURE');
  }

  openAddImageDialog(): void {
    this.dialog
      .open(AddImageDialogComponent, {
        panelClass: 'br-20',
        width: '100%',
        maxWidth: '1140px',
        data: { ratio: this.ratio,
                mode:this.mode },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data?.imageCropped) {
          this.isOpenAddImage = true;
          this.croppedImageBaseAs64 = data?.imageCropped;
        }

        if (data?.formData) {
          this.formData = data?.formData;
          this.selectedUploadImage.emit({
            formData: this.formData,
            imageAsBase64: this.croppedImageBaseAs64,
          });
        }
      });
  }

  cancelPicture($event: MouseEvent): void {
    event.stopPropagation();
    this.isOpenAddImage = false;
    this.croppedImageBaseAs64 = null;
    this.imageDeleted.emit();
  }
}
