import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentsInterface } from '@platri/df-common-core';

@Pipe({
  name: 'googleAddressReadable',
})
export class GoogleAddressReadablePipe implements PipeTransform {
  transform(value: AppointmentsInterface, noLocationName: boolean = false): string {
    if (value === null) {
      return null;
    }
    const addressSplit = value.address
      ? value.address.formattedAddress.split(',')
      : value.location.address.formattedAddress.split(',');
    if (value.location?.name && !noLocationName) {
      return `${value.location?.name}\n${addressSplit[0]}, ${addressSplit[1]}`;
    } else {
      return `${
        addressSplit[0] === value.address.name
          ? addressSplit[0]
          : value.address.name + '\n' + addressSplit[0]
      }, ${addressSplit[1]}`;
    }
  }
}
