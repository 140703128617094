import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { PaypalCheckoutComponent } from './paypal-checkout.component';

@NgModule({
  declarations: [PaypalCheckoutComponent],
  exports: [PaypalCheckoutComponent],
  imports: [CommonModule],
})
export class PaypalCheckoutModule {
}
