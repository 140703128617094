import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function rangeValidator(startControlName: string, endControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const startControl = formGroup.get(startControlName);
    const endControl = formGroup.get(endControlName);

    if (!startControl || !endControl) {
      return null;
    }

    const isInRange = startControl.value < endControl.value;

    if (!isInRange) {
      return { 'range': true };
    }

    return null;
  };
}

