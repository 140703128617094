import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilterFormInterface } from '../../interfaces';
import { FilterDialogDataInterface } from '../../interfaces/filter-dialog-data.interface';
import {
  DANCE_STYLES,
  DanceEventCategoryEnum,
  DanceManagerCategoryEnum,
  MUSIC_STYLES
} from '@platri/df-common-core';
import { SearchCategoryEnum } from '../../enums';
import { FilterSortTypeEnum } from '@platri/dfx-angular-core';

@Component({
  selector: 'df-search-filter-dialog',
  templateUrl: './search-filter-dialog.component.html',
  styleUrls: ['./search-filter-dialog.component.scss'],
})
export class SearchFilterDialogComponent implements OnInit, OnDestroy {
  tempFilterFormGroup: FormGroup<FilterFormInterface>;

  danceManagerCategoriesEnumValues: DanceManagerCategoryEnum[];
  danceEventCategoriesEnumValues: DanceEventCategoryEnum[];
  
  filterButtonTranslateLabel: string;

  private subscriptions: Subscription = new Subscription();
  private eventMusicStylesFormArray: FormArray;
  private eventDanceStylesFormArray: FormArray;
  private courseDanceStylesFormArray: FormArray;
  private studioDanceStylesFormArray: FormArray;
  protected readonly SearchCategoryEnum = SearchCategoryEnum;

  showAllDanceStyles = false;
  showAllMusicStyles = false;
  
  DANCE_STYLES = DANCE_STYLES;
  FILTERED_DANCE_STYLES = [];
  NON_FILTERED_DANCE_STYLES = [];
  
  MUSIC_STYLES = MUSIC_STYLES;
  FILTERED_MUSIC_STYLES = [];
  NON_FILTERED_MUSIC_STYLES = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public filterDialogDataInterface: FilterDialogDataInterface,
    private dialogRef: MatDialogRef<SearchFilterDialogComponent>,
    private fb: FormBuilder
  ) {}
  
  ngOnInit(): void {
    this.setFilterButtonTranslateLabel();
    this.filterPopularDanceStyles();
    this.filterPopularMusicStyles();
    
    this.tempFilterFormGroup = this.filterDialogDataInterface.filterFormGroup;
    this.danceManagerCategoriesEnumValues = Object.values(DanceManagerCategoryEnum);
    this.setDefaultEventCategories();

    this.eventMusicStylesFormArray = this.filterDialogDataInterface
      .filterFormGroup.controls.eventsFilter.controls.eventMusicStyles;
    this.eventDanceStylesFormArray = this.filterDialogDataInterface
      .filterFormGroup.controls.eventsFilter.controls.eventDanceStyles;  
    this.courseDanceStylesFormArray = this.filterDialogDataInterface
      .filterFormGroup.controls.coursesFilter.controls.courseDanceStyles;
    this.studioDanceStylesFormArray =  this.filterDialogDataInterface
      .filterFormGroup.controls.studiosFilter.controls.studioDanceStyles;
  }
  
  setFilterButtonTranslateLabel(): void {
    if (this.isCategoryEvents) {
      this.filterButtonTranslateLabel = 'SEARCH.SHOW_EVENTS';
    }
    if (this.isCategoryFestivals) {
      this.filterButtonTranslateLabel = 'SEARCH.SHOW_FESTIVALS';
    }
    if (this.isCategoryCourses) {
      this.filterButtonTranslateLabel = 'SEARCH.SHOW_COURSES';
    }
    if (this.isCategoryStudios) {
      this.filterButtonTranslateLabel = 'SEARCH.SHOW_DANCE_MANAGERS';
    }
  }
  
  filterPopularDanceStyles(): void {
    this.FILTERED_DANCE_STYLES = DANCE_STYLES.filter((danceStyle) => ['bachata', 'salsa', 'kizomba', 'zouk'].includes(danceStyle.translationKey));  
    this.NON_FILTERED_DANCE_STYLES = DANCE_STYLES.filter((danceStyle) => !['bachata', 'salsa', 'kizomba', 'zouk'].includes(danceStyle.translationKey));  
  }

  filterPopularMusicStyles(): void {
    this.FILTERED_MUSIC_STYLES = MUSIC_STYLES.filter((danceStyle) => ['bachata', 'hip-hip', 'discofox', 'cha-cha-cha'].includes(danceStyle.translationKey));
    this.NON_FILTERED_MUSIC_STYLES = MUSIC_STYLES.filter((danceStyle) => !['bachata', 'hip-hip', 'discofox', 'cha-cha-cha'].includes(danceStyle.translationKey));
  }

  toggleDanceStyle(danceStyle: string): void {
    if (this.isCategoryEvents || this.isCategoryFestivals) {
      const index = this.eventDanceStylesFormArray.value.indexOf(danceStyle);

      if (index === -1) {
        this.eventDanceStylesFormArray.push(this.fb.control(danceStyle));
      } else {
        this.eventDanceStylesFormArray.removeAt(index);
      }
    } else if (this.isCategoryCourses) {
      const index = this.courseDanceStylesFormArray.value.indexOf(danceStyle);

      if (index === -1) {
        this.courseDanceStylesFormArray.push(this.fb.control(danceStyle));
      } else {
        this.courseDanceStylesFormArray.removeAt(index);
      }
    } else if (this.isCategoryStudios) {
      const index = this.studioDanceStylesFormArray.value.indexOf(danceStyle);

      if (index === -1) {
        this.studioDanceStylesFormArray.push(this.fb.control(danceStyle));
      } else {
        this.studioDanceStylesFormArray.removeAt(index);
      }
    }
  }

  isDanceStyleSelected(danceStyle: string): boolean {
    if (this.isCategoryEvents || this.isCategoryFestivals) {
      return this.eventDanceStylesFormArray.value.includes(danceStyle);
    } else if (this.isCategoryCourses) {
      return this.courseDanceStylesFormArray.value.includes(danceStyle);
    } else if (this.isCategoryStudios) {
      return this.studioDanceStylesFormArray.value.includes(danceStyle);
    } else {
      return false;
    }
  }

  toggleEventMusicStyle(musicStyle: string): void {
    const index = this.eventMusicStylesFormArray.value.indexOf(musicStyle);

    if (index === -1) {
      this.eventMusicStylesFormArray.push(this.fb.control(musicStyle));
    } else {
      this.eventMusicStylesFormArray.removeAt(index);
    }
  }

  isEventMusicStyleSelected(musicStyle: string): boolean {
    return this.eventMusicStylesFormArray.value.includes(musicStyle);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  removeSelectedDanceStyle(index: number): void {
    if (this.isCategoryEvents || this.isCategoryFestivals) {
      this.eventDanceStylesFormArray.removeAt(index);
    }
    else if (this.isCategoryCourses) {
      this.courseDanceStylesFormArray.removeAt(index);
    }
    else if (this.isCategoryStudios) {
      this.studioDanceStylesFormArray.removeAt(index);
    }
  }

  toggleDanceEventCategory(category: DanceEventCategoryEnum): void {
    const categories: FormArray = this.filterDialogDataInterface.filterFormGroup
      .controls.eventsFilter.controls.eventCategories;
    const index = categories.controls.findIndex(
      (control) => control.value === category
    );

    if (index === -1) {
      categories.push(new FormControl(category));
    } else {
      categories.removeAt(index);
    }
  }
  
  isDanceEventCategorySelected(category: DanceEventCategoryEnum): boolean {
    return this.filterDialogDataInterface.filterFormGroup.controls.eventsFilter
      .controls.eventCategories.value.includes(category);
  }

  toggleDanceManagerCategory(category: DanceManagerCategoryEnum): void {
    const categories = this.filterDialogDataInterface.filterFormGroup.controls
      .studiosFilter.controls.studioCategories as FormArray;
    const index = categories.controls.findIndex(
      (control) => control.value === category
    );

    if (index === -1) {
      categories.push(new FormControl(category));
    } else {
      categories.removeAt(index);
    }
  }
  
  isDanceManagerCategorySelected(category: DanceManagerCategoryEnum): boolean {
    return this.filterDialogDataInterface.filterFormGroup.controls.studiosFilter
      .controls.studioCategories.value.includes(category);
  }

  onCourseLevelSelected(event: { fromLevel: number; toLevel: number }): void {
    if (event.fromLevel !== event.toLevel) {
      this.filterDialogDataInterface.filterFormGroup
        .controls.coursesFilter.controls.courseStartDifficulty
        .patchValue(event.fromLevel);
      this.filterDialogDataInterface.filterFormGroup
        .controls.coursesFilter.controls.courseEndDifficulty
        .patchValue(event.toLevel);
    }
  }
  
  closeDialogWithoutSave(): void {
    this.filterDialogDataInterface.filterFormGroup = this.tempFilterFormGroup;
    this.dialogRef.close({changed: false, reset: false});
  }
  
  closeDialogWithSave(): void {
    this.dialogRef.close({changed: true, reset: false});
  }

  resetFilterForm(): void {
    if(this.isCategoryEvents) {
      const eventFilter = this.filterDialogDataInterface
        .filterFormGroup.controls.eventsFilter;
      eventFilter.controls.eventCategories.clear();
      eventFilter.controls.eventDanceStyles.clear();
      eventFilter.controls.eventMusicStyles.clear();
      eventFilter.controls.eventName.reset();
      eventFilter.controls.eventSortType.setValue(FilterSortTypeEnum.START_DATE);
    } else if(this.isCategoryCourses) {
      const courseFilter = this.filterDialogDataInterface
        .filterFormGroup.controls.coursesFilter;
      courseFilter.controls.courseDanceStyles.clear();
      courseFilter.controls.courseEndDifficulty.reset();
      courseFilter.controls.courseName.reset();
      courseFilter.controls.courseStartDifficulty.reset();
      courseFilter.controls.courseSortType.setValue(FilterSortTypeEnum.START_DATE);
    } else if(this.isCategoryStudios) {
      const studioFilter = this.filterDialogDataInterface
        .filterFormGroup.controls.studiosFilter;
      studioFilter.controls.studioCategories.clear();
      studioFilter.controls.studioDanceStyles.clear();
      studioFilter.controls.studioName.reset();
    } else if(this.isCategoryFestivals) {
      this.eventDanceStylesFormArray.clear();
    }

    this.filterDialogDataInterface.filterFormGroup.controls.eventsFilter
      .controls.eventSortType.setValue(FilterSortTypeEnum.START_DATE);
  }

  private setDefaultEventCategories():void {
    this.danceEventCategoriesEnumValues = Object.values(DanceEventCategoryEnum).filter(
      (category) => category !== DanceEventCategoryEnum.FESTIVAL
    );
  }
  
  private get isCategoryFestivals(): boolean {
    return this.filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.FESTIVALS;
  }
  
  private get isCategoryEvents(): boolean {
    return this.filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS;
  }
  
  private get isCategoryStudios():boolean {
    return this.filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.STUDIOS;
  }
  
  private get isCategoryCourses(): boolean {
    return this.filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.COURSES;
  }
}
