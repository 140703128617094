import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassSubscriptionGuestListTableComponent } from './pass-subscription-guest-list-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipesModule } from '../../pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfSharedButtonModule } from '../buttons';
import {MatChip} from "@angular/material/chips";
import { FreePassInviteDialogModule } from '../../dialogs';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [PassSubscriptionGuestListTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    PipesModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    DfSharedButtonModule,
    MatChip,
    FreePassInviteDialogModule,
    TranslocoDirective
  ],
  exports: [PassSubscriptionGuestListTableComponent],
})
export class PassSubscriptionGuestListTableModule {}
