import { FeesPaidByEnum, TicketInterface } from '../../..';

export class CreateTicketRequestDto implements TicketInterface {
  name: string;
  description: string;
  quantity: number;
  price: number;
  taxPercentage: number;
  feesPaidBy: FeesPaidByEnum;
  saleStart?: Date;
  saleEnd?: Date;
  danceEventId: string;
  sellAsFollowerLeader = false;
  sellAsFollowerActive = false;
  sellAsLeaderActive = false;
}
