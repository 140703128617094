import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmNotificationPageComponent } from './dfm-notification-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule, Routes } from '@angular/router';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule, DmNotificationItemModule, PipesModule, SharedEmptyPageModule } from '@platri/dfx-angular-core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DfmNotificationPageComponent,
  },
];

@NgModule({
  declarations: [DfmNotificationPageComponent],
    imports: [
      CommonModule,
      MatDividerModule,
      RouterModule.forChild(routes),
      BreadcrumbComponent,
      BreadcrumbItemDirective,
      MatIconModule,
      MatCardModule,
      DfSharedButtonModule,
      PipesModule,
      MatTabsModule,
      MatMenuModule,
      MatProgressSpinnerModule,
      SharedEmptyPageModule,
      DmNotificationItemModule,
      MatButtonModule,
      MatTooltipModule,
      TranslocoDirective,
    ],
})
export class DfmNotificationPageModule {}
