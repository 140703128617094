import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  DfSharedButtonModule,
  TranslocoStateConst
} from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { AppointmentsPage } from './appointments.page';
import {
  AppointmentsTableModule,
  InventoryPassListModule,
  InventorySubscriptionListModule,
  InventoryTicketListModule
} from '../../components';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';
import {MatProgressSpinner} from "@angular/material/progress-spinner";

const routes = [
  {
    path: '',
    component: AppointmentsPage,
  }
];

@NgModule({
  declarations: [AppointmentsPage],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    DfSharedButtonModule,
    MatChipsModule,
    InventoryTicketListModule,
    InventoryPassListModule,
    InventorySubscriptionListModule,
    TranslocoDirective,
    TranslocoModule,
    AppointmentsTableModule,
    MatProgressSpinner
  ]
})
export class AppointmentsPageModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-user', alias: 'USER' });
  }
}
