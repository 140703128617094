<div
  class="mt-10 mb-10 unset-line-height"
  [ngClass]="{ unread: !notification.isRead }"
>
  <button
    mat-menu-item
    class="flex-row align-start-center"
    (click)="open()"
  >
    <mat-icon color="primary">
      {{ notification.icon }}
    </mat-icon>
    <div class="notification-text flex-column align-start">
      <p [innerHtml]="notification.body"></p>
      {{ notification.createdAt | timeAgo }}
    </div>
  </button>
</div>
