<ng-container *transloco="let t">
  <div style="position: relative;">
    <div *ngIf="danceManager" class="flex-row-center-center">
      <div class="flex-row preview-card w-100 preview clickable opacityAnimation">
        <a [target]="'_self'" [routerLink]="['/inventory', 'passes', userPassCollapsed.id]" style="flex: 1;">
          <div class="container-flex-row-responsive-col w-100 h-100">
            <div class="flex-column-center-center" id="left-column">
              <div class="flex-column preview-image-container"> 
                <img [src]="danceManager.titleImage?.url ?? 'assets/images/studio_header.png' " alt="" class="preview-image w-100 mb0 block" />
              </div>
            </div>
  
            <div class="flex-column align-start" id="right-column">
              <div class="flex-direction-swap-row-to-column" style="width: 100%; height: 100%;">
                <div class="flex-column preview-header left-container">
                  <span *ngIf="userPassCollapsed" class="titleText name left-align ellipsis-1">
                    {{ userPassCollapsed.name }}
                    {{ userPassCollapsed.status === userPassStatusEnum.FREE && !userPassCollapsed.trial ? ' (Free)' : ''}}
                  </span>
                  <a [href]="'/app/@' + danceManager.urlName" style="margin-top: 10px;">
                    <span class="subText name left-align ellipsis-1" (click)="$event.stopPropagation();">
                      {{ danceManager.name }}
                    </span>
                  </a>
                  <div class="flex-row" [ngClass]="userPassCollapsed.validUntil ? 'ellipsis-2': 'ellipsis-3'" style="width: 100%; margin-top: 10px; word-break: normal !important;">
                    <a [href]="'/app/dance-class/' + course.id" *ngFor="let course of courses; let isLast = last">
                      <span class="subText name" (click)="$event.stopPropagation();">
                        {{ course.name }}<span *ngIf="!isLast">, </span>
                      </span>
                    </a>
                  </div>
                  <span *ngIf="userPassCollapsed.validUntil" class="name left-align ellipsis-1" style="margin-top: 10px;">
                      {{ t('GENERIC_WRAPPER.VALID_UNTIL') }}: {{ userPassCollapsed.validUntil | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}
                  </span>
                </div>
                  
                <mat-divider *ngIf="userPassCollapsed.usageCount !== null" class="hide-on-mobile" [vertical]="true" style="margin-left: 20px; margin-right: 20px;"></mat-divider>
                <mat-divider *ngIf="userPassCollapsed.usageCount !== null" class="hide-on-web" style="margin-top: 10px; margin-bottom: 10px;"></mat-divider>
                
                <div *ngIf="userPassCollapsed.usageCount !== null" class="flex-column right-container">
                  <span *ngIf="userPassCollapsed" class="titleText name left-align ellipsis-1">
                    {{ t('GENERIC_WRAPPER.STAMPS') }}
                  </span>
                  <div *ngIf="userPassCollapsed.usageCount" class="subText name ellipsis-1">
                    <mat-grid-list cols="5" rowHeight="40px" gutterSize="10px" style="margin-top: 10px; margin-bottom: 10px;">
                      <mat-grid-tile *ngFor="let stamp of shownStamps" class="stamp-container">
                        <div [class]="stamp <= userPassCollapsed?.userPassScans?.length ? 'align-center stamp used' : 'align-center stamp unused'">
                          <mat-icon *ngIf="stamp <= userPassCollapsed?.userPassScans?.length" style="color: #00A1DF">check</mat-icon>
                          <span *ngIf="stamp > userPassCollapsed?.userPassScans?.length">{{ stamp }}</span>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-container>
