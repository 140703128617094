<ng-container *transloco="let t">
  <mat-form-field appearance="outline" class="limitWidthOnMobile">
    <mat-label>{{ t(labelTranslateKey) }}</mat-label>
    <mat-select
      disableOptionCentering
      panelClass="mt3"
      [formControl]="phonePrefixCtrl"
      (selectionChange)="onSelectPhonePrefix($event)"
      #singleSelect
    >
      <mat-option
        *ngFor="let phonePrefix of filteredPhonePrefixes | async"
        [value]="phonePrefix"
      >
        {{ phonePrefix.name }} <span>{{ phonePrefix.dial_code }}</span
        >&nbsp;<span *ngIf="phonePrefix.after_dial_code"
          >({{ phonePrefix.after_dial_code }})</span
        >
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
