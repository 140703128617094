import { DfmCoursePassesTableInterface, PassStatusEnum } from '../../..';
import { PassValidityDto } from './pass-validity.dto';

export class DfmCoursePassesTableDto implements DfmCoursePassesTableInterface {
  id: string;
  name: string;
  description: string;
  accessTargetNames: string[];
  numberOfUses: number;
  quantity: number;
  soldQuantity: number;
  price: number;
  usageCount: number;
  targetIds: string[];
  status: PassStatusEnum;
  validity: PassValidityDto;
}
