import { Component, Input } from '@angular/core';

enum LevelBarColorEnum {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PRIMARY = 'primary',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SECONDARY = 'secondary',
}

// TODO: startPoint currently not used, needs to be added to the html/css
// TODO (NICE TO HAVE): would be cool, if you could put start and end color of gradient as input

@Component({
  selector: 'df-shared-lib-level-bar',
  templateUrl: './level-bar.component.html',
  styleUrls: ['./level-bar.component.scss'],
})
export class LevelBarComponent {
  @Input() label: string;
  @Input() startPoint = 0;
  @Input() endPoint = 100;
  @Input() color: LevelBarColorEnum = LevelBarColorEnum.PRIMARY;
}
