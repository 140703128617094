import { Component, OnDestroy, OnInit } from '@angular/core';
import { DanceSchoolStateService, NotificationDanceManagerStateService, NotificationHttpService, NotificationsPageUrlFragmentEnum } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationInterface } from '@platri/df-common-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dfm-notifications',
  templateUrl: './dfm-notification-page.component.html',
  styleUrls: ['./dfm-notification-page.component.scss'],
})
export class DfmNotificationPageComponent implements OnInit, OnDestroy {
  activeTabIndex = 0;
  currentDanceSchoolId: string;

  isLoading = false;
  hasFailedLoadingNoConnection = false;
  
  private subscriptions = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly notificationHttpService: NotificationHttpService,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly router: Router,
    public readonly notificationDanceManagerStateService: NotificationDanceManagerStateService,
  ) {}

  ngOnInit(): void {
    this.getUrlFragmentForTabIndex();
    this.loadNotifications();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadNotifications(): void {
    this.currentDanceSchoolId = this.danceSchoolService.getSyncCurrentDanceSchool().id;    
    this.notificationDanceManagerStateService.clear();
    this.notificationDanceManagerStateService.loadAllNotificationsByDanceManagerId(this.currentDanceSchoolId);
  }

  markNotificationRead(notification: NotificationInterface): void {
    this.notificationHttpService.markAsReadById(notification.id).subscribe({
      next: () => {
        this.loadNotifications();
        this.notificationDanceManagerStateService.loadUnreadNotificationsCountsByDanceManagerIds(this.danceSchoolService.getSyncCurrentDanceSchools()?.map((obj) => obj.id));
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  markNotificationUnread(notification: NotificationInterface): void {
    this.notificationHttpService.markAsNotReadById(notification.id).subscribe({
      next: () => {
        this.loadNotifications();
        this.notificationDanceManagerStateService.loadUnreadNotificationsCountsByDanceManagerIds(this.danceSchoolService.getSyncCurrentDanceSchools()?.map((obj) => obj.id));
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  markAllNotificationsRead(): void {
    this.notificationHttpService.markAllReadByTargetId(this.currentDanceSchoolId)
      .subscribe({
        next: () => {
          this.loadNotifications();
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  getUrlFragmentForTabIndex(): void {
    this.isLoading = true;
    this.subscriptions.add(
      this.route.fragment.subscribe((hashtag) => {
        if (hashtag != null) {
          hashtag = hashtag.toLowerCase();
          switch (hashtag) {
            case NotificationsPageUrlFragmentEnum.NEW: {
              this.activeTabIndex = 0;
              break;
            }
            case NotificationsPageUrlFragmentEnum.CLEARED: {
              this.activeTabIndex = 1;
              break;
            }
            default: {
              this.activeTabIndex = 0;
              break;
            }
          }
        }
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
        this.hasFailedLoadingNoConnection = true;
      })
    );
  }

  changeRouteToFragment(index: number): void {
    switch (index) {
      case 0: {
        window.location.hash = '#' + NotificationsPageUrlFragmentEnum.NEW;
        break;
      }
      case 1: {
        window.location.hash = '#' + NotificationsPageUrlFragmentEnum.CLEARED;
        break;
      }
    }
  }
}
