import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AuthStateService } from '../../services';
import { getContactUrl } from '@platri/df-common-core';
import { Subscription } from 'rxjs';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[contactUrl]',})
export class ContactUrlDirective implements OnInit, OnDestroy {

  private subscription = new Subscription();
  
  constructor(private el: ElementRef, private renderer: Renderer2, private authService: AuthStateService) { }

  ngOnInit(): void {
    this.listenOnUserChanges();
  }
  
  setHref(url: string): void {
    this.renderer.setAttribute(this.el.nativeElement, 'href', url);
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  listenOnUserChanges(): void {
    this.subscription.add(this.authService.getAsyncCurrentUser()
      .subscribe((user) => {
        this.setHref(getContactUrl(user?.appLang));
      }));
  }
  
}
