<ng-container *transloco="let t">
  <div class="flex-column align-center pl3 pr3">
    <div class="notifications-page-container w-100">
      <div class="notifications-page-breadcrumb">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="notifications-page-card-container">
        <mat-card style="background-color: white" class="notifications-page-card round-corners w-100 no-shadow-mat-card">
          <span class="notifications-page-title">{{ t('DANCE_MANAGER_NOTIFICATION.NOTIFICATIONS') }}</span>
          <mat-tab-group class="pt2" [@.disabled]="true" [selectedIndex]="activeTabIndex" (selectedIndexChange)="changeRouteToFragment($event)">
            <mat-tab label="{{ t('DANCE_MANAGER_NOTIFICATION.TAB_NEW') }}">
              <ng-template matTabContent>
                <div style="overflow: hidden;" class="mt2">
                  <div class="flex-row align-space-between">
                    <div class="flex-row pb2">
                      <span class="notification-card-title">
                        {{ t('DANCE_MANAGER_NOTIFICATION.NEW_NOTIFICATIONS') }} ({{(notificationDanceManagerStateService.getAsyncAvailableUnreadNotifications() | async)?.length}})
                      </span>
                    </div>
                    <div class="flex-row-end-center mb2 gap-15">
                      <div class="flex-row align-center mark-all-as-read" (click)="markAllNotificationsRead()" id="clear-all-button">
                        <span>{{ t('DANCE_MANAGER_NOTIFICATION.CLEAR_ALL_NOTIFICATIONS') }}</span>
                      </div>
                      <button mat-icon-button (click)="loadNotifications()" matTooltip="{{ t('GENERIC_WRAPPER.RELOAD') }}" class="mark-all-as-read">
                        <mat-icon>refresh</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="(notificationDanceManagerStateService.getAsyncIsLoadingNotifications() | async) === false && !isLoading; else spinner">
                    <div *ngIf="(notificationDanceManagerStateService.getAsyncAvailableUnreadNotifications() | async)?.length > 0; else noNotifications">
                      <div class="flex-column" *ngFor="let notification of notificationDanceManagerStateService.getAsyncAvailableUnreadNotifications() | async">
                        <df-shared-lib-dm-notification-item 
                          [notification]="notification" 
                          (notificationReadButtonClicked)="markNotificationRead(notification)"
                        ></df-shared-lib-dm-notification-item>
                      </div>
                    </div>
                  </div>
                  <ng-template #noNotifications>
                    <div class="gap-15 flex-column align-center w-100 my4 py4">
                      <df-shared-empty-page
                        [emptyTitle]="t('DANCE_MANAGER_NOTIFICATION.NO_NOTIFICATIONS_TITLE')"
                        [emptyDescription]="t('DANCE_MANAGER_NOTIFICATION.NO_NOTIFICATIONS_DESCRIPTION')"
                        [imagePath]="'assets/images/dance_manager/notifications/no_notifications.svg'"
                        [hideBtn]="true"
                      ></df-shared-empty-page>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </mat-tab>
            <mat-tab label="{{ t('DANCE_MANAGER_NOTIFICATION.TAB_CLEARED') }}">
              <ng-template matTabContent>
                <div style="overflow: hidden" class="mt2">
                  <div *ngIf="(notificationDanceManagerStateService.getAsyncIsLoadingNotifications() | async) === false && !isLoading; else spinner">
                    <div class="flex-row pb2">
                      <span class="notification-card-title">
                        {{ t('DANCE_MANAGER_NOTIFICATION.TAB_CLEARED') }} ({{(notificationDanceManagerStateService.getAsyncAvailableReadNotifications() | async)?.length}})
                      </span>
                    </div>
                    <div *ngIf="(notificationDanceManagerStateService.getAsyncAvailableReadNotifications() | async)?.length > 0; else noClearedNotifications">
                      <div class="flex-column w-100" *ngFor="let notification of notificationDanceManagerStateService.getAsyncAvailableReadNotifications() | async">
                        <df-shared-lib-dm-notification-item 
                          [notification]="notification" 
                          (notificationReadButtonClicked)="markNotificationUnread(notification)"
                        ></df-shared-lib-dm-notification-item>
                      </div>
                    </div>
                  </div>
                  <ng-template #noClearedNotifications>
                    <div class="gap-15 flex-column align-center w-100 my4 py4">
                      <df-shared-empty-page
                        [emptyTitle]="t('DANCE_MANAGER_NOTIFICATION.NO_CLEARED_NOTIFICATIONS_TITLE')"
                        [emptyDescription]="t('DANCE_MANAGER_NOTIFICATION.NO_CLEARED_NOTIFICATIONS_DESCRIPTION')"
                        [imagePath]="'assets/images/dance_manager/notifications/no_notifications.svg'"
                        [hideBtn]="true"
                      ></df-shared-empty-page>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </mat-card>
      </div>
    </div>
  </div>
  
  <ng-template #spinner>
    <mat-spinner style="margin: auto" diameter="80"></mat-spinner>
  </ng-template>
</ng-container>
