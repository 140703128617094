<ng-container *transloco="let t">
  <div *ngIf="invitedCoHosts.length > 0">
    <mat-card class="notification" *ngFor="let coHost of invitedCoHosts">
      <div class="notification-content">
        <img [src]="danceEvent.danceSchool.imageUrl && danceEvent.danceSchool.imageUrl !== '' ? danceEvent.danceSchool.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" alt="User Avatar" class="avatar">
           <div class="notification-text">
               {{ t('GENERIC_WRAPPER.CO_HOST_NOTIFICATION', { coHostDanceManagerName: danceEvent.danceSchool.name, danceEventName: danceEvent.name }) }}
           </div>
      </div>
      <div class="button-container">
        <df-shared-lib-button
          [buttonLabel]="t('GENERIC_WRAPPER.ACCEPT')"
          [materialButtonStyleType]="'flat'"
          [height]="'37px'"
          [minWidth]="'100%'"
          (buttonClicked)="acceptCoHostInvitation(coHost)"
          class="notification-accept">
        </df-shared-lib-button>
        <df-shared-lib-button
          [buttonLabel]="t('GENERIC_WRAPPER.DECLINE')"
          [materialButtonStyleType]="'outlined'"
          [height]="'37px'"
          [minWidth]="'100%'"
          (buttonClicked)="declineCoHostInvitation(coHost)"
          class="notification-decline">
        </df-shared-lib-button>
      </div>
    </mat-card>
 </div>
</ng-container>
