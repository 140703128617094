import {
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {Subscription} from "rxjs";
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private readonly translocoService: TranslocoService
  ) {}

  transform(value: string | Date) : string{
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = Number.isNaN(seconds)
      ? 1000
      : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.FEW_SECONDS');
    } else if (seconds <= 90) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.MINUTE');
    } else if (minutes <= 45) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.VOR')+ ' ' + minutes + ' ' + this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.MINUTES');
    } else if (minutes <= 90) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.HOUR');
    } else if (hours <= 22) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.VOR') + ' ' +hours + this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.HOURS');
    } else if (hours <= 36) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.DAY');
    } else if (days <= 25) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.VOR')+ ' ' +days + this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.DAYS');
    } else if (days <= 45) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.MONTH');
    } else if (days <= 345) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.VOR')+ ' ' +months + this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.MONTHS');
    } else if (days <= 545) {
      return this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.YEAR');
    } else {
      // (days > 545)
      return years + ' ' + this.translocoService.translate('GENERIC_WRAPPER.TIME_AGO.YEARS');
    }
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number): 2 | 30 | 300 | 3600 {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) {
      // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) {
      // less then a day, update every 5 mins
      return 300;
    } else {
      // update every hour
      return 3600;
    }
  }
}
