import { PassInterface } from './pass.interface';
import { UserPassStatusEnum } from '../../enums';
import { DmPassSubscriptionInterface } from '../dm-pass-subscription.interface';
import { UserPassSubscriptionInterface } from '../user-pass-subscription.interface';

export interface GetPassesCurrentUserInterface {
  userPassId?: string;
  danceManagerId?: string;
  usageCount?: number;
  usedCount?: number;
  pass?: PassInterface;
  createdAt?: Date;
  updatedAt?: Date;
  validUntil?: Date;
  status?: UserPassStatusEnum;
  dmPassSubscription?: DmPassSubscriptionInterface;
  userPassSubscription?: UserPassSubscriptionInterface;
}
