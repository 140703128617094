import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import {startWith} from "rxjs/operators";
import {Subscription} from "rxjs";
import * as _ from 'lodash';
import { Address, Room, Scheduler } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-shared-lib-location-room-address-selection',
  templateUrl: './location-room-address-selection.component.html',
  styleUrls: ['./location-room-address-selection.component.scss'],
})
export class LocationRoomAddressSelectionComponent implements OnInit, OnDestroy {

  @Input() locations: Location[];
  @Input() rooms: Room[];
  @Input() scheduler: Scheduler;

  @Input() formGroup: FormGroup;

  private subscriptions: Subscription = new Subscription();

  get locationId(): AbstractControl {
    return this.formGroup.controls.locationId;
  }
  ngOnInit(): void {
    this.checkValueChangesOfLocation();
    this.checkValueChangesOfAddress();
  }
  
  checkValueChangesOfLocation(): void {
    this.rooms = this.locations.find(
      // @ts-ignore
      (location) => location.id === this.scheduler?.location?.id
      // @ts-ignore
    )?.rooms;
    this.subscriptions.add(
      this.formGroup.controls.locationId.valueChanges
        .pipe(
          startWith(this.formGroup.controls.locationId.value as string)
        ).subscribe((locationId: string) => {
        if (!_.isEmpty(locationId)) {
          this.setRoomsByLocationId(locationId);
          this.clearAddressInput();
        } else {
          this.clearLocationAndRoomInput(locationId);
        }
        this.formGroup.controls.address.updateValueAndValidity({
          emitEvent: false,
        });
      })
    );
  }

  checkValueChangesOfAddress(): void {
    this.subscriptions.add(
      this.formGroup.controls.address.valueChanges
        .pipe(
          startWith(this.formGroup.controls.address.value as Address)
        ).subscribe((address: Address) => {
        if (address) {
          this.clearLocationAndRoomValidators();
        } else if (this.locationId.value !== '') {
          this.formGroup.controls.locationId.setValidators(Validators.required);
        }
        this.formGroup.controls.locationId.updateValueAndValidity({
          emitEvent: false,
        });
        this.formGroup.controls.roomId.updateValueAndValidity({
          emitEvent: false,
        });
      })
    );
  }

  onAddressSelected(address?: Address): void {
    if (address) {
      this.formGroup.controls.address.setValue(address);
    } else {
      this.formGroup.controls.address.setValue(null);
    }
  }

  setRoomsByLocationId(locationId: string): void {
    const selectedLocation = this.locations.find(
      // @ts-ignore
      (location) => location.id === locationId);
    // @ts-ignore
    this.rooms = selectedLocation?.rooms;
  }

  clearLocationAndRoomValidators(): void {
    this.formGroup.controls.locationId.clearValidators();
    this.formGroup.controls.roomId.clearValidators();
  }

  clearLocationAndRoomInput(locationId: string): void {
    if (_.isEmpty(locationId)) {
      this.locationId.clearValidators();
      this.locationId.updateValueAndValidity({emitEvent: false});
    }
    this.rooms = [];
    this.formGroup.controls.address.setValidators(Validators.required);

    this.formGroup.patchValue({
      roomId: null,
    });
  }

  clearAddressInput(): void {
    this.formGroup.controls.address.setValue(null);
    this.formGroup.controls.address.clearValidators();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
