import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeInformationToHoursMinutes'
})
export class TimeInformationToHoursMinutesPipe implements PipeTransform {
  transform(value: { hours?: number; minutes?: number }, showAmPm: boolean = false): string {
    if (!value) {
      return null;
    }
    const minutes = value.minutes === 0 ? '00' : value.minutes < 10 ? '0' + value.minutes : value.minutes;
    const hours = value.hours < 10 ? '0' + value.hours : value.hours;
    const ampm = +hours >= 12 ? 'PM' : 'AM';
    return hours + ':' + minutes + (showAmPm ? ' ' + ampm : '');
  }
}
