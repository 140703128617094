import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import * as _ from "lodash";
import { Image } from '@platri/df-common-shared-graphql';
import { Files } from '../../services';

@Component({
  selector: 'df-shared-lib-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('carousel') carousel: ElementRef;
  @Input() images: Image[];
  @Input() files: Files[];
  @Input() editMode = false;
  @Input() onlyView = false;
  @Input() thumbnail: Image;

  @Output() thumbnailChanged: EventEmitter<Image> = new EventEmitter<Image>();
  @Output() filesChanged: EventEmitter<Files> = new EventEmitter<Files>();
  @Output() deleteClickedOnImage: EventEmitter<Image> = new EventEmitter<Image>();
  @Output() deleteClickedOnFiles: EventEmitter<Files> = new EventEmitter<Files>();

  touchCoordX: number;
  thumbnailControl = new UntypedFormControl();
  fileControl = new UntypedFormControl();
  fullscreen = false;
  onFullScreenListener: any;


  constructor() {
  }

  ngOnInit() {
    this.bindDocumentListeners();
    this.initializeControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.thumbnail?.currentValue) {
      this.thumbnailControl.patchValue(changes.thumbnail.currentValue);
    } else if (!(_.isEqual(changes.images?.currentValue, changes.images?.previousValue)) && !this.thumbnailControl.value) {
      if (changes.images.currentValue) {
        this.thumbnailControl.patchValue(changes.images.currentValue[0]);
      }
      if (this.carousel) {
        this.scrollLeft(this.carousel.nativeElement, true);
      }
    }
  }

  initializeControl(): void {
    this.thumbnailControl.valueChanges.subscribe(thumbnail => {
      if (thumbnail) {
        this.fileControl.patchValue(null);
        this.thumbnailChanged.emit(thumbnail);
      }
    });
    this.fileControl.valueChanges.subscribe(preview => {
      if (preview) {
        this.thumbnailControl.patchValue(null);
        this.filesChanged.emit(preview);
      }
    });
  }

  swipeStart(event: TouchEvent) {
    this.touchCoordX = event.changedTouches[0].clientX;
  }

  swipeEnd(event: TouchEvent) {
    const newTouchCoordX: number = event.changedTouches[0].clientX;

    if (newTouchCoordX < this.touchCoordX) {
      // Swipe left
      this.scrollRight(this.carousel.nativeElement);
    } else if (newTouchCoordX > this.touchCoordX) {
      // Swipe Right
      this.scrollLeft(this.carousel.nativeElement);
    }
  }

  scrollRight(carousel: HTMLDivElement, complete = false) {
    const width = 260;
    let newScrollLocation: number;
    if (carousel.clientWidth > 3 * width) {
      newScrollLocation = 3 * width + carousel.scrollLeft;
    } else if (carousel.clientWidth > 2 * width) {
      newScrollLocation = 2 * width + carousel.scrollLeft;
    } else {
      newScrollLocation = width + carousel.scrollLeft;
    }
    if (complete) {
      carousel.scroll({left: carousel.scrollWidth, top: 0, behavior: "smooth"});
    } else {
      carousel.scroll({left: newScrollLocation, top: 0, behavior: "smooth"});
    }
  }

  scrollLeft(carousel: HTMLDivElement, complete = false) {
    let newScrollLocation: number;
    const width = 260;
    if (carousel.clientWidth > 3 * width) {
      newScrollLocation = -(3 * width) + carousel.scrollLeft;
    } else if (carousel.clientWidth > 2 * width) {
      newScrollLocation = -(2 * width) + carousel.scrollLeft;
    } else {
      newScrollLocation = -width + carousel.scrollLeft;
    }
    if (complete) {
      carousel.scroll({left: -carousel.scrollWidth, top: 0, behavior: "smooth"});
    } else {
      carousel.scroll({left: newScrollLocation, top: 0, behavior: "smooth"});
    }
  }

  onImageClick(image: Image, imageElement: HTMLImageElement) {
    if (this.onlyView && this.thumbnailControl.value.url === image.url) {
      this.openPreviewFullScreen(imageElement);
    }
    this.thumbnailControl.patchValue(image);
    this.fileControl.patchValue(null);
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    }
  }

  private closePreviewFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  onFullScreenChange() {
    this.fullscreen = !this.fullscreen;
  }

  private openPreviewFullScreen(imageElement: HTMLImageElement) {
    if (imageElement.requestFullscreen) {
      imageElement.requestFullscreen();
    } else if (imageElement['mozRequestFullScreen']) {
      imageElement['mozRequestFullScreen']();
    } /* Firefox */ else if (imageElement['webkitRequestFullscreen']) {
      imageElement['webkitRequestFullscreen']();
    } /* Chrome, Safari & Opera */ else if (imageElement['msRequestFullscreen']) {
      imageElement['msRequestFullscreen']();
    } /* IE/Edge */
  }

  bindDocumentListeners() {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullScreenListener);
    document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.addEventListener(
      'webkitfullscreenchange',
      this.onFullScreenListener
    );
    document.addEventListener('msfullscreenchange', this.onFullScreenListener);
  }

  unbindDocumentListeners() {
    document.removeEventListener('fullscreenchange', this.onFullScreenListener);
    document.removeEventListener(
      'mozfullscreenchange',
      this.onFullScreenListener
    );
    document.removeEventListener(
      'webkitfullscreenchange',
      this.onFullScreenListener
    );
    document.removeEventListener(
      'msfullscreenchange',
      this.onFullScreenListener
    );
    this.onFullScreenListener = null;
  }

  ngOnDestroy() {
    this.unbindDocumentListeners();
  }

  onFileClick(file: Files, imageElement: HTMLImageElement) {
    if (this.onlyView && this.fileControl.value.base64 === file.base64) {
      this.openPreviewFullScreen(imageElement);
    }
    this.fileControl.patchValue(file);
    this.thumbnailControl.patchValue(null);
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    }
  }

  onDeleteClick(imageOrFile: Image | Files, isImage = false) {
    if (isImage) {
      this.deleteClickedOnImage.emit(imageOrFile as Image);
    } else {
      this.deleteClickedOnFiles.emit(imageOrFile as Files);
    }
  }
}
