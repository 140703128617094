import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DanceEventCoHostStatus, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';
import { debounceTime, Subject, Subscription, tap } from 'rxjs';
import { DanceManagerCoHostInterface } from '../co-host-selection';
import { DanceManagerHttpService, DanceSchoolStateService } from '../../services';

@Component({
  selector: 'df-shared-lib-search-dance-managers',
  templateUrl: './search-dance-managers.component.html',
  styleUrls: ['./search-dance-managers.component.scss']
})
export class SearchDanceManagersComponent implements OnInit {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() showDanceManagerSearchLoading = false;
  @Input() customFormGroup: UntypedFormGroup;
  @Input() customFormControlName: string;
  @Input() existedDanceManagers: any[];
  @Input() resetFormAfterSelection = false;

  @Output() optionSelected = new EventEmitter<any>();

  @ViewChild('danceManagerInput') danceManagerInput: ElementRef;

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  private searchInputChangeSubject = new Subject<string>();
  subscriptions: Subscription = new Subscription();
  mappedDanceManagers: DanceManagerCoHostInterface[] = [];

  constructor(private readonly danceManagerHttpService: DanceManagerHttpService, private readonly danceSchoolService: DanceSchoolStateService) {}
  

  ngOnInit(): void {
    this.subscribeToSearch ();
  }
  
  emitSelectDanceManager(danceManager: DanceManagerCoHostInterface):void{
    this.optionSelected.emit(danceManager);
    if (this.existedDanceManagers) {
      this.existedDanceManagers.push(danceManager);
    } else {
      this.existedDanceManagers = [danceManager];
    }
  }
  
  onInputChange(event: any): void {
    this.searchInputChangeSubject.next(event.target.value);
    if (event.target.value === null || event.target.value === '') {
      this.mappedDanceManagers = [];
    }
  }

  subscribeToSearch ():void{
    this.subscriptions.add(
      this.searchInputChangeSubject
        .pipe(
          tap(() => (this.showDanceManagerSearchLoading = true)),
          debounceTime(500))
        .subscribe((inputText) => {
          if (inputText) {
            const getAllByFilterDto = {
              page: 1,
              limit: 20,
              search: inputText
            };
            
            this.danceManagerHttpService.getAllByFilter(getAllByFilterDto)
              .pipe( tap(() => { this.showDanceManagerSearchLoading = false;}))
              .subscribe(response =>{
                  if (response && response.results) {
                    this.mappedDanceManagers = response.results.map((item: any) => ({
                      danceManager: item.name,
                      danceManagerId: item.id,
                      imageUrl: item.imageUrl,
                      status: DanceEventCoHostStatus.PENDING
                    })).filter((value) => value.danceManager !== this.danceSchoolService.getSyncCurrentDanceSchool().name);
                  }
                }
              );
          }else {
            this.showDanceManagerSearchLoading = false;
          }
        })
    );
  }

  resetFormControlValue():void {
    if(this.resetFormAfterSelection){
    this.danceManagerInput.nativeElement.value = null;
    this.mappedDanceManagers = [];
    }
  }

  isDanceManagerDisabled(dm: DanceManagerCoHostInterface): boolean {
    if(this.existedDanceManagers){
      return this.existedDanceManagers.some(
        (existingCoHost) => existingCoHost.danceManagerId === dm.danceManagerId
      );
    }
    return false;
  }

 
}
