import { DanceManagerCategoryEnum, DanceManagerSubscriptionTypeEnum, DanceSchoolInterface, DanceSchoolTypeEnum, RadiusUnitEnum } from '../../..';
import { AddressDto, DanceManagerSocialLinkDto, DanceSchoolLocationDto, DanceSchoolToUserDto, DanceStyleDto, ImageDto } from './index';
import { DanceManagerSubscriptionDto } from './dance-manager-subscription.dto';
import { DanceManagerFaqDto } from './dance-manager-faq.dto';

export class DanceSchoolDto implements DanceSchoolInterface {
  id: string;
  name: string;
  phonePrefix: string;
  phonePrefixAfter: string;
  danceStyles: DanceStyleDto[];
  phoneNumber: string;
  danceSchoolType: DanceSchoolTypeEnum;
  urlName: string;
  danceSchoolToUsers: DanceSchoolToUserDto[];
  imageUrl: string;
  imageGalleryUrls: string[];
  email: string;
  socialLinks: DanceManagerSocialLinkDto[];
  description: string;
  teamDescription: string;
  titleImage: ImageDto;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  businessAddress: AddressDto;
  danceManagerCategory?: DanceManagerCategoryEnum[];
  danceManagerSubscriptionType: DanceManagerSubscriptionTypeEnum;
  danceManagerSubscriptions?: DanceManagerSubscriptionDto[];
  stripeTotalSaldo: number;
  locations?: DanceSchoolLocationDto[];
  faqs?: DanceManagerFaqDto[];
  apiKey?: string;
  vat: number;
}
