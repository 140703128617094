<ng-container *transloco="let t">
  <div class="relative course-page-container flex-row-center-center h-100">
    <div class="course-background-header">
      <img 
        class="hide-on-mobile" 
        [src]="(course && course.mainImage && course.mainImage.url)? course.mainImage.url : 'assets/images/event_header.png'" 
        alt=""
      />
    </div>
    <div class="flex-column w-100 h-100" style="max-width: 1140px">
      <df-monorepo-course-page-content 
        *ngIf="course" 
        [course]="course"
        [passMinPrice]="passMinPrice"
        id="course-content"
        class="course-page-content"
      ></df-monorepo-course-page-content>
      <div class="flex-column-center-center w-100 h-100">
        <mat-spinner *ngIf="isWaitingForResponse && !isInitialized"></mat-spinner>
      </div>
      <div *ngIf="!isInitialized && hasErrorLoadingCoursesDetails" class="error-box">
        <p style="color: red" data-cy="connection-error">{{ t('GENERIC_WRAPPER.NO_SERVER_CONNECTION') }}</p>
        <button 
          mat-button 
          color="primary" 
          type="button" 
          (click)="loadData()" 
          data-cy="try-again-button"
        >{{ t('GENERIC_WRAPPER.TRY_AGAIN_SHORT') }}</button>
      </div>
    </div>
  </div>
</ng-container>
