<ng-container *transloco="let t">
  <div *ngIf="(conversation | conversationToPartner : currentPartnerId)?.length > 0">
    <div class="conversation-outer-view">
      <div class="conversation-view">
        <div *ngIf="loading" class="loading-container">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>

        <ng-container *ngIf="!loading">
          <div class="conversation-header">
            <div class="back-button-cell">
              <button mat-icon-button color="#4D4D4D" [routerLink]="[]">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </div>
            <div class="avatar-cell">
              <a *ngIf="conversation.partners.length <= 2" [routerLink]="['/', '@' +  (conversation | conversationFirstPartner : currentPartnerId).username]">
                <img
                  class="avatar"
                  imageFallback
                  [fallbackSrc]="conversation.partners.length <= 2 ? 
                    (conversation | conversationFirstPartner : currentPartnerId)?.id.startsWith('user') ? DEFAULT_PROFILE_PICTURE_PLACEHOLDER : DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER : DEFAULT_GROUP_MESSAGE_PLACEHOLDER"
                  [src]="conversation.partners.length <= 2 ? (conversation | conversationFirstPartner : currentPartnerId).imageUrl || DEFAULT_PROFILE_PICTURE_PLACEHOLDER : DEFAULT_GROUP_MESSAGE_PLACEHOLDER"/>
              </a>
            </div>
            <div class="partner-names-cell">
              <h3>
                <span *ngFor="let toPartner of conversation | conversationToPartner: currentPartnerId; let last = last">
                  <a
                    class="partner-link"
                    [routerLink]="['/', '@' + toPartner.username]"
                  >
                    {{ toPartner.username }}</a
                  ><ng-container *ngIf="!last">, </ng-container>
                </span>
              </h3>
            </div>
            <div class="more-button-cell">
              <div class="text-right">
                <div *ngIf="conversation.messages.length > 0">
                 <!--the condition is always true since isCurrentUser in not defined-->
                  <button
                    *ngIf="!isCurrentUser"
                    class="vert"
                    mat-icon-button
                    color="#4D4D4D"
                    [matMenuTriggerFor]="menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="delete()">
                      <mat-icon>delete</mat-icon>
                      <span>{{
                        t('GENERIC_WRAPPER.CONVERSATION.MORE_OPTIONS_DELETE_CHAT')
                      }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="messageText" #messageText>
              <df-message
                *ngFor="let message of conversation.messages; let index = index"
                [message]="message"
                [nextMessage]="conversation.messages[index + 1]"
                [currentPartnerId]="currentPartnerId"
                [conversationPartner]="getConversationPartnerByMessage(message)"
                [showUsername]="conversation.partners.length > 2"
              ></df-message>

              <p *ngIf="showConversationReadInformation()" class="read-at">
                {{ t('GENERIC_WRAPPER.CONVERSATION.READ') }}
                {{ conversation.readAt | timeAgo }}
              </p>
            </div>

            <ng-container *ngIf="showEmojis">
              <span class="emojis">
                <div class="emojis-header">
                  <button mat-icon-button (click)="toggleEmojis()">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
                <df-emojis (selectedEmoji)="appendChars($event)"></df-emojis>
              </span>
            </ng-container>
            <div *ngIf="!loading" class="send-message">
              <div class="flex-row align-space-between-center gap-10">
                <button mat-icon-button (click)="toggleEmojis()">
                  <mat-icon>insert_emoticon</mat-icon>
                </button>
                <df-shared-lib-quill-mention-messenger
                  class="w-100 mt-10"
                  (typedMessengerContent)="setMessengerContent($event)"
                  [resetMessengerField]="resetContent"
                  [setEmojis]="settedEmojis"
                  (keyEnterPressed)="onEnterSendMessage()"
                  #textInput
                ></df-shared-lib-quill-mention-messenger>
                <button
                  mat-icon-button
                  matTooltip="Press enter to send"
                  color="primary"
                  [disabled]="!message.trim()"
                  (click)="onClickSendMessage()"
                >
                  <mat-icon>send</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
