<ng-container *transloco="let t">
  <mat-card *ngIf="!isLocationsPage" class="location-card" [class.w-100]="!isLocationsPage">
    <div *ngFor="let location of locations; let last = last; let index = index">
      <mat-expansion-panel class="w-100 flex-row align-space-between-center expansion-panel" hideToggle [expanded]="index === 0" (opened)="cardOpenState[index] = true" (closed)="cardOpenState[index] = false">
        <mat-expansion-panel-header>
          <div class="w-100 location-title flex-row align-space-between-center">
            <div class="flex-row">
              <h2 class="align-center">{{ location.name }}</h2>
            </div>
            <div class="w-10 h-100 align-center">
              <button mat-icon-button *ngIf="isOwnerOrAdmin" (click)="routeToLocationEdit(location.id)">
                <mat-icon>edit</mat-icon>
              </button>
              <mat-icon class="align-end" fontSet="material-icons-round" [ngClass]="cardOpenState[index] ? 'rotate' : ''">keyboard_arrow_down</mat-icon>
            </div>
          </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="w-100"></div>
          <!-- address -->
          <div class="w-100 flex-row align-start" style="gap: 10px;margin-top: 10px;">
            <mat-icon>room</mat-icon>
            <a target="_blank" [href]="location.address.googleMapsUrl" class="link hover-div w-100">
              {{ location.address.formattedAddress }}
            </a>
          </div>
          <!-- opening hours -->
          <div class="w-100 flex-row align-start" style="gap: 10px;margin-top: 10px;" *ngIf="location.showOpeningHours">
            <mat-icon>access_time</mat-icon>
            <div class="w-100 opening-hours-expansion-panel flex-column">
              <mat-expansion-panel hideToggle (opened)="panelOpenState[index] = true" (closed)="panelOpenState[index] = false">
                <mat-expansion-panel-header>
                  <div class="flex-row" style="gap: 5px; font-size: 14px">
                    <span [ngStyle]="{ color: openStatus[index]  ? '#00B00C' : '#CE0404' }">
                      {{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.' + (openStatus[index]  ? 'OPEN' : 'CLOSED')) }}
                    </span>
                    <span *ngIf="closesAt[index] || opensAt[index]">•</span>
                    <span *ngIf="closesAt[index] || opensAt[index]">
                      {{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.' + (openStatus[index]  ? 'CLOSES' : 'OPENS')) }}
                      {{
                        openStatus[index]
                          ? (closesAt[index] | dateTimezoned:'UTC': currentLang: opensAt: false: true)
                          : (opensAtDay[index] ? opensAtDay[index] : '') + ' ' + (opensAt[index] | dateTimezoned:'UTC': currentLang:null: false: true)
                      }}
                    </span>
                  </div>
                  <mat-icon fontSet="material-icons-round" [ngClass]="panelOpenState[index] ? 'rotate' : ''">keyboard_arrow_down</mat-icon>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <ng-container *ngFor="let days of location.openingHours | keyvalue: ascOrder">
                    <div style="gap: 30px;" class="my1 flex-row align-space-between" [ngClass]="days.key === today ? 'bold' : ''">
                      <span class="days">{{ t('GENERIC_WRAPPER.WEEKDAYS.FULL.' + days.key).toUpperCase() }}:</span>
                      <div class="align-end">
                        <div class="flex-column">
                          <span class="times" *ngFor="let times of days.value">{{ times | timeRange : currentLang}}</span>
                          <span *ngIf="days.value.length < 1">{{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.CLOSED') }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </mat-expansion-panel>
            </div>
          </div>
          <!-- phone number --> 
          <div *ngIf="location.phoneNumber" class="w-100 flex-row align-start" style="gap: 10px; margin-top: 10px;">
            <mat-icon>phone</mat-icon>
            <a href="tel:{{ location.phonePrefix }}{{ location.phonePrefixAfter }}{{location.phoneNumber}}" class="link hover-div w-100">
              {{ location.phonePrefix }}
              {{ location.phonePrefixAfter }}
              {{ location.phoneNumber }}
              </a>
          </div>
          <!-- email -->
          <div *ngIf="location.email as email" class="w-100 flex-row align-start" style="gap: 10px; margin-top: 10px;">
            <mat-icon>email</mat-icon>
            <a target="_blank" href="mailto:{{ email }}" class="link hover-div w-100">
              {{ email }}
            </a>
          </div>
       </ng-template>
      </mat-expansion-panel>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
  </mat-card>
      
  <div *ngIf="isLocationsPage" class="locations-card-grid">
    <mat-card *ngFor="let location of locations;  let index = index" style="padding: 16px; margin-bottom: 16px; margin-right: 16px;" class="location-card h-100 locations-card-half">
      <div class="w-100 flex-column align-start" style="gap: 10px;">
        <div class="w-100 location-title flex-row align-space-between-center">
          <div class="flex-row">
            <div class="public-or-private-icon align-center">
              <button mat-icon-button [matMenuTriggerFor]="privacyMenu">
                <mat-icon *ngIf="location.isPublic" id="public-icon" data-cy="public-icon">public</mat-icon>
                <mat-icon *ngIf="!location.isPublic" id="private-icon" data-cy="private-icon">lock</mat-icon>
              </button>
              <mat-menu #privacyMenu="matMenu">
                <div class="p1 flex-column-center-start">
                  <button mat-menu-item (click)="updateVisibility(location, true)">
                    <mat-icon class="vSub material-icons-round">public</mat-icon>
                    <span>{{t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.PRIVACY_PUBLIC')}}</span>
                  </button>
                  <button mat-menu-item (click)="updateVisibility(location, false)">
                    <mat-icon class="vSub material-icons-round">lock</mat-icon>
                    <span>{{t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.PRIVACY_PRIVATE')}}</span>
                  </button>
                </div>
              </mat-menu>
            </div>
            <h2 class="align-center">{{ location.name }}</h2>
          </div>
          <div style="width: 10%;" class="pr2 flex-column">
            <button mat-icon-button [matMenuTriggerFor]="menu" data-cy="locations-card-menu-button">
              <mat-icon style="color: #4D4D4D;">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="navigateToLocationPage(location)">
                <span>{{ t('GENERIC_WRAPPER.EDIT') }}</span>
              </button>
              <button mat-menu-item (click)="deleteLocation(location)">
                <span>{{ t('GENERIC_WRAPPER.DELETE') }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
    
        <div class="w-100"></div>
        <!-- adress -->
        <div
          class="w-100 flex-row align-start"
          style="gap: 10px;"
        >
          <mat-icon>room</mat-icon>
          <a  target="_blank" [href]="location.address.googleMapsUrl" class="link hover-div w-100">
            {{ location.address.formattedAddress }}
          </a>
        </div>
        <!-- opening hours -->
        <div
          class="w-100 flex-row align-start"
          style="gap: 10px;"
          *ngIf="location.showOpeningHours"
        >
          <mat-icon>access_time</mat-icon>
    
          <div class="w-100 opening-hours-expansion-panel flex-column">
            <mat-expansion-panel
              hideToggle
              (opened)="panelOpenState[index] = true"
              (closed)="panelOpenState[index] = false"
            >
              <mat-expansion-panel-header>
                <div class="flex-row" style="gap: 5px;">
                  <span [ngStyle]="{ color: openStatus[index]  ? '#00B00C' : '#CE0404' }">
                    {{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.' + (openStatus[index]  ? 'OPEN' : 'CLOSED')) }}
                  </span>
                  <span *ngIf="closesAt[index] || opensAt[index]">•</span>
                  <span *ngIf="closesAt[index] || opensAt[index]">
                    {{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.' + (openStatus[index]  ? 'CLOSES' : 'OPENS')) }}
                    {{
                      openStatus[index]
                        ? (closesAt[index] | dateTimezoned:'UTC': currentLang: opensAt: false: true)
                        : (opensAtDay[index] ? opensAtDay[index] : '') + ' ' + (opensAt[index] | dateTimezoned:'UTC': currentLang:null: false: true)
                    }}
                  </span>
                </div>
                <mat-icon fontSet="material-icons-round" [ngClass]="panelOpenState[index] ? 'rotate' : ''">keyboard_arrow_down</mat-icon>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ng-container *ngFor="let days of location.openingHours | keyvalue: ascOrder">
                  <div
                    style="gap: 30px;"
                    class="my1 flex-row align-space-between"
                    [ngClass]="days.key === today ? 'bold' : ''"
                  >
                    <span class="days">{{( t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.WEEKDAYS.FULL.' + days.key).toUpperCase()) }}:</span>
                    <div class="align-end">
                      <div class="flex-column">
                        <span class="times" *ngFor="let times of days.value">{{ times | timeRange : currentLang}}</span>
                        <span *ngIf="days.value.length < 1">{{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.CLOSED') }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </mat-expansion-panel>
          </div>
        </div>
        <!-- phone number --> 
        <div *ngIf="location.phoneNumber" class="w-100 flex-row align-start" style="gap: 10px;">
          <mat-icon>phone</mat-icon>
          <a href="tel:{{ location.phonePrefix }}{{ location.phonePrefixAfter }}{{location.phoneNumber}}" class="link hover-div w-100">
            {{ location.phonePrefix }}
            {{ location.phonePrefixAfter }}
            {{ location.phoneNumber }}
            </a>
        </div>
        <!-- email -->
        <div *ngIf="location.email as email" class="w-100 flex-row align-start" style="gap: 10px;">
          <mat-icon>email</mat-icon>
          <a target="_blank" href="mailto:{{ email }}" class="link hover-div w-100">
            {{ email }}
          </a>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
