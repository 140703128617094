import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateOrderValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const startControl = formGroup.get('startTime');
  const endControl = formGroup.get('endTime');

  if (!startControl || !endControl) {
    return null;
  }

  const startTime = startControl.value;
  const endTime = endControl.value;

  if (startTime && endTime) {
    const start = new Date();
    const end = new Date();

    const [startHour, startMinute] = startTime.split(':').map((val: string) => parseInt(val, 10));
    const [endHour, endMinute] = endTime.split(':').map((val: string) => parseInt(val, 10));

    start.setHours(startHour, startMinute);
    end.setHours(endHour, endMinute);

    if (end < start) {
      startControl.setErrors({ timeInvalid: true });
      endControl.setErrors({ timeInvalid: true });
      return { timeInvalid: true };
    }
    else {
      startControl.setErrors(null);
      endControl.setErrors(null);
    }
  }

  return null;
};
