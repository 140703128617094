<ng-container *transloco="let t">
  <div class="p2">
    <div *ngIf="data.title" class="mb-10">
    <span>
      <strong>{{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.' + data.title) }}</strong>
    </span>
  </div>
  <form [formGroup]="formGroup">
    <div class="mb-10 flex-column">
      <mat-form-field appearance="outline" class="w-100">
        <input
          autocomplete="off"
          matInput
          placeholder="{{ t('GENERIC_WRAPPER.LOCATION_NAME') }}"
          formControlName="name"
        />
        <mat-hint align="end">
          {{name.value.length}} / 32
        </mat-hint>
        <mat-error *ngIf="name?.hasError('required')">
          {{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.ERROR.REQUIRED') }}
        </mat-error>
        <mat-error
          *ngIf="name?.hasError('maxlength')"
          class="flex-row-wrap-space-between"
        >
          <div>
            {{ t('GENERIC_WRAPPER.DANCE_SCHOOL_LOCATION.ERROR.NAME_ERROR_MAX_LENGTH') }}
          </div>
          <div>
            {{name?.errors?.maxlength.actualLength}} / {{name?.errors?.maxlength.requiredLength}}
          </div>
        </mat-error>
      </mat-form-field>
      <div class="w-100 flex-column" style="gap: 10px;">
        <strong>
          {{ t('GENERIC_WRAPPER.ADDRESS') }}
        </strong>
        <df-shared-lib-google-address-search-autocomplete
          [address]="address.value"
          (addressSelected)="setSelectedAddress($event)"
          (addressChanging)="setSelectedAddress()"
        ></df-shared-lib-google-address-search-autocomplete>
      </div>
  
      <div class="mt2 flex-column" style="gap: 30px;">
        <mat-radio-group
          aria-label="Select an option"
          formControlName="isPublic"
          color="primary"
          [value]="isPublic.value"
        >
        <div class="mt0">
          <mat-radio-button [value]="true">
            {{ t('GENERIC_WRAPPER.PERMANENT_LOCATION') }}
          </mat-radio-button>
        </div>
        <div class="mt2">
          <mat-radio-button [value]="false">
            {{ t('GENERIC_WRAPPER.TEMPORARY_LOCATION') }}
          </mat-radio-button>
        </div>
        </mat-radio-group>
      </div>
      <div class="pt1 flex-row align-end" style="gap: 12px;">
        <df-shared-lib-button
          [height]="'46px'"
          [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
          [materialButtonStyleType]="'outlined'"
          (buttonClicked)="close()"
        ></df-shared-lib-button>
        <df-shared-lib-button
          [height]="'46px'"
          [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
          [materialButtonStyleType]="'flat'"
          [isDisabled]="formGroup.invalid"
          (buttonClicked)="add()"
        ></df-shared-lib-button>
      </div>
    </div>
  </form>
  </div>
</ng-container>
