import { CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, CustomRecurrenceWeekDaysTypeEnum, SchedulerRecurrenceTypeEnum, SchedulersInterface, SchedulerTypeEnum } from '../../..';
import { AppointmentsDto } from './appointments.dto';
import {AppointmentUsersDto} from "./appointment-users.dto";
import {AddressDto} from "./address.dto";
import {LocationsDto} from "./locations.dto";
import {RoomDto} from "./room.dto";

export class SchedulersDto implements SchedulersInterface {
  additionalInformation?: string;
  address: AddressDto;
  appointmentToUsers: AppointmentUsersDto[];
  appointments: AppointmentsDto;
  customRecurrenceEndType?: CustomRecurrenceEndTypeEnum;
  customRecurrenceNumber?: number;
  customRecurrenceType?: CustomRecurrenceTypeEnum;
  customRecurrenceWeekDaysTypes?: CustomRecurrenceWeekDaysTypeEnum[];
  duration: number;
  endDate?: Date;
  id: string;
  location?: LocationsDto;
  numberOfAppointments?: number;
  recurrenceType?: SchedulerRecurrenceTypeEnum;
  room?: RoomDto;
  schedulerType: SchedulerTypeEnum;
  startDate: Date;
}
