import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { PassPriceFormComponent } from './pass-price-form.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { PipesModule } from '../../pipes';
import { DfSharedButtonModule } from '../buttons';
import { DfQuillEditorModule } from '../df-quill-editor';
import {FormatNumberModule, NoScrollDirective} from '../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [PassPriceFormComponent],
  exports: [PassPriceFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatCardModule,
    MatDividerModule,
    DfSharedButtonModule,
    MatFormFieldModule,
    MatInputModule,
    DfQuillEditorModule,
    ReactiveFormsModule,
    FormatNumberModule,
    MatSelectModule,
    MatRadioModule,
    NoScrollDirective,
    TranslocoDirective
  ]
})
export class PassPriceFormModule {}
