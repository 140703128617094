<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveGenderData()">
    <div class="flex-row" style="gap: 1rem">
      <mat-form-field appearance="outline">
        <mat-label>{{ t('USER.GENDER') }}</mat-label>
        <mat-select
          formControlName="gender"
          disableOptionCentering
          required
        >
          <mat-option
            *ngFor="let gender of Gender | keyvalue"
            [value]="gender.value"
          >
            {{ t('USER.GENDER_ENUM.' + gender.key) }}
          </mat-option
          >
        </mat-select>
      </mat-form-field>
  
  
    </div>
  
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveGenderData()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
