import { MessagePartnerDto } from './message-partner.dto';
import { MessageDto } from './message.dto';
import { ConversationInterface } from "../../..";

export class ConversationDto implements ConversationInterface {
  id?: string;
  fromPartnerId: string;
  partnerIds?: string;
  partners?: MessagePartnerDto[];
  messages?: MessageDto[];
  readAt?: Date | null;
}
