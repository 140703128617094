import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CourseSubscriptionTableModule,
  DfSharedButtonModule,
  PassPriceFormModule,
  SharedEmptyPageModule
} from '@platri/dfx-angular-core';
import { DfmCourseSubscriptionsPage } from './dfm-course-subscriptions.page';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmCourseSubscriptionsPage],
  exports: [
    DfmCourseSubscriptionsPage
  ],
  imports: [
    CommonModule,
    PassPriceFormModule,
    CourseSubscriptionTableModule,
    SharedEmptyPageModule,
    DfSharedButtonModule,
    TranslocoDirective
  ]
})
export class DfmCourseSubscriptionsPageModule {}
