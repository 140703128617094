import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceIntervalFormSettingsComponent } from './dance-interval-form-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceIntervalFormSettingsComponent],
  exports: [DanceIntervalFormSettingsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatRadioModule,
        MatButtonModule,
        DfSharedButtonModule,
        TranslocoDirective
    ],
})
export class DanceIntervalFormSettingsModule {}
