<ng-container *transloco="let t">
  <div class="flex-column gap-20">
    <mat-chip-listbox class="flex-row gap-20 pt1">
        <mat-chip-option *ngFor="let lookingForItem of temporaryLookingForList; let index = index" [selectable]="false" class="flex-row align-center">
          <span>{{ lookingForItem }}</span>
          <button matChipRemove (click)="delete(index)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
    </mat-chip-listbox>
    <div class="flex-column">
      <div class="flex-row gap-20">
        <mat-form-field style="width: 50%;" appearance="outline">
          <mat-label>{{ t('USER.KEYWORDS') }}</mat-label>
          <input matInput (keyup.enter)="add()" autocomplete="off" [formControl]="lookingFor"/>
          <mat-error
            *ngIf="lookingFor.hasError('required') ">{{ t('USER.INPUT_EMPTY_ERROR') }}</mat-error>
          <mat-error
            *ngIf="lookingFor.hasError('minlength') ">{{ t('USER.INPUT_MIN_LENGTH_ERROR') }}</mat-error>
          <mat-error
            *ngIf="lookingFor.hasError('maxlength') ">{{ t('USER.INPUT_MAX_LENGTH_ERROR') }}</mat-error>
        </mat-form-field>
        <div style="flex: 1;">
          <button mat-raised-button style="height: 56px;" (click)="add()" [disabled]="lookingFor.invalid">
            <mat-icon>done</mat-icon>
          </button>
        </div>
      </div>
      <div class="flex-column gap-20">
        <span><strong>{{ t('USER.EXAMPLES') }}</strong></span>
        <div class="div-style">
          <mat-chip-listbox>
            <mat-chip-option #dancepartner (click)="addStandardExamples('Dance Partner')"
                             [style.display]="standardExampleInList('Dance Partner')?'none':''" class="mat-chip-example" [selectable]="false">{{ t('USER.DANCE_PARTNER') }}</mat-chip-option>
            <mat-chip-option #events (click)="addStandardExamples('Events')"
                             [style.display]="standardExampleInList('Events')?'none':''" class="mat-chip-example" [selectable]="false">{{ t('GENERIC_WRAPPER.EVENTS') }}</mat-chip-option>
            <mat-chip-option #courses (click)="addStandardExamples('Courses')"
                             [style.display]="standardExampleInList('Courses')?'none':''"
                             class="mat-chip-example" [selectable]="false">{{ t('GENERIC_WRAPPER.COURSES') }}</mat-chip-option>
            <mat-chip-option #communities (click)="addStandardExamples('Communities')"
                             [style.display]="standardExampleInList('Communities')?'none':''"
                             class="mat-chip-example" [selectable]="false">{{ t('USER.COMMUNITIES') }}</mat-chip-option>
            <mat-chip-option #studios (click)="addStandardExamples('Studios')"
                             [style.display]="standardExampleInList('Studios')?'none':''"
                             class="mat-chip-example" [selectable]="false">{{ t('USER.STUDIOS') }}</mat-chip-option>
            <mat-chip-option #danceroom (click)="addStandardExamples('Dance room')"
                             [style.display]="standardExampleInList('Dance room')?'none':''"
                             class="mat-chip-example" [selectable]="false">{{ t('USER.DANCE_ROOM') }}</mat-chip-option>
            <mat-chip-option #dancer (click)="addStandardExamples('Dancer')"
                             [style.display]="standardExampleInList('Dancer') ? 'none':''"
                             class="mat-chip-example" [selectable]="false">{{ t('USER.DANCER') }}</mat-chip-option>
          </mat-chip-listbox>
        </div>

      </div>
    </div>

    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        (buttonClicked)="cancel()"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        [isDisabled]="editModeClosed"
      ></df-shared-lib-button>
      <df-shared-lib-button
        (buttonClicked)="save()"
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        [isDisabled]="editModeClosed "
        class="profile-save-button"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
