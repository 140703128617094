import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CourseInterface, DanceStyleInterface, TimeOperations } from '@platri/df-common-core';
import { DanceStyle } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-shared-lib-duplicate-dance-event-dialog',
  templateUrl: './duplicate-course-dialog.component.html',
  styleUrls: ['./duplicate-course-dialog.component.scss']
})
export class DuplicateCourseDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) 
              public data: CourseInterface, 
              public dialogRef: MatDialogRef<DuplicateCourseDialogComponent>, 
              private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.data.name, [Validators.required, Validators.maxLength(64)]],
      danceStyles: [this.data.danceStyles, [Validators.required]],
      startDifficulty: [this.data.startDifficulty],
      endDifficulty: [this.data.endDifficulty],
      takeoverPasses: [true]
    });
  }

  save(): void {
    // { name, danceStyles, startDifficulty, endDifficulty, takeoverPasses }
    this.dialogRef.close(this.formGroup.getRawValue());
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectDanceStyle(danceStyle: DanceStyleInterface): void {
    this.formGroup.patchValue({
      danceStyles: this.formGroup.controls.danceStyles.value ? this.formGroup.controls.danceStyles.value.concat([danceStyle]) : [danceStyle]
    });
  }

  removeSelectedDanceStyle(danceStyle: DanceStyle): void {
    let selectedDanceStyles = this.formGroup.controls.danceStyles?.value;
    const index = selectedDanceStyles.indexOf(danceStyle);
    if (index >= 0) {
      selectedDanceStyles.splice(index, 1);
      selectedDanceStyles = selectedDanceStyles.length === 0 ? null : [...selectedDanceStyles];
    }
    this.formGroup.patchValue({danceStyles: selectedDanceStyles});
  }

  setCourseLevel(event: { fromLevel: number; toLevel: number }): void {
    if (event.fromLevel !== event.toLevel) {
      this.formGroup.controls.startDifficulty.setValue(event.fromLevel);
      this.formGroup.controls.endDifficulty.setValue(event.toLevel);
    }
  }
}
