import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImageCropperDialogComponent } from './image-cropper-dialog.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ImageCropperDialogComponent],
  exports: [ImageCropperDialogComponent],
    imports: [
      CommonModule,
      ImageCropperModule,
      MatButtonModule,
      MatDialogModule,
      MatIconModule,
      TranslocoDirective,
    ],
})
export class ImageCropperDialogModule {}
