import { DanceSchoolLocationOpeningHoursDayDto } from './dance-school-location-opening-hours-day.dto';
import { OpeningHoursInterface } from '../../..';

export class DanceSchoolLocationOpeningHoursDto implements OpeningHoursInterface {
  monday: DanceSchoolLocationOpeningHoursDayDto[];
  tuesday: DanceSchoolLocationOpeningHoursDayDto[];
  wednesday: DanceSchoolLocationOpeningHoursDayDto[];
  thursday: DanceSchoolLocationOpeningHoursDayDto[];
  friday: DanceSchoolLocationOpeningHoursDayDto[];
  saturday: DanceSchoolLocationOpeningHoursDayDto[];
  sunday: DanceSchoolLocationOpeningHoursDayDto[];
}
