import {Pipe, PipeTransform} from '@angular/core';
import {WeekDay} from '@angular/common';
import {format, getDay} from "date-fns";
import { AppointmentRecurrenceTypeEnum } from '@platri/df-common-shared-graphql';
import { CustomRecurrenceTypeEnum } from '@platri/df-common-shared-graphql';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'formatInterval',
})
export class IntervalPipe implements PipeTransform {
  intervalEnum = AppointmentRecurrenceTypeEnum;
  customRecurrenceTypeEnum = CustomRecurrenceTypeEnum;
  currentDateString: string;
  nth: string;
  weekDay: string;
  weekDaysNumber: number[];
  weekDays: string[] = [];
  weekDayEnm = WeekDay;

  constructor(
    private readonly translocoService: TranslocoService
  ) {
  }

  transform(
    value: string,
    dateForWeekDay?: Date,
    customRecurrenceNumber?: number,
    customRecurrenceWeekDaysNumber?: number[],
    date?: Date
  ): string {
    this.currentDateString = date
      ? format(date, 'd. MMMM'/*, { locale: this.getLocale(this.userService.getCurrentUserValue().appLang || 'en') }*/)
      : format(new Date(), 'd. MMMM');
    switch (this.getNthOfDate(date)) {
      case 1:
        this.nth = this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.FIRST');
        break;
      case 2:
        this.nth = this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.SECOND');
        break;
      case 3:
        this.nth = this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.THIRD');
        break;
      case 4:
        this.nth = this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.FOURTH');
        break;
      case 5:
        this.nth = this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.FIFTH');
        break;
    }

    if (customRecurrenceWeekDaysNumber) {
      let index = 0;
      while (index !== -1) {
        index = customRecurrenceWeekDaysNumber.findIndex(
          (weekdayNumber) => weekdayNumber === 7
        );
        if (index !== -1) {
          customRecurrenceWeekDaysNumber[index] = 0;
        }
      }
      customRecurrenceWeekDaysNumber.forEach((nr) => {
        this.weekDays.push(this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKDAYS.' + this.weekDayEnm[nr].toUpperCase()));
      });
    }

    if (dateForWeekDay) {
      this.weekDay = this.weekDayEnm[getDay(dateForWeekDay)];
    } else {
      this.weekDay = this.weekDayEnm[
        getDay(date ? date : new Date())
        ];
    }
    if (value === this.intervalEnum.DAILY) {
      return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.DAILY');
    } else if (value === this.intervalEnum.EVERY_MONDAY_TO_FRIDAY) {
      return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.EVERY_MONDAY_TO_FRIDAY');
    } else if (value === this.intervalEnum.CUSTOM) {
      return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.CUSTOM');
    } else if (value === this.intervalEnum.MONTHLY_NTH) {
      return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.MONTHLY_ON') + ' ' + this.nth + ' ' + this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKDAYS.' + this.weekDay.toUpperCase());
    } else if (value === this.intervalEnum.WEEKLY_NTH) {
      return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKLY_ON') + ' ' + this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKDAYS.' + this.weekDay.toUpperCase());
    } else if (value === this.intervalEnum.YEARLY) {
      return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.YEARLY_ON') + ' ' + this.currentDateString;
    } else if (value === this.customRecurrenceTypeEnum.DAY) {
      if (customRecurrenceNumber && customRecurrenceNumber > 1) {
        return (
          this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.EVERY') +
          customRecurrenceNumber +
          ' ' +
          this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.DAYS')
        );
      } else {
        return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.DAILY');
      }
    } else if (value === this.customRecurrenceTypeEnum.WEEK) {
      if (customRecurrenceNumber && customRecurrenceNumber > 1) {
        if (this.weekDays.length > 0) {
          return (
            this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.EVERY') +
            customRecurrenceNumber +
            ' ' +
            this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKS') +
            ' ' +
            this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.ON') +
            ' ' +
            this.weekDays.join(', ')
          );
        } else {
          return (
            this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.EVERY') +
            customRecurrenceNumber +
            ' ' +
            this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKS')
          );
        }
      } else {
        if (this.weekDays.length > 0) {
          return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKLY_ON') + ' ' + this.weekDays.join(', ');
        } else {
          return this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKLY_ON') + ' ' + this.translocoService.translate('GENERIC_WRAPPER.FORMAT_INTERVAL_PIPE.TYPESCRIPT.WEEKDAYS.' + this.weekDay.toUpperCase());
        }
      }
    } else {
      return value;
    }
  }

  getNthOfDate(dateInput: Date): number {
    const date = dateInput ? dateInput instanceof Date ? dateInput.getDate() : new Date(dateInput).getDate() : new Date().getDate();
    return Math.ceil(date / 7);
  }

}
