import { DanceStyleInterface } from '../dance-style.interface';
import { MusicStyleInterface } from '../music-style.interface';
import { ImageInterface } from '../image.interface';
import { DanceManagerInterface } from '../dance-manager';
import {
  DanceEventCategoryEnum,
  DanceEventSearchStatusEnum,
  DanceEventStatusEnum,
  RadiusUnitEnum
} from '../../enums';
import { DanceEventCoHostsInterface } from './dance-event-co-hosts';
import { DanceEventRestrictionsInterface } from './dance-event-restrictions.interface';
import { DanceEventRulesInterface } from './dance-event-rules.interface';
import { AppointmentsInterface } from '../appointments';
import { PageViewsInterface } from '../page-views.interface';

export interface DanceEventsInterface {
  appointments?: AppointmentsInterface[];
  categories?: DanceEventCategoryEnum[];
  cheapestPrice?: number;
  coHosts?: DanceEventCoHostsInterface[];
  createdAt?: Date;
  danceSchool?: DanceManagerInterface;
  danceStyles?: DanceStyleInterface[];
  description?: string;
  distance?: number;
  hasSoldTickets?: boolean;
  id?: string;
  isActive?: boolean;
  isSoldOut?: boolean;
  mainImage?: ImageInterface;
  musicStyles?: MusicStyleInterface[];
  name?: string;
  radiusUnit?: RadiusUnitEnum;
  restrictions?: DanceEventRestrictionsInterface;
  rules?: DanceEventRulesInterface;
  tags?: string[];
  updatedAt?: Date;
  url?: string;
  pageViews?: PageViewsInterface;
  searchStatus?: DanceEventSearchStatusEnum;
  status?: DanceEventStatusEnum;
  images?:ImageInterface[];
}
