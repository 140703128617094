import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, Subscription, tap } from 'rxjs';
import { TicketHttpService } from '../http-services';
import { TicketInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class TicketStateService {
  private ticketToDuplicateSubject: BehaviorSubject<TicketInterface | null> = new BehaviorSubject<TicketInterface | null>(null);
  
  private ticketSubject: BehaviorSubject<TicketInterface | null> = new BehaviorSubject<TicketInterface | null>(null);
  private ticketsSubject: BehaviorSubject<TicketInterface[]> = new BehaviorSubject<TicketInterface[]>([]);

  private subscriptions: Subscription = new Subscription();

  #loadingSubject$ = new BehaviorSubject<boolean>(false);
  loadingSubject$ = this.#loadingSubject$.asObservable();

  constructor(private readonly ticketHttpService: TicketHttpService) {}

  sendTicketToDuplicate(ticket: TicketInterface): void {
    this.ticketToDuplicateSubject.next(ticket);
  }
  
  getSyncTicketToDuplicate(): TicketInterface {
    return this.ticketToDuplicateSubject.getValue();
  }
  
  sendLoading(data: boolean): void {
    this.#loadingSubject$.next(data);
  }

  sendTicket(ticket: TicketInterface): void {
    this.ticketSubject.next(ticket);
  }

  getSyncTicket(): TicketInterface | null {
    return this.ticketSubject.getValue();
  }

  getAsyncTicket(): Observable<TicketInterface | null> {
    return this.ticketSubject.asObservable();
  }

  sendTickets(tickets: TicketInterface[]): void {
    this.ticketsSubject.next(tickets);
  }

  getSyncTickets(): TicketInterface[] {
    return this.ticketsSubject.getValue();
  }

  getAsyncTickets(): Observable<TicketInterface[]> {
    return this.ticketsSubject.asObservable();
  }

  getById(id: string): void {
    this.subscriptions.add(
      this.ticketHttpService.getById(id).subscribe((result) => {
        this.sendTicket(result);
      })
    );
  }

  getAllByDanceEvent(danceEventId: string, withFreeTickets: boolean = false): void {
    this.sendLoading(true);
    this.ticketHttpService
      .getAllByDanceEventId(danceEventId, withFreeTickets)
      .pipe(
        tap((result) => {
          this.sendTickets(result);
          this.sendLoading(false);
        }),
        catchError((err) => {
          console.log(err);
          this.sendLoading(false);
          throw err;
        })
      )
      .subscribe();
  }

  clear(): void {
    this.ticketSubject.next(null);
    this.ticketsSubject.next([]);
    this.sendLoading(false);
  }
}
