import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { CreateDanceManagerAppComponent } from './create-dance-manager-app.component';
import { AddImageModule, DfAddLocationsModule, DfQuillEditorModule, DfSharedButtonModule, GoogleAddressSearchAutocompleteModule, PhoneInputModule, SearchDanceStyleModule } from '@platri/dfx-angular-core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PaginatorModule } from 'primeng/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [CreateDanceManagerAppComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    AddImageModule,
    DfAddLocationsModule,
    DfQuillEditorModule,
    DfSharedButtonModule,
    GoogleAddressSearchAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    PaginatorModule,
    PhoneInputModule,
    ReactiveFormsModule,
    SearchDanceStyleModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    TranslocoModule
  ],
  exports: [
    CreateDanceManagerAppComponent,
  ]
})
export class CreateDanceManagerAppModule {
  constructor() {
    console.log('CreateDanceManagerAppModule initialized');
  }
}
