import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  DanceManagerUserGroupsInterface,
  emailRegex,
  UsersInterface
} from '@platri/df-common-core';
import { CreateFreeTicketRequestDto } from '@platri/df-nestjs-core';
import { TranslocoService } from '@jsverse/transloco';
import {
  DanceManagerHttpService,
  DanceSchoolStateService,
  UserHttpService,
  UserTicketStateService
} from '@platri/dfx-angular-core';

@Component({
  selector: 'df-add-free-tickets-dialog',
  templateUrl: './add-free-tickets-dialog.component.html',
  styleUrls: ['./add-free-tickets-dialog.component.scss'],
})
export class AddFreeTicketsDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  searchInputChangeSubject = new Subject<string>();
  showUserSearchLoadingSpinner = false;
  dfmUserGroups: DanceManagerUserGroupsInterface[];

  userOptions: any[] = [];
  selectedUsers: any[] = [];
  selectedEmails: string[] = [];

  eventId: string;

  emailInput: UntypedFormControl;
  selectedDfmUserGroups: UntypedFormControl;

  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly userTicketService: UserTicketStateService,
    private danceManagerHttpService: DanceManagerHttpService,
    private danceSchoolService: DanceSchoolStateService,
    private translocoService: TranslocoService,
    private dialogRef: MatDialogRef<AddFreeTicketsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.eventId = this.data.eventId;
    this.subscribeToSearch();
    this.initEmailForm();
    this.loadUserGroups();
  }

  loadUserGroups(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDmUserGroupsByDanceManagerId({danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id}).subscribe({
        next: (data) => {
          this.dfmUserGroups = data;
        }
      })
    );
  }

  onUserSelected($event: any): void {
    this.selectedUsers.push($event);
  }

  onEmailSelected(): void {
    if (this.emailInput.valid) {
      this.selectedEmails.push(this.emailInput.value);
      this.emailInput.reset();
    }
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }

  initEmailForm(): void {
    this.emailInput = new UntypedFormControl('', [
      Validators.email,
      Validators.pattern(emailRegex)
    ]);
    this.selectedDfmUserGroups = new UntypedFormControl([]);
  }

  getErrorMessage(): string {
    return this.emailInput.hasError('email') ? this.translocoService.translate('DANCE_MANAGER_EVENT.FREE_TICKET.EMAIL_INVALID') : '';
  }

  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject
        .pipe(
          tap(() => (this.showUserSearchLoadingSpinner = true)),
          debounceTime(600)
        )
        .subscribe((inputText: string) => {
          if (inputText) {
            this.subscriptions.add(
              this.userHttpService
                .searchUsersByKey(inputText)
                .pipe(
                  tap(() => {
                    this.showUserSearchLoadingSpinner = false;
                  })
                )
                .subscribe((users: UsersInterface[]) => {
                  this.userOptions = this.mapToOptions(users);
                })
            );
          } else {
            this.userOptions = [];
            this.showUserSearchLoadingSpinner = false;
          }
        })
    );
  }

  removeSelectedUser(selectedUser: UsersInterface): void {
    const index = this.selectedUsers.indexOf(selectedUser);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

  removeSelectedEmail(selectedEmail: string): void {
    const index = this.selectedEmails.indexOf(selectedEmail);
    if (index >= 0) {
      this.selectedEmails.splice(index, 1);
    }
  }

  private mapToOptions(users: UsersInterface[]) {
    return users.map((user) => ({
      id: user.id,
      value: user.username,
      data: user,
      displayValue: user.username,
      info: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null,
      imageUrl: user.imageUrl ?? null,
    }));
  }
  
  get isInviteDisabled(): boolean {
    return this.selectedUsers.length === 0 && this.selectedEmails.length === 0 && this.selectedDfmUserGroups.value?.length === 0;
  }
  
  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  invite(): void {
    if (this.selectedUsers || this.selectedDfmUserGroups.value?.leading > 0) {
      const freeTicketInvitations: CreateFreeTicketRequestDto = {
        userIds: [],
        emails: [],
        userGroupIds: []
      };
      for (const user of this.selectedUsers) {
        freeTicketInvitations.userIds.push(user.id);
      }
      for (const email of this.selectedEmails) {
        freeTicketInvitations.emails.push(email);
      }
      for (const userGroupId of this.selectedDfmUserGroups.value) {
        freeTicketInvitations.userGroupIds.push(userGroupId);
      }
      this.userTicketService.createFreeEventTickets(
        this.eventId,
        freeTicketInvitations
      );
      this.dialogRef.close();
    }
  }
}
