import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthStateService, DanceEventHttpService, DanceManagerPayoutHttpService, DanceSchoolStateService, dfAppRoute, dfLoginRoute, DfmStateService, TicketOrderHttpService, UserHttpService } from '@platri/dfx-angular-core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DanceEventsDto, DanceManagerPayoutDetailElementInterface, DanceManagerPayoutDetailInterface, UserDto, UsersInterface } from '@platri/df-common-core';
import { getSubscription } from '@platri/elab-angular-core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'dfm-payout-detail',
  templateUrl: './dfm-payout-detail-page.component.html',
  styleUrls: ['./dfm-payout-detail-page.component.scss']
})
export class DfmPayoutDetailPageComponent implements OnInit, OnDestroy{
  displayedColumns: string[] = ['amount', 'date', 'product', 'username'];

  danceManagerDetailPayout: DanceManagerPayoutDetailInterface;
  dataSource = new MatTableDataSource<DanceManagerPayoutDetailElementInterface>();
  loading = true;

  currentUser: UsersInterface;

  usersToLoad: any = {};
  danceEventsToLoad: any = {};

  loadedUsers: { [key: string]: UserDto } = {};
  loadedDanceEvents: { [key: string]: DanceEventsDto } = {};

  routerParams!: Params;
  danceManagerIdFromParam: string;
  payoutIdFromParam: string;

  subscriptions = new Subscription();

  constructor(private readonly danceSchoolService: DanceSchoolStateService, private readonly danceManagerPayoutHttpService: DanceManagerPayoutHttpService, private readonly authService: AuthStateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dfmStateService: DfmStateService,
              private breadcrumbService: BreadcrumbService,
              private ticketOrderHttpService: TicketOrderHttpService,
              private userHttpService: UserHttpService,
              private danceEventHttpService: DanceEventHttpService) {
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.getDanceEventFromRoute();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.dfmStateService.getAsyncSelectedDm().subscribe({
      next: (data) => {
        if (data) {
          this.danceManagerIdFromParam = data.id;
          this.payoutIdFromParam = this.routerParams['danceManagerPayoutId'];
          if (!this.danceManagerIdFromParam || !this.payoutIdFromParam) {
            this.router.navigate([dfAppRoute, dfLoginRoute]);
          } else {
            this.loadPayout();
          }
        }
      }
    });
  }

  loadPayout(): void {
   this.subscriptions.add(
     this.danceManagerPayoutHttpService.getByDanceManagerIdAndDanceManagerPayoutIdv2(this.danceManagerIdFromParam, this.payoutIdFromParam).subscribe({
       next: (data) => {
         this.danceManagerDetailPayout = data;
         this.loadTicketSummary();
       }
     })
   );
  }
  
  loadTicketSummary(): void {
    this.subscriptions.add(
      this.ticketOrderHttpService.getDetailsByStripePaymentIntentIds(this.danceManagerDetailPayout.stripePaymentIntentIds).subscribe({
        next: (data) => {
          this.dataSource.data = data;
          data.forEach((entry) => {
            if (entry.userId) {
              this.usersToLoad[entry.userId] = true;
            }
            if (entry.eventId) {
              this.danceEventsToLoad[entry.eventId] = true;
            }
          });
          this.loadUsers();
          this.loadDanceEvents();
          this.loading = false;
        }
      })
    );
  }
  
  loadUsers(): void {
    const userIds = [];

    for (const usersToLoadKey in this.usersToLoad) {
      userIds.push(usersToLoadKey);
    }
    
    if (userIds?.length > 0) {
      this.subscriptions.add(
        this.userHttpService.getUsersByIds(userIds).subscribe({
          next: (data) => {
            data.forEach((dto) => {
              this.loadedUsers[dto.id] = dto;
            });
            
          }
        })
      );
    }
  } 
  
  loadDanceEvents(): void {
    const danceEventIds = [];

    for (const danceEventsToLoadKey in this.danceEventsToLoad) {
      danceEventIds.push(danceEventsToLoadKey);
    }
    
    if (danceEventIds?.length > 0) {
      this.subscriptions.add(
        this.danceEventHttpService.getByIdsBulk(danceEventIds).subscribe({
          next: (data) => {
            data.forEach((dto) => {
              this.loadedDanceEvents[dto.id] = dto;
            });
          }
        })
      );
    }
  }
  
  downloadPayout(payoutId: string): void {
    const ticketWindow = window.open(); //OPEN WINDOW FIRST ON SUBMIT THEN POPULATE PDF
    ticketWindow!.document.write('Loading...');

    this.danceManagerPayoutHttpService.downloadPayout(this.danceSchoolService.getSyncCurrentDanceSchool().id!, payoutId).subscribe({
      next: (blob) => {
        const url = URL.createObjectURL(blob);
        ticketWindow!.location.href = url;
      },
      error: (err) => {
        console.error(err);
        ticketWindow!.close();
      }
    });
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goBack(): void {
    this.router.navigate(['./../'], {
      relativeTo: this.activatedRoute,
    });
  }

  getDanceEventFromRoute(): void {
    this.breadcrumbService.set('@id', 'GENERIC_WRAPPER.PAYOUT');
  }
}
