<ng-container *transloco="let t">
  <mat-toolbar style="padding: 0; background: none;">
    <div class="header-box">
      <button
        mat-stroked-button
        class="menu-button hide-when-large"
        [matMenuTriggerFor]="menu"
        (menuClosed)="toggleMenuIcon()"
        (menuOpened)="toggleMenuIcon()"
      >
        <mat-icon>{{ this.isMenuOpen ? 'close' : 'menu' }}</mat-icon>
      </button>
      <df-header-logo></df-header-logo>
      <button
        [routerLink]="['']"
        [routerLinkActive]="'active-link'"
        mat-icon-button
        style="align-self: center"
        [ngStyle]="{ opacity: this.isMenuOpen ? '0' : '1' }"
        class="border-radius-20 no-border hide-when-large"
        matTooltip="{{ t('GENERIC_WRAPPER.SEARCH') }}">
        <mat-icon>search</mat-icon>
      </button>
  
      <div class="flex-row align-center hide-when-small gap-10">
        <button
          mat-button
          class="hide-when-small"
          [routerLink]="dfCreateDanceManagerRoute"
          routerLinkActive="active-link"
        >
          <span>{{ t('DANCEFLAVORS_HEADER.CREATE_DANCE_MANAGER_GUEST') }}</span>
        </button>
        <button
          [routerLink]="['']"
          [routerLinkActive]="'active-link'"
          [routerLinkActiveOptions]="{ exact: false }"
          #searchRouterLinkActive="routerLinkActive"
          mat-icon-button
          class="hide-when-small"
          matTooltip="{{ t('GENERIC_WRAPPER.SEARCH') }}">
          <mat-icon style="color: #4D4D4D">search</mat-icon>
        </button>
        <df-shared-lib-header-language-selection class="hide-when-small"></df-shared-lib-header-language-selection>
        
        <df-header-user-button class="hide-when-small"></df-header-user-button>
      </div>
    </div>
  </mat-toolbar>
  
  <mat-menu #menu="matMenu" class="header-menu" [hasBackdrop]="false">
    <a class="flex-row start-center hide-when-large my2" mat-menu-item [routerLink]="['/']">
      <span class="fs16">{{ t('GENERIC_WRAPPER.HOME') }}</span>
    </a>
    <div class="px2">
      <mat-divider></mat-divider>
    </div>
    <button mat-menu-item (click)="routeToLogin()" class="my2">
      <span class="fs16">{{ t('GENERIC_WRAPPER.LOGIN') }}</span>
    </button>
    <div class="px2">
      <mat-divider></mat-divider>
    </div>
    <button mat-menu-item (click)="routeToRegister()" class="my2">
      <span class="fs16">{{ t('GENERIC_WRAPPER.REGISTER') }}</span>
    </button>
    <div class="px2">
      <mat-divider></mat-divider>
    </div>  
    <button mat-menu-item (click)="routeToImprint()" class="my2">
      <span class="fs16">{{ t('GENERIC_WRAPPER.IMPRINT') }}</span>
    </button>
    <div (click)="$event.stopPropagation()" class="my2">
      <df-shared-lib-header-language-selection></df-shared-lib-header-language-selection>
    </div>
  </mat-menu>
</ng-container>
