import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { GoogleSignInButtonComponent } from './google-sign-in-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [GoogleSignInButtonComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatCheckboxModule,
    GoogleSigninButtonModule,
    TranslocoModule
  ],
  providers: [],
  exports: [GoogleSignInButtonComponent]
})
export class GoogleSignInButtonModule { }
