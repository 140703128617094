import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnavailableForWebDialogComponent } from './unavailable-for-web-dialog.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [UnavailableForWebDialogComponent],
    imports: [CommonModule, MatIconModule, TranslocoDirective],

  exports: [UnavailableForWebDialogComponent],
})
export class UnavailableForWebDialogModule {}
