import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NotificationInterface,
  NotificationUseCaseEnum,
  NotificationUseCaseSpecificDataInterface
} from '@platri/df-common-core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

interface DmNotificationItemInterface {
  title: string;
  text: string;
  routerLink: string[];
  createdAt: Date;
  readAt: Date;
}

@Component({
  selector: 'df-shared-lib-dm-notification-item',
  templateUrl: './dm-notification-item.component.html',
  styleUrls: ['./dm-notification-item.component.scss'],
})
export class DmNotificationItemComponent implements OnInit {
  @Input() notification: NotificationInterface;
  @Output() notificationReadButtonClicked = new EventEmitter<void>();
  
  dmNotificationItem: DmNotificationItemInterface;
  
  constructor(
    private translocoService: TranslocoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mapToDmNotificationItem();
  }
  
  mapToDmNotificationItem(): void {
    switch (this.notification.notificationUseCase) {
      case NotificationUseCaseEnum.NEW_FOLLOW:
        this.mapFromNewFollowToDmNotificationItem();
        break;
      case NotificationUseCaseEnum.NEW_EVENT_CO_HOST_REQUEST:
        this.mapFromNewCoHostRequestToDmToUserNotificationItem();
        break;
      default:
        break;
    }
  }
  
  mapFromNewFollowToDmNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.dmNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      title: this.translocoService.translate('GENERIC_WRAPPER.NEW_FOLLOWER'),
      text: this.translocoService.translate('GENERIC_WRAPPER.HAS_STARTED_FOLLOWING', { username: useCaseSpecificData.fromTargetUserName }),
      routerLink: ['', '@' + useCaseSpecificData.fromTargetUserName]
    };
  }
  
  mapFromNewCoHostRequestToDmToUserNotificationItem(): void {
    const useCaseSpecificData = this.notification.useCaseSpecificData as NotificationUseCaseSpecificDataInterface;
    this.dmNotificationItem = {
      readAt: this.notification.readAt,
      createdAt: this.notification.createdAt,
      title: this.translocoService.translate('GENERIC_WRAPPER.NEW_CO_HOST_REQUEST_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.NEW_CO_HOST_REQUEST_TEXT', { danceManagerName: useCaseSpecificData.danceSchoolName, danceEventName: useCaseSpecificData.danceEventName }),
      routerLink: ['', 'dance-event', useCaseSpecificData.danceEventId]
    };
  }

  handleLinkClick(): void {
    this.router.navigate(this.dmNotificationItem.routerLink);
  }

  handleButtonClick(event: MouseEvent): void {
    event.stopPropagation();
    this.notificationReadButtonClicked.emit();
  }
}
