import { MessageInterface, MessageReactionEnum, MessageStatusEnum, MessageTypeEnum } from '@platri/df-common-chat-plug-play';

export class MessageDto implements MessageInterface {
  id: number;
  senderId: string;
  recipientId: string;
  recipientReaction: MessageReactionEnum;
  date: Date;
  value: string;
  status: MessageStatusEnum;
  type: MessageTypeEnum;
  repliedToMessageId?: number;
  repliedToMessage?: MessageDto;
  senderDeleted: boolean;
  recipientDeleted: boolean;
  
  constructor(args: MessageDto) {
    if (args) { 
      this.id = args.id;
      this.senderId = args.senderId;
      this.recipientId = args.recipientId;
      this.recipientReaction = args.recipientReaction;
      this.date = args.date;
      this.value = args.value;
      this.status = args.status;
      this.type = args.type;
      this.repliedToMessageId = args.repliedToMessageId;
      this.repliedToMessage = args.repliedToMessage;
      this.senderDeleted = args.senderDeleted;
      this.recipientDeleted = args.recipientDeleted;
    } 
  }
}
