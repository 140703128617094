<ng-container *transloco="let t">
  <div *ngIf="tickets?.length > 0" class="flex-column" style="gap: 2rem;">
    <div class="flex-row" style="gap: 0.5rem; padding: 0 8px">
      <div style="flex: 1 1 5%;"></div>
      <div class="flex-row-center-center ml1 bold" style="flex: 1 1 95%;">
        <span class="header-20">{{ t('DANCE_MANAGER_EVENT.TICKET_NAME') }}</span>
        <span class="header-20">{{ t('DANCE_MANAGER_EVENT.SOLD') }}</span>
        <span class="header-15">{{ t('DANCE_MANAGER_EVENT.TICKET_PRICE') }}</span>
        <span class="header-20">{{ t('DANCE_MANAGER_EVENT.SALE_START') }}</span>
        <span class="header-20">{{ t('DANCE_MANAGER_EVENT.SALE_END') }}</span>
        <div *ngIf="canCreateTickets" class="w-100" style="display: flex; justify-content: flex-end; align-items: flex-start;">
          <button class="btn-outlined" mat-button (click)="navigateToCreateTicketPage()">
            <span style="vertical-align: middle">{{ t('DANCE_MANAGER_EVENT.NEW_TICKET') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="pb2 ticket-list-container" cdkScrollable>
      <div class="flex-column-center-center w-100">
        <div cdkDropList class="flex-column ticket-list w-100" style="gap: 1rem;" (cdkDropListDropped)="onMouseDropEvent($event)">
          <mat-card *ngFor="let ticket of tickets; let i = index" style="flex-direction: row; box-sizing: border-box; display: flex; padding: 16px; gap: 0.5rem;" class="flex-row draggable-card mat-elevation-z0" cdkDrag>
            <div style="flex: 1 1 100%; max-width: 5%;" cdkDragHandle class="flex-column-center-center drag-and-drop-indicator">
              <mat-icon>drag_indicator</mat-icon>
            </div>
            <div class="flex-column gap-15 w-100">
                    <div class="flex-row" style="align-items: center;">
                      <div style="flex: 1 1 100%; max-width: 20%;" class="flex-row-start-center gap-10">
                        <span class="flex-row-center-center" [ngStyle]="{ color: ticket.ticketSaleStatus === 'ON_SALE' ? '#00B00C' : 'red' }">•</span>
                          <div class="ticket-name-container mr-20">
                            <span class="bold ">{{ ticket.name }}</span>
                          </div>
                      </div>
  
                      <span style="flex: 1 1 100%; max-width: 15%; align-items: center;" >{{ticket.ticketType === ticketTypeEnum.FREE ? ticket.quantity : ticket.soldQuantity + '/' + ticket.quantity }}</span>
                      <span style="flex: 1 1 100%; max-width: 15%;">{{ticket.ticketType === ticketTypeEnum.FREE ? '' : (ticket.price | centsToCurrencyString: 'EUR') }}</span>
                      <span style="flex: 1 1 100%; max-width: 20%;">{{ticket.saleStart && ticket.ticketType !== ticketTypeEnum.FREE ? (ticket.saleStart | dateTimezoned : 'UTC' : currentLang : ticket.eventDuration : true : false : 'shortDate') : '' }}</span>
                      <span style="flex: 1 1 100%; max-width: 20%;">{{ticket.saleEnd && ticket.ticketType !== ticketTypeEnum.FREE ? (ticket.saleEnd | dateTimezoned : 'UTC' : currentLang : ticket.eventDuration : true : false : 'shortDate') : '' }}</span>
                      <div *ngIf="ticket.ticketType === ticketTypeEnum.PAID" style="margin-left: auto">
                        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="navigateToEditTicketPage(ticket.id)">
                            <span>{{ t('GENERIC_WRAPPER.EDIT') }}</span>
                          </button>
                          <button mat-menu-item (click)="navigateToCreateTicketPage(ticket)">
                            <span>{{ t('GENERIC_WRAPPER.DUPLICATE') }}</span>
                          </button>
                          <button mat-menu-item (click)="deleteTicket(ticket)">
                            <span>{{ t('GENERIC_WRAPPER.DELETE') }}</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="tickets?.length === 0" class="flex-column-center-center gap-20 h-100" style="margin-top: 15%; margin-bottom: 15%; overflow: hidden;">
    <div class="flex-row-center-center circle">
      <img height="174" src="assets/images/no-tickets.png" />
    </div>
    <div class="flex-column-center-center gap-10">
      <span class="no-tickets-title">{{ t('DANCE_MANAGER_EVENT.NO_TICKETS') }}</span>
      <span class="px3 no-tickets-text">{{ t('DANCE_MANAGER_EVENT.NO_TICKETS_TEXT') }}</span>
    </div>
    <div *ngIf="canCreateTickets">
      <button class="btn-inlined" mat-button (click)="navigateToCreateTicketPage()">
        {{ t('DANCE_MANAGER_EVENT.CREATE_TICKET') }}
      </button>
    </div>
  </div>
</ng-container>
