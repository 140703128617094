<div  *ngIf="control">
  <mat-form-field [appearance]="appearance">
    <mat-label>{{label}}*</mat-label>
    <button type="button" mat-icon-button matPrefix (click)="input.stepDown(); setFormControlValue()" *ngIf="!disabled">
      <mat-icon style="padding-top: 4px;">remove</mat-icon>
    </button>
    
    <div class="align-center">
      <span matPrefix *ngIf="prefix" [ngClass]="disabled ? 'disabled' : ''">{{prefix}}</span>
      <input dfNoScroll type="number" matInput [formControl]="control" #input [placeholder]="placeholder" [autocomplete]="autocomplete" class="matInput">
      <span matSuffix *ngIf="suffix" [ngClass]="disabled ? 'disabled' : ''">{{suffix}}</span>
    </div>

    <button type="button" mat-icon-button matSuffix (click)="input.stepUp(); setFormControlValue(true)" *ngIf="!disabled">
      <mat-icon style="padding-top: 4px;">add</mat-icon>
    </button>

    <mat-error *ngIf="control.hasError('onlyNumbers')">{{ 'Invalid input' }}</mat-error>
    <mat-error *ngIf="control.hasError('required')">{{errorRequired}}</mat-error>
    <mat-error *ngIf="control.hasError('min')">{{errorMin}}</mat-error>
    <mat-error *ngIf="control.hasError('max')">{{errorMax}}</mat-error>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  </mat-form-field>
</div>
