import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'status-info-dialog',
  templateUrl: 'status-info-dialog.component.html',
  styleUrls: ['./status-info-dialog.component.scss'],
})
export class StatusInfoDialogComponent {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  constructor(
    public dialogRef: MatDialogRef<StatusInfoDialogComponent>
  ) // @Inject(MAT_DIALOG_DATA)
  // public data: {
  //   title: string;
  //   subtitle: string;
  //   infoText: string;
  //   cancelText: string;
  // }
  {}

  close(): void {
    this.dialogRef.close();
  }
}
