import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { DfDanceStyleEditComponent } from './df-dance-style-edit.component';
import { DfDanceStyleSearchInputModule } from '../df-dance-style-search-input';
import { DfSharedButtonModule } from '../buttons';
import { SliderGradientModule } from '../slider-gradient';
import { DfDanceStyleEditItemModule } from '../df-dance-style-edit-item';
import { PipesModule } from "../../pipes";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfDanceStyleEditComponent],
    imports: [
      CommonModule,
      SliderGradientModule,
      MatExpansionModule,
      MatIconModule,
      DfDanceStyleEditItemModule,
      MatDividerModule,
      DfDanceStyleSearchInputModule,
      DfSharedButtonModule,
      PipesModule,
      TranslocoDirective,
    ],
  exports: [DfDanceStyleEditComponent],
})
export class DfDanceStyleEditModule {}
