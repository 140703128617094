export enum MicroservicesEnum {
  ADMINISTRATION = 'ADMINISTRATION',
  AUTH = 'AUTH',
  COURSE = 'COURSE',
  DANCE_EVENT = 'DANCE_EVENT',
  DANCE_SCHOOL = 'DANCE_SCHOOL',
  FOLLOW = 'FOLLOW',
  MESSAGE = 'MESSAGE',
  SEARCH_POSTING = 'SEARCH_POSTING',
  TICKET = 'TICKET',
  USER = 'USER',
}
