<ng-container *transloco="let t">
  <div class="w-100 flex-column" style="gap: 1rem;">
    <div class="flex-row align-space-between-center mt2">
<!--      <span class="page-title">{{'PASS_DETAILS.SALES' | translate}} ({{getNumberOfPaidPasses()}})</span>-->
      <div>
        <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.PASS_DETAILS.SEND_PASS')" [materialButtonStyleType]="'flat'" (buttonClicked)="openFreePassInviteDialog()"></df-shared-lib-button>
      </div>
    </div>
    <div class="w-100 flex-row" style="padding-top: 10px;">
        <mat-chip
          (click)="toggleChip(0)"
          [class]="this.activeChipIndex === 0 ? 'active-chip' : ''"
          style="margin-right: 10px;"
        >
          {{ t('GENERIC_WRAPPER.PASS_DETAILS.ACTIVE') }}
        </mat-chip>
        <mat-chip
          (click)="toggleChip(1)"
          [class]="this.activeChipIndex === 1 ? 'active-chip' : ''"
        >
          {{ t('GENERIC_WRAPPER.PASS_DETAILS.USED') }}
        </mat-chip>
    </div>
    <table *ngIf="isInitialized" mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.USER') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center">
            <a class="align-center">
              <img class="circle-icon mr1" [src]="loadedUsers[element.userId]?.imageUrl && loadedUsers[element.userId]?.imageUrl !== '' ? loadedUsers[element.userId]?.imageUrl: DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
            </a>
            <a *ngIf="loadedUsers[element.userId]" [href]="'/app/@' + loadedUsers[element.userId]?.username">
              {{ loadedUsers[element.userId]?.firstName && loadedUsers[element.userId]?.lastName ? loadedUsers[element.userId]?.firstName + ' ' + loadedUsers[element.userId]?.lastName + ' (@' + loadedUsers[element.userId]?.username + ')' : loadedUsers[element.userId]?.username}}
            </a>
            <a *ngIf="!loadedUsers[element.userId]" [href]="'mailto:' + element.email">
              {{ element.email + ' (' + (t('GENERIC_WRAPPER.GUEST')) + ')'}}
            </a>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="passType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.PASS_DETAILS.PASS_TYPE') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="w-100 flex-column">
            <span *ngFor="let userPass of element.userPasses">
              {{userPass.pass.trial ? (t('GENERIC_WRAPPER.PASS_DETAILS.TRIAL_PASS')) : userPass.status === UserPassStatusEnum.FREE ? 'Freepass' : (t('GENERIC_WRAPPER.PASS_DETAILS.CHARGEABLE'))}}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="stamps">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.STAMPS') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="w-100 flex-column">
            <span *ngFor="let userPass of element.userPasses">
              {{userPass.pass.usageCount !== null ? userPass.userPassScans.length + '/' + userPass.pass.usageCount : (t('GENERIC_WRAPPER.PASS_DETAILS.UNLIMITED'))}}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="validUntil">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.VALID_TILL') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="w-100 flex-column">
            <span *ngFor="let userPass of element.userPasses">{{userPass.validUntil ? (userPass.validUntil | date: 'mediumDate':'UTC') : '-'}}</span>
          </div>
        </td>
      </ng-container>
<!--      <ng-container matColumnDef="stamps">-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Stempel</th>-->
<!--        <td mat-cell *matCellDef="let element" class="w-100">-->
<!--          <table class="mt-10 mb-10">-->
<!--            <thead>-->
<!--              <tr>-->
<!--                <th>Passart</th>-->
<!--                <th>Anzahl/Gültigkeit</th>-->
<!--              </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--              <tr *ngFor="let userPass of element.userPasses">-->
<!--                <td>{{userPass.status === userPassStatusEnum.FREE ? 'Freepass' : 'Kostenpflichtig'}}</td>-->
<!--                <td>{{userPass.userPassScans.length}}/{{userPass.pass.usageCount ?? userPass.userPassScans.length}}</td>-->
<!--              </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </td> -->
<!--      </ng-container>-->
      <ng-container matColumnDef="openChat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"></th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-column align-center">
            <div>
              <mat-icon *ngIf="element.userId" class="open-chat-icon" (click)="openChat(loadedUsers[element.userId]?.id)">chat_bubble_outline</mat-icon>
              <a [href]="'mailto:' + element.email">
                <mat-icon *ngIf="element.email" fontSet="material-icons-outlined">mail</mat-icon>
              </a>
            </div>
          </div>
        </td> 
      </ng-container>
<!--      -->
<!--      <ng-container matColumnDef="danceStyles">-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ 'PACKAGES.COURSES.TABLE.DANCESTYLES'| translate }}</th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--            <ng-container *ngFor="let danceStyle of element.danceStyles; let last = last">-->
<!--                {{ danceStyle.translationKey | titlecase }}-->
<!--              {{ !last ? ', ' : '' }}-->
<!--            </ng-container>-->
<!--        </td>-->
<!--      </ng-container>-->
<!--      -->
<!--      <ng-container matColumnDef="difficultyRange">-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ 'PACKAGES.COURSES.TABLE.LEVEL'| translate }}</th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--            {{ element.startDifficulty | skillLevelTranslation | translate}}-->
<!--          - -->
<!--          {{ element.endDifficulty | skillLevelTranslation | translate}}-->
<!--        </td>-->
<!--      </ng-container>-->
<!--      -->
<!--      &lt;!&ndash; <ng-container matColumnDef="teacher">-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ 'PACKAGES.COURSES.TABLE.TEACHER'| translate }}</th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--          <div>-->
<!--             {{ element.teacherId }}-->
<!--          </div>-->
<!--        </td>-->
<!--      </ng-container> &ndash;&gt;-->

<!--      <ng-container matColumnDef="nextAppointment">-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ 'PACKAGES.COURSES.TABLE.APPOINTMENT'| translate }} </th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--          <div>-->
<!--            {{ element.nextAppointmentDate ? (element.nextAppointmentDate | date:'dd.MM.yyyy') : '-' }}-->
<!--          </div>-->
<!--        </td>-->
<!--      </ng-container>-->
<!--      -->
<!--      <ng-container matColumnDef="options">-->
<!--        <th mat-header-cell *matHeaderCellDef scope="col"></th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--          <div class="pr2 flex-row align-center-end">-->
<!--            <button mat-icon-button (click)="editDanceCourse(element)" matTooltip="{{ 'ADMINISTRATION.MANAGE_TEAM.EDIT' | translate }}">-->
<!--              <mat-icon>edit</mat-icon>-->
<!--            </button>-->
<!--            <button mat-icon-button (click)="$event.stopPropagation(); deleteDanceCourse(element)" matTooltip="{{ 'ADMINISTRATION.MANAGE_TEAM.REMOVE' | translate }}">-->
<!--              <mat-icon>delete</mat-icon>-->
<!--            </button>-->
<!--          </div>-->
<!--        </td>-->
<!--      </ng-container>-->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
