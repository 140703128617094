import { TimeRangeInterface } from './time-range.interface';

export interface OpeningHoursInterface {
  monday?: TimeRangeInterface[];
  tuesday?: TimeRangeInterface[];
  wednesday?: TimeRangeInterface[];
  thursday?: TimeRangeInterface[];
  friday?: TimeRangeInterface[];
  saturday?: TimeRangeInterface[];
  sunday?: TimeRangeInterface[];
}
