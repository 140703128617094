import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";
import {startOfDay, isEqual, differenceInDays, addDays} from "date-fns";

@Pipe({
  name: 'formatDay',
})
export class FormatDayPipe implements PipeTransform {

  constructor(private readonly datePipe: DatePipe) {
  }
  transform(date: Date): string {
    if (date === null) {
      return null;
    }

    if(isEqual(startOfDay(date), startOfDay(new Date()))) {
      return 'TODAY';
    } else if (isEqual(startOfDay(date), addDays(startOfDay(new Date()), 1))) {
      return 'TOMORROW';
    } else if (differenceInDays(startOfDay(date), startOfDay(new Date())) < 7) {
      return this.datePipe.transform(date, 'EEEE');
    } else {
      return this.datePipe.transform(date, 'shortDate');
    }
  }
}
