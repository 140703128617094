import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthStateService, ConfirmationDialogComponent, CoursesHttpService, DanceManagerHttpService, DelayDialogComponent, dfAppRoute, getSubscription, UserPassHttpService, UserPassSubscriptionHttpService } from '@platri/dfx-angular-core';
import {
  CourseInterface,
  CourseStatusEnum,
  DanceSchoolInterface,
  DelayDialogInterface,
  UserPassInterface,
  UserPassScanStatusEnum,
  UserPassStatusEnum,
  UserPassSubscriptionInterface,
  UserPassSubscriptionStatusEnum,
  UsersInterface
} from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DateTime, Interval } from 'luxon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-inventory-subscription-detail',
  templateUrl: './inventory-subscription-detail.page.html',
  styleUrls: ['./inventory-subscription-detail.page.scss']
})
export class InventorySubscriptionDetailPage implements OnInit, OnDestroy {
  currentUser: UsersInterface;
  userPassSubscription: UserPassSubscriptionInterface;
  currentUserPass: UserPassInterface = null;
  currentPassOrganizer: DanceSchoolInterface = null;
  activePassCourses: CourseInterface[] = [];
  shownStamps: number[] = [];

  routerParams!: Params;
  subscriptionIdFromParam: string;
  isLoading = false;
  hasFailedLoadingNoConnection = false;

  hasTruncateDescription = false;
  isDescriptionTruncated = false;
  descriptionTruncateLength = 20;

  hasTruncateCourses = false;
  isCoursesTruncated = false;
  coursesTruncateLength = 3;
  
  dateNextMonday = DateTime.now().setZone('UTC').startOf('week').plus({week: 1}).toJSDate();
  
  subscriptions = new Subscription();
  
  userPassStatusEnum = UserPassStatusEnum;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthStateService,
    private userPassHttpService: UserPassHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private coursesHttpService: CoursesHttpService,
    private matDialog: MatDialog,
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private readonly translocoService: TranslocoService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.subscriptionIdFromParam = this.routerParams['subscriptionId'];
    if (!this.subscriptionIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoading = true;
    this.hasFailedLoadingNoConnection = false;
          
    this.subscriptions.add(
      this.userPassHttpService.getUserPassSubscriptionUserPassById(this.subscriptionIdFromParam).subscribe(userPass => {
        if (!userPass) {
          this.router.navigate(['/inventory']);
        }
        this.currentUserPass = userPass;
        this.userPassSubscription = userPass.userPassSubscription;
        this.createStampsShownArray();
        this.loadOrganizer();
        
        const targets = this.userPassSubscription.targets;
        const targetIds: string[] = Array.from(new Set(targets?.map(pt => pt.targetId) ?? []));
        this.loadLinkedCourses(targetIds);
        
        this.hasTruncateDescription = this.userPassSubscription?.description?.split(' ').length > this.descriptionTruncateLength;
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
        this.router.navigate(['/inventory']);
      })
    );
  }

  createStampsShownArray(): void {
    const usageCount: number = this.currentUserPass.userPassSubscription.weeklyUsage;
    const usedCount: number = this.currentUserPass.userPassScans.length;

    const displayCount = usageCount > 10 ? 10 : usageCount;
    let start = 0;
    if (usedCount > 5) {
      start = usedCount - (usedCount % 5);
    }
    if (start + 10 > usageCount) {
      start = usageCount - 10;
    }
    start = Math.max(start, 0);
    for (let i = 0; i < displayCount; i++) {
      this.shownStamps[i] = start + 1 + i;
    }
  }
  
  loadOrganizer(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceManagerById(this.userPassSubscription.danceManagerId).subscribe(organizer => {
        if (!organizer) {
          this.router.navigate(['/inventory']);
        }
        this.currentPassOrganizer = organizer;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
      })
    );
  }
  
  loadLinkedCourses(targetIds: string[]): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(targetIds).subscribe(courses => {
        if (courses.length === 0) {
          this.router.navigate(['/inventory']);
        }
        this.activePassCourses = courses;
        this.hasTruncateCourses = this.activePassCourses.length > this.coursesTruncateLength;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error.status === 0) {
          this.hasFailedLoadingNoConnection = true;
        }
      })
    );
  }
  
  onBackButtonClick(): void {
    this.router.navigate(['inventory'], {fragment: 'subscriptions'});
  }

  // routeToPassBuyout(): void {
  //   const createTicketOrderDto: CreateTicketOrderRequestDto = {
  //     passOrderItems: [],
  //     ticketOrderItems: []
  //   };
  //
  //   createTicketOrderDto.passOrderItems.push({
  //     passId: this.pass.id,
  //     quantity: 1
  //   });
  //  
  //   this.ticketOrderService.createTicketOrder(createTicketOrderDto, this.pass.passTargets[0].targetId);
  // }

  openStripeBillingPortalSession(): void {
    this.userPassSubscriptionHttpService.getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser().subscribe({
      next: (url) => {
        if (url !== null) {
          const delayDialogData: DelayDialogInterface = {
            delayTime: 3000,
            title: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.STARTING_SOON',
            text: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.HAS_SUBSCRIPTION'
          };
          const dialogRef = this.matDialog.open(DelayDialogComponent, {
            maxWidth: '400px',
            data: delayDialogData
          });
          dialogRef.afterClosed().subscribe(confirm => {
            if (confirm) {
              window.open(url, '_blank');
            }
          });
        } else {
          // TODO: Show dialog that the user has no subscriptions yet
        }
      }
    });
  }

  openRefundPassDialog(): void {
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: {
          cancelButtonColor: '',
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.NO'),
          submitButtonColor: 'warn',
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.YES'),
          text: this.translocoService.translate('USER.WITHDRAWAL_ORDER_DIALOG_TEXT')
        }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.userPassSubscriptionHttpService.refundById(this.currentUserPass.userPassSubscription.id)
            .subscribe({
              next: () => {
                this.router.navigate(['/inventory'], {fragment: 'subscriptions'});
                this.matSnackBar.open(this.translocoService.translate('USER.WITHDRAWAL_ORDER_SUCCESS_TEXT'));
              },
              error: () => {
                this.matSnackBar.open(this.translocoService.translate('USER.WITHDRAWAL_ORDER_ERROR_TEXT'));
              }
            });
        }
      });
  }

  canBeWithdrawn(): boolean {
    const dateTimeNow = DateTime.now().setZone('UTC');
    const dateTimePurchase = DateTime.fromJSDate(this.currentUserPass.createdAt).setZone('UTC');
    const diffInDays = Interval.fromDateTimes(dateTimePurchase, dateTimeNow).length('day');
    if (diffInDays >= 14) {
      return false;
    }
    return this.currentUserPass?.userPassScans?.length === 0;
  }
  
  protected readonly CourseStatusEnum = CourseStatusEnum;
  protected readonly UserPassStatusEnum = UserPassStatusEnum;
  protected readonly userPassSubscriptionStatusEnum = UserPassSubscriptionStatusEnum;

  get terminatedPaymentsLeft(): boolean {
    const subscription = this.currentUserPass.userPassSubscription;
    return (
      subscription.status === this.userPassSubscriptionStatusEnum.TERMINATED_PERIOD_END &&
      new Date(subscription.currentPeriodEndAt).toDateString() !== new Date(subscription.endedAt).toDateString()
    );
  }
  
  get canBeCancelled(): boolean {
    const status = this.currentUserPass.userPassSubscription.status;
    return (
      status !== this.userPassSubscriptionStatusEnum.TERMINATED_PERIOD_END &&
      status !== this.userPassSubscriptionStatusEnum.TERMINATED &&
      status !== this.userPassSubscriptionStatusEnum.REFUND
    );
  }
  
  cancelSubscription(): void {
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: {
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.NO'),
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.YES'),
          title: this.translocoService.translate('USER.CANCEL_SUBSCRIPTION_DIALOG_TITLE'),
          text: this.translocoService.translate('USER.CANCEL_SUBSCRIPTION_DIALOG_TEXT'),
        }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.userPassSubscriptionHttpService.cancelById(this.currentUserPass.userPassSubscription.id)
            .subscribe({
              next: () => {
                window.location.reload();
                this.matSnackBar.open(this.translocoService.translate('USER.CANCEL_SUBSCRIPTION_SUCCESS_TEXT'));
              },
              error: () => {
                this.matSnackBar.open(this.translocoService.translate('USER.CANCEL_SUBSCRIPTION_ERROR_TEXT'));
              }
            });
        }
      });
  }

  get canBeReactivated(): boolean {
    const userPassSubscription = this.currentUserPass.userPassSubscription;
    return (
      userPassSubscription.status === this.userPassSubscriptionStatusEnum.TERMINATED_PERIOD_END && !userPassSubscription.priceAccepted
    );
  }
  
  reactivateSubscription(): void {
    const userPassSubscription = this.currentUserPass.userPassSubscription;
    if(userPassSubscription?.newPrice && !userPassSubscription?.priceAccepted) {
      this.router.navigate(['price-update-subscription', this.currentUserPass.userPassSubscription.id]);
    } else {
      this.matDialog
        .open(ConfirmationDialogComponent, {
          data: {
            cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.NO'),
            submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.YES'),
            text: this.translocoService.translate('USER.REACTIVATE_SUBSCRIPTION_DIALOG_TEXT'),
          }
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.userPassSubscriptionHttpService.reactivateById(userPassSubscription.id)
              .subscribe({
                next: () => {
                  window.location.reload();
                  this.matSnackBar.open(this.translocoService.translate('USER.REACTIVATE_SUBSCRIPTION_SUCCESS_TEXT'));
                },
                error: () => {
                  this.matSnackBar.open(this.translocoService.translate('USER.REACTIVATE_SUBSCRIPTION_ERROR_TEXT'));
                }
              });
          }
        });
    }
  }
  
  numberOfScans(): number {
    return this.currentUserPass?.userPassScans?.filter((scan) => scan.status === UserPassScanStatusEnum.FINAL).length;
  }
}
