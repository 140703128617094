import { UserAppLangEnum } from '../enums';
import { CONTACT_URL_DE, CONTACT_URL_EN } from '../constants';

export function getContactUrl(appLang: UserAppLangEnum): string {
  switch (appLang) {
    case UserAppLangEnum.EN:
      return CONTACT_URL_EN;
    case UserAppLangEnum.DE:
    default:
      return CONTACT_URL_DE;
  }
}
