<ng-container *transloco="let t">
  <div class="flex-column align-center" [formGroup]="createLocationFormGroup">
    <div class="dfm-create-location-page-container w-100 flex-column">
      <div class="flex-row">
        <mat-card class="dfm-create-location-page-card-container round-corners mat-elevation-z3 mt2 mb3 w-100 no-shadow-mat-card">
          <div class="p-40">
            <div class="dfm-create-location-page-header">
              <span>{{ t('GENERIC_WRAPPER.LOCATION_TITLE_CREATE') }}</span>
            </div>
            <mat-divider class="mt-20 mb-40"></mat-divider>
            <div class="flex-column dfm-create-location-page-content">
              <div class="dfm-create-location-name-container">
                <span>{{ t('GENERIC_WRAPPER.LOCATION_NAME') }}</span>
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>{{ t('GENERIC_WRAPPER.LOCATION_NAME') }}</mat-label>
                  <input
                    matInput
                    formControlName="name"
                    autocomplete="off"
                    #name
                    id="create-location-name-input"
                    [placeholder]="t('GENERIC_WRAPPER.LOCATION_NAME')"
                  />
                  <mat-hint align="end">{{ name.value.length }} / 50</mat-hint>
                  <mat-error *ngIf="createLocationFormGroup.controls.name.hasError('required')">
                    {{ t('GENERIC_WRAPPER.NAME_IS_REQUIRED') }}
                  </mat-error>
                  <mat-error
                    *ngIf="createLocationFormGroup.controls.name.hasError('maxlength')"
                    class="errorTextSpaceBetween"
                  >
                    <div>
                      {{ t('GENERIC_WRAPPER.NAME_ERROR_MAX_LENGTH') }}
                    </div>
                    <div style="margin-left: auto">
                      {{ createLocationFormGroup.controls.name.errors?.maxlength.actualLength }} /
                      {{ createLocationFormGroup.controls.name.errors?.maxlength.requiredLength }}
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="dfm-create-location-address-container">
                <span>{{ t('GENERIC_WRAPPER.ADDRESS') }}</span>
                <df-shared-lib-google-address-search-autocomplete 
                  class="w-100"
                  [touched]="isAddressEmptyOnSubmit" 
                  (addressChanging)="setSelectedAddress()" 
                  (addressSelected)="setSelectedAddress($event)" 
                  [types]="['address']"
                ></df-shared-lib-google-address-search-autocomplete>
                <mat-radio-group class="flex-column" formControlName="isPublic" #isPublic >
                  <mat-radio-button class="mb1" color="primary" [value]="true" [checked]="true">{{ t('GENERIC_WRAPPER.PERMANENT_LOCATION') }}</mat-radio-button>
                  <mat-radio-button color="primary" [value]="false">{{ t('GENERIC_WRAPPER.TEMPORARY_LOCATION') }}</mat-radio-button>
                </mat-radio-group>
              </div>
              
              <div class="dfm-create-location-opening-hours-container">
                <div [class]="createLocationFormGroup.controls.openingHours.value === null ? 'dfm-create-location-opening-hours-header-column' : 'dfm-create-location-opening-hours-header-row'">
                  <span>{{ t('DANCE_MANAGER_LOCATION.OPENING_HOURS_TITLE') }}</span>
                  <span class="blue-clickable-span" (click)="openHoursDialog()">{{createLocationFormGroup.controls.openingHours.value === null ? (t('DANCE_MANAGER_LOCATION.OPENING_HOURS_DESCRIPTION')) : (t('GENERIC_WRAPPER.CHANGE') | titlecase)}}</span>
                </div>
                <div *ngIf="createLocationFormGroup.controls.showOpeningHours.value" class="dfm-create-location-opening-hours-display-container">
                  <ng-container *ngFor="let option of openingHoursArray">
                    <div class="dfm-create-location-opening-hours-display-container-item">
                      <span>{{ (t('GENERIC_WRAPPER.WEEKDAYS.FULL.' + option.key | uppercase)) }}</span>
                      <div class="flex-column">
                        <span *ngIf="option.value.length === 0; else timesContainer">
                              {{ t('GENERIC_WRAPPER.CLOSED') }}
                        </span>
                        <ng-template #timesContainer>
                          <span *ngFor="let times of option.value">
                              {{ times | timeRange : currentLang }}
                          </span>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="dfm-create-location-phone-number-container">
                <df-monorepo-phone-input
                  [formGroup]="createLocationFormGroup"
                  [showQuickLinkAddPhone]="true"
                  [showSuffix]="false"
                  [quickLinkPhonePrefix]="danceManager.phonePrefix"
                  [quickLinkPhoneNumber]="danceManager.phoneNumber"
                  [quickLinkPhonePrefixAfter]="danceManager.phonePrefixAfter"
                ></df-monorepo-phone-input>
              </div>
              <div class="dfm-create-location-email-container">
                <div class="dfm-create-location-email-header">
                  <span>{{ t('GENERIC_WRAPPER.EMAIL') }}</span>
                  <span class="blue-clickable-span" (click)="onEmailLinkTextClick()">{{ t('DANCE_MANAGER_LOCATION.EMAIL_DESCRIPTION') }}{{ danceManager?.email }}</span>
                </div>
                <mat-form-field
                  class="mt2 w-100"
                  appearance="outline"
                >
                  <mat-label>{{ t('GENERIC_WRAPPER.EMAIL') }}</mat-label>
                  <input class="w-100" matInput type="text" formControlName="email" #email />
                </mat-form-field>
              </div>
              <div class="dfm-create-location-amenity-container">
                <df-monorepo-amenities
                  class="w-100"
                  [amenitiesTypeRoom]="false"
                  (updateData)="onChangeAmenities($event)"
                ></df-monorepo-amenities>
              </div>
              <div class="dfm-create-location-button-container">
                <df-shared-lib-button 
                  [isDisabled]="isWaitingForCreateLocationResponse" 
                  [loading]="isWaitingForCreateLocationResponse" 
                  [materialButtonStyleType]="'outlined'" 
                  [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" 
                  (buttonClicked)="onCancel()"
                  class="w-100-lt-sm"
                ></df-shared-lib-button>
                <df-shared-lib-button 
                  [isDisabled]="isWaitingForCreateLocationResponse" 
                  [loading]="isWaitingForCreateLocationResponse" 
                  [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" 
                  (buttonClicked)="onSubmit()"
                  class="w-100-lt-sm"
                  id="location-save-button"
                ></df-shared-lib-button>
              </div>
              <div *ngIf="createFailedNoConnection" style="height: 20px; align-self: center;">
                <p class="dfm-create-location-no-connection-error">{{ t('DANCE_MANAGER_LOCATION.NO_CONNECTION_TRY_LATER') }}</p>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
