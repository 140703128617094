import { Component, OnInit } from '@angular/core';
import { DanceManagerUserInviteGenerateTokenResponseInterface, DanceManagerUserRoleEnum, environmentForWeb } from '@platri/df-common-core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DanceManagerHttpService, DanceSchoolStateService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

enum UserInviteTypeEnum {
  EMAIL = 'EMAIL',
  LINK = 'LINK'
}

@Component({
  selector: 'elab-dm-user-invite-dialog',
  templateUrl: './dance-manager-user-invite-dialog.component.html',
  styleUrls: ['./dance-manager-user-invite-dialog.component.scss'],
})
export class DanceManagerUserInviteDialogComponent implements OnInit {

  form: FormGroup;
  loading = false;
  link = '';
  
  protected readonly DanceSchoolRolesEnum = DanceManagerUserRoleEnum;
  protected readonly UserInviteTypeEnum = UserInviteTypeEnum;

  constructor(
    private readonly formBuilder: FormBuilder,
    public readonly matDialogRef: MatDialogRef<DanceManagerUserInviteDialogComponent>,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService
  ) {}
  
  get type(): AbstractControl {
    return this.form.get('type');
  }
  
  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      email: [null, Validators.required],
      role: [DanceManagerUserRoleEnum.ADMIN, Validators.required],
      type: [UserInviteTypeEnum.EMAIL]
    });
    
    this.form.get('type').valueChanges.subscribe((type) => {
      if (type === UserInviteTypeEnum.EMAIL) {
        this.form.get('email').addValidators(Validators.required);
        this.form.get('email').reset();
      } else {
        this.form.get('email').removeValidators(Validators.required);
        
      }
      this.form.get('email').reset();
      this.form.updateValueAndValidity();
    });
  }
  
  submit(): void {
    if (this.form.valid) {
      const data = this.form.getRawValue();
      if (data.type === UserInviteTypeEnum.EMAIL) {
        this.sendUserInviteEmail(data.email, data.role);
      } else {
        this.requestUserInviteToken(data.email, data.role);
      }
    }
  }
  
  sendUserInviteEmail(email: string, role: DanceManagerUserRoleEnum): void {
    this.loading = true;
    this.danceManagerHttpService.userInviteEmail(
      // @ts-ignore
      this.danceSchoolService.getSyncCurrentDanceSchool().id, { email, role }).subscribe({
      next: () => {
        this.loading = false;
        this.matDialogRef.close();
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.INVITATION_SENT_SUCCESSFULLY'));
      },
      error: (err) => {
        this.loading = false;
        this.matDialogRef.close();
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SOMETHING_WENT_WRONG_TRY_LATER_AGAIN'));
      }
    });
  }
  
  requestUserInviteToken(email: string, role: DanceManagerUserRoleEnum): void {
    this.loading = true;
    this.danceManagerHttpService.requestUserInviteToken(
      // @ts-ignore
      this.danceSchoolService.getSyncCurrentDanceSchool().id, { email, role }).subscribe({
      next: (data: DanceManagerUserInviteGenerateTokenResponseInterface) => {
        this.loading = false;
        this.link = this.createUserInviteLinkByToken(data.token);
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }
  
  createUserInviteLinkByToken(token: string): string {
    return `${environmentForWeb.host}/invite-to-dm?token=${token}`;
  }

}
