import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, Subscription, tap } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DmOnboardingRequiredDialogComponent } from '../dm-onboarding-required-dialog';
import { DmUpgradeRequiredDialogComponent } from '../dm-upgrade-required-dialog';
import {
  ActivatePackageErrorEnum,
  ConfirmationDialogInterface,
  DanceManagerFeatureInterface,
  DanceManagerFeatureNameEnum,
  DanceManagerSubscriptionTypeEnum,
  DanceSchoolInterface,
  DeactivateEventPackageErrorEnum,
  DeactivateEventTicketsPackageErrorEnum,
  environmentForWeb
} from '@platri/df-common-core';
import {
  DanceManagerHttpService,
  DanceManagerService,
  DfmStateService
} from '../../services';
import { getSubscription } from '../../helpers';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dm-packages-card-list',
  templateUrl: './dm-packages-card-list.component.html',
  styleUrls: ['./dm-packages-card-list.component.scss'],
})
export class DmPackagesCardListComponent implements OnInit, OnDestroy {
  danceManager: DanceSchoolInterface;
  isFeatureEventActive = false;
  isFeatureEventTicketsActive = false;
  isFeatureCoursesActive = false;
  isFeatureCoursePassesActive = false;
  isFeatureCourseSubscriptionActive = false;

  danceManagerFeatureName = DanceManagerFeatureNameEnum;
  danceManagerSubscriptionType = DanceManagerSubscriptionTypeEnum;

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;
  
  private subscription = new Subscription();

  constructor(
    private danceManagerHttpService: DanceManagerHttpService,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private matDialog: MatDialog,
    private danceManagerService: DanceManagerService,
    private dfmStateService: DfmStateService
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }
  
  initSubscriptions(): void {
    this.subscription.add(getSubscription(this.dfmStateService.selectedDmFeatures, this.onDanceManagerFeaturesChange.bind(this)));
    this.subscription.add(getSubscription(this.dfmStateService.selectedDm, this.onDanceManagerChange.bind(this)));
  }

  onDanceManagerFeaturesChange(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.isFeatureEventActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.DANCE_EVENTS);
    this.isFeatureEventTicketsActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.DANCE_EVENTS_TICKETS);
    this.isFeatureCoursesActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.COURSES);
    this.isFeatureCoursePassesActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.COURSE_PASSES);
    this.isFeatureCourseSubscriptionActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.COURSE_SUBSCRIPTION);
  }

  onDanceManagerChange(danceManager: DanceSchoolInterface): void {
    this.danceManager = danceManager;
  }
  
  reloadDmFeatures(): void {
    const selectedDmId = this.dfmStateService.selectedDm.value.id;
    this.danceManagerHttpService.getDanceManagerFeaturesById(selectedDmId).subscribe({
      next: (features) => {
        this.dfmStateService.sendSelectedDmFeatures(features);
      }
    });
    this.danceManagerService.getDanceManagerFeaturesById(selectedDmId);
  }

  onDanceManagerFeatureSlide(event: MatSlideToggleChange, danceManagerFeatureName: DanceManagerFeatureNameEnum): void {
    if (event.checked) {
      this.subscription.add(
        this.danceManagerHttpService.activateFeature(this.dfmStateService.selectedDm.value.id, [danceManagerFeatureName])
          .pipe(
            tap(() => {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.ACTIVATE_SUCCESS'));
              this.reloadDmFeatures();
            }),
            catchError((err) => {
              if (err.status === 400) {
                if ([this.danceManagerFeatureName.DANCE_EVENTS_TICKETS, this.danceManagerFeatureName.COURSE_PASSES, this.danceManagerFeatureName.COURSE_SUBSCRIPTION].includes(danceManagerFeatureName)) {
                  this.handleActivateEventTicketPackageError(err.error.error);
                }
              }
              event.source.checked = false;
              this.reloadDmFeatures();
              throw err;
            })
          )
          .subscribe()
      );
    } else {
      this.subscription.add(
        this.danceManagerHttpService
          .deactivateFeature(this.dfmStateService.selectedDm.value.id, [danceManagerFeatureName])
          .pipe(
            tap(() => {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.DEACTIVATE_SUCCESS'));
              this.reloadDmFeatures();
            }),
            catchError((err) => {
              if (err.status === 400) {
                if (danceManagerFeatureName === this.danceManagerFeatureName.DANCE_EVENTS) {
                  this.handleDeactivateEventPackageError(err.error.error);
                }
                if (
                  danceManagerFeatureName ===
                  this.danceManagerFeatureName.DANCE_EVENTS_TICKETS
                ) {
                  this.handleDeactivateEventTicketPackageError(err.error.error);
                }
              }
              
              event.source.checked = true;
              this.reloadDmFeatures();
              throw err;
            })
          )
          .subscribe()
      );
    }
  }

  showPayoutOnboardingRequiredDialog(): void {
    this.matDialog.open(DmOnboardingRequiredDialogComponent, {
      maxHeight: '100vh',
      width: '400px',
      panelClass: ['onboarding-mat'],
    });
  }

  showSubscriptionRequiredDialog(): void {
    this.matDialog.open(DmUpgradeRequiredDialogComponent, {
      maxHeight: '100vh',
      width: '400px',
      panelClass: ['onboarding-mat'],
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  handleDeactivateEventPackageError(error: DeactivateEventPackageErrorEnum): void {
    switch (error) {
      case DeactivateEventPackageErrorEnum.UPCOMING_EVENT:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.PACKAGE_FEATURE_DEACTIVATE_ERROR_UPCOMING_EVENT'));
        break;
      case DeactivateEventPackageErrorEnum.REQUIRED_BY_OTHER_PACKAGE:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.PACKAGE_FEATURE_DEACTIVATE_ERROR_REQUIRED_BY_TICKET_PACKAGE'));
        break;
      default:
        break;
    }
  }
  
  handleDeactivateEventTicketPackageError(error: DeactivateEventTicketsPackageErrorEnum): void {
    switch (error) {
      case DeactivateEventTicketsPackageErrorEnum.TICKETS_ON_SALE_AND_SOLD:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.PACKAGE_FEATURE_DEACTIVATE_ERROR_TICKET_SALE_UPCOMING_EVENT'));
        break;
      case DeactivateEventTicketsPackageErrorEnum.FREE_TICKETS_SENT:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.PACKAGE_FEATURE_DEACTIVATE_ERROR_FREE_TICKETS'));
        break;
      case DeactivateEventTicketsPackageErrorEnum.TICKETS_ON_SALE_NONE_SOLD:
        const confirmationDialogData: ConfirmationDialogInterface = {
          cancelButtonColor: '',
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
          submitButtonColor: 'warn',
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DEACTIVATE'),
          text: this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.DEACTIVATE_TICKETS_PACKAGE_WITH_NON_SOLD_TICKETS_TEXT'),
          title: this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.DEACTIVATE_TICKETS_PACKAGE_WITH_NON_SOLD_TICKETS_TITLE')
        }; 

        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
          maxWidth: '400px',
          data: confirmationDialogData,
        });
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.handleForceDeactivateTicketPackage();
          }
        });
        break;
      default:
        break;
    }
  }
  
  handleActivateEventTicketPackageError(error: ActivatePackageErrorEnum): void {
    switch (error) {
      case ActivatePackageErrorEnum.PAYOUT_INIT_REQUIRED:
        this.showPayoutOnboardingRequiredDialog();
        break;
      case ActivatePackageErrorEnum.EVENT_PACKAGE_REQUIRED:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.PACKAGE_FEATURE_REQUIRED_DANCE_EVENTS'));
        break;
      case ActivatePackageErrorEnum.COURSE_PACKAGE_REQUIRED:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.PACKAGE_FEATURE_REQUIRED_COURSES'));
        break;
      case ActivatePackageErrorEnum.SUBSCRIPTION_REQUIRED:
        this.showSubscriptionRequiredDialog();
        break;
      default:
        break;
    }
  }
  
  handleForceDeactivateTicketPackage(): void {
    this.danceManagerHttpService
      .deactivateFeature(this.dfmStateService.selectedDm.value.id, [DanceManagerFeatureNameEnum.DANCE_EVENTS_TICKETS], true)
      .pipe(
        tap(() => {
          this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGES.DEACTIVATE_SUCCESS'));
          this.reloadDmFeatures();
        }),
        catchError((err) => {
          if (err.status === 400) {
            this.handleDeactivateEventTicketPackageError(err.error.error);
          }

          this.reloadDmFeatures();
          throw err;
        })
      )
      .subscribe();
  }
}
