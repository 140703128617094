import {MessagePartnerInterface} from "../../..";

export class MessagePartnerDto implements MessagePartnerInterface {
  id: string;
  username?: string;
  userId?: string;
  info?: string;
  imageUrl?: string;
  isNamePublic?: boolean;
}
