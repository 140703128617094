import { DanceManagerUserRoleEnum } from '../enums';
import { UsersInterface } from './users';

export interface AuthDanceSchoolUserRoleInterface {
  user?: UsersInterface;
  danceSchoolUserRole?: DanceManagerUserRoleEnum;
  validFrom?: Date;
  validUntil?: Date;
  danceSchoolId?: string;
  danceSchoolUrlName?: string;
}
