<ng-container *transloco="let t">
  <div style="padding: 16px; height: 100dvh">
    <div class="mb1">
      <a mat-menu-item class="align-start-center hide-on-web" [routerLink]="['/']" (click)="closeMenu.emit()">
        <mat-icon fontSet="material-icons-outlined">home</mat-icon>
        <span class="fs16">{{ t('GENERIC_WRAPPER.HOME') }}</span>
      </a>
    </div>
    <div class="mb1">
      <a *ngIf="user" class="align-start-center" mat-menu-item [routerLink]="['@' + user.username]" [routerLinkActive]="'active-link'" (click)="closeMenu.emit()">
        <mat-icon class="hide-on-web" fontSet="material-icons-outlined">person_outline</mat-icon>
        <span class="fs16">{{ t('GENERIC_WRAPPER.PROFILE') }}</span>
      </a>
    </div>
    <div class="mb1">
      <a [routerLinkActive]="'active-link'" class="align-start-center" [routerLink]="'/inventory'" mat-menu-item (click)="closeMenu.emit()">
        <mat-icon class="hide-on-web" fontSet="material-icons-outlined">inventory_2</mat-icon>
        <span class="fs16">{{ t('GENERIC_WRAPPER.MY_INVENTORY') }}</span>
      </a>
    </div>
    <div class="mb1">
      <a [routerLinkActive]="'active-link'" [routerLink]="['/messages']" class="my1 hide-on-web align-start-center" mat-menu-item (click)="closeMenu.emit()">
        <mat-icon class="hide-on-web" [matBadge]="unreadMessagesCount" [matBadgeHidden]="unreadMessagesCount === 0" matBadgeColor="accent">chat_bubble_outline</mat-icon>
        <span class="fs16">{{ t('GENERIC_WRAPPER.MESSAGES') }}</span>
      </a>
    </div>
    <div *ngIf="dmPassSubscriptionFeature" class="mb1">
      <a class="my1 hide-on-web align-start-center" mat-menu-item (click)="openStripeBillingPortalSession()">
        <mat-icon class="hide-on-web">subscriptions</mat-icon>
        <span class="fs16">{{ t('GENERIC_WRAPPER.MANAGE_SUBSCRIPTIONS') }}</span>
      </a>
    </div>
  
    <div class="px2 py1 mt2 mb2">
      <mat-divider></mat-divider>
    </div>
  
    <div class="mb1">
      <button mat-menu-item (click)="bugReportClicked.emit(); closeMenu.emit();">
        <span class="fs16">{{ t('GENERIC_WRAPPER.REPORT_BUG') }}</span>
      </button>
    </div>
    <div class="mb1">
      <button mat-menu-item (click)="featureRequestClicked.emit(); closeMenu.emit();">
        <span class="fs16">{{ t('GENERIC_WRAPPER.FEATURE_REQUEST') }}</span>
      </button>
    </div>
    <div>
      <button mat-menu-item (click)="imprintClicked.emit(); closeMenu.emit();">
        <span class="fs16">{{ t('GENERIC_WRAPPER.IMPRINT') }}</span>
      </button>
    </div>
    <div class="px2 py1 mt2 mb2">
      <mat-divider></mat-divider>
    </div>
  
    <a class="mb1" [routerLinkActive]="'active-link'" [routerLink]="['/settings']" mat-menu-item (click)="closeMenu.emit();">
      <span class="fs16">{{ t('GENERIC_WRAPPER.SETTINGS') }}</span>
    </a>
    <button mat-menu-item (click)="logoutClicked.emit(); closeMenu.emit();">
      <span class="fs16">{{ t('GENERIC_WRAPPER.LOGOUT') }}</span>
    </button>
  </div>
</ng-container>
