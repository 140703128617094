import { DanceManagerPassesPublicResponseInterface } from '../../..';
import { PassTargetDto } from './pass-target.dto';
import { PassValidityDto } from './pass-validity.dto';

export class DanceManagerPassesPublicResponseDto implements DanceManagerPassesPublicResponseInterface {
  id: string;
  name: string;
  description: string;
  usageCount: number;
  availableQuantity: number;
  price: number;
  passTargets: PassTargetDto[];
  validity: PassValidityDto;
  trial: boolean;
}
