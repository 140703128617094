import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmenitiesComponent } from './amenities.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PipesModule } from '../../../pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [AmenitiesComponent],
  exports: [AmenitiesComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    PipesModule,
    MatTooltipModule,
    TranslocoModule,
  ],
})
export class AmenitiesModule {}
