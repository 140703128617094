import { PassInterface, UserPassInterface } from './pass';
import { CurrencyTypeEnum } from '../enums';

export interface PdfDataCourseInterface {
  qrCode?: string;
  pass?: PassInterface;
  userPass?: UserPassInterface;
  currency?: CurrencyTypeEnum;
  createdAt?: Date;
  validUntil?: Date;
  danceManagerName?: string;
  courseNames?: string[];
  usageCount?: number;
  scannedUsages?: number;
}
