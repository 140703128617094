
export class ReadMessageRequestDto {
  messageId: number;
  
  constructor(args: ReadMessageRequestDto) {
    if (args) {
      this.messageId = args.messageId;
    }
  }
}
