import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursePage } from './pages/app';
import { CourseResolver, dfCourseIdRouterParam, TicketOrderResolver, UserPassSubscriptionResolver } from '@platri/dfx-angular-core';
import { CoursePassesPage } from './pages/course-passes';
import { CoursePassesCheckoutPage } from './pages/course-passes-checkout/course-passes-checkout.page';
import { CoursePassesCheckoutCompletePage } from './pages/course-passes-checkout-complete/course-passes-checkout-complete.page';
import { UserPassSubscriptionCheckoutCompletePage } from './pages/user-pass-subscription-checkout-complete/user-pass-subscription-checkout-complete.page';

const routes: Routes = [
  { 
    path: ':' + dfCourseIdRouterParam,
    children: [
      {
        path: '',
        component: CoursePage,
      },
      {
        path: 'passes',
        resolve: {
          targetObject: CourseResolver,
        },
        component: CoursePassesPage
      },
      {
        path: 'passes/checkout',
        resolve: {
          targetObject: CourseResolver,
        },
        component: CoursePassesCheckoutPage
      },
      {
        path: 'passes/checkout-complete/:ticketOrderId',
        resolve: {
          targetObject: TicketOrderResolver,
        },
        component: CoursePassesCheckoutCompletePage
      },
      {
        path: 'user-pass-subscription/checkout-complete/:userPassSubscriptionId',
        resolve: {
          targetObject: UserPassSubscriptionResolver,
        },
        component: UserPassSubscriptionCheckoutCompletePage
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfCourseAppRoutingModule {
  constructor() {
    console.log('FrontendDfCourseAppRoutingModule initialized');
  }
}
