import {
  AfterContentInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DanceManagerHttpService,
  DanceSchoolStateService,
  dfAppRoute,
  dfLoginRoute,
  dfmDanceManagerRoute,
  dfmUserGroupId,
  dfmUserGroupsRoute,
  getSubscription,
  UserHttpService
} from '@platri/dfx-angular-core';
import {
  DanceManagerUserGroupsInterface,
  DanceSchoolInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  EditUserGroupRequestDto,
  UserAppLangEnum,
  UserDto,
  UsersInterface
} from '@platri/df-common-core';
import { tap } from 'rxjs/operators';
import { DfmEditUserGroupFormInterface } from './dfm-edit-user-group-form.interface';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dfm-edit-user-group-form',
  templateUrl: './dfm-edit-user-group-form.component.html',
  styleUrls: ['./dfm-edit-user-group-form.component.scss']
})
export class DfmEditUserGroupFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulCreateUserGroupEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  editUserGroupFormGroup!: FormGroup<DfmEditUserGroupFormInterface>;
  dfmUserGroup: DanceManagerUserGroupsInterface;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForEditUserGroupResponse = false;
  
  editFailedNoConnection = false;

  routerParams!: Params;
  dfmUserGroupId: string;
  danceManager: DanceSchoolInterface;
  currentLang = UserAppLangEnum.DE;

  searchInputChangeSubject = new Subject<string>();
  showUserSearchLoadingSpinner = false;
  
  selectedUsers: any[] = [];
  userOptions: any[] = [];
  displayedColumns: string[] = ['value', 'info'];

  loadedUsers: { [key: string]: UserDto } = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private danceSchoolService: DanceSchoolStateService,
    private translocoService: TranslocoService,
    private userHttpService: UserHttpService,
    private danceManagerHttpService: DanceManagerHttpService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
  }

  initializeFormGroup(): void {
    this.editUserGroupFormGroup = this.createEditUserGroupFormGroup();
  }

  createEditUserGroupFormGroup(): FormGroup<DfmEditUserGroupFormInterface> {
    return this.fb.group<DfmEditUserGroupFormInterface>({
      name: this.fb.control('',  [Validators.required, Validators.maxLength(50)]),
      userIds: this.fb.control([],  Validators.required),
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.editUserGroupFormGroup.valueChanges.subscribe(res => {
      this.editFailedNoConnection = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscribeToSearch();
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.dfmUserGroupId = this.routerParams[dfmUserGroupId];
    if (!this.dfmUserGroupId) {
      this.router.navigate([dfAppRoute, dfLoginRoute]);
    } else {
      this.loadData();
    }
  }
  
  loadData(): void {
    this.loadCurrentDanceManager();
    this.loadDfmUserGroup();
    this.isInitialized = true;
  }
  
  loadCurrentDanceManager(): void {
    this.danceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
  }
  
  loadDfmUserGroup(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDmUserGroupById(this.dfmUserGroupId).subscribe({
        next: (data) => {
          this.dfmUserGroup = data;
          this.editUserGroupFormGroup.patchValue({
            name: data.name,
            userIds: data.userIds
          });
          this.loadAlreadySelectedUsers();
        }
      })
    );
  }
  
  loadAlreadySelectedUsers(): void {
    const userIds = [];

    for (const usersToLoadKey of this.dfmUserGroup.userIds) {
      userIds.push(usersToLoadKey);
    }

    if (userIds?.length > 0) {
      this.subscriptions.add(
        this.userHttpService.getUsersByIds(userIds, true).subscribe({
          next: (data) => {
            this.selectedUsers = this.mapToOptions(data);
            data.forEach((dto) => {
              this.loadedUsers[dto.id] = dto;
            });
          }
        })
      );
    }
  }

  onSubmit(): void {
    if (this.editUserGroupFormGroup.valid) {
      this.isWaitingForEditUserGroupResponse = true;
      this.editFailedNoConnection = false;
      const {name, userIds} = this.editUserGroupFormGroup.getRawValue();
      const editUserGroupRequestDto: EditUserGroupRequestDto = {name, userIds};
      this.subscriptions.add(this.danceManagerHttpService.editDmUserGroups(this.dfmUserGroupId, editUserGroupRequestDto).subscribe({
        next: () => {
          this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmUserGroupsRoute]);
          this.isWaitingForEditUserGroupResponse = false;
        },
        error: (err) => {
          console.log(err);
          if (err.status === 0) {
            this.onConnectionLost();
          }
          this.isWaitingForEditUserGroupResponse = false;
        }
      }));
    }
  }

  onUserSelected(user: any): void {
    const index = this.selectedUsers.findIndex((obj) => obj.id === user.id);
    if (index === -1) {
      this.selectedUsers.push(user);
      this.editUserGroupFormGroup.patchValue({
        userIds: this.selectedUsers.map((obj) => obj.id)
      });
    }
  }
  
  onUserRemoved(user: any): void {
    const index = this.selectedUsers.findIndex((obj) => obj.id === user.id);
    this.selectedUsers.splice(index, 1);
    this.editUserGroupFormGroup.patchValue({
      userIds: this.selectedUsers.map((obj) => obj.id)
    });
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }

  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject.pipe(
        tap(() => (this.showUserSearchLoadingSpinner = true)),
        debounceTime(600)
      ).subscribe((inputText: string) => {
        if (inputText) {
          this.subscriptions.add(
            this.userHttpService.searchUsersByKey(inputText, true).pipe(
              tap(() => {
                this.showUserSearchLoadingSpinner = false;
              })
            ).subscribe((users: UsersInterface[]) => {
              this.userOptions = this.mapToOptions(users);
            })
          );
        } else {
          this.userOptions = [];
          this.showUserSearchLoadingSpinner = false;
        }
      })
    );
  }

  private mapToOptions(users: UsersInterface[]): any {
    return users.map((user) => ({
      id: user.id,
      value: user.username,
      data: user,
      displayValue: user.username,
      info: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null,
      imageUrl: user.imageUrl ?? null,
    }));
  }

  onConnectionLost(): void {
    this.editFailedNoConnection = true;
  }

  onCancel(): void {
    this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmUserGroupsRoute]);
  }

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
}
