import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedEmptyPageComponent } from './shared-empty-page.component';
import { DfSharedButtonModule } from '../buttons';

@NgModule({
  declarations: [SharedEmptyPageComponent],
  exports: [SharedEmptyPageComponent],
  imports: [CommonModule, DfSharedButtonModule,],
})
export class SharedEmptyPageModule {}
