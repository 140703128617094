import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DfmCreateLocationFormComponent } from './dfm-create-location-form.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AmenitiesModule, DfSharedButtonModule, GoogleAddressSearchAutocompleteModule, OpenHoursModule, PhoneInputModule, PipesModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmCreateLocationFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    AmenitiesModule,
    DfSharedButtonModule,
    GoogleAddressSearchAutocompleteModule,
    MatDividerModule,
    PhoneInputModule,
    PipesModule,
    MatRadioModule,
    MatCardModule,
    OpenHoursModule,
    TranslocoDirective
  ],
  exports: [DfmCreateLocationFormComponent]
})
export class DfmCreateLocationFormModule { }
