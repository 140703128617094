<div *ngIf="imageUrls.length === 1">
  <div class="mt2 w-100 gallery flex-row-wrap align-gt-xs align-start-lt-md">
    <ng-container *ngFor="let image of imageUrls">
      <img [src]="image" class="gallery-element gallery-element-responsive" (click)="openFullscreenDialog(image)"/>
    </ng-container>
  </div>
</div>
<div *ngIf="imageUrls.length !== 1">
  <div class="mt2 w-100 gallery flex-row-wrap align-gt-xs align-space-around-lt-md">
    <ng-container *ngFor="let image of imageUrls">
      <img [src]="image" class="gallery-element gallery-element-responsive" (click)="openFullscreenDialog(image)"/>
    </ng-container>
  </div>
</div>
