import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DmPackagesCardListComponent } from './dm-packages-card-list.component';
import { DmPackagesCardItemModule } from '../dm-packages-card-item';
import { DmOnboardingRequiredDialogModule } from '../dm-onboarding-required-dialog';
import { DmUpgradeRequiredDialogModule } from '../dm-upgrade-required-dialog';
import { PipesModule } from '../../pipes';
import { ConfirmationDialogModule } from '../confirmation-dialog';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DmPackagesCardListComponent],
    imports: [
      CommonModule,
      MatCardModule,
      MatIconModule,
      MatMenuModule,
      MatButtonModule,
      MatFormFieldModule,
      MatChipsModule,
      MatDividerModule,
      ReactiveFormsModule,
      PipesModule,
      MatSlideToggleModule,
      DmPackagesCardItemModule,
      DmOnboardingRequiredDialogModule,
      DmUpgradeRequiredDialogModule,
      ConfirmationDialogModule,
      TranslocoDirective,
    ],
  exports: [DmPackagesCardListComponent],
})
export class DmPackagesCardListModule {}
