<ng-container *transloco="let t">
  <div [formGroup]="formGroup" class="flex-column" style="gap: 10px;">
    <div class="w-100-children flex-row mat-form-container-lt-lg" style="gap: 20px;">
      <mat-form-field appearance="outline">
        <mat-label>{{t('GENERIC_WRAPPER.TEACHERS')}}</mat-label>
        <mat-select
          formControlName="teachers"
          multiple
          [compareWith]="compareTeachers"
          disableOptionCentering>
          <mat-option *ngFor="let teacher of teachers" [value]="teacher"
          >{{ teacher.firstName }} {{ teacher.lastName }}</mat-option>
        </mat-select>
        <mat-error>{{t('GENERIC_WRAPPER.MIN_ONE_TEACHER')}}</mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-container>
