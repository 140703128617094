<ng-container *transloco="let t">
  <div [formGroup]="formGroup" class="flex-column gap-40" style="max-width: 600px;">
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PRICE') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.PRICE_DESC') }}</span>
      <mat-form-field appearance="outline" style="max-width: 125px;">
        <input dfNoScroll [formatNumber]="2" [step]="0.01" formControlName="price" id="price" matInput type="number" [placeholder]="t('GENERIC_WRAPPER.PRICE') "/>
        <span matSuffix class="mat-suffix-symbol">€</span>
        <mat-error *ngIf="price.hasError('required')">{{ t('GENERIC_WRAPPER.PRICE_REQUIRED') }}</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.VAT') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.VAT_DESC') }}</span>
      <mat-form-field appearance="outline" style="max-width: 200px;">
        <input dfNoScroll matInput type="number" formControlName="taxPercentage" required [placeholder]="t('GENERIC_WRAPPER.PASSES_MANAGER.VAT')">
        <span matSuffix class="mat-suffix-symbol">%</span>
        <mat-error *ngIf="taxPercentage.hasError('required')">{{ t('GENERIC_WRAPPER.VAT_REQUIRED') }}</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.FEE') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.FEE_DESC') }}</span>
      <mat-radio-group class="flex-column" formControlName="feesPaidBy">
        <mat-radio-button [value]="feesPaidByEnum.CREATOR">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.FEE_YOU') }}</mat-radio-button>
        <mat-radio-button [value]="feesPaidByEnum.CUSTOMER" [disabled]="true">{{ t('GENERIC_WRAPPER.PASSES_MANAGER.FEE_CUSTOMER') }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</ng-container>
