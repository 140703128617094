import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DanceManagerFaqDto, environmentForWeb } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DanceSchoolFaqHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-schools`;

  constructor(private http: HttpClient) {}
  
  getAllByDanceSchoolId(danceSchoolId: string): Observable<DanceManagerFaqDto[]> {
    return this.http.get<DanceManagerFaqDto[]>(
      `${this.SERVICE_URL}/${danceSchoolId}/faqs`
    );
  }

  create(danceSchoolId: string, question: string, answer: string): Observable<DanceManagerFaqDto> {
    return this.http.post<DanceManagerFaqDto>(`${this.SERVICE_URL}/${danceSchoolId}/faqs`,
      {
        question,
        answer,
      }
    );
  }

  updateById(
    danceSchoolId: string,
    faq: DanceManagerFaqDto
  ): Observable<DanceManagerFaqDto> {
    return this.http.put<DanceManagerFaqDto>(
      `${this.SERVICE_URL}/${danceSchoolId}/faqs/${faq.id}`,
      faq
    );
  }

  deleteById(danceSchoolId: string, faqId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.SERVICE_URL}/${danceSchoolId}/faqs/${faqId}`
    );
  }

  updateSequenceNumber(
    danceSchoolId: string,
    newSequence: { id: string; sequenceNumber: number }[]
  ): Observable<any> {
    return this.http.put<any>(
      `${this.SERVICE_URL}/${danceSchoolId}/faqs/sequence-numbers`,
      newSequence
    );
  }
}
