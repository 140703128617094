import { DancePartnerGenderEnum, DanceStyleRoleEnum, DpsPostingInterface, SearchPostingTypeEnum } from '../../..';
import { ImageDto } from '../../..';
import { PlaceDto } from './place.dto';
import { DancePartnerDanceStyleDto } from './dance-partner-dance-style.dto';

export class UpdateDancePartnerSearchPostingRequestDto implements DpsPostingInterface {
  title: string;
  description: string;
  searchPostingType: SearchPostingTypeEnum;
  ageFrom?: number;
  ageTo?: number;
  dancePartnerDanceRole?: DanceStyleRoleEnum;
  dancePartnerDanceStyles?: DancePartnerDanceStyleDto[];
  dancePartnerGender?: DancePartnerGenderEnum;
  heightFrom?: number;
  heightTo?: number;
  mainImage?: ImageDto;
  images?: ImageDto[];
  place: PlaceDto;
}
