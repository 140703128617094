import { Component } from '@angular/core';
import { DanceSchoolHttpService, DanceSchoolStateService, DfmStateService, Files, ImageService } from '../../services';
import { map } from 'rxjs/operators';
import { DanceSchoolInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-dance-studio-images-dialog',
  templateUrl: './dance-studio-images-dialog.component.html',
  styleUrls: ['./dance-studio-images-dialog.component.scss'],
})
export class DanceStudioImagesDialogComponent {
  uploadedAwardImages: FileList = null;
  files: Files[] = [];
  newImageSelected = false;

  constructor(
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly dfmStateService: DfmStateService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly imageService: ImageService,
  ) {}

  onImageSelect(uploadEvent: DataTransfer | EventTarget): void {
    this.files = this.imageService.createFiles(uploadEvent);
    this.newImageSelected = true;
  }

  awardImagesUpload(): void {
    const formData = this.imageService.createFormData(this.files);
    this.danceSchoolHttpService.uploadGalleryImagesByDanceSchoolId(this.danceSchoolService.getSyncCurrentDanceSchool().id, formData).pipe(
      map((danceSchool) => this.sendStateServiceData(danceSchool))
    ).subscribe();
  }
  
  sendStateServiceData(danceSchool: DanceSchoolInterface): void {
    this.danceSchoolService.sendCurrentDanceSchool(danceSchool);
    this.dfmStateService.sendSelectedDm(danceSchool);
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
  }
}
