import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersInterface } from '@platri/df-common-core';
import { UserHttpService } from '../http-services';
import { AuthStateService } from './auth-state.service';
import { VerifyEmailDialogComponent } from '../../components';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // @ts-ignore
  private currentUserSubject: BehaviorSubject<UsersInterface> = new BehaviorSubject<UsersInterface>(null);
  private currentLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription = new Subscription();

  constructor(private readonly userHttpService: UserHttpService, private readonly dialog: MatDialog, private readonly router: Router, private readonly authService: AuthStateService) {}

  // BUSINESS LOGIC

  verifyEmail(token: string): void {
    this.userHttpService.verifyEmail(token).subscribe(
      () => {
        this.dialog
          .open(VerifyEmailDialogComponent, {
            panelClass: 'verify-email-dialog',
            width: '27vw',
            data: {
              message: 'Your Account has been successfully verified!',
              buttonText: 'Awesome!',
              matIcon: 'celebration'
            }
          })
          .afterClosed()
          .subscribe(() => {
            if (this.authService.getSyncCurrentUser()) {
              this.routeToLandingPage();
            } else {
              this.routeToLoginPage();
            }
          });
      },
      (error) => {
        let message: string;
        if (error.status === 403 || error.status === 412 || error.status === 400) {
          message = error.error.message;
        } else {
          message = 'Oops.. Something went wrong. Please try again later. If the error persists contact our support.';
        }
        this.dialog
          .open(VerifyEmailDialogComponent, {
            panelClass: 'verify-email-dialog',
            width: '27vw',
            data: {
              message,
              buttonText: 'Oh no!',
              matIcon: 'sentiment_dissatisfied'
            }
          })
          .afterClosed()
          .subscribe(() => this.routeToLandingPage());
      }
    );
  }

  // ROUTING LOGIC

  routeToLandingPage(): void {
    this.router.navigate(['/']);
  }

  routeToLoginPage(): void {
    this.router.navigate(['login']);
  }

  reportUser(userId: string, reportReason: string): Observable<void> {
    return this.userHttpService.reportUser(userId, reportReason);
  }
}
