import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StandaloneAppComponent } from './standalone-app.component';
import { StandaloneRoutingModule } from './standalone-routing.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ActivityHttpService, AuthStateService, UserHttpService } from '@platri/dfx-angular-core';
import { GenericWrapperComponent, GenericWrapperModule } from '@platri/df-angular-generic-wrapper';
import { authInterceptorProviders } from '../lib';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [StandaloneAppComponent],
  imports: [
    BrowserModule,
    StandaloneRoutingModule,
    GenericWrapperModule,
    TranslocoModule
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    authInterceptorProviders
  ],
  bootstrap: [GenericWrapperComponent]
})
export class StandaloneAppModule {
  constructor(
    private authService: AuthStateService,
    private userHttpService: UserHttpService,
    private activityHttpService: ActivityHttpService
  ) {
    this.authService.getAsyncAccessToken().subscribe(accessToken => {
      if (accessToken) {
        this.userHttpService.getCurrentUser().subscribe(res => {
          this.authService.sendCurrentUserChanges(res);
          this.activityHttpService.logActivity().subscribe(activity => {})
        }, error => {
          this.authService.logout();
        });
      } else {
        this.authService.sendCurrentUserChanges(null);
      }
    });
  }
}
