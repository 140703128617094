import { Component, Input } from '@angular/core';
import {
  DanceSchoolStateService,
  dfmDanceManagerRoute,
  dfmMarketPlaceRoute,
  PassesStateService,
  PassHttpService
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'dfm-course-passes-page',
  templateUrl: './dfm-course-passes.page.html',
  styleUrls: ['./dfm-course-passes.page.scss']
})
export class DfmCoursePassesPage {
  @Input() passesFeatureActive = false;
  
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly passHttpService: PassHttpService,
    private readonly passesService: PassesStateService,
    private readonly danceSchoolService: DanceSchoolStateService
  ) {}

  navigateToCreatePassPage(id?: string): void {
    if (id) {
      this.passHttpService.getById(id).subscribe({
        next: (pass) => {
          this.passesService.passToDuplicateSubject.next(pass);
          this.router.navigate(['create-pass'], {relativeTo: this.activatedRoute});
        }
      });
    } else {
      this.router.navigate(['create-pass'], {relativeTo: this.activatedRoute});
    }
  }

  navigateToMarketplacePage(): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmMarketPlaceRoute]);
  }

  navigateToEditPassPage(passId: string): void {
    this.router.navigate(['pass', passId], { relativeTo: this.activatedRoute });
  }
}
