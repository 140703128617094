import { UserPassInterface, UserPassStatusEnum, UserPassSubscriptionDto } from '../../..';
import { PassDto } from './pass.dto';
import { UserPassScanDto } from './user-pass-scan.dto';

export class UserPassDto implements UserPassInterface {
  id: string;
  passId: string;
  userId: string;
  guestUserEmail: string;
  orderId: string;
  quantity: number;
  createdAt: Date;
  description: string;
  status: UserPassStatusEnum;
  userPassScans: UserPassScanDto[];
  pass: PassDto;
  validUntil: Date;
  userPassSubscription: UserPassSubscriptionDto;
}
