import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb, GetUserPassSubscriptionByDmPassSubscriptionIdRequestDto, UserPassSubscriptionDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class UserPassSubscriptionHttpService {
  private apiBaseUrl = `${environmentForWeb.apiTicketUrl}/user-pass-subscription`;

  constructor(private readonly http: HttpClient) {}
  
  createUserPassSubscriptionWebhook(data): Observable<void> {
    return this.http.post<void>(`${this.apiBaseUrl}/webhook`, data);
  }

  createCheckoutSession(dmPassSubscriptionId: string): Observable<string> {
    // @ts-ignore
    return this.http.post<string>(`${this.apiBaseUrl}/checkout-session`, {dmPassSubscriptionId} , { responseType: 'text' });
  }

  getUserPassSubscriptionByIdAndCurrentUser(id: string): Observable<UserPassSubscriptionDto> {
    return this.http.get<UserPassSubscriptionDto>(`${this.apiBaseUrl}/${id}`);
  }
  
  getUserPassSubscriptionsByCurrentUser(): Observable<UserPassSubscriptionDto[]> {
    return this.http.get<UserPassSubscriptionDto[]>(`${this.apiBaseUrl}/current-user`);
  }

  getUserPassSubscriptionByCurrentUserAndDanceManagerId(danceManagerId: string): Observable<UserPassSubscriptionDto> {
    return this.http.get<UserPassSubscriptionDto>(`${this.apiBaseUrl}/current-user/dance-manager/${danceManagerId}`);
  }

  getUserPassSubscriptionsByCurrentUserAndDanceManagerId(danceManagerId: string): Observable<UserPassSubscriptionDto[]> {
    return this.http.get<UserPassSubscriptionDto[]>(`${this.apiBaseUrl}/current-user/dance-manager/${danceManagerId}/v2`);
  }

  getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser(): Observable<string> {
    // @ts-ignore
    return this.http.get<string>(`${this.apiBaseUrl}/current-user/billing-portal-session`, { responseType: 'text' });
  }

  getSubscribersBySubscriptionId(dto: GetUserPassSubscriptionByDmPassSubscriptionIdRequestDto): Observable<UserPassSubscriptionDto[]> {
    return this.http.post<UserPassSubscriptionDto[]>(`${this.apiBaseUrl}/sales/dm-pass-subscription`, dto);
  }

  refundById(id: string): Observable<void> {
    return this.http.patch<void>(`${this.apiBaseUrl}/${id}/refund`, {});
  }
  
  cancelById(id: string): Observable<void> {
    return this.http.patch<void>(`${this.apiBaseUrl}/${id}/cancel`, {});
  }
  
  reactivateById(id: string): Observable<void> {
    return this.http.patch<void>(`${this.apiBaseUrl}/${id}/reactivate`, {});
  }

  acceptNewSubscriptionPrice(userPassSubscriptionId: string, isAccepted: boolean): Observable<void> {
    return this.http.post<void>(
      `${this.apiBaseUrl}/accept-new-subscription-price/${userPassSubscriptionId}`, 
      {priceAccepted: isAccepted}
    );
  }
  
}
