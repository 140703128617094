import { DanceManagerUserRoleEnum } from '../enums';
import { DanceSchoolToUserInterface, UsersInterface } from '../interfaces';

export function danceManagerUserHasRoles(currentUserId: string, danceSchoolToUsers: DanceSchoolToUserInterface[], neededRoles: DanceManagerUserRoleEnum[]): boolean {
  return danceSchoolToUsers?.some((danceSchoolToUser) => neededRoles.includes(<DanceManagerUserRoleEnum> danceSchoolToUser.danceSchoolUserRole) && danceSchoolToUser?.user?.id === currentUserId);
}

export function danceManagerUserHasRolesNew(user: UsersInterface, id: string, neededRoles: DanceManagerUserRoleEnum[]): boolean {
  if (user.roles && user.roles) {
    let hasRoles = false;
    neededRoles.forEach((role) => {
      // @ts-ignore
      const danceManagerRole: string[] = user.roles.danceSchool[role];
      if (danceManagerRole && danceManagerRole.includes(id)) {
        hasRoles = true;
      }
    });
    return hasRoles;
  } else {
    return false;
  }
}
