import {Pipe, PipeTransform} from '@angular/core';
import {AuthStateService} from "../services/state-services/auth-state.service";
import { Appointment } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'isAppointmentParticipant',
  pure: false
})
export class IsAppointmentParticipantPipe implements PipeTransform {
  constructor(private readonly authService: AuthStateService) {
  }

  transform(value: Appointment): boolean {
    return !!value.students?.find(
      (student) => student.id === this.authService.getSyncCurrentUser()?.id
    );
  }
}
