<ng-container *transloco="let t">
  <div class="flex-column">
    <div class="title-container flex-row align-start-center">
      <h2 style="margin-right: 15px;">{{t('GENERIC_WRAPPER.CREATE_NEW_COURSE')}}</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-column mt3 gap-20 form-container-gt-xs" [formGroup]="rulesFormGroup">
      <div
        class="flex-column gap-10"
      >
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.RULES.CHECK_IN_TITLE')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.RULES.CHECK_IN_SUBTEXT')}}</span>
        <mat-checkbox
          class="checkbox-br"
          color="primary"
          formControlName="isCheckInActive"
        >
          {{t('DANCE_MANAGER_COURSE.RULES.CHECK_IN_CHECK_BOX')}}
        </mat-checkbox>
      </div>
      <div class="flex-column" [formGroup]="rulesFormGroup.get('participantRestriction')">
        <span class="section-title">Participant restrictions (optional)</span>
        <mat-checkbox
          class="checkbox-br bold"
          color="primary"
          formControlName="isAgeRestricted"
        >
          {{t('DANCE_MANAGER_COURSE.RULES.AGE_RESTRICTION')}}
        </mat-checkbox>
        <df-shared-lib-level-slider-material
          class="age-slider"
          *ngIf="rulesFormGroup.get('participantRestriction.isAgeRestricted').value"
          [showRange]="true"
          [fromLevel]="ageRestrictionFormGroup.getRawValue().fromAge"
          [toLevel]="ageRestrictionFormGroup.getRawValue().toAge"
          [step]="1"
          [unit]="t('GENERIC_WRAPPER.YEAR_PLURAL')"
          (setLevelEvent)="setAgeRange($event)"
        >
        </df-shared-lib-level-slider-material>
      </div>
      <div
        class="flex-column gap-10"
        [formGroup]="rulesFormGroup.get('participantRestriction.genderRestriction')"
      >
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.RULES.GENDER_RESTRICTION')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.RULES.GENDER_RESTRICTION_SUBTEXT')}}</span>
        <mat-checkbox
          class="checkbox-br"
          color="primary"
          formControlName="isMaleRestricted"
        >
          {{t('DANCE_MANAGER_COURSE.GENDER_RESTRICTION_MEN')}}
        </mat-checkbox>
  
        <mat-checkbox
          class="checkbox-br"
          color="primary"
          formControlName="isFemaleRestricted"
        >
          {{t('DANCE_MANAGER_COURSE.GENDER_RESTRICTION_WOMEN')}}
        </mat-checkbox>
  
        <mat-checkbox
          class="checkbox-br"
          color="primary"
          formControlName="isDiverseRestricted"
        >
          {{t('DANCE_MANAGER_COURSE.GENDER_RESTRICTION_DIVERSE')}}
        </mat-checkbox>
      </div>
      <div
        class="flex-column gap-10"
        [formGroup]="rulesFormGroup.controls.clothingRestriction"
        *ngIf="rulesFormGroup.controls.clothingRestriction as formGroup"
      >
        <mat-checkbox
          class="checkbox-br bold"
          color="primary"
          formControlName="isRestricted"
        >
          {{ t('DANCE_MANAGER_COURSE.RULES.DRESSCODE') }}
        </mat-checkbox>
        <df-shared-lib-quill-editor
          *ngIf="formGroup.controls.isRestricted.value"
          [hasError]="
            formGroup.controls.restriction.hasError('maxlength') ||
            (formGroup.controls.restriction.hasError('required') &&
              formGroup.controls.restriction.touched)
          "
          [enableMentions]="true"
          [enableCounter]="true"
          [maxLength]="1000"
          [id]="'clothingRestriction'"
          [placeholder]="'DANCE_MANAGER_COURSE.RULES.RULES_DESCRIPTION'"
          customFormControlName="restriction"
        ></df-shared-lib-quill-editor>
        <mat-error
          *ngIf="
            formGroup.controls.restriction.hasError('required') &&
            formGroup.controls.restriction.touched
          "
          class="custom-error"
          >{{t('DANCE_MANAGER_COURSE.RULES.DRESSCODE_RESTRICTION_ERROR_REQUIRED')}}</mat-error
        >
        <mat-error
          *ngIf="
            formGroup.controls.isRestricted.value &&
            formGroup.controls.restriction.hasError('maxlength')
          "
          class="custom-error"
          >{{t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH')}}
        </mat-error>
      </div>
    </div>
    <div class="w-100 gap-20 align-end-center mt1">
      <df-shared-lib-button
        materialButtonStyleType="outlined"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="onBackClick.emit()"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('DANCE_MANAGER_COURSE.STEPPER.CREATE_COURSE')"
        (buttonClicked)="onContinueClick.emit()"
        [isDisabled]="!rulesFormGroup.valid"
        [loading]="loading"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
