import { NotificationInterface, NotificationTargetTypeEnum, NotificationTypeEnum, NotificationUseCaseEnum } from '../../..';

export class NotificationDto implements NotificationInterface{
  id: string;
  createdAt: Date;
  notificationType: NotificationTypeEnum;
  notificationUseCase: NotificationUseCaseEnum;
  readAt: Date;
  fromTargetId: string;
  toTargetId: string;
  fromTargetType: NotificationTargetTypeEnum;
  toTargetType: NotificationTargetTypeEnum;
  useCaseSpecificData: object;
}
