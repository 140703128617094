import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'df-unavailable-for-web-dialog',
  templateUrl: './unavailable-for-web-dialog.component.html',
  styleUrls: ['./unavailable-for-web-dialog.component.scss'],
})
export class UnavailableForWebDialogComponent {
  constructor(
    private readonly matDialogRef: MatDialogRef<UnavailableForWebDialogComponent>
  ) {}

  close(): void {
    this.matDialogRef.close();
  }
}
