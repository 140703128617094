<ng-container *transloco="let t">
 <div class="flex-column gap-10" style="display: flex;">
    <mat-card class="social-link-container" *ngFor="let userLink of currentUser?.socialLinks; let i = index">
      <div class="flex-row link">
        <a [href]="userLink.url">
          <div class="flex-row" style="padding: 10px; border-radius: 1rem; min-width: 350px;">
            <img class="icon" height="26px" width="26px"  [src]="'assets/icons/social-icons/' + userLink.type.toLowerCase() + '.png'">
            <div style="margin-left: 10px;">
              <p style="display: inline-block" class="text text-link url-ellipsis">{{ userLink.url }}</p>
              <p class="text">{{ userLink.type }}</p>
            </div>
          </div>
        </a>
      </div>
      <div class="flex-column w-100" style="justify-content: center; align-items: end;">
        <button mat-button color="warn" (click)="deleteSocialLinks(i)" [matTooltip]="t('GENERIC_WRAPPER.DELETE')">
          <mat-icon> delete</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</ng-container>
