import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'df-edit-course-rules-form',
  templateUrl: './edit-course-rules-form.component.html',
  styleUrls: ['./edit-course-rules-form.component.scss']
})
export class EditCourseRulesFormComponent implements OnInit {
  @Input() rulesFormGroup: UntypedFormGroup;
  @Input() allowOverlapping: boolean;

  @Output() onSaveClick = new EventEmitter<void>();
  
  ngOnInit(): void {
    this.rulesFormGroup.get('participantRestriction.genderRestriction').valueChanges.subscribe((changes) => {
      this.rulesFormGroup.get('participantRestriction.isGenderRestricted').setValue(Object.values(changes).some((value) => value));
    });
    this.rulesFormGroup.get('clothingRestriction.isRestricted').valueChanges.subscribe((value) => {
      if (value) {
        this.rulesFormGroup.get('clothingRestriction.restriction').addValidators(Validators.required);
      } else {
        this.rulesFormGroup.get('clothingRestriction.restriction').removeValidators(Validators.required);
        this.rulesFormGroup.get('clothingRestriction.restriction').reset();
      }
    });
  }

  setAgeRange(event: { fromLevel: number; toLevel: number }): void {
    if (!(this.allowOverlapping === false && event.fromLevel === event.toLevel)) {
      this.rulesFormGroup.get('participantRestriction.ageRestriction.fromAge').setValue(event.fromLevel);
      this.rulesFormGroup.get('participantRestriction.ageRestriction.toAge').setValue(event.toLevel);
      if(event.fromLevel > event.toLevel) {
        this.ageRestrictionFormGroup.setErrors({ invalidRange: true });
      }
      else {
        this.ageRestrictionFormGroup.setErrors(null);
      }
      this.rulesFormGroup.markAsDirty();
    }
  }

  get ageRestrictionFormGroup (): UntypedFormGroup {
    return <UntypedFormGroup> this.rulesFormGroup.get('participantRestriction.ageRestriction');
  }

}
