import { RadiusUnitEnum, SearchPostingStatusEnum, SearchPostingTypeEnum } from '../../enums';
import { ImageInterface } from '../image.interface';
import { DancePartnerGenderEnum } from '../../enums/dps-posting/dance-partner-gender.enum';
import { DanceStyleRoleEnum } from '../../enums/dps-posting/dance-style-role.enum';
import { DpsPostingsToDanceStylesInterface } from './dps-postings-to-dance-styles.interface';
import { PlaceInterface } from '../place.interface';
import { UsersInterface } from '../users';

export interface DpsPostingInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  title: string;
  description: string;
  searchPostingType: SearchPostingTypeEnum;
  searchPostingStatus?: SearchPostingStatusEnum;
  mainImage?: ImageInterface;
  images?: ImageInterface[];
  user?: UsersInterface;
  dancePartnerGender?: DancePartnerGenderEnum;
  heightFrom?: number;
  heightTo?: number;
  ageFrom?: number;
  ageTo?: number;
  dancePartnerDanceRole?: DanceStyleRoleEnum;
  dancePartnerSearchPostingsToDanceStyles?: DpsPostingsToDanceStylesInterface[];
  place?: PlaceInterface;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
}
