<ng-container *transloco="let t">
  <mat-form-field
    class="w-100"
    *ngIf="!showChips; else withChips"
    appearance="outline"
  >
    <mat-label>{{ t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT') }}</mat-label>
    <input
      [formControl]="searchMusicStyleInput"
      matInput
      autocomplete="off"
      #musicStyleInput
      [matAutocomplete]="auto"
      (click)="matAutocompleteTrigger.openPanel()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectOption($event.option.value)"
      (closed)="resetFormControlValue()"
      hideSingleSelectionIndicator
    >
      <mat-option
        *ngFor="let musicStyle of filteredMusicStylesList | async"
        [disabled]="musicStyle | isDisabled: previouslySelectedOptions:true"
        [value]="musicStyle"
      >
        {{ t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase()) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="searchMusicStyleInput.hasError('required')"
      >Please select at least one music style.</mat-error
    >
  </mat-form-field>
  
  <ng-template #withChips>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT')}}</mat-label>
      <mat-chip-grid #chipList aria-label="Fruit selection">
        <mat-chip-row *ngFor="let selectedMusicStyle of selectedMusicStyles" (removed)="removeSelectedMusicStyle(selectedMusicStyle)">
          {{ t('GENERIC_WRAPPER.MUSIC_STYLES.' + selectedMusicStyle.translationKey.toUpperCase()) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="{{ t('GENERIC_WRAPPER.NEW_MUSIC_STYLE') }}..."
          #musicStyleInput
          autocomplete="off"
          (click)="matAutocompleteTrigger.openPanel()"
          [formControl]="searchMusicStyleInput"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectOption($event.option.value)"
        hideSingleSelectionIndicator>
        <mat-option
          *ngFor="let filteredMusicStyle of filteredMusicStylesList | async"
          [value]="filteredMusicStyle"
          [disabled]="filteredMusicStyle | isDisabled: selectedMusicStyles:true">
          {{ t('GENERIC_WRAPPER.MUSIC_STYLES.' + filteredMusicStyle.translationKey.toUpperCase()) }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="searchMusicStyleInput.hasError('required')">Please select at least one dance style.</mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
