import { PassStatusEnum } from '../../enums';
import { PassValidityInterface } from '../pass';

export interface DfmCoursePassesTableInterface {
  id?: string;
  name?: string;
  description?: string;
  accessTargetNames?: string[];
  targetIds?: string[];
  numberOfUses?: number;
  quantity?: number;
  soldQuantity?: number;
  price?: number;
  sequenceNumber?: number;
  usageCount?: number;
  status?: PassStatusEnum;
  validity?: PassValidityInterface;
}
