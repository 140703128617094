import { LastLoginInterface } from '../../..';
import { UserDto } from '../../..';

export class LastLoginDto implements LastLoginInterface {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  user: UserDto;
  loginDate: Date;
  
  constructor(args: LastLoginDto) {
    if (args) {
      this.id = args.id;
      this.createdAt = args.createdAt;
      this.updatedAt = args.updatedAt;
      this.userId = args.userId;
      this.user = args.user;
      this.loginDate = args.loginDate;
    }
  }
}
