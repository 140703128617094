<div class="div-style">
  <span class="material-icons-round">
sentiment_dissatisfied
</span>
  <h1>Error 403</h1>
  <p>
    You shall not dance here!
    <br />
    sorry,but you don't have access to this page
    <br />
    <br />
    <a href="https://www.danceflavors.com">Go back to Danceflavors.com</a>
  </p>
</div>
