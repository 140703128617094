import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProfileDanceStyle } from '@platri/df-common-shared-graphql';
import { DANCE_STYLES } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-dance-style-search-input',
  templateUrl: './df-dance-style-search-input.component.html',
  styleUrls: ['./df-dance-style-search-input.component.scss'],
})
export class DfDanceStyleSearchInputComponent implements OnInit {
  @Input() alreadySelectedDanceStyles: ProfileDanceStyle[];
  @Output() selectedDanceStyleTranslationKey = new EventEmitter<string>();

  danceStyleControl = new UntypedFormControl();
  filteredDanceStyleList: Observable<string[]>;
  danceStyleList: string[];

  private subscription = new Subscription();

  constructor(
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.getAllDanceStyles();
  }

  getAllDanceStyles(): void {
    this.danceStyleList = [];
    DANCE_STYLES.forEach((danceStyle) => {
      this.danceStyleList.push(danceStyle.translationKey);
    });
    this.filterAlreadySelectedDanceStyles();
  }

  filterAlreadySelectedDanceStyles(): void {
    this.danceStyleList = this.danceStyleList.filter(
      (danceStyle) => !this.alreadySelectedDanceStyles?.find(
        (selectedDanceStyle) => selectedDanceStyle.translationKey === danceStyle)
    );
    this.initializeFormControl();
  }

  initializeFormControl(): void {
    this.filteredDanceStyleList = this.danceStyleControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterDanceStyleList(value || ''))
    );
    this.sortDanceStyles();
  }

  onDanceStyleSelect(translationKey): void {
    this.selectedDanceStyleTranslationKey.emit(translationKey);
    this.danceStyleControl.reset();
    this.filterAlreadySelectedDanceStyles();
  }

  private filterDanceStyleList(value: string): string[] {
    return this.danceStyleList.filter((danceStyle) =>
      danceStyle?.toLowerCase().includes(value?.toLowerCase())
    );
  }

  private sortDanceStyles(): void {
    const danceStylesTranslations = this.translocoService
      .translate('GENERIC_WRAPPER.DANCE_STYLES');
    this.filteredDanceStyleList = this.filteredDanceStyleList.pipe(
      map((dancestyle) => {
        dancestyle.sort((a, b) => danceStylesTranslations[a.toUpperCase()] <
                                  danceStylesTranslations[b.toUpperCase()]
                                  ? -1
                                  : 1);
        return dancestyle;
      })
    );
  }
}
