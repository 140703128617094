import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DanceSchoolLocationPreviewCardComponent } from './dance-school-location-preview-card.component';
import { PipesModule } from '../../pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { ShareModule } from '../share';
import { ImageUploadCropperModule } from '../image-cropper';
import { TranslocoModule } from '@jsverse/transloco';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [DanceSchoolLocationPreviewCardComponent],
  imports: [CommonModule, MatIconModule, PipesModule, MatTooltipModule, RouterModule, NgxSkeletonLoaderModule, MatButtonModule, MatChipsModule, MatCardModule, MatMenuModule, ShareModule, ImageUploadCropperModule, TranslocoModule, MatDividerModule],
  exports: [DanceSchoolLocationPreviewCardComponent]
})
export class DanceSchoolLocationPreviewCardModule {}
