import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseInterface, DanceManagerInterface, DmPassSubscriptionInterface, DelayDialogInterface, UserAppLangEnum, UsersInterface, UserPassSubscriptionDto } from '@platri/df-common-core';
import { UserPassSubscriptionHttpService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { dfAppRoute, dfLoginRoute } from '../../shared';
import { DelayDialogComponent } from '../../dialogs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-pass-subscription-checkout-list-item',
  templateUrl: './pass-subscription-checkout-list-item.component.html',
  styleUrls: ['./pass-subscription-checkout-list-item.component.scss']
})
export class PassSubscriptionCheckoutListItemComponent {
  @Input() sub: DmPassSubscriptionInterface;
  @Input() courses: CourseInterface[];
  @Input() isOwnerOrAdmin: boolean;
  @Input() danceManager: DanceManagerInterface;
  @Input() currentUser: UsersInterface;
  @Input() userPassSubscriptions: UserPassSubscriptionDto[] = [];
  
  currentLang: UserAppLangEnum;
  loading = false;
  
  public showAllAccessibleCourses = false;

  constructor(
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
    private readonly userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly matDialog: MatDialog
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }
  
  openStripeCheckoutSession(): void {
    if (this.userHasSubscription()) {
      this.userPassSubscriptionHttpService.getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser().subscribe({
        next: (url) => {
          if (url !== null) {
            const delayDialogData: DelayDialogInterface = {
              delayTime: 3000,
              title: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.STARTING_SOON',
              text: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.HAS_SUBSCRIPTION'
            };
            const dialogRef = this.matDialog.open(DelayDialogComponent, {
              maxWidth: '400px',
              data: delayDialogData
            });
            dialogRef.afterClosed().subscribe(confirm => {
              if (confirm) {
                window.open(url, '_blank');
              }
            });
          }
        }, error: () => {
          this.loading = false;
        }
      });
    } else {
      if (this.currentUser) {
        this.loading = true;
        this.userPassSubscriptionHttpService.createCheckoutSession(this.sub.id).subscribe({
          next: (url) => {
            const delayDialogData: DelayDialogInterface = {
              delayTime: 3000,
              title: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.STARTING_SOON',
              text: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.BOUGHT_SUBSCRIPTION'
            };
            const dialogRef = this.matDialog.open(DelayDialogComponent, {
              maxWidth: '400px',
              data: delayDialogData
            });
            dialogRef.afterClosed().subscribe(confirm => {
              if (confirm) {
                window.open(url, '_blank');
              }
            });
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        });
      } else {
        this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
      }
    }
  }
  
  userHasSubscription(): boolean {
    return this.userPassSubscriptions?.findIndex((obj) => obj.dmPassSubscriptionId === this.sub.id) > -1;
  }
  
  routeToSubscriptionEdit(subscriptionId: string): void {
    this.router.navigate(['/dance-manager', this.danceManager?.id, 'courses', 'pass-subscription', subscriptionId]);
  }
}
