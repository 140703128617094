import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { UserRoleEnum } from '@platri/elab-common-auth-plug-play';
import { BUG_REPORT_FORM_DE, BUG_REPORT_FORM_EN, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, DelayDialogInterface, environmentForWeb, FEATURE_REQUEST_FORM_DE, FEATURE_REQUEST_FORM_EN, UsersInterface } from '@platri/df-common-core';
import { dfAppRoute, dfCreateDanceManagerRoute, dfLoginRoute, dfRegisterRoute } from '../../shared';
import { UserPassSubscriptionHttpService } from '../../services';
import { DelayDialogComponent } from '../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-header-user-button',
  templateUrl: './header-user-button.component.html',
  styleUrls: ['./header-user-button.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlapTrigger: false,
        xPosition: 'before',
        yPosition: 'below',
        backdropClass: 'cdk-overlay-transparent-backdrop',
        overlayPanelClass: 'header-user-button'
      }
    }
  ]
})
export class HeaderUserButtonComponent {
  @Input() currentUser: UsersInterface;
  @Output() logout = new EventEmitter<any>();
  
  isOpen = false;

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;
  
  constructor(
    private translocoService: TranslocoService,
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private matDialog: MatDialog
  ) {}
  
  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  menuClosed(): void {
    this.isOpen = false;
  }
  
  onLogout(event: any): void {
    event.stopPropagation();
    this.logout.emit();
  }

  openStripeBillingPortalSession(): void {
    this.userPassSubscriptionHttpService.getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser().subscribe({
      next: (url) => {
        if (url !== null) {
          const delayDialogData: DelayDialogInterface = {
            delayTime: 3000,
            title: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.STARTING_SOON',
            text: 'GENERIC_WRAPPER.MANAGE_SUBSCRIPTION_DIALOG.HAS_SUBSCRIPTION'
          };
          const dialogRef = this.matDialog.open(DelayDialogComponent, {
            maxWidth: '400px',
            data: delayDialogData
          });
          dialogRef.afterClosed().subscribe(confirm => {
            if (confirm) {
              window.open(url, '_blank');
            }
          });
        } else {
          // TODO: Show dialog that the user has no subscriptions yet
        }
      }
    });
  }
  
  openBugReport():void {
    const url = this.translocoService.getActiveLang().toUpperCase() === 'DE' ? BUG_REPORT_FORM_DE : BUG_REPORT_FORM_EN;
    window.open(url, '_blank');
  }

  openFeatureRequest():void  {
    const url = this.translocoService.getActiveLang().toUpperCase() === 'DE' ? FEATURE_REQUEST_FORM_DE : FEATURE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }
  
  protected readonly UserRoleEnum = UserRoleEnum;
  protected readonly dfCreateDanceManagerRoute = dfCreateDanceManagerRoute;
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  protected readonly dfRegisterRoute = dfRegisterRoute;
  protected readonly dfLoginRoute = dfLoginRoute;
  protected readonly dfAppRoute = dfAppRoute;
}
