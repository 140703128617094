export interface NotificationUseCaseSpecificDataInterface {
  fromTargetImageUrl: string;
  fromTargetUserName: string;
  fromUserId: string;
  toUserId: string;
  danceManagerUrlName?: string;
  danceManagerName?: string;
  danceSchoolUserRole?: string;
  danceSchoolName?: string;
  danceEventName?: string;
  danceEventId?: string;
  fromDanceEventId?: string;
  danceManagerLogoUrl?: string;
  danceSchoolId?: string;
  passId?: string;
  passName?: string;
  ticketId?: string;
  ticketName?: string;
  userPassId?: string;
  courseName?: string;
  courseId?: string;
  date?: Date;
}
