import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { HeaderAppModule } from './pages/app';
import { FrontendDfHeaderAppRoutingModule } from './frontend-df-header-app-routing.module';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoStateConst } from '@platri/dfx-angular-core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfHeaderAppRoutingModule,
    HeaderAppModule,
    TranslocoModule,
    MatSnackBarModule,
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendDfHeaderAppModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-header', alias: 'DANCEFLAVORS_HEADER' });
  }
}
