import { DanceManagerPayoutStatusEnum } from '../../enums';
import { DanceManagerPayoutTransactionInterface } from './dance-manager-payout-transaction.interface';
import { CurrencyInterface } from '../currency.interface';

export interface DanceManagerPayoutInterface {
  id: string;
  stripePayoutId: string;
  amount: number;
  stripeCreatedAt: Date;
  stripeArrivalAt: Date;
  currency: CurrencyInterface;
  status: DanceManagerPayoutStatusEnum;
  transactions: DanceManagerPayoutTransactionInterface[];
}
