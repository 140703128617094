import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MagicLinkLoginPageComponent } from './magic-link-login-page.component';
import { RouterModule } from '@angular/router';
import { MagicLoginFormModule } from '../../components/magic-login-page';

@NgModule({
  declarations: [MagicLinkLoginPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: '',
      component: MagicLinkLoginPageComponent
    }]),
    MatProgressSpinnerModule,
    MagicLoginFormModule
  ],
  exports: [MagicLinkLoginPageComponent]
})
export class MagicLinkLoginPageModule { }
