import { Component, Input, OnChanges } from '@angular/core';
import {
  ConfirmationDialogInterface,
  CourseAppointmentInterface,
  CourseInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  UserAppLangEnum
} from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  CoursesStateService,
  DanceSchoolStateService,
  dfmCourseRoute,
  dfmDanceManagerRoute
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Observable } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'df-course-appointments-table',
  templateUrl: './course-appointments-table.component.html',
  styleUrls: ['./course-appointments-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CourseAppointmentsTableComponent implements OnChanges {
  @Input() filteredAppointments: CourseAppointmentInterface[];
  @Input() course: CourseInterface;
  dataSource: CourseAppointmentInterface[];
  displayedColumns: string[] = ['date', 'duration', 'teachers', 'location', 'maxParticipants', 'menu'];  
  
  expandedElement: CourseAppointmentInterface | null;
  loading$: Observable<boolean>;
  currentLang: UserAppLangEnum;
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  constructor(
    private translocoService: TranslocoService,
    private coursesService: CoursesStateService, 
    private matDialog: MatDialog, 
    private router: Router, 
    private readonly route: ActivatedRoute,
    private danceSchoolService: DanceSchoolStateService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }
  
  ngOnChanges(): void {
    this.dataSource = this.filteredAppointments;
    this.loading$ = this.coursesService.loadingSubject$;
  }
  
  openDeleteConfirmationDialog(appointment: CourseAppointmentInterface): void {
   const dialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENT_CARD.DIALOG_TITLE'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENT_CARD.DIALOG_TEXT')
    };

    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      maxWidth: '350px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.coursesService.deleteCourseAppointmentById(appointment.id, this.course.id);
      }
    });
  }

  navigateToEditAppointmentsPage(appointment: CourseAppointmentInterface): void {
    this.router.navigate([
      dfmDanceManagerRoute,
      this.danceSchoolService.getSyncCurrentDanceSchool().id,
      dfmCourseRoute,
      'preview',
      this.course.id,
      'edit-appointments',
      appointment.id
    ]);
  }

  onAppointmentClick(appointment: CourseAppointmentInterface): void {
    this.router.navigate(['appointment', appointment.id], {relativeTo: this.route});
  }
}
