import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchListAdComponent } from './search-list-ad.component';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from '@platri/dfx-angular-core';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [SearchListAdComponent],
  imports: [
    CommonModule,
    RouterLink,
    MatIcon,
    PipesModule,
    TranslocoDirective
  ],
  exports: [SearchListAdComponent]
})
export class SearchListAdModule {}
