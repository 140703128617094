import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { DmSubscriptionPriceTableCardComponent } from './dm-subscription-price-table-card.component';

@NgModule({
  declarations: [DmSubscriptionPriceTableCardComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    MatDividerModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [DmSubscriptionPriceTableCardComponent],
})
export class DmSubscriptionPriceTableCardModule {}
