<ng-container *transloco="let t">
  <div class="flex-column align-center pl3 pr3">
    <div class="courses-manager-container w-100 flex-column pb2">
      <div class="courses-manager-breadcrumb">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="courses-manager-card-container">
        <mat-card style="background-color: white" class="courses-manager-card round-corners w-100 no-shadow-mat-card">
          <div class="courses-manager-outside-padding">
            <mat-tab-group
              [@.disabled]="true"
              [(selectedIndex)]="currentTabIndex"
              (selectedTabChange)="onTabChange($event)"
            >
              <mat-tab label="{{t('GENERIC_WRAPPER.COURSES')}}">
                <dfm-course-page
                  *ngIf="currentTabIndex === 0"
                  [danceSchool]="danceSchool"
                  (resetPagination)="resetPagination()"
                ></dfm-course-page>
              </mat-tab>
              <mat-tab label="{{t('GENERIC_WRAPPER.PASSES')}}">
                <dfm-course-passes-page
                  *ngIf="currentTabIndex === 1"
                  [passesFeatureActive]="passesFeatureActive"
                ></dfm-course-passes-page>
              </mat-tab>
              <mat-tab *ngIf="dmPassSubscriptionFeature" label="{{t('GENERIC_WRAPPER.SUBSCRIPTIONS')}}">
                <dfm-course-subscriptions-page 
                  *ngIf="currentTabIndex === 2" 
                  [subscriptionFeatureActive]="subscriptionFeatureActive"
                ></dfm-course-subscriptions-page>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
