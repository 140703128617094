import { AddressDto } from '../../..';

export class UpdateCourseAppointmentRequestDto {
  date: Date;
  duration: number;
  description?: string;
  locationId?: string;
  roomId?: string;
  address?: AddressDto;
  teacherUserIds: string[];
  maxParticipants?: number;
}
