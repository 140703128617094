<ng-container *transloco="let t">
  <div *ngIf="course" class="relative course-passes-container flex-column align-start-center w-100">
    <div class="bg-layer hide-on-mobile">
      <img [src]="course.mainImage?.url ?? DEFAULT_COURSE_PICTURE_PLACEHOLDER" alt="" />
    </div>
    <div class="content-card py2 w-100">
      <div class="w-100 back-btn-container">
        <div class="flex-column align-start hide-on-mobile" (click)="navigateToCoursePage()">
          <a class="flex-row align-start back-btn">
            <mat-icon>arrow_back</mat-icon>
            <span class="pl1 m-auto bold">{{ t('GENERIC_WRAPPER.COURSE') }}</span>
          </a>
        </div>
      </div>
      <div class="flex-row align-start pt2">
        <img class="course-image hide-on-mobile" [src]="course.mainImage?.url ?? DEFAULT_COURSE_PICTURE_PLACEHOLDER" alt="" /> 
        <div class="course-header-container flex-column w-100">
          <div class="flex-row align-space-between w-100 px2">
            <span style="font-size: 20px">
              <strong class="course-name-text">{{ course.name }}</strong>
            </span>
            <span *ngIf="!showMobileSummary" class="flex-column align-end-start back-btn pb1 hide-on-web" (click)="navigateToCoursePage()">
              <mat-icon>close</mat-icon>
            </span>
          </div>
          <span class="px2" style="font-size: 20px">{{ course.danceManager.name }}</span>
          <span *ngIf="nextAppointment" class="pt2 px2" style="font-size: 16px; color: #6f6f6f">{{ nextAppointment.date | dateTimezoned: 'UTC' : currentLang : nextAppointment.duration }} {{nextAppointment.address.timezoneId | timezoneAbbreviation : nextAppointment.date }}</span>
        </div>
      </div>
      
      <mat-divider class="mt2 hide-on-web"></mat-divider>
      
  <!--    <div *ngIf="!dmPassSubscriptionFeature" class="card-container flex-column mt3 w-100" style="gap: 1rem;">-->
  <!--      <div *ngIf="showGuestInputFields" [formGroup]="guestEmailForm" class="flex-column-center-center w-100">-->
  <!--        <span>Bitte geben Sie eine Email Adresse an, an die die Tickets verschickt werden sollen.</span>-->
  <!--        <div class="flex-row-center-center w-100 gap-10 pt2">-->
  <!--          <mat-form-field appearance="outline">-->
  <!--            <mat-label>Email</mat-label>-->
  <!--            <input matInput formControlName="email">-->
  <!--          </mat-form-field>-->
  <!--          <mat-form-field appearance="outline">-->
  <!--            <mat-label>Email bestätigen</mat-label>-->
  <!--            <input matInput formControlName="confirmEmail">-->
  <!--          </mat-form-field>-->
  <!--        </div>-->
  <!--        <df-shared-lib-button-->
  <!--          [buttonLabel]="'Abschicken'"-->
  <!--          [isDisabled]="guestEmailForm.invalid || creationTicketOrderLoading"-->
  <!--          (buttonClicked)="continueToCheckout()"-->
  <!--        ></df-shared-lib-button>-->
  <!--      </div>-->
  <!--      -->
  <!--      <div-->
  <!--        *ngIf="!showGuestInputFields && passesForSale && passesForSale?.length > 0 && !showMobileSummary"-->
  <!--        class="passes-list-container flex-column"-->
  <!--        style="gap: 1rem;"-->
  <!--        [formGroup]="formGroup"-->
  <!--      >-->
  <!--        <div *ngFor="let pass of passesForSale; let last = last" class="flex-column" style="gap: 0.75rem;">-->
  <!--          <df-pass-checkout-list-item-->
  <!--            [courses]="filterUserPassCoursesByPassId(pass.passTargets)"-->
  <!--            [pass]="pass"-->
  <!--            [formGroup]="formGroup"-->
  <!--          ></df-pass-checkout-list-item>-->
  <!--          <mat-divider *ngIf="!last"></mat-divider>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      -->
  <!--      <div-->
  <!--        *ngIf="!showGuestInputFields && passesForSale && passesForSale.length === 0"-->
  <!--        class="passes-list-container flex-column"-->
  <!--      >-->
  <!--        <df-pass-checkout-empty-list></df-pass-checkout-empty-list>-->
  <!--      </div>-->
  <!--      -->
  <!--      <div *ngIf="!showGuestInputFields"  class="checkout-detail-container">-->
  <!--        &lt;!&ndash; Checkout summary &ndash;&gt;-->
  <!--        <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'" style="margin-bottom: 20px;">-->
  <!--          <df-pass-checkout-list-summary [selectedPasses]="selectedPasses" [form]="formGroup" [subtotal]="subtotal" [fees]="fees" (closeSummary)="showMobileSummary = false"></df-pass-checkout-list-summary>-->
  <!--        </div>-->
  <!--        &lt;!&ndash; Payment methods &ndash;&gt;-->
  <!--        <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'" style="padding: 20px;">-->
  <!--          <div class="flex-row align-space-between" style="margin-bottom: 12px">-->
  <!--            <span>{{ 'EVENT_TICKETS_DIALOG.WE_ACCEPT' | translate }}</span>-->
  <!--          </div>-->
  <!--          <df-payment-methods-view></df-payment-methods-view>-->
  <!--        </div>-->
  <!--        &lt;!&ndash; Checkout button &ndash;&gt;-->
  <!--        <div class="flex-column-center-center py2 hide-on-mobile">-->
  <!--          <df-shared-lib-button-->
  <!--            *ngIf="currentUser"-->
  <!--            class="w-100"-->
  <!--            [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading"-->
  <!--            [buttonLabel]="'CHECKOUT.HTML.CHECKOUT' | translate"-->
  <!--            (buttonClicked)="continueToCheckout(true)"-->
  <!--          ></df-shared-lib-button>-->
  <!--          <df-shared-lib-button-->
  <!--            *ngIf="!currentUser"-->
  <!--            class="w-100"-->
  <!--            [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading"-->
  <!--            [buttonLabel]="'LOGIN_AND_BUY' | translate"-->
  <!--            (click)="continueToCheckout(true)"-->
  <!--          ></df-shared-lib-button>-->
  <!--          <span-->
  <!--            *ngIf="!currentUser && !selectedPassesContainTrialPass()"-->
  <!--            [class]="selectedPasses.length === 0 ? 'continue-as-guest-button-disabled' : 'continue-as-guest-button'"-->
  <!--            (click)="continueToCheckout()"-->
  <!--          >{{ 'CONTINUE_AS_GUEST' | translate }}</span>-->
  <!--        </div>-->
  <!--        <div class="py1 px1 hide-on-mobile">-->
  <!--          {{ 'EVENT_TICKETS_DIALOG.NEED_HELP' | translate }}-->
  <!--          <a style="text-decoration: none; color: #3377FF" target="_blank" contactUrl>-->
  <!--            {{ 'EVENT_TICKETS_DIALOG.CONTACT_US' | translate }}-->
  <!--          </a>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      &lt;!&ndash; Space for sticky button on mobile &ndash;&gt;-->
  <!--      <div class="py3 hide-on-web"></div>-->
  <!--    </div>-->
  <!--    -->
      <mat-tab-group *ngIf="dmPassSubscriptionFeature && showTabGroup()" class="flex-column item-list-container" style="margin-top: 20px;" [(selectedIndex)]="currenTabIndex" (selectedTabChange)="onTabChange($event)">
        <mat-tab [label]="t('GENERIC_WRAPPER.PASSES')">
          <ng-container *ngTemplateOutlet="passesTab"></ng-container>
        </mat-tab>
        <mat-tab [label]="t('GENERIC_WRAPPER.SUBSCRIPTIONS')">
          <ng-container *ngTemplateOutlet="subscriptionsTab"></ng-container>
        </mat-tab>
      </mat-tab-group>
      
      <div *ngIf="!showTabGroup()">
        <div *ngIf="passesForSale && passesForSale?.length > 0" class="flex-column item-list-container">
          <ng-container *ngTemplateOutlet="passesTab"></ng-container>
        </div>
        <div *ngIf="passSubscriptionsForSale && passSubscriptionsForSale?.length > 0" class="flex-column item-list-container">
          <ng-container *ngTemplateOutlet="subscriptionsTab"></ng-container>
        </div>
      </div>
    </div>
    
    <!-- Sticky checkout button -->
    <div class="flex-column-center-center sticky-button-container hide-on-web">
      <div class="flex-row align-space-between w-100 pt2 px2">
        <!-- Toggle summary button -->
        <div *ngIf="selectedPasses && selectedPasses.length > 0" class="flex-row" style="cursor: pointer" (click)="showMobileSummary = !showMobileSummary">
          <i [class]="selectedPasses.length === 0 ? '' : 'fa badge fa-lg'"><mat-icon style="color: #3377FF">shopping_cart</mat-icon></i>
          <span *ngIf="selectedPasses.length > 0" class="wrapper-span">{{ selectedPasses.length }}</span>
  
          <span>
            <mat-icon style="color: #4d4d4d" [class]="showMobileSummary ? 'expand-open expand-button' : 'expand-button'">expand_more</mat-icon>
          </span>
        </div>
        
        <!-- Total Price -->
        <span>{{ this.subtotal || 0 | centsToCurrencyString: 'EUR' }}</span>
      </div>
      <!-- Checkout Button -->
      <div class="w-100 px2 pb2 pt1 gap-10 flex-column">
        <df-shared-lib-button 
          *ngIf="currentUser" 
          [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading" 
          [buttonLabel]="t('GENERIC_WRAPPER.CHECKOUT')" 
          (click)="continueToCheckout(true)"
        ></df-shared-lib-button>
        <df-shared-lib-button
          *ngIf="!currentUser" class="w-100" 
          [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading" 
          [buttonLabel]="t('GENERIC_WRAPPER.LOGIN_AND_BUY')" 
          (click)="continueToCheckout(true)"
        ></df-shared-lib-button>
<!--        <df-shared-lib-button -->
<!--          *ngIf="!currentUser && !selectedPassesContainTrialPass()" -->
<!--          class="w-100" -->
<!--          [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading" -->
<!--          [buttonLabel]="t('GENERIC_WRAPPER.CONTINUE_AS_GUEST')" -->
<!--          (click)="continueToCheckout()"-->
<!--        ></df-shared-lib-button>-->
      </div>
    </div>
  </div>
  
  <ng-template #passesTab>
    <div class="card-container flex-column mt3 w-100" style="gap: 1rem;">
      <div *ngIf="showGuestInputFields" [formGroup]="guestEmailForm" class="flex-column-center-center w-100">
        <span>{{ t('DANCE_COURSE.PLEASE_ENTER_EMAIL_ADDRESS') }}</span>
        <div class="flex-row-center-center w-100 gap-10 pt2">
          <mat-form-field appearance="outline">
            <mat-label>email</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.EMAIL_CONFORMATION') }}</mat-label>
            <input matInput formControlName="confirmEmail">
          </mat-form-field>
        </div>
        <df-shared-lib-button
          [buttonLabel]="t('GENERIC_WRAPPER.SEND')"
          [isDisabled]="guestEmailForm.invalid || creationTicketOrderLoading"
          (buttonClicked)="continueToCheckout()"
        ></df-shared-lib-button>
      </div>
      
      <div
        *ngIf="!showGuestInputFields && !showMobileSummary"
        class="passes-list-container flex-column"
        style="gap: 1rem;"
        [formGroup]="formGroup"
      >
        <div *ngFor="let pass of passesForSale; let last = last" class="flex-column" style="gap: 0.75rem;">
          <df-pass-checkout-list-item
            [courses]="filterUserPassCoursesByPassId(pass.passTargets)"
            [pass]="pass"
            [formGroup]="formGroup"
          ></df-pass-checkout-list-item>
          <mat-divider *ngIf="!last"></mat-divider>
          <div class="hide-on-web" style="padding-bottom: 50px;"></div>
        </div>
      </div>
      
      <div
        *ngIf="!showGuestInputFields && passesForSale && passesForSale.length === 0"
        class="passes-list-container flex-column"
      >
        <df-pass-checkout-empty-list></df-pass-checkout-empty-list>
      </div>
      
      <div *ngIf="!showGuestInputFields"  class="checkout-detail-container">
        <!-- Checkout summary -->
        <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'" style="margin-bottom: 20px;">
          <df-pass-checkout-list-summary [selectedPasses]="selectedPasses" [form]="formGroup" [subtotal]="subtotal" [fees]="fees" (closeSummary)="showMobileSummary = false"></df-pass-checkout-list-summary>
        </div>
        <!-- Payment methods -->
        <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'" style="padding: 20px;">
          <div class="flex-row align-space-between" style="margin-bottom: 12px">
            <span>{{ t('GENERIC_WRAPPER.WE_ACCEPT') }}</span>
          </div>
          <df-payment-methods-view></df-payment-methods-view>
        </div>
        <!-- Checkout button -->
        <div class="flex-column-center-center py2 hide-on-mobile">
          <df-shared-lib-button
            *ngIf="currentUser"
            class="w-100"
            [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading"
            [buttonLabel]="t('GENERIC_WRAPPER.CHECKOUT')"
            (buttonClicked)="continueToCheckout(true)"
          ></df-shared-lib-button>
          <df-shared-lib-button
            *ngIf="!currentUser"
            class="w-100"
            [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading"
            [buttonLabel]="t('GENERIC_WRAPPER.LOGIN_AND_BUY')"
            (click)="continueToCheckout(true)"
          ></df-shared-lib-button>
<!--          <span-->
<!--            *ngIf="!currentUser && !selectedPassesContainTrialPass()"-->
<!--            [class]="selectedPasses.length === 0 ? 'continue-as-guest-button-disabled' : 'continue-as-guest-button'"-->
<!--            (click)="continueToCheckout()"-->
<!--          >{{ t('GENERIC_WRAPPER.CONTINUE_AS_GUEST') }}</span>-->
        </div>
        <div class="py1 px1 hide-on-mobile">
          {{ t('GENERIC_WRAPPER.NEED_HELP') }}
          <a style="text-decoration: none; color: #3377FF" target="_blank" contactUrl>
            {{ t('GENERIC_WRAPPER.CONTACT_US') }}
          </a>
        </div>
      </div>
  <!-- Space for sticky button on mobile -->
      <div class="py3 hide-on-web"></div>
    </div>
  </ng-template>
  
  <ng-template #subscriptionsTab>
    <div
      *ngIf="!showGuestInputFields && !showMobileSummary"
      class="passes-list-container mt3 flex-column"
    >
      <div *ngFor="let sub of passSubscriptionsForSale; let last = last">
        <div *ngIf="filterUserPassSubscriptionCoursesByPassId(sub.targets).length > 0" class="flex-column" style="gap: 0.75rem;">
          <df-pass-subscription-checkout-list-item
            [courses]="filterUserPassSubscriptionCoursesByPassId(sub.targets)"
            [sub]="sub"
            [currentUser]="currentUser"
            [userPassSubscriptions]="currentUserPassSubscriptions"
          ></df-pass-subscription-checkout-list-item>
          <mat-divider *ngIf="!last" style="margin-bottom: 1rem;"></mat-divider>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
