import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UsersInterface } from '@platri/df-common-core';
import { UserHttpService } from '../http-services';
import { getSubscription } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class UserStateService implements OnDestroy {
  isLoadingUser = new BehaviorSubject<boolean>(true);
  updateUserFailed = new BehaviorSubject<boolean>(false);
  selectedUser = new BehaviorSubject<UsersInterface | null>(null);
 
  subscriptions = new Subscription();

  constructor(
    private userHttpService: UserHttpService
  ) {
    console.log('Initializing UserStateService');
  }

  ngOnDestroy(): void {
    console.log("Destroying PokemonStateService");
    this.subscriptions.unsubscribe();
    this.selectedUser.unsubscribe();
    this.updateUserFailed.unsubscribe();
    this.isLoadingUser.unsubscribe();
  }

  getSyncSelectedUser(): UsersInterface | null {
    return this.selectedUser.getValue();
  }

  getAsyncSelectedUser(): Observable<UsersInterface | null> {
    return this.selectedUser.asObservable();
  }

  getSyncIsLoadingUser(): boolean {
    return this.isLoadingUser.getValue();
  }

  getAsyncIsLoadingUser(): Observable<boolean> {
    return this.isLoadingUser.asObservable();
  }

  getAsyncUpdateFailed(): Observable<boolean> {
    return this.updateUserFailed.asObservable();
  }
  
  loadCurrentUser(): void {
    this.subscriptions.add(getSubscription(this.userHttpService.getCurrentUser(), this.onLoadedSelectedUser.bind(this), () => {console.log("Couldn't load Current User!"); this.sendIsLoadingUser(false);}));
  }
  
  updateCurrentUser(user: UsersInterface): void {
    this.subscriptions.add(getSubscription(this.userHttpService.updateUser(user), this.onLoadedSelectedUser.bind(this), () => {console.log("Couldn't update Current User!"); this.sendUpdateUserFailed(true); this.sendIsLoadingUser(false);}));
  }

  onLoadedSelectedUser(user: UsersInterface): void {
    this.sendUpdateUserFailed(false);
    this.sendIsLoadingUser(false);
    this.sendSelectedUser(user);
  }

  private sendSelectedUser(user: UsersInterface): void {
    this.selectedUser.next(user);
  }

  private sendIsLoadingUser(isLoading: boolean): void {
    this.isLoadingUser.next(isLoading);
  }
  
  private sendUpdateUserFailed(failed: boolean): void {
    this.updateUserFailed.next(failed);
  }


}
