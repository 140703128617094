import { BillingOrShippingAddressInterface } from '../../..';

export class BillingOrShippingAddressDto implements BillingOrShippingAddressInterface {
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  city: string;
  country: string;
  zipCode: string;
  state: string;
  addressLine1?: string;
  isDefault: boolean;
}
