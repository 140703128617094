<ng-container *transloco="let t">
  <div class="hide-toolbar">
    <quill-editor
      style="width: 100%"
      matInput
      [placeholder]="t('GENERIC_WRAPPER.MESSAGE')"
      [modules]="modules"
      [formats]="undefined"
      (onContentChanged)="onContentChanged($event)"
      (onEditorCreated)="setFocus($event)"
      (keydown)="onKeyEnterPressed($event)"
    >
    </quill-editor>
  </div>
</ng-container>
