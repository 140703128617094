import { NotificationDataInterface, NotificationUseCaseEnum } from '../../..';

export class GuestListInvitationDataNotificationDto implements NotificationDataInterface {
  public readonly useCase = NotificationUseCaseEnum.GUEST_LIST_INVITATION;
  
  constructor(
    public readonly useCaseSpecificData: {
      toUserId: string;
      danceEventId: string;
      fromUserId: string;
      danceEventName: string;
    }
  ) {}
}
