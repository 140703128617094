import { Component, Input } from '@angular/core';

@Component({
  selector: 'dfm-sidenav-courses',
  templateUrl: './dfm-sidenav-courses.component.html',
  styleUrls: ['./dfm-sidenav-courses.component.scss'],
})
export class DfmSidenavCoursesComponent {
  @Input() isExpanded: boolean;
}
