import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfQuillMentionMessengerComponent } from './df-quill-mention-messenger.component';
import { QuillModule } from 'ngx-quill';
import { PipesModule } from '../../pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DfQuillMentionMessengerComponent],
  imports: [
    CommonModule,
    QuillModule,
    PipesModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    TranslocoDirective
  ],
  exports: [DfQuillMentionMessengerComponent],
})
export class DfQuillMentionMessengerModule {}
