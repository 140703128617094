import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrialLessonInviteDialogComponent } from './trial-lesson-invite-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '../../pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { DfSharedButtonModule } from '../buttons';
import { DfDanceStyleEditModule } from '../df-dance-style-edit';
import { UserSearchAutocompleteModule } from '../user-search-autocomplete';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { TranslocoDirective } from "@jsverse/transloco";

@NgModule({
  exports: [TrialLessonInviteDialogComponent],
  declarations: [TrialLessonInviteDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    DfSharedButtonModule,
    DfDanceStyleEditModule,
    MatExpansionModule,
    MatDividerModule,
    PipesModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UserSearchAutocompleteModule,
    MatInputModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSelect,
    TranslocoDirective
  ]
})
export class TrialLessonInviteDialogModule {}
