import { Pipe, PipeTransform } from '@angular/core';
import { addMinutes } from "date-fns";

@Pipe({
  name: 'timezoneOffsetDate',
})
export class TimezoneOffsetDatePipe implements PipeTransform {
  transform(date: Date, offsetMinutes: number): Date {
    if (date === null) {
      return null;
    }

    return addMinutes(new Date(date), (new Date().getTimezoneOffset()) + offsetMinutes);
  }
}
