import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserHttpService } from '../../services';
import { tap } from 'rxjs/operators';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UsersInterface } from '@platri/df-common-core';
import { TransferTypeEnum } from '../../enums';

@Component({
  selector: 'df-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss'],
})
export class TransferDialogComponent implements OnInit, OnDestroy {
  searchInputChangeSubject = new Subject<string>();
  showUserSearchLoadingSpinner = false;

  userOptions: any[] = [];
  selectedUser: UsersInterface;
  selectedUsername: string;
  
  transferType: TransferTypeEnum;
  confirmTransfer = false;

  private subscriptions: Subscription = new Subscription();
  
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { transferType: TransferTypeEnum },
    private readonly userHttpService: UserHttpService,
    private readonly dialogRef: MatDialogRef<TransferDialogComponent>
  ) {}

  ngOnInit(): void {
    this.transferType = this.data.transferType;
    this.subscribeToSearch();
  }

  onUserSelected($event: any): void {
    this.selectedUser = $event;
    this.selectedUsername = $event.data.username;
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
  
  onRemovingSelectedUser(): void {
    this.selectedUser = null;
    this.selectedUsername = null;
  }

  onSendClick(): void {
    this.confirmTransfer = true;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  
  onAcceptClick(): void {
    this.dialogRef.close({ selectedUser: this.selectedUser });
  }
  
  onDeclineClick(): void {
    this.confirmTransfer = false;
  }
  
  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject
        .pipe(
          tap(() => (this.showUserSearchLoadingSpinner = true)),
          debounceTime(600)
        )
        .subscribe((inputText: string) => {
          if (inputText) {
            this.subscriptions.add(
              this.userHttpService
                .searchUsersByKey(inputText)
                .pipe(
                  tap(() => {
                    this.showUserSearchLoadingSpinner = false;
                  })
                )
                .subscribe((users: UsersInterface[]) => {
                  this.userOptions = this.mapToOptions(users);
                })
            );
          } else {
            this.userOptions = [];
            this.showUserSearchLoadingSpinner = false;
          }
        })
    );
  }

  private mapToOptions(users: UsersInterface[]): {id: string; value: string; data: UsersInterface; displayValue: string; info: string; imageUrl: string}[] {
    return users.map((user) => ({
      id: user.id,
      value: user.username,
      data: user,
      displayValue: user.username,
      info: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null,
      imageUrl: user.imageUrl ?? null,
    }));
  }

  protected readonly TransferTypeEnum = TransferTypeEnum;
}
