import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StripePaymentComponent } from './stripe-payment.component';
import { environment } from '@platri/df-common-core';
import { DfSharedButtonModule } from '../../buttons';
import {MatCheckbox} from "@angular/material/checkbox";
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  exports: [StripePaymentComponent],
  declarations: [StripePaymentComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DfSharedButtonModule,
    MatCheckbox,
    TranslocoDirective
  ]
})
export class StripePaymentModule {}
