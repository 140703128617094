<ng-container *transloco="let t">
  <div class="flex-column" [formGroup]="formGroup">
    <div class="pt3 pb3 pl1 flex-column w-100" style="gap: 2rem;">
      <span><strong>{{ t('DANCE_MANAGER_EVENT.DATE_AND_TIME') }}</strong></span>
      <div class="flex-column">
        <mat-form-field (click)="startDate.showPicker()" appearance="outline" class="w-15-gt-sm w-30-lt-md w-50-lt-sm">
          <mat-label>{{ t('DANCE_MANAGER_EVENT.EVENT_DATE_START') }}</mat-label>
          <input type="datetime-local" matInput #startDate formControlName="startDate"/>
          <mat-icon matSuffix fontIcon="today"/>
        </mat-form-field>
        <mat-form-field (click)="endDate.showPicker()" appearance="outline" class="w-15-gt-sm w-30-lt-md w-50-lt-sm">
          <mat-label>{{ t('DANCE_MANAGER_EVENT.EVENT_DATE_END') }}</mat-label>
          <input type="datetime-local" matInput #endDate formControlName="endDate" [min]="formGroup.get('startDate').value"/>
          <mat-icon matSuffix fontIcon="today"/>
        </mat-form-field>
        <mat-error *ngIf="formGroup.hasError('dateRangeInvalid')">
            {{ t('DANCE_MANAGER_EVENT.EVENT_DATE_END_MUST_BE_LATER_START_DATE') }}
        </mat-error>
      </div>
      <div
        class="mt3 flex-column"
        style="gap: 0.5rem;"
      >
        <span
          ><strong>{{ t('GENERIC_WRAPPER.EVENT_LOCATION') }}</strong></span
        >
        <mat-radio-group
          [formControl]="locationTypeControl"
          color="primary"
          class="flex-column"
        >
          <mat-radio-button [value]="locationAddressEnum.LOCATION" [disabled]="locations.length === 0">
            <span class="word-break">{{ t('DANCE_MANAGER_EVENT.CHOOSE_LOCATION') }}</span>
          </mat-radio-button>
          <mat-form-field
            class="mt2 w-30-gt-sm w-100-lt-sm"
            appearance="outline"
            hideRequiredMarker="{{locations.length === 0}}"
          >
            <mat-label>{{
                t((locations.length === 0 ? 'DANCE_MANAGER_EVENT.NO_LOCATIONS' : 'DANCE_MANAGER_EVENT.LOCATION_NAME'))
            }}</mat-label>
            <mat-select
              [disableControl]="!locationTypeControl.value || locationTypeControl.value === locationAddressEnum.ADDRESS"
              formControlName="locationId"
              disableOptionCentering
              data-cy="event-appointment-location-select"
            >
              <mat-option
                *ngFor="let location of locations"
                [value]="location.id"
              >
                {{ location.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-radio-button class="mt2" [value]="locationAddressEnum.ADDRESS"
            ><span>{{ t('DANCE_MANAGER_EVENT.MANUAL_ADDRESS') }}</span></mat-radio-button
          >
          <df-shared-lib-google-address-search-autocomplete
            [disabled]="true"
            [address]="formGroup.controls.address.value"
            [disableCondition]="
              !locationTypeControl.value ||
              locationTypeControl.value !== locationAddressEnum.ADDRESS
            "
            (addressChanging)="onAddressSelected()"
            (addressSelected)="onAddressSelected($event)"
            [houseNumberRequired]="true"
            class="mt2 w-30-gt-sm w-100-lt-sm"
            id="google-address-search-input"
          >
          </df-shared-lib-google-address-search-autocomplete>
        </mat-radio-group>
      </div>
    </div>
     <div
       class="flex-responsive-container"
     >
        <div
          class="px3 flex-responsive-button-container"
          style="gap: 1rem;"
        >
          <df-shared-lib-button
            *ngIf="showCancelButton"
            [buttonLabel]="cancelButtonLabel"
            (buttonClicked)="cancelClickEventEmitter.emit()"
            materialButtonStyleType="outlined"
          >
          </df-shared-lib-button>
          <df-shared-lib-button
            [buttonLabel]="submitButtonLabel"
            [isDisabled]="formGroup.invalid"
            (buttonClicked)="submitClickEventEmitter.emit()"
            id="continue-button-2"
          >
          </df-shared-lib-button>
        </div>
      </div>
  </div>
</ng-container>
