import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DmPackagesCardItemComponent } from './dm-packages-card-item.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '../../pipes';
import { ContactUrlDirectiveModule } from '../../directives';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DmPackagesCardItemComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      PipesModule,
      MatDividerModule,
      MatSlideToggleModule,
      MatCardModule,
      ContactUrlDirectiveModule,
      TranslocoDirective,
    ],
  providers: [],
  exports: [DmPackagesCardItemComponent],
})
export class DmPackagesCardItemModule {}
