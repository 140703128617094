import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mustMatch(controlName: string, matchControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const matchControl = formGroup.get(matchControlName);

    if (!matchControl || !control) {
      return null;
    }
    
    if (matchControl?.errors && !matchControl?.errors?.mustMatch) {
      return null;
    }

    if (control.value !== matchControl.value) {
      matchControl.setErrors({ mustMatch: true });
      return { mustMatch: true };
    } else {
      matchControl.setErrors(null);
      return null;
    }
  };
}
