import { ParticipantRestrictionInterface } from '../participant-restriction.interface';
import { RestrictionInterface } from '../restriction.interface';

export interface CourseRulesInterface {
  participantRestriction?: ParticipantRestrictionInterface;
  clothingRestriction?: RestrictionInterface;
}



