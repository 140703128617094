import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsViewComponent } from './payment-methods-view.component';

@NgModule({
  declarations: [PaymentMethodsViewComponent],
  exports: [PaymentMethodsViewComponent],
    imports: [CommonModule, ]
})
export class PaymentMethodsViewModule {}
