<ng-container>
  <div>
    <span class="text fs16" ngClass.lt-md="fs14">{{ label }}</span>
    <div class="level-bar-container">
      <div
        class="flex-column align-start-center"
        [class]="'level-bar ' + color"
        [style.width]="endPoint + '%'"
      ></div>
    </div>
  </div>
</ng-container>
