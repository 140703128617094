<ng-container *transloco="let t">
  <a [routerLink]="['/', 'dance-event', event.id]" class="preview-card flex-column w-100">
    <img [src]="event.mainImage?.url ?? DEFAULT_EVENT_PICTURE_PLACEHOLDER" alt="" class="preview-image"/>
    <div class="card-info-box flex-column">
      <div class="flex-row align-space-between w-100" style="gap: 0.5em">
        <div class="flex-column w-100">
          <span class="card-title ellipsis-1"><strong>{{event.name}}</strong></span>
          <div class="card-content">
            <span class="card-description ellipsis-1">{{event.danceSchool?.name}}</span>
            <span class="card-description">{{(event.appointments ? (event.appointments[0].startDate | dateTimeStringDanceEvent : event.appointments[0].duration) : '')}}</span>
          </div>
        </div>
        <div *ngIf="event.searchStatus" class="card-icon align-center">
          <img *ngIf="event.searchStatus === searchStatus.NEW" [src]="'assets/icons/search-card-icons/new_logo.svg'" alt="" />
          <img *ngIf="event.searchStatus === searchStatus.SALE_ENDS_SOON" [src]="'assets/icons/search-card-icons/acute.svg'" alt="" />
          <img *ngIf="event.searchStatus === searchStatus.POPULAR" [src]="'assets/icons/search-card-icons/mode_heat.svg'" alt="" />
        </div>
      </div>
      <div class="flex-row align-start-center align-space-between">
        <span class="card-description" *ngIf="event.isSoldOut">{{t('GENERIC_WRAPPER.SOLD_OUT')}}</span>
        <span class="card-description" *ngIf="!event.isSoldOut && event.cheapestPrice !== null && event.cheapestPrice >= 0">{{t('GENERIC_WRAPPER.TICKETS_START_AT') + ' '}}<strong>{{ event.cheapestPrice | centsToCurrencyString: 'EUR' }}</strong></span>
        <span class="card-description" *ngIf="!event.cheapestPrice"></span>
        <span class="card-description" *ngIf="event.appointments">{{event.appointments[0]?.address?.city ? event.appointments[0].address.city : ''}}</span>
      </div>
    </div>
  </a>
</ng-container>
