import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmCreateUserGroupPageComponent } from './dfm-create-user-group-page.component';
import { RouterModule, Routes } from '@angular/router';
import { DfmCreateUserGroupFormModule } from '../../components/dfm-create-user-group-form/dfm-create-user-group-form.module';

const routes: Routes = [

  {
    path: '',
    component: DfmCreateUserGroupPageComponent,
  },
];

@NgModule({
  declarations: [DfmCreateUserGroupPageComponent],
  imports: [
    CommonModule,
    DfmCreateUserGroupFormModule,
    RouterModule.forChild(routes)
  ],
  exports: [DfmCreateUserGroupPageComponent]
})
export class DfmCreateUserGroupPageModule {}
