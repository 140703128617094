import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'df-monorepo-dm-subscription-price-table-card',
  templateUrl: './dm-subscription-price-table-card.component.html',
  styleUrls: ['./dm-subscription-price-table-card.component.scss'],
})
export class DmSubscriptionPriceTableCardComponent implements AfterViewInit {
  @Input() stripePricingTableId: string;
  @Input() stripePublishableKey: string;
  @Input() stripeClientReferenceId: string;

  @ViewChild('stripeDiv') divBinder: ElementRef<HTMLElement>;

  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.innerHTML = `<stripe-pricing-table
      pricing-table-id="${this.stripePricingTableId}"
      publishable-key="${this.stripePublishableKey}"
      client-reference-id="${this.stripeClientReferenceId}">
    </stripe-pricing-table>`;
  }
}
