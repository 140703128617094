import { RouterModule, Routes } from '@angular/router';
import { SettingsPage } from './settings.page';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    component: SettingsPage,
    children: [],
  },
  {
    path: '404',
    loadChildren: () =>
      import('@platri/dfx-angular-core').then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
