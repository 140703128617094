import { Component, Input, OnInit } from '@angular/core';
import { QuillModules } from 'ngx-quill/config';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import Quill from 'quill';
import Counter from '../../helpers/quill-counter-module';

Quill.register('modules/counter', Counter);

@Component({
  selector: 'df-shared-lib-quill',
  templateUrl: './df-quill.component.html',
  styleUrls: ['./df-quill.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class DfQuillComponent implements OnInit {

  @Input() customFormControlName: string;
  @Input() hasError = false;
  @Input() disableRichText = false;
  @Input() enableCounter = false;
  @Input() maxLength = 2000;
  
  modules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ script: 'sub' }, { script: 'super' }],
      [{ align: [] }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ size: ['small', false, 'large', 'huge'] }], 
      ['clean']
    ],
  };

  counterConfig = {
    container: '#counter',
    unit: 'char',
    limit: this.maxLength
  };
  
  ngOnInit(): void {
    if (this.enableCounter) {
      this.counterConfig.limit = this.maxLength;
      this.modules['counter'] = this.counterConfig;
    }
  }

}
