<ng-container *transloco="let t">
  <div
    class="flex-column-center-center conditional-padding"
    style="padding-top: 18px;"
  >
    <div class="w-100 flex-column" style="max-width: 1140px; margin-bottom: -16px;">
      <div class="flex-column">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb; let i = index">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="flex-row">
        <mat-card
          class="round-corners mat-elevation-z3 mt2 w-100 no-shadow-mat-card administration-show administration-show-respon"
          style="max-width: 1140px"
        >
          <div class="px3 pt3">
            <span class="title">{{ t('GENERIC_WRAPPER.ADMINISTRATION') }}</span>
            <mat-tab-group class="pt2" [selectedIndex]="activeTabIndex" (selectedIndexChange)="changeHashTag($event)">
              <mat-tab class="mt2" label="{{ t('DANCE_MANAGER_ADMINISTRATION.TAB.GENERAL') }}">
                <ng-template matTabContent>
                  <div style="overflow: hidden" class="mt3">
                    <dfm-general></dfm-general>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_ADMINISTRATION.TAB.CONTACT') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <dfm-contact></dfm-contact>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_ADMINISTRATION.TAB.TEAM') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <dfm-team></dfm-team>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_ADMINISTRATION.TAB.FAQ') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <div class="w-100 h-100">
                      <df-administration-faqs></df-administration-faqs>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_ADMINISTRATION.TAB.GALLERY') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <div class="w-100 h-100">
                      <dfm-gallery [danceSchoolImageUrls]="danceSchool?.imageGalleryUrls"></dfm-gallery>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
