import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { LocationInterface } from '@platri/df-common-core';
import { getSubscription } from '../../helpers';
import { LocationHttpService } from '../http-services';

@Injectable({providedIn: 'root'})
export class LocationsStateService implements OnDestroy {
  isLoadingLocations = new BehaviorSubject<boolean>(false);
  availableLocations = new BehaviorSubject<LocationInterface[]>([]);
  selectedLocation = new BehaviorSubject<LocationInterface | null>(null);
  
  subscriptions = new Subscription();
  
  constructor(private readonly locationHttpService: LocationHttpService) {
    console.log('Initializing LocationsStateService');
  }
  
  ngOnDestroy(): void {
    console.log('Destroying LocationsStateService');
    this.subscriptions.unsubscribe();
    this.isLoadingLocations.unsubscribe();
    this.availableLocations.unsubscribe();
  }

  getAsyncIsLoadingLocations(): Observable<boolean> {
    return this.isLoadingLocations.asObservable();
  }
  
  getAsyncAvailableLocations(): Observable<LocationInterface[]> {
    return this.availableLocations.asObservable();
  }
  
  getAsyncSelectedLocation(): Observable<LocationInterface | null> {
    return this.selectedLocation.asObservable();
  }
  
  loadAllLocationsByDanceManagerId(danceManagerId: string): void {
    this.sendIsLoadingLocations(true);
    this.subscriptions.add(getSubscription(this.locationHttpService.getAllByDanceManagerId(danceManagerId), this.onLoadedAllLocations.bind(this), () => {console.log(`Couldn't load Locations`); this.sendIsLoadingLocations(false);}));
  }
  
  onLoadedAllLocations(locations: LocationInterface[]): void {
    this.sendIsLoadingLocations(false);
    this.sendAvailableLocations(locations);
  }
  
  loadLocationById(id: string, danceManagerId: string): void {
    this.sendIsLoadingLocations(true);
    this.subscriptions.add(getSubscription(this.locationHttpService.getLocationById(id, danceManagerId), this.onLoadedLocation.bind(this), () => {console.log(`Couldn't load Location`); this.sendIsLoadingLocations(false);}));
  }

  onLoadedLocation(location: LocationInterface): void {
    this.sendIsLoadingLocations(false);
    this.sendSelectedLocation(location);
  }

  deleteLocation(id: string, danceManagerId: string): Observable<any> {
    this.sendIsLoadingLocations(true);
    return this.locationHttpService.deleteLocation(id, danceManagerId).pipe(map((res) => {this.loadAllLocationsByDanceManagerId(danceManagerId); return res;}));
  }
  
  clear(): void {
    this.availableLocations.next([]);
    this.isLoadingLocations.next(false);
  }

  private sendAvailableLocations(locations: LocationInterface[]): void {
    this.availableLocations.next(locations);
  }
  
  sendSelectedLocation(location: LocationInterface): void {
    this.selectedLocation.next(location);
  }
  
  private sendIsLoadingLocations(isLoading: boolean): void {
    this.isLoadingLocations.next(isLoading);
  }
}
