export enum DanceManagerCategoryEnum {
  DANCE_STUDIO = 'DANCE_STUDIO',
  DANCER = 'DANCER',
  DANCE_GROUP = 'DANCE_GROUP',
  TEACHER = 'TEACHER',
  CHOREOGRAPHER = 'CHOREOGRAPHER',
  ORGANIZER = 'ORGANIZER',
  CLUB = 'CLUB',
  DISCO = 'DISCO',
  DJ = 'DJ',
  EVENT_LOCATION = 'EVENT_LOCATION',
}
