import { CurrencyDto } from '../../..';
import {DmPassSubscriptionInterface,  DmPassSubscriptionIntervalEnum } from '../../..';
import { DmPassSubscriptionTargetDto } from './dm-pass-subscription-target.dto';

export class DmPassSubscriptionDto implements DmPassSubscriptionInterface {
  id: string;
  createdAt: Date;
  currency: CurrencyDto;
  taxPercentage: number;
  price: number;
  name: string;
  description: string;
  sequenceNumber: number;
  danceManagerId: string;
  durationValue: number;
  durationUnit: DmPassSubscriptionIntervalEnum;
  isPaidMonthly: boolean;
  weeklyUsage: number;
  targets: DmPassSubscriptionTargetDto[];
  numberOfSales: number;
}
