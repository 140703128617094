import {Directive, ElementRef, HostBinding, Input, OnChanges, OnInit} from '@angular/core';

@Directive({
  selector: '[matBadgeIcon]',
})
export class MatBadgeIconDirective implements OnInit{

  @Input() matBadgeIcon: string;
  @Input() iconSizeInPixel = 20;

  constructor(
    private readonly el: ElementRef
  ) {
  }

  ngOnInit(): void {
    const badge = this.el.nativeElement.querySelector('.mat-badge-content');
    badge.style.display = 'flex';
    badge.style.alignItems = 'center';
    badge.style.justifyContent = 'center';
    badge.innerHTML = `<i class="material-icons-round" style="font-size: ${this.iconSizeInPixel}px">${this.matBadgeIcon}</i>`;
  }

}
