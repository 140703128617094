import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[dfNoScroll]',
  standalone: true
})
export class NoScrollDirective {

  @HostListener('wheel', ['$event'])
  onWheel(event: Event) {
    event.preventDefault();
  }

}
