import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MagicLoginFormInterface } from './magic-login-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getSubscription } from '@platri/elab-angular-core';
import { AuthHttpService, dfAppRoute, dfLoginRoute, noWhitespaceValidator, UserHttpService } from '@platri/dfx-angular-core';
import { emailRegex } from '@platri/df-common-core';

@Component({
  selector: 'elab-magic-login-form',
  templateUrl: './magic-login-form.component.html',
  styleUrls: ['./magic-login-form.component.scss']
})
export class MagicLoginFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulMagicLoginEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  magicLoginFormGroup!: FormGroup<MagicLoginFormInterface>;

  subscriptions: Subscription = new Subscription();

  isInitialized = false;
  isWaitingForMagicLoginResponseResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  magicLoginFailedWrongData = false;
  magicLoginFailedWithNoConnection = false;
  magicLoginFailedWithInternalServer = false;
  
  hasSuccessfullyMagicLogin = false;

  routerParams!: Params;
  returnUrlFromParam: string;
  
  email: string;
  
  constructor(
    private fb: FormBuilder,
    private userHttpService: UserHttpService,
    private authHttpService: AuthHttpService,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.magicLoginFormGroup = this.createRegisterFormGroup();
    this.updateFormGroupEmail();
  }
  
  updateFormGroupEmail(): void{
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email') ?? '';
    this.magicLoginFormGroup.controls.email.patchValue(this.email);
  }

  createRegisterFormGroup(): FormGroup<MagicLoginFormInterface> {
    return this.fb.group<MagicLoginFormInterface>({
        email: this.fb.control('', [Validators.required, Validators.email, noWhitespaceValidator(), Validators.pattern(emailRegex)])
      }
    );
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.magicLoginFormGroup.valueChanges.subscribe(res => {
      this.magicLoginFailedWithNoConnection = false;
      this.magicLoginFailedWithInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }

  onSubmit(): void {
    if (this.magicLoginFormGroup.valid ) {
      this.isWaitingForMagicLoginResponseResponse = true;
      this.magicLoginFailedWrongData = false;
      this.magicLoginFailedWithNoConnection = false;
      this.magicLoginFailedWithInternalServer = false;
      const {email} = this.magicLoginFormGroup.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.subscriptions.add(this.authHttpService.createMagicLink(email!).subscribe(res => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        }
        this.hasSuccessfullyMagicLogin = true;
        this.matSnackbar.open("Successfully send magic login mail!");
        this.isWaitingForMagicLoginResponseResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onEmailProviderDynamicLinkError();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        if (error.status === 404) {
          this.onWrongData();
        }
        this.isWaitingForMagicLoginResponseResponse = false;
      }));
    }
  }

  onWrongData(): void {
    this.magicLoginFailedWrongData = true;
  }

  onConnectionLost(): void {
    (this.magicLoginFailedWithNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.magicLoginFailedWithInternalServer = true;
  }
  
  onEmailProviderDynamicLinkError(): void {
    // todo: Handle current send email bug on geolab
    this.hasSuccessfullyMagicLogin = true;
    this.isWaitingForMagicLoginResponseResponse = false;
  }

  routeToLogin(emitSuccessfulmagicLogin: boolean = false): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
    if (emitSuccessfulmagicLogin) {
      this.successfulMagicLoginEmitter.next(true);
    }
  }

}
