import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class SidenavStateService {
  private readonly sidenav$ = new BehaviorSubject<MatSidenav>(null);
  private readonly expandedState$ = new BehaviorSubject<boolean>(true);

  public getAsyncCurrentSidenav(): Observable<MatSidenav> {
    return this.sidenav$.asObservable();
  }

  public getSyncCurrentSideNav(): MatSidenav {
    return this.sidenav$.getValue();
  }

  public sendCurrentSidenav(sidenav: MatSidenav) {
    this.sidenav$.next(sidenav);
  }

  public getAsyncCurrentExpandedState(): Observable<boolean> {
    return this.expandedState$.asObservable();
  }

  public getSyncCurrentExpandedState(): boolean {
    return this.expandedState$.getValue();
  }

  public sendCurrentExpandedState(expanded: boolean) {
    this.expandedState$.next(expanded);
  }

  //BUSINESS LOGIC

  public openSidenav() {
    this.sidenav$.getValue().open();
  }

  public closeSidenav() {
    return this.sidenav$.getValue().close();
  }

  public toggleSidenav(): void {
    this.sidenav$.getValue().toggle();
  }

  clear() {
    this.sidenav$.next(null);
    this.expandedState$.next(true);
  }
}
