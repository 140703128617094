import { NgModule } from '@angular/core';
import { SearchDanceStyleComponent } from './search-dance-style.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import {PipesModule} from "../../pipes";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [SearchDanceStyleComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    PipesModule,
    MatChipsModule,
    MatIconModule,
    TranslocoDirective
  ],
  exports: [SearchDanceStyleComponent],
})
export class SearchDanceStyleModule {}
