<div *ngIf="(danceSchool$ | async) === false" class="h-100 flex-row align-center">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="danceSchool$ | async" class="h-100">
  <dfm-main-content [smallSidenav]="true">
    <ng-container show-sidenav>
      <dfm-sidenav></dfm-sidenav>
    </ng-container>
    <router-outlet style="background-color: green;"></router-outlet>
  </dfm-main-content>
</div>
