<ng-container *transloco="let t">
  <div class="flex-row align-space-between align-center pt2 pb2">
    <div *ngIf="subscriptionFeatureActive">
      <span class="course-title" >{{t('GENERIC_WRAPPER.SUBSCRIPTIONS')}}</span>
    </div>
    <div>
      <df-shared-lib-button
        *ngIf="subscriptionFeatureActive"
        [materialButtonStyleType]="'flat'"
        [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_SUBSCRIPTION')"
        (buttonClicked)="navigateToCreatePassSubscriptionPage()"
      ></df-shared-lib-button>
    </div>
  </div>
  
  <df-course-subscription-table
    *ngIf="subscriptionFeatureActive"
    (editSubscriptionClicked)="navigateToEditPassSubscriptionPage($event)"
    (duplicateSubscriptionClicked)="navigateToCreatePassSubscriptionPage($event)">
  </df-course-subscription-table>
  
  <div *ngIf="!subscriptionFeatureActive">
    <div class="align-center" style="padding-top: 113px;">
      <df-shared-empty-page
        [buttonLabel]="t('DANCE_MANAGER_COURSE.MARKETPLACE_BUTTON_TEXT')"
        [emptyTitle]="t('DANCE_MANAGER_COURSE.ACTIVATE_SUB_PACKET.TITLE')"
        [emptyDescription]="t('DANCE_MANAGER_COURSE.ACTIVATE_SUB_PACKET.TEXT')"
        [imagePath]="'assets/images/courses-manager/no_courses.svg'"
        (buttonClicked)="navigateToMarketplacePage()"
      ></df-shared-empty-page>
    </div>
  </div>
</ng-container>
