<div class="flex-column gap-10 statistics-box">
  <div class="flex-row align-space-between-center w-100">
    <div class="flex-row-center-center gap-5">
      <div class="flex-row-center-center icon-container">
        <mat-icon>{{ icon }}</mat-icon>
      </div>
      <span>{{ StatBoxTitle }}</span>
    </div>
    <span><b>{{ StatBoxMainStats.left }}</b> / {{ StatBoxMainStats.right }}</span>
  </div>
  <div class="flex-row-center-center w-100 progress-bar-container">
    <div [ngStyle]="{ flex: StatBoxMainStats.left > 0 ? (StatBoxMainStats.left / StatBoxMainStats.right) * 100 : 0 }" class="w-100 h-100 progress"></div>
    <div [ngStyle]="{ flex: StatBoxMainStats.left > 0 ? 100 - (StatBoxMainStats.left / StatBoxMainStats.right) * 100 : 100}" class="w-100 h-100 background"></div>
  </div>
  <span *ngIf="showSubStats && (StatBoxSubStats.left > 0 || StatBoxSubStats.right > 0)">{{ StatBoxSubTitle }}</span>
  <div *ngIf="showSubStats && (StatBoxSubStats.left > 0 || StatBoxSubStats.right > 0)" class="flex-row-center-center w-100 role-bar-container" style="gap: 2px;">
    <div *ngIf="StatBoxSubStats.left > 0" [ngStyle]="{ flex: (StatBoxSubStats.left / (StatBoxSubStats.left + StatBoxSubStats.right)) * 100}" [ngClass]="StatBoxSubStats.right === 0 ? 'only-follower' : ''" class="flex-row-center-center w-100 h-100 follower">
      {{ StatBoxSubStats.left }}
    </div>
    <div *ngIf="StatBoxSubStats.right > 0" [ngStyle]="{ flex: (StatBoxSubStats.right / (StatBoxSubStats.left + StatBoxSubStats.right)) * 100}" [ngClass]="StatBoxSubStats.left === 0 ? 'only-leader' : ''" class="flex-row-center-center w-100 h-100 leader">
      {{ StatBoxSubStats.right }}
    </div>
  </div>
  <div *ngIf="showSubStats && (StatBoxSubStats.left > 0 || StatBoxSubStats.right > 0)" class="flex-row-start-center gap-15 role-indicator-container">
    <div class="flex-row-center-center gap-5">
      <div class="role-indicator follower"></div>
      <span>{{ indicatorTitles.left }}</span>
    </div>
    <div class="flex-row-center-center gap-5">
      <div class="role-indicator leader"></div>
      <span>{{ indicatorTitles.right }}</span>
    </div>
  </div>
</div>
