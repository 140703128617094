<ng-container *transloco="let t">
  <div
    class="flex-column align-center pl3 pr3"
    *ngIf="!isStudioPage; else isStudioPageView"
  >
    <div class="dfm-locations-wrapper-container w-100 flex-column">
      <div class="dfm-locations-wrapper-breadcrumb align-start">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="flex-row">
        <mat-card class="round-corners mat-elevation-z3 mt2 mb3 w-100 no-shadow-mat-card">
          <div class="p-40">
            <div class="flex-row dfm-locations-wrapper-header-row">
              <span>{{t('GENERIC_WRAPPER.LOCATION_WRAPPER.HEADER_TITLE')}}</span>
              <df-shared-lib-button 
                class="new-locations-button" 
                [buttonLabel]="t('GENERIC_WRAPPER.LOCATION_WRAPPER.NEW_LOCATION_BUTTON')" 
                (buttonClicked)="routeToCreatePage()" 
                [materialButtonStyleType]="'flat'"
              ></df-shared-lib-button>
            </div>
            <mat-divider style="margin-top: 8px;"></mat-divider>
            <div class="dfm-locations-wrapper-body" *ngIf="(this.locationsStateService.getAsyncIsLoadingLocations() | async) === false; else spinner">
              <div class="w-100" *ngIf="(locationsStateService.getAsyncAvailableLocations() | async)?.length > 0; else noLocations">
                <df-monorepo-locations-card
                  [locations]="locationsStateService.getAsyncAvailableLocations() | async"
                  [isLocationsPage]="true"
                  [isOwnerOrAdmin]="isOwnerOrAdmin"
                ></df-monorepo-locations-card>
              </div>
              <ng-template #noLocations>
                <div class="gap-15 flex-column align-center w-100 my4 py4">
                  <df-shared-empty-page
                    [buttonLabel]="t('GENERIC_WRAPPER.LOCATION_WRAPPER.NEW_LOCATION_BUTTON')"
                    [emptyTitle]="t('GENERIC_WRAPPER.LOCATION_WRAPPER.EMPTY_TITLE')"
                    [emptyDescription]="t('GENERIC_WRAPPER.LOCATION_WRAPPER.EMPTY_DESCRIPTION')"
                    [imagePath]="'assets/images/dance_manager/locations/no_locations.png'"
                    (buttonClicked)="routeToCreatePage()"
                  ></df-shared-empty-page>
                </div>
              </ng-template>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  
  <ng-template #isStudioPageView>
    <div class="dfm-locations-wrapper-body-studio" *ngIf="(this.locationsStateService.getAsyncIsLoadingLocations() | async) === false; else spinner">
      <div class="w-100 dfm-locations-wrapper-grid" *ngIf="(locationsStateService.getAsyncAvailableLocations() | async)?.length > 0; else noLocations">
        <div class="dfm-locations-wrapper-location-card-full">
          <df-monorepo-locations-card
            [locations]="locationsStateService.getAsyncAvailableLocations() | async"
            [isOwnerOrAdmin]="isOwnerOrAdmin"
            id="locations"
          ></df-monorepo-locations-card>
        </div>
      </div>
      <ng-template #noLocations>
        <mat-card *ngIf="isDmPreview" class="border-radius-10-without-shadow dfm-locations-wrapper-location-mat-card">
          <div class="gap-15 flex-column align-center w-100">
            <div class="flex-column align-center circle mt3">
              <img class="dfm-locations-wrapper-image-size" src="assets/images/dance_manager/locations/no_locations.png" alt="no_notifications icon"/>
            </div>
            <span class="dfm-locations-wrapper-no-location-title">{{ t('GENERIC_WRAPPER.LOCATION_WRAPPER.EMPTY_TITLE') }}</span>
            <span class="px3 no-location-description">{{ t('GENERIC_WRAPPER.LOCATION_WRAPPER.EMPTY_DESCRIPTION') }}</span>
            <df-shared-lib-button class="dfm-locations-wrapper-new-locations-button" [buttonLabel]="t('GENERIC_WRAPPER.LOCATION_WRAPPER.NEW_LOCATION_BUTTON')" (buttonClicked)="routeToCreatePage()" [minWidth]="'144px'"></df-shared-lib-button>
          </div>
        </mat-card>
      </ng-template>
    </div>
  </ng-template>
  
  <ng-template #spinner>
    <mat-spinner
      style="margin: auto"
      diameter="80"
    ></mat-spinner>
  </ng-template>
</ng-container>
