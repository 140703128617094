<ng-container *transloco="let t">
  <div class="flex-column align-center" style="overflow: hidden;">
    <div class="align-center circle mt3">
      <img
        src="assets/images/courses-manager/no_courses.svg"
        alt="no_courses icon"
      />
    </div>
    <div class="w-100 h-100 flex-column align-center text-container">
      <span class="title-text pt2 pb1">{{t('DANCE_MANAGER_COURSE.NO_COURSES')}}</span>
      <span class="description-text">
        {{t('DANCE_MANAGER_COURSE.NO_COURSES_TEXT')}}
      </span>
    </div>
    <div class="pt2">
      <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_COURSE')" (buttonClicked)="navigateToCreatePage()">
      </df-shared-lib-button>
    </div>
  </div>
</ng-container>
