import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DanceManagerHttpService } from '../../services';
import { DanceManagerFaqInterface } from '@platri/df-common-core';
import { dfmAdministrationRoute } from '../..';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'df-monorepo-faqs-card',
  templateUrl: './df-faqs-card.component.html',
  styleUrls: ['./df-faqs-card.component.scss'],
})
export class DfFaqsCardComponent implements OnInit, OnDestroy {
  @Input() isOwnerOrAdmin = false;
  @Input() danceManagerId: string;
  @Input() danceManagerUrlName: string;
  @Input() loadedFaqs: DanceManagerFaqInterface[] = [];

  panelOpenState: boolean[] = [false];
  faqs: DanceManagerFaqInterface[] ;

  currentLang: string;
  
  private subscription = new Subscription();
  
  
  constructor(private readonly translocoService: TranslocoService, private readonly router: Router, private readonly danceManagerHttpService: DanceManagerHttpService) {
    this.currentLang = translocoService.getActiveLang().toUpperCase();
  }

  routeToSettings(target: string, fragment: string = ''): void {
    this.router.navigate(['dance-manager', this.danceManagerId, target], { fragment: fragment });
  }
 

  ngOnInit(): void {
    if (this.loadedFaqs?.length === 0) {
      this.subscription.add(
        this.danceManagerHttpService.getFaqsByDanceManagerId(this.danceManagerId).subscribe((values => {
          this.faqs = values;
        }))
      );
    } else {
      this.faqs = this.loadedFaqs;
    }
  }
  
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  protected readonly dfmAdministrationRoute = dfmAdministrationRoute;
}
