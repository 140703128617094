import { NotificationTargetTypeEnum, NotificationTypeEnum, NotificationUseCaseEnum } from '../../enums';

export interface NotificationInterface {
  id?: string;
  createdAt?: Date;
  notificationType?: NotificationTypeEnum;
  notificationUseCase?: NotificationUseCaseEnum;
  fromTargetId?: string;
  toTargetId?: string;
  fromTargetType?: NotificationTargetTypeEnum;
  toTargetType?: NotificationTargetTypeEnum;
  useCaseSpecificData?: object;
  readAt?: Date | null;
}
