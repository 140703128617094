import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserTicketHttpService } from '../services';

@Injectable({ providedIn: 'root' })
export class EventUserTicketsResolver  {
  constructor(private userTicketHttpService: UserTicketHttpService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const eventId = route.paramMap.get('eventId');
    return this.userTicketHttpService.getAllByCurrentUserAndDanceEventId(eventId!, true);
  }
}
