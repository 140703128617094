import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CourseInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-course-rules-form',
  templateUrl: './course-rules-form.component.html',
  styleUrls: ['./course-rules-form.component.scss']
})
export class CourseRulesFormComponent implements OnInit {
  @Input() rulesFormGroup: UntypedFormGroup;
  @Input() existingCourse: CourseInterface;
  @Input() loading = false;

  @Output() onBackClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSkipClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onContinueClick: EventEmitter<void> = new EventEmitter<void>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnInit(): void {
    this.rulesFormGroup.get('participantRestriction.genderRestriction').valueChanges.subscribe((changes) => {
      this.rulesFormGroup.get('participantRestriction.isGenderRestricted').setValue(Object.values(changes).some((value) => value));
    });
    this.rulesFormGroup.get('clothingRestriction.isRestricted').valueChanges.subscribe((value) => {
      if (value) {
        this.rulesFormGroup.get('clothingRestriction.restriction').addValidators(Validators.required);
      } else {
        this.rulesFormGroup.get('clothingRestriction.restriction').removeValidators(Validators.required);
        this.rulesFormGroup.get('clothingRestriction.restriction').reset();
      }
    });
  }

  setAgeRange(event: { fromLevel: number; toLevel: number }): void {
    this.rulesFormGroup.get('participantRestriction.ageRestriction.fromAge').setValue(event.fromLevel);
    this.rulesFormGroup.get('participantRestriction.ageRestriction.toAge').setValue(event.toLevel);
    if(event.fromLevel > event.toLevel){
      this.ageRestrictionFormGroup.setErrors({ invalidRange: true });
    }else{
      this.ageRestrictionFormGroup.setErrors(null);
    }
    this.rulesFormGroup.markAsDirty();
  }

  get ageRestrictionFormGroup (): UntypedFormGroup {
    return <UntypedFormGroup> this.rulesFormGroup.get('participantRestriction.ageRestriction');
  }

}
