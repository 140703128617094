export class ChatProfileMockData implements ChatProfileMockDataInterface {
  aboutText: string;
  hasPets: boolean;
  job: string;
  name: string;
  personQuantity: number;
  personalities: string[];
  relationShipStatus: string;
  isSmoker: boolean;
  
  constructor() {
    this.name = 'Rachel Müller';
    this.personQuantity = 1;
    this.relationShipStatus = 'Single';
    this.isSmoker = false;
    this.job = 'Consultant';
    this.hasPets = true;
    this.personalities = ["Sportler/in", 'Offen', 'Ordentlich'];
    this.aboutText = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.';
    
  }
  
}

export interface ChatProfileMockDataInterface {
  name: string;
  personQuantity: number;
  relationShipStatus: string;
  isSmoker: boolean;
  job: string;
  hasPets: boolean;
  personalities: string[];
  aboutText: string;
}
