import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompleteProfileDialogComponent } from './complete-profile-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { DfSharedButtonModule, ImageFallbackDirectiveModule } from '@platri/dfx-angular-core';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CompleteProfileDialogComponent],
    imports: [
      CommonModule,
      MatDialogModule,
      MatCheckboxModule,
      MatButtonModule,
      DfSharedButtonModule,
      MatIconModule,
      MatChipsModule,
      MatProgressSpinnerModule,
      MatProgressBarModule,
      ImageFallbackDirectiveModule,
      TranslocoDirective,
    ],
})
export class CompleteProfileDialogModule {}
