import { UserAppLangEnum, UsersInterface } from '../../..';

export class CreateUserRequestDto implements UsersInterface {
  email: string;
  password: string;
  username: string;
  firstName: string;
  lastName: string;
  appLang: UserAppLangEnum;
}
