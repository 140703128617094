<ng-container *transloco="let t">
  <div *ngIf="!isLoading && danceManager" class="relative course-passes-container flex-column align-start-center w-100">
    <div class="bg-layer hide-on-mobile">
      <img [src]="danceManager.imageUrl ?? DEFAULT_DANCE_MANAGER_TITLE_PICTURE_PLACEHOLDER" alt="" />
    </div>
    <div class="content-card py2 w-100">
      <div class="w-100 back-btn-container">
        <div class="flex-column align-start hide-on-mobile" (click)="navigateToDanceManagerPage()">
          <a class="flex-row align-start back-btn">
            <mat-icon>arrow_back</mat-icon>
            <span class="pl1 m-auto bold">{{t('GENERIC_WRAPPER.DANCE_MANAGER') }}</span>
          </a>
        </div>
      </div>
      <div class="flex-row align-start pt2">
        <img class="course-image hide-on-mobile" [src]="danceManager.imageUrl ?? DEFAULT_DANCE_MANAGER_TITLE_PICTURE_PLACEHOLDER" alt="" /> 
        <div class="course-header-container flex-column w-100">
          <div class="flex-row align-space-between w-100 px2">
            <span style="font-size: 20px">
              <strong class="course-name-text">{{ danceManager.name }}</strong>
            </span>
          </div>
        </div>
      </div>
      
      <div class="card-container flex-column mt3 w-100" style="gap: 1rem;">
        <div *ngIf="showGuestInputFields" [formGroup]="guestEmailForm" class="flex-column-center-center w-100">
          <span>Bitte geben Sie eine Email Adresse an, an die die Tickets verschickt werden sollen.</span>
          <div class="flex-row-center-center w-100 gap-10 pt2">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Email bestätigen</mat-label>
              <input matInput formControlName="confirmEmail">
            </mat-form-field>
          </div>
          <df-shared-lib-button
            [buttonLabel]="t('GENERIC_WRAPPER.SEND')"
            [isDisabled]="guestEmailForm.invalid || creationTicketOrderLoading"
            (buttonClicked)="continueToCheckout()"
          ></df-shared-lib-button>
        </div>
        
        <div *ngIf="!showGuestInputFields && danceManagerPasses && danceManagerPasses.length > 0" class="passes-list-container flex-column" style="gap: 1rem;" [formGroup]="formGroup">
          <div *ngFor="let pass of danceManagerPasses; let last = last" class="flex-column" style="gap: 0.75rem;">
            <df-pass-checkout-list-item 
              [courses]="filterUserPassCoursesByPassId(pass.passTargets)" 
              [pass]="pass"
              [formGroup]="formGroup"
            ></df-pass-checkout-list-item>
            <mat-divider *ngIf="!last"></mat-divider>
          </div>
        </div>
        
        <div *ngIf="!showGuestInputFields && danceManagerPasses && danceManagerPasses.length === 0">
          <df-pass-checkout-empty-list></df-pass-checkout-empty-list>
        </div>
        
        <div *ngIf="!showGuestInputFields"  class="checkout-detail-container">
          <!-- Checkout summary -->
          <div class="payment-methods-container hide-on-mobile" style="margin-bottom: 20px;">
            <df-pass-checkout-list-summary 
              [selectedPasses]="selectedPasses" 
              [form]="formGroup" 
              [subtotal]="subtotal" 
              [fees]="fees"
            ></df-pass-checkout-list-summary>
          </div>
          <!-- Payment methods -->
          <div class="payment-methods-container hide-on-mobile" style="padding: 20px;">
            <div class="flex-row align-space-between" style="margin-bottom: 12px">
              <span>{{ t('GENERIC_WRAPPER.WE_ACCEPT') }}</span>
            </div>
            <df-payment-methods-view></df-payment-methods-view>
          </div>
          <!-- Checkout button -->
          <div class="flex-column-center-center py2 hide-on-mobile">
            <df-shared-lib-button 
              *ngIf="currentUser" 
              class="w-100" 
              [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading" 
              [buttonLabel]="t('GENERIC_WRAPPER.CHECKOUT')" 
              (buttonClicked)="continueToCheckout(true)"
            ></df-shared-lib-button>
            <df-shared-lib-button 
              *ngIf="!currentUser" 
              class="w-100" 
              [isDisabled]="selectedPasses.length === 0 || creationTicketOrderLoading" 
              [buttonLabel]="t('GENERIC_WRAPPER.LOGIN_AND_BUY')" 
              (click)="continueToCheckout(true)"
            ></df-shared-lib-button>
<!--            <span -->
<!--              *ngIf="!currentUser && !selectedPassesContainTrialPass()" -->
<!--              [class]="selectedPasses.length === 0 ? 'continue-as-guest-button-disabled' : 'continue-as-guest-button'" -->
<!--              (click)="continueToCheckout()"-->
<!--            >-->
<!--              {{ t('GENERIC_WRAPPER.CONTINUE_AS_GUEST') }}-->
<!--            </span>-->
          </div>
          <div class="py1 px1 hide-on-mobile">
            {{ t('GENERIC_WRAPPER.NEED_HELP') }}
            <a style="text-decoration: none; color: #3377FF" target="_blank" contactUrl>
              {{ t('GENERIC_WRAPPER.CONTACT_US') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
