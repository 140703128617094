export interface AddressInterface {
  googleMapsPlaceId?: string;
  street?: string;
  streetNumber?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  formattedAddress?: string;
  latitude?: number;
  longitude?: number;
  googleMapsUrl?: string;
  state?: string;
  timezoneOffsetMinutes?: number;
  isDefault?: boolean;
  addressLine1?: string;
  timezoneId?: string;
  name?: string;
  location?: any;
}
