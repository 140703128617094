import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventTicketListItemComponent } from './event-ticket-list-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [EventTicketListItemComponent],
  exports: [EventTicketListItemComponent],
    imports: [
      CommonModule,
      FormsModule,
      PipesModule,
      MatFormFieldModule,
      MatSelectModule,
      ReactiveFormsModule,
      MatIconModule,
      MatTooltipModule,
      MatButtonModule,
      MatInputModule,
      MatSliderModule,
      MatSlideToggleModule,
      DfSharedButtonModule,
      TranslocoDirective
    ]
})
export class EventTicketListItemModule {}
