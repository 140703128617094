import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { AddAppointmentDialogComponent } from './add-appointment-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule } from '../buttons';

@NgModule({
  declarations: [AddAppointmentDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    DfSharedButtonModule
  ],
  exports: [AddAppointmentDialogComponent],
})
export class AddAppointmentDialogModule {}
