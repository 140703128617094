<ng-container *transloco="let t">
  <div class="flex-column align-center pl3 pr3 pb3">
    <div class="w-100 flex-column" style="max-width: 1140px; margin-bottom: -16px; padding-top: 18px">
      <div>
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <mat-card class="round-corners mat-elevation-z3 mt2 w-100 no-shadow-mat-card settings-show settings-show-respon" style="max-width: 1140px;">
        <div class="px3 pt3">
          <span class="title">{{ t(showPaymentHistory ? 'GENERIC_WRAPPER.PAYMENT_HISTORY' : 'GENERIC_WRAPPER.SETTINGS') }}</span>
          <mat-tab-group dynamicHeight class="pt2" [selectedIndex]="activeTabIndex" (selectedIndexChange)="changeRouteToFragment($event)" preserveContent="false">
            <mat-tab label="{{ t('GENERIC_WRAPPER.SUBSCRIPTION') }}">
              <ng-container *ngIf="activeTabIndex === 0">
                <df-monorepo-dm-subscription-card (showPaymentHistoryEmitter)="changeTitle($event)"></df-monorepo-dm-subscription-card>
              </ng-container>
            </mat-tab>
            <mat-tab label="{{ t('GENERIC_WRAPPER.GENERAL') }}">
              <dfm-general-settings></dfm-general-settings>
            </mat-tab>
            <mat-tab *ngIf="!danceSchool?.stripePayoutsEnabled" label="Onboarding">
              <ng-container *ngIf="activeTabIndex === 2">
                <dfm-stripe-onboarding></dfm-stripe-onboarding>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card>
    </div>
  </div>
</ng-container>
