import { DanceManagerUserRoleEnum } from '../../..';

export class DanceManagerToUserCreatedRequestDto {
  id: string;
  danceSchoolUserRole: DanceManagerUserRoleEnum;
  danceSchoolId: string;
  danceManagerUrlName: string;
  userId: string;
  danceSchoolImageUrl?: string;
  eventMessageCreated?: string;
  danceManagerName?: string;
}
