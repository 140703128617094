import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DanceManagerPassesPublicResponseDto, emailRegex, GenderEnum, UsersInterface } from '@platri/df-common-core';
import { DfmStateService, PassHttpService, UserHttpService, UserPassHttpService } from '../../services';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-free-pass-invite-dialog',
  templateUrl: './free-pass-invite-dialog.component.html',
  styleUrls: ['./free-pass-invite-dialog.component.scss'],
})
export class FreePassInviteDialogComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  searchInputChangeSubject = new Subject<string>();
  showUserSearchLoadingSpinner = false;

  userOptions: any[] = [];
  selectedUsers: any[] = [];
  selectedEmails: string[] = [];

  eventId: string;

  emailInput: UntypedFormControl;

  selectedPass: string;
  passes: DanceManagerPassesPublicResponseDto[] = [];

  constructor(
    private userHttpService: UserHttpService,
    private translocoService: TranslocoService,
    private dialogRef: MatDialogRef<FreePassInviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dfmStateService: DfmStateService,
    private passHttpService: PassHttpService,
    private userPassHttpService: UserPassHttpService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.subscribeToSearch();
    this.initEmailForm();
  }
  
  loadData(): void {
    this.passHttpService.getAllByDanceManagerIdPublicView(this.dfmStateService.getSyncSelectedDm().id).subscribe({
      next: (data) => {
        this.passes = data;
      }
    });
  }

  onUserSelected($event: any): void {
    this.selectedUsers.push($event);
  }

  onEmailSelected(): void {
    if (this.emailInput.valid && this.emailInput.value !== '') {
      this.selectedEmails.push(this.emailInput.value);
      this.emailInput.reset();
    }
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }

  initEmailForm(): void {
    this.emailInput = new UntypedFormControl('', [
      Validators.email,
      Validators.pattern(emailRegex)
    ]);
  }

  getErrorMessage(): string {
    return this.emailInput.hasError('email') ? this.translocoService.translate('GENERIC_WRAPPER.EMAIL_INVALID') : '';
  }

  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject
        .pipe(
          tap(() => (this.showUserSearchLoadingSpinner = true)),
          debounceTime(600)
        )
        .subscribe((inputText: string) => {
          if (inputText) {
            this.subscriptions.add(
              this.userHttpService
                .searchUsersByKey(inputText)
                .pipe(
                  tap(() => {
                    this.showUserSearchLoadingSpinner = false;
                  })
                )
                .subscribe((users: UsersInterface[]) => {
                  this.userOptions = this.mapToOptions(users);
                })
            );
          } else {
            this.userOptions = [];
            this.showUserSearchLoadingSpinner = false;
          }
        })
    );
  }

  removeSelectedUser(selectedUser: UsersInterface): void {
    const index = this.selectedUsers.indexOf(selectedUser);
    if (index >= 0) {
      this.selectedUsers.splice(index, 1);
    }
  }

  removeSelectedEmail(selectedEmail: string): void {
    const index = this.selectedEmails.indexOf(selectedEmail);
    if (index >= 0) {
      this.selectedEmails.splice(index, 1);
    }
  }

  private mapToOptions(users: UsersInterface[]) {
    return users.map((user) => ({
      id: user.id,
      value: user.username,
      data: user,
      displayValue: user.username,
      info: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null,
      imageUrl: user.imageUrl ?? null,
    }));
  }
  
  get isInviteDisabled(): boolean {
    return this.selectedPass === undefined || (this.selectedUsers.length === 0 && this.selectedEmails.length === 0);
  }
  
  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  invite(): void {
    if (!this.isInviteDisabled) {
      const emails = [];
      const userIds = [];
      for (const user of this.selectedUsers) {
        userIds.push(user.id);
      }
      for (const email of this.selectedEmails) {
        emails.push(email);
      }
      this.userPassHttpService.createFreePass(this.selectedPass, userIds, emails)
        .subscribe({
          next: () => {
            this.dialogRef.close();
          }
        });
    }
  }

  protected readonly Gender = GenderEnum;
}
