import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Location } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-create-dance-event-appointment-location-page',
  templateUrl: './create-dance-event-appointment-location.page.html',
  styleUrls: ['./create-dance-event-appointment-location.page.scss'],
})
export class CreateDanceEventAppointmentLocationPage {
  @Input() formGroup: UntypedFormGroup;
  @Input() locations: Location[];

  @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSubmitClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onImageFormData: EventEmitter<FormData> =
    new EventEmitter<FormData>();
}
