import {NgControl} from '@angular/forms';
import {AfterViewInit, Directive, HostListener, Input} from "@angular/core";

@Directive({
  selector: '[formatNumber]',
  standalone: true
})
export class FormatNumberDirective implements AfterViewInit {

  fractionDigits = 2;

  constructor(private ngControl: NgControl) {
  }
  
  @Input() set formatNumber(fractionDigits: number) {
    this.fractionDigits = fractionDigits;
    if (this.ngControl.control) {
      this.ngControl.control.patchValue(this.ngControl.control.value.toFixed(this.fractionDigits));
    }
  }
  
  

  ngAfterViewInit(): void {
    if (this.ngControl.control) {
      this.ngControl.control.patchValue(this.ngControl.control.value?.toFixed(this.fractionDigits));
    }
  }

  @HostListener('blur') onBlur(): void {
    this.ngControl.control!.patchValue(this.ngControl.control!.value.toFixed(this.fractionDigits));
  }

  @HostListener('focusin') onFocusIn(): void {
    if (this.ngControl.control!.value) {
      this.ngControl.control!.patchValue(+this.ngControl.control!.value);
    }
  }

  

}
