<ng-container *transloco="let t">
  <div class="flex-column" style="min-height: calc(100vh - 56px)">
    <div class="w-100 flex-row align-center align-space-between-center">
      <p style="font-size: 18px">
        <strong>{{ t('GENERIC_WRAPPER.FAQ') }}</strong>
      </p>
      <div *ngIf="isOwnerOrAdmin" class="align-center">
        <button mat-icon-button style="padding: 0px !important; margin: 0px !important;" class="edit-button align-center" (click)="routeToSettings(dfmAdministrationRoute,'faq')">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
    <mat-accordion class="pl1 pr1">
      <mat-expansion-panel *ngFor="let faq of faqs; let i = index" hideToggle (opened)="panelOpenState[i] = true" (closed)="panelOpenState[i] = false">
        <mat-expansion-panel-header>
          <div class="flex-row" style="gap: 5px;">
            <strong>{{faq.question}}</strong>
            <mat-icon fontSet="material-icons-round" [ngClass]="panelOpenState[i] ? 'rotate' : ''">arrow_drop_down</mat-icon>
          </div>
        </mat-expansion-panel-header>
        <span [innerHTML]="faq.answer | dynamicTag"></span>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
