import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DanceEventWithTickets,
  Ticket,
  TicketTypeEnum
} from '@platri/df-common-shared-graphql';
import {
  ConfirmationDialogComponent,
  DanceEventHttpService,
  DanceSchoolStateService,
  TicketHttpService,
  TicketStateService
} from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-event-tickets',
  templateUrl: './event-tickets.page.html',
  styleUrls: ['./event-tickets.page.scss']
})
export class EventTicketsPage implements OnInit, OnDestroy {
  displayedColumns: string[] = ['ticketName', 'soldQuantity', 'price', 'saleStart', 'saleEnd', 'ticketControls'];
  isLoadingDanceEvents = false;

  //TODO: Research if it is possible to use DanceEvent without adding a type or adding a local type
  events: DanceEventWithTickets[] = [];
  subscriptions: Subscription = new Subscription();

  readonly currentDate = new Date();

  readonly ticketTypeEnum = TicketTypeEnum;

  currentLang: string;

  constructor(
    private danceSchoolService: DanceSchoolStateService, 
    private readonly danceEventHttpService: DanceEventHttpService, 
    private readonly ticketHttpService: TicketHttpService, 
    private readonly ticketService: TicketStateService, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly dialog: MatDialog, 
    private readonly router: Router,
    private readonly translocoService: TranslocoService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    this.loadDanceEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadDanceEvents(): void {
    this.isLoadingDanceEvents = true;
    this.subscriptions.add(
      this.danceEventHttpService.getAllByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, true, true).subscribe((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.events = res;
      })
    );
  }

  navigateToCreateTicketPage(): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'event-tickets', 'create']);
  }

  navigateToEditTicketPage(id: string): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'event-tickets', 'events', id, 'edit']);
  }

  navigateToDetailsPage(id: string): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'event-tickets', 'events', id]);
  }

  //deleting works but need to refresh table after successful delete

  deleteTicket(ticket: Ticket): void {
    this.subscriptions.add(
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            cancelButtonColor: '',
            cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
            submitButtonColor: 'warn',
            submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
            text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_DIALOG_TEXT'),
          }
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.ticketHttpService.delete(ticket.id!).subscribe({
                next: (res) => {
                  this.ticketService.getAllByDanceEvent(ticket.danceEventId!, true);
                  this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_SUCCESS'));
                },
                error: (error) => {
                  this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_ERROR'));
                }
              })
            );
          }
        })
    );
  }
}
