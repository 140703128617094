import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TicketHttpService } from '../services';

@Injectable({ providedIn: 'root' })
export class EventTicketResolver  {
  constructor(private ticketHttpService: TicketHttpService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const searchEventTicketId: string = <string>route.paramMap.get('eventTicketId');
    return this.ticketHttpService.getById(searchEventTicketId);
  }
}
