import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  DfSharedButtonModule,
  TranslocoStateConst
} from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { InventoryPage } from './inventory.page';
import { InventoryPassListModule, InventorySubscriptionListModule, InventoryTicketListModule } from '../../components';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';

const routes = [
  {
    path: '',
    component: InventoryPage,
  },
  {
    path: 'tickets/:eventId',
    loadChildren: () => import('./inventory-ticket-detail-page/inventory-ticket-detail-page.module').then((m) => m.InventoryTicketDetailPageModule)
  },
  {
    path: 'passes/:passId',
    loadChildren: () => import('./inventory-pass-detail-page/inventory-pass-detail-page.module').then((m) => m.InventoryPassDetailPageModule)
  },
  {
    path: 'subscriptions/:subscriptionId',
    loadChildren: () => import('./inventory-subscription-detail-page/inventory-subscription-detail-page.module').then((m) => m.InventorySubscriptionDetailPageModule)
  }
];

@NgModule({
  declarations: [InventoryPage],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatDividerModule,
      MatFormFieldModule,
      MatSelectModule,
      MatTabsModule,
      MatCardModule,
      MatButtonModule,
      MatIconModule,
      DfSharedButtonModule,
      MatChipsModule,
      InventoryTicketListModule,
      InventoryPassListModule,
      InventorySubscriptionListModule,
      TranslocoDirective,
      TranslocoModule,
    ]
})
export class InventoryPageModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-user', alias: 'USER' });
  }
}
