import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfmPayoutOverviewPageComponent } from './dfm-payout-overview-page.component';
import { DfSharedButtonModule, PipesModule, SharedEmptyPageModule } from '@platri/dfx-angular-core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DfmPayoutOverviewPageComponent,
  },
  {
    path: ':danceManagerPayoutId',
    loadChildren: () => import('../dfm-payout-detail-page/dfm-payout-detail-page.module').then((m) => m.DfmPayoutDetailPageModule),
  }
];

@NgModule({
  declarations: [DfmPayoutOverviewPageComponent],
    imports: [
      CommonModule,
      MatDividerModule,
      RouterModule.forChild(routes),
      MatCardModule,
      MatButtonModule,
      MatDialogModule,
      MatProgressSpinnerModule,
      DfSharedButtonModule,
      PipesModule,
      MatTableModule,
      MatIconModule,
      BreadcrumbComponent,
      BreadcrumbItemDirective,
      SharedEmptyPageModule,
      TranslocoDirective,
    ],
})
export class DfmPayoutOverviewPageModule {}
