import { UserDto } from './user.dto';
import { DanceManagerUserRoleEnum, DanceSchoolToUserInterface } from '../../..';

export class DanceSchoolToUserDto implements DanceSchoolToUserInterface {
  id: string;
  danceSchoolUserRole: DanceManagerUserRoleEnum;
  danceSchoolId: string;
  user: UserDto;
  isPublic: boolean;
}
