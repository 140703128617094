import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AuthStateService } from '@platri/dfx-angular-core';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit, OnDestroy {
  activeTabIndex: number;

  currentUser: UsersInterface;

  subscriptions: Subscription = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthStateService
  ) {
  }

  ngOnInit() {
    this.getHashtag();
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.subscriptions.add(
      this.authService.getAsyncCurrentUser()
        .pipe(distinctUntilChanged((userA, userB) => _.isEqual(userA, userB)))
        .subscribe((user) => {
          if (user) {
            this.currentUser = user;
          }
        })
    );
  }

  getHashtag() {
    this.activatedRoute.fragment.subscribe((hashtag) => {
      if (hashtag != null) {
        hashtag = hashtag.toLowerCase();
        switch (hashtag) {
          case 'general': {
            this.activeTabIndex = 0;
            break;
          }
          case 'profile': {
            this.activeTabIndex = 1;
            break;
          }
          case 'account': {
            this.activeTabIndex = 2;
            break;
          }
        }
      }
    });
  }

  changeHashTag(index: number) {
    switch (index) {
      case 0: {
        window.location.hash = '#general';
        break;
      }
      case 1: {
        window.location.hash = '#profile';
        break;
      }
      case 2: {
        window.location.hash = '#account';
        break;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
