import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription, take, takeWhile } from 'rxjs';
import { TicketOrderStatusEnum } from '@platri/df-common-shared-graphql';
import { AuthStateService, CheckoutCompleteErrorTextInterface, DanceManagerHttpService, dfUrlNameRouterParam, PurchaseConfirmationTextInterface, TicketOrderHttpService, TicketOrderService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { DanceManagerInterface, TicketOrderInterface, UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-passes-checkout-complete',
  templateUrl: './dance-manager-passes-checkout-complete.page.html',
  styleUrls: ['./dance-manager-passes-checkout-complete.page.scss']
})
// todo not used - delete?
export class DanceManagerPassesCheckoutCompletePage implements OnInit, OnDestroy {
  currentLang: string;
  currentUser: UsersInterface;
  danceManager: DanceManagerInterface;

  ticketOrder: TicketOrderInterface | null;

  isIntervalActive = false;

  ticketOrderStatusEnum = TicketOrderStatusEnum;

  confirmationText: PurchaseConfirmationTextInterface;
  errorText: CheckoutCompleteErrorTextInterface;
  subscriptions: Subscription = new Subscription();

  constructor(private readonly translocoService: TranslocoService, private readonly ticketOrderService: TicketOrderService, private readonly router: Router, private readonly authService: AuthStateService, private readonly activatedRoute: ActivatedRoute, private readonly ticketOrderHttpService: TicketOrderHttpService, private readonly danceManagerHttpService: DanceManagerHttpService) {
    this.currentLang = this.translocoService.getActiveLang();
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  ngOnInit(): void {
    this.loadDanceManager(this.activatedRoute.snapshot.params['url-name']);
    this.listenOnTicketOrderChanges();
  }

  loadDanceManager(urlName: string): void {
    if (urlName) {
      this.danceManagerHttpService.getDanceManagerByUrlNameForPublicView(urlName.replace('@', '')).subscribe((danceManager) => {
        this.danceManager = danceManager;
      });
    }
  }

  listenOnTicketOrderChanges(): void {
    this.subscriptions.add(
      this.ticketOrderService.getAsyncTicketOrder().subscribe((ticketOrder) => {
        this.ticketOrder = ticketOrder;
        if (ticketOrder && this.ticketOrder.status !== null && this.ticketOrder.status === TicketOrderStatusEnum.PAYMENT_INITIALIZED && !this.isIntervalActive) {
          this.activateIntervalCheckingTicketOrder(this.ticketOrder.id);
        }

        if ([TicketOrderStatusEnum.PAID, TicketOrderStatusEnum.PAYMENT_PENDING].includes(ticketOrder?.status)) {
          this.setConfirmationText();
        }

        if ([TicketOrderStatusEnum.PAYMENT_ERROR].includes(ticketOrder?.status)) {
          this.setErrorText();
        }
      })
    );
    this.ticketOrderService.getTicketOrderById(this.activatedRoute.snapshot.data.targetObject.id);
  }

  activateIntervalCheckingTicketOrder(ticketOrderId: string): void {
    if (!this.isIntervalActive) {
      this.isIntervalActive = true;
      const timer = interval(3000);

      const takeTwelve = timer.pipe(take(10));

      this.subscriptions.add(
        takeTwelve.pipe(takeWhile(() => this.ticketOrder.status === TicketOrderStatusEnum.PAYMENT_INITIALIZED)).subscribe(() => {
          this.ticketOrderService.getTicketOrderById(ticketOrderId);
        })
      );
    }
  }

  download(): void {
    const ticketWindow = window.open();
    ticketWindow.document.write('Loading...');

    this.ticketOrderHttpService.download(this.ticketOrder.id).subscribe({
      next: (blob) => {
        const url = URL.createObjectURL(blob);
        ticketWindow.location.href = url;
      },
      error: (err) => {
        console.error(err);
        ticketWindow.close();
      }
    });
  }

  navigateToDanceSchool(): void {
    const danceSchoolName = this.activatedRoute.snapshot.params[dfUrlNameRouterParam];
    this.router.navigateByUrl('/' + danceSchoolName);
  }

  navigateToMyTickets(): void {
    this.router.navigate(['inventory'], { fragment: 'passes' });
  }

  ngOnDestroy(): void {
    this.ticketOrderService.clear();
    this.subscriptions.unsubscribe();
  }

  private setConfirmationText(): void {
    const currentUser = this.authService.getSyncCurrentUser();
    const firstName = currentUser?.username ?? this.translocoService.translate('GENERIC_WRAPPER.GUEST');
    const email = currentUser?.email ?? this.ticketOrder.userEmail;
    this.confirmationText = {
      title: 'GENERIC_WRAPPER.THANKS_FOR_ORDER',
      message: this.translocoService.translate('GENERIC_WRAPPER.PASSES_CHECKOUT.' + (currentUser ? 'TEXT' : 'TEXT_GUEST')),
      messageParams: {firstName, email},
      navButton: {
        label: 'GENERIC_WRAPPER.PASSES_CHECKOUT.BUTTON',
        navUrl: '/inventory#passes'
      },
      downloadText: 'GENERIC_WRAPPER.PASSES_CHECKOUT.DOWNLOAD_PDF'
    };
  }

  private setErrorText(): void {
    this.errorText = {
      title: 'GENERIC_WRAPPER.SOMETHING_WENT_WRONG_SHORT',
      text: 'GENERIC_WRAPPER.PASSES_CHECKOUT.ERROR_TEXT',
      navButton: {
        label: 'GENERIC_WRAPPER.PASSES_CHECKOUT.TO_COURSE',
        navUrl: '/' + this.activatedRoute.snapshot.params[dfUrlNameRouterParam]
      }
    };
  }
}
