import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthStateService } from '@platri/dfx-angular-core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.getSyncCurrentUser()) {
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.authService.routeToLogin(state.url);
      return false;
    }
  }
}
