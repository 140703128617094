import { Injectable, OnDestroy } from '@angular/core';
import { AbstractStateV2Service } from '@platri/elab-angular-core';
import { DanceManagerDto, DanceManagerFeatureInterface, DanceManagerInterface, DanceManagerSubscriptionInterface } from '@platri/df-common-core';
import { DanceManagerHttpService } from '../http-services';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DfmStateService extends AbstractStateV2Service<DanceManagerDto> implements OnDestroy{
  selectedDm: BehaviorSubject<DanceManagerInterface | null> = new BehaviorSubject<DanceManagerInterface | null>(null);
  selectedDmSubscription: BehaviorSubject<DanceManagerSubscriptionInterface | null> = new BehaviorSubject<DanceManagerSubscriptionInterface | null>(null);
  selectedDmFeatures: BehaviorSubject<DanceManagerFeatureInterface[] | null> = new BehaviorSubject<DanceManagerFeatureInterface[] | null>(null);
  
  constructor(
    private danceManagerHttpService: DanceManagerHttpService,
  ) {
    console.log('Initializing ' + DfmStateService.name);
    super();
  }

  loadAvailableData(): void {
    this.subscriptions.add(this.allData.getSubscription(this.danceManagerHttpService.getAllDanceManagersByCurrentUser()));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.allData.unsubscribe();
    this.selectedData.unsubscribe();
  }

  //
  // selectedDm
  //

  getSyncSelectedDm(): DanceManagerInterface | null {
    return this.selectedDm.getValue();
  }

  getAsyncSelectedDm(): Observable<DanceManagerInterface | null> {
    return this.selectedDm.asObservable();
  }
  
  sendSelectedDm(data: DanceManagerInterface): void {
    this.selectedDm.next(data);
  }
  
  //
  // selectedDmFeatures
  //

  getSyncSelectedDmFeatures(): DanceManagerFeatureInterface[] | null {
    return this.selectedDmFeatures.getValue();
  }

  getAsyncSelectedDmFeatures(): Observable<DanceManagerFeatureInterface[] | null> {
    return this.selectedDmFeatures.asObservable();
  }

  sendSelectedDmFeatures(data: DanceManagerFeatureInterface[]): void {
    this.selectedDmFeatures.next(data);
  }
  
  //
  // selectedDmSubscription
  //
  
  getSyncSelectedDmSubscription(): DanceManagerSubscriptionInterface | null {
    return this.selectedDmSubscription.getValue();
  }

  getAsyncSelectedDmSubscription(): Observable<DanceManagerSubscriptionInterface | null> {
    return this.selectedDmSubscription.asObservable();
  }

  sendSelectedDmSubscription(data: DanceManagerSubscriptionInterface): void {
    this.selectedDmSubscription.next(data);
  }
}
