<ng-container *transloco="let t">
  <mat-form-field
    class="w-100"
    appearance="outline"
    *ngIf="!showChips; else withChips"
    >
    <mat-label>{{t('GENERIC_WRAPPER.CATEGORY')}}</mat-label>
    <div *ngIf="selectedFilters.length > 0 && !showChips">
      <mat-chip-grid  #chipList aria-label="Fruit selection">
        <mat-chip-option         
          *ngFor="let category of selectedFilters"
          aria-label="Fruit selection"
          [removable]="true"
          [selectable]="true"
          (removed)="removeSelectedCategory(category)"
        >
        {{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </mat-chip-grid>
    </div>
    <mat-chip-listbox #chipList aria-label="Fruit selection">
      <input
        #categoryInput
        [placeholder]="t('GENERIC_WRAPPER.CATEGORY')"
        autocomplete="off"
        (click)="matAutocompleteTrigger.openPanel()"
        [formControl]="searchCategoryInput"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
      >
    </mat-chip-listbox>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectCategory($event.option.value)"
      (closed)="checkCategoryClosed()"
      hideSingleSelectionIndicator
      >
      <mat-option    
        *ngFor="let category of filteredCategoriesList | async"
        [value]="category"
        [disabled]="category | isDisabled: selectedFilters:false"
        >{{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  
  <ng-template #withChips>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.CATEGORY')}}</mat-label>
      <mat-chip-grid  #chipList aria-label="Fruit selection">
        <mat-chip-row      
          *ngFor="let category of selectedFilters ; let i = index"
          aria-label="Fruit selection"
          (removed)="removeSelectedCategory(i)">
          {{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
      </mat-chip-grid>
  
      <mat-chip-listbox #chipList aria-label="Fruit selection">
      <input
      #categoryInput
      [placeholder]="t('GENERIC_WRAPPER.CATEGORY')"
      autocomplete="off"
      (click)="matAutocompleteTrigger.openPanel()"
      [formControl]="searchCategoryInput"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
       >
      </mat-chip-listbox>
      <mat-autocomplete
      #auto="matAutocomplete"
      hideSingleSelectionIndicator
      (closed)="checkCategoryClosed()"
      >
      <mat-option    
        *ngFor="let category of filteredCategoriesList | keyvalue;"
        [value]="category.value"
        [disabled]="category.key | isDisabled: selectedFilters:false"
        (click)="selectCategory(category.key, category.value)"
        >{{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category.key) }}
      </mat-option>
    </mat-autocomplete>
    </mat-form-field>
  </ng-template>
</ng-container>
