import { DanceManagerUserRoleEnum } from '../enums';
import { DanceManagerInterface } from './dance-manager';
import { UsersInterface } from './users';

export interface DanceSchoolToUserInterface {
  id?: string;
  userId?: string;
  danceSchoolUserRole?: DanceManagerUserRoleEnum;
  danceSchool?: DanceManagerInterface;
  danceSchoolId?: string;
  user?: UsersInterface;
  createdAt?: Date;
  updatedAt?: Date;
  isPublic?: boolean;
}
