<ng-container *transloco="let t">
  <div *ngIf="(loading$ | async) === false else spinner">
    <div class="flex-column align-center pl3 pb3 pr3 p0" style=" padding-top: 18px;">
        <div class="w-100 flex-column"style="max-width: 1140px; margin-bottom: -16px;">
            <div>
                <xng-breadcrumb [separator]="iconTemplate">
                    <ng-container *xngBreadcrumbItem="let breadcrumb">
                        <ng-container>{{ t(breadcrumb) }}</ng-container>
                    </ng-container>
                </xng-breadcrumb>
                <ng-template #iconTemplate>
                    <mat-icon>chevron_right</mat-icon>
                </ng-template>
            </div>
            <div class="flex-row">
                <mat-card class="round-corners mat-elevation-z3 mt2 w-100 no-shadow-mat-card packages-show packages-show-respon" style="max-width: 1140px;">
                    <div class="px3 pt3 flex-column">
                        <span class="title">{{ t('GENERIC_WRAPPER.PAYOUTS') }}</span>
                        <mat-divider style="position: unset"></mat-divider>
                        
                            <div class="pt3 align-space-between container-alignment">
                                <div class="info-card p2 flex-column align-center">
                                    <div class="flex-row align-space-between w-100">
                                        <span class="info-card-title">{{ t('DANCE_MANAGER_PAYOUT.TOTAL_BALANCE') }}</span>
                                        <div class="fee-info-card">
                                            <span class="fee-info-card-text p1">{{ t('DANCE_MANAGER_PAYOUT.EXCL_FEES') }}</span>
                                        </div>
                                    </div>
                                    <span class="info-card-value pt1">{{(currentDanceManager$ | async)?.stripeTotalSaldo | centsToCurrencyString: 'EUR'}}</span>
                                    <span class="info-card-subtitle pt1">{{ t('DANCE_MANAGER_PAYOUT.NEXT_PAYOUT') }}: {{ nextMonthFirstDate | dateTimezoned: 'shortDate' : currentUser?.appLang : null : true }}</span>
                                </div>
                                <div class="flex-row" style="gap: 12px;">
                                    <df-shared-lib-button [isDisabled]="danceManagerPayoutService.loadingSubject$ | async" [buttonLabel]="t('GENERIC_WRAPPER.RELOAD')" [matIconName]="'refresh_rounded'" (buttonClicked)="syncPayouts()"></df-shared-lib-button>
                                </div>
                            </div>
                            <table *ngIf="dataSource.data.length > 0; else emptyPaymentView" mat-table [dataSource]="dataSource" class="mat-elevation-z0 pt2">
                                <!-- Initiated Date Column -->
                                <ng-container matColumnDef="stripeCreatedAt">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.INITIATED_DATE') }}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.stripeCreatedAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'shortDate'}} </td>
                                </ng-container>
                                
                                <!-- Estimated Arrival Column -->
                                <ng-container matColumnDef="stripeArrivalAt">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.ESTIMATED_DATE') }}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.stripeArrivalAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'shortDate'}} </td>
                                </ng-container>
                                
                                <!-- Payout Status Column -->
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.PAYOUT_STATUS') }}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div [class]="'payout-status-' + element.status.toLowerCase() + '-card'">
                                            <span [class]="'payout-status-' + element.status.toLowerCase() + '-text'" class="p1">{{ t('DANCE_MANAGER_PAYOUT.PAYOUT_STATUS_' + element.status) }}</span>
                                        </div>
                                    </td>
                                </ng-container>
                                
                                <!-- Total Amount Column -->
                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.PAID_OUT_AMOUNT') }}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.amount | centsToCurrencyString: 'EUR'}} </td>
                                </ng-container>
                                
                                <!-- Action Column -->
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef> Action </th>
                                    <td mat-cell *matCellDef="let element"><button mat-icon-button>
                                        <mat-icon (click)="$event.stopPropagation(); downloadPayout(element);">file_download</mat-icon>
                                    </button></td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="routeToPayoutDetail(row.id)" class="row-hover-effect"></tr>
                            </table>                  
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <ng-template #emptyPaymentView>
      <div class="w-100 flex-column align-center">
        <df-shared-empty-page
          [emptyTitle]="t('DANCE_MANAGER_PAYOUT.NO_PAYMENTS.TITLE')"
          [emptyDescription]="t('DANCE_MANAGER_PAYOUT.NO_PAYMENTS.DESCRIPTION')"
          [imagePath]="'assets/images/dance_manager/payments/no_payments.svg'"
          [hideBtn]="true"
        ></df-shared-empty-page>
      </div>
    </ng-template>
  </div>

  <ng-template #spinner>
    <div *ngIf="(loading$ | async) === true">
      <mat-spinner
        style="margin: auto"
        diameter="80"
      ></mat-spinner>
    </div>
  </ng-template>
</ng-container>
