import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DANCE_STYLES, DanceStyleInterface } from '@platri/df-common-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getSubscription } from '@platri/elab-angular-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-search-dance-style',
  templateUrl: './search-dance-style.component.html',
  styleUrls: ['./search-dance-style.component.scss'],
})
export class SearchDanceStyleComponent implements OnInit, OnDestroy, OnChanges {
  danceStylesList: DanceStyleInterface[];
  selectedDanceStyles: DanceStyleInterface[] = [];
  clonedDanceStylesList: DanceStyleInterface[];
  filteredDanceStylesList: DanceStyleInterface[];
  loading = true;
  searchDanceStyleInput: FormControl = new FormControl('');

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('danceStyleInput') danceStyleInput: ElementRef;
  
  @Input() previouslySelectedOptions: DanceStyleInterface[];
  @Input() selectedFilters: DanceStyleInterface[];
  @Input() required = false;
  @Input() showChips = false;
  @Output() selectedOption = new EventEmitter<DanceStyleInterface>();
  @Output() selectedFiltersChanged = new EventEmitter<DanceStyleInterface[]>();

  subscription: Subscription = new Subscription();

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.loadDanceStyles();
    if (this.required) {
      this.searchDanceStyleInput.addValidators(Validators.required);
    }
    
    this.onSearchDanceStyleInput();
  }

  initializeSubscriptions(): void {
    this.subscription.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteQueryParamsChanges.bind(this)));
  }

  onActivatedRouteQueryParamsChanges(params: Params): void {
    if (params['dancestyles']) {
      const translationKeys = params['dancestyles'].split(',');
      this.selectedDanceStyles = this.clonedDanceStylesList.filter((danceStyle) => translationKeys.includes(danceStyle.translationKey));
      this.selectedFiltersChanged.emit(
        this.selectedDanceStyles.map(
          (selectedDanceStyle) => selectedDanceStyle
        )
      );    
    }
  }

  ngOnChanges(changes: SimpleChanges):void {
    if (
      changes.selectedFilters?.currentValue !==
      changes.selectedFilters?.previousValue
    ) {
      this.selectedDanceStyles = this.selectedFilters|| [];
    }
    if (this.searchDanceStyleInput) {
      this.validateDanceStyles();
    }
  }
  
  loadDanceStyles(): void {
    this.subscription.add(
      this.translocoService.selectTranslate('GENERIC_WRAPPER.DANCE_STYLES').subscribe((translations) => {
        this.danceStylesList = DANCE_STYLES.sort(
          (firstDanceStyle, secondDanceStyle) =>
            translations[firstDanceStyle.translationKey.toUpperCase()] < translations[secondDanceStyle.translationKey.toUpperCase()] ? -1 : 1
        );
      })
    );
    this.clonedDanceStylesList = this.danceStylesList?.length > 0 ? structuredClone(this.danceStylesList) : [];
    this.filteredDanceStylesList = this.clonedDanceStylesList;
    this.searchDanceStyleInput.enable();
    this.loading = false;
    this.initializeSubscriptions();
  }

  onSearchDanceStyleInput(): void {
    this.subscription.add(
      this.searchDanceStyleInput.valueChanges
        .pipe(debounceTime(250))
        .subscribe((input: string | DanceStyleInterface) => {
          this.filteredDanceStylesList = this.clonedDanceStylesList.filter((name) => name.translationKey.toLowerCase().includes(typeof input === 'string' ? input?.toLowerCase() : input?.translationKey.toLowerCase()));
        })
    );
  }

  removeSelectedDanceStyle(danceStyle: DanceStyleInterface):void {
    const index = this.selectedDanceStyles.indexOf(danceStyle);
    if (index >= 0) {
      this.selectedDanceStyles.splice(index, 1);
      this.selectedDanceStyles = [...this.selectedDanceStyles];
      
      if (this.selectedDanceStyles.length > 0) {
        this.selectedFiltersChanged.emit(
          this.selectedDanceStyles.map(
            (selectedDanceStyle) => selectedDanceStyle
          )
        );
      } else {
        this.selectedFiltersChanged.emit(null);
      }
      this.updateQueryParams();
    }
  }

  selectOption(danceStyle: DanceStyleInterface): void {
    if (!this.previouslySelectedOptions?.includes(danceStyle)) {
      this.selectedDanceStyles = this.selectedDanceStyles.concat([danceStyle]);
      this.selectedOption.emit(danceStyle);
      this.onSearchDanceStyleInput();
      this.searchDanceStyleInput.setValue(this.selectedDanceStyles);
      this.selectedFiltersChanged.emit(
        this.selectedDanceStyles.map(
          (selectedDanceStyle) => selectedDanceStyle
        )
      );
      this.updateQueryParams();
    }
  }

  resetFormControlValue():void {
    this.searchDanceStyleInput.patchValue('');
    this.validateDanceStyles();
  }

  validateDanceStyles(): void {
    if (
      (this.selectedDanceStyles?.length === 0 ||
        this.previouslySelectedOptions?.length === 0 ||
        this.selectedDanceStyles === null ||
        this.previouslySelectedOptions === null) &&
      this.required
    ) {
      this.searchDanceStyleInput.setErrors({ required: true });
    } else {
      this.searchDanceStyleInput.setErrors(null);
    }
  }
  
  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        dancestyles: this.selectedDanceStyles?.length > 0 ? this.selectedDanceStyles.map(
          (selectedDanceStyle) => selectedDanceStyle.translationKey
        ).join(',') : null
      },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy(): void { 
    this.subscription.unsubscribe();
  }
}
