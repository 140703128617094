<ng-container *transloco="let t">
  <div class="general-container flex-column">
    <div
      class="flex-column gallery-page-container"
    >
      <div *ngIf="danceSchoolImageUrls?.length > 0; else emptyGallery">
        <div
          class="w-100 flex-column  picture-container"
        >
          <div class="image-container">
              <div
                *ngFor="let imageUrl of danceSchoolImageUrls"
                class="container"
              >
                <div
                  class="image-highlighter"
                >
                  <button mat-icon-button (click)="deleteImage(imageUrl)" class="btn">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
                <img [src]="imageUrl" alt="" />
              </div>
          </div>
        </div>
        <div class="flex-row align-start mt-20" style="margin-left: 6px;">
          <df-shared-lib-button
            [materialButtonStyleType]="'outlined'"
            [buttonLabel]="t('DANCE_MANAGER_ADMINISTRATION.GALLERY.ADD_ADDITIONAL_IMAGES')"
            (buttonClicked)="openImageUploadDialog()">
          </df-shared-lib-button>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #emptyGallery>
    <div class="general-container flex-column align-center">
      <div
        class="empty-gallery-wrap-container w-100 flex-column align-center"
      >
        <div class="circle mt3 align-center">
          <img
          src="assets/images/dance_manager/gallery/no_gallery.svg" 
          alt="no_gallery icon"
          />
        </div>
        <div class="flex-column align-center">
          <span class="no-gallery-title">{{ t('DANCE_MANAGER_ADMINISTRATION.GALLERY.NO_GALLERY_TITLE') }}</span>
          <span class="px3 no-gallery-text">{{ t('DANCE_MANAGER_ADMINISTRATION.GALLERY.NO_GALLERY_DESCRIPTION')}}</span>
        </div>
        <df-shared-lib-button
          [buttonLabel]="t('DANCE_MANAGER_ADMINISTRATION.GALLERY.NO_GALLERY_UPLOAD_BUTTON')"
          (buttonClicked)="openImageUploadDialog()"
        >
        </df-shared-lib-button>
      </div>
    </div>
  </ng-template>
</ng-container>
