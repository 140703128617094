import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CdkStep } from '@angular/cdk/stepper';
import { DanceEventsInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-event-ticket-settings-step',
  templateUrl: './event-ticket-settings-step.component.html',
  styleUrls: ['./event-ticket-settings-step.component.scss']
})
export class EventTicketSettingsStepComponent extends CdkStep{
  @Input() passedFormGroup: UntypedFormGroup;
  @Input() danceEvents?: DanceEventsInterface[];

  constructor() {
    super(null);
  }
  get nameControl(): AbstractControl {
    return this.passedFormGroup.get('name');
  }

  compareDanceEvent(d1: DanceEventsInterface, d2: DanceEventsInterface): boolean {
    return d1 && d2 ? d1.id === d2.id : d1 === d2;
  }
  
}
