import { CurrencyDto } from '../../..';
import { DmPassSubscriptionIntervalEnum, UserPassSubscriptionInterface, UserPassSubscriptionStatusEnum } from '../../..';
import { DmPassSubscriptionTargetDto } from './dm-pass-subscription-target.dto';

export class UserPassSubscriptionDto implements UserPassSubscriptionInterface {
  id: string;
  createdAt: Date;
  startedAt: Date;
  endedAt: Date;
  canceledAt: Date;
  currentPeriodStartAt: Date;
  currentPeriodEndAt: Date;
  currency: CurrencyDto;
  taxPercentage: number;
  price: number;
  name: string;
  description: string;
  danceManagerId: string;
  dmPassSubscriptionId: string;
  stripeSubscriptionId: string;
  durationValue: number;
  durationUnit: DmPassSubscriptionIntervalEnum;
  isPaidMonthly: boolean;
  weeklyUsage: number;
  targets: DmPassSubscriptionTargetDto[];
  status: UserPassSubscriptionStatusEnum;
  savedCurrentStatus?: UserPassSubscriptionStatusEnum;
  userId: string;
  newPrice?: number;
  priceChangeDate?: Date;
  priceAcceptanceDate?: Date;
  priceAccepted?: boolean;
  
  constructor(args: UserPassSubscriptionDto) {
    if (args) {
      this.id = args.id;
      this.createdAt = args.createdAt;
      this.startedAt = args.startedAt;
      this.currentPeriodStartAt = args.currentPeriodStartAt;
      this.currentPeriodEndAt = args.currentPeriodEndAt;
      this.endedAt = args.endedAt;
      this.canceledAt = args.canceledAt;
      this.currency = args.currency;
      this.taxPercentage = args.taxPercentage;
      this.price = args.price;
      this.name = args.name;
      this.description = args.description;
      this.danceManagerId = args.danceManagerId;
      this.durationValue = args.durationValue;
      this.durationUnit = args.durationUnit;
      this.isPaidMonthly = args.isPaidMonthly;
      this.weeklyUsage = args.weeklyUsage;
      this.targets = args.targets;
      this.status = args.status;
      this.savedCurrentStatus = args.status;
      this.userId = args.userId;
      this.dmPassSubscriptionId = args.dmPassSubscriptionId;
      this.stripeSubscriptionId = args.stripeSubscriptionId;
      this.newPrice = args.newPrice;
      this.priceChangeDate = args.priceChangeDate;
      this.priceAcceptanceDate = args.priceAcceptanceDate;
      this.priceAccepted = args.priceAccepted;
    }
  }
}
