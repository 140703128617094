import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerPassesListItemComponent } from './dance-manager-passes-list-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CourseListItemCardModule, DfSharedButtonModule, PassCheckoutListItemModule, PipesModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatIcon} from "@angular/material/icon";
import { MatList, MatListItem, MatListItemIcon } from '@angular/material/list';
import { MatCard } from '@angular/material/card';
import {MatIconButton} from "@angular/material/button";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceManagerPassesListItemComponent],
    imports: [
      CommonModule,
      InfiniteScrollModule,
      CourseListItemCardModule,
      MatProgressSpinnerModule,
      PassCheckoutListItemModule,
      MatIcon, 
      MatList,
      MatListItem, 
      PipesModule,
      MatCard, 
      MatListItemIcon, 
      DfSharedButtonModule,
      MatIconButton, 
      TranslocoDirective
    ],
  exports: [DanceManagerPassesListItemComponent],
})
export class DanceManagerPassesListItemModule {}
