import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {
  CoursePageContentModule,
  CoursePassesTableModule,
  DanceEventPreviewCardModule,
  DfSharedButtonModule,
  DmEventPreviewCardModule,
  PaginatorModule,
  PipesModule,
  SharedEmptyPageModule
} from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoursePreviewComponent } from './course-preview.component';
import { ParticipantsListTableModule } from '../../components/participants-list/participants-list-table.module';
import { CourseAppointmentsTableModule } from '../../components/course-appointments-table/course-appointments-table.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CourseInfoFormModule } from '../../components/course-info-form/course-info-form.module';
import { EditCourseRulesFormModule } from '../../components/edit-course-rules-form/edit-course-rules-form.module';
import { EditCourseInfoFormModule } from '../../components/edit-course-info-form/edit-course-info-form.module';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

@NgModule({
  declarations: [CoursePreviewComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    PaginatorModule,
    TranslocoModule,
    MatTooltipModule,
    MatTabsModule,
    DanceEventPreviewCardModule,
    DmEventPreviewCardModule,
    RouterModule,
    CoursePageContentModule,
    ParticipantsListTableModule,
    PipesModule,
    CourseAppointmentsTableModule,
    MatButtonToggleModule,
    CourseInfoFormModule,
    EditCourseRulesFormModule,
    DfSharedButtonModule,
    EditCourseInfoFormModule,
    CoursePassesTableModule,
    SharedEmptyPageModule,
    BreadcrumbComponent, 
    BreadcrumbItemDirective
  ]
})
export class CoursePreviewModule {}
