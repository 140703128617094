<ng-container *transloco="let t">
  <div class="flex-column">
    <div *ngFor="let dayName of objectKeys(openingHours); let i = index" class="flex-column">
      <div *ngIf="i !== objectKeys(openingHours).length -1">
        <div *ngFor="let openingHour of openingHours[dayName]; let i = index;" class="flex-row gap-10">
          <span style="width: 10%">{{ i === 0 ? t('GENERIC_WRAPPER.WEEKDAYS.SHORT.' + dayName.toUpperCase()) + ':' : ''}}</span>
          <span>{{openingHour.timeStart | timeInformationToHoursMinutes : showTimeInEnglishFormat}} -  {{openingHour.timeEnd | timeInformationToHoursMinutes : showTimeInEnglishFormat}} {{showTimeInEnglishFormat ? '' : t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.TIME')}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
