import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  DanceSchoolHttpService,
  DfmStateService
} from '@platri/dfx-angular-core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Address } from '@platri/df-common-shared-graphql';
import {
  DanceManagerSocialLinkInterface,
  DanceSchoolInterface,
  emailRegex
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-contact',
  templateUrl: './dfm-contact.component.html',
  styleUrls: ['./dfm-contact.component.scss'],
})
export class DfmContactComponent implements OnInit, OnDestroy {
  danceSchool: DanceSchoolInterface;
  formGroup: UntypedFormGroup;

  destroy$: Subject<void> = new Subject<void>();
  subscriptions = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private dfmStateService: DfmStateService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadDanceSchool();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      businessAddress: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex)]],
      phonePrefix: ['49'],
      phonePrefixAfter: [null],
      phoneNumber: [null, Validators.pattern('^[0-9 ]*$')],
      socialLinks: [''],
    });
  }

  loadDanceSchool(): void {
    this.dfmStateService
      .getAsyncSelectedDm()
      .pipe(takeUntil(this.destroy$))
      .subscribe((danceSchool) => {
        if (danceSchool) {
          this.danceSchool = danceSchool;
          this.formGroup.patchValue({
            businessAddress: danceSchool.businessAddress,
            email: danceSchool.email,
            phonePrefix: danceSchool.phonePrefix,
            phonePrefixAfter: danceSchool.phonePrefixAfter,
            phoneNumber: danceSchool.phoneNumber,
            socialLinks: danceSchool.socialLinks,
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateSocialLinks(socialLinks: DanceManagerSocialLinkInterface[]): void {
    this.formGroup.patchValue({
      socialLinks,
    });
  }

  saveContactForm(): void {
    if (this.formGroup.valid) {
      const data = this.formGroup.getRawValue();
      delete data.businessAddress.deletedDate;
      
      this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchool.id, this.formGroup.getRawValue()).subscribe({
        next: () => {
          this.dfmStateService.loadAvailableData();
          this.openSnackBarSavedSuccess();
        },
        error: () => {
          this.dfmStateService.loadAvailableData();
          this.openSnackBarSaveFailed();
        },
      });
    }
  }

  openSnackBarSaveFailed(): void {
    const message = this.translocoService.translate('GENERIC_WRAPPER.SNACKBAR.ENTER_VALID_LINK');
    this.matSnackBar.open(message);
  }

  setSelectedBusinessAddress(address?: Address): void {
    if (address) {
      this.formGroup.controls.businessAddress.setValue(address);
    } else {
      this.formGroup.controls.businessAddress.setValue(null);
    }
  }

  openSnackBarSavedSuccess(): void {
    const message = this.translocoService.translate('GENERIC_WRAPPER.SNACKBAR.SUCCESSFULLY_SAVED');
    this.matSnackBar.open(message);
  }
}
