import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  DanceEventStateService,
  DanceSchoolLocationService,
  DanceSchoolStateService,
  dateRangeValidator,
  rangeValidator,
  SharedCancelDialogComponent
} from '@platri/dfx-angular-core';
import { MatStepper } from '@angular/material/stepper';
import { DanceStyle, MusicStyle } from '@platri/df-common-shared-graphql';
import {
  CreateDanceEventRequestDto,
  DanceEventCoHostsInterface,
  LocationInterface
} from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import { DateTime, Interval } from 'luxon';
import { debounceTime } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-create-dance-event-page',
  templateUrl: './create-dance-event-stepper.page.html',
  styleUrls: ['./create-dance-event-stepper.page.scss']
})
export class CreateDanceEventStepperPage implements OnInit {
  generalInfoFormGroup: UntypedFormGroup;
  appointmentAndLocationFormGroup: UntypedFormGroup;
  rulesFormGroup: UntypedFormGroup;
  participationFormGroup: UntypedFormGroup;
  ageRestrictionFormGroup: UntypedFormGroup;
  genderRestrictionFormGroup: UntypedFormGroup;
  clothRestrictionFormGroup: UntypedFormGroup;

  firstStepValid = false;
  secondStepValid = false;

  locations: LocationInterface[];

  constructor(
    private readonly fb: UntypedFormBuilder, 
    public readonly danceEventService: DanceEventStateService, 
    private readonly locationService: DanceSchoolLocationService, 
    private readonly danceSchoolService: DanceSchoolStateService, 
    private readonly matDialog: MatDialog,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.getLocationsOfCurrentDanceManager();
  }

  getLocationsOfCurrentDanceManager(): void {
    this.locationService.getAsyncCurrentDanceSchoolLocations().subscribe((locations) => {
      this.locations = locations;
      this.initializeFormGroups();
    });

    this.locationService.getAllDanceSchoolLocationsByDanceSchoolId(this.danceSchoolService.getSyncCurrentDanceSchool().id!);
  }

  initializeFormGroups(): void {
    this.initializeAgeRestrictionFormGroup();
    this.initializeGenderRestrictionFormGroup();
    this.initializeClothRestrictionFormGroup();
    this.initializeParticipationFormGroup();
    this.initializeRulesFormGroup();
    this.initializeAppointmentAndLocationFormGroup();
    this.initializeGeneralInfoFormGroup();
  }

  initializeGeneralInfoFormGroup(): void {
    this.generalInfoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      description: [null, [Validators.required],],
      categories: [null, [Validators.required]],
      danceStyles: [],
      musicStyles: [],
      coHosts: [],
      tags: [],
      url: [null, Validators.pattern('(https://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      mainImage: [null]
      
    });
    
    this.generalInfoFormGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.firstStepValid = this.generalInfoFormGroup.valid;
      if (data.url !== null && data.url !== '' && !data.url.includes('https://')) {
        this.generalInfoFormGroup.patchValue({
          url: 'https://' + data.url
        });
      }
      if (data.url === '') {
        this.generalInfoFormGroup.patchValue({
          url: null
        });
      }
    });
  }

  initializeAppointmentAndLocationFormGroup(): void {
    this.appointmentAndLocationFormGroup = this.fb.group({
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      locationId: [null, [Validators.required]],
      address: [null]
    }, {
      validators: dateRangeValidator()
    });
  }

  initializeAgeRestrictionFormGroup(): void {
    this.ageRestrictionFormGroup = this.fb.group(
      {
        fromAge: this.fb.control({ value: 0, disabled: false }, [Validators.min(0), Validators.max(150)]),
        toAge: this.fb.control({ value: 100, disabled: false }, [Validators.min(0), Validators.max(150)])
      },
      {
        validators: rangeValidator('fromAge', 'toAge')
      }
    );
  }

  initializeGenderRestrictionFormGroup(): void {
    this.genderRestrictionFormGroup = this.fb.group({
      isMaleRestricted: this.fb.control({ value: false, disabled: false }),
      isFemaleRestricted: this.fb.control({ value: false, disabled: false }),
      isDiverseRestricted: this.fb.control({ value: false, disabled: false })
    });
  }

  initializeClothRestrictionFormGroup(): void {
    this.clothRestrictionFormGroup = this.fb.group({
      isRestricted: [false],
      restriction: [null]
    });
  }

  initializeParticipationFormGroup(): void {
    this.participationFormGroup = this.fb.group({
      isAgeRestricted: this.fb.control(false),
      ageRestriction: this.ageRestrictionFormGroup,
      isGenderRestricted: this.fb.control(false),
      genderRestriction: this.genderRestrictionFormGroup
    });
  }

  initializeRulesFormGroup(): void {
    this.rulesFormGroup = this.fb.group({
      participationRestriction: this.participationFormGroup,
      clothingRestriction: this.clothRestrictionFormGroup
    });
  }

  navigateToEventsOverViewPage(): void {
    if (this.generalInfoFormGroup.dirty) {
      const cancelDialogRef = this.matDialog.open(SharedCancelDialogComponent, {
        panelClass: ['br-20', 'bg-glassmorph'],
        maxWidth: '400px',
        disableClose: true,
        data: {
          title: this.translocoService.translate('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CREATE.TITLE'),
          subtitle: this.translocoService.translate('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CREATE.SUBTITLE'),
          infoText: this.translocoService.translate('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CREATE.STAY'),
          cancelText: this.translocoService.translate('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CREATE.OK')
        }
      });

      cancelDialogRef.afterClosed().subscribe((reason) => {
        if (!reason) {
          this.danceEventService.navigateToEventsOverViewPage();
        }
      });
    } else {
      this.danceEventService.navigateToEventsOverViewPage();
    }
  }

  goToNextStep(index: number, stepper: MatStepper): void {
    if (index === 0) {
      if (this.generalInfoFormGroup.valid) {
        stepper.selected!.completed = true;
        stepper.next();
      }
    }
    if (index === 1) {
      if (this.appointmentAndLocationFormGroup.valid) {
        stepper.selected!.completed = true;
        stepper.next();
      }
    }
  }

  createDanceEvent(active: boolean): void {
    const generalInformationRawValue = this.generalInfoFormGroup.getRawValue();

    const {address} = this.appointmentAndLocationFormGroup.getRawValue();

    const startDateTime = this.parseDateTime(this.appointmentAndLocationFormGroup.getRawValue().startDate);
    const endDateTime = this.parseDateTime(this.appointmentAndLocationFormGroup.getRawValue().endDate);
    const diffInMinutes = Interval.fromDateTimes(startDateTime, endDateTime).length('minute');
    
    if (address) {
      delete address.__typename;
    }

    const participationRestrictionRawValue = this.rulesFormGroup.getRawValue().participationRestriction;
    const clothingRestrictionRawValue = this.clothRestrictionFormGroup.getRawValue();

    const createDanceEvent: CreateDanceEventRequestDto = {
      name: generalInformationRawValue.name,
      categories: generalInformationRawValue.categories,
      description: generalInformationRawValue.description,
      danceStyleIds: generalInformationRawValue.danceStyles?.map((danceStyle: DanceStyle) => danceStyle.id) ?? null,
      musicStyleIds: generalInformationRawValue.musicStyles?.map((musicStyle: MusicStyle) => musicStyle.id) ?? null,
      startDate: this.appointmentAndLocationFormGroup.getRawValue().startDate,
      duration: diffInMinutes,
      tags: generalInformationRawValue.tags,
      url: generalInformationRawValue.url,
      isActive: active,
      locationId: this.appointmentAndLocationFormGroup.getRawValue().locationId,
      address: this.appointmentAndLocationFormGroup.getRawValue().address,
      restrictions: {
        clothingRestriction: {
          isRestricted: clothingRestrictionRawValue.isRestricted,
          restriction: clothingRestrictionRawValue.isRestricted ? clothingRestrictionRawValue.restriction : null
        },
      },
      rules: {
        participationRestriction: participationRestrictionRawValue
      },
      danceSchoolId: this.danceSchoolService.getSyncCurrentDanceSchool().id!,
      coHosts: generalInformationRawValue.coHosts?.map((obj: DanceEventCoHostsInterface) => ({
          danceManagerId: obj.danceManagerId
        })) ?? null
    };
    let formData = null;
    if (this.generalInfoFormGroup.get('mainImage')?.value != null) {
      formData = new FormData();
      formData.append('file', this.generalInfoFormGroup.get('mainImage')?.value.formData.get('file'));
    }
    
    this.danceEventService.createDanceEventNew(createDanceEvent, active, formData);
    
  }

  parseDateTime(dateTimeString: string): DateTime {
    const [datePart, timePart] = dateTimeString.split('T');

    const [year, month, day] = datePart.split('-').map(Number);

    const [hour, minute] = timePart.split(':').map(Number);

    const dateTime = DateTime.now()
      .setZone('UTC')
      .set({
        day: day,
        month: month,
        year: year,
        hour: hour,
        minute: minute,
        second: 0,
        millisecond: 0
      });

    return dateTime;
  }

  goToPreviousStep(stepper: MatStepper): void {
    stepper.previous();
  }
}
