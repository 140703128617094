export enum DanceManagerSocialLinkTypeEnum {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  XING = 'XING',
  WEBSITE = 'WEBSITE',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}
