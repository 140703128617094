import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserFollowStateService, UserProfileStateService } from '../../services';

@Component({
  selector: 'df-follow-info-dialog',
  templateUrl: './follow-info-dialog.component.html',
  styleUrls: ['./follow-info-dialog.component.scss']
})
export class FollowInfoDialogComponent implements OnInit {
  tabIndex = 0;
  hideFollowing = false;
  hideFollowers = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<FollowInfoDialogComponent>, private readonly _profileService: UserProfileStateService, public readonly userFollowStateService: UserFollowStateService) {
   
  }

  ngOnInit(): void {
    this.tabIndex = this.data.tabIndex;
    this.hideFollowing = this.data.hideFollowing;
    this.hideFollowers = this.data.hideFollowers;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
