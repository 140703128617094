import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { EmptyCoursesListComponent } from './empty-courses-list.component';
import { RouterModule } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [EmptyCoursesListComponent],
  exports: [EmptyCoursesListComponent],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        DfSharedButtonModule,
        TranslocoDirective
    ],
})
export class EmptyCoursesListModule {}
