import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../../pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {TranslocoDirective } from '@jsverse/transloco';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { DanceEventPreviewCardComponent } from './dance-event-preview-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserSearchAutocompleteModule } from '../../user-search-autocomplete';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [DanceEventPreviewCardComponent],
    imports: [
      CommonModule,
      MatTooltipModule,
      MatButtonModule,
      MatIconModule,
      PipesModule,
      NgxSkeletonLoaderModule,
      RouterModule,
      MatMenuModule,
      MatDialogModule,
      MatButtonModule,
      MatChipsModule,
      MatIconModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      UserSearchAutocompleteModule,
      MatInputModule,
      TranslocoDirective,
    ],
  exports: [DanceEventPreviewCardComponent],
})
export class DanceEventPreviewCardModule {}
