import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import {
  CURRENT_LANGUAGE,
  DfLogoModule,
  HeaderLanguageSelectionModule,
  HeaderUserButtonModule,
  ImageFallbackDirectiveModule,
  NotificationItemModule,
  PipesModule,
  SidenavResponsiveDmModule,
  SidenavResponsivePersonalModule,
  TranslocoStateConst,
  UserNotificationItemModule
} from '@platri/dfx-angular-core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HeaderAppComponent } from './header-app.component';
import { HeaderAfterLoggedInComponent } from '../../components/header-after-logged-in/header-after-logged-in.component';
import { HeaderBeforeLoggedInComponent } from '../../components/header-before-logged-in/header-before-logged-in.component';
import { HeaderDanceManagerSelectionComponent } from '../../components/header-after-logged-in/header-dance-manager-selection/header-dance-manager-selection.component';
import { HeaderUserNotificationComponent } from '../../components/header-after-logged-in/header-user-notification/header-user-notification.component';
import { HeaderDfLogoComponent } from '../../components/header-after-logged-in/header-df-logo/header-df-logo.component';
import {
  TranslocoDirective,
  TranslocoModule,
  TranslocoService
} from '@jsverse/transloco';

@NgModule({
  declarations: [
    HeaderAppComponent,
    HeaderAfterLoggedInComponent,
    HeaderBeforeLoggedInComponent,
    HeaderDanceManagerSelectionComponent,
    HeaderUserNotificationComponent,
    HeaderDfLogoComponent
  ],
  exports: [HeaderAppComponent],
    imports: [
      CommonModule,
      MatToolbarModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      RouterModule,
      MatDividerModule,
      DfLogoModule,
      NotificationItemModule,
      TranslocoModule,
      MatBadgeModule,
      MatTooltipModule,
      HeaderLanguageSelectionModule,
      PipesModule,
      UserNotificationItemModule,
      ImageFallbackDirectiveModule,
      SidenavResponsivePersonalModule,
      SidenavResponsiveDmModule,
      MatProgressSpinnerModule,
      HeaderUserButtonModule,
      TranslocoDirective,
    ],
  providers: [
    {
      provide: CURRENT_LANGUAGE,
      useFactory: (translocoService: TranslocoService): string => translocoService.getActiveLang().toUpperCase(),
      deps: [TranslocoService],
    }
  ],
})
export class HeaderAppModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-header', alias: 'DANCEFLAVORS_HEADER' });
  }
}
