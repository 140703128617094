import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuplicateDanceEventDialogComponent } from './duplicate-dance-event-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DfSharedButtonModule } from '../buttons';
import {MatIcon} from "@angular/material/icon";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DuplicateDanceEventDialogComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatInputModule,
      MatCheckboxModule,
      DfSharedButtonModule,
      MatIcon,
      TranslocoDirective,
    ],
  exports: [DuplicateDanceEventDialogComponent],
})
export class DuplicateDanceEventDialogModule {}
