import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialElevationDirective } from './material-elevation.directive';

@NgModule({
  declarations: [MaterialElevationDirective],
  exports: [
    MaterialElevationDirective
  ],
  imports: [
    CommonModule
  ]
})
export class MaterialElevationModule { }
