<ng-container *transloco="let t">
  <div class="flex-row" style="padding-top: 10px;">
    <mat-chip (click)="toggleChip(0)" [class]="activeChipIndex === 0 ? 'active-chip' : ''" style="margin-right: 10px;">
      {{ t('GENERIC_WRAPPER.ACTIVE') }}
    </mat-chip>
    <mat-chip (click)="toggleChip(1)" [class]="activeChipIndex === 1 ? 'active-chip' : ''">
      {{ t('USER.INVENTORY.USED') }}
    </mat-chip>
  </div>
  <div *ngIf="activeChipIndex === 0">
    <div *ngIf="userPassesCollapsed?.length > 0 && userPassesDanceManagers?.length > 0 && userPassesCourses?.length > 0; else noPasses" class="flex-column-start-center gap-5 mt2">
      <df-user-inventory-pass-list-item
        *ngFor="let activeUserPass of userPassesCollapsed"
        [danceManager]="filterUserPassDanceManagersByPassId(activeUserPass.danceManagerId)"
        [courses]="filterUserPassCoursesByPassId(activeUserPass.targets)"
        [userPassCollapsed]="activeUserPass"></df-user-inventory-pass-list-item>
    </div>
    <ng-template #noPasses>
      <div class="flex-column-center-center gap-15" style="padding-top: 10vh;">
        <img style="display: block; width: 174px; height: 174px" [src]="'assets/images/tickets-icon.png'" [alt]="">
        <span class="no-tickets-and-passes-title">{{ t('USER.NO_ACTIVE_PASSES') }}</span>
        <span class="no-tickets-and-passes-sub-title">{{ t('USER.NO_ACTIVE_PASSES_SUBTITLE') }}</span>
        <df-shared-lib-button
          buttonLabel="{{t('USER.SEARCH_COURSES')}}"
          (buttonClicked)="navigateToCoursesSearch()">
        </df-shared-lib-button>
      </div>
    </ng-template>
  </div>
  <div *ngIf="activeChipIndex === 1">
    <div class="pt2 pb2">
      <div *ngIf="userPassesCollapsed?.length > 0 && userPassesDanceManagers?.length > 0 && userPassesCourses?.length > 0" class="flex-column-start-center gap-5 mb2">
        <df-user-inventory-pass-list-item
          *ngFor="let usedUserPass of userPassesCollapsed"
          [danceManager]="filterUserPassDanceManagersByPassId(usedUserPass.danceManagerId)"
          [courses]="filterUserPassCoursesByPassId(usedUserPass.targets)"
          [userPassCollapsed]="usedUserPass"></df-user-inventory-pass-list-item>
      </div>
      <div *ngIf="!(userPassesCollapsed?.length > 0)">
        <div class="flex-column-center-center gap-15" style="padding-top: 10vh;">
          <img style="display: block; width: 174px; height: 174px" [src]="'assets/images/tickets-icon.png'" [alt]="">
          <span class="no-tickets-and-passes-title">{{ t('USER.NO_USED_PASSES') }}</span>
          <span class="no-tickets-and-passes-sub-title">{{ t('USER.NO_USED_PASSES_SUBTITLE') }}</span>
          <df-shared-lib-button
            buttonLabel="{{t('USER.SEARCH_COURSES')}}"
            (buttonClicked)="navigateToCoursesSearch()">
          </df-shared-lib-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
