import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Address as ElabGoogleAddress, FrontendGoogleAutocompleteDirective, LatLngLiteral, Options } from '@platri/elab-angular-google-autocomplete';
import { Subject } from 'rxjs';
import { CoordinatesStateService, SearchHttpService } from '../../services';
import { AddressInterface, ConfirmationDialogInterface } from '@platri/df-common-core';
import { CoordinatesInput } from '@platri/df-common-shared-graphql';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-google-address-search-autocomplete',
  templateUrl: './google-address-search-autocomplete.component.html',
  styleUrls: ['./google-address-search-autocomplete.component.scss'],
})
export class GoogleAddressSearchAutocompleteComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @ViewChild('address') addressInput: ElementRef;
  @ViewChild('addressAlternative') addressInputAlternative: ElementRef;

  @ViewChild('placesRef') placesRef: FrontendGoogleAutocompleteDirective;

  @Output() addressSelected = new EventEmitter<AddressInterface>();
  @Output() addressChanging = new EventEmitter<void>();
  @Output() newPacContainer = new EventEmitter<HTMLDivElement>();
  @Output() keyPressedEnter = new EventEmitter<void>();

  @Input() optional: boolean;
  @Input() touched: boolean;
  @Input() label: string;
  @Input() hint: string;
  @Input() disabled: boolean;
  @Input() disableCondition: boolean;
  @Input() errorMessageTranslationKey: string;
  @Input() markAddressAsTouched: boolean;
  @Input() coordinates: CoordinatesInput;
  @Input() address: AddressInterface;
  @Input() houseNumberRequired = true;
  @Input() types: string[] = [];
  @Input() alternativeDesign = false;
  @Input() isCity = false;
  @Input() customPlaceholder: string;

  options: Partial<Options>;
  enterKeyPressed = false;
  tempInputAddress: ElabGoogleAddress;
  coordinatesChecked = false;
  alreadyExistingCoordinates: CoordinatesInput;
  searchAddressForm: UntypedFormGroup;
  destroy$ = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    private searchService: SearchHttpService,
    private coordinatesService: CoordinatesStateService,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private searchHttpService: SearchHttpService
  ) {}

  get addressControl(): AbstractControl {
    return this.searchAddressForm?.get('address');
  }
  
  ngOnInit(): void {
    this.options = {
      types: this.types,
    };
    this.initForm();
    this.setAddress(this.address);
    if (this.coordinates) {
      this.getAddressFromCoordinates(this.coordinates);
    } else {
      this.getBrowserPermissionForLocationOfUser();
    }
    this.checkErrorMessage();

    this.initInputElement();
  }

  ngAfterContentChecked(): void {
    this.initInputElement();
  }

  ngAfterViewInit(): void {
    this.initInputElement();
  }

  checkErrorMessage(): void {
    if (this.isCity) {
      this.errorMessageTranslationKey = 'GOOGLE_ADDRESS_ERROR.REQUIRED_CITY';
    } else {
      this.errorMessageTranslationKey = 'GOOGLE_ADDRESS_ERROR.REQUIRED';
    }
  }

  initInputElement(): void {
    const element: HTMLInputElement =
      this.addressInput?.nativeElement ||
      this.addressInputAlternative?.nativeElement;

    if (element) {
      this.selectFirstOnEnter(element);
    }
  }

  selectFirstOnEnter(input: HTMLInputElement): void {
    /* Store original event listener */
    const _addEventListener = input.addEventListener;

    input.addEventListener = (type, listener): void => {
      if (type === 'keydown') {
        /* Store existing listener function */
        const _listener = listener;
        listener = (event): void => {
          /* Simulate a 'down arrow' keypress if no address has been selected */
          const suggestionSelected =
            document.getElementsByClassName('pac-item-selected').length;
          if (event.key === 'Enter' && !suggestionSelected) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });
            _listener.apply(input, [e]);
          }
          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markAddressAsTouched?.currentValue) {
      this.searchAddressForm?.controls.address.markAsTouched();
    }
    if (changes.address?.currentValue) {
      this.setAddress(changes.address.currentValue);
    }
    if (changes.disableCondition?.currentValue) {
      this.setAddress(this.address);
    }
    if (changes.coordinates?.currentValue && !this.coordinatesChecked) {
      this.getAddressFromCoordinates(this.coordinates);
      this.coordinatesChecked = true;
    }
    if (this.touched){
      this.addressControl.markAsTouched();
    }
  }

  getAddressFromCoordinates(coordinates: CoordinatesInput): void {
    if (
      Math.ceil(coordinates?.latitude) / 100 !==
        Math.ceil(this.alreadyExistingCoordinates?.latitude) / 100 ||
      Math.ceil(coordinates?.longitude) / 100 !==
        Math.ceil(this.alreadyExistingCoordinates?.longitude)/ 100 ||
      this.addressControl.touched
    ) {
      this.alreadyExistingCoordinates = coordinates;
      // todo!
      // @ts-ignore
      this.searchService.getAddressFromCoordinate({latitude: coordinates.latitude, longitude: coordinates.longitude})
        .subscribe((address) => {
          if (address) {
            this.addressControl.patchValue(
              address.results.find((a) => a.types.includes('postal_code'))
                ?.formatted_address ??
                address.results.find((a) =>
                  a.types.includes('administrative_area_level_4')
                )?.formatted_address,
              { emitEvent: false }
            );
            const location = address.results
              .map((a) => a.address_components.find((b) => b.types.includes('locality')))
              .filter((c) => c?.long_name)
              .map((c) => c.long_name)[0] || null;
            this.addressSelected.emit({
              location: location,
              latitude: this.getLatLngLiteralFromCity(address).lat,
              longitude: this.getLatLngLiteralFromCity(address).lng,
            });
            if (this.enterKeyPressed) {
              this.keyPressedEnter.emit();
            }
          }
        });
    }
  }

  getLatLngLiteralFromCity(address: any): LatLngLiteral {
    return (
      (address.results.find((a) =>
        a.address_components.find((b) => b.types.includes('locality'))
      )?.geometry.location as unknown as LatLngLiteral) ??
      (address.results.find((a) =>
        a.address_components.find((b) =>
          b.types.includes('administrative_area_level_4')
        )
      )?.geometry.location as unknown as LatLngLiteral)
    );
  }
  
  initForm(): void {
    this.searchAddressForm = this.fb.group({
      address: [
        {
          value: this.disabled
            ? ''
            : this.address
            ? this.address.name !==
              this.address.street + ' ' + this.address.streetNumber
              ? this.address.name + ' ' + this.address.formattedAddress
              : this.address.formattedAddress
            : '',
          disabled: this.disabled,
        },
      ],
    });
    if (!this.optional) {
      this.addressControl.setValidators(Validators.required);
      this.addressControl.updateValueAndValidity();
    }
    
    this.searchAddressForm.valueChanges.subscribe((data) => {
      if (data.address === '') {
        this.addressChanging.emit();
      }
    });
  }

  setAddress(address: AddressInterface): void {
    if (!this.addressControl) {
      this.initForm();
    }
    if (address && !this.disableCondition) {
      this.addressControl.patchValue(
        address.name &&
          address.name !== address.street + ' ' + address.streetNumber
          ? address.name + ' ' + address.formattedAddress
          : address.formattedAddress
      );
    }
  }

  handleAddressChange(inputAddress: ElabGoogleAddress): void {
    this.addressControl.setErrors(null);

    if (!inputAddress.address_components) {
      this.keyPressedEnter.emit();
    } else {
      this.tempInputAddress = inputAddress;

      const getAddressComponent = (type: string): string => {
        const component = inputAddress.address_components.find(a =>
          a.types.includes(type)
        );
        return component ? component.long_name : '';
      };
  
      const address: AddressInterface = {
        city: getAddressComponent('locality') || getAddressComponent('administrative_area_level_1') || '',
        country: getAddressComponent('country') || '',
        formattedAddress: inputAddress.formatted_address,
        latitude: inputAddress.geometry.location.lat(),
        longitude: inputAddress.geometry.location.lng(),
        googleMapsUrl: inputAddress.url,
        googleMapsPlaceId: inputAddress.place_id,
        streetNumber: getAddressComponent('street_number') || '',
        zipCode: getAddressComponent('postal_code') || '',
        street: getAddressComponent('route') || '',
        state: getAddressComponent('administrative_area_level_1') || '',
        timezoneOffsetMinutes: inputAddress['utc_offset_minutes'] || 0,
        name: inputAddress['name'] || '',
      };
      
      this.searchService
        // todo!
        // @ts-ignore
        .getTimezoneFromCoordinate({
          latitude: address.latitude,
          longitude: address.longitude,
        })
        .subscribe((timezoneData: any) => {
          if (timezoneData) {
            address.timezoneId = timezoneData.timeZoneId;
          } else {
            address.timezoneId = 'Unknown';
          }
        });
      if (this.houseNumberRequired) {
        if (address.streetNumber && address.streetNumber.trim() !== '') {
          this.addressControl.setErrors(null);
          this.addressSelected.emit(address);
          if (this.enterKeyPressed) {
            this.keyPressedEnter.emit();
          }
        } else {
          this.addressControl.setErrors({ noStreetNumber: true });
          this.addressChanging.emit();
        }
      } else {
        this.addressSelected.emit(address);
        if (this.enterKeyPressed) {
          this.keyPressedEnter.emit();
        }
      }
    }
  }

  getBrowserPermissionForLocationOfUserManual(): void {
    if ('geolocation' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          if (this.alreadyExistingCoordinates){
            this.getAddressFromCoordinates(this.alreadyExistingCoordinates);
          }
          this.getBrowserPermissionForLocationOfUser();
        } else {
          this.openLocationRequestDialog();
        }
      });
    }
  }
  
  openLocationRequestDialog():void{
    const confirmationDialogData: ConfirmationDialogInterface = {
      submitButtonColor: 'primary',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.OK'),
      title: this.translocoService.translate('GENERIC_WRAPPER.LOCATION_REQUEST.DIALOG_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.LOCATION_REQUEST.DIALOG_TEXT'),
      buttonHeight:'35px',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '500px',
      data: confirmationDialogData
    });
  }
  
  getBrowserPermissionForLocationOfUser(): void {
    navigator.geolocation.getCurrentPosition(
      (userPosition) => {
        this.searchHttpService.getAddressFromCoordinate({latitude: userPosition.coords.latitude, longitude: userPosition.coords.longitude}).subscribe({
          next: (data) => {
            const location = data.results
              .map((a) => a.address_components.find((b) => b.types.includes('locality')))
              .filter((c) => c?.long_name)
              .map((c) => c.long_name)[0] || null;
            this.coordinatesService.sendCoordinates({
              latitude: userPosition.coords.latitude,
              longitude: userPosition.coords.longitude,
              location: location
            });
          }
        });
      },
      () => {

      },
      { enableHighAccuracy: true }
    );
  }

  onKeyUp($event): void {
    if ($event.keyCode === 13) {
      this.enterKeyPressed = true;
      if (this.tempInputAddress) {
        this.handleAddressChange(this.tempInputAddress);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.enterKeyPressed = false;
  }

  getPacContainer(): void {
    this.newPacContainer.emit(document.querySelector('.pac-container'));
  }
}
