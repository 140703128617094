import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormatNumberDirective } from './format-number.directive';

@NgModule({
  declarations: [FormatNumberDirective],
  imports: [CommonModule],
  exports: [FormatNumberDirective],
})
export class FormatNumberModule {
}
