<ng-container *transloco="let t">
  <ng-container *ngIf="passedFormGroup">
    <div
      class="step-body-container flex-column gap-20"
      [formGroup]="passedFormGroup"
    >
      <div>
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.ticket-sales-settings.sales-start') }}
        </h4>
        <p>{{ t('GENERIC_WRAPPER.ticket-sales-settings.sales-start-text') }}</p>
        <div class="flex-row gap-10">
          <mat-form-field appearance="outline" class="w-50">
            <mat-label>{{
              t('GENERIC_WRAPPER.ticket-sales-settings.date-label')
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [max]="passedFormGroup.controls.salesEndDate.value"
              readonly
              id="date-start"
              formControlName="salesStartDate"
              (click)="picker.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              type="button"
              (click)="passedFormGroup.controls.salesStartDate.reset()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <df-shared-lib-time-picker
            class="w-25"
            [label]="t('GENERIC_WRAPPER.ticket-sales-settings.time-label')"
            [passedFormGroup]="passedFormGroup"
            passedFormControlName="salesStartTime"
            [defaultSelection]="false"
          ></df-shared-lib-time-picker>
        </div>
      </div>

      <div>
        <h4 class="step-body-title">
          {{ t('GENERIC_WRAPPER.ticket-sales-settings.sales-end') }}
        </h4>
        <p>{{ t('GENERIC_WRAPPER.ticket-sales-settings.sales-end-text') }}</p>
        <div class="flex-row gap-10">
          <mat-form-field appearance="outline" class="w-50">
            <mat-label>{{
              t('GENERIC_WRAPPER.ticket-sales-settings.date-label')
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="endPicker"
              [min]="passedFormGroup.controls.salesStartDate.value ?? currentDate"
              id="date-end"
              formControlName="salesEndDate"
              (click)="endPicker.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              type="button"
              (click)="passedFormGroup.controls.salesEndDate.reset()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <df-shared-lib-time-picker
            class="w-25"
            [label]="t('GENERIC_WRAPPER.ticket-sales-settings.time-label')"
            [passedFormGroup]="passedFormGroup"
            passedFormControlName="salesEndTime"
            [defaultSelection]="false"
          ></df-shared-lib-time-picker>
        </div>
      </div>
      <div
        class="mb2 flex-column gap-25"
      >
        <div>
          <div class="flex-row gap-10 align-start-center">
            <h4 class="step-body-title">
              {{ t('GENERIC_WRAPPER.ticket-sales-settings.sellAsFollowerLeader') }}
            </h4>
            <mat-icon
              [matTooltip]="
                t(
                  'GENERIC_WRAPPER.ticket-sales-settings.sellAsFollowerLeader-tooltip'
                )
              "
              matTooltipClass="styledTooltip"
              >help</mat-icon
            >
          </div>
          <p>
            {{
              t(
                'GENERIC_WRAPPER.ticket-sales-settings.sellAsFollowerLeader-subtitle'
              )
            }}
          </p>

          <div class="slider-container flex-row gap-10">
            <mat-slide-toggle
              color="primary"
              formControlName="sellAsFollowerLeader"
            >
              {{ t('GENERIC_WRAPPER.ticket-sales-settings.sellAsFollowerLeader') }}
            </mat-slide-toggle>
          </div>
        </div>
        <div
          *ngIf="passedFormGroup.controls.sellAsFollowerLeader.value"
          class="my1"
        >
          <div class="flex-row gap-10 align-start-center">
            <h4 class="step-body-title">
              {{
                t('GENERIC_WRAPPER.ticket-sales-settings.ticket-sale-activation')
              }}
            </h4>
            <mat-icon
              [matTooltip]="
                t(
                  'GENERIC_WRAPPER.ticket-sales-settings.ticket-sale-activation-tooltip'
                )
              "
              matTooltipClass="styledTooltip"
              >help</mat-icon
            >
          </div>

          <p>
            {{
              t(
                'GENERIC_WRAPPER.ticket-sales-settings.ticket-sale-activation-subtitle'
              )
            }}
          </p>

          <div class="slider-container flex-column gap-10">
            <mat-slide-toggle
              color="primary"
              formControlName="sellAsFollowerActive"
              >{{
                t('GENERIC_WRAPPER.SELL_AS_FOLLOWER_ACTIVE')
              }}</mat-slide-toggle
            >
            <mat-slide-toggle
              color="primary"
              formControlName="sellAsLeaderActive"
              >{{
                t('GENERIC_WRAPPER.SELL_AS_LEADER_ACTIVE')
              }}</mat-slide-toggle
            >
          </div>
        </div>
      </div>
      <div>
        <h4 class="step-body-title">{{ t('GENERIC_WRAPPER.USER_GROUP_SALE_LABEL') }}</h4>
        <p>{{ t('GENERIC_WRAPPER.USER_GROUP_SALE_TEXT') }}</p>
        <mat-form-field appearance="outline">
          <mat-label>{{ passedFormGroup.controls.danceManagerUserGroupIds?.value?.length > 0 ? 
            t('GENERIC_WRAPPER.USER_GROUP_SALE_LABEL') :
            t('GENERIC_WRAPPER.USER_GROUP_AVAILABLE_FOR_SALE_TO_EVERYONE')}}</mat-label>
          <mat-select formControlName="danceManagerUserGroupIds" multiple>
            @for (dfmUserGroup of dfmUserGroups; track dfmUserGroup) {
              <mat-option [value]="dfmUserGroup.id">{{dfmUserGroup.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</ng-container>
