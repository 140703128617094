import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryPassListItemComponent } from './inventory-pass-list-item.component';
import { RouterLink } from '@angular/router';
import { PipesModule } from '@platri/dfx-angular-core';
import { MatDivider } from '@angular/material/divider';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatIcon } from '@angular/material/icon';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [InventoryPassListItemComponent],
    imports: [
      CommonModule,
      RouterLink,
      PipesModule,
      MatDivider,
      MatGridList,
      MatGridTile,
      MatIcon,
      TranslocoDirective
    ],
  exports: [InventoryPassListItemComponent],
})
export class InventoryPassListItemModule {}
