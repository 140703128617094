import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmCreateLocationPageComponent } from './dfm-create-location-page.component';
import { DfmCreateLocationFormModule } from '../../components/dfm-create-location-form/dfm-create-location-form.module';
import { RouterModule, Routes } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DfmCreateLocationPageComponent,
  },
];

@NgModule({
  declarations: [DfmCreateLocationPageComponent],
  imports: [
    CommonModule,
    DfmCreateLocationFormModule,
    RouterModule.forChild(routes),
    TranslocoDirective
  ],
  exports: [DfmCreateLocationPageComponent]
})
export class DfmCreateLocationPageModule {}
