import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@platri/df-common-shared-graphql';
import { Subscription } from 'rxjs';
import {
  CoursesStateService,
  DanceManagerService,
  DanceSchoolStateService,
  dfmCourseRoute,
  dfmDanceManagerRoute,
  dfmMarketPlaceRoute,
  PassesStateService,
  quillCharacterCountValidator
} from '@platri/dfx-angular-core';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  CourseAppointmentInterface,
  CourseAppointmentStatusEnum,
  CourseInterface,
  DanceManagerFeatureInterface,
  DanceManagerFeatureNameEnum,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  DfmCoursePassesTableInterface,
  UpdateCourseInterface
} from '@platri/df-common-core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { differenceInMinutes } from 'date-fns';
import { getSubscription } from '@platri/elab-angular-core';

@Component({
  selector: 'df-course-preview',
  templateUrl: './course-preview.component.html',
  styleUrls: ['./course-preview.component.scss']
})
export class CoursePreviewComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  activeTabIndex: number;

  locations: Location[];

  course: CourseInterface;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  currentAppointmentFilter = 'Any';
  filteredAppointments: CourseAppointmentInterface[] = [];
  upcomingAppointments: CourseAppointmentInterface[] = [];
  pastAppointments: CourseAppointmentInterface[] = [];
  cancelledAppointments: CourseAppointmentInterface[] = [];
  
  infoFormGroup: UntypedFormGroup;
  rulesFormGroup: UntypedFormGroup;
  mode = 'EDIT';
  passes: DfmCoursePassesTableInterface[] = [];
  relatedPasses: DfmCoursePassesTableInterface[] = [];
  passesFeatureActive = false;
  danceManagerFeatureName = DanceManagerFeatureNameEnum;
  
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly fb: UntypedFormBuilder,
    private readonly coursesService: CoursesStateService,
    private readonly danceManagerService: DanceManagerService,
    private readonly route: ActivatedRoute,
    public readonly passesService: PassesStateService,
  ) {}

  ngOnInit(): void {
    this.coursesService.clear();
    this.loadCourse();
    this.initializeSubscriptions();
    this.getHashtag();
    this.initFormGroups();
    this.injectData();
  }

  loadCourse(): void {
    this.course = this.activatedRoute.snapshot.data.targetObject;
    this.setAppointments();
  }

  setAppointments (): void {
    this.filteredAppointments = this.course.appointments;
    
    this.upcomingAppointments = this.course.appointments?.filter(appointment => appointment.status === CourseAppointmentStatusEnum.UPCOMING);
    this.pastAppointments = this.course.appointments?.filter(appointment => appointment.status === CourseAppointmentStatusEnum.PAST);
    this.cancelledAppointments = this.course.appointments?.filter(appointment => appointment.status === CourseAppointmentStatusEnum.CANCELLED);
    this.breadcrumbService.set('@courseName', this.course.name);
  }

  private initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.coursesService.courseSubject$, this.onCourseChange.bind(this)));
    this.subscriptions.add(getSubscription(this.passesService.getAsyncDfmCoursePasses(), this.onPassesChange.bind(this)));
    this.subscriptions.add(getSubscription(this.danceManagerService.getAsyncDanceManagerFeatures(), this.onDanceManagerFeaturesChange.bind(this)));
  }
  
  injectData(): void {
    if (this.course) {
      this.infoFormGroup.patchValue(this.course, {
        emitEvent: true,
        onlySelf: true
      });
      this.rulesFormGroup.patchValue({
        ...this.course.rules,
        isCheckInActive: this.course.isCheckInActive
      }, {
        emitEvent: true,
        onlySelf: true
      });
      
    }
  }

  onCourseChange(course: CourseInterface): void {
    if (course) {
      this.course = course;
      this.setAppointments();
      this.getRelatedPasses();
    }
  }
  
  onPassesChange(passes: DfmCoursePassesTableInterface[]): void {
    this.passes = passes;
    this.getRelatedPasses();
  }

  onDanceManagerFeaturesChange(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.passesFeatureActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.COURSE_PASSES);
  }
  
  getHashtag(): void {
    this.activatedRoute.fragment.subscribe((hashtag) => {
      if (hashtag != null) {
        hashtag = hashtag.toLowerCase();
        switch (hashtag) {
          case 'preview': {
            this.activeTabIndex = 0;
            break;
          }
          case 'info': {
            this.activeTabIndex = 1;
            break;
          }
          case 'rules': {
            this.activeTabIndex = 2;
            break;
          }
          case 'appointments': {
            this.activeTabIndex = 3;
            break;
          }
          case 'passes': {
            this.activeTabIndex = 4;
            break;
          }
          case 'participants': {
            this.activeTabIndex = 5;
            break;
          }
          default: {
            this.activeTabIndex = 0;
            break;
          }
        }
      }
    });
  }

  changeHashTag(index: number): void {
    switch (index) {
      case 0: {
        window.location.hash = '#preview';
        break;
      }
      case 1: {
        window.location.hash = '#info';
        break;
      }
      case 2: {
        window.location.hash = '#rules';
        break;
      }
      case 3: {
        window.location.hash = '#appointments';
        break;
      }
      case 4: {
        window.location.hash = '#passes';
        break;
      }
      case 5: {
        window.location.hash = '#participants';
        break;
      }
    }
  }

  routeToCourse(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['app', 'dance-class', this.course.id]));
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  routeToPackages(): void {
    this.router.navigate(['dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmMarketPlaceRoute]);
  }

  filterAppointments(filter: MatButtonToggleChange): void {
    if (this.currentAppointmentFilter === filter.value) {
      filter.source.checked = !filter.source.checked;
      this.currentAppointmentFilter = 'Any';
      this.filteredAppointments = this.course.appointments;
    } else {
      this.currentAppointmentFilter = filter.value;
      switch (filter.value) {
        case 'upcoming':
          this.filteredAppointments = this.upcomingAppointments;
          break;
        case 'past':
          this.filteredAppointments = this.pastAppointments;
          break;
        case 'cancelled':
          this.filteredAppointments = this.cancelledAppointments;
          break;
      }
    }
  }

  initFormGroups(): void {
    this.initInfoFormGroup();
    this.initRulesFormGroup();
  }

  initInfoFormGroup(): void {
    this.infoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      description: [null, [Validators.required, quillCharacterCountValidator(2000)]],
      danceStyles: [null, [Validators.required]],
      startDifficulty: [0],
      endDifficulty: [100],
      tags: [[]],
      mainImage: [null]
    });
  }
  
  initRulesFormGroup(): void {
    this.rulesFormGroup = this.fb.group({
      participantRestriction: this.fb.group({
        isAgeRestricted: [false],
        ageRestriction: this.fb.group({ fromAge: [0], toAge: [100] }),
        isGenderRestricted: [false],
        genderRestriction: this.fb.group({
          isMaleRestricted: [false],
          isFemaleRestricted: [false],
          isDiverseRestricted: [false]
        })
      }),
      clothingRestriction: this.fb.group({
        isRestricted: [false],
        restriction: [null, [Validators.maxLength(1000)]]
      }),
      isCheckInActive: [false]
    });
  }

  updateCourseInfo(formGroup: UntypedFormGroup): void {
    const inputFormData = formGroup.getRawValue();
    const updateCourse: UpdateCourseInterface = {
      name: inputFormData.name,
      description: inputFormData.description,
      danceStyleIds: inputFormData.danceStyles.map((danceStyle) => danceStyle.id),
      startDifficulty: inputFormData.startDifficulty,
      endDifficulty: inputFormData.endDifficulty,
      tags: inputFormData.tags,
    };

    if (inputFormData.tags.length > 0) {
      this.course.tags = inputFormData.tags;
    }

    const formData = new FormData();

    if (inputFormData.mainImage === null) {
      this.coursesService.deleteMainImageById(this.course.id).subscribe();
    }

    if (formGroup.get('mainImage').value?.formData !== undefined) {
      this.coursesService.deleteMainImageById(this.course.id).subscribe();
      formData.append('mainImage', inputFormData.mainImage.formData.get('file'));
    }
    formData.append('body', JSON.stringify(updateCourse));
    
    this.coursesService.updateCourse(this.course.id, formData);
  }
  
  updateCourseRules(): void {
    if (this.rulesFormGroup.valid && this.course) {
      const inputFormData = this.rulesFormGroup.getRawValue();
      this.coursesService.sendLoading(true);
      this.coursesService.updateCourseRules(this.course.id, inputFormData);
    }
  }

  calculateDurationOfTimes(startTime: string, endTime: string): number {
    const startDateTime = new Date();
    startDateTime.setHours(parseInt(startTime.split(':')[0], 10));
    startDateTime.setMinutes(parseInt(startTime.split(':')[1], 10));
    startDateTime.setSeconds(0);
    const endDateTime = new Date();
    endDateTime.setHours(parseInt(endTime.split(':')[0], 10));
    endDateTime.setMinutes(parseInt(endTime.split(':')[1], 10));
    endDateTime.setSeconds(0);
    return differenceInMinutes(endDateTime, startDateTime);
  }

  getRelatedPasses(): void {
    if (this.course?.id) {
      this.relatedPasses = this.passes.filter(pass =>
        pass.targetIds?.includes(this.course.id)
      );
    } else {
      this.relatedPasses = [];
    }
  }
  
  navigateToCreateAppointmentsPage(): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmCourseRoute, 'preview', this.course.id, 'create-appointments']);
  }

  navigateToEditPassPage(passId: string): void {
    this.router.navigate(['../../pass', passId], { relativeTo: this.route });
  }

  navigateToCreatePassPage(): void {
    this.router.navigate(['../../create-pass'], { relativeTo: this.route });
  }

  navigateToMarketplacePage(): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmMarketPlaceRoute]);
  }
}
