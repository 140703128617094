import {
  AfterContentInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DfmCreateUserGroupFormInterface } from 'libs/frontend/dfm-user-group/src/lib/components/dfm-create-user-group-form/dfm-create-user-group-form.interface';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DanceManagerHttpService,
  DanceSchoolStateService,
  dfmDanceManagerRoute,
  DfmDanceManagerUrlNameRouterParam,
  dfmUserGroupsRoute,
  getSubscription,
  LocationHttpService,
  UserHttpService
} from '@platri/dfx-angular-core';
import {
  CreateUserGroupRequestDto,
  DanceSchoolInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  UserAppLangEnum,
  UsersInterface
} from '@platri/df-common-core';
import { tap } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dfm-create-user-group-form',
  templateUrl: './dfm-create-user-group-form.component.html',
  styleUrls: ['./dfm-create-user-group-form.component.scss']
})
export class DfmCreateUserGroupFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulCreateUserGroupEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  createUserGroupFormGroup!: FormGroup<DfmCreateUserGroupFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForCreateUserGroupResponse = false;
  isAddressEmptyOnSubmit = false;
  
  createFailedNoConnection = false;

  routerParams!: Params;
  danceManagerUrlNameFromParam: string;
  danceManager: DanceSchoolInterface;
  currentLang = UserAppLangEnum.DE;

  searchInputChangeSubject = new Subject<string>();
  showUserSearchLoadingSpinner = false;
  
  selectedUsers: any[] = [];
  userOptions: any[] = [];
  displayedColumns: string[] = ['value', 'info'];


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationHttpService: LocationHttpService,
    private danceSchoolService: DanceSchoolStateService,
    private translocoService: TranslocoService,
    private userHttpService: UserHttpService,
    private danceManagerHttpService: DanceManagerHttpService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.loadCurrentDanceManager();
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.createUserGroupFormGroup = this.createCreateUserGroupFormGroup();
  }

  createCreateUserGroupFormGroup(): FormGroup<DfmCreateUserGroupFormInterface> {
    return this.fb.group<DfmCreateUserGroupFormInterface>({
      name: this.fb.control('',  [Validators.required, Validators.maxLength(50)]),
      userIds: this.fb.control([],  Validators.required),
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.createUserGroupFormGroup.valueChanges.subscribe(res => {
      this.createFailedNoConnection = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscribeToSearch();
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.danceManagerUrlNameFromParam = this.routerParams[DfmDanceManagerUrlNameRouterParam];
  }
  
  loadCurrentDanceManager(): void {
    this.danceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
  }

  onSubmit(): void {
    if (this.createUserGroupFormGroup.valid) {
      this.isWaitingForCreateUserGroupResponse = true;
      this.createFailedNoConnection = false;
      const {name, userIds} = this.createUserGroupFormGroup.getRawValue();
      const createUserGroupRequestDto: CreateUserGroupRequestDto = {name, userIds, danceManagerId: this.danceManager.id};
      this.subscriptions.add(this.danceManagerHttpService.createDmUserGroups(createUserGroupRequestDto).subscribe({
        next: () => {
          this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmUserGroupsRoute]);
          this.isWaitingForCreateUserGroupResponse = false;
        },
        error: (err) => {
          console.log(err);
          if (err.status === 0) {
            this.onConnectionLost();
          }
          this.isWaitingForCreateUserGroupResponse = false;
        }
      }));
    }
  }

  onUserSelected(user: any): void {
    const index = this.selectedUsers.findIndex((obj) => obj.id === user.id);
    if (index === -1) {
      this.selectedUsers.push(user);
      this.createUserGroupFormGroup.patchValue({
        userIds: this.selectedUsers.map((obj) => obj.id)
      });
    }
  }
  
  onUserRemoved(user: any): void {
    const index = this.selectedUsers.findIndex((obj) => obj.id === user.id);
    this.selectedUsers.splice(index, 1);
    this.createUserGroupFormGroup.patchValue({
      userIds: this.selectedUsers.map((obj) => obj.id)
    });
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }

  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject.pipe(
        tap(() => (this.showUserSearchLoadingSpinner = true)),
        debounceTime(600)
      ).subscribe((inputText: string) => {
        if (inputText) {
          this.subscriptions.add(
            this.userHttpService.searchUsersByKey(inputText, true).pipe(
              tap(() => {
                this.showUserSearchLoadingSpinner = false;
              })
            ).subscribe((users: UsersInterface[]) => {
              this.userOptions = this.mapToOptions(users);
            })
          );
        } else {
          this.userOptions = [];
          this.showUserSearchLoadingSpinner = false;
        }
      })
    );
  }

  private mapToOptions(users: UsersInterface[]): any {
    return users.map((user) => ({
      id: user.id,
      value: user.username,
      data: user,
      displayValue: user.username,
      info: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null,
      imageUrl: user.imageUrl ?? null,
    }));
  }

  onConnectionLost(): void {
    this.createFailedNoConnection = true;
  }

  onCancel(): void {
    this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmUserGroupsRoute]);
  }

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
}
