import { Component, Input, OnInit } from '@angular/core';
import {
  DanceEventsDto,
  DanceEventSearchStatusEnum,
  DEFAULT_EVENT_PICTURE_PLACEHOLDER,
  UserAppLangEnum
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-search-events-list-item',
  templateUrl: './search-events-list-item.component.html',
  styleUrls: ['./search-events-list-item.component.scss']
})
export class SearchEventsListItemComponent implements OnInit{
  @Input() event: DanceEventsDto;

  currentLang: UserAppLangEnum;
  searchStatus = DanceEventSearchStatusEnum;

  constructor(
    private translocoService: TranslocoService
  ) {
  }
  
  ngOnInit(): void {
    this.translocoService.langChanges$.subscribe((res) => {
      this.currentLang = res.toUpperCase() as UserAppLangEnum;
    });
  }

  protected readonly DEFAULT_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;
}
