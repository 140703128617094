import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  CourseAppointmentStatusEnum,
  CourseInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  UserDto,
  UserPassDto,
  UserPassStatusEnum
} from '@platri/df-common-core';
import {
  DfmStateService,
  TrialLessonInviteDialogComponent,
  UserHttpService,
  UserPassHttpService
} from '@platri/dfx-angular-core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { getSubscription } from '@platri/elab-angular-core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-participants-list-table',
  templateUrl: './participants-list-table.component.html',
  styleUrls: ['./participants-list-table.component.scss'],
})
export class ParticipantsListTableComponent implements OnInit, OnDestroy {  
  public readonly displayedColumns: string[] = [
    'username_email',
    'firstName',
    'lastName',
    'passes',
    'lastParticipated',
    'openChat',
  ];

  @Input() course: CourseInterface;
  @ViewChild(MatSort) sort: MatSort;
  
  userPasses: UserPassDto[];
  passesByUserId = new Map<string, UserPassDto[]>();
  passesByGuestEmail = new Map<string, UserPassDto[]>();
  userIds: string[] = [];
  activeUsers: UserDto[] = [];
  formerUsers: UserDto[] = [];
  isInitialized = false;
  activeChipIndex = 0;
  participantListDataSource = new MatTableDataSource<UserDto>();
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private dfmStateService: DfmStateService,
    private readonly userPassHttpService: UserPassHttpService,
    private readonly userService: UserHttpService,
    private readonly dialog: MatDialog,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.participantListDataSource.sort = this.sort;
  }

  private loadData(): void {
    this.subscriptions.add(getSubscription(this.userPassHttpService.getAllByCourseId(this.course.id), this.onParticipantsChange.bind(this)));
  }

  onParticipantsChange(userPasses: UserPassDto[]): void {
    this.userPasses = userPasses;
    this.passesByUserId.clear();
    this.passesByUserId = new Map<string, UserPassDto[]>();
    this.passesByGuestEmail = new Map<string, UserPassDto[]>();
    
    userPasses.forEach((userPass) => {
      const userId = userPass.userId;
      if(userId == null) {
        const email = userPass.guestUserEmail;
        if (this.passesByGuestEmail.has(email)) {
          this.passesByGuestEmail.get(email)?.push(userPass);
        } else {
          this.passesByGuestEmail.set(email, [userPass]);
        }
      } else if (this.passesByUserId.has(userId)) {
        this.passesByUserId.get(userId)!.push(userPass);
      } else {
        this.passesByUserId.set(userId, [userPass]);
      }
    });
    this.userIds = [...new Set(userPasses.filter((userPass) => userPass.userId !== null).map((userPass) => userPass.userId))];
    this.loadUsers();
  }

  loadUsers(): void {
    this.subscriptions.add(
      this.userService.getUsersByIds(this.userIds, false, true).subscribe({
        next: (users) => {

          if(this.userPasses)
          users.forEach(user => {
            const userPasses = this.passesByUserId.get(user.id);
            userPasses.forEach((userPass) => {
              if(this.passIsActive(userPass)) {
                this.activeUsers.push(user);
              } else {
                this.formerUsers.push(user);
              }
            });
          });


          if(this.passesByGuestEmail)
          this.passesByGuestEmail.forEach((userPasses, userEmail) => {
            userPasses.forEach((userPass) => {
              const user = new UserDto();
              user.email = userEmail;
              if(this.passIsActive(userPass)) {
                this.activeUsers.push(user);
              } else {
                this.formerUsers.push(user);
              }
            });
          });
          
          this.formerUsers = this.removeDuplicates(this.formerUsers);
          this.activeUsers = this.removeDuplicates(this.activeUsers);
          this.formerUsers = this.formerUsers.filter((user) => !this.activeUsers.includes(user));
          
          this.updateDataSource();
          this.isInitialized = true;
        }
      })
    );
  }

  removeDuplicates(users: UserDto[]): UserDto[] {
    const emailSet = new Set<string>();
    const idSet = new Set<string>();
    return users.filter(user => {
      if ((user.email && emailSet.has(user.email)) || (user.id && idSet.has(user.id))) {
        return false;
      } else {
        emailSet.add(user.email);
        idSet.add(user.id);
        return true;
      }
    });
  }

  passIsActive(userPass: UserPassDto): boolean {
    const canBeScanned = userPass.pass.usageCount  == null || userPass.pass.usageCount > userPass.userPassScans.length;
    const isValid = userPass.validUntil == null || new Date(userPass.validUntil) > new Date();
    return canBeScanned && isValid;
  }

  passesColumnForUser(userId: string, userEmail: string): string {
    const getPassLabel = (userPass: UserPassDto): string | null => {
      const freeLabel = userPass.status === UserPassStatusEnum.FREE || userPass.pass.price === 0 ? ` (Free)` : ``;
      const usage = userPass.pass.usageCount ? ` ${userPass.userPassScans.length}/${userPass.pass.usageCount}` : ``;
      const label = userPass.pass.name + usage + freeLabel;
      
      const isPassActive = this.passIsActive(userPass);
      return (isPassActive && this.activeChipIndex === 0) || (!isPassActive && this.activeChipIndex === 1) ? label : null;
    };

    const passes = userId ? this.passesByUserId.get(userId) : this.passesByGuestEmail.get(userEmail);

    return passes.map(getPassLabel).filter((label): label is string => label !== null).join(", ");
  }

  lastParticipatedDateForUser(userId: string, userEmail: string): Date {
    const getPassLastUsedDate = (userPass: UserPassDto): Date | null => {
      const dates = userPass.userPassScans.map((scan) => scan.scannedAt);
      if (dates.length === 0) return null;
      return dates.reduce((latest, current) =>  current > latest ? current : latest);
    };

    const passes = userId ? this.passesByUserId.get(userId) : this.passesByGuestEmail.get(userEmail);
    const allDates = passes.map(getPassLastUsedDate);
    
    if (allDates.length === 0) return null;
    return allDates.reduce((latest, current) =>  current > latest ? current : latest);
  }

  updateDataSource(): void {
    this.participantListDataSource = new MatTableDataSource(this.activeChipIndex === 0 ? this.activeUsers : this.formerUsers);
  }

  openChat(userId: string): void {
    if (userId) {
      const newUrl = `/dance-manager/${this.dfmStateService.selectedDm.value.id}/messages?partnerIds=user-${userId}`;
      this.router.navigateByUrl(newUrl);
    }
  }
  
  openTrialLessonInviteDialog(): void {
    const dialogRef = this.dialog.open(TrialLessonInviteDialogComponent, {
      panelClass: 'responsive-dialog',
      data: { appointments: this.course.appointments.filter(appointment => appointment.status === CourseAppointmentStatusEnum.UPCOMING) }
    });
    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result;
      if (dialogResult) {
        const trialPassRequestDto: TrialLessonRequestDto = {
          courseId: this.course.id,
          appointmentId: dialogResult.appointment.id,
          danceManagerId: this.course.danceManagerId,
          userId: dialogResult.selectedUser?.id,
          email: dialogResult.email
        };
        this.userPassHttpService.createTrialPass(trialPassRequestDto).subscribe(() => {
          this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_COURSE.TRIAL_LESSON.SNACKBAR.SUCCESSFUL', {user: dialogResult.selectedUser?.displayValue}));
        }, error => {
          if (error.error.error === 'user_already_has_trial_pass') {
            this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_COURSE.TRIAL_LESSON.SNACKBAR.USER_ALREADY_HAS_TRIAL_PASS', {user: dialogResult.selectedUser?.displayValue}));
          } else {
            this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_COURSE.TRIAL_LESSON.SNACKBAR.FAILED'));
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleChip(value: number): void {
    this.activeChipIndex = value;
    this.updateDataSource();
  }
}

interface TrialLessonRequestDto {
  courseId?: string;
  appointmentId?: string;
  danceManagerId?: string;
  userId?: string;
  email?: string;
}
