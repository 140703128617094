<ng-container *transloco="let t">
  <div class="event-tickets-checkout-container flex-column align-start-center w-100 h-100">
    <div class="content-card py2 w-100">
      <!-- Back Button -->
      <div class="w-100 back-btn-container">
          <div class="flex-column align-start hide-on-mobile" (click)="navigateToPassesPage()">
            <a class="flex-row align-start back-btn">
              <mat-icon>arrow_back</mat-icon>
              <span class="pl1 m-auto bold">{{t('GENERIC_WRAPPER.PASSES') }}</span>
            </a>
          </div>
        </div>
      <!--Card Header -->
      <div class="flex-row align-start pt2">
        <img class="dance-event-image hide-on-mobile" [src]="danceManager?.imageUrl ??'assets/images/studio_card_image_web.png'" alt=""/>
        <div class="container-a flex-column w-100">
          <div class="flex-row align-space-between w-100 px2">
            <span style="font-size: 20px">
              <strong class="mobile-title-text">{{ danceManager?.name }}</strong>
            </span>
            <span class="flex-column align-end-start back-btn pb1 hide-on-web" (click)="navigateToDanceManagerPage()">
              <mat-icon>close</mat-icon>
            </span>
          </div>
        </div>
      </div>
      
      <mat-divider class="mt2 hide-on-web"></mat-divider>
      
      <!-- Card Content -->
      <div class="card-container flex-column mt3 w-100" style="gap: 1rem;">
        <!-- Payment -->
        <div class="payment-container flex-column" style="gap: 1rem;">
          <df-monorepo-ticket-checkout-payment 
            *ngIf="ticketOrder" 
            [ticketOrder]="ticketOrder"
          ></df-monorepo-ticket-checkout-payment>
        </div>
  
        <!-- Checkout Detail -->
        <div class="checkout-detail-container">
          <!-- Checkout Summery -->
          <div class="payment-methods-container hide-on-mobile">
            <df-monorepo-dance-event-ticket-order-summary
              *ngIf="ticketOrder"
              [ticketOrder]="ticketOrder"
            ></df-monorepo-dance-event-ticket-order-summary>
          </div>
          <div class="hide-on-mobile px1 pt2">
            {{ t('GENERIC_WRAPPER.NEED_HELP') }}
            <a style="text-decoration: none; color: #3377FF" target="_blank" contactUrl>
              {{ t('GENERIC_WRAPPER.CONTACT_US') }}</a
            >
          </div>
        </div>
  
        <!-- Space For Sticky Button on Mobile-->
        <div class="py3 hide-on-web"></div>
      </div>
    </div>
  </div>
</ng-container>
