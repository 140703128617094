import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  AuthStateService,
  getSubscription,
  TicketStateService,
  UserTicketStateService
} from '@platri/dfx-angular-core';
import { Observable, Subscription } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import {
  DanceEventsInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  isAppointmentActive,
  TicketDanceEventStatisticsInterface,
  TicketInterface,
  UsersInterface,
  UserTicketCollapsedByUserInterface,
  UserTicketDanceEventStatisticsInterface,
  UserTicketDanceRoleTypeEnum,
  wait
} from '@platri/df-common-core';
import { Router } from '@angular/router';
import { AddFreeTicketsDialogComponent } from '../../dialogs';
import { TranslocoService } from '@jsverse/transloco';

interface TicketWithCheckedIn extends TicketInterface {
  checkedInQuantity?: number;
}
@Component({
  selector: 'dfm-event-guest-list-table',
  templateUrl: './dfm-event-guest-list-table.component.html',
  styleUrls: ['./dfm-event-guest-list-table.component.scss']
})
export class DfmEventGuestListTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  public readonly displayedColumns: string[] = [
    'user',
    // 'email',
    'tickets',
    'danceRole',
    'checkInStatus',
    'openChat'
  ];

  eventTickets: TicketWithCheckedIn[] = [];
  filterText = '';
  
  total: TicketDanceEventStatisticsInterface;
  totalWithoutFreeTickets: TicketDanceEventStatisticsInterface;

  @ViewChild(MatPaginator, { static: false }) set paginator(val: MatPaginator) {
    if (val) {
      this.guestListDataSource.paginator = val;
    }
  }

  @ViewChild(MatSort, { static: false }) set sort(val: MatSort) {
    if (val) {
      this.guestListDataSource.sort = val;
    }
  }

  @ViewChild('ticketTypeSelect') ticketTypeSelect: MatSelect;
  @ViewChild('roleTypeSelect') roleTypeSelect: MatSelect;
  @ViewChild('statisticsSelect') statisticsSelect: MatSelect;

  @Input() danceEvent: DanceEventsInterface;

  guestListDataSource = new MatTableDataSource<UserTicketCollapsedByUserInterface>();
  danceEventTicketStatistics: TicketDanceEventStatisticsInterface[] = [];
  danceRolesEnum = UserTicketDanceRoleTypeEnum;

  isDanceEventActive = false;
  loading$: Observable<boolean>;
  private subscriptions: Subscription = new Subscription();

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  currentUser: UsersInterface;
  
  constructor(
    private userTicketService: UserTicketStateService, 
    private readonly dialog: MatDialog, 
    private readonly ticketService: TicketStateService, 
    private readonly translocoService: TranslocoService, 
    private readonly router: Router, 
    private authStateService: AuthStateService
  ) {
    this.currentUser = this.authStateService.getSyncCurrentUser();
    this.total = {
      ticketName: this.translocoService.translate('DANCE_MANAGER_EVENT.STATISTICS.TOTAL'),
      quantity: 0,
      soldQuantity: 0,
      checkedInQuantity: 0
    };
    this.totalWithoutFreeTickets = {
      ticketName: this.translocoService.translate('DANCE_MANAGER_EVENT.STATISTICS.TOTAL_WITHOUT_FREE_TICKETS'),
      quantity: 0,
      soldQuantity: 0,
      checkedInQuantity: 0
    };
  }

  ngOnInit(): void {
    this.loading$ = this.ticketService.loadingSubject$;
    this.subscriptions.add(getSubscription(this.ticketService.getAsyncTickets(), this.onTicketsChange.bind(this)));
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.danceEvent) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.ticketService.clear();
  }
  
  loadData(): void {
    if (this.danceEvent && this.danceEvent?.id) {
      this.userTicketService.getDanceEventUserTicketStatistics(this.danceEvent.id);
      this.isDanceEventActive = isAppointmentActive(
        // @ts-ignore
        this.danceEvent.appointments[0]
      );
      this.getTicketsOfDanceEvent();
    }
  }

  onTicketsChange(tickets: TicketInterface[]): void {
    if (tickets?.length > 0) {
      this.eventTickets = tickets;
    }
  }

  getTicketsOfDanceEvent(): void {
    this.ticketService.getAllByDanceEvent(this.danceEvent.id, true);
  }

  public openAddGuestDialog(): void {
    const dialogRef = this.dialog.open(AddFreeTicketsDialogComponent, {
      data: { eventId: this.danceEvent.id },
      maxWidth: '809px',
      maxHeight: '615px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      wait(1000).then(() => {
        this.userTicketService.getDanceEventUserTicketStatistics(this.danceEvent.id);
        this.getTicketsOfDanceEvent();
      });
    });
  }

  getUserTicketsForDanceEvent(): void {
    this.subscriptions.add(
      this.userTicketService.danceEventUserTicketStatistics$.subscribe((data: UserTicketDanceEventStatisticsInterface) => {
        if (data) {
          this.guestListDataSource.data = data.userTicketsCollapsedByUser;
          this.danceEventTicketStatistics = data.ticketStatistics;
          this.total.quantity = data.totalStatistics.quantity;
          this.totalWithoutFreeTickets.quantity = data.totalStatistics.quantityWithoutFreeTickets;
          this.total.soldQuantity = data.totalStatistics.soldQuantity;
          this.totalWithoutFreeTickets.soldQuantity = data.totalStatistics.soldQuantityWithoutFreeTickets;
          this.total.checkedInQuantity = data.totalStatistics.checkedInQuantity;
          this.totalWithoutFreeTickets.checkedInQuantity = data.totalStatistics.checkedInQuantityWithoutFreeTickets;
          this.total.checkedInFollowerQuantity = data.totalStatistics.checkedInFollowerQuantity;
          this.total.checkedInLeaderQuantity = data.totalStatistics.checkedInLeaderQuantity;
          this.total.soldFollowerQuantity = data.totalStatistics.soldFollowerQuantity;
          this.total.soldLeaderQuantity = data.totalStatistics.soldLeaderQuantity;
          this.totalWithoutFreeTickets.checkedInFollowerQuantity = data.totalStatistics.checkedInFollowerQuantity;
          this.totalWithoutFreeTickets.checkedInLeaderQuantity = data.totalStatistics.checkedInLeaderQuantity;
          this.totalWithoutFreeTickets.soldFollowerQuantity = data.totalStatistics.soldFollowerQuantity;
          this.totalWithoutFreeTickets.soldLeaderQuantity = data.totalStatistics.soldLeaderQuantity;
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.getUserTicketsForDanceEvent();
    this.filterAndSortSetup();
  }

  extractUserData(data: UserTicketCollapsedByUserInterface): string[] {
    if (data.user) {
      const userDataArray: string[] = Object.values(data.user);
      const email = userDataArray[1];
      const firstName = userDataArray[2];
      const lastName = userDataArray[3];
      const username = userDataArray[4];
      return [firstName?.toLowerCase(), lastName?.toLowerCase(), username?.toLowerCase(), email?.toLowerCase()];
    } else {
      return [];
    }
  }

  filterAndSortSetup(): void {
    this.guestListDataSource.filterPredicate = (data: UserTicketCollapsedByUserInterface, filter):boolean => {
      const filterMatchedInUserData: boolean = this.extractUserData(data).some((field) => {
        if (typeof field === 'string') {
          return field.includes(filter);
        } else {
          return false;
        }
      });
      const ticketTypeMatched: boolean = data.ticketSaleInformation.some((ticket) => ticket?.ticketName === this.ticketTypeSelect.value);
      const roleTypeMatched: boolean = data.ticketSaleInformation.some((role) => role?.danceRoleType === this.roleTypeSelect.value);
      const doubleFilter: boolean = data.ticketSaleInformation.some((double) => double?.danceRoleType === this.roleTypeSelect.value && double?.ticketName === this.ticketTypeSelect.value);
      if (filter === 'All' && this.ticketTypeSelect.value === 'All' && this.roleTypeSelect.value === 'All') {
        return true;
      } else {
        if (this.ticketTypeSelect.value !== 'All' && this.roleTypeSelect.value !== 'All' && (filter === this.roleTypeSelect.value || filter === this.ticketTypeSelect.value)) {
          return doubleFilter;
        } else {
          if (filter !== this.ticketTypeSelect.value && this.ticketTypeSelect.value !== 'All') {
            return filterMatchedInUserData && ticketTypeMatched;
          } else if (filter !== this.roleTypeSelect.value && this.roleTypeSelect.value !== 'All') {
            return filterMatchedInUserData && roleTypeMatched;
          } else {
            return filterMatchedInUserData || ticketTypeMatched || roleTypeMatched;
          }
        }
      }
    };
    this.guestListDataSource.sortingDataAccessor = (data: UserTicketCollapsedByUserInterface, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'firstName':
          return data.user?.firstName;
        case 'lastName':
          return data.user?.lastName;
        case 'username':
          return data.user?.username;
        case 'email':
          return data.user?.email;
        case 'openChat':
          return data.user?.id;
        case 'tickets':
          return data.ticketSaleInformation[0].ticketName;
        default:
          return '';
      }
    };
  }

  bindFilterInput(event: Event): void {
    this.filterText = (event.target as HTMLInputElement).value;
    if (this.filterText === '') {
      this.applyTextFilter(this.ticketTypeSelect.value);
    } else {
      this.applyTextFilter(this.filterText.trim().toLowerCase());
    }
  }

  applyTextFilter(filter: string): void {
    this.guestListDataSource.filter = filter;
  }

  applyTicketFilter(event: MatSelectChange): void {
    const filterValue = event.value;
    const textFilterActive: boolean = this.filterText ? true : false;
    if (filterValue !== 'All') {
      this.guestListDataSource.filter = textFilterActive ? this.filterText : filterValue;
    } else {
      this.guestListDataSource.filter = textFilterActive ? this.filterText : '';
    }
  }

  openChat(userId: string): void {
    if (userId) {
      const newUrl = `/dance-manager/${this.danceEvent.danceSchool.id}/messages/${userId}?partnerIds=${this.currentUser.id}`;
      this.router.navigateByUrl(newUrl);
    }
  }

  refreshList(): void {
    this.userTicketService.getDanceEventUserTicketStatistics(this.danceEvent.id);
    this.isDanceEventActive = isAppointmentActive(
      // @ts-ignore
      this.danceEvent.appointments[0]
    );
    this.getUserTicketsForDanceEvent();
    this.filterAndSortSetup();
  }

}
