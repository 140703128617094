import {NgControl} from '@angular/forms';
import {Directive, Input, OnChanges, SimpleChanges} from "@angular/core";

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective implements OnChanges {

  @Input() disableControl: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disableControl) {
      if (this.ngControl.control) {
        const action = changes.disableControl.currentValue ? 'disable' : 'enable';
        this.ngControl.control[action]();
        if (changes.disableControl.currentValue) {
          this.ngControl.reset();
        }
      }
    }
  }

  constructor(private ngControl: NgControl
  ) {
  }

}
