<ng-container *transloco="let t">
  <div class="h-100 flex-column flex-item">
    <mat-nav-list *ngIf="selectedDanceManager" class="flex-column-space-between">
      <div>
        <mat-form-field appearance="outline" class="px2 pt2 w-100" *ngIf="showLabel && danceManagers.length > 1">
          <mat-select [(value)]="selectedDanceManager" [compareWith]="compareObjects" disableOptionCentering #selectedOption>
            <mat-option *ngFor="let danceManager of danceManagers | orderByPipe:'name'" [value]="danceManager" [routerLink]="['/dance-manager', danceManager.id]">
              <div class="option-content">
                <span class="">{{ danceManager.name }}</span>
                <div *ngIf="selectedOption.value.id !== danceManager.id">
                  <span class="badge-container" 
                    [matBadge]="danceManager | unreadCountDmPipe : (countUnreadMessageDmSubject | async) : (notificationStateService.getAsyncAvailableUnreadNotificationsCount() | async)"
                    [matBadgeHidden]="(danceManager | unreadCountDmPipe : (countUnreadMessageDmSubject | async) : (notificationStateService.getAsyncAvailableUnreadNotificationsCount() | async)) === 0" 
                    matBadgeColor="accent"
                  ></span>
                </div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <a mat-list-item routerLinkActive="active-link" matTooltipClass="tooltip"
           [routerLink]="['/dance-manager', selectedDanceManager.id]" 
           [routerLinkActiveOptions]="{exact: true}" 
           [matTooltip]="!showLabel ? t('DFM_APP.DFM_SIDENAV.HOME') : null">
          <mat-icon matListItemIcon class="material-icons-outlined">home</mat-icon>
          <span *ngIf="showLabel" matListItemTitle [routerLink]="['/dance-manager', selectedDanceManager.id]" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-link">{{t('DFM_APP.DFM_SIDENAV.HOME')}}</span>
        </a>
        
        <a mat-list-item routerLink="notifications" routerLinkActive="active-link" [matTooltip]="!showLabel ? t('DFM_APP.DFM_SIDENAV.NOTIFICATIONS') : null" matTooltipClass="tooltip" >
          <mat-icon 
            matListItemIcon 
            class="material-icons-outlined mr2"
            [matBadge]="selectedDanceManager | unreadCountDmPipe : null : (notificationStateService.getAsyncAvailableUnreadNotificationsCount() | async)"
            [matBadgeHidden]="(selectedDanceManager | unreadCountDmPipe : null : (notificationStateService.getAsyncAvailableUnreadNotificationsCount() | async)) === 0"
            matBadgeColor="accent"
          >
            notifications_out
          </mat-icon>
          <span *ngIf="showLabel" matListItemTitle routerLink="notifications" routerLinkActive="active-link">{{t('DFM_APP.DFM_SIDENAV.NOTIFICATIONS')}}</span>
        </a>
        
        <a mat-list-item routerLink="user-groups" routerLinkActive="active-link" [matTooltip]="!showLabel ? t('DFM_APP.DFM_SIDENAV.USER_GROUPS') : null" matTooltipClass="tooltip" >
          <mat-icon
            matListItemIcon
            class="material-icons-outlined mr2">
            groups
          </mat-icon>
          <span *ngIf="showLabel" matListItemTitle routerLink="notifications" routerLinkActive="active-link">{{ t('DFM_APP.DFM_SIDENAV.USER_GROUPS')}}</span>
        </a>
        
        <div securedDanceSchoolElement [danceManagerId]="selectedDanceManager.id" [allowedRoles]="[DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN, DanceManagerUserRoleEnum.TEACHER]">
          <a mat-list-item routerLink="locations" routerLinkActive="active-link" [matTooltip]="!showLabel ? 'Locations' : null" matTooltipClass="tooltip" >
            <mat-icon matListItemIcon class="material-icons-outlined mr2">
              room
            </mat-icon>
            <span *ngIf="showLabel" matListItemTitle routerLink="locations" routerLinkActive="active-link">{{ t('DFM_APP.DFM_SIDENAV.LOCATIONS') }}</span>
          </a>
        </div>
        
        <dfm-sidenav-events
          securedDanceSchoolElement
          [danceManagerId]="selectedDanceManager.id"
          [danceManagerFeatureName]="danceManagerFeatureName.DANCE_EVENTS"
          [danceManagerFeatures]="danceManagerFeatures"
          [positionStrategy]="positionStrategy"
          [isExpanded]="showLabel"
          [loading]="loading"
          id="events-side-nav-button"
        ></dfm-sidenav-events>
        
        <dfm-sidenav-courses
          securedDanceSchoolElement
          [danceManagerId]="selectedDanceManager.id"
          [danceManagerFeatureName]="danceManagerFeatureName.COURSES"
          [danceManagerFeatures]="danceManagerFeatures"
          [isExpanded]="showLabel"
          id="sidenav-course-button"
        ></dfm-sidenav-courses>      
        
        <a mat-list-item (click)="navigateToMessages()" routerLinkActive="active-link" [matTooltip]="!showLabel ? 'Messages' : null" matTooltipClass="tooltip">
          <mat-icon
            matListItemIcon
            class="material-icons-outlined mr2"
            [matBadge]="unreadMessagesCount"
            [matBadgeHidden]="unreadMessagesCount === 0"
            matBadgeColor="accent">
            messages
          </mat-icon>
          <span *ngIf="showLabel" matListItemTitle routerLink="messages" routerLinkActive="active-link">{{ t('DFM_APP.DFM_SIDENAV.MESSAGES') }}</span>
        </a>
        
        <dfm-sidenav-administration
          securedDanceSchoolElement
          [danceManagerId]="selectedDanceManager.id"
          [allowedRoles]="[DanceManagerUserRoleEnum.MANAGER, DanceManagerUserRoleEnum.ADMIN, DanceManagerUserRoleEnum.OWNER]"
          [isExpanded]="showLabel"
        ></dfm-sidenav-administration>
  
        <div securedDanceSchoolElement
          [danceManagerId]="selectedDanceManager.id"
          [allowedRoles]="[DanceManagerUserRoleEnum.OWNER]">
          <a
            mat-list-item
            [routerLink]="dfmPayoutRoute"
            routerLinkActive="active-link"
            [matTooltip]="!showLabel ? t('DFM_APP.DFM_SIDENAV.PAYOUTS') : null"
            matTooltipClass="tooltip">
            <mat-icon matListItemIcon class="material-icons-outlined mr2">payments</mat-icon>
            <span *ngIf="showLabel" [routerLink]="dfmPayoutRoute" routerLinkActive="active-link">{{ t('DFM_APP.DFM_SIDENAV.PAYOUTS') }}</span>
          </a>
        </div>
        
        <div securedDanceSchoolElement
          [danceManagerId]="selectedDanceManager.id"
          [allowedRoles]="[DanceManagerUserRoleEnum.MANAGER, DanceManagerUserRoleEnum.ADMIN, DanceManagerUserRoleEnum.OWNER]">
          <a
            mat-list-item
            routerLink="settings"
            routerLinkActive="active-link"
            [matTooltip]="!showLabel ? t('DFM_APP.DFM_SIDENAV.SETTINGS') : null"
            matTooltipClass="tooltip">
            <mat-icon matListItemIcon class="material-icons-outlined mr2">settings</mat-icon>
            <span *ngIf="showLabel" routerLink="settings" routerLinkActive="active-link">{{ t('DFM_APP.DFM_SIDENAV.SETTINGS') }}</span>
          </a>
        </div>
        
        <div securedDanceSchoolElement
          [danceManagerId]="selectedDanceManager.id"
          [allowedRoles]="[DanceManagerUserRoleEnum.MANAGER,DanceManagerUserRoleEnum.ADMIN,DanceManagerUserRoleEnum.OWNER]">
          <a
            mat-list-item
            [routerLink]="dfmMarketPlaceRoute"
            routerLinkActive="active-link"
            [matTooltip]="!showLabel ? t('DFM_APP.DFM_SIDENAV.MARKET_PLACE') : null"
            matTooltipClass="tooltip">
            <mat-icon matListItemIcon class="material-icons-outlined mr2">grid_view</mat-icon>
            <span *ngIf="showLabel" [routerLink]="dfmMarketPlaceRoute" routerLinkActive="active-link">{{ t('DFM_APP.DFM_SIDENAV.MARKET_PLACE') }}</span>
          </a>
        </div>
      </div>
    </mat-nav-list>
  </div>
</ng-container>
