import { CourseDmOverviewInterface } from './course-dm-overview.interface';
import { DanceStyleInterface } from '../dance-style.interface';
import { ImageInterface } from '../image.interface';

export interface CourseDmPublicOverviewInterface extends CourseDmOverviewInterface{
  danceStyles: DanceStyleInterface[];
  description: string;
  nextAppointmentDate?: Date;
  mainImage?: ImageInterface;
  nextAppointmentDuration?: number;
}
