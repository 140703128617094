import { FollowTargetTypeEnum } from '../../enums';

export interface FollowInterface {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  danceSchoolId?: string;
  danceClassId?: string;
  danceEventId?: string;
  roomRentalId?: string;
  locationId?: string;
  followedUserId?: string;
  targetType: FollowTargetTypeEnum;
  isFollowing: boolean;
  deletedAt?: Date;
}
