import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenderFormSettingsComponent } from './gender-form-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { DfSharedButtonModule, NumberInputModule } from '@platri/dfx-angular-core';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [GenderFormSettingsComponent],
  exports: [GenderFormSettingsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NumberInputModule,
    MatButtonModule,
    DfSharedButtonModule,
    TranslocoDirective
  ]
})
export class GenderFormSettingsModule {}
