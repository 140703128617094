import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'isDisabled',
})
export class IsDisabledPipe implements PipeTransform {

  transform(element: any, elements: any[], deep= false): boolean {
    if (deep) {
      return !!elements?.find(
        (elementA) => elementA.id === element.id
      );
    } else {
      return elements?.includes(element);
    }
  }
}
