import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EventTicketEmptyListComponent } from './event-ticket-empty-list.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [EventTicketEmptyListComponent],
  exports: [EventTicketEmptyListComponent],
    imports: [
      CommonModule,
      FormsModule, 
      PipesModule, 
      RouterModule,
      MatIconModule,
      MatButtonModule, 
      DfSharedButtonModule, 
      TranslocoDirective
    ]
})
export class EventTicketEmptyListModule {}
