import { Pipe, PipeTransform } from '@angular/core';
import { Conversation } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'conversationGetPartnerIds'
})
export class ConversationGetPartnerIdsPipe implements PipeTransform {
  transform(conversation: Conversation, currentPartnerId: string): string {
    return conversation.partners
      .filter((partner) => partner.id !== currentPartnerId)
      .map((user) => user.id)
      .join(',') ?? conversation.partners[0].id.toString();
  }
}
