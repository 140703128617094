import { DanceManagerInterface } from './dance-manager';

export interface DanceManagerFaqInterface {
  id?: string;
  created?: Date;
  updated?: Date;
  deletedDate?: Date;
  question?: string;
  answer?: string;
  danceSchool?: DanceManagerInterface;
  sequenceNumber?: number;
}
