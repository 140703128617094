import { MessageStatusEnum } from '../enums';
import { MessageReactionEnum, MessageTypeEnum } from '../enums';

export interface MessageInterface {
  id: number;
  senderId?: string;
  senderDeleted?: boolean;
  recipientId?: string;
  recipientDeleted?: boolean;
  recipientReaction?: MessageReactionEnum;
  date?: Date;
  value?: string;
  status?: MessageStatusEnum;
  type?: MessageTypeEnum;
  repliedToMessageId?: number;
}
