import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTicketSubmitStepComponent } from './event-ticket-submit-step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../pipes';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [
    EventTicketSubmitStepComponent
  ],
  exports: [
    EventTicketSubmitStepComponent
  ],
  providers: [],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      CdkStepperModule,
      MatFormFieldModule,
      MatSelectModule,
      MatInputModule,
      PipesModule,
      TranslocoDirective,
    ]
})
export class EventTicketSubmitStepModule { }
