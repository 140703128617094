<ng-container *transloco="let t">
  <div class="w-100 background-color background-container flex-column flex-align-center-gt-xs">
    <div class="w-100 h-100 stepper-container flex-column">
      <mat-horizontal-stepper class="background-color py2" linear #stepper>
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>
  
        <mat-step
          [label]="t('DANCE_MANAGER_EVENT.BASIC_INFO')"
          class="pb1"
          [completed]="firstStepValid"
        >
          <ng-template matStepContent>
            <df-create-dance-event-info-general-page
              [formGroup]="generalInfoFormGroup"
              (onCancelClick)="navigateToEventsOverViewPage()"
              (onSubmitClick)="goToNextStep(0, stepper)"></df-create-dance-event-info-general-page>
          </ng-template>
        </mat-step>
        <mat-step
          [label]="t('DANCE_MANAGER_EVENT.DATE_AND_LOCATION')"
          [completed]="secondStepValid"
        >
          <ng-template matStepContent>
            <df-create-dance-event-appointment-location-page
              [formGroup]="appointmentAndLocationFormGroup"
              [locations]="locations"
              (onCancelClick)="goToPreviousStep(stepper)"
              (onSubmitClick)="goToNextStep(1, stepper)"></df-create-dance-event-appointment-location-page>
          </ng-template>
        </mat-step>
        <mat-step [label]="t('DANCE_MANAGER_EVENT.RULES')">
          <ng-template matStepContent>
            <df-create-dance-event-rules-page
              [formGroup]="rulesFormGroup"
              [loading]="danceEventService.loadingSubject$ | async"
              (onCancelClick)="goToPreviousStep(stepper)"
              (onSubmitClick)="createDanceEvent($event)"></df-create-dance-event-rules-page>
          </ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</ng-container>
