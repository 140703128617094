import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerCourseListComponent } from './dance-manager-course-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CourseListItemCardModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DanceManagerCourseListComponent],
  imports: [CommonModule, InfiniteScrollModule, CourseListItemCardModule, MatProgressSpinnerModule,],
  exports: [DanceManagerCourseListComponent],
})
export class DanceManagerCourseListModule {}
