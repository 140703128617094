import { CourseDmOverviewInterface } from '../../..';
import { DanceStyleDto } from '../../..';

export class CourseDmOverviewDto implements CourseDmOverviewInterface {
  id: string;
  name: string;
  startDifficulty: number;
  endDifficulty: number;
  danceStyles: DanceStyleDto[];
  nextAppointmentDate?: Date;
  description: string;
}
