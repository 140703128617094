import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb, GetPassesByCurrentUserResponseDto, UserPassDto, UserPassInterface, UserPassScannedFilterEnum } from '@platri/df-common-core';

export type GetPassesByCurrentUserType = 'pass' | 'subscription';

@Injectable({
  providedIn: 'root',
})
export class UserPassHttpService {
  private apiBaseUrl = `${environmentForWeb.apiTicketUrl}/user-pass`;

  constructor(private readonly http: HttpClient) {}

  getAllByDmIdAndTargetIdAndUserIds(danceManagerId: string, targetId: string, userIds: string[], appointmentId: string): Observable<UserPassDto[]> {
    return this.http.post<UserPassDto[]>(`${environmentForWeb.apiTicketUrl}/courses/user-passes/target/user-ids`, {
      danceManagerId,
      targetId,
      userIds,
      appointmentId
    });
  }

  scan(userPassId: string, targetId: string, force = false, appointmentId: string): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}/scan/v2`,{
      qrCodeString: userPassId,
      targetId,
      force,
      appointmentId
    });
  }
  
  getAllByPassId(passId: string, onlyActive: boolean = true): Observable<UserPassDto[]> {
    return this.http.get<UserPassDto[]>(`${this.apiBaseUrl}/pass/${passId}/${onlyActive}`);
  }
  
  getAllByCourseId(courseId: string): Observable<UserPassDto[]> {
    return this.http.get<UserPassDto[]>(`${this.apiBaseUrl}/course/${courseId}`);
  }
  
  getAllByCurrentUser(status: UserPassScannedFilterEnum): Observable<GetPassesByCurrentUserResponseDto[]> {
    return this.http.get<GetPassesByCurrentUserResponseDto[]>(`${this.apiBaseUrl}/current-user/${status}`);
  }
  
  getAllByCurrentUserV2(status: UserPassScannedFilterEnum): Observable<UserPassInterface[]> {
    return this.http.get<UserPassInterface[]>(`${this.apiBaseUrl}/current-user/${status}/v2`);
  }
  
  getAllUserPassSubscriptionPassesByCurrentUser(status: UserPassScannedFilterEnum): Observable<UserPassDto[]> {
    return this.http.get<UserPassDto[]>(`${this.apiBaseUrl}/current-user/user-pass-subscriptions/${status}`);
  }
  
  getUserPassById(userPassId: string): Observable<GetPassesByCurrentUserResponseDto> {
    return this.http.get<GetPassesByCurrentUserResponseDto>(`${this.apiBaseUrl}/${userPassId}`);
  }

  getUserPassByIdV2(userPassId: string): Observable<UserPassInterface> {
    return this.http.get<UserPassInterface>(`${this.apiBaseUrl}/${userPassId}/v2`);
  }
  
  getUserPassSubscriptionUserPassById(userPassId: string): Observable<UserPassDto> {
    return this.http.get<UserPassDto>(`${this.apiBaseUrl}/user-pass-subscriptions/${userPassId}`);
  }

  getParticipantsByAppointmentId(appointmentId: string): Observable<UserPassDto[]> {
    return this.http.get<UserPassDto[]>(`${this.apiBaseUrl}/course/appointment-participants/${appointmentId}`);
  }
  
  createFreePass(passId: string, userIds: string[], emails: string[]): Observable<void> {
    return this.http.post<void>(`${this.apiBaseUrl}/free-pass`, {
      userIds,
      emails,
      passId
    });
  }

  transferById(id: string, userId: string): Observable<void> {
    return this.http.put<void>(`${this.apiBaseUrl}/${id}/transfer`, { userId: userId });
  }

  refundById(id: string): Observable<void> {
    return this.http.patch<void>(`${this.apiBaseUrl}/${id}/refund`, {});
  }
  
  createTrialPass(dto: TrialLessonRequestDto): Observable<void> {
    return this.http.post<void>(`${this.apiBaseUrl}/trial-pass`, dto);
  }
}

interface TrialLessonRequestDto {
  courseId?: string;
  appointmentId?: string;
  danceManagerId?: string;
  userId?: string;
  email?: string;
}
