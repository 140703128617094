import { UserTicketDanceRoleTypeEnum, UserTicketInterface, UserTicketStatusEnum } from '../../..';
import { TicketDto } from '../../..';

export class UserTicketDto implements UserTicketInterface {
  id: string;
  createdAt: Date;
  ticket: TicketDto;
  userId: string;
  invitedUserEmail: string;
  ticketOrderId: string;
  quantity: number;
  status: UserTicketStatusEnum;
  userTicketDanceRoleType: UserTicketDanceRoleTypeEnum;
}
