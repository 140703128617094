import { AuthDanceSchoolUserRoleJwtEntryInterface } from '../interfaces';
import { DanceManagerUserRoleEnum } from '../enums';

export function getDanceManagerIdsFromTokenRoles(roles: AuthDanceSchoolUserRoleJwtEntryInterface): string[] {
  const ids = new Set<string>();

  Object.values(roles).forEach(roleIds => {
    roleIds.forEach((id: string) => ids.add(id));
  });

  return Array.from(ids);
}

export function getDanceManagerIdsFromTokenRolesByRoles(roles: AuthDanceSchoolUserRoleJwtEntryInterface, roleNames: DanceManagerUserRoleEnum[]): string[] {
  const ids = new Set<string>();

  roleNames.forEach(roleName => {
    const roleIds = roles[roleName];
    if (roleIds) {
      roleIds.forEach(id => ids.add(id));
    }
  });

  return Array.from(ids);
}

export function hasAccessToDanceManagerFromTokenRolesByIdAndRoles(danceManagerId: string, roles: AuthDanceSchoolUserRoleJwtEntryInterface, roleNames: DanceManagerUserRoleEnum[]): boolean {
  const ids = new Set<string>();

  roleNames.forEach(roleName => {
    const roleIds = roles[roleName];
    if (roleIds) {
      roleIds.forEach(id => ids.add(id));
    }
  });

  return Array.from(ids).includes(danceManagerId);
}
