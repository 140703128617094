import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CourseInterface, PassSaleSelectionPageInterface, UserAppLangEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-pass-checkout-list-item',
  templateUrl: './pass-checkout-list-item.component.html',
  styleUrls: ['./pass-checkout-list-item.component.scss']
})
export class PassCheckoutListItemComponent {
  @Input() pass: PassSaleSelectionPageInterface;
  @Input() formGroup: UntypedFormGroup;
  @Input() courses: CourseInterface[];
  currentLang: UserAppLangEnum;
  public showAllAccessibleCourses = false;
  
  public readonly amounts = Array(10).fill(10).map((x, i) => i + 1);

  constructor(
    private readonly translocoService: TranslocoService, 
    public dialog: MatDialog
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }
  
  get currentQuantity(): AbstractControl<number> {
    return this.formGroup.get(this.pass?.id);
  }
  
  get currentQuantityCanBeIncreased(): boolean {
    const currentValue = +this.currentQuantity.value;
    return (!this.pass.trial && (currentValue < this.pass.availableQuantity && currentValue < 10) && this.courses.length > 0) || (this.pass.trial && currentValue === 0);
  }

  changeValue(number: number): void {
    const currentValue = +this.currentQuantity.value;
    if ((number === 1) && this.currentQuantityCanBeIncreased) {
      this.formGroup.get(this.pass?.id).setValue(currentValue + number);
    } else if (number === -1) {
      if (currentValue >= 1) {
        this.formGroup.get(this.pass?.id).setValue(currentValue + number);
      }
    }
  }
  
}
