import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmEditLocationPageComponent } from './dfm-edit-location-page.component';
import { RouterModule, Routes } from '@angular/router';
import { DfmEditLocationFormModule } from '../../components/dfm-edit-location-form/dfm-edit-location-form.module';

const routes: Routes = [

  {
    path: '',
    component: DfmEditLocationPageComponent,
  },
];

@NgModule({
  declarations: [DfmEditLocationPageComponent],
  imports: [
    CommonModule,
    DfmEditLocationFormModule,
    RouterModule.forChild(routes)
  ],
  exports: [DfmEditLocationPageComponent]
})
export class DfmEditLocationPageModule {}
