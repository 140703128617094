<ng-container *transloco="let t">
  <div style="position: relative;">
    <div *ngIf="danceManager" class="flex-row-center-center">
      <div class="flex-row preview-card w-100 preview clickable opacityAnimation">
        <a (click)="navigateToDetailPage()" style="flex: 1;">
          <div class="container-flex-row-responsive-col align-space-between w-100 h-100">
            <div class="flex-column gap-20 w-100 h-100 p2">
              <div class="flex-row gap-20">
                <img class="circle" style="width: 64px; height: 64px; border: 1px solid #C4C7C8;" [src]="danceManager.imageUrl ?? 'assets/images/studio_logo.png'" alt=""/>
                <div class="flex-column">
                  <span *ngIf="userPass" class="titleText name left-align ellipsis-1">{{ userPass.userPassSubscription?.name }}</span>
                  <a [href]="'/app/@' + danceManager.urlName" (click)="$event.stopPropagation();">
                    <span class="subText name left-align ellipsis-1" >
                      {{ danceManager.name }}
                    </span>
                  </a>
                </div>
              </div>
              <div class="flex-column gap-10" (click)="$event.stopPropagation();">
                <span *ngIf="showNextPaymentInfo" [innerHTML]="t('USER.NEXT_PAYMENT_AT', {price: (getNextPaymentPrice() | centsToCurrencyString: 'EUR'), date: (userPass.userPassSubscription.currentPeriodEndAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate')})"></span>
                <span *ngIf="userPass.userPassSubscription.status === userPassSubscriptionStatusEnum.TERMINATED_PERIOD_END" [innerHTML]="t('USER.TERMINATED_AS_OF', {date: (userPass.userPassSubscription.endedAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate')})"></span>
                <span *ngIf="userPass.userPassSubscription.status === userPassSubscriptionStatusEnum.TERMINATED" [innerHTML]="t('USER.EXPIRED_ON', {date: (userPass.userPassSubscription.currentPeriodEndAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate')})"></span>
                <span *ngIf="userPass.userPassSubscription.status !== userPassSubscriptionStatusEnum.TERMINATED" style="color: #00a1df;" class="clickable-span flex-row align-start-center span-with-icon" (click)="$event.stopPropagation(); openStripeBillingPortalSession()">
                  {{ t('GENERIC_WRAPPER.MANAGE_SUBSCRIPTIONS') }}
                </span>
              </div>
            </div>
            <div *ngIf="userPass.userPassSubscription.status !== userPassSubscriptionStatusEnum.TERMINATED" class="flex-direction-swap-row-to-column align-end-start w-100 h-100 p2">           
              <div class="flex-column h-100" style="margin-right: 20px;">
                <mat-divider class="hide-on-mobile h-100" style="display: block" [vertical]="true"></mat-divider>
                <mat-divider class="hide-on-web h-100"></mat-divider>
              </div>
              <div class="flex-column right-container w-100" style="max-width: 320px;">
                <span class="titleText name left-align ellipsis-1">
                  {{ t('GENERIC_WRAPPER.STAMPS') }}
                </span>
                <div *ngIf="userPass.userPassSubscription.weeklyUsage" class="subText name ellipsis-1">
                  <mat-grid-list cols="5" rowHeight="40px" gutterSize="10px" style="margin-top: 10px; margin-bottom: 10px;">
                    <mat-grid-tile *ngFor="let stamp of shownStamps" class="stamp-container">
                      <div [class]="stamp <= numberOfScans() ? 'align-center stamp used' : 'align-center stamp unused'">
                        <mat-icon *ngIf="stamp <= numberOfScans()" style="color: #00A1DF">check</mat-icon>
                        <span *ngIf="stamp > numberOfScans()">{{ stamp }}</span>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
                <div class="subText name ellipsis-0">
                  <span *ngIf="!userPass.userPassSubscription.weeklyUsage">{{ t('GENERIC_WRAPPER.UNLIMITED') }}</span>
                  <span *ngIf="userPass.userPassSubscription.weeklyUsage">{{t('USER.STAMPS_RESET_AT') + (dateNextMonday | dateTimezoned:'UTC' : currentUser.appLang : null: true: false : 'shortDate')}}</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-container>
