import { Component } from '@angular/core';

@Component({
  selector: 'df-monorepo-dfm-create-user-group-page',
  templateUrl: './dfm-create-user-group-page.component.html',
  styleUrls: ['./dfm-create-user-group-page.component.scss']
})
export class DfmCreateUserGroupPageComponent {
  
}
