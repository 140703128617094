<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveDanceInterval()" class="flex-column" style="gap: 1rem;">
    <mat-divider></mat-divider>
    <mat-radio-group formControlName="danceInterval" class="flex-column" style="gap: 1rem;" color="primary">
      <div *ngFor="let danceInterval of danceIntervalEnumSorted |keyvalue" class="flex-column" style="gap: 0.5rem;">
        <mat-radio-button
          [value]="danceInterval.value">{{t('USER.DANCE_INTERVAL_ENUM.' + danceInterval.value)}}</mat-radio-button>
        <mat-divider></mat-divider>
      </div>
    </mat-radio-group>
  
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        (buttonClicked)="cancel()"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveDanceInterval()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
