import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DanceSchoolInterface, PageInterface } from '@platri/df-common-core';
import { DanceManagerHttpService } from '../http-services';
import { getSubscription } from '../../helpers';

@Injectable({providedIn: 'root'})
export class DanceManagerStateService implements OnDestroy{
  isLoadingDanceManagers = new BehaviorSubject<boolean>(false);
  availableDanceManagers = new BehaviorSubject<DanceSchoolInterface[]>([]);
  //selectedDanceManager = new BehaviorSubject<DanceSchoolInterface | null>(null);
  
  isLoadingDanceManager = new BehaviorSubject<boolean>(false);
  availableDanceManager = new BehaviorSubject<DanceSchoolInterface>(null);

  

  subscriptions = new Subscription();
  
  constructor(private danceManagerHttpService: DanceManagerHttpService) {
    console.log('Initializing DanceManagerStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying DanceManagerStateService');
    this.subscriptions.unsubscribe();
    this.isLoadingDanceManagers.unsubscribe();
    this.availableDanceManagers.unsubscribe();
    this.isLoadingDanceManager.unsubscribe();
    this.availableDanceManager.unsubscribe();
    //this.selectedDanceManager.unsubscribe();
  }
  
  loadAllDanceManagersByIds(ids: string[]): void {
    this.sendIsLoadingDanceManagers(true);
    this.subscriptions.add(getSubscription(this.danceManagerHttpService.getAllDanceManagersByIds(ids), this.onLoadedDanceManagers.bind(this), () => {console.log(`Couldn't load Dance Managers`); this.sendIsLoadingDanceManagers(false);}));
  }
  
  onLoadedDanceManagers(pagination: PageInterface<DanceSchoolInterface>): void {
    this.sendIsLoadingDanceManagers(false);
    this.sendAvailableDanceManagers(pagination.results);
  }

  loadDanceManagerById(id: string): void {
    this.sendIsLoadingDanceManager(true);
    this.subscriptions.add(getSubscription(this.danceManagerHttpService.getDanceManagerById(id), this.onLoadedDanceManager.bind(this), () => {console.log(`Couldn't load Dance Manager`); this.sendIsLoadingDanceManager(false);}));
  }

  onLoadedDanceManager(danceManager: DanceSchoolInterface): void {
    this.sendIsLoadingDanceManager(false);
    this.sendAvailableDanceManager(danceManager);
  }
 
  
  getAsyncIsLoadingDanceManagers(): Observable<boolean> {
    return this.isLoadingDanceManagers.asObservable();
  }
  
  getAsyncAvailableDanceManagers(): Observable<DanceSchoolInterface[]> {
    return this.availableDanceManagers.asObservable();
  }

  getAsyncIsLoadingDanceManager(): Observable<boolean> {
    return this.isLoadingDanceManager.asObservable();
  }

  getAsyncAvailableDanceManager(): Observable<DanceSchoolInterface> {
    return this.availableDanceManager.asObservable();
  }
  
  // getAsyncSelectedDanceManager(): Observable<DanceSchoolInterface | null> {
  //   return this.selectedDanceManager.asObservable();
  // }
  
  clear(): void {
    this.isLoadingDanceManagers.next(false);
    this.availableDanceManagers.next([]);
    this.isLoadingDanceManager.next(false);
    this.availableDanceManager.next(null);
    //this.selectedDanceManager.next(null);
  }

  private sendIsLoadingDanceManager(isLoading: boolean): void {
    this.isLoadingDanceManager.next(isLoading);
  }

  private sendAvailableDanceManager(danceManagers: DanceSchoolInterface): void {
    this.availableDanceManager.next(danceManagers);
  }
  
  private sendIsLoadingDanceManagers(isLoading: boolean): void {
    this.isLoadingDanceManagers.next(isLoading);
  }
  
  private sendAvailableDanceManagers(danceManagers: DanceSchoolInterface[]): void {
    this.availableDanceManagers.next(danceManagers);
  }
  
  // private sendSelectedDanceManager(danceManager: DanceSchoolInterface): void {
  //   this.selectedDanceManager.next(danceManager);
  // }
  
}
