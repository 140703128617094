import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DanceManagerFeature, DanceManagerFeatureName, DanceManagerSubscription, DanceManagerSubscriptionType, DanceSchool } from '@platri/df-common-shared-graphql';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DanceManagerService, DanceSchoolStateService } from '../../services';
import { getSubscription } from '../../helpers';
import { DanceManagerSubscriptionInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-dm-packages-card-header',
  templateUrl: './dm-packages-card-header.component.html',
  styleUrls: ['./dm-packages-card-header.component.scss'],
})
export class DmPackagesCardHeaderComponent implements OnInit, OnDestroy {
  danceManager: DanceSchool;
  @Input() danceManagerSubscription: DanceManagerSubscriptionInterface;

  isFeatureEventActive = false;
  isFeatureEventTicketsActive = false;
  isFeatureCoursesActive = false;

  danceManagerFeatureName = DanceManagerFeatureName;
  danceManagerSubscriptionType = DanceManagerSubscriptionType;

  private subscription = new Subscription();

  constructor(
    public readonly _danceManagerService: DanceManagerService,
    private readonly _danceSchoolService: DanceSchoolStateService,
    public router: Router
  ) {}

  ngOnInit(): void {    
    this.subscription.add(
      getSubscription(
        this._danceManagerService.getAsyncDanceManagerFeatures(),
        this.onDanceManagerFeaturesChange.bind(this)
      )
    );
    this.subscription.add(
      getSubscription(
        this._danceSchoolService.getAsyncCurrentDanceSchool(),
        this.onDanceManagerChange.bind(this)
      )
    );
  }

  onDanceManagerFeaturesChange(
    danceManagerFeatures: DanceManagerFeature[]
  ): void {
    this.isFeatureEventActive = !!danceManagerFeatures.find(
      (danceManagerFeature) =>
        danceManagerFeature.name === this.danceManagerFeatureName.DANCE_EVENTS
    );
    this.isFeatureEventTicketsActive = !!danceManagerFeatures.find(
      (danceManagerFeature) =>
        danceManagerFeature.name ===
        this.danceManagerFeatureName.DANCE_EVENTS_TICKETS
    );
    this.isFeatureCoursesActive = !!danceManagerFeatures.find(
      (danceManagerFeature) =>
        danceManagerFeature.name === this.danceManagerFeatureName.COURSES
    );
  }

  onDanceManagerChange(danceManager: DanceSchool): void {
    this.danceManager = danceManager;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

    protected readonly DanceManagerSubscriptionType = DanceManagerSubscriptionType;
}


