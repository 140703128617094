import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainContentModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfmAppRoutingModule } from './dfm-app-routing.module';
import { DfmAppComponent } from './dfm-app.component';
import { DfmMainContentModule, DfmSidenavModule } from '@platri/df-angular-dfm-core';

const MATERIAL_MODULES = [MatProgressSpinnerModule];

@NgModule({
  declarations: [DfmAppComponent],
  imports: [
    CommonModule,
    DfmMainContentModule,
    DfmAppRoutingModule,
    MainContentModule,
    ...MATERIAL_MODULES,
    DfmSidenavModule
  ],
  exports: [DfmAppComponent,]
})
export class DfmAppModule {
  constructor() {
    console.log('DfmAppModule initialized');
  }
}
