import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursePassesCheckoutPage } from './course-passes-checkout.page';
import { MatIconModule } from '@angular/material/icon';
import { ContactUrlDirectiveModule, EventTicketOrderSummaryModule, PipesModule, TicketCheckoutPaymentModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';
@NgModule({
  declarations: [CoursePassesCheckoutPage],
    imports: [
      CommonModule,
      TranslocoModule,
      MatIconModule,
      PipesModule,
      MatDividerModule,
      TicketCheckoutPaymentModule,
      EventTicketOrderSummaryModule,
      ContactUrlDirectiveModule,
      MatProgressSpinner
    ]
})
export class CoursePassesCheckoutPageModule {}
