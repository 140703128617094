<ng-container *transloco="let t">
  <div class="w-100 background-color background-container flex-column flex-align-center-gt-xs">
    <div class="w-100 h-100 stepper-container flex-column">
      <mat-horizontal-stepper class="background-color py2" linear #stepper>
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>
  
        <mat-step [label]="t('DANCE_MANAGER_COURSE.STEPPER.INFO_TITLE')" class="pb1" [completed]="infoFormGroup.valid">
          <ng-template matStepContent>
            <div class="flex-column w-100 h-100" style="margin-top: 20px;">
              <mat-card>
                <df-course-info-form [infoFormGroup]="infoFormGroup" (onCancelClick)="checkInfoFormGroupIsDirtyAndShowCancelDialog()" (onContinueClick)="stepper.next()" [loading]="loading"></df-course-info-form>
              </mat-card>
            </div>
          </ng-template>
        </mat-step>
        <mat-step [label]="t('DANCE_MANAGER_COURSE.STEPPER.RULES_TITLE')" [completed]="rulesFormGroup.valid && rulesFormGroup.touched">
          <ng-template matStepContent>
            <div class="flex-column w-100 h-100">
              <mat-card>
                <df-course-rules-form [rulesFormGroup]="rulesFormGroup" (onBackClick)="stepper.previous()" (onContinueClick)="submit()" [loading]="loading"></df-course-rules-form>
              </mat-card>
            </div>
          </ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</ng-container>
