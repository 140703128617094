import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { HeaderLanguageSelectionComponent } from './header-language-selection.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [HeaderLanguageSelectionComponent],
  exports: [HeaderLanguageSelectionComponent],
    imports: [
      CommonModule,
      MatCardModule,
      MatFormFieldModule,
      MatSelectModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      MatTooltipModule,
      TranslocoModule,
    ],
  providers: [],
})
export class HeaderLanguageSelectionModule {}
