import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { first, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DanceEventHttpService, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService, quillCharacterCountValidator, TicketHttpService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import {
  DanceEventsInterface,
  DanceManagerUserGroupsInterface,
  FeesPaidByEnum,
  TicketDto,
  TicketInterface,
  TimeInterface,
  TimeOperations
} from '@platri/df-common-core';
import { Time } from '@platri/df-common-shared-graphql';
import { map } from 'rxjs/operators';

@Component({
  selector: 'df-edit-event-ticket',
  templateUrl: './edit-event-ticket.component.html',
  styleUrls: ['./edit-event-ticket.component.scss']
})
export class EditEventTicketComponent implements OnInit, OnDestroy {
  isLoadingData = true;
  isDataInjected = false;
  loadingDataError = false;

  eventTicket: TicketInterface;
  danceEvent: DanceEventsInterface;
  danceEvents: DanceEventsInterface[];
  dfmUserGroups: DanceManagerUserGroupsInterface[];

  parentFormGroup: UntypedFormGroup;
  ticketSettingsFormGroup: UntypedFormGroup;
  pricingFormGroup: UntypedFormGroup;
  salesSettingsFormGroup: UntypedFormGroup;
  taxPercentage:number;
  isVatChanged= false;
  

  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private matSnackBar: MatSnackBar,
    private danceSchoolService: DanceSchoolStateService,
    private readonly danceEventHttpService: DanceEventHttpService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private activatedRoute: ActivatedRoute,
    private ticketHttpService: TicketHttpService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getTicketFromRoute();
    this.loadData();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTicketFromRoute(): void {
    this.eventTicket = this.activatedRoute.snapshot.data.targetObject;
  }
  
  loadData(): void {
    this.loadAllDanceEvents();
    this.loadUserGroups();
  }

  loadAllDanceEvents(): void {
    this.subscriptions.add(
      this.danceEventHttpService.getAllByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, true, true).subscribe({
        next: (res) => {
          this.danceEvents = res;
          this.danceEvent = this.danceEvents.find((danceEvent) => danceEvent.id === this.eventTicket.danceEventId);
          this.isLoadingData = false;
          this.initializeForms();
        },
        error: (error) => {
          this.loadingDataError = true;
          this.isLoadingData = false;
        }
      })
    );
  }

  loadUserGroups(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDmUserGroupsByDanceManagerId({danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id}).subscribe({
        next: (data) => {
          this.dfmUserGroups = data;
        }
      })
    );
  }

  initializeForms(): void  {
    this.initializeTicketSettingsFormGroup();
    this.initializePricingFormGroup();
    this.initializeSalesSettingsFormGroup();
    this.initializeParentFormGroup();
    this.initializeFormSubscriptions();
    this.injectLoadedData();
    this.disableUnavailableFormFields();
  }

  initializeTicketSettingsFormGroup(): void  {
    this.ticketSettingsFormGroup = this.fb.group({
      event: this.fb.control(null, [Validators.required]),
      name: this.fb.control({ value: null, disabled: this.eventTicket.soldQuantity > 0 }, [Validators.required]),
      description: this.fb.control('', [Validators.required, quillCharacterCountValidator(1000)]),
      availableQuantity: this.fb.control({ value: null }, [Validators.required, Validators.min(this.eventTicket.soldQuantity)])
    });
  }

  initializePricingFormGroup(): void  {
    this.pricingFormGroup = this.fb.group({
      price: this.fb.control({ value: null, disabled: this.eventTicket.soldQuantity > 0 }, [Validators.required, Validators.min(0), Validators.max(9999999)]),
      tax: this.fb.group({
        percent: this.fb.control({ value: null, disabled: this.eventTicket.soldQuantity > 0 }, [Validators.required, Validators.min(0), Validators.max(100)])
      }),
      feesPaidBy: this.fb.control({ value: FeesPaidByEnum.CREATOR, disabled: true }, [Validators.required])
    });
  }

  initializeSalesSettingsFormGroup(): void  {
    this.salesSettingsFormGroup = this.fb.group(
      {
        salesStartDate: this.fb.control(null),
        salesStartTime: this.fb.control('00:00'),
        salesEndDate: this.fb.control(null),
        salesEndTime: this.fb.control('23:59'),
        sellAsFollowerLeader: this.fb.control({
          value: false,
          disabled: this.eventTicket.soldQuantity > 0
        }),
        sellAsFollowerActive: this.fb.control(true),
        sellAsLeaderActive: this.fb.control(true),
        salesSettingsFormGroup: this.fb.control(null),
        danceManagerUserGroupIds: this.fb.control(null)
      },
      {
        validator: this.atLeastOne('sellAsFollowerActive', 'sellAsLeaderActive')
      }
    );

    this.subscriptions.add(
      this.salesSettingsFormGroup.valueChanges.subscribe((data) => {
      if (data.salesStartDate) {
        this.salesSettingsFormGroup.controls['salesStartTime'].enable({
          emitEvent: false
        });
        if (this.salesSettingsFormGroup.controls['salesStartTime'].value === null) {
          this.salesSettingsFormGroup.patchValue(
            {
              salesStartTime: '00:00'
            },
            {
              emitEvent: false
            }
          );
        } else {
          if (this.salesSettingsFormGroup.controls['salesEndTime'] !== null && new Date(this.salesSettingsFormGroup.controls['salesEndDate'].value).getTime() === new Date(this.salesSettingsFormGroup.controls['salesStartDate'].value).getTime() && this.salesSettingsFormGroup.controls['salesEndTime'].value <= this.salesSettingsFormGroup.controls['salesStartTime'].value) {
            this.salesSettingsFormGroup.patchValue(
              {
                salesEndTime: this.salesSettingsFormGroup.controls['salesStartTime'].value
              },
              {
                emitEvent: false
              }
            );
          }
        }
      } else {
        this.salesSettingsFormGroup.controls['salesStartTime'].disable({
          emitEvent: false
        });
        this.salesSettingsFormGroup.patchValue(
          {
            salesStartTime: null
          },
          {
            emitEvent: false
          }
        );
      }
      if (data.salesEndDate) {
        this.salesSettingsFormGroup.controls['salesEndTime'].enable({
          emitEvent: false
        });
        if (this.salesSettingsFormGroup.controls['salesEndTime'].value === null) {
          this.salesSettingsFormGroup.patchValue(
            {
              salesEndTime: '23:45'
            },
            {
              emitEvent: false
            }
          );
        }
      } else {
        this.salesSettingsFormGroup.controls['salesEndTime'].disable({
          emitEvent: false
        });
        this.salesSettingsFormGroup.patchValue(
          {
            salesEndTime: null
          },
          {
            emitEvent: false
          }
        );
      }
    })
    );
  }

  initializeParentFormGroup(): void  {
    this.parentFormGroup = this.fb.group({
      ticketSettings: this.ticketSettingsFormGroup,
      pricing: this.pricingFormGroup,
      salesSettings: this.salesSettingsFormGroup
    });
  }

  initializeFormSubscriptions(): void  {
    this.initializeFormValueFirstChangeSubscription();
  }

  initializeFormValueFirstChangeSubscription(): void {
    this.subscriptions.add(
      this.parentFormGroup.valueChanges.pipe(first()).subscribe((res) => {
        this.isDataInjected = true;
      })
    );
  }

  atLeastOne(...fields: string[]) {
    return (group: UntypedFormGroup): ValidationErrors | null =>
      fields.some((fieldName: string) => {
        const field = group.get(fieldName).value;
        return group.get('sellAsFollowerLeader').value ? field : true;
      })
        ? null
        : ({
            atLeastOne: 'At least one field has to be provided.'
          } as ValidationErrors);
  }

  injectLoadedData(): void {
    let salesStartDate;
    let salesEndDate;
    let salesStartTime;
    let salesEndTime;
    if (this.eventTicket.saleStart) {
      salesStartDate = new Date(this.eventTicket.saleStart);
      salesStartDate.setTime(salesStartDate.getTime() + salesStartDate.getTimezoneOffset() * 60 * 1000);

      const startTime: TimeInterface = {
        hours: new Date(this.eventTicket.saleStart).getUTCHours(),
        minutes: new Date(this.eventTicket.saleStart).getUTCMinutes()
      };
      salesStartTime = this.timeToString(startTime);
    }
    if (this.eventTicket.saleEnd) {
      salesEndDate = new Date(this.eventTicket.saleEnd);
      salesEndDate.setTime(salesEndDate.getTime() + salesEndDate.getTimezoneOffset() * 60 * 1000);

      const endTime = new Time();
      endTime.hours = new Date(this.eventTicket.saleEnd).getUTCHours();
      endTime.minutes = new Date(this.eventTicket.saleEnd).getUTCMinutes();
      salesEndTime = this.timeToString(endTime);
    }
    this.taxPercentage = this.eventTicket.taxPercentage;
    this.pricingFormGroup.get('tax.percent')?.valueChanges.subscribe(value => {
      if (value !==this.taxPercentage){
        this.taxPercentage = value;
        this.isVatChanged = true;
      }
    });
    this.parentFormGroup.patchValue(
      {
        ticketSettings: {
          event: this.danceEvent,
          name: this.eventTicket.name,
          description: this.eventTicket.description,
          availableQuantity: this.eventTicket.quantity
        },
        pricing: {
          price: +this.eventTicket.price / 100,
          tax: {
            percent: this.taxPercentage
          },
          feesPaidBy: this.eventTicket.feesPaidBy
        },
        salesSettings: {
          salesStartDate: salesStartDate,
          salesStartTime: salesStartTime ?? null,
          salesEndDate: salesEndDate,
          salesEndTime: salesEndTime ?? null,
          sellAsFollowerLeader: this.eventTicket.sellAsFollowerLeader,
          sellAsFollowerActive: this.eventTicket.sellAsFollowerActive,
          sellAsLeaderActive: this.eventTicket.sellAsLeaderActive,
          danceManagerUserGroupIds: this.eventTicket.danceManagerUserGroupIds
        }
      },
      { emitEvent: true, onlySelf: true }
    );
  }

  disableUnavailableFormFields(): void  {
    this.ticketSettingsFormGroup.controls.event.disable();
  }

  submitForm(): void  {
    const updateTicketDto: TicketDto = this.mapRawFormValueToDto();
    this.parentFormGroup.disable();
    this.subscriptions.add(
      this.ticketHttpService.update(this.eventTicket.id, updateTicketDto).subscribe({
      next: (res) => {
        if (this.isVatChanged){
          this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id, ({vat: this.taxPercentage})).pipe(
            map((result) => {
              this.danceSchoolService.sendCurrentDanceSchool(result);
              this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
                map((danceSchools) => danceSchools),
                map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
              ).subscribe();
            })
          ).subscribe();
        }
        this.matSnackBar.open('Successfully updated ticket!');
        this.parentFormGroup.enable();
        this.router.navigate(
          [
            'dance-manager',
            this.danceSchoolService.getSyncCurrentDanceSchool().id,
            'events',
            'preview',
            // @ts-ignore
            res['danceEvent']['id']
          ],
          { fragment: 'tickets' }
        );
      },
      error: (error) => {
        this.matSnackBar.open("Couldn't update Ticket!");
        this.parentFormGroup.enable();
      }
    })
    );
  }

  mapRawFormValueToDto(): TicketDto {
    const rawFormValue = this.parentFormGroup.getRawValue();
    let saleStartDateTime = null;
    let saleEndDateTime = null;
    if (rawFormValue.salesSettings.salesStartDate) {
      const saleStartDate = new Date(rawFormValue.salesSettings.salesStartDate);
      const salesStartTime = rawFormValue.salesSettings.salesStartTime;
      saleStartDateTime = DateTime.now()
        .setZone('UTC')
        .set({
          day: saleStartDate.getDate(),
          month: saleStartDate.getMonth() + 1,
          year: saleStartDate.getFullYear(),
          hour: TimeOperations.stringToTime(salesStartTime).hours,
          minute: TimeOperations.stringToTime(salesStartTime).minutes,
          second: 0,
          millisecond: 0
        })
        .toString();
    }
    if (rawFormValue.salesSettings.salesEndDate) {
      const saleEndDate = new Date(rawFormValue.salesSettings.salesEndDate);
      const salesEndTime = rawFormValue.salesSettings.salesEndTime;
      saleEndDateTime = DateTime.now()
        .setZone('UTC')
        .set({
          day: saleEndDate.getDate(),
          month: saleEndDate.getMonth() + 1,
          year: saleEndDate.getFullYear(),
          hour: TimeOperations.stringToTime(salesEndTime).hours,
          minute: TimeOperations.stringToTime(salesEndTime).minutes,
          second: 0,
          millisecond: 0
        })
        .toString();
    }
    return {
      name: rawFormValue.ticketSettings.name,
      description: rawFormValue.ticketSettings.description,
      quantity: rawFormValue.ticketSettings.availableQuantity,
      price: Math.round(+rawFormValue.pricing.price * 100),
      taxPercentage: rawFormValue.pricing.tax.percent,
      feesPaidBy: rawFormValue.pricing.feesPaidBy,
      saleStart: saleStartDateTime ?? null,
      saleEnd: saleEndDateTime ?? null,
      sellAsFollowerLeader: rawFormValue.salesSettings.sellAsFollowerLeader,
      sellAsFollowerActive: rawFormValue.salesSettings.sellAsFollowerActive,
      sellAsLeaderActive: rawFormValue.salesSettings.sellAsLeaderActive,
      danceManagerUserGroupIds: rawFormValue.salesSettings.danceManagerUserGroupIds?.length > 0 ? rawFormValue.salesSettings.danceManagerUserGroupIds : null
    };
  }

  timeToString(time: Time): string | null {
    if (!time) {
      return null;
    }
    if (time.hours === 0 && time.minutes === 0) {
      return `00:00`;
    }
    if (!time.hours && !time.minutes) {
      return '';
    }
    if (time.hours < 10) {
      if (time.minutes < 10) {
        return `0${time.hours}:0${time.minutes}`;
      }
      return `0${time.hours}:${time.minutes}`;
    }
    if (time.minutes === 0) {
      return `${time.hours}:00`;
    }
    if (time.minutes < 10) {
      return `${time.hours}:0${time.minutes}`;
    }
    return `${time.hours}:${time.minutes}`;
  }

  navigateToEvent(): void {
    this.router.navigate(
      ['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'events', 'preview', this.danceEvent.id],
      { fragment: 'tickets' }
    );
  }
}
