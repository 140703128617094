import { DanceManagerInterface } from '../../dance-manager';
import { DanceEventsInterface } from '../dance-events.interface';
import { DanceEventCoHostStatus } from '../../../enums';

export interface DanceEventCoHostsInterface {
  danceManager: DanceManagerInterface;
  danceEvent: DanceEventsInterface;
  danceManagerId: string;
  danceEventId: string;
  status: DanceEventCoHostStatus;
}
