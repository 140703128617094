<ng-container *transloco="let t">
  <div class="flex-column gap-15 p2">
    <span>{{ t('DANCE_EVENT.NO_TICKET_AVAILABLE_TEXT') }}</span>
    <div>
      <span>{{ t('DANCE_EVENT.MORE_INFORMATION_TEXT') }}
        <a *ngIf="danceEvent.url" [href]="danceEvent.url" class="tickets-link" target="_blank">{{ t('GENERIC_WRAPPER.HERE_LINK') }}.</a>
        <a *ngIf="!danceEvent.url" [routerLink]="['/', '@' + danceSchool.urlName]" class="tickets-link" target="_blank">{{ t('GENERIC_WRAPPER.HERE_LINK') }}.</a>
      </span>
    </div>
    <span>{{ t('DANCE_EVENT.CONTACT_INFORMATION_TEXT') }}</span>
    <span><mat-icon>call</mat-icon>&nbsp;&nbsp;({{danceSchool.phonePrefix }})&nbsp;{{ danceSchool.phoneNumber }}{{ danceSchool.phonePrefixAfter ?? '' }}</span>
    <span *ngIf="danceSchool.email"><mat-icon>email</mat-icon>&nbsp;&nbsp;{{ danceSchool.email }}</span>
    <df-shared-lib-button [buttonLabel]=" t('GENERIC_WRAPPER.CHAT')" (buttonClicked)="openChatWithDanceSchool()" [matIconName]="'chat'" style="max-width: 150px"></df-shared-lib-button>
  </div>
</ng-container>
