import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DanceManagerPayoutHttpService } from '../http-services/dance-manager-payout-http.service';
import { DanceManagerPayoutInterface } from '@platri/df-common-core';
import { DanceManagerPayoutDetailInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class DanceManagerPayoutStateService {
  #payoutsSubject$ = new BehaviorSubject<DanceManagerPayoutInterface[]>([]);
  payoutsSubject$ = this.#payoutsSubject$.asObservable();

  #payoutDetailSubject$ = new BehaviorSubject<DanceManagerPayoutDetailInterface>(null);
  payoutDetailSubject$ = this.#payoutDetailSubject$.asObservable();

  #loadingSubject$ = new BehaviorSubject<boolean>(false);
  loadingSubject$ = this.#loadingSubject$.asObservable();

  constructor(private readonly danceManagerPayoutHttpService: DanceManagerPayoutHttpService) {}

  sendPayouts(data: DanceManagerPayoutInterface[]): void {
    this.#payoutsSubject$.next(data);
  }

  sendPayoutDetail(data: DanceManagerPayoutDetailInterface): void {
    this.#payoutDetailSubject$.next(data);
  }

  sendLoading(loading: boolean): void {
    this.#loadingSubject$.next(loading);
  }

  getAllByDanceManagerId(danceManagerId: string): void {
    this.sendLoading(true);
    this.danceManagerPayoutHttpService.getAllByDanceManagerId(danceManagerId).subscribe({
      next: (result) => {
        this.sendPayouts(result);
        this.sendLoading(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoading(false);
      }
    });
  }

  getOneByDanceManagerIdAndDanceManagerPayoutId(danceManagerId: string, danceManagerPayoutId: string): void {
    this.sendLoading(true);
    this.danceManagerPayoutHttpService.getByDanceManagerIdAndDanceManagerPayoutId(danceManagerId, danceManagerPayoutId).subscribe({
      next: (result) => {
        this.sendPayoutDetail(result);
        this.sendLoading(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoading(false);
      }
    });
  }

  syncByDanceManagerId(danceManagerId: string): void {
    this.sendLoading(true);
    this.danceManagerPayoutHttpService.syncByDanceManagerId(danceManagerId).subscribe({
      next: () => {
        this.getAllByDanceManagerId(danceManagerId);
        this.sendLoading(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoading(false);
      }
    });
  }
}
