<ng-container *transloco="let t">
  <div class="fifty-fifty-view">
    <div class="register-container flex-column">
      <h4 class="mat-headline-3" style="text-align: center; margin-bottom: 30px; margin-top: 30px;">{{ t('GENERIC_WRAPPER.WELCOME') }}!</h4>
      <div>
        <elab-register-form></elab-register-form>
      </div>
    </div>
  </div>
</ng-container>
