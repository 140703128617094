import { AfterViewInit, Component, Inject, Input, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Files, ImageService } from '../../services';

@Component({
  selector: 'df-shared-lib-image-input',
  templateUrl: './crop-multiple-images-dialog.component.html',
  styleUrls: ['./crop-multiple-images-dialog.component.scss'],
})
export class CropMultipleImagesDialogComponent implements AfterViewInit {
  @Input() resizeToHeight = 512;
  @Input() resizeToWidth = 1024;

  fileAndBase64: { file: File; base64: string }[] = [];
  files: Files[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { files: File[]; aspectRatio?: number },
    private readonly imageService: ImageService,
    private readonly dialogRef: MatDialogRef<CropMultipleImagesDialogComponent>,
    private readonly renderer: Renderer2
  ) {}

  imageCropped(event: ImageCroppedEvent, index: number, file: File) {
    if (this.fileAndBase64[index]?.base64) {
      this.fileAndBase64[index].base64 = event.base64;
    } else {
      this.fileAndBase64.push({ file: file, base64: event.base64 });
    }
  }

  ngAfterViewInit() {
    const moveDiv = document.querySelector('.move');
    const icon = this.renderer.createElement('mat-icon');
    this.renderer.appendChild(icon, this.renderer.createText('open_with'));
    this.renderer.addClass(icon, 'material-icons-round');
    this.renderer.addClass(icon, 'mr1');
    const div = this.renderer.createElement('div');
    const span = this.renderer.createElement('span');
    this.renderer.appendChild(
      span,
      this.renderer.createText('PULL TO REPOSITION')
    );
    div.append(icon);
    div.append(span);
    div.id = 'text';
    moveDiv.appendChild(div);
  }

  onCropClick() {
    this.fileAndBase64.forEach((fileAndBase64) => {
      this.files.push({
        fileName: fileAndBase64.file.name,
        base64: fileAndBase64.base64,
      });
    });
    this.dialogRef.close(this.files);
  }
}
