import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ContactUrlDirectiveModule, EventTicketShoppingCardModule, GoogleAddressSearchAutocompleteModule, PipesModule, TicketCheckoutPaymentModule } from '@platri/dfx-angular-core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EventTicketsCheckoutPage } from './event-tickets-checkout.page';
import { EventTicketOrderSummaryModule } from '../../components/event-ticket-order-summary/event-ticket-order-summary.module';
import {TranslocoDirective} from "@jsverse/transloco";

const routes = [
  {
    path: '',
    component: EventTicketsCheckoutPage,
  },
];

@NgModule({
  declarations: [EventTicketsCheckoutPage],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatDividerModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        PipesModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        GoogleAddressSearchAutocompleteModule,
        MatRippleModule,
        MatProgressSpinnerModule,
        EventTicketShoppingCardModule,
        EventTicketOrderSummaryModule,
        TicketCheckoutPaymentModule,
        ContactUrlDirectiveModule,
        TranslocoDirective,
    ],
})
export class EventTicketsCheckoutPageModule {}
