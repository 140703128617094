import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthStateService, dfAppRoute, getSubscription, TransferDialogComponent, TransferTypeEnum, UserTicketHttpService } from '@platri/dfx-angular-core';
import { TicketTypeEnum, UsersInterface, UserTicketInterface, UserTicketStatusEnum } from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-app-inventory-ticket-detail',
  templateUrl: './inventory-ticket-detail.page.html',
  styleUrls: ['./inventory-ticket-detail.page.scss']
})
export class InventoryTicketDetailPage implements OnInit, OnDestroy {
  userTickets: UserTicketInterface[] = [];
  
  currentUserTicketIndex = 0;
  currentUserTicket: UserTicketInterface = null;
  currentUser: UsersInterface;
  ticketOrderIds = [];
  freeTicketIds = [];

  routerParams!: Params;
  eventIdFromParam: string;

  isLoadingUserTickets = false;
  hasFailedLoadingUserTicketsNoConnection = false;
  
  subscriptions = new Subscription();
  
  userTicketStatusEnum = UserTicketStatusEnum;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthStateService,
    private readonly userTicketHttpService: UserTicketHttpService,
    private readonly dialog: MatDialog,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.eventIdFromParam = this.routerParams['eventId'];

    if (!this.eventIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoadingUserTickets = true;
    this.hasFailedLoadingUserTicketsNoConnection = false;
    this.subscriptions.add(
      this.userTicketHttpService.getAllByCurrentUserAndDanceEventId(this.eventIdFromParam, true).subscribe(userTickets => {
        if (userTickets.length === 0) {
          this.router.navigate(['/inventory']);
        }
        this.userTickets = userTickets;
        this.mapUserTickets();
        this.isLoadingUserTickets = false;
      }, error => {
        console.log(error);
        this.isLoadingUserTickets = false;
        if (error.status === 0) {
          this.hasFailedLoadingUserTicketsNoConnection = true;
        }
        this.router.navigate(['/inventory']);
      })
    );
  }

  mapUserTickets(): void {
    this.currentUserTicket = this.userTickets[0];
    for (const userTicket of this.userTickets) {
      if (userTicket.ticket.ticketType === TicketTypeEnum.PAID) {
        const found = this.ticketOrderIds.some((value) => value === userTicket.ticketOrderId);
        if (!found) {
          this.ticketOrderIds.push(userTicket.ticketOrderId);
        }
      }
      if (userTicket.ticket.ticketType === TicketTypeEnum.FREE && userTicket.status !== UserTicketStatusEnum.INVALID) {
        this.freeTicketIds.push(userTicket.id);
      }
    }
  }

  onBackButtonClick(): void {
    this.router.navigate(['inventory']);
  }

  onLeftButtonClick(): void {
    this.currentUserTicketIndex -= 1;
    this.currentUserTicket = this.userTickets[this.currentUserTicketIndex];
  }

  onRightButtonClick(): void {
    this.currentUserTicketIndex += 1;
    this.currentUserTicket = this.userTickets[this.currentUserTicketIndex];
  }

  downloadTickets(ticketOrderId: string): void {
    const ticketWindow = window.open();
    ticketWindow.document.write(this.translocoService.translate('USER.LOADING_TICKET') + '...');

    this.userTicketHttpService
      .downloadTickets(
        this.currentUserTicket.ticket.danceEvent.id,
        ticketOrderId
      )
      .subscribe({
        next: (blob) => {
          const url = URL.createObjectURL(blob);
          ticketWindow.location.href = url;
        },
        error: (err) => {
          console.error(err);
          ticketWindow.close();
        },
      });
  }

  downloadFreeUserTickets(): void {
    const ticketWindow = window.open(); //OPEN WINDOW FIRST ON SUBMIT THEN POPULATE PDF
    ticketWindow.document.write(this.translocoService.translate('USER.LOADING_TICKET') + '...');

    this.userTicketHttpService
      .downloadTickets(
        this.currentUserTicket.ticket.danceEvent.id,
        undefined,
        this.freeTicketIds
      )
      .subscribe({
        next: (blob) => {
          const url = URL.createObjectURL(blob);
          ticketWindow.location.href = url;
        },
        error: (err) => {
          console.error(err);
          ticketWindow.close();
        },
      });
  }

  openTransferTicketDialog(): void {
    const dialogRef = this.dialog.open(TransferDialogComponent, {
      panelClass: 'responsive-dialog',
      data: {
        transferType: TransferTypeEnum.TICKET
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      const dialogResult = result;
      if (dialogResult) {
        this.userTicketHttpService.transferById(this.currentUserTicket.id, dialogResult.selectedUser.id).subscribe({
          next: () => {
            this.matSnackBar.open(this.translocoService.translate('USER.TRANSFER_TICKET_SNACKBAR'));
            this.loadData();
          }
        });
      }
    });
  }
  
}
