<ng-container *transloco="let t;">
  <h2 class="text-center">
    <span (click)="navigateToEvent()" class="link">Event Tickets </span>
    <span>/ Create</span>
  </h2>

  <ng-container *ngIf="parentFormGroup">
    <form>
      <df-custom-stepper (submit)="submit()" #stepper [linear]="true">
        <cdk-step
          label="{{t('DANCE_MANAGER_EVENT.EVENT_TICKETS_SETTINGS_LABEL')}}"
          errorMessage="Some settings are required"
          [stepControl]="ticketSettingsFormGroup"
        >
          <df-event-ticket-settings-step
            [danceEvents]="danceEvents"
            [passedFormGroup]="ticketSettingsFormGroup"
          >
          </df-event-ticket-settings-step>
        </cdk-step>
        <cdk-step
          label="{{t('DANCE_MANAGER_EVENT.EVENT_TICKET_PRICING_LABEL')}}"
          errorMessage="Some pricing informations are missing"
          [stepControl]="pricingFormGroup"
        >
          <df-event-ticket-pricing-step
            [passedFormGroup]="pricingFormGroup"
          ></df-event-ticket-pricing-step>
        </cdk-step>
        <cdk-step
          label="{{t('DANCE_MANAGER_EVENT.TICKET_SALES_SETTINGS_LABEL')}}{{t('DANCE_MANAGER_EVENT.OPTIONAL')}}"
          errorMessage="Some settings are required"
          [optional]="false"
          [stepControl]="salesSettingsFormGroup"
        >
          <df-event-ticket-sales-settings-step
            [passedFormGroup]="salesSettingsFormGroup"
            [dfmUserGroups]="dfmUserGroups"
          ></df-event-ticket-sales-settings-step>
        </cdk-step>
        <cdk-step
          label="{{t('DANCE_MANAGER_EVENT.EVENT_TICKET_PRICING_LABEL')}}"
          [stepControl]="parentFormGroup"
          errorMessage="Some settings are required"
        >
          <df-event-ticket-submit-step
            [danceEvent]="danceEvent"
            [dfmUserGroups]="dfmUserGroups"
            [passedFormGroup]="parentFormGroup"
          ></df-event-ticket-submit-step>
        </cdk-step>
      </df-custom-stepper>
    </form>
  </ng-container>
</ng-container>
