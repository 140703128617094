export class GenerateStrings {

  static randomNumericString(length: number = 10): string {
    let randomNumericString = '';
    const characters = '0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      randomNumericString += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return randomNumericString;
  }

}
