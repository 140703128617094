<ng-container *transloco="let t">
  <div class="filter">
    <div class="align-center filter-header">
      <span class="filter-title">{{ t('SEARCH.SEARCH_FILTER_DIALOG.TITLE') }}</span>
      <div class="align-center filter-cancel-button" (click)="closeDialogWithoutSave()">
        <mat-icon style="transform: scale(1.2)">close</mat-icon>
      </div>
    </div>
  
    <div class="flex-column filter-box">
      <!-- Events | Festivals -->
      <ng-container *ngIf="filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS || filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.FESTIVALS">
        <!-- Dance Event Categories -->
        <div *ngIf="filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS" class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.CATEGORY') }}</h2>
          <div class="flex-row-wrap wrap-gap">
            <div
              *ngFor="let category of danceEventCategoriesEnumValues"
              (click)="toggleDanceEventCategory(category)"
              [class]="isDanceEventCategorySelected(category) ? 'custom-category-chips selected' : 'custom-category-chips'"
            >
              {{ t('GENERIC_WRAPPER.EVENT_CATEGORIES.' + category) }}
            </div>
          </div>
        </div>
        <mat-divider  *ngIf="filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.EVENTS" class="divider-margin"></mat-divider>
        
        <!-- Tanzstile -->
        <div class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h2>
          <div class="flex-column" style="gap: 20px">
            <div class="flex-row-wrap wrap-gap">
              <div *ngFor="let danceStyle of FILTERED_DANCE_STYLES" class="custom-category-chips" (click)="toggleDanceStyle(danceStyle.translationKey)" [class.selected]="isDanceStyleSelected(danceStyle.translationKey)">
                {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
              </div>
              <ng-container *ngIf="showAllDanceStyles">
                <div *ngFor="let danceStyle of NON_FILTERED_DANCE_STYLES " class="custom-category-chips" (click)="toggleDanceStyle(danceStyle.translationKey)" [class.selected]="isDanceStyleSelected(danceStyle.translationKey)">
                  {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
                </div>
              </ng-container>
            </div>
            <button (click)="showAllDanceStyles = !showAllDanceStyles" class="show-more-button">
              {{ showAllDanceStyles ? t('GENERIC_WRAPPER.SHOW_LESS') : t('GENERIC_WRAPPER.SHOW_MORE') }}
              <mat-icon>{{showAllDanceStyles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
            </button>
          </div>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Musikstile -->
        <div class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.MUSIC_STYLES_TEXT') }}</h2>
          <div class="flex-column" style="gap: 20px">
            <div class="flex-row-wrap wrap-gap">
              <div *ngFor="let musicStyle of FILTERED_MUSIC_STYLES" class="custom-category-chips" (click)="toggleEventMusicStyle(musicStyle.translationKey)" [class.selected]="isEventMusicStyleSelected(musicStyle.translationKey)">
                {{t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase()) }}
              </div>
              <ng-container *ngIf="showAllMusicStyles">
                <div *ngFor="let musicStyle of NON_FILTERED_MUSIC_STYLES " class="custom-category-chips" (click)="toggleEventMusicStyle(musicStyle.translationKey)" [class.selected]="isEventMusicStyleSelected(musicStyle.translationKey)">
                  {{t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase()) }}
                </div>
              </ng-container>
            </div>
            <button (click)="showAllMusicStyles = !showAllMusicStyles" class="show-more-button">
              {{ showAllMusicStyles ? t('GENERIC_WRAPPER.SHOW_LESS') : t('GENERIC_WRAPPER.SHOW_MORE') }}
              <mat-icon>{{ showAllMusicStyles ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
            </button>
          </div>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Sort Type -->
        <div class="flex-column" style="margin-bottom: 16px;">
          <h2 class="filter-label">{{ t('SEARCH.SEARCH_FILTER_DIALOG.SORT_TYPE') }}</h2>
          <mat-radio-group [formControl]="filterDialogDataInterface.filterFormGroup.controls.eventsFilter.controls.eventSortType" class="flex-column">
            <mat-radio-button value="START_DATE">{{  t('GENERIC_WRAPPER.DATE') }}</mat-radio-button>
            <mat-radio-button value="DISTANCE">{{ t('SEARCH.SEARCH_FILTER_DIALOG.SORT_TYPE_DISTANCE') }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Name -->
        <div class="flex-column">
          <h2 class="filter-label">{{ t('GENERIC_WRAPPER.NAME') }}</h2>
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="filterDialogDataInterface.filterFormGroup.controls.eventsFilter.controls.eventName"
              type="text"
              placeholder="{{ t('SEARCH.SEARCH_FILTER_DIALOG.NAME_SEARCH') }}"
            >
          </mat-form-field>
        </div>
      </ng-container>
      
      <!-- Courses -->
      <ng-container *ngIf="filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.COURSES">
        <!-- Tanzstile -->
        <div class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h2>
          <div class="flex-column" style="gap: 20px">
            <div class="flex-row-wrap wrap-gap">
              <div *ngFor="let danceStyle of FILTERED_DANCE_STYLES" class="custom-category-chips" (click)="toggleDanceStyle(danceStyle.translationKey)" [class.selected]="isDanceStyleSelected(danceStyle.translationKey)">
                {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
              </div>
              <ng-container *ngIf="showAllDanceStyles">
                <div *ngFor="let danceStyle of NON_FILTERED_DANCE_STYLES " class="custom-category-chips" (click)="toggleDanceStyle(danceStyle.translationKey)" [class.selected]="isDanceStyleSelected(danceStyle.translationKey)">
                  {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
                </div>
              </ng-container>
            </div>
            <button (click)="showAllDanceStyles = !showAllDanceStyles" class="show-more-button">
              {{ showAllDanceStyles ? t('GENERIC_WRAPPER.SHOW_LESS') : t('GENERIC_WRAPPER.SHOW_MORE') }}
              <mat-icon>{{showAllDanceStyles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
            </button>
          </div>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Course Level -->
        <div class="flex-column">
          <h2 class="filter-label">{{ t('GENERIC_WRAPPER.COURSE_LEVEL') }}</h2>
          <df-shared-lib-level-slider-material
            [showRange]="false"
            [showLevels]="true"
            [fromLevel]="filterDialogDataInterface.filterFormGroup.controls.coursesFilter.controls.courseStartDifficulty.value"
            [step]="10"
            [toLevel]="filterDialogDataInterface.filterFormGroup.controls.coursesFilter.controls.courseEndDifficulty.value"
            (setLevelEvent)="onCourseLevelSelected($event)"
            style="max-width: 486px;"
          ></df-shared-lib-level-slider-material>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Sort Type -->
        <div class="flex-column" style="margin-bottom: 16px;">
          <h2 class="filter-label">{{ t('SEARCH.SEARCH_FILTER_DIALOG.SORT_TYPE') }}</h2>
          <mat-radio-group [formControl]="filterDialogDataInterface.filterFormGroup.controls.coursesFilter.controls.courseSortType" class="flex-column">
            <mat-radio-button value="START_DATE">{{  t('GENERIC_WRAPPER.DATE') }}</mat-radio-button>
            <mat-radio-button value="DISTANCE">{{ t('SEARCH.SEARCH_FILTER_DIALOG.SORT_TYPE_DISTANCE') }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Name -->
        <div class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.NAME') }}</h2>
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="filterDialogDataInterface.filterFormGroup.controls.coursesFilter.controls.courseName"
              type="text"
              placeholder="{{ t('SEARCH.SEARCH_FILTER_DIALOG.NAME_SEARCH') }}"
            >
          </mat-form-field>
        </div>
      </ng-container>
      
      <!-- Studios -->
      <ng-container *ngIf="filterDialogDataInterface.filterFormGroup.controls.searchCategory.value === SearchCategoryEnum.STUDIOS">
        <!-- Dance Manager Categories -->
        <div class="flex-column" style="margin-bottom: 20px;">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.CATEGORY') }}</h2>
          <div class="flex-row-wrap wrap-gap">
            <div
              *ngFor="let category of danceManagerCategoriesEnumValues"
              (click)="toggleDanceManagerCategory(category)"
              [class]="isDanceManagerCategorySelected(category) ? 'custom-category-chips selected' : 'custom-category-chips not-selected'"
            >
              {{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category) }}
            </div>
          </div>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Tanzstile -->
        <div class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</h2>
          <div class="flex-column" style="gap: 20px">
            <div class="flex-row-wrap wrap-gap">
              <div *ngFor="let danceStyle of FILTERED_DANCE_STYLES" class="custom-category-chips" (click)="toggleDanceStyle(danceStyle.translationKey)" [class.selected]="isDanceStyleSelected(danceStyle.translationKey)">
                {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
              </div>
              <ng-container *ngIf="showAllDanceStyles">
                <div *ngFor="let danceStyle of NON_FILTERED_DANCE_STYLES " class="custom-category-chips" (click)="toggleDanceStyle(danceStyle.translationKey)" [class.selected]="isDanceStyleSelected(danceStyle.translationKey)">
                  {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
                </div>
              </ng-container>
            </div>
            <button (click)="showAllDanceStyles = !showAllDanceStyles" class="show-more-button">
              {{ showAllDanceStyles ? t('GENERIC_WRAPPER.SHOW_LESS') : t('GENERIC_WRAPPER.SHOW_MORE') }}
              <mat-icon>{{showAllDanceStyles ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
            </button>
          </div>
        </div>
        <mat-divider class="divider-margin"></mat-divider>
        
        <!-- Name -->
        <div class="flex-column">
          <h2 class="filter-label">{{  t('GENERIC_WRAPPER.NAME') }}</h2>
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="filterDialogDataInterface.filterFormGroup.controls.studiosFilter.controls.studioName"
              type="text"
              placeholder="{{ t('SEARCH.SEARCH_FILTER_DIALOG.NAME_SEARCH') }}"
            >
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  
    <div class="filter-footer align-start-center">
      <div class="reset-filter-text align-center" (click)="resetFilterForm()">
        <span>{{ t('GENERIC_WRAPPER.CLEAR_ALL') }}</span>
      </div>
      <df-shared-lib-button
        [height]="'40px'"
        [materialButtonStyleType]="'flat'"
        [buttonLabel]="t(this.filterButtonTranslateLabel)"
        (buttonClicked)="closeDialogWithSave()"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
