import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import {
  AppointmentCardModule,
  DanceEventPreviewCardModule,
  DfFaqsCardModule,
  DfGalleryModule,
  DfLocationsCardModule,
  DfmLocationsWrapperPageModule,
  PassSubscriptionCheckoutListItemModule,
  PipesModule,
  SharedEmptyPageModule,
  ShareModule,
  TeamMemberCardModule,
  TicketOrderResolver,
  TranslocoStateConst
} from '@platri/dfx-angular-core';
import { MatExpansionModule } from '@angular/material/expansion';
import { DanceManagerPublicPage } from './dance-manager-public.page';
import { DanceManagerCourseListModule, DanceManagerHeaderImageModule, DanceManagerHeaderModule, DanceManagerPassesListItemModule, DanceManagerPassesListModule } from '../../components';
import { RouterModule, Routes } from '@angular/router';
import { DanceManagerPassesPage, DanceManagerPassesPageModule } from '../dance-manager-passes';
import { DanceManagerPassesCheckoutPage, DanceManagerPassesCheckoutPageModule } from '../dance-manager-passes-checkout';
import { DanceManagerPassesCheckoutCompletePage, DanceManagerPassesCheckoutCompletePageModule } from '../dance-manager-passes-checkout-complete';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DanceManagerPublicPage,
  },
  {
    path: 'passes',
    component: DanceManagerPassesPage
  },
  {
    path: 'passes/checkout',
    component: DanceManagerPassesCheckoutPage
  },
  {
    path: 'passes/checkout-complete/:ticketOrderId',
    resolve: {
      targetObject: TicketOrderResolver,
    },
    component: DanceManagerPassesCheckoutCompletePage
  }
];

@NgModule({
  declarations: [DanceManagerPublicPage],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatSidenavModule,
      MatTabsModule,
      MatCardModule,
      MatChipsModule,
      MatIconModule,
      MatButtonModule,
      MatMenuModule,
      DfLocationsCardModule,
      ShareModule,
      DanceManagerHeaderImageModule,
      MatDividerModule,
      AppointmentCardModule,
      TeamMemberCardModule,
      MatExpansionModule,
      MatTabsModule,
      DfGalleryModule,
      DanceEventPreviewCardModule,
      PipesModule,
      DanceManagerPassesListItemModule,
      DanceManagerHeaderModule,
      DanceManagerHeaderImageModule,
      DfFaqsCardModule,
      SharedEmptyPageModule,
      DfmLocationsWrapperPageModule,
      DanceManagerCourseListModule,
      DanceManagerPassesListModule,
      DanceManagerPassesPageModule,
      DanceManagerPassesCheckoutPageModule,
      DanceManagerPassesCheckoutCompletePageModule,
      PassSubscriptionCheckoutListItemModule,
      TranslocoDirective
    ],
  exports: [DanceManagerPublicPage]
}) 
export class DanceManagerPublicPageModule {
  constructor() {
    console.log('DanceManagerPublicPageModule initialized');
    TranslocoStateConst.push({ scope: 'df-dance-manager', alias: 'DANCE_MANAGER' });
  }
}
