<ng-container *transloco="let t">
  <div class="flex-container">
    <form class="form flex-container-centered-content" [formGroup]="resetPasswordFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyResetPassword">
      <h4 class="mat-headline-3" style="text-align: center; margin-bottom: 16px">{{ t('GENERIC_WRAPPER.RESET_PASSWORD') }}</h4>
      <p style="margin-bottom: 16px">{{ t('GENERIC_WRAPPER.RESET_PASSWORD_TITLE') }}</p>
      <div class="flex-container-row">
        <div class="form-step-container flex-container-column">
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.EMAIL') }}</mat-label>
            <input id="email-input" matInput type="text" [formControl]="resetPasswordFormGroup.controls.email" placeholder="max.muster@danceflavors.com" data-cy="email-input">
            <mat-error *ngIf="resetPasswordFormGroup.controls.email.hasError('pattern')">
              {{ t('GENERIC_WRAPPER.PLEASE_INSERT_A_CORRECT_EMAIL') }}
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.PASSWORD') }}</mat-label>
            <input id="password-input" matInput [formControl]="resetPasswordFormGroup.controls.password" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="password-input">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.CONFIRM_PASSWORD') }}</mat-label>
            <input id="confirm-password-input" matInput [formControl]="resetPasswordFormGroup.controls.confirmPassword" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="confirm-password-input">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="resetPasswordFormGroup.controls.confirmPassword.errors?.['mustMatch']">
              {{ t('GENERIC_WRAPPER.PASSWORDS_DO_NOT_MATCH') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    
      <div style="width: 100%">
        <button id="reset-password-button" [disabled]="isWaitingForResetPasswordResponse" [class.spinner]="isWaitingForResetPasswordResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="submit-button">{{ t('GENERIC_WRAPPER.SAVE') }}</button>
      </div>
  
      <div style="height: 60px">
        <p class="reset-password-error" *ngIf="resetPasswordFailedWrongData" data-cy="wrong-data-error">{{ t('GENERIC_WRAPPER.PROVIDED_DATA_WRONG') }}</p>
        <p class="reset-password-error" *ngIf="resetPasswordFailedNoConnection" data-cy="connection-error">{{ t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED') }}</p>
        <p class="reset-password-error" *ngIf="resetPasswordFailedInternalServer" data-cy="internal-server-error">{{ t('GENERIC_WRAPPER.SERVER_ERROR') }}</p>
        <p class="reset-password-error" *ngIf="resetPasswordFormGroup.controls.email.hasError('whitespace')" data-cy="internal-server-error">{{ t('GENERIC_WRAPPER.WHITESPACE_NOT_ALLOWED_EMAIL') }}</p>
      </div>
      
      
    </form>
    
    <div id="successful-reset-password" *ngIf="hasSuccessfullyResetPassword" class="flex-container-centered-content" data-cy="successful-box">
      <p>{{ t('GENERIC_WRAPPER.RESET_PASSWORD_SUCCESSFUL') }}!</p>
      <button id="navigate-to-login-button" mat-raised-button color="primary" type="button" (click)="routeToLogin()" data-cy="back-to-login-button">{{ t('GENERIC_WRAPPER.GO_TO_LOGIN') }}</button>
    </div>
    
  </div>
  
  <p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">{{ t('GENERIC_WRAPPER.FATAL_ERROR_LOADING_DATA') }}</p>
</ng-container>
