<ng-container *transloco="let t">
  <div
    style="background: #ffffff 0 0 no-repeat padding-box"
    class="w-100-children h-100 flex-column"
  >
    <div class="title-div pt2">
      <h1 class="p3 m0">
        {{ t('DANCE_MANAGER_EVENT.EVENT_TICKETS') }}
      </h1>
      <button
        mat-stroked-button
        class="add-class-button"
        (click)="navigateToCreateTicketPage()"
        [disabled]="events && events.length===0"
      >
        <mat-icon
          [svgIcon]="'df-add'"
          style="vertical-align: middle"
        ></mat-icon>
        <span
          style="vertical-align: middle"
          [matTooltip]="(events && events.length===0 ? 'DANCE_MANAGER_EVENT.CREATE_AN_EVENT' : '')"
        >
          {{ t('DANCE_MANAGER_EVENT.NEW_TICKET') }}
        </span>
      </button>
    </div>

    <div class="mt4 p3">
      <mat-accordion class="flex-column" style="gap: 1rem;">
        <mat-expansion-panel *ngFor="let event of events">
          <mat-expansion-panel-header>
            <mat-panel-description>
              <div class="w-100 panel-description flex-row align-space-between">
                <div class="flex-column-center">
                  <span
                    ><strong style="font-size: 18px"
                      >{{event.name}}</strong
                    ></span
                  >
                  <span
                    *ngIf="event.tickets && event.tickets.length > 0"
                    class="details"
                    matRipple
                    (click)="navigateToDetailsPage(event.id); $event.stopPropagation()"
                    >Show Details</span
                  >
                </div>
                <button
                  mat-stroked-button
                  class="add-ticket-button"
                  color="white"
                  (click)="$event.stopPropagation()"
                  [routerLink]="['events', event.id, 'create']"
                >
                  <mat-icon class="material-icons-round add-button"
                    >add</mat-icon
                  >
                  {{ t('DANCE_MANAGER_EVENT.NEW_TICKET') }}
                </button>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <table
              class="w-100"
              mat-table
              matSort
              [dataSource]="event.tickets"
              fixedLayout="true"
            >
              <ng-container matColumnDef="ticketName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{('DANCE_MANAGER_EVENT.TICKET_NAME')}}
                </th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
              </ng-container>
              <ng-container matColumnDef="soldQuantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{('DANCE_MANAGER_EVENT.SOLD')}}
                </th>
                <td mat-cell *matCellDef="let row">
                  {{row.ticketType === ticketTypeEnum.FREE ? row.quantity + ' '
                  + ('DANCE_MANAGER_EVENT.INVITED') :
                  row.soldQuantity + '/' + row.quantity}}
                </td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{('DANCE_MANAGER_EVENT.TICKET_PRICE')}}
                </th>
                <td mat-cell *matCellDef="let row">
                  {{row.ticketType === row.FREE ? '' : row.price | centsToCurrencyString: 'EUR'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="saleStart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ t('DANCE_MANAGER_EVENT.SALE_START') }}
                </th>
                <td mat-cell *matCellDef="let row">
                  {{row.saleStart? (row.saleStart | dateTimezoned : 'UTC': currentLang : row.eventDuration) : '-'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="saleEnd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ t('DANCE_MANAGER_EVENT.SALE_END') }}
                </th>
                <td mat-cell *matCellDef="let row">
                  {{row.saleEnd? (row.saleEnd | dateTimezoned : 'dd.MM.yyyy' :
                  'UTC') : '-'}}
                </td>
              </ng-container>
              <ng-container matColumnDef="ticketControls">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  style="align-items: center !important; width: 135px"
                >
                  <!-- <mat-slide-toggle disabled>
                  </mat-slide-toggle> -->
                  <button
                    [disabled]="row.ticketType === ticketTypeEnum.FREE"
                    mat-icon-button
                    [matTooltip]="t('mat-icons.edit')"
                    (click)="navigateToEditTicketPage(row.id)"
                  >
                    <mat-icon class="material-icons-round edit-button"
                      >edit</mat-icon
                    >
                  </button>
                  <!-- <button
                    mat-icon-button
                    disabled
                    [matTooltip]="t('mat-icons.delete')"
                    (click)="deleteTicket(row,event)"
                  >
                    <mat-icon class="material-icons-round delete-button"
                      >delete</mat-icon
                    >
                  </button> -->
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</ng-container>
