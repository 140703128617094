import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CoursesStateService,
  DanceSchoolStateService,
  getSubscription
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseInterface } from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { DanceSchool } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'dfm-course-page',
  templateUrl: './dfm-course.page.html',
  styleUrls: ['./dfm-course.page.scss']
})
export class DfmCoursePage implements OnInit {
  @Input() danceSchool: DanceSchool;
  @Output() resetPagination: EventEmitter<void> = new EventEmitter<void>();
  
  courses: CourseInterface[] = [];
  
  subscriptions: Subscription = new Subscription();
  
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly danceSchoolService: DanceSchoolStateService,
    public readonly coursesService: CoursesStateService,
  ) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.danceSchoolService.getAsyncCurrentDanceSchool(), this.onDanceSchoolChangeSuccessful.bind(this)));
    this.subscriptions.add(getSubscription(this.coursesService.coursesSubject$, this.onCoursesChange.bind(this)));
  }

  onDanceSchoolChangeSuccessful(danceSchool: DanceSchool): void {
    this.coursesService.getAllByDanceManagerIdForDmOverview(danceSchool.id, 1, 50);
  }
  
  onCoursesChange(courses: CourseInterface[]): void {
    if (courses?.length > 0) {
      this.courses = courses;
    }
  }
  
  reloadCourses(): void {
    this.onResetPagination();
    this.courses = [];
    this.coursesService.getAllByDanceManagerIdForDmOverview(this.danceSchool.id!, 1, 50);
  }
  
  onResetPagination(): void {
    this.resetPagination.emit();
  }

  navigateToCreatePage(): void {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }
}
