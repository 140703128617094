import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { DmOnboardingRequiredDialogComponent } from './dm-onboarding-required-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatDialogClose} from "@angular/material/dialog";
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DmOnboardingRequiredDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogClose,
    TranslocoDirective
  ],
  providers: [],
  exports: [DmOnboardingRequiredDialogComponent],
})
export class DmOnboardingRequiredDialogModule {}
