import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DfmUserGroupComponent } from './pages/app';
import { dfmUserGroupId } from '@platri/dfx-angular-core';

const routes: Routes = [
  {
    path: '',
    component: DfmUserGroupComponent,
  },
  {
    path: 'create',
    loadChildren: () => import('./pages/dfm-create-user-group-page/dfm-create-user.group-page.module').then((m) => m.DfmCreateUserGroupPageModule),
  },
  {
    path: 'edit/:' + dfmUserGroupId,
    loadChildren: () => import('./pages/dfm-edit-user-group-page/dfm-edit-user.group-page.module').then((m) => m.DfmEditUserGroupPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfmUserGroupAppRoutingModule {
  constructor() {
    console.log('FrontendDfmUserGroupAppRoutingModule initialized');
  }
}
