<ng-container *transloco="let t">
  <div class="flex-column align-space-between" style="gap: 1em;" [formGroup]="form">
    <!-- ticket name and price -->
    <div class="flex-row align-space-between w-100">
      <div [ngClass]="ticket.ticketSaleStatus !== ticketSaleStatusEnum.ON_SALE? 'disabled ticket-name': 'ticket-name'">
        {{ ticket.name }}
      </div>
      
      <span class="align-center" [ngClass]="ticket.ticketSaleStatus !== ticketSaleStatusEnum.ON_SALE? 'disabled price-label': 'price-label'">
        {{ ticket.price | centsToCurrencyString: 'EUR' }}
      </span>
    </div>
    <!-- sale date info -->
    <div *ngIf="ticket.ticketSaleStatus === ticketSaleStatusEnum.ON_SALE && ticket.saleEnd" class="flex-row w-100">
      <span class="sale-ends-label">{{ t('DANCE_EVENT.SALE_ENDS_ON') }}: {{ticket.saleEnd | dateTimezoned: 'UTC':currentLang:ticket.eventDuration }}</span>
    </div>
    <!-- description -->
    <div class="flex-row w-100" *ngIf="ticket.description">
      <div class="flex-column w-100">
        <div class="flex-column" style="gap: 0.5rem;" [class]="showAllDescription ? 'description' : ' description ellipsis-2'" [style.max-height]="showAllDescription ? 'none' : '200px'"  [ngStyle]="ticket.ticketSaleStatus !== ticketSaleStatusEnum.ON_SALE? { opacity: '38%' }: {}" #descriptionEl>
          <div style="width: 100%" [innerHTML]="ticket.description | dynamicTag"></div>
        </div>
        <a *ngIf="isDescriptionMoreThenTwoLines" class="show-more-text" (click)="showAllDescription = !showAllDescription">{{!showAllDescription ? t('GENERIC_WRAPPER.READ_MORE') : t('GENERIC_WRAPPER.READ_LESS') }}</a>
      </div>
    </div>
    <!-- regular amount selection-->
    <div class="flex-row-end-center w-100">
    <div *ngIf="ticket.ticketSaleStatus === ticketSaleStatusEnum.ON_SALE &&!ticket.sellAsFollowerLeader" class="row-center">
      <button (click)="changeValue(-1)" class="cursor-pointer" [ngClass]="currentQuantity.value > 0 ? 'quantity-button' : 'disabled-quantity-button'">
        <mat-icon>remove</mat-icon>
      </button>
      <span class="ml-10 mr-10">{{ currentQuantity.value }}</span>
      <button (click)="changeValue(1)" class="cursor-pointer" [ngClass]="currentQuantityCanBeIncreased ? 'quantity-button' : 'disabled-quantity-button'">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    </div>
    <!-- l/f amount selection -->
    <div *ngIf="ticket.ticketSaleStatus === ticketSaleStatusEnum.ON_SALE && ticket.sellAsFollowerLeader">
      <!--leader tickets-->
      <div class="row-space-between">
        <div class="col">
          <strong>{{ t('DANCE_EVENT.LEADER_TICKET') }}
            <mat-icon class="material-symbols" matTooltip="{{ t('DANCE_EVENT.LEADER_DESC') }}" matTooltipPosition="after">help</mat-icon>
          </strong>
        </div>
        <span *ngIf="!ticket.sellAsLeaderActive">{{ t('GENERIC_WRAPPER.NOT_AVAILABLE') }}</span>
        <div class="col">
          <div *ngIf="ticket.sellAsLeaderActive" class="flex-row-end-center">
            <button (click)="changeValue(-1, true, false)" class="cursor-pointer" [ngClass]="leaderQuantity.value > 0 ? 'quantity-button' : 'disabled-quantity-button'">
              <mat-icon>remove</mat-icon>
            </button>
            <span class="ml-10 mr-10">{{ leaderQuantity.value }}</span>
            <button (click)="changeValue(1, true, false)" class="cursor-pointer" [ngClass]="leaderQuantityCanBeIncreased ? 'quantity-button' : 'disabled-quantity-button'">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <!--follower tickets-->
      <div class="row-space-between" style="padding-top: 5px;">
        <div class="col">
          <strong>{{ t('DANCE_EVENT.FOLLOWER_TICKET') }}
            <mat-icon class="material-symbols" matTooltip="{{ t('DANCE_EVENT.FOLLOWER_DESC') }}" matTooltipPosition="after">help</mat-icon>
          </strong>
        </div>
        <span *ngIf="!ticket.sellAsFollowerActive">{{ t('GENERIC_WRAPPER.NOT_AVAILABLE') }}</span>
        <div class="col">
          <div *ngIf="ticket.sellAsFollowerActive" class="row-center">
            <button (click)="changeValue(-1, false, true)" class="cursor-pointer" [ngClass]="followerQuantity.value > 0 ? 'quantity-button' : 'disabled-quantity-button'"><mat-icon>remove</mat-icon></button>
            <span class="ml-10 mr-10">{{ followerQuantity.value }}</span>
            <button (click)="changeValue(1, false, true)" class="cursor-pointer" [ngClass]="followerQuantityCanBeIncreased ? 'quantity-button' : 'disabled-quantity-button'"><mat-icon>add</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
    <!-- not available -->
    <div *ngIf="ticket.ticketSaleStatus !== ticketSaleStatusEnum.ON_SALE" class="flex-row align-end sale-information-label">
      <span *ngIf="ticket.ticketSaleStatus === ticketSaleStatusEnum.SOLD_OUT">
        {{ t('GENERIC_WRAPPER.SOLD_OUT') }}
      </span>
      <span *ngIf="ticket.ticketSaleStatus === ticketSaleStatusEnum.UPCOMING_SALE">
        {{ t('DANCE_EVENT.SALE_STARTS') }}: <br />{{ticket.saleStart | dateTimezoned: 'UTC':currentLang:ticket.eventDuration }}
      </span>
      <span *ngIf="ticket.ticketSaleStatus === ticketSaleStatusEnum.PAST_SALE">
        {{ t('DANCE_EVENT.SALE_OVER') }}: <br />{{ticket.saleEnd | dateTimezoned: 'UTC':currentLang:ticket.eventDuration }}
      </span>
    </div>
  </div>
</ng-container>
