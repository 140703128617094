import { MessagesRoutingModule } from './messages-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatsModule } from '../components';
import { AuctionChat2Module } from '../pages';

@NgModule({
  declarations: [
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    ChatsModule,
    AuctionChat2Module,
    MessagesRoutingModule,
  ]
})
export class MessagesModule {
  constructor() {
    console.log('MessagesModule initialized');
  }
}
