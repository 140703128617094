import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmEmailPageComponent } from './confirm-email-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ConfirmEmailPageComponent],
    imports: [
      CommonModule,
      RouterModule.forChild([{
          path: '',
          component: ConfirmEmailPageComponent
      }]),
      MatProgressSpinnerModule,
      TranslocoDirective,
    ],
  exports: [ConfirmEmailPageComponent]
})
export class ConfirmEmailPageModule { }
