import { DmPassSubscriptionInterface, DmPassSubscriptionIntervalEnum } from '../../..';
import { DmPassSubscriptionTargetDto } from './dm-pass-subscription-target.dto';

export class CreateDmPassSubscriptionRequestDto implements DmPassSubscriptionInterface {
  danceManagerId: string;
  name: string;
  price: number;
  durationUnit: DmPassSubscriptionIntervalEnum;
  durationValue: number;
  isPaidMonthly: boolean;
  weeklyUsage: number;
  description: string;
  taxPercentage: number;
  targets: DmPassSubscriptionTargetDto[];
  
  constructor(args: CreateDmPassSubscriptionRequestDto) {
    if (args) {
      this.danceManagerId = args.danceManagerId;
      this.name = args.name;
      this.price = args.price;
      this.durationUnit = args.durationUnit;
      this.durationValue = args.durationValue;
      this.isPaidMonthly = args.isPaidMonthly;
      this.weeklyUsage = args.weeklyUsage;
      this.description = args.description;
      this.taxPercentage = args.taxPercentage;
      this.targets = args.targets;
    }
  }
}
