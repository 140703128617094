import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmUpgradeRequiredDialogComponent } from './dm-upgrade-required-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DmSubscriptionPriceTableCardModule } from '../dm-subscription-price-table-card';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DmUpgradeRequiredDialogComponent],
    imports: [
        CommonModule,
        MatIconModule,
        DmSubscriptionPriceTableCardModule,
        TranslocoDirective
    ],
  providers: [],
  exports: [DmUpgradeRequiredDialogComponent],
})
export class DmUpgradeRequiredDialogModule {}
