import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Address } from '@platri/df-common-shared-graphql';
import { LocationInterface } from '@platri/df-common-core';

export interface AddNewDmLocationDialogComponentInterface {
  location?: LocationInterface;
  title: string;
}

@Component({
  selector: 'df-shared-lib-add-new-dm-location-dialog',
  templateUrl: './add-new-dm-location-dialog.component.html',
  styleUrls: ['./add-new-dm-location-dialog.component.scss']
})
export class AddNewDmLocationDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: AddNewDmLocationDialogComponentInterface,
    private fb: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<AddNewDmLocationDialogComponent>
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      address: [null, Validators.required],
      isPublic: [false]
    });

    if (this.data.location) {
      this.formGroup.patchValue({
        ...this.data.location
      });
    }
  }

  get name() {
    return this.formGroup.get('name');
  }

  get address() {
    return this.formGroup.get('address');
  }

  get isPublic() {
    return this.formGroup.get('isPublic');
  }

  setSelectedAddress(address?: Address): void {
    if (address) {
      this.formGroup.controls.address.setValue(address);
    } else {
      this.formGroup.controls.address.setValue(null);
    }
  }

  add(): void {
    this.dialogRef.close(this.formGroup.getRawValue());
  }

  close(): void {
    this.dialogRef.close();
  }
}
