import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DfDmFollowContainerModule } from '../df-dm-follow-container';
import { DfSharedButtonModule } from '../buttons';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthStateService, FollowHttpService, TicketOrderHttpService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationUseCaseEnum, TicketOrderInterface, UsersInterface } from '@platri/df-common-core';
import { Observable, shareReplay, take } from 'rxjs';
import { dfDanceEventIdRouterParam } from '../../shared';
import { TranslocoDirective } from '@jsverse/transloco';

export interface PurchaseConfirmationTextInterface {
  title: string;
  message: string;
  messageParams: any;
  checkboxText?: string;
  navButton?: { label: string; navUrl: string };
  navUrlOnError?: string;
  downloadText?: string;
}

@Component({
  selector: 'df-purchase-confirmation',
  templateUrl: './purchase-confirmation.component.html',
  styleUrls: ['./purchase-confirmation.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCheckboxModule, DfDmFollowContainerModule, DfSharedButtonModule, TranslocoDirective]
})
export class PurchaseConfirmationComponent implements OnDestroy {
  @Input({ required: true }) confirmationText: PurchaseConfirmationTextInterface;
  @Input() ticketOrder?: TicketOrderInterface;
  @Input() danceManagerId?: string;

  currentUser$: Observable<UsersInterface>;
  sendNotifyToFollowers = true;

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly ticketOrderHttpService: TicketOrderHttpService, private readonly authService: AuthStateService, private readonly followHttpService: FollowHttpService) {
    this.currentUser$ = this.authService.getAsyncCurrentUser().pipe(shareReplay(1));
  }

  ngOnDestroy(): void {
    this.setNotificationSetting();
  }

  navigateToMyTickets(url: string): void {
    this.router.navigateByUrl(url);
  }

  downloadTicket(): void {
    const ticketWindow = window.open();
    ticketWindow.document.write('Loading...');

    this.ticketOrderHttpService.download(this.ticketOrder.id).subscribe({
      next: (blob) => {
        const url = URL.createObjectURL(blob);
        ticketWindow.location.href = url;
      },
      error: (err) => {
        console.error(err);
        ticketWindow.close();
      }
    });
  }

  private setNotificationSetting(): void {
    if (!this.sendNotifyToFollowers) {
      return;
    }

    const danceEventId = this.route.snapshot.params[dfDanceEventIdRouterParam];
    const useCase = NotificationUseCaseEnum.NOTIFY_FOLLOWERS_PARTICIPATE_EVENT;

    this.followHttpService
      .notifyFollowers(danceEventId, useCase)
      .pipe(take(1))
      .subscribe({
        next: (success) => {
          console.log('Notification sent successfully:', success);
        },
        error: (err) => {
          console.error('Notification failed:', err);
        }
      });
  }
}
