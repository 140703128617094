import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-shared-lib-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit, OnDestroy {
  @Input() name: string;
  metaTitle = 'DanceManager | Danceflavors';
  mobileButtons: string[] = ['telegram', 'messenger'];
  subscriptions = new Subscription();

  constructor(
    private title: Title,
    private meta: Meta,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly matSnackBar: MatSnackBar
  ) {
    this.matIconRegistry.addSvgIcon(
      `df_facebook_button`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/Facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `df_x_button`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/X.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `df_link_button`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/Link.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `df_mail_button`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/Mail outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `df_chat_button`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/Old chat.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `df_whatsapp_button`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/Whatsapp.svg'
      )
    );
  }

  ngOnInit(): void {
    //primary
    this.title.setTitle(this.metaTitle);
    this.meta.addTag({ name: 'title', content: this.metaTitle });
    this.meta.addTag({ name: 'description', content: '' });
    this.meta.addTag({
      name: 'image',
      content: 'https://upload.wikimedia.org/wikipedia/commons/3/3a/Cat03.jpg',
    });

    //facebook
    this.meta.addTag({ property: 'og:type', content: 'website' });
    this.meta.addTag({ property: 'og:title', content: this.metaTitle });
    this.meta.addTag({ property: 'og:desciption', content: 'danceManager' });
    this.meta.addTag({
      property: 'og:image',
      content: 'https://upload.wikimedia.org/wikipedia/commons/3/3a/Cat03.jpg',
    });

    //twitter
    this.meta.addTag({
      property: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.addTag({ property: 'twitter:title', content: this.metaTitle });
    this.meta.addTag({
      property: 'twitter:description',
      content: 'danceMagaer',
    });
    this.meta.addTag({
      property: 'twitter:image',
      content: 'https://upload.wikimedia.org/wikipedia/commons/3/3a/Cat03.jpg',
    });
  }

  ngOnDestroy(): void {
    this.title.setTitle('Danceflavors');
    this.meta.updateTag({ name: 'description', content: '' });
    this.subscriptions.unsubscribe();
  }
}
