import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantsListTableComponent } from './participants-list-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import {DfSharedButtonModule, PipesModule, SharedEmptyPageModule, StatisticsCardModule} from '@platri/dfx-angular-core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { StatusInfoDialogModule } from './status-info-dialog/status-info-dialog.module';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatChip} from "@angular/material/chips";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ParticipantsListTableComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        PipesModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatDividerModule,
        StatisticsCardModule,
        StatusInfoDialogModule,
        MatProgressSpinner,
        SharedEmptyPageModule,
        MatChip,
        TranslocoDirective,
        DfSharedButtonModule
    ],
  exports: [ParticipantsListTableComponent],
})
export class ParticipantsListTableModule {}
