import { File } from '../../..';

export class FacebookEventImportDto {
  name: string;
  description: string;
  startDate: Date;
  duration: number;
  imageUrl?: string ;
  imageFile?: File;
  address?: string;
}
