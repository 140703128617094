<div class="div-style">
  <span class="material-icons-round"> sentiment_dissatisfied </span>
  <h1>Error 404</h1>
  <p>
    <br />
    <br />
    I'm sorry but the dance floor is full, there
    <br />
    seems to be no dancing space here
    <br />
    <br />
    <a href="https://www.danceflavors.com">Go back to Danceflavors.com</a>
  </p>
</div>
