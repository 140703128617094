import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {debounceTime, take, takeUntil} from 'rxjs/operators';
import { PHONE_PREFIX_DATA } from './constants';
import { PhonePrefixInterface } from './interfaces';

@Component({
  selector: 'df-shared-lib-material-phone-prefix-dropdown',
  templateUrl: './material-phone-prefix-dropdown.component.html',
  styleUrls: ['./material-phone-prefix-dropdown.component.scss'],
})
export class MaterialPhonePrefixDropdownComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() phonePrefix = '';
  @Input() phonePrefixAfter = '';
  @Input() labelTranslateKey = 'GENERIC_WRAPPER.PHONE_PREFIX';

  @Output()
  phonePrefixSelected: EventEmitter<PhonePrefixInterface> = new EventEmitter<PhonePrefixInterface>();

  protected phonePrefixes: PhonePrefixInterface[] = PHONE_PREFIX_DATA;

  public phonePrefixCtrl: UntypedFormControl = new UntypedFormControl();

  public phonePrefixFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredPhonePrefixes: ReplaySubject<PhonePrefixInterface[]> = new ReplaySubject<PhonePrefixInterface[]>(1);

  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.initPhonePrefixCtrl();

    this.filteredPhonePrefixes.next(this.phonePrefixes);

    this.phonePrefixFilterCtrl.valueChanges
      .pipe(debounceTime(250), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPhonePrefixes();
      });
  }

  initPhonePrefixCtrl(): void {
    if (!this.phonePrefix) {
      this.phonePrefixCtrl.setValue(this.phonePrefixes[70]);
    } else {
      this.phonePrefixCtrl.setValue(
        this.phonePrefixes.find((phonePrefix) =>
          !this.phonePrefixAfter
            ? phonePrefix.dial_code.indexOf(this.phonePrefix) > -1
            : phonePrefix.dial_code.indexOf(this.phonePrefix) > -1 &&
            phonePrefix?.after_dial_code?.indexOf(this.phonePrefixAfter) > -1
        )
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phonePrefix?.currentValue || changes.phonePrefixAfter?.currentValue)
    this.initPhonePrefixCtrl();
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSelectPhonePrefix(event: MatSelectChange): void {
    this.phonePrefixSelected.emit(event.value);
  }

  private setInitialValue(): void {
    this.filteredPhonePrefixes
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (
          a: PhonePrefixInterface,
          b: PhonePrefixInterface
        ) => a && b && a.name === b.name;
      });
  }

  protected filterPhonePrefixes(): void {
    if (!this.phonePrefixes) {
      return;
    }
    let search = this.phonePrefixFilterCtrl.value;
    if (!search) {
      this.filteredPhonePrefixes.next(this.phonePrefixes);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPhonePrefixes.next(
      this.phonePrefixes.filter(
        (phonePrefix) => phonePrefix.name.toLowerCase().indexOf(search) > -1
      )
    );
  }
}
