import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaypalPaymentComponent } from './paypal-payment.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  exports: [PaypalPaymentComponent],
  declarations: [PaypalPaymentComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        TranslocoDirective,
    ],
})
export class PaypalPaymentModule {}
