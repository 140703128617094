<ng-container *transloco="let t">
<div class="chat-wrapper" [ngClass]="{'mobile-show-conversation-list': !conversationPartnerIds,'mobile-show-conversation-detail': !!conversationPartnerIds}">
  <div class="conversation-list">
    <div class="flex-row">
      <h3 class="class-list-title">{{t('GENERIC_WRAPPER.MESSAGES_PAGE.CHATS')}}</h3>
    </div>
    <div *ngIf="!enableMultiPartnerChat">
      <form [formGroup]="form">
        <df-shared-lib-user-search-autocomplete
          style="flex: 1 1 auto;"
          (optionSelected)="setSelectedUser($event); openConversationByPartner()"
          (inputChange)="onInputChange($event)"
          [showUserSearchLoading]="partnerSearchLoading"
          [userOptions]="userOptions"
          [resetFormAfterSelection]="true"
        ></df-shared-lib-user-search-autocomplete>
        <mat-error *ngIf="user.hasError('emptyField')"></mat-error>
        <mat-error *ngIf="user.hasError('notAllowedToSendMessagesToOwnAccount')">
          Not allowed to send messages to own account
        </mat-error>
        <mat-error *ngIf="user.hasError('userNotFound')">
          User not found
        </mat-error>
      </form>
    </div>
    <df-search-conversation-partners 
      *ngIf="enableMultiPartnerChat" 
      [currentPartnerId]="currentPartnerId"
    ></df-search-conversation-partners>
    
    <mat-spinner *ngIf="loadingInit" [diameter]="20"></mat-spinner>
    
    <ng-container *ngIf="!loadingInit">
      <div>
        <div *ngFor="let conversation of conversations; let last = last" class="list-element" [class.last-item]="last" >
          <df-conversation-list-entry
            [conversation]="conversation"
            [currentPartnerId]="currentPartnerId"
            [active]="isActive(conversation)"
          ></df-conversation-list-entry>
        </div>
        <div *ngIf="conversations.length === 0" class="no-messages">
          {{t('GENERIC_WRAPPER.MESSAGES_PAGE.NO_MESSAGES')}}
        </div>
        <div class="center">
          <mat-spinner *ngIf="loadingMore"> [diameter]="20"></mat-spinner>
        </div>
      </div>
    </ng-container>
    
    <br/>
    
    <div *ngIf="!loadingInit && !loadingMore && hasMore" class="pb1 flex-row align-center">
      <df-shared-lib-button 
        [materialButtonStyleType]="'outlined'" 
        [buttonLabel]="t(buttonLabel)" 
        (buttonClicked)="loadMore()"
      ></df-shared-lib-button>
    </div>
  </div>
  
  <div class="conversation-detail">
    <div class="conversation-detail-inner-container">
      <df-conversation
        *ngIf="conversationPartnerIds"
        [conversationPartnerIds]="conversationPartnerIds"
        [currentPartnerId]="currentPartnerId"
        (messageCreated)="reload()"
        (conversationDeleted)="reload()"
      ></df-conversation>
    </div>
    <div *ngIf="!conversationPartnerIds" class="w-100 h-100 flex-column align-center">
      <span>{{t('GENERIC_WRAPPER.CONVERSATION.START_CONVERSATION')}}</span>
    </div>
  </div>
</div>
</ng-container>
