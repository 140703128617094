import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { StatisticsBoxComponent } from './statistics-box.component';

@NgModule({
  declarations: [StatisticsBoxComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
  ],
  exports: [StatisticsBoxComponent],
})
export class StatisticsBoxdModule {}
