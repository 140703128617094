<ng-container *transloco="let t">
  <div class="px3 pb3 flex-column form-container-width">
    <form
      class="flex-column"
      [formGroup]="descriptionForm"
      >
      <span class="team-description-title">
        {{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.DESCRIPTION.TITLE_LABEL') }}
      </span>
      <span class="team-description-text">
        {{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.DESCRIPTION.EXPLANATION') }}
      </span>
      <div class="flex-row description-container">
        <df-shared-lib-quill-editor
          style="width: 50%; padding-right: 20px;"
          [enableMentions]="true"
          [disableRichText]="true"
          [id]="'description'"
          [placeholder]="'GENERIC_WRAPPER.DESCRIPTION'"
          [customFormControlName]="'description'"
        ></df-shared-lib-quill-editor>
        <df-shared-lib-button
          [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
          (buttonClicked)="saveTeamDescription()"
          id="team-save-button"
          style="margin-bottom: 6px;"
        ></df-shared-lib-button>
      </div>
    </form>
    <mat-divider></mat-divider>
   
    <div *ngIf="isOwnerOrAdmin" class="flex-column">
       <form [formGroup]="roleForm">
        <div class="flex-row search-container">
          <df-shared-lib-user-search-autocomplete
            class="user-input-flex"
            (optionSelected)="setSelectedUser($event)"
            [required]="true"
            (inputChange)="onSearchInputChange($event)"
            [showUserSearchLoading]="userSearchLoading"
            [userOptions]="userOptions"
          >
          </df-shared-lib-user-search-autocomplete>
          <mat-form-field appearance="outline" class="user-input-flex" id="select-role-drop-down">
            <mat-label>{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.SELECT_ROLE') }}</mat-label>
            <mat-select
              formControlName="danceSchoolUserRole"
              disableOptionCentering
            >
              <mat-option
                *ngFor="let roleOption of danceSchoolUserRoleEnum | keyvalue"
                [value]="roleOption.value"
              >{{ roleOption.value | titlecase }}</mat-option>
              </mat-select>
              <mat-error> {{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.ERROR_SELECT_ROLE') }}</mat-error>
          </mat-form-field>
          <df-shared-lib-button
            [isDisabled]="roleForm.invalid"
            [buttonLabel]="t('GENERIC_WRAPPER.ADD')"
            (buttonClicked)="save()"
            style="margin-top: 6px;"
          ></df-shared-lib-button>
        </div>
      </form>
      <div class="flex-row pt-20">
        <span class="user-invite-text ">{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.NO_MEMBER_TEXT') }}</span>
        <span class="df-link user-invite-text" (click)="openDmUserInviteDialog()">{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.NO_MEMBER_INVITATION') }}</span>
      </div>
    </div>
    
    <dfm-roles-table [ngClass]="isOwnerOrAdmin ? '' : 'pt2'"></dfm-roles-table> 
  </div>
</ng-container>
