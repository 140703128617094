import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AvailableDataStateClassInterface, convertUTCToLocalFast, getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ChatsOverviewStateService } from '../../services/chats-overview-state.service';
import { UserDto } from '@platri/elab-common-auth-plug-play';
import { ChatDataInterface } from '../../mock-data';
import { ChatHttpService, ChatStateService } from '../../services';
import { CreateMessageRequestDto, MessageDto, MessageStatusEnum, MessageTypeEnum, ReactMessageRequestDto, ReadMessageRequestDto } from '@platri/df-common-chat-plug-play';
import { MessagePartnerInterface } from '@platri/df-common-core';
import { ChatAppChatRouterParam, ChatAppChatsTechnicalUserRouterQueryParam } from '../../constants';
import { AuthStateService, UserHttpService } from '@platri/dfx-angular-core';
import { AuthTechnicalUserStateService } from '../../services/auth-technical-user-state.service';

@Component({
  selector: 'df-auction-chat2',
  templateUrl: './auction-chat2.component.html',
  styleUrls: ['./auction-chat2.component.scss'],
  providers: [ChatStateService]
})
export class AuctionChat2Component implements OnInit, OnDestroy {
  @ViewChild('chat-content') private chatContent: ElementRef;

  allMessagesLoaded = false;
  isLoading = false;
  debounceTimer: any;
  currentPage = 1;
  isLoadingMoreMessages = false;

  chatData: ChatDataInterface[] = [];
  messages: MessageDto[] = [];
  currentUser: UserDto;
  recipientUser: MessagePartnerInterface;

  subscriptions: Subscription =  new Subscription();

  isSendingMessage: BehaviorSubject<boolean> = new BehaviorSubject(false);

  routerParams!: Params;
  recipientIdFromParam: string;

  queryParams!: Params;
  technicalUserIdFromQueryParam: string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
   // private attachementHttpService: AttachmentHttpService,
    private authStateService: AuthStateService,
    private authTechnicalUserStateService: AuthTechnicalUserStateService,
    private chatHttpService: ChatHttpService,
    private chatStateService: ChatStateService,
    private chatsOverviewStateService: ChatsOverviewStateService,
    private router: Router,
    private userHttpService: UserHttpService
  ) {}
  
  ngOnInit(): void {
    this.loadParams();
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    if (this.technicalUserIdFromQueryParam) {
      this.authTechnicalUserStateService.logout();
    }
    this.subscriptions.unsubscribe();
  }
  
  loadParams(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.technicalUserIdFromQueryParam = this.queryParams[ChatAppChatsTechnicalUserRouterQueryParam];
    this.routerParams = this.activatedRoute.snapshot.params;
    this.recipientIdFromParam = this.routerParams[ChatAppChatRouterParam];
    this.chatStateService.isTechnicalUserState = !!this.technicalUserIdFromQueryParam;
  }

  initializeSubscriptions(): void {
    if (this.technicalUserIdFromQueryParam) {
      this.subscriptions.add(getSubscription(this.authTechnicalUserStateService.getAsyncCurrentUser(), this.onCurrentTechnicalUserDataChanges.bind(this)));
    } else {
      this.subscriptions.add(getSubscription(this.authStateService.getAsyncCurrentUser(), this.onCurrentUserDataChanges.bind(this)));
    }
  }

  onCurrentTechnicalUserDataChanges(currentUser: UserDto | null): void {
    if (!this.technicalUserIdFromQueryParam) return;
    if (!currentUser) {
      this.authTechnicalUserStateService.login(this.technicalUserIdFromQueryParam).subscribe(res => {
        if (res) {
          this.authTechnicalUserStateService.sendCurrentUser({id: this.technicalUserIdFromQueryParam, email: '', emailVerified: true});
        }
      });
    } else if(!this.currentUser) {
      this.currentUser = currentUser;
      this.loadData();
    }
  }

  onCurrentUserDataChanges(currentUser: UserDto | null): void {
    if (!currentUser) {
    //  this.userHttpService.getCurrentUser().subscribe(res => {
     //   this.authStateService.sendCurrentUser(res);
    //  });
    } else if (!this.currentUser) {
      this.currentUser = currentUser;
      this.loadData();
    }
  }
  
  loadData(): void {
    this.subscriptions.add(getSubscription(this.chatStateService.getAsyncAllData(), this.onChatDataChanges.bind(this)));
    this.chatStateService.loadAvailableData({otherUserId: this.recipientIdFromParam, offset: 0});
    this.subscriptions.add(this.userHttpService.getOtherUserById(this.recipientIdFromParam).subscribe(res => this.recipientUser = res, error => console.log("Could not load other user")));
  }

  onChatDataChanges(messages: AvailableDataStateClassInterface<MessageDto>): void {
    if (!messages) {
      return;
    }
    
    
    if (!messages.hasErrorLoadingData && !messages.isLoadingData) {
      if (this.chatData.length === 0) {
        this.chatData = this.transformMessageDtosToChatData(messages.availableData.filter(message => message.senderId === this.recipientIdFromParam || message.recipientId === this.recipientIdFromParam)).sort((a, b) => a.date.getTime() - b.date.getTime()).reverse();
        this.messages = this.chatData.map(chatData => chatData.chatMessages).reduce((acc, chatMessages) => [...chatMessages, ...acc], []);
        if (this.messages.length < 30) this.allMessagesLoaded = true;
      } else if (this.messages.length < messages.availableData.length) {
        
        if (messages.availableData.length > (this.messages.length + 1) || (this.isLoading && messages.availableData.length >= (this.messages.length + 1))) {

          this.chatData = this.transformMessageDtosToChatData(messages.availableData.filter(message => message.senderId === this.recipientIdFromParam || message.recipientId === this.recipientIdFromParam)).sort((a, b) => a.date.getTime() - b.date.getTime()).reverse();
          this.messages = this.chatData.map(chatData => chatData.chatMessages).reduce((acc, chatMessages) => [...chatMessages, ...acc], []);
          this.isLoading = false;
          
        } else {
          // const firstFoundMessage = messages.availableData.findIndex(message => message.id === this.messages[this.messages.length - 1].id);
          // if (firstFoundMessage === 0 && false) {
          //   const originalScrollTop = document.getElementById('chat-content')!.scrollTop;
          //   for(let i = firstFoundMessage - 1; i >= 0; i--) {
          //     this.addChatMessageToBeginning(messages.availableData[i]);
          //   }
          //   setTimeout(() => {
          //     document.getElementById('chat-content')!.scrollTop = originalScrollTop;
          //   }, 0);
          //   this.messages = this.chatData.map(chatData => chatData.chatMessages).reduce((acc, chatMessages) => [...acc, ...chatMessages], []);
          //   setTimeout(() => this.isLoading = false, 50);
          // } else {
          //   const lastElement = messages.availableData[messages.availableData.length - 1];
          //   if (lastElement.senderId === this.recipientIdFromParam || lastElement.recipientId === this.recipientIdFromParam) {
          //     this.addChatMessage(lastElement);
          //   }
          // }
          const lastElement = messages.availableData[messages.availableData.length - 1];
          if (lastElement.senderId === this.recipientIdFromParam || lastElement.recipientId === this.recipientIdFromParam) {
            this.addChatMessage(lastElement);
          }
        }
      } else if (this.messages.length === messages.availableData.length) {
        this.allMessagesLoaded = true;
        this.isLoading = false;
      }
    }
  }

  addChatMessageToBeginning(chatMessage: MessageDto): void {
    chatMessage.date = new Date(chatMessage.date);
    const foundChatData: ChatDataInterface | undefined = this.chatData.find(chatData => this.vergleicheDatum(chatData.date, chatMessage.date));
    if (foundChatData) {
      foundChatData.chatMessages.unshift(chatMessage);
    } else {
      const dateString = convertUTCToLocalFast(chatMessage.date.toString());
      const dateKey = dateString!.split('T')[0];
      this.chatData.unshift({
        date: new Date(dateKey),
        chatMessages: [chatMessage]
      });
    }
  }
  
  addChatMessage(chatMessage: MessageDto): void {
    chatMessage.date = new Date(chatMessage.date);
    const foundChatData: ChatDataInterface | undefined = this.chatData.find(chatData => this.vergleicheDatum(chatData.date, chatMessage.date));
    if (foundChatData) {
      foundChatData.chatMessages.push(chatMessage);
    } else {
      const dateString = convertUTCToLocalFast(chatMessage.date.toString());
      const dateKey = dateString!.split('T')[0];
      this.chatData.unshift({
        date: new Date(dateKey),
        chatMessages: [chatMessage]
      });
    }
    this.messages.push(chatMessage);
  }

  transformMessageDtosToChatData(input: MessageDto[]): ChatDataInterface[] {
    const groupedByDate: { [key: string]: ChatDataInterface } = {};

    input.forEach(message => {
      const dateString = convertUTCToLocalFast(message.date.toString());
      const dateKey = dateString!.split('T')[0];
      if (!groupedByDate[dateKey]) {
        groupedByDate[dateKey] = {
          date: new Date(dateKey),
          chatMessages: []
        };
      }

      message.date = new Date(message.date);

      groupedByDate[dateKey].chatMessages.push(message);
    });

    return Object.values(groupedByDate);
  }

  sendChatMessage(chatMessage: any): void {
    const createMessageRequestDto: CreateMessageRequestDto = new CreateMessageRequestDto({recipientId: this.recipientIdFromParam, date: new Date(), status: MessageStatusEnum.IS_SENT, value: chatMessage.value, type: MessageTypeEnum.TEXT, repliedToMessageId: chatMessage.repliedToMessageId ?? null});
    this.chatHttpService.createMessage(createMessageRequestDto, this.technicalUserIdFromQueryParam ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
      this.isSendingMessage.next(false);
    });

  }

  readChatMessage(chatMessageId: number): void {
    const readMessageRequestDto: ReadMessageRequestDto = new ReadMessageRequestDto({messageId: chatMessageId});
    const chats = this.chatsOverviewStateService.getSyncAvailableData();
    const foundChat = chats.find(chat => chat.lastMessage.id === chatMessageId);
    if (foundChat) {
      foundChat.lastMessage.status = MessageStatusEnum.IS_READ;
    }
    this.chatHttpService.readMessage(readMessageRequestDto, this.technicalUserIdFromQueryParam ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
    });
  }

  reactChatMessage(chatMessageReactionDto: any): void {
    const reactMessageRequestDto: ReactMessageRequestDto = new ReactMessageRequestDto({messageId: chatMessageReactionDto.messageId, recipientReaction: chatMessageReactionDto.messageReaction});
    this.chatHttpService.reactMessage(reactMessageRequestDto, this.technicalUserIdFromQueryParam ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
    });
  }

  vergleicheDatum(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  deleteChatMessage(message: MessageDto): void {
    this.chatHttpService.deleteMessage(message.id, this.technicalUserIdFromQueryParam ? '' : '').subscribe();
  }

  loadMoreChatMessages(): void {
    if (this.isLoading || this.allMessagesLoaded) return;
    this.isLoading = true;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      console.log("LOAD MORE");
      
      this.subscriptions.add(this.chatHttpService.getAllMessagesFromUserWithOtherUser(this.recipientIdFromParam, (this.messages.length), 30, this.technicalUserIdFromQueryParam ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
        this.currentPage = this.currentPage + 1;
        if (res.length < 30) this.allMessagesLoaded = true;
        this.chatStateService.pushNewMessages(res);
      }));
    }, 5);
  }

  deleteChat(): void {
    this.chatHttpService.deleteAllMessagesToOtherUser(this.recipientIdFromParam, this.technicalUserIdFromQueryParam ? this.authTechnicalUserStateService.getSyncAccessToken() : '').subscribe(res => {
      this.chatsOverviewStateService.removeChat(this.recipientIdFromParam);
      this.router.navigate(['../'], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
    });
  } 

  uploadFileAndSendChatMessage(file: File): void {
    // this.attachementHttpService.uploadFile(file, true).subscribe(uploadUrl => {
    //   const createMessageDto: CreateMessageRequestDto = {
    //     value: uploadUrl,
    //     senderId: this.currentUser!.id,
    //     recipientId: this.recipientIdFromParam,
    //     date: new Date(),
    //     type: MessageTypeEnum.MEDIA,
    //     status: MessageStatusEnum.IS_SENT
    //   }
    //   this.chatHttpService.createMessage(createMessageDto).subscribe(res => {
    //  
    //   })
    // })
  }
}
