import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { DfSharedButtonModule, NumberInputModule, PipesModule } from '@platri/dfx-angular-core';
import { MonthsDancingFormSettingsComponent } from './months-dancing-form-settings.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [MonthsDancingFormSettingsComponent],
  exports: [MonthsDancingFormSettingsComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      NumberInputModule,
      MatButtonModule,
      MatSliderModule,
      PipesModule,
      DfSharedButtonModule,
      TranslocoDirective,
    ]
})
export class MonthsDancingFormSettingsModule {
}
