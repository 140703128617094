import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateDanceManagerAppComponent } from './pages/app';

const routes: Routes = [
  { 
    path: '', 
    component: CreateDanceManagerAppComponent, 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendCreateDanceManagerRoutingModule {
  constructor() {
    console.log('FrontendCreateDanceManagerAppRoutingModule initialized');
  }
}
