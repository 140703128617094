import { AppointmentsDto } from './appointments.dto';
import { AppointmentCommentsInterface } from '../../..';
import { UserDto } from './user.dto';

export class AppointmentCommentsDto implements AppointmentCommentsInterface{
  id: string;
  content: string;
  appointment: AppointmentsDto;
  user: UserDto;
}
