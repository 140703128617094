import { CurrencyInterface } from '../currency.interface';
import { FeesPaidByEnum, PassStatusEnum } from '../../enums';
import { PassTargetInterface } from './pass-target.interface';
import { UserPassInterface } from './user-pass.interface';
import { PassValidityInterface } from './pass-validity.interface';

export interface PassInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  currency?: CurrencyInterface;
  taxPercentage?: number;
  price?: number;
  name?: string;
  description?: string;
  quantity?: number;
  soldQuantity?: number;
  availableQuantity?: number;
  feesPaidBy?: FeesPaidByEnum;
  passTargets?: PassTargetInterface[];
  danceManagerId?: string;
  usageCount?: number;
  userPasses?: UserPassInterface[];
  sequenceNumber?: number;
  status?: PassStatusEnum;
  validity?: PassValidityInterface;
  trial?: boolean;
}
