import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, Location, LocationAddressEnum } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-add-edit-event-appointment-location',
  templateUrl: './add-edit-event-appointment-location.component.html',
  styleUrls: ['./add-edit-event-appointment-location.component.scss'],
})
export class AddEditEventAppointmentLocationComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() locations: Location[];
  @Input() showCancelButton = true;
  @Input() cancelButtonLabel: string;
  @Input() submitButtonLabel: string;

  @Output() cancelClickEventEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitClickEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  currentDate = new Date();

  locationAddressEnum = LocationAddressEnum;
  locationTypeControl: UntypedFormControl = new UntypedFormControl(LocationAddressEnum.LOCATION);

  formGroupInvalidState = true;

  ngOnInit(): void {
    if ((this.address?.value != null && this.location?.value == null) || this.locations.length === 0) {
      this.locationTypeControl.patchValue(LocationAddressEnum.ADDRESS);
    }
    this.formGroup.valueChanges.subscribe((a) => {
      this.formGroupInvalidState = this.formGroup.invalid;
    });
    this.listenOnLocationTypeChanges();
  }
  
  listenOnLocationTypeChanges(): void {
    this.locationTypeControl.valueChanges.subscribe(value => {
      if (value === LocationAddressEnum.ADDRESS) {
        this.formGroup.controls.address.enable();
        this.formGroup.controls.address.addValidators(Validators.required);
      } else {
        this.formGroup.controls.address.disable();
        this.formGroup.controls.address.removeValidators(Validators.required);
      }
      this.formGroup.updateValueAndValidity();
    });
  }

  get date(): AbstractControl | null {
    return this.formGroup.get('date');
  }

  get location(): AbstractControl | null {
    return this.formGroup.get('locationId');
  }

  get address(): AbstractControl | null {
    return this.formGroup.get('address');
  }

  onAddressSelected(address?: Address): void {
    if (address) {
      this.formGroup.controls.address.setValue(address);
    } else {
      this.formGroup.controls.address.setValue(null);
    }
  }
}
