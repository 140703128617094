import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb, LoginResponseDto, SignInRequestDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiAuthUrl}/auth`;

  constructor(private http: HttpClient) {}

  signIn(loginUser: SignInRequestDto): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(`${this.SERVICE_URL}/sign-in`, loginUser);
  }

  logoutInHttp(refreshToken: string): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/logout`, {
      refreshToken: refreshToken
    });
  }

  //todo: TOKEN META DATA DTO
  introspectToken(accessToken: string): Observable<any> {
    return this.http.post<any>(`${this.SERVICE_URL}/token`, {
      accessToken
    });
  }

  refreshTokens(refreshToken: string): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(`${this.SERVICE_URL}/refresh`, {
      refreshToken
    });
  }

  signInFacebook(token: LoginResponseDto): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(`${this.SERVICE_URL}/sign-in/facebook`, token);
  }

  signInDanceManager(danceManagerId: string): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(`${this.SERVICE_URL}/sign-in-dm`, {danceManagerId});
  }

  signInGoogle(jwt: string): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(`${this.SERVICE_URL}/sign-in/google`, { accessToken: jwt });
  }

  loginWithMagicLink(token: string): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(`${this.SERVICE_URL}/magic-link/sign-in`, { token: token });
  }

  createMagicLink(emailOrUsername: string): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/magic-link/create`, {
      emailOrUsername
    });
  }
}
