<ng-container *transloco="let t">
  <mat-card class="dm-event-preview-card-overall-container mb2 flex-row w-100" >
    <a class="dm-event-preview-card-link-container"
      [routerLink]="'preview/' + danceEvent.id"
      style="text-decoration: none; color: inherit;">
      
        <!-- Date -->
        <div
          class="dm-event-preview-card-date flex-column"
        >
        <span *ngIf="danceEvent.isActive" class="event-month">{{
          danceEvent.appointments[0].startDate | monthNameShortPipe
          }}</span>
  
          <span *ngIf="danceEvent.isActive" class="event-day">{{
            danceEvent.appointments[0].startDate | dayOfMonthPipe : 'UTC'
            }}</span>
        </div>
        <!-- Image -->
        <!-- new ones will be in 2:1 -->
        <div class="dm-event-preview-card-image-container  ">
          <img
            class="dm-event-preview-card-image"
            [src]="danceEvent?.mainImage?.url ?? 'assets/images/event_card_image_web.png'"
            [alt]=""
          />
        </div>
        <!-- Details -->
        <div
          *ngIf="danceEvent"
          class="w-100 pl2 flex-column dm-event-preview-card-details-container"
          style="min-width: 185px;"
        >
        <span
          class="mt1 flex-row dm-event-preview-card-header"
        >{{ danceEvent?.name }}</span
        >
  
          <div class="pb1 flex-row dm-event-preview-card-full-date">
            <div *ngIf="danceEvent.isActive">
              <p class="m0">
                {{
                danceEvent.appointments[0].startDate
                  | dateTimezoned: 'UTC': currentLang : danceEvent.appointments[0].duration : false : false
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- Dance Manager Name -->
        <div class="dm-event-preview-card-dm-name">
           {{currentDanceManager}}
        </div>
     
    </a>
    <div style="width: 20%; height: 100%;" class="pr2 flex-row dm-event-preview-card-icon-container">
      <button *ngIf="danceEventListTab !== danceEventListTabEnum.PAST && !danceEvent.isActive" type="submit" (click)="publishDanceEvent()" mat-icon-button matTooltip="{{ t('GENERIC_WRAPPER.PUBLISH') }}">
        <mat-icon>publish</mat-icon>
      </button>
      <button *ngIf="danceEventListTab !== danceEventListTabEnum.PAST" [routerLink]="'preview/' + danceEvent.id" mat-icon-button matTooltip="{{ t('GENERIC_WRAPPER.EDIT') }}">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="duplicateDanceEvent()" matTooltip="{{ t('GENERIC_WRAPPER.DUPLICATE') }}">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button *ngIf="danceEventListTab !== danceEventListTabEnum.PAST" mat-icon-button (click)="deleteDanceEvent()" matTooltip="{{ t('GENERIC_WRAPPER.DELETE') }}">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-card>
</ng-container>
