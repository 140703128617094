<ng-container *transloco="let t">
  <div class="user-groups-manager-container flex-column align-center">
    <div class="user-groups-container w-100 flex-column pb2">
      <div class="user-groups-breadcrumb">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="user-groups-card-container">
        <mat-card style="background-color: white" class="user-groups-card round-corners w-100 no-shadow-mat-card">
          <div class="user-groups-outside-padding">
            <div class="user-groups-title-button-container">
                  <div style="display: flex; align-items: center;">
                    <span class="user-group-title" >{{t('DFM_USER_GROUP.USER_GROUPS')}} ({{dataSource?.data?.length ?? 0}})</span>
                  </div>
                  <div>
                    <df-shared-lib-button
                      class="hide-on-mobile"
                      [materialButtonStyleType]="'flat'"
                      [buttonLabel]="t('DFM_USER_GROUP.CREATE_USER_GROUP')"
                      id="create-new-user-group"
                      (buttonClicked)="navigateToCreatePage()"
                    ></df-shared-lib-button>
                    <df-shared-lib-button
                      class="hide-on-web"
                      [isMatIconButton]="true"
                      [matIconName]="'add'"
                      [materialButtonStyleType]="'flat'"
                      id="create-new-user-group-mobile"
                      (buttonClicked)="navigateToCreatePage()"
                    ></df-shared-lib-button>
                  </div> 
                </div>
              <table *ngIf="dataSource?.data?.length > 0 && hasLoadedUsers; else noUserGroups" mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 20%">{{ t('GENERIC_WRAPPER.NAME') }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex-row-start-center" style="gap: 10px;">
          <span id="event-name">
            {{ element.name }}
          </span>
        </div>
      </td> 
    </ng-container>
    
    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 20%">{{t('GENERIC_WRAPPER.USER')}}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex-row-start-center" style="gap: 4px;" [innerHTML]="getFirstThreeUsers(element)">
        </div>
      </td> 
    </ng-container>
    
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef scope="col"></th>
      <td mat-cell *matCellDef="let element">
        <div class="pr2 flex-row align-center-end">
          <button mat-icon-button (click)="$event.stopPropagation(); navigateToEditPage(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="$event.stopPropagation(); deleteUserGroup(element)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="$event.stopPropagation(); navigateToEditPage(row)"></tr>
  </table>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noUserGroups>
  <ng-container *transloco="let t">
    <div class="align-center" style="padding-top: 113px;">
      <df-shared-empty-page
        [buttonLabel]="t('DFM_USER_GROUP.CREATE_USER_GROUP')"
        [emptyTitle]="t('DFM_USER_GROUP.NO_USER_GROUPS_TITLE')"
        [emptyDescription]="t('DFM_USER_GROUP.NO_USER_GROUPS_TEXT')"
        (buttonClicked)="navigateToCreatePage()"
      ></df-shared-empty-page>
    </div>
  </ng-container>
</ng-template>
