import { AmenityInterface } from './amenity.interface';
import { ImageInterface } from './image.interface';
import { LocationInterface } from './location.interface';
import { DanceSchoolRoomTypeEnum } from '../enums';

export interface RoomInterface {
  id?: string;
  name?: string;
  size?: number;
  amenities?: AmenityInterface[];
  images?: ImageInterface[];
  type?: DanceSchoolRoomTypeEnum;
  location?: LocationInterface;
  locationId?: string;
  rooms?: RoomInterface[];
}
