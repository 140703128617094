<ng-container *transloco="let t">
  <div class="full-view">
    <div class="flex-container">
      <div class="loading-box" *ngIf="isWaitingForConfirmEmailResponse">
        <mat-spinner></mat-spinner>
      </div>
      
      <div *ngIf="!confirmEmailFailedNoConnection && !isWaitingForConfirmEmailResponse && hasSuccessfullyConfirmEmail" class="pt3">
        {{ t('GENERIC_WRAPPER.CONFIRM_EMAIL_TEXT') }}
      </div>
      
      <div *ngIf="!confirmEmailFailedNoConnection && !isWaitingForConfirmEmailResponse && !hasSuccessfullyConfirmEmail"  class="pt3">
        {{ t('GENERIC_WRAPPER.SOMETHING_WENT_WRONG') }}
      </div>
      
      <div *ngIf="confirmEmailFailedNoConnection"  class="pt3">
        {{ t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED') }}
      </div>
    </div>
  </div>
</ng-container>
