import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  AuthStateService,
  DanceManagerHttpService,
  dfAppRoute,
  dfLoginRoute,
  FooterStateService,
  mustMatch,
  TicketHttpService,
  TicketOrderService
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppointmentsInterface, calculateApplicationFee, calculateTaxOnApplicationFee,
  CreateTicketOrderRequestDto,
  DanceEventsInterface,
  DanceSchoolInterface,
  DEFAULT_EVENT_PICTURE_PLACEHOLDER,
  emailRegex,
  FeesPaidByEnum,
  TicketInterface,
  UsersInterface,
  UserTicketDanceRoleTypeEnum
} from '@platri/df-common-core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-event-tickets',
  templateUrl: './event-tickets.component.html',
  styleUrls: ['./event-tickets.component.scss']
})
export class EventTicketsComponent implements OnInit, AfterViewInit, OnDestroy {
  
  formGroup: UntypedFormGroup;
  subtotal: number;
  fees: number;
  currentUser: UsersInterface;
  selectedTickets: TicketInterface[] = [];
  tickets: TicketInterface[];
  ticketsExpanded: TicketInterface[] = [];
  danceSchool: DanceSchoolInterface;
  danceEvent: DanceEventsInterface;
  appointment: AppointmentsInterface;
  subscriptions: Subscription = new Subscription();
  
  isMobileResponsive: boolean;
  showMobileSummary: boolean;
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;

  creationTicketOrderLoading = false;
  showGuestInputFields = false;
  guestEmailForm = this.fb.group(
    {
      email: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]],
      confirmEmail: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]]
    },
    {
      validators: mustMatch('email', 'confirmEmail')
    }
  );
  
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authService: AuthStateService,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly ticketHttpService: TicketHttpService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly footerService: FooterStateService,
    private readonly ticketOrderService: TicketOrderService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private fb: FormBuilder,
    private translocoService: TranslocoService
  ) {
    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result: BreakpointState) => {
        this.isMobileResponsive = result.matches;
        this.footerService.showFooter$.next(!result.matches);
        if (this.showMobileSummary && !result.matches) {
          this.showMobileSummary = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.getDanceEventDataFromRoute();
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  ngAfterViewInit(): void  {
    this.detectChanges();
  }

  ngOnDestroy(): void  {
    this.subscriptions.unsubscribe();
    this.footerService.showFooter$.next(true);
  }

  private getDanceEventFromResolver(): void {
    this.danceEvent = this.activatedRoute.snapshot.data.targetObject;
    this.danceSchool = this.danceEvent.danceSchool;
    if (this.danceEvent.appointments.length > 0) {
      this.appointment = this.danceEvent.appointments[0];
    }
  }

  initializeForm(): void {
    this.formGroup = this.formBuilder.group({});
    this.tickets.forEach((ticket) => {
      if (ticket?.id) {
        if (ticket.sellAsFollowerLeader) {
          this.formGroup.addControl(ticket.id + '_leader', this.formBuilder.control('0'));
          this.formGroup.addControl(ticket.id + '_follower', this.formBuilder.control('0'));
        } else {
          this.formGroup.addControl(ticket.id, this.formBuilder.control('0'));
        }
      }
    });
  }

  getDanceEventDataFromRoute(): void  {
    this.getDanceEventFromResolver();
    this.loadTicketsOfDanceEvent();
  }

  private loadTicketsOfDanceEvent(): void {
    this.subscriptions.add(
      this.ticketHttpService.getAllByDanceEventId(this.danceEvent?.id!).subscribe((result) => {
        this.tickets = result;
        this.tickets.forEach((ticket) => {
          if (ticket.sellAsFollowerLeader) {
            if (ticket.sellAsLeaderActive) {
              const temp = { ...ticket };
              temp.id = ticket.id + '_leader';
              temp.name = ticket.name + ' (Leader)';
              this.ticketsExpanded.push(temp);
            }
            if (ticket.sellAsFollowerActive) {
              const temp = { ...ticket };
              temp.id = ticket.id + '_follower';
              temp.name = ticket.name + ' (Follower)';

              this.ticketsExpanded.push(temp);
            }
          } else {
            this.ticketsExpanded.push({ ...ticket });
          }
        });

        this.initializeForm();
        this.initializeFormSubscription();
      })
    );
  }

  initializeFormSubscription(): void {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((form) => {
        this.selectedTickets = [];
        this.subtotal = 0;
        this.fees = 0;
        this.ticketsExpanded.forEach((ticket) => {
          if (form[ticket.id!] > 0) {
            this.selectedTickets.push(ticket);
            
            if (ticket.feesPaidBy === FeesPaidByEnum.CUSTOMER) {
              const sub = ticket.price! * form[ticket.id!];
              const applicationFeeWithTax = calculateTaxOnApplicationFee(calculateApplicationFee(sub, form[ticket.id], 4.5, 49));
              this.fees = this.fees + applicationFeeWithTax;
              this.subtotal += sub + applicationFeeWithTax;
            } else {
              this.subtotal += ticket.price! * form[ticket.id!];
            }
          }
        });
      })
    );
  }

  continueToCheckout(withLogin = false): void {
      const createTicketOrder: CreateTicketOrderRequestDto = {
        ticketOrderItems: [],
        passOrderItems: []
      };
      for (const control in this.formGroup.controls) {
        const ticket = this.ticketsExpanded.find((obj) => obj.id === control);
        if (ticket && this.formGroup.controls[control].value > 0) {
          if (ticket.id?.includes('_leader') || ticket.id?.includes('_follower')) {
            createTicketOrder!.ticketOrderItems!.push({
              ticketId: ticket.id.substring(0, ticket.id.indexOf('_')),
              quantity: this.formGroup.controls[control].value,
              ticketDanceRole: ticket.id.substring(ticket.id.indexOf('_') + 1) as UserTicketDanceRoleTypeEnum
            });
          } else {
            createTicketOrder!.ticketOrderItems!.push({
              //todo: !
              ticketDanceRole: undefined,
              ticketId: ticket.id,
              quantity: this.formGroup.controls[control].value
            });
          }
        }
      }
    if (createTicketOrder!.ticketOrderItems!.length > 0) {
      if (withLogin && !this.authService.getSyncCurrentUser()) {
        this.creationTicketOrderLoading = true;
        this.ticketOrderService.sendTempCreateTicketOrderData(createTicketOrder, this.danceEvent.id);
        this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: `/dance-event/${this.danceEvent.id}/tickets/checkout`}});
      } else {
        if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && !this.showGuestInputFields) {
          this.showGuestInputFields = true;
        }
        else{
          if(!this.authService.getSyncCurrentUser() && this.subtotal === 0 && this.guestEmailForm.valid){
            createTicketOrder.userGuestEmail = this.guestEmailForm.get('email').value;
            this.creationTicketOrderLoading = true;
            this.ticketOrderService.createTicketOrderForTickets(createTicketOrder, this.danceEvent.id!);
          }
          else if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && this.guestEmailForm.invalid) {
            console.log('SHOW ERROR');
          }
          else {
            this.creationTicketOrderLoading = true;
            this.ticketOrderService.createTicketOrderForTickets(createTicketOrder, this.danceEvent.id);
          }
          
        }
        
      }
    }
  }
  

  detectChanges(): void {
    this.cdRef.markForCheck();
    this.cdRef.detach();
    this.cdRef.detectChanges();
    this.cdRef.reattach();
  }

  navigateToEventPage(): void  {
    this.router.navigate(['dance-event', this.danceEvent.id]);
  }

}
