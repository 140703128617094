import {
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { GenericWrapperComponent } from './generic-wrapper.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GenericWrapperRoutingModule } from './generic-wrapper-routing.module';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/de';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  NativeDateAdapter
} from '@angular/material/core';
import { HeaderAppModule } from '@platri/df-angular-header';
import {
  CustomMatSnackbarService,
  OmniWebsocketService,
  TranslocoStateConst
} from '@platri/dfx-angular-core';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { environmentForWeb } from '@platri/df-common-core';
import { TranslocoRootModule } from './transloco-root.module';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { customHeaderInterceptorProviders } from '../helpers/custom-header.interceptor';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  // @ts-ignore
  format(date: Date, displayFormat: Object): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [GenericWrapperComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    HammerModule,
    GenericWrapperRoutingModule,
    HeaderAppModule
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environmentForWeb.googleAuthClientId
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    { provide: TRANSLOCO_SCOPE, useValue: TranslocoStateConst},
    { provide: LOCALE_ID, useValue: 'de-DE'},
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    OmniWebsocketService,
    customHeaderInterceptorProviders
  ],
  bootstrap: [GenericWrapperComponent],
  exports: [GenericWrapperComponent],
})
export class GenericWrapperModule {
  constructor(
    private omniWebsocketService: OmniWebsocketService
  ) {
    console.log('GenericWrapperModule initialized');
  }
}
