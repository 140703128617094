import { ImageDto } from './image.dto';
import { DanceSchoolRoomTypeEnum, RoomInterface } from '../../..';
import { DanceSchoolLocationDto } from './dance-school-location.dto';
import { AmenityDto } from './amenity.dto';

export class DanceSchoolRoomDto implements RoomInterface {
  id: string;
  name: string;
  size: number;
  type: DanceSchoolRoomTypeEnum;
  images: ImageDto[];
  amenities: AmenityDto[];
  locationId: string;
  location?: DanceSchoolLocationDto;
  rooms: DanceSchoolRoomDto[];
}
