import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GenderEnum, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-gender-form-settings',
  templateUrl: './gender-form-settings.component.html',
  styleUrls: ['./gender-form-settings.component.scss']
})
export class GenderFormSettingsComponent implements OnInit {
  @Input() currentUser: UsersInterface;
  @Output() genderSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  public Gender = GenderEnum;
  isSubmitting=true;
  formGroup: UntypedFormGroup;
  constructor(private readonly formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }
  initForm(): void {
    this.formGroup = this.formBuilder.group({
      gender: [this.currentUser.gender, Validators.required],

    });
  }

  saveGenderData(): void {
    if (this.formGroup.valid) {
      const user = {
        gender: this.formGroup.value.gender,

      };
      this.genderSaved.emit(user);
      this.isSubmitting=true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }

}
