import { DanceClassToUserTypeEnum } from '../../..';
import { DanceClassDto } from './dance-class.dto';
import { UserDto } from './user.dto';

export class DanceClassToUserDto{
  id?: string;
  danceClassToUserType?: DanceClassToUserTypeEnum;
  danceClass?: DanceClassDto;
  user?: UserDto;
  createdAt?: Date;
  updatedAt?: Date;
}
