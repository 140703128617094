import { BookMePostingCategoryEnum, RadiusUnitEnum } from '../../enums';
import { ImageInterface } from '../image.interface';
import { PlaceInterface } from '../place.interface';
import { UsersInterface } from '../users';
import { CurrencyInterface } from '../currency.interface';
import { MusicStyleInterface } from '../music-style.interface';

export interface BookMePostingInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  title?: string;
  price?: number;
  currency?: CurrencyInterface;
  description?: string;
  mainImage?: ImageInterface;
  images?: ImageInterface[];
  bookMePostingCategory?: BookMePostingCategoryEnum;
  user?: UsersInterface;
  travelDistance?: number;
  place?: PlaceInterface;
  distance?: number;
  distanceUnit?: RadiusUnitEnum;
  musicStyles?: MusicStyleInterface[];
}
