import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { GenericWrapperComponent } from './generic-wrapper.component';
import { dfConfirmEmailRoute, dfConfirmEmailTokenRouterParam, dfForgotPasswordRoute, dfLoginRoute, dfMagicLinkLoginRoute, dfMagicLinkLoginTokenRouterParam, dfMagicLoginRoute, dfRegisterRoute, dfResetPasswordRoute, dfResetPasswordTokenRouterParam, dfSignUpRoute, dfUrlNameRouterParam, NoAuthGuard } from '@platri/dfx-angular-core';

const routes: Routes = [
  {
    path: '',
    component: GenericWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.LandingPageModule),
      }
    ]
  },
  {
    path: dfLoginRoute,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.LoginPageModule)
  },
  {
    path: dfRegisterRoute,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.RegisterPageModule)
  },
  {
    path: dfSignUpRoute,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.RegisterPageModule)
  },
  {
    path: dfMagicLoginRoute,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.MagicLinkLoginPageModule)
  },
  {
    path: dfForgotPasswordRoute,
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.ForgotPasswordPageModule)
  },
  {
    path: dfResetPasswordRoute + '/:' + dfResetPasswordTokenRouterParam,
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.ResetPasswordPageModule)
  },
  {
    path: dfConfirmEmailRoute + '/:' + dfConfirmEmailTokenRouterParam,
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.ConfirmEmailPageModule)
  },
  {
    path: dfMagicLinkLoginRoute + '/:' + dfMagicLinkLoginTokenRouterParam,
    canActivate: [NoAuthGuard],
    loadChildren: () => import('@platri/df-angular-auth').then((m) => m.MagicLinkLoginTokenPageModule)
  },
  {
    path: 'dance-manager/process-subscription',
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.ProcessSubscriptionPageModule),
  },
  {
    path: ':' + dfUrlNameRouterParam,
    loadChildren: () => import('@platri/df-angular-profile').then((m) => m.FrontendDfProfileAppModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class GenericWrapperRoutingModule {
  constructor() {
    console.log('GenericWrapperRoutingModule initialized');
  }
}
