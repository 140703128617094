<ng-container *transloco="let t">
  <div class="flex-column align-space-between">
    <div class="flex-row align-space-between center w-100">
      <span class="ticket-name">{{ sub.name }}</span>
      <div class="flex-column align-end">
        <span><strong>{{sub.price | centsToCurrencyString : 'EUR'}}</strong></span>
        <span *ngIf="sub.isPaidMonthly">
          {{ t('GENERIC_WRAPPER.PER_MONTH') }}
        </span>
        <span *ngIf="!sub.isPaidMonthly">
          {{ t('GENERIC_WRAPPER.PER') + ' ' + (sub.durationValue > 1 ? sub.durationValue + ' ' : '') + t(sub.durationUnit + (sub.durationValue > 1 ? '_PLURAL' : '_SINGULAR'))}}
        </span>
      </div>
    </div>
    
    <div class="usage-text flex-column" style="margin-bottom: 1em;">
      <span>{{ sub.weeklyUsage ? sub.weeklyUsage + (t('GENERIC_WRAPPER.SUB_MANAGER.USAGES_PER_WEEK')) : (t('GENERIC_WRAPPER.SUB_MANAGER.USAGES_PER_WEEK_UNLIMITED'))}}</span> 
      <span>{{ (t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION')) + ': ' + sub.durationValue + ' ' + t(sub.durationUnit + (sub.durationValue > 1 ? '_PLURAL' : '_SINGULAR')) }}</span>
    </div>
    
    <div class="flex-row w-100" *ngIf="sub.description" style="margin-bottom: 1em;">
      <div class="flex-column w-100">
        <div class="flex-column description-text ellipsis-2" style="gap: 0.5rem;">
          <div style="width: 100%" [innerHTML]="sub.description | dynamicTag"></div>
        </div>
      </div>
    </div>
    
    <div class="flex-row align-space-between">
      <div *ngIf="courses.length > 0">
        <span class="bold">{{ t('GENERIC_WRAPPER.SUB_MANAGER.COURSES_YOU_CAN_ACCESS') }}</span>
        <mat-list *ngIf="(courses.length <= 3) || (showAllAccessibleCourses && (courses.length > 3))">
          <mat-list-item class="max-height-25" *ngFor="let course of courses;">
            <ul>
              <a [href]="'/app/dance-class/' + course.id" target="_blank">
                <li class="bullet-point-text">{{ course.name }}</li>
              </a>
            </ul>
          </mat-list-item>
        </mat-list>
        <mat-list *ngIf="!showAllAccessibleCourses && (courses.length > 3)">
          <mat-list-item class="max-height-25" *ngFor="let course of courses | slice:0:3">
            <ul>
              <a [href]="'/app/dance-class/' + course.id" target="_blank">
                <li class="bullet-point-text">{{ course.name }}</li>
              </a>
            </ul>
          </mat-list-item>
        </mat-list>
        <a
          *ngIf="courses.length > 3"
          class="show-more-text"
          (click)="showAllAccessibleCourses = !showAllAccessibleCourses"
        >
          {{!showAllAccessibleCourses ? (t('GENERIC_WRAPPER.SHOW_MORE')) : (t('GENERIC_WRAPPER.SHOW_LESS')) }}
        </a>
      </div>
      <div class="align-end">
        <div class="flex-row-end-center w-100 row-center">
          <df-shared-lib-button
            *ngIf="!isOwnerOrAdmin"
            [isDisabled]="loading"
            [buttonLabel]="!userHasSubscription() ? (t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIBE')) : (t('GENERIC_WRAPPER.MANAGE_SUBSCRIPTIONS'))"
            (buttonClicked)="openStripeCheckoutSession()"
          ></df-shared-lib-button>
          <button mat-icon-button *ngIf="isOwnerOrAdmin && currentUser" (click)="routeToSubscriptionEdit(sub.id)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
