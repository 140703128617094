import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../lib').then((m) => m.DfAppModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfAppRoutingModule {
  constructor() {
    console.log('FrontendDfAppRoutingModule initialized');
  }
}
