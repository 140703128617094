<ng-container *transloco="let t">
  <mat-form-field *ngIf="!showChips; else withChips" class="w-100" appearance="outline">
    <mat-label>{{t('GENERIC_WRAPPER.CATEGORY') }}</mat-label>
      <input
        [formControl]="searchCategoryInput"
        matInput
        #categoryInput
        [placeholder]="t('GENERIC_WRAPPER.CATEGORY')"
        autocomplete="off"
        (click)="matAutocompleteTrigger.openPanel()"
        [matAutocomplete]="auto"
      >
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectCategory($event)"
      (closed)="checkCategoryClosed()"
      hideSingleSelectionIndicator
    >
      <mat-option    
        *ngFor="let category of filteredCategoriesList | async"
        [disabled]="category | isDisabled: selectedFilters:false"
        [value]="category"
        >{{ t('GENERIC_WRAPPER.EVENT_CATEGORIES.' + category) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="searchCategoryInput.hasError('required')" style="min-width: 225px;">{{ t('GENERIC_WRAPPER.CHOOSE_CATEGORY') }}</mat-error>
  </mat-form-field>
  <div *ngIf="selectedFilters?.length > 0 && !showChips">
    <mat-chip-listbox  #chipList aria-label="Fruit selection">
      <mat-chip-option       
        *ngFor="let category of selectedFilters"
        aria-label="Fruit selection"
        [removable]="true"
        [selectable]="false"
        (removed)="removeSelectedCategory(category)"
      >
        {{ t('GENERIC_WRAPPER.EVENT_CATEGORIES.' + category) }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  
  
  <ng-template #withChips>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.CATEGORY')}}</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let category of selectedFilters" (removed)="removeSelectedCategory(category)">
          {{ t('GENERIC_WRAPPER.EVENT_CATEGORIES.' + category) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          #categoryInput
          [placeholder]="t('GENERIC_WRAPPER.CATEGORY')"
          autocomplete="off"
          (click)="matAutocompleteTrigger.openPanel()"
          [formControl]="searchCategoryInput"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
        >
      </mat-chip-grid>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectCategory($event)"
        hideSingleSelectionIndicator
      >
        <mat-option    
          *ngFor="let category of categoriesList"        
          [disabled]="category | isDisabled: selectedFilters:false"
          
          [value]="category"
          
          >{{ t('GENERIC_WRAPPER.EVENT_CATEGORIES.' + category) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-template>
</ng-container>
