import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  textAreaForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: ConfirmationDialogInterface,
    private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.textAreaForm = this.fb.group({
      textareaFormInput: new UntypedFormControl('', [Validators.required]),
    });
  }
  confirm(): void {
    if (this.data.withTextArea) {
      if (this.textAreaForm.valid) {
        this.dialogRef.close({
          result: true,
          textAreaInput: this.textAreaForm.value.textareaFormInput,
        });
      }
    } else {
      this.dialogRef.close(true);
    }
  }
}
