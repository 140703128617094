import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordFormInterface } from './forgot-password-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getSubscription } from '@platri/elab-angular-core';
import { dfAppRoute, dfLoginRoute, noWhitespaceValidator, UserHttpService } from '@platri/dfx-angular-core';
import { emailRegex } from '@platri/df-common-core';

@Component({
  selector: 'elab-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulForgotPasswordEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  forgotPasswordFormGroup!: FormGroup<ForgotPasswordFormInterface>;

  subscriptions: Subscription = new Subscription();

  isInitialized = false;
  isWaitingForForgotResponseResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  forgotPasswordFailedWrongData = false;
  forgotPasswordFailedWithNoConnection = false;
  forgotPasswordFailedWithInternalServer = false;
  
  hasSuccessfullyForgotPassword = false;

  routerParams!: Params;
  returnUrlFromParam: string;
  
  constructor(
    private fb: FormBuilder,
    private userHttpService: UserHttpService,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.forgotPasswordFormGroup = this.createRegisterFormGroup();
  }

  createRegisterFormGroup(): FormGroup<ForgotPasswordFormInterface> {
    return this.fb.group<ForgotPasswordFormInterface>({
        email: this.fb.control('', [Validators.required, Validators.email, noWhitespaceValidator(), Validators.pattern(emailRegex)])
      }
    );
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.forgotPasswordFormGroup.valueChanges.subscribe(res => {
      this.forgotPasswordFailedWithNoConnection = false;
      this.forgotPasswordFailedWithInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }

  onSubmit(): void {
    if (this.forgotPasswordFormGroup.valid ) {
      this.isWaitingForForgotResponseResponse = true;
      this.forgotPasswordFailedWrongData = false;
      this.forgotPasswordFailedWithNoConnection = false;
      this.forgotPasswordFailedWithInternalServer = false;
      const {email} = this.forgotPasswordFormGroup.getRawValue();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.subscriptions.add(this.userHttpService.forgotPassword(email!).subscribe(res => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        }
        this.hasSuccessfullyForgotPassword = true;
        this.matSnackbar.open("Successfully send reset password mail!");
        this.isWaitingForForgotResponseResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onEmailProviderDynamicLinkError();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        if (error.status === 404) {
          this.onWrongData();
        }
        this.isWaitingForForgotResponseResponse = false;
      }));
    }
  }

  onWrongData(): void {
    this.forgotPasswordFailedWrongData = true;
  }

  onConnectionLost(): void {
    (this.forgotPasswordFailedWithNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.forgotPasswordFailedWithInternalServer = true;
  }
  
  onEmailProviderDynamicLinkError(): void {
    // todo: Handle current send email bug on geolab
    this.hasSuccessfullyForgotPassword = true;
    this.isWaitingForForgotResponseResponse = false;
  }

  routeToLogin(emitSuccessfulForgotPassword: boolean = false): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
    if (emitSuccessfulForgotPassword) {
      this.successfulForgotPasswordEmitter.next(true);
    }
  }

}
