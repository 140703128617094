import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {AmenityHttpService} from "../http-services/amenity-http.service";
import {tap} from "rxjs/operators";
import { AmenityDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class AmenityStateService {
  private amenitiesSubject: BehaviorSubject<AmenityDto[]> = new BehaviorSubject<AmenityDto[]>([]);
  private amenitySubject: BehaviorSubject<AmenityDto> = new BehaviorSubject<AmenityDto>(null);

  constructor(private readonly amenityHttpService: AmenityHttpService) {
    this.getAmenitiesFromBackend();
  }

  getSyncAmenities(): AmenityDto[] {
    return this.amenitiesSubject.getValue();
  }

  getAsyncAmenities(): Observable<AmenityDto[]> {
    return this.amenitiesSubject.asObservable();
  }

  private sendAmenities(amenities: AmenityDto[]) {
    this.amenitiesSubject.next(amenities);
  }

  getSyncAmenity(): AmenityDto {
    return this.amenitySubject.getValue();
  }

  getAsyncAmenity(): Observable<AmenityDto> {
    return this.amenitySubject.asObservable();
  }

  private sendAmenityChanges(amenities: AmenityDto) {
    this.amenitySubject.next(amenities);
  }

  getAmenitiesFromBackend(): void {
    this.amenityHttpService
      .getAllAmenities()
      .pipe(
        tap((amenities: AmenityDto[]) => this.sendAmenities(amenities))
      )
      .subscribe();
  }

  postAmenity(amenity: AmenityDto): void {
    this.amenityHttpService
      .postAmenity(amenity)
      .pipe(
        tap(() => this.getAmenitiesFromBackend())
      ).subscribe();
  }

  getAmenityByIconKey(iconKey: string): void {
    this.amenityHttpService
      .getAmenityByIconKey(iconKey)
      .pipe(
        tap(() => this.getAmenitiesFromBackend())
      ).subscribe();
  }

  putAmenityByIconKeyHttp(iconKey: string, amenity: AmenityDto): void {
    this.amenityHttpService
      .putAmenityByIconKey(iconKey,  amenity)
      .pipe(
        tap(() => this.getAmenitiesFromBackend())
      ).subscribe();
  }

  deleteAmenityByIconKey(iconKey: string): void {
    this.amenityHttpService
      .deleteAmenityByIconKey(iconKey)
      .pipe(
        tap(() => this.getAmenitiesFromBackend())
      ).subscribe();
  }
}
