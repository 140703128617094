import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DanceEventCoHostsInterface, DanceEventCoHostStatus, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';
import { DanceManagerStateService } from '../../services/state-services/dance-manager-state.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-event-co-hosts',
  templateUrl: './dance-event-co-hosts.component.html',
  styleUrls: ['./dance-event-co-hosts.component.scss'],
})
export class DanceEventCoHostsComponent implements OnInit, OnDestroy, OnChanges{
  @Input() coHosts: DanceEventCoHostsInterface[];
  
  coHostsToDisplay: DanceEventCoHostsInterface[];
  showAll = false;

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  
  private subscriptions = new Subscription();
  
  constructor(private danceManagerStateService: DanceManagerStateService) {}
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.danceManagerStateService.clear();
  }

  ngOnInit(): void {
    this.filterCoHostsByAccepted();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coHosts) {
      this.filterCoHostsByAccepted();
    }
  }

  filterCoHostsByAccepted(): void {
    this.coHostsToDisplay = this.coHosts ? this.coHosts.filter(coHost => coHost.status === DanceEventCoHostStatus.ACCEPTED) : [];
  }

}
