<ng-container *transloco="let t">
  <div class="flex-row-center-center padding-container">
    <mat-card class="round-corners mat-elevation-z3 event-show w-100">
      <div style="background: #ffffff 0 0 no-repeat padding-box" class="flex-column w-100-children"><div>
        <h2 class="py2 m0">{{ t('DANCE_MANAGER_EVENT.NEW_EVENT') }}</h2>
      </div>
      <div class="flex-column mb1">
        <mat-divider></mat-divider>
      </div>
        <div>
          <df-add-edit-event-rules
            [formGroup]="formGroup"
            [creationStepper]="true"
            [loading]="loading"
            (onCancelClick)="onCancelClick.emit()"
            (onSubmitClick)="onSubmitClick.emit($event)"
          ></df-add-edit-event-rules>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
