import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePictureDialogComponent } from './profile-picture-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImageUploadCropperModule } from '@platri/dfx-angular-core';

@NgModule({
  declarations: [ProfilePictureDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ImageUploadCropperModule
  ],
})
export class ProfilePictureDialogModule {}
