import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfQuillComponent } from './df-quill.component';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DfQuillComponent],
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [DfQuillComponent],
})
export class DfQuillModule {}
