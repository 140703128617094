import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddressInterface,
  CourseInterface,
  CustomRecurrenceTypeEnum,
  DanceManagerUserRoleEnum,
  DanceSchoolInterface,
  LocationInterface,
  TimeOperations,
  UsersInterface,
  WeekdaysStringEnum
} from '@platri/df-common-core';
import {
  CoursesHttpService,
  CoursesStateService,
  DanceSchoolStateService,
  dateOrderValidator,
  dfmCourseRoute,
  dfmDanceManagerRoute
} from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import {
  AppointmentRecurrenceTypeEnum,
  SchedulerTypeEnum
} from '@platri/df-common-shared-graphql';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { differenceInMinutes } from 'date-fns';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-create-appointments-page',
  templateUrl: './course-create-appointments-page.component.html',
  styleUrls: ['./course-create-appointments-page.component.scss']
})
export class CourseCreateAppointmentsPageComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  danceManager: DanceSchoolInterface;
  danceManagerUsers: UsersInterface[] = [];
  course: CourseInterface;
  selectedLocation: LocationInterface;

  addressInputType = 'location';
  today = new Date();
  endTimeIsLater = true;
  
  subscriptions = new Subscription();

  protected readonly SchedulerTypeEnum = SchedulerTypeEnum;
  protected readonly AppointmentRecurrenceTypeEnum = AppointmentRecurrenceTypeEnum;
  protected readonly WeekdaysStringEnum = WeekdaysStringEnum;
  protected readonly CustomRecurrenceTypeEnum = CustomRecurrenceTypeEnum;

  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private breadcrumbService: BreadcrumbService, 
    private activatedRoute: ActivatedRoute, 
    private coursesService: CoursesStateService, 
    private danceSchoolService: DanceSchoolStateService, 
    private coursesHttpService: CoursesHttpService, 
    private translocoService: TranslocoService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadDanceManager();
    this.createFormGroup();
    this.loadCourse();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  createFormGroup(): void {
    this.formGroup = this.fb.group({
      startDate: [null, [Validators.required]],
      duration: [],
      description: [null, [Validators.maxLength(1000)]],
      teacherUserIds: [null, [Validators.required]],
      schedulerType: [this.SchedulerTypeEnum.SINGLE],
      endDate: [null],
      recurrenceType: [AppointmentRecurrenceTypeEnum.DAILY],
      customRecurrenceType: [CustomRecurrenceTypeEnum.DAY],
      customRecurrenceNumber: [1],
      customRecurrenceWeekDays: [],
      customRecurrenceEnd: ['DATE'],
      numberOfAppointments: [1],
      maxParticipants: [1, [Validators.required, Validators.min(1)]],
      locationId: [null],
      address: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]]
    }, {validators: dateOrderValidator});

    this.formGroup.valueChanges.subscribe((change) => {
      if (change.schedulerType === SchedulerTypeEnum.SERIES) {
        this.formGroup.controls.endDate.addValidators(Validators.required);
      } else {
        this.formGroup.controls.endDate.removeValidators(Validators.required);
        this.formGroup.controls.endDate.updateValueAndValidity({emitEvent: false});
      }
      if (change.recurrenceType === AppointmentRecurrenceTypeEnum.CUSTOM) {
        this.formGroup.controls.customRecurrenceNumber.addValidators([Validators.required, Validators.min(1)]);
      } else {
        this.formGroup.controls.customRecurrenceNumber.removeValidators([Validators.required, Validators.min(1)]);
      }
      if (change.customRecurrenceEnd === 'NUMBER_OF_APPOINTMENTS') {
        this.formGroup.controls.numberOfAppointments.addValidators([Validators.required, Validators.min(1)]);
        this.formGroup.controls.numberOfAppointments.updateValueAndValidity({ emitEvent: false });
        this.formGroup.controls.endDate.removeValidators(Validators.required);
        this.formGroup.controls.endDate.updateValueAndValidity({ emitEvent: false });
      } else {
        this.formGroup.controls.numberOfAppointments.clearValidators();
        this.formGroup.controls.numberOfAppointments.updateValueAndValidity({ emitEvent: false });
        this.formGroup.controls.endDate.addValidators(Validators.required);
      }
      if (change.customRecurrenceType === CustomRecurrenceTypeEnum.WEEK) {
        this.formGroup.controls.customRecurrenceWeekDays.addValidators(Validators.required);
      } else {
        this.formGroup.controls.customRecurrenceWeekDays.removeValidators(Validators.required);
      }
      if(change.startTime !== null && change.endTime !== null) {
        this.endTimeIsLater = this.calculateEndTimeIsLater(change.startTime, change.endTime);
      }
    });
  }

  calculateEndTimeIsLater(startTime: string, endTime: string): boolean {
    function parseTime(time: string): Date {
      const [hours, minutes] = time.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }

    return parseTime(endTime) > parseTime(startTime);
  }

  loadCourse(): void {
    this.course = this.activatedRoute.snapshot.data.targetObject;
    this.breadcrumbService.set('@courseName', this.course.name);
  }
  
  loadDanceManager(): void {
    this.danceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
    this.danceManagerUsers = this.danceManager.danceSchoolToUsers
      .filter((danceSchoolUser) => [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN, DanceManagerUserRoleEnum.TEACHER].includes(danceSchoolUser.danceSchoolUserRole))
      .map((danceSchoolUser) => danceSchoolUser.user);

    if (this.danceManager.locations.length === 0) {
      this.addressInputType = 'manual';
    }
  }

  onLocationSelected(location: LocationInterface): void {
    this.selectedLocation = location;
    this.formGroup.controls.address.setValue(location.address);
  }

  onAddressSelected(address: AddressInterface): void {
    this.formGroup.controls.address.setValue(address);
    this.selectedLocation = null;
    this.formGroup.controls.locationId.reset();
  }
  
  onSaveClick(): void {
    const inputFormData = this.formGroup.getRawValue();
    const appointmentScheduler = {
      courseId: this.course.id,
      ...inputFormData,
      duration: this.calculateDurationOfTimes(inputFormData.startTime, inputFormData.endTime)
    };

    appointmentScheduler.startDate = DateTime.now()
      .setZone('UTC')
      .set({
        day: new Date(inputFormData.startDate).getDate(),
        month: new Date(inputFormData.startDate).getMonth() + 1,
        year: new Date(inputFormData.startDate).getFullYear(),
        hour: TimeOperations.stringToTime(appointmentScheduler.startTime).hours,
        minute: TimeOperations.stringToTime(appointmentScheduler.startTime).minutes,
        second: 0,
        millisecond: 0
      })
      .toString();
    if (appointmentScheduler.endDate) {
      appointmentScheduler.endDate = DateTime.now()
        .setZone('UTC')
        .set({
          day: new Date(inputFormData.endDate).getDate(),
          month: new Date(inputFormData.endDate).getMonth() + 1,
          year: new Date(inputFormData.endDate).getFullYear(),
          hour: TimeOperations.stringToTime(appointmentScheduler.startTime).hours,
          minute: TimeOperations.stringToTime(appointmentScheduler.startTime).minutes,
          second: 0,
          millisecond: 0
        })
        .toString();
    }

    delete appointmentScheduler.startTime;
    delete appointmentScheduler.endTime;
    
    this.coursesHttpService.createCourseAppointmentScheduler(appointmentScheduler).subscribe({
      next: () => {
        this.coursesHttpService.getById(this.course.id, true).subscribe({
          next: (course: CourseInterface) => {
            this.course = course;
            this.coursesService.sendCourse(course);
            this.coursesService.sendLoading(false);
            this.openSnackBarCreateAppointmentsSuccess();
            this.router.navigate([dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmCourseRoute, 'preview', this.course.id], {fragment: 'appointments'});
          }
        });
      },
      error: (err) => {
        this.coursesService.sendLoading(false);
      }
    });
  }

  calculateDurationOfTimes(startTime: string, endTime: string): number {
    const startDateTime = new Date();
    startDateTime.setHours(+(startTime.split(':')[0]));
    startDateTime.setMinutes(+(startTime.split(':')[1]));
    startDateTime.setSeconds(0);
    const endDateTime = new Date();
    endDateTime.setHours(+(endTime.split(':')[0]));
    endDateTime.setMinutes(+(endTime.split(':')[1]));
    endDateTime.setSeconds(0);
    return differenceInMinutes(endDateTime, startDateTime);
  }

  openSnackBarCreateAppointmentsSuccess(): void {
    const message = this.translocoService.translate('DANCE_MANAGER_COURSE.SNACKBAR.CREATED_APPOINTMENT_SUCCESSFUL');
    this.matSnackBar.open(message);
  }
  
  onCancelClick(): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmCourseRoute, 'preview', this.course.id], {fragment: 'appointments'});
  }
  
  changeAddressInput(addressInputType: string): void {
    if (addressInputType) {
      this.addressInputType = addressInputType;
    }
  }
}
