<ng-container *transloco="let t">
  <div mat-dialog-title class="marginb05">
    <span class="fs16 bold">
      {{ t('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.TITLE') }}
    </span>
  </div>
  <div mat-dialog-content class="marginb1">
    <span class="fs14">
      {{ t('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CONTENT') }}
    </span>
  </div>
  <div class="flex-row-center" style="gap: 10px;">
    <df-shared-lib-button
      [buttonLabel]="t('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CANCEL_WITHOUT_SAVE')"
    ></df-shared-lib-button>
  
    <df-shared-lib-button
      [buttonLabel]="t('DANCE_MANAGER_EVENT.EVENT_CANCEL_DIALOG.CANCEL_WITH_SAVE')"
    ></df-shared-lib-button>
  </div>
</ng-container>
