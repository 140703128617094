import { MusicStyleStatusEnum } from '../enums';

export const MUSIC_STYLES = [
  {
    id: "d0136a75-5aca-4b22-a1b1-762c17aec503",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "jazz"
  },
  {
    id: "21558512-00f0-43f1-a64a-fac1ccb74f66",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "pop"
  },
  {
    id: "eabfbaf7-d6e0-4d6b-accc-c49be162f6c3",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "rock"
  },
  {
    id: "03f5ba26-7008-4949-a699-e77ea7ca6e05",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "hip-hop"
  },
  {
    id: "6aefffeb-33ab-486e-8234-93207ff97432",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "blues"
  },
  {
    id: "ae548c2c-f289-42f7-a110-11b6f9b3ab23",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "edm"
  },
  {
    id: "fbaebb45-c0da-4098-95ed-c8225cf62e07",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "classical"
  },
  {
    id: "99e507c6-7f1e-49cc-b60e-3684a0a5c174",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "reggae"
  },
  {
    id: "781e9d60-50ad-42bf-bc41-64dc5ab15951",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "funk"
  },
  {
    id: "a980c13c-eac6-4245-b67b-38edae2ac68d",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "soul"
  },
  {
    id: "56aba355-ae61-4f52-92b2-9ea77a4310ce",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "country"
  },
  {
    id: "ce522736-57fd-457e-9b5e-e6cb8efb16cc",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "folk"
  },
  {
    id: "ebac6522-99db-4e13-aac2-eab4060ebb4f",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "r&b"
  },
  {
    id: "d5643bcc-4a7c-4f74-a966-2f670c0f04af",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "metal"
  },
  {
    id: "47334f0a-e5cd-4134-ac14-5a9c791018df",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "punk"
  },
  {
    id: "9fc3e79e-7392-4785-a536-5bffa4a1024e",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "indie"
  },
  {
    id: "4d036f55-6eb9-481e-b257-a339a2cb8023",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "salsa"
  },
  {
    id: "e4fbebfa-dd57-4070-96f1-c407f6e4f31d",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "merengue"
  },
  {
    id: "e7eeee0c-5d01-4e00-90c3-4c357e846195",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "bachata"
  },
  {
    id: "bf5ee9b9-31f3-400f-9a35-25c39ee4e50a",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "cha-cha-cha"
  },
  {
    id: "d42e449f-c3f9-450e-a676-59fbc52cf404",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "rumba"
  },
  {
    id: "60ecfe4e-6797-4f4c-99a3-e6a4f01a7a08",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "cumbia"
  },
  {
    id: "ab3c1a9d-a6b1-4875-8038-515c33d46b0c",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "samba"
  },
  {
    id: "06fca8dd-66ce-4f2a-9dcf-a4fcea94f051",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "tango"
  },
  {
    id: "cd82ea07-77cc-4a32-8fac-14bb47f6353c",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "discofox"
  },
  {
    id: "7f86abaa-0e78-4d03-aa3a-92d080616b02",
    musicStyleStatus: MusicStyleStatusEnum.ACTIVE,
    translationKey: "schlager"
  }
];
