import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'df-shared-lib-slider-gradient',
  templateUrl: './slider-gradient.component.html',
  styleUrls: ['./slider-gradient.component.scss'],
})
export class SliderGradientComponent {
  @Input() styleClass: string;
  @Input() minValue = 0;
  @Input() maxValue = 100;
  @Input() stepSize = 1;
  @Input() currentStep = 0;

  @Output() currentStepChanged = new EventEmitter<number>();

  onMatSliderChange(value: number): void {
    this.currentStepChanged.emit(value);
  }
}
