import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  DanceManagerService,
  DanceSchoolHttpService,
  DanceStudioImagesDialogComponent,
  DfmStateService
} from '@platri/dfx-angular-core';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-gallery',
  templateUrl: './dfm-gallery.component.html',
  styleUrls: ['./dfm-gallery.component.scss'],
})
export class DfmGalleryComponent {
  @Input() danceSchoolImageUrls: string[];

  @Output() closeEditMode = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private dfmStateService: DfmStateService,
    private danceManagerService: DanceManagerService
  ) {}

  deleteImage(imageUrl: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.GALLERY_PICTURE_TITLE'),
          text: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.GALLERY_PICTURE_SUBTITLE'),
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const imageUrlSplit = imageUrl.split('/');
          const fileName = imageUrlSplit[imageUrlSplit.length - 1];
          this.danceSchoolHttpService
            .deleteGalleryImage(this.dfmStateService.getSyncSelectedDm().id, fileName)
            .pipe(map((danceSchool) => this.danceManagerService.getDanceManagerById(danceSchool.id)))
            .subscribe();
        }
      });
  }

  openImageUploadDialog(): void {
    this.dialog.open(DanceStudioImagesDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      minWidth: '300px',
      width: '50vw',
    });
  }
}
