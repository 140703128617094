<ng-container *transloco="let t">
  <div class="container w-100 align-center flex-column">
    <div class="banner"></div>
    <div class="content-layout flex-content py2">
      <div *ngIf="profile" [ngClass]="((profile?.languageSkills && profile?.languageSkills.length > 0) || profile?.danceStyles.length > 0) && (teachesForCourses.length > 0 || activeAtDanceManagers.length > 0 ) ?'flex-profile-header-with-two-elements' : 'flex-profile-header'">
        <df-profile-header
          [profile]="profile"
          [isCurrentUser]="isCurrentUser">
        </df-profile-header>
      </div>
      <div class="flex-row flex-profile-content" *ngIf="(profile?.languageSkills && profile?.languageSkills.length > 0) || profile?.danceStyles.length > 0">
        <div class="flex-column flex-profile-content">
          <mat-card
            *ngIf="profile?.danceStyles.length > 0"
            class="br-20 mb2 transparent-container"
            style="padding: 16px;">
            <div class="fs18 bold">
              {{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}
            </div>
            <div class="pb1 pt1 divider-wrapper">
              <mat-divider class="w-100"></mat-divider>
            </div>
            <div *ngFor="let danceStyle of profile.danceStyles; let i = index">
              <df-shared-lib-level-bar
                [color]="levelBarColorEnum.PRIMARY"
                [label]="t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) + ' ' +
                  t('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.' + (danceStyle.experience | danceSkillLevelPipe).toUpperCase()) +
                  (danceStyle.danceStyle?.isPairDance && (danceStyle.isFollower || danceStyle.isLeader) ? ' / ' + t(danceStyle.danceStyle?.isPairDance | danceSkillRolePipe : danceStyle.isFollower : danceStyle.isLeader) : '')"
                [endPoint]="danceStyle.experience">
              </df-shared-lib-level-bar>
            </div>
          </mat-card>
          <mat-card
             class="br-20 mb2 transparent-container"
             *ngIf="profile?.languageSkills && profile?.languageSkills.length > 0"
             style="padding: 16px;">
            <div class="fs18 bold">{{ t('USER.LANGUAGES') }}</div>
            <div class="pb1 pt1 divider-wrapper">
              <mat-divider class="w-100"></mat-divider>
            </div>
            <div *ngFor="let language of profile.languageSkills; let i = index">
              <df-shared-lib-level-bar
                  [color]="levelBarColorEnum.SECONDARY"
                  [label]="t('GENERIC_WRAPPER.PROFILE_LANGUAGE.' + language.key.toUpperCase()) "
                  [endPoint]="language.languageLevel | languageLevelAsNumberPipe">
                </df-shared-lib-level-bar></div>
              </mat-card>
          </div>
        </div>
      <div class="flex-column flex-profile-content" *ngIf="teachesForCourses.length > 0 || activeAtDanceManagers.length > 0">
        <mat-card class="br-20 mb2 transparent-container" *ngIf="activeAtDanceManagers.length > 0">
          <div class="flex-column content-container p2">
            <span class="fs18 bold">{{ t('USER.ACTIVE_AT') }}</span>
            <div class="pb1 pt1 divider-wrapper">
              <mat-divider class="w-100"></mat-divider>
            </div>
            <div *ngFor="let activeAtDanceSchools of activeAtDanceManagers; let isFirst = first" [ngStyle]="{ 'padding-top': isFirst ? '6px' : '12px' }">
              <a [href]="'/app/@' + activeAtDanceSchools.urlName" style="text-decoration:none">
                <div class="flex-row-start-center gap-10">
                  <img style="width: 50px; border-radius: 50%;" [src]="activeAtDanceSchools.imageUrl && activeAtDanceSchools.imageUrl !== '' ? activeAtDanceSchools.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" alt="" imageFallback [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
                  <span class="dance-manager-link-text">{{ activeAtDanceSchools.name }}</span>
                </div>
              </a>
            </div>
          </div>
        </mat-card>
        <mat-card class="br-20 mb2 transparent-container" *ngIf="teachesForCourses.length > 0">
          <div class="flex-column content-container p2">
            <span class="fs18 bold">{{ t('USER.TEACHER_FOR') }}</span>
            <div class="pb1 pt1 divider-wrapper">
              <mat-divider class="w-100"></mat-divider>
            </div>
            <div *ngFor="let activeAtDanceSchools of teachesForCourses; let isFirst = first;" [ngStyle]="{ 'padding-top': isFirst ? '6px' : '12px' }">
              <a [href]="'/app/dance-class/' + activeAtDanceSchools.id" style="text-decoration:none">
                <div class="flex-row-start-center gap-10">
                  <span class="dance-manager-link-text">{{ activeAtDanceSchools.name }}</span>
                </div>
              </a>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
