import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { DanceManagerHttpService, DanceSchoolHttpService } from '../http-services';
import { DanceManagerFeatureInterface, DanceManagerSubscriptionInterface } from '@platri/df-common-core';
import { DfmStateService } from './dfm-state.service';
import { DanceSchoolStateService } from './dance-school-state.service';

@Injectable({
  providedIn: 'root'
})
export class DanceManagerService {
  private danceManagerFeatures: BehaviorSubject<DanceManagerFeatureInterface[]> = new BehaviorSubject<DanceManagerFeatureInterface[]>([]);

  #latestDanceManagerSubscription$ = new BehaviorSubject<DanceManagerSubscriptionInterface | null>(null);
  #danceManagerSubscriptionLoading$ = new BehaviorSubject<boolean>(false);

  latestDanceManagerSubscription$ = this.#latestDanceManagerSubscription$.asObservable();

  danceManagerSubscriptionLoading$ = this.#danceManagerSubscriptionLoading$.asObservable();
  
  subscriptions = new Subscription();

  constructor(
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly danceSchoolStateService: DanceSchoolStateService,
    private readonly dfmStateService: DfmStateService
  ) {}

  public getAsyncDanceManagerFeatures(): Observable<DanceManagerFeatureInterface[]> {
    return this.danceManagerFeatures.asObservable();
  }

  public getSyncDanceManagerFeatures(): DanceManagerFeatureInterface[] {
    return this.danceManagerFeatures.getValue();
  }

  public sendDanceManagerFeatures(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.danceManagerFeatures.next(danceManagerFeatures);
  }

  sendLatestDanceManagerSubscription(danceManagerSubscription: DanceManagerSubscriptionInterface): void {
    this.#latestDanceManagerSubscription$.next(danceManagerSubscription);
  }

  sendDanceManagerSubscriptionLoading(loading: boolean): void {
    this.#danceManagerSubscriptionLoading$.next(loading);
  }
  
  // BUSINESS LOGIC

  getDanceManagerFeaturesById(id: string): void {
    this.danceManagerHttpService
      .getDanceManagerFeaturesById(id)
      .pipe(tap((res) => this.sendDanceManagerFeatures(res)))
      .subscribe();
  }

  getLatestSubscriptionByDanceManagerId(id: string): void {
    this.sendDanceManagerSubscriptionLoading(true);
    this.danceManagerHttpService
      .getSubscriptionByDanceManagerId(id)
      .pipe(
        tap((res) => {
          this.sendLatestDanceManagerSubscription(res);
          this.sendDanceManagerSubscriptionLoading(false);
        }),
        catchError((err) => {
          this.sendDanceManagerSubscriptionLoading(false);
          throw err;
        })
      )
      .subscribe();
  }

  cancelSubscriptionByDanceManagerIdAndDanceManagerSubscriptionId(danceManagerId: string, danceManagerSubscriptionId: string): void {
    this.sendDanceManagerSubscriptionLoading(true);
    this.danceManagerHttpService
      .cancelSubscriptionById(danceManagerId, danceManagerSubscriptionId)
      .pipe(delay(2000))
      .subscribe({
        next: () => {
          this.getLatestSubscriptionByDanceManagerId(danceManagerId);
        },
        error: (err) => {
          this.sendDanceManagerSubscriptionLoading(false);
          throw err;
        }
      });
  }

  reactivateSubscriptionByDanceManagerId(danceManagerId: string): void {
    this.sendDanceManagerSubscriptionLoading(true);
    this.danceManagerHttpService
      .reactivateSubscriptionByDanceManagerId(danceManagerId)
      .pipe(delay(2000))
      .subscribe({
        next: () => {
          this.getLatestSubscriptionByDanceManagerId(danceManagerId);
        },
        error: (err) => {
          this.sendDanceManagerSubscriptionLoading(false);
          throw err;
        }
      });
  }

  getSubscriptionByCheckoutSessionId(checkoutSessionId: string): void {
    this.sendDanceManagerSubscriptionLoading(true);
    this.danceManagerHttpService
      .getSubscriptionByCheckoutSessionId(checkoutSessionId)
      .pipe(
        tap((res) => {
          this.sendLatestDanceManagerSubscription(res);
          this.sendDanceManagerSubscriptionLoading(false);
        }),
        catchError((err) => {
          this.sendDanceManagerSubscriptionLoading(false);
          throw err;
        })
      )
      .subscribe();
  }

  getDanceManagerById(id: string): void {
    this.danceManagerHttpService.getDanceManagerById(id).subscribe({
      next: (danceManager) => {
        this.dfmStateService.sendSelectedDm(danceManager);
        this.danceManagerHttpService.getDanceManagerFeaturesById(danceManager.id).subscribe({
          next: (features) => {
            this.dfmStateService.sendSelectedDmFeatures(features);
          }
        });
        this.danceManagerHttpService.getSubscriptionByDanceManagerId(danceManager.id).subscribe({
          next: (danceManagerSubscription) => {
            this.dfmStateService.sendSelectedDmSubscription(danceManagerSubscription);
          }
        });
      }
    });
  }

  getDanceSchoolById(id: string): void {
    this.danceSchoolHttpService.getDanceSchoolById(id).subscribe((result) => {
      this.danceSchoolStateService.sendCurrentDanceSchool(result);
      this.getDanceManagerFeaturesById(id);
    });
  }

  // CLEAR

  sendClearDanceManagerFeatures(): void {
    this.danceManagerFeatures.next([]);
  }

  sendClearLatestDanceManagerSubscription(): void {
    this.#latestDanceManagerSubscription$.next(null);
  }
}
