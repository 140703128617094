<ng-container *transloco="let t">
  <div class="flex-row align-space-between align-center pt2 pb2">
    <div *ngIf="passesFeatureActive">
      <span class="course-title">{{t('GENERIC_WRAPPER.PASSES')}}</span>
    </div>
    <div>
      <df-shared-lib-button
        *ngIf="passesFeatureActive"
        [materialButtonStyleType]="'flat'"
        [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_PASS')"
        (buttonClicked)="navigateToCreatePassPage()"
      ></df-shared-lib-button>
    </div>
  </div>
  
  <df-course-passes-table
    *ngIf="passesFeatureActive"
    (editPassClicked)="navigateToEditPassPage($event)"
    (duplicatePassClicked)="navigateToCreatePassPage($event)"
  ></df-course-passes-table>
  
  <div *ngIf="!passesFeatureActive">
    <div class="align-center" style="padding-top: 113px;">
      <df-shared-empty-page
        [buttonLabel]="t('DANCE_MANAGER_COURSE.MARKETPLACE_BUTTON_TEXT')"
        [emptyTitle]="t('DANCE_MANAGER_COURSE.ACTIVATE_PASS_PACKET.TITLE')"
        [emptyDescription]="t('DANCE_MANAGER_COURSE.ACTIVATE_PASS_PACKET.TEXT')"
        [imagePath]="'assets/images/courses-manager/no_courses.svg'"
        (buttonClicked)="navigateToMarketplacePage()"
      ></df-shared-empty-page>
    </div>
  </div>
</ng-container>
