import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, debounceTime, map, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import {
  DanceSchoolHttpService,
  DfmStateService,
  LogoAndTitleUploadDialogComponent,
  MessageHttpService,
  SharedCancelDialogComponent
} from '@platri/dfx-angular-core';
import {
  DanceManagerCategoryEnum,
  DanceStyle
} from '@platri/df-common-shared-graphql';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DANCE_MANAGER_CATEGORY_REQUEST_FORM_DE,
  DANCE_MANAGER_CATEGORY_REQUEST_FORM_EN,
  DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE,
  DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN,
  DanceSchoolInterface,
  DanceStyleInterface,
  LogoAndTitleUploadDialogTypeEnum
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-general',
  templateUrl: './dfm-general.component.html',
  styleUrls: ['./dfm-general.component.scss'],
})
export class DfmGeneralComponent implements OnDestroy, OnInit {
  @Input() formGroup: UntypedFormGroup;

  @Output() onSubmitClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedFiltersChanged = new EventEmitter<DanceManagerCategoryEnum[]>();

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  
  public danceManagerCategoryEnum = DanceManagerCategoryEnum;
  filteredCategoriesList: Observable<DanceManagerCategoryEnum[]>;
  clonedCategoriesList: DanceManagerCategoryEnum[];
  selectedCategories: DanceManagerCategoryEnum[] = [];

  displayErrorCategorySelection = false;

  showCheckIcon = false;
  checkUrlLoading = false;

  regExUrlName = /^[a-zA-Z0-9]+(([_]?[a-zA-Z0-9]?)*[a-zA-Z0-9]+)$/g;

  danceSchool: DanceSchoolInterface;
  searchCategoryInput: UntypedFormControl;

  subscription = new Subscription();

  constructor(
    public readonly dialog: MatDialog,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
    private readonly matSnackBar: MatSnackBar,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private dfmStateService: DfmStateService,
    private messageHttpService: MessageHttpService
  ) {}

  get nameControl(): AbstractControl {
    return this.formGroup.get('name');
  }

  get urlNameControl(): AbstractControl {
    return this.formGroup.get('urlName');
  }

  get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  get categoryControl(): AbstractControl {
    return this.formGroup.get('danceManagerCategory');
  }

  get danceStylesControl(): AbstractControl {
    return this.formGroup.get('danceStyles');
  }
  
  ngOnInit(): void {
    this.searchCategoryInput = this.formBuilder.control({
      value: '',
      disabled: true,
    });

    this.initForm();
    this.loadDanceSchool();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(32)]],
      urlName: [[Validators.required, Validators.pattern(this.regExUrlName)]],
      description: [null, Validators.maxLength(2000)],
      danceManagerCategory: [],
      danceStyles: []
    });
  }

  loadDanceSchool(): void {
    this.dfmStateService
      .getAsyncSelectedDm()
      .subscribe((danceSchool) => {
        if (danceSchool) {
          this.danceSchool = danceSchool;
          this.formGroup.patchValue({
            name: danceSchool.name,
            urlName: danceSchool.urlName,
            description: danceSchool.description,
            danceManagerCategory: danceSchool.danceManagerCategory,
            danceStyles: danceSchool.danceStyles
          });
          this.formGroup.updateValueAndValidity();
          this.selectedCategories = danceSchool.danceManagerCategory ?? [];
          this.listenOnUrlNameChanges();
        }
      });
  }

  listenOnUrlNameChanges(): void {
    this.formGroup.controls.urlName.valueChanges
      .pipe(
        tap(() => (this.showCheckIcon = false)),
        debounceTime(300)
      )
      .subscribe((inputText: string) => {
        if (inputText.includes(' ')) {
          const newText = inputText.replace(/ /g, '_');
          this.formGroup.controls.urlName.setValue(newText);
        }
        if (this.formGroup.controls.urlName.value.match(this.regExUrlName)) {
          if (this.danceSchool.urlName !== inputText) {
            this.checkUrlLoading = true;
            this.messageHttpService.searchPartner(inputText).pipe(
              map(() => false),
              catchError((error) => {
                if (error.status === 404) {
                  return of(true);
                } else {
                  throw error;
                }
              })
            ).subscribe({
              next: (isAvailable: boolean) => {
                this.checkUrlLoading = false;
                if (!isAvailable) {
                  this.formGroup.controls.urlName.markAsTouched();
                  this.formGroup.controls.urlName.setErrors({ notUnique: true });
                  this.showCheckIcon = false;
                } else {
                  this.formGroup.controls.urlName.setErrors(null);
                  this.showCheckIcon = true;
                }
              },
            });
          }
        } else {
          this.checkUrlLoading = false;
          this.formGroup.controls.urlName.setErrors({
            invalidCharacters: true,
          });
        }
      });
  }

  updateDanceManager(): void {
    const data = {
      ...this.formGroup.getRawValue()
    };
    delete data.danceStyles;
    data.danceStyleIds = this.formGroup.getRawValue().danceStyles?.map((danceStyle) => danceStyle.id);
    
    this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchool.id, data).subscribe({
      next: () => {
        this.dfmStateService.loadAvailableData();
        this.router.navigate(['/dance-manager/' + this.dfmStateService.getSyncSelectedDm().id + '/administration']);
        this.showSnackBarChangesMadeSuccess();
      }
    });
    
  }

  showSnackBarChangesMadeSuccess(): void {
    const message = this.translocoService.translate('GENERIC_WRAPPER.SNACKBAR.SUCCESSFULLY_SAVED');
    this.matSnackBar.open(message);
  }

  getFilteredCategories(): void {
    this.clonedCategoriesList = Object.values(DanceManagerCategoryEnum);
    this.searchCategoryInput.valueChanges
      .pipe(debounceTime(250))
      .subscribe((input: string | DanceManagerCategoryEnum) => {
        this.filteredCategoriesList = of(
          this.clonedCategoriesList.filter((name) =>
            name
              .toLowerCase()
              .includes(typeof input === 'string' ? input.toLowerCase() : input)
          )
        );
      });
  }

  selectCategory(category: DanceManagerCategoryEnum): void {
    if (!this.selectedCategories?.includes(category)) {
      this.selectedCategories?.push(category);
    }
    this.categoryControl.setValue(null);
    this.getFilteredCategories();

    this.formGroup.patchValue({
      danceManagerCategory: this.selectedCategories,
    });

    this.selectedFiltersChanged.emit(this.selectedCategories);
  }

  removeSelectedCategory(category: DanceManagerCategoryEnum): void {
    const index = this.selectedCategories.indexOf(category);
    if (index >= 0) {
      this.selectedCategories.splice(index, 1);
  
      this.formGroup.patchValue({
        danceManagerCategory: [...this.selectedCategories],
      });
  
      this.selectedFiltersChanged.emit([...this.selectedCategories]);
    }
  }
  

  selectDanceStyle(danceStyle: DanceStyleInterface): void {
    this.formGroup.patchValue({
      danceStyles: this.danceStylesControl?.value
                   ? this.danceStylesControl!.value.concat([danceStyle])
                   : [danceStyle],
    });
  }

  removeSelectedDanceStyle(danceStyle: DanceStyle): void {
    let selectedDanceStyles = this.danceStylesControl?.value;
    const index = selectedDanceStyles.indexOf(danceStyle);
    if (index >= 0) {
      selectedDanceStyles.splice(index, 1);
      selectedDanceStyles =
        selectedDanceStyles.length === 0 ? null : [...selectedDanceStyles];
    }
    this.formGroup.patchValue({
      danceStyles: selectedDanceStyles,
    });
  }

  checkCategoryClosed():void {
    this.displayErrorCategorySelection =
      !this.selectedCategories || !this.selectedCategories.length;
  }
  checkOptionSelected(selection: DanceManagerCategoryEnum): boolean {
    if (this.selectedCategories) {
      return this.selectedCategories.includes(selection);
    } else {
      return false;
    }
  }

  deleteLogo(): void {
    this.danceSchoolHttpService
      .deleteDanceManagerLogoByDanceSchoolId(this.danceSchool.id!)
      .subscribe((danceSchool) => {
        this.danceSchool.imageUrl = danceSchool.imageUrl;
      });
  }

  confirmDeleteIcon(): void {
    const cancelDialogRef = this.dialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.LOGO_TITLE'),
        subtitle: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.LOGO_SUBTITLE'),
        infoText: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.CANCEL'),
        cancelText: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.DELETE'),
      },
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.deleteLogo();
      }
    });
  }

  confirmDeleteBackground(): void {
    const cancelDialogRef = this.dialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.COVER_PICTURE_TITLE'),
        subtitle: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.COVER_PICTURE_SUBTITLE'),
        infoText: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.CANCEL'),
        cancelText: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.DELETE'),
      },
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.danceSchoolHttpService
          .deleteDanceManagerTitleByDanceSchoolId(this.danceSchool.id!)
          .subscribe((danceSchool) => {
            this.danceSchool.titleImage = danceSchool.titleImage;
          });
      }
    });
  }

  openDanceSchoolLogoDialogComponent(): void {
    const dialogRef = this.dialog.open(LogoAndTitleUploadDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      width: '100%',
      maxWidth: '1140px',
      data: {
        id: this.danceSchool.id,
        mode: LogoAndTitleUploadDialogTypeEnum.STUDIO_LOGO,
      },
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.danceSchool.imageUrl = result.imageUrl;
        }
      })
    );
  }

  openTitleDialog(): void {
    const dialogRef = this.dialog.open(LogoAndTitleUploadDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      width: '100%',
      maxWidth: '1140px',
      data: {
        id: this.danceSchool.id,
        mode: LogoAndTitleUploadDialogTypeEnum.STUDIO_TITLE,
        ratio: 2,
      },
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.danceSchool.titleImage = result.titleImage;
        }
      })
    );
  }

  openDanceManagerCategoryRequestForm(): void {
    const url =
      this.translocoService.getActiveLang() === 'de'
      ? DANCE_MANAGER_CATEGORY_REQUEST_FORM_DE
      : DANCE_MANAGER_CATEGORY_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }

  openDanceManagerDanceStyleRequestForm(): void {
    const url =
      this.translocoService.getActiveLang() === 'de'
      ? DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE
      : DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
