import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'df-emojis',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss'],
})
export class EmojisComponent {
  @Output() selectedEmoji = new EventEmitter<string>();

  emojis = [
    '😄',
    '😃',
    '😀',
    '🙂',
    '😊',
    '😉',
    '😍',
    '😘',
    '😚',
    '😗',
    '😙',
    '😜',
    '😝',
    '😛',
    '😳',
    '😁',
    '😔',
    '😒',
    '😞',
    '😣',
    '😢',
    '😂',
    '😭',
    '😪',
    '😥',
    '😰',
    '😅',
    '😓',
    '😩',
    '😫',
    '😨',
    '😱',
    '😠',
    '😡',
    '😤',
  ];

  onClick(emoji: string): void {
    this.selectedEmoji.emit(emoji);
  }
}
