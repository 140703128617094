<ng-container *transloco="let t">
  <div class="flex-column">
    <div mat-dialog-title class="dialog-title">
      <span>{{ t('GENERIC_WRAPPER.UPLOAD_IMAGE') }}</span>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <image-cropper
      style="max-height: 460px !important;"
      [imageFile]="data.fileToCrop"
      [maintainAspectRatio]="true"
      [resizeToWidth]="resizeToWidth"
      [resizeToHeight]="resizeToHeight"
      [aspectRatio]="data.croppedImageRatio"
      [format]="'jpeg'"
      [output]="'base64'"
      (imageCropped)="croppedImage($event)"
    ></image-cropper>
  </div>
  <div class="flex-row-end" style="gap: 1rem;">
    <button mat-stroked-button mat-dialog-close>
      {{ t('GENERIC_WRAPPER.CANCEL') }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!formData"
      [mat-dialog-close]="submitData()"
    >
      {{ t('GENERIC_WRAPPER.SAVE') }}
    </button>
  </div>
</ng-container>
