<div class="w-100" style="height: 80%">
  <df-shared-lib-image-upload-cropper
    [croppedImageRatio]="data.ratio.width / data.ratio.height"
    [roundCropper]="data.mode?.includes('LOGO')"
    [isDialog]="true"
    (imageCropped)="imagePreview($event)"
    (uploadFormData)="onSubmit($event)"
  >
  </df-shared-lib-image-upload-cropper>
</div>
