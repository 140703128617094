import { AddressInterface } from '../../..';

export class DanceSchoolAddressDto implements AddressInterface {
  latitude: number;
  longitude: number;
  googleMapsUrl: string;
  googleMapsPlaceId: string;
  state: string;
  timezoneOffsetMinutes?: number;
  timezoneId?: string;
  name?: string;
}
