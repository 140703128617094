import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TeamMemberCardComponent } from './team-member-card.component';
import { ImageFallbackDirectiveModule } from '../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [TeamMemberCardComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    ImageFallbackDirectiveModule,
    TranslocoDirective
  ],
  exports: [TeamMemberCardComponent],
})
export class TeamMemberCardModule {}
