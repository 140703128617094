<ng-container *transloco="let t">
  <div [formGroup]="formGroup" style="position: relative; min-height: 45px" class="opacityAnimation">
    <div class="slider-container">
      <mat-slider [min]="min" [max]="max" [discrete]="showRange" [step]="step" [displayWith]="formatLabel" [disabled]="disabled">
        <input formControlName="start" matSliderStartThumb>
        <input formControlName="end" matSliderEndThumb>
      </mat-slider>
      <mat-error *ngIf="formGroup.errors?.rangeInvalid">{{t('GENERIC_WRAPPER.LEVEL_SLIDER_DIFF_VALUES_ERROR')}}</mat-error>
    </div>
        
    <div *ngIf="showLevels">
      <p>
        {{ textFrom }}
        <ng-container *ngIf="textFrom !== textTo">
          <ng-container>{{ t('GENERIC_WRAPPER.LEVEL_SLIDER_TO') }}</ng-container>
          {{ textTo }}
        </ng-container>
      </p>
    </div>
  </div>
</ng-container>
