import { NgModule } from '@angular/core';
import { DanceStudioOverviewAdComponent } from './dance-studio-overview-ad.component';
import { MatCard } from '@angular/material/card';
import { DfSharedButtonModule } from '../buttons';
import { NgIf } from '@angular/common';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceStudioOverviewAdComponent],
  exports: [DanceStudioOverviewAdComponent],
    imports: [
      MatCard,
      DfSharedButtonModule,
      NgIf,
      TranslocoDirective,
    ]
})
export class DanceStudioOverviewAdModule {}
