import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RedirectWordpressDialogComponent } from './redirect-wordpress-dialog.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [RedirectWordpressDialogComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    TranslocoDirective,
  ],
  exports: [RedirectWordpressDialogComponent],
})
export class RedirectWordpressDialogModule {}
