import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaypalPaymentModule } from '../payment-components';
import { TicketCheckoutPaypalPaymentComponent } from './ticket-checkout-paypal-payment.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  exports: [TicketCheckoutPaypalPaymentComponent],
  declarations: [TicketCheckoutPaypalPaymentComponent],
    imports: [
      CommonModule,
      PaypalPaymentModule,
      MatButtonModule,
      MatProgressSpinnerModule,
      TranslocoDirective,
    ],
})
export class TicketCheckoutPaypalPaymentModule {}
