import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthHttpService } from '@platri/dfx-angular-core';
import { LoginResponseDto } from '@platri/df-common-core';
import { ChatAppChatsTechnicalUserRouterQueryParam } from '../constants';
import { UserDto } from '@platri/elab-common-auth-plug-play';
import { ChatsComponent } from '../components';

@Injectable({
  providedIn: ChatsComponent
})
export class AuthTechnicalUserStateService implements OnDestroy {
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  accessToken: BehaviorSubject<string> = new BehaviorSubject<string>("");
  refreshToken: BehaviorSubject<string> = new BehaviorSubject<string>("");
  currentTechnicalUser: BehaviorSubject<UserDto | null> = new BehaviorSubject<UserDto | null>(null);
  subscriptions: Subscription = new Subscription();
  
  isLoggingIn = false;

  constructor(
    private authHttpService: AuthHttpService,
    private activeRoute: ActivatedRoute
  ) {
    console.log('Initializing AuthTechnicalUserStateService');
    this.activeRoute.queryParams.subscribe((queryParams) => {
      if (!queryParams[ChatAppChatsTechnicalUserRouterQueryParam]) {
        this.currentTechnicalUser.next(null);
      }
    })
  }
  
  ngOnDestroy(): void {
    console.log("Destroying AuthTechnicalUserStateService");
    this.isLoggedIn.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  login(technicalUserId: string): Observable<LoginResponseDto | null> {
    if (!this.isLoggingIn) {
      this.isLoggingIn = true;
      return this.authHttpService.signInDanceManager(technicalUserId).pipe(map((res) => {
        this.sendIsLoggedIn(true);
        if (res.refreshToken) {
          this.sendRefreshToken(res.refreshToken);
        }
        this.sendAccessToken(res.accessToken);
        this.isLoggingIn = false;
        return res;
      }));
    } else {
      return of(null);
    }
   
  }
  
  refreshTokens(): void {
    if (this.getSyncRefreshToken()) {
      this.authHttpService.refreshTokens(this.getSyncRefreshToken()).subscribe(res => {
        this.sendAccessToken(res.accessToken);
        if (res.refreshToken) {
          this.sendRefreshToken(res.refreshToken);
        }
      });
    }
  }
  
  logout(): void {
    if (!this.activeRoute.snapshot.queryParams[ChatAppChatsTechnicalUserRouterQueryParam]) {
      console.log("Technical User Logout");
      this.sendIsLoggedIn(false);
      this.sendAccessToken('');
      this.sendRefreshToken('');
    }
  }

  getSyncAccessToken(): string {
    return this.accessToken.getValue();
  }

  getAsyncAccessToken(): Observable<string> {
    return this.accessToken.asObservable();
  }

  private sendAccessToken(accessToken: string): void {
    this.accessToken.next(accessToken);
  }

  getSyncRefreshToken(): string {
    return this.refreshToken.getValue();
  }

  getAsyncRefreshToken(): Observable<string> {
    return this.refreshToken.asObservable();
  }

  private sendRefreshToken(refreshToken: string): void {
    this.refreshToken.next(refreshToken);
  }

  getSyncIsLoggedIn(): boolean {
    return this.isLoggedIn.getValue();
  }

  getAsyncIsLoggedIn(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  private sendIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn.next(isLoggedIn);
  }

  sendCurrentUser(user: UserDto): void {
    this.currentTechnicalUser.next(user);
  }
  
  getAsyncCurrentUser(): Observable<UserDto | null> {
    return this.currentTechnicalUser.asObservable();
  }

}
