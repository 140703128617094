import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { CoursesHttpService, CoursesStateService } from '../services';
import { dfAppointmentIdRouterParam, dfCourseIdRouterParam } from '../shared';

@Injectable({ providedIn: 'root' })
export class AppointmentResolver {
  constructor(private readonly coursesHttpService: CoursesHttpService, private readonly coursesService: CoursesStateService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const searchCourseAppointmentId = route.paramMap.get(dfAppointmentIdRouterParam);
    
    return this.coursesHttpService.getAppointmentById(searchCourseAppointmentId).pipe(
      tap((res) => {
        this.coursesService.sendAppointment(res);
        return res;
      }),
      catchError((error) => {
        this.router.navigateByUrl('/404');
        return EMPTY;
      })
    );
  }
}
