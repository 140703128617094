<ng-container *transloco="let t">
  <mat-divider class="hide-on-mobile"></mat-divider>
  <footer class="flex-row-center-center px2">
    <div class="footer-main" id="footer-main">
      <div class="footer-container flex-row align-space-between" style="font-size: 11px" [ngClass]="sidenav ? 'links py2' : 'links my2'">
        <div>
          <a style="margin-right: 8px;" [routerLink]="['/legal/imprint']">{{t('GENERIC_WRAPPER.IMPRINT')}}</a>
          <span style="margin-right: 8px;">|</span>
          <a style="margin-right: 8px;" [routerLink]="['/legal/terms-of-use']">{{t('GENERIC_WRAPPER.TERMS_OF_USE')}}</a>
          <span style="margin-right: 8px;">|</span>
          <a style="margin-right: 8px;" [routerLink]="['/legal/privacy-policy']">{{t('GENERIC_WRAPPER.PRIVACY_AND_POLICY')}}</a>
          <span style="margin-right: 8px;">|</span>
          <a style="margin-right: 8px;" [routerLink]="['/legal/licences']">{{t('GENERIC_WRAPPER.LICENCES')}}</a>
          <span style="margin-right: 8px;">|</span>
          <a style="margin-right: 8px;" contactUrl>{{t('GENERIC_WRAPPER.CONTACT')}}</a>
          <span style="margin-right: 8px;">|</span>
          <a style="margin-right: 8px;" href="https://www.danceflavors.com/status">{{t('GENERIC_WRAPPER.APPLICATION_STATUS')}}</a>
          <span style="margin-right: 8px;">|</span>
          <a style="margin-right: 8px;" [routerLink]="['/create-dance-manager']">{{t('GENERIC_WRAPPER.CREATE_DANCE_MANAGER')}}</a>
        </div>
  <!--      <span style="font-size: 10px" matTooltip=" App version: {{version}}">© {{ year }} Danceflavors GmbH  </span>-->
      </div>
    </div>
  </footer>
</ng-container>
