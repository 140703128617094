export enum UserPassSubscriptionStatusEnum {
  INITIALIZED = 'INITIALIZED',
  ACTIVE = 'ACTIVE',
  PAID = 'PAID',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  ACTIVE_PAYMENT_PENDING = 'ACTIVE_PAYMENT_PENDING',
  TERMINATED_PERIOD_END = 'TERMINATED_PERIOD_END',
  TERMINATED = 'TERMINATED',
  REFUND = 'REFUND'
}
