import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DanceSchoolToUserHttpService } from '../http-services';
import { ConfirmationDialogComponent } from '../../components';
import { DanceManagerUserRoleEnum, DanceSchoolToUserInterface } from '@platri/df-common-core';
import { DanceManagerHttpService } from '../http-services';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class DanceSchoolToUserStateService {
  isLoadingDanceManagerToUsers = new BehaviorSubject<boolean>(false);
  private currentDanceSchoolToUsersSubject: BehaviorSubject<DanceSchoolToUserInterface[]> = new BehaviorSubject<DanceSchoolToUserInterface[]>([]);

  constructor(
    private readonly danceSchoolToUserHttpService: DanceSchoolToUserHttpService,
    private readonly dialog: MatDialog, 
    private readonly translocoService: TranslocoService, 
    private readonly danceManagerHttpService: DanceManagerHttpService,
  ) {}

  getAsyncIsLoadingDanceManagerToUsers(): Observable<boolean> {
    return this.isLoadingDanceManagerToUsers.asObservable();
  }
  
  public getAsyncCurrentDanceSchoolToUsers(): Observable<DanceSchoolToUserInterface[]> {
    return this.currentDanceSchoolToUsersSubject.asObservable();
  }

  public getSyncCurrentDanceSchoolToUsers(): DanceSchoolToUserInterface[] {
    return this.currentDanceSchoolToUsersSubject.getValue();
  }

  public sendCurrentDanceSchoolToUsers(danceSchoolToUsers: DanceSchoolToUserInterface[]): void {
    if (danceSchoolToUsers) {
      this.currentDanceSchoolToUsersSubject.next(danceSchoolToUsers);
    }
  }

  clear(): void {
    this.currentDanceSchoolToUsersSubject.next([]);
  }

  // BUSINESS LOGIC

  getAllDanceSchoolToUsersByDanceSchoolId(danceSchoolId: string): void {
    this.sendIsLoadingDanceManagerToUsers(true);
    this.danceManagerHttpService
      .getDanceSchoolToUsersByDanceSchoolId(danceSchoolId)
      .pipe(
        map((result) => {
          this.sendCurrentDanceSchoolToUsers(result);
          this.sendIsLoadingDanceManagerToUsers(false);
        })
      )
      .subscribe({
        error: () => {
          this.sendIsLoadingDanceManagerToUsers(false);
        }
      });
  }

  //TODO: Fix methods when danceSchoolToUserHttpService.updateDanceSchoolToUsersHttp was changed to DTO input
  updateDanceSchoolToUser(danceSchoolToUser: DanceSchoolToUserInterface, role: DanceManagerUserRoleEnum): void {
    this.sendIsLoadingDanceManagerToUsers(true);
    const updatedDanceSchoolToUser: DanceSchoolToUserInterface = {
      ...danceSchoolToUser,
      danceSchoolUserRole: role
    };
    this.danceSchoolToUserHttpService.updateDanceSchoolToUsers(updatedDanceSchoolToUser)
      .pipe(
        map((danceSchoolToUserBackend) => {
          const currentDanceSchoolToUsers = this.getSyncCurrentDanceSchoolToUsers();
          currentDanceSchoolToUsers[currentDanceSchoolToUsers.findIndex((dsUser) => dsUser.id === danceSchoolToUserBackend.id)] = danceSchoolToUserBackend;
          this.sendCurrentDanceSchoolToUsers(currentDanceSchoolToUsers);
          this.sendIsLoadingDanceManagerToUsers(false);
        })
      )
      .subscribe({
        error: (err) => {
          this.translocoService.selectTranslate(`GENERIC_WRAPPER.DANCE_SCHOOL_TO_USER_ERROR_ENUM.${err.error.message}`).subscribe((translation) => this.openConfirmationDialogForError(translation));
          this.sendIsLoadingDanceManagerToUsers(false);
        }
      });
  }

  //TODO: Fix methods when danceSchoolToUserHttpService.deleteDanceSchoolToUsersHttp was changed to DTO input
  deleteDanceSchoolToUser(danceSchoolToUser: DanceSchoolToUserInterface): void {
    this.sendIsLoadingDanceManagerToUsers(true);
    this.danceSchoolToUserHttpService.deleteDanceSchoolToUsers(danceSchoolToUser)
      .pipe(map(() => this.getAllDanceSchoolToUsersByDanceSchoolId(danceSchoolToUser.danceSchoolId)))
      .subscribe({
        error: (err) => {
          this.translocoService.selectTranslate(`GENERIC_WRAPPER.DANCE_SCHOOL_TO_USER_ERROR_ENUM.${err.error.message}`).subscribe((translation) => this.openConfirmationDialogForError(translation));
          this.sendIsLoadingDanceManagerToUsers(false);
        }
      });
  }

  createDanceSchoolToUser(danceSchoolId: string, userId: string, danceSchoolUserRole: DanceManagerUserRoleEnum): Observable<void> {
    this.sendIsLoadingDanceManagerToUsers(true);
    return this.danceSchoolToUserHttpService.createDanceSchoolToUsers(danceSchoolId, userId, danceSchoolUserRole).pipe(
      map((danceSchoolToUserBackend) => {
        const currentDanceSchoolToUsers = this.getSyncCurrentDanceSchoolToUsers();
        currentDanceSchoolToUsers.push(danceSchoolToUserBackend);
        this.sendCurrentDanceSchoolToUsers(currentDanceSchoolToUsers);
        this.sendIsLoadingDanceManagerToUsers(false);
      }),
    );
  }

  // DIALOG LOGIC
  openConfirmationDialogForError(errorMessage: string): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        text: errorMessage,
        submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.OK'),
        submitButtonColor: 'primary'
      }
    });
  }

  sendIsLoadingDanceManagerToUsers(isLoading: boolean): void {
    this.isLoadingDanceManagerToUsers.next(isLoading);
  }

  // ROUTING LOGIC
}
