<ng-container *transloco="let t">
  <div
    class="event-tickets-checkout-complete-container w-100 h-100 flex-column-center-center">
    <div class="content-container">
        <ng-container [ngSwitch]="currentState">
          <ng-container *multiSwitchCases="stateInit">
          <div class="flex-column-center-center p4">
            <mat-spinner [diameter]="35"></mat-spinner>
            <span
              class="pt-20 content-title"> {{ t('GENERIC_WRAPPER.LOADING') }} </span>
            <span
              class="pt-20 content-text"> {{ t('GENERIC_WRAPPER.PAYMENT_IN_PROGRESS_DESC') }} </span>
          </div>
        </ng-container>
          
        <ng-container
          *multiSwitchCases="stateSuccess">
          <df-purchase-confirmation [ticketOrder]="ticketOrder"
                                    [confirmationText]="confirmationText"
                                    [danceManagerId]="danceManagerId"/>
        </ng-container>
        <ng-container *multiSwitchCases="stateError">
          <div class="flex-column-center-center p4">
            <img height="44" width="44"
                 src="assets/images/checkout-complete/error.png" alt=""/>
            <span
              class="pt-20 content-title"> {{ t(errorText.title) }} </span>
            <span
              class="pt-20 content-text"> {{ t(errorText.text) }} </span>
            <div class="pt-20 w-100 button-container">
              <df-shared-lib-button
                [buttonLabel]="t(errorText.navButton.label)"
                [materialButtonStyleType]="'flat'"
                (buttonClicked)="navigateToEventPage(errorText.navButton.navUrl)"/>
            </div>
            <span class="pt-20 content-text">
              {{ t('GENERIC_WRAPPER.NEED_HELP') }}
              <a href="mailto:support@danceflavors.com"
                 style="color: #0092cc; text-decoration: none"> {{ t('GENERIC_WRAPPER.CONTACT_US') }} </a></span
            >
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
