import { MessageReactionEnum } from '@platri/df-common-chat-plug-play';

export class ReactMessageRequestDto {
  messageId: number;
  recipientReaction: MessageReactionEnum;
  
  constructor(args: ReactMessageRequestDto) {
    if (args) {
      this.messageId = args.messageId;
      this.recipientReaction = args.recipientReaction;
    }
  }
}
