import { ParticipantRestrictionInterface } from '../../..';

export class CourseParticipantAgeRestrictionDto {
  fromAge?: number;
  toAge?: number;
}
export class CourseParticipantGenderRestrictionDto {
  isMaleRestricted?: boolean;
  isFemaleRestricted?: boolean;
  isDiverseRestricted?: boolean;
}
export class CourseParticipantRestrictionDto implements ParticipantRestrictionInterface {
  isAgeRestricted?: boolean;
  isGenderRestricted?: boolean;
  ageRestriction?: CourseParticipantAgeRestrictionDto;
  genderRestriction?: CourseParticipantGenderRestrictionDto;
}
