import { AppointmentUsersDto } from './appointment-users.dto';
import { AppointmentCommentsDto } from './appointment-comments.dto';
import { AppointmentsInterface } from '../../..';
import { AddressDto } from './address.dto';
import { DanceStyleDto } from './dance-style.dto';
import { LocationsDto } from './locations.dto';
import { RoomDto } from './room.dto';
import { SchedulersDto } from './schedulers.dto';
import { UserDto } from './user.dto';
import { DanceClassDto } from './dance-class.dto';

export class AppointmentsDto implements AppointmentsInterface {
  additionalInformation?: string;
  address?: AddressDto;
  appointmentToUsers?: AppointmentUsersDto[];
  cancellationReason?: string;
  cancelled?: boolean;
  clientTimezone?: string;
  comments?: AppointmentCommentsDto[];
  content?: string;
  danceStyles?: DanceStyleDto[];
  description?: string;
  duration?: number;
  id?: string;
  location?: LocationsDto;
  maxParticipants?: number;
  name?: string;
  room?: RoomDto;
  scheduler?: SchedulersDto;
  startDate?: Date;
  students?: UserDto[];
  teachers?: UserDto[];
  danceClass?: DanceClassDto;
  teacherUserIds?: string[];
}
