import { MessageStatusEnum, MessageTypeEnum } from '@platri/df-common-chat-plug-play';

export class CreateMessageRequestDto {
  recipientId: string;
  date: Date;
  value: string;
  status: MessageStatusEnum;
  type: MessageTypeEnum;
  repliedToMessageId?: number;
  
  constructor(args: CreateMessageRequestDto) {
    if (args) {
      this.recipientId = args.recipientId;
      this.date = args.date;
      this.value = args.value;
      this.status = args.status;
      this.type = args.type;
      this.repliedToMessageId = args.repliedToMessageId;
    }
  }
}
