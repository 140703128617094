import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AuthStateService,
  ConfirmationDialogComponent,
  mustMatch,
  PhonePrefixInterface,
  UserHttpService,
  UserProfileStateService
} from '@platri/dfx-angular-core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  BillingOrShippingAddressInterface,
  ConfirmationDialogInterface,
  DeleteOrDeactivateEnum,
  emailRegex,
  UsersInterface
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-account-settings',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  currentUser: UsersInterface;
  passwordForm: UntypedFormGroup;

  phoneForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  deleteDeactivateForm: UntypedFormGroup;
  deleteOrDeactivate: string;
  errorForEmailChange: string;
  errorForPasswordChange: string;
  errorForAccountDeletion: string;
  emailError: string;
  destroy$: Subject<void> = new Subject<void>();
  deleteOrDeactivateEnum = DeleteOrDeactivateEnum;
  addresses: BillingOrShippingAddressInterface[];
  editAddress = false;
  loading: boolean;
  sendStatus: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder, 
    private readonly authService: AuthStateService, 
    private readonly router: Router, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly dialog: MatDialog,
    private readonly userHttpService: UserHttpService, 
    private readonly translocoService: TranslocoService, 
    private readonly userProfileService: UserProfileStateService, 
    private readonly userService: UserHttpService
  ) {}

  get newEmail(): AbstractControl {
    return this.emailForm.get('newEmail');
  }
  
  get confirmNewEmail(): AbstractControl {
    return this.emailForm.get('confirmNewEmail');
  }
  
  get confirmPassword(): AbstractControl {
    return this.emailForm.get('confirmPassword');
  }
  
  ngOnInit(): void {
    this.listenOnUserChanges();
    this.initialisePasswordForm();
    this.initialisePhoneNumberForm();
    this.initialiseEmailForm();
    this.initialiseDeleteDeactivateForm();
  }

  listenOnUserChanges(): void {
    this.authService.getAsyncCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.addresses = JSON.parse(JSON.stringify(user.addresses));
        this.editAddress = this.addresses?.length === 0;
      }
    });
  }

  initialisePasswordForm(): void {
    this.passwordForm = this.formBuilder.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
      },
      {
        validators: mustMatch('newPassword', 'confirmPassword')
      }
    );
  }

  initialisePhoneNumberForm(): void {
    this.phoneForm = this.formBuilder.group({
      phonePrefix: [this.currentUser.phonePrefix || '+49'],
      phonePrefixAfter: [this.currentUser.phonePrefixAfter || ''],
      phoneNumber: [this.currentUser.phoneNumber, Validators.pattern('^[0-9 ]*$')]
    });
  }

  savePhoneNumber(): void {
    if (this.phoneForm.valid) {
      const formValue = this.phoneForm.getRawValue();
      this.userProfileService.updateUserProfilePhoneNumber(formValue);
    }
  }

  onPhonePrefixSelected(phonePrefix: PhonePrefixInterface): void {
    this.phoneForm.patchValue({
      phonePrefix: phonePrefix.dial_code,
      phonePrefixAfter: phonePrefix?.after_dial_code ?? null,
    });
  }

  initialiseEmailForm(): void {
    this.emailForm = this.formBuilder.group(
      {
        currentEmail: [
          {
            value: this.currentUser.email ? this.currentUser.email : '',
            disabled: true
          },
          [Validators.required, Validators.email, Validators.pattern(emailRegex)]
        ],
        newEmail: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]],
        confirmNewEmail: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex)]],
        confirmPassword: ['', Validators.required]
      },
      {
        validators: mustMatch('newEmail', 'confirmNewEmail')
      }
    );
  }

  initialiseDeleteDeactivateForm(): void {
    this.deleteDeactivateForm = this.formBuilder.group({
      confirmPassword: ['', [Validators.required]]
    });
  }

  changeEmail(): void {
    const updateEmail = {
      newEmail: this.emailForm.value.newEmail,
      confirmPassword: this.emailForm.value.confirmPassword
    };
    this.userHttpService.updateEmail(updateEmail).subscribe({
      next: (user) => {
        this.emailForm.reset();
        Object.keys(this.emailForm.controls).forEach((key) => {
          this.emailForm.get(key).setErrors(null);
        });
        this.emailForm.controls.currentEmail.patchValue(user.email);
        this.translocoService.selectTranslate('USER.EMAIL_CHANGE_SUCCESS').subscribe((translation) => {
          this.matSnackBar.open(translation);
        });
        this.authService.sendCurrentUserChanges(user);
        this.authService.refreshTokens().subscribe(() => {
          // email in tokens need to be updated
        });
      },
      error: (error) => {
        this.errorForEmailChange = error.error.message;
        this.emailForm.controls.confirmPassword.setErrors({ required: false });
      }
    });
  }

  onEmailTyped(): void {
    if (this.emailForm.value.newEmail && this.emailForm.controls.newEmail.valid) {
      this.userHttpService.isEmailAvailable(this.emailForm.value.newEmail).subscribe((result) => {
        if (result) {
          this.emailForm.controls.newEmail.setErrors({ emailUsed: true });
        } else {
          this.emailForm.controls.newEmail.setErrors(null);
        }
        this.emailForm.updateValueAndValidity();
      });
    }
  }

  changePassword(): void {
    const updatePassword = {
      newPassword: this.passwordForm.value.newPassword,
      currentPassword: this.passwordForm.value.currentPassword
    };
    this.userHttpService.updatePassword(updatePassword).subscribe({
      next: (user) => {
        this.translocoService.selectTranslate('USER.PASSWORD_CHANGE_SUCCESS').subscribe((translation) => {
          this.passwordForm.reset();
          Object.keys(this.passwordForm.controls).forEach((key) => {
            this.passwordForm.get(key).setErrors(null);
          });
          this.matSnackBar.open(translation);
        });
        this.authService.sendCurrentUserChanges(user);
      },
      error: (error) => {
        this.errorForPasswordChange = error.error.message;
        this.passwordForm.controls.currentPassword.setErrors({
          required: false
        });
      }
    });
  }

  createPassword(): void {
    this.loading = true;
    this.userService.forgotPassword(this.currentUser.email).subscribe(
      () => {},
      (error) => {
        this.loading = false;
        this.sendStatus = 'error';
      },
      () => {
        this.loading = false;
        this.sendStatus = 'success';
      }
    );
  }

  deleteAccount(): void {
    if (this.deleteDeactivateForm.valid) {
      const confirmPassword = this.deleteDeactivateForm.value.confirmPassword;
      const data: ConfirmationDialogInterface = {
        text: this.translocoService.translate(this.deleteOrDeactivate === DeleteOrDeactivateEnum.DELETE ? 'USER.SETTINGS.SURE_DEL' : '' +
          '' +
          'USER.SETTINGS.SURE_DEA'),
        cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
        submitButtonColor: 'primary',
        cancelButtonColor: 'warn',
        submitButtonText: this.translocoService.translate(this.deleteOrDeactivate === DeleteOrDeactivateEnum.DELETE ? 'GENERIC_WRAPPER.DELETE' : 'GENERIC_WRAPPER.DEACTIVATE')
      };
      const successfulText: string = this.translocoService.translate(this.deleteOrDeactivate === DeleteOrDeactivateEnum.DELETE ? 'USER.SETTINGS.DEL_SUCCESS' : 'USER.SETTINGS.DEA_SUCCESS');
      this.dialog
        .open(ConfirmationDialogComponent, {
          data
        })
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result: boolean) => {
            if (result) {
              this.loading = true;
              if (this.deleteOrDeactivate === DeleteOrDeactivateEnum.DELETE) {
                this.userHttpService.deleteUser(confirmPassword).subscribe({
                  next: () => {
                    this.loading = true;
                    this.authService.logout();
                    this.router.navigate(['/']);
                    this.matSnackBar.open(successfulText);
                  },
                  error: (error) => {
                    this.loading = false;
                    if (error.error.message === 'USER_HAS_DANCE_STUDIOS') {
                      this.translocoService.selectTranslate('USER.USER_IS_AT_LEAST_IN_ONE_STUDIO_ADMIN').subscribe((translations) => {
                        this.matSnackBar.open(translations);
                      });
                    }
                    this.deleteDeactivateForm.controls.confirmPassword.setErrors({
                      required: false
                    });
                  }
                });
              } else {
                this.loading = true;
                this.userHttpService.deactivateUser(confirmPassword).subscribe({
                  next: () => {
                    this.loading = true;
                    this.authService.logout();
                    this.router.navigate(['/']);
                    this.matSnackBar.open(successfulText);
                  },
                  error: (error) => {
                    this.loading = false;
                    if (error.error.message === 'USER_HAS_DANCE_STUDIOS') {
                      this.translocoService.selectTranslate('USER.USER_IS_AT_LEAST_IN_ONE_STUDIO_ADMIN').subscribe((translations) => {
                        this.matSnackBar.open(translations);
                      });
                    }
                    this.deleteDeactivateForm.controls.confirmPassword.setErrors({
                      required: false
                    });
                  }
                });
              }
            }
          }
        });
    }
  }
}
