import { Routes } from '@angular/router';
import { AuthGuard } from '../helpers';

export const dfAppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'search',
  },
  {
    path: 'search',
    loadChildren: () => import('@platri/df-angular-search').then((m) => m.FrontendDfSearchAppModule),
  },
  {
    path: 'invite-to-dm',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.InviteToDmPageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/df-angular-user').then((m) => m.SettingsPageModule),
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/df-angular-chat').then((m) => m.MessagesModule),
  },
  {
    path: 'time-out',
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.ConnectionTimedOutModule),
  },
  {
    path: '404',
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.PageNotFoundModule),
  },
  {
    path: 'dance-class',
    loadChildren: () => import('@platri/df-angular-course').then((m) => m.FrontendDfCourseAppModule),
  },
  {
    path: 'dance-event',
    loadChildren: () => import('@platri/df-angular-dance-event').then((m) => m.FrontendDfDanceEventAppModule),
  },
  {
    path: 'create-dance-manager',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/df-angular-create-dance-manager').then((m) => m.FrontendCreateDanceManagerModule),
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/df-angular-user').then((m) => m.InventoryPageModule)
  },
  {
    path: 'appointments',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/df-angular-user').then((m) => m.AppointmentsPageModule)
  },
  {
    path: 'price-update-subscription',
    canActivate: [AuthGuard],
    loadChildren: () => import('@platri/df-angular-user').then((m) => { return m.PriceUpdateSubscriptionModule; })
  }
];
