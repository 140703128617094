import { PaymentProviderEnum, TicketOrderTransactionInterface, TransactionTypeEnum } from '../../..';

export class TicketOrderTransactionDto implements TicketOrderTransactionInterface{
  id: string;
  createdAt: Date;
  updatedAt: Date;
  amount: number;
  transactionType: TransactionTypeEnum;
  paymentProvider: PaymentProviderEnum;
  paymentProviderOrderId: string;
  paymentProviderData: object;
  paymentProviderAccountId: string;
  ticketOrderId: string;
}
