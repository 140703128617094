import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DanceManagerSocialLinkInterface, DanceSchoolInterface } from '@platri/df-common-core';

@Component({
  selector: 'dfm-social-links-preview',
  templateUrl: './dfm-social-links-preview.component.html',
  styleUrls: ['./dfm-social-links-preview.component.scss'],
})
export class DfmSocialLinksPreviewComponent implements OnInit {
  @Input() danceSchool: DanceSchoolInterface;
  @Output() deleteClick = new EventEmitter<DanceManagerSocialLinkInterface[]>();

  temporarySocialLinks: DanceManagerSocialLinkInterface[] = [];

  ngOnInit(): void {
    if (this.danceSchool?.socialLinks) {
      this.temporarySocialLinks = JSON.parse(
        JSON.stringify(this.danceSchool.socialLinks)
      );
    }
  }

  deleteSocialLinks(index) {
    this.temporarySocialLinks.splice(index, 1);
    this.danceSchool.socialLinks = this.temporarySocialLinks;
    this.deleteClick.emit(this.danceSchool.socialLinks);
  }

  openSocialMediaLink(url: string) {
    window.open(url, '_blank');
  }

}
