import { AppointmentSchedulerInterface, CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, CustomRecurrenceWeekDaysTypeEnum, SchedulerRecurrenceTypeEnum, SchedulerTypeEnum } from '../../..';
import { AddressDto, AppointmentLocationDto, RoomDto } from '../../..';

export class CourseAppointmentSchedulerDto implements AppointmentSchedulerInterface {
  id: string;
  startDate: Date;
  duration: number;
  description: string;
  teacherUserIds: string[];
  schedulerType: SchedulerTypeEnum;
  recurrenceType: SchedulerRecurrenceTypeEnum;
  customRecurrenceType: CustomRecurrenceTypeEnum;
  customRecurrenceNumber: number;
  customRecurrenceWeekDays: CustomRecurrenceWeekDaysTypeEnum[];
  customRecurrenceEnd: CustomRecurrenceEndTypeEnum;
  numberOfAppointments: number;
  location: AppointmentLocationDto;
  room: RoomDto;
  address: AddressDto;
}
