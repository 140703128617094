
import { CreateDanceSchoolLocationRequestDto } from './create-dance-school-location-request.dto';
import { DanceManagerCategoryEnum } from '../../../enums';
import { DanceSchoolInterface } from '../../../interfaces';
import { AddressDto } from '../address.dto';
import { ImageDto } from '../image.dto';

export class CreateDanceManagerDto implements DanceSchoolInterface {
  name: string;
  description: string;
  danceManagerCategory: DanceManagerCategoryEnum[];
  danceStyleIds?: string[];
  businessAddress: AddressDto;
  phonePrefix: string;
  phonePrefixAfter: string;
  phoneNumber: string;
  email: string;
  locations: CreateDanceSchoolLocationRequestDto[];
  vat: number;
  imageUrl: string;
  titleImage: ImageDto;

  constructor(args: CreateDanceManagerDto) {
    if (args) {
      this.name = args.name;
      this.description = args.description;
      this.danceManagerCategory = args.danceManagerCategory;
      this.danceStyleIds = args.danceStyleIds;
      this.businessAddress = args.businessAddress;
      this.phonePrefix = args.phonePrefix;
      this.phonePrefixAfter = args.phonePrefixAfter;
      this.phoneNumber = args.phoneNumber;
      this.email = args.email;
      this.locations = args.locations;
      this.vat = args.vat;
      this.imageUrl = args.imageUrl;
      this.titleImage = args.titleImage;
    }
  }
}
