import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SelectDmDialogComponent } from './select-dm-dialog.component';
import { DfSharedButtonModule } from '../buttons';
import { MatMenuItem } from '@angular/material/menu';
import { PipesModule } from '../../pipes';
import { MatDialogClose } from '@angular/material/dialog';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [SelectDmDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DfSharedButtonModule,
    MatMenuItem,
    PipesModule,
    MatDialogClose,
    TranslocoDirective
  ]
})
export class SelectDmDialogModule {}
