import { NgModule } from '@angular/core';
import { TimeInformationToHoursMinutesPipe } from './time-information-to-hours-minutes.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { IntervalPipe } from './interval.pipe';
import { IsAppointmentParticipantPipe } from './is-appointment-participant.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { AmenityIconPipe } from './amenity-icon.pipe';
import { DayNameAbbreviationPipe } from './day-name-abbreviation.pipe';
import { IsPastDatePipe } from './is-past-date.pipe';
import { GetImagesOfRoomPipe } from './get-images-of-room.pipe';
import { IsDisabledPipe } from './is-disabled.pipe';
import { TimeDiffPipe } from './time-diff.pipe'; 
import { FormatDayPipe } from './format-day.pipe';
import { GoogleLatLngLiteralPipe } from './google-lat-lng-literal.pipe';
import { TimezoneOffsetDatePipe } from './timezone-offset-date.pipe';
import { RoundDistancePipe } from './round-distance.pipe';
import { DateTimezonedPipe } from './date-timezoned.pipe';
import { GetIsFavoriteFromFollowsByTargetIdAndTargetTypePipe } from './get-is-favorite-from-follows-by-target-id-and-target-type.pipe';
import { TicketFeeCalculatePipe } from './ticket-fee-calculate.pipe';
import { NumberToEuroStringPipe } from './number-to-euro-string.pipe';
import { TransformMonthYearLabelPipe } from './transform-month-year-label.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { MonthNameShortPipe } from './month-name-short.pipe';
import { WeekdayNamePipe } from './weekday-name.pipe';
import { UserTicketCollapsedSaleInformationPipe } from './user-ticket-collapsed-sale-information.pipe';
import { LocalizedDatePipe } from './local-date.pipe';
import { DanceSkillLevelPipe } from './dance-skill-level.pipe';
import { LanguageLevelAsNumberPipe } from './language-level-as-number.pipe';
import { CentsToCurrencyStringPipe } from './cents-to-currency-string.pipe';
import { GoogleAddressReadablePipe } from './google-address-readable.pipe';
import { DayOfMonthPipe } from './day-of-month.pipe';
import { YearPipe } from './year.pipe';
import { DanceSkillRolePipe } from './dance-skill-role.pipe';
import { SubscriptionLatestInvoice } from './subscription-latest-invoice.pipe';
import { UnreadCountDmPipePipe } from './unread-count-dm.pipe';
import { DynamicTagPipe } from './dynamic-tag.pipe';
import { StripHtmlTagsPipe } from './strip-html-tags.pipe';
import { TruncatePipe } from './truncate.pipe';
import { NewLineBreakPipe } from './new-line-break.pipe';
import { KeysPipe } from './keys.pipe';
import { TimeRangePipe } from './time-range.pipe';
import { OrderByPipe } from './order-by.pipe';
import { ConversationFirstPartnerPipe } from './conversation-first-partner.pipe';
import { ConversationToMessagePartnersPipe } from './conversation-to-message-partners.pipe';
import { ConversationGetPartnerIdsPipe } from './conversation-get-partner-ids.pipe';
import { ConversationGetPartnerNamePipe } from './conversation-get-partner-name.pipe';
import { ConversationGetPartnerUsernamePipe } from './conversation-get-partner-username.pipe';
import { ConversationIsReadPipe } from './conversation-is-read.pipe';
import { SkillLevelTranslationPipe } from './skill-level.translation.pipe';
import { TimezoneAbbreviationPipe } from './timezone-abbreviation.pipe';
import { CurrencyPositionPipe } from './currency-position.pipe';
import { RichTextEllipsisPipe } from './rich-text-ellipsis.pipe';
import { DateRangePipe } from './date-range.pipe';
import { DateTimeStringDanceEventPipe } from './date-time-string-dance-event.pipe';

@NgModule({
  providers: [TimeInformationToHoursMinutesPipe],
  declarations: [
    DateTimeStringDanceEventPipe,
    CurrencyPositionPipe,
    TimeAgoPipe,
    TimeInformationToHoursMinutesPipe,
    IntervalPipe,
    IsAppointmentParticipantPipe,
    LinkifyPipe,
    AmenityIconPipe,
    DayNameAbbreviationPipe,
    IsPastDatePipe,
    GetImagesOfRoomPipe,
    IsDisabledPipe,
    TimeDiffPipe,
    FormatDayPipe,
    GoogleLatLngLiteralPipe,
    TimezoneOffsetDatePipe,
    RoundDistancePipe,
    DateTimezonedPipe,
    GetIsFavoriteFromFollowsByTargetIdAndTargetTypePipe,
    TicketFeeCalculatePipe,
    NumberToEuroStringPipe,
    TransformMonthYearLabelPipe,
    MonthNamePipe,
    MonthNameShortPipe,
    WeekdayNamePipe,
    UserTicketCollapsedSaleInformationPipe,
    LocalizedDatePipe,
    DanceSkillLevelPipe,
    LanguageLevelAsNumberPipe,
    DynamicTagPipe,
    CentsToCurrencyStringPipe,
    GoogleAddressReadablePipe,
    DayOfMonthPipe,
    YearPipe,
    DanceSkillRolePipe,
    SubscriptionLatestInvoice,
    UnreadCountDmPipePipe,
    StripHtmlTagsPipe,
    TruncatePipe,
    NewLineBreakPipe,
    KeysPipe,
    TimeRangePipe,
    OrderByPipe,
    ConversationFirstPartnerPipe,
    ConversationToMessagePartnersPipe,
    ConversationGetPartnerIdsPipe,
    ConversationGetPartnerNamePipe,
    ConversationGetPartnerUsernamePipe,
    ConversationIsReadPipe,
    SkillLevelTranslationPipe,
    TimezoneAbbreviationPipe,
    RichTextEllipsisPipe,
    DateRangePipe,
  ],
  exports: [
    CurrencyPositionPipe,
    TimeAgoPipe,
    TimeInformationToHoursMinutesPipe,
    IntervalPipe,
    IsAppointmentParticipantPipe,
    LinkifyPipe,
    AmenityIconPipe,
    DayNameAbbreviationPipe,
    IsPastDatePipe,
    GetImagesOfRoomPipe,
    IsDisabledPipe,
    TimeDiffPipe,
    FormatDayPipe,
    GoogleLatLngLiteralPipe,
    TimezoneOffsetDatePipe,
    RoundDistancePipe,
    DateTimezonedPipe,
    GetIsFavoriteFromFollowsByTargetIdAndTargetTypePipe,
    TicketFeeCalculatePipe,
    NumberToEuroStringPipe,
    TransformMonthYearLabelPipe,
    MonthNamePipe,
    MonthNameShortPipe,
    WeekdayNamePipe,
    UserTicketCollapsedSaleInformationPipe,
    LocalizedDatePipe,
    DanceSkillLevelPipe,
    LanguageLevelAsNumberPipe,
    DynamicTagPipe,
    CentsToCurrencyStringPipe,
    GoogleAddressReadablePipe,
    DayOfMonthPipe,
    YearPipe,
    DanceSkillRolePipe,
    SubscriptionLatestInvoice,
    UnreadCountDmPipePipe,
    StripHtmlTagsPipe,
    TruncatePipe,
    NewLineBreakPipe,
    KeysPipe,
    TimeRangePipe,
    OrderByPipe,
    ConversationFirstPartnerPipe,
    ConversationToMessagePartnersPipe,
    ConversationGetPartnerIdsPipe,
    ConversationGetPartnerNamePipe,
    ConversationGetPartnerUsernamePipe,
    ConversationIsReadPipe,
    SkillLevelTranslationPipe,
    TimezoneAbbreviationPipe,
    RichTextEllipsisPipe,
    DateRangePipe,
    DateTimeStringDanceEventPipe
  ],
})
export class PipesModule {}
