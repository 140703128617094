import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { AuthStateService, CoursesHttpService, CoursesStateService, DanceEventHttpService, DanceManagerHttpService, DanceSchoolStateService, dfmAdministrationRoute, DmPassSubscriptionHttpService, getSubscription, PassHttpService, SeoService, UserPassSubscriptionHttpService } from '@platri/dfx-angular-core';
import { CoordinatesInput } from '@platri/df-common-shared-graphql';
import { MatTabGroup } from '@angular/material/tabs';
import { CourseInterface, DanceEventsInterface, DanceManagerFeatureNameEnum, DanceManagerInterface, DanceManagerPassesPublicResponseDto, DanceManagerUserRoleEnum, DanceSchoolInterface, DanceSchoolToUserInterface, DmPassSubscriptionInterface, DmPassSubscriptionTargetInterface, environmentForWeb, hasAccessToDanceManagerFromTokenRolesByIdAndRoles, LocationInterface, UserPassSubscriptionDto, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-dance-manager-public-page',
  templateUrl: './dance-manager-public.page.html',
  styleUrls: ['./dance-manager-public.page.scss']
})
export class DanceManagerPublicPage implements OnInit, OnChanges, OnDestroy {
  @Input() urlName: string;
  @Input() isDmPreview = true;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  
  matTabsToDisplay = [];
  loadedData = false;
  
  danceManager: DanceManagerInterface;
  currentUser: UsersInterface;
  
  showEventsTab = false;
  showCoursesTab = false;
  showPassesTab = false;
  showSubscriptionsTab = false;
  showGalleryTab = false;
  showTeamTab = false;
  
  danceEvents: DanceEventsInterface[];
  courses: CourseInterface[];
  passes: DanceManagerPassesPublicResponseDto[];
  dmPassSubscriptions: DmPassSubscriptionInterface[];
  
  isOwnerOrAdmin = false;
  currentUserPassSubscriptions: UserPassSubscriptionDto[] = [];
  
  currentDanceSchool: DanceSchoolInterface;
  publicDanceSchoolToUsers:DanceSchoolToUserInterface[] = [];
  locations: LocationInterface[];
  coordinates: CoordinatesInput;
  
  hasDanceSchoolImageGalleryUrls = false;
  showTabGroup: boolean;
  selectedTabIndex = 0;
  pageViews: number | null = null;

  isFeatureEventActive = false;
  isFeatureCourseActive = false;

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;

  private subscriptions: Subscription = new Subscription();
  
  constructor(
    private readonly courseHttpService: CoursesHttpService,
    private readonly passHttpService: PassHttpService,
    private readonly danceEventHttpService: DanceEventHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private readonly authService: AuthStateService,
    private readonly route: ActivatedRoute,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly seoService: SeoService,
    private readonly router: Router,
    public readonly coursesService: CoursesStateService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private dmPassSubscriptionHttpService: DmPassSubscriptionHttpService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', '100%');
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        let urlName = null;
        let id = null;
        if (params.urlName) {
          urlName = params.urlName;
        } else if (params.id) {
          id = params.id;
        } else {
          urlName = this.urlName;
        }
        this.loadDanceManager(urlName, id);
      })
    );
  }
  
  loadDanceManager(urlName?: string, id?: string): void {
    this.matTabsToDisplay = [];
    if (urlName) {
      this.danceManagerHttpService.getDanceManagerByUrlNameForPublicView(urlName).subscribe({
        next: (value) => {
          this.danceSchoolService.sendCurrentDanceSchool(value);
          this.danceManager = value;
          this.subscriptions.add(getSubscription(
            this.userPassSubscriptionHttpService.getUserPassSubscriptionsByCurrentUserAndDanceManagerId(this.danceManager.id),
            this.onCheckUserPassSubscriptionForDanceManagerSuccessful.bind(this),
            this.onCheckUserPassSubscriptionForDanceManagerFailed.bind(this),
          ));
          this.showEventsTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.DANCE_EVENTS);
          this.showCoursesTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.COURSES);
          this.showPassesTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.COURSE_PASSES);
          this.showSubscriptionsTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.COURSE_SUBSCRIPTION);
          this.showGalleryTab = !!(this.danceManager.imageGalleryUrls && this.danceManager.imageGalleryUrls?.length > 0);
          this.checkICurrentUserIsOwnerOrAdmin();

          this.loadedData = true;
        }
      });
      this.loadPageViews(urlName);
    }
    if (id) {
      this.danceManagerHttpService.getDanceManagerByIdForPublicView(id).subscribe({
        next: (value) => {
          this.danceSchoolService.sendCurrentDanceSchool(value);
          this.danceManager = value;
          this.subscriptions.add(getSubscription(
            this.userPassSubscriptionHttpService.getUserPassSubscriptionByCurrentUserAndDanceManagerId(this.danceManager.id),
            this.onCheckUserPassSubscriptionForDanceManagerSuccessful.bind(this),
            this.onCheckUserPassSubscriptionForDanceManagerFailed.bind(this),
          ));
          this.showEventsTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.DANCE_EVENTS);
          this.showCoursesTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.COURSES);
          this.showPassesTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.COURSE_PASSES);
          this.showSubscriptionsTab = !!this.danceManager.danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === DanceManagerFeatureNameEnum.COURSE_SUBSCRIPTION);
          this.showGalleryTab = !!(this.danceManager.imageGalleryUrls && this.danceManager.imageGalleryUrls?.length > 0);
          this.checkICurrentUserIsOwnerOrAdmin();

          this.loadedData = true;
          this.loadPageViews(this.danceManager.urlName);
        }
      });
    }
  }

  onCheckUserPassSubscriptionForDanceManagerSuccessful(subs: UserPassSubscriptionDto[]): void {
    this.currentUserPassSubscriptions = subs;
  }

  onCheckUserPassSubscriptionForDanceManagerFailed(err): void {
    console.error(err);
  }
  
  loadPageViews(urlName: string): void {
    if(!this.isDmPreview) return;
    this.subscriptions.add(
      this.danceManagerHttpService.getPageViews(urlName).subscribe((pageViews) => {
        this.pageViews = pageViews;
      })
    );
  }
  
  loadDataToDanceManager(): void {
    const requests = [];
    if (this.showEventsTab) {
      requests.push(this.danceEventHttpService.getAllByDanceManagerId(this.danceManager.id, true, true));
    }
    if (this.showCoursesTab) {
      requests.push(this.courseHttpService.getAllByDanceManagerForPublicOverview(1, 100, this.danceManager.id));
    }
    if (this.showPassesTab) {
      requests.push(this.passHttpService.getAllByDanceManagerIdPublicView(this.danceManager.id));
    }
    if (this.showSubscriptionsTab) {
      requests.push(this.dmPassSubscriptionHttpService.getAllByDanceManagerId(this.danceManager.id));
    }
    if (this.showTeamTab) {
      // TODO use instead the public endpoint to get dance manager users
      requests.push(this.danceManagerHttpService.getDanceSchoolToUsersByDanceSchoolId(this.danceManager.id));
    }
    if (requests.length > 0) {
      forkJoin(requests).subscribe({
        next: (data) => {
          this.danceEvents = this.showEventsTab ? data[0] : [];
          // @ts-ignore
          this.courses = this.showCoursesTab ? data[1].results : [];
          // @ts-ignore
          this.passes = this.showPassesTab ? data[2] : [];
          // @ts-ignore
          this.dmPassSubscriptions = this.showSubscriptionsTab ? data[3] : [];
          // @ts-ignore
          this.publicDanceSchoolToUsers = this.showTeamTab ? (data[4]|| []).filter((obj) => obj.isPublic) : [];
          // this.isMatTabGroupEmptyCheck();
          if (this.danceEvents?.length > 0) {
            this.matTabsToDisplay.push('events');
          }
          if (this.courses?.length > 0) {
            this.matTabsToDisplay.push('courses');
          }
          if (this.showPassesTab && this.passes && this.passes?.length > 0) {
            this.matTabsToDisplay.push('passes');
          }
          if (this.dmPassSubscriptionFeature && this.dmPassSubscriptions?.length > 0) {
            this.matTabsToDisplay.push('subscriptions');
          }
          if (this.showGalleryTab) {
            this.matTabsToDisplay.push('gallery');
          } 
          if (this.showTeamTab && this.publicDanceSchoolToUsers?.length > 0) {
            this.matTabsToDisplay.push('team');
          }
          if (this.danceManager?.faqs !== null && this.danceManager?.faqs.length > 0) {
            this.matTabsToDisplay.push('faqs');
          }
          this.processFragment();
        }
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.isMatTabGroupEmptyCheck();
  }

  focusFirstTab(): void {
    if (this.tabGroup){
      this.tabGroup.selectedIndex = 0;
      this.tabGroup.realignInkBar();
    }
  }
  
  stripHtmlTags(input: string): string {
    return input.replace(/<[^>]+>/g, '');
  }
  
  setSeoData(): void {
    this.seoService.setMetaTagProperty('og:locale', 'de_DE');
    this.seoService.setMetaTagProperty('og:type', 'website');
    this.seoService.setPageTitle(`${this.danceManager.name} - Danceflavors`);
    this.seoService.setMetaTagProperty('og:title', `${this.danceManager.name} - Danceflavors`);
    if (this.danceManager.description) {
      this.seoService.setMetaTagName('description', this.danceManager.description.length > 155 ? this.danceManager.description.substring(0, 155) + '...' : this.danceManager.description);
      this.seoService.setMetaTagProperty('og:description', this.stripHtmlTags(this.danceManager.description) );
    }
    this.seoService.setMetaTagProperty('og:url', window.location.href);
    this.seoService.setMetaTagProperty('og:site_name', 'Danceflavors');
    if (this.danceManager.imageUrl) {
      this.seoService.setMetaTagProperty('og:image', this.danceManager.imageUrl);
      this.seoService.setMetaTagProperty('og:image:width', '1200');
      this.seoService.setMetaTagProperty('og:image:height', '630');
      this.seoService.setMetaTagProperty('og:image:type', 'image/jpg');
    }
    this.seoService.setCanonicalURL();
  }

  isMatTabGroupEmptyCheck(): void {
    this.showTabGroup = !!((this.isFeatureEventActive && this.danceEvents?.length > 0) ||
      !!((this.isFeatureCourseActive && this.coursesService.getSyncCoursesPage()?.results?.length > 0)) ||
      (this.isFeatureCourseActive) ||
      (this.locations && this.locations.length > 0) ||
      (this.currentDanceSchool && (this.currentDanceSchool.teamDescription || (this.currentDanceSchool.danceSchoolToUsers && this.currentDanceSchool.danceSchoolToUsers.length > 0))) ||
      (this.isOwnerOrAdmin || this.hasDanceSchoolImageGalleryUrls) ||
      (this.currentDanceSchool && this.currentDanceSchool.faqs && this.currentDanceSchool.faqs.length > 0));
  }
  
  checkICurrentUserIsOwnerOrAdmin() :void {
    const currentUser = this.authService.getSyncCurrentUser();
    if (currentUser) {
      this.isOwnerOrAdmin = hasAccessToDanceManagerFromTokenRolesByIdAndRoles(this.danceManager.id, currentUser.roles.danceSchool, [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]);
    }
    this.showTeamTab = true;
    this.setSeoData();
    this.loadDataToDanceManager();
  }

  routeToSettings(target: string, fragment: string = ''): void {
    this.router.navigate(['dance-manager', this.danceManager.id, target], { fragment: fragment });
  }

  routeToNew(type: string): void {
    switch (type) {
      case 'events':
      case 'courses':
        {
          this.router.navigate(['dance-manager', this.danceManager.id, type, 'create']);
        }
        break;
      default:
        {
          this.router.navigate(['dance-manager', this.danceManager.id, type, 'edit', 'info']);
        }
        break;
    }
  }
  
  routeToEditTeam(): void {
    this.router.navigate(['/dance-manager/' + this.danceManager.id + '/administration'], {fragment: 'team'});
  }

  onTabChange(event: any): void {
    const tabLabel = this.matTabsToDisplay[event.index];
    this.router.navigate([], { fragment: tabLabel });
  }

  processFragment(): void {
    this.subscriptions.add(
      this.route.fragment.subscribe(fragment => {
        const index = this.matTabsToDisplay.indexOf(fragment);
        this.selectedTabIndex = index >= 0 ? index : 0;
      })
    );
  }

  filterUserPassSubscriptionCoursesByPassId(targets: DmPassSubscriptionTargetInterface[]): CourseInterface[] {
    const targetIds: string[] = Array.from(new Set(targets.map(target => target.targetId) ?? []));
    return this.courses.filter(course => targetIds.includes(course.id));
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.seoService.resetAll();
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  
  protected readonly dfmAdministrationRoute = dfmAdministrationRoute;
}
