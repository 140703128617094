import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DfmAddEditFaqComponent } from './dfm-add-edit-faq.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {
  DfQuillEditorModule,
  DfSharedButtonModule
} from '@platri/dfx-angular-core';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DfmAddEditFaqComponent],
  exports: [DfmAddEditFaqComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    DfQuillEditorModule,
    DfSharedButtonModule,
    TranslocoDirective,
  ],
})
export class DfmAddEditFaqModule {}
