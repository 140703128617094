import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule, Routes } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProcessSubscriptionPage } from './process-subscription.page';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from '../../pipes';
import { DmPackagesCardListModule, DmSubscriptionCardModule } from '../../components';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: ProcessSubscriptionPage,
  },
];

@NgModule({
  declarations: [ProcessSubscriptionPage],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatCardModule,
      MatIconModule,
      MatMenuModule,
      MatButtonModule,
      MatFormFieldModule,
      MatChipsModule,
      MatDividerModule,
      ReactiveFormsModule,
      PipesModule,
      MatSlideToggleModule,
      DmPackagesCardListModule,
      DmSubscriptionCardModule,
      MatProgressSpinnerModule,
      TranslocoDirective,
    ],
  providers: [],
})
export class ProcessSubscriptionPageModule {}
