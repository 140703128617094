import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { generateTimeOptionsStringArray } from '@platri/df-common-core';
import { AppointmentRecurrenceTypeEnum, SchedulerTypeEnum } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-shared-lib-time-picker',
  templateUrl: './df-time-picker.component.html',
  styleUrls: ['./df-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DfTimePickerComponent implements OnInit {
  @Input() label: string;
  @Input() minuteSteps = 15;
  @Input() timeFormat = 'HH:mm';
  @Input() passedFormControlName: string;
  @Input() passedFormGroup: UntypedFormGroup;
  @Input() defaultSelection = true;
  @Input() requiredErrorMessage = 'This is required';
  @Input() orderErrorMessage = '';

  selectableTimes: string[] = [];

  ngOnInit(): void {
    this.selectableTimes = generateTimeOptionsStringArray(
      this.minuteSteps,
      this.timeFormat
    );
  }

  protected readonly AppointmentRecurrenceTypeEnum = AppointmentRecurrenceTypeEnum;
  protected readonly SchedulerTypeEnum = SchedulerTypeEnum;
}
