import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DfSharedButtonModule, NumberInputModule } from '@platri/dfx-angular-core';
import { DfmSocialLinksEditComponent } from './dfm-social-links-edit.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmSocialLinksEditComponent],
  exports: [DfmSocialLinksEditComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NumberInputModule,
    DfSharedButtonModule,
    TranslocoDirective,
  ],
})
export class DfmSocialLinksEditModule {}
