import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MagicLinkLoginTokenPageComponent } from './magic-link-login-token-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [MagicLinkLoginTokenPageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: '',
      component: MagicLinkLoginTokenPageComponent
    }]),
    MatProgressSpinnerModule,
    TranslocoDirective,
  ],
  exports: [MagicLinkLoginTokenPageComponent]
})
export class MagicLinkLoginTokenPageModule { }
