import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { DanceManagerUserRoleEnum } from '@platri/df-common-core';

@Component({
  selector: 'dfm-edit-role-dialog',
  templateUrl: './dfm-edit-role-dialog.component.html',
  styleUrls: ['./dfm-edit-role-dialog.component.scss'],
})
export class DfmEditRoleDialogComponent implements OnInit {
  editRoleForm: UntypedFormGroup;
  danceSchoolUserRoleEnum = DanceManagerUserRoleEnum;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roleEditRef: MatDialogRef<DfmEditRoleDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.editRoleForm = this.fb.group({
      role: this.data.role,
    });
  }

  saveRole(): void {
    this.roleEditRef.close(this.editRoleForm.controls.role.value);
  }
}
