<ng-container *transloco="let t">
  <!--Opening times-->
  <div class="flex-column" style="gap: 10px;">
  <span *ngIf="!hideTitle">
    <b>{{ t('title')}}</b>
  </span>

    <ng-container>
      <div class="flex-column">
        <div
          *ngFor="let dayName of objectKeys(openingHoursData); let i = index"
          class="sub-text flex-row-wrap"
        >
          <div
            class="align-start lt-md-column"
            style="width: 100%"
          >
            <div class="lt-md-flex-30" style="margin-top: 25px; flex: 1 1 20%;">
              <span>{{ t(('weekdays.' + dayName).toLowerCase()) }}</span>
            </div>
            <div
              class="align-space-between-center lt-md-flex-40"
              style="margin-top: 25px; flex: 1 1 20%;"
            >
              <mat-slide-toggle
                color="primary"
                (change)="toggleOpeningHoursDay($event, dayName)"
                [checked]="openingHoursData[dayName].length !== 0"
              ></mat-slide-toggle>
            </div>

            <div
              class="flex-row-wrap align-start lt-md-flex-50"
              style="gap: 10px; flex: 1 1 60%;"
            >
              <ng-container *ngIf="!!openingHoursData[dayName]">
                <div class="flex-column align-start">
                  <ng-container
                    *ngFor="
                    let openingHoursDay of openingHoursData[dayName];
                    let i = index
                  "
                  >
                    <div class="flex-row-wrap align-start-center">
                      <mat-form-field class="input-time">
                        <input
                          matInput
                          autocomplete="off"
                          type="time"
                          (ngModelChange)="
                          setOpeningHoursDataFormat(
                            openingHoursData[dayName][i],
                            $event,
                            true
                          )
                        "
                          [ngModel]="
                          openingHoursData[dayName][i].timeStart
                            | timeInformationToHoursMinutes
                        "
                          (change)="sendOpeningHoursData()"
                        />
                      </mat-form-field>
                      <span class="hyphen-spacer"> - </span>
                      <mat-form-field class="input-time">
                        <input
                          matInput
                          autocomplete="off"
                          type="time"
                          [ngModel]="
                          openingHoursData[dayName][i].timeEnd
                            | timeInformationToHoursMinutes
                        "
                          (ngModelChange)="
                          setOpeningHoursDataFormat(
                            openingHoursData[dayName][i],
                            $event,
                            false
                          )
                        "
                          (change)="sendOpeningHoursData()"
                        />
                      </mat-form-field>
                      <button
                        *ngIf="openingHoursData[dayName].length === 1"
                        mat-icon-button
                        color="primary"
                      >
                        <mat-icon
                          style="margin-bottom: 6px"
                          (click)="addOpeningHoursForDay(dayName)"
                        >add</mat-icon
                        >
                      </button>
                      <button
                        *ngIf="openingHoursData[dayName].length > 1"
                        mat-icon-button
                        color="accent"
                      >
                        <mat-icon
                          style="margin-bottom: 6px"
                          (click)="deleteOpeningHoursForDay(dayName, i)"
                          [matTooltip]="t('mat-icons.delete')"
                        >delete</mat-icon
                        >
                      </button>
                    </div>
                  </ng-container>
                  <p
                    class="mt0 opening-hours-error"
                    *ngIf="!valid && errorKey === dayName"
                  >
                    {{t('opening_hours_error.' + this.errorTranslationKey)}}
                  </p>
                </div>
              </ng-container>

              <div *ngIf="openingHoursData[dayName].length === 0">
                <div style="margin-top: 25px !important">
                  <span>{{t('closed')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
