<ng-container [formGroup]="customFormGroup">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label><mat-icon class="icon-display" style="vertical-align: middle;">search</mat-icon> {{ label }}</mat-label>
    <input
      [matAutocomplete]="auto"
      (input)="onInputChange($event)"
      autocomplete="off"
      matInput
      placeholder="{{ placeholder }}"
      #danceManagerInput
    />
    <mat-autocomplete
      #auto="matAutocomplete" hideSingleSelectionIndicator>
      
      <mat-option *ngIf="showDanceManagerSearchLoading">
        <div class="flex-row align-center">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
      </mat-option>
      <mat-option
        *ngFor="let danceManager of mappedDanceManagers"
        [disabled]="isDanceManagerDisabled(danceManager)"
        [value]="danceManager.danceManager"
        (onSelectionChange)="emitSelectDanceManager(danceManager)"
        (click)="resetFormControlValue()"
      
      >
        <div class="flex-row gap-10 align-start-center">
          <img
            class="profilePicture hide-on-mobile"
            [src]="danceManager.imageUrl && danceManager.imageUrl !== '' ? danceManager.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
          />
          <strong> {{ danceManager.danceManager }}</strong>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
