import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'elab-google-sign-in-button',
  templateUrl: './google-sign-in-button.component.html',
  styleUrls: ['./google-sign-in-button.component.scss']
})
export class GoogleSignInButtonComponent implements OnInit, OnDestroy {
  @Output() accessTokenEmitter = new EventEmitter<string>();
  
  private subscription = new Subscription();
  
  currentLang: string;
  
  constructor(private socialAuthService: SocialAuthService, private translocoService: TranslocoService) {
    this.currentLang = this.translocoService.getActiveLang();
  }
  
  ngOnInit(): void {
    this.subscription.add(
      this.socialAuthService.authState.subscribe((data) => {
        this.accessTokenEmitter.emit(data.idToken);
      })
    );
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
