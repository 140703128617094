import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CheckUserExistsResultInterface,
  CreateUserRequestDto,
  environmentForWeb,
  LoginResponseDto,
  MessagePartnerInterface,
  UpdateUserClass,
  UserDto,
  UsersInterface
} from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiUsersUrl}/users`;
  private readonly MESSAGES_URL = `${environmentForWeb.apiMessageUrl}/message-partners`;

  constructor(private http: HttpClient) {}

  getUserByUsername(username: string): Observable<UserDto> {
    return this.http.get<UserDto>(`${this.SERVICE_URL}/username/${username}`);
  }
  
  searchUsersByKey(key: string, withSensitiveData: boolean = false): Observable<UserDto[]> {
    return this.http.get<UserDto[]>(`${this.SERVICE_URL}/search/${key}?withSensitiveData=${withSensitiveData}`);
  }
  
  getCurrentUser(): Observable<UsersInterface> {
    return this.http.get<UsersInterface>(`${this.SERVICE_URL}`);
  }

  signUp(user: CreateUserRequestDto): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/sign-up`, user);
  }

  signUpWithGoogle(jwt: string): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/sign-up/google`, {
      accessToken: jwt
    });
  }

  signUpWithFacebook(token: LoginResponseDto): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/sign-up/facebook`, token);
  }

  updateUser(user: UpdateUserClass): Observable<UserDto> {
    return this.http.patch<UserDto>(`${this.SERVICE_URL}`, user);
  }
  

  updateEmail(updateEmail: { confirmPassword: string; newEmail: string }): Observable<UserDto> {
    return this.http.patch<UserDto>(`${this.SERVICE_URL}/email/change`, updateEmail);
  }

  isEmailAvailable(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.SERVICE_URL}/email/check`, {
      email
    });
  }

  isUserLoginInformationExisting(emailOrUsername: string): Observable<CheckUserExistsResultInterface> {
    return this.http.post<CheckUserExistsResultInterface>(`${this.SERVICE_URL}/email-or-username/check`, {
      emailOrUsername
    });
  }

  updatePassword(updatePasswordInterface: { newPassword: string; currentPassword: string }): Observable<UserDto> {
    return this.http.patch<UserDto>(`${this.SERVICE_URL}/password/change`, updatePasswordInterface);
  }

  deactivateUser(password: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.SERVICE_URL}/deactivate`, {
      password
    });
  }

  deleteUser(password: string): Observable<boolean> {
    return this.http.patch<boolean>(`${this.SERVICE_URL}/delete`, {
      password
    });
  }

  verifyEmail(token: string): Observable<void> {
    return this.http.get<void>(`${this.SERVICE_URL}/email/verify/${token}`);
  }

  forgotPassword(email: string): Observable<void> {
    return this.http.get<void>(`${this.SERVICE_URL}/email/reset-password/${email}`);
  }

  resetPassword(email: string, token: string, password: string): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/email/reset-password`, {
      email,
      token,
      password
    });
  }

  setPasswordGuest(token: string, password: string, firstName: string, lastName: string): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/set-password-guest`, {
      token,
      password,
      firstName,
      lastName
    });
  }

  reportUser(userId: string, reportReason: string): Observable<void> {
    return this.http.post<void>(`${this.SERVICE_URL}/report/${userId}`, {
      reportReason
    });
  }

  getOtherUserById(userId: string): Observable<MessagePartnerInterface> {
    return this.http.get<MessagePartnerInterface>(`${this.MESSAGES_URL}/search-by-id/${userId}`);
  }
  
  getOtherUsersByIds(ids: string[]): Observable<MessagePartnerInterface[]> {
    return this.http.post<MessagePartnerInterface[]>(`${this.MESSAGES_URL}/search-by-ids`, {ids});
  }
  
  getUsersByIds(ids: string[], withSensitiveData = false, onlyNames = false): Observable<UserDto[]> {
    return this.http.post<UserDto[]>(`${this.SERVICE_URL}/bulk?withSensitiveData=${withSensitiveData}&onlyNames=${onlyNames}`, {userIds: ids}, {
      headers: {
        'X-API-KEY': environmentForWeb.apiKey,
      },
    });

  }
}
