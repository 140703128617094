import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, Subscription, take } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Follow } from '@platri/df-common-shared-graphql';
import { DanceSchoolInterface, DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER, FollowTargetTypeEnum, UsersInterface } from '@platri/df-common-core';

import { AuthStateService, DanceSchoolHttpService, DanceSchoolStateService, UserFollowStateService } from '../../services';
import { dfAppRoute, dfLoginRoute } from '../../shared';
import { FollowInfoDialogComponent } from '../follow-info-dialog';

@Component({
  selector: 'df-shared-lib-dm-follow-container',
  templateUrl: './df-dm-follow-container.component.html',
  styleUrls: ['./df-dm-follow-container.component.scss']
})
export class DfDmFollowContainerComponent implements OnInit, OnDestroy {
  @Input() danceManagerId: string;
  
  danceManager: DanceSchoolInterface;

  currentUser: UsersInterface;
  completed : Observable<boolean>;

  subscriptions: Subscription = new Subscription();
  
  constructor(
    private readonly authService: AuthStateService,
    private readonly router: Router,
    private readonly matSnackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public readonly userFollowStateService: UserFollowStateService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.completed = this.userFollowStateService.getAsyncIsLoadingFollow();
    this.danceSchoolHttpService.getDanceSchoolById(this.danceManagerId).subscribe(danceManager => {
      this.danceManager = danceManager;
      this.loadFollowCountOfCurrentUser();
    });
  }
  

  loadFollowCountOfCurrentUser(): void {
    this.userFollowStateService.loadFollowCountByTargetIdAndTargetType(this.danceManagerId, FollowTargetTypeEnum.DANCE_SCHOOL);
  }

  loadFollowsAndFollowers(): void {
    this.userFollowStateService.loadFollowsByTargetIdAndTargetType(this.danceManagerId, FollowTargetTypeEnum.DANCE_SCHOOL);
    this.userFollowStateService.loadFollowersByTargetIdAndTargetType(this.danceManagerId, FollowTargetTypeEnum.DANCE_SCHOOL);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.userFollowStateService.clear();
  }
  openFollowInfoDialog(tabIndex: number = 0): void {
    if (this.currentUser) {
      this.loadFollowsAndFollowers();
      const dialogRef = this.dialog.open(FollowInfoDialogComponent, {
        maxHeight: '80vh',
        minWidth: '300px',
        width: '50vw',
        id: 'follow-info-dialog-container',
        panelClass: 'follow-info-dialog-container',
        data: {
          tabIndex: tabIndex,
          hideFollowing: true,
          hideFollowers: false
        }
      });

      this.router.events
        .pipe(
          take(1),
          filter((event) => event instanceof NavigationStart)
        )
        .subscribe(() => {
          dialogRef.close();
        });

      dialogRef.afterClosed().subscribe(() => {
       
      });
    } else {
      this.routeToLogin();
    }
  }

  followTarget(): void {
    if (this.currentUser) {
      if (this.userFollowStateService.getSyncAvailableFollowCount().isCurrentUserFollowing) {
        this.userFollowStateService
          .unfollowUser(this.userFollowStateService.getSyncAvailableFollowCount().followId)
          .pipe(filter((v) => v !== null))
          .subscribe((data: any) => {
            if (data) {
              this.matSnackBar.open('Unfollowed');
              this.loadFollowCountOfCurrentUser();
            }
          });
      } else {
        this.userFollowStateService
          .followUser(this.danceManagerId, FollowTargetTypeEnum.DANCE_SCHOOL)
          .pipe(filter((v) => v !== null))
          .subscribe((data: Follow) => {
            if (data) {
              this.matSnackBar.open('Followed');
              this.loadFollowCountOfCurrentUser();
            }
          });
      }
    } else {
      this.routeToLogin();
    }
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
  }

  DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;

}
