<ng-container *transloco="let t">
  <div class="mat-card-wrapper">
    <mat-card style="padding: 16px;" class="package-card round-corners mat-elevation-z1 mt2">
      <div class="flex-row align-space-between">
        <span class="package-title">{{ title }}</span>
        <div [ngClass]="'subscription-type-container-' +danceManagerSubscriptionType.toLowerCase()">
          <span>{{ (danceManagerSubscriptionType === DanceManagerSubscriptionTypeEnum.BRONZE ? 'Pro' : danceManagerSubscriptionType) | titlecase }}</span>
        </div>
      </div>
      <p class="package-description">{{ description }}</p>
      <a class="package-link-text" target="_blank" contactUrl>{{ t('GENERIC_WRAPPER.LEARN_MORE') }}</a>
      <div class="bottom-align">
        <img [ngStyle]="{ 'margin-bottom': showToggleForAction ? '' : '56px' }" class="image-size" [src]="imgUrl"/>
        <div class="py2 card-divider-container">
          <mat-divider></mat-divider>
        </div>
        <div *ngIf="!isFeatureComingSoon && showToggleForAction" class="flex-row align-space-between pb2">
          <span>{{isFeatureActive ? (t('GENERIC_WRAPPER.ACTIVE')) : (t('GENERIC_WRAPPER.INACTIVE'))}}</span>
          <mat-slide-toggle [checked]="isFeatureActive" (change)="toggleClickedEvent.emit($event)"></mat-slide-toggle>
        </div>
        <div *ngIf="isFeatureComingSoon" class="flex-row">
          <span>{{ t('GENERIC_WRAPPER.COMING_SOON_SHORT') }}</span>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
