<div class="flex-row align-center">
  <a *ngIf="dmNotificationItem" class="w-100 cursor-pointer" (click)="handleLinkClick()">
    <mat-card class="notification-card round-corners mat-elevation-z1 mb2">
      <div class="flex-column">
        <span class="notification-card-title">{{ dmNotificationItem.text }}</span>
        <span class="notification-card-time-passed">{{ dmNotificationItem.createdAt | timeAgo }}</span>
      </div>
      <button
        (click)="handleButtonClick($event)"
        class="notification-read-button"
        mat-icon-button>
        <mat-icon class="icon-color">{{ notification.readAt ? 'redo' : 'done'}}</mat-icon>
      </button>
    </mat-card>
  </a>
</div>
