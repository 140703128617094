import { AddressInterface, CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, CustomRecurrenceWeekDaysTypeEnum, LocationInterface, SchedulerRecurrenceTypeEnum, SchedulersInterface, SchedulerTypeEnum, UsersInterface } from '../../..';

export class SchedulerDto implements SchedulersInterface {
  schedulerType: SchedulerTypeEnum;
  recurrenceType: SchedulerRecurrenceTypeEnum;
  customRecurrenceType: CustomRecurrenceTypeEnum;
  customRecurrenceWeekDaysTypes: CustomRecurrenceWeekDaysTypeEnum[];
  customRecurrenceEndType: CustomRecurrenceEndTypeEnum;
  customRecurrenceNumber: number;
  numberOfAppointments: number;
  locationId: string;
  roomId: string;
  address: AddressInterface;
  location: LocationInterface;
  additionalInformation: string;
  danceClassId: string;
  teachers: UsersInterface[];
  clientTimezone: string;
}
