import { addMinutes, isBefore, isWithinInterval, isAfter } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function isDateInTimeZonePast(
  date: Date,
  timeZone: string,
  duration?: number
): boolean {
  if (duration) {
    const dateWithDuration = addMinutes(
      date,
      +duration === -1 ? 600 : duration
    );
    return isBefore(
      utcToZonedTime(dateWithDuration, timeZone),
      utcToZonedTime(Date.now(), timeZone)
    );
  } else {
    return isBefore(date, utcToZonedTime(Date.now(), timeZone));
  }
}

export function isCurrentDateBeforeInTimezone(
  date: Date,
  timeZone: string
): boolean {
  return isBefore(utcToZonedTime(Date.now(), timeZone), date);
}

export function isCurrentDateAfterInTimezone(
  date: Date,
  timeZone: string
): boolean {
  return isAfter(utcToZonedTime(Date.now(), timeZone), date);
}

export function isCurrentDateInInterval(
  startDate: Date,
  endDate: Date,
  timeZone: string
): boolean {
  return isWithinInterval(utcToZonedTime(Date.now(), timeZone), {
    start: startDate,
    end: endDate,
  });
}
