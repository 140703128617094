import { PassSaleSelectionPageInterface, PassStatusEnum } from '../../..';
import { CurrencyDto } from '../../..';
import { PassTargetDto } from './pass-target.dto';
import { PassValidityDto } from './pass-validity.dto';

export class PassSaleSelectionPageResponseDto implements PassSaleSelectionPageInterface {
  id: string;
  name: string;
  usageCount: number;
  availableQuantity: number;
  price: number;
  currency: CurrencyDto;
  description: string;
  passTargets: PassTargetDto[];
  status: PassStatusEnum;
  validity: PassValidityDto;
  trial: boolean;
}
