import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserProfileStateService } from '../../services';
import { DANCE_STYLES, DanceStyleInterface, UsersDanceStylesInterface } from '@platri/df-common-core';
import { DfDanceStyleSearchInputComponent } from '../df-dance-style-search-input';

@Component({
  selector: 'df-shared-lib-dance-style-edit',
  templateUrl: './df-dance-style-edit.component.html',
  styleUrls: ['./df-dance-style-edit.component.scss']
})
export class DfDanceStyleEditComponent implements OnInit {
  @Input() danceStylesToEdit: UsersDanceStylesInterface[] = [];
  @Output() closeEditMode = new EventEmitter<any>();
  @ViewChild(DfDanceStyleSearchInputComponent) dfDanceStyleSearchInputComponent: DfDanceStyleSearchInputComponent;
  temporaryDanceStyles: UsersDanceStylesInterface[] = [];

  dataChanged = false;

  constructor(private readonly userProfileService: UserProfileStateService) {}

  ngOnInit(): void {
    this.temporaryDanceStyles = this.danceStylesToEdit;
  }

  onDanceStyleSelectedFromSearch(translationKey: string): void {
    const index = this.temporaryDanceStyles?.findIndex((danceStyle) => danceStyle.translationKey === translationKey);
    if (index === -1) {
      const selectedDanceStyle: DanceStyleInterface = DANCE_STYLES.find((danceStyle) => danceStyle.translationKey === translationKey);
      if (selectedDanceStyle) {
        this.temporaryDanceStyles.push({
          isLeader: false,
          isFollower: false,
          translationKey: selectedDanceStyle.translationKey,
          experience: 1,
          danceStyle: selectedDanceStyle
        });
        this.dataChanged = true;
      }
    }
  }

  onDanceStyleUpdated(profileDanceStyle: UsersDanceStylesInterface): void {
    const index = this.temporaryDanceStyles.findIndex((danceStyle) => danceStyle.translationKey === profileDanceStyle.translationKey);
    if (index > -1) {
      this.temporaryDanceStyles![index] = profileDanceStyle;
      this.dataChanged = true;
    }
  }

  onDanceStyleDeleted(profileDanceStyle: UsersDanceStylesInterface): void {
    const index = this.temporaryDanceStyles.findIndex((danceStyle) => danceStyle.translationKey === profileDanceStyle.translationKey);
    if (index > -1) {
      this.temporaryDanceStyles.splice(index, 1);
      this.dataChanged = true;
    }
    this.dfDanceStyleSearchInputComponent.getAllDanceStyles();
  }

  trackProfileDanceStyle(index: number, danceStyle: UsersDanceStylesInterface): string {
    return danceStyle.translationKey;
  }

  onSaveClick(): void {
    // @ts-ignore
    this.userProfileService.updateUserProfileDanceStyles(this.temporaryDanceStyles);
    this.reset();
  }

  reset(): void {
    this.closeEditMode.emit();
    this.dataChanged = false;
    this.temporaryDanceStyles = [];
  }
}
