import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function quillCharacterCountValidator(maxCharacters: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const strippedValue = control.value
      .replace(/<br\s*\/?>/g, ' ')
      .replace(/<\/?p>/g, '')
      .replace(/&nbsp;/g, ' ');
    const actualCharacterCount = strippedValue.length;
    if (actualCharacterCount > maxCharacters) {
      return { characterCountExceeded: true };
    }
    return null;
  };
}
