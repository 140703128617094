<ng-container *transloco="let t">
  <div class="w-100 flex-column" *ngIf="danceManagerSubscription?.subscriptionStatus === danceManagerSubscriptionStatus.ACTIVE; else showInactiveSubscriptionHistory">
      <div class="subscription-detail-container p2 flex-column">
          <div class="flex-row align-center">
              <mat-spinner *ngIf="loading" [diameter]="35"></mat-spinner>
          </div>
          <div *ngIf="!loading && danceManager && danceManagerSubscription">
              <div class="flex-row align-space-between">
                  <span>
              {{ t('GENERIC_WRAPPER.CURRENT_PLAN.TITLE') }}:
              <strong>
                {{ t('GENERIC_WRAPPER.SUBSCRIPTION_TYPE_OPTIONS.' + danceManager.danceManagerSubscriptionType) }}
                ({{ t('GENERIC_WRAPPER.SUBSCRIPTION_INTERVAL_OPTIONS.' + danceManagerSubscription.subscriptionInterval.toUpperCase()) }})
              </strong
              >
            </span>
                  <span
                          *ngIf="
          danceManagerSubscription?.subscriptionStatus ===
          danceManagerSubscriptionStatus.ACTIVE
        "
                          style="color: #3377FF; cursor: pointer"
                          (click)="openCancelDialog()"
                  >
        {{ t('GENERIC_WRAPPER.CANCEL_SUBSCRIPTION') }}
      </span>
              </div>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <div class="flex-row align-space-between">
                  <span>
              {{ t('GENERIC_WRAPPER.CURRENT_PLAN_PRICE') }}:
              <strong>
                {{ t('GENERIC_WRAPPER.SUBSCRIPTION_PRICES.' + danceManagerSubscription.subscriptionInterval.toUpperCase()) }}
                ({{ t('GENERIC_WRAPPER.SUBSCRIPTION_INTERVAL_OPTIONS.' + danceManagerSubscription.subscriptionInterval.toUpperCase()) }})
              </strong>
            </span>
              </div>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <span>
          {{ t('GENERIC_WRAPPER.CURRENT_PLAN_STATUS') }}:
          <strong>
            {{ t('GENERIC_WRAPPER.STATUS_OPTIONS.' + danceManagerSubscription.subscriptionStatus) }}
          </strong>
        </span>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <span>
          {{ t('GENERIC_WRAPPER.PLAN_START_DATE') }}:
          <strong>{{
              danceManagerSubscription.startedAt
                  | date: 'fullDate':null:translocoService.getActiveLang().toUpperCase()
              }}</strong>
        </span>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <span>
          {{ t(danceManagerSubscription.subscriptionStatus === danceManagerSubscriptionStatus.ACTIVE ? 'GENERIC_WRAPPER.SUBSCRIPTION_RENEWS_AT' :  'GENERIC_WRAPPER.SUBSCRIPTION_END_AT') }}:
          <strong>{{
              danceManagerSubscription.currentPeriodEndAt
                  | date: 'fullDate':null:translocoService.getActiveLang().toUpperCase()
              }}</strong>
        </span>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <span>
          {{ t('GENERIC_WRAPPER.PAYMENT_METHOD') }}:
          <strong>
            {{ t('GENERIC_WRAPPER.PAYMENT_METHOD_OPTIONS.' + danceManagerSubscription.subscriptionPaymentMethod.toUpperCase()) }}
          </strong>
        </span>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <span
                      *ngIf="
            danceManagerSubscription.danceManagerSubscriptionInvoices?.length > 0
          "
              >
          {{ t('GENERIC_WRAPPER.INVOICE') }}:
          <a
                  style="color: #3377FF; cursor: pointer"
                  target="_blank"
                  [href]="
              danceManagerSubscription | subscriptionLatestInvoice
            "
          >
            {{ t('GENERIC_WRAPPER.DOWNLOAD_INVOICE') }}
          </a>
        </span>
              <div class="py2 divider-container">
                  <mat-divider></mat-divider>
              </div>
              <div style="cursor: pointer;" class="flex-row align-space-between" (click)="showPaymentHistoryClick.emit(true)">
                   <span>
          {{ t('GENERIC_WRAPPER.PAYMENT_HISTORY') }}
  
        </span>
                  <mat-icon style="color: #4D4D4D">keyboard_arrow_right</mat-icon>
              </div>
          </div>
      </div>
  </div>
  
  <ng-template #showInactiveSubscriptionHistory>
    <div class="subscription-detail-container p2">
      <div class="flex-row align-center">
                <mat-spinner *ngIf="loading" [diameter]="35"></mat-spinner>
      </div>
      <div *ngIf="!loading && danceManager && danceManagerSubscription">
          <div class="flex-row align-space-between">
              <span>
                  {{ t('GENERIC_WRAPPER.CURRENT_PLAN.TITLE') }}:
                  <strong>
                    {{t('GENERIC_WRAPPER.SUBSCRIPTION_TYPE_OPTIONS.' + danceManager.danceManagerSubscriptionType) }}
                  </strong>
              </span>
             <div *ngIf=" danceManagerSubscription?.subscriptionStatus === danceManagerSubscriptionStatus.TERMINATED_PERIOD_END">
             <span class="reactivate-subscription" (click)="reactiveSubscription()">{{ t('GENERIC_WRAPPER.REACTIVATE_SUBSCRIPTION') }}</span>
             </div>
          </div>
        
          <div class="py2 divider-container">
              <mat-divider></mat-divider>
          </div>
          <ng-container *ngIf="isExpired(danceManagerSubscription.currentPeriodEndAt); else notExpired">
              <span>
                  {{ t(danceManagerSubscription.subscriptionStatus === danceManagerSubscriptionStatus.ACTIVE ? 'GENERIC_WRAPPER.SUBSCRIPTION_RENEWS_AT' :  'GENERIC_WRAPPER.SUBSCRIPTION_BRONZE_ENDED_AT') }}:
                  <strong>{{ danceManagerSubscription.currentPeriodEndAt | dateTimezoned: 'UTC' : currentUser.appLang : null : true }}
                  </strong>
              </span>
          </ng-container>
          <ng-template #notExpired>
              <span>
                  {{ t(danceManagerSubscription.subscriptionStatus === danceManagerSubscriptionStatus.ACTIVE ? 'GENERIC_WRAPPER.SUBSCRIPTION_RENEWS_AT' :  'GENERIC_WRAPPER.SUBSCRIPTION_BRONZE_WILL_END') }}:
                  <strong>
                      {{ danceManagerSubscription.currentPeriodEndAt | dateTimezoned : 'UTC': currentUser.appLang : null : true }}
                  </strong>
              </span>
          </ng-template>
          <div class="py2 divider-container">
              <mat-divider></mat-divider>
          </div>
          <div style="cursor: pointer;" class="flex-row align-space-between" (click)="showPaymentHistoryClick.emit(true)">
              <span>
                  {{ t('GENERIC_WRAPPER.PAYMENT_HISTORY') }}
              </span>
              <mat-icon style="color: #4D4D4D">keyboard_arrow_right</mat-icon>
          </div>
      </div>
      
    </div>
  </ng-template>
</ng-container>
