import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropMultipleImagesDialogComponent } from './crop-multiple-images-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CropMultipleImagesDialogComponent],
    imports: [
      CommonModule,
      ImageCropperModule,
      MatDialogModule,
      MatButtonModule,
      MatIconModule,
      TranslocoDirective,
    ],
  exports: [CropMultipleImagesDialogComponent],
})
export class CropMultipleImagesDialogModule {}
