import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from '../../pipes';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { ConversationComponent, ConversationModule } from './conversation';
import { UserSearchAutocompleteModule } from '../user-search-autocomplete';
import { SearchConversationPartnersEntryComponent, SearchConversationPartnersEntryModule } from './search-conversation-partners-entry';
import { ConversationListEntryComponent, ConversationListEntryModule } from './conversation-list-entry';
import { MessageComponent, MessageModule } from './message';
import { SearchConversationPartnersComponent, SearchCoversationPartnersModule } from './search-conversation-partners';
import { DfSharedMessagesComponent } from './messages.component';
import { DfSharedButtonModule } from "../buttons";

@NgModule({
  declarations: [
    DfSharedMessagesComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    PipesModule,
    UserSearchAutocompleteModule,
    TranslocoModule,
    MatIconModule,
    ConversationModule,
    MessageModule,
    ConversationListEntryModule,
    SearchCoversationPartnersModule,
    SearchConversationPartnersEntryModule,
    DfSharedButtonModule
  ],
  exports: [
    ConversationComponent,
    MessageComponent,
    DfSharedMessagesComponent,
    ConversationListEntryComponent,
    SearchConversationPartnersComponent,
    SearchConversationPartnersEntryComponent
  ],
})
export class SharedMessagesModule {}
