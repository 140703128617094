<ng-container *transloco="let t">
  <div class="flex-column" [formGroup]="formGroup">
    <div
      class="pt3 pb3 pl1 flex-column"
      style="gap: 2rem;"
    >
      <div class="flex-column">
        <span class="m0">
          <strong>{{ t('DANCE_MANAGER_EVENT.EVENT_NAME') }}</strong>
        </span>
        <span class="fs14 mt1">
          {{ t('DANCE_MANAGER_EVENT.CHOOSE_NAME') }}
        </span>
        <mat-form-field
          class="mt2 pad-matformfield"
          appearance="outline"
          style="width: 33%"
        >
          <mat-label>{{ t('DANCE_MANAGER_EVENT.EVENT_NAME') }}</mat-label>
          <input matInput type="text" formControlName="name" required #name id="event-name-input" />
          <mat-hint align="end">{{ name.value.length }} / 64</mat-hint>
          <mat-error *ngIf="nameControl.hasError('required')">{{ t('DANCE_MANAGER_EVENT.ERROR_NAME') }}</mat-error>
          <mat-error
            *ngIf="nameControl.hasError('maxlength')"
            class="errorTextSpaceBetween"
          >
            <div>
              {{ t('GENERIC_WRAPPER.ERROR_NAME_MAX_LENGTH') }}
            </div>
            <div style="margin-left: auto">
              {{ nameControl.errors?.maxlength.actualLength }} /
              {{ nameControl.errors?.maxlength.requiredLength }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>
  
      <div class="flex-column">
        <span>
          <strong>{{ t('GENERIC_WRAPPER.CATEGORY') }}</strong>
        </span>
        <span class="mb2 mt1" style="font-size: 14px">{{ t('DANCE_MANAGER_EVENT.CATEGORY_DESCRIPTION') }}</span>
        <df-shared-lib-search-category
          class="pad-matformfield"
          [selectedFilters]="categoryControl.value"
          [required]="true"
          (selectedFiltersChanged)="selectCategory($event)"
          id="category-search-input"
        ></df-shared-lib-search-category>
      </div>
  
      <div class="flex-column">
        <span>
          <strong>{{ t('GENERIC_WRAPPER.DESCRIPTION') }}</strong>
        </span>
        <span class="mb2 mt1" style="font-size: 14px; width: 75%">{{ t('DANCE_MANAGER_EVENT.DESCRIPTION_INFO') }}</span>
        
        <df-shared-lib-quill style="width: 75%" [customFormControlName]="'description'" [hasError]="(formGroup.controls.description.hasError('required') && formGroup.controls.description.touched)"></df-shared-lib-quill>
        <mat-error *ngIf="descriptionControl.hasError('required') && descriptionControl.touched" class="custom-error">{{ t('DANCE_MANAGER_EVENT.ERROR_DESCRIPTION') }}</mat-error>
      </div>
      
      <div class="flex-column">
        <span>
          <strong>{{ t('DANCE_MANAGER_EVENT.DANCE_STYLES') }}</strong>
        </span>
        <span class="fs14 my1">
          {{ t('DANCE_MANAGER_EVENT.DANCE_STYLES_DESCRIPTION') }}
        </span>
        <df-shared-lib-search-dance-style
          class="pad-matformfield"
          [previouslySelectedOptions]="danceStylesControl.value"
          (selectedOption)="selectDanceStyle($event)"
        >
        </df-shared-lib-search-dance-style>
         <div class="request-dance-style mb2">
           <span>{{t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE') + " "}}</span>
           <span class="df-link" (click)="openDanceManagerDanceStyleRequestForm()"
           >{{ t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE_LINK') }}.</span>
         </div>
        
        <div *ngIf="danceStylesControl?.value?.length > 0">
          <mat-chip-listbox>
            <mat-chip-option
              class="chip-bg"
              [selectable]="false"
              [removable]="true"
              (removed)="removeSelectedDanceStyle(danceStyle)"
              *ngFor="let danceStyle of danceStylesControl.value"
            >
              {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>
      
      <div class="flex-column">
        <span><strong>{{ t('DANCE_MANAGER_EVENT.MUSIC_STYLES') }}</strong></span>
        <span class="fs14 my1">{{ t('GENERIC_WRAPPER.MUSIC_STYLES_DESCRIPTION') }}</span>
        <df-shared-lib-search-music-style
          class="pad-matformfield"
          [previouslySelectedOptions]="musicStylesControl.value"
          (selectedOption)="selectMusicStyle($event)"
        ></df-shared-lib-search-music-style>
        <div class="request-dance-style mb2">
          <span>{{t('DANCE_MANAGER_EVENT.REQUEST_MUSIC_STYLES') + " "}}</span>
          <span class="df-link" (click)="openDanceManagerMusicStyleRequestForm()">{{ t('DANCE_MANAGER_EVENT.REQUEST_MUSIC_STYLES_LINK') }}.</span>
        </div>
  
        <div *ngIf="musicStylesControl?.value?.length > 0">
          <mat-chip-listbox>
            <mat-chip-option
              class="chip-bg"
              [selectable]="false"
              [removable]="true"
              (removed)="removeSelectedMusicStyle(musicStyle)"
              *ngFor="let musicStyle of musicStylesControl.value"
            >
              {{t('GENERIC_WRAPPER.MUSIC_STYLES.' + musicStyle.translationKey.toUpperCase())}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>
      
      <div class="flex-column">
        <span>
          <strong>{{ t('DANCE_MANAGER_EVENT.TAGS') }}</strong>
        </span>
        <span class="fs14 my1">
          {{ t('DANCE_MANAGER_EVENT.TAGS_DESCRIPTION') }}
        </span>
        <mat-form-field
          color="primary"
          appearance="outline"
          class="pad-matformfield"
        >
          <mat-label>{{ t('DANCE_MANAGER_EVENT.TAGS') }}</mat-label>
          <mat-chip-grid #tagList>
            <input
              autocomplete="off"
              [matChipInputFor]="tagList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addTag($event)"
            />
          </mat-chip-grid>
          <mat-hint align="end">{{ tagsControl?.value?.length }} / 10</mat-hint>
        </mat-form-field>
  
        <mat-chip-listbox class="pad-matformfield">
          <mat-chip-option
            *ngFor="let tag of tagsControl.value"
            class="chip-bg"
            [selectable]="false"
            [removable]="true"
            (removed)="removeTag(tag)"
          >
            {{ tag }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      
      <div class="flex-column">
        <span>
          <strong>{{ t('DANCE_MANAGER_EVENT.LINK') }}</strong>
        </span>
        <span class="my1" style="font-size: 14px">
          {{ t('DANCE_MANAGER_EVENT.LINK_DESCRIPTION') }}
        </span>
        <mat-form-field appearance="outline" class="pad-matformfield">
          <mat-label>
            {{ t('DANCE_MANAGER_EVENT.LINK') }}
          </mat-label>
          <input type="text" matInput formControlName="url" />
        </mat-form-field>
        <mat-error *ngIf="urlControl.hasError('pattern')">{{ t('DANCE_MANAGER_EVENT.INVALID_URL') }}</mat-error>
      </div>
      
      <!--the beginning of co-host-->
      <div class="flex-column">
        <span class="co-host-title">{{ t('DANCE_MANAGER_EVENT.CO_HOSTS') }}</span>
        <span class="co-host-description mb2 mt1">{{ t('DANCE_MANAGER_EVENT.CO_HOSTS_DESCRIPTION') }}</span>
        <df-shared-lib-co-host-selection
          class="pad-matformfield"
          (optionSelected)="setSelectedCoHost($event)"
          (optionRemoved)="removeSelectedCoHost($event)"
          [customFormGroup]="formGroup"
          [existedDanceManagers] = danceEvent?.coHosts
        ></df-shared-lib-co-host-selection>
      </div>
      <!--the end of co host html code-->
      
      <div class="flex-column" style="margin-bottom: 0;">
        <span
          ><strong>{{ t('DANCE_MANAGER_EVENT.PICTURE') }}</strong></span
        >
        <span class="my2" style="font-size: 14px">
          {{ t('DANCE_MANAGER_EVENT.PICTURE_DESCRIPTION') }}
        </span>
        <df-add-image
          class="pad-matformfield"
          (selectedUploadImage)="onImageUpload($event)"
          (imageDeleted)="onImageDelete()" 
          [showEmptyPreview]="true"
          [isOpenAddImage]="!!(danceEvent && danceEvent.mainImage)"
          [croppedImageBaseAs64]="
            danceEvent && danceEvent.mainImage
              ? danceEvent.mainImage?.url
              : null
          "
        >
        </df-add-image>
      </div>
      <div
        class="flex-responsive-container"
      >
        <div
          class="px3 flex-responsive-button-container"
          style="gap: 1rem;"
        >
          <df-shared-lib-button
            *ngIf="showCancelButton"
            [buttonLabel]="cancelButtonLabel"
            (buttonClicked)="onCancelClick.emit()"
            materialButtonStyleType="outlined"
          >
          </df-shared-lib-button>
          <df-shared-lib-button
            [buttonLabel]="submitButtonLabel"
            [isDisabled]="formGroup.invalid"
            (buttonClicked)="onSubmitClick.emit()"
            id="continue-button-1"
          >
          </df-shared-lib-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
