import { Pipe, PipeTransform } from '@angular/core';
import { LevelSliderEnum } from '@platri/df-common-core';

@Pipe({
  name: 'danceSkillLevelPipe',
})
export class DanceSkillLevelPipe implements PipeTransform {
  transform(value: number): LevelSliderEnum {
    if (value === null) {
      return null;
    }
    return getLevelSliderEnumForLevel(value);
  }
}

function getLevelSliderEnumForLevel(value: number): LevelSliderEnum {
  const enumValue = calculateSkillLevel(value);
  switch (enumValue) {
    case 0:
      return LevelSliderEnum.BEGINNER;
    case 1:
      return LevelSliderEnum.IMPROVER;
    case 2:
      return LevelSliderEnum.INTERMEDIATE;
    case 3:
      return LevelSliderEnum.ADVANCED;
    case 4:
      return LevelSliderEnum.MASTER;
    default:
      return LevelSliderEnum.BEGINNER;
  }
}

function calculateSkillLevel(value: number): number {
  if (value >= 100) {
    return 4;
  }
  return Math.floor(value / 20);
}
