import { Component } from '@angular/core';

@Component({
  selector: 'df-header-logo',
  templateUrl: './header-df-logo.component.html',
  styleUrls: ['./header-df-logo.component.scss']
})
export class HeaderDfLogoComponent {
  
}
