import { MessagePartnerInterface } from './message-partner.interface';
import { MessageInterface } from './message.interface';

export interface ConversationInterface {
  id?: string;
  fromPartnerId: string;
  partnerIds?: string;
  partners?: MessagePartnerInterface[];
  messages?: MessageInterface[];
  readAt?: Date | null;
}

export interface ConversationEntityInterface {
  id?: string;
  fromPartnerId?: string;
}
