import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryTicketListItemComponent } from './inventory-ticket-list-item.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { PipesModule } from '@platri/dfx-angular-core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [InventoryTicketListItemComponent],
  imports: [
    CommonModule,
    MatIcon,
    MatIconButton,
    MatMenu,
    RouterLink,
    PipesModule,
    MatTooltip,
    MatMenuTrigger,
    MatMenuItem,
    TranslocoDirective

  ],
  exports: [InventoryTicketListItemComponent]
})
export class InventoryTicketListItemModule {}
