import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER, NotificationInterface, NotificationOverviewInterface } from '@platri/df-common-core';
import { NotificationStateService } from '@platri/dfx-angular-core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'df-header-user-notification',
  templateUrl: './header-user-notification.component.html',
  styleUrls: ['./header-user-notification.component.scss']
})
export class HeaderUserNotificationComponent implements OnInit, OnDestroy{
  @Input() unreadCount: NotificationOverviewInterface;
  @Input() notifications: NotificationInterface[] = [];
  
  loading$: Observable<boolean>;

  private subscription = new Subscription();

  constructor(private notificationStateService: NotificationStateService) {}

  ngOnInit(): void {
    this.loading$ = this.notificationStateService.getAsyncIsLoadingNotifications();
  }

  onNotificationIconClick(): void {
    this.notificationStateService.loadAllNotificationsForCurrentUser();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
}
