import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfDmFollowContainerComponent } from './df-dm-follow-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FollowInfoCardModule } from '../follow-info-card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '../../pipes';
import { ImageFallbackDirectiveModule } from '../../directives';
import { DfSharedButtonModule } from '../buttons';
import { FollowInfoDialogModule } from '../follow-info-dialog';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfDmFollowContainerComponent],
    imports: [CommonModule, PipesModule, ReactiveFormsModule, MatInputModule, FollowInfoCardModule, FollowInfoDialogModule, MatSnackBarModule, MatChipsModule, MatButtonModule, MatIconModule, MatCardModule, ImageFallbackDirectiveModule, DfSharedButtonModule, TranslocoDirective],
  exports: [DfDmFollowContainerComponent]
})
export class DfDmFollowContainerModule {}
