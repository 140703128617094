import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerUserInviteDialogComponent } from './dance-manager-user-invite-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { DfSharedButtonModule } from '../buttons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DfLinkClipboardModule } from '../df-link-clipboard';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceManagerUserInviteDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatInputModule,
    DfSharedButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    MatButtonModule,
    DfLinkClipboardModule,
    TranslocoDirective,
  ],
  exports: [DanceManagerUserInviteDialogComponent],
})
export class DanceManagerUserInviteDialogModule {}
