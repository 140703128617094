import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb, FollowCountsDto, FollowDto, FollowTargetTypeEnum, NotificationUseCaseEnum } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class FollowHttpService {
  private SERVICE_URL = `${environmentForWeb.apiFollowUrl}/follows`;

  constructor(private readonly http: HttpClient) {}

  addFollowToUser(addFollowToUserDto: any): Observable<FollowDto> {
    return this.http.post<FollowDto>(`${this.SERVICE_URL}`, addFollowToUserDto);
  }

  deleteFollowFromUser(id: string): Observable<void> {
    return this.http.delete<void>(`${this.SERVICE_URL}/${id}`);
  }

  getFollowCountsByTargetIdAndTargetType (targetId: string, targetType: FollowTargetTypeEnum) : Observable<FollowCountsDto>{
    return this.http.get <FollowCountsDto> (`${this.SERVICE_URL}/follow-counts/${targetType}/${targetId}`);
  }

  getFollowsByTargetIdAndTargetType(targetId: string, targetType: FollowTargetTypeEnum): Observable<FollowCountsDto[]>{
     return this.http.get<FollowCountsDto[]>(`${this.SERVICE_URL}/follows/${targetType}/${targetId}`);
  }

  getFollowersByTargetIdAndTargetType(targetId: string, targetType: FollowTargetTypeEnum):Observable<FollowCountsDto[]>{
    return this.http.get<FollowCountsDto[]>(`${this.SERVICE_URL}/followers/${targetType}/${targetId}`);
  }
  
  notifyFollowers(targetId: string, useCase: NotificationUseCaseEnum):Observable<boolean>{
    return this.http.post<boolean>(`${this.SERVICE_URL}/notify-followers-push-notification`, {"targetId": targetId, "useCase": useCase});
  }
}
