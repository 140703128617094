import { NotificationDataInterface, NotificationUseCaseEnum } from '../../..';

export class AddedToDanceManagerNotificationDataDto implements NotificationDataInterface {
  public readonly useCase = NotificationUseCaseEnum.ADDED_TO_DANCE_MANAGER;
  
  constructor(
    public readonly useCaseSpecificData: {
      id: string;
      danceSchoolUserRole?: string;
      danceSchoolId: string;
      userId: string;
      danceManagerLogoUrl?: string;
      danceManagerName?: string;
      danceManagerUrlName?: string;
    }) {}
}
