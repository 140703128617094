import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DanceManagerService, DfmStateService } from '@platri/dfx-angular-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-market-place',
  templateUrl: './dfm-market-place.page.html',
  styleUrls: ['./dfm-market-place.page.scss'],
})
export class DfmMarketPlacePage implements OnInit {

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
    public readonly danceManagerService: DanceManagerService,
    public dfmStateService: DfmStateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['from_stripe_onboarding'] === 'true' && queryParams['status'] !== '') {
        if (queryParams['status'] === 'success') {
          this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_MARKET_PLACE.ONBOARDING_SUCCESS_MESSAGE'));
        } else {
          this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_MARKET_PLACE.ONBOARDING_EXPIRED_MESSAGE'));
        }
      }
    });
  }
}
