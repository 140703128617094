import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  environmentForWeb,
  UserTicketCollapsedUpcomingPastFilteredDto,
  UserTicketDanceEventStatisticsResponseDto,
  UserTicketDto
} from '@platri/df-common-core';
import { CreateFreeTicketRequestDto } from '@platri/df-nestjs-core';

@Injectable({
  providedIn: 'root',
})
export class UserTicketHttpService {
  private apiBaseUrl = `${environmentForWeb.apiTicketUrl}/user-tickets`;

  constructor(private readonly http: HttpClient) {}

  transferById(id: string, userId: string): Observable<UserTicketDto> {
    return this.http.put<UserTicketDto>(`${this.apiBaseUrl}/${id}/transfer`, { userId: userId });
  }

  downloadTickets(eventId: string, ticketOrderId?: string, userTicketIds?: string[]): Observable<Blob> {
    return this.http.post(
      `${this.apiBaseUrl}/download`,
      {
        eventId,
        ...(ticketOrderId && { ticketOrderId }),
        ...(Array.isArray(userTicketIds) && { userTicketIds }),
      },
      { responseType: 'blob' }
    );
  }

  postFreeEventTickets(eventId: string, dto: CreateFreeTicketRequestDto): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}/free-tickets/${eventId}`,
      dto
    );
  }

  getAllByCurrentUserAndDanceEventId(danceEventId: string, withDanceEventObject = false): Observable<any> {
    return this.http.get(
      `${this.apiBaseUrl}/dance-event/${danceEventId}${
        withDanceEventObject ? '?withEventObject=true' : ''
      }`
    );
  }

  getAllUserTicketCollapsedByEventAndOrder(): Observable<UserTicketCollapsedUpcomingPastFilteredDto> {
    return this.http.get<UserTicketCollapsedUpcomingPastFilteredDto>(`${this.apiBaseUrl}/collapsed`);
  }
  
  getStatisticsByDanceEventId(danceEventId: string): Observable<UserTicketDanceEventStatisticsResponseDto> {
    return this.http.get<UserTicketDanceEventStatisticsResponseDto>(`${this.apiBaseUrl}/statistics/dance-event/${danceEventId}`);
  }
}
