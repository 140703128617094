
export class EventInfoAndTicketQuantityByStripePaymentIntentResponseDto {
  eventId: string;
  eventName: string;
  eventDate: Date;
  numberOfTickets: number;
  stripePaymentIntentIds: string[];
  total?: number;
  applicationFeeTotal?: number;
}
