import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainContentModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfmGenericWrapperComponent } from './dfm-generic-wrapper.component';
import { RouterModule } from '@angular/router';
import { DfmMainContentModule } from '../dfm-main-content';
import { DfmSidenavModule } from '../dfm-sidenav';

const MATERIAL_MODULES = [MatProgressSpinnerModule];

@NgModule({
  declarations: [DfmGenericWrapperComponent],
  imports: [
    CommonModule,
    DfmMainContentModule,
    MainContentModule,
    ...MATERIAL_MODULES,
    DfmSidenavModule,
    RouterModule
  ],
  exports: [DfmGenericWrapperComponent,]
})
export class DfmGenericWrapperModule {
  constructor() {
    console.log('DfmGenericWrapperModule initialized');
  }
}
