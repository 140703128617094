import { Pipe, PipeTransform } from '@angular/core';
import { DanceManagerSubscription, DanceManagerSubscriptionInvoice } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'subscriptionLatestInvoice',
})
export class SubscriptionLatestInvoice implements PipeTransform {
  transform(subscription: DanceManagerSubscription): string {
    if (subscription.danceManagerSubscriptionInvoices?.length > 0) {
      const invoices = subscription.danceManagerSubscriptionInvoices.sort(
        (
          a: DanceManagerSubscriptionInvoice,
          b: DanceManagerSubscriptionInvoice
        ) => {
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          return 0;
        }
      );
      return invoices[0].stripeInvoicePdfDownloadUrl;
    }
    return null;
  }
}
