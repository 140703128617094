<ng-container *transloco="let t">
  <div class="mat-card-wrapper">
    <mat-card style="padding: 16px;" class="package-card round-corners mat-elevation-z1 mt2 flex-column">
      <div class="package-content">
        <div class="w-100">
          <p class="package-description">{{ t('GENERIC_WRAPPER.CURRENT_PLAN.TITLE') }}</p>
          <div style="display: flex; justify-content: space-between;">
            <p class="package-subcripton-type">{{danceManager.danceManagerSubscriptionType === DanceManagerSubscriptionType.BRONZE ? 'Pro' : danceManager.danceManagerSubscriptionType | titlecase}} &nbsp;</p>
            <p *ngIf="danceManagerSubscription && danceManager.danceManagerSubscriptionType === 'BRONZE'" class="package-description paragraph-position-correction">({{ t('GENERIC_WRAPPER.SUBSCRIPTION_INTERVAL_OPTIONS.' + danceManagerSubscription.subscriptionInterval.toUpperCase()) }})</p>
          </div>
        </div>
        <a  style="text-decoration: none; color: inherit;" [routerLink]="'/../dance-manager/' + danceManager.id + '/settings'" fragment="subscription">
          <div class="upgrade-button-container">
            <df-shared-lib-button 
              [minWidth]="'131px'" 
              [height]="'46px'" 
              [buttonLabel]="t('GENERIC_WRAPPER.CURRENT_PLAN.' +danceManager.danceManagerSubscriptionType)" 
              [materialButtonStyleType]="'flat'"
            ></df-shared-lib-button>
          </div>
        </a>
      </div>
    </mat-card>  
  </div>
</ng-container>
