import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime',
  standalone: true
})
export class RelativeTimePipe implements PipeTransform {

  transform(value: any): string {
    const now = new Date();
    const past = new Date(value);
    const diffInSeconds = Math.abs((now.getTime() - past.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInMonths > 0) {
      return diffInMonths === 1 ? `${diffInMonths} Monat her` : `${diffInMonths} Monate her`;
    } else if (diffInWeeks > 0) {
      return diffInWeeks === 1 ? `${diffInWeeks} Woche her` : `${diffInWeeks} Wochen her`;
    } else if (diffInDays > 0) {
      return diffInDays === 1 ? `${diffInDays} Tag her` : `${diffInDays} Tage her`;
    } else if (diffInHours > 0) {
      return diffInHours === 1 ? `${diffInHours} Stunde her` : `${diffInHours} Stunden her`;
    } else if (diffInMinutes > 0) {
      return diffInMinutes === 1 ? `${diffInMinutes} Minute her` : `${diffInMinutes} Minuten her`;
    } else {
      return `Einige Sekunden her`;
    }
  }
}
