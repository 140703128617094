import { DateTime } from 'luxon';

export function generateTimeOptionsStringArray(
  minute: number = 30,
    timeFormat: string = 'HH:mm'
  ): string[] {
    const options = [];
    const splits = 60 / minute;
    for (let hour = 0; hour < 24; hour++) {
        for (let i = 0; i < splits; i++) {
            options.push(
                DateTime.now()
                  .setZone('UTC')
                .set({
                    hour,
                    minute: i * minute,
                    second: 0,
                    millisecond: 0,
                  })
                .toFormat(timeFormat)
            );
          }
      }
    return options;
  }
