<ng-container *transloco="let t">
  <a
    mat-list-item
    routerLink="events"
    routerLinkActive="active-link"
    matTooltipClass="tooltip"
    [matTooltip]="!isExpanded ? t('DFM_APP.DFM_SIDENAV.EVENTS') : null">
    <mat-icon matListItemIcon class="material-icons-outlined mr2">celebration</mat-icon>
    <span *ngIf="isExpanded" matListItemTitle routerLink="events" routerLinkActive="active-link">
      {{ t('DFM_APP.DFM_SIDENAV.EVENTS') }}
    </span>
  </a>
</ng-container>
