<ng-container *transloco="let t">
  <ng-container *ngIf="data">
    <div class="mb-20">
      <h3 mat-dialog-title>{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.CHANGE_ROLE') }}</h3>
      <strong style="padding: 24px;">
        {{ data.user.firstName + ' ' + data.user.lastName }}
      </strong>
      <mat-dialog-content [formGroup]="editRoleForm">
        <mat-form-field class="w-100" appearance="outline">
          <mat-select
            formControlName="role"
            disableOptionCentering
          >
            <mat-option
              *ngFor="let role of danceSchoolUserRoleEnum | keyvalue"
              [value]="role.value"
            >{{ role.value | titlecase  }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
    </div>
  
    <div class="flex-row align-end" style="gap: 10px; padding: 0 24px;">
      <button
        mat-button
        class="primary-outlined"
        [color]="data.cancelButtonColor || 'primary'"
        mat-dialog-close
      >
        {{ t('GENERIC_WRAPPER.CANCEL') }}
      </button>
      <button
        class="primary-contained"
        [color]="data.submitButtonColor || 'primary'"
        mat-raised-button
        (click)="saveRole()"
      >
        {{ t('GENERIC_WRAPPER.SAVE') }}
      </button>
    </div>
  </ng-container>
</ng-container>
