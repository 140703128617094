import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { FrontendDfmCourseAppRoutingModule } from './frontend-dfm-course-app-routing.module';
import { DfmCoursesModule } from './pages/app';
import { TranslocoStateConst } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfmCourseAppRoutingModule,
    DfmCoursesModule,
    MatSnackBarModule,
    TranslocoModule
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendDfmCourseAppModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'dfm-course', alias: 'DANCE_MANAGER_COURSE' });
  }
}
