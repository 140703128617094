import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateLocationRequestDto, UpdateLocationRequestDto, environmentForWeb, LocationDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class LocationHttpService {
  private apiUrl = `${environmentForWeb.apiDanceSchoolUrl}/dance-schools`;

  constructor(private readonly http: HttpClient) {}

  createLocation(createLocationRequestDto: CreateLocationRequestDto): Observable<LocationDto> {
    return this.http.post<LocationDto>(`${this.apiUrl}/${createLocationRequestDto.danceManagerId}/locations`, createLocationRequestDto);
  }

  updateLocation(id: string, danceManagerId: string, updateLocationRequestDto: UpdateLocationRequestDto): Observable<LocationDto> {
    return this.http.patch<LocationDto>(`${this.apiUrl}/${danceManagerId}/locations/${id}`, updateLocationRequestDto);
  }

  deleteLocation(id: string, danceManagerId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${danceManagerId}/locations/${id}`);
  }
  
  getLocationById(id: string, danceManagerId: string): Observable<LocationDto> {
    return this.http.get<LocationDto>(`${this.apiUrl}/${danceManagerId}/locations/${id}`);
  }

  getAllByDanceManagerId(danceManagerId: string): Observable<LocationDto[]> {
    return this.http.get<LocationDto[]>(`${this.apiUrl}/${danceManagerId}/locations`);
  }

  updateVisibilityByIdAndDanceManagerId(id: string, isPublic: boolean, danceManagerId: string): Observable<LocationDto> {
    return this.http.put<LocationDto>(`${this.apiUrl}/${danceManagerId}/locations/${id}/visibility`, 
      { isPublic }
    );
  }

}
