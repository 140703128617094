import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { MessageHttpService } from '../components';
import { dfUrlNameRouterParam } from '../shared';

@Injectable({ providedIn: 'root' })
export class NameResolver  {
  constructor(private readonly messageHttpService: MessageHttpService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let searchUserName = route.paramMap.get(dfUrlNameRouterParam);
    if (searchUserName[0] === '@') {
      searchUserName = searchUserName.slice(1, searchUserName.length);
    } else {
      this.router.navigateByUrl('/404');
    }
    return this.messageHttpService.searchPartner(searchUserName).pipe(
      catchError((error) => {
        this.router.navigateByUrl('/404');
        return EMPTY;
      })
    );
  }
}
