import { Pipe, PipeTransform } from '@angular/core';
import { Conversation, MessagePartner } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'conversationToPartner'
})
export class ConversationToMessagePartnersPipe implements PipeTransform {
  transform(conversation: Conversation | null, currentPartnerId: string): MessagePartner[] {
    if (!conversation) {
      return [];
    } else {
      return conversation.partners.filter((partner) => partner.id !== currentPartnerId);
    }
  }
}
