import {Pipe, PipeTransform} from '@angular/core';
import { Follow, FollowTargetTypeEnum } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'getIsFavoriteFromFollowsByTargetIdAndTargetType'
})
export class GetIsFavoriteFromFollowsByTargetIdAndTargetTypePipe implements PipeTransform {
  // @ts-ignore
  transform(
    value: Follow[],
    targetId: string,
    targetType: FollowTargetTypeEnum,
  ): boolean {
    let ids: string[];
    if (value) {
      switch (targetType) {
        case FollowTargetTypeEnum.DANCE_EVENT:
          ids = value.filter((follow) => follow.danceEventId !== null).map((follow) => follow.danceEventId);
          break;
        case FollowTargetTypeEnum.DANCE_CLASS:
          ids = value.filter((follow) => follow.danceClassId !== null).map((follow) => follow.danceClassId);
          break;
        case FollowTargetTypeEnum.DANCE_SCHOOL:
          ids = value.filter((follow) => follow.danceSchoolId !== null).map((follow) => follow.danceSchoolId);
          break;
        case FollowTargetTypeEnum.ROOM_RENTAL:
          ids = value.filter((follow) => follow.roomRentalId !== null).map((follow) => follow.roomRentalId);
          break;
        case FollowTargetTypeEnum.LOCATION:
          ids = value.filter((follow) => follow.locationId !== null).map((follow) => follow.locationId);
          break;
      }
      return ids.includes(targetId);
    } else {
      return null;
    }

  }
}
