<ng-container *transloco="let t">
  <div class="flex-column responsive-max-width">
    <div class="title-container flex-row align-start-center">
      <h2 style="margin-right: 15px;">{{t('GENERIC_WRAPPER.CREATE_NEW_COURSE')}}</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-column gap-20 mt3" [formGroup]="infoFormGroup">
      <div class="gap-10 flex-column">
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.INFO.NAME')}}</strong>
        <mat-form-field appearance="outline" class="mt2 pad-matformfield">
          <mat-label>{{t('DANCE_MANAGER_COURSE.INFO.NAME')}}</mat-label>
          <input matInput type="text" id="course-name-mat-input" formControlName="name" required #name />
          <mat-hint align="end">{{ name.value.length }} / 64</mat-hint>
          <mat-error *ngIf="infoFormGroup.controls.name.hasError('required')">{{t('GENERIC_WRAPPER.NAME_IS_REQUIRED')}}</mat-error>
          <mat-error *ngIf="infoFormGroup.controls.name.hasError('maxlength')" class="errorTextSpaceBetween">
              <span>{{t('GENERIC_WRAPPER.NAME_ERROR_MAX_LENGTH')}}</span>
              <span style="margin-left: auto">
                {{ infoFormGroup.controls.name.errors?.maxlength.actualLength }} /
                {{ infoFormGroup.controls.name.errors?.maxlength.requiredLength }}
              </span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-column gap-10">
        <strong class="fs16">{{t('GENERIC_WRAPPER.DESCRIPTION')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.INFO.DESCRIPTION_SUBTEXT')}}</span>
       <df-shared-lib-quill-editor
         [hasError]="
            infoFormGroup.controls.description.hasError('characterCountExceeded') ||
            (infoFormGroup.controls.description.hasError('required') &&
              infoFormGroup.controls.description.touched)"
         [enableMentions]="true"
         [enableCounter]="true"
         [isRequired]="true"
         [maxLength]="2000"
         [id]="'description'"
         [placeholder]="'GENERIC_WRAPPER.DESCRIPTION'"
         customFormControlName="description"
         class="pad-matformfield-description"
       ></df-shared-lib-quill-editor>
        <mat-error
          *ngIf="
            infoFormGroup.controls.description.hasError('required') &&
            infoFormGroup.controls.description.touched"
          class="custom-error"
        >{{t('GENERIC_WRAPPER.DESCRIPTION_REQUIRED')}}</mat-error>
        <mat-error
          *ngIf="infoFormGroup.controls.description.hasError('characterCountExceeded')"
          class="custom-error"
        >{{t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH')}}
        </mat-error>
      </div>
      <div class="flex-column gap-10">
        <strong class="fs16">{{t('GENERIC_WRAPPER.DANCE_STYLES_TEXT')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.INFO.DANCE_STYLES_SUBTEXT')}}</span>
        <df-shared-lib-search-dance-style
          class="pad-matformfield"
          [required]="true"
          [selectedFilters]="infoFormGroup.controls.danceStyles.value"
          [previouslySelectedOptions]="infoFormGroup.controls.danceStyles.value"
          (selectedOption)="selectDanceStyle($event)"
        >
        </df-shared-lib-search-dance-style>
        <div class="request-dance-style mb2">
         <span>{{(t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE')) + " "}}</span>
         <span class="df-link" (click)="openDanceManagerDanceStyleRequestForm()"
         >{{t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE_LINK')}}</span>
        </div>
      
        <div
          *ngIf="infoFormGroup.controls.danceStyles?.value?.length > 0"
          class="compensate-form-field-padding"
        >
          <mat-chip-listbox>
            <mat-chip-option
              [removable]="true"
              [selectable]="false"
              (removed)="removeSelectedDanceStyle(danceStyle)"
              *ngFor="let danceStyle of infoFormGroup.controls.danceStyles.value"
            >
              {{t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>
      <div class="gap-20 flex-column">
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.INFO.SKILL_LEVEL')}}</strong>
         <df-shared-lib-level-slider-material
           [showRange]="false"
           [showLevels]="true"
           [fromLevel]="0"
           [step]="10"
           [toLevel]="100"
           (setLevelEvent)="setCourseLevel($event)"
           class="pad-matformfield"
         >
        </df-shared-lib-level-slider-material>
      </div>
      <div class="gap-10 flex-column">
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.INFO.TAGS')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.INFO.TAGS_SUBTEXT')}}</span>
        <mat-form-field
          color="primary"
          appearance="outline"
          class="pad-matformfield"
        >
          <mat-label>{{t('DANCE_MANAGER_COURSE.INFO.TAGS')}}</mat-label>
          <mat-chip-grid #tagList>
            <input
              autocomplete="off"
              [matChipInputFor]="tagList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addTag($event)"
            />
          </mat-chip-grid>
          <mat-hint align="end">{{ infoFormGroup.controls.tags?.value?.length ?? 0 }} / 10</mat-hint>
        </mat-form-field>
  
        <mat-chip-listbox class="compensate-form-field-padding pad-matformfield">
          <mat-chip-option
            *ngFor="let tag of infoFormGroup.controls.tags.value"
            class="chip-bg"
            [selectable]="false"
            [removable]="true"
            (removed)="removeTag(tag)"
          >
            {{ tag }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div class="flex-column gap-10">
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.INFO.PICTURE')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.INFO.PICTURE_SUBTEXT')}}</span>
        <df-add-image
          class="pad-matformfield"
          (selectedUploadImage)="onImageUpload($event)"
          (imageDeleted)="onImageDelete()"
          [ratio]="{ width: 2, height: 1 }"
          [showEmptyPreview]="true">
        </df-add-image>
      </div>
    </div>
    <div class="w-100 gap-20 align-end-center mt1">
      <df-shared-lib-button
        materialButtonStyleType="outlined"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="onCancelClick.emit()"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.CONTINUE')"
        (buttonClicked)="onContinueClick.emit()"
        [isDisabled]="!infoFormGroup.valid"
        [loading]="loading"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
