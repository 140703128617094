<ng-container *transloco="let t">
  <div style="position: relative" class="flex-row-center-center">
  <div class="preview clickable opacityAnimation flex-row preview-card w-100">
    <a target="_self" [routerLink]="['/inventory', 'tickets', userTicketCollapsed.event.id]" style="flex: 1;">
      <div class="flex-row container-flex-row-responsive-col w-100 h-100" style="gap: 1rem;">
        <div class="flex-column-center-center" id="left-column">
          <div class="flex-column preview-image-container">
            <img [src]="userTicketCollapsed.event.mainImage?.url ?? DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER" alt="" class="preview-image w-100 mb0 block" />
          </div>
        </div>
        <div class="flex-column align-start w-100" id="right-column">
          <div class="flex-row align-space-between" id="appointment-row">
            <span class="appointment-text left-align" *ngIf="userTicketCollapsed.event.appointments && userTicketCollapsed.event.appointments.length > 0">
              {{ userTicketCollapsed.event.appointments[0].startDate | dateRange : userTicketCollapsed.event.appointments[0].duration : currentUser?.appLang ?? null}}
              {{userTicketCollapsed.event.appointments[0].address.timezoneId |timezoneAbbreviation:userTicketCollapsed.event.appointments[0].startDate}}
            </span>
            <div *ngIf="this.userTicketCollapsed?.isCanceled" class="cancelled-tickets-button align-center">
              <div class="flex-row align-center">
                <mat-icon class="distance-icon material-icons-round vSub" style="color: #B00020; margin-right: 8px;">info</mat-icon>
                <span>{{ t('USER.CANCELLED') }}</span>
              </div>
            </div>
          </div>
          <div class="flex-row w-100">
            <div class="flex-column preview-header">
              <a [href]="'/app/dance-event/' + userTicketCollapsed.event.id">
                <span
                  class="dance-event name left-align ellipsis-1"
                  #eventName
                  [matTooltip]="eventName.offsetHeight < eventName.scrollHeight ? userTicketCollapsed.event.name : null"
                  [matTooltipClass]="'fontSize14'"
                  (click)="$event.stopPropagation();">
                      {{ userTicketCollapsed.event.name }}
                </span>
              </a>
              <span
                *ngIf="userTicketCollapsed.event.danceSchool"
                class="dance-school name left-align ellipsis-1"
                #danceSchoolName
                [matTooltip]="danceSchoolName.offsetHeight < danceSchoolName.scrollHeight ? userTicketCollapsed.event.danceSchool.name : null"
                [matTooltipClass]="'fontSize14'">
                {{ userTicketCollapsed.event.danceSchool.name }}
              </span>
            </div>
          </div>
          <div class="flex-row eventDescription w-100 h-100 mb5px">
            <div [class.preview-event-description]="true" [class.dm-card-preview-event-description]="false">
              <span class="ellipsis-2" [innerHTML]="userTicketCollapsed.event.description | stripHtmlTags | truncate: 200"></span>
            </div>
          </div>
  
          <div class="flex-row align-start-center mb5px w-100">
            <a [href]="'/app/dance-event/' + userTicketCollapsed.event.id" style="color: #00a1df">
              <span class="flex-row align-start-center span-with-icon" (click)="$event.stopPropagation();">
                <mat-icon style="padding-right: 30px">open_in_new</mat-icon>
                {{ t('GENERIC_WRAPPER.SEE_EVENT_PAGE') }}
              </span>
            </a>
          </div>
  
          <div class="flex-row end-row-distance w-100">
            <div class="flex-column w-100">
              <span *ngIf="userTicketCollapsed.event.cheapestPrice && userTicketCollapsed.event.cheapestPrice !== -1">
                <b>{{ t('GENERIC_WRAPPER.TICKETS_START_AT') }}</b>
                {{ userTicketCollapsed.event.cheapestPrice | centsToCurrencyString: 'EUR' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
    <div *ngIf="userTicketCollapsed && !this.userTicketCollapsed?.isCanceled" class="p2 download-tickets-button">
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        <mat-icon class="material-icons-round">more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" xPosition="before">
      <button *ngFor="let ticketOrderId of userTicketCollapsed?.ticketOrderIds; let index = index" mat-menu-item (click)="download.emit({ danceEventId: userTicketCollapsed.event.id, ticketOrderId })">
        <mat-icon class="material-icons-round">file_download</mat-icon>
        <span *ngIf="userTicketCollapsed?.ticketOrderIds.length > 1">Tickets {{ index + 1 }}</span>
        <span *ngIf="userTicketCollapsed?.ticketOrderIds.length === 1">Ticket</span>
      </button>
      <button *ngIf="userTicketCollapsed?.freeUserTicketIds?.length > 0" mat-menu-item (click)="download.emit({ danceEventId: userTicketCollapsed.event.id, freeUserTicketIds: userTicketCollapsed.freeUserTicketIds })">
        <mat-icon class="material-icons-round">file_download</mat-icon>
        <span *ngIf="userTicketCollapsed?.freeUserTicketIds.length > 1">Free Tickets</span>
        <span *ngIf="userTicketCollapsed?.freeUserTicketIds.length === 1">Free Ticket</span>
      </button>
    </mat-menu>
  </div>
    </div>
</ng-container>
