import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogModule, DanceStudioImagesDialogModule, DfSharedButtonModule } from '@platri/dfx-angular-core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { DfmGalleryComponent } from './dfm-gallery.component';
import { MatButtonModule } from '@angular/material/button';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmGalleryComponent],
  exports: [DfmGalleryComponent],
  imports: [
    CommonModule,
    MatIconModule,
    DfSharedButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    DanceStudioImagesDialogModule,
    ConfirmationDialogModule,
    MatButtonModule,
    TranslocoDirective
  ],
})
export class DfmGalleryModule {}
