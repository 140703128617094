import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventPageComponent } from './event-page.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DanceEventCoHostsModule, DfSharedButtonModule, PipesModule, ShareModule } from '@platri/dfx-angular-core';
import { GoogleMapsModule } from '@angular/google-maps';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';
import { DanceEventHeaderModule } from '../dance-event-header';
import { EventTicketsModule } from '../../pages';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [EventPageComponent],
  exports: [EventPageComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        ShareModule,
        DanceEventHeaderModule,
        MatChipsModule,
        MatDividerModule,
        MatListModule,
        ReactiveFormsModule,
        EventTicketsModule,
        PipesModule,
        MatTooltipModule,
        GoogleMapsModule,
        MatChipsModule,
        RouterLink,
        DanceEventCoHostsModule,
        DfSharedButtonModule,
        TranslocoDirective,
    ]
})
export class EventPageModule {}
