import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environmentForWeb, TicketDto } from '@platri/df-common-core';

@Injectable({providedIn: 'root'})
export class TicketHttpService {
  private SERVICE_URL = `${environmentForWeb.apiTicketUrl}/tickets`;

  constructor(
    private http: HttpClient,
  ) {
  }

  create(createTicket: TicketDto): Observable<TicketDto> {
    return this.http.post<TicketDto>(this.SERVICE_URL, createTicket);
  }

  update(id: string, updateTicketDto: TicketDto): Observable<TicketDto> {
    return this.http.patch<TicketDto>(this.SERVICE_URL + '/' + id, updateTicketDto);
  }

  delete(id: string): Observable<TicketDto> {
    return this.http.delete<TicketDto>(this.SERVICE_URL + '/' + id);
  }

  updateSequenceNumber(eventId: string, newSequence: {id: string; sequenceNumber: number}[]): Observable<any> {
    return this.http.patch<any>(this.SERVICE_URL + '/events/' + eventId , newSequence);
  }
  
  getById(id: string): Observable<TicketDto> {
    return this.http.get<TicketDto>(this.SERVICE_URL + '/' + id);
  }
  
  getAllByDanceEventId(danceEventId: string, withFreeTickets: boolean = false): Observable<TicketDto[]> {
    return this.http.get<TicketDto[]>(this.SERVICE_URL + '/events/' + danceEventId + `?withFreeTickets=${withFreeTickets}`);
  }

  getDanceManagerHasSoldTickets(danceManagerId: string): Observable<boolean> {
    return this.http.get<boolean>(this.SERVICE_URL + '/dance-manager/' + danceManagerId + '/has-sold-tickets');
  }
  
  getEventMinPriceById(danceEventId: string): Observable<number> {
    return this.http.get<number>(this.SERVICE_URL + '/event-min-price/' + danceEventId);
  }

}

