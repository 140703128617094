import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, filter, map, Observable, of } from 'rxjs';
import { DanceManagerHttpService } from '../services';
import { DanceManagerFeatureInterface } from '@platri/df-common-core';
import { dfmCourseRoute, dfmEventRoute } from '../shared';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({providedIn: 'root'})
export class PackageGuard  {
  features: DanceManagerFeatureInterface[];
  constructor(private router: Router, private snackBar: MatSnackBar, private readonly translocoService: TranslocoService, private readonly danceManagerHttpService: DanceManagerHttpService,) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const danceManagerId = state.url.split('/')[2];
    const packageName = state.url.split('/')[3];
    if (packageName === dfmCourseRoute || packageName === dfmEventRoute) {
      return this.checkPackageState(danceManagerId, packageName);
    }
    return of(true);
  }

  checkPackageState(danceManagerId: string, packageName: string): Observable<boolean> {
    return this.danceManagerHttpService.getDanceManagerFeaturesById(danceManagerId).pipe(
      filter((res) => {
        if (res.length > 0) {
          return true;
        } else {
          return this.denyAccess();
        }
      }),
      map((res) => this.matchPackage(packageName, res)),
      catchError(() => of(false))
    );
  }

  matchPackage(packageName: string, features: DanceManagerFeatureInterface[]): boolean {
    return !!features.find((feature) => feature.name.includes(packageName.toUpperCase()));
  }

  denyAccess(): boolean {
    this.snackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PACKAGE_NOT_AVAILABLE'));
    this.router.navigate(['/']);
    return false;
  }
}
