import {
  AppointmentParticipantInterface,
  AppointmentParticipantStatusEnum
} from '../../..';
import { CourseAppointmentDto } from './course-appointment.dto';

export class AppointmentParticipantDto implements AppointmentParticipantInterface {
  id: string;
  appointmentId: string;
  userId: string;
  status: AppointmentParticipantStatusEnum;
  appointment?: CourseAppointmentDto;
}
