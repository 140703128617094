import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfQuillEditorModule, DfSharedButtonModule, DfTimePickerModule, DisableControlModule, GoogleAddressSearchAutocompleteModule, NoScrollDirective, PipesModule } from '@platri/dfx-angular-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CourseAppointmentsFormComponent } from './course-appointments-form.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CourseAppointmentsTableModule } from '../course-appointments-table/course-appointments-table.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CourseAppointmentsFormComponent],
  exports: [CourseAppointmentsFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        MatIconModule,
        MatButtonModule,
        RouterModule,
        MatCardModule,
        MatDividerModule,
        DfSharedButtonModule,
        MatFormFieldModule,
        MatInputModule,
        DfQuillEditorModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatFormFieldModule,
        GoogleAddressSearchAutocompleteModule,
        MatRadioModule,
        MatDatepickerModule,
        MatMomentDateModule,
        DfTimePickerModule,
        CourseAppointmentsTableModule,
        NoScrollDirective,
        DisableControlModule,
        TranslocoDirective
    ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class CourseAppointmentsFormModule {}
