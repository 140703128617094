import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { DfmEventPageModule } from './pages';
import { FrontendDfmEventAppRoutingModule } from './frontend-dfm-event-app-routing.module';
import { TranslocoStateConst } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfmEventAppRoutingModule,
    DfmEventPageModule,
    MatSnackBarModule,
    TranslocoModule
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendDfmEventAppModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'dfm-event', alias: 'DANCE_MANAGER_EVENT' });
  }
}
