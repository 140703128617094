import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { skipWhile, Subscription } from 'rxjs';
import { addMinutes } from 'date-fns';
import { OrderItemsCollapsed, OrderStatusEnum } from '@platri/df-common-shared-graphql';
import { DanceEventsInterface, DEFAULT_EVENT_PICTURE_PLACEHOLDER, TicketInterface, UserAppLangEnum } from '@platri/df-common-core';
import { DanceEventHttpService, TicketHttpService } from '../../services';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-event-ticket-shopping-card',
  templateUrl: './event-ticket-shopping-card.component.html',
  styleUrls: ['./event-ticket-shopping-card.component.scss']
})
export class EventTicketShoppingCardComponent implements OnInit, OnDestroy {
  @Input() orderItemsCollapsed: OrderItemsCollapsed;
  @Input() editable = true;
  @Input() deletable = true;

  @Output() editClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteClicked: EventEmitter<string> = new EventEmitter<string>();

  ticket: TicketInterface;
  danceEvent: DanceEventsInterface;
  endDate: Date;
  atLeastOneTicketInvalid = false;
  showTicketDetails = false;
  appLang: UserAppLangEnum;

  orderStatusEnum = OrderStatusEnum;

  subscription: Subscription = new Subscription();
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;

  constructor(private readonly ticketHttpService: TicketHttpService, private readonly translocoService: TranslocoService, private readonly danceEventHttpService: DanceEventHttpService) {}

  ngOnInit(): void {
    this.checkIfAtLeastOneTicketIsInvalid();
    this.getTicketById();
    this.appLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkIfAtLeastOneTicketIsInvalid(): void {
    this.orderItemsCollapsed.elements.forEach((orderItem) => {
      if (orderItem.status === OrderStatusEnum.INVALID) {
        this.atLeastOneTicketInvalid = true;
      }
    });
  }

  getTicketById(): void {
    this.subscription.add(
      this.ticketHttpService
        .getById(this.orderItemsCollapsed.productSpecificId)
        .pipe(skipWhile((val) => val === null))
        .subscribe((result) => {
          this.ticket = result;
          this.getDanceEventById();
        })
    );
  }

  getDanceEventById(): void {
    this.subscription.add(
      this.danceEventHttpService
        .getById(this.ticket.danceEventId)
        .pipe(skipWhile((val) => val === null))
        .subscribe((result) => {
          this.danceEvent = result;
          this.calculateEndDateOfDanceEvent();
        })
    );
  }

  calculateEndDateOfDanceEvent(): void {
    if (this.danceEvent.appointments[0].duration > 0) {
      this.endDate = addMinutes(new Date(this.danceEvent.appointments[0].startDate), this.danceEvent.appointments[0].duration);
    }
  }

  onEditClick(): void {
    // this.editClicked.emit(this.orderItem);
  }

  onDeleteClick(): void {
    this.deleteClicked.emit(this.orderItemsCollapsed.productSpecificId);
  }
}
