import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DanceSchoolDto, DanceSchoolInterface, environmentForWeb } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DanceSchoolHttpService {
  // @ts-ignore
  private readonly SERVICE_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-schools`;

  constructor(private readonly http: HttpClient) {}

  createDanceSchool(danceSchool: DanceSchoolDto): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(`${this.SERVICE_URL}`, danceSchool);
  }

  createDanceManager(formData: FormData): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(
      `${environmentForWeb.apiDanceSchoolUrl}/dance-managers`,
      formData
    );
  }

  deleteDanceManager(danceManagerId: string): Observable<void> {
    return this.http.delete<void>(
      `${environmentForWeb.apiDanceSchoolUrl}/dance-managers/${danceManagerId}`
    );
  }

  uploadDanceManagerLogoByDanceSchoolId(
    id: string,
    logo: FormData
  ): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(
      `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-images/dance-manager/${id}/logo-image`,
      logo
    );
  }

  deleteDanceManagerLogoByDanceSchoolId(
    id: string
  ): Observable<DanceSchoolDto> {
    return this.http.delete<DanceSchoolDto>(
      `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-images/dance-manager/${id}/logo-image`
    );
  }

  uploadDanceManagerTitleByDanceSchoolId(
    id: string,
    title: FormData
  ): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(
      `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-images/dance-manager/${id}/title-image`,
      title
    );
  }

  deleteDanceManagerTitleByDanceSchoolId(
    id: string
  ): Observable<DanceSchoolDto> {
    return this.http.delete<DanceSchoolDto>(
      `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-images/dance-manager/${id}/title-image`
    );
  }

  //TODO: Change Interface to DTO
  updateDanceSchoolById(
    danceSchoolId: string,
    danceSchool: DanceSchoolInterface
  ): Observable<DanceSchoolInterface> {
    return this.http.patch<DanceSchoolInterface>(
      `${this.SERVICE_URL}/${danceSchoolId}`,
      danceSchool
    );
  }

  getDanceSchoolById(id: string): Observable<DanceSchoolDto> {
    return this.http.get<DanceSchoolDto>(`${this.SERVICE_URL}/${id}`);
  }

  getAllDanceSchoolsByCurrentUser(): Observable<DanceSchoolDto[]> {
    return this.http.get<DanceSchoolDto[]>(`${this.SERVICE_URL}/users`);
  }

  getAllDanceSchools(): Observable<DanceSchoolDto[]> {
    return this.http.get<DanceSchoolDto[]>(`${this.SERVICE_URL}`);
  }

  uploadLogoByDanceSchoolId(
    id: string,
    logo: FormData
  ): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(
      `${this.SERVICE_URL}/${id}/images/main`,
      logo
    );
  }

  uploadTitleByDanceSchoolId(
    id: string,
    title: FormData
  ): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(
      `${this.SERVICE_URL}/${id}/images/title`,
      title
    );
  }

  uploadGalleryImagesByDanceSchoolId(
    id: string,
    images: FormData
  ): Observable<DanceSchoolDto> {
    return this.http.post<DanceSchoolDto>(
      `${this.SERVICE_URL}/${id}/images/gallery`,
      images
    );
  }

  deleteGalleryImage(
    id: string,
    fileName: string
  ): Observable<DanceSchoolDto> {
    return this.http.delete<DanceSchoolDto>(
      `${this.SERVICE_URL}/${id}/images/${fileName}`
    );
  }
}
