<ng-container *transloco="let t">
  <div *ngIf="!confirmTransfer" class="flex-column gap-10 p2" style="width: 410px">
    <span class="title">{{t('GENERIC_WRAPPER.TRANSFER_' + transferType + '.TITLE')}}</span>
    <div class="description mb-10">
      {{t('GENERIC_WRAPPER.TRANSFER_' + transferType + '.TRANSFER_' + transferType + '_TO')}}
    </div>
    
    <div>
      <df-shared-lib-user-search-autocomplete
        (optionSelected)="onUserSelected($event)"
        (inputChange)="onInputChange($event)"
        [label]="'GENERIC_WRAPPER.USERNAME_OR_EMAIL'"
        [showUserSearchLoading]="showUserSearchLoadingSpinner"
        [userOptions]="userOptions">
      </df-shared-lib-user-search-autocomplete>
    </div>
    
    <div *ngIf="selectedUser">
      <div style="margin-bottom: 20px">
        <span  class="send-to-text mb-10">{{t('GENERIC_WRAPPER.TRANSFER_' + transferType + '.TRANSFER_' + transferType + '_SEND_INFO')}}</span>
      </div>
      <div class="flex-row align-start-center first-name-cell">
        <img class="circle-icon mr1" [src]="selectedUser.imageUrl && selectedUser.imageUrl !== '' ? selectedUser.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
        <span class="username-text">{{ selectedUsername }}</span>
        <div style="flex-grow: 1"></div>
        <button (click)="onRemovingSelectedUser()" mat-icon-button style="margin-left: 10px">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    
    <div class="flex-row align-end-center gap-20 mt-10 flex w-100">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        (buttonClicked)="onCancelClick()"
        [isDisabled]="false"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
      <df-shared-lib-button
        (buttonClicked)="onSendClick()"
        [isDisabled]="!selectedUser"
        [buttonLabel]="t('GENERIC_WRAPPER.TRANSFER_CONFIRM')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
    </div>
  </div>
  
  <div *ngIf="confirmTransfer" class="flex-column p2 gap-10" style="width: 300px !important">
    <span class="title">{{t('GENERIC_WRAPPER.TRANSFER_' + transferType + '.TITLE')}}</span>
      <span class="description">{{t('GENERIC_WRAPPER.TRANSFER_' + transferType + '.TRANSFER_' + transferType + '_CONFIRMATION_NOTE')}}</span>
    
    <div class="flex-row align-end-center gap-20 mt-10 flex w-100">
      <df-shared-lib-button
        (buttonClicked)="onDeclineClick()"
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.NO')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
      <df-shared-lib-button
        (buttonClicked)="onAcceptClick()"
        [isDisabled]="!selectedUser"
        [buttonLabel]="t('GENERIC_WRAPPER.YES')"
        style="flex-grow: 1;"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
