<ng-container *transloco="let t">
  <div class="event-tickets-checkout-container flex-column align-start-center w-100 h-100">
    <div *ngIf="!loading; else loadingSpinner" class="content-card py2 w-100">
      <!-- Back Button -->
      <div class="w-100 back-btn-container">
          <div class="flex-column align-start hide-on-mobile" (click)="navigateToEventPage()">
            <a class="flex-row align-start back-btn">
              <mat-icon>arrow_back</mat-icon>
              <span class="pl1 m-auto bold">Tickets</span>
            </a>
          </div>
        </div>
      <!--Card Header -->
      <div class="flex-row align-start pt2">
        <img class="dance-event-image hide-on-mobile" [src]="course?.mainImage?.url ??'assets/images/studio_card_image_web.png'" alt=""/>
        <div class="container-a flex-column w-100">
          <div class="flex-row align-space-between w-100 px2">
            <span style="font-size: 20px">
              <strong class="mobile-title-text">{{ course.name }}</strong>
            </span>
            <span *ngIf="!showMobileSummary" class="flex-column align-end-start back-btn pb1 hide-on-web" (click)="navigateToEventPage()">
              <mat-icon>close</mat-icon>
            </span>
          </div>
          <span class="px2" style="font-size: 20px">
            {{ course.danceManager?.name }}
          </span>
          <span
            *ngIf="course.appointments[0] as appointment"
            class="pt2 px2"
            style="font-size: 16px; color: #6f6f6f"
          >
            {{ appointment.date | dateTimezoned: 'UTC': currentLang :appointment.duration }}
          </span>
        </div>
      </div>
      <mat-divider class="mt2 hide-on-web"></mat-divider>
      <!-- Card Content -->
      <div class="card-container flex-column mt3 w-100" style="gap: 1rem;">
        <!-- Payment -->
        <div class="payment-container flex-column" style="gap: 1rem;">
          <df-monorepo-ticket-checkout-payment *ngIf="ticketOrder" [ticketOrder]="ticketOrder"></df-monorepo-ticket-checkout-payment>
        </div>
  
        <!-- Checkout Detail -->
        <div class="checkout-detail-container">
          <!-- Checkout Summery -->
          <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'">
            <df-monorepo-dance-event-ticket-order-summary
              *ngIf="ticketOrder"
              [ticketOrder]="ticketOrder"
              (closeSummary)="showMobileSummary = false"
            ></df-monorepo-dance-event-ticket-order-summary>
          </div>
          <div class="hide-on-mobile px1 pt2">
            {{ t('GENERIC_WRAPPER.NEED_HELP') }}
            <a style="text-decoration: none; color: #3377FF" target="_blank" contactUrl>
              {{ t('GENERIC_WRAPPER.CONTACT_US') }}</a
            >
          </div>
        </div>
  
        <!-- Space For Sticky Button on Mobile-->
        <div class="py3 hide-on-web"></div>
      </div>
    </div>
  </div>
  
  <ng-template #loadingSpinner>
    <mat-spinner [diameter]="35"></mat-spinner>
  </ng-template>
</ng-container>
