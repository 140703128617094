<div class="flex-column align-center">
  <div class="align-center circle mt3">
    <img src="{{imagePath}}" alt="no_search icon"/>
  </div>
  <div class="w-100 h-100 flex-column align-center text-container" style="max-width: 70%;">
    <span class="title-text pt2 pb1">{{emptyTitle}}</span>
    <span class="description-text">{{emptyDescription}}</span>
  </div>
  <div class="pt2" [class.hide]="hideBtn">
    <df-shared-lib-button
      [buttonLabel]="buttonLabel"
      (buttonClicked)="buttonClicked.emit()"
      id="{{buttonId}}"
    ></df-shared-lib-button>
  </div>
</div>
