import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { getSubscription } from '@platri/elab-angular-core';
import { ChatWebsocketService } from '../../services';
import { UserDto, UserSearchDto } from '@platri/elab-common-auth-plug-play';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ChatInterface } from '../../interfaces';
import { ChatsOverviewStateService } from '../../services/chats-overview-state.service';
import { filter, map, Observable, Subject, Subscription, tap } from 'rxjs';
import { getTimeHelper } from '../../helpers';
import { ChatAppChatRouterParam, ChatAppChatsTechnicalUserRouterQueryParam } from '../../constants';
import { MessageStatusEnum, MessageTypeEnum } from '@platri/df-common-chat-plug-play';
import { AuthStateService, MessageHttpService, UserHttpService } from '@platri/dfx-angular-core';
import { AuthTechnicalUserStateService } from '../../services/auth-technical-user-state.service';
import { MessagePartnerInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  providers: [ChatsOverviewStateService, ChatWebsocketService, AuthTechnicalUserStateService]
})
export class ChatsComponent implements OnInit, OnDestroy {
  currentUser: UserDto | null = null;
  allChats: ChatInterface[] = [];
  shownChats: ChatInterface[] = [];
  hasNextPage = false;
  hasPreviousPage = false;
  chatsPerPage = 50;
  showChat = true;
  
  subscriptions = new Subscription();

  routerParams!: Params;
  recipientIdFromParam: string;
  
  queryParams!: Params;
  technicalUserIdFromQueryParam: string;
  
  foundUsers: UserSearchDto[];

  isMobile = false;
  
  currentPage = 1;

  searchUserInputChange = new Subject<string>();
  searchUserName: string;
  searchUserLoading: boolean;
  searchUsersFound: any[];
  
  queryParamsLoaded = false;
  
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private authStateService: AuthStateService, 
    private authTechnicalUserStateService: AuthTechnicalUserStateService,
    private chatsOverviewStateService: ChatsOverviewStateService,
    private userHttpService: UserHttpService,
    private messageHttpService: MessageHttpService
  ) {}
  
  ngOnInit(): void {
    console.log("Initializing Chats");
    this.checkResponsiveView();
    this.getRecipientIdAndTechnicalUserIdFromRouterUrl();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    console.log("Destroying Chats");
    if (this.technicalUserIdFromQueryParam) {
      this.authTechnicalUserStateService.logout();
    }
  }
  
  @HostListener('window:resize', ['$event'])
  checkResponsiveView(): void {
    this.isMobile = window.matchMedia('(max-width: 700px)').matches;
  }
  
  checkRouterUrl(): boolean {
    const urlPattern = /^\/dance-manager\/[a-f0-9-]+\/messages\?technicalUserId=[a-f0-9-]+$/;
    return urlPattern.test(this.router.url);
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.authStateService.getAsyncCurrentUser(), this.onCurrentUserDataChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.authTechnicalUserStateService.getAsyncCurrentUser(), this.onCurrentTechnicalUserDataChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.filterRouteObservableForChatRouterParam(this.router.events), this.onRecipientIdChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.filterRouteObservableForChatRouterQueryParam(this.router.events), this.onTechnicalUserIdChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.searchUserInputChange, this.onSearchUserInputChange.bind(this)));
  }

  onRecipientIdChanges(recipientId: string): void {
    this.recipientIdFromParam = recipientId;
  } 

  onTechnicalUserIdChanges(technicalUserId: string): void {
    this.technicalUserIdFromQueryParam = technicalUserId;
  }

  onCurrentUserDataChanges(currentUser: UserDto | null): void {
    if (!currentUser) {
      this.userHttpService.getCurrentUser().subscribe(res => {
        this.authStateService.sendCurrentUserChanges(res);
      });
    } else if (!this.currentUser) {
      if (this.technicalUserIdFromQueryParam) {
        this.chatsOverviewStateService.isTechnicalUserState = true;
      } else {
        this.currentUser = currentUser;
        this.chatsOverviewStateService.isTechnicalUserState = false;
        this.subscriptions.add(getSubscription(this.chatsOverviewStateService.getAsyncAvailableData(), this.onAvailableDataChanges.bind(this)));
        this.chatsOverviewStateService.loadAvailableData();
      }
    }
  }

  onCurrentTechnicalUserDataChanges(currentUser: UserDto | null): void {
    if (!this.technicalUserIdFromQueryParam) return;
    if (!currentUser) {
      this.authTechnicalUserStateService.login(this.technicalUserIdFromQueryParam).subscribe(res => {
        if (res) {
          this.authTechnicalUserStateService.sendCurrentUser({id: this.technicalUserIdFromQueryParam, email: '', emailVerified: true});
        }
      });
    } else if(!this.currentUser) {
      this.currentUser = currentUser;
      this.subscriptions.add(getSubscription(this.chatsOverviewStateService.getAsyncAvailableData(), this.onAvailableDataChanges.bind(this)));
      this.chatsOverviewStateService.loadAvailableData();
    }
  }

  onAvailableDataChanges(chatData: ChatInterface[]): void {
    this.allChats = chatData;
    this.filterShownChats();
  }
  
  nextPage(): void {
    if (this.hasNextPage) {
      this.currentPage++;
      this.filterShownChats();
    }
  }
  
  previousPage(): void {
    if (this.hasPreviousPage) {
      this.currentPage--;
      this.filterShownChats();
    }
  }
  
  filterShownChats(): void {
    const startIndex = (this.currentPage - 1) * this.chatsPerPage;
    const endIndex = startIndex + this.chatsPerPage;
    this.shownChats = this.allChats.slice(startIndex, endIndex);
    this.hasPreviousPage = this.currentPage > 1;
    this.hasNextPage = this.currentPage < this.allChats.length / this.chatsPerPage;
  }

  openChat(userId: string): void {
    if (this.recipientIdFromParam !== userId) {
      this.showChat = false;
      setTimeout(() => this.showChat = true, 1);
    }
  }
  
  filterRouteObservableForChatRouterParam(routerObservable: Observable<any>): Observable<any> {
    return routerObservable.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute.firstChild),
      map(route => {
        while (route?.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route !== null),
      map(route => route?.snapshot.params[ChatAppChatRouterParam])
    );
  }

  filterRouteObservableForChatRouterQueryParam(routerObservable: Observable<any>): Observable<any> {
    return routerObservable.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute.firstChild),
      map(route => {
        while (route?.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route !== null),
      map(route => route?.snapshot.queryParams[ChatAppChatsTechnicalUserRouterQueryParam])
    );
  }

  getRecipientIdAndTechnicalUserIdFromRouterUrl(): void {
    setTimeout(() => {
      let route = this.activatedRoute.root;
      const snapshot = route.snapshot;
      while (route.firstChild) {
        route = route.firstChild;
      }

      if (snapshot.params[ChatAppChatRouterParam]) {
        this.recipientIdFromParam = snapshot.params[ChatAppChatRouterParam];
      }

      if (snapshot.queryParams[ChatAppChatsTechnicalUserRouterQueryParam]) {
        this.technicalUserIdFromQueryParam = snapshot.queryParams[ChatAppChatsTechnicalUserRouterQueryParam];
      }
      
      this.queryParamsLoaded = true;
      this.initializeSubscriptions();
    }, 1);
   
  }

  setSelectedUser(event1: any): void {
    const id = event1.id.replace('user-', '').replace('dance-school-', '');
    this.router.navigate([`${id}`], {queryParamsHandling: 'preserve', relativeTo: this.activatedRoute});
    if (this.recipientIdFromParam !== this.currentUser?.id) {
      this.showChat = false;
      setTimeout(() => {
        this.showChat = true;
        this.onSearchUserInputChange('');
      }, 1);
    }
  }

  onSearchUserInputChange(event1: string): void {
    if (event1) {
      this.messageHttpService
        .searchPartners(event1)
        .pipe(
          tap(() => {
            this.searchUserLoading = false;
          }),
        )
        .subscribe((result) => {
          this.searchUsersFound = this.mapSearchUserResults(result.filter((obj) => obj.id !== this.currentUser?.id));
        });
    } else {
      this.searchUsersFound = [];
      this.searchUserLoading = false;
    }
  }

  private mapSearchUserResults(partners: MessagePartnerInterface[]) {
    return partners.map(
      (partner) =>
        ({
          id: partner.id,
          value: partner.username,
          displayValue: partner.username,
          info: partner.id.includes('dance-school-') ? 'Tanzschule' : (partner.info || ''),
          imageUrl: partner.imageUrl ?? null,
          isDanceSchool: partner.id.includes('dance-school-')
        } as any)
    );
  }
  
  
  
  protected readonly getTimeHelper = getTimeHelper;
  protected readonly MessageStatusEnum = MessageStatusEnum;
  protected readonly MessageTypeEnum = MessageTypeEnum;
}

