import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'df-shared-lib-fab-button',
  templateUrl: './df-fab-button.component.html',
  styleUrls: ['./df-fab-button.component.scss'],
})
export class DfFabButtonComponent {
  @Input() isDisabled = false;
  @Input() size = '44px';
  @Input() matIconName = 'add';
  @Input() backgroundColor = '#00a1df';
  @Input() color = 'white';

  @Output() buttonClicked = new EventEmitter<void>();
}
