import{ Component, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AuthStateService, ConfirmationDialogComponent, DanceManagerHttpService, DanceSchoolStateService, DfmStateService, InfoDialogComponent, noWhitespaceValidator, PhoneInputComponent, PhonePrefixInterface } from '@platri/dfx-angular-core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Address, DanceManagerCategoryEnum } from '@platri/df-common-shared-graphql';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subscription } from 'rxjs';
import { CreateDanceManagerDto, DANCE_MANAGER_CATEGORY_REQUEST_FORM_DE, DANCE_MANAGER_CATEGORY_REQUEST_FORM_EN, DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE, DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN, DanceSchoolDto, DanceStyleInterface, emailRegex, LogoAndTitleUploadDialogTypeEnum, UsersInterface } from '@platri/df-common-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatChipOption } from '@angular/material/chips';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-create-dance-manager',
  templateUrl: './create-dance-manager-app.component.html',
  styleUrls: ['./create-dance-manager-app.component.scss'],
  animations: [
    trigger('switchAnimation', [
      transition('* => *', [
        style({ opacity: '0', transform: 'translateX(10%)' }),
        animate(
          '0.25s ease',
          style({ opacity: '1', transform: 'translateX(0%)' })
        ),
      ]),
    ]),
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: '0', transform: 'translateX(20%)' }),
        animate(
          '0.25s ease',
          style({ opacity: '1', transform: 'translateX(0%)' })
        ),
      ]),
      transition(':leave', [
        style({ opacity: '1', transform: 'translateX(0%)' }),
        animate(
          '0.25s ease',
          style({ opacity: '0', transform: 'translateX(-20%)' })
        ),
      ]),
    ]),
  ],
})
export class CreateDanceManagerAppComponent implements OnInit {
  @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;

  formGroup: UntypedFormGroup;
  imageFormGroup: UntypedFormGroup;
  phoneNumberValid = true;
  dmCategories = DanceManagerCategoryEnum;
  loading = false;

  logoTemp: string | undefined;
  titleTemp: string | undefined;

  steps = [
    {
      number: 1,
      optional: false,
      controls: ['name', 'description'],
    },
    {
      number: 2,
      optional: false,
      controls: ['danceManagerCategory'],
    },
    {
      number: 3,
      optional: true,
      controls: ['danceStyles'],
    },
    {
      number: 4,
      optional: false,
      controls: ['businessAddress', 'locations'],
    },
    {
      number: 5,
      optional: true,
      controls: ['logo', 'title_image'],
    },
    {
      number: 6,
      optional: false,
      controls: ['phonePrefix', 'phonePrefixAfter', 'phoneNumber', 'email'],
    },
  ];

  currentStepValid = false;
  currentStep = 1;
  maxSteps: number = this.steps.length;
  progress = new BehaviorSubject((this.currentStep / this.maxSteps) * 100);
  currentUser: UsersInterface;

  protected readonly LogoAndTitleUploadDialogTypeEnum = LogoAndTitleUploadDialogTypeEnum;
  showAddLocationButton = false;

  errorWhileUploadingPictures = false;
  uploadedLogoImageUrl: string;
  uploadedTitleImageUrl: string;
  
  subscriptions: Subscription =  new Subscription();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
    private readonly router: Router,
    private readonly authService: AuthStateService,
    private readonly dialog: MatDialog,
    private dfmStateService: DfmStateService,
    private danceManagerHttpService: DanceManagerHttpService
  ) {
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(32)]],
      description: ['', [Validators.required, this.quillCharacterCountValidator(2000)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.pattern(emailRegex), noWhitespaceValidator()],
      ],
      businessAddress: ['', Validators.required],
      locations: [[]],
      phonePrefix: ['+49', Validators.required],
      phonePrefixAfter: [''],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9 ]*$')]],
      danceManagerCategory: [<string[]>[], Validators.required],
      danceStyles:[[]]
    });
    this.formGroup.valueChanges.subscribe((change: any) => {
      this.checkCurrentStepValidity();
    });

    this.imageFormGroup = this.formBuilder.group({
      logo: [null, [Validators.required]],
      titleImage: [null, [Validators.required]],
    });
  }

  quillCharacterCountValidator(maxCharacters: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const strippedValue = control.value
        .replace(/<br\s*\/?>/g, ' ')
        .replace(/<\/?p>/g, '')
        .replace(/&nbsp;/g, ' ');
      const actualCharacterCount = strippedValue.length;
      if (actualCharacterCount > maxCharacters) {
        return { characterCountExceeded: true };
      }
      return null;
    };
  }

  get nameControl(): AbstractControl | null {
    return this.formGroup.get('name');
  }

  get descriptionControl(): AbstractControl | null {
    return this.formGroup.get('description');
  }

  get emailControl(): AbstractControl | null {
    return this.formGroup.get('email');
  }

  get danceManagerCategoryControl(): AbstractControl | null {
    return this.formGroup.get('danceManagerCategory');
  }

  get danceStylesControl(): AbstractControl | null {
    return this.formGroup.get('danceStyles');
  }

  selectDanceStyle(danceStyle: DanceStyleInterface): void {
    this.formGroup.patchValue({
      danceStyles: this.danceStylesControl?.value ? this.danceStylesControl.value.concat([danceStyle]) : [danceStyle]
    });
  }

  removeSelectedDanceStyle(danceStyle: DanceStyleInterface): void {
    let selectedDanceStyles = this.danceStylesControl?.value;
    const index = selectedDanceStyles.indexOf(danceStyle);
    if (index >= 0) {
      selectedDanceStyles.splice(index, 1);
      selectedDanceStyles =
        selectedDanceStyles.length === 0 ? null : [...selectedDanceStyles];
    }
    this.formGroup.patchValue({
      danceStyles: selectedDanceStyles,
    });
  }

  toggleSelection(category: string, matChipOption: MatChipOption): void {
    const selectedCategories: string[] = this.danceManagerCategoryControl!.value;
    if (matChipOption.selected) {
      selectedCategories.push(category);
      this.danceManagerCategoryControl!.setValue(selectedCategories);
    } else {
      selectedCategories.splice(selectedCategories.indexOf(category), 1);
      this.danceManagerCategoryControl!.setValue(selectedCategories);
    }
  }

  matchSelection(category: string): boolean {
    return this.danceManagerCategoryControl!.value.includes(category);
  }

  submit(): void {
    if (this.formGroup.valid && this.phoneNumberValid) {
      this.loading = true;

      const observables: Observable<any>[] = [];

      const logoImage = this.imageFormGroup.controls.logo.value;
      const titleImage = this.imageFormGroup.controls.titleImage.value;

      if (logoImage) {  
        const formData = new FormData();
        formData.append('file', logoImage.get('file'));
        observables.push(this.danceManagerHttpService.uploadFile(formData).pipe(catchError(() => of(null))));
      }
      if (!logoImage) observables.push(of(null));

      if (titleImage) {
        const formData = new FormData();
        formData.append('file', titleImage.get('file'));
        observables.push(this.danceManagerHttpService.uploadFile(formData).pipe(catchError(() => of(null))));
      }
      if (!titleImage) observables.push(of(null));
      
      forkJoin(observables).subscribe({
        next: ([result1, result2]) => {
          this.uploadedLogoImageUrl = result1;
          this.uploadedTitleImageUrl = result2; 
        },
        error: () => {
          this.errorWhileUploadingPictures = true;
          console.log('Upload images failed.');
        },
        complete: () => {
          const danceStyles = this.formGroup.getRawValue().danceStyles;
          const danceStyleIds = danceStyles?.map((danceStyle: DanceStyleInterface) => danceStyle.id);
          
          const request: CreateDanceManagerDto = new CreateDanceManagerDto({...this.formGroup.getRawValue(), danceStyleIds: danceStyleIds, imageUrl: this.uploadedLogoImageUrl ?? null, titleImage: this.uploadedTitleImageUrl ? {url: this.uploadedTitleImageUrl} : null});
          this.danceManagerHttpService.postDanceManager(request).subscribe({
            next: (danceManager) => {
              setTimeout(
                () => {
                  this.loading = false;
                  this.openSuccessDialog();
                  this.authService.refreshTokens().subscribe({
                    next: () => {
                      this.dfmStateService.loadAvailableData();
                      this.router.navigate(['/dance-manager/' + danceManager.id]);
                    }
                  });
                },
                1000
              );
            }
          });
        }
      });
    } else {
      this.phoneInputComponent.formGroup.markAllAsTouched();
      this.phoneInputComponent.formGroup.updateValueAndValidity();
      this.formGroup.markAllAsTouched();
      this.formGroup.updateValueAndValidity();
    }
  }

  phonePrefixSelected(phonePrefix: PhonePrefixInterface): void {
    this.formGroup.patchValue({
      phonePrefix: phonePrefix.dial_code,
      phonePrefixAfter: phonePrefix.after_dial_code ?? null,
    });
  }

  setSelectedBusinessAddress(address?: Address): void {
    if (address) {
      this.showAddLocationButton = true;
      this.formGroup.controls.businessAddress.setValue(address);
    } else {
      this.showAddLocationButton = false;
      this.formGroup.controls.businessAddress.setValue(null);
    }
  }

  onImageUpload(event: {
    formData: FormData;
    imageAsBase64: string;
  }, controlName: string): void {
    this.imageFormGroup.get(controlName)?.setValue(event.formData);
    switch (controlName) {
      case 'logo':
        this.logoTemp = event.imageAsBase64;
        break;
      case 'titleImage':
        this.titleTemp = event.imageAsBase64;
        break;
      default:
        break;
    }
    this.checkCurrentStepValidity();
  }

  onImageDelete(controlName: string): void {
    this.imageFormGroup.get(controlName)?.setValue(null);
    switch (controlName) {
      case 'logo':
        this.logoTemp = undefined;
        break;
      case 'titleImage':
        this.titleTemp = undefined;
        break;
      default:
        break;
    }
    this.checkCurrentStepValidity();
  }

  updateProgress(): void {
    this.progress.next((this.currentStep / this.maxSteps) * 100);
  }

  checkCurrentStepValidity(): void {
    this.currentStepValid =
      this.steps[this.currentStep - 1].controls.every(
        (controlName) => this.formGroup.get(controlName)?.valid
      ) ||
      this.steps[this.currentStep - 1].controls.some(
        (controlName) => this.imageFormGroup.get(controlName)?.valid
      ) ||
      this.steps[this.currentStep - 1].optional;
  }

  nextStep(): void {
    if (
      !(this.currentStep >= this.maxSteps) &&
      (this.currentStepValid || this.steps[this.currentStep - 1].optional)
    ) {
      this.currentStep = this.currentStep + 1;
      this.updateProgress();
      this.checkCurrentStepValidity();
    }
    if (this.currentStep === this.maxSteps && this.currentStepValid) {
      this.submit();
    }
  }

  previousStep(): void {
    this.currentStep--;
    this.updateProgress();
    this.checkCurrentStepValidity();
  }

  cancelCreateDanceManager(): void {
    const cancelDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '300px',
      data: {
        title: this.translocoService.translate('CREATE_DANCE_MANAGER.CANCEL_DIALOG.TITLE'),
        text: this.translocoService.translate('CREATE_DANCE_MANAGER.CANCEL_DIALOG.SUBTITLE'),
        cancelButtonText: this.translocoService.translate('CREATE_DANCE_MANAGER.CANCEL_DIALOG.STAY'),
        submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.OK'),
      }
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (reason) {
        this.router.navigate(['']);
      }
    });
  }

  openDanceManagerCategoryRequestForm(): void {
    const url =
      this.translocoService.getActiveLang().toUpperCase() === 'DE'
      ? DANCE_MANAGER_CATEGORY_REQUEST_FORM_DE
      : DANCE_MANAGER_CATEGORY_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }

  updateFormEmailWithCurrentUserEmail(): void {
    this.formGroup.patchValue({email: this.currentUser.email});
  }

  openDanceManagerDanceStyleRequestForm(): void {
    const url =
      this.translocoService.getActiveLang().toUpperCase() === 'DE'
      ? DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE
      : DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }
  
  get locations() {
    return this.formGroup.get('locations');
  }
  
  addBusinessLocation(): void {
    // @ts-ignore
    const bAddress = this.formGroup.controls.businessAddress.value;

    const newLocation = {
      name: this.translocoService.translate('CREATE_DANCE_MANAGER.BUSINESS_ADDRESS'),
      address: bAddress,
      isPublic: false
    };

    this.formGroup.patchValue({
      locations: [...this.locations.value, newLocation]
    });
    this.showAddLocationButton = false;
  }

  openSuccessDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      width: '400px',
      data: {
        icon: "celebration",
        title: this.translocoService.translate('CREATE_DANCE_MANAGER.INFO_DIALOG.TITLE'),
        description: this.translocoService.translate('CREATE_DANCE_MANAGER.INFO_DIALOG.DESCRIPTION'),
        buttonText: this.translocoService.translate('GENERIC_WRAPPER.CONTINUE'),
      }
    });
  }
}
