import { Pipe, PipeTransform } from '@angular/core';
import { RadiusUnitEnum } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'roundDistancePipe',
})
export class RoundDistancePipe implements PipeTransform {
  transform(
    distance: number,
    unit: RadiusUnitEnum = RadiusUnitEnum.KM
  ): string {
    if (distance === null) {
      return null;
    }
    if (distance < 1) {
      return '< 1';
    } else {
      return Math.round(distance).toString();
    }
  }
}
