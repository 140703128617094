import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_EVENT_PICTURE_PLACEHOLDER, UsersInterface, UserTicketCollapsedByEventAndOrderInterface } from '@platri/df-common-core';
import { AuthStateService } from '@platri/dfx-angular-core';

@Component({
  selector: 'df-user-inventory-list-ticket-item',
  templateUrl: './inventory-ticket-list-item.component.html',
  styleUrls: ['./inventory-ticket-list-item.component.scss']
})
export class InventoryTicketListItemComponent implements OnInit{
  @Input() userTicketCollapsed: UserTicketCollapsedByEventAndOrderInterface;

  @Output() download = new EventEmitter<{
    danceEventId: string;
    ticketOrderId?: string;
    freeUserTicketIds?: string[];
  }>();

  currentUser: UsersInterface;

  constructor(private authService: AuthStateService) {
    
  }
  
  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  protected readonly DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;
}
