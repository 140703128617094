import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, Subscription } from 'rxjs';
import { AuthStateService, DanceSchoolStateService, TaggingService } from '../../services';
import { MessageHttpService, PartnerService } from '../messages';
import { ControlContainer, FormGroupDirective, UntypedFormControl } from '@angular/forms';
import Quill from 'quill';
import Counter from '../../helpers/quill-counter-module';
import { TranslocoService } from '@jsverse/transloco';
// import { Mention, MentionBlot } from 'quill-mention';

// Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });
Quill.register('modules/counter', Counter);

@Component({
  selector: 'df-shared-lib-quill-editor',
  templateUrl: './df-quill-editor.component.html',
  styleUrls: ['./df-quill-editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class DfQuillEditorComponent implements OnInit, OnDestroy {
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent | undefined;

  //quill stuff
  // @ViewChild(QuillEditorComponent, { static: false })
  // editor: QuillEditorComponent;
  
  @Input() customFormControlName: string;
  @Input() enableMentions = false;
  @Input() enableCounter = false;
  @Input() disableRichText = false;
  @Input() elementId: string;
  @Input() placeholder: string;
  @Input() maxLength = 9999;
  @Input() minLength = 0;
  @Input() hasError = false;
  @Input() isRequired = false;
  
  userOptions: any[] = [];
  searchInputChangeSubject = new Subject<string>();
  currentSearchTerm: string;
  currentPartnerId: string;

  // config example:
  //   <df-shared-lib-quill-editor
  //   [enableMentions]="boolean"
  //   //set to true to enable mentioning users with @username, default is false
  //   [enableCounter]="boolean"
  //   //set to enable a counter below the editor displaying current number of characters/maximum characters, default is false
  //   [id]="'string'"
  //   //pass a string with desired id for the nested editor element
  //   [placeholder]="'string'"
  //   //pass a string with desired placeholder for the nested editor element, translate pipe is applied in the component already
  //   [maxLength]="number"
  //   //set a max length for the editor, set a value when enabling the counter, the default max length is 9999 characters
  //   [minLength]="number"
  //   //set a min length for the editor, the default min length is 0 characters
  //   [customFormControlName]="'string'"
  //   //pass the formControl name that you use in the parent components formGroup
  // >
  // </df-shared-lib-quill-editor>
  //


  constructor(
    private readonly danceSchoolService: DanceSchoolStateService, 
    private readonly messageHttpService: MessageHttpService,
    private readonly authService: AuthStateService, 
    private readonly taggingService: TaggingService, 
    private readonly partnerService: PartnerService, 
    private readonly translocoService: TranslocoService) {}

  mentionConfig = {
    mentionListClass: 'mention-menu',
    listItemClass: 'mention-item',
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@'],
    showDenotationChar: true,
    spaceAfterInsert: true,
    onSelect: (item) => {
      const editor = this.editor.quillEditor;
      //delete entered search term:
      editor.deleteText(
        editor.getSelection().index - this.currentSearchTerm.length,
        this.currentSearchTerm.length,
        'user' // necessary because quill-mention triggers changes as 'api' instead of 'user'
      );
      //insert selected item value as plain text:
      editor.insertText(editor.getSelection().index, item.value + ' ', 'user');
      //set text cursor after inserted item:
      editor.setSelection(editor.getSelection().index + item.value.length, 'user');
    },
    source: (searchTerm, renderList) => {
      this.searchInputChangeSubject.next(searchTerm);
      if (searchTerm.length === 0) {
        renderList(this.userOptions, searchTerm);
      } else {
        const matches = [];

        this.userOptions.forEach((entry) => {
          if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
            matches.push(entry);
          }
        });
        const translatedTypes = {
          studio: this.translocoService.translate('GENERIC_WRAPPER.DANCE_MANAGER'),
          user: this.translocoService.translate('GENERIC_WRAPPER.USER')
        };
        renderList(matches, translatedTypes);
      }
    },
    renderItem: function renderItem(item, translatedTypes) {
      const icon = item.imageUrl ? '<img class="material-icons-round" style="border-radius:25px" src="' + item.imageUrl + '">' : '<span class="material-icons-round">account_circle</span>';

      const type = item.isDanceSchool ? translatedTypes['studio'] : translatedTypes['user'];
      const text = '<div style="display:flex; flex-direction:column;">' + '<span>' + item.value + '</span>' + '<span style="color:#959595; font-size:11px">' + type + '</span>' + '</div>';
      return icon + text;
    }
  };

  counterConfig = {
    container: '#counter',
    unit: 'char',
    limit: this.maxLength
  };

  removeColor = function (node, delta) {
    delta.forEach((e) => {
      if (e.attributes) {
        e.attributes.color = '';
        e.attributes.background = '';
      }
    });
    return delta;
  };

  /**
   * Prevents, that a user can drag and drop images into the editor
   */
  dragAndDropImageHandler = function (imageDataUrl, type, imageData) {};

  /**
   * Prevents, that a user can copy paste images into the editor
   */
  copyPasteImageHandler = function (node, delta) {
    // const deltaImport = Quill.import('delta');
    // return new deltaImport().insert('');
    
    return '';
  };

  formats = [];

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ align: [] }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      ['clean'] // remove formatting button
    ],
    // imageDropAndPaste: {
    //   // add an custom image handler
    //   handler: this.dragAndDropImageHandler
    // }
  };
  
  private setToolbarTabindex(): void {
    setTimeout(() => {
      const toolbar = document.querySelector('.ql-toolbar');
      if (toolbar) {
        const buttons = toolbar.querySelectorAll('button, span');
        buttons.forEach(button => {
          button.setAttribute('tabindex', '-1');
        });
      }
    }, 500);
  }
  // end quill stuff

  subscriptions: Subscription = new Subscription();
  editorFormControl = new UntypedFormControl();

  ngOnInit() {
    // if (this.enableMentions) {
    //   this.subscribeToSearch();
    //   this.setCurrentPartner();
    //   this.modules['mention'] = this.mentionConfig;
    // }
    if (this.enableCounter) { 
      this.counterConfig.limit = this.maxLength;
      this.modules['counter'] = this.counterConfig;
    }
    if (this.disableRichText) {
      this.modules['toolbar'] = [];
    }

    // const Block = Quill.import('blots/block');
    // Block.tagName = 'DIV';
    // Quill.register(Block, true);
  }
  
  ngAfterViewInit(): void {
    this.editor.onEditorCreated.subscribe(() => {
      this.setToolbarTabindex();
    });
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  //**mentions-stuff**
  setCurrentPartner(): void {
    this.currentPartnerId = this.partnerService.getCurrentPartnerId(this.authService.getSyncCurrentUser(), this.danceSchoolService.getSyncCurrentDanceSchool());
  }

  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject.subscribe((inputText: string) => {
        if (inputText) {
          this.currentSearchTerm = inputText;
          this.subscriptions.add(
            this.messageHttpService.searchPartners(inputText).subscribe({
              next: (result) => {
                this.userOptions = this.taggingService.mapToOptions(result.filter((obj) => obj.id !== this.currentPartnerId));
              }
            })
          );
        } else {
          this.userOptions = [];
        }
      })
    );
  }
  //**end mentions-stuff**
}
