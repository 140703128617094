import {Injectable} from "@angular/core";
import * as _ from "lodash";

export interface Files {
  base64: string;
  fileName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  allowedImageFileFormats = ['image/png', 'image/jpeg', 'image/jpg'];

  constructor() {
  }

  createFileArray(uploadEvent: DataTransfer | EventTarget): File[] {
    const files: File[] = [];
    for (let i = 0; i < (uploadEvent as HTMLInputElement).files.length; i++) {
      files.push(<File>(
        (uploadEvent as HTMLInputElement).files[i]
      ));
    }
    return files;
  }

  createFiles(uploadEvent: DataTransfer | EventTarget): Files[] {
    const files = [];
    for (
      let index = 0;
      index < (uploadEvent as HTMLInputElement).files.length;
      index++
    ) {
      const file = (uploadEvent as HTMLInputElement).files[index];
      if (this.allowedImageFileFormats.includes(file.type)) {
        const fileName = file.name;
        const reader = new FileReader();
        reader.onload = (event: any) => {
          files.push({
            base64: event.target.result,
            fileName,
          });
        };
        reader.readAsDataURL(file);
      }
    }
    return files;
  }

  createFormData(files: Files[], existingFormData?: FormData): FormData {
    let formData = new FormData();
    if (existingFormData) {
      formData = existingFormData;
    }
    const filesCopy = _.cloneDeep(files);
    for (const pair of ((formData as any).entries())) {
      const index = filesCopy.findIndex(file => file.fileName === pair[1].name);
      filesCopy.splice(index, 1);
    }
    filesCopy.forEach((fileInterface, index) => {
      formData.append(
        'file',
        this.base64ToFile(fileInterface.base64),
        fileInterface.fileName
      );
    });
    return formData;
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  fileToBase64(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(<string>reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
