import { RoomInterface } from '../../..';
import { DanceSchoolLocationDto } from '../../..';

export class UpdateRoomDto
  implements Partial<RoomInterface> {
  id: string;
  name: string;
  qm: number;
  splittedRoom: boolean;
  location: DanceSchoolLocationDto;
}
