import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { CoursePage } from './course.page';
import { CoursePageContentModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [CoursePage],
  imports: [
    CommonModule, 
    RouterOutlet, 
    RouterLink, 
    RouterLinkActive, 
    CoursePageContentModule, 
    MatProgressSpinnerModule, 
    MatButtonModule, 
    TranslocoModule],
  exports: [CoursePage],
  providers: []
})
export class CoursePageModule {
  constructor() {
    console.log('CoursePageModule initialized');
  }
}
