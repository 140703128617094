import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {
  ConfirmationDialogInterface,
  DfmCoursePassesTableInterface,
  PassInterface,
  PassStatusEnum
} from '@platri/df-common-core';
import {
  CoursesHttpService,
  DanceSchoolStateService,
  PassesStateService,
  PassHttpService
} from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { getSubscription } from '../../helpers';
import { DanceSchool } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-course-passes-table',
  templateUrl: './course-passes-table.component.html',
  styleUrls: ['./course-passes-table.component.scss'],
})
export class CoursePassesTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) table: MatTable<DfmCoursePassesTableInterface>;
  
  @Output() passDeleted = new EventEmitter<void>();
  @Output() editPassClicked = new EventEmitter<string>();
  @Output() duplicatePassClicked = new EventEmitter<string>();
  
  passes: DfmCoursePassesTableInterface[] = [];
  
  displayedColumns = ['position', 'name', 'accessTargetNames', 'stamps', 'validity', 'sold', 'price', 'options'];
  dataSource: MatTableDataSource<DfmCoursePassesTableInterface>;
  dragDisabled = true;
  expandedElement: PassInterface | null;
  expendedCoursesElement: PassInterface | null;
  
  activePassChipIndex = 0;
  selectedPassStatus: PassStatusEnum = PassStatusEnum.ACTIVE;
  
  subscriptions = new Subscription();
  
  constructor(
    private passHttpService: PassHttpService, 
    private passesService: PassesStateService, 
    private danceSchoolService: DanceSchoolStateService, 
    private matSnackBar: MatSnackBar,
    private coursesHttpService: CoursesHttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private translocoService: TranslocoService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   this.fetchAccessTargetNames();
    //   this.updateDataSource();
    // });
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.danceSchoolService.getAsyncCurrentDanceSchool(), this.onDanceSchoolChangeSuccessful.bind(this)));
    this.subscriptions.add(getSubscription(this.passesService.getAsyncDfmCoursePasses(), this.onPassesChange.bind(this)));
  }

  onDanceSchoolChangeSuccessful(danceSchool: DanceSchool): void {
    this.passesService.loadAllDfmCoursePassesByDanceManagerId(danceSchool.id, PassStatusEnum.ACTIVE);
  }

  onPassesChange(passes: DfmCoursePassesTableInterface[]): void {
    this.passes = passes;
    this.updateDataSource();
    this.fetchAccessTargetNames();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchAccessTargetNames(): void {
    const allTargetIds = Array.from(new Set(this.passes.flatMap(pass => pass.targetIds)));
    if (allTargetIds.length > 0) {
      this.coursesHttpService.getCoursesBulkById(allTargetIds).subscribe(
        (courses) => {
          const courseNameMap = new Map(courses.map(course => [course.id, course.name]));
          this.passes.forEach((pass) => {
            pass.accessTargetNames = pass.targetIds
              .filter(targetId => courseNameMap.has(targetId)) 
              .map(targetId => courseNameMap.get(targetId)); 
          });
          this.updateDataSource();
        }
      );
    }
    else {
      this.passes.forEach(pass => pass.accessTargetNames = []);
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.passes);
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }
  
  editPassById(pass: PassInterface): void {
    this.editPassClicked.emit(pass.id);
  }
  
  duplicatePassById(pass: PassInterface): void {
    this.duplicatePassClicked.emit(pass.id);
  }
  
  updatePassStatusById(pass: PassInterface, status: PassStatusEnum): void {
    this.passHttpService.updatePassStatusById(pass.id, status).subscribe( {
      next: () => {
        this.passesService.loadAllDfmCoursePassesByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, this.selectedPassStatus);
      }
    });
  }
  
  deletePassById(pass: PassInterface): void {
     const confirmationDialogData: ConfirmationDialogInterface = {
       title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_PASS_DIALOG.TITLE'),
       cancelButtonColor: '',
       cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_PASS_DIALOG.DESCRIPTION')
    };
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.passHttpService.deletePassById(pass.id).subscribe( {
            next: () => {
              this.passesService.loadAllDfmCoursePassesByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, this.selectedPassStatus);
            },
            error: (err) => {
              if (err.status === 405) {
                this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.PASS_DELETE_ERROR'));
              } else {
                console.error(err);
              }
            }
          });
        }
      });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.passes, event.previousIndex, event.currentIndex);
      this.updateDataSource();
      this.updateSequenceNumber();
    }
  }

  updateSequenceNumber(): void {
    const newSequence: { id: string; sequenceNumber: number }[] = [];

    this.passes.forEach((ticket, index) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newSequence.push({ id: ticket.id!, sequenceNumber: index });
    });

    this.subscriptions.add(
      this.passHttpService.updateSequenceNumber(this.danceSchoolService.getSyncCurrentDanceSchool().id, newSequence).subscribe({
        next: () => {
          this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SEQUENCE_UPDATED'));
        },
        error: () => {
          this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SEQUENCE_UPDATE_ERROR'));
        }
      })
    );
  }

  togglePassChip(value: number): void {
    this.activePassChipIndex = value;
    if (value === 0) {
      this.selectedPassStatus = PassStatusEnum.ACTIVE;
    }
    else {
      this.selectedPassStatus = PassStatusEnum.ARCHIVED;
    }
    this.passesService.loadAllDfmCoursePassesByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, this.selectedPassStatus);
  }

  navigateToCreatePassPage(id?: string): void {
    if (id) {
      this.passHttpService.getById(id).subscribe({
        next: (pass) => {
          this.passesService.passToDuplicateSubject.next(pass);
          this.router.navigate(['create-pass'], {relativeTo: this.route});
        }
      });
    } else {
      this.router.navigate(['create-pass'], {relativeTo: this.route});
    }
  }

  protected readonly PassStatusEnum = PassStatusEnum;

  expandItem(element: PassInterface): void {
    if(this.expendedCoursesElement === element) {
      this.expandedElement = null;
      this.expendedCoursesElement = null;
    } else {
      if(element.description !== null) {
        this.expandedElement = element;
      }
      this.expendedCoursesElement = element;
    }
  }
}
