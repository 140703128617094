import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Order } from '@platri/df-common-shared-graphql';

declare let paypal;

@Component({
  selector: 'df-paypal-checkout',
  templateUrl: './paypal-checkout.component.html',
  styleUrls: ['./paypal-checkout.component.scss'],
})
export class PaypalCheckoutComponent implements AfterViewInit {
  @Input() priceDetails: {
    currencyCode: string;
    total: number;
    subTotal: number;
    taxTotal: number;
    description: string;
  };
  @Input() order: Order;

  @Output() onCheckoutSuccess = new EventEmitter<any>();
  @Output() onCheckoutCancelled = new EventEmitter<void>();
  @Output() onCheckoutError = new EventEmitter<void>();

  @ViewChild('paypal') paypalElement: ElementRef;

  preparePurchaseUnits(): any {
    const danceEventId =
      this.order.orderItemsCollapsed[0].elements[0].productSpecificData[
        'danceEventId'
      ];
    const danceEventIdSplit = danceEventId.split('-');
    return [
      {
        description:
          'Tickets ' +
          this.order.orderItemsCollapsed[0].elements[0].productSpecificData[
            'danceEventName'
          ] +
          ' ' +
          danceEventIdSplit[danceEventIdSplit.length - 1],
        custom:
          this.order.orderItemsCollapsed[0].elements[0].productSpecificData[
            'danceEventId'
          ],
        amount: {
          currency_code: this.priceDetails.currencyCode,
          value: this.priceDetails.total,
          breakdown: {
            item_total: {
              value: this.priceDetails.subTotal,
              currency_code: this.priceDetails.currencyCode,
            },
            tax_total: {
              value: this.priceDetails.taxTotal,
              currency_code: this.priceDetails.currencyCode,
            },
          },
        },
      },
    ];
  }

  ngAfterViewInit() {
    const purchaseUnitA = this.preparePurchaseUnits();
    paypal
      .Buttons({
        createOrder: async (data, actions) => actions.order.create({
            intent: 'CAPTURE',
            application_context: {
              brand_name: 'Danceflavors GmbH',
            },
            purchase_units: purchaseUnitA,
          }),
        onApprove: async (data, actions) => {
          this.onCheckoutSuccess.emit(actions);
        },
        onError: (error) => {
          this.onCheckoutError.emit();
        },
        onCancel: () => {
          this.onCheckoutCancelled.emit();
        },
      })
      .render(this.paypalElement.nativeElement);
  }
}
