import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'df-shared-lib-statistics-card',
  templateUrl: './statistics-card.component.html',
  styleUrls: ['./statistics-card.component.scss'],
})
export class StatisticsCardComponent {
  @Input() cardTitle: string;

  @Input() leftStatBox: {
    icon: string;
    title: string;
    mainStats: { left: number; right: number };
    subTitle: string;
    subStats: { left: number; right: number };
  };

  @Input() rightStatBox: {
    icon: string;
    title: string;
    mainStats: { left: number; right: number };
    subTitle: string;
    subStats: { left: number; right: number };
  };

  @Input() indicatorTitles: { left: string; right: string };

  @Input() showSubStats = false;

  @Output() removeCardEvent = new EventEmitter<void>();
}
