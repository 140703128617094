<ng-container *transloco="let t">
  <form [formGroup]="customFormGroup">
  <textarea [formControlName]="customFormControlName" (input)="onInputChange($event)" [rows]="rows" [mention]=taggableOptions [mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'username', dropUp: true}" [mentionListTemplate]="mentionListTemplate"></textarea>
  </form>
    <ng-template #mentionListTemplate let-item="item">
    
    <div style="display: flex; flex-direction: row;">
      <img *ngIf="item.imageUrl" class="material-icons-round" style="border-radius: 25px; margin-top: 5px;" [src]="item.imageUrl">
      <span *ngIf="!item.imageUrl" class="material-icons-round" style="margin-top: 5px;">account_circle</span>
      <div style="display:flex; flex-direction:column; padding-left: 10px;">
        <span>{{item.username}}</span>
        <span *ngIf="item.userId" style="color:#959595; font-size:11px">{{ t('GENERIC_WRAPPER.USER')}}</span>
        <span *ngIf="item.danceSchoolId" style="color:#959595; font-size:11px">{{ t('GENERIC_WRAPPER.DANCE_MANAGER')}}</span>
      </div>
    </div>
  </ng-template>
</ng-container>
