import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionFormSettingsComponent } from './description-form-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { DfQuillEditorModule, DfSharedButtonModule, TaggingTextAreaModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DescriptionFormSettingsComponent],
  exports: [DescriptionFormSettingsComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      DfQuillEditorModule,
      TaggingTextAreaModule,
      DfSharedButtonModule,
      TranslocoDirective
    ]
})
export class DescriptionFormSettingsModule {}
