import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { utcToZonedTime } from 'date-fns-tz';
import { addMinutes } from 'date-fns';
import { UserAppLangEnum } from '@platri/df-common-core';

@Pipe({
  name: 'dateTimezoned',
})
export class DateTimezonedPipe extends DatePipe implements PipeTransform {
  readonly germanDateFormat = 'EE dd. LLL yyyy';
  readonly germanTimeFormat = 'HH:mm';
  readonly englishDateFormat = 'EE, LLL dd, yyyy';
  readonly englishTimeFormat = 'h:mm a';

  // @ts-ignore
  transform(
    value: Date | string | number,
    timezone: string,
    locale: UserAppLangEnum,
    duration: number,
    onlyDate: boolean = false,
    onlyTime: boolean = false,
    format?: string
  ): string | null {
    if (value === null || value === undefined || !(value instanceof Date) && typeof value !== 'string' && typeof value !== 'number') {
      return null;
    }
    const date = new Date(value);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const zonedDate = utcToZonedTime(value, timezone ?? 'Europe/Berlin');
    if (onlyDate) {
      return this.getDateForLocale(zonedDate, locale, format);
    }
    if (onlyTime) {
      let timeString = this.getTimeForLocale(zonedDate, locale, false);
      if (duration && duration > 0) {
        const endDate = addMinutes(date, duration);
        timeString += ' - ' + this.getTimeForLocale(endDate, locale, true);
      } else {
        if (locale === UserAppLangEnum.DE) {
          timeString += ' Uhr';
        }
      }
      return timeString;
    }

    let dateAndTimeString =
      this.getDateForLocale(zonedDate, locale) +
      ', ' +
      this.getTimeForLocale(zonedDate, locale);
    if (duration && duration > 0) {
      const endDate = addMinutes(date, duration);
      dateAndTimeString += ' - ' + this.getTimeForLocale(endDate, locale, true);
    } else {
      if (locale === UserAppLangEnum.DE) {
        dateAndTimeString += ' Uhr';
      }
    }
    return dateAndTimeString;
  }

  getDateForLocale(date: Date, locale: UserAppLangEnum, format?: string): string {
    return super.transform(
      date,
      locale === UserAppLangEnum.DE
        ? format ?? this.germanDateFormat
        : format ?? this.englishDateFormat,
      null,
      locale === UserAppLangEnum.DE ? 'de-DE' : 'en-US'
    );
  }

  getTimeForLocale(
    date: Date,
    locale: string,
    isEndTime: boolean = false
  ): string {
    return (
      super.transform(
        date,
        locale === UserAppLangEnum.DE
          ? this.germanTimeFormat
          : this.englishTimeFormat,
        null,
        locale === UserAppLangEnum.DE ? 'de-DE' : 'en-US'
      ) + (locale === UserAppLangEnum.DE && isEndTime ? ' Uhr' : '')
    );
  }
}
