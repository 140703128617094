import { UserSocialLinkDto } from './user-social-link.dto';
import { DanceIntervalEnum, GenderEnum, UserAppLangEnum, UserRoleEnum, UsersInterface, UserStatusEnum } from '../../..';
import { BillingOrShippingAddressDto } from './billing-or-shipping-address.dto';
import { UsersLanguagesDto } from './users-languages.dto';
import { UsersDanceStylesDto } from './users-dance-styles.dto';

export class UserDto implements UsersInterface {
  id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  status?: UserStatusEnum;
  imageUrl?: string;
  description?: string;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  jobTitle?: string;
  gender?: GenderEnum;
  height?: number;
  birthday?: Date;
  formerPlace?: string;
  addresses?: BillingOrShippingAddressDto[];
  qualifications?: string[];
  userRole?: UserRoleEnum;
  appLang?: UserAppLangEnum;
  emailVerified?: boolean;
  lookingFor?: string[];
  socialLinks?: UserSocialLinkDto[];
  monthsDancing?: number;
  danceInterval?: DanceIntervalEnum;
  hasPassword?: boolean;
  showCompleteProfileDialog?: boolean;
  danceStyles?: UsersDanceStylesDto[];
  languageSkills?: UsersLanguagesDto[];
  dancingArea?: string;
  isAgePublic: boolean;
  isNamePublic: boolean;
}
