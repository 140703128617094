import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserProfileStateService, UserStateService } from '@platri/dfx-angular-core';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {
  step = 0;

  currentUser: UsersInterface;
  loading: Observable<boolean>;

  subscriptions = new Subscription();

  constructor(
    private userStateService: UserStateService, 
    private userProfileService: UserProfileStateService,
  ) {}

  ngOnInit(): void {
    this.loading = this.userStateService.getAsyncIsLoadingUser();
    this.listenOnUserChanges();
    this.loadCurrentUser();
  }
  
  listenOnUserChanges(): void {
    this.subscriptions.add(
      this.userStateService.getAsyncSelectedUser().subscribe((user) => {
        this.currentUser = user;
        this.userProfileService.sendUserProfileChanges(user);
      })
    );
  }
  
  loadCurrentUser(): void {
    this.userStateService.loadCurrentUser();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateUser(user: UsersInterface): void {
    this.userStateService.updateCurrentUser(user);
    this.loadCurrentUser();
  }

  setStep(stepNr: number): void {
    this.step = stepNr;
  }
}
