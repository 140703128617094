import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { CancelDialogComponent } from './cancel-dialog.component';
import {DfSharedButtonModule} from "@platri/dfx-angular-core";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CancelDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    DfSharedButtonModule,
    TranslocoDirective,
  ],
  exports: [CancelDialogComponent],
})
export class CancelDialogModule {}
