<ng-container *transloco="let t">
  <div
    class="list-entry"
    [ngClass]="{
      active: active,
      unread:conversation.messages[0].fromPartnerId !== currentPartnerId && !(conversation | conversationIsRead)
    }"
    [routerLink]="[]"
    [queryParams]="{ partnerIds: conversation | conversationGetPartnerIds : currentPartnerId }"
  >
    <div class="avatar-cell">
      <img
        class="avatar"
        imageFallback
        [fallbackSrc]="conversation.partners.length <= 2 ? 
          (conversation | conversationFirstPartner : currentPartnerId)?.id.startsWith('user') ? DEFAULT_PROFILE_PICTURE_PLACEHOLDER : DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER : DEFAULT_GROUP_MESSAGE_PLACEHOLDER"
        [src]="
          conversation.partners.length <= 2
            ? (conversation | conversationFirstPartner : currentPartnerId)?.imageUrl && (conversation | conversationFirstPartner : currentPartnerId)?.imageUrl !== '' ? (conversation | conversationFirstPartner : currentPartnerId).imageUrl :
              ((conversation | conversationFirstPartner : currentPartnerId)?.id.startsWith('user')
                ? DEFAULT_PROFILE_PICTURE_PLACEHOLDER
                : 'assets/images/studio_logo.png')
            : 'assets/images/group_picture.png'
        "
      />
    </div>
    <div class="text-cell">
      <span class="time">
        {{ conversation.messages[0].createdAt | timeAgo }}
      </span>
      <div style="clear: left"></div>
      <strong>{{ conversation | conversationGetPartnerUsername : currentPartnerId }} </strong>
      <div style="padding: 3px 0">
        <ng-container *ngIf="conversation.messages[0].fromPartnerId === currentPartnerId">
          {{ t('GENERIC_WRAPPER.YOU') }}:
        </ng-container>
        {{ conversation.messages[0].text | truncate : 6 }}
      </div>
    </div>
  </div>
</ng-container>
