import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'df-create-dance-event-info-general-page',
  templateUrl: './create-dance-event-info-general.page.html',
  styleUrls: ['./create-dance-event-info-general.page.scss'],
})
export class CreateDanceEventInfoGeneralPage {
  @Input() formGroup: UntypedFormGroup;

  @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSubmitClick: EventEmitter<void> = new EventEmitter<void>();
}
