<ng-container *transloco="let t">
  <div class="flex-container">
    <div *ngIf="showSocialButtons" style="display: flex; flex-direction: column; text-align: center; align-items: center; justify-content: center; gap: 10px;">
        <elab-google-sign-in-button (accessTokenEmitter)="signInWithGoogle($event)"></elab-google-sign-in-button>
        <elab-facebook-sign-in-button (accessTokenEmitter)="signInWithFacebook($event)"></elab-facebook-sign-in-button>
    </div>
    <div *ngIf="showSocialButtons" style="display: flex; flex-direction: row; justify-content: center; width: 350px; margin: auto">
      <mat-divider class="divider-left"></mat-divider>
        <h4 style="text-align: center; margin: 28px 0; color: #808080">{{ t('GENERIC_WRAPPER.OR') }}</h4>
      <mat-divider class="divider-right"></mat-divider>
    </div>
    <form class="form flex-container-centered-content" id="register-form" [formGroup]="registerFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyRegistered">
      <div class="flex-container-row">
        <div class="form-step-container flex-container-column">
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.FIRST_NAME') }}</mat-label>
            <input matInput id="firstname-input" type="text" [formControl]="registerFormGroup.controls.firstname" [placeholder]="t('GENERIC_WRAPPER.FIRST_NAME')" data-cy="firstname-input">
            <mat-error id="first-name-required-error" *ngIf="registerFormGroup.controls.firstname.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.FIRST_NAME_REQUIRED') }}
            </mat-error>
            <mat-error id="first-name-too-long-error" *ngIf="registerFormGroup.controls.firstname.hasError('maxlength')" class="errorTextSpaceBetween" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.FIRST_NAME_LENGTH') }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.LAST_NAME') }}</mat-label>
            <input matInput id="lastname-input" type="text" [formControl]="registerFormGroup.controls.lastname" [placeholder]="t('GENERIC_WRAPPER.LAST_NAME')" data-cy="lastname-input">
            <mat-error id="last-name-required-error" *ngIf="registerFormGroup.controls.lastname.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.LAST_NAME_REQUIRED') }}
            </mat-error>
            <mat-error id="last-name-too-long-error" *ngIf="registerFormGroup.controls.lastname.hasError('maxlength')" class="errorTextSpaceBetween" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.LAST_NAME_LENGTH') }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.EMAIL') }}</mat-label>
            <input matInput id="email-input" type="text" [formControl]="registerFormGroup.controls.email" [placeholder]="t('GENERIC_WRAPPER.EMAIL')" data-cy="email-input">
            <mat-error id="email-required-error"  *ngIf="registerFormGroup.controls.email.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.EMAIL_REQUIRED') }}
            </mat-error>
            <mat-error id="email-too-long-error" *ngIf="registerFormGroup.controls.email.hasError('maxlength')" class="errorTextSpaceBetween" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.EMAIL_LENGTH') }}
            </mat-error>
            <mat-error id="email-whitespace-error" *ngIf="registerFormGroup.controls.email.hasError('whitespace')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.WHITESPACE_NOT_ALLOWED_EMAIL') }}
            </mat-error>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.PASSWORD') }}</mat-label>
            <input matInput id="password-input" [formControl]="registerFormGroup.controls.password" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="password-input">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error id="password-required-error"  *ngIf="registerFormGroup.controls.password.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.PASSWORD_REQUIRED') }}
            </mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.CONFIRM_PASSWORD') }}</mat-label>
            <input matInput id="confirm-password-input" [formControl]="registerFormGroup.controls.confirmPassword" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="confirm-password-input">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error id="passwords-not-much-error" *ngIf="registerFormGroup.controls.confirmPassword.errors?.['mustMatch']">
              {{ t('GENERIC_WRAPPER.PASSWORDS_DO_NOT_MATCH') }}
            </mat-error>
            <mat-error id="confirm-password-required-error" *ngIf="registerFormGroup.controls.password.hasError('required')" data-cy="internal-server-error">
              {{ t('GENERIC_WRAPPER.PASSWORD_REQUIRED') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div style="width: 100%">
        <button id="registration-button" [disabled]="isWaitingForRegisterResponse" [class.spinner]="isWaitingForRegisterResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="sign-up-button">Registrieren</button>
      </div>
      
      <div style="margin-top: 16px;">
        <p class="hind-mtr" style="margin-top: 5px; margin-block-start: 0; margin-block-end: 0;"><span style="margin-right: 12px;">{{ t('GENERIC_WRAPPER.ALREADY_HAVE_AN_ACCOUNT') }}</span> <span class="hind-mtr link" (click)="routeToLogin()" data-cy="sign-up-button">{{ t('GENERIC_WRAPPER.LOGIN') }}</span></p>
      </div>
  
      <div *ngIf="signUpFailedWrongData || signUpFailedNoConnection || signUpFailedInternalServer" style="height: 60px">
        <p class="register-error" *ngIf="signUpFailedWrongData" data-cy="wrong-data-error">{{ t('GENERIC_WRAPPER.PROVIDED_DATA_WRONG') }}</p>
        <p class="register-error" *ngIf="signUpFailedNoConnection" data-cy="connection-error">{{ t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED') }}</p>
        <p class="register-error" *ngIf="signUpFailedInternalServer" data-cy="internal-server-error">{{ t('GENERIC_WRAPPER.SERVER_ERROR') }}</p>
      </div>
      
      
    </form>
    
    <div id="successful-registration" *ngIf="hasSuccessfullyRegistered" class="flex-container-centered-content" data-cy="successful-box">
      <p >{{ t('GENERIC_WRAPPER.REGISTRATION_SUCCESSFUL') }}!</p>
      <button mat-raised-button color="primary" type="button" (click)="routeToLogin(true)" data-cy="back-to-login-button">{{ t('GENERIC_WRAPPER.GO_TO_LOGIN') }}</button>
    </div>
    
  </div>
  
  <p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">{{ t('GENERIC_WRAPPER.FATAL_ERROR_LOADING_DATA') }}</p>
</ng-container>
