<div class="user-notification-wrapper">
  <a *ngIf="userNotificationItem" class="followers-mat-menu-panel" [routerLinkActive]="'active-link'" [routerLink]="userNotificationItem.routerLink" (click)="onClick()" mat-menu-item>
    <div class="flex-column">
      <img class="notification-icon mr-10" [src]="userNotificationItem.imageUrl && userNotificationItem.imageUrl !== '' ? userNotificationItem.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" imageFallback [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
    </div>
    <div class="flex-row align-space-between-center align-self-center">
      <div style="width: 250px;" class="flex-column">
        <span class="ellipsis-3 notification-message flex-row">{{userNotificationItem.text}}</span>
        <span class="notification-time flex-row">{{userNotificationItem.createdAt | timeAgo}}</span>
      </div>
      <div *ngIf="userNotificationItem.readAt === null" class="unread-dot"></div>
    </div>
  </a>
</div>
