import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoModule } from '@jsverse/transloco';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DmEventPreviewCardComponent } from './dm-event-preview-card.component';
import { PipesModule } from '../../../pipes';
import {MatTooltipModule} from "@angular/material/tooltip";
import { DuplicateDanceEventDialogModule } from '../../duplicate-dance-event-dialog';

@NgModule({
  declarations: [DmEventPreviewCardComponent],
  exports: [DmEventPreviewCardComponent],
    imports: [
      CommonModule,
      MatDividerModule,
      MatFormFieldModule,
      MatSelectModule,
      MatTabsModule,
      TranslocoModule,
      MatCardModule,
      PipesModule,
      MatIconModule,
      MatButtonModule,
      MatMenuModule,
      MatProgressSpinnerModule,
      MatMenuModule,
      DuplicateDanceEventDialogModule,
      RouterModule,
      MatTooltipModule,
    ],
})
export class DmEventPreviewCardModule {}
