import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTimeShort',
  standalone: true
})
export class RelativeTimeShortPipe implements PipeTransform {

  transform(value: any): string {
    const now = new Date();
    const past = new Date(value);
    const diffInSeconds = Math.abs((now.getTime() - past.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInMonths > 0) {
      return diffInMonths === 1 ? `${diffInMonths} Monat` : `${diffInMonths} Monate`;
    } else if (diffInWeeks > 0) {
      return diffInWeeks === 1 ? `${diffInWeeks} Woche` : `${diffInWeeks} Wochen`;
    } else if (diffInDays > 0) {
      return diffInDays === 1 ? `${diffInDays} Tag` : `${diffInDays} Tage`;
    } else if (diffInHours > 0) {
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
      //return diffInHours === 1 ? `${diffInHours} Std` : `${diffInHours} Std`;
    } else if (diffInMinutes > 0) {
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
      return diffInMinutes === 1 ? `${diffInMinutes} Min` : `${diffInMinutes} Min`;
    } else {
      const hours = value.getHours().toString().padStart(2, '0');
      const minutes = value.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
      return `Jetzt`;
    }
  }
}
