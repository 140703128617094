import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursePassesCheckoutCompletePage } from './course-passes-checkout-complete.page';
import { DfCheckoutCompleteComponent } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [CoursePassesCheckoutCompletePage],
  imports: [
    CommonModule, 
    DfCheckoutCompleteComponent,
    TranslocoModule
  ]
})
export class CoursePassesCheckoutCompletePageModule {}
