<ng-container *transloco="let t">
  <div class="flex-row align-space-between align-center pt2 pb2">
    <div style="display: flex; align-items: center;">
      <span class="course-title" >{{t('GENERIC_WRAPPER.COURSES')}}</span>
    </div>
    <div>
      <df-shared-lib-button
        [materialButtonStyleType]="'flat'"
        [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_COURSE')"
        (buttonClicked)="navigateToCreatePage()"
        id="create-new-course"
      ></df-shared-lib-button>
    </div>
  </div>
  
  <df-course-list-table
    [courses]="courses"
    (danceCourseReload)="reloadCourses()"
  ></df-course-list-table>
  
  <div *ngIf="(coursesService.loadingSubject$ | async) === false && courses?.length === 0">
    <div class="align-center" style="padding-top: 113px;">
      <df-shared-empty-page
        [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_COURSE')"
        [emptyTitle]="t('DANCE_MANAGER_COURSE.NO_COURSES')"
        [emptyDescription]="t('DANCE_MANAGER_COURSE.NO_COURSES_TEXT')"
        [imagePath]="'assets/images/courses-manager/no_courses.svg'"
        (buttonClicked)="navigateToCreatePage()"
      ></df-shared-empty-page>
    </div>
  </div>
</ng-container>
