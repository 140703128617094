<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveHeightData()">
    <div class="flex-row" style="gap: 1rem;">
      <df-shared-lib-number-input
        [control]="formGroup.controls.height"
        [label]="t('USER.HEIGHT')"
        [suffix]="'CM'"
        [errorMin]="t('USER.HEIGHT_MIN_ERROR')"
        [errorMax]="t('USER.HEIGHT_MAX_ERROR')"
      ></df-shared-lib-number-input>
    </div>
  
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveHeightData()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
