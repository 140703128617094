import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialPhonePrefixDropdownComponent } from './material-phone-prefix-dropdown.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [MaterialPhonePrefixDropdownComponent],
  exports: [MaterialPhonePrefixDropdownComponent],
    imports: [
      CommonModule,
      MatFormFieldModule,
      MatSelectModule,
      MatInputModule,
      ReactiveFormsModule,
      TranslocoDirective,
    ],
})
export class MaterialPhonePrefixDropdownModule {}
