import { TicketDanceEventStatisticsInterface } from '../../..';

export class TicketDanceEventStatisticsDto implements TicketDanceEventStatisticsInterface{
  ticketId: string;
  ticketName: string;
  quantity: number;
  quantityWithoutFreeTickets: number;
  soldQuantity: number;
  checkedInQuantity: number;
  checkedInQuantityWithoutFreeTickets: number;
  soldQuantityWithoutFreeTickets: number;
  soldLeaderQuantity: number;
  soldFollowerQuantity: number;
  checkedInLeaderQuantity: number;
  checkedInFollowerQuantity: number;
}
