import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DanceIntervalEnum, UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-dance-interval-form-settings',
  templateUrl: './dance-interval-form-settings.component.html',
  styleUrls: ['./dance-interval-form-settings.component.scss'],
})
export class DanceIntervalFormSettingsComponent implements OnInit {

  @Input() currentUser: UsersInterface;
  @Output() danceIntervalSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  isSubmitting = true;
  formGroup: UntypedFormGroup;

  danceIntervalEnumSorted = [DanceIntervalEnum.EVERY_DAY, DanceIntervalEnum.TWO_THREE_TIMES_A_WEEK, DanceIntervalEnum.ONCE_A_WEEK, DanceIntervalEnum.ONCE_A_MONTH, DanceIntervalEnum.I_DONT_DANCE];


  constructor(private readonly formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      danceInterval: [this.currentUser.danceInterval]
    });
  }

  saveDanceInterval(): void {
    if (this.formGroup.valid) {
      const user = {danceInterval: this.formGroup.value.danceInterval};
      this.danceIntervalSaved.emit(user);
      this.isSubmitting = true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
