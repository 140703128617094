import { Pipe, PipeTransform } from '@angular/core';
import { Conversation, MessagePartner } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'conversationFirstPartner'
})
export class ConversationFirstPartnerPipe implements PipeTransform {
  transform(conversation: Conversation, currentPartnerId: string): MessagePartner {
    return conversation.partners.find((partner) => partner.id !== currentPartnerId);
  }
}
