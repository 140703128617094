import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type MaterialButtonStyleType = 'outlined' | 'flat';

@Component({
  selector: 'df-shared-lib-button',
  templateUrl: './df-shared-button.component.html',
  styleUrls: ['./df-shared-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DfSharedButtonComponent {
  @Input() buttonLabel = '';
  @Input() materialButtonStyleType: MaterialButtonStyleType = 'flat';
  @Input() isDisabled = false;
  @Input() height = '45px';
  @Input() minWidth = '64px';
  @Input() borderRadius = '10px';
  @Input() loading = false;
  @Input() matIconName: string;
  @Input() isMatIconButton = false;
  @Input() matToolTipText: string;

  @Output() buttonClicked = new EventEmitter<void>();

  click(): void {
    if (!this.isDisabled) {
      this.buttonClicked.emit();
    }
  }
}
