<ng-container *transloco="let t">
  <div *ngIf="!loading else spinner">
    <div class="flex-column align-start-center p3 p0">
        <div class="flex-column w-100" style="max-width: 1140px; margin-bottom: -16px;">
            <div>
                <xng-breadcrumb [separator]="iconTemplate">
                    <ng-container *xngBreadcrumbItem="let breadcrumb">
                        <ng-container>{{ t(breadcrumb) }}</ng-container>
                    </ng-container>
                </xng-breadcrumb>
                <ng-template #iconTemplate>
                    <mat-icon>chevron_right</mat-icon>
                </ng-template>
            </div>
            <div class="flex-row">
                <mat-card
                        class="round-corners mat-elevation-z3 mt2 w-100 packages-card packages-show packages-show-respon"
                        style="max-width: 1140px"
                >
                    <div class="px3 pt2 flex-column">
                        <span class="title">{{ t('GENERIC_WRAPPER.PAYOUT') }}</span>
                        <mat-divider style="position: unset"></mat-divider>

                        <div class="flex-row" style="gap: 10px; margin-top: 16px">
                            <mat-icon style="cursor: pointer" (click)="goBack()">arrow_back</mat-icon>
                            <span>{{ t('GENERIC_WRAPPER.PAYOUTS') }}</span>
                        </div>
                      
                        <div class="flex-row" style="position: relative">
                            <div class="pt3 flex-column gap-20 flex-row-gt-sm">
                                <div class="info-card p2 flex-column-center">
                                    <div class="flex-row align-space-between">
                                        <span class="info-card-title">{{ t('DANCE_MANAGER_PAYOUT.TOTAL_BALANCE')}}</span>
                                        <div class="fee-info-card">
                                            <span class="fee-info-card-text p1">{{ t('DANCE_MANAGER_PAYOUT.INCL_FEES')}}</span>
                                        </div>
                                    </div>
                                    <span class="info-card-value pt1">{{ danceManagerDetailPayout?.totalAmount | centsToCurrencyString: 'EUR'}}</span>
                                </div>
                                <div class="info-card-fee p2 flex-column-center">
                                    <div class="flex-row align-space-between">
                                        <span class="info-card-title">{{ t('DANCE_MANAGER_PAYOUT.APPLICATION_FEE')}}</span>
                                    </div>
                                    <span class="info-card-value pt1">{{ danceManagerDetailPayout?.totalFee | centsToCurrencyString: 'EUR'}}</span>
                                </div>
                            </div>
                            <div class="downloadBtn-container flex-column gap-12">
                                <button class="btn-outlined" mat-button (click)="downloadPayout(danceManagerDetailPayout.id)">
                                    <mat-icon style="padding-right: 30px;">file_download</mat-icon>
                                    <span style="vertical-align: middle">{{ t('GENERIC_WRAPPER.DOWNLOAD_INVOICE')}}</span>
                                </button>
                            </div>
                        </div>
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 pt2">
                                <!-- Amount Column -->
                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.TABLE_HEADER.AMOUNT')}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.amount | centsToCurrencyString: element.currency}} </td>
                                </ng-container>

                                <!-- Date Column -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.TABLE_HEADER.DATE')}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.date | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'shortDate'}} </td>
                                </ng-container>

                                <!-- Payout Status Column -->
                                <ng-container matColumnDef="product">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.TABLE_HEADER.PRODUCT')}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="p1">
                                          <a *ngIf="element.eventId" style="text-decoration: none; color: inherit;" [href]="'/app/dance-event/' + loadedDanceEvents[element.eventId]?.id">
                                            {{loadedDanceEvents[element.eventId]?.name}} - {{loadedDanceEvents[element.eventId]?.appointments[0].startDate | dateTimezoned: loadedDanceEvents[element.eventId]?.appointments[0].address.timezoneId : currentUser?.appLang : null : true : false : 'shortDate'}}
                                          </a>
                                          <p style="margin: 0;" *ngFor="let product of element.productsWithQuantity">
                                            {{product}}
                                          </p>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Action Column -->
                                <ng-container matColumnDef="username">
                                    <th mat-header-cell *matHeaderCellDef>{{ t('DANCE_MANAGER_PAYOUT.TABLE_HEADER.USERNAME')}}</th>
                                    <td mat-cell *matCellDef="let element"> 
                                      <a *ngIf="loadedUsers[element.userId]" style="text-decoration: none; color: inherit;" [href]="'/app/@' + loadedUsers[element.userId]?.username">
                                        {{ loadedUsers[element.userId]?.username }}
                                      </a>
                                      <p *ngIf="!loadedUsers[element.userId]">
                                        {{ element.userEmail }} ({{ t('GUEST') }})
                                      </p>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
  </div>

  <ng-template #spinner>
    <div *ngIf="(loading$ | async) === true">
      <mat-spinner
            style="margin: auto"
            diameter="80"
      ></mat-spinner>
    </div>
  </ng-template>
</ng-container>
