import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, DanceSchoolFaqHttpService } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogInterface, DanceManagerFaqDto } from '@platri/df-common-core';

@Component({
  selector: 'dfm-add-edit-faq',
  templateUrl: './dfm-add-edit-faq.component.html',
  styleUrls: ['./dfm-add-edit-faq.component.scss'],
})
export class DfmAddEditFaqComponent implements OnInit, OnDestroy {
  faqForm: UntypedFormGroup;

  @Input() faq: DanceManagerFaqDto;
  @Input() faqPanelStateOpen: boolean;
  @Input() danceSchoolId: string;
  @Output() reloadNewFaqArray = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private danceSchoolFaqHttpService: DanceSchoolFaqHttpService
  ) {}

  get answerControl(): any {
    return this.faqForm.get('answer');
  }

  ngOnInit(): void {
    this.initForm();
    if (this.faq) {
      this.setForm();
    }
  }

  initForm(): void {
    this.faqForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required],
    });
  }

  setForm(): void {
    this.faqForm.controls.question.setValue(this.faq.question);
    this.faqForm.controls.answer.setValue(this.faq.answer);
  }

  saveFaq(): void {
    if (!this.faq) {
      this.createFaq();
    } else {
      this.updateFaq();
    }
  }

  createFaq(): void {
    this.subscription.add(
      this.danceSchoolFaqHttpService
        .create(
          this.danceSchoolId,
          this.faqForm.value.question,
          this.faqForm.value.answer
        )
        .subscribe(() => {
          this.reloadNewFaqArray.emit();
        })
    );
  }

  updateFaq(): void {
    const updatedFaq: DanceManagerFaqDto = {
      id: this.faq.id,
      ...this.faqForm.getRawValue(),
    };
    this.subscription.add(
      this.danceSchoolFaqHttpService
        .updateById(this.danceSchoolId, updatedFaq)
        .subscribe(() => this.reloadNewFaqArray.emit())
    );
  }

  deleteFaq(): void {
    this.subscription.add(
      this.danceSchoolFaqHttpService
        .deleteById(this.danceSchoolId, this.faq.id!)
        .subscribe(() => {
          this.reloadNewFaqArray.emit();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
