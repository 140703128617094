
export class CreateCheckoutSessionRequestDto {
  dmPassSubscriptionId: string;
  
  constructor(args: CreateCheckoutSessionRequestDto) {
    if (args) {
      this.dmPassSubscriptionId = args.dmPassSubscriptionId;
    }
  }
}
