import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'googleLatLngLiteralPipe',
})
export class GoogleLatLngLiteralPipe implements PipeTransform {
  transform(value: number[]): google.maps.LatLng {
    if (value === null) {
      return null;
    }
   return new google.maps.LatLng(value[0],value[1]);
  }
}
