<ng-container *transloco="let t">
  <div [formGroup]="formGroup" class="flex-column gap-40" style="max-width: 600px;">
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.PRICE') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_PRICE_DESC') }}</span>
      <mat-form-field appearance="outline" style="max-width: 125px;">
        <input dfNoScroll [formatNumber]="2" [step]="0.01" formControlName="price" id="price" matInput type="number" [placeholder]="t('GENERIC_WRAPPER.PRICE') "/>
        <span matSuffix class="mat-suffix-symbol">€</span>
        <mat-error *ngIf="price.hasError('required')">{{ t('GENERIC_WRAPPER.PRICE_REQUIRED') }}</mat-error>
      </mat-form-field>
      
      <mat-radio-group formControlName="isPaidMonthly" class="flex-column gap-10">
        <mat-radio-button [value]="true">
          {{ t('GENERIC_WRAPPER.SUB_MANAGER.MONTHLY_PAID') }} -
          <span class="mat-radio-description">{{ t('GENERIC_WRAPPER.SUB_MANAGER.MONTHLY_PAID_DESC') }}</span>
        </mat-radio-button>
        
        <mat-radio-button [value]="false">
          {{ t('GENERIC_WRAPPER.SUB_MANAGER.TOTAL_DURATION_PRICE') }} -
          <span class="mat-radio-description">{{ t('GENERIC_WRAPPER.SUB_MANAGER.TOTAL_DURATION_PRICE_DESC') }}</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_VAT') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_VAT_DESC') }}</span>
      <mat-form-field appearance="outline" style="max-width: 125px;">
        <input dfNoScroll matInput type="number" formControlName="taxPercentage" required [placeholder]="t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_VAT')">
        <span matSuffix class="mat-suffix-symbol">%</span>
        <mat-error *ngIf="taxPercentage.hasError('required')">{{ t('GENERIC_WRAPPER.VAT_REQUIRED') }}</mat-error>
      </mat-form-field>
    </div>
  <!--  <div class="flex-column gap-12">-->
  <!--    <span class="mat-label-header">{{ 'PASSES_MANAGER.HTML.FEE' | translate }}</span>-->
  <!--    <span class="mat-label-subtext">{{ 'PASSES_MANAGER.HTML.FEE_DESC' | translate }}</span>-->
  <!--    <mat-radio-group class="flex-column" formControlName="feesPaidBy">-->
  <!--      <mat-radio-button [value]="feesPaidByEnum.CREATOR">{{ 'PASSES_MANAGER.HTML.FEE_YOU' | translate }}</mat-radio-button>-->
  <!--      <mat-radio-button [value]="feesPaidByEnum.CUSTOMER" [disabled]="true">{{ 'PASSES_MANAGER.HTML.FEE_CUSTOMER' | translate }}</mat-radio-button>-->
  <!--    </mat-radio-group>-->
  <!--  </div>-->
  </div>
</ng-container>
