import { ScanPassResultEnum } from '../enums';

export interface ScanPassResponseInterface {
  result?: ScanPassResultEnum;
  passName?: string;
  userInfo?: string;
  validUntil?: Date;
  passUsageCount?: number;
  passScannedCount?: number;
  isUserPassSubscription?: boolean;
  lastScannedAt?: Date;
}
