import { Component } from '@angular/core';
import { AuthStateService, DanceManagerHttpService, DanceManagerPayoutHttpService, DanceManagerPayoutStateService, DanceSchoolStateService } from '@platri/dfx-angular-core';
import { MatTableDataSource } from '@angular/material/table';
import { DanceManagerPayoutInterface, DanceSchoolInterface, UsersInterface } from '@platri/df-common-core';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'dfm-payout-overview',
  templateUrl: './dfm-payout-overview-page.component.html',
  styleUrls: ['./dfm-payout-overview-page.component.scss'],
})
export class DfmPayoutOverviewPageComponent {
  displayedColumns: string[] = [
    'stripeCreatedAt',
    'stripeArrivalAt',
    'status',
    'amount',
    'action',
  ];

  currentDanceManager$: Observable<DanceSchoolInterface>;
  dataSource = new MatTableDataSource<DanceManagerPayoutInterface>();
  loading$: Observable<boolean>;
  
  nextMonthFirstDate: Date;
  currentUser: UsersInterface;

  constructor(
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    public readonly danceManagerPayoutService: DanceManagerPayoutStateService,
    private readonly danceManagerPayoutHttpService: DanceManagerPayoutHttpService,
    private readonly authService: AuthStateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.nextMonthFirstDate = DateTime.now()
      .plus({ month: 1 })
      .startOf('month')
      .toJSDate();
  }

  ngOnInit(): void {
    this.loading$ = this.danceManagerPayoutService.loadingSubject$;
    this.currentDanceManager$ = this.danceSchoolService.getAsyncCurrentDanceSchool();
    this.danceManagerPayoutService.payoutsSubject$.subscribe((payouts) => {
      this.dataSource.data = payouts;
    });

    this.danceManagerPayoutService.getAllByDanceManagerId(
      this.danceSchoolService.getSyncCurrentDanceSchool().id!
    );
  }

  syncPayouts(): void {
    this.danceManagerPayoutService.syncByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id!);
    this.danceManagerHttpService.getDanceManagerByUrlName(this.danceSchoolService.getSyncCurrentDanceSchool().urlName!).subscribe((result) => {
      this.danceSchoolService.sendCurrentDanceSchool(result);
    });
  }

  downloadPayout(payout: DanceManagerPayoutInterface): void {
    const ticketWindow = window.open(); //OPEN WINDOW FIRST ON SUBMIT THEN POPULATE PDF
    ticketWindow!.document.write('Loading...');

    this.danceManagerPayoutHttpService
      .downloadPayout(
        this.danceSchoolService.getSyncCurrentDanceSchool().id!,
        payout.id
      )
      .subscribe({
        next: (blob) => {
          const url = URL.createObjectURL(blob);
          ticketWindow!.location.href = url;
        },
        error: (err) => {
          console.error(err);
          ticketWindow!.close();
        },
      });
  }

  routeToPayoutDetail(id: string): void{
    this.router.navigate(['./' + id], {
      relativeTo: this.route,
    });
  }
}
