import { NotificationItemIconEnum, NotificationRefTypeEnum, NotificationTypeEnum } from '../../enums';

export interface NotificationItemInterface {
  id: string;
  icon: NotificationItemIconEnum;
  toUserId: string;
  notificationType: NotificationTypeEnum;
  message: string;
  createdAt: Date;
  isRead: boolean;
  danceClassId: string;
  title: string;
  body: string;
  notificationRefType: NotificationRefTypeEnum;
  notificationRefId: string;
}
