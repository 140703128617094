<ng-container *transloco="let t">
  <div class="full-view">
    <div class="flex-container">
      <div class="loading-box" *ngIf="isWaitingForDeleteAccountResponse">
        <mat-spinner></mat-spinner>
      </div>
      
      <div *ngIf="!deleteAccountFailedNoConnection && !isWaitingForDeleteAccountResponse && hasSuccessfullyDeleteAccount">
        {{ t('GENERIC_WRAPPER.DELETE_ACCOUNT_SUCCESSFUL') }}
      </div>
      
      <div *ngIf="!deleteAccountFailedNoConnection && !isWaitingForDeleteAccountResponse && !hasSuccessfullyDeleteAccount">
        {{ t('GENERIC_WRAPPER.SOMETHING_WENT_WRONG') }}
      </div>
      
      <div *ngIf="deleteAccountFailedNoConnection">
        {{ t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED') }}
      </div>
      
      <div *ngIf="deleteAccountFailedInternalServer">
        {{ t('GENERIC_WRAPPER.SERVER_ERROR') }}
      </div>
    </div>
  </div>
</ng-container>
