import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceEventCoHostsComponent } from './dance-event-co-hosts.component';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceEventCoHostsComponent],
  imports: [CommonModule, RouterLink, TranslocoDirective],
  exports: [DanceEventCoHostsComponent],
})
export class DanceEventCoHostsModule {}
