import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTicketSettingsStepComponent } from './event-ticket-settings-step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DfQuillEditorModule } from '../df-quill-editor';
import {NoScrollDirective} from "../../directives";
import { TranslocoStateConst } from '../../constants';

@NgModule({
  declarations: [EventTicketSettingsStepComponent],
  exports: [EventTicketSettingsStepComponent],
  providers: [
  ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      CdkStepperModule,
      MatStepperModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatSelectModule,
      MatIconModule,
      TranslocoModule,
      DfQuillEditorModule,
      MatCheckboxModule,
      NoScrollDirective,
    ],
})
export class EventTicketSettingsStepModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'dfm-event', alias: 'DANCE_MANAGER_EVENT' });
  }
}
