<ng-container *transloco="let t">
  <div *ngIf="danceManager" class="main-container">
    <mat-card style="padding: 16px;" class="main-container-title-image w-100 border-radius-10-without-shadow">
      <div class="dance-manager-image-container relative">
        <div *ngIf="isOwnerOrAdmin" class="dance-manager-image-container-highlighter w-100 h-100 absolute circle" (click)="openDanceSchoolLogoDialogComponent()">
          <mat-icon fontSet="material-icons-outlined">photo_camera</mat-icon>
        </div>
        <img [ngClass]="isOwnerOrAdmin ? 'editable' : 'read-only'" class="profile-image circle" [src]="danceManager.imageUrl ?? 'assets/images/studio_logo.png'" [alt]="t('GENERIC_WRAPPER.DANCE_SCHOOL_PICTURE_ALT')"/>
        <button mat-icon-button *ngIf="isOwnerOrAdmin && danceManager.imageUrl" class="edit-button delete-button" (click)="clickOnLogoDelete()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      <div class="flex-row align-center dance-manager-name">
        <span>{{ danceManager.name }}</span>
        <button *ngIf="isOwnerOrAdmin && isDmPreview" mat-icon-button (click)="routeToDanceManager()" [matTooltip]="t('DANCE_MANAGER.OPEN_DANCE_MANAGER_PACE')">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
      <div class="dance-manager-categories">
        <span *ngFor="let dmCategory of danceManager.danceManagerCategory; let isLast = last;">
          {{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + dmCategory.toUpperCase()) }}{{ isLast ? '' : ', ' }}
        </span>
      </div>
      <div class="dance-manager-follow-container w-100">
        <df-shared-lib-follow 
          [profileId]="danceManager.id"
          [targetType]="followTargetTypeEnum.DANCE_SCHOOL" 
          [hideFollowing]="true"
          [pageViews]="pageViews"
        ></df-shared-lib-follow>
        <div *ngIf="danceManager.description" class="dance-manager-description-container w-100">
          <div class="w-100 flex-row" style="justify-content: space-between; align-items: center;">
            <span class="card-title-font">{{ t('DANCE_MANAGER.ABOUT_US') }}</span>
            <a style="text-decoration: none; color: inherit">
              <button mat-icon-button *ngIf="isOwnerOrAdmin" (click)="routeToSettings()">
                <mat-icon>edit</mat-icon>
              </button>
            </a>
          </div>
          <mat-divider class="w-100" style="position: unset"></mat-divider>
          <div class="w-100 card-description-text ql-container" style="border-width: 0;">
            <div style="padding: 0;" [innerHTML]="!isTruncated ? (danceManager.description | dynamicTag | truncate: descriptionTruncateLength) : (danceManager.description | dynamicTag)"></div>
          </div>
          <div *ngIf="hasTruncateLength && !isTruncated">
            <span class="read-more-text" (click)="isTruncated = true">{{ t('GENERIC_WRAPPER.READ_MORE') }}</span>
          </div>
          <div *ngIf="isTruncated">
            <span class="read-more-text" (click)="isTruncated = false">{{ t('GENERIC_WRAPPER.READ_LESS') }}</span>
          </div>
        </div>
      </div>
    </mat-card>
    
    <mat-card class="border-radius-10-without-shadow social-link-container" *ngIf="danceManager.socialLinks && danceManager.socialLinks.length > 0">
      <div class="w-100 flex-row" style="justify-content: space-between; align-items: center;">
        <span class="card-title-font">{{ t('DANCE_MANAGER.FIND_US') }}</span>
        <button mat-icon-button *ngIf="isOwnerOrAdmin" (click)="routeToSettings('contact')">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <mat-divider class="w-100" style="position: unset; margin-bottom: 10px"></mat-divider>
      <div *ngFor="let socialLink of danceManager.socialLinks" class="social-icon">
        <a [href]="socialLink.url" target="_blank" rel="noopener" class="social-link-url">
          <img class="w-100 h-100" [alt]="socialLink.type + ' Icon'" [src]="'assets/icons/social-icons/' +socialLink.type.toLowerCase() +'.png'" [ngStyle]="socialLink.type === 'WEBSITE' && { filter: 'brightness(0.2)' }"/>
          <span class="fs16">{{socialLink.type?.substring(0, 1) + socialLink.type?.substring(1)?.toLowerCase()}}</span>
        </a>
      </div>
    </mat-card>
  
    <mat-card class="border-radius-10-without-shadow dance-styles-container" *ngIf="danceManager?.danceStyles.length > 0">
       <div class="flex-row align-space-between-center w-100">
         <span class="card-title-font">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</span >
         <a style="text-decoration: none; color: inherit;">
           <button mat-icon-button *ngIf="isOwnerOrAdmin" (click)="routeToSettings()">
             <mat-icon>edit</mat-icon>
           </button>
         </a>
      </div>
      <mat-divider class="w-100" style="position: unset"></mat-divider>
      <div class="pt-8">
        <mat-chip-listbox aria-label="Dancestyles list">
          <mat-chip-option 
            disableRipple="true" 
            selectable="false"
            *ngFor="let danceStyle of danceManager.danceStyles"
          >
            {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase())}}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </mat-card>
    <df-monorepo-locations-wrapper 
      [isStudioPage]="true"
      [isDmPreview]="isDmPreview"
    ></df-monorepo-locations-wrapper>
  </div>
</ng-container>
