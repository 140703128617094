import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CourseAppointmentDto, CourseDmOverviewDto, CourseDto, CourseRulesInterface, CourseStatusEnum, CreateCourseRequestDto, environmentForWeb, PageDto, SortDirectionEnum } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class CoursesHttpService {
  private SERVICE_URL = `${environmentForWeb.apiCoursesUrl}/courses`;
  private SERVICE_URL_APPOINTMENTS = `${environmentForWeb.apiCoursesUrl}/appointments`;

  constructor(private readonly httpClient: HttpClient) {}
  
  getAllByDanceManagerForDmOverview(page = 1, limit = 10, danceManagerId: string, status?: CourseStatusEnum): Observable<PageDto<CourseDmOverviewDto>> {
    return this.httpClient.post<PageDto<CourseDmOverviewDto>>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/course-overview-page`, {
      page,
      limit,
      status,
      sortColumn: 'name',
      sortDirection: 'ASC'
    });
  }

  getAllByDanceManagerForPublicOverview(page = 1, limit = 10, danceManagerId: string): Observable<PageDto<CourseDmOverviewDto>> {
    return this.httpClient.post<PageDto<CourseDmOverviewDto>>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/courses-public-view`, {
      page,
      limit,
      sortColumn: 'name',
      sortDirection: 'ASC'
    });
  }

  getAllByFilter(args: {page: number; limit: number; withAppointments: boolean; latitude?: number; longitude?: number; sortColumn?: string; startDifficulty?: number; endDifficulty?: number; sortDirection?: SortDirectionEnum; radius?: number; danceManagerId?: string; danceStyles?: string[]; search?: string}): Observable<PageDto<CourseDto>> {
    let params = new HttpParams();
    params = params.set('page', args.page);
    params = params.set('limit', args.limit);
    params = params.set('withAppointments', args.withAppointments);
    if (args.latitude) {
      params = params.set('latitude', args.latitude);
    }
    if (args.longitude) {
      params = params.set('longitude', args.longitude);
    }
    if (args.longitude && args.latitude) {
      if (args.radius) {
        params = params.set('radius', args.radius);
        params = params.set('radiusUnit', 'KM');
      } else {
        params = params.set('radius', 250);
        params = params.set('radiusUnit', 'KM');
      }
    }
    if (args.sortColumn && args.sortDirection) {
      params = params.set('sortColumn', args.sortColumn);
      params = params.set('sortDirection', args.sortDirection);
    }
    if (args.startDifficulty) {
      params = params.set('startDifficulty', args.startDifficulty);
    }
    if (args.endDifficulty) {
      params = params.set('endDifficulty', args.endDifficulty);
    }
    if (args.danceManagerId) {
      params = params.set('danceManagerId', args.danceManagerId);
    }
    if (args.danceStyles?.length > 0) {
      params = params.set('danceStyles', args.danceStyles.toString());
    }
    if (args.search) {
      params = params.set('search', args.search);
    }
    return this.httpClient.get<PageDto<CourseDto>>(`${this.SERVICE_URL}`, {
      params
    });
  }

  getById(id: string, withAppointments = false, onlyUpcoming = true): Observable<CourseDto> {
    return this.httpClient.get<CourseDto>(`${this.SERVICE_URL}/${id}?withAppointments=${withAppointments}&onlyUpcoming=${onlyUpcoming}`);
  }
  
  getAllWhereTeacherByUserId(userId: string): Observable<CourseDto[]> {
    return this.httpClient.get<CourseDto[]>(`${this.SERVICE_URL}/teachers/${userId}`);
  }

  getCoursePageViewsById(id: string): Observable<number> {
    return this.httpClient.get<number>(`${this.SERVICE_URL}/${id}/page-views`);
  }

  updateMainImageById(id: string, formData: FormData): Observable<CourseDto> {
    return this.httpClient.put<CourseDto>(`${environmentForWeb.apiCoursesUrl}/course-images/course/${id}/main-image`, formData);
  }

  deleteMainImageById(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${environmentForWeb.apiCoursesUrl}/course-images/course/${id}/main-image`);
  }
  
  createCourse(course: CreateCourseRequestDto): Observable<CourseDto> {
    return this.httpClient.post<CourseDto>(`${environmentForWeb.apiCoursesUrl}/courses`, course);
  }

  updateCourseStatusById(id: string, status: CourseStatusEnum): Observable<CourseDto> {
    return this.httpClient.patch<CourseDto>(`${this.SERVICE_URL}/${id}/status`, {status});
  }

  updateCourseGeneralInfo(id: string, formData: FormData): Observable<CourseDto> {
    return this.httpClient.put<CourseDto>(`${this.SERVICE_URL}/${id}/general-info`, formData);
  }

  updateCourseRules(id: string, data: CourseRulesInterface): Observable<CourseDto> {
    return this.httpClient.put<CourseDto>(`${this.SERVICE_URL}/${id}/rules`, data);
  }

  createCourseAppointmentScheduler(schedulerData: any): Observable<any> {
    return this.httpClient.post<any>(`${environmentForWeb.apiCoursesUrl}/appointment-schedulers`, schedulerData);
  }

  deleteCourseById(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.SERVICE_URL}/${id}`);
  }

  deleteCourseAppointmentById(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.SERVICE_URL_APPOINTMENTS}/${id}`);
  }

  updateCourseAppointmentById(id: string, formData: FormData): Observable<void> {
    return this.httpClient.put<void>(`${this.SERVICE_URL_APPOINTMENTS}/${id}`, formData);
  }

  getAppointmentById(id: string): Observable<CourseAppointmentDto> {
    return this.httpClient.get<CourseAppointmentDto>(`${this.SERVICE_URL_APPOINTMENTS}/${id}`);
  }

  getCoursesBulkById(targetIds: string[]): Observable<CourseDto[]> {
    const ids = { ids: targetIds };
    return this.httpClient.post<CourseDto[]>(`${this.SERVICE_URL}/bulk`, ids);
  }

  duplicateCourseById(id: string, name: string, danceStyleIds: string[], startDifficulty: number, endDifficulty: number, takeoverPasses: boolean): Observable<CourseDto> {
    return this.httpClient.post<CourseDto>(`${this.SERVICE_URL}/${id}/duplicate`, {
      name,
      danceStyleIds,
      startDifficulty,
      endDifficulty,
      takeoverPasses
    });
  }
}
