import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DanceManagerService, DanceSchoolHttpService, DanceSchoolStateService } from '@platri/dfx-angular-core';
import { Observable } from 'rxjs';
import { DanceSchoolInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-app',
  templateUrl: './df-app.component.html',
  styleUrls: ['./df-app.component.scss'],
})
export class DfAppComponent implements OnInit {
  public readonly danceSchool$: Observable<DanceSchoolInterface>;
  
  constructor(
    private danceSchoolService: DanceSchoolStateService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private danceManagerService: DanceManagerService,
    private route: ActivatedRoute
  ) {
    this.danceSchool$ = this.danceSchoolService.getAsyncCurrentDanceSchool();
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.danceSchoolHttpService.getDanceSchoolById(params.id).subscribe((result) => {
          this.danceSchoolService.sendCurrentDanceSchool(result);
          this.danceManagerService.getDanceManagerFeaturesById(params.id);
        });
      }
    });
  }
}
