import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DfAppComponent } from './df-app.component';
import {
  dfConfirmEmailRoute,
  dfConfirmEmailTokenRouterParam,
  dfForgotPasswordRoute,
  dfLoginRoute,
  dfMagicLinkLoginRoute,
  dfMagicLinkLoginTokenRouterParam,
  dfMagicLoginRoute,
  dfRegisterRoute,
  dfResetPasswordRoute,
  dfResetPasswordTokenRouterParam,
  dfSignUpRoute
} from '@platri/dfx-angular-core';
import { AuthGuard, NoAuthGuard } from '../../helpers';

const routes: Routes = [
  {
    path: '',
    component: DfAppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.LandingPageModule),
      },
      {
        path: dfLoginRoute,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.LoginPageModule)
      },
      {
        path: dfMagicLoginRoute,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.MagicLinkLoginPageModule)
      },
      {
        path: dfForgotPasswordRoute,
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.ForgotPasswordPageModule)
      },
      {
        path: dfRegisterRoute,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.RegisterPageModule)
      },
      {
        path: dfSignUpRoute,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.RegisterPageModule)
      },
      {
        path: dfResetPasswordRoute + '/:' + dfResetPasswordTokenRouterParam,
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.ResetPasswordPageModule)
      },
      {
        path: dfConfirmEmailRoute + '/:' + dfConfirmEmailTokenRouterParam,
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.ConfirmEmailPageModule)
      }, 
      {
        path: dfMagicLinkLoginRoute + '/:' + dfMagicLinkLoginTokenRouterParam,
        canActivate: [NoAuthGuard],
        loadChildren: () => import('@platri/df-angular-auth').then((m) => m.MagicLinkLoginTokenPageModule)
      },
      {
        path: 'invite-to-dm',
        canActivate: [AuthGuard],
        loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.InviteToDmPageModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@platri/df-angular-user').then(
            (m) => m.SettingsPageModule
          ),
      },
      {
        path: 'search',
        loadChildren: () => import('@platri/df-angular-search').then((m) => m.FrontendDfSearchAppModule),
      },
      {
        path: 'messages', canActivate: [AuthGuard], loadChildren: () => import('@platri/df-angular-chat').then((m) => m.MessagesModule),
      },
      {
        path: 'time-out',
        loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.ConnectionTimedOutModule),
      },
      {
        path: '404',
        loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.PageNotFoundModule),
      },
      {
        path: 'dance-class',
        loadChildren: () => import('@platri/df-angular-course').then((m) => m.FrontendDfCourseAppModule),
      },
      {
        path: 'dance-event',
        loadChildren: () => import('@platri/df-angular-dance-event').then((m) => m.FrontendDfDanceEventAppModule),
      },
      {
        path: 'dance-manager/process-subscription',
        loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.ProcessSubscriptionPageModule),
      },
      {
        path: 'create-dance-manager',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@platri/df-angular-create-dance-manager').then((m) => m.FrontendCreateDanceManagerModule),
      },
      {
        path: 'inventory',
        canActivate: [AuthGuard],
        loadChildren: () => import('@platri/df-angular-user').then((m) => m.InventoryPageModule)
      },
      {
        path: 'inventory',
        canActivate: [AuthGuard],
        loadChildren: () => import('@platri/df-angular-user').then((m) => m.AppointmentsPageModule)
      }
    ],
  },

  {
    path: '404',
    loadChildren: () =>
      import('@platri/dfx-angular-core').then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DfAppRoutingModule {
  constructor() {
    console.log('DfAppRoutingModule initialized');
  }
}
