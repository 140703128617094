import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {Meta} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  private readonly DEFAULT_TITLE = 'Danceflavors';

  private metaTagNameList: string[] = [];

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private readonly meta: Meta,
  ) {
  }

  setTitle(title: string): void {
    this._doc.title = title;
  }

  addUpdateMetaTag(tagName: string, content: string): void {
    this.metaTagNameList.push(tagName);
    if (this.meta.getTag(`name="${tagName}"`)) {
      this.meta.updateTag({name: tagName, content});
    } else {
      this.meta.addTag({name: tagName, content});
    }
  }

  resetTitle(): void {
    this._doc.title = this.DEFAULT_TITLE;
  }

  removeMetaTag(tagName: string): void {
    this.meta.removeTag(`name="${tagName}"`);
  }

  removeAllMetaTags(): void {
    for (const metaTagName of this.metaTagNameList) {
      this.removeMetaTag(metaTagName);
    }
    this.metaTagNameList = [];
  }

  insertStructuredData(json: any, className = 'structured-data-website'): void {
    const script = this._doc.createElement('script');
    script.setAttribute('class', className);
    script.type = 'application/ld+json';
    script.text = JSON.stringify(json);
    this._doc.head.appendChild(script);
  }

  removeStructuredData(): void {
    const els = [];
    ['structured-data-website', 'structured-data-org'].forEach(c => {
      els.push(...Array.from(this._doc.head.getElementsByClassName(c)));
    });
    els.forEach(el => this._doc.head.removeChild(el));
  }

  resetAll(): void {
    this.resetTitle();
    this.removeAllMetaTags();
    this.removeStructuredData();
  }

}
