import { ImageDto } from './image.dto';
import { RoomInterface } from '../../..';
import { DanceSchoolLocationDto } from './dance-school-location.dto';
import { AmenityDto } from './amenity.dto';

export class RoomDto
  implements RoomInterface {
  id: string;
  name: string;
  qm: number;
  splittedRoom: boolean;
  location: DanceSchoolLocationDto;
  images: ImageDto[];
  amenities?: AmenityDto[];
}
