import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { RegisterFormComponent } from './register-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import {FacebookSignInButtonModule} from '../../facebook-sign-in-button';
import {GoogleSignInButtonModule} from '../../google-sign-in-button';
import {MatDividerModule} from "@angular/material/divider";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [RegisterFormComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatInputModule,
      MatSelectModule,
      MatButtonModule,
      MatIconModule,
      RouterLink,
      FacebookSignInButtonModule,
      GoogleSignInButtonModule,
      MatDividerModule,
      TranslocoDirective
    ],
  exports: [RegisterFormComponent]
})
export class RegisterFormModule { }
