import { OpeningHoursDayDto } from './opening-hours-day.dto';
import { OpeningHoursInterface } from '../../..';

export class OpeningHoursDto implements OpeningHoursInterface {
  monday: OpeningHoursDayDto[];
  tuesday: OpeningHoursDayDto[];
  wednesday: OpeningHoursDayDto[];
  thursday: OpeningHoursDayDto[];
  friday: OpeningHoursDayDto[];
  saturday: OpeningHoursDayDto[];
  sunday: OpeningHoursDayDto[];
}
