<ng-container *transloco="let t">
  <div class="flex-column align-center pl3 pr3">
    <div class="events-manager-container w-100 pb2">
      <div class="events-manager-breadcrumb">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb; let i = index">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="events-manager-card-container">
        <mat-card style="background-color: white" class="events-manager-card round-corners w-100 no-shadow-mat-card">
          <mat-card-content style="padding: 0;">
           <mat-tab-group
             dynamicHeight
             [@.disabled]="true"
             [(selectedIndex)]="currenTabIndex"
             (selectedTabChange)="onTabChange($event)"
           >
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.UPCOMING_EVENTS') }}">
                <ng-template matTabContent class="h-100">
                  <div class="flex-row align-space-between align-center pt3">
                    <div><span class="events-manager-title" >{{ t('GENERIC_WRAPPER.EVENTS') }} ({{danceEvents?.length}})</span></div>
                    <div>
                       <df-shared-lib-button 
                         class="hide-on-mobile" 
                         [materialButtonStyleType]="'flat'" 
                         [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')" 
                         (buttonClicked)="navigateToCreatePage()" 
                         id="create-new-event-button"
                       ></df-shared-lib-button>
                    </div>
                  </div>
                  <div *ngIf="(danceEventService.loadingSubject$ | async) === true" class="center mt3">
                    <mat-spinner
                      style="margin: auto"
                      [diameter]="35"
                    ></mat-spinner>
                  </div>
                  <div
                    *ngIf="(danceEventService.loadingSubject$ | async) === false && danceEvents.length > 0"
                    class="mt2"
                  >
                    <div class="h-100">
                      <df-dance-event-list-table 
                        [danceEvents]="danceEvents"
                        [danceEventListTab]="danceEventListTabEnum.UPCOMING"
                        (danceEventDuplicated)="goToTabAndUpdateData()"
                        (danceEventDeleted)="goToTabAndUpdateData(currenTabIndex)">
                      </df-dance-event-list-table>
                    </div>
                    <div class="container">
                      <div class="plus-btn">
                       <df-shared-lib-fab-button (buttonClicked)="navigateToCreatePage()"></df-shared-lib-fab-button>
                      </div>
                     </div>
                  </div>
                  <div
                    *ngIf="(danceEventService.loadingSubject$ | async) === false && danceEvents.length === 0"
                    class="align-center" style="padding-top: 113px;"
                  >
                    <df-shared-empty-page
                      [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')"
                      [emptyTitle]="t('GENERIC_WRAPPER.NO_EVENTS')"
                      [emptyDescription]="t('DANCE_MANAGER_EVENT.NO_EVENTS_TEXT')"
                      [imagePath]="'assets/images/events-manager/no_events.svg'"
                      (buttonClicked)="navigateToCreatePage()"
                    ></df-shared-empty-page>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.PAST_EVENTS') }}">
                <ng-template matTabContent>
                  <div class="flex-row align-space-between align-center pt3">
                    <div><span class="events-manager-title" >{{ t('GENERIC_WRAPPER.EVENTS') }} ({{danceEvents?.length}})</span></div>
                    <div>
                       <df-shared-lib-button 
                         class="hide-on-mobile" 
                         [materialButtonStyleType]="'flat'" 
                         [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')" 
                         (buttonClicked)="navigateToCreatePage()"
                       ></df-shared-lib-button>
                    </div>
                  </div>
                  <div *ngIf="(danceEventService.loadingSubject$ | async) === true" class="center mt3">
                    <mat-spinner
                      style="margin: auto"
                      [diameter]="35"
                    ></mat-spinner>
                  </div>
                  <div
                    *ngIf="(danceEventService.loadingSubject$ | async) === false && danceEvents.length > 0"
                    class="mt3"
                  >
                    <df-dance-event-list-table
                      [danceEvents]="danceEvents"
                      [danceEventListTab]="danceEventListTabEnum.PAST"
                      (danceEventDuplicated)="goToTabAndUpdateData()"
                      (danceEventDeleted)="goToTabAndUpdateData(currenTabIndex)">
                    </df-dance-event-list-table>
                  </div>
                  <div
                    *ngIf="(danceEventService.loadingSubject$ | async) === false && danceEvents.length === 0"
                    class="align-center" style="padding-top: 113px;"
                  >
                    <df-shared-empty-page
                      [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')"
                      [emptyTitle]="t('DANCE_MANAGER_EVENT.NO_PAST_EVENTS')"
                      [emptyDescription]="t('DANCE_MANAGER_EVENT.NO_PAST_EVENTS_TEXT')"
                      [imagePath]="'assets/images/events-manager/no_events.svg'"
                      (buttonClicked)="navigateToCreatePage()"
                    ></df-shared-empty-page>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.DRAFT_EVENTS') }}">
                <ng-template matTabContent>
                  <div class="flex-row align-space-between align-center pt3">
                    <div><span class="events-manager-title" >{{ t('GENERIC_WRAPPER.EVENTS') }} ({{danceEvents?.length}})</span></div>
                    <div>
                      <df-shared-lib-button
                        class="hide-on-mobile" 
                        [materialButtonStyleType]="'flat'" 
                        [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')"
                        (buttonClicked)="navigateToCreatePage()"
                      ></df-shared-lib-button>
                    </div>
                  </div>
                  <div *ngIf="(danceEventService.loadingSubject$ | async) === true" class="center mt3">
                    <mat-spinner
                      style="margin: auto"
                      [diameter]="35"
                    ></mat-spinner>
                  </div>
                  <div
                    *ngIf="(danceEventService.loadingSubject$ | async) === false && danceEvents.length > 0"
                    class="mt3"
                  >
                    <df-dance-event-list-table 
                      [danceEvents]="danceEvents"
                      [danceEventListTab]="danceEventListTabEnum.DRAFT"
                      (danceEventDuplicated)="goToTabAndUpdateData(currenTabIndex)"
                      (danceEventPublished)="goToTabAndUpdateData()"
                      (danceEventDeleted)="goToTabAndUpdateData(currenTabIndex)">
                    </df-dance-event-list-table>
                  </div>
                  <div
                    *ngIf="(danceEventService.loadingSubject$ | async) === false && danceEvents.length === 0"
                    class="align-center" style="padding-top: 113px;"
                  >
                    <df-shared-empty-page
                      [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')"
                      [emptyTitle]="t('DANCE_MANAGER_EVENT.NO_DRAFT_EVENTS')"
                      [emptyDescription]="t('DANCE_MANAGER_EVENT.NO_DRAFT_EVENTS_TEXT')"
                      [imagePath]="'assets/images/events-manager/no_events.svg'"
                      (buttonClicked)="navigateToCreatePage()"
                    ></df-shared-empty-page>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
