export enum TicketOrderStatusEnum {
  OPEN = "OPEN",
  PAYMENT_INITIALIZED = "PAYMENT_INITIALIZED",
  PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PAYMENT_ERROR_REFUND = "PAYMENT_ERROR_REFUND",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAID = "PAID",
  PAYMENT_OVERDUE = "PAYMENT_OVERDUE",
  REFUND = "REFUND",
  REFUND_PARTIAL = "REFUND_PARTIAL"
}
