import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthStateService, CustomMatSnackbarService, dfAppRoute } from '@platri/dfx-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getSubscription } from '@platri/elab-angular-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'elab-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  
  private subscriptions = new Subscription();

  routerParams!: Params;
  returnUrlFromParam: string;
  constructor(
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private matSnackbar: CustomMatSnackbarService,
    private router: Router,
    private authService: AuthStateService
  ) {
  }
  
    initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.route.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }
  
  //
  // openLoginDialog(): void {
  //   const dialogRef = this.matDialog.open(LoginDialogComponent, {
  //     minWidth: '75vw'
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
  
  
}
