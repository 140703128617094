import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfSharedButtonModule } from '../buttons';
import { ImageUploadCropperComponent } from './image-upload-cropper.component';
import { ImageCropperDialogModule } from '../image-cropper-dialog';

@NgModule({
  declarations: [ImageUploadCropperComponent],
  imports: [
    CommonModule,
    ImageCropperModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    ImageCropperDialogModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    DfSharedButtonModule,
  ],
  exports: [ImageUploadCropperComponent],
})
export class ImageUploadCropperModule {}
