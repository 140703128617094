import { DanceManagerSubscriptionInvoiceInterface } from '../../..';
import { DanceManagerSubscriptionDto } from './dance-manager-subscription.dto';

export class DanceManagerSubscriptionInvoiceDto implements DanceManagerSubscriptionInvoiceInterface{
  id: string;
  createdAt: Date;
  updatedAt: Date;
  danceManagerSubscription: DanceManagerSubscriptionDto;
  danceManagerSubscriptionId: string;
  stripeInvoiceId: string;
  stripeInvoicePdfDownloadUrl: string;
  currency: string;
  total: number;
}
