import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { CompleteProfileDialogModule, ProfilePictureDialogModule } from '../../../dialogs';
import { ProfileHeaderComponent } from './profile-header.component';
import { MatDividerModule } from '@angular/material/divider';
import { DfSharedButtonModule, FollowInfoCardModule, FollowInfoDialogModule, ImageFallbackDirectiveModule, PipesModule, UserSocialLinksPreviewModule, UserSocialLinksProfileIconsModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ProfileHeaderComponent],
  exports: [ProfileHeaderComponent],
    imports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      RouterModule,
      MatChipsModule,
      ProfilePictureDialogModule,
      FollowInfoDialogModule,
      FollowInfoCardModule,
      MatSelectModule,
      MatMenuModule,
      MatCardModule,
      MatDividerModule,
      PipesModule,
      MatProgressSpinnerModule,
      UserSocialLinksPreviewModule,
      UserSocialLinksProfileIconsModule,
      CompleteProfileDialogModule,
      ImageFallbackDirectiveModule,
      DfSharedButtonModule,
      TranslocoDirective
    ]
})
export class ProfileHeaderModule {}
