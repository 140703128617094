import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { BreakpointObserver, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HelperStateService, SidenavStateService } from '@platri/dfx-angular-core';

@Component({
  selector: 'dfm-main-content',
  templateUrl: './dfm-main-content.component.html',
  styleUrls: ['./dfm-main-content.component.scss'],
  animations: [
    trigger('sidenavExpand', [
      state('collapsed', style({ width: '68px' })),
      state('expanded', style({ width: '300px' })),
      state('noSmallSidenav', style({ width: '268px' })),
      transition(
        'expanded <=> collapsed',
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('sidenavContentExpand', [
      state('collapsed', style({ marginLeft: '68px' })),
      state('expanded', style({ marginLeft: '300px' })),
      state('noMarginLeft', style({ marginLeft: '0' })),
      transition(
        'expanded <=> collapsed',
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DfmMainContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  @Input() smallSidenav = false;

  year = new Date().getFullYear();

  mobileQuery: MediaQueryList;
  appBannerQuery: MediaQueryList;
  smallScreenQuery: MediaQueryList;
  expanded: boolean;
  appBannerClosed: boolean;
  isMobile = false;

  destroy$: Subject<void> = new Subject<void>();

  private mobileQueryListener: () => void;
  
  constructor(private sidenavService: SidenavStateService, private media: MediaMatcher, private cdRef: ChangeDetectorRef, private helperService: HelperStateService, private breakpointObserver: BreakpointObserver) {
    this.setSideNavRegardingMediaSize();
  }

  ngOnInit(): void {
    this.isMobile = this.helperService.isMobile;
    this.sidenavService
      .getAsyncCurrentExpandedState()
      .pipe(takeUntil(this.destroy$))
      .subscribe((expanded) => {
        this.expanded = expanded;
      });
    this.helperService
      .getAsyncAppBannerClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appBannerClosed) => (this.appBannerClosed = appBannerClosed));
  }

  ngAfterViewInit(): void {
    this.sidenavService.sendCurrentSidenav(this.sidenav);
    // detect screen size changes to toggle sidenav
    this.breakpointObserver
      .observe(['(max-width: 1213px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.collapseSidenav();
          this.cdRef.detectChanges();
        } else {
          this.expandSidenav();
          this.cdRef.detectChanges();
        }
      });
  }

  setSideNavRegardingMediaSize(): void {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
    this.appBannerQuery = this.media.matchMedia('(max-width: 292px)');
    this.smallScreenQuery = this.media.matchMedia('(max-width: 959px)');
    this.mobileQueryListener = (): void => this.cdRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.appBannerQuery.addEventListener('change', this.mobileQueryListener);
    this.smallScreenQuery.addEventListener('change', this.mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.appBannerQuery.removeEventListener('change', this.mobileQueryListener);
    this.smallScreenQuery.removeEventListener('change', this.mobileQueryListener);
    this.sidenavService.clear();
    this.destroy$.next();
    this.destroy$.complete();
  }
  

  collapseSidenav(): void {
    if (this.smallSidenav) {
      this.expanded = false;
      setTimeout(() => this.sidenavService.sendCurrentExpandedState(this.expanded), 300);
    } else {
      this.sidenav.close();
    }
  }

  expandSidenav(): void {
    if (this.smallSidenav) {
      this.expanded = true;
      this.sidenavService.sendCurrentExpandedState(this.expanded);
    } else {
      this.sidenav.open();
    }
  }
}
