import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'df-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
