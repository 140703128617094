import { AmenityInterface, LocationInterface } from '../../..';
import { AddressDto } from './address.dto';

export class AppointmentLocationDto implements LocationInterface {
  id: string;
  name: string;
  address: AddressDto;
  amenities?: AmenityInterface[];
  danceSchoolId: string;
}
