import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { LanguageEditComponent } from './language-edit.component';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [LanguageEditComponent],
  exports: [LanguageEditComponent],
    imports: [
      CommonModule,
      FormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      MatIconModule,
      MatChipsModule,
      MatBadgeModule,
      ReactiveFormsModule,
      DfSharedButtonModule,
      MatAutocomplete,
      MatOption,
      MatAutocompleteTrigger,
      PipesModule,
      TranslocoDirective
    ]
})
export class LanguageEditModule {}
