import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { DfmSettingsPage } from './dfm-settings.page';
import {BreadcrumbComponent, BreadcrumbItemDirective} from 'xng-breadcrumb';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { DmSubscriptionCardModule } from '@platri/dfx-angular-core';
import { DfmGeneralSettingsModule } from '../../components/dfm-general-settings';
import {DfmStripeOnboardingModule} from '../../components/dfm-stripe-onboarding';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmSettingsPage],
    imports: [
      CommonModule,
      RouterOutlet,
      RouterLink,
      RouterLinkActive,
      BreadcrumbComponent,
      MatIconModule,
      MatCardModule,
      MatTabsModule,
      DmSubscriptionCardModule,
      DfmGeneralSettingsModule,
      DfmStripeOnboardingModule,
      BreadcrumbItemDirective,
      TranslocoDirective,
    ],
  exports: [DfmSettingsPage]
})
export class DfmSettingsPageModule {
  constructor() {
    console.log('DfmSettingsPageModule initialized');
  }
}
