import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  Renderer2,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageService } from '../../services';

@Component({
  selector: 'df-shared-lib-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent implements AfterViewInit {
  @Input() resizeToHeight = 512;
  @Input() resizeToWidth = 1024;

  formData: FormData;
  croppedImageAsBase64FromDialog: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fileToCrop: File;
      croppedImageRatio: number;
      allowedImageFileFormatsShort: string[];
    },
    private readonly imageService: ImageService,
    private readonly renderer: Renderer2
  ) {}

  croppedImage(imageCroppedEvent: ImageCroppedEvent) {
    const croppedImageAsBase64 = imageCroppedEvent.base64;
    this.croppedImageAsBase64FromDialog = croppedImageAsBase64;
    this.formData = new FormData();
    this.formData.append(
      'file',
      this.imageService.base64ToFile(croppedImageAsBase64),
      this.data.fileToCrop.name
    );
  }

  ngAfterViewInit() {
    const moveDiv = document.querySelector('.move');
    const icon = this.renderer.createElement('mat-icon');
    this.renderer.appendChild(icon, this.renderer.createText('open_with'));
    this.renderer.addClass(icon, 'material-icons-round');
    this.renderer.addClass(icon, 'mr1');
    const div = this.renderer.createElement('div');
    const span = this.renderer.createElement('span');
    this.renderer.appendChild(
      span,
      this.renderer.createText('PULL TO REPOSITION')
    );
    div.append(icon);
    div.append(span);
    div.id = 'text';
    // @ts-ignore
    moveDiv.appendChild(div);
  }
  submitData() {
    const croppedImageBase64FromDialog = this.croppedImageAsBase64FromDialog;
    const formDataFromDialog = this.formData;

    return { croppedImageBase64FromDialog, formDataFromDialog };
  }
}
