<ng-container *transloco="let t">
  <div class="flex-column w-100 p2" [formGroup]="formGroup">
    <span class="title mb-30">{{t('GENERIC_WRAPPER.DUPLICATE_COURSE.TITLE') }}</span>
    
      <mat-form-field appearance="outline">
        <mat-label>{{t('GENERIC_WRAPPER.NAME') }}</mat-label>
        <input matInput type="text" formControlName="name"/>
      </mat-form-field>
    
    <div class="flex-column gap-10" [formGroup]="formGroup">
    <!--  <span class="input-description-text">{{t('GENERIC_WRAPPER.CREATE_COURSE.INFO.DANCE_STYLES')}}</span>-->
  <!--    <span class="input-description-sub-text">{{t('GENERIC_WRAPPER.CREATE_COURSE.INFO.DANCE_STYLES_SUBTEXT')}}</span>-->
      <div>
        <df-shared-lib-search-dance-style
          [required]="true"
          [selectedFilters]="formGroup.controls.danceStyles.value"
          [previouslySelectedOptions]="formGroup.controls.danceStyles.value"
          (selectedOption)="selectDanceStyle($event)"
        ></df-shared-lib-search-dance-style>
      </div>
      <div *ngIf="formGroup.controls.danceStyles?.value?.length > 0" class="compensate-form-field-padding pb-20">
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let danceStyle of formGroup.controls.danceStyles.value" [removable]="true" [selectable]="false" (removed)="removeSelectedDanceStyle(danceStyle)">
            {{  t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}<mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      
      <span class="input-description-sub-text">{{t('GENERIC_WRAPPER.CREATE_COURSE.INFO.SKILL_LEVEL')}}</span>
      <div class="pad-matformfield">
        <df-shared-lib-level-slider-material
          [showRange]="false"
          [showLevels]="true"
          [fromLevel]="data.startDifficulty"
          [step]="10"
          [toLevel]="data.endDifficulty"
          (setLevelEvent)="setCourseLevel($event)"
        >
        </df-shared-lib-level-slider-material>
      </div>
      
      <mat-checkbox color="primary" formControlName="takeoverPasses" [checked]="formGroup.controls.takeoverPasses.value">
        {{t('GENERIC_WRAPPER.DUPLICATE_COURSE.DUPLICATE_PASSES') }}
      </mat-checkbox>
    </div>
    
    <div class="flex-row align-space-between gap-15 mt-30">
      <df-shared-lib-button class="w-100" [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" (buttonClicked)="closeDialog()" materialButtonStyleType="outlined"></df-shared-lib-button>
      <df-shared-lib-button class="w-100" [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" (buttonClicked)="save()" [isDisabled]="formGroup.invalid" materialButtonStyleType="flat"></df-shared-lib-button>
    </div>
  </div>
</ng-container>
