<ng-container *transloco="let t">
  <div class="flex-column-center-center">
    <div class="flex-column w-100 pb2" style="max-width: 1140px;">
      <mat-card class="appointment-container mat-elevation-z3">
        <div class="flex-column p2 no-padding-mobile">
          <span class="my-appointments-title">{{ t('USER.APPOINTMENTS.TITLE') }}</span>
          <ng-container *ngIf="!isLoading; else loadingSpinner">
            <df-user-appointments-table
              [appointments]="appointments"
              [selectedCourseStatus]="selectedCourseStatus"
              (filterChangeEmitter)="onFilterChange($event)"
              (checkOutEmitter)="onCheckOut($event)"
            ></df-user-appointments-table>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>
  
  <ng-template #loadingSpinner>
    <mat-spinner [diameter]="35"></mat-spinner>
  </ng-template>
</ng-container>
