import { Transform } from 'class-transformer';

const ToBoolean = () => {
  const toPlain = Transform(
    ({ value }) => value,
    {
      toPlainOnly: true,
    }
  );
  const toClass = (target: any, key: string) => Transform(
      ({ obj }) => valueToBoolean(obj[key]),
      {
        toClassOnly: true,
      }
    )(target, key);
  return function (target: any, key: string): void {
    toPlain(target, key);
    toClass(target, key);
  };
};

const valueToBoolean = (value: any): boolean | undefined => {
  if (value === undefined) {
    return undefined;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if (['true', 'on', 'yes', '1'].includes(value?.toLowerCase())) {
    return true;
  }
  if (['false', 'off', 'no', '0'].includes(value?.toLowerCase())) {
    return false;
  }
  return undefined;
};

export { ToBoolean };
