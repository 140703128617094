<ng-container *transloco="let t">
  <div class="relative event-tickets-container flex-column align-start-center w-100">
    <div class="event-background-header hide-on-mobile">
      <img fill="100" [ngSrc]="danceEvent.mainImage?.url ?? DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER" alt=""/>
    </div>
  
    <div class="content-card py2 w-100">
      <div class="w-100 back-btn-container">
        <div class="flex-column align-start hide-on-mobile" (click)="navigateToEventPage()">
          <a class="flex-row align-start back-btn">
            <mat-icon>arrow_back</mat-icon>
            <span class="pl1 m-auto bold">{{ t('GENERIC_WRAPPER.EVENT') }}</span>
          </a>
        </div>
      </div>
      
      <!-- Card Content -->
      <div class="card-container flex-column mt3 w-100" style="gap: 1rem;">
        <div *ngIf="showGuestInputFields" [formGroup]="guestEmailForm" class="flex-column-center-center w-100">
          <span>{{ t('DANCE_EVENT.PLEASE_ENTER_EMAIL_ADDRESS') }}</span>
          <div class="flex-row-center-center w-100 gap-10 pt2">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ t('GENERIC_WRAPPER.EMAIL_CONFORMATION') }}</mat-label>
              <input matInput formControlName="confirmEmail">
            </mat-form-field>
          </div>
          <df-shared-lib-button
            [buttonLabel]="t('GENERIC_WRAPPER.SEND')"
            [isDisabled]="guestEmailForm.invalid || creationTicketOrderLoading"
            (buttonClicked)="continueToCheckout()"
          ></df-shared-lib-button>
        </div>
      
        <!-- Ticket List -->
        <div *ngIf="!showGuestInputFields && tickets && tickets.length > 0 && !showMobileSummary" class="ticket-list-container flex-column" style="gap: 1rem;" [formGroup]="formGroup">
          <div *ngFor="let ticket of tickets; let last = last" class="flex-column" style="gap: 0.75rem;">
            <df-dance-event-ticket-list-item [ticket]="ticket" [form]="formGroup"></df-dance-event-ticket-list-item>
            <mat-divider *ngIf="!last"></mat-divider>
            <div class="hide-on-web" style="padding-bottom: 50px;"></div>
          </div>
        </div>

        <!-- Empty Ticket List -->
        <div *ngIf="!showGuestInputFields && tickets && tickets.length === 0 && danceSchool" class="empty-ticket-list-container flex-column p2" style="gap: 1rem;" [hidden]="showMobileSummary">
          <df-dance-event-ticket-empty-list [danceSchool]="danceSchool" [danceEvent]="danceEvent">
          </df-dance-event-ticket-empty-list>
        </div>
        <!-- Checkout Detail -->
        <div *ngIf="!showGuestInputFields" class="checkout-detail-container">
          <!-- Checkout Summary -->
          <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'" style="margin-bottom: 20px;">
            <df-dance-event-ticket-list-summary
              [selectedTickets]="selectedTickets"
              [form]="formGroup"
              [subtotal]="subtotal"
              [fees]="fees"
              (closeSummary)="showMobileSummary = false"
            ></df-dance-event-ticket-list-summary>
          </div>
          
          <!-- Payment methods -->
          <div [class]="!showMobileSummary ? 'payment-methods-container hide-on-mobile' : 'payment-methods-container'" style="padding: 20px;">
            <div class="flex-row align-space-between" style="margin-bottom: 12px">
              <span><strong>{{ t('GENERIC_WRAPPER.WE_ACCEPT') }}</strong></span>
            </div>
            <df-payment-methods-view></df-payment-methods-view>
          </div>
        
          <!-- Checkout Button -->
          <div class="flex-column-center-center py2 hide-on-mobile">
            <df-shared-lib-button *ngIf="currentUser" class="w-100" [isDisabled]="selectedTickets.length === 0 || creationTicketOrderLoading" [buttonLabel]="t('GENERIC_WRAPPER.CHECKOUT')" (click)="continueToCheckout(true)"></df-shared-lib-button>
            <df-shared-lib-button *ngIf="!currentUser" class="w-100" [isDisabled]="selectedTickets.length === 0 || creationTicketOrderLoading" [buttonLabel]="t('GENERIC_WRAPPER.LOGIN_AND_BUY')" (click)="continueToCheckout(true)"></df-shared-lib-button>
            <span *ngIf="!currentUser" [class]="selectedTickets.length === 0 ? 'continue-as-guest-button-disabled' : 'continue-as-guest-button'" (click)="continueToCheckout()">{{ t('GENERIC_WRAPPER.CONTINUE_AS_GUEST') }}</span>
          </div>
          <div class="py1 px1 hide-on-mobile">
            {{ t('GENERIC_WRAPPER.NEED_HELP') }}
            <a style="text-decoration: none; color: #3377FF" target="_blank" contactUrl>
              {{ t('GENERIC_WRAPPER.CONTACT_US') }}
            </a>
          </div>
        </div>

        <!-- Space For Sticky Button on Mobile-->
        <div class="py3 hide-on-web"></div>
      </div>
    </div>
  </div>
  <!--Sticky-Checkout Button-->
  <div class="flex-column-center-center sticky-tickets-button-container hide-on-web">
    <div class="flex-row align-space-between w-100 pt2 px2">
      <!-- Toggle Summary Button -->
      <div *ngIf="tickets && tickets.length > 0" class="flex-row" style="cursor: pointer" (click)="showMobileSummary = !showMobileSummary">
        <i [class]="selectedTickets.length === 0 ? '' : 'fa badge fa-lg'"><mat-icon style="color: #3377FF">shopping_cart</mat-icon></i>
        <span *ngIf="selectedTickets.length > 0" class="wrapper-span">{{ selectedTickets.length }}</span>

        <span><mat-icon style="color: #4d4d4d" [class]="showMobileSummary ? 'expand-open expand-button' : 'expand-button'">expand_more</mat-icon></span
        >
      </div>

      <!-- Total Price -->
      <span>{{ this.subtotal || 0 | centsToCurrencyString: 'EUR' }}</span>
    </div>

    <!-- Checkout Button -->
    <div class="w-100 px2 pb2 pt1 gap-10 flex-column">
      <df-shared-lib-button *ngIf="currentUser" [isDisabled]="selectedTickets.length === 0" [buttonLabel]="t('GENERIC_WRAPPER.CHECKOUT')" (click)="continueToCheckout(true)"></df-shared-lib-button>
      <df-shared-lib-button *ngIf="!currentUser" [isDisabled]="selectedTickets.length === 0" [buttonLabel]="t('GENERIC_WRAPPER.LOGIN_AND_BUY')" (click)="continueToCheckout(true)"></df-shared-lib-button>
      <df-shared-lib-button *ngIf="!currentUser" [isDisabled]="selectedTickets.length === 0" [buttonLabel]="t('GENERIC_WRAPPER.CONTINUE_AS_GUEST')" (click)="continueToCheckout()"></df-shared-lib-button>
    </div> 
  </div>
</ng-container>
