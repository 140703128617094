import { DanceSchoolInterface } from '../dance-school.interface';
import { UserInterface } from '@platri/elab-common-auth-plug-play';
import { UsersInterface } from '@platri/df-common-core';

export interface MessagePartnerInterface {
  id: string;
  username?: string;
  userId?: string;
  danceSchoolId?: string;
  danceSchool?: DanceSchoolInterface;
  user?: UsersInterface;
  info?: string;
  imageUrl?: string;
  isNamePublic?: boolean;
}
