import { UserTicketDanceRoleTypeEnum } from '../../..';

export class CreateTicketOrderItemRequestDto {
  ticketId: string;
  quantity: number;
  ticketDanceRole: UserTicketDanceRoleTypeEnum;
}
export class CreatePassOrderItemRequestDto {
  passId: string;
  quantity: number;
}
export class CreateTicketOrderRequestDto {
  ticketOrderItems: CreateTicketOrderItemRequestDto[];
  passOrderItems: CreatePassOrderItemRequestDto[];
  userGuestEmail?: string;
  isWeb?: boolean;
}
