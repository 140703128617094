import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { AddNewDmLocationDialogComponent } from './add-new-dm-location-dialog.component';
import { GoogleAddressSearchAutocompleteModule } from '../google-address-search-autocomplete';
import { ConfirmationDialogModule } from '../confirmation-dialog';
import { DfSharedButtonModule } from '../buttons';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [AddNewDmLocationDialogComponent],
  exports: [AddNewDmLocationDialogComponent],
  imports: [
    CommonModule, 
    MatDialogModule, 
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule, 
    MatInputModule, 
    GoogleAddressSearchAutocompleteModule, 
    MatButtonModule,
    MatDividerModule,
    ConfirmationDialogModule,
    MatRadioModule,
    DfSharedButtonModule, 
    TranslocoDirective,
  ],
  providers: []
})
export class AddNewDmLocationDialogModule {}
