import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message.component';
import {PipesModule} from "../../../pipes";

@NgModule({
  declarations: [
    MessageComponent,
  ],
    imports: [
        CommonModule,
        PipesModule,
        PipesModule,
    ],
  exports: [
    MessageComponent,
  ],
})
export class MessageModule {}
