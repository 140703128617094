import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardWithBackgroundComponent } from './card-with-background.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [CardWithBackgroundComponent],
  exports: [CardWithBackgroundComponent],
  imports: [CommonModule, MatCardModule],
})
export class CardWithBackgroundModule {}
