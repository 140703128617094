import { FeesPaidByEnum, TicketSaleStatusEnum, TicketTypeEnum } from '../enums';
import { CurrencyInterface } from './currency.interface';
import { DanceEventsInterface } from './dance-events';

export interface TicketInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  danceEventId?: string;
  currency?: CurrencyInterface;
  taxPercentage?: number;
  price?: number;
  name?: string;
  description?: string;
  quantity?: number;
  soldQuantity?: number;
  availableQuantity?: number;
  saleStart?: Date;
  saleEnd?: Date;
  clientTimezone?: string;
  feesPaidBy?: FeesPaidByEnum;
  sequenceNumber?: number;
  eventTimezone?: string;
  eventStart?: Date;
  eventDuration?: number;
  ticketType?: TicketTypeEnum;
  ticketSaleStatus?: TicketSaleStatusEnum;
  sellAsFollowerLeader?: boolean;
  sellAsFollowerActive?: boolean;
  sellAsLeaderActive?: boolean;
  danceEvent?: DanceEventsInterface;
  danceManagerId?: string;
  danceManagerUserGroupIds?: string[];
}
