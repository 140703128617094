import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, Subject, Subscription, tap } from 'rxjs';
import { AuthStateService, DanceSchoolStateService, WebsocketService } from '../../services';
import { MessageHttpService, PartnerService } from './services';
import { ConversationInterface, ConversationStreamInterface, environmentForWeb, MessagePartnerInterface } from '@platri/df-common-core';
import { UserSearchAutocompleteComponent } from '../user-search-autocomplete';

@Component({
  selector: 'df-shared-lib-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class DfSharedMessagesComponent implements OnInit, OnDestroy {
  @ViewChild(UserSearchAutocompleteComponent)
  userSearchAutoComplete: UserSearchAutocompleteComponent;

  // IMPORTANT: This feature toggle needs to be disabled until the mobile version is finalized
  // @ts-ignore
  enableMultiPartnerChat = environmentForWeb.multiChatEnabled;

  searchInputChangeSubject = new Subject<string>();
  partnerSearchLoading = false;
  userOptions: any[] = [];

  conversations: ConversationInterface[] = [];
  hasMore = false;
  loadingInit = true;
  loadingMore = false;
  form: UntypedFormGroup;
  currentPartnerId: string;
  loadLimit = 10;
  loadMoreLimit = 5;

  conversationPartnerIds: string | null = null;
  
  buttonLabel = 'GENERIC_WRAPPER.MESSAGES_PAGE.SHOW_MORE_MESSAGES';

  readonly unreadMessagesCountUpdated = (): void => {
    this.reload();
  };

  private subscriptions: Subscription = new Subscription();

  constructor(private readonly messageHttpService: MessageHttpService, private formBuilder: UntypedFormBuilder, private router: Router, private authService: AuthStateService, private websocketService: WebsocketService, private danceSchoolService: DanceSchoolStateService, private partnerService: PartnerService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.setCurrentPartner();

    this.subscriptions.add(
      this.activatedRoute.queryParamMap.subscribe((queryParams) => {
        this.conversationPartnerIds = queryParams
          .get('partnerIds')
          ?.split(',')
          .sort((a, b) => a.localeCompare(b))
          .join(',');
      })
    );

    this.subscriptions.add(this.authService.getAsyncCurrentUser().subscribe(() => this.setCurrentPartner()));

    this.subscriptions.add(this.danceSchoolService.getAsyncCurrentDanceSchool().subscribe(() => this.setCurrentPartner()));

    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      user: [null, Validators.required]
    });

    this.loadInit();
    this.subscribeToSearch();

    const socket = this.websocketService.getSocket();

    socket.on('unreadMessagesCountUpdated', this.unreadMessagesCountUpdated);
    //this.reload();
  }

  setCurrentPartner(): void {
    this.currentPartnerId = this.partnerService.getCurrentPartnerId(this.authService.getSyncCurrentUser(), this.danceSchoolService.getSyncCurrentDanceSchool());
  }

  ngOnDestroy(): void {
    const socket = this.websocketService.getSocket();
    if (socket) {
      socket.off('unreadMessagesCountUpdated', this.unreadMessagesCountUpdated);
    }
    this.subscriptions?.unsubscribe();
  }

  loadInit(): void {
    this.conversations = [];
    this.hasMore = false;
    this.loadingInit = true;
    this.loadingMore = false;
    
    this.subscriptions.add(
      this.messageHttpService.getConversationsWithLastMessage(this.currentPartnerId, this.loadLimit + 1)
        .subscribe({
          next: (data) => {
            const hasMore = !!data.conversations[this.loadLimit];
            const conversationsFiltered = data.conversations;
            if (hasMore) {
              conversationsFiltered.splice(-1, 1);
            }

            this.conversations = conversationsFiltered;
            this.hasMore = hasMore;
            //this.reload();
          },
          error: (err) => {
            this.loadingInit = false;
            console.log(err);
          },
          complete: () => {
            this.loadingInit = false;
          }
        })
    );
  }

  loadMore(): void {
    console.log('loadMore');
    if (!this.loadingMore) {
      this.loadingMore = true;

      const conversation = this.conversations[this.conversations.length - 1];
      const lastMessageCreated = conversation.messages[0].createdAt;
      this.loadLimit += this.loadMoreLimit;

      this.subscriptions.add(
        this.messageHttpService.getConversationsWithLastMessage(this.currentPartnerId, this.loadMoreLimit + 1, lastMessageCreated)
          .subscribe({
            next: (data: ConversationStreamInterface) => {
              const hasMore = !!data.conversations[this.loadMoreLimit];
              const conversationsFiltered = data.conversations;
              if (hasMore) {
                conversationsFiltered.splice(-1, 1);
              }

              this.conversations = this.conversations.concat(conversationsFiltered);
              this.hasMore = hasMore;
            },
            error: (err) => console.log(err),
            complete: () => {
              this.loadingMore = false;
            }
          })
      );
    }
  }

  reload(): void {
    console.log('reload');
    this.subscriptions.add(
      this.messageHttpService.getConversationsWithLastMessage( this.currentPartnerId, this.loadLimit + 1)
        .subscribe((data: ConversationStreamInterface) => {
          const hasMore = !!data.conversations[this.loadLimit];
          const conversationsFiltered = data.conversations;
          if (hasMore) {
            conversationsFiltered.splice(-1, 1);
          }
          this.conversations = conversationsFiltered;
          this.hasMore = hasMore;
      })
    );
  }

  openConversationByPartner(): void {
    const option: any = this.form.controls.user.value;
    if (!option) {
      this.form.controls.user.setErrors({ emptyField: true });
    } else {
      this.subscriptions.add(
        this.messageHttpService
          .searchPartner(option.value)
          .subscribe({
            next: (partner: MessagePartnerInterface) => {
              if (partner.id === this.currentPartnerId) {
                this.form.controls.username.setErrors({
                  notAllowedToSendMessagesToOwnAccount: true
                });
              } else {
                this.router.navigate([], {
                  queryParams: { partnerIds: partner.id },
                  relativeTo: this.activatedRoute
                });
              }
            },
            error: (err) => {
              if (err.status === 404) {
                this.form.controls.user.setErrors({ userNotFound: true });
              }
            }
          })
      );
    }
  }

  get user(): AbstractControl {
    return this.form.get('user');
  }

  isActive(conversation: ConversationInterface): boolean {
    return (
      this.conversationPartnerIds &&
      conversation.partnerIds
        .split(',')
        .filter((partnerIdEntry) => partnerIdEntry !== this.currentPartnerId)
        .join(',') === this.conversationPartnerIds
    );
  }

  setSelectedUser($event: any): void {
    this.form.controls.user.setValue($event);
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }

  subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject
        .pipe(
          tap(() => (this.partnerSearchLoading = true)),
          debounceTime(500)
        )
        .subscribe((inputText) => {
          if (inputText) {
            this.messageHttpService
              .searchPartners(inputText)
              .pipe(
                tap(() => {
                  this.partnerSearchLoading = false;
                }),
              )
              .subscribe((result) => {
                this.userOptions = this.mapToOptions(result.filter((obj) => obj.id !== this.currentPartnerId));
              });
          } else {
            this.userOptions = [];
            this.partnerSearchLoading = false;
          }
        })
    );
  }

  private mapToOptions(partners: MessagePartnerInterface[]) {
    return partners.map(
      (partner) =>
        ({
          id: partner.id,
          value: partner.username,
          displayValue: partner.username,
          info: partner.id.includes('dance-school-') ? 'Tanzschule' : (partner.info || ''),
          imageUrl: partner.imageUrl ?? null,
          isDanceSchool: partner.id.includes('dance-school-')
        } as any)
    );
  }
}
