import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogInterface,
  DanceManagerUserRoleEnum,
  DanceSchoolInterface,
  DanceSchoolToUserInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  hasAccessToDanceManagerFromTokenRolesByIdAndRoles
} from '@platri/df-common-core';
import {
  AuthStateService,
  ConfirmationDialogComponent,
  DanceManagerService,
  DanceSchoolToUserStateService,
  DfmStateService
} from '@platri/dfx-angular-core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DfmEditRoleDialogComponent } from '../dfm-edit-role-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-roles-table',
  templateUrl: './dfm-roles-table.component.html',
  styleUrls: ['./dfm-roles-table.component.scss'],
})
export class DfmRolesTableComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  danceSchoolToUsers: DanceSchoolToUserInterface[];

  displayedColumns = [
    'username',
    'userFirstName',
    'userLastName',
    'role',
    'isPublic',
    'options',
  ];
  dataSource: MatTableDataSource<DanceSchoolToUserInterface>;

  danceSchool: DanceSchoolInterface;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  
  isOwnerOrAdmin = false;

  constructor(
    public dialog: MatDialog,
    public danceSchoolToUserService: DanceSchoolToUserStateService,
    private translocoService: TranslocoService,
    private dfmStateService: DfmStateService,
    private danceManagerService: DanceManagerService,
    private authService: AuthStateService
  ) {}

  ngOnInit(): void {
    this.getDanceSchool();
  }

  getDanceSchool(): void {
    this.dfmStateService
      .getAsyncSelectedDm()
      .subscribe((danceSchool) => {
        if (danceSchool) {
          this.danceSchool = danceSchool;
          this.getAllDanceSchoolToUsersByDanceSchoolId(danceSchool.id);
          this.isOwnerOrAdmin = hasAccessToDanceManagerFromTokenRolesByIdAndRoles(danceSchool.id, this.authService.getSyncCurrentUser().roles.danceSchool, [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]);
          if (!this.isOwnerOrAdmin) {
            this.displayedColumns = [
              'username',
              'userFirstName',
              'userLastName',
              'role',
              'isPublic'
            ];
          }
        }
      });
  }

  getAllDanceSchoolToUsersByDanceSchoolId(danceSchoolId: string): void {
    this.danceSchoolToUserService
      .getAsyncCurrentDanceSchoolToUsers()
      .subscribe((danceSchoolToUsers) => {
        this.danceSchoolToUsers = danceSchoolToUsers;
        this.dataSource = new MatTableDataSource(this.danceSchoolToUsers);
        this.dataSource.sort = this.sort;
      });

    this.danceSchoolToUserService.getAllDanceSchoolToUsersByDanceSchoolId(
      danceSchoolId
    );
  }

  editDanceSchoolToUser(danceSchoolToUser: DanceSchoolToUserInterface): void {
    const userToEdit = {
      user: danceSchoolToUser.user,
      role: danceSchoolToUser.danceSchoolUserRole,
    };
    this.dialog
      .open(DfmEditRoleDialogComponent, {
        minWidth: '300px',
        height: '271px',
        data: userToEdit,
      })
      .afterClosed()
      .subscribe((newRole) => {
        if (newRole) {
          this.danceSchoolToUserService.updateDanceSchoolToUser(
            {
              ...danceSchoolToUser,
              danceSchool: this.danceSchool,
            },
            newRole
          );
          this.danceManagerService.getDanceManagerById(this.danceSchool.id);
        }
      });
  }

  deleteDanceSchoolToUser(danceSchoolToUser: DanceSchoolToUserInterface): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.REMOVE'),
      title: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.TEAM_REMOVE_TITLE'),
      text: this.translocoService.translate('DANCE_MANAGER_ADMINISTRATION.DELETE_DIALOG.TEAM_REMOVE_CONFIRMATION', {
        username: danceSchoolToUser.user!.username,
      }
    )};
    this.dialog
      .open(ConfirmationDialogComponent, {
        minWidth: '300px',
        data: confirmationDialogData,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.danceSchoolToUserService.deleteDanceSchoolToUser({
            ...danceSchoolToUser,
            danceSchool: this.danceSchool,
          });
          this.danceManagerService.getDanceManagerById(this.danceSchool.id);
        }
      });
  }

  onIsPublicToggle(event: MatSlideToggleChange, danceSchoolToUser: DanceSchoolToUserInterface): void {
    this.danceSchoolToUserService.updateDanceSchoolToUser(
      {
        ...danceSchoolToUser,
        danceSchool: this.danceSchool,
        isPublic: event.checked,
      },
      danceSchoolToUser.danceSchoolUserRole
    );
  }
}
