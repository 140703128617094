import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ProfileHttpService, UserHttpService } from '../http-services';
import { AuthStateService } from './auth-state.service';
import { UserDto, UsersDanceStylesDto, UsersInterface, UsersLanguagesInterface } from '@platri/df-common-core';
import { UserStateService } from './user-state.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileStateService {
  private userProfileSubject: BehaviorSubject<UsersInterface> = new BehaviorSubject<UsersInterface>(null);

  private isCurrentUser = false;
  

  constructor(private userStateService: UserStateService, private readonly userProfileHttpService: ProfileHttpService, private readonly router: Router, private readonly authService: AuthStateService, private readonly userHttpService: UserHttpService) {}

  public getAsyncUserProfile(): Observable<UsersInterface> {
    return this.userProfileSubject.asObservable();
  }

  public getSyncCurrentUserProfile(): UsersInterface {
    return this.userProfileSubject.getValue();
  }

  public getIsCurrentUser(): boolean {
    return this.isCurrentUser;
  }

  sendUserProfileChanges(user: UsersInterface): void {
    this.userProfileSubject.next(user);
    const currentUser = this.authService.getSyncCurrentUser();
    if (currentUser && user?.username === currentUser.username) {
      this.authService.sendCurrentUserChanges({
        ...this.authService.getSyncCurrentUser(),
        ...user
      });
    }
  }

  public getProfileByUsername(username: string): void {
    this.userHttpService.getUserByUsername(username).subscribe({
      next: (profile) => {
        if (profile) {
          if (this.authService.getSyncCurrentUser()) {
            this.isCurrentUser = profile.id === this.authService.getSyncCurrentUser().id;
          }
          this.sendUserProfileChanges(profile);
        }
      },
      error: (error) => {
        if (error.status === 404) {
          this.router.navigate(['/404']);
        }
      }
    });
  }

  public updateProfilePicture(formData: FormData): Observable<UsersInterface> {
    return this.userProfileHttpService.postProfilePicture(formData).pipe(
      tap((user) => {
        const profile = this.getSyncCurrentUserProfile();
        profile.imageUrl = user.imageUrl;
        this.sendUserProfileChanges(profile);
      })
    );
  }

  public deleteProfilePicture(): void {
    this.userProfileHttpService.deleteProfilePicture().subscribe((user) => {
      const profile = this.getSyncCurrentUserProfile();
      profile.imageUrl = user.imageUrl;
      this.sendUserProfileChanges(profile);
    });
  }

  //TODO: When userProfileHttpService.updateUserInformationHttp was changed to UserDto, fix this method and where it is used
  public updateUserProfileLanguages(languages: UsersLanguagesInterface[]): void {
    this.userProfileHttpService.putProfileLanguagesByUser(languages).subscribe((res) => {
      const userProfile = this.getSyncCurrentUserProfile();
      userProfile.languageSkills = res;
      this.sendUserProfileChanges(userProfile);
    });
  }

  public updateUserProfileDanceStyles(danceStyles: UsersDanceStylesDto[]): void {
    this.userProfileHttpService.putProfileDanceStylesByUser(danceStyles).subscribe((res) => {
      const userProfile = this.getSyncCurrentUserProfile();
      userProfile.danceStyles = res;
      this.sendUserProfileChanges(userProfile);
    });
  }

  //TODO: When userProfileHttpService.updateUserInformationHttp was changed to UserDto, fix this method and where it is used
  public updateUserProfileLookingFor(lookingFor: string[]): void {
    const update = { lookingFor };
    this.userProfileHttpService.updateUserInformation(update).subscribe((res) => {
      const userProfile = this.getSyncCurrentUserProfile();
      userProfile.lookingFor = res.lookingFor;
      this.sendUserProfileChanges(userProfile);
    });
  }

  public updateUserProfilePhoneNumber(update: UserDto): void {
    this.userProfileHttpService.updateUserInformation(update).subscribe((res) => {
      this.sendUserProfileChanges(res);
    });
  }

  //TODO: When userProfileHttpService.updateUserInformationHttp was changed to UserDto, fix this method and where it is used
  public updateShowCompleteProfileDialog(user: UsersInterface): void {
    this.userProfileHttpService.updateUserInformation(user).subscribe((updatedUser: UsersInterface) => {
      },
      (error) => {
      console.error('Error updating user:', error);       
    });
  }

  clear(): void {
    this.userProfileSubject.next(null);
    this.isCurrentUser = false;
  }
}
