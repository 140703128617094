<ng-container *transloco="let t">
  <div class="flex-column mt2">
     <div class="flex-row align-start-center mt1">
        <h2>
           {{t('GENERIC_WRAPPER.CREATE_NEW_COURSE')}}{{ course ? ': ' : null }}
        </h2>
        <span *ngIf="course" class="ml1">
        {{ course.name }}
        </span>
     </div>
     <mat-divider inset class="w-100" style="margin-left: 0 !important;" ></mat-divider>
     <div class="flex-column" *ngIf="mode === 'MANAGE'">
        <div class="w-100 flex-row align-end-center mb2">
           <button
             class="btn-outlined"
             mat-button
             (click)="onNewAppointmentClick.emit()"
           >
             <span style="vertical-align: middle">{{t('DANCE_MANAGER_COURSE.STEPPER.NEW_APPOINTMENT')}}</span>
           </button>
        </div>
        <df-course-appointments-table
          [filteredAppointments]="course?.appointments"
        ></df-course-appointments-table>
     </div>
     <div
       class="flex-column mb2 form-container"
       *ngIf="mode === 'EDIT'"
       [formGroup]="appointmentFormGroup"
     >
     <!-- location selection -->
     <div class="flex-column mb1 mt2">
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.SELECT_LOCATION')}}</strong>
        <mat-radio-group
          (change)="changeAddressInput($event.value)"
          class="flex-column mb1"
        >
           <mat-radio-button color="primary" value="location" [checked]="danceSchool.locations.length !== 0" [disabled]="danceSchool.locations.length === 0" style="padding-bottom: 16px;">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.EXISTING_LOCATION')}}
           </mat-radio-button>
          <!-- normal location -->
           <mat-form-field
             appearance="outline"
             *ngIf="addressInputType === 'location' || danceSchool.locations.length === 0"
             hideRequiredMarker="{{danceSchool.locations.length === 0}}"
           >
              <mat-label>{{t('DANCE_MANAGER_COURSE.' + (danceSchool.locations.length === 0 ? 'NO_LOCATIONS' : 'SELECT'))}}
              </mat-label>
              <mat-select formControlName="locationId" [disableControl]="addressInputType === 'manual'">
                 <mat-option
                   *ngFor="let location of danceSchool.locations"
                   [value]="location.id"
                   (click)="onLocationSelected(location)"
                 >
                  {{ location.name }}
                 </mat-option>
              </mat-select>
           </mat-form-field>
           <mat-radio-button color="primary" value="manual" [checked]="danceSchool.locations.length === 0" style="padding-bottom: 16px;">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MANUAL_ADDRESS')}}
           </mat-radio-button>
        </mat-radio-group>
       <!-- manual adress -->
        <df-shared-lib-google-address-search-autocomplete
          *ngIf="addressInputType === 'manual'"
          class="mb1"
          [alternativeDesign]="true"
          [address]="appointmentFormGroup.controls.address.value"
          (addressSelected)="onAddressSelected($event)"
          [types]="['address']"
        >
        </df-shared-lib-google-address-search-autocomplete>
     </div>
     <!-- select room if chosen location has rooms -->
     <div class="flex-column mb1">
        <strong class="fs16 mb1">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.ROOM')}}</strong>
        <span class="fs14 subtitle mb1">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.ROOM_SUBTEXT')}}</span>
        <mat-form-field appearance="outline">
           <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.SELECT_ROOM')}}
           </mat-label
           >
           <mat-select formControlName="roomId">
              <mat-option
                *ngFor="let room of selectedLocation.rooms"
                [value]="room.id"
              >{{ room.name }}</mat-option
              >
           </mat-select>
        </mat-form-field>
     </div>
     <!-- teacher selection -->
     <div class="flex-column mb1">
        <strong class="fs16 mb1">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TEACHERS')}}</strong>
        <mat-form-field appearance="outline">
           <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TEACHERS')}}
           </mat-label>
           <mat-select formControlName="teacherUserIds" multiple>
              <mat-option
                *ngFor="let teacher of danceSchoolUsers"
                [value]="teacher.id"
              >
              {{
                teacher.firstName
                  ? teacher.firstName + ' ' + teacher.lastName
                  : teacher.username
                }}</mat-option
              >
           </mat-select>
           <mat-error *ngIf="appointmentFormGroup.controls.teacherUserIds.hasError('required')">
             {{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TEACHERS_ERROR_REQUIRED')}}
           </mat-error>
        </mat-form-field>
     </div>
     <!-- choose single appointment or scheduler setup -->
     <div class="flex-column mb1">
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.APPOINTMENT_TYPE')}}</strong>
        <mat-radio-group
          class="flex-column mb1"
          formControlName="schedulerType"
        >
           <mat-radio-button
             color="primary"
             [value]="SchedulerTypeEnum.SINGLE"
             [checked]="true"
           >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.SCHEDULER_SINGLE')}}</mat-radio-button
           >
           <mat-radio-button color="primary" [value]="SchedulerTypeEnum.SERIES">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.SCHEDULER_SERIES')}}
           </mat-radio-button>
        </mat-radio-group>
       <!-- start date -->
        <mat-form-field appearance="outline">
           <mat-label>{{t('GENERIC_WRAPPER.DATE')}}
           </mat-label>
           <input
             matInput
             [matDatepicker]="picker"
             readonly
             id="date-start"
             (click)="picker.open()"
             formControlName="startDate"
             [min]="today"
           />
           <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
           <mat-datepicker #picker></mat-datepicker>
           <button
             mat-icon-button
             matSuffix
             aria-label="Clear"
             type="button"
             (click)="appointmentFormGroup.controls.startDate.reset()"
           >
              <mat-icon>close</mat-icon>
           </button>
           <mat-error
             *ngIf="appointmentFormGroup.controls.startDate.hasError('required')"
           >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_DATE_ERROR_REQUIRED')}}
           </mat-error
           >
        </mat-form-field>
       <!-- start and end times -->
        <div
          class="mb1 time-picker-mobile align-space-between-center" 
        >
           <df-shared-lib-time-picker
             [label]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_TIME')"
             class="w-100"
             [passedFormGroup]="appointmentFormGroup"
             passedFormControlName="startTime"
           ></df-shared-lib-time-picker>
           <df-shared-lib-time-picker
             [label]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_TIME')"
             class="w-100"
             [passedFormGroup]="appointmentFormGroup"
             passedFormControlName="endTime"
           ></df-shared-lib-time-picker>
        </div>
       <!-- series scheduler setup -->
        <mat-form-field
          appearance="outline"
          *ngIf="
           appointmentFormGroup.controls.schedulerType.value ==
           SchedulerTypeEnum.SERIES
           "
        >
           <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.INTERVAL')}}
           </mat-label>
           <mat-select formControlName="recurrenceType">
              <mat-option
                *ngFor="let type of AppointmentRecurrenceTypeEnum | keyvalue"
                [value]="type.value"
              >{{
                type.value
                  | formatInterval
                  : null
                    : null
                    : null
                    : (appointmentFormGroup.controls.startDate.value
                    ? appointmentFormGroup.controls.startDate.value.toDate()
                    : null)
                }}</mat-option
              >
           </mat-select>
        </mat-form-field>
       <!-- end date for all scheduler types except custom -->
        <mat-form-field
          appearance="outline"
          *ngIf="
           appointmentFormGroup.controls.schedulerType.value ==
           SchedulerTypeEnum.SERIES &&
           appointmentFormGroup.controls.recurrenceType.value !==
           AppointmentRecurrenceTypeEnum.CUSTOM
           "
        >
           <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_DATE')}}
           </mat-label>
           <input
             matInput
             [matDatepicker]="picker"
             readonly
             id="date-end"
             (click)="picker.open()"
             formControlName="endDate"
             [min]="appointmentFormGroup.controls.startDate.value"
           />
           <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
           <mat-datepicker #picker></mat-datepicker>
           <button
             matSuffix
             mat-icon-button
             aria-label="Clear"
             type="button"
             (click)="appointmentFormGroup.controls.endDate.reset()"
           >
              <mat-icon>close</mat-icon>
           </button>
           <mat-error
             *ngIf="appointmentFormGroup.controls.endDate.hasError('required')"
           >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_DATE_ERROR_REQUIRED')}}
           </mat-error
           >
        </mat-form-field>
        <strong
          class="fs14 subtitle"
          *ngIf="
           appointmentFormGroup.controls.schedulerType.value ===
           SchedulerTypeEnum.SERIES &&
           appointmentFormGroup.controls.recurrenceType.value ===
           AppointmentRecurrenceTypeEnum.CUSTOM
           "
        >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.REPEAT')}}</strong
        >
        <div
          class="mb1 mobile-align align-space-between-center" 
          *ngIf="
           appointmentFormGroup.controls.schedulerType.value ===
           SchedulerTypeEnum.SERIES &&
           appointmentFormGroup.controls.recurrenceType.value ===
           AppointmentRecurrenceTypeEnum.CUSTOM
           "
        >
           <mat-form-field
             appearance="outline"
             class="number-input-formfield"
             ngClass.lt-md="w-100"
           >
              <input
                dfNoScroll
                placeholder="1"
                min="1"
                matInput
                type="number"
                formControlName="customRecurrenceNumber"
              />
              <mat-error
                *ngIf="
                 appointmentFormGroup.controls.customRecurrenceNumber.hasError(
                 'required'
                 )
                 "
              >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}</mat-error
              >
           </mat-form-field>
           <mat-form-field appearance="outline" ngClass.lt-md="w-100">
              <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.CYCLE')}}
              </mat-label>
              <mat-select formControlName="customRecurrenceType">
                 <mat-option
                   *ngFor="let recurrenceType of CustomRecurrenceTypeEnum | keyvalue"
                   [value]="recurrenceType.value"
                 >
                 {{ t(recurrenceType.value + 'S') }}
                 </mat-option>
              </mat-select>
           </mat-form-field>
           <mat-form-field
             appearance="outline"
             *ngIf="
              appointmentFormGroup.controls.customRecurrenceType.value ===
              CustomRecurrenceTypeEnum.WEEK
              "
             ngClass.lt-md="w-100"
           >
              <mat-label>{{t('DANCE_MANAGER_COURSE.WEEKDAYS')}}
              </mat-label>
              <mat-select
                formControlName="customRecurrenceWeekDays"
                disableOptionCentering
                multiple
              >
                 <mat-option
                   *ngFor="let weekday of WeekdaysStringEnum | keyvalue: ascOrder"
                   [value]="weekday.value"
                 >{{ weekday.value }}</mat-option
                 >
              </mat-select>
           </mat-form-field>
        </div>
        <strong
          class="fs14 subtitle"
          *ngIf="
           appointmentFormGroup.controls.schedulerType.value ===
           SchedulerTypeEnum.SERIES &&
           appointmentFormGroup.controls.recurrenceType.value ===
           AppointmentRecurrenceTypeEnum.CUSTOM
           "
        >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.INTERVAL_ENDS')}}</strong
        >
        <mat-radio-group
          class="mb1 flex-column"
          *ngIf="
           appointmentFormGroup.controls.schedulerType.value ===
           SchedulerTypeEnum.SERIES &&
           appointmentFormGroup.controls.recurrenceType.value ===
           AppointmentRecurrenceTypeEnum.CUSTOM
           "
        >
           <mat-radio-button color="primary" [value]="'DATE'" [checked]="true">
           <div class="flex-row align-start-center mb1">
              <span>Date</span>
              <mat-form-field
                appearance="outline"
                class="form-field-padding-override"
              >
                 <mat-label>End Date</mat-label>
                 <input
                   matInput
                   [matDatepicker]="picker"
                   readonly
                   id="date-end-custom"
                   (click)="picker.open()"
                   formControlName="endDate"
                   [min]="appointmentFormGroup.controls.startDate.value"
                 />
                 <mat-datepicker-toggle
                   matSuffix
                   [for]="picker"
                 ></mat-datepicker-toggle>
                 <mat-datepicker #picker></mat-datepicker>
                 <button
                   matSuffix
                   mat-icon-button
                   aria-label="Clear"
                   type="button"
                   (click)="appointmentFormGroup.controls.endDate.reset()"
                 >
                    <mat-icon>close</mat-icon>
                 </button>
              </mat-form-field>
           </div>
           </mat-radio-button>
           <mat-radio-button color="primary" [value]="'NUMBER_OF_APPOINTMENTS'">
              <div class="flex-row align-start-center mb1">
                 <span>After</span>
                 <mat-form-field
                   appearance="outline"
                   class="number-input-formfield form-field-padding-override"
                 >
                    <input
                      dfNoScroll
                      placeholder="1"
                      min="1"
                      matInput
                      type="number"
                      formControlName="numberOfAppointments"
                    />
                    <mat-error
                      *ngIf="
                       appointmentFormGroup.controls.numberOfAppointments.hasError(
                       'required'
                       )
                       "
                    >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}
                    </mat-error
                    >
                 </mat-form-field>
                 <span>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.APPOINTMENTS')}}</span>
              </div>
           </mat-radio-button>
        </mat-radio-group>
     </div>
     <div class="flex-column mb1">
        <strong class="fs16 mb1">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MAX_PARTICIPANTS')}}</strong>
        <span class="fs14 subtitle mb1">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MAX_PARTICIPANTS_SUBTEXT')}}</span>
        <mat-form-field appearance="outline" class="number-input-formfield">
           <input
             dfNoScroll
             placeholder="1"
             min="1"
             matInput
             type="number"
             formControlName="maxParticipants"
           />
           <mat-error
             *ngIf="
              appointmentFormGroup.controls.maxParticipants.hasError('required')
              "
           >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}</mat-error
           >
        </mat-form-field>
     </div>
     <div
       class="flex-column mb1"
       *ngIf="appointmentFormGroup.controls.description as editorControl"
     >
        <strong class="fs16 mb1">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.ADDITIONAL_INFO')}}</strong>
        <df-shared-lib-quill-editor
          [hasError]="
        editorControl.hasError('maxlength') && editorControl.touched
        "
          [disableRichText]="true"
          [enableMentions]="true"
          [enableCounter]="true"
          [maxLength]="2000"
          [id]="'description'"
          [placeholder]="'DANCE_MANAGER_COURSE.APPOINTMENTS.ADDITIONAL_INFO'"
          customFormControlName="description"
        ></df-shared-lib-quill-editor>
        <mat-error
          *ngIf="editorControl.hasError('maxlength')"
          class="custom-error"
        >{{t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH')}}
        </mat-error>
     </div>
  </div>
  <div class="w-100 align-space-between-center">
     <df-shared-lib-button
       materialButtonStyleType="outlined"
       [buttonLabel]="t('GENERIC_WRAPPER.BACK')"
       (buttonClicked)="onBackClick.emit()"
     ></df-shared-lib-button>
     <div class="w-100 align-end-center mb2">
        <df-shared-lib-button
          *ngIf="mode === 'EDIT'"
          class="mr1"
          materialButtonStyleType="outlined"
          [buttonLabel]="t('DANCE_MANAGER_COURSE.STEPPER.SKIP')"
          (buttonClicked)="onSkipClick.emit()"
        ></df-shared-lib-button>
        <df-shared-lib-button
          [buttonLabel]="t('DANCE_MANAGER_COURSE.STEPPER.' + (mode === 'EDIT' ? 'CONTINUE' : 'FINISH'))"
          (buttonClicked)="onContinueClick.emit()"
          [isDisabled]="mode === 'EDIT' && !appointmentFormGroup.valid"
          [loading]="loading"
        ></df-shared-lib-button>
     </div>
  </div>
  </div>
</ng-container>
