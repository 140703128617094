import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CoursesHttpService,
  CoursesStateService,
  DanceManagerService,
  DanceSchoolStateService,
  getSubscription,
  PassesStateService
} from '@platri/dfx-angular-core';
import { DanceSchool } from '@platri/df-common-shared-graphql';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CourseInterface,
  DanceManagerFeatureInterface,
  DanceManagerFeatureNameEnum,
  DfmCoursePassesTableInterface,
  environmentForWeb,
  PassStatusEnum
} from '@platri/df-common-core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'df-courses-manager',
  templateUrl: './dfm-courses.component.html',
  styleUrls: ['./dfm-courses.component.scss']
})
export class DfmCoursesComponent implements OnInit, OnDestroy {
  danceSchool: DanceSchool;
  currentTabIndex = 0;

  page = 1;
  
  passesFeatureActive = false;
  subscriptionFeatureActive = false;
  
  danceManagerFeatureName = DanceManagerFeatureNameEnum;
  subscriptions: Subscription = new Subscription();

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;
  
  constructor(
    private readonly danceSchoolService: DanceSchoolStateService, 
    private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    private readonly danceManagerService: DanceManagerService,
    public readonly coursesService: CoursesStateService,
    public readonly passesService: PassesStateService
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      switch(fragment) {
        case "courses":
          this.currentTabIndex = 0;
          break;
        case "passes":
          this.currentTabIndex = 1;
          break;
        case "subscriptions":
          this.currentTabIndex = 2;
          break;
        default:
          this.currentTabIndex = 0;
          break;
      }
    });
    this.initializeSubscriptions();
  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.danceSchoolService.getAsyncCurrentDanceSchool(), this.onDanceSchoolChangeSuccessful.bind(this)));
    this.subscriptions.add(getSubscription(this.danceManagerService.getAsyncDanceManagerFeatures(), this.onDanceManagerFeaturesChange.bind(this)));
  }

  onDanceSchoolChangeSuccessful(danceSchool: DanceSchool): void {
    this.danceSchool = danceSchool;
  }
  
  onDanceManagerFeaturesChange(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.passesFeatureActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.COURSE_PASSES);
    this.subscriptionFeatureActive = !!danceManagerFeatures.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.COURSE_SUBSCRIPTION);
  }

  resetPagination(): void {
    this.page = 1;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currentTabIndex = event.index;
    switch (event.index) {
      case 0:
        console.log("load data of courses");
        this.router.navigate([], { fragment: 'courses' });
        break;
      case 1:
        console.log("load data of passes");
        this.router.navigate([], { fragment: 'passes' });
        break;
      case 2:
        console.log("load data of pass subscriptions");
        this.router.navigate([], { fragment: 'subscriptions' });
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
