import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialogInterface, DfmCoursePassesTableInterface, DmPassSubscriptionInterface, PassStatusEnum } from '@platri/df-common-core';
import { CoursesHttpService, DanceSchoolStateService, DmPassSubscriptionHttpService, PassSubscriptionStateService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslocoService } from '@jsverse/transloco';
import { getSubscription } from '../../helpers';

@Component({
  selector: 'df-course-subscription-table',
  templateUrl: './course-subscription-table.component.html',
  styleUrls: ['./course-subscription-table.component.scss'],
})
export class CourseSubscriptionTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) table: MatTable<DfmCoursePassesTableInterface>;
  
  @Output() passSubscriptionDeleted = new EventEmitter<void>();
  @Output() editSubscriptionClicked = new EventEmitter<string>();
  @Output() duplicateSubscriptionClicked = new EventEmitter<string>();
  
  passSubscriptions: DmPassSubscriptionInterface[] = [];
  
  displayedColumns = ['position', 'name', 'durationValue', 'weeklyUsage', 'price', 'targets', 'options'];
  dataSource: MatTableDataSource<DmPassSubscriptionInterface>;
  dragDisabled = true;
  expandedElement: DmPassSubscriptionInterface | null;
  expendedCoursesElement: DmPassSubscriptionInterface | null;
  
  passSubscriptionAccessTargetNames: { 
    id: string; 
    name: string;
  }[];
  
  subscriptions = new Subscription();
  
  constructor(
    private coursesHttpService: CoursesHttpService,
    private dmPassSubscriptionHttpService: DmPassSubscriptionHttpService,
    private passSubscriptionStateService: PassSubscriptionStateService,
    private danceSchoolStateService: DanceSchoolStateService,
    private translocoService: TranslocoService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   this.fetchAccessTargetNames();
    //   this.updateDataSource();
    // });
    this.loadData();
  }
  
  loadData(): void {
    this.dmPassSubscriptionHttpService.getAllByDanceManagerId(this.danceSchoolStateService.getSyncCurrentDanceSchool().id).subscribe({
      next: (data) => {
        this.passSubscriptions = data;
        this.fetchAccessTargetNames();
        this.updateDataSource();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.passSubscriptions && !changes.passSubscriptions.firstChange) {
      this.fetchAccessTargetNames();
      this.updateDataSource();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchAccessTargetNames(): void {
    const allTargetIds = Array.from(
      this.passSubscriptions.flatMap(sub => sub.targets || []).map(target => target.targetId)
    );
    
    if (allTargetIds.length > 0) {
      this.coursesHttpService.getCoursesBulkById(allTargetIds).subscribe(courses => {
        this.passSubscriptionAccessTargetNames = [];
        for (const course of courses) {
          this.passSubscriptionAccessTargetNames.push({id: course.id, name: course.name});
        }
        this.updateDataSource();
      });
    } else {
      this.passSubscriptionAccessTargetNames = [];
      this.updateDataSource();
    }
  }

  getFilteredTargetNames(passSubscription: DmPassSubscriptionInterface): string[] {
    const targetIds = passSubscription.targets?.map(target => target.targetId) || [];
    const filteredTargets = this.passSubscriptionAccessTargetNames?.filter(targetName => targetIds.includes(targetName.id));
    return filteredTargets?.map(targetName => targetName.name);
  }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.passSubscriptions);
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }

  editSubscriptionById(subscriptionId: string): void {
    this.editSubscriptionClicked.emit(subscriptionId);
  }

  duplicateSubscriptionById(subscriptionId: string): void {
    this.duplicateSubscriptionClicked.emit(subscriptionId);
  }
  
  deleteSubscriptionById(subscriptionId: string): void {
     const confirmationDialogData: ConfirmationDialogInterface = {
       title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_SUBSCRIPTION_DIALOG.TITLE'),
       text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_SUBSCRIPTION_DIALOG.DESCRIPTION'),
       cancelButtonColor: '',
       cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
       submitButtonColor: '',
       submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE')
    };
    this.matDialog.open(ConfirmationDialogComponent, {
      data: confirmationDialogData,
      panelClass: 'dialog_radius'
    }).afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.dmPassSubscriptionHttpService.deletePassSubscriptionById(subscriptionId).subscribe(() => {
          this.passSubscriptionStateService.sendAvailableDfmCoursePassSubscriptions(this.passSubscriptions.filter(sub => sub.id !== subscriptionId));
        }, error => {
          if (error.status === 405) {
            this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SUBSCRIPTION_DELETE_ERROR'));
          } else {
            console.error(error);
          }
        });
      }
    });
  }

  expandItem(element: DmPassSubscriptionInterface): void {
    if(this.expendedCoursesElement === element) {
      this.expandedElement = null;
      this.expendedCoursesElement = null;
    } else {
      if(element.description !== null) {
        this.expandedElement = element;
      }
      this.expendedCoursesElement = element;
    }
  }
  
  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.passSubscriptions, event.previousIndex, event.currentIndex);
      this.updateDataSource();
      this.updateSequenceNumber();
    }
  }
  
  updateSequenceNumber(): void {
    const newSequence: { id: string; sequenceNumber: number }[] = [];

    this.passSubscriptions.forEach((sub, index) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newSequence.push({ id: sub.id!, sequenceNumber: index });
    });

    this.subscriptions.add(this.dmPassSubscriptionHttpService.updateSequenceNumber(this.danceSchoolStateService.getSyncCurrentDanceSchool().id, newSequence).subscribe({
      next: () => {
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SEQUENCE_UPDATED'));
      },
      error: (err) => {
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SEQUENCE_UPDATE_ERROR'));
      }
    }));
  }
  
  navigateToCreateSubscriptionPage(): void {
    this.router.navigate(['create-pass-subscription'], {relativeTo: this.route});
  }

  protected readonly PassStatusEnum = PassStatusEnum;
}
