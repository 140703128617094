import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DfmPassSubscriptionDetailPage } from './dfm-pass-subscription-detail.page';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { DfSharedButtonModule, PassSubscriptionGuestListTableModule, PassSubscriptionInfoFormModule, PassSubscriptionPriceFormModule, PassSubscriptionTargetsTableModule } from '../../components';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { ReactiveFormsModule } from '@angular/forms';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DfmPassSubscriptionDetailPage,
  },
];

@NgModule({
  declarations: [DfmPassSubscriptionDetailPage],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatIconModule,
      MatCardModule,
      MatTabsModule,
      PassSubscriptionInfoFormModule,
      PassSubscriptionPriceFormModule,
      PassSubscriptionTargetsTableModule,
      PassSubscriptionGuestListTableModule,
      DfSharedButtonModule,
      BreadcrumbComponent,
      BreadcrumbItemDirective,
      ReactiveFormsModule,
      TranslocoDirective,
    ]
})
export class DfmPassSubscriptionDetailPageModule {}
