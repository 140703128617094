import { FeesPaidByEnum, PassInterface } from '../../..';
import { CurrencyDto } from '../../..';
import { PassTargetDto } from './pass-target.dto';
import { PassValidityDto } from './pass-validity.dto';

export class PassDto implements PassInterface {
  id: string;
  createdAt: Date;
  currency: CurrencyDto;
  taxPercentage: number;
  price: number;
  name: string;
  description: string;
  quantity: number;
  sequenceNumber: number;
  feesPaidBy: FeesPaidByEnum;
  passTargets: PassTargetDto[];
  danceManagerId: string;
  usageCount: number;
  validity: PassValidityDto;
  trial: boolean;
}
