<ng-container *transloco="let t">
  <div class="w-100 flex-column" style="gap: 1rem;">
    <ng-container *ngIf="isInitialized else spinner">
      <div class="align-space-between-center">
        <span class="participants-title"> 
          {{t('DANCE_MANAGER_COURSE.PARTICIPANTS')}} ({{activeChipIndex === 0 ? activeUsers?.length : formerUsers?.length}})
        </span>
        <df-shared-lib-button
          [materialButtonStyleType]="'outlined'"
          [buttonLabel]="t('DANCE_MANAGER_COURSE.TRIAL_LESSON.INVITE')"
          (buttonClicked)="openTrialLessonInviteDialog()"
        ></df-shared-lib-button>
      </div>
      <div class="flex-row" style="padding-top: 10px;">
        <mat-chip (click)="toggleChip(0)" [class]="this.activeChipIndex === 0 ? 'active-chip' : ''" style="margin-right: 10px;">
          {{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.PARTICIPANTS_TAB.ACTIVE')}}
        </mat-chip>
        <mat-chip (click)="toggleChip(1)" [class]="this.activeChipIndex === 1 ? 'active-chip' : ''">
          {{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.PARTICIPANTS_TAB.FORMER')}}
        </mat-chip>
      </div>
      <div id="participants-hint" class="sub-text-12 flex-row-start-center">
        <mat-icon fontIcon="info_outlined"></mat-icon>
        <span>{{ t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.PARTICIPANTS_TAB.PARTICIPANTS_HINT_' + (this.activeChipIndex === 0 ? 'ACTIVE' : 'FORMER')) }}</span>
      </div>
      <table *ngIf="(activeChipIndex === 0 ? activeUsers?.length : formerUsers?.length) > 0; else emptyView" mat-table [dataSource]="participantListDataSource" matSort class="w-100 flex-table">
      
        <!-- Username/Email Column -->
        <ng-container matColumnDef="username_email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{t('DANCE_MANAGER_COURSE.PARTICIPANTS_TAB.USERNAME')}}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-row align-start-center">
              <a *ngIf="element.username" class="align-center" [href]="'/app/@' + element.username">
                <img class="circle-icon mr1" [src]="element.imageUrl && element.imageUrl !== '' ? element.imageUrl: DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
              </a>
              <a *ngIf="element?.username" [href]="'/app/@' + element?.username">
                {{ element?.username }}
              </a>
            <a
              *ngIf="!element?.username && element?.email"
              [href]="'mailto:' + element?.email"
            >
              {{ element?.email }}
            </a>
            <span *ngIf="!element?.username && !element?.email">
              {{t('DANCE_MANAGER_COURSE.NO_DATA')}}
            </span>
            </div>
          </td>
        </ng-container>
        
        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{t('GENERIC_WRAPPER.FIRST_NAME')}}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-row align-start-center">
              <a *ngIf="element?.firstName" [href]="'/app/@' + element.username">
                {{ element.firstName }}
              </a>
              <span *ngIf="!element.firstName"> - </span>
            </div>
          </td>
        </ng-container>
        
        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{t('GENERIC_WRAPPER.LAST_NAME')}}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-row align-start-center">
             <a *ngIf="element?.lastName" [href]="'/app/@' + element?.username">
              {{ element?.lastName }}
            </a>
            <span *ngIf="!element?.lastName"> - </span>
            </div>
          </td>
        </ng-container>
        
        <!-- Passes Column -->
        <ng-container matColumnDef="passes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{t('GENERIC_WRAPPER.PASSES')}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="passesColumnForUser(element.id, element.email)">
              {{ passesColumnForUser(element.id, element.email) }}
            </span>
            <span *ngIf="!passesColumnForUser(element.id, element.email)">
              {{t('DANCE_MANAGER_COURSE.NO_DATA')}}
            </span>
          </td>
        </ng-container>
        
        <!-- Last participated Column -->
        <ng-container matColumnDef="lastParticipated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.PARTICIPANTS_TAB.LAST_PARTICIPATED')}}
          </th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ lastParticipatedDateForUser(element.id, element.email) | formatDay }}
            </span>
          </td>
        </ng-container>
        
        
        <ng-container matColumnDef="openChat">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.id">
              <div class="flex-column align-center">
                <div>
                  <mat-icon class="open-chat-icon" (click)="openChat(element?.id)">chat_bubble_outline</mat-icon>
                </div>
              </div>
            </span>
          </td> 
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        
      </table>
    </ng-container>
  </div>
  
  <ng-template #spinner>
    <div class="loading-box w-100">
      <mat-spinner style="margin: auto" diameter="100"></mat-spinner>
    </div>
  </ng-template>
  
  <ng-template #emptyView>
    <df-shared-empty-page
      [emptyTitle]="t('DANCE_MANAGER_COURSE.PARTICIPANTS_TAB.NO_PARTICIPANTS')"
      [emptyDescription]="t('DANCE_MANAGER_COURSE.PARTICIPANTS_TAB.NO_PARTICIPANTS_DESC')"
      [imagePath]="'assets/images/events-manager/no_guests.svg'"
      [hideBtn]="true"
    ></df-shared-empty-page>
  </ng-template>
</ng-container>
