<ng-container *transloco="let t">
  <div class="flex-column" [formGroup]="formGroup">
    <div
      class="flex-column formContainerWidth"
    >
      <div class="flex-column">
        <span class="m0"
          ><strong>{{ t('DANCE_MANAGER_ADMINISTRATION.CONTACT.BUSINESS_ADDRESS') }}</strong>
        </span>
  
        <span class="mt1 pb1" style="width: 90%;">
          {{ t('DANCE_MANAGER_ADMINISTRATION.CONTACT.BUSINESS_DESCRIPTION') }}
        </span>
        <df-shared-lib-google-address-search-autocomplete
                [label]="t('DANCE_MANAGER_ADMINISTRATION.CONTACT.BUSINESS_ADDRESS')"
                [address]="formGroup.controls.businessAddress.value"
                (addressSelected)="setSelectedBusinessAddress($event)"
                (addressChanging)="setSelectedBusinessAddress()"
        >
        </df-shared-lib-google-address-search-autocomplete>
      </div>
      <div class="flex-column">
        <df-monorepo-phone-input
          [showSuffix]="false"
          [formGroup]="formGroup">
        </df-monorepo-phone-input>
      </div>
      <div class="flex-column">
        <span class="m0">
          <strong>
            {{ t('GENERIC_WRAPPER.EMAIL') }}
          </strong>
        </span>
        <form [formGroup]="formGroup">
          <mat-form-field 
            appearance="outline"
            class="mt2 w-100 pad-matformfield"
          >
            <mat-label>{{t('GENERIC_WRAPPER.EMAIL')}}</mat-label>
            <input type="text" matInput formControlName="email" autocomplete="off" id="contact-email-mat-form"/>
          </mat-form-field>
        </form>
      </div>
      <div class="flex-column">
        <!-- NEW LINKS -->
        <span class="mb2">
          <strong>
            {{ t('GENERIC_WRAPPER.SOCIAL_LINKS') }}
          </strong>
        </span>
        <dfm-social-links-edit
          [danceSchool]="danceSchool"
          (saveClick)="updateSocialLinks($event)"
        >
        </dfm-social-links-edit>
  
        <div *ngIf="danceSchool?.socialLinks?.length > 0" class="paddingMobile">
          <dfm-social-links-preview
            [danceSchool]="danceSchool"
            (deleteClick)="updateSocialLinks($event)">
          </dfm-social-links-preview>
        </div>
      </div>
    </div>
    <div class="buttonRowContainer">
      <div class="buttonContainer">
        <df-shared-lib-button
            [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
            [isDisabled]="formGroup.invalid"
            (buttonClicked)="saveContactForm()"
            id="contact-save-button"
          >
        </df-shared-lib-button>
      </div>
    </div>
  </div>
</ng-container>
