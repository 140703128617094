import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  Subscription
} from 'rxjs';
import {
  AuthStateService,
  DanceManagerService,
  DanceSchoolHttpService,
  DanceSchoolStateService,
  DanceSchoolToUserStateService,
  dfAppRoute,
  dfmMarketPlaceRoute,
  dfmPayoutRoute,
  DfmStateService,
  getSubscription,
  LocationsStateService,
  MessageStateService,
  NotificationDanceManagerStateService,
  SidenavStateService,
  WebsocketService
} from '@platri/dfx-angular-core';
import {
  DanceEventsInterface,
  DanceManagerFeatureInterface,
  DanceManagerFeatureNameEnum,
  DanceManagerInterface,
  danceManagerRolesWithAccessOnDm,
  DanceManagerUserRoleEnum,
  DanceSchoolInterface,
  getDanceManagerIdsFromTokenRolesByRoles,
  PartnerType
} from '@platri/df-common-core';
import { MatSidenav } from '@angular/material/sidenav';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { UntypedFormControl } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MessageCountStateService } from '@platri/df-angular-chat';
import { DmMessageCountInterface } from '@platri/df-common-chat-plug-play';

@Component({
  selector: 'dfm-sidenav',
  templateUrl: './dfm-sidenav.component.html',
  styleUrls: ['./dfm-sidenav.component.scss']
})
export class DfmSidenavComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  danceSchool: DanceSchoolInterface;
  danceEvent: DanceEventsInterface | null;
  loading: boolean;
  showLabel: boolean;

  DanceManagerUserRoleEnum = DanceManagerUserRoleEnum;
  danceSchoolControl: UntypedFormControl = new UntypedFormControl(null);
  positionStrategy: ConnectedPosition[] = [
    {
      offsetX: 67,
      originX: 'start',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center'
    }
  ];

  danceManagerFeatures: DanceManagerFeatureInterface[] = [];
  danceManagerFeatureName = DanceManagerFeatureNameEnum;

  danceManagerId: string;

  routerParams!: Params;
  danceManagerIdFromParam: string;
  selectedDanceManager: DanceManagerInterface;
  
  danceManagers: DanceManagerInterface[];
  
  subscriptions = new Subscription();

  readonly onUnreadMessagesCountUpdated = (): void => {
    this.messageService.loadAllMessagesCountOfCurrentUser();
  };

  unreadMessagesCount = 0;
  countUnreadMessageDmSubject = new BehaviorSubject<DmMessageCountInterface[]>([]);

  constructor(
    public readonly router: Router,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly danceManagerService: DanceManagerService,
    private readonly locationService: LocationsStateService, 
    private readonly danceSchoolToUserService: DanceSchoolToUserStateService,
    private readonly route: ActivatedRoute, 
    private readonly sidenavService: SidenavStateService, 
    private readonly websocketService: WebsocketService, 
    private readonly authService: AuthStateService, 
    private readonly breakpointObserver: BreakpointObserver, 
    private readonly messageService: MessageStateService,
    public readonly notificationStateService: NotificationDanceManagerStateService,
    private cdRef: ChangeDetectorRef,
    private dfmStateService: DfmStateService,
    private messageCountStateService: MessageCountStateService
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.dfmStateService.loadAvailableData();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.route.params, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.dfmStateService.getAsyncAvailableData(), this.onDataChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.dfmStateService.selectedDm, this.onSelectedDmChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.dfmStateService.selectedDmFeatures, this.onDanceManagerFeaturesChange.bind(this)));
    this.subscriptions.add(getSubscription(combineLatest([this.breakpointObserver.observe(['(max-width: 599px)', '(min-width: 1213px)'])]), this.onBreakpointChanges.bind(this)));

    this.subscriptions.add(
      combineLatest([this.breakpointObserver.observe(['(max-width: 599px)', '(min-width: 1213px)']), this.sidenavService.getAsyncCurrentExpandedState()])
        .subscribe(([breakPointsResult, isExpanded]) => {
          this.showLabel = breakPointsResult.matches && isExpanded;
          this.cdRef.detectChanges();
        })
    );
    this.subscriptions.add(getSubscription(this.messageCountStateService.getAsyncDmUnreadMessageCountData().pipe(debounceTime(500)), this.onDmUnreadMessageCountDataChanged.bind(this)));
  }

  onDmUnreadMessageCountDataChanged(data: DmMessageCountInterface[]): void {
    this.countUnreadMessageDmSubject.next(data);
    const dmUnreadMessages = data?.find((obj) => obj.danceManagerId === this.danceSchool.id);
    if (dmUnreadMessages) {
      this.unreadMessagesCount = Number(dmUnreadMessages.count);
    } else {
      this.unreadMessagesCount = 0;
    }
  }
  
  onBreakpointChanges([breakPointsResult, isExpanded]): void {
    this.showLabel = breakPointsResult.matches && isExpanded;
    this.cdRef.detectChanges();
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.danceManagerId = this.routerParams['id'];
    if (!this.danceManagerId) {
      this.router.navigate([dfAppRoute]);
    }

    this.loadDanceSchoolAndSubscribe(params.id);
    
    this.danceManagerService.getDanceManagerById(this.danceManagerId);
  }
  
  onDataChanges(danceManagers: DanceManagerInterface[]): void {
    const danceManagerIdsWithEnoughRights = getDanceManagerIdsFromTokenRolesByRoles(this.authService.getSyncCurrentUser().roles?.danceSchool, danceManagerRolesWithAccessOnDm);
    this.danceManagers = danceManagers.filter((danceManager) => danceManagerIdsWithEnoughRights.includes(danceManager.id));
    
    if (danceManagers && this.danceManagerIdFromParam) {
      const currentDanceSchool = this.danceManagers.find((dm) => dm.id === this.danceManagerIdFromParam);
      this.danceSchoolControl.patchValue(currentDanceSchool);
    }
  }
  
  onSelectedDmChanges(danceManager: DanceManagerInterface): void {
    this.selectedDanceManager = danceManager;
    this.messageCountStateService.loadAvailableData();

    if (this.danceManagers && this.danceManagerIdFromParam) {
      const currentDanceSchool = this.danceManagers.find((dm) => dm.id === this.danceManagerIdFromParam);
      this.danceSchoolControl.patchValue(currentDanceSchool);
    }
  }

  onDanceManagerFeaturesChange(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.danceManagerFeatures = danceManagerFeatures;
    this.cdRef.detectChanges();
  }

  loadDanceSchoolAndSubscribe(id: string): void {
    this.danceManagerId = id;
    this.subscriptions.add(
      this.danceSchoolService.getAsyncCurrentDanceSchool().subscribe((danceSchool) => {
        const lastDanceSchoolId = this.danceSchool?.id;

        if (danceSchool && lastDanceSchoolId !== danceSchool.id) {
          this.danceSchool = danceSchool;
          this.locationService.loadAllLocationsByDanceManagerId(danceSchool.id);
          this.messageService.loadAllMessagesCountOfCurrentUser();
        } else {
          this.unreadMessagesCount = 0;
        }

        const socket = this.websocketService.getSocket();
        if (socket) {
          if (lastDanceSchoolId) {
            socket.emit('leave-partner-room', {
              room: PartnerType.danceSchool + '-' + lastDanceSchoolId
            });
          }
          if (danceSchool) {
            socket.emit('join-partner-room', {
              room: PartnerType.danceSchool + '-' + danceSchool.id
            });
          }
        }
      })
    );

    this.danceSchoolHttpService.getDanceSchoolById(id).subscribe((result) => {
      this.danceSchoolService.sendCurrentDanceSchool(result);
      this.danceManagerService.getDanceManagerFeaturesById(id);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.danceSchoolService.sendCurrentDanceSchool(null);
    this.danceSchoolToUserService.clear();

    const socket = this.websocketService.getSocket();

    if (socket) {
      socket.emit('leave-partner-room', {
        room: PartnerType.danceSchool + '-' + this.danceSchool.id
      });

      socket.off('unreadMessagesCountUpdated', this.onUnreadMessagesCountUpdated);
    }
  }

  compareObjects(o1: DanceManagerInterface, o2: DanceManagerInterface): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  navigateToMessages(): void {
    this.router.navigate(['messages'], { queryParams: { technicalUserId: this.danceManagerId }, relativeTo: this.route });
  }
  
  protected readonly dfmMarketPlaceRoute = dfmMarketPlaceRoute;
  protected readonly dfmPayoutRoute = dfmPayoutRoute;
}
