import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DfmDanceManagerLocationIdRouterParam, DfmLocationsWrapperPage, DfmLocationsWrapperPageModule } from '@platri/dfx-angular-core';

const routes: Routes = [
  {
    path: '',
    component: DfmLocationsWrapperPage,
  },
  {
    path: 'create',
    loadChildren: () => import('./pages/dfm-create-location-page/dfm-create-location-page.module').then((m) => m.DfmCreateLocationPageModule),
  },
  {
    path: ':' + DfmDanceManagerLocationIdRouterParam + '/edit',
    loadChildren: () => import('./pages/dfm-edit-location-page/dfm-edit-location-page.module').then((m) => m.DfmEditLocationPageModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), DfmLocationsWrapperPageModule],
  exports: [RouterModule]
})
export class FrontendDfmLocationAppRoutingModule {
  constructor() {
    console.log('FrontendDfmLocationAppRoutingModule initialized');
  }
}
