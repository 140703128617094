export enum SearchCategoryEnum {
  EVENTS = 'EVENTS',
  FESTIVALS = 'FESTIVALS',
  COURSES = 'COURSES',
  STUDIOS = 'STUDIOS',
  PARTNERS = 'PARTNERS',
  ROOMS = 'ROOMS',
  BOOK_ME = 'BOOK_ME',
  USER = 'USER'
}
