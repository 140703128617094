import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageHttpService } from '../messages';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

export interface TaggableOptionInterface {
  danceSchoolId?: string;
  id: string;
  imageUrl?: string;
  info: string;
  userId?: string;
  username: string;
}

@Component({
  selector: 'elab-tagging-text-area',
  templateUrl: './tagging-text-area.component.html',
  styleUrls: ['./tagging-text-area.component.scss']
})
export class TaggingTextAreaComponent implements OnInit{
  @ViewChild('mirrorDiv') mirrorDiv: ElementRef;

  @Input() rows = 5;
  @Input() customFormGroup: UntypedFormGroup;
  @Input() customFormControlName: string;

  taggableOptions: TaggableOptionInterface[] = [];

  private inputChanged = new Subject<{ value: string; caretPos: number}>();

  constructor(private readonly messageHttpService: MessageHttpService) {}
  
  ngOnInit(): void {
    this.listenOnInputChanged();
  }
  
  listenOnInputChanged(): void {
    this.inputChanged.pipe(debounceTime(300)).subscribe(({value, caretPos}) => {
      const wordBeforeCaret = this.extractWordAtPosition(value, caretPos);

      if (wordBeforeCaret.startsWith('@')) {
        this.openUserSelectionDialog(wordBeforeCaret.replace('@', ''));
      } else {
        this.taggableOptions = [];
      }
    });
  }

  extractWordAtPosition(text: string, position: number): string {
    const left = text.slice(0, position).split(/\s/).pop();
    const right = text.slice(position).split(/\s/)[0];

    return left + right;
  }

  onInputChange(event: any): void {
    this.inputChanged.next({
      value: event.target.value,
      caretPos: event.target.selectionStart
    });
  }

  openUserSelectionDialog(term: string): void {
    this.messageHttpService.searchPartners(term).pipe(debounceTime(500)).subscribe({
      next: (result: TaggableOptionInterface[]) => {
        this.taggableOptions = result;
      }
    });
  }
  
}
