<ng-container *transloco="let t">
  <div class="flex-column-center-start p3" style="min-height: calc(100vh - 68px);">
     <div class="flex-column w-100" style="max-width: 1140px;">
        <!-- Breadcrumbs -->
        <div>
           <xng-breadcrumb [separator]="iconTemplate">
              <ng-container *xngBreadcrumbItem="let breadcrumb">
                 <ng-container>{{ t(breadcrumb) }}</ng-container>
              </ng-container>
           </xng-breadcrumb>
           <ng-template #iconTemplate>
              <mat-icon>chevron_right</mat-icon>
           </ng-template>
        </div>
        <div class="flex-column w-100">
           <mat-card class="main-content round-corners my2" style="padding: 40px;">
              <span class="page-title">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NEW_APPOINTMENT')}}</span>
              <div class="pt-20 pb-40">
                 <mat-divider style="position: unset"></mat-divider>
              </div>
              <div [formGroup]="formGroup" class="flex-column" style="max-width: 50%; gap: 20px;">
                 <div class="flex-column w-75" style="gap: 1rem;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.DATE_AND_TIME')}}</span>
                    <div>
                       <mat-radio-group class="flex-column" style="gap: 16px;" formControlName="schedulerType">
                          <mat-radio-button color="primary" [value]="SchedulerTypeEnum.SINGLE" [checked]="true">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.SCHEDULER_SINGLE')}}</mat-radio-button>
                          <mat-radio-button color="primary" [value]="SchedulerTypeEnum.SERIES">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.SCHEDULER_SERIES')}}</mat-radio-button>
                       </mat-radio-group>
                    </div>
                    <div class="flex-column">
                      <mat-form-field appearance="outline">
                        <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_DATE')}}</mat-label>
                        <input matInput [matDatepicker]="picker" readonly id="date-start" (click)="picker.open()" formControlName="startDate" [min]="today"/>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <button
                          matSuffix
                          mat-icon-button
                          aria-label="Clear"
                          type="button"
                          (click)="formGroup.controls.startDate.reset()">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="formGroup.controls.startDate.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_DATE_ERROR_REQUIRED')}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" *ngIf="formGroup.controls.schedulerType.value === SchedulerTypeEnum.SERIES && formGroup.controls.recurrenceType.value !== AppointmentRecurrenceTypeEnum.CUSTOM">
                        <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_DATE')}}</mat-label>
                        <input matInput [matDatepicker]="picker" readonly id="date-end" (click)="picker.open()" formControlName="endDate" [min]="formGroup.controls.startDate.value"/>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <button matSuffix mat-icon-button aria-label="Clear" type="button" (click)="formGroup.controls.endDate.reset()">
                          <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="formGroup.controls.endDate.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_DATE_ERROR_REQUIRED')}}</mat-error>
                      </mat-form-field>
                      <df-shared-lib-time-picker
                        class="w-100"
                        id="course-appointment-start-time"
                        [passedFormControlName]="'startTime'"
                        [passedFormGroup]="formGroup"
                        [label]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_TIME')"
                        [requiredErrorMessage]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.START_TIME_REQUIRED')"
                      ></df-shared-lib-time-picker>
                      <df-shared-lib-time-picker
                        class="w-100"
                        [passedFormControlName]="'endTime'"
                        [passedFormGroup]="formGroup"
                        [label]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_TIME')"
                        [requiredErrorMessage]="t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_TIME_REQUIRED')"
                      ></df-shared-lib-time-picker>
                      <mat-error *ngIf="!endTimeIsLater">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.END_TIME_BEFORE_START_TIME')}}</mat-error>
                    <div *ngIf="formGroup.controls.schedulerType.value === SchedulerTypeEnum.SERIES" class="flex-column">
                       <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.INTERVAL')}}</span>
                       <mat-form-field [appearance]="'outline'">
                          <mat-select formControlName="recurrenceType">
                             <mat-option
                               *ngFor="let type of AppointmentRecurrenceTypeEnum | keyvalue"
                               [value]="type.value"
                             >{{
                               type.value
                                 | formatInterval
                                 : null
                                   : null
                                   : null
                                   : (formGroup.controls.startDate.value
                                   ? formGroup.controls.startDate.value.toDate()
                                   : null)
                               }}</mat-option
                             >
                          </mat-select>
                       </mat-form-field>
                    </div>
                    <span class="section-subtitle" *ngIf="formGroup.controls.schedulerType.value === SchedulerTypeEnum.SERIES && formGroup.controls.recurrenceType.value === AppointmentRecurrenceTypeEnum.CUSTOM">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.REPEAT')}}</span>
                      <div class="flex-row-start-center mt-5 gap-10" *ngIf="formGroup.controls.schedulerType.value === SchedulerTypeEnum.SERIES && formGroup.controls.recurrenceType.value === AppointmentRecurrenceTypeEnum.CUSTOM">
                       <mat-form-field appearance="outline" class="w-25">
                          <input dfNoScroll placeholder="1" min="1" matInput type="number" formControlName="customRecurrenceNumber"/>
                          <mat-error *ngIf="formGroup.controls.customRecurrenceNumber.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}</mat-error>
                       </mat-form-field>
                       <mat-form-field appearance="outline">
                          <mat-label>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.CYCLE')}}</mat-label>
                          <mat-select formControlName="customRecurrenceType">
                             <mat-option *ngFor="let recurrenceType of CustomRecurrenceTypeEnum | keyvalue" [value]="recurrenceType.value">{{ t(recurrenceType.value + 'S')}}</mat-option>
                          </mat-select>
                       </mat-form-field>
                       <mat-form-field appearance="outline" *ngIf="formGroup.controls.customRecurrenceType.value ===CustomRecurrenceTypeEnum.WEEK" class="w-100.lt-md">
                          <mat-label>{{t('DANCE_MANAGER_COURSE.WEEKDAYS')}}</mat-label>
                          <mat-select formControlName="customRecurrenceWeekDays" disableOptionCentering multiple>
                             <mat-option
                               *ngFor="let weekday of WeekdaysStringEnum | keyvalue: ascOrder"
                               [value]="weekday.value"
                             >{{ weekday.value }}</mat-option
                             >
                          </mat-select>
                       </mat-form-field>
                    </div>
                     <div class="flex-column">
                       <span class="section-subtitle" *ngIf="formGroup.controls.schedulerType.value === SchedulerTypeEnum.SERIES && formGroup.controls.recurrenceType.value === AppointmentRecurrenceTypeEnum.CUSTOM">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.INTERVAL_ENDS')}}</span>
                        <mat-radio-group class="flex-column" formControlName="customRecurrenceEnd" *ngIf="formGroup.controls.schedulerType.value === SchedulerTypeEnum.SERIES && formGroup.controls.recurrenceType.value === AppointmentRecurrenceTypeEnum.CUSTOM">
                          <mat-radio-button color="primary" [value]="'DATE'" [checked]="true">
                            <div class="flex-row-start-center" style="gap: 8px;">
                              <span class="section-subtitle">{{t('GENERIC_WRAPPER.DATE')}}</span>
                              <mat-form-field appearance="outline" style="margin-top: 16px;">
                                <mat-label>End Date</mat-label>
                                <input matInput [matDatepicker]="picker" readonly id="date-end-custom" (click)="picker.open()" formControlName="endDate" [min]="formGroup.controls.startDate.value"/>
  <!--                              <div class="flex-row">-->
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
  <!--                              <button matSuffix mat-icon-button aria-label="Clear" type="button" (click)="formGroup.controls.endDate.reset()">-->
  <!--                                <mat-icon>close</mat-icon>-->
  <!--                              </button>-->
  <!--                              </div>-->
                                
                              </mat-form-field>
                              <button matSuffix mat-icon-button aria-label="Clear" (click)="formGroup.controls.endDate.reset()">
                                  <mat-icon>close</mat-icon>
                                </button>
                            </div>
                          </mat-radio-button>
                          <mat-radio-button color="primary" [value]="'NUMBER_OF_APPOINTMENTS'">
                            <div class="flex-row-start-center" style="gap: 8px">
                              <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.AFTER')| titlecase}}</span>
                              <mat-form-field appearance="outline" class="w-25" style="margin-top: 16px;">
                                <input dfNoScroll placeholder="1" min="1" matInput type="number" formControlName="numberOfAppointments"/>
                                <mat-error *ngIf="formGroup.controls.numberOfAppointments.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}</mat-error>
                              </mat-form-field>
                              <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.APPOINTMENTS')}}</span>
                            </div>
                       </mat-radio-button>
                    </mat-radio-group>
                     </div>
                   </div>
                   
                 </div>
                 <div class="flex-column pt-20 w-75" style="gap: 10px;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.LOCATION')}}</span>
                    <mat-radio-group (change)="changeAddressInput($event.value)" class="flex-column" style="gap: 16px;">
                       <mat-radio-button color="primary" value="location" [checked]="danceManager.locations.length !== 0" [disabled]="danceManager.locations.length === 0"><span>{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.EXISTING_LOCATION')}}</span></mat-radio-button>
                       <mat-form-field  appearance="outline" *ngIf="addressInputType === 'location' || danceManager.locations.length === 0" hideRequiredMarker="{{danceManager.locations.length === 0}}">
                          <mat-label>{{ t(danceManager.locations.length === 0 ? 'NO_LOCATIONS' : 'GENERIC_WRAPPER.SELECT') }}</mat-label>
                          <mat-select [disableControl]="addressInputType === 'manual'" formControlName="locationId" data-cy="location-select">
                             <mat-option *ngFor="let location of danceManager.locations" [value]="location.id" (click)="onLocationSelected(location)">{{ location.name }}</mat-option>
                          </mat-select>
                       </mat-form-field>
                       <mat-radio-button color="primary" value="manual" [checked]="danceManager.locations.length === 0" >{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MANUAL_ADDRESS')}}</mat-radio-button>
                       <df-shared-lib-google-address-search-autocomplete
                         class="pt-10"
                         *ngIf="addressInputType === 'manual'"
                         [alternativeDesign]="true"
                         [address]="formGroup.controls.address.value"
                         (addressSelected)="onAddressSelected($event)"
                         [types]="['address']">
                       </df-shared-lib-google-address-search-autocomplete>
                    </mat-radio-group>
                 </div>
                <!-- Teachers -->
                 <div class="flex-column pt-20 w-75" style="gap: 10px;">
                    <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.TEACHERS')}}</span>
                    <mat-form-field appearance="outline">
                       <mat-label>{{t('GENERIC_WRAPPER.SELECT')}}</mat-label>
                       <mat-select formControlName="teacherUserIds" multiple>
                          <mat-option *ngFor="let teacher of danceManagerUsers" [value]="teacher.id">{{ teacher.firstName ? teacher.firstName + ' ' + teacher.lastName : teacher.username}}</mat-option>
                       </mat-select>
                       <mat-error *ngIf="formGroup.controls.teacherUserIds.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TEACHERS_ERROR_REQUIRED')}}</mat-error>
                    </mat-form-field>
                 </div>
                <!-- Participants -->
                 <div class="flex-column" style="gap: 10px;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.PARTICIPANTS')}}</span>
                    <span class="section-subtitle">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.MAX_PARTICIPANTS_SUBTEXT')}}</span>
                    <mat-form-field appearance="outline" class="w-25">
                       <input dfNoScroll placeholder="1" min="1" matInput type="number" formControlName="maxParticipants"/>
                       <mat-error *ngIf="formGroup.controls.maxParticipants.hasError('required')">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.NUMBER')}}</mat-error>
                    </mat-form-field>
                 </div>
                <!-- Additional information -->
                 <div *ngIf="formGroup.controls.description as editorControl" class="flex-column" style="gap: 10px;">
                    <span class="section-title">{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.ADDITIONAL_INFO')}}</span>
                    <df-shared-lib-quill-editor
                      [hasError]="editorControl.hasError('maxlength') && editorControl.touched"
                      [disableRichText]="true"
                      [enableMentions]="true"
                      [enableCounter]="true"
                      [maxLength]="1000"
                      [id]="'description'"
                      [placeholder]="'DANCE_MANAGER_COURSE.APPOINTMENTS.ADDITIONAL_INFO'"
                      customFormControlName="description"></df-shared-lib-quill-editor>
                    <mat-error *ngIf="editorControl.hasError('maxlength')" class="custom-error">{{t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH')}}</mat-error>
                 </div>
              </div>
              <div class="flex-row-end-center pt-20" style="gap: 16px;">
                 <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" [materialButtonStyleType]="'outlined'" (buttonClicked)="onCancelClick()"></df-shared-lib-button>
                <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" [isDisabled]="formGroup.invalid || !endTimeIsLater" (buttonClicked)="onSaveClick()" id="save-new-appointment-for-course"></df-shared-lib-button>
              </div>
           </mat-card>
        </div>
     </div>
  </div>
</ng-container>
