import { AppointmentUsersInterface } from './appointment-users.interface';
import { AppointmentCommentsInterface } from './appointment-comments.interface';
import { AddressInterface } from '../address.interface';
import { DanceStyleInterface } from '../dance-style.interface';
import { LocationInterface } from '../location.interface';
import { RoomInterface } from '../room.interface';
import { SchedulersInterface } from '../schedulers';
import { UsersInterface } from '../users';
import { AppointmentParticipantInterface } from '../appointment-participants';

export interface AppointmentsInterface {
  additionalInformation?: string;
  address?: AddressInterface;
  appointmentToUsers?: AppointmentUsersInterface[];
  cancellationReason?: string;
  cancelled?: boolean;
  clientTimezone?: string;
  comments?: AppointmentCommentsInterface[];
  content?: string;
  createdAt?: Date;
  date?: Date;
  danceStyles?: DanceStyleInterface[];
  description?: string;
  duration?: number;
  id?: string;
  location?: LocationInterface;
  maxParticipants?: number;
  name?: string;
  room?: RoomInterface;
  scheduler?: SchedulersInterface;
  startDate?: Date;
  students?: UsersInterface[];
  teachers?: UsersInterface[];
  teacherUserIds?: string[];
  updatedAt?: Date;
  participants?: AppointmentParticipantInterface[];
}
