import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'df-create-dance-event-rules-page',
  templateUrl: './create-dance-event-rules.page.html',
  styleUrls: ['./create-dance-event-rules.page.scss'],
})
export class CreateDanceEventRulesPage {
  @Input() formGroup: UntypedFormGroup;
  @Input() loading: boolean;

  @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSubmitClick: EventEmitter<boolean> = new EventEmitter<boolean>();
}
