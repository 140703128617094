import { DanceIntervalEnum, GenderEnum, UserAppLangEnum, UserRoleEnum, UserStatusEnum } from '../../enums';
import { SocialLinkInterface } from '../social-link.interface';
import { UsersDanceStylesInterface } from './users-dance-styles.interface';
import { UsersLanguagesInterface } from './users-languages.interface';
import { BillingOrShippingAddressInterface } from '../addresses';
import { SocialProviderInterface } from '../social-provider.interface';
import { AuthDanceSchoolUserRoleJwtInterface } from '../auth-dance-school-user-role-jwt.interface';

export interface UsersInterface {
  addresses?: BillingOrShippingAddressInterface[];
  appLang?: UserAppLangEnum;
  birthPlace?: string;
  birthday?: Date;
  createdAt?: Date;
  currentHashedRefreshToken?: string;
  danceInterval?: DanceIntervalEnum;
  danceStyles?: UsersDanceStylesInterface[];
  dancingArea?: string;
  description?: string;
  email?: string;
  emailVerified?: boolean;
  firstName?: string;
  formerPlace?: string;
  gender?: GenderEnum | null;
  hasPassword?: boolean;
  height?: number;
  id?: string;
  imageUrl?: string;
  isAgePublic?: boolean;
  isNamePublic?: boolean;
  jobTitle?: string;
  languageSkills?: UsersLanguagesInterface[];
  lastName?: string;
  lookingFor?: string[];
  monthsDancing?: number;
  password?: string;
  phoneNumber?: string;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  roles?: AuthDanceSchoolUserRoleJwtInterface;
  salt?: string;
  showCompleteProfileDialog?: boolean;
  socialLinks?: SocialLinkInterface[];
  socialProviders?: SocialProviderInterface[];
  status?: UserStatusEnum;
  updatedAt?: Date;
  username?: string;
  userRole?: UserRoleEnum;
}
