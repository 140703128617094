import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DfQuillEditorModule, DfSharedButtonModule, DisableControlModule, LevelSliderMaterialModule, LevelSliderViewModule } from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { AddEditEventRulesComponent } from './add-edit-event-rules.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [AddEditEventRulesComponent],
  exports: [AddEditEventRulesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    DfSharedButtonModule,
    MatCheckboxModule,
    LevelSliderViewModule,
    DisableControlModule,
    DfQuillEditorModule,
    LevelSliderMaterialModule,
    TranslocoDirective
  ],
})
export class AddEditEventRulesModule {}
