import { RadiusUnitEnum, SortDirectionEnum } from '../../../enums';

export interface SearchDanceSchoolsQueryInterface {
  search?: string;
  danceStyles?: string[];
  longitude?: number;
  latitude?: number;
  radius?: number;
  radiusUnit?: RadiusUnitEnum;
  limit?: number;
  pageNumber?: number;
  pageSize?: number;
  sortCol?: string;
  sortDir?: SortDirectionEnum;
}
