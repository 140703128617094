<ng-container *transloco="let t">
  <div *ngIf="(loading$ | async) === false else spinner" >
    <div *ngIf="total?.quantity > 0; else emptyGuestList" >
      <div class="w-100 mb2 flex-column align-start">
        <div class="w-100 flex-row-wrap-space-between">
          <h2 style="padding-right: 10px;">{{ t('DANCE_MANAGER_EVENT.STATISTICS.TITLE') }}</h2>
          <df-shared-lib-button [isMatIconButton]="true" (buttonClicked)="refreshList()" [matIconName]="'refresh'" [minWidth]="'28px'" [height]="'38px'"></df-shared-lib-button>
        </div>
        <div class="pt1 dance-event-ticket-statistics-container ">
          <mat-form-field  class="w-100" appearance="outline">
            <mat-select
              [value]="totalWithoutFreeTickets.quantity !== total.quantity ? [total, totalWithoutFreeTickets] : [total]"
              placeholder="{{ t('DANCE_MANAGER_EVENT.STATISTICS.NONE') }}"
              multiple
              #statisticsSelect
            >
              <mat-select-trigger>
                {{
                  statisticsSelect.value && statisticsSelect.value.length > 0
                    ? statisticsSelect.value.length > 1
                      ? statisticsSelect.value.length +
                        ' ' +
                        t('DANCE_MANAGER_EVENT.STATISTICS.SELECTED')
                      : statisticsSelect.value[0].ticketName
                    : ''
                }}
              </mat-select-trigger>
              <mat-option [value]="total"> {{ total.ticketName }} </mat-option>
              <mat-option *ngIf="totalWithoutFreeTickets.quantity !== total.quantity" [value]="totalWithoutFreeTickets"> {{ totalWithoutFreeTickets.ticketName }} </mat-option>
              <mat-option
                *ngFor="let ticket of danceEventTicketStatistics"
                [value]="ticket"
              >
                {{ ticket.ticketName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
  
    <div class=" df-shared-lib-statistics-card-container" >
      <df-shared-lib-statistics-card
        class="df-shared-lib-statistics-card"
        *ngFor="let ticket of statisticsSelect.selected; let i = index"
        (removeCardEvent)="statisticsSelect.selected[i].deselect()"
        [cardTitle]="ticket.value.ticketName"
        [indicatorTitles]="{
          left: t('GENERIC_WRAPPER.FOLLOWER'),
          right: t('GENERIC_WRAPPER.LEADER')
        }"
        [showSubStats]="true"
        [leftStatBox]="{
          icon: 'local_activity',
          title: t('GENERIC_WRAPPER.TICKETS'),
          mainStats: {
            left: ticket.value.soldQuantity,
            right: ticket.value.quantity
          },
          subTitle:
            t('GENERIC_WRAPPER.FOLLOWER') +
            ' / ' +
            t('GENERIC_WRAPPER.LEADER') +
            ' ' +
            t('GENERIC_WRAPPER.TICKETS'),
          subStats: { left: ticket.value.soldFollowerQuantity, right: ticket.value.soldLeaderQuantity }
        }"
        [rightStatBox]="{
          icon: 'checklist',
          title: t('DANCE_MANAGER_EVENT.STATISTICS.CHECKED_IN'),
          mainStats: {
            left: ticket.value.checkedInQuantity,
            right: ticket.value.soldQuantity
          },
          subTitle:
            t('GENERIC_WRAPPER.FOLLOWER') +
            ' / ' +
            t('GENERIC_WRAPPER.LEADER') +
            ' ' +
            t('DANCE_MANAGER_EVENT.STATISTICS.CHECKED_IN'),
          subStats: { left: ticket.value.checkedInFollowerQuantity, right: ticket.value.checkedInLeaderQuantity }
        }"
      ></df-shared-lib-statistics-card>
    </div>
    <!-- Guestlist-Header -->
    <div class=" guest-list-header">
      <h2 class="guest-list-title">{{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.TITLE') }}</h2>
      <button
        mat-flat-button
        class="invite-button btn-inlined"
        [disabled]="!isDanceEventActive"
        (click)="openAddGuestDialog()"
      >
        <div class="align-center">
          <mat-icon>person_add</mat-icon>
          <span style="vertical-align: middle">
            {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.INVITE') }}
          </span>
        </div>
      </button>
    </div>
  
    <div class="w-100 filter-container align-center align-space-between">
      <mat-form-field class="mr1"  style="width: 65%;" appearance="outline">
        <div class="align-center">
          <mat-icon>search</mat-icon>
          <input
            matInput
            (input)="bindFilterInput($event)"
            placeholder="{{ t('GENERIC_WRAPPER.SEARCH') }}"
            #filterInput
            [value]="filterText"
          />
        </div>
      </mat-form-field>
      <mat-form-field class="mr1"  style="width: 35%;" appearance="outline">
        <mat-select
          [value]="'All'"
          (selectionChange)="applyTicketFilter($event)"
          #ticketTypeSelect
        >
          <mat-option [value]="'All'">
            {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.TICKET_TYPE') }}
          </mat-option>
          <mat-option *ngFor="let ticket of eventTickets" [value]="ticket.name">{{
            ticket.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <!-- SEARCH Dancerole -->
      <mat-form-field  style="width: 20%;" appearance="outline">
        <mat-select
          [value]="'All'"
          (selectionChange)="applyTicketFilter($event)"
          #roleTypeSelect
        >
          <mat-option [value]="'All'">
            {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.DANCE_ROLE') }}
          </mat-option>
          <mat-option *ngFor="let danceRoles of danceRolesEnum | keyvalue" [value]="danceRoles.value">{{
            danceRoles.value | titlecase
          }}</mat-option>
        </mat-select>
      </mat-form-field>
  
  
    </div>
    <!-- Guestlist-Table -->
    <div class="table-container w-100 flex">
      <table
        class="guest-list-table pb2"
        mat-table
        [dataSource]="guestListDataSource"
        matSort
        matSortActive="tickets"
      >
        <ng-container matColumnDef="user">
          <th class="user-header" mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('GENERIC_WRAPPER.USER') }}</th>
          <td class="user-cell" mat-cell *matCellDef="let row">
            <div class="flex-row-start-center">
              <a class="align-center">
                <img class="circle-icon mr1" [src]="row.user?.imageUrl && row.user?.imageUrl !== '' ? row.user.imageUrl: DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
              </a>
              <a *ngIf="row.user?.id; else noUser" [href]="'/app/@' + row.user?.username">
                {{ row.user?.firstName && row.user?.lastName ? 
                  row.user?.firstName + ' ' + row.user?.lastName + ' (@' + row.user?.username + ')' : 
                row.user?.username ?? (row.user?.email + ' (' + t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.GUEST') + ')') }}
              </a>
              <ng-template #noUser>
                <a [href]="'mailto:' + row.user?.email">
                  {{ row.user?.email + ' (' + t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.GUEST') + ')' }}
                </a>
              </ng-template>
            </div>
          </td>
        </ng-container>
        
  <!--      <ng-container matColumnDef="email">-->
  <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>-->
  <!--          {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.MAIL') }}-->
  <!--        </th>-->
  <!--        <td mat-cell *matCellDef="let row">-->
  <!--          <a [href]="'mailto:' + row.user?.email">-->
  <!--            {{ row.user?.email }}-->
  <!--          </a>-->
  <!--          <span *ngIf="!row.user?.email">-->
  <!--            {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.NO_DATA') }}-->
  <!--          </span>-->
  <!--        </td>-->
  <!--      </ng-container>-->
      
        <ng-container  matColumnDef="tickets" >
          <th class="tickets-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t('GENERIC_WRAPPER.TICKETS') }}
          </th>
          <td class="tickets-cell" mat-cell *matCellDef="let row">
            <div class="tickets-restriction-container flex-column">
              <div *ngFor="let ticketType of row.ticketSaleInformation">
                <span style="display:block; min-height: 30px;">
                  {{ ticketType.boughtQuantity + 'x ' }}
                  {{ ticketType.ticketName | truncate:30 }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>
      
  
        <ng-container matColumnDef="danceRole">
          <th class="dance-role-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.DANCE_ROLE') }}
          </th>
          <td class="dance-role-cell" mat-cell *matCellDef="let row">
            <div class="flex-column dance-role-cell">
              <div *ngFor="let ticketType of row.ticketSaleInformation">
                <span style="display:block; height: 30px;"
                > {{ ticketType.danceRoleType === null ? '     ' : ticketType.danceRoleType | titlecase }}</span
                >
              </div>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="checkInStatus">
          <th class="checked-in-status-header" mat-header-cell *matHeaderCellDef style="text-align: center">
            {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.CHECKED_IN') }}
          </th>
          <td class="checked-in-status-cell" mat-cell *matCellDef="let row">
            <div class="flex-column align-center align-space-between">
              <div style="display:block; min-width: 85px;"
                class="flex-row"
                *ngFor="let ticketType of row.ticketSaleInformation"
              >
                <div
                  *ngIf="ticketType.checkedInQuantity"
                >
                  <mat-icon style="color: #00b00c">done</mat-icon>
                  <span>{{
                    '(' +
                      ticketType.checkedInQuantity +
                      ' / ' +
                      ticketType.boughtQuantity +
                      ')'
                  }}</span>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="openChat">
          <th class="open-chat-icon-header" mat-header-cell *matHeaderCellDef>
          </th>
          <td class="open-chat-icon-cell" mat-cell *matCellDef="let row">
            <div class="flex-column align-center">
              <div>
                <mat-icon *ngIf="row.user?.id" class="open-chat-icon" (click)="openChat(row.user?.id)">chat_bubble_outline</mat-icon>
                <a *ngIf="!row.user?.id" [href]="'mailto:' + row.user?.email">
                  <mat-icon *ngIf="row.user?.email" fontSet="material-icons-outlined">mail</mat-icon>
                </a>
              </div>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">
            <div class="flex-row align-center my3">
              {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.NO_RESULTS') }}
              {{ guestListDataSource.filter }}
            </div>
          </td>
        </tr>
      </table>
      <!--  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>-->
    </div>
  </div>
  
  <ng-template #emptyGuestList>
    <div class="w-100 my4 py4 flex-column align-center mb1">
    <div class="circle align-center">
      <img
        src="assets/images/events-manager/no_guests.svg"
        alt="no_guests icon"
      />
    </div>
      <span class="no-guests-title mt1">{{ t('DANCE_MANAGER_EVENT.NO_GUESTS_TITLE') }}</span>
      <span class="px3 no-guests-text mt1 mb1">{{ t('DANCE_MANAGER_EVENT.NO_GUESTS_TEXT') }}</span>
    <button
      mat-flat-button
      class="btn-inlined"
      [disabled]="!isDanceEventActive"
      (click)="openAddGuestDialog()"
      id="primary-invite-guest-button"
    >
      <div class="align-center">
        <span style="vertical-align: middle">
          {{ t('DANCE_MANAGER_EVENT.GUEST_LIST_PAGE.INVITE') }}
        </span>
      </div>
    </button>
    </div>
  </ng-template>
  </div>
  
  <ng-template #spinner>
    <div *ngIf="(loading$ | async) === true" class="center mt3">
      <mat-spinner
              style="margin: auto"
              diameter="40"
      ></mat-spinner>
    </div>
  </ng-template>
</ng-container>
