import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelSliderViewComponent } from './level-slider-view.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [LevelSliderViewComponent],
    imports: [CommonModule, TranslocoDirective],
  exports: [LevelSliderViewComponent],
})
export class LevelSliderViewModule {}
