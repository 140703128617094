import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonComponent } from './df-shared-button.component';
import {MatTooltip} from "@angular/material/tooltip";

@NgModule({
  exports: [DfSharedButtonComponent],
  declarations: [DfSharedButtonComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTooltip,
    ],
})
export class DfSharedButtonModule {}
