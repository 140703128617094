import { Component, Input } from '@angular/core';
import { DanceSchoolToUser } from '@platri/df-common-shared-graphql';
import { DanceSchoolToUserInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-team-member-card',
  templateUrl: './team-member-card.component.html',
  styleUrls: ['./team-member-card.component.scss'],
})
export class TeamMemberCardComponent {
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  @Input() danceSchoolToUser: DanceSchoolToUserInterface;
}
