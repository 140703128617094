import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelSliderMaterialComponent } from './level-slider-material.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {SliderModule} from "primeng/slider";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSliderModule} from "@angular/material/slider";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [LevelSliderMaterialComponent],
    imports: [CommonModule, MatProgressSpinnerModule, SliderModule, FormsModule, NgxSkeletonLoaderModule, MatFormFieldModule, MatSliderModule, ReactiveFormsModule, TranslocoDirective],
  exports: [LevelSliderMaterialComponent],
})
export class LevelSliderMaterialModule {}
