import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ConfirmationDialogComponent,
  DanceSchoolStateService,
  getSubscription,
  TicketHttpService,
  TicketStateService
} from '@platri/dfx-angular-core';
import { utcToZonedTime } from 'date-fns-tz';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import {
  DanceEventsInterface,
  FeesPaidByEnum,
  isAppointmentActive,
  TicketInterface,
  TicketTypeEnum,
  UserAppLangEnum
} from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-event-ticket-list',
  templateUrl: './dfm-event-ticket-list.component.html',
  styleUrls: ['./dfm-event-ticket-list.component.scss']
})
export class DfmEventTicketListComponent implements OnInit, OnDestroy {
  danceEvent: DanceEventsInterface;
  tickets: TicketInterface[] = [];

  ticketTypeEnum = TicketTypeEnum;
  feesPaidByEnum = FeesPaidByEnum;

  currentDate: Date;
  currentLang: UserAppLangEnum;
  canCreateTickets = true;

  expand: boolean[] = [false];

  private subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute, 
    private readonly router: Router, 
    private readonly ticketService: TicketStateService, 
    private readonly ticketHttpService: TicketHttpService, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly translocoService: TranslocoService, 
    private readonly danceSchoolService: DanceSchoolStateService, 
    private readonly matDialog: MatDialog,
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.getDanceEventFromRoute();
    this.getTicketsOfDanceEvent();
    this.canCreateTicketsCheck();
  }

  ngOnDestroy(): void {
    this.ticketService.clear();
    this.subscription.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscription.add(getSubscription(this.ticketService.getAsyncTickets(), this.onTicketsChange.bind(this)));
  }
  
  canCreateTicketsCheck(): void {
    this.danceEvent.appointments.forEach((value) => {
      this.canCreateTickets = isAppointmentActive(value);
    });
  }

  onTicketsChange(tickets: TicketInterface[]): void {
    if (tickets?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.currentDate = utcToZonedTime(new Date(), tickets[0].eventTimezone!);
      this.currentDate = new Date(this.currentDate.getTime() - this.currentDate.getTimezoneOffset() * 60000);
      this.tickets = tickets;
      for (const ticket of this.tickets) {
        ticket.saleStart =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ticket.saleStart == null ? new Date(ticket.createdAt) : ticket.saleStart;
        ticket.saleEnd =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ticket.saleEnd == null ? new Date(this.danceEvent.appointments[0]?.startDate) : ticket.saleEnd;
      }
    }
  }

  getDanceEventFromRoute(): void {
    this.danceEvent = this.route.snapshot.data.targetObject;
  }

  getTicketsOfDanceEvent(): void {
    this.ticketService
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .getAllByDanceEvent(this.danceEvent.id!, true);
  }

  onMouseDropEvent(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.tickets, event.previousIndex, event.currentIndex);
      this.updateSequenceNumber();
    }
  }

  updateSequenceNumber(): void {
    const newSequence: { id: string; sequenceNumber: number }[] = [];

    this.tickets.forEach((ticket, index) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newSequence.push({ id: ticket.id!, sequenceNumber: index });
    });

    this.subscription.add(
      this.ticketHttpService.updateSequenceNumber(this.danceEvent.id!, newSequence).subscribe({
        next: () => {
          const message = this.translocoService.translate('DANCE_MANAGER_EVENT.TICKET_SEQUENCE_UPDATED');
          this.matSnackBar.open(message);
        },
        error: (err) => {
          this.matSnackBar.open("Couldn't save the new sequence of the tickets");
        }
      })
    );
  }

  expandCard(index: number): void {
    this.expand[index] = !this.expand[index];
  }

  navigateToCreateTicketPage(ticket?: TicketInterface): void {
    if (ticket) {
      this.ticketService.sendTicketToDuplicate(ticket);
    }
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'event-tickets', 'events', this.danceEvent.id, 'create']);
  }

  navigateToEditTicketPage(id: string): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'event-tickets', 'events', id, 'edit']);
  }

  deleteTicket(ticket: TicketInterface): void {
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: {
          cancelButtonColor: '',
          cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
          submitButtonColor: 'warn',
          submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
          text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_DIALOG_TEXT'),
        }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.ticketHttpService.delete(ticket.id).subscribe({
            next: (res) => {
              this.getTicketsOfDanceEvent();
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_SUCCESS'));
            },
            error: (error) => {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_ERROR'));
            }
          });
        }
      });
  }
}
