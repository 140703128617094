import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterPageComponent } from './register-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { LoginFormModule, RegisterFormModule } from '../../components';
import { RouterModule } from '@angular/router';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [RegisterPageComponent],
    imports: [
      CommonModule,
      RouterModule.forChild([{
          path: '',
          component: RegisterPageComponent
      }]),
      RegisterFormModule,
      LoginFormModule,
      MatDividerModule,
      TranslocoDirective
    ],
  exports: [RegisterPageComponent]
})
export class RegisterPageModule { }
