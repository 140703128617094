import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {  UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {  Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DanceManagerCategoryEnum } from '@platri/df-common-shared-graphql';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-search-dm-category',
  templateUrl: './search-dm-category.component.html',
  styleUrls: ['./search-dm-category.component.scss'],
})
export class SearchDmCategoryComponent implements OnInit, OnDestroy {
  @Input() endpointUrl: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() showChips = false;
  @Input() returnStrings = false;
  @Input() selectedFilters: DanceManagerCategoryEnum[];

  @Output() selectedFiltersChanged = new EventEmitter<DanceManagerCategoryEnum[]>();

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('categoryInput') categoryInput: ElementRef;
  
  searchCategoryInput: UntypedFormControl;
  originalCategoriesList: string[];
  filteredCategoriesList: any;
 
  private subscription = new Subscription();
  constructor(private readonly formBuilder: UntypedFormBuilder, private cd: ChangeDetectorRef,private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.searchCategoryInput = this.formBuilder.control({
      value: '',
      disabled: true,
    }, []);
    this.getCategories();
    this.getFilteredCategories();
  }


  getFilteredCategories(): void {
    this.subscription.add(
      this.searchCategoryInput.valueChanges
        .pipe(debounceTime(250))
        .subscribe((input: string | DanceManagerCategoryEnum) => {
          this.translocoService
            .getTranslation('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES')
            .subscribe((translations) => {
              this.originalCategoriesList = structuredClone(Object(translations));
              this.filteredCategoriesList = {};
              const searchValue = input.toLowerCase();
              Object.keys(this.originalCategoriesList).forEach((key) => {
                const value = this.originalCategoriesList[key].toLowerCase();
                if (value.includes(searchValue)) {
                  this.filteredCategoriesList[key] = this.originalCategoriesList[key];
                }
              });
            });
          this.cd.detectChanges();
        })
    );
  }

  getCategories(): void {
    this.subscription.add(
    this.translocoService
      .getTranslation('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES')
      .subscribe((translations) => {
        this.originalCategoriesList = structuredClone(Object(translations));
        this.filteredCategoriesList = this.originalCategoriesList;
      })
    );
  }
  
  selectCategory(key: string, value: string ): void {
    if (this.selectedFilters && !this.selectedFilters.includes(key as DanceManagerCategoryEnum)) {
        this.selectedFilters = this.selectedFilters?.concat([key as DanceManagerCategoryEnum]);
    } else if (this.selectedFilters === null || this.selectedFilters?.length === 0) {
        this.selectedFilters = [key as DanceManagerCategoryEnum];
    }
    this.getFilteredCategories();
    this.selectedFiltersChanged.emit(this.selectedFilters);
  }

  removeSelectedCategory(i: number): void {
    if (i >= 0) {
      this.selectedFilters.splice(i, 1);
      this.selectedFilters = [...this.selectedFilters];
      if (this.selectedFilters.length > 0) {
        this.selectedFiltersChanged.emit(this.selectedFilters);
      } else {
        this.selectedFiltersChanged.emit(null);
      }
    }
  }

  checkCategoryClosed(): void {
    this.searchCategoryInput.setValue('');
    this.categoryInput.nativeElement.value='';
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
  
}
