export enum UserAppLangEnum{
  DE = 'DE',
  EN = 'EN',
  IT = 'IT',
  ES = 'ES',
  FR = 'FR',
  NL = 'NL',
  LB = 'LB',
  PL = 'PL'
}
