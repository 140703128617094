<ng-container *transloco="let t">
 <ng-container [formGroup]="searchUserForm">
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-100">
      <mat-label><mat-icon class="icon-display" style="vertical-align: middle;">search</mat-icon> {{ t(label) }}</mat-label>
      <input
        [matAutocomplete]="auto"
        formControlName="user"
        autocomplete="off"
        matInput
        id="user-autocomplete-input"
        placeholder="{{ t(placeholder) }}"
      />
      <mat-autocomplete #auto="matAutocomplete" hideSingleSelectionIndicator>
        <mat-option *ngIf="showUserSearchLoading">
          <div class="flex-row-center-center">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let user of userOptions"
          [value]="user.value"
          (onSelectionChange)="emitSelectedUsername(user)"
        >
          <div class="flex-row-start-center" style="gap: 8px;">
            <img
              class="profilePicture flex-hide-lt-sm"
              imageFallback
              [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
              [src]="
                user.imageUrl && user.imageUrl !== '' ? user.imageUrl :
                (user.isDanceSchool
                  ? 'assets/images/studio_logo.png'
                  : DEFAULT_PROFILE_PICTURE_PLACEHOLDER)
              "
              [alt]="'PROFILE_PICTURE'"
            />
            <strong> {{ user.displayValue }}</strong>
            <ng-template *ngIf="(user.firstName && user.lastName) || user.info ">
              <span>|</span>
              <small *ngIf="user.firstName && user.lastName">{{ user.firstName }} {{user.lastName}}</small>
              <small *ngIf="user.info">{{ user.info }}</small>
            </ng-template>
          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-error>{{ t(errorMessage) }}</mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>
