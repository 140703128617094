<ng-container *transloco="let t">
  <div class="flex-column-center-center" style="overflow: hidden">
    <div class="flex-row-center-center circle mt3">
      <img
        src="assets/images/events-manager/no_events.svg"
        alt="no_events icon"
      />
    </div>
    <div class="pt2 w-100 h-100 text-container flex-column-center-center">
      <span class="empty-dance-event-list-title">{{ t('GENERIC_WRAPPER.NO_EVENTS') }}</span>
      <span class="pt1 empty-dance-event-list-text">{{ t('DANCE_MANAGER_EVENT.NO_EVENTS_TEXT') }}</span>
    </div>
    <div class="pt2">
      <df-shared-lib-button 
        [buttonLabel]="t('DANCE_MANAGER_EVENT.NEW_EVENT')" 
        (buttonClicked)="navigateToCreatePage()"
      ></df-shared-lib-button>
    </div>
  </div>
</ng-container>
