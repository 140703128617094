<ng-container *transloco="let t">
  <div [formGroup]="formGroup" class="flex-column gap-10">
    <div class="w-100-children flex-direction-swap-row-to-column change-gap-on-mobile">
      <mat-form-field appearance="outline">
        <mat-label>{{t('GENERIC_WRAPPER.LOCATIONS')}}</mat-label>
        <mat-select
          formControlName="locationId"
          disableOptionCentering>
          <mat-option
            *ngFor="let location of locations"
            [value]="location.id">{{ location.name }}</mat-option>
          <mat-option [value]="''">{{t('GENERIC_WRAPPER.ANOTHER_ADDRESS')}}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="locationId.hasError('required')">{{t('GENERIC_WRAPPER.REQUIRED_LOCATION_OR_ADDRESS')}}</mat-error>
      </mat-form-field>
  
      <mat-form-field
        appearance="outline"
        *ngIf="rooms?.length > 0"
      >
        <mat-label>{{t('GENERIC_WRAPPER.ROOM_OPTIONAL')}}</mat-label>
        <mat-select
          formControlName="roomId"
          disableOptionCentering
        >
          <mat-option [value]="null">{{t('GENERIC_WRAPPER.NONE')}}</mat-option>
          <mat-option
            *ngFor="let room of rooms"
            [value]="room.id"
          >{{ room.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
  
      <df-shared-lib-google-address-search-autocomplete
        *ngIf="locationId.value === ''"
        [address]="formGroup.controls.address.value"
        (addressChanging)="onAddressSelected()"
        (addressSelected)="onAddressSelected($event)"
        style="flex-grow: 1;"
        [types]="['address']"
      >
      </df-shared-lib-google-address-search-autocomplete>
    </div>
  </div>
</ng-container>
