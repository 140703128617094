import {format, parseISO} from 'date-fns';
import {de} from 'date-fns/locale';
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'weekdayNamePipe'
})
export class WeekdayNamePipe implements PipeTransform {
  transform(value: Date): string {
    if (value === null) {
      return null;
    }
    return format(parseISO(value.toString()), 'EEEE', {locale: de});
  }
}
