import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { UserAppLangEnum } from '@platri/df-common-core';

@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {

  transform(
    startDate: Date, 
    durationInMinutes: number, 
    locale: UserAppLangEnum = UserAppLangEnum.DE,
    split?: 'start' | 'end'
  ): string {
    const startDateTime = DateTime.fromJSDate(new Date(startDate)).setZone('UTC').setLocale(locale  === UserAppLangEnum.DE ? 'de' : 'en');

    const endDateTime = startDateTime.plus({ minutes: durationInMinutes });

    const timeFormat = locale === UserAppLangEnum.EN ? 'hh:mm a' : 'HH:mm';
    
    const startFormat = locale === UserAppLangEnum.DE
                        ? startDateTime.toFormat(`ccc. d. LLL yyyy, ${timeFormat}`)
                        : startDateTime.toFormat(`ccc, LLL d, yyyy, ${timeFormat}`);

    let endFormat: string;

    if (+durationInMinutes !== -1) {
      if (startDateTime.toFormat('yyyyLLdd') === endDateTime.toFormat('yyyyLLdd')) {
        endFormat = endDateTime.toFormat(timeFormat);
      }
      else {
        endFormat = locale === UserAppLangEnum.DE
                    ? endDateTime.toFormat(`ccc. d. LLL yyyy, ${timeFormat}`)
                    : endDateTime.toFormat(`ccc, LLL d, yyyy, ${timeFormat}`);
      }
    }
    
    switch (split) {
      case 'start':
        return `${startFormat}${+durationInMinutes === -1 ? '' : ' Uhr'}`;
      case 'end':
        return `${endFormat} ${locale === UserAppLangEnum.DE ? ' Uhr' : ''}`;
      default:
        return `${startFormat}${+durationInMinutes === -1 ? '' : ' Uhr - ' + endFormat} ${locale === UserAppLangEnum.DE ? ' Uhr' : ''}`;
    }
  }
}
