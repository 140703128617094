import {Pipe, PipeTransform} from '@angular/core';
import {addDays, addHours, addMinutes, differenceInHours} from "date-fns";
import { Time } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'timeDiff',
})
export class TimeDiffPipe implements PipeTransform {
  transform(value: { timeFrom: Time; timeTo: Time }): number {
    if (value === null) {
      return null;
    }
    let startTimeDate = new Date();
    startTimeDate = addHours(startTimeDate, value.timeFrom.hours);
    startTimeDate = addMinutes(startTimeDate, value.timeFrom.minutes);

    let endTimeDate = new Date();
    endTimeDate = addHours(endTimeDate, value.timeTo.hours);
    endTimeDate = addMinutes(endTimeDate, value.timeTo.minutes);

    if (value.timeTo.hours < value.timeFrom.hours || value.timeTo.hours === value.timeFrom.hours && value.timeTo.minutes <= value.timeFrom.minutes) {
      endTimeDate = addDays(endTimeDate, 1);
    }
    return differenceInHours(endTimeDate, startTimeDate);
  }
}
