<ng-container *transloco="let t">
  <div
    class="flex-column pl3 pr3 pt2 align-center"
    style="min-height: calc(100vh - 56px)"
  >
    <div class="w-100 flex-column flex-container">
      <div class="breadcrumb-container">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb; let i = index;">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="flex-row" style="justify-content: center; box-sizing: border-box;">
        <mat-card
          class="round-corners mat-elevation-z3 mt2 event-show event-show-respon w-100"
        >
          <div class="mat-card-padding-mobile px3" style="padding-bottom: 32px; padding-top: 32px;">
            <div class="flex-row align-start-center">
              <span class="event-title">{{ danceEvent?.name }}</span>
              <button mat-icon-button (click)="routeToEvent()" [matTooltip]="t('DANCE_MANAGER_EVENT.OPEN_EVENT_PAGE')">
                <mat-icon>open_in_new</mat-icon>
              </button>
            </div>
            <mat-tab-group
              [selectedIndex]="activeTabIndex"
              (selectedIndexChange)="changeHashTag($event)"
            >
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.PREVIEW') }}">
                <ng-template matTabContent>
                  <div style="overflow: hidden; margin-top: 15px;" class="mb2">
                    <df-monorepo-event-page
                      [danceEvent]="danceEvent"
                    ></df-monorepo-event-page>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.INFO') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <df-add-edit-event-general-info
                      [showCancelButton]="false"
                      [submitButtonLabel]="t('GENERIC_WRAPPER.SAVE')"
                      [formGroup]="generalInfoFormGroup"
                      (onSubmitClick)="updateGeneralInfoOfEvent()"
                      [danceEvent]="danceEvent"
                    ></df-add-edit-event-general-info>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.DATE_AND_LOCATION') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <df-add-edit-event-appointment-location
                      [showCancelButton]="false"
                      [submitButtonLabel]="t('GENERIC_WRAPPER.SAVE')"
                      [formGroup]="appointmentAndLocationFormGroup"
                      [locations]="locations"
                      (submitClickEventEmitter)="updateAppointmentAndLocationOfEvent()"
                    ></df-add-edit-event-appointment-location>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.RULES') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <df-add-edit-event-rules
                      [formGroup]="rulesFormGroup"
                      (onSubmitClick)="updateRulesOfEvent()"
                    ></df-add-edit-event-rules>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('GENERIC_WRAPPER.TICKETS') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <div
                      class="w-100 h-100"
                      *ngIf="ticketsFeatureActive; else featureInactiveInfo"
                    >
                      <dfm-event-ticket-list>
                      </dfm-event-ticket-list>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
  
              <mat-tab label="{{ t('DANCE_MANAGER_EVENT.GUESTLIST') }}">
                <ng-template matTabContent>
                  <div class="mt3">
                    <div
                      class="w-100 h-100"
                      *ngIf="ticketsFeatureActive; else featureInactiveInfo"
                    >
                      <dfm-event-guest-list-table
                        [danceEvent]="danceEvent"
                      ></dfm-event-guest-list-table>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  
  <ng-template #featureInactiveInfo>
    <div
      class="w-100 my4 py4 flex-column-center-center"
      style="gap: 15px;"
    >
      <span class="bold fs18">{{ t('DANCE_MANAGER_EVENT.FEATURE_NOT_ACTIVATED_TITLE') }}</span>
      <span class="fs16">{{ t('DANCE_MANAGER_EVENT.FEATURE_NOT_ACTIVATED_SUBTITLE') }}</span>
      <df-shared-lib-button
        [buttonLabel]="t('DANCE_MANAGER_EVENT.GO_TO')"
        (buttonClicked)="routeToPackages()"
      ></df-shared-lib-button>
    </div>
  </ng-template>
  
  <ng-template #emptyGuestList>
    <div
      class="w-100 my4 py4 flex-column-center-center"
      style="gap: 15px;"
    >
    <div class="circle mt3 flex-row-center-center">
      <img
        src="assets/images/events-manager/no_guests.svg"
        alt="no_guests icon"
      />
    </div>
      <span class="bold fs18">{{ t('DANCE_MANAGER_EVENT.NO_GUESTS_TITLE') }}</span>
      <span class="fs16">{{ t('DANCE_MANAGER_EVENT.NO_GUESTS_TEXT') }}</span>
      <df-shared-lib-button
        [buttonLabel]="t('DANCE_MANAGER_EVENT.NO_GUESTS_BTN')"
        (buttonClicked)="routeToPackages()" 
      ></df-shared-lib-button>
    </div>
  </ng-template>
</ng-container>
