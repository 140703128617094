<ng-container *transloco="let t">
  <div *ngIf="danceEvent" class="dance-class-header relative">
    <div [ngClass]="isOwnerOrAdmin ? 'editable' : 'read-only'" class="dance-class-header-highlighter" (click)="openTitleDialog()">
      <div class="flex-row-center-center h-100">
        <mat-icon fontSet="material-icons-outlined">photo_camera</mat-icon>
      </div>
    </div>
  
    <button mat-icon-button *ngIf="isOwnerOrAdmin && danceEvent.mainImage && danceEvent.mainImage.url" class="delete-button" (click)="confirmDelete()">
      <mat-icon class="x-symbol">clear</mat-icon>
    </button>
    <img [src]="danceEvent.mainImage?.url ?? DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER" alt="" [class]="isOwnerOrAdmin ? 'editable' : 'read-only'" class="dance-class-title-image"/>
  </div>
</ng-container>
