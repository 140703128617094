<div class="div-style">
  <span class="material-icons-round">
sentiment_dissatisfied
</span>
  <h1>Error 401</h1>
  <p>
   Sorry,but you are not authorized to
    <br />
    dance here,please
    <br />
    <br />
    <a href="https://www.danceflavors.com">Go back to Danceflavors.com</a>
  </p>
</div>
