<ng-container *transloco="let t">
  <ng-container *ngIf="(loading$ | async) === false else spinner" >
    <span class="appointment-title"> 
      {{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TITLE')}} ({{filteredAppointments?.length}})
    </span>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-100">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{t('GENERIC_WRAPPER.DATE')}}</th>
        <td mat-cell *matCellDef="let element"> 
          <span class="fs14">
            {{ element.date | dateTimezoned: 'UTC' : currentLang : element.duration :true }}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>{{t('DANCE_MANAGER_COURSE.TABLE_COLUMNS.DURATION')}}</th>
        <td mat-cell *matCellDef="let element"> 
            <span class="fs14">
              {{ element.date | dateTimezoned: 'UTC': currentLang : element.duration :false:true }}
            </span>
         </td>
      </ng-container>
      <ng-container matColumnDef="teachers">
        <th mat-header-cell *matHeaderCellDef >{{t('DANCE_MANAGER_COURSE.TABLE_COLUMNS.TEACHER')}}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngFor="let teacher of element.teachers; let last = last;">
            <span (click)="$event.stopPropagation()" [routerLink]="['/', '@' + teacher.username]" class="link">{{ teacher?.firstName ?? teacher.username }}</span>
            <span *ngIf="!last">, </span>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>{{t('DANCE_MANAGER_COURSE.LOCATION')}}</th>
        <td mat-cell *matCellDef="let element" class="ellipsis-column">
          <ng-container *ngIf="element.location" [matTooltip]="element.location.name">
            <span [matTooltip]="element.location.name">{{ element.location.name }}</span>
          </ng-container>
          <ng-container *ngIf="!element.location" >
            <span [matTooltip]="element.address.formattedAddress">{{ element.address.formattedAddress }}</span>
          </ng-container>
        </td> 
      </ng-container>
      <ng-container matColumnDef="maxParticipants">
        <th mat-header-cell *matHeaderCellDef>{{t('DANCE_MANAGER_COURSE.TABLE_COLUMNS.MAX_PARTICIPANTS')}}</th>
        <td mat-cell *matCellDef="let element"> {{ element.maxParticipants }} </td>
      </ng-container>
      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row" style="justify-content: flex-end !important;">
            <button *ngIf="element.description !== null" mat-icon-button (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
             <mat-icon>{{ expandedElement === element ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
            <button (click)="$event.stopPropagation();" mat-icon-button [matMenuTriggerFor]="menu" class="flex-row-start-center">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="navigateToEditAppointmentsPage(element)" mat-menu-item>
                <span>{{t('GENERIC_WRAPPER.EDIT')}}</span>
              </button>
              <button (click)="openDeleteConfirmationDialog(element)" mat-menu-item>
                <span>{{t('GENERIC_WRAPPER.REMOVE')}}</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedAppointment">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="appointment-description"
               [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <div class="pbt-16">
              <div [innerHTML]="element.description"></div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="example-element-row clickable-row cursor-pointer" mat-row *matRowDef="let element; columns: displayedColumns" (click)="onAppointmentClick(element)"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedAppointment']" class="expanded-appointment-row"></tr>
    </table>
  </ng-container>
  
  <ng-template #spinner>
    <div class="loading-box w-100" *ngIf="(loading$ | async) === true">
      <mat-spinner style="margin: auto" diameter="100"></mat-spinner>
    </div>
  </ng-template>
</ng-container>
