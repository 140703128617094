import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OpeningHoursInterface, TimeRange } from '@platri/df-common-shared-graphql';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-open-hours-dialog',
  templateUrl: './open-hours-dialog.component.html',
  styleUrls: ['./open-hours-dialog.component.scss'],
})
export class OpenHoursDialogComponent implements OnInit {
  public objectKeys = Object.keys;
  valid: boolean;
  errorKey: string;
  errorTranslationKey: string;
  openingHoursData: OpeningHoursInterface;
  constructor(
    private readonly dialogRef: MatDialogRef<OpenHoursDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.setOpeningHoursData();
  }

  private setOpeningHoursData(): void {
    this.openingHoursData = this.data.openingHoursData;
    if (!this.openingHoursData) {
      this.setDefaultOpeningHours();
    }
  }

  private setDefaultOpeningHours(): void {
    this.openingHoursData = {
      monday: [this.defaultOpeningHoursDay],
      tuesday: [this.defaultOpeningHoursDay],
      wednesday: [this.defaultOpeningHoursDay],
      thursday: [this.defaultOpeningHoursDay],
      friday: [this.defaultOpeningHoursDay],
      saturday: [this.defaultOpeningHoursDay],
      sunday: [this.defaultOpeningHoursDay],
    };
  }

  public toggleOpeningHoursDay(event: any, dayName: string): void {
    if (!event.checked) {
      this.openingHoursData[dayName] = [];
    } else {
      this.openingHoursData[dayName] = [this.defaultOpeningHoursDay];
    }
  }

  public addOpeningHoursForDay(dayName: string): void {
    this.openingHoursData[dayName].push({
      timeStart: {
        hours: this.openingHoursData[dayName][0].timeEnd.hours + 1,
        minutes: 0,
      },
      timeEnd: {
        hours: this.openingHoursData[dayName][0].timeEnd.hours + 2,
        minutes: 0,
      },
    });
  }

  public deleteOpeningHoursForDay(dayName: string, index: number): void {
    this.openingHoursData[dayName].splice(index, 1);
  }

  public sendOpeningHoursData(): void {
    this.valid = this.validateOpeningHours(this.openingHoursData);
    if (this.valid) {
      this.dialogRef.close(this.openingHoursData);
    }
    else {
      this.matSnackBar.open(
        this.translocoService.translate('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.SNACKBAR_ERROR_INVALID_OPENING_HOURS')
      );
    }
  }

  validateOpeningHours(openingHours: OpeningHoursInterface): boolean {
    let valid = true;
    for (const key in openingHours) {
      if (openingHours[key].length > 1) {
        const timeRangeFirstDay: TimeRange = openingHours[key][0];
        const timeRangeSecondDay: TimeRange = openingHours[key][1];
        if (
          this.checkIfTimeRangesOverlap(timeRangeFirstDay, timeRangeSecondDay)
        ) {
          this.errorKey = key;
          this.errorTranslationKey = 'overlap_error';
          return false;
        }
      }
      openingHours[key].forEach((timeRange: TimeRange) => {
        if (
          timeRange.timeEnd.hours < timeRange.timeStart.hours ||
          (timeRange.timeEnd.hours === timeRange.timeStart.hours &&
            timeRange.timeEnd.minutes <= timeRange.timeStart.minutes)
        ) {
          this.errorKey = key;
          this.errorTranslationKey = 'end_time_before_start_time';
          valid = false;
        }
      });
    }
    return valid;
  }

  checkIfTimeRangesOverlap(
    timeRangeA: TimeRange,
    timeRangeB: TimeRange
  ): boolean {
    return (
      ((timeRangeA.timeStart.hours < timeRangeB.timeEnd.hours ||
        (timeRangeA.timeStart.hours === timeRangeB.timeEnd.hours &&
          timeRangeA.timeStart.minutes <= timeRangeB.timeEnd.minutes)) &&
        (timeRangeB.timeStart.hours < timeRangeA.timeEnd.hours ||
          (timeRangeB.timeStart.hours === timeRangeA.timeEnd.hours &&
            timeRangeB.timeStart.minutes <= timeRangeA.timeEnd.minutes))) ||
      !(
        timeRangeB.timeEnd.hours < timeRangeA.timeStart.hours ||
        (timeRangeB.timeEnd.hours === timeRangeA.timeStart.hours &&
          timeRangeB.timeEnd.minutes < timeRangeA.timeStart.minutes) ||
        ((timeRangeB.timeEnd.hours > timeRangeA.timeEnd.hours ||
          (timeRangeB.timeEnd.hours === timeRangeA.timeEnd.hours &&
            timeRangeB.timeEnd.minutes > timeRangeA.timeEnd.minutes)) &&
          (timeRangeB.timeEnd.hours > timeRangeB.timeStart.hours ||
            (timeRangeB.timeEnd.hours === timeRangeB.timeStart.hours &&
              timeRangeB.timeEnd.minutes > timeRangeB.timeStart.minutes)))
      )
    );
  }

  private get defaultOpeningHoursDay(): TimeRange {
    return {
      timeStart: {
        hours: 9,
        minutes: 0,
      },
      timeEnd: {
        hours: 18,
        minutes: 0,
      },
    };
  }

  setOpeningHoursDataFormat(
    openingHoursDateElement: TimeRange,
    $event: string,
    start: boolean
  ) {
    const helperArray = $event.split(':');
    if (start) {
      openingHoursDateElement.timeStart.hours = +helperArray[0];
      openingHoursDateElement.timeStart.minutes = +helperArray[1];
    } else {
      openingHoursDateElement.timeEnd.hours = +helperArray[0];
      openingHoursDateElement.timeEnd.minutes = +helperArray[1];
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
