<ng-container *transloco="let t">
  <div style="gap: 12px; display: flex; flex-direction: column;" class="p1">
    <div class="flex-row-end-center">
      <button mat-icon-button class="cursor-pointer gray-color" mat-dialog-close="true"><mat-icon class="delete-icon">clear</mat-icon>
      </button>
    </div>
    <div class="flex-row-center-center" style="position: relative; padding-bottom: 24px;">
      <img imageFallback class="img-circle-icon" [src]="currentProfileImage ?? DEFAULT_PROFILE_PICTURE_PLACEHOLDER" [alt]="t('GENERIC_WRAPPER.PROFILE_PICTURE')" [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
      <div class="img-circle-progress-bar">
        <mat-progress-spinner [value]="completionRate" [diameter]="150" [strokeWidth]="5"></mat-progress-spinner>
      </div>
      <div class="img-circle-text">
        <span class="incomplete-text-chip">{{ t('USER.COMPLETE_PROFILE_DIALOG.INCOMPLETE') }}</span>
      </div>
    </div>
    <span class="flex-row-center-center complete-profile-dialog-title">{{ t('USER.COMPLETE_PROFILE_DIALOG.PROFILE_COMPLETION') }}</span>
    <span class="flex-row-center-center complete-profile-dialog-text">{{ t('USER.COMPLETE_PROFILE_DIALOG.EXPLANATION') }}</span>
    <div class="flex-row-center-center" style="gap: 8px;">
      <mat-checkbox class="flex-row-center-center" style="color: #808080" (click)="changeShowCompleteProfileDialog()"></mat-checkbox>
      <span class="complete-profile-checkbox-text cursor-pointer" (click)="changeShowCompleteProfileDialog()">{{ t('USER.COMPLETE_PROFILE_DIALOG.DONT_SHOW_ME_AGAIN') }}</span>
    </div>
    <df-shared-lib-button [buttonLabel]="t('USER.COMPLETE_PROFILE_DIALOG.LETS_DO_IT') " (buttonClicked)="routeToProfileSettings()" mat-dialog-close="true"></df-shared-lib-button>
    <div class="flex-row-center-center pt-5">
       <span class="cursor-pointer ill-do-it-later-text" mat-dialog-close="true" >{{ t('USER.COMPLETE_PROFILE_DIALOG.ILL_DO_IT_LATER') }}</span>
    </div>
  </div>
</ng-container>
