import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { ConversationComponent } from './conversation.component';
import { EmojisModule } from '../emojis';
import { MessageModule } from '../message';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { DfQuillMentionMessengerModule } from '../../df-quill-mention-messenger';
import { PipesModule } from '../../../pipes';
import { ImageFallbackDirectiveModule } from '../../../directives';

@NgModule({
  declarations: [ConversationComponent],
    imports: [
      CommonModule,
      FormsModule,
      MatButtonModule,
      MatIconModule,
      MatInputModule,
      RouterModule,
      MatProgressSpinnerModule,
      PipesModule,
      MatTooltipModule,
      TranslocoModule,
      EmojisModule,
      MatMenuModule,
      MessageModule,
      DfQuillMentionMessengerModule,
      ImageFallbackDirectiveModule,
    ],
  exports: [ConversationComponent],
})
export class ConversationModule {}
