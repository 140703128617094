import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CourseInterface } from '@platri/df-common-core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'df-add-appointment-dialog',
  templateUrl: 'add-appointment-dialog.component.html',
  styleUrls: ['./add-appointment-dialog.component.scss'],
})
export class AddAppointmentDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AddAppointmentDialogComponent>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    
    @Inject(MAT_DIALOG_DATA)
    public data: {
      course: CourseInterface;
      title: string;
      infoText: string;
      cancelText: string;
      confirmText: string;
    }){}

  close(reasonStay = true): void {
    this.dialogRef.close(reasonStay);
  }
  confirmAction(): void {
    this.switchToAppointment();
  }

  switchToAppointment(): void {
    window.location.hash = '#appointments';
  }
}
