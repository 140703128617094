import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import { LocationRoomAddressSelectionComponent } from './location-room-address-selection.component';
import { GoogleAddressSearchAutocompleteModule } from '../google-address-search-autocomplete';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
    declarations: [
        LocationRoomAddressSelectionComponent
    ],
    imports: [
      CommonModule,
      MatFormFieldModule,
      MatSelectModule,
      ReactiveFormsModule,
      GoogleAddressSearchAutocompleteModule,
      TranslocoDirective,
    ],
    exports: [
        LocationRoomAddressSelectionComponent
    ]
})
export class LocationRoomAddressSelectionModule {
}
