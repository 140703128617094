import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedClickCheckElementDirective } from './unauthorized-click-check-element.directive';

@NgModule({
  declarations: [UnauthorizedClickCheckElementDirective],
  imports: [CommonModule],
  exports: [UnauthorizedClickCheckElementDirective],
})
export class UnauthorizedClickCheckElementModule {}
