import { ImageDto } from './image.dto';
import { DanceSchoolRoomDto } from './dance-school-room.dto';
import { RoomInterface } from '../../..';
import { AmenityDto } from './amenity.dto';

export class UpdateDanceSchoolRoomRequestDto implements RoomInterface {
  name: string;
  size: number;
  images: ImageDto[];
  amenities: AmenityDto[];
  rooms: Required<DanceSchoolRoomDto[]>;
}
