import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCheckInPassDialogComponent } from './select-check-in-pass-dialog.component';
import { FormsModule } from '@angular/forms';
import { DfSharedButtonModule } from '../buttons';
import { TranslocoDirective } from "@jsverse/transloco";
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  exports: [SelectCheckInPassDialogComponent],
  declarations: [SelectCheckInPassDialogComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    FormsModule,
    MatRadioGroup,
    MatRadioButton,
    DfSharedButtonModule,
    MatDialogModule,
  ]
})
export class SelectCheckInPassDialogModule {}
