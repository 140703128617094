import { DanceManagerUserRoleEnum } from '../enums';

export const ALLOWED_SCANNER_ROlES = [
  DanceManagerUserRoleEnum.ADMIN,
  DanceManagerUserRoleEnum.OWNER,
  DanceManagerUserRoleEnum.MANAGER,
  DanceManagerUserRoleEnum.TEACHER,
  DanceManagerUserRoleEnum.SALES,
  DanceManagerUserRoleEnum.SCANNER,
  DanceManagerUserRoleEnum.BARKEEPER
];
