import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DanceEventHttpService, DanceEventStateService, DanceSchoolStateService, getSubscription, TicketStateService } from '@platri/dfx-angular-core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DanceEventsInterface, DanceEventTimePhaseEnum, DanceSchoolInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-events-manager',
  templateUrl: './dfm-event.page.html',
  styleUrls: ['./dfm-event.page.scss']
})
export class DfmEventPage implements OnInit, OnDestroy {
  danceSchool: DanceSchoolInterface;
  danceEvents: DanceEventsInterface[];

  danceEventListTabEnum = DanceEventTimePhaseEnum;
  currenTabIndex = 0;



  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private danceSchoolService: DanceSchoolStateService, 
    public danceEventService: DanceEventStateService, 
    private cd: ChangeDetectorRef, 
    private ticketService: TicketStateService,
    private danceEventHttpService: DanceEventHttpService
  ) {}

  ngOnInit():void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions():void {
    this.subscriptions.add(getSubscription(this.danceSchoolService.getAsyncCurrentDanceSchool(), this.onDanceSchoolChangeSuccessful.bind(this)));
    this.subscriptions.add(getSubscription(this.danceEventService.danceEventsSubject$, this.onDanceEventsChangeSuccessful.bind(this)));
  }

  onDanceSchoolChangeSuccessful(danceSchool: DanceSchoolInterface): void {
    this.danceSchool = danceSchool;
    this.danceEventService.getAllByDanceStudioIdAndTimePhase(danceSchool.id, DanceEventTimePhaseEnum.UPCOMING);
  }

  onDanceEventsChangeSuccessful(danceEvents: DanceEventsInterface[]): void {
    this.danceEvents = danceEvents;
  }

  navigateToCreatePage(): void {
    this.router.navigate(['create'], { relativeTo: this.route });
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currenTabIndex = event.index;
    this.loadDanceEventsForTab(this.currenTabIndex);
  }

  loadDanceEventsForTab(index: number): void {
    switch (index) {
      case 0:
        this.danceEventService.getAllByDanceStudioIdAndTimePhase(this.danceSchool.id, DanceEventTimePhaseEnum.UPCOMING);
        break;
      case 1:
        this.danceEventService.getAllByDanceStudioIdAndTimePhase(this.danceSchool.id, DanceEventTimePhaseEnum.PAST);
        break;
      case 2:
        this.danceEventService.getAllByDanceStudioIdAndTimePhase(this.danceSchool.id, DanceEventTimePhaseEnum.DRAFT);
        break;
      default:
        this.danceEventService.getAllByDanceStudioIdAndTimePhase(this.danceSchool.id, DanceEventTimePhaseEnum.UPCOMING);
        break;
    }
  }

  goToTabAndUpdateData(index = 0): void {
    this.currenTabIndex = index;
    this.loadDanceEventsForTab(index);
    this.cd.detectChanges();
    this.ticketService.clear();
  }
  ngOnDestroy() : void {
    this.subscriptions.unsubscribe();
    this.danceEventService.clear();
  }
}
