import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { TicketOrderHttpService } from '../services';

@Injectable({ providedIn: 'root' })
export class TicketOrderResolver  {
  constructor(private readonly ticketOrderHttpService: TicketOrderHttpService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const searchTicketOrderId = route.paramMap.get('ticketOrderId');
    if (searchTicketOrderId) {
      return this.ticketOrderHttpService.getById(searchTicketOrderId).pipe(
        catchError((error) => {
          this.router.navigateByUrl('/404');
          return EMPTY;
        })
      );
    } else {
      this.router.navigateByUrl('/404');
    }
  }
}
