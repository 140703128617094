import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderUserButtonComponent } from './header-user-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterLinkActive, RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { PipesModule } from '../../pipes';
import {ImageFallbackDirectiveModule} from '../../directives';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [HeaderUserButtonComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        RouterLinkActive,
        RouterModule,
        PipesModule,
        MatListModule,
        ImageFallbackDirectiveModule,
        TranslocoDirective
    ],
  exports: [HeaderUserButtonComponent],
  providers: [] 
})
export class HeaderUserButtonModule { }
