import { AddressInterface } from '../../..';

export class AddressDto implements AddressInterface {
  latitude: number;
  longitude: number;
  googleMapsUrl: string;
  googleMapsPlaceId: string;
  state: string;
  timezoneOffsetMinutes?: number;
  timezoneId?: string;
  addressLine1?: string;
  isDefault?: boolean;
  name?: string;
  city?: string;
}
