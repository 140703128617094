import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'df-shared-lib-logo',
  templateUrl: './df-logo.component.html',
  styleUrls: ['./df-logo.component.css'],
})
export class DfLogoComponent implements OnInit {
  @Input() widthLogo: number;

  constructor() {}

  ngOnInit() {
    this.setDefaultWidth();
  }

  private setDefaultWidth() {
    if (!this.widthLogo) {
      this.widthLogo = 200;
    }
  }
}
