<div *ngIf="danceManager" class="flex-row w-100 dance-school-header relative">
  <div *ngIf="isOwnerOrAdmin" [ngClass]="isOwnerOrAdmin ? 'editable' : 'read-only'" class="dance-school-header-highlighter w-100 h-100" (click)="openDanceSchoolLogoDialogComponent()">
    <div class="flex-row align-center h-100">
      <mat-icon fontSet="material-icons-outlined">photo_camera</mat-icon>
    </div>
  </div>
  <button mat-icon-button *ngIf="isOwnerOrAdmin && danceManager.titleImage?.url" class="delete-button" (click)="confirmDelete()">
    <mat-icon>clear</mat-icon>
  </button>
  <img [src]="danceManager.titleImage?.url ??'assets/images/studio_header.png'" alt="" [ngClass]="isOwnerOrAdmin ? 'editable' : 'read-only'" class="dance-school-title-image w-100" ngClass.gt-xs="title-image-border-radius" id="header-image" (load)="scroll()"/>
  <div class="flex-row align-center gap-10 social-links-container hide-on-tablet" *ngIf="danceManager.socialLinks && danceManager.socialLinks.length > 0">
    <div *ngFor="let socialLink of danceManager.socialLinks" class="flex-row align-center gap-5 social-icon">
      <a [href]="socialLink.url" target="_blank" rel="noopener" class="flex-row align-center gap-5">
        <img class="w-100 h-100" height="26px" width="26px" [alt]="socialLink.type + ' Icon'" [src]="'assets/icons/social-icons/' +socialLink.type.toLowerCase() +'.png'"/>
      </a>
    </div>
  </div>
</div>
