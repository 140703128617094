import { DanceEventDmOverviewInterface } from '../../..';

export class DanceEventsDmOverviewDto implements DanceEventDmOverviewInterface{
  id: string;
  mainImageUrl?: string;
  name: string;
  startDate: Date;
  isActive: boolean;
  duration: number;
}
