import { GenderEnum, UserAppLangEnum, UserStatusEnum } from '../enums';
import { UsersInterface } from './users';
import { BillingOrShippingAddressInterface } from './addresses';
import { SocialProviderInterface } from './social-provider.interface';

export class UpdateUserClass implements UsersInterface {
  birthday?: Date;
  description?: string;
  email?: string;
  firstName?: string;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  formerPlace?: string;
  dancingArea?: string;
  addresses?: BillingOrShippingAddressInterface[];
  height?: number;
  imageUrl?: string;
  jobTitle?: string;
  lastName?: string;
  gender?: GenderEnum;
  status?: UserStatusEnum;
  token?: string;
  username?: string;
  qualifications?: string[];
  socialProviders?: SocialProviderInterface[];
  appLang?: UserAppLangEnum;
}
