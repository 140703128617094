import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { addMinutes, getHours, getMinutes } from 'date-fns';
import { DateTime, Interval } from 'luxon';
import { Subscription } from 'rxjs';
import { TicketStateService } from '../../services';
import { DanceEvent, Ticket } from '@platri/df-common-shared-graphql';
import { getSubscription } from '../../helpers';
import { dateRangeValidator } from '../../validators';

@Component({
  selector: 'df-shared-lib-duplicate-dance-event-dialog',
  templateUrl: './duplicate-dance-event-dialog.component.html',
  styleUrls: ['./duplicate-dance-event-dialog.component.scss']
})
export class DuplicateDanceEventDialogComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  currentDate = new Date();

  tickets: Ticket[] = [];
  private subscription = new Subscription();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DanceEvent, public dialogRef: MatDialogRef<DuplicateDanceEventDialogComponent>, private readonly formBuilder: UntypedFormBuilder, private readonly ticketService: TicketStateService) {}

  ngOnInit(): void {
    this.initForm();
    this.initializeSubscriptions();
    this.ticketService.getAllByDanceEvent(this.data.id!, true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeSubscriptions() {
    this.subscription.add(getSubscription(this.ticketService.getAsyncTickets(), this.onTicketsChange.bind(this)));
  }

  onTicketsChange(tickets: Ticket[]): void {
    this.tickets = tickets;
  }

  initForm(): void {
    const startDate = new Date(this.data.appointments[0].startDate);
    startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000);
    const startTime = {
      hours: getHours(startDate),
      minutes: getMinutes(startDate)
    };
    const endDate = addMinutes(startDate, this.data.appointments[0].duration);
    const endTime = {
      hours: getHours(endDate),
      minutes: getMinutes(endDate)
    };
    this.formGroup = this.formBuilder.group({
      name: [this.data.name, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      duplicateTickets: [false]
    }, {
      validators: dateRangeValidator()
    });
  }

  save(): void {
    const { startDate, endDate, duplicateTickets } = this.formGroup.getRawValue();
    const startDateTime = this.parseDateTime(startDate);
    const endDateTime = this.parseDateTime(endDate);
    const diffInMinutes = Interval.fromDateTimes(startDateTime, endDateTime).length('minute');
    

    this.dialogRef.close({
      name: this.formGroup.getRawValue().name,
      startDate: startDate,
      duration: diffInMinutes,
      duplicateTickets
    });
  }


  parseDateTime(dateTimeString: string): DateTime {
    const [datePart, timePart] = dateTimeString.split('T');

    const [year, month, day] = datePart.split('-').map(Number);

    const [hour, minute] = timePart.split(':').map(Number);

    const dateTime = DateTime.now()
      .setZone('UTC')
      .set({
        day: day,
        month: month,
        year: year,
        hour: hour,
        minute: minute,
        second: 0,
        millisecond: 0
      });

    return dateTime;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
