import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnavailableForWebComponent } from './unavailable-for-web.component';
import { MatCardModule } from '@angular/material/card';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [UnavailableForWebComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    TranslocoDirective
  ],

  exports: [UnavailableForWebComponent],
})
export class UnavailableForWebModule {}
