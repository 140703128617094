import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventorySubscriptionListItemComponent } from './inventory-subscription-list-item.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { PipesModule } from '@platri/dfx-angular-core';
import { MatTooltip } from '@angular/material/tooltip';
import {MatDivider} from "@angular/material/divider";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [InventorySubscriptionListItemComponent],
    imports: [
      CommonModule,
      MatIcon,
      MatIconButton,
      MatMenu,
      RouterLink,
      PipesModule,
      MatTooltip,
      MatMenuTrigger,
      MatMenuItem,
      MatDivider,
      MatGridList,
      MatGridTile,
      TranslocoDirective,
    ],
  exports: [InventorySubscriptionListItemComponent]
})
export class InventorySubscriptionListItemModule {}
