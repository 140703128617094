import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'df-shared-lib-connection-timed-out',
  templateUrl: './connection-timed-out.page.html',
  styleUrls: ['./connection-timed-out.page.scss'],
})
export class ConnectionTimedOutPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
