import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthStateService, DanceEventHttpService, DanceEventStateService, dfAppRoute, dfCourseIdRouterParam, dfDanceEventIdRouterParam, getSubscription, SeoService, ShareComponent, TicketHttpService } from '@platri/dfx-angular-core';
import { Ticket } from '@platri/df-common-shared-graphql';
import { MatDialog } from '@angular/material/dialog';
import {
  AppointmentsInterface,
  DanceEventCoHostsInterface,
  DanceEventCoHostStatus,
  DanceEventsInterface,
  DanceEventStatusEnum,
  DanceManagerUserRoleEnum,
  DanceSchoolInterface,
  DanceSchoolToUserInterface,
  DEFAULT_EVENT_PICTURE_PLACEHOLDER,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  getDanceManagerIdsFromTokenRoles,
  hasAccessToDanceManagerFromTokenRolesByIdAndRoles,
  isAppointmentActive,
  UserAppLangEnum,
  UsersInterface
} from '@platri/df-common-core';
import { DateTime } from 'luxon';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-event',
  templateUrl: './dance-event.page.html',
  styleUrls: ['./dance-event.page.scss']
})
export class DanceEventPage implements OnInit, OnDestroy {
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;

  danceEvent: DanceEventsInterface;
  appointment: AppointmentsInterface | null;
  danceSchool: DanceSchoolInterface;
  danceSchoolToUsers: DanceSchoolToUserInterface[];
  isOwnerOrAdmin: boolean;
  isEventActive: boolean;
  tickets: Ticket[] = [];
  currentLang: UserAppLangEnum;
  endDate: Date;
  invitedCoHosts: DanceEventCoHostsInterface []=[];
  dataSource: MatTableDataSource<DanceSchoolToUserInterface>;
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  pageViews: number | null = null;
  ticketMinPrice = -1;

  googleMapsOptions: google.maps.MapOptions = {
    zoom: 14,
    disableDoubleClickZoom: true,
    clickableIcons: false,
    disableDefaultUI: true,
    draggable: true,
    draggableCursor: 'pointer'
  };
  
  currentUser: UsersInterface;

  routerParams!: Params;
  danceEventIdFromParam: string;
  
  danceEventStatusEnum = DanceEventStatusEnum;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    public authService: AuthStateService, 
    @Optional() private dialog: MatDialog,
    private danceEventService: DanceEventStateService, 
    private seoService: SeoService,
    private translocoService: TranslocoService, 
    private danceEventHttpService: DanceEventHttpService,
    private ticketHttpService: TicketHttpService
    ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnInit(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.danceEventIdFromParam = this.routerParams[dfDanceEventIdRouterParam];
    if (!this.danceEventIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }

    this.loadData();
  }
  
  loadData(): void {
    this.subscriptions.add(
      this.danceEventHttpService.getById(this.danceEventIdFromParam, true).subscribe({
        next: (danceEvent) => {
          this.danceEvent = danceEvent;
          this.pageViews = danceEvent.pageViews.webViews + danceEvent.pageViews.mobileViews;
          this.getAppointmentDataFromEvent();
          if (this.authService.getSyncCurrentUser()) {
            this.currentUser = this.authService.getSyncCurrentUser();
            this.getInvitationOfPendingCoHostsForCurrentUser();
            this.isOwnerOrAdmin = hasAccessToDanceManagerFromTokenRolesByIdAndRoles(this.danceSchool.id, this.currentUser.roles.danceSchool, [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]);
          }
        }
      })
    );
  }

  getInvitationOfPendingCoHostsForCurrentUser(): void {
    const danceManagerIdsCurrentUser = getDanceManagerIdsFromTokenRoles(this.authService.getSyncCurrentUser().roles.danceSchool);
    const coHostIds = this.danceEvent.coHosts.map((coHost) => coHost.danceManagerId);
    if (this.isOwnerOrAdmin){
      danceManagerIdsCurrentUser.forEach((danceManagerId) => {
        if (coHostIds.includes(danceManagerId)) {
          const matchingCoHost = this.danceEvent.coHosts.find((coHost) => coHost.danceManagerId === danceManagerId);
          if (matchingCoHost && matchingCoHost.status === DanceEventCoHostStatus.PENDING) {
            this.invitedCoHosts.push(matchingCoHost);
          }
        }
      });
    }
  }


  acceptCoHostInvitation(coHost: DanceEventCoHostsInterface): void {
    if (coHost.status === DanceEventCoHostStatus.PENDING) {
      this.danceEventHttpService.acceptOrDeclineCoHostInvitation(coHost.danceEventId, coHost.danceManagerId, DanceEventCoHostStatus.ACCEPTED)
        .subscribe(() => {
          this.invitedCoHosts = this.invitedCoHosts.filter(ch => ch !== coHost);
          const index = this.danceEvent.coHosts.findIndex(ch => ch.danceManagerId === coHost.danceManagerId);
          if (index > -1) {
            this.danceEvent.coHosts[index].status = DanceEventCoHostStatus.ACCEPTED;
          }
          this.danceEvent.coHosts = [...this.danceEvent.coHosts];
        });
    }
  }


  declineCoHostInvitation(coHost: DanceEventCoHostsInterface): void {
    if (coHost.status === DanceEventCoHostStatus.PENDING) {
      this.danceEventHttpService.acceptOrDeclineCoHostInvitation(coHost.danceEventId, coHost.danceManagerId, DanceEventCoHostStatus.DECLINED)
        .subscribe(() => {
          this.invitedCoHosts = this.invitedCoHosts.filter(ch => ch !== coHost);
        });
    }
  }

  getAppointmentDataFromEvent() : void {
    this.danceSchool = this.danceEvent.danceSchool;
    this.appointment = this.danceEvent?.appointments && this.danceEvent?.appointments.length > 0 ? this.danceEvent.appointments[0] : null;
    if (this.danceEvent?.appointments.length > 0) {
      this.appointment = this.danceEvent.appointments[0];
      this.doAppointmentChecks();
      this.loadEventTicketMinPrice();
    }
    this.setSeoData();
  }

  getPageViews(): void {
    this.subscriptions.add(
      this.danceEventHttpService.getEventPageViewsById(this.danceEvent.id).subscribe((result) => {
        this.pageViews = result;
      })
    );
  }

  doAppointmentChecks(): void {
    if (this.appointment) {
      this.isEventActive = isAppointmentActive(this.appointment);
    }
  }

  openGoogleMapsInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  onClickTicketsButton(): void {
    this.router.navigate(['dance-event', this.danceEvent.id, 'tickets']);
  }

  openChatWithDanceSchool(): void {
    this.router.navigateByUrl(`messages/${this.danceSchool.id}`);
  }

  openShareComponentDialog(): void {
    this.dialog.open(ShareComponent, {
      autoFocus: false,
      panelClass: ['br-20', 'bg-glassmorph'],
      maxHeight: '100vh',
      maxWidth: '311px',
      minWidth: '310px',
      width: '25vw'
    });
  }

  setSeoData(): void {
    this.seoService.setPageTitle(`${this.danceEvent.name!} - Danceflavors`);
    this.seoService.setMetaTagProperty('og:title', this.danceEvent.name!);
    if (this.danceEvent.description) {
      this.seoService.setMetaTagName('description', this.danceEvent.description!.length > 155 ? this.stripHtmlTags(this.danceEvent.description!.substring(0, 155) + '...') : this.stripHtmlTags(this.danceEvent.description!));
      this.seoService.setMetaTagProperty('og:description', this.stripHtmlTags(this.danceEvent.description));
    }
    if (this.danceEvent.mainImage?.url) {
      this.seoService.setMetaTagProperty('og:image', this.danceEvent.mainImage?.url);
    }
    this.seoService.setMetaTagProperty('og:url', window.location.href);
    this.setStructuredDataForDanceEvent();
  }

  setStructuredDataForDanceEvent(): any {
    const danceEventStartDate = DateTime.fromJSDate(new Date(this.appointment?.startDate)).setZone('UTC');
    let danceEventEndDate = danceEventStartDate;
    if (this.appointment?.duration > 0) {
      danceEventEndDate = danceEventEndDate.plus({
        minute: this.appointment?.duration
      });
    } else {
      danceEventEndDate = danceEventEndDate.plus({
        minute: 600
      });
    }
    const structuredData: any = [
      {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: this.danceEvent?.name ?? '',
        startDate: danceEventStartDate?.toString() ?? '',
        endDate: danceEventEndDate?.toString() ?? '',
        eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        location: {
          '@type': 'Place',
          name: this.appointment?.address?.name ?? '',
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.appointment?.address?.formattedAddress?.split(',')[0] ?? '',
            addressLocality: this.appointment?.address?.city ?? '',
            postalCode: this.appointment?.address?.zipCode ?? '',
            addressCountry: this.appointment?.address?.country ?? ''
          }
        },
        image: [this.danceEvent.mainImage?.url ?? ''],
        description: this.danceEvent?.description ?? '',
        organizer: {
          '@type': 'Organization',
          name: this.danceSchool?.name ?? '',
          url: new URL(window.location.href).origin + '/app/@' + this.danceSchool?.urlName
        }
      }
    ];
    this.seoService.updateStructuredData(structuredData);
  }

  stripHtmlTags(html: string): string {
    html = html.replace(/<br\s*\/?>/gi, ' ')
      .replace(/<\/?p>/gi, ' ')
      .replace(/<\/?div>/gi, ' ');
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }
  
  loadEventTicketMinPrice(): void {
    this.ticketHttpService.getEventMinPriceById(this.danceEvent.id).subscribe({
      next: (ticketMinPrice) => {
        this.ticketMinPrice = ticketMinPrice;
        
        console.log(this.danceEvent.hasSoldTickets);
        console.log(this.ticketMinPrice);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.danceEventService.clear();
    this.seoService.resetAll();
  }

  protected readonly DanceEventCoHostStatus = DanceEventCoHostStatus;
  protected readonly DanceEventStatusEnum = DanceEventStatusEnum;
}
