<div>
  <df-chat 
    *ngIf="chatData && currentUser && recipientUser"
    (chatMessageSentEmitter)="sendChatMessage($event)"
    (chatMessageReadEmitter)="readChatMessage($event)"
    (chatMessageReactedEmitter)="reactChatMessage($event)"
    (chatMessageDeletedEmitter)="deleteChatMessage($event)"
    (chatScrolledToTopEmitter)="loadMoreChatMessages()"
    (chatUploadedFileEmitter)="uploadFileAndSendChatMessage($event)"
    (deleteChatEmitter)="deleteChat()"
    [recipientUser]="recipientUser" 
    [chatData]="chatData" 
    [isSendingMessage]="isSendingMessage" 
    [recipientId]="recipientIdFromParam" 
    [senderId]="currentUser.id"
    [allMessagesLoaded]="allMessagesLoaded"
    [isLoadingMoreMessages]="isLoading"
  ></df-chat>
</div>
