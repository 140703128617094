<ng-container *transloco="let t">
  <div class="flex-row-center-center">
  <div mat-dialog-content class="w-100 h-100">
    <div class="flex-row align-space-between-center header mb2">
      <span class="bold">{{ t('GENERIC_WRAPPER.IMAGE_UPLOAD_HEADER') }}</span>
      <button style="padding: 0px" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="flex-column-center-center dropzone p2 py4 gap-25" style="margin-bottom: 25px;">
      <mat-icon
        class="material-icons-round" 
        style="font-size: 80px; height: 80px; width: 80px; color: #646464"
      >
        file_upload
      </mat-icon>
      <span
        class="bold"
        style="color: #4d4d4d; font-size: 40px"
        [innerHtml]="t('GENERIC_WRAPPER.IMAGE_UPLOAD_HEADER')"
      ></span>
      <input
        type="file"
        accept=".png, .jpeg, .jpg"
        size="41943040"
        (change)="onImageSelect($event.target)"
        id="pictureInput"
        multiple
        class="pictureInput"
      />
      <span
        style="color: #4d4d4d; white-space: pre-wrap"
        [innerHtml]="t('GENERIC_WRAPPER.IMAGE_UPLOAD_TEXT')"
      ></span>
      <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.IMAGE_UPLOAD_SELECT_FILE')">
      </df-shared-lib-button>
    </div>
    <div class="flex-column w-100" style="justify-content: space-evenly;"
    >
      <div class="image-container">
        <div class="container" *ngFor="let file of files; let i = index">
          <div
            class="image-highlighter"
          >
            <button mat-icon-button (click)="deleteFile(i)" class="btn">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
          <img
            [src]="file.base64"
            alt=""
          />
          <!-- <button mat-icon-button color="primary" (click)="deleteFile(i)" [matTooltip]="t('mat-icons.delete')">
            <mat-icon>delete_outline</mat-icon>
          </button> -->
        </div>
      </div>
    </div>
    <div class="flex-row-end-center gap-10" style="margin-top: 15px;">
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        materialButtonStyleType="outlined"
        mat-dialog-close
      >
      </df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        [isDisabled]="!newImageSelected"
        (buttonClicked)="awardImagesUpload()"
        mat-dialog-close
      >
      </df-shared-lib-button>
    </div>
  </div>
</div>
</ng-container>
