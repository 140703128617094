import { RadiusUnitEnum } from '../../../enums';
import { ImageInterface } from '../../image.interface';

export interface SearchRoomsResultInterface {
  id: string;
  maxParticipants: number;
  pricePerHour: number;
  description: string;
  image: ImageInterface;
  formattedAddress: string;
  roomSize: number;
  distance: number;
  radiusUnit: RadiusUnitEnum;
  danceSchoolName: string;
  danceSchoolUrlName: string;
  danceSchoolImageUrl: string;
  roomName: string;
  locationName: string;
}
