import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { FrontendCreateDanceManagerRoutingModule } from './frontend-create-dance-manager-routing.module';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { CreateDanceManagerAppModule } from './pages/app';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { TranslocoStateConst } from '@platri/dfx-angular-core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendCreateDanceManagerRoutingModule,
    CreateDanceManagerAppModule,
    MatSnackBarModule,
    TranslocoModule,
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendCreateDanceManagerModule {
  constructor() {
    TranslocoStateConst.push({ scope: 'df-create-dance-manager', alias: 'CREATE_DANCE_MANAGER' });
  }
}
