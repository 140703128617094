import { SearchPostingStatusEnum, SearchPostingTypeEnum } from '../../enums';
import { ImageInterface } from '../image.interface';
import { PlaceInterface } from '../place.interface';
import { UsersInterface } from '../users';

export interface BaseSearchPosting {
  deletedAt?: Date;
  title: string;
  description: string;
  searchPostingType: SearchPostingTypeEnum;
  searchPostingStatus?: SearchPostingStatusEnum;
  mainImage?: ImageInterface;
  images?: ImageInterface[];
  user?: UsersInterface;
  place?: PlaceInterface;
}
