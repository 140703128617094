export enum SystemLanguageEnum {
  GERMAN = 'DE',
  ENGLISH = 'EN',
  POLISH = 'PL',
  ITALIAN = 'IT',
  FRENCH = 'FR',
  SPANISH = 'ES',
  LUXEMBOURGISH = 'LB',
  DUTCH = 'NL'
}
