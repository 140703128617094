import { FeesPaidByEnum, TicketInterface } from '../../..';

export class UpdateTicketRequestDto implements TicketInterface {
  name: string;
  description: string;
  quantity: number;
  price: number;
  saleStart?: Date;
  saleEnd?: Date;
  taxPercentage: number;
  feesPaidBy: FeesPaidByEnum;
  sellAsFollowerLeader = false;
  sellAsFollowerActive = false;
  sellAsLeaderActive = false;
}
