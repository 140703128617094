import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthStateService, dfAppRoute, dfLoginRoute } from '@platri/dfx-angular-core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private counter = 0;
  private refreshTokenCounter = 0;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthStateService,
    private activatedRoute: ActivatedRoute
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // @ts-ignore
      catchError((error) => {
        if (request.url.includes('logout')) {
          // @ts-ignore
          return;
        }
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, error, next);
        } 
        else if (error instanceof HttpErrorResponse && error.status === 0) {
          // @ts-ignore
          setTimeout(() => {
            this.counter++;
            if (this.counter < 3) {
              return next.handle(request);
            } 
            else {
              if (navigator.onLine) {
                //                this.router.navigate(['/', 'time-out']);
              }
            }
          }, 10000);
        }
        return throwError(() => error);
      })
    );
  }

  refreshingToken(accessToken?: string): void {
    if (accessToken) {
      this.isRefreshing = false;
      this.authService.sendAccessToken(accessToken);
    } else {
      this.isRefreshing = true;
      this.authService.sendAccessToken(null);
    }
  }

  private handle401Error(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ) {
    if (
      !this.authService.getSyncCurrentUser() ||
      //todo: ENUM
      error.error.message === "USER_NOT_FOUND"
    ) {
      return next.handle(this.routeToLogin(request));
    } else {
      if (!this.isRefreshing) {
        this.refreshingToken();
        return this.authService.refreshTokens().pipe(
          switchMap(() => {
            const accessToken = this.authService.getSyncAccessToken();
            this.refreshingToken(accessToken);
            return next.handle(this.addToken(request, accessToken));
          }),
          catchError((error) => {
            this.authService.logout();
            this.authService.logoutInWebapp();
            throw error;
          })
        );
      } else {
        if (request.body?.refreshToken) {
          if (this.authService.getSyncCurrentUser()) {
            this.authService.logout();
            this.authService.logoutInWebapp();
            location.reload();
          }
        }
        return this.authService.getAsyncAccessToken().pipe(
          filter((token) => token != null),
          take(1),
          switchMap((jwt) => next.handle(this.addToken(request, jwt)))
        );
      }
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  routeToLogin(request: HttpRequest<any>): HttpRequest<any> {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
    return request;
  }
  
}
