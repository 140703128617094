import { DanceEventCategoryEnum } from '../../..';
import { DanceEventRestrictionDto, DanceEventRulesDto } from '../../..';

export class UpdateDanceEventRequestDto {
  name?: string;
  danceStyleIds?: string[];
  musicStyleIds?: string[];
  coHosts: string[];
  description?: string;
  restrictions?: DanceEventRestrictionDto;
  rules?: DanceEventRulesDto;
  url?: string;
  tags?: string[];
  isActive?: boolean;
  categories?: DanceEventCategoryEnum[];
}
