import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PipesModule } from '../../pipes';
import { DmNotificationItemComponent } from './dm-notification-item.component';
import { DfSharedButtonModule } from '../buttons';
import { MatCardModule } from '@angular/material/card';
import {MatIconModule} from "@angular/material/icon";
import { MatButtonModule } from '@angular/material/button';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DmNotificationItemComponent],
  exports: [DmNotificationItemComponent],
  imports: [CommonModule, RouterLinkActive, RouterLink, TranslocoDirective, PipesModule, DfSharedButtonModule, MatCardModule, MatIconModule, MatButtonModule],
  providers: []
})
export class DmNotificationItemModule {}
