import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { AppointmentAvailabilityEnum } from '@platri/df-common-shared-graphql';
import { DanceEvent } from '@platri/df-common-shared-graphql';
import { Router } from '@angular/router';

@Component({
  selector: 'dfm-sidenav-events',
  templateUrl: './dfm-sidenav-events.component.html',
  styleUrls: ['./dfm-sidenav-events.component.scss'],
})
export class DfmSidenavEventsComponent implements OnChanges{
  @Input() showEvent: boolean;
  @Input() showAppointment: boolean;
  @Input() positionStrategy: ConnectedPosition[];
  @Input() isExpanded: boolean;
  @Input() danceEvent: DanceEvent;
  @Input() appointmentAvailability: AppointmentAvailabilityEnum;
  @Input() loading: boolean;

  createEventPath = false;

  constructor(private readonly _router: Router) {
    this.createEventPath = this._router.url.includes('events/create');
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
}
