import { AddressInterface } from '../address.interface';
import { LocationInterface } from '../location.interface';
import { RoomInterface } from '../room.interface';
import { UsersInterface } from '../users';
import { CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, CustomRecurrenceWeekDaysTypeEnum, SchedulerRecurrenceTypeEnum, SchedulerTypeEnum } from '../../enums';
import { CourseAppointmentInterface } from '../course-appointment';

export interface AppointmentSchedulerInterface {
  startDate?: Date;
  endDate?: Date;
  deletedAt?: Date;
  duration?: number;
  customRecurrenceNumber?: number;
  numberOfAppointments?: number;
  description?: string;
  schedulerType?: SchedulerTypeEnum;
  recurrenceType?: SchedulerRecurrenceTypeEnum;
  customRecurrenceType?: CustomRecurrenceTypeEnum;
  customRecurrenceEnd?: CustomRecurrenceEndTypeEnum;
  customRecurrenceWeekDays?: CustomRecurrenceWeekDaysTypeEnum[];
  appointments?: CourseAppointmentInterface[];
  address?: AddressInterface;
  location?: LocationInterface;
  room?: RoomInterface;
  teachers?: UsersInterface[];
  maxParticipants?: number;
}
