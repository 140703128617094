import { DanceEventsInterface } from './dance-events';
import { TimeInterface } from './time.interface';
import { LocationInterface } from './location.interface';
import { TicketInterface } from './ticket.interface';

export interface PdfDataDanceEventInterface {
  qrCode?: string;
  danceEvent?: DanceEventsInterface;
  danceSchool?: {
    name?: string;
    imageUrl?: string;
  };
  date?: Date;
  startTime?: TimeInterface;
  duration?: number;
  location?: LocationInterface;
  ticket?: TicketInterface;
  disclaimer?: string;
  refundPolicy?: string;
}
