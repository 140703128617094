import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfFollowComponent } from './df-follow.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { FollowInfoCardModule } from '../follow-info-card';
import { PipesModule } from '../../pipes';
import { FollowInfoDialogModule } from '../follow-info-dialog';
import {MatIcon} from "@angular/material/icon";
import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DfFollowComponent],
  imports: [CommonModule, PipesModule, ReactiveFormsModule, MatInputModule, FollowInfoCardModule, FollowInfoDialogModule, MatSnackBarModule, MatChipsModule, MatButtonModule, MatIcon, MatTooltip, TranslocoDirective],
  exports: [DfFollowComponent]
})
export class DfFollowModule {}
