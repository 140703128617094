import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCreateAppointmentsPageComponent } from './course-create-appointments-page.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { ParticipantsListTableModule } from '../../components/participants-list/participants-list-table.module';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DfSharedButtonModule, DfQuillEditorModule, DfTimePickerModule, GoogleAddressSearchAutocompleteModule, PipesModule, NoScrollDirective, DisableControlModule } from '@platri/dfx-angular-core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: CourseCreateAppointmentsPageComponent,
  },
];

@NgModule({
  declarations: [CourseCreateAppointmentsPageComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        BreadcrumbComponent,
        BreadcrumbItemDirective,
        MatIconModule,
        ParticipantsListTableModule,
        MatCardModule,
        MatDividerModule,
        FormsModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        DfTimePickerModule,
        MatOptionModule,
        MatSelectModule,
        PipesModule,
        GoogleAddressSearchAutocompleteModule,
        DfQuillEditorModule,
        DfSharedButtonModule,
        MatSnackBarModule,
        NoScrollDirective,
        DisableControlModule,
        TranslocoDirective
    ]
})
export class CourseCreateAppointmentsPageModule {}
