import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Files, ImageService } from '../../services';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { ImageCropperDialogComponent } from '../image-cropper-dialog';
import { CropMultipleImagesDialogComponent } from '../crop-multiple-images-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-image-upload-cropper',
  templateUrl: './image-upload-cropper.component.html',
  styleUrls: ['./image-upload-cropper.component.scss'],
})
export class ImageUploadCropperComponent
  implements OnDestroy, AfterViewChecked
{
  @Input() maxFileSizeInBytes = 5242880;
  @Input() croppedImageRatio = 1;
  @Input() multipleFiles = false;
  @Input() isDialog = false;
  @Input() roundCropper = false;
  @Input() cropperIsDialog = false;
  @Input() closeDialog = true;
  @Input() imagePreview = false;
  @Input() resizeToHeight = 512;
  @Input() resizeToWidth = 1024;
  @Input() isLoading = false;
  @Input() transform: ImageTransform = {};

  @Output() uploadFormData: EventEmitter<FormData> =
    new EventEmitter<FormData>();
  @Output() croppedImages: EventEmitter<Files[]> = new EventEmitter<Files[]>();
  @Output() imageCropped: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('fileInput') fileInput: ElementRef;

  allowedImageFileFormats = ['image/png', 'image/jpeg', 'image/jpg'];
  allowedImageFileFormatsShort = ['png', 'jpg', 'jpeg'];
  imageSelected: boolean;
  croppedFiles: File[] = [];
  uploadedFiles: Files[] = [];
  croppedImageAsBase64: string;
  showPreview: boolean;
  scale = 1;
  roundedScale: string;

  subscriptions: Subscription = new Subscription();

  constructor(
    private readonly imageService: ImageService,
    private readonly matDialog: MatDialog,
    private readonly translocoService: TranslocoService,
    private readonly renderer: Renderer2
  ) {}

  roundScaleToOneDecimalPoint(): void {
    this.roundedScale = this.scale.toFixed(1);
  }

  zoomIn(): void {
    if(this.scale < 3) {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
      this.roundScaleToOneDecimalPoint();
    }
  }

  zoomOut(): void {
    if (this.scale >= 0.2 ) {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
    this.roundScaleToOneDecimalPoint();
    }
  }

  deletePicture(): void {
    this.showPreview = false;
    this.imageSelected = false;
    this.croppedImageAsBase64 = '';
  }

  setValueOfTarget(event: MouseEvent):void {
    (event.target as HTMLInputElement).value = null;
  }

  onFileSelect(event: Event): void {
    if (this.multipleFiles) {
      this.onMultipleFilesSelect(event);
    } else {
      this.onSingleFileSelect(event);
    }
  }

  onSingleFileSelect(event: Event): void {
    const uploadedFile: File = <File>(
      (event.target as HTMLInputElement).files[0]
    );
    if (uploadedFile.size > this.maxFileSizeInBytes) {
      this.openDialogFileSizeTooBig();
      this.fileInput.nativeElement.value = null;
    } else {
      if (this.allowedImageFileFormats.includes(uploadedFile.type)) {
        this.croppedFiles.push(uploadedFile);

        if (this.cropperIsDialog) {
          this.subscriptions.add(
            this.matDialog
              .open(ImageCropperDialogComponent, {
                width: '100%',
                maxWidth: '1140px',
                data: {
                  fileToCrop: uploadedFile,
                  croppedImageRatio: this.croppedImageRatio,
                  allowedImageFileFormatsShort:
                    this.allowedImageFileFormatsShort,
                },
              })
              .afterClosed()
              .subscribe((data) => {
                if (data.formDataFromDialog) {
                  this.uploadFormData.emit(data.formDataFromDialog);

                  if (this.imagePreview && data.croppedImageBase64FromDialog) {
                    this.croppedImageAsBase64 =
                      data.croppedImageBase64FromDialog;

                    this.showPreview = true;
                  }
                }
              })
          );
        } else {
          this.imageSelected = true;
        }
      } else {
        this.openDialogWrongFileType();
        this.fileInput.nativeElement.value = null;
      }
    }
  }

  ngAfterViewChecked():void {
    if (!document.querySelector('#text') && document.querySelector('.move')) {
      const moveDiv = document.querySelector('.move');
      const div = this.renderer.createElement('div');
      const span = this.renderer.createElement('span');
      this.renderer.appendChild(
        span,
        this.renderer.createText('PULL TO REPOSITION')
      );
      div.append(span);
      div.id = 'text';
      div.class = 'br-20';
      moveDiv.appendChild(div);
    }
  }

  onMultipleFilesSelect(event: Event): void {
    this.croppedFiles = this.imageService.createFileArray(event.target);
    let fileSizeExceeded = false;
    this.croppedFiles.forEach((file) => {
      if (file.size > this.maxFileSizeInBytes) {
        fileSizeExceeded = true;
      }
    });
    if (fileSizeExceeded) {
      this.openDialogFileSizeTooBig();
      this.croppedFiles = [];
    } else {
      this.subscriptions.add(
        this.matDialog
          .open(CropMultipleImagesDialogComponent, {
            maxHeight: '100vh',
            minWidth: '300px',
            width: '50vw',
            data: {
              files: this.croppedFiles,
              aspectRatio: this.croppedImageRatio,
            },
          })
          .afterClosed()
          .subscribe((result: Files[]) => {
            if (result) {
              this.uploadedFiles = this.uploadedFiles.concat(result);
              this.croppedImages.emit(this.uploadedFiles);
            }
            this.croppedFiles = [];
          })
      );
    }
  }

  triggerUploadFormData(): void {
    const formData = new FormData();
    formData.append(
      'file',
      this.imageService.base64ToFile(this.croppedImageAsBase64),
      this.croppedFiles[0].name
    );
    this.imageCropped.emit(this.croppedImageAsBase64);
    this.uploadFormData.emit(formData);
  }

  croppedImage(event: ImageCroppedEvent): void {
    this.croppedImageAsBase64 = event.base64;
  }

  openDialogFileSizeTooBig(): void {
    const translationKey = `GENERIC_WRAPPER.IMAGE_UPLOAD.FILE_SIZE_TOO_BIG_${this.multipleFiles ? 'MULTI' : 'SINGLE'}_IMAGES.`;
    const data = {
      title: this.translocoService.translate(translationKey + 'TITLE'),
      text: this.translocoService.translate(translationKey + 'TEXT'),
      cancelButtonColor: 'primary',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.CLOSE'),
    };
    this.matDialog.open(ConfirmationDialogComponent, {
      minWidth: '300px',
      data,
    });
  }

  openDialogWrongFileType(): void {
    const translationKey = `GENERIC_WRAPPER.IMAGE_UPLOAD.TYPE_NOT_ALLOWED_${this.multipleFiles ? 'MULTI' : 'SINGLE'}_IMAGES.`;
    const data = {
      title: this.translocoService.translate(translationKey + 'TITLE'),
      text: this.translocoService.translate(translationKey + 'TEXT'),
      cancelButtonColor: 'primary',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.CLOSE'),
    };
    this.matDialog.open(ConfirmationDialogComponent, {
      minWidth: '300px',
      data,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.showPreview = false;
    this.imageSelected = false;
  }
}
