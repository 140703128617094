import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoDialogComponent } from './info-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule } from '../buttons';

@NgModule({
  declarations: [InfoDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    DfSharedButtonModule
  ],
  exports: [InfoDialogComponent],
})
export class InfoDialogModule {}
