import { PaginationInterface, SortDirectionEnum } from '../../..';
export enum ValidSortColumns {
  ID = 'id',
  USERNAME = 'username',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
}

export class UserSearchPaginationDto implements PaginationInterface {
  limit: number;
  page: number;
  sortDirection?: SortDirectionEnum;
  sortColumn?: ValidSortColumns;
}
