import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'df-shared-lib-verify-email-dialog',
  templateUrl: 'verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.scss'],
})
export class VerifyEmailDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {message: string; buttonText: string; matIcon: string}
  ) {}


}
