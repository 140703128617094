import { Pipe, PipeTransform } from '@angular/core';
import { TimeRangeInterface, UserAppLangEnum } from '@platri/df-common-core';
import { DatePipe } from '@angular/common';
import { utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'timeRange',
})
export class TimeRangePipe extends DatePipe implements PipeTransform {
  readonly germanTimeFormat = 'HH:mm';
  readonly englishTimeFormat = 'h:mm a';
  readonly germanAngularLocal = 'de-DE';
  readonly englishAngularLocal = 'en-US';
  // @ts-ignore
  transform(timeRange: TimeRangeInterface, locale: UserAppLangEnum): string {
    const date = new Date();
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const zonedDateTimeRangeStart = utcToZonedTime(date, 'UTC');
    zonedDateTimeRangeStart.setHours(timeRange.timeStart.hours);
    zonedDateTimeRangeStart.setMinutes(timeRange.timeStart.minutes);
    const zonedDateTimeRangeEnd = utcToZonedTime(date, 'UTC');
    zonedDateTimeRangeEnd.setHours(timeRange.timeEnd.hours);
    zonedDateTimeRangeEnd.setMinutes(timeRange.timeEnd.minutes);
    return this.getTimeForLocale(zonedDateTimeRangeStart, locale, false)  + ' - ' + this.getTimeForLocale(zonedDateTimeRangeEnd, locale, true);
  }

  getTimeForLocale(date: Date, locale: string, isEndTime: boolean = false): string {
    return (
      super.transform(
        date,
        locale === UserAppLangEnum.DE ? this.germanTimeFormat : this.englishTimeFormat,
        null,
        locale === UserAppLangEnum.DE ? this.germanAngularLocal : this.englishAngularLocal,
      ) + (locale === UserAppLangEnum.DE && isEndTime ? ' Uhr' : '')
    );
  }

}
