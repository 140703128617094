import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'amenityIcon',
})
export class AmenityIconPipe implements PipeTransform {
  transform(value: string, rotation = false): string | null {
    if (value === null) {
      return null;
    }
    if (rotation) {
      switch (value) {
        case 'space_bar':
          return 'rotate-180';
        case 'maximize':
          return 'rotate-270';
        case 'wb_twilight':
          return 'rotate-180';
        default:
          return 'rotate-180';
      }
    }
    return null;
  }
}
