import { CreateDanceEventInterface, DanceEventCategoryEnum } from '../../..';
import { AddressDto, DanceEventCoHostsDto, DanceEventRestrictionDto, DanceEventRulesDto } from '../../..';

export class CreateDanceEventRequestDto implements CreateDanceEventInterface{
  name: string;
  danceStyleIds?: string[];
  musicStyleIds?: string[];
  description: string;
  restrictions?: DanceEventRestrictionDto;
  rules?: DanceEventRulesDto;
  url?: string;
  tags?: string[];
  danceSchoolId: string;
  isActive?: boolean;
  startDate: Date;
  duration: number;
  coHosts?: DanceEventCoHostsDto[];
  locationId?: string;
  address?: AddressDto;
  clientTimezone?: string;
  categories: DanceEventCategoryEnum[];
}
