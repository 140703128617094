import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { UserTicketHttpService } from '../http-services/user-ticket-http.service';
import { UserTicketCollapsedByUserInterface, UserTicketCollapsedUpcomingPastFilteredInterface, UserTicketDanceEventStatisticsInterface, UserTicketInterface, UserTicketSaleInformationWithTotalInterface, UserTicketUpcomingPastFilteredInterface } from '@platri/df-common-core';
import { CreateFreeTicketRequestDto } from '@platri/df-nestjs-core';

@Injectable({
  providedIn: 'root'
})
export class UserTicketStateService {
  private userTicketsUpcomingPastFilteredSubject: BehaviorSubject<UserTicketUpcomingPastFilteredInterface | null> = new BehaviorSubject<UserTicketUpcomingPastFilteredInterface | null>(null);
  private userTicketsUpcomingPastCollapsedSubject: BehaviorSubject<UserTicketCollapsedUpcomingPastFilteredInterface | null> = new BehaviorSubject<UserTicketCollapsedUpcomingPastFilteredInterface | null>(null);
  private userTicketsSubject: BehaviorSubject<UserTicketInterface[]> = new BehaviorSubject<UserTicketInterface[]>([]);
  private userTicketSubject: BehaviorSubject<UserTicketInterface | null> = new BehaviorSubject<UserTicketInterface | null>(null);

  private userTicketsWithSaleInformationSubject: BehaviorSubject<UserTicketCollapsedByUserInterface[]> = new BehaviorSubject<UserTicketCollapsedByUserInterface[]>([]);
  private userTicketsTotalSaleInformationSubject = new BehaviorSubject<UserTicketSaleInformationWithTotalInterface | null>(null);

  #danceEventUserTicketStatistics$ = new BehaviorSubject<UserTicketDanceEventStatisticsInterface | null>(null);
  danceEventUserTicketStatistics$ = this.#danceEventUserTicketStatistics$.asObservable();

  constructor(private readonly _userTicketHttpService: UserTicketHttpService) {}

  private addDanceEventUserTicketStatistics(data: UserTicketDanceEventStatisticsInterface): void {
    this.#danceEventUserTicketStatistics$.next(data);
  }

  sendUserTicketsChange(userTickets: UserTicketInterface[]): void {
    this.userTicketsSubject.next(userTickets);
  }

  getSyncUserTickets(): UserTicketInterface[] {
    return this.userTicketsSubject.getValue();
  }

  getAsyncUserTickets(): Observable<UserTicketInterface[]> {
    return this.userTicketsSubject.asObservable();
  }

  sendUserTicketsWithSaleInformationChange(data: UserTicketCollapsedByUserInterface[]): void {
    this.userTicketsWithSaleInformationSubject.next(data);
  }

  getSyncUserTicketsWithSaleInformation(): UserTicketCollapsedByUserInterface[] {
    return this.userTicketsWithSaleInformationSubject.getValue();
  }

  getAsyncUserTicketsWithSaleInformation(): Observable<UserTicketCollapsedByUserInterface[]> {
    return this.userTicketsWithSaleInformationSubject.asObservable();
  }

  sendUserTicketsUpcomingPastFilteredChange(userTickets: UserTicketUpcomingPastFilteredInterface): void {
    this.userTicketsUpcomingPastFilteredSubject.next(userTickets);
  }

  getSyncUserTicketsUpcomingPastFiltered(): UserTicketUpcomingPastFilteredInterface | null {
    return this.userTicketsUpcomingPastFilteredSubject.getValue();
  }

  getAsyncUserTicketsUpcomingPastFiltered(): Observable<UserTicketUpcomingPastFilteredInterface | null> {
    return this.userTicketsUpcomingPastFilteredSubject.asObservable();
  }

  sendUserTicketsUpcomingPastCollapsedChange(userTickets: UserTicketCollapsedUpcomingPastFilteredInterface): void {
    this.userTicketsUpcomingPastCollapsedSubject.next(userTickets);
  }

  getSyncUserTicketsUpcomingPastCollapsed(): UserTicketCollapsedUpcomingPastFilteredInterface | null {
    return this.userTicketsUpcomingPastCollapsedSubject.getValue();
  }

  getAsyncUserTicketsUpcomingPastCollapsed(): Observable<UserTicketCollapsedUpcomingPastFilteredInterface | null> {
    return this.userTicketsUpcomingPastCollapsedSubject.asObservable();
  }

  sendUserTicketChange(userTicket: UserTicketInterface): void {
    this.userTicketSubject.next(userTicket);
  }

  getSyncUserTicket(): UserTicketInterface | null {
    return this.userTicketSubject.getValue();
  }

  getAsyncUserTicket(): Observable<UserTicketInterface | null> {
    return this.userTicketSubject.asObservable();
  }

  sendUserTicketTotalSaleInformation(totalSaleInformation: UserTicketSaleInformationWithTotalInterface): void {
    this.userTicketsTotalSaleInformationSubject.next(totalSaleInformation);
  }

  getAsyncUserTicketTotalSaleInformation(): Observable<UserTicketSaleInformationWithTotalInterface | null> {
    return this.userTicketsTotalSaleInformationSubject.asObservable();
  }

  sendClearUserTickets(): void {
    this.userTicketsSubject.next([]);
  }

  sendClearUserTicket(): void {
    this.userTicketSubject.next(null);
  }

  sendClearUserTicketGuestList(): void {
    this.userTicketsTotalSaleInformationSubject.next(null);
    this.userTicketsWithSaleInformationSubject.next([]);
  }

  getCurrentUserTickets(): void {
    this._userTicketHttpService
      .getAllUserTicketCollapsedByEventAndOrder()
      .pipe(tap((response) => this.sendUserTicketsUpcomingPastCollapsedChange(response)))
      .subscribe();
  }

  getDanceEventUserTicketStatistics(eventId: string): void {
    this._userTicketHttpService
      .getStatisticsByDanceEventId(eventId)
      .pipe(tap((response) => this.addDanceEventUserTicketStatistics(response)))
      .subscribe();
  }

  createFreeEventTickets(eventId: string, dto: CreateFreeTicketRequestDto): void {
    this._userTicketHttpService
      .postFreeEventTickets(eventId, dto)
      .pipe(
        delay(300),
        tap(() => {
          this.getDanceEventUserTicketStatistics(eventId);
        })
      )
      .subscribe();
  }
}
