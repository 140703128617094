import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import { MatFormFieldAppearance} from "@angular/material/form-field";
import { onlyNumbersValidator } from '../../validators';

@Component({
  selector: 'df-shared-lib-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() hint?: string;
  @Input() errorRequired? : string = 'This field is required';
  @Input() errorMin?: string = `You need to enter an amount more than then minimum allowed`;
  @Input() errorMax?: string = 'You need to enter an amount less than the maximum allowed';
  @Input() disabled?: boolean = false;
  @Input() appearance?: MatFormFieldAppearance = 'outline';
  @Input() placeholder?: string;
  @Input() autocomplete?: string = 'off';
  

  ngOnInit(): void {
    this.control.setValidators([onlyNumbersValidator()]);
    this.control.updateValueAndValidity();
  }

  setFormControlValue(increment: boolean = false) {
    if (this.control.value || this.control.value === 0) {
      this.control.setValue(
        increment ? this.control.value +1 : this.control.value -1
      );
    } else {
      this.control.setValue(1);
    }
  }
}
