<ng-container *transloco="let t">
  <div class="w-100 flex-column" style="gap: 1rem;">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 20%">{{ t('GENERIC_WRAPPER.NAME') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row-start-center" style="gap: 10px;">
            <span id="event-name">
              {{ element.name }}
            </span>
          </div>
        </td> 
      </ng-container>
      
      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 35%"> {{ t('GENERIC_WRAPPER.FROM') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-column">
            {{ element.appointments[0].startDate | dateRange : element.appointments[0].duration : currentLang : 'start' }}
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 35%"> {{ t('GENERIC_WRAPPER.TO') }}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.appointments[0].duration && element.appointments[0].duration !== -1" class="flex-column">
            {{ element.appointments[0].startDate | dateRange : element.appointments[0].duration : currentLang : 'end' }}
          </div>
          <span *ngIf="!element.appointments[0].duration || element.appointments[0].duration === -1">-</span>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="danceStyles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 15%">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngFor="let danceStyle of element.danceStyles; let last = last">
            {{ danceStyle.translationKey | titlecase }}
            {{ !last ? ', ' : '' }}
          </ng-container>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="pageViews">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" style="width: 5%">
          <mat-icon fontSet="material-icons-outlined" [matTooltip]="t('GENERIC_WRAPPER.NUMBER_OF_PAGE_VIEWS')">visibility</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <div style="margin-left: 7px;">
            {{element.pageViews.webViews + element.pageViews.mobileViews}}
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef scope="col"></th>
        <td mat-cell *matCellDef="let element">
          <div class="pr2 flex-row align-center-end">
            <button *ngIf="danceEventListTab !== danceEventListTabEnum.PAST && !element.isActive" type="submit" (click)="$event.stopPropagation(); publishDanceEvent(element)" mat-icon-button matTooltip="{{ t('GENERIC_WRAPPER.PUBLISH') }}">
              <mat-icon>publish</mat-icon>
            </button>
            <button mat-icon-button (click)="editDanceEvent(element)" matTooltip="{{ t('GENERIC_WRAPPER.EDIT') }}">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); duplicateDanceEvent(element)" matTooltip="{{ t('GENERIC_WRAPPER.DUPLICATE') }}">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); navigateToGuestList(element)" matTooltip="{{ t('GENERIC_WRAPPER.GUESTLIST') }}">
              <mat-icon>people</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); deleteDanceEvent(element)" matTooltip="{{ t('GENERIC_WRAPPER.REMOVE') }}">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns" (click)="editDanceEvent(row)"></tr>
    </table>
  </div>
</ng-container>
