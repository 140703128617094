import { DanceEventsInterface } from '../dance-events';
import { UserTicketInterface } from '../user-ticket.interface';

export class UserTicketCollapsedByEventAndOrderInterface {
  eventId?: string;
  eventStart?: Date;
  eventTimezone?: string;
  orderIds?: string[];
  ticketOrderIds?: string[];
  freeUserTicketIds?: string[];
  event?: DanceEventsInterface;
  numberOfTickets: number;
  isCanceled: boolean;
  userTickets?: UserTicketInterface[];
}
