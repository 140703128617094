import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DanceSchoolHttpService, dfmAdministrationRoute, dfmDanceManagerRoute, LogoAndTitleUploadDialogComponent, SharedCancelDialogComponent } from '@platri/dfx-angular-core';
import { Router } from '@angular/router';
import { DanceSchoolInterface, FollowTargetTypeEnum, LogoAndTitleUploadDialogTypeEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-manager-header',
  templateUrl: './dance-manager-header.component.html',
  styleUrls: ['./dance-manager-header.component.scss'],
})
export class DanceManagerHeaderComponent implements OnChanges, OnDestroy {
  @Input() danceManager: DanceSchoolInterface;
  @Input() isOwnerOrAdmin: boolean;
  @Input() isDmPreview: boolean;
  @Input() pageViews: number | null;

  followTargetTypeEnum = FollowTargetTypeEnum;

  hasTruncateLength = false;
  isTruncated = false;
  descriptionTruncateLength = 30;
  
  currentRoute: string;

  private subscription = new Subscription();
  
  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly translocoService: TranslocoService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.currentRoute = this.router.url;
    this.hasTruncateLength = this.danceManager?.description?.split(' ').length > this.descriptionTruncateLength;
  }

  openDanceSchoolLogoDialogComponent(): void {
    const dialogRef = this.dialog.open(LogoAndTitleUploadDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      width: '100%',
      maxWidth: '1140px',
      data: {
        id: this.danceManager.id,
        mode: LogoAndTitleUploadDialogTypeEnum.STUDIO_LOGO,
      },
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.danceManager.imageUrl = result.imageUrl;
        }
      })
    );
  }

  clickOnLogoDelete(): void {
    const cancelDialogRef = this.dialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.translocoService.translate('DANCE_MANAGER.DELETE_LOGO'),
        subtitle: this.translocoService.translate('DANCE_MANAGER.DELETE_LOGO_SUBTITLE'),
        infoText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
        cancelText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      },
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.deleteLogo();
      }
    });
  }

  deleteLogo(): void {
    this.subscription.add(
      this.danceSchoolHttpService.deleteDanceManagerLogoByDanceSchoolId(this.danceManager.id).subscribe((danceSchool) => {
        this.danceManager.imageUrl = danceSchool.imageUrl;
      })
    );
  }

  routeToSettings(fragment?: string): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceManager.id, dfmAdministrationRoute], { fragment: fragment });
  }

  routeToDanceManager(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['app', '@' + this.danceManager.urlName]));
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
