import {Component, Input, OnInit} from '@angular/core';
import { Amenity } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-monorepo-amenities-view',
  templateUrl: './amenities-view.component.html',
  styleUrls: ['./amenities-view.component.scss'],
})
export class AmenitiesViewComponent {
  @Input() amenities: Amenity[];
}
