import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryPassListComponent } from './inventory-pass-list.component';
import { MatChip } from '@angular/material/chips';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import { InventoryPassListItemModule } from '../inventory-pass-list-item';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [InventoryPassListComponent],
    imports: [
        CommonModule,
        MatChip,
        DfSharedButtonModule,
        InventoryPassListItemModule,
        TranslocoDirective
    ],
  exports: [InventoryPassListComponent]
})
export class InventoryPassListModule {}
