<ng-container *transloco="let t">
  <div class="flex-column">
      <df-shared-lib-dance-style-search-input
              [alreadySelectedDanceStyles]="temporaryDanceStyles"
              (selectedDanceStyleTranslationKey)="onDanceStyleSelectedFromSearch($event)"
      ></df-shared-lib-dance-style-search-input>
      <div *ngFor="let danceStyle of temporaryDanceStyles | orderByPipe:'translationKey'; trackBy: trackProfileDanceStyle">
          <div>
              <mat-divider></mat-divider>
          </div>
          <df-shared-lib-dance-style-edit-item
                  class="p2"
                  [profileDanceStyle]="danceStyle"
                  (onProfileDanceStyleChange)="onDanceStyleUpdated($event)"
                  (onProfileDanceStyleDelete)="onDanceStyleDeleted($event)"
          ></df-shared-lib-dance-style-edit-item>
      </div>
      <div class="flex-row align-end-center gap-20 action-section">
          <df-shared-lib-button
                  (buttonClicked)="reset()"
                  [isDisabled]="!dataChanged"
                  [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
          ></df-shared-lib-button>
          <df-shared-lib-button
                  (buttonClicked)="onSaveClick()"
                  [isDisabled]="!dataChanged"
                  [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
          ></df-shared-lib-button>
      </div>
  </div>
</ng-container>
