import { Injectable } from '@angular/core';
import { MessagePartnerDto } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class TaggingService {
  constructor() {}

  mapToOptions(partners: MessagePartnerDto[]): MessagePartnerDto[] {
    return partners.map(
      (partner) =>
        ({
          id: partner.id,
          value: partner.username,
          displayValue: partner.username, //duplicate?
          info: partner.info || 'Tanzschule',
          imageUrl: partner.imageUrl ?? null,
          isDanceSchool: partner.id.includes('dance-school-')
        } as any)
    );
  }
}
