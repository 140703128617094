import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LanguageSelectionComponent } from './language-selection.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [LanguageSelectionComponent],
  exports: [LanguageSelectionComponent],
    imports: [
      CommonModule,
      MatCardModule,
      MatFormFieldModule,
      MatSelectModule,
      TranslocoDirective,
    ],
  providers: [],
})
export class LanguageSelectionModule {}
