import { PassTargetTypeEnum, UserPassScanStatusEnum } from '../../enums';

export interface UserPassScanInterface {
  id?: string;
  userPassId?: string;
  scannedByUserId?: string;
  targetId?: string;
  targetType?: PassTargetTypeEnum;
  scannedAt?: Date;
  status?: UserPassScanStatusEnum;
}
