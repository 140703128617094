import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceEventListTableComponent } from './dance-event-list-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmationDialogModule } from '../../confirmation-dialog';
import { PipesModule } from '../../../pipes';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceEventListTableComponent],
  exports: [DanceEventListTableComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatSortModule,
        MatDialogModule,
        ConfirmationDialogModule,
        MatTooltipModule,
        MatSlideToggleModule,
        PipesModule,
        TranslocoDirective
    ],
})
export class DanceEventListTableModule {}
