import { FollowTargetTypeEnum, NotificationDataInterface, NotificationUseCaseEnum } from '../../..';

export class FollowNotificationDataDto implements NotificationDataInterface {
  public readonly useCase = NotificationUseCaseEnum.NEW_FOLLOW;
  
  constructor(
    public readonly useCaseSpecificData: {
      fromTargetId: string;
      toTargetId: string;
      toTargetType: FollowTargetTypeEnum;
    }) {}
}
