import {Injectable} from '@angular/core';
import {io} from "socket.io-client";
import { environmentForWeb } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private socket = null;

  connect(accessToken: string): void {
    if (!this.socket) {
      const websocketUrl = environmentForWeb.production ?
        'wss://' + window.location.hostname :
        'ws://localhost:4200';
      this.socket = io(websocketUrl);

      this.socket.on('connect', () => {
        this.socket.emit('authenticate', {jwt: accessToken});
      });
      this.socket.on('disconnect', () => {
      });
    } else {
      this.socket.emit('authenticate', {jwt: accessToken});
    }
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  getSocket() {
    return this.socket;
  }
}
