import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function atLeastOneRequiredValidator(fields: string[]): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const hasAtLeastOne = fields.some(field => {
      const control = formGroup.get(field);
      if (!control) {
        // Check nested controls
        const nestedFields = field.split('.');
        let nestedControl = formGroup;
        nestedFields.forEach(nestedField => {
          nestedControl = nestedControl?.get(nestedField);
        });
        return nestedControl && nestedControl.value && nestedControl.value.trim();
      }
      return control && control.value && control.value.trim();
    });

    return hasAtLeastOne ? null : { atLeastOneRequired: true };
  };
}

export function atLeastOneOrAllInGroupRequiredValidator(groupFields: string[], otherFields: string[]): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const groupValid = groupFields.every(field => {
      const control = formGroup.get(field);
      return control && control.value !== null && control.value !== undefined && control.value !== '';
    });

    if (groupValid) {
      return null;
    }

    const otherValid = otherFields.some(field => {
      const control = formGroup.get(field);
      return control && control.value !== null && control.value !== undefined && control.value !== '';
    });

    return otherValid ? null : { atLeastOneOrAllInGroupRequired: true };
  };
}
