import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DanceEventCoHostsInterface, DanceEventsInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-co-host-notification',
  templateUrl: './co-host-notification.component.html',
  styleUrls: ['./co-host-notification.component.scss'],
})
export class CoHostNotificationComponent{
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  @Input() invitedCoHosts: DanceEventCoHostsInterface[] = [];
  @Input() danceEvent: DanceEventsInterface;
  @Output() acceptInvitation: EventEmitter<DanceEventCoHostsInterface> = new EventEmitter();
  @Output() declineInvitation: EventEmitter<DanceEventCoHostsInterface> = new EventEmitter();

  acceptCoHostInvitation(coHost: DanceEventCoHostsInterface):void {
    this.acceptInvitation.emit(coHost);
  }

  declineCoHostInvitation(coHost: DanceEventCoHostsInterface):void {
    this.declineInvitation.emit(coHost);
  }
  
}
