import { DanceManagerPayoutTransactionStatusEnum } from '../../enums';

export interface DanceManagerPayoutTransactionInterface {
  id: string;
  stripeTransactionId: string;
  stripePaymentIntentId: string;
  amount: number;
  fee: number;
  netAmount: number;
  type: DanceManagerPayoutTransactionStatusEnum;
}
