import { DanceEventRestrictionsInterface } from './dance-event-restrictions.interface';
import { DanceEventRulesInterface } from './dance-event-rules.interface';
import { ImageInterface } from '../image.interface';
import { AddressInterface } from '../address.interface';
import { DanceEventCoHostsInterface } from './dance-event-co-hosts';

export interface CreateDanceEventInterface {
  name?: string;
  danceStyleIds?: string[];
  musicStyleIds?: string[];
  description?: string;
  restrictions?: DanceEventRestrictionsInterface;
  rules?: DanceEventRulesInterface;
  mainImage?: ImageInterface;
  url?: string;
  tags?: string[];
  danceSchoolId?: string;
  isActive?: boolean;
  startDate?: Date;
  duration?: number;
  locationId?: string;
  address?: AddressInterface;
  coHosts?: DanceEventCoHostsInterface[];
}
