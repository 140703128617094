@if (ticketOrder) {
  <df-checkout-complete
    [ticketOrder]="ticketOrder"
    [danceManagerId]="course?.danceManagerId"
    [confirmationText]="confirmationText"
    [errorText]="errorText"
    [currentState]="ticketOrder?.status"
    [stateInit]="[ticketOrderStatusEnum.PAYMENT_INITIALIZED]"
    [stateSuccess]="[ticketOrderStatusEnum.PAID, ticketOrderStatusEnum.PAYMENT_PENDING]"
    [stateError]="[ticketOrderStatusEnum.PAYMENT_ERROR]"
  />
}
