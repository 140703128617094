import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '../../pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { DmSubscriptionCardComponent } from './dm-subscription-card.component';
import { DmSubscriptionPriceTableCardModule } from '../dm-subscription-price-table-card';
import { DmSubscriptionInfoCardModule } from '../dm-subscription-info-card';
import { DmSubscriptionPaymentHistoryCardModule } from '../dm-subscription-payment-history-card';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DmSubscriptionCardComponent],
    imports: [
      CommonModule,
      MatChipsModule,
      MatDividerModule,
      ReactiveFormsModule,
      PipesModule,
      DmSubscriptionPriceTableCardModule,
      DmSubscriptionInfoCardModule,
      DmSubscriptionPaymentHistoryCardModule,
      TranslocoDirective,
    ],
  exports: [DmSubscriptionCardComponent],
})
export class DmSubscriptionCardModule {}
