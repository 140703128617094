import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { DfAddLocationsComponent } from './df-add-locations.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { AddNewDmLocationDialogModule } from '../add-new-dm-location-dialog';
import { SliderGradientModule } from '../slider-gradient';
import { GoogleAddressSearchAutocompleteModule } from '../google-address-search-autocomplete';
import { ExternalLinkModule } from '../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DfAddLocationsComponent],
  imports: [
    CommonModule,
    SliderGradientModule, 
    MatCheckboxModule,
    ReactiveFormsModule, 
    FormsModule, 
    MatFormFieldModule, 
    MatAutocompleteModule,
    MatInputModule, 
    GoogleAddressSearchAutocompleteModule, 
    AddNewDmLocationDialogModule,
    MatIconModule,
    MatTooltipModule,
    ExternalLinkModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    TranslocoDirective,
  ],
  exports: [DfAddLocationsComponent]
})
export class DfAddLocationsModule {}
