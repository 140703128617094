import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translocoService: TranslocoService) {
  }

  transform(value: any, pattern: string ,timezone: string ): any {
    const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
    return datePipe.transform(value, pattern,timezone ?? 'UTC');
  }

}
