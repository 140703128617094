<ng-container *transloco="let t">
  <ng-container *ngFor="let danceSchoolLink of danceSchool?.socialLinks; let i = index; let last = last;">
    <mat-card class="socialLinksCard round-corners mat-elevation-z1" style="align-items: center;" [style.margin-bottom]="last ? 0 : '10px'">
      <div class="flex-row w-100" style="align-items: center; padding-left: 20px; cursor: pointer;" (click)="openSocialMediaLink(danceSchoolLink.url)">
        <img class="icon" height="26px" width="26px"  [src]="'assets/icons/social-icons/' + danceSchoolLink.type.toLowerCase() + '.png'">
        <div>
          <p style="display: inline-block;" class="text text-link url-ellipsis">{{ danceSchoolLink.url }}</p>
          <p class="text">{{ danceSchoolLink.type }}</p>
        </div>
      </div>
      <button mat-button color="warn" (click)="deleteSocialLinks(i); $event.stopPropagation()" [matTooltip]="t('GENERIC_WRAPPER.DELETE')">
        <mat-icon> delete</mat-icon>
      </button>
    </mat-card>
  </ng-container>
</ng-container>
