import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { PaymentProviderEnum, TicketOrderInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-ticket-checkout-payment',
  templateUrl: './ticket-checkout-payment.component.html',
  styleUrls: ['./ticket-checkout-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCheckoutPaymentComponent {
  @Input() ticketOrder: TicketOrderInterface;

  paymentProviderEnum = PaymentProviderEnum;
  selectedPaymentProvider = PaymentProviderEnum.STRIPE;

  paymentProviderSelectionChanged(event: MatRadioChange): void {
    this.selectedPaymentProvider = event.value;
  }
}
