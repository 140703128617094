import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE,
  DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN,
  DanceStyleInterface
} from '@platri/df-common-core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-info-form',
  templateUrl: './course-info-form.component.html',
  styleUrls: ['./course-info-form.component.scss']
})
export class CourseInfoFormComponent {
  @Input() infoFormGroup: UntypedFormGroup;
  @Input() loading = false;
  @Input() allowOverlapping: boolean;

  @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onContinueClick: EventEmitter<void> = new EventEmitter<void>();
  
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsMaxReached = false;

  constructor(private readonly translocoService: TranslocoService) {}
 
  setTagsMaxReached(): boolean {
    if (this.infoFormGroup.controls.tags?.value?.length >= 10) {
      this.tagsMaxReached = true;
      return this.tagsMaxReached;
    }
    else {
      this.tagsMaxReached = false;
      return this.tagsMaxReached;
    }
  }

  selectDanceStyle(danceStyle: DanceStyleInterface): void {
    this.infoFormGroup.patchValue({
      danceStyles: this.infoFormGroup.controls.danceStyles.value ? this.infoFormGroup.controls.danceStyles.value.concat([danceStyle]) : [danceStyle]
    });
    this.infoFormGroup.markAsDirty();
  }

  removeSelectedDanceStyle(danceStyle: DanceStyleInterface): void {
    let selectedDanceStyles = this.infoFormGroup.controls.danceStyles?.value;
    const index = selectedDanceStyles.indexOf(danceStyle);
    if (index >= 0) {
      selectedDanceStyles.splice(index, 1);
      selectedDanceStyles = selectedDanceStyles.length === 0 ? null : [...selectedDanceStyles];
    }
    this.infoFormGroup.patchValue({
      danceStyles: selectedDanceStyles
    });
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.chipInput?.inputElement;
    const value = event.value;
    this.setTagsMaxReached();
    if (this.tagsMaxReached === false) {
      if (value.trim() !== '') {
        if (this.infoFormGroup.controls.tags?.value && this.infoFormGroup.controls.tags?.value?.length > 0) {
          if (!this.infoFormGroup.controls.tags?.value?.includes(value)) {
            if ((value || '').trim()) {
              const existingTags: string[] = JSON.parse(JSON.stringify(this.infoFormGroup.controls.tags.value));
              existingTags.push(value);
              this.infoFormGroup.patchValue({
                tags: existingTags
              });
            }
            if (input) {
              input.value = '';
            }
          }
        } else {
          this.infoFormGroup.patchValue({
            tags: [value]
          });
          if (input) {
            input.value = '';
          }
        }
      }
    } else {
      this.infoFormGroup.controls.tags?.disable();
    }
    this.infoFormGroup.markAsDirty();
  }

  setCourseLevel(event: { fromLevel: number; toLevel: number }): void {
    if (!(this.allowOverlapping === false && event.fromLevel === event.toLevel)) {
      this.infoFormGroup.controls.startDifficulty.setValue(event.fromLevel);
      this.infoFormGroup.controls.endDifficulty.setValue(event.toLevel);
      this.infoFormGroup.markAsDirty();
    }
  }

  removeTag(tag: string): void {
    const index = this.infoFormGroup.controls.tags?.value.indexOf(tag);
    if (index >= 0) {
      this.infoFormGroup.controls.tags!.value.splice(index, 1);
    }
    this.setTagsMaxReached();
    if (!this.tagsMaxReached) {
      this.infoFormGroup.controls.tags?.enable();
    }
  }

  onImageUpload(formData: FormData): void {
    if (formData) {
      this.infoFormGroup.patchValue({
        mainImage: formData
      });
      this.infoFormGroup.markAsDirty();
    }
  }
  onImageDelete(): void {
    this.infoFormGroup.get('mainImage').setValue(null);
  }

  openDanceManagerDanceStyleRequestForm(): void {
    const url =
      this.translocoService.getActiveLang().toUpperCase() === 'DE'
      ? DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE
      : DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }
}
