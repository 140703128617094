import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { LoginFormComponent } from './login-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FacebookSignInButtonModule } from '../../facebook-sign-in-button';
import { GoogleSignInButtonModule } from '../../google-sign-in-button';
import { MatDividerModule } from '@angular/material/divider';
import { TranslocoDirective, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatCheckboxModule,
    FacebookSignInButtonModule,
    GoogleSignInButtonModule,
    MatDividerModule,
    TranslocoModule,
    TranslocoDirective
  ],
  exports: [LoginFormComponent]
})
export class LoginFormModule { }
