import * as _ from 'lodash';
import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  parse,
  startOfDay,
} from 'date-fns';
import { TimeRangeInterface, TimeInterface } from '../interfaces';

export class TimeOperations {
  static checkIfTimeIsNotBetweenTwoTimes(
    timeToCheck: TimeInterface,
    timeStart: TimeInterface,
    timeEnd: TimeInterface
  ): boolean {
    const dateToCheck = this.getDate(timeToCheck);
    const dateTimeStart = this.getDate(timeStart);
    const dateTimeEnd = this.getDate(timeEnd);

    //todo: Functions missing
    if (isBefore(dateTimeEnd, dateTimeStart)) {
      return (
        isBefore(dateToCheck, dateTimeStart) &&
        isAfter(dateToCheck, dateTimeEnd)
      );
    } else {
      return (
        isAfter(dateToCheck, dateTimeEnd) ||
        isBefore(dateToCheck, dateTimeStart)
      );
    }
  }

  static checkIfTimesOverlapWithBlockedTimes(
    timeRange: TimeRangeInterface,
    blockedTimes: TimeRangeInterface[]
  ): boolean {
    let isAvailable = false;
    return !blockedTimes.every((timeInfo) => {
      isAvailable = !this.checkIfTimesOverlap(timeRange, timeInfo);
      return isAvailable;
    });
  }

  static checkIfTimesOverlap(
    timeRangeToCheck: TimeRangeInterface,
    bookedRange: TimeRangeInterface
  ): boolean {
    // @ts-ignore
    const dateToCheckStart = this.getDate(timeRangeToCheck.timeStart);
    // @ts-ignore
    const dateToCheckEnd = this.getDate(timeRangeToCheck.timeEnd);
    // @ts-ignore
    const dateBookedStart = this.getDate(bookedRange.timeStart);
    // @ts-ignore
    const dateBookedEnd = this.getDate(bookedRange.timeEnd);

    if (
      isEqual(dateToCheckEnd, dateToCheckStart) ||
      isBefore(dateToCheckEnd, dateToCheckStart)
    ) {
      if (
        isEqual(dateToCheckStart, dateBookedEnd) ||
        isAfter(dateToCheckStart, dateBookedEnd)
      ) {
        return isAfter(dateToCheckEnd, dateBookedStart);
      } else {
        return isAfter(addDays(dateToCheckEnd, 1), dateBookedEnd);
      }
    } else {
      return (
        isBefore(dateToCheckStart, dateBookedEnd) &&
        isAfter(dateToCheckEnd, dateBookedStart)
      );
    }
  }

  static calculateDiffInMinutesBetweenTimes(
    startTime: TimeInterface,
    endTime?: TimeInterface
  ): number {
    if (
      // @ts-ignore
      startTime.hours >= 0 &&
      // @ts-ignore
      startTime.minutes >= 0 &&
      // @ts-ignore
      endTime.hours >= 0 &&
      // @ts-ignore
      endTime.minutes >= 0
    ) {
      const startDateTime = new Date();
      // @ts-ignore
      startDateTime.setHours(startTime.hours);
      // @ts-ignore
      startDateTime.setMinutes(startTime.minutes);
      const endDateTime = new Date();
      // @ts-ignore
      endDateTime.setHours(endTime.hours);
      // @ts-ignore
      endDateTime.setMinutes(endTime.minutes);
      return differenceInMinutes(endDateTime, startDateTime);
    } else {
      return -1;
    }
  }

  static getDate(time: TimeInterface): Date {
    let date = startOfDay(new Date());
    // @ts-ignore
    date = addHours(date, time.hours);
    // @ts-ignore
    date = addMinutes(date, time.minutes);

    return date;
  }

  static checkIfTimesOverlapWithOpeningHours(
    timeRange: TimeRangeInterface,
    timeOpeningHours: TimeRangeInterface
  ): boolean {
    // @ts-ignore
    const dateToCheckStart = this.getDate(timeRange.timeStart);
    // @ts-ignore
    const dateToCheckEnd = this.getDate(timeRange.timeEnd);
    // @ts-ignore
    const dateOpeningHoursStart = this.getDate(timeOpeningHours.timeStart);
    // @ts-ignore
    const dateOpeningHoursEnd = this.getDate(timeOpeningHours.timeEnd);

    if (_.isEqual(timeOpeningHours.timeStart, timeOpeningHours.timeEnd)) {
      return true;
    }
    if (_.isEqual(timeRange.timeStart, timeRange.timeEnd)) {
      return false;
    }

    if (isBefore(dateOpeningHoursEnd, dateOpeningHoursStart)) {
      return (
        (isEqual(dateToCheckStart, dateOpeningHoursStart) ||
          isAfter(dateToCheckStart, dateOpeningHoursStart)) &&
        (((isEqual(dateOpeningHoursStart, dateToCheckEnd) ||
              isAfter(dateOpeningHoursStart, dateToCheckEnd)) &&
            (isEqual(dateToCheckStart, dateToCheckEnd) ||
              isAfter(dateToCheckStart, dateToCheckEnd))) ||
          ((isEqual(dateOpeningHoursEnd, dateToCheckEnd) ||
              isBefore(dateOpeningHoursEnd, dateToCheckEnd)) &&
            (isEqual(dateOpeningHoursStart, dateToCheckEnd) ||
              isBefore(dateOpeningHoursStart, dateToCheckEnd))))
      );
    } else {
      return (
        this.checkOneTimeWithOpeningHours(
          dateToCheckStart,
          dateOpeningHoursStart,
          dateOpeningHoursEnd
        ) &&
        this.checkOneTimeWithOpeningHours(
          dateToCheckEnd,
          dateOpeningHoursStart,
          dateOpeningHoursEnd
        )
      );
    }
  }

  static checkOneTimeWithOpeningHours(
    dateToCheck: Date,
    dateOpeningHoursStart: Date,
    dateOpeningHoursEnd: Date
  ): boolean {
    return (
      (getHours(dateToCheck) > getHours(dateOpeningHoursStart) &&
        getHours(dateToCheck) < getHours(dateOpeningHoursEnd)) ||
      (getHours(dateToCheck) === getHours(dateOpeningHoursStart) &&
        getHours(dateToCheck) === getHours(dateOpeningHoursEnd) &&
        getMinutes(dateToCheck) >= getMinutes(dateOpeningHoursStart) &&
        getMinutes(dateToCheck) <= getMinutes(dateOpeningHoursEnd)) ||
      (getHours(dateToCheck) === getHours(dateOpeningHoursStart) &&
        getHours(dateToCheck) < getHours(dateOpeningHoursEnd) &&
        getMinutes(dateToCheck) >= getMinutes(dateOpeningHoursStart)) ||
      (getHours(dateToCheck) === getHours(dateOpeningHoursEnd) &&
        getMinutes(dateToCheck) <= getMinutes(dateOpeningHoursEnd)) ||
      (getHours(dateOpeningHoursStart) === getHours(dateOpeningHoursEnd) &&
        getMinutes(dateOpeningHoursStart) ===
        getMinutes(dateOpeningHoursEnd)) ||
      (getHours(dateOpeningHoursStart) > getHours(dateOpeningHoursEnd) &&
        getHours(dateToCheck) >= getHours(dateOpeningHoursStart)) ||
      (getHours(dateOpeningHoursStart) === getHours(dateOpeningHoursEnd) &&
        getMinutes(dateOpeningHoursStart) > getMinutes(dateOpeningHoursEnd) &&
        getHours(dateToCheck) > getHours(dateOpeningHoursStart)) ||
      (getHours(dateToCheck) === getHours(dateOpeningHoursStart) &&
        getMinutes(dateToCheck) >= getMinutes(dateOpeningHoursStart))
    );
  }

  static timeToString(time: TimeInterface): string {
    if (!time) {
      // @ts-ignore
      return;
    }
    if (time.hours === 0 && time.minutes === 0) {
      return `00:00`;
    }
    if (!time.hours && !time.minutes) {
      return '';
    }
    // @ts-ignore
    if (time.hours < 10) {
      // @ts-ignore
      if (time.minutes < 10) {
        return `0${time.hours}:0${time.minutes}`;
      }
      return `0${time.hours}:${time.minutes}`;
    }
    if (time.minutes === 0) {
      return `${time.hours}:00`;
    }
    // @ts-ignore
    if (time.minutes < 10) {
      return `${time.hours}:0${time.minutes}`;
    }
    return `${time.hours}:${time.minutes}`;
  }

  static stringToTime(time: string): TimeInterface {
    return {
      hours: getHours(parse(time, 'HH:mm', new Date())),
      minutes: getMinutes(parse(time, 'HH:mm', new Date())),
    };
  }

  static calculateDiffInHoursBetweenTimes(
    startTime: TimeInterface,
    endTime: TimeInterface
  ): number {
    let startTimeDate = new Date();
    // @ts-ignore
    startTimeDate = addHours(startTimeDate, startTime.hours);
    // @ts-ignore
    startTimeDate = addMinutes(startTimeDate, startTime.minutes);

    let endTimeDate = new Date();
    // @ts-ignore
    endTimeDate = addHours(endTimeDate, endTime.hours);
    // @ts-ignore
    endTimeDate = addMinutes(endTimeDate, endTime.minutes);

    if (
      // @ts-ignore
      endTime.hours < startTime.hours ||
      (endTime.hours === startTime.hours &&
        // @ts-ignore
        endTime.minutes <= startTime.minutes)
    ) {
      endTimeDate = addDays(endTimeDate, 1);
    }

    return +(
      Math.round(
        Number(differenceInMinutes(endTimeDate, startTimeDate) / 60 + 'e+2')
      ) + 'e-2'
    );
  }
}
