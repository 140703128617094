import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { DfmSettingsPageModule } from './pages/app';
import { FrontendDfmSettingsAppRoutingModule } from './frontend-dfm-settings-app-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfmSettingsAppRoutingModule,
    DfmSettingsPageModule,
    MatSnackBarModule,
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendDfmSettingsAppModule {}
