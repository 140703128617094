import { AbstractControl, ValidatorFn } from '@angular/forms';

export function optionalMinMaxValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === undefined || control.value === '') {
      return null;
    }
    const value = Number(control.value);
    if (isNaN(value)) {
      return { 'invalidNumber': true };
    }
    if (value < min) {
      return { 'min': { 'requiredMin': min, 'actual': value } };
    }
    if (value > max) {
      return { 'max': { 'requiredMax': max, 'actual': value } };
    }
    return null;
  };
}
