import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogoAndTitleUploadDialogTypeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-add-image-dialog',
  templateUrl: './add-image-dialog.component.html',
  styleUrls: ['./add-image-dialog.component.scss'],
})
export class AddImageDialogComponent {
  formData: FormData;
  imageCropped: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      formdata: FormData;
      imageCropped: string;
      ratio: { width: number; height: number };
      mode: LogoAndTitleUploadDialogTypeEnum;
    },

    private readonly dialogRef: MatDialogRef<AddImageDialogComponent>
  ) {}

  imagePreview(croppedImage: string):void {
    this.imageCropped = croppedImage;
  }

  onSubmit(formData: FormData):void {
    this.formData = formData;

    if (this.imageCropped && this.formData) {
      this.dialogRef.close({
        formData: this.formData,
        imageCropped: this.imageCropped,
      });
    }
  }
}
