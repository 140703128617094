<ng-container *transloco="let t">
    <div class="w-100 h-100 container align-center">
    <mat-card class="form-card flex-column">
      <mat-progress-bar [mode]="loading? 'indeterminate' : 'determinate'" [value]="progress.value" data-cy="mat-progress-bar"></mat-progress-bar>
      <div class="full-content-container w-100 h-100 flex-row">
        <div class="h-100 background-image-container flex-column">
          <div class="p3" [@switchAnimation]="currentStep">
            <div class="mat-headline-4" style="margin-bottom: 16px;">
              {{ t('CREATE_DANCE_MANAGER.STEPS.' + currentStep + '.TITLE') }}
            </div>
            <div class="mat-headline-5" *ngIf="steps[currentStep-1].optional" style="color: #3377FF;">
              {{ t('GENERIC_WRAPPER.OPTIONAL') }}
            </div>
            <div class="mat-headline-6">
              {{ t('CREATE_DANCE_MANAGER.STEPS.' + currentStep + '.SUBTITLE') }}
            </div>
          </div>
        </div>
        
        <div class="form-container w-100 h-100 p2">
          <div class="w-100 mobileButtonContainer mb-20">
            <div class="align-start" *ngIf="currentStep !== 1">
              <button
                mat-button
                (click)="previousStep()"
                [disabled]="loading"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
            </div>
            <div class="align-end w-100">
              <button
                mat-button
                (click)="cancelCreateDanceManager()"
                [disabled]="loading"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="currentStep">
            <span>
              {{ t('CREATE_DANCE_MANAGER.STEP') }} {{currentStep}}
              {{ t('CREATE_DANCE_MANAGER.OF') }} {{maxSteps}}
            </span>
          </div>
          <div
            class="w-100 h-100 flex-column removeMobilePadding"
          >
            <div class="mb-30 mobileTextContainer w-100" [@switchAnimation]="currentStep">
              <div class="mat-headline-5">
                {{ t('CREATE_DANCE_MANAGER.STEPS.' + currentStep + '.TITLE') }}
              </div>
              <div class="mat-subtitle-1" *ngIf="steps[currentStep-1].optional">
                {{ t('GENERIC_WRAPPER.OPTIONAL') }}
              </div>
              <div class="mat-headline-6">
                {{ t('CREATE_DANCE_MANAGER.STEPS.'+currentStep+'.SUBTITLE') }}
              </div>
            </div>
            <form
              [formGroup]="formGroup"
              class="h-100 w-100 relative"
            >
              <div
                class="w-100 step"
                *ngIf="currentStep === 1"
                [@enterAnimation]
                id="step1"
              >
                <!-- Name -->
                <div class="w-100 flex-column mb-40">
                  <div class="mb-10">
                    <strong>{{ t('GENERIC_WRAPPER.NAME') }}</strong>
                  </div>
                  <mat-form-field appearance="outline" class="w-100">
                    <input
                      id="dance-manager-name-input"
                      autocomplete="off"
                      matInput
                      placeholder="{{ t('CREATE_DANCE_MANAGER.PAGE_NAME') }}"
                      formControlName="name"
                      #name
                    />
                    <mat-hint align="end">{{name.value.length}} / 32</mat-hint>
                    
                    <mat-error *ngIf="nameControl && nameControl.hasError('required')">
                      {{ t('GENERIC_WRAPPER.NAME_IS_REQUIRED') }}
                    </mat-error>
                    
                    <mat-error
                      *ngIf=" nameControl && nameControl.hasError('maxlength')"
                      class="errorTextSpaceBetween"
                    >
                      <div>
                        {{ t('GENERIC_WRAPPER.NAME_ERROR_MAX_LENGTH') }}
                      </div>
                      <div>
                        {{nameControl.errors?.maxlength.actualLength}} /
                        {{nameControl.errors?.maxlength.requiredLength}}
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- Description -->
                <div class="w-100 flex-column mt">
                  <div class="mb-10">
                    <strong>{{ t('GENERIC_WRAPPER.DESCRIPTION') }}</strong>
                  </div>
                  <div class="mt2 w-100">
                    <df-shared-lib-quill-editor
                      [enableMentions]="false"
                      [id]="'description'"
                      [placeholder]="'GENERIC_WRAPPER.DESCRIPTION'"
                      [customFormControlName]="'description'"
                      [enableCounter]="true"
                      [maxLength]="2000"
                      [hasError]="descriptionControl.touched && descriptionControl.invalid"
                    ></df-shared-lib-quill-editor>
                  </div>
                  <mat-error
                    *ngIf="descriptionControl.hasError('characterCountExceeded')"
                    class="custom-error"
                  >{{ t('GENERIC_WRAPPER.DESCRIPTION_ERROR_LENGTH') }}</mat-error>
                  <mat-error
                    *ngIf="descriptionControl.touched && descriptionControl.hasError('required')"
                    class="custom-error"
                  >{{ t('GENERIC_WRAPPER.DESCRIPTION_REQUIRED') }}</mat-error>
                </div>
              </div>
              
              <!--dm category-->
              <div
                class="w-100 step flex-column"
                *ngIf="currentStep === 2"
                [@enterAnimation]
                id="step2"
              >
                <div class="w-100 flex-column">
                  <strong class="mb-10">{{ t('GENERIC_WRAPPER.CATEGORY') }}</strong>
                  <div class="request-category mb-10">
                    <span>{{ t('CREATE_DANCE_MANAGER.SELECT_MANY') + " " }}</span>
                  </div>
                  <mat-chip-listbox selectable multiple class="mb-20">
                    <mat-chip-option
                      disableRipple
                      *ngFor="let category of dmCategories | keyvalue"
                      (selectionChange)="toggleSelection(category.value, $event)"
                      [selected]="matchSelection(category.value)"
                    >{{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category.value) }}</mat-chip-option>
                  </mat-chip-listbox>
                  <div class="request-category">
                    <span>{{ t('CREATE_DANCE_MANAGER.CANNOT_FIND_CATEGORY') + " " }}</span>
                    <span 
                      class="df-link" 
                      (click)="openDanceManagerCategoryRequestForm()"
                    >{{ t('CREATE_DANCE_MANAGER.REQUEST_CATEGORY') }}.</span>
                  </div>
                </div>
              </div>
              
              <!--DanceStyles Step-->
              <div
                class="flex-column w-100 step gap-40"
                *ngIf="currentStep === 3"
                [@enterAnimation]
                id="step3"
              >
                <div class="w-100 flex-column mb-40">
                  <div class="flex-column">
                    <df-shared-lib-search-dance-style 
                      class="w-75" 
                      [previouslySelectedOptions]="danceStylesControl?.value" 
                      (selectedOption)="selectDanceStyle($event)"
                    ></df-shared-lib-search-dance-style>
                       
                    <div class="request-dance-style mb2">
                      <span>{{t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE') + " "}}</span>
                      <span 
                        class="df-link" 
                        (click)="openDanceManagerDanceStyleRequestForm()"
                      >{{ t('GENERIC_WRAPPER.REQUEST_DANCE_STYLE_LINK') }}.</span>
                    </div>
                       
                    <div *ngIf="danceStylesControl?.value?.length > 0" class="w-75">
                      <mat-chip-listbox>
                        <mat-chip-option
                          class="chip-bg"
                          [removable]="true"
                          [selectable]="false"
                          (removed)="removeSelectedDanceStyle(danceStyle)"
                          *ngFor="let danceStyle of danceStylesControl.value">
                          {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                      </mat-chip-listbox>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Business Address -->
              <div
                class="w-100 step flex-column"
                *ngIf="currentStep === 4"
                [@enterAnimation]
                id="step4"
              >
                <div class="flex-column w-100 mb-40">
                  <span class="label-text mb-10">{{ t('CREATE_DANCE_MANAGER.BUSINESS_ADDRESS') }}</span>
                  <span class="description-text mb-10">{{ t('CREATE_DANCE_MANAGER.BUSINESS_ADDRESS_DESCRIPTION') }}</span>
                  <df-shared-lib-google-address-search-autocomplete
                    [label]="t('CREATE_DANCE_MANAGER.BUSINESS_ADDRESS')"
                    [address]="formGroup.controls.businessAddress.value"
                    (addressSelected)="setSelectedBusinessAddress($event)"
                    (addressChanging)="setSelectedBusinessAddress()"
                    data-cy="google-auto-complete-button"
                  ></df-shared-lib-google-address-search-autocomplete>
                  <div>
                    <span 
                      *ngIf="showAddLocationButton" 
                      class="add-location-text-button link" 
                      (click)="addBusinessLocation()"
                    >{{ t('CREATE_DANCE_MANAGER.ADD_AS_LOCATION') }}</span>
                  </div>
                </div>
                
                <div class="w-100 flex-column">
                  <df-shared-lib-add-locations [formGroup]="formGroup"></df-shared-lib-add-locations>
                </div>
              </div>
              
              <!--Logo-->
              <div
                class="w-100 step flex-column"
                *ngIf="currentStep === 5"
                [@enterAnimation]
                id="step5"
              >
                <div class="w-100 mb-40 flex-column">
                  <div class="mb-10">
                    <strong>{{ t('CREATE_DANCE_MANAGER.LOGO') }}</strong>
                  </div>
                  <df-add-image
                    [ratio]="{width:1,height:1}"
                    [mode]="LogoAndTitleUploadDialogTypeEnum.STUDIO_LOGO"
                    (selectedUploadImage)="onImageUpload($event,'logo')"
                    (imageDeleted)="onImageDelete('logo')"
                    [croppedImageBaseAs64]="logoTemp"
                    [isOpenAddImage]="!!logoTemp"
                  ></df-add-image>
                </div>
                <div class="w-100 flex-column">
                  <div class="mb-10">
                    <strong>{{ t('CREATE_DANCE_MANAGER.TITLE_IMAGE') }}</strong>
                  </div>
                  
                  <df-add-image
                    (selectedUploadImage)="onImageUpload($event,'titleImage')"
                    (imageDeleted)="onImageDelete('titleImage')"
                    [croppedImageBaseAs64]="titleTemp"
                    [isOpenAddImage]="!!titleTemp"
                    [ratio]="{width:2,height:1}"
                  ></df-add-image>
                </div>
              </div>
              
              <!--phone and email-->
              <div
                class="w-100 step flex-column"
                *ngIf="currentStep === 6"
                [@enterAnimation]
                id="step6"
              >
                <div class="w-100 mb-40">
                  <df-monorepo-phone-input
                    [formGroup]="formGroup"
                    [showSuffix]="false"
                  ></df-monorepo-phone-input>
                </div>
                
                <div class="w-100 flex-column">
                  <strong>{{ t('GENERIC_WRAPPER.EMAIL') }}</strong>
                  <p class="df-link" (click)="updateFormEmailWithCurrentUserEmail()">
                    {{ currentUser?.email ? t('CREATE_DANCE_MANAGER.STEPS.6.USE_CURRENT_EMAIL') + ' ' + currentUser?.email : '' }}
                  </p>
                  <mat-form-field appearance="outline" class="w-100">
                    <input
                      autocomplete="off"
                      matInput
                      placeholder="Email"
                      formControlName="email"
                    />
                    <mat-error *ngIf="emailControl && (emailControl.hasError('required') || emailControl.hasError('pattern'))">
                      {{ t('GENERIC_WRAPPER.EMAIL_IS_REQUIRED') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      <div class="button-container w-100 p2">
        <df-shared-lib-button 
          class="cancelBtn" 
          [minWidth]="'150px'" 
          [materialButtonStyleType]="'outlined'" 
          [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" 
          (buttonClicked)="cancelCreateDanceManager()" 
          [isDisabled]="loading"
        ></df-shared-lib-button>
        
        <div class="flex-row align-center w-100">
          <div class="flex-column w-100">
            <div class="w-100 submit-skip-BtnContainer">
              <div class="flex-row w-100 alignBtnEnd">
                <df-shared-lib-button 
                  *ngIf="currentStep !== 1" 
                  class="cancelBtn mr-10" 
                  [materialButtonStyleType]="'outlined'" 
                  [minWidth]="'150px'" 
                  [buttonLabel]="t('GENERIC_WRAPPER.BACK')" 
                  (buttonClicked)="previousStep()"
                ></df-shared-lib-button> 
                <df-shared-lib-button 
                  class="mobileBtnStretch" 
                  [minWidth]="'150px'" 
                  [buttonLabel]="t(currentStep === 6 ? 'GENERIC_WRAPPER.CREATE' : 'GENERIC_WRAPPER.NEXT')" 
                  (buttonClicked)="nextStep()" 
                  [isDisabled]="!currentStepValid || loading"
                ></df-shared-lib-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
