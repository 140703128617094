import { AgeRestrictionInterface, GenderRestrictionInterface, ParticipantRestrictionInterface } from '../../..';

export class AgeRestrictionDto implements AgeRestrictionInterface{
  fromAge?: number;
  toAge?: number;
}
export class GenderRestrictionDto implements GenderRestrictionInterface{
  isMaleRestricted?: boolean;
  isFemaleRestricted?: boolean;
  isDiverseRestricted?: boolean;
}
export class ParticipantRestrictionDto implements ParticipantRestrictionInterface{
  isAgeRestricted?: boolean;
  isGenderRestricted?: boolean;
  ageRestriction?: AgeRestrictionDto;
  genderRestriction?: GenderRestrictionDto;
}
