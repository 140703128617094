<ng-container *transloco="let t">
  <form class="w-100">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.DANCESTYLE_SKILL')}}</mat-label>
      <input
        autocomplete="off"
        type="text"
        matInput
        [formControl]="danceStyleControl"
        [matAutocomplete]="auto"
        [minlength]="4"
        [maxlength]="24"
      >
      <mat-autocomplete #auto="matAutocomplete" hideSingleSelectionIndicator>
        <mat-option
          *ngFor="let danceStyle of filteredDanceStyleList | async"
          [value]="danceStyle"
          (click)="onDanceStyleSelect(danceStyle)"
        >
          {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle?.toUpperCase()) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</ng-container>
