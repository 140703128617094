import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DanceSchoolInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class DanceSchoolStateService {
  // @ts-ignore
  private currentDanceSchoolSubject: BehaviorSubject<DanceSchoolInterface> = new BehaviorSubject<DanceSchoolInterface>(null);
  private currentDanceSchoolsSubject: BehaviorSubject<DanceSchoolInterface[]> = new BehaviorSubject<DanceSchoolInterface[]>([]);
  
  //
  // currentDanceSchoolSubject
  //
  
  public getAsyncCurrentDanceSchool(): Observable<DanceSchoolInterface> {
    return this.currentDanceSchoolSubject.asObservable();
  }

  public getSyncCurrentDanceSchool(): DanceSchoolInterface {
    return this.currentDanceSchoolSubject.getValue();
  }

  public sendCurrentDanceSchool(danceSchool: DanceSchoolInterface): void {
    if (danceSchool) {
      this.currentDanceSchoolSubject.next({
        ...this.getSyncCurrentDanceSchool(),
        ...danceSchool
      });
    }
    else {
      this.currentDanceSchoolSubject.next(null);
    }
  }

  //
  // currentDanceSchoolsSubject
  //

  public getAsyncCurrentDanceSchools(): Observable<DanceSchoolInterface[]> {
    return this.currentDanceSchoolsSubject.asObservable();
  }

  public getSyncCurrentDanceSchools(): DanceSchoolInterface[] {
    return this.currentDanceSchoolsSubject.getValue();
  }

  public sendCurrentDanceSchools(danceSchools: DanceSchoolInterface[]): void {
    this.currentDanceSchoolsSubject.next(danceSchools);
  }
}
