import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerPassesPage } from './dance-manager-passes.page';
import { MatIconModule } from '@angular/material/icon';
import { ContactUrlDirectiveModule, DfSharedButtonModule, PassCheckoutEmptyListModule, PassCheckoutListItemModule, PassCheckoutListSummaryModule, PaymentMethodsViewModule, PipesModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceManagerPassesPage],
    imports: [
      CommonModule,
      MatIconModule,
      PipesModule,
      MatDividerModule,
      ReactiveFormsModule,
      PassCheckoutListItemModule,
      PassCheckoutEmptyListModule,
      PaymentMethodsViewModule,
      DfSharedButtonModule,
      ContactUrlDirectiveModule,
      PassCheckoutListSummaryModule,
      MatFormField,
      MatError,
      MatInput,
      MatLabel,
      FormsModule,
      TranslocoDirective
    ]
})
export class DanceManagerPassesPageModule {
  
  constructor() {
    console.log('Init DanceManagerPassesPageModule');
  }
}
