import { UserTokenTypeEnum } from '../enums';
import { UsersInterface } from './users';

export interface UserTokenInterface {
  created?: Date;
  validUntil?: Date;
  userTokenType?: UserTokenTypeEnum;
  id?: string;
  token?: string;
  user?: UsersInterface;
}
