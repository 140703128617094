import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (typeof control.value !== 'string') {
      return null;
    }

    const hasWhitespace = control.value.indexOf(' ') >= 0;
    return !hasWhitespace ? null : { 'whitespace': true };
  };
}
