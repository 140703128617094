<ng-container *transloco="let t">
  <div class="flex-column w-100 gap-20 p2" [formGroup]="formGroup">
    <span class="title">{{t('GENERIC_WRAPPER.DUPLICATE_EVENT') }}</span>
    <mat-form-field appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.NAME') }}</mat-label>
      <input matInput type="text" formControlName="name"/>
    </mat-form-field>
    <mat-form-field (click)="startDate.showPicker()" appearance="outline" class="w-15-gt-sm w-30-lt-md w-50-lt-sm">
      <mat-label>{{t('GENERIC_WRAPPER.EVENT_DATE_START')}}</mat-label>
      <input type="datetime-local" matInput #startDate formControlName="startDate"/>
      <mat-icon matSuffix fontIcon="today"/>
    </mat-form-field>
    <mat-form-field (click)="endDate.showPicker()" appearance="outline" class="w-15-gt-sm w-30-lt-md w-50-lt-sm">
      <mat-label>{{t('GENERIC_WRAPPER.EVENT_DATE_END')}}</mat-label>
      <input type="datetime-local" matInput #endDate formControlName="endDate" [min]="formGroup.get('startDate').value"/>
      <mat-icon matSuffix fontIcon="today"/>
    </mat-form-field>
    <mat-error *ngIf="formGroup.hasError('dateRangeInvalid')">
        {{ t('GENERIC_WRAPPER.EVENT_DATE_END_MUST_BE_LATER_START_DATE')}}
    </mat-error>
    
    <ng-container *ngIf="tickets?.length > 0">
      <mat-checkbox color="primary" formControlName="duplicateTickets" [checked]="formGroup.controls.duplicateTickets.value">{{t('GENERIC_WRAPPER.DUPLICATE_TICKETS') }}</mat-checkbox>
    </ng-container>
  
    <div class="flex-row align-space-between gap-15">
      <df-shared-lib-button class="w-100" [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" (buttonClicked)="closeDialog()" materialButtonStyleType="outlined"></df-shared-lib-button>
      <df-shared-lib-button class="w-100" [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" (buttonClicked)="save()" [isDisabled]="formGroup.invalid" materialButtonStyleType="flat"></df-shared-lib-button>
    </div>
  </div>
</ng-container>
