<ng-container *transloco="let t">
  <df-shared-lib-google-address-search-autocomplete
    #addressInput
    [label]="t('GENERIC_WRAPPER.CITY')"
    [alternativeDesign]="false"
    (addressSelected)="onAddressSelected($event)"
    [houseNumberRequired]="false"
    [isCity]="true"
    [types]="['(cities)']"
  ></df-shared-lib-google-address-search-autocomplete>
  <div class="flex-row align-end-center gap-20 action-section">
    <df-shared-lib-button
      [materialButtonStyleType]="'outlined'"
      [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
      (buttonClicked)="cancel()"
      [isDisabled]="!addressInput.searchAddressForm.dirty"
    ></df-shared-lib-button>
    <df-shared-lib-button
      [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
      (buttonClicked)="saveFormerData()"
      [isDisabled]="newPlace === null"
    ></df-shared-lib-button>
  </div>
</ng-container>
