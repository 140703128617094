<ng-container *transloco="let t">
  <div class="flex-container">
    <form class="form flex-container-centered-content" id="magic-login-form" [formGroup]="magicLoginFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyMagicLogin">
      <h4 class="mat-headline-3 change-font-size-mobile" style="text-align: center; margin-bottom: 16px; margin-top: 16px;">{{ t('GENERIC_WRAPPER.LOGIN_WITHOUT_PASSWORD') }}</h4>
      <div style="width: 100%" class="flex-container-row">
        <div style="margin-bottom: -4px" class="form-step-container flex-container-column">
          <p style="margin-bottom: 16px;">{{ t('GENERIC_WRAPPER.LOGIN_WITHOUT_PASSWORD_TEXT') }}</p>
          <mat-form-field appearance="outline">
            <mat-label>{{ t('GENERIC_WRAPPER.EMAIL') }}</mat-label>
            <input id="email-input" matInput type="text" [formControl]="magicLoginFormGroup.controls.email" [placeholder]="t('GENERIC_WRAPPER.EMAIL')" >
            <mat-error *ngIf="magicLoginFormGroup.controls.email.hasError('pattern')">
              {{ t('GENERIC_WRAPPER.PLEASE_INSERT_A_CORRECT_EMAIL') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      
      <div style="width: 100%">
        <button id="reset-button" [disabled]="isWaitingForMagicLoginResponseResponse || !magicLoginFormGroup.valid" [class.spinner]="isWaitingForMagicLoginResponseResponse" style="width: 100%; color: white; border-radius: 10px; " mat-raised-button color="primary" type="submit" data-cy="submit-button">{{ t('GENERIC_WRAPPER.REQUEST_LINK') }}</button>
      </div>
      
      <div style="margin-top: 16px; ">
        <p style="margin-block-start: 0; margin-block-end: 0; width: 100%;"> <button type="button" id="back-to-login-button" mat-button color="primary" (click)="routeToLogin()" data-cy="cancel-button">{{ t('GENERIC_WRAPPER.BACK_TO_LOGIN') }}</button></p>
      </div>
  
      <div style="height: 60px">
        <p class="forgot-password-error wrong-data" id="magic-login-wrong-data-error" *ngIf="magicLoginFailedWrongData" data-cy="wrong-data-error">{{ t('GENERIC_WRAPPER.PROVIDED_DATA_WRONG') }}</p>
        <p class="forgot-password-error" *ngIf="magicLoginFailedWithNoConnection" data-cy="connection-error">{{ t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED') }}</p>
      </div>
      
      
    </form>
    
    <div id="successful-magic-link-requested" *ngIf="hasSuccessfullyMagicLogin" class="flex-container-centered-content">
      <h4>{{ t('GENERIC_WRAPPER.EMAIL_SENT_TO') }}</h4>
      <p style=" margin-block-start: 0;">
        <span>
          {{magicLoginFormGroup.controls.email.value}}
          <mat-icon style="color: green; vertical-align: text-bottom">
          check_circle
        </mat-icon>
        </span>
        <br> <br>
        <span [innerHTML]=" t('GENERIC_WRAPPER.CHECK_EMAIL_FURTHER_REQUESTS')"></span>
      </p>
      <button mat-raised-button color="primary" type="button" style="width: 100%" (click)="routeToLogin(true)" data-cy="ok-button">{{ t('GENERIC_WRAPPER.OK') }}</button>
      <div style="height: 60px">
        <!-- Resend Email ? -->
        <p class="forgot-password-error" *ngIf="magicLoginFailedWithNoConnection && false" data-cy="forgot-password-error">{{ t('GENERIC_WRAPPER.SEND_ANOTHER_EMAIL') }}</p>
      </div>
    </div>
    
  </div>
  
  <p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">{{ t('GENERIC_WRAPPER.FATAL_ERROR_LOADING_DATA') }}</p>
  <p class="login-error" *ngIf="magicLoginFormGroup.controls.email.hasError('whitespace')" data-cy="internal-server-error">{{ t('GENERIC_WRAPPER.WHITESPACE_NOT_ALLOWED_EMAIL_USERNAME') }}</p>
</ng-container>
