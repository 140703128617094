import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentsInterface, CourseInterface, CreateTicketOrderRequestDto, DEFAULT_COURSE_PICTURE_PLACEHOLDER, DmPassSubscriptionInterface, DmPassSubscriptionTargetInterface, emailRegex, environmentForWeb, PassSaleSelectionPageInterface, PassSaleSelectionPageResponseDto, PassTargetInterface, UserAppLangEnum, UserPassSubscriptionDto, UsersInterface } from '@platri/df-common-core';
import { AuthStateService, CoursesHttpService, CoursesStateService, dfAppRoute, dfLoginRoute, DmPassSubscriptionHttpService, FooterStateService, getSubscription, mustMatch, PassHttpService, TicketOrderService, UserPassSubscriptionHttpService } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-course-passes-page',
  templateUrl: './course-passes.page.html',
  styleUrls: ['./course-passes.page.scss']
})
export class CoursePassesPage implements OnInit, OnDestroy {
  course: CourseInterface;
  activePassCourses: CourseInterface[] = [];
  activePassSubscriptionCourses: CourseInterface[] = [];
  passesForSale: PassSaleSelectionPageInterface[];
  passSubscriptionsForSale: DmPassSubscriptionInterface[];

  currentUserPassSubscriptions: UserPassSubscriptionDto[] = [];
  
  nextAppointment: AppointmentsInterface;
  formGroup: UntypedFormGroup;

  selectedPasses: PassSaleSelectionPageInterface[] = [];
  subtotal: number;
  fees: number;
  
  DEFAULT_COURSE_PICTURE_PLACEHOLDER = DEFAULT_COURSE_PICTURE_PLACEHOLDER;

  showMobileSummary: boolean;
  currentLang: UserAppLangEnum;
  
  currentUser: UsersInterface;
  
  showGuestInputFields = false;
  
  currenTabIndex = 0;

  guestEmailForm = this.fb.group(
    {
      email: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]],
      confirmEmail: ['', [Validators.required, Validators.email, Validators.pattern(emailRegex), Validators.maxLength(60)]]
    },
    {
      validators: mustMatch('email', 'confirmEmail')
    }
  );
  
  creationTicketOrderLoading = false;
  
  private subscriptions = new Subscription();

  dmPassSubscriptionFeature = environmentForWeb.dmPassSubscriptionFeature;
  
  constructor(
    private coursesService: CoursesStateService, 
    private router: Router, 
    private breakpointObserver: BreakpointObserver, 
    private footerService: FooterStateService,
    private translocoService: TranslocoService,
    private passHttpService: PassHttpService, 
    private dmPassSubscriptionHttpService: DmPassSubscriptionHttpService,
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService,
    private fb: FormBuilder,
    private authService: AuthStateService,
    private activatedRoute: ActivatedRoute, 
    private ticketOrderService: TicketOrderService,
    private readonly coursesHttpService: CoursesHttpService,
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result: BreakpointState) => {
        this.footerService.showFooter$.next(!result.matches);
        if (this.showMobileSummary && !result.matches) {
          this.showMobileSummary = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.course = this.activatedRoute.snapshot.data.targetObject;
    if (this.course?.appointments?.length > 0) {
      this.nextAppointment = this.course.appointments[0];
    }
    this.initializeSubscriptions();
    this.currentUser = this.authService.getSyncCurrentUser();
  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.passHttpService.getAllForSaleSelectionPageByTargetId(this.course.id), this.onGetAllForSaleSelectionPageByTargetIdSuccessful.bind(this)));
    this.subscriptions.add(getSubscription(this.dmPassSubscriptionHttpService.getAllByDanceManagerId(this.course.danceManagerId), this.onGetAllPassSubscriptionsByDanceManagerIdSuccessful.bind(this)));
    this.subscriptions.add(getSubscription(this.userPassSubscriptionHttpService.getUserPassSubscriptionsByCurrentUserAndDanceManagerId(this.course.danceManagerId), this.onCheckUserPassSubscriptionForDanceManagerSuccessful.bind(this)));
  }

  onCheckUserPassSubscriptionForDanceManagerSuccessful(subs: UserPassSubscriptionDto[]): void {
    this.currentUserPassSubscriptions = subs;
  }
  
  onGetAllForSaleSelectionPageByTargetIdSuccessful(passes: PassSaleSelectionPageResponseDto[]): void {
    this.passesForSale = passes;
    const targetIds: string[] = Array.from(new Set(this.passesForSale?.flatMap(pass =>pass.passTargets?.map(target => target.targetId) ?? [])));
    this.getCoursesByTargetIds(targetIds, 'PASS');

    this.initializeForm();
    this.initializeFormSubscription();
    this.loadTicketOrder();
  }
  
  onGetAllPassSubscriptionsByDanceManagerIdSuccessful(res: DmPassSubscriptionInterface[]): void {
    this.passSubscriptionsForSale = res.filter(sub => sub.targets?.some(target => target.targetId === this.course.id));
    const targetIds: string[] = Array.from(new Set(this.passSubscriptionsForSale?.flatMap(sub => sub.targets?.map(target => target.targetId) ?? [])));
    this.getCoursesByTargetIds(targetIds, 'SUBSCRIPTION');
  }

  getCoursesByTargetIds(targetIds: string[], type: 'PASS'|'SUBSCRIPTION'): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(targetIds).subscribe(courses => {
        if (type === 'PASS') {
          this.activePassCourses = courses;
        } else if (type === 'SUBSCRIPTION') {
          this.activePassSubscriptionCourses = courses;
        }
      }, error => {
        console.log(error);
      })
    );
  }

  initializeForm(): void {
    this.formGroup = this.fb.group({});
    this.passesForSale.forEach((pass) => {
      if (pass?.id) {
        this.formGroup.addControl(pass.id, this.fb.control('0'));
      }
    });
  }

  initializeFormSubscription(): void {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((form) => {
        this.selectedPasses = [];
        this.subtotal = 0;
        this.fees = 0;
        this.passesForSale.forEach((pass) => {
          if (form[pass.id] > 0) {
            this.selectedPasses.push(pass);
            this.subtotal += pass.price * form[pass.id];
          }
        });
      })
    );
  }
  
  loadTicketOrder(): void {
    const ticketOrderData = this.ticketOrderService.getSyncTempCreateTicketOrderData();
    if (ticketOrderData?.passOrderItems?.length > 0) {
      ticketOrderData.passOrderItems.forEach(item => {
        if (this.formGroup.contains(item.passId)) {
          this.formGroup.patchValue({
            [item.passId]: item.quantity
          });
        }
      });
    }
  }

  filterUserPassCoursesByPassId(passTargets: PassTargetInterface[]): CourseInterface[] {
    const targetIds: string[] = Array.from(new Set(passTargets.map(target => target.targetId) ?? []));
    return this.activePassCourses.filter(course => targetIds.includes(course.id));
  }

  filterUserPassSubscriptionCoursesByPassId(targets: DmPassSubscriptionTargetInterface[]): CourseInterface[] {
    const targetIds: string[] = Array.from(new Set(targets.map(target => target.targetId) ?? []));
    return this.activePassSubscriptionCourses.filter(course => targetIds.includes(course.id));
  }
  
  selectedPassesContainTrialPass(): boolean {
    let containsTrialPass = false;
    for (const pass of this.selectedPasses) {
      if (pass.trial === true) {
        containsTrialPass = true;
      }
    }
    return containsTrialPass;
  }
  
  showTabGroup(): boolean {
    return this.passesForSale && this.passesForSale?.length > 0 && this.passSubscriptionsForSale && this.passSubscriptionsForSale?.length > 0 && this.dmPassSubscriptionFeature;
  }

  navigateToCoursePage(): void  {
    this.router.navigate(['dance-class', this.course.id]);
  }

  continueToCheckout(withLogin = true): void {
    const createTicketOrderDto: CreateTicketOrderRequestDto = {
      passOrderItems: [],
      ticketOrderItems: []
    };
    for (const control in this.formGroup.controls) {
      const pass = this.passesForSale.find((obj) => obj.id === control);
      if (pass && this.formGroup.controls[control].value > 0) {
        createTicketOrderDto.passOrderItems.push({
          passId: pass.id,
          quantity: this.formGroup.controls[control].value
        });
      }
    }

    if (createTicketOrderDto.passOrderItems.length > 0) {
      if (withLogin && !this.authService.getSyncCurrentUser()) {
        this.creationTicketOrderLoading = true;
        this.ticketOrderService.sendTempCreateTicketOrderData(createTicketOrderDto, this.course.id);
        if (this.selectedPassesContainTrialPass()) {
          this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
        }
        else {
          this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: `/dance-class/${this.course.id}/passes/checkout`}});
        }
      }
      else {
        if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && !this.showGuestInputFields) {
          this.showGuestInputFields = true;
        }
        else {
          if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && this.guestEmailForm.valid) {
            createTicketOrderDto.userGuestEmail = this.guestEmailForm.get('email').value;
            this.creationTicketOrderLoading = true;
            this.ticketOrderService.createTicketOrder(createTicketOrderDto, this.course.id);
          }
          else if (!this.authService.getSyncCurrentUser() && this.subtotal === 0 && this.guestEmailForm.invalid) {
            console.log('SHOW ERROR');
          }
          else {
            this.creationTicketOrderLoading = true;
            this.ticketOrderService.createTicketOrder(createTicketOrderDto, this.course.id);
          }
        }
      }
    }
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currenTabIndex = event.index;
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
  }
  
  ngOnDestroy(): void {
    this.coursesService.clear();
    this.subscriptions.unsubscribe();
  }
}
