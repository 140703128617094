import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DanceManagerPayoutDetailDto, DanceManagerPayoutDto, environmentForWeb } from '@platri/df-common-core';


@Injectable({
  providedIn: 'root'
})
export class DanceManagerPayoutHttpService {
  private SERVICE_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-payout`;
  private SERVICE_PAYOUT_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-stripe-account`;

  constructor(private readonly http: HttpClient) {}

  getAllByDanceManagerId(danceManagerId: string): Observable<DanceManagerPayoutDto[]> {
    return this.http.get<DanceManagerPayoutDto[]>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}`);
  }

  getByDanceManagerIdAndDanceManagerPayoutId(danceManagerId: string, danceManagerPayoutId: string): Observable<DanceManagerPayoutDetailDto> {
    return this.http.get<DanceManagerPayoutDetailDto>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/${danceManagerPayoutId}`);
  }

  getByDanceManagerIdAndDanceManagerPayoutIdv2(danceManagerId: string, danceManagerPayoutId: string): Observable<DanceManagerPayoutDetailDto> {
    return this.http.get<DanceManagerPayoutDetailDto>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/${danceManagerPayoutId}/v2`);
  }

  getByDanceManagerIdAndDanceManagerPayoutIdNew(danceManagerId: string, danceManagerPayoutId: string): Observable<DanceManagerPayoutDto> {
    return this.http.get<DanceManagerPayoutDto>(`${this.SERVICE_URL}/dance-manager/${danceManagerId}/${danceManagerPayoutId}/new`);
  }

  syncByDanceManagerId(danceManagerId: string): Observable<void> {
    return this.http.get<void>(`${this.SERVICE_URL}/sync/${danceManagerId}`);
  }

  downloadPayout(danceManagerId: string, payoutId: string): Observable<Blob> {
    return this.http.get(`${this.SERVICE_URL}/download/${danceManagerId}/${payoutId}`, {
      responseType: 'blob'
    });
  }
  
  payoutDanceManager(danceManagerId: string): Observable<any> {
    return this.http.patch(`${this.SERVICE_PAYOUT_URL}/payout/${danceManagerId}`, {});
  }
}
