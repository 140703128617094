import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InventorySubscriptionDetailPage } from './inventory-subscription-detail.page';
import { QRCodeModule } from 'angularx-qrcode';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatList, MatListItem} from "@angular/material/list";
import {TranslocoDirective} from "@jsverse/transloco";

const routes = [
  {
    path: '',
    component: InventorySubscriptionDetailPage,
  },
];

@NgModule({
  declarations: [InventorySubscriptionDetailPage],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatDividerModule,
      MatFormFieldModule,
      MatSelectModule,
      MatTabsModule,
      MatCardModule,
      MatButtonModule,
      MatIconModule,
      DfSharedButtonModule,
      QRCodeModule,
      PipesModule,
      MatMenuModule,
      MatSnackBarModule,
      MatGridListModule,
      MatList,
      MatListItem,
      TranslocoDirective,
    ]
})
export class InventorySubscriptionDetailPageModule {}
