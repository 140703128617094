import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPictureChatComponent } from './list-picture-chat.component';
import { ImageFallbackDirectiveModule } from '@platri/dfx-angular-core';

@NgModule({
  declarations: [ListPictureChatComponent],
  imports: [
    CommonModule,
    ImageFallbackDirectiveModule
  ],
  exports: [ListPictureChatComponent]
})
export class ListPictureChatModule { }
