import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { EventTicketsPage } from './event-tickets.page';
import { CreateEventTicketPage } from './create-event-ticket';
import { EditEventTicketComponent } from './edit-event-ticket';
import { DanceEventResolver, dfDanceEventIdRouterParam, EventTicketResolver } from '@platri/dfx-angular-core';
import { EventTicketDetailsComponent } from './event-ticket-details';

const routes: Routes = [
  {
    path: '',
    component: EventTicketsPage,
  },
  {
    path: 'create',
    component: CreateEventTicketPage,
  },
  {
    path: 'events/:eventTicketId/edit',
    component: EditEventTicketComponent,
    resolve: {
      targetObject: EventTicketResolver,
    },
  },
  {
    path: 'events/:danceEventId',
    component: EventTicketDetailsComponent,
    resolve: {
      targetObject: DanceEventResolver,
    },
  },
  {
    path: 'events/:' + dfDanceEventIdRouterParam + '/create',
    component: CreateEventTicketPage,
    resolve: {
      targetObject: DanceEventResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventTicketsRoutingModule {}
