import { NgModule } from '@angular/core';
import { CoHostNotificationComponent } from './co-host-notification.component';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes';
import { MatIconModule } from '@angular/material/icon';
import { SearchCategoryModule } from '../search-category-component';
import { SearchDanceManagersModule } from '../search-dance-managers';
import { DfSharedButtonModule } from '../buttons';
import { MatCardModule } from '@angular/material/card';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CoHostNotificationComponent],
    imports: [
      CommonModule,
      MatAutocompleteModule,
      ReactiveFormsModule,
      PipesModule,
      MatIconModule,
      SearchCategoryModule,
      SearchDanceManagersModule,
      DfSharedButtonModule,
      MatCardModule,
      TranslocoDirective
    ],
  exports: [CoHostNotificationComponent],
})
export class CoHostNotificationModule {}
