import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Subscription } from 'rxjs';
import {
  DanceEventStateService,
  DanceManagerService,
  DanceSchoolLocationService,
  DanceSchoolStateService,
  dateRangeValidator,
  dfmMarketPlaceRoute,
  DfmStateService,
  getSubscription,
  rangeValidator
} from '@platri/dfx-angular-core';
import { BreadcrumbService } from 'xng-breadcrumb';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { addMinutes } from 'date-fns';
import {
  DanceEventCoHostsInterface,
  DanceEventsInterface,
  DanceManagerFeatureInterface,
  DanceManagerFeatureNameEnum,
  DanceSchoolInterface,
  DanceStyleInterface,
  LocationInterface,
  MusicStyleInterface,
  UpdateDanceEventAppointmentAndLocationRequestDto
} from '@platri/df-common-core';
import { DateTime, Interval } from 'luxon';
import {
  UpdateDanceEventGeneralInfo,
  UpdateDanceEventRules
} from '@platri/df-common-shared-graphql';

@Component({
  selector: 'dfm-event-preview-page',
  templateUrl: './dfm-event-preview.page.html',
  styleUrls: ['./dfm-event-preview.page.scss']
})
export class DfmEventPreviewPage implements OnInit, OnDestroy {
  ticketsFeatureActive = false;
  danceManagerFeatureName = DanceManagerFeatureNameEnum;

  danceEvent: DanceEventsInterface;
  danceSchool: DanceSchoolInterface;
  subscriptions: Subscription = new Subscription();
  activeTabIndex: number;

  generalInfoFormGroup: UntypedFormGroup;
  appointmentAndLocationFormGroup: UntypedFormGroup;
  rulesFormGroup: UntypedFormGroup;
  participationFormGroup: UntypedFormGroup;
  ageRestrictionFormGroup: UntypedFormGroup;
  genderRestrictionFormGroup: UntypedFormGroup;
  clothRestrictionFormGroup: UntypedFormGroup;

  locations: LocationInterface[];

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private danceSchoolService: DanceSchoolStateService, 
    private danceEventService: DanceEventStateService, 
    private breadcrumbService: BreadcrumbService, 
    private fb: UntypedFormBuilder, 
    private locationService: DanceSchoolLocationService, 
    private danceManagerService: DanceManagerService,
    private dfmStateService: DfmStateService
    ) {}

  ngOnInit(): void {
    this.getLocationsOfCurrentDanceManager();
    this.danceEventService.danceEventSubject$.subscribe((e) => {
      this.updateDanceEvent(e);
    });
    this.getHashtag();
    this.subscriptions.add(getSubscription(this.dfmStateService.selectedDmFeatures, this.onDanceManagerFeaturesChange.bind(this)));
    this.subscriptions.add(getSubscription(this.danceManagerService.getAsyncDanceManagerFeatures(), this.onDanceManagerFeaturesChange.bind(this)));
  }

  onDanceManagerFeaturesChange(danceManagerFeatures: DanceManagerFeatureInterface[]): void {
    this.ticketsFeatureActive = !!danceManagerFeatures?.find((danceManagerFeature) => danceManagerFeature.name === this.danceManagerFeatureName.DANCE_EVENTS_TICKETS);
  }

  getHashtag():void {
    this.activatedRoute.fragment.subscribe((hashtag) => {
      if (hashtag != null) {
        hashtag = hashtag.toLowerCase();
        switch (hashtag) {
          case 'preview': {
            this.activeTabIndex = 0;
            break;
          }
          case 'info': {
            this.activeTabIndex = 1;
            break;
          }
          case 'location': {
            this.activeTabIndex = 2;
            break;
          }
          case 'rules': {
            this.activeTabIndex = 3;
            break;
          }
          case 'tickets': {
            this.activeTabIndex = 4;
            break;
          }
          case 'guestlist': {
            this.activeTabIndex = 5;
            break;
          }
          default: {
            this.activeTabIndex = 0;
            break;
          }
        }
      }
    });
  }

  changeHashTag(index: number):void {
    switch (index) {
      case 0: {
        window.location.hash = '#preview';
        break;
      }
      case 1: {
        window.location.hash = '#info';
        break;
      }
      case 2: {
        window.location.hash = '#location';
        break;
      }
      case 3: {
        window.location.hash = '#rules';
        break;
      }
      case 4: {
        window.location.hash = '#tickets';
        break;
      }
      case 5: {
        window.location.hash = '#guestlist';
        break;
      }
    }
  }

  getLocationsOfCurrentDanceManager(): void {
    this.locationService.getAsyncCurrentDanceSchoolLocations().subscribe((locations) => {
      this.locations = locations;
      this.getDanceEventFromRoute();
      this.initializeFormGroups();
      this.injectGeneralInfo();
      this.injectAppointmentAndLocation();
      this.injectRulesData();
    });

    this.locationService.getAllDanceSchoolLocationsByDanceSchoolId(this.danceSchoolService.getSyncCurrentDanceSchool().id);
  }

  getDanceEventFromRoute(): void {
    this.danceEvent = this.activatedRoute.snapshot.data.targetObject;
    this.breadcrumbService.set('@eventName', this.danceEvent?.name);
  }

  updateDanceEvent(danceEvent: DanceEventsInterface):void {
    this.danceEvent = danceEvent;
    this.breadcrumbService.set('@eventName', this.danceEvent?.name);
  }

  routeToEvent(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['app', 'dance-event', this.danceEvent.id]));

    window.open(url, '_blank');
  }

  ngOnDestroy():void {
    this.subscriptions.unsubscribe();
    this.danceEventService.clear();
  }

  initializeFormGroups(): void {
    this.initializeAgeRestrictionFormGroup();
    this.initializeGenderRestrictionFormGroup();
    this.initializeClothRestrictionFormGroup();
    this.initializeParticipationFormGroup();
    this.initializeRulesFormGroup();
    this.initializeAppointmentAndLocationFormGroup();
    this.initializeGeneralInfoFormGroup();
  }

  initializeGeneralInfoFormGroup(): void {
    this.generalInfoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      description: [null, [Validators.required]],
      categories: [null, [Validators.required]],
      danceStyles: [],
      musicStyles: [],
      tags: [],
      url: [null, Validators.pattern('(https://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      mainImage: [null],
      coHosts: []
    });

    this.generalInfoFormGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      if (data.url !== null && data.url !== '' && !data.url.includes('https://')) {
        this.generalInfoFormGroup.patchValue({
          url: 'https://' + data.url
        });
      }
      if (data.url === '') {
        this.generalInfoFormGroup.patchValue({
          url: null
        });
      }
    });
  }

  initializeAppointmentAndLocationFormGroup(): void {
    this.appointmentAndLocationFormGroup = this.fb.group({
      locationId: [null, [Validators.required]],
      address: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]]
    }, {
      validators: dateRangeValidator()
    });
  }

  initializeAgeRestrictionFormGroup(): void {
    this.ageRestrictionFormGroup = this.fb.group(
      {
        fromAge: this.fb.control({ value: 0, disabled: false }, [Validators.min(0), Validators.max(150)]),
        toAge: this.fb.control({ value: 100, disabled: false }, [Validators.min(0), Validators.max(150)])
      },
      {
        validators: rangeValidator('fromAge', 'toAge')
      }
    );
  }

  initializeGenderRestrictionFormGroup(): void {
    this.genderRestrictionFormGroup = this.fb.group({
      isMaleRestricted: this.fb.control({
        value: false,
        disabled: false
      }),
      isFemaleRestricted: this.fb.control({
        value: false,
        disabled: false
      }),
      isDiverseRestricted: this.fb.control({
        value: false,
        disabled: false
      })
    });
  }
  
  initializeClothRestrictionFormGroup(): void {
    this.clothRestrictionFormGroup = this.fb.group({
      isRestricted: [false],
      restriction: [null]
    });
  }

  initializeParticipationFormGroup(): void {
    this.participationFormGroup = this.fb.group({
      isAgeRestricted: this.fb.control(false),
      ageRestriction: this.ageRestrictionFormGroup,
      isGenderRestricted: this.fb.control(false),
      genderRestriction: this.genderRestrictionFormGroup
    });
  }

  initializeRulesFormGroup(): void {
    this.rulesFormGroup = this.fb.group({
      participationRestriction: this.participationFormGroup,
      clothingRestriction: this.clothRestrictionFormGroup
    });
  }

  injectGeneralInfo():void {
    if (this.danceEvent) {
      this.generalInfoFormGroup.patchValue(this.danceEvent, {
        emitEvent: true,
        onlySelf: true
      });
    }
  }

  injectAppointmentAndLocation():void {
    const appointment = this.danceEvent.appointments[0];
    const startDate = new Date(appointment.startDate);
    startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000);
    const endDate = addMinutes(startDate, appointment.duration);
    
    this.appointmentAndLocationFormGroup.patchValue(
      {
        startDate: this.getFormattedDatetime(startDate.toString()),
        endDate: this.getFormattedDatetime(endDate.toString()),
        locationId: appointment.location?.id,
        address: appointment.address
      },
      { emitEvent: true, onlySelf: true }
    );
    if (this.danceEvent?.hasSoldTickets) {
      this.appointmentAndLocationFormGroup.get('startDate').disable();
      this.appointmentAndLocationFormGroup.get('endDate').disable();
    }
  }

  getFormattedDatetime(dateString: string): string {
    const d = new Date(dateString);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    let hour = '' + d.getHours();
    let min = '' + d.getMinutes();
    let sec = '' + d.getSeconds();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (min.length < 2) min = '0' + min;
    if (sec.length < 2) sec = '0' + sec;
    return [year, month, day].join('-')+'T'+[hour,min,sec].join(':');
  }

  injectRulesData(): void {
    this.rulesFormGroup.patchValue(this.danceEvent?.rules, {
      emitEvent: true,
      onlySelf: true
    });
    this.clothRestrictionFormGroup.patchValue(this.danceEvent?.restrictions?.clothingRestriction, {
      emitEvent: true,
      onlySelf: true
    });
    if (this.danceEvent?.hasSoldTickets) {
      this.rulesFormGroup?.get('participationRestriction').disable();
    }
  }

  updateGeneralInfoOfEvent(): void {
    const formRawValue = this.generalInfoFormGroup.getRawValue();
    const updateDanceEvent: UpdateDanceEventGeneralInfo = {
      name: formRawValue.name,
      description: formRawValue.description,
      categories: formRawValue.categories,
      danceStyleIds: formRawValue.danceStyles?.length > 0 ? formRawValue.danceStyles.map((danceStyle: DanceStyleInterface) => danceStyle.id) : [],
      musicStyleIds: formRawValue.musicStyles?.length > 0 ? formRawValue.musicStyles.map((musicStyle: MusicStyleInterface) => musicStyle.id) : [],
      coHosts: formRawValue.coHosts?.map((obj: DanceEventCoHostsInterface) => ({
        danceManagerId: obj.danceManagerId
      })) ?? [],
      tags: formRawValue.tags,
      url: formRawValue.url
    };
    
    const formData = new FormData();
    
    if (this.generalInfoFormGroup.get('mainImage').value === null) {
      this.danceEventService.deleteMainImageById(this.danceEvent.id).subscribe();
    }
    
    if (this.generalInfoFormGroup.get('mainImage').value?.formData !== undefined) {
      this.danceEventService.deleteMainImageById(this.danceEvent.id).subscribe();
      formData.append('mainImage', this.generalInfoFormGroup.get('mainImage')?.value.formData.get('file')); 
    }
    formData.append('body', JSON.stringify(updateDanceEvent));
    this.danceEventService.updateGeneralInfoDanceEventById(this.danceEvent.id, formData);
  }

  updateAppointmentAndLocationOfEvent(): void {
    const formRawValue = this.appointmentAndLocationFormGroup.getRawValue();
    const startDate = new Date(formRawValue.startDate);
    
    const startDateTime = this.parseDateTime(formRawValue.startDate);
    const endDateTime = this.parseDateTime(formRawValue.endDate);
    const diffInMinutes = Interval.fromDateTimes(startDateTime, endDateTime).length('minute');
    
    if (formRawValue.address) {
      delete formRawValue.address.__typename;
    }

    const updateDanceEvent: UpdateDanceEventAppointmentAndLocationRequestDto = {
      startDate: startDate,
      duration: diffInMinutes,
      locationId: formRawValue.locationId ?? null,
      address: formRawValue.address ?? null
    };

    this.danceEventService.updateAppointmentAndLocationDanceEventById(this.danceEvent.id!, updateDanceEvent);
  }

  parseDateTime(dateTimeString: string): DateTime {
    const [datePart, timePart] = dateTimeString.split('T');

    const [year, month, day] = datePart.split('-').map(Number);

    const [hour, minute] = timePart.split(':').map(Number);

    const dateTime = DateTime.now()
      .setZone('UTC')
      .set({
        day: day,
        month: month,
        year: year,
        hour: hour,
        minute: minute,
        second: 0,
        millisecond: 0
      });

    return dateTime;
  }

  updateRulesOfEvent(): void {
    const updateDanceEvent: UpdateDanceEventRules = {
      restrictions: {
        clothingRestriction: {
          isRestricted: this.clothRestrictionFormGroup.getRawValue().isRestricted,
          restriction: this.clothRestrictionFormGroup.getRawValue().isRestricted ? this.clothRestrictionFormGroup.getRawValue().restriction : null
        }
      },
      rules: {
        participationRestriction: this.participationFormGroup.getRawValue()
      }
    };

    this.danceEventService.updateRulesDanceEventById(this.danceEvent.id, updateDanceEvent);
  }

  routeToPackages():void {
    this.router.navigate(['dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmMarketPlaceRoute]);
  }
}
