<div class="flex-column-center-center p3">
  <div class="flex-row-center-center">
    <mat-icon style="font-size: 100px; height: 100px; width: 100px">{{data.matIcon}}</mat-icon>
  </div>
  <div class="flex-row-center-center my3">
    <span>
      {{data.message}}
    </span>
  </div>
  <button mat-stroked-button color="primary" mat-dialog-close >{{data.buttonText}}</button>
</div>
