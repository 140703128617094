import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DfmCoursePassesTableInterface, PassInterface, PassStatusEnum } from '@platri/df-common-core';
import { getSubscription } from '../../helpers';
import { PassHttpService } from '../http-services';

@Injectable({providedIn: 'root'})
export class PassesStateService implements OnDestroy {
  isLoadingPasses = new BehaviorSubject<boolean>(false);
  availableDfmCoursePasses = new BehaviorSubject<DfmCoursePassesTableInterface[]>([]);
  passToDuplicateSubject: BehaviorSubject<PassInterface | null> = new BehaviorSubject<PassInterface | null>(null);

  subscriptions = new Subscription();

  constructor(private readonly passHttpService: PassHttpService) {
    console.log('Initializing PassesStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying PassesStateService');
    this.subscriptions.unsubscribe();
    this.availableDfmCoursePasses.unsubscribe();
  }

  clear(): void {
    this.availableDfmCoursePasses.next([]);
    this.passToDuplicateSubject.next(null);
  }

  loadAllDfmCoursePassesByDanceManagerId(danceManagerId: string, status?: PassStatusEnum): void {
    this.sendIsLoadingPasses(true);
    this.subscriptions.add(getSubscription(this.passHttpService.getAllForDfmCoursePassesByDanceManagerId(danceManagerId, status), this.onLoadedAllDfmCoursePasses.bind(this), () => {
      console.log(`Couldn't load Dfm Course Passes by dance manager id`);
      this.sendIsLoadingPasses(false);
    }));
  }

  onLoadedAllDfmCoursePasses(data: DfmCoursePassesTableInterface[]): void {
    this.sendIsLoadingPasses(false);
    this.sendAvailableDfmCoursePasses(data);
  }

  private sendAvailableDfmCoursePasses(data: DfmCoursePassesTableInterface[]): void {
    this.availableDfmCoursePasses.next(data);
  }

  private sendIsLoadingPasses(isLoading: boolean): void {
    this.isLoadingPasses.next(isLoading);
  }

  getAsyncIsLoadingPasses(): Observable<boolean> {
    return this.isLoadingPasses.asObservable();
  }

  getAsyncDfmCoursePasses(): Observable<DfmCoursePassesTableInterface[]> {
    return this.availableDfmCoursePasses.asObservable();
  }

}
