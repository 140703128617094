import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmGeneralComponent } from './dfm-general.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuillModule } from 'ngx-quill';
import { AddImageModule, DfQuillEditorModule, DfSharedButtonModule, SearchCategoryModule, SearchDanceStyleModule, SearchMusicStyleModule } from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmGeneralComponent],
  exports: [DfmGeneralComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    QuillModule,
    DfQuillEditorModule,
    SearchDanceStyleModule,
    SearchMusicStyleModule,
    SearchCategoryModule,
    MatChipsModule,
    MatIconModule,
    AddImageModule,
    DfSharedButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    MatButtonModule,
    TranslocoDirective,
  ],
})
export class DfmGeneralModule {}
