export interface ConfirmationDialogInterface {
  title?: string;
  text: string;
  textColor?: string;
  withTextArea?: boolean;
  textAreaInput?: string;
  cancelButtonText?: string;
  submitButtonText: string;
  cancelButtonColor?: any;
  submitButtonColor?: any;
  requiredText?: string;
  placeholder?: string;
  buttonHeight?:string;
}
