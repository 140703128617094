<ng-container *transloco="let t">
  <div class="flex-column p2">
      <div class="flex-row mb2"> 
          <div class="onboarding-title-container w-100">
              <span class="onboarding-title">
                  <strong>{{ t('GENERIC_WRAPPER.PACKAGES.DIALOG_TITLE') }}</strong>
              </span>
          </div>
          <button class="icon-button-box" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
      </div>
      <div class="mb1">
          <span class="onboarding-text">
              {{ t('GENERIC_WRAPPER.PACKAGES.DIALOG_DESCRIPTION') }}
          </span>
      </div> 
      <div class="stripe-container">
          <df-monorepo-dm-subscription-price-table-card
              [stripeClientReferenceId]="danceManager.id"
              [stripePublishableKey]="stripePublishableKey"
              [stripePricingTableId]="stripePricingTableId">
          </df-monorepo-dm-subscription-price-table-card>
      </div>
  </div>
</ng-container>
