<div class="p2">
  <div *ngIf="data.icon" class="w-100 flex-row align-center pb1"><mat-icon>{{ data.icon }}</mat-icon></div>
  <div class="marginb05 flex-row align-center w-100">
    <div><span class="fs16 bold">{{ data.title }}</span></div>
  </div>
  <div class="py-20">
    <span class="fs14 align-center" style="text-align: center;"> {{ data.description }}</span>
  </div>
  <div class="flex-row align-center">
    <df-shared-lib-button
      *ngIf="data.buttonText"
      class="w-100"
      [materialButtonStyleType]="'flat'" 
      [buttonLabel]="data.buttonText"
      [minWidth]="'140px'"
      [borderRadius]="'24px'"
      (buttonClicked)="close(false)">
    </df-shared-lib-button>
  </div>
</div>
