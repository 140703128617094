import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmEventPreviewPage } from './dfm-event-preview.page';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DanceEventPreviewCardModule, DfSharedButtonModule, DmEventPreviewCardModule, PaginatorModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { AddEditDanceEventGeneralInfoModule, AddEditEventAppointmentLocationModule, AddEditEventRulesModule, DfmEventGuestListTableModule, DfmEventTicketListModule } from '../../components';
import { EventPageModule } from '@platri/df-angular-dance-event';

@NgModule({
  declarations: [DfmEventPreviewPage],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    PaginatorModule,
    TranslocoModule,
    MatTooltipModule,
    MatTabsModule,
    DanceEventPreviewCardModule,
    DmEventPreviewCardModule,
    DfmEventGuestListTableModule,
    RouterModule,
    AddEditDanceEventGeneralInfoModule,
    AddEditEventAppointmentLocationModule,
    AddEditEventRulesModule,
    DfmEventTicketListModule,
    DfSharedButtonModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    EventPageModule
  ]
})
export class DfmEventPreviewPageModule {}
