<ng-container *transloco="let t">
  <div class="progress-bar-container">
    <div *ngIf="fromLevel > 0" [style.flex]="fromLevel" class="w-100 h-100 background"></div>
    <div [style.flex]="toLevel - fromLevel" class="w-100 h-100 progress"></div>
    <div [style.flex]="100 - toLevel" class="w-100 h-100 background"></div>
  </div>
  
  <span
    >{{ t('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.' + textFrom.toUpperCase()) }}
    {{
      textTo != textFrom
        ? ' - ' + t('GENERIC_WRAPPER.LEVEL_SLIDER_ENUM.' + textTo.toUpperCase())
        : null
    }}
  </span>
</ng-container>
