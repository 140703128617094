import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import { UserPassSubscriptionHttpService } from '../services';

@Injectable({ providedIn: 'root' })
export class UserPassSubscriptionResolver  {
  constructor(private readonly userPassSubscriptionHttpService: UserPassSubscriptionHttpService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const userPassSubscriptionId = route.paramMap.get('userPassSubscriptionId');
    if (userPassSubscriptionId) {
      return this.userPassSubscriptionHttpService.getUserPassSubscriptionByIdAndCurrentUser(userPassSubscriptionId).pipe(
        catchError((error) => {
          this.router.navigateByUrl('/404');
          return EMPTY;
        })
      );
    } else {
      this.router.navigateByUrl('/404');
    }
  }
}
