<div
  class="message collapsed"
  [ngClass]="{ me: message.fromPartnerId === currentPartnerId }"
>
  <div class="content">
    <span class="msg">
      <span class="username" *ngIf="showUsername">{{
        conversationPartner.username
      }}</span>
      <span [innerText]="message.text | linkify | dynamicTag" style="white-space: pre-wrap"></span>
      <time>{{ message.createdAt | timeAgo }}</time>
    </span>
  </div>
</div>
