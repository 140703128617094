import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsernameFormSettingsComponent } from './username-form-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [UsernameFormSettingsComponent],
  exports: [UsernameFormSettingsComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      MatProgressSpinnerModule,
      MatButtonModule,
      DfSharedButtonModule,
      TranslocoDirective,
    ],
})
export class UsernameFormSettingsModule {}
