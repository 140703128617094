import { CustomRecurrenceEndTypeEnum, CustomRecurrenceTypeEnum, CustomRecurrenceWeekDaysTypeEnum, SchedulerRecurrenceTypeEnum, SchedulerTypeEnum } from '../../..';
import { AddressDto } from '../../..';

export class UpdateCourseAppointmentSchedulerRequestDto {
  startDate: Date;
  duration: number;
  description: string;
  teacherUserIds: string[];
  schedulerType: SchedulerTypeEnum;
  endDate: Date;
  recurrenceType: SchedulerRecurrenceTypeEnum;
  customRecurrenceType: CustomRecurrenceTypeEnum;
  customRecurrenceNumber: number;
  customRecurrenceWeekDays: CustomRecurrenceWeekDaysTypeEnum[];
  customRecurrenceEnd: CustomRecurrenceEndTypeEnum;
  numberOfAppointments: number;
  maxParticipants: number;
  locationId: string;
  roomId: string;
  address: AddressDto;
}
