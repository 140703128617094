import { CurrencyTypeEnum } from '../enums';

export interface ResponsePayoutDetailSummaryInterface {
  amount: number;
  currency: CurrencyTypeEnum;
  date: Date;
  productsWithQuantity: string[];
  eventName: string;
  eventDate: Date;
  eventTimezone: string;
  username: string;
}
