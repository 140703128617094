<ng-container *transloco="let t">
  <div class="flex-column" style="gap: 20px; max-width: 600px;" [formGroup]="rulesFormGroup.get('participantRestriction')">
    <div
      [formGroup]="rulesFormGroup"
      class="flex-column gap-10"
    >
        <strong class="fs16">{{t('DANCE_MANAGER_COURSE.RULES.CHECK_IN_TITLE')}}</strong>
        <span class="fs14 subtitle">{{t('DANCE_MANAGER_COURSE.RULES.CHECK_IN_SUBTEXT')}}</span>
        <mat-checkbox
          class="checkbox-br"
          color="primary"
          formControlName="isCheckInActive"
        >
          {{t('DANCE_MANAGER_COURSE.RULES.CHECK_IN_CHECK_BOX')}}
        </mat-checkbox>
      </div>
    <span class="section-title">Participant restrictions (optional)</span>
    <div class="flex-column">
      <mat-checkbox color="primary" formControlName="isAgeRestricted"><span class="checkbox-text">{{t('DANCE_MANAGER_COURSE.RULES.AGE_RESTRICTION')}}</span></mat-checkbox>
         <df-shared-lib-level-slider-material
           class="age-slider"
           *ngIf="rulesFormGroup.get('participantRestriction.isAgeRestricted').value"
           [showRange]="true"
           [fromLevel]="ageRestrictionFormGroup.getRawValue().fromAge"
           [toLevel]="ageRestrictionFormGroup.getRawValue().toAge"
           [step]="1"
           [unit]="t('GENERIC_WRAPPER.YEAR_PLURAL')"
           (setLevelEvent)="setAgeRange($event)"
         >
        </df-shared-lib-level-slider-material>
    </div>
    <span class="section-title pt-10">{{t('DANCE_MANAGER_COURSE.RULES.GENDER_RESTRICTION')}} (optional)</span>
    <div class="flex-column" style="gap: 16px;" [formGroup]="rulesFormGroup.get('participantRestriction.genderRestriction')">
        <mat-checkbox color="primary" formControlName="isMaleRestricted">{{t('DANCE_MANAGER_COURSE.GENDER_RESTRICTION_MEN')}}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="isFemaleRestricted">{{t('DANCE_MANAGER_COURSE.GENDER_RESTRICTION_WOMEN')}}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="isDiverseRestricted">{{t('DANCE_MANAGER_COURSE.GENDER_RESTRICTION_DIVERSE')}}</mat-checkbox>
    </div>  
    <span class="section-title pt-10">Dress code (optional)</span>
    <div class="flex-column" [formGroup]="rulesFormGroup.get('clothingRestriction')" *ngIf="rulesFormGroup.controls.clothingRestriction as formGroup">
        <df-shared-lib-quill-editor
          [hasError]="formGroup.controls.restriction.hasError('maxlength') || (formGroup.controls.restriction.hasError('required') && formGroup.controls.restriction.touched)"
          [enableMentions]="true"
          [enableCounter]="true"
          [maxLength]="1000"
          [id]="'clothingRestriction'"
          [placeholder]="'DANCE_MANAGER_COURSE.RULES.RULES_DESCRIPTION'"
          customFormControlName="restriction"></df-shared-lib-quill-editor>
    </div>
  </div>
  <div class="flex-row-end-center">
    <df-shared-lib-button 
      [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" 
      (buttonClicked)="onSaveClick.emit()"
    ></df-shared-lib-button>
  </div>
</ng-container>
