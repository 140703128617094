<ng-container *transloco="let t">
  <div class="flex-column align-center pl3 pr3">
    <div *ngIf="isInitialized && loadedPass" class="dfm-pass-detail-container w-100 flex-column pb2">
      <div class="dfm-pass-detail-breadcrumb">
        <xng-breadcrumb [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container>{{ t(breadcrumb) }}</ng-container>
          </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
      </div>
      <div class="dfm-pass-detail-card-container h-100">
        <mat-card style="background-color: white" class="dfm-pass-detail-card round-corners w-100 h-100 no-shadow-mat-card">
          <span class="page-title">{{loadedPass.name}}</span>
          <div style="flex: 1;" class="pt2 flex-column h-100">
            <mat-tab-group class="flex-column h-100" [(selectedIndex)]="currenTabIndex" (selectedTabChange)="onTabChange($event)">
              
              <mat-tab class="flex-column h-100" [label]="t('GENERIC_WRAPPER.INFO')">
                <div class="pt3 flex-column align-space-between h-100 overflow-hidden">
                  <df-pass-info-form
                    [infoFormGroup]="infoFormGroup"
                    [priceFormGroup]="priceFormGroup"
                    [enabledUsesOption]="enabledUsesOption"
                    [enabledValidDateOption]="enabledValidDateOption"
                    (enabledUsesOptionChange)="enabledUsesOption = $event"
                    (enabledValidDateOptionChange)="enabledValidDateOption = $event"
                  ></df-pass-info-form>
                  <div class="flex-row gap-10">
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                    <span [innerHTML]="t('GENERIC_WRAPPER.INFO_TEXT_ALREADY_SOLD_PASSES')"></span>
                  </div>
                  <div class="flex-row w-100 align-end-center">
                    <df-shared-lib-button [isDisabled]="!infoFormGroup.dirty || infoFormGroup.invalid" [buttonLabel]="t('GENERIC_WRAPPER.UPDATE')" (buttonClicked)="onSubmit()"></df-shared-lib-button>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab *ngIf="infoFormGroup.get('trial').value === false" class="flex-column h-100" [label]="t('GENERIC_WRAPPER.PRICE')">
                <div class="pt3 flex-column align-space-between h-100 overflow-hidden">
                  <df-pass-price-form [formGroup]="priceFormGroup"></df-pass-price-form>
                  <div class="pt3 flex-row gap-10">
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                    <span [innerHTML]="t('GENERIC_WRAPPER.INFO_TEXT_ALREADY_SOLD_PASSES')"></span>
                  </div>
                  <div class="flex-row w-100 align-end-center">
                    <df-shared-lib-button [isDisabled]="!priceFormGroup.dirty || priceFormGroup.invalid" [buttonLabel]="t('GENERIC_WRAPPER.UPDATE')" (buttonClicked)="onSubmit()"></df-shared-lib-button>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab [label]="t('GENERIC_WRAPPER.COURSES')">
                <div class="pt3 flex-column align-space-between h-100 overflow-hidden">
                  <df-shared-lib-pass-targets-table 
                    [courses]="courses" 
                    [alreadySelectedTargetIds]="assignedTargetIds"
                    (selectedCourses)="selectedTargets($event)"
                  ></df-shared-lib-pass-targets-table>
                  <div class="pt3 flex-row gap-10">
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                    <span [innerHTML]="t('GENERIC_WRAPPER.INFO_TEXT_ALREADY_SOLD_PASSES')"></span>
                  </div>
                  <div class="flex-row w-100 align-end-center">
                    <df-shared-lib-button 
                      [isDisabled]="infoFormGroup.invalid || priceFormGroup.invalid || !selectedCoursesChanged"
                      [buttonLabel]="t('GENERIC_WRAPPER.UPDATE')"
                      (buttonClicked)="onSubmit()"
                    ></df-shared-lib-button>
                  </div>
                </div>
              </mat-tab>
              
              <mat-tab [label]="t('GENERIC_WRAPPER.PASS_DETAILS.SALES')">
                <dfm-pass-guest-list-table [passId]="passIdFromParam"></dfm-pass-guest-list-table>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
