import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DanceEvent,
  FeesPaidByEnum,
  Ticket,
  TicketTypeEnum
} from '@platri/df-common-shared-graphql';
import { Subscription } from 'rxjs';
import {
  ConfirmationDialogComponent,
  DanceSchoolStateService,
  getSubscription,
  TicketHttpService,
  TicketStateService
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { utcToZonedTime } from 'date-fns-tz';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-event-ticket-details',
  templateUrl: './event-ticket-details.component.html',
  styleUrls: ['./event-ticket-details.component.scss']
})
export class EventTicketDetailsComponent implements OnInit, OnDestroy {
  isLoadingDanceEvents = false;

  event: DanceEvent;
  tickets: Ticket[];
  subscriptions: Subscription = new Subscription();

  currentDate;
  FeesPaidByEnum = FeesPaidByEnum;

  readonly ticketTypeEnum = TicketTypeEnum;

  currentLang: string;

  constructor(
    private danceSchoolService: DanceSchoolStateService, 
    private readonly dialog: MatDialog,
    private readonly ticketHttpService: TicketHttpService, 
    private readonly ticketService: TicketStateService, 
    private readonly router: Router, 
    private readonly activatedRoute: ActivatedRoute, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly translocoService: TranslocoService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    this.getDanceEventFromRoute();
    this.initializeSubscriptions();
    this.ticketService.getAllByDanceEvent(this.event.id!, true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getDanceEventFromRoute(): void {
    if (this.activatedRoute.snapshot.data.targetObject) {
      this.event = this.activatedRoute.snapshot.data.targetObject;
    }
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.ticketService.getAsyncTickets(), this.onTicketsChangeSuccessful.bind(this)));
  }

  onTicketsChangeSuccessful(tickets: Ticket[]): void {
    if (tickets && tickets.length > 0) {
      this.currentDate = utcToZonedTime(
        new Date(),
        // @ts-ignore
        this.event.appointments[0].address.timezoneId
      );
      const userTimezoneOffset = this.currentDate.getTimezoneOffset() * 60000;
      this.currentDate = new Date(this.currentDate.getTime() - userTimezoneOffset);
      this.tickets = tickets;
      for (const ticket of this.tickets) {
        ticket.saleStart = ticket.saleStart !== null ? new Date(ticket.saleStart!) : null;
        ticket.saleEnd = ticket.saleEnd !== null ? new Date(ticket.saleEnd!) : null;
      }
    }
  }

  navigateToEditTicketPage(id: string): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'event-tickets', 'events', id, 'edit']);
  }

  deleteTicket(ticket: Ticket): void {
    this.subscriptions.add(
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: {
            cancelButtonColor: '',
            cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
            submitButtonColor: 'warn',
            submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
            text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_DIALOG_TEXT'),
          }
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.ticketHttpService.delete(ticket.id!).subscribe({
                next: (res) => {
                  this.ticketService.getAllByDanceEvent(this.event.id!, true);
                  this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_SUCCESS'));
                },
                error: (error) => {
                  this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_TICKET_ERROR'));
                }
              })
            );
          }
        })
    );
  }

  mouseDrop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.tickets, event.previousIndex, event.currentIndex);
      this.updateSequenceNumber();
    }
  }

  updateSequenceNumber(): void {
    const newSequence: { id: string; sequenceNumber: number }[] = [];

    this.tickets.forEach((ticket, index) => {
      newSequence.push({ id: ticket.id, sequenceNumber: index });
    });

    this.subscriptions.add(
      this.ticketHttpService.updateSequenceNumber(this.event.id!, newSequence).subscribe({
        next: () => {
          this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SEQUENCE_UPDATED'));
        },
        error: (err) => {
          this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SEQUENCE_UPDATE_ERROR'));
        }
      })
    );
  }
}
