import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DanceSchoolInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UnreadDmNotificationInterface } from '@platri/df-common-core';
import { DmMessageCountInterface } from '@platri/df-common-chat-plug-play';

@Component({
  selector: 'df-header-dance-manager-selection',
  templateUrl: './header-dance-manager-selection.component.html',
  styleUrls: ['./header-dance-manager-selection.component.scss']
})
export class HeaderDanceManagerSelectionComponent implements OnChanges{
  @Input() unreadMessagesInterface: DmMessageCountInterface[];
  @Input() danceSchools: DanceSchoolInterface[] = [];
  @Input() unreadNotificationsCountsByDanceManager: UnreadDmNotificationInterface[];
  
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  unreadMessagesCountTotal = 0;
  unreadNotificationsCountTotal = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unreadNotificationsCountsByDanceManager) {
      this.calculateTotalUnreadNotifications();
    }
    
    if (changes.unreadMessagesInterface) {
      this.calculateTotalUnreadMessages();
    }
  }

  private calculateTotalUnreadMessages(): void {
    if (Array.isArray(this.unreadMessagesInterface)) {
      this.unreadMessagesCountTotal = this.unreadMessagesInterface
        .reduce((total, notification) => total + notification.count, 0);
    } else {
      this.unreadMessagesCountTotal = 0;
    }
  }

  private calculateTotalUnreadNotifications(): void {
    if (Array.isArray(this.unreadNotificationsCountsByDanceManager)) {
      this.unreadNotificationsCountTotal = this.unreadNotificationsCountsByDanceManager
        .reduce((total, notification) => total + notification.count, 0);
    } else {
      this.unreadNotificationsCountTotal = 0; 
    }
  }
  
  
}
