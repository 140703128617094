@if (ticketOrder) {
  <df-checkout-complete
    [ticketOrder]="ticketOrder"
    [danceManagerId]="danceEvent?.danceSchool?.id"
    [confirmationText]="confirmationText"
    [errorText]="errorText"
    [currentState]="ticketOrder?.status"
    [stateInit]="[ticketOrderStatusEnum.PAYMENT_INITIALIZED, ticketOrderStatusEnum.PAYMENT_IN_PROGRESS]"
    [stateSuccess]="[ticketOrderStatusEnum.PAID, ticketOrderStatusEnum.PAYMENT_PENDING]"
    [stateError]="[ticketOrderStatusEnum.PAYMENT_ERROR]"
  />
}
