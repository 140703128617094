import { DanceStyleStatusEnum } from '../enums';

export const DANCE_STYLES = [
  {
    id: "dd6171da-d265-1246-e78c-68ff087d925d",
    translationKey: "tangoLatinDances",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "85c0b259-9be1-0303-9ccd-085e5ac630fd",
    translationKey: "ballroom",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "6a647889-2f27-64d2-8efe-0edb3515e47e",
    translationKey: "ballet",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "63857164-b368-add7-a377-edd01ff34c4d",
    translationKey: "modern",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "3bd578ad-854e-e99e-c1c1-f9185cec999c",
    translationKey: "contemporary",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "0bb9e2b5-2b90-b063-db01-86a900aa03a8",
    translationKey: "jazz",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "0418fb4c-73f5-5595-441d-81ebc4443856",
    translationKey: "hiphop",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "4adc9577-88f8-e303-015b-b6ecb9a67b51",
    translationKey: "dancehall",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "339e9e05-829f-f2b4-fc2f-fca34d498329",
    translationKey: "popping",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "aef70b18-1c27-35aa-4f00-d385378ea60e",
    translationKey: "breakdancing",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "6f11f9ba-b6f9-d713-cfdc-e9ccb7eead6f",
    translationKey: "boogaloo",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "6e7c3dbb-5294-b602-cf0d-9d7a37fc4094",
    translationKey: "poledance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "5be17eee-ac24-5523-579b-b02cbbe01228",
    translationKey: "zumba",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "d9e28149-6760-05a3-3da1-15f71c4514cc",
    translationKey: "shuffle",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "562ec469-7aff-c2a5-173d-bf105a04fc64",
    translationKey: "vogue",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "b936c95e-180d-3af2-5928-ed23fef570c7",
    translationKey: "acrobatics",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "dd4acca5-4919-2c5b-7bd5-e2490e193ae6",
    translationKey: "folkDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "0d7c57a6-2833-53fc-a0bd-219eee2f8ea1",
    translationKey: "fitness",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "5106e3be-cdc8-a324-47e7-b963253deeee",
    translationKey: "salsa",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "3d937fc9-6818-e1c0-5cd8-ae7342ea2c84",
    translationKey: "bachata",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "0d936c2f-6a42-d1d8-0355-581da0e5ad36",
    translationKey: "kizomba",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "03f8d4b4-b64d-76a0-7c13-1ff6fee7a896",
    translationKey: "westCoastSwing",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "5fb757d5-9332-e55b-388e-5ac83e8a3fed",
    translationKey: "lindyHop",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "4b3f1361-f9c4-296c-457a-251f530aba11",
    translationKey: "chaChaCha",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "60fdf550-beb9-b69d-ed82-b67fcedbc6e8",
    translationKey: "boogieWoogie",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "05ac475f-fea0-4ed9-cea9-f285074a9a30",
    translationKey: "discoFox",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "cc4f0366-b676-132e-e8b1-e3893092bd80",
    translationKey: "foxtrott",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "4aebb85f-9f5f-054b-ff06-cd248f9586ba",
    translationKey: "jive",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "ffec7667-0f4b-2910-9208-541523170608",
    translationKey: "standard",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "bba15d1d-832e-6d76-8014-9daa62f140e8",
    translationKey: "latin",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "ad5a94e2-cca3-44ca-a229-4f61643c80ca",
    translationKey: "waltz",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "96884578-9289-964a-f8f7-e9b72b3a2375",
    translationKey: "wienerWalzer",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "30481fca-d52d-c5a4-90eb-b87c104942bb",
    translationKey: "mambo",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "ccbb2ae2-e9c5-3eae-c539-f87e51d0b7c0",
    translationKey: "merengue",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "42e4649a-a06e-fb2e-4c3e-d215bd4841ce",
    translationKey: "pasodoble",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "76db065a-7666-8404-a0bb-d0a3f5e5987d",
    translationKey: "quickstep",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "9f29fc6e-ea4c-4677-025f-a55acf995e4f",
    translationKey: "rumba",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "32d69c28-f7c6-908b-d680-bf0e2cd1a704",
    translationKey: "rumbaAfro",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "8b02ef02-b91e-2b80-86ff-81e6bd60562e",
    translationKey: "rocknroll",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "9d1f7da9-1652-cb17-1459-e0b3f655921b",
    translationKey: "sambaBallroom",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "ad59aae7-e26c-af5c-507a-04448ffdb8f0",
    translationKey: "sambaBrasil",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "9ccd5528-8655-a6fd-b98c-85596f58540d",
    translationKey: "forro",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "9822dba0-8769-f6b7-03ad-d2d1c7bfad3b",
    translationKey: "slowfox",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "68180a20-fcc5-1e67-7a6d-05abaf11423e",
    translationKey: "tangoArgentino",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "7303dcb3-6dac-f303-048a-f7f110e0633b",
    translationKey: "zouk",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "83e84b10-2697-8039-ac7e-406bc6f725e2",
    translationKey: "swing",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "64f0246f-7ce5-4ef6-032a-cc9970509bf8",
    translationKey: "reggaeton",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "4682fad4-1f4b-3b69-884c-3378626f6bc5",
    translationKey: "bolero",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "79d04444-e520-0ea7-101b-01483579147a",
    translationKey: "son",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "6b6cca76-0d5f-5181-952a-3642e5c93448",
    translationKey: "cumbia",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "288737de-e2a6-45b7-ad6c-ea2a546c58a8",
    translationKey: "afrobeat",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "7ab15cd6-e916-4b83-a791-916ef8969c4b",
    translationKey: "bellyDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "5cb2652e-b7d9-45f7-b220-d7dc1344f73b",
    translationKey: "copoeira",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "ea83fcc5-4f60-4b68-b488-96bb3653fa7f",
    translationKey: "chaleston",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "c499e4c2-93ba-4774-923c-a3c47509a4cf",
    translationKey: "flamenco",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "61fe65d7-8ef0-41c4-be13-fcaca51f0d65",
    translationKey: "houseDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "08b85b8b-3f0f-43ad-9c88-902fdd25bc27",
    translationKey: "lineDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "72149b3c-0d5a-4f02-9c6e-844defaaa94c",
    translationKey: "ladyStyle",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "53e71ec0-b8c3-4dcf-8f2f-1900db38f327",
    translationKey: "polka",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "39add990-cac9-4cf6-a647-210df76c2703",
    translationKey: "stepdance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "94bf2307-5bb4-43c4-ab1f-67c50d4744b5",
    translationKey: "streetdance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "e45f6bdb-3c9a-45dc-aa4e-51c1bf20669a",
    translationKey: "tapDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: true
  },
  {
    id: "393ce8c8-445b-4a9e-a57f-04573bd4df24",
    translationKey: "videoclip",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "afa1de59-17ef-402e-b9c2-8eca35933c8c",
    translationKey: "twerking",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "b2724731-ff15-4e05-9150-e6f4ad314baa",
    translationKey: "waacking",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "474fc555-080e-479b-b5b1-c7249a0b02ef",
    translationKey: "ecstaticDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  },
  {
    id: "bb726492-eea1-4c9e-b7f9-34218f3a983c",
    translationKey: "freeDance",
    danceStyleStatus: DanceStyleStatusEnum.ACTIVE,
    isPairDance: false
  }
];
