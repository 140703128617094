import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environmentForWeb } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root'
})
export class ActivityHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiUsersUrl}/activity`;

  constructor(private http: HttpClient) {}

  logActivity(): Observable<boolean> {
    return this.http.get<boolean>(`${this.SERVICE_URL}`);
  }
  
}
