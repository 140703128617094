import { DanceEventCategoryEnum } from '../../..';
import { DanceEventCoHostsDto } from '../../..';

export class UpdateDanceEventGeneralInfoDto {
  danceStyleIds?: string[];
  musicStyleIds?: string[];
  tags?: string[];
  categories: DanceEventCategoryEnum[];
  coHosts?: DanceEventCoHostsDto[];
}
