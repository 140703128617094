import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DmPassSubscriptionInterface, PassInterface } from '@platri/df-common-core';

@Injectable({providedIn: 'root'})
export class PassSubscriptionStateService implements OnDestroy {
  availableDfmCoursePassSubscriptions = new BehaviorSubject<DmPassSubscriptionInterface[]>([]);
  passSubscriptionToDuplicateSubject: BehaviorSubject<PassInterface | null> = new BehaviorSubject<PassInterface | null>(null);

  constructor() {
    console.log('Initializing PassSubscriptionStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying PassSubscriptionStateService');
  }

  getAsyncAvailableDfmCoursePassSubscriptions(): Observable<DmPassSubscriptionInterface[]> {
    return this.availableDfmCoursePassSubscriptions.asObservable();
  }

  getSyncAvailableDfmCoursePassSubscriptions(): DmPassSubscriptionInterface[] {
    return this.availableDfmCoursePassSubscriptions.getValue();
  }

  sendAvailableDfmCoursePassSubscriptions(data: DmPassSubscriptionInterface[]): void {
    this.availableDfmCoursePassSubscriptions.next(data);
  }

  getAsyncPassSubscriptionToDuplicateSubject(): Observable<PassInterface | null> {
    return this.passSubscriptionToDuplicateSubject.asObservable();
  }

  getSyncPassSubscriptionToDuplicateSubject(): PassInterface | null {
    return this.passSubscriptionToDuplicateSubject.getValue();
  }

  sendPassSubscriptionToDuplicateSubject(data: PassInterface | null): void {
    this.passSubscriptionToDuplicateSubject.next(data);
  }
  
  clear(): void {
    this.availableDfmCoursePassSubscriptions.next([]);
    this.passSubscriptionToDuplicateSubject.next(null);
  }
}
