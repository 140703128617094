export interface Filter {
  title: string;
  options?: FilterOptionInterface[];
  startDate?: any;
  endDate?: any;
}

export interface FilterOptionInterface {
  label: string;
  value?: string;
  totalCount: number;
  checked?: boolean;
}
