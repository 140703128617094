<ng-container *transloco="let t">
  <div class="flex-column align-space-between" style="gap: 1em;" [formGroup]="formGroup">
    <div class="flex-row align-space-between center w-100">
      <span class="ticket-name">{{ pass.name }}</span>
      <span>{{pass.trial ? (t('GENERIC_WRAPPER.FREE_PASS')) : pass.price | centsToCurrencyString : 'EUR'}}</span>
    </div>
    <span class="usage-text">
      {{ pass.usageCount !== null ? pass.usageCount + ' ' + (t('GENERIC_WRAPPER.STAMPS')) + (pass.validity !== null ? '. ' : '') : '' }}
      {{ pass.validity !== null ? (t('GENERIC_WRAPPER.VALID_FOR')) + ' ' + pass.validity.value + ' ' + t('GENERIC_WRAPPER.' + pass.validity.interval +  (pass.validity.value > 1 ? '_PLURAL' : '_SINGULAR')) + ' ' + (t('GENERIC_WRAPPER.AFTER_PURCHASE')) : '' }}
    </span>
    <div class="flex-row w-100" *ngIf="pass.description">
      <div class="flex-column w-100">
        <div class="flex-column description-text ellipsis-2" style="gap: 0.5rem;">
          <div style="width: 100%" [innerHTML]="pass.description | dynamicTag"></div>
        </div>
      </div>
    </div>
    
    <div *ngIf="courses.length > 0">
      <span class="bold">{{ t('GENERIC_WRAPPER.COURSES_YOU_CAN_ACCESS') }}</span>
      <mat-list *ngIf="(courses.length <= 3) || (showAllAccessibleCourses && (courses.length > 3))">
        <mat-list-item class="max-height-25" *ngFor="let course of courses;">
          <ul>
            <a [href]="'/app/dance-class/' + course.id" target="_blank">
              <li class="bullet-point-text">{{ course.name }}</li>
            </a>
          </ul>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="!showAllAccessibleCourses && (courses.length > 3)">
        <mat-list-item class="max-height-25" *ngFor="let course of courses | slice:0:3">
          <ul>
            <a [href]="'/app/dance-class/' + course.id" target="_blank">
              <li class="bullet-point-text">{{ course.name }}</li>
            </a>
          </ul>
        </mat-list-item>
      </mat-list>
      <a *ngIf="courses.length > 3" class="show-more-text" (click)="showAllAccessibleCourses = !showAllAccessibleCourses">{{!showAllAccessibleCourses ? (t('GENERIC_WRAPPER.SHOW_MORE')) : (t('GENERIC_WRAPPER.SHOW_LESS')) }}</a>
    </div>
    
    <div *ngIf="pass.availableQuantity > 0; else soldOut" class="flex-row-end-center w-100 row-center">
      <button (click)="changeValue(-1)" class="cursor-pointer" [ngClass]="currentQuantity.value > 0 ? 'quantity-button' : 'disabled-quantity-button'">
        <mat-icon>remove</mat-icon>
      </button>
      <span class="ml-10 mr-10">{{ formGroup.get(pass?.id).value }}</span>
      <button (click)="changeValue(1)" class="cursor-pointer" [ngClass]="this.currentQuantityCanBeIncreased ? 'quantity-button' : 'disabled-quantity-button'">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  
  <ng-template #soldOut>
    <div class="flex-row-end-center w-100 pb1">
      {{ t('GENERIC_WRAPPER.SOLD_OUT') }}
    </div>
  </ng-template>
</ng-container>
