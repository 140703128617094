import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UnreadMessagesInterface } from '@platri/df-common-core';
import { MessageHttpService } from '../../components';
import { getSubscription } from '@platri/elab-angular-core';

@Injectable({
  providedIn: 'root'
})
export class MessageStateService implements OnDestroy {
  availableMessagesCounts = new BehaviorSubject<UnreadMessagesInterface | null>(null);

  subscriptions = new Subscription();
  
  constructor(private readonly messageHttpService: MessageHttpService) {
    console.log('Initializing MessageStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying MessageStateService');
    this.subscriptions.unsubscribe();
    this.availableMessagesCounts.unsubscribe();
  }
  
  getAsyncAvailableMessagesCounts(): Observable<UnreadMessagesInterface> {
    return this.availableMessagesCounts.asObservable();
  }
  
  loadAllMessagesCountOfCurrentUser(): void {
    this.subscriptions.add(getSubscription(this.messageHttpService.getUnreadMessagesCount(), this.sendLoadedMessagesCounts.bind(this), () => {console.log(`Couldn't load messages count`);}));
  }
  
  sendLoadedMessagesCounts(data: UnreadMessagesInterface): void {
    this.availableMessagesCounts.next(data);
  }
}
