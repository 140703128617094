<ng-container *transloco="let t">
  <div class="flex-row" style="padding-top: 10px;">
    <mat-chip (click)="toggleChip(0)" [class]="activeChipIndex === 0 ? 'active-chip' : ''" style="margin-right: 10px;">
      {{ t('GENERIC_WRAPPER.ACTIVE') }}
    </mat-chip>
    <mat-chip (click)="toggleChip(1)" [class]="activeChipIndex === 1 ? 'active-chip' : ''">
      {{ t('USER.INVENTORY.EXPIRED') }}
    </mat-chip>
  </div>
  <div *ngIf="activeChipIndex === 0">
    <div *ngIf="userPasses?.length > 0" class="flex-column-start-center gap-5 mt2">
      <df-user-inventory-list-subscription-item 
        *ngFor="let userPass of userPasses" 
        [userPass]="userPass"
        [danceManager]="filterUserPassDanceManagersByPassId(userPass.userPassSubscription.danceManagerId)"
        [courses]="filterUserPassCoursesByPassId(userPass.userPassSubscription.targets)"></df-user-inventory-list-subscription-item>
    </div>
    <div *ngIf="!(userPasses?.length > 0)">
      <div class="flex-column-center-center gap-15" style="padding-top: 10vh;">
        <img style="display: block; width: 174px; height: 174px" [src]="'assets/images/tickets-icon.png'" [alt]="">
        <span class="no-tickets-and-passes-title">{{ t('USER.NO_ACTIVE_SUBSCRIPTIONS') }}</span>
        <span class="no-tickets-and-passes-sub-title">{{ t('USER.NO_ACTIVE_SUBSCRIPTIONS_SUBTITLE') }}</span>
        <df-shared-lib-button
          buttonLabel="{{t('USER.SEARCH_COURSES')}}"
          (buttonClicked)="navigateToCoursesSearch()">
        </df-shared-lib-button>
      </div>
    </div>
  </div>
  <div *ngIf="activeChipIndex === 1">
    <div class="pt2 pb2">
      <div *ngIf="userPasses?.length > 0" class="flex-column-start-center gap-5 mb2">
      <df-user-inventory-list-subscription-item 
        *ngFor="let userPass of userPasses" 
        [userPass]="userPass"
        [danceManager]="filterUserPassDanceManagersByPassId(userPass.userPassSubscription.danceManagerId)"
        [courses]="filterUserPassCoursesByPassId(userPass.userPassSubscription.targets)"></df-user-inventory-list-subscription-item>
      </div>
      <div *ngIf="!(userPasses?.length > 0)">
        <div class="flex-column-center-center gap-15" style="padding-top: 10vh;">
          <img style="display: block; width: 174px; height: 174px" [src]="'assets/images/tickets-icon.png'" [alt]="">
        <span class="no-tickets-and-passes-title">{{ t('USER.NO_EXPIRED_SUBSCRIPTIONS') }}</span>
        <span class="no-tickets-and-passes-sub-title">{{ t('USER.NO_EXPIRED_SUBSCRIPTIONS_SUBTITLE') }}</span>
          <df-shared-lib-button
            buttonLabel="{{t('USER.SEARCH_COURSES')}}"
            (buttonClicked)="navigateToCoursesSearch()">
          </df-shared-lib-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
