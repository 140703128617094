import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dfAppRoute, dfConfirmDeactivateAccountTokenRouterParam, dfLoginRoute } from '@platri/dfx-angular-core';

@Component({
  selector: 'elab-deactivate-account-page',
  templateUrl: './deactivate-account-page.component.html',
  styleUrls: ['./deactivate-account-page.component.scss']
})
export class DeactivateAccountPageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription =  new Subscription();

  isWaitingForDeactivateAccountResponse = false;

  deactivateAccountFailedWrongData = false;
  deactivateAccountFailedNoConnection = false;
  deactivateAccountFailedInternalServer = false;

  hasSuccessfullyDeactivateAccount = false;
  
  routerParams!: Params;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matSnackbar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[dfConfirmDeactivateAccountTokenRouterParam]) {
      this.router.navigate([dfAppRoute, dfLoginRoute]);
    } else {
      this.deactivateAccount();
    }
  }
  
  deactivateAccount(): void {
    this.isWaitingForDeactivateAccountResponse = true;
    this.deactivateAccountFailedWrongData = false;
    this.deactivateAccountFailedNoConnection = false;
    this.deactivateAccountFailedInternalServer = false;
    // this.subscriptions.add(this.userHttpService.deactivateAccount(this.routerParams[dfConfirmDeactivateAccountTokenRouterParam]).subscribe(res => {
    //   this.hasSuccessfullyDeactivateAccount = true;
    //   this.isWaitingForDeactivateAccountResponse = false;
    //   this.matSnackbar.open("Successfully deactivated account!");
    // }, error => {
    //   console.log(error);
    //   if (error.status === 0) {
    //     this.onConnectionLost();
    //   }
    //   this.isWaitingForDeactivateAccountResponse = false;
    // }));
  }

  onConnectionLost(): void {
    (this.deactivateAccountFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.deactivateAccountFailedInternalServer = true;
  }

}
