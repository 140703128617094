import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DfmCoursePassesTableInterface, UserAppLangEnum } from '@platri/df-common-core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-pass-subscription-targets-table',
  templateUrl: './pass-subscription-targets-table.component.html',
  styleUrls: ['./pass-subscription-targets-table.component.scss'],
})
export class PassSubscriptionTargetsTableComponent implements OnInit, OnChanges{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  @Input() courses: DfmCoursePassesTableInterface[] = [];
  @Input() alreadySelectedTargetIds: string[] = [];
  
  @Output() selectedCourses = new EventEmitter<DfmCoursePassesTableInterface[]>();

  displayedColumns = ['select', 'name', 'danceStyles', 'level','nextAppointment'];
  dataSource: MatTableDataSource<DfmCoursePassesTableInterface>;
  selection = new SelectionModel<DfmCoursePassesTableInterface>(true, []);
  changedSelection = [];
  
  currentLang: UserAppLangEnum;

  subscription = new Subscription();

  constructor(private translocoService: TranslocoService) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnInit(): void {
    this.updateDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.courses) {
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.courses);
    this.dataSource.sort = this.sort;
    if (this.courses?.length > 0 && this.alreadySelectedTargetIds?.length > 0) {
      this.courses.forEach(row => {
        if (this.alreadySelectedTargetIds.includes(row.id)) {
          this.selection.select(row);
        }
      });
      this.changedSelection = this.courses;
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSelectionChange(event: any, row: DfmCoursePassesTableInterface): void {
    if (event.checked) {
      this.selection.select(row);
    } else {
      this.selection.deselect(row);
    }
    this.selectedCourses.emit(this.selection.selected);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedCourses.emit(this.selection.selected);
    } else {
      this.dataSource.data.forEach(row => this.selection.select(row));
      this.selectedCourses.emit(this.selection.selected);
    }
  }

}
