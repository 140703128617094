<ng-container *transloco="let t">
  <div class="p2" style="  height: 100% !important;  width: 100% !important;">
    <div
      *ngIf="isDialog"
      class="header mb2 flex-row align-space-between-center"
    >
      <span class="bold">{{ t('GENERIC_WRAPPER.IMAGE_UPLOAD_HEADER') }}</span>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="mx1 image-cropper-field-container" style="max-height: 580px; height: 580px">
      <div *ngIf="!imageSelected; then imageUploader; else imageCropper"></div>
      <div
        *ngIf="isDialog"
        class="button-container-alignment flex-row gap-10"
        style="height: 10%; align-items: end"
      >
        <df-shared-lib-button
          [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
          materialButtonStyleType="outlined"
          mat-dialog-close
        >
        </df-shared-lib-button>
        <df-shared-lib-button
          [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
          [isDisabled]="!imageSelected || isLoading"
          [loading]="isLoading"
          (buttonClicked)="triggerUploadFormData()"
        >
        </df-shared-lib-button>
      </div>
      <ng-template #imageUploader>
        <div class="cropper-field w-100">
          <div class="dropzone p2 flex-column align-center w-100">
            <mat-icon
              class="material-icons-round"
              style="font-size: 60px; height: 60px; width: 60px; color: #646464"
            >file_upload</mat-icon
            >
            <span
              class="bold pt2 pb1"
              style="color: #4d4d4d; font-size: 26px"
              [innerHtml]="t('GENERIC_WRAPPER.IMAGE_UPLOAD_HEADER')"
            ></span>
            <input
              id="pictureInput"
              class="pictureInput"
              #fileInput
              type="file"
              [multiple]="multipleFiles"
              [accept]="allowedImageFileFormats"
              [size]="maxFileSizeInBytes"
              (click)="setValueOfTarget($event)"
              (change)="onFileSelect($event)"
            />
            <span
              class="pb2"
              style="color: #4d4d4d; white-space: pre-wrap"
              [innerHtml]="t('GENERIC_WRAPPER.IMAGE_UPLOAD_TEXT')"
            ></span>
            <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.IMAGE_UPLOAD_SELECT_FILE')">
            </df-shared-lib-button>
          </div>
        </div>
      </ng-template>
      <ng-template #imageCropper>
        <div>
          <image-cropper
            [imageFile]="croppedFiles[0]"
            [maintainAspectRatio]="true"
            [transform]="transform"
            [backgroundColor]="'white'"
            [aspectRatio]="croppedImageRatio"
            [resizeToWidth]="resizeToWidth"
            [resizeToHeight]="resizeToHeight"
            [roundCropper]="roundCropper"
            [format]="'jpeg'"
            [output]="'base64'"
            (imageCropped)="croppedImage($event)"
          ></image-cropper>
          <div class="row pb10">
            <button mat-icon-button (click)="zoomOut()"><mat-icon>remove</mat-icon></button>
            <p>{{ roundedScale ? roundedScale : 1 }}</p>
            <button mat-icon-button (click)="zoomIn()"><mat-icon>add</mat-icon></button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
