import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '@platri/elab-angular-core';
import { FrontendDfSearchAppRoutingModule } from './frontend-df-search-app-routing.module';
import { TranslocoStateConst } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FrontendDfSearchAppRoutingModule,
    MatSnackBarModule,
    TranslocoModule
  ],
  providers: [
    CustomMatSnackbarService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 }},
  ],
  exports: []
})
export class FrontendDfSearchAppModule {
  constructor() {
    console.log("FrontendDfSearchAppModule initialized");
    TranslocoStateConst.push({ scope: 'df-search', alias: 'SEARCH' });
  }
}
