import { DanceStyleDto, ImageDto } from '../../..';
import { DanceManagerDto } from './dance-manager.dto';

export class CoursePublicViewDto  {
  id: string;
  name: string;
  startDifficulty: number;
  endDifficulty: number;
  danceStyles: DanceStyleDto[];
  nextAppointmentDate?: Date;
  nextAppointmentDuration?: number;
  description: string;
  mainImage?: ImageDto;
  cheapestPrice: number;
  isCheckInActive: boolean;
  danceManager?: DanceManagerDto;
}
