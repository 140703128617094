<ng-container *transloco="let t">
  <div class="flex-column">
    <div mat-dialog-title class="dialog-title">
      <span>{{ t('GENERIC_WRAPPER.UPLOAD_IMAGE') }}</span>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngFor="let file of data.files; let i = index">
      <image-cropper
        [imageFile]="file"
        [resizeToWidth]="resizeToWidth"
        [resizeToHeight]="resizeToHeight"
        [maintainAspectRatio]="true"
        [aspectRatio]="data.aspectRatio || 1"
        [format]="'jpeg'"
        [output]="'base64'"
        (imageCropped)="imageCropped($event, i, file)"
      ></image-cropper>
    </div>
    <div style="gap: 1rem" class="flex-row align-end mt2">
      <button mat-stroked-button mat-dialog-close>
        {{ t('GENERIC_WRAPPER.CANCEL') }}
      </button>
      <button mat-raised-button color="primary" (click)="onCropClick()">
        {{ t('GENERIC_WRAPPER.SAVE') }}
      </button>
    </div>
  </div>
</ng-container>
