import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { FollowInfoDialogComponent } from './follow-info-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FollowInfoCardModule } from '../follow-info-card';
import {SharedEmptyPageModule} from "../shared-empty-page";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [FollowInfoDialogComponent],
    imports: [
      CommonModule,
      MatTabsModule,
      MatIconModule,
      MatButtonModule,
      MatDialogModule,
      FollowInfoCardModule,
      MatProgressSpinnerModule,
      SharedEmptyPageModule,
      TranslocoDirective,
    ],
})
export class FollowInfoDialogModule {}
