import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-user-search-autocomplete',
  templateUrl: './user-search-autocomplete.component.html',
  styleUrls: ['./user-search-autocomplete.component.scss'],
})
export class UserSearchAutocompleteComponent implements OnInit {
  @Output() inputChange = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter<any>();
  @Input() label = 'GENERIC_WRAPPER.SEARCH_USER';
  @Input() placeholder = 'GENERIC_WRAPPER.NAME_OR_USERNAME';
  @Input() required = false;
  @Input() errorMessage = 'GENERIC_WRAPPER.USER_SEARCH_ERROR';
  @Input() showUserSearchLoading = false;
  @Input() userOptions: any[];
  @Input() resetFormAfterSelection = false;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  searchUserForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.subscribeToSearchUser();
  }

  initForm(): void {
    this.searchUserForm = this.fb.group({
      user: ['', []],
    });
    if (this.required) {
      this.searchUserForm.controls.user.setValidators(Validators.required);
    } else {
      this.searchUserForm.controls.user.setValidators(null);
    }
    this.userOptions = [];
  }

  subscribeToSearchUser(): void {
    this.searchUserForm.controls.user.valueChanges.subscribe((text) =>
      this.inputChange.emit(text)
    );
  }

  emitSelectedUsername(option: any): void {
    this.optionSelected.emit(option);
    if (this.resetFormAfterSelection) {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.searchUserForm.reset();
  }
}
