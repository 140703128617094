import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmAdministrationPage } from './dfm-administration.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuillModule } from 'ngx-quill';
import {
  AddImageModule,
  DfQuillEditorModule,
  DfSharedButtonModule,
  SearchCategoryModule,
  SearchDanceStyleModule,
  SearchMusicStyleModule
} from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import {
  DfmContactModule,
  DfmFaqsModule,
  DfmGalleryModule,
  DfmGeneralModule,
  DfmTeamModule
} from '../../components';
import { TranslocoDirective } from '@jsverse/transloco';

const routes = [
  {
    path: '',
    component: DfmAdministrationPage,
  },
];

@NgModule({
  declarations: [DfmAdministrationPage],
  exports: [DfmAdministrationPage],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    QuillModule,
    DfQuillEditorModule,
    SearchDanceStyleModule,
    SearchMusicStyleModule,
    SearchCategoryModule,
    MatChipsModule,
    MatIconModule,
    AddImageModule,
    DfSharedButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    DfmGeneralModule,
    DfmFaqsModule,
    DfmTeamModule,
    DfmContactModule,
    MatCardModule,
    DfmGalleryModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    TranslocoDirective
  ]
})
export class DfmAdministrationPageModule {}
