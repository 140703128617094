import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'numberToEuroStringPipe'
})
export class NumberToEuroStringPipe implements PipeTransform {
  transform(value: number | string): string {
    if(value === null) {
      return null;
    }
    return (+value).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
  }
}
