import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicTag'
})
export class DynamicTagPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\B(@\w*)/gm, (match) => '<a class="simpleTag" href="/app/@' + match.substring(1) + '">' + match + '</a>');
  }
}
