import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DanceSchoolHttpService, LogoAndTitleUploadDialogComponent, SharedCancelDialogComponent } from '@platri/dfx-angular-core';
import { DanceSchoolInterface, LogoAndTitleUploadDialogTypeEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-manager-header-image',
  templateUrl: './dance-manager-header-image.component.html',
  styleUrls: ['./dance-manager-header-image.component.scss'],
})
export class DanceManagerHeaderImageComponent implements OnDestroy{
  @Input() danceManager: DanceSchoolInterface;
  @Input() isOwnerOrAdmin: boolean;
  
  private subscription: Subscription = new Subscription();
  private mdBreakpoint = 960;
  
  constructor(
    private readonly dialog: MatDialog,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly translocoService: TranslocoService
  ) {
  }

  openDanceSchoolLogoDialogComponent(): void {
    const dialogRef = this.dialog.open(LogoAndTitleUploadDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      width: '100%',
      maxWidth: '1140px',
      data: {
        id: this.danceManager.id,
        mode: LogoAndTitleUploadDialogTypeEnum.STUDIO_TITLE,
      },
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.danceManager.titleImage = result.titleImage;
        }
      })
    );
  }

  scroll(): void {
    const headerImage = document.getElementById('header-image');
    const headerImageHeight = headerImage ? headerImage.offsetHeight : 0;
    let factor = 0.75;

    if (window.innerWidth < this.mdBreakpoint) {
      factor = 0.5;
    }
    
    const container = document.getElementById('pageBody');
    if (container) {
      setTimeout(function () {
        container.scrollTo({
          top: factor * headerImageHeight,
          behavior: 'smooth'
        });
      }, 500);
    }
  }

  confirmDelete(): void {
    const cancelDialogRef = this.dialog.open(SharedCancelDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      maxWidth: '400px',
      disableClose: true,
      data: {
        title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COVER_PICTURE'),
        subtitle: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COVER_PICTURE_SUBTITLE'),
        infoText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
        cancelText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      },
    });

    cancelDialogRef.afterClosed().subscribe((reason) => {
      if (!reason) {
        this.deleteTitle();
      }
    });
  }

  deleteTitle(): void {
    this.subscription.add(
      this.danceSchoolHttpService.deleteDanceManagerTitleByDanceSchoolId(this.danceManager.id).subscribe((danceSchool) => {
        this.danceManager.titleImage = danceSchool.titleImage;
      })
    );
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
