<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveDanceYears()">
    <div class="flex-column">
      <mat-slider
        class="mt3 ml2"
        [displayWith]="formatLabel"
        [min]="0"
        [max]="132"
        [step]="1">
        <input matSliderThumb (valueChange)="onInputChange($event)" formControlName="monthsDancing">
      </mat-slider>
      <span class="ml3">{{formGroup.controls.monthsDancing.value | transformMonthYearLabel : false}}</span>
    </div>
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveDanceYears()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
