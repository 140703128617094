import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmSidenavComponent } from './dfm-sidenav.component';
import {
  DfSharedButtonModule,
  PipesModule,
  SecuredDanceSchoolElementModule
} from '@platri/dfx-angular-core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { DfmSidenavAdministrationModule } from './dfm-sidenav-administration';
import { DfmSidenavEventsModule } from './dfm-sidenav-events';
import { DfmSidenavCoursesModule } from './dfm-sidenav-courses';
import { TranslocoDirective } from '@jsverse/transloco';

const INNER_COMPONENT_MODULES = [
  DfmSidenavAdministrationModule,
  DfmSidenavEventsModule,
  DfmSidenavCoursesModule, 
]; 

const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatListModule,
  MatSelectModule,
  MatTooltipModule,
  MatIconModule,
  MatBadgeModule,
  MatButtonModule,
];

@NgModule({
  declarations: [DfmSidenavComponent],
  exports: [DfmSidenavComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        SecuredDanceSchoolElementModule,
        ...INNER_COMPONENT_MODULES,
        ...MATERIAL_MODULES,
        PipesModule,
        DfSharedButtonModule,
        TranslocoDirective
    ]
})
export class DfmSidenavModule {}
