import { Injectable } from '@angular/core';
import { DanceSchoolLocationHttpService } from '../http-services';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { LocationDto, LocationInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DanceSchoolLocationService {
  private currentDanceSchoolLocationSubject: BehaviorSubject<LocationInterface> =
    new BehaviorSubject<LocationInterface>(null);
  private currentDanceSchoolLocationsSubject: BehaviorSubject<LocationInterface[]> =
    new BehaviorSubject<LocationInterface[]>([]);

  private currentLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private liveEditRoomLocationSubject: BehaviorSubject<LocationInterface> =
    new BehaviorSubject<LocationInterface>(null);

  constructor(
    private readonly danceSchoolLocationHttpService: DanceSchoolLocationHttpService
  ) {}

  getAsyncLoading(): Observable<boolean> {
    return this.currentLoadingSubject.asObservable();
  }

  sendLiveEditRoomLocation(location: LocationInterface): void {
    if (!_.isEqual(this.liveEditRoomLocationSubject.getValue(), location)) {
      this.liveEditRoomLocationSubject.next(location);
    }
  }

  getAsyncLiveEditRoomLocation(): Observable<LocationInterface> {
    return this.liveEditRoomLocationSubject.asObservable();
  }

  getSyncLiveEditRoomLocation(): LocationInterface {
    return this.liveEditRoomLocationSubject.getValue();
  }

  public getAsyncCurrentDanceSchoolLocation(): Observable<LocationInterface> {
    return this.currentDanceSchoolLocationSubject.asObservable();
  }

  public getAsyncCurrentDanceSchoolLocations(): Observable<LocationInterface[]> {
    return this.currentDanceSchoolLocationsSubject.asObservable();
  }

  public getSyncCurrentDanceSchoolLocations(): LocationInterface[] {
    return this.currentDanceSchoolLocationsSubject.getValue();
  }

  public getSyncCurrentDanceSchoolLocation(): LocationInterface {
    return this.currentDanceSchoolLocationSubject.getValue();
  }

  public sendCurrentDanceSchoolLocation(
    danceSchoolLocation: LocationInterface
  ): void {
    if (danceSchoolLocation) {
      const danceSchoolLocationData = {
        ...this.getSyncCurrentDanceSchoolLocation(),
        ...danceSchoolLocation,
      };
      this.currentDanceSchoolLocationSubject.next(danceSchoolLocationData);
    }
  }

  public sendCurrentDanceSchoolLocationsChanges(
    danceSchoolLocations: LocationInterface[]
  ): void {
    if (danceSchoolLocations) {
      this.currentDanceSchoolLocationsSubject.next(danceSchoolLocations);
    }
  }

  sendClearLocation(): void {
    this.currentDanceSchoolLocationSubject.next(null);
  }

  sendClearLocations(): void {
    this.currentDanceSchoolLocationsSubject.next([]);
  }

  sendClearLiveEditRoomLocation(): void {
    this.liveEditRoomLocationSubject.next(null);
  }

  // BUSINESS LOGIC

  getAllDanceSchoolLocationsByDanceSchoolId(danceSchoolId: string): void {
    this.danceSchoolLocationHttpService
      .getLocationsOfDanceSchool(danceSchoolId)
      .pipe(
        tap((danceSchoolLocations) => {
          this.currentLoadingSubject.next(false);
          this.sendCurrentDanceSchoolLocationsChanges(danceSchoolLocations);
        })
      )
      .subscribe();
  }

  createDanceSchoolLocation(
    danceSchoolId: string,
    danceSchoolLocation: LocationDto,
    formData?: FormData
  ): void {
    this.currentLoadingSubject.next(true);
    this.danceSchoolLocationHttpService
      .createDanceSchoolLocation(danceSchoolId, danceSchoolLocation)
      .pipe(
        tap((location) => {
          if (formData) {
            // @ts-ignore
            this.updateImagesOfLocation(danceSchoolId, location.id, formData);
          } else {
            this.getAllDanceSchoolLocationsByDanceSchoolId(danceSchoolId);
            this.sendCurrentDanceSchoolLocation(location);
          }
        })
      )
      .subscribe(
        () => {},
        () => this.currentLoadingSubject.next(false)
      );
  }

  updateImagesOfLocation(
    danceSchoolId: string,
    locationId: string,
    formData: FormData
  ): void {
    this.danceSchoolLocationHttpService
      .updateDanceSchoolLocation(danceSchoolId, locationId, formData)
      .pipe(
        tap((location) => {
          this.getAllDanceSchoolLocationsByDanceSchoolId(danceSchoolId);
          this.sendCurrentDanceSchoolLocation(location);
        })
      )
      .subscribe();
  }

  updateDanceSchoolLocation(
    danceSchoolId: string,
    danceSchoolLocationId: string,
    danceSchoolLocation: LocationDto,
    formData?: FormData
  ): void {
    // @ts-ignore
    delete danceSchoolLocation.id;
    this.danceSchoolLocationHttpService
      .updateDanceSchoolLocation(
        danceSchoolId,
        danceSchoolLocationId,
        danceSchoolLocation
      )
      .pipe(
        tap((location) => {
          if (formData) {
            this.updateImagesOfLocation(
              danceSchoolId,
              danceSchoolLocationId,
              formData
            );
          } else {
            this.getAllDanceSchoolLocationsByDanceSchoolId(danceSchoolId);
            this.sendCurrentDanceSchoolLocation(location);
          }
        })
      )
      .subscribe();
  }

  deleteDanceSchoolLocation(
    danceSchoolId: string,
    danceSchoolLocationId: string
  ): Observable<any> {
    return this.danceSchoolLocationHttpService
      .deleteDanceSchoolLocation(danceSchoolId, danceSchoolLocationId)
      .pipe(
        tap(() => this.getAllDanceSchoolLocationsByDanceSchoolId(danceSchoolId))
      );
  }
  

  getLocationById(id: string, danceSchoolId: string): Observable<LocationInterface> {
    return this.danceSchoolLocationHttpService.getLocationById(id, danceSchoolId)
      .pipe(
        tap((location) => this.sendCurrentDanceSchoolLocation(location))
      );
  }

  updateVisibilityById(id: string, isPublic: boolean, danceSchoolId: string): void {
    this.danceSchoolLocationHttpService
      .updateVisibilityById(id, isPublic, danceSchoolId)
      .pipe(
        tap(() => this.getAllDanceSchoolLocationsByDanceSchoolId(danceSchoolId))
      ).subscribe();
  }
}
