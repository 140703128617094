import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ChatAppChatsTechnicalUserRouterQueryParam } from '@platri/elab-angular-best-practise';
import { getSubscription } from '@platri/elab-angular-core';
import { filter, map, Observable, Subscription, take } from 'rxjs';
import { OmniWebsocketDataInterface, UsersInterface } from '@platri/df-common-core';
import {
  AuthStateService,
  DfmStateService,
  LanguageService,
  OmniWebsocketService,
  RedirectWordpressDialogComponent,
  UserHttpService
} from '@platri/dfx-angular-core';
import { TranslocoService } from '@jsverse/transloco';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'df-generic-wrapper',
  templateUrl: './generic-wrapper.component.html',
  styleUrls: ['./generic-wrapper.component.scss']
})
export class GenericWrapperComponent implements OnInit {
  currentUser: UsersInterface | null = null;
  currentTechnicalUser: any | null = null;
  
  loadedRoutes: string[];

  subscriptions = new Subscription();

  queryParams!: Params;
  technicalUserIdFromQueryParam: string;
  
  translationLoaded = false;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private userHttpService: UserHttpService,
    private router: Router,
    private authStateService: AuthStateService,
    private languageService: LanguageService,
    private ngZone: NgZone,
    private omniWebsocketService: OmniWebsocketService,
    private dfmStateService: DfmStateService,
    private translocoService: TranslocoService,
    private matDialog: MatDialog
  ) {
  }
  
  async ngOnInit(): Promise<void> {
    await this.loadTransloco();
    this.loadedRoutes = this.router.config.map((routes) =>  routes.path ? routes.path : '');
    this.initializeSubscriptions();
  }
  
  async loadTransloco(): Promise<void> {
    this.translocoService.load('de').pipe(take(1)).subscribe({
      next: () => {
        this.translationLoaded = true;
      }
    });
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.filterRouteObservableForRouterQueryParam(this.router.events), this.onQueryParamChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.authStateService.getAsyncCurrentUser(), this.onCurrentUserDataChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.omniWebsocketService.getAsyncEventBus(), this.onNotificationEvents.bind(this)));
  }

  onNotificationEvents(websocketEventInterface: OmniWebsocketDataInterface): void {
    switch (websocketEventInterface.event) {
      case "authRolesChanged":
        this.authStateService.refreshTokens().subscribe({
          next: () => {
            this.dfmStateService.loadAvailableData();
          }
        });
        break;
    }
  }

  filterRouteObservableForRouterQueryParam(routerObservable: Observable<any>): Observable<any> {
    return routerObservable.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute.firstChild),
      map(route => {
        while (route?.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route !== null),
      map(route => route?.snapshot.queryParams)
    );
  }

  onQueryParamChanges(queryParams: Params): void {
    this.queryParams = queryParams;
    if (this.queryParams['fromLanding'] && this.queryParams['fromLanding'] === 'true') {
      this.openRedirectWordpressDialog();
    }
    this.technicalUserIdFromQueryParam = this.queryParams[ChatAppChatsTechnicalUserRouterQueryParam];
    if (!this.technicalUserIdFromQueryParam) {
      this.currentTechnicalUser = null;
    }
  }
  
  openRedirectWordpressDialog(): void {
    const timeout = 3000;
    const matDialogRef = this.matDialog.open(RedirectWordpressDialogComponent, {
      position: {
        bottom: '80px',
        left: '30px'
      },
      hasBackdrop: false
    });
    this.router.navigate([], {
      queryParams: {
        fromLanding: null
      },
      queryParamsHandling: 'merge'
    });

    matDialogRef.afterOpened().subscribe((_) => {
      setTimeout(() => {
        matDialogRef.close();
      }, timeout);
    });
  }

  onCurrentUserDataChanges(currentUser: UsersInterface | null): void {
    if (!currentUser && this.authStateService.getSyncRefreshToken()) {
      this.userHttpService.getCurrentUser().subscribe({
        next: (user) => {
          this.authStateService.sendCurrentUserChanges(user);
        },
        error: (err) => {
          console.log(err);
        }
      });
    } else if (!this.currentUser) {
      this.currentUser = currentUser;
      // this.subscriptions.add(getSubscription(this.authTechnicalUserStateService.getAsyncCurrentUser(), this.onCurrentUserTechnicalUserDataChanges.bind(this)));
    }
  }

  // onCurrentUserTechnicalUserDataChanges(currentUser: UserDto | null): void {
  //   console.log(12);
  //   if (!this.technicalUserIdFromQueryParam) return;
  //   if (!currentUser) {
  //     this.authTechnicalUserStateService.login(this.technicalUserIdFromQueryParam).subscribe(res => {
  //       if (res) {
  //         this.userHttpService.getCurrentUser(res.accessToken).subscribe(technicalUser => {
  //           this.authTechnicalUserStateService.sendCurrentUser(technicalUser);
  //         });
  //       }
  //     });
  //   } else if (!this.currentTechnicalUser) {
  //     this.currentTechnicalUser = currentUser;
  //   }
  // }

}
