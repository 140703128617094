import { BillingOrShippingAddressInterface } from './addresses';
import { UserTicketInterface } from './user-ticket.interface';
import { CurrencyTypeEnum, TicketOrderStatusEnum } from '../enums';
import { TicketOrderTransactionInterface } from './ticket-order-transaction.interface';
import { UserPassInterface } from './pass';
import { Column } from 'typeorm';

export interface TicketOrderInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  invoiceId?: string;
  totalPrice?: number;
  totalTax?: number;
  totalRefund?: number;
  customerApplicationFee?: number;
  currencyType?: CurrencyTypeEnum;
  status?: TicketOrderStatusEnum;
  billingAddress?: BillingOrShippingAddressInterface;
  shippingAddress?: BillingOrShippingAddressInterface;
  userId?: string;
  userEmail?: string;
  userTickets?: UserTicketInterface[];
  userPasses?: UserPassInterface[];
  ticketOrderTransactions?: TicketOrderTransactionInterface[];
  confirmationMailSent?: boolean;
  feePercentage?: number;
  feePerTicket?: number;
}
