import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-months-dancing-form-settings',
  templateUrl: './months-dancing-form-settings.component.html',
  styleUrls: ['./months-dancing-form-settings.component.scss'],
})
export class MonthsDancingFormSettingsComponent implements OnInit, OnDestroy {

  @Input() currentUser: UsersInterface;
  @Output() monthsDancingSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  isSubmitting = true;
  formGroup: UntypedFormGroup;
  subscription: Subscription = new Subscription();

  currentSliderValue = 0;

  stepIndex = 0;
  steps: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132];
  finalValue = 0;


  constructor(
    private readonly formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  onInputChange(event: any): void {
    this.stepIndex = +event.value;
    this.finalValue = this.steps[this.stepIndex];
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      monthsDancing: [+this.currentUser.monthsDancing, Validators.min(0)],
    });
    this.subscription.add(this.formGroup.controls.monthsDancing.valueChanges.subscribe(value => {
      this.formGroup.markAsDirty();
    }));
  }

  saveDanceYears(): void {
    if (this.formGroup.valid) {
      const user = {monthsDancing: this.formGroup.value.monthsDancing};
      this.monthsDancingSaved.emit(user);
      this.isSubmitting = true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  formatLabel(value: number): string {
    if (isNaN(value)) {
      return 0 + ' M.';
    }
    if (value > 131) {
      return '10+ J.';
    }
    if (value >= 12) {
      return Math.floor(value / 12) + ' J.';
    }
    return value + ' M.';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
