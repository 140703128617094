import { Component, Input } from '@angular/core';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';

@Component({
  selector: 'elab-list-picture-chat',
  templateUrl: './list-picture-chat.component.html',
  styleUrls: ['./list-picture-chat.component.scss']
})
export class ListPictureChatComponent {
  @Input({required: true}) imageUrl: string = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  @Input({required: false}) isProfilePicture: boolean;
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
}
