<ng-container *transloco="let t">
  <div class="p2">
    <div class="mb-10">
      <span class="title">{{ t(data.title) }}</span>
    </div>
    
    <div class="mb-10">  
      <span class="text">{{ t(data.text) }}</span>
    </div>
    
    <div class="align-center mb-10">
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </div>
    
    <df-shared-lib-button
      [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
      [materialButtonStyleType]="'flat'"
      (buttonClicked)="onCancel()"
    ></df-shared-lib-button>
  </div>
</ng-container>
