<ng-container *transloco="let t">
  <div class="flex-row align-space-between-center mb2">
    <h4>Status</h4>
    <button type="button" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="flex-column gap-10">
    <div class="flex-row-start-center gap-10">
      <span class="activityStatusType green">•</span>
      <span class="fs14"
        >The user has participated in at least one of the last three
        appointments</span
      >
    </div>
    <div class="flex-row-start-center gap-10">
      <span class="activityStatusType yellow">•</span>
      <span class="fs14">The user has cancelled an appointment before</span>
    </div>
    <div class="flex-row-start-center gap-10">
      <span class="activityStatusType red">•</span>
      <span class="fs14"
        >The user has not attended an appointment and did not cancel
      </span>
    </div>
    <div class="flex-row-start-center gap-10">
      <span class="activityStatusType grey">•</span>
      <span class="fs14"
        >The user has not attended any of the last three appointments</span
      >
    </div>
    <div class="flex-row-end-center">
      <df-shared-lib-button
        buttonLabel="OK"
        materialButtonStyleType="outlined"
        (buttonClicked)="close()"
      >
      </df-shared-lib-button>
    </div>
  </div>
</ng-container>
