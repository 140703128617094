import { UsersInterface } from './users';

export interface LastLoginInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  user?: UsersInterface;
  userId?: number;
  loginDate?: Date;
}
