import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditEventGeneralInfoComponent } from './add-edit-event-general-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuillModule } from 'ngx-quill';
import { AddImageModule, CoHostSelectionModule, DfQuillEditorModule, DfQuillModule, DfSharedButtonModule, SearchCategoryModule, SearchDanceManagersModule, SearchDanceStyleModule, SearchMusicStyleModule, UserSearchAutocompleteModule } from '@platri/dfx-angular-core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [AddEditEventGeneralInfoComponent],
  exports: [AddEditEventGeneralInfoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    QuillModule,
    DfQuillEditorModule,
    SearchDanceStyleModule,
    SearchMusicStyleModule,
    SearchCategoryModule,
    SearchDanceManagersModule,
    MatChipsModule,
    MatIconModule,
    AddImageModule,
    DfSharedButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    UserSearchAutocompleteModule,
    CoHostSelectionModule,
    DfQuillModule,
    TranslocoDirective
  ]
})
export class AddEditDanceEventGeneralInfoModule {}
