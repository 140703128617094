import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, Observable, tap } from 'rxjs';
import { CoursesHttpService, CoursesStateService } from '../services';
import { dfCourseIdRouterParam } from '../shared';

@Injectable({ providedIn: 'root' })
export class CourseResolver  {
  constructor(private readonly coursesHttpService: CoursesHttpService, private readonly coursesService: CoursesStateService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const searchCourseId = route.paramMap.get(dfCourseIdRouterParam);
    const isDm = state.url.includes('/dance-manager/');
    return this.coursesHttpService.getById(searchCourseId, true, !isDm).pipe(
      tap((res) => {
        this.coursesService.sendCourse(res);
        return res;
      }),
      catchError((error) => {
        this.router.navigateByUrl('/404');
        return EMPTY;
      })
    );
  }
}
