<div style="position: relative;">
  <ng-container *ngIf="danceSchool; else liveEdit">
    <a *ngIf="danceSchool.urlName" [routerLink]="['/', '@' + danceSchool.urlName]">
      <div class="preview clickable opacityAnimation preview_card flex-row h-lt-sm column-lt-sm">
        <div class="flex-column w-lt-sm" id="left-column">
          <div id="studio_card_image" class="relative h-100 flex-column" style="gap: 0.5rem;">
            <img [src]="danceSchool.titleImage?.url ?? 'assets/images/studio_card_image_web.png'" alt="" class="previewImage w-100 h-100 mb0 block"/>
          </div>
        </div>

        <div class="flex-column align-start w-lt-sm flex-lt-sm" id="right-column">
          <div class="flex-row" id="rc-top-row">
            <div class="previewHeader flex-column align-start-center">
              <div class="flex-row-center-center">
                <img class="dance-school-image circle" [src]="danceSchool.imageUrl ?? 'assets/images/studio_logo.png'" alt="" />
                <div class="flex-column">
                  <div 
                    class="dance-school name w-100 ellipsis-1 align-start-center" 
                    #danceSchoolName 
                    [matTooltip]="danceSchoolName.offsetHeight < danceSchoolName.scrollHeight ? danceSchool.name : null" 
                    [matTooltipClass]="'fontSize14'" 
                  >
                    {{ danceSchool.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="rc-mid-row" class="mb5px flex-row">
            <div class="preview-room-description" [innerHTML]="danceSchool.description | stripHtmlTags |  truncate:200"></div>
          </div>
          <div id="rc-bottom-row" class="self-end w-100 flex-row align-space-between-center">
            <span *ngIf="danceSchool.distance >= 0" id="distance-span">
              <mat-icon class="material-icons-round vSub">place</mat-icon>
              {{ danceSchool.distance | roundDistancePipe }}
              {{ danceSchool.radiusUnit }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </ng-container>

  <div 
    [ngClass]="loading ? 'loading' : 'notLoading'" 
    [ngStyle]="loading ? {} : { position: 'absolute', top: 0 }"
    class="preview previewSkeleton w-100 flex-row column-lt-sm" 
    style="gap: 5px;"
    [routerLink]="danceSchool ? ['/', danceSchool.urlName] : null"
  >
    <div class="flex-column w-lt-sm" style="width: 36%; height: 100%;">
      <div class="previewImage w-100 h-100 mb0 block">
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="flex-column w-lt-sm" style="width: 64%; height: 100%" id="skeleton-right-column">
      <div class="flex-row">
        <ngx-skeleton-loader [theme]="{ height: '45px', width: '45px', 'margin-bottom': 0, 'border-radius': '3rem' }"></ngx-skeleton-loader>
        <div class="flex-column" style="width: 100%">
          <ngx-skeleton-loader style="width: 100%" [theme]="{ width: '80%', height: '18px', 'margin-bottom': '0' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader style="width: 100%" [theme]="{ width: '80%', height: '18px', 'margin-bottom': '0' }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="flex-row">
        <ngx-skeleton-loader style="width: 100%" [theme]="{ width: '100%', height: '64px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
      </div>
      <div class="flex-row">
        <ngx-skeleton-loader style="width: 100%" [theme]="{ width: '100%', height: '20px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>

<ng-template #liveEdit>
  <div *ngIf="location$ | async as location" class="preview flex-column" style="gap: 0.5rem;">
    <div class="flex-row align-space-between">
      <div class="mx2 mt2 previewHeader flex-row-center-center" style="gap: 1rem;">
        <img class="dance-school-image" [src]="danceSchool.imageUrl ?? 'assets/images/studio_logo.png'" alt="" />
        <div class="flex-column-center">
          <span 
            class="dance-school-name ellipsis-2" 
            #danceSchoolName 
            [matTooltip]="danceSchoolName.offsetHeight < danceSchoolName.scrollHeight ? danceSchool.name : null" 
            [matTooltipClass]="'fontSize14'"
          >
            <b>{{ danceSchool.name }}</b>
          </span>
          <span 
            *ngIf="danceSchool.name.trim() !== location.name.trim()" 
            class="location-name ellipsis-1" 
            #locationName 
            [matTooltip]="locationName.offsetHeight < locationName.scrollHeight ? location.name : null" 
            [matTooltipClass]="'fontSize14'"
          >
            {{ location.name ?? 'Location name' }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex-column-space-between" style="flex: 1 1 auto;">
      <div class="flex-column" style="height: 100%; gap: 0.5rem;">
        <ng-container *ngIf="location.previewImage && location.previewImage.url">
          <img [src]="location.previewImage?.url" [alt]="location.previewImage?.alt" [title]="location.previewImage?.title" alt="" class="previewImage" />
        </ng-container>
        <ng-container *ngIf="!location.previewImage">
          <img class="previewImage" [src]="'assets/images/DF_Danceschool.svg'" alt="Default Dance School Image" />
        </ng-container>
        <div class="mx2 my1 previewRoomDescription ellipsis-2">
          {{ danceSchool?.description }}
        </div>
      </div>
      <div class="mx2 mb1 flex-column">
        <div class="flex-row-end" style="grid-gap: 0.5rem; display: grid; flex-wrap: wrap-reverse">
          <span>
            <mat-icon class="material-icons-round vSub">place</mat-icon>
            21km
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
