import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UserDto, UserPassDto, UserPassStatusEnum } from '@platri/df-common-core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DfmStateService, UserHttpService, UserPassHttpService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { FreePassInviteDialogComponent } from '../../dialogs';

interface PassGuestListTableInterface {
  userId: string;
  email: string;
  userPasses: UserPassDto[];
}

@Component({
  selector: 'dfm-pass-guest-list-table',
  templateUrl: './pass-guest-list-table.component.html',
  styleUrls: ['./pass-guest-list-table.component.scss']
})
export class PassGuestListTableComponent implements OnInit, OnDestroy {
  @Input() passId: string;
  @Output() hasSoldPassesEmitter = new EventEmitter<boolean>();

  dataSource: MatTableDataSource<PassGuestListTableInterface>;
  
  displayedColumns: string[] = ['user', 'passType', 'stamps', 'validUntil', 'openChat'];

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  userPasses: UserPassDto[] = [];
  userIds: string[] = [];
  loadedUsers: { [key: string]: UserDto } = {};
  users: UserDto[] = [];
  
  isInitialized = false;
  
  data: PassGuestListTableInterface[] = [];

  activeChipIndex = 0;
  
  userPassStatusEnum = UserPassStatusEnum;

  private subscriptions: Subscription = new Subscription();
  
  constructor(
    private router: Router,
    private userPassHttpService: UserPassHttpService,
    private dfmStateService: DfmStateService,
    private userHttpService: UserHttpService,
    private matDialog: MatDialog
  ) {}
  
  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.subscriptions.add(
      this.userPassHttpService.getAllByPassId(this.passId, this.activeChipIndex === 0).subscribe({
        next: (userPasses) => {
          this.data = [];
          for (const obj of userPasses) {
            let index;
            if (obj.userId) {
              index = this.data.findIndex((a) => a.userId === obj.userId);
            } else {
              index = this.data.findIndex((a) => a.userId === null && a.email === obj.guestUserEmail);
            }
            if (index === -1) {
              this.data.push({
                userId : obj.userId ?? null,
                email: obj.guestUserEmail ?? null,
                userPasses: [obj]
              });
            } else {
              this.data[index].userPasses.push(obj);
            }
          }
          
          if (userPasses.length > 0) {
            this.hasSoldPassesEmitter.emit(true);
          }
          
          this.userPasses = userPasses;
          this.userIds = [...new Set(userPasses.filter((userPass) => userPass.userId !== null).map((userPass) => userPass.userId))];
          this.loadUsers();
          this.updateDataSource();
        }
      })
    );
  }
  
  loadUsers(): void {
    this.subscriptions.add(
      this.userHttpService.getUsersByIds(this.userIds, false, true).subscribe({
        next: (users) => {
          this.users = users;
          users.forEach((user) => {
            this.loadedUsers[user.id] = user;
          });
          this.isInitialized = true;
        }
      })
    );
  }

  passesColumnForUser(userId: string, userEmail: string): string {
    const getPassLabel = (userPass: UserPassDto): string | null => {
      const freeLabel = userPass.status === UserPassStatusEnum.FREE || userPass.pass.price === 0 ? ` (Free)` : ``;
      return `${userPass.userPassScans.length}/${userPass.pass.usageCount ?? userPass.userPassScans.length}${freeLabel}`;
    };
    
    const data = this.data.find((a) => userId ? a.userId === userId : a.email === userEmail);
    
    return data.userPasses.map(getPassLabel).filter((label): label is string => label !== null).join(",<br>");
  }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openChat(userId: string): void {
    if (userId) {
      const newUrl = `/dance-manager/${this.dfmStateService.selectedDm.value.id}/messages/${userId}?technicalUserId=${this.dfmStateService.selectedDm.value.id}`;
      this.router.navigateByUrl(newUrl);
    }
  }

  toggleChip(value: number): void {
    this.activeChipIndex = value;
    this.loadData();
  }

  openFreePassInviteDialog(): void {
    const dialogRef = this.matDialog.open(FreePassInviteDialogComponent, {
      minWidth: '75vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.loadData();
    });
  }
  
  getNumberOfPaidPasses(): number {
    return this.userPasses.filter((obj) => obj.status === UserPassStatusEnum.PAID).length;
  }

}
