<ng-container *transloco="let t">
  <mat-form-field
    class="w-100"
    *ngIf="!showChips; else withChips"
    appearance="outline"
  >
    <mat-label>{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</mat-label>
    <input
      [formControl]="searchDanceStyleInput"
      matInput
      autocomplete="off"
      #danceStyleInput
      id="dance-style-input"
      [matAutocomplete]="auto"
      (click)="matAutocompleteTrigger.openPanel()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectOption($event.option.value)"
      (closed)="resetFormControlValue()"
      hideSingleSelectionIndicator
    >
      <mat-option
        *ngFor="let danceStyle of filteredDanceStylesList"
        [disabled]="danceStyle | isDisabled: previouslySelectedOptions:true"
        [value]="danceStyle"
      >
        {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="searchDanceStyleInput.hasError('required')">{{ t('GENERIC_WRAPPER.CHOOSE_DANCE_STYLE') }}</mat-error>
  </mat-form-field>
  
  <ng-template #withChips>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{t('GENERIC_WRAPPER.DANCE_STYLES_TEXT')}}</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row 
          *ngFor="let selectedDanceStyle of selectedDanceStyles" (removed)="removeSelectedDanceStyle(selectedDanceStyle)">
            {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + selectedDanceStyle.translationKey.toUpperCase()) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="{{ t('GENERIC_WRAPPER.NEW_DANCE_STYLE') }}..."
          [formControl]="searchDanceStyleInput"
          matInput
          autocomplete="off"
          #danceStyleInput
          [matAutocomplete]="auto"
          (click)="matAutocompleteTrigger.openPanel()"
          [matChipInputFor]="chipList"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectOption($event.option.value)"
        (closed)="resetFormControlValue()"
        hideSingleSelectionIndicator>
        <mat-option 
          *ngFor="let danceStyle of filteredDanceStylesList" 
          [disabled]="danceStyle | isDisabled: previouslySelectedOptions:true"
          [value]="danceStyle">
            {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
        </mat-option>
      </mat-autocomplete>
    <mat-error *ngIf="searchDanceStyleInput.hasError('required')">{{ t('GENERIC_WRAPPER.CHOOSE_DANCE_STYLE') }}</mat-error>
    </mat-form-field>
  </ng-template>
</ng-container>
