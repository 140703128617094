<button
  *ngIf="isMatIconButton && matIconName"
  [class]="materialButtonStyleType === 'flat' ? 'flat' : 'outlined'"
  (click)="buttonClicked.emit()"
  [style]="{
    'border-radius': borderRadius,
    'background-color': '#3377FF !important',
    'color': 'white'
  }"
  mat-icon-button
  data-cy="mat-icon-button"
  color="primary">
    <mat-icon>{{matIconName}}</mat-icon>
</button>
<button
  *ngIf="!isMatIconButton && materialButtonStyleType === 'flat'"
  [style]="{
    width: '100%',
    'min-width': minWidth ?? 'unset',
    height: this.height,
    'border-radius': borderRadius
  }"
  class="df-button flat flex-row-center-center"
  mat-flat-button
  data-cy="mat-flat-button"
  color="primary"
  [disabled]="isDisabled"
  type="button"
  (click)="click()"
  [matTooltip]="matToolTipText ?? null"
  [matTooltipPosition]="'above'"
>
  <mat-icon *ngIf="matIconName && !loading">{{ matIconName }}</mat-icon>
  <span *ngIf="!loading" [ngStyle]="{'margin-left': matIconName ? '6px' : 0}">{{ buttonLabel }}</span>
  <mat-spinner *ngIf="loading" [diameter]="35"></mat-spinner>
</button>
<button
  *ngIf="!isMatIconButton && materialButtonStyleType === 'outlined'"
  [style]="{
    width: '100%',
    'min-width': minWidth ?? 'unset',
    height: this.height,
    'border-radius': borderRadius
  }"
  class="df-button outlined flex-row-center-center"
  mat-stroked-button
  data-cy="mat-stroked-button"
  color="primary"
  [disabled]="isDisabled"
  type="button"
  (click)="click()"
>
  <mat-icon *ngIf="matIconName && !loading">{{ matIconName }}</mat-icon>
  <span *ngIf="!loading" [ngStyle]="{'margin-left': matIconName && buttonLabel ? '6px' : 0}">{{ buttonLabel }}</span>
  <mat-spinner *ngIf="loading" [diameter]="35"></mat-spinner>
</button>
