import { PageMetaDto } from './page-meta.dto';

export class PageDto<T> {
  results: T[];
  pagination: PageMetaDto;
  totalElements: number;
  
  constructor(results: T[], pagination: PageMetaDto, totalElements: number) {
    this.results = results;
    this.pagination = pagination;
    this.totalElements = totalElements;
  }
}
