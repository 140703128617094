<ng-container *transloco="let t">
  <div class="flex-row" *ngIf="images?.length > 0 || files?.length > 0">
    <div class="flex-column align-center">
      <a
        class="prev"
        (click)="scrollLeft(carousel)"
      >&#10094;
      </a>
    </div>
    <div class="row flex-row" #carousel (touchstart)="swipeStart($event)"
         (touchend)="swipeEnd($event)">
      <mat-radio-group class="p1 flex-row" [formControl]="thumbnailControl" *ngIf="!editMode; else edit">
        <div class="tile" *ngFor="let image of images">
          <div class="flex-column align-center gap-20">
            <img
              #imageElement
              class="tile__img"
              [src]="image.url"
              alt=""
              [ngStyle]="image.url === thumbnailControl.value?.url ? {border: '5px solid #3692D7'} : {}"
              (click)="onImageClick(image, imageElement)"
            />
            <mat-radio-button color="primary" [value]="image" *ngIf="!onlyView"
                              [checked]="image.url === thumbnailControl.value.url"></mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
    <div class="flex-column align-center">
      <a
        class="next"
        (click)="scrollRight(carousel)"
      >&#10095;
      </a>
    </div>
  </div>

  <ng-template #edit>
    <mat-radio-group class="pl1 py1 flex-row" [formControl]="thumbnailControl">
      <div class="tile" *ngFor="let image of images">
        <div class="flex-column align-center gap-20">
          <div class="flex-column gap-10">
            <img
              #imageElement
              class="tile__img"
              [src]="image.url"
              alt=""
              [ngStyle]="image.url === thumbnailControl.value?.url ? {border: '5px solid #3692D7'} : {}"
              (click)="onImageClick(image, imageElement)"
            />
            <div class="flex-row align-center">
              <button mat-icon-button color="primary" (click)="onDeleteClick(image, true)" [matTooltip]="t('mat-icons.delete')">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </div>
          <mat-radio-button color="primary" [value]="image" *ngIf="!onlyView"
                            [checked]="image.url === thumbnailControl.value?.url"></mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
    <mat-radio-group class="pr1 py1 flex-row" [formControl]="fileControl">
      <div class="tile" *ngFor="let file of files">
        <div class="flex-column gap-20 align-center">
          <div class="flex-column gap-10">
            <img
              #imageElement
              class="tile__img"
              [src]="file?.base64"
              alt=""
              [ngStyle]="file?.base64 === fileControl.value?.base64 ? {border: '5px solid #3692D7'} : {}"
              (click)="onFileClick(file, imageElement)"
            />
            <div class="flex-row align-center">
              <button mat-icon-button color="primary" (click)="onDeleteClick(file)" [matTooltip]="t('mat-icons.delete')">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </div>
          <mat-radio-button color="primary" [value]="file" *ngIf="!onlyView"
                            [checked]="file?.base64 === fileControl.value?.base64 || file?.base64 === files[0].base64 && !thumbnailControl.value"></mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
  </ng-template>
</ng-container>
