import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogInterface, CourseInterface } from '@platri/df-common-core';
import { CoursesHttpService } from '../../services';
import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { TranslocoService } from '@jsverse/transloco';

export type CourseListItemBottomInformation = 'distance' | 'peopleEnrolled';

@Component({
  selector: 'df-course-list-item-card',
  templateUrl: './course-list-item-card.component.html',
  styleUrls: ['./course-list-item-card.component.scss']
})
export class CourseListItemCardComponent {
  currentLanguage: string;

  constructor(private readonly translocoService: TranslocoService, private readonly dialog: MatDialog, private readonly coursesHttpService: CoursesHttpService) {
    this.currentLanguage = this.translocoService.getActiveLang().toUpperCase();
  }

  @Input() course: CourseInterface;
  @Input() bottomInformation: CourseListItemBottomInformation = 'distance';
  @Input() isDm = false;
  @Input() smallWidth = false;
  @Output() clicked = new EventEmitter<void>();
  @Output() danceCourseDeleted = new EventEmitter<void>();
  @Input() isDmCard = false;

  deleteDanceCourse(): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COURSE_DIALOG.TITLE'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_COURSE_DIALOG.DESCRIPTION')
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.coursesHttpService.deleteCourseById(this.course.id).subscribe({
            next: () => {
              this.danceCourseDeleted.emit();
            }
          });
        }
      });
  }
}
