import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Observable, of, Subject} from "rxjs";
import * as _ from "lodash";
import { AmenitiesTypeEnum, AmenityInterface } from '@platri/df-common-core';
import { AmenityStateService } from '../../../services';

@Component({
  selector: 'df-monorepo-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss'],
})
export class AmenitiesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  @Input() amenitiesTypeRoom = false;
  @Input() locationAmenities: AmenityInterface[];
  @Input() amenities: AmenityInterface[];
  @Input() totalRoomNumber: number;
  @Output() updateData = new EventEmitter<AmenityInterface[]>();

  totalAmenitiesList: AmenityInterface[];
  clonedAmenitiesList: AmenityInterface[];
  filteredAmenitiesList: Observable<AmenityInterface[]>;
  filterInput: UntypedFormControl;
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly amenityService: AmenityStateService
  ) {}

  ngOnInit(): void {
    this.getAllAmenities();
    if (!this.amenities) {
      this.amenities = [];
    } else {
      this.filterSelectedAmenities();
    }
    this.sendLocationRoomsData();
    this.filterInput = this.formBuilder.control('');
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.amenities?.currentValue) {
      this.amenities = changes.amenities.currentValue;
      this.filterSelectedAmenities();
      this.getFilteredAmenities();
    }
  }

  getAllAmenities(): void {
    this.amenityService
      .getAsyncAmenities()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: AmenityInterface[]) => {
        if (res) {
          if (this.amenitiesTypeRoom) {
            this.totalAmenitiesList = res.filter(amenity => amenity.type === AmenitiesTypeEnum.ROOM);
          } else {
            this.totalAmenitiesList = res.filter(amenity => amenity.type === AmenitiesTypeEnum.LOCATION);
          }
          this.clonedAmenitiesList = _.cloneDeep(this.totalAmenitiesList);
          this.getFilteredAmenities();
        }
      });
  }

  getFilteredAmenities(): void {
    this.filteredAmenitiesList = of(this.clonedAmenitiesList);
    this.filterInput?.valueChanges
      .pipe(debounceTime(250))
      .subscribe((input: string) => {
        this.filteredAmenitiesList = of(
          this.clonedAmenitiesList.filter((amenity) =>
            amenity.iconKey.toLowerCase()
              .includes(input.toLowerCase())
          )
        );
      });
  }

  filterSelectedAmenities(): void {
    this.amenities.forEach((amenity) => {
      this.clonedAmenitiesList = this.clonedAmenitiesList?.filter(
        (filteredAmenity) => filteredAmenity.id !== amenity.id
      );
    });
    this.getFilteredAmenities();
  }

  public sendLocationRoomsData(): void {
    this.updateData.emit(this.amenities);
  }

  public addAmenity(amenity: AmenityInterface): void {
    if (this.amenities.find((amenityFromInput) => amenity.id === amenityFromInput.id)) {
      return;
    }
    this.amenities.push(amenity);
    this.filterSelectedAmenities();
    this.sendLocationRoomsData();
    this.filterInput.patchValue('');
  }

  public deleteAmenity(index: number): void {
    const deletedAmenity: AmenityInterface = this.amenities[index];
    this.amenities.splice(index, 1);
    if (this.amenitiesTypeRoom) {
      if (this.totalAmenitiesList.findIndex(amenity => amenity.id === deletedAmenity.id) !== -1) {
        this.clonedAmenitiesList.push(deletedAmenity);
      }
    } else {
      if (this.totalAmenitiesList.findIndex(amenity => amenity.id === deletedAmenity.id) !== -1) {
        this.clonedAmenitiesList.push(deletedAmenity);
      }
    }
    this.getFilteredAmenities();
    this.sendLocationRoomsData();
  }

  public onAmenitySelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.addAmenity(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
