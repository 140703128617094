import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthStateService, CheckoutCompleteErrorTextInterface, CoursesHttpService, dfCourseIdRouterParam, FooterStateService, getSubscription, PurchaseConfirmationTextInterface, TicketOrderService } from '@platri/dfx-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CourseInterface, UserPassSubscriptionInterface, UserPassSubscriptionStatusEnum, UsersInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-user-pass-subscription-checkout-complete',
  templateUrl: './user-pass-subscription-checkout-complete.page.html',
  styleUrls: ['./user-pass-subscription-checkout-complete.page.scss']
})
export class UserPassSubscriptionCheckoutCompletePage implements OnInit, OnDestroy {
  currentLang: string;
  currentUser: UsersInterface;

  userPassSubscription: UserPassSubscriptionInterface | null;

  isMobileResponsive: boolean;
  showMobileSummary: boolean;

  subscriptions: Subscription = new Subscription();

  userPassSubscriptionStatusEnum = UserPassSubscriptionStatusEnum;

  confirmationText: PurchaseConfirmationTextInterface;
  errorText: CheckoutCompleteErrorTextInterface;

  routerParams!: Params;
  courseIdFromParam: string;
  course: CourseInterface;

  constructor(
    private translocoService: TranslocoService,
    private readonly ticketOrderService: TicketOrderService, 
    private readonly router: Router, 
    private readonly breakpointObserver: BreakpointObserver, 
    private readonly footerService: FooterStateService, 
    private readonly authService: AuthStateService, 
    private readonly activatedRoute: ActivatedRoute, 
    private readonly coursesHttpService: CoursesHttpService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
    this.currentUser = this.authService.getSyncCurrentUser();
    this.setConfirmationText();
    this.setErrorText();

    this.subscriptions.add(
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result: BreakpointState) => {
        this.isMobileResponsive = result.matches;
        this.footerService.showFooter$.next(!result.matches);
        if (this.showMobileSummary && !result.matches) {
          this.showMobileSummary = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.getUserPassSubscriptionFromRouteResolver();
  }

  getUserPassSubscriptionFromRouteResolver(): void {
    this.userPassSubscription = this.activatedRoute.snapshot.data.targetObject;
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.courseIdFromParam = this.routerParams[dfCourseIdRouterParam];
    this.loadCourse();
  }

  loadCourse(): void {
    this.subscriptions.add(
      this.coursesHttpService.getById(this.courseIdFromParam).subscribe((course) => {
        if (course) {
          this.course = course;
        }
      })
    );
  }

  // download(): void {
  //   const ticketWindow = window.open();
  //   ticketWindow.document.write('Loading...');
  //
  //   this.ticketOrderHttpService.download(this.ticketOrder.id)
  //     .subscribe({
  //       next: (blob) => {
  //         const url = URL.createObjectURL(blob);
  //         ticketWindow.location.href = url;
  //       },
  //       error: (err) => {
  //         console.error(err);
  //         ticketWindow.close();
  //       },
  //     });
  // }

  ngOnDestroy(): void {
    this.ticketOrderService.clear();
    this.subscriptions.unsubscribe();
  }

  private setConfirmationText(): void {
    const currentUser = this.authService.getSyncCurrentUser();
    const email = currentUser?.email;
    this.confirmationText = {
      title: 'GENERIC_WRAPPER.THANKS_FOR_ORDER',
      message: 'DANCE_COURSE.SUBSCRIPTION_CHECKOUT.TEXT',
      messageParams: { email },
      navButton: {
        label: 'DANCE_COURSE.SUBSCRIPTION_CHECKOUT.BUTTON',
        navUrl: '/inventory#subscriptions'
      }
    };
  }

  private setErrorText(): void {
    this.errorText = {
      title: 'GENERIC_WRAPPER.SOMETHING_WENT_WRONG_SHORT',
      text: 'GENERIC_WRAPPER.PASSES_CHECKOUT.ERROR_TEXT',
      navButton: {
        label: 'GENERIC_WRAPPER.PASSES_CHECKOUT.TO_COURSE',
        navUrl: '/dance-class/' + this.activatedRoute.snapshot.params[dfCourseIdRouterParam]
      }
    };
  }
}
