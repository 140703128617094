import { Component } from '@angular/core';

@Component({
  selector: 'df-payment-methods-view',
  templateUrl: './payment-methods-view.component.html',
  styleUrls: ['./payment-methods-view.component.scss']
})
export class PaymentMethodsViewComponent {
  
  paymentMethods = ['visa', 'master_card', 'paypal', 'sepa', 'sofort', 'klarna', 'giropay', 'ideal'];
  
}
