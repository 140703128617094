import { Component } from '@angular/core';
import { dfAppRoute } from '@platri/dfx-angular-core';
import { filter, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getSubscription } from '@platri/elab-angular-core';

@Component({
  selector: 'elab-standalone-app-root',
  templateUrl: './standalone-app.component.html',
  styleUrls: ['./standalone-app.component.scss']
})
export class StandaloneAppComponent {
  
  title = 'wrapper';
  loadedRoute = false;

  subscriptions: Subscription = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions()
    this.onActivatedRouteChanges();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.router.events.pipe(filter(event => event instanceof NavigationEnd)), this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(): void {
    const activeRoute = this.findActiveRoute(this.activatedRoute);
    this.loadedRoute = activeRoute.snapshot.url?.length >= 1;

  }

  private findActiveRoute(route: ActivatedRoute): ActivatedRoute {
    if (route.firstChild) {
      return this.findActiveRoute(route.firstChild);
    }
    return route;
  }
  
  protected readonly dfAppRoute = dfAppRoute;
}
