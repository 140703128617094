<ng-container *transloco="let t">
  <div class="my-40 pf-settings-outside-wrapper-mobile-view" *ngIf="!(loading | async)">
    <mat-accordion>
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('GENERIC_WRAPPER.NAME') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            {{ currentUser.firstName + ' ' + currentUser.lastName }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <df-name-form-settings
            [currentUser]="currentUser"
            (nameSaved)="updateUser($event)"
            (closeEditMode)="setStep(2)"
          >
          </df-name-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.USERNAME') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            {{ currentUser.username }}
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-username-form-settings
            [currentUser]="currentUser"
            (userNameSaved)="updateUser($event)"
            (closeEditMode)="setStep(3)"
          ></df-username-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.HEIGHT') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div>
              <span *ngIf="currentUser.height">{{
                currentUser.height + 'cm'
              }}</span>
            </div>
            <div *ngIf="!currentUser.height">
              {{ t('USER.HEIGHT_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-height-form-settings
            [currentUser]="currentUser"
            (heightSaved)="updateUser($event)"
            (closeEditMode)="setStep(4)"
          ></df-height-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.GENDER') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.gender">
              <span>{{t('USER.GENDER_ENUM.' + currentUser.gender.toUpperCase()) }}</span
              >&nbsp;
            </div>
            <div *ngIf="!currentUser.gender">
              {{ t('USER.GENDER_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-gender-form-settings
            [currentUser]="currentUser"
            (genderSaved)="updateUser($event)"
            (closeEditMode)="setStep(5)"
          ></df-gender-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.DATE_OF_BIRTH') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.birthday">
              {{
                currentUser.birthday | date: 'mediumDate':null
              }}
            </div>
            <div *ngIf="!currentUser.birthday">
              {{ t('USER.DATE_OF_BIRTH_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-birth-date-form-settings
            [currentUser]="currentUser"
            (birthDateSaved)="updateUser($event)"
            (closeEditMode)="setStep(6)"
          ></df-birth-date-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.DANCE_AREA') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.dancingArea">
              {{ currentUser.dancingArea }}
            </div>
            <div *ngIf="!currentUser.dancingArea">
              {{ t('USER.DANCE_AREA_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-dance-area-form-settings
            [currentUser]="currentUser"
            (dancingAreaSaved)="updateUser($event)"
            (closeEditMode)="setStep(7)"
          ></df-dance-area-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel
        [expanded]="step === 7"
        (opened)="setStep(7)"
        style="overflow: visible"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('GENERIC_WRAPPER.DESCRIPTION') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.description">
              <div
                [innerHTML]="currentUser.description | slice: 0:50"
                class="hide-p-margin"
              ></div>
              <ng-container *ngIf="currentUser.description.length > 50"
                >...
              </ng-container>
            </div>
            <div *ngIf="!currentUser.description">
              {{ t('USER.DESCRIPTION_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-description-form-settings
            [currentUser]="currentUser"
            (descriptionSaved)="updateUser($event)"
            (closeEditMode)="setStep(8)"
          ></df-description-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 8" (opened)="setStep(8)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.YEARS_DANCING') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.monthsDancing">
              {{ currentUser.monthsDancing | transformMonthYearLabel: false }}
            </div>
            <div *ngIf="!currentUser.monthsDancing">
              {{ t('USER.YEARS_DANCING_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-months-dancing-form-settings
            [currentUser]="currentUser"
            (monthsDancingSaved)="updateUser($event)"
            (closeEditMode)="setStep(9)"
          ></df-months-dancing-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 9" (opened)="setStep(9)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.DANCE_INTERVAL') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.danceInterval">
              {{ t('USER.DANCE_INTERVAL_ENUM.' + currentUser.danceInterval) }}
            </div>
            <div *ngIf="!currentUser.danceInterval">
              {{ t('USER.DANCE_INTERVAL_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-dance-interval-form-settings
            [currentUser]="currentUser"
            (danceIntervalSaved)="updateUser($event)"
            (closeEditMode)="setStep(10)"
          ></df-dance-interval-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 10" (opened)="setStep(10)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div
              *ngIf="currentUser?.danceStyles?.length > 0"
              class="ellipsis-1"
            >
              <span *ngFor="let danceStyle of currentUser.danceStyles; let last = last">
                {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}{{ last ? '' : ', ' }}
              </span>
            </div>
            <div *ngIf="!currentUser || !currentUser.danceStyles || currentUser.danceStyles.length === 0">
              {{ t('USER.DANCE_STYLES_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <df-shared-lib-dance-style-edit
            *ngIf="step === 10"
            [danceStylesToEdit]="currentUser.danceStyles" (closeEditMode)="setStep(11)"
          ></df-shared-lib-dance-style-edit>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 11" (opened)="setStep(11)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.LANGUAGES') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div
              *ngIf="
                currentUser &&
                currentUser.languageSkills &&
                currentUser.languageSkills.length > 0
              "
            >
              <span *ngFor="let language of currentUser.languageSkills">
                {{t('GENERIC_WRAPPER.PROFILE_LANGUAGE.' + language.key.toUpperCase()) }}&nbsp;
              </span>
            </div>
            <div
              *ngIf="
                !currentUser ||
                !currentUser.languageSkills ||
                currentUser.languageSkills.length === 0
              "
            >
              {{ t('USER.LANGUAGES_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <df-language-edit
            [user]="currentUser"
            (closeEditMode)="setStep(12)"
          ></df-language-edit>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 12" (opened)="setStep(12)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="ellipsis-1">
          {{ t('USER.LOOKING_FOR_TEXT') }}
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div *ngIf="currentUser && currentUser.lookingFor && currentUser.lookingFor.length > 0">
          <span *ngFor="let lookingFor of currentUser.lookingFor; let isLast = last">
            {{ lookingFor }}{{ isLast ? '' : ', ' }}
          </span>
        </div>
        <div *ngIf="!currentUser || !currentUser.lookingFor || currentUser.lookingFor.length === 0">
          {{ t('USER.LOOKING_FOR_SUBTEXT') }}
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <df-monorepo-looking-for-edit
        [user]="currentUser"
        (closeEditMode)="setStep(13)"
      ></df-monorepo-looking-for-edit>
    </ng-template>
  </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 13" (opened)="setStep(13)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.JOB') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.jobTitle">
              {{ currentUser.jobTitle }}
            </div>
            <div *ngIf="!currentUser.jobTitle">
              {{ t('USER.JOB_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-job-form-settings
            [currentUser]="currentUser"
            (jobSaved)="updateUser($event)"
            (closeEditMode)="setStep(14)"
          ></df-job-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 14" (opened)="setStep(14)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="ellipsis-1">
              {{ t('USER.PLACE_OF_ORIGIN') }}
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.formerPlace">
              {{ currentUser.formerPlace }}
            </div>
            <div *ngIf="!currentUser.formerPlace">
              {{ t('USER.PLACE_OF_ORIGIN_SUBTEXT') }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-template matExpansionPanelContent>
          <df-from-form-settings
            [currentUser]="currentUser"
            (userSaved)="updateUser($event)"
            (closeEditMode)="setStep(15)"
          ></df-from-form-settings>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel [expanded]="step === 15" (opened)="setStep(15)" (closed)="setStep(0)">
        <mat-expansion-panel-header>
          <mat-panel-title><div class="ellipsis-1">{{ t('USER.SOCIAL_LINKS') }}</div></mat-panel-title>
  
        <mat-panel-description>
          <div *ngFor="let linkTypes of currentUser.socialLinks; let last = last">
            {{ linkTypes.type | titlecase}}{{ last ? '' : ',&nbsp;' }}
          </div>
          <div *ngIf="currentUser?.socialLinks?.length === 0">
            {{ t('USER.ADD_LINK') }}
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
         <df-shared-lib-user-social-links-edit
           *ngIf="step === 15"
           [currentUser]="currentUser"
           (saveClick)="updateUser($event)"
           (closeEditMode)="setStep(15)">
        </df-shared-lib-user-social-links-edit>
        <div *ngIf="step === 15 && currentUser?.socialLinks?.length > 0">
          <h3>{{ t('USER.MY_LINKS') }}</h3>
          <df-shared-lib-social-links
            [currentUser]="currentUser"
            (saveClick)="updateUser($event)"
          ></df-shared-lib-social-links>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
