import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfLocationsCardComponent } from './df-locations-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslocoModule } from '@jsverse/transloco';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { PipesModule } from '../../pipes';
import { EmailLinkModule, PhoneLinkModule } from '../contact-links';

@NgModule({
  declarations: [DfLocationsCardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    PipesModule,
    PhoneLinkModule,
    EmailLinkModule,
    MatDividerModule,
    GoogleMapsModule,
    TranslocoModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule
  ],
  exports: [DfLocationsCardComponent],
})
export class DfLocationsCardModule {}
