import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AuthStateService,
  dfAppRoute,
  dfLoginRoute,
  dfMagicLinkLoginTokenRouterParam
} from '@platri/dfx-angular-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'elab-magic-link-login-token-page',
  templateUrl: './magic-link-login-token-page.component.html',
  styleUrls: ['./magic-link-login-token-page.component.scss']
})
export class MagicLinkLoginTokenPageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription =  new Subscription();

  isWaitingForMagicLinkLoginResponse = false;

  magicLinkLoginFailedWrongData = false;
  magicLinkLoginFailedNoConnection = false;
  magicLinkLoginFailedInternalServer = false;

  hasSuccessfullyMagicLinkLogin = false;
  
  routerParams!: Params;
  returnUrlFromParam: string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private authService: AuthStateService,
    private translocoService: TranslocoService
) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[dfMagicLinkLoginTokenRouterParam]) {
      this.router.navigate([dfAppRoute, dfLoginRoute]);
    } else {
      if (this.activatedRoute.snapshot.queryParams['returnUrl']) {
        this.returnUrlFromParam = this.activatedRoute.snapshot.queryParams['returnUrl'];
      }
      this.magicLinkLogin();
    }
  }
  
  magicLinkLogin(): void {
    this.isWaitingForMagicLinkLoginResponse = true;
    this.magicLinkLoginFailedWrongData = false;
    this.magicLinkLoginFailedNoConnection = false;
    this.subscriptions.add(this.authService.loginWithMagicLink(this.routerParams[dfMagicLinkLoginTokenRouterParam]).subscribe(res => {
      this.hasSuccessfullyMagicLinkLogin = true;
      this.isWaitingForMagicLinkLoginResponse = false;
      if (this.returnUrlFromParam) {
        this.router.navigateByUrl(this.returnUrlFromParam);
      } else {
        this.router.navigate([dfAppRoute]);
      }
      this.matSnackbar.open(this.translocoService.translate('GENERIC_WRAPPER.MAGIC_LINK_LOGIN_SUCCESSFUL'));
    }, error => {
      console.log(error);
      if (error.status === 0) {
        this.onConnectionLost();
      }
      this.isWaitingForMagicLinkLoginResponse = false;
    }));
  }

  onConnectionLost(): void {
    (this.magicLinkLoginFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.magicLinkLoginFailedInternalServer = true;
  }
  

}
