import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { wait } from '@platri/df-common-core';

@Component({
  selector: 'elab-shared-link-clipboard',
  templateUrl: './df-link-clipboard.component.html',
  styleUrls: ['./df-link-clipboard.component.scss']
})
export class DfLinkClipboardComponent{
  
  @Input() textToCopy = '';
  @Input() successMessage = 'GENERIC_WRAPPER.COPIED';
  
  copyClicked = false;
  
  constructor(
    private readonly clipboard: Clipboard
  ) {}
  
  copy(): void {
    this.clipboard.copy(this.textToCopy);
    this.copyClicked = true;
    wait(1500).then(() => {
      this.copyClicked = false;
    });
  }
  
  
}
