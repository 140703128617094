import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { CourseAppointmentInterface, CourseAppointmentStatusEnum, CourseInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';
import { CoursesHttpService, dfAppRoute, dfCourseIdRouterParam, DmPassSubscriptionHttpService, getSubscription, PassHttpService, SeoService } from '@platri/dfx-angular-core';
import { DateTime } from 'luxon';

@Component({
  selector: 'df-course-page',
  templateUrl: './course.page.html',
  styleUrls: ['./course.page.scss']
})
export class CoursePage implements OnInit, OnDestroy {
  course: CourseInterface;
  appointment: CourseAppointmentInterface | null;
  isCourseActive: boolean;
  passMinPrice = -1;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  routerParams!: Params;
  courseIdFromParam: string;

  private subscriptions: Subscription = new Subscription();
  isWaitingForResponse: boolean;
  hasErrorLoadingCoursesDetails: boolean;
  isInitialized: boolean;

  constructor(
    private coursesHttpService: CoursesHttpService, 
    private passHttpService: PassHttpService,
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private dmPassSubscriptionHttpService: DmPassSubscriptionHttpService
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.courseIdFromParam = this.routerParams[dfCourseIdRouterParam];
    if (!this.courseIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }

    this.loadData();
  }

  loadData(): void {
    this.hasErrorLoadingCoursesDetails = false;
    this.isWaitingForResponse = true;
    this.subscriptions.add(
      this.coursesHttpService.getById(this.courseIdFromParam, true).subscribe(course => {
        this.course = course;
        this.appointment = this.course?.appointments && this.course?.appointments.length > 0 ? this.course.appointments[0] : null;
        if (this.course?.appointments.length > 0) {
          this.appointment = this.course.appointments[0];
          this.doAppointmentChecks();
          this.loadMinPrice();
        }
        this.setSeoData();
        this.isWaitingForResponse = false;
        this.isInitialized = true;
      }, error => {
        this.hasErrorLoadingCoursesDetails = true;
        //this.router.navigate(['/404']);
        this.isWaitingForResponse = false;
      })
    );
  }

  doAppointmentChecks(): void {
    if (this.appointment) {
      this.isCourseActive = (this.appointment.status === CourseAppointmentStatusEnum.UPCOMING || this.appointment.status === CourseAppointmentStatusEnum.RUNNING);
    }
  }
  
  loadMinPrice(): void {
    forkJoin([this.passHttpService.getCourseMinPriceById(this.course.id), this.dmPassSubscriptionHttpService.getMinPriceByTargetId(this.course.id)])
      .subscribe({
        next: (response: [passMinPrice: number, dmPassSubscriptionMinPrice: number]) => {
          this.passMinPrice = response[0] > -1 && response[1] > -1 ? Math.min(response[0], response[1])
            : response[0] > -1 ? response[0] : response[1] > -1
                ? response[1] : -1;
        }
      });
  }

  setSeoData(): void {
    this.seoService.setPageTitle(`${this.course.name} - Danceflavors`);
    this.seoService.setMetaTagProperty('og:title', this.course.name);
    if (this.course.description) {
      this.seoService.setMetaTagName('description', this.course.description.length > 155 ? this.stripHtmlTags(this.course.description.substring(0, 155) + '...') : this.stripHtmlTags(this.course.description));
      this.seoService.setMetaTagProperty('og:description', this.stripHtmlTags(this.course.description));
    }
    if (this.course.mainImage?.url) {
      this.seoService.setMetaTagProperty('og:image', this.course.mainImage?.url);
    }
    this.seoService.setMetaTagProperty('og:url', window.location.href);
    this.setStructuredDataForDanceEvent();
  }

  setStructuredDataForDanceEvent(): any {
    const nextAppointmentDate = this.course.nextAppointmentDate;
    if (nextAppointmentDate) {
      const startDate = DateTime.fromJSDate(new Date(nextAppointmentDate)).setZone('UTC');
      let endDate = startDate;
      if (this.course.nextAppointmentDuration > 0) {
        endDate = endDate.plus({
          minute: this.course.nextAppointmentDuration
        });
      }
      const structuredData: any = [
        {
          '@context': 'https://schema.org',
          '@type': 'Event',
          name: this.course?.name ?? '',
          startDate: startDate?.toString() ?? '',
          endDate: endDate?.toString() ?? '',
          eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
          eventStatus: 'https://schema.org/EventScheduled',
          location: {
            '@type': 'Place',
            name: this.course?.appointments[0]?.address?.name ?? '',
            address: {
              '@type': 'PostalAddress',
              streetAddress: this.course?.appointments[0]?.address?.formattedAddress?.split(',')[0] ?? '',
              addressLocality: this.course?.appointments[0]?.address?.city ?? '',
              postalCode: this.course?.appointments[0]?.address?.zipCode ?? '',
              addressCountry: this.course?.appointments[0]?.address?.country ?? ''
            }
          },
          image: [this.course.mainImage?.url ?? ''],
          description: this.course?.description ?? '',
          organizer: {
            '@type': 'Organization',
            name: this.course?.name ?? '',
            url: new URL(window.location.href).origin + '/app/@' + this.course?.danceManager?.urlName
          }
        }
      ];
      this.seoService.updateStructuredData(structuredData);
    }
  }

  stripHtmlTags(html: string): string {
    html = html.replace(/<br\s*\/?>/gi, ' ')
      .replace(/<\/?p>/gi, ' ')
      .replace(/<\/?div>/gi, ' ');
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  ngOnDestroy(): void  {
    this.subscriptions.unsubscribe();
    this.seoService.resetAll();
  }
}
