import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DfmGeneralSettingsComponent } from './dfm-general-settings.component';
import { ConfirmationDialogModule, DfQuillEditorModule, DfSharedButtonModule } from '@platri/dfx-angular-core';
import { MatButtonModule } from '@angular/material/button';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmGeneralSettingsComponent],
    imports: [
      CommonModule,
      MatInputModule,
      ConfirmationDialogModule,
      DfQuillEditorModule,
      DfSharedButtonModule,
      ReactiveFormsModule,
      MatButtonModule,
      TranslocoDirective
    ],
  exports: [DfmGeneralSettingsComponent],
})
export class DfmGeneralSettingsModule {}
