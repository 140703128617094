import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@platri/dfx-angular-core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DfmEventTicketListComponent } from './dfm-event-ticket-list.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmEventTicketListComponent],
  exports: [DfmEventTicketListComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    CdkStepperModule,
    CdkScrollableModule,
    DragDropModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    MatExpansionModule,
    TranslocoDirective
  ],
})
export class DfmEventTicketListModule {}
