import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfmPayoutDetailPageComponent } from './dfm-payout-detail-page.component';
import { DfSharedButtonModule, PipesModule } from '@platri/dfx-angular-core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import {TranslocoDirective} from "@jsverse/transloco";

const routes: Routes = [
  {
    path: '',
    component: DfmPayoutDetailPageComponent,
    data: { breadcrumb: { alias: 'id' } },
  },
];

@NgModule({
  declarations: [DfmPayoutDetailPageComponent],
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule.forChild(routes),
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        DfSharedButtonModule,
        PipesModule,
        MatTableModule,
        MatIconModule,
        BreadcrumbComponent,
        BreadcrumbItemDirective,
        TranslocoDirective
    ]
})
export class DfmPayoutDetailPageModule {}
