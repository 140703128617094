import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AddressInterface, AppointmentsInterface, CourseInterface, DanceManagerUserRoleEnum, DanceSchoolInterface, LocationInterface, TimeOperations, UsersInterface } from '@platri/df-common-core';
import { CoursesHttpService, CoursesStateService, dfmDanceManagerRoute, DanceSchoolStateService, dfAppRoute, dfmCourseRoute, getSubscription } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { addMinutes, differenceInMinutes, getHours, getMinutes } from 'date-fns';
import { DateTime } from 'luxon';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'df-course-edit-appointment-page',
  templateUrl: './course-edit-appointment-page.component.html',
  styleUrls: ['./course-edit-appointment-page.component.scss']
})
export class CourseEditAppointmentPageComponent implements OnInit, OnDestroy, AfterContentInit {
  danceManager: DanceSchoolInterface;
  course: CourseInterface;
  
  today = new Date();
  
  subscriptions = new Subscription();

  startTime: string;
  endTime: string;
  endTimeIsLater: boolean;

  routerParams!: Params;
  appointmentIdFromParam: string;

  isInitialized = false;
  isLoadingAppointment = false;
  isWaitingForEditAppointmentResponse = false;
  hasFailedLoadingAppointmentNoConnection = false;
  
  editFailedNoConnection = false;
  editFailedInternalServer = false;
  
  courseEditAppointmentFormGroup: FormGroup;
  
  teachers: UsersInterface[] = [];
  loadedAppointment: AppointmentsInterface;
  
  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private fb: FormBuilder, 
    private breadcrumbService: BreadcrumbService, 
    private activatedRoute: ActivatedRoute, 
    private coursesService: CoursesStateService, 
    private danceSchoolService: DanceSchoolStateService,
    private coursesHttpService: CoursesHttpService,
    private matSnackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    //this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.courseEditAppointmentFormGroup = this.createCourseEditAppointmentFormGroup();
    this.courseEditAppointmentFormGroup.disable();

    this.courseEditAppointmentFormGroup.valueChanges.subscribe((data) => {
      if(data.startTime !== null && data.endTime !== null) {
        this.endTimeIsLater = this.calculateEndTimeIsLater(data.startTime, data.endTime);
      }
    });
  }
  
  calculateEndTimeIsLater(startTime: string, endTime: string): boolean {
    function parseTime(time: string): Date {
      const [hours, minutes] = time.split(':').map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    }

    const startDate = parseTime(startTime);
    const endDate = parseTime(endTime);

    return endDate > startDate;
  }
  
  createCourseEditAppointmentFormGroup(): FormGroup {
    return this.fb.group({
      startDate: this.fb.control(null, [Validators.required]),
      duration: this.fb.control(null),
      description: this.fb.control(null, [Validators.maxLength(1000)]),
      teacherUserIds: this.fb.control(null, [Validators.required]),
      maxParticipants: this.fb.control(1, [Validators.required, Validators.min(1)]),
      locationId: this.fb.control(null),
      address: this.fb.control(null, [Validators.required]),
      startTime: this.fb.control(null, [Validators.required]),
      endTime: this.fb.control(null, [Validators.required]),
      addressSelectionType: this.fb.control('location')
    });
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.appointmentIdFromParam = this.routerParams['appointmentId'];
    if (!this.appointmentIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
  }
  
  loadData(): void {
    this.loadCourseData();
    this.loadDanceManagerData();

    this.subscriptions.add(
      this.coursesHttpService.getAppointmentById(this.appointmentIdFromParam).subscribe(courseAppointment => {
        this.loadedAppointment = courseAppointment;
        this.patchEditAppointmentFormGroup(courseAppointment);
        this.isLoadingAppointment = false;
        this.courseEditAppointmentFormGroup.enable();
      }, error => {
        console.log(error);
        this.isLoadingAppointment = false;
        if (error.status === 0) {
          this.onLoadAppointmentConnectionLost();
        }
      })
    );
  }

  patchEditAppointmentFormGroup(appointment: AppointmentsInterface): void {
    const startDate = new Date(appointment.date);
    startDate.setTime(startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000);
    const startTime = {
      hours: getHours(startDate),
      minutes: getMinutes(startDate)
    };
    const endDate = addMinutes(startDate, appointment.duration);
    const endTime = {
      hours: getHours(endDate),
      minutes: getMinutes(endDate)
    };
    
    this.courseEditAppointmentFormGroup.patchValue({
      ...appointment,
      startDate,
      startTime: TimeOperations.timeToString(startTime),
      endTime: TimeOperations.timeToString(endTime),
      addressSelectionType: appointment.location ? 'location' : 'manual',
      teacherUserIds: appointment.teachers?.map((teacher) => teacher.id),
      locationId: appointment.location ? appointment.location.id : null
    });

    if (this.danceManager.locations.length === 0) {
      this.courseEditAppointmentFormGroup.controls.addressSelectionType.setValue('manual');
    }
  }

  onLoadAppointmentConnectionLost(): void {
    this.hasFailedLoadingAppointmentNoConnection = true;
  }
  
  loadCourseData(): void {
    this.course = this.activatedRoute.snapshot.data.targetObject;
    this.breadcrumbService.set('@courseName', this.course.name);
  }

  loadDanceManagerData(): void {
    this.danceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
    this.teachers = this.danceManager.danceSchoolToUsers
      .filter((danceSchoolUser) => [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN, DanceManagerUserRoleEnum.TEACHER].includes(danceSchoolUser.danceSchoolUserRole))
      .map((danceSchoolUser) => danceSchoolUser.user);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onLocationSelected(location: LocationInterface): void {
    this.courseEditAppointmentFormGroup.controls.address.setValue(location.address);
    this.courseEditAppointmentFormGroup.controls.locationId.setValue(location.id);
  }

  onAddressSelected(address: AddressInterface): void {
    this.courseEditAppointmentFormGroup.controls.address.setValue(address);
    this.courseEditAppointmentFormGroup.controls.locationId.setValue(null);
    this.courseEditAppointmentFormGroup.controls.locationId.reset();
  }

  calculateDurationOfTimes(startTime: string, endTime: string): number {
    const startDateTime = new Date();
    startDateTime.setHours(+(startTime.split(':')[0]));
    startDateTime.setMinutes(+(startTime.split(':')[1]));
    startDateTime.setSeconds(0);
    const endDateTime = new Date();
    endDateTime.setHours(+(endTime.split(':')[0]));
    endDateTime.setMinutes(+(endTime.split(':')[1]));
    endDateTime.setSeconds(0);
    return differenceInMinutes(endDateTime, startDateTime);
  }
  
  onCancelClick(): void {
    this.navigateToCoursePreview();
  }
  
  navigateToCoursePreview(): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmCourseRoute, 'preview', this.course.id], {fragment: 'appointments'});
  }
  
  changeAddressInput(addressInputType: string): void {
    if (addressInputType) {
      this.courseEditAppointmentFormGroup.controls.addressSelectionType.setValue(addressInputType);
      if (addressInputType === 'location') {
        this.courseEditAppointmentFormGroup.patchValue({
          locationId: this.danceManager.locations[0].id
        });
      }
    }
  }

  compareById(userA: any, userB: any): boolean {
    return userA?.id === userB?.id;
  }

  onSubmit(): void {
    if (this.courseEditAppointmentFormGroup.valid) {
      this.isWaitingForEditAppointmentResponse = true;
      this.editFailedNoConnection = false;
      this.editFailedInternalServer = false;
      const data = this.courseEditAppointmentFormGroup.getRawValue();
      
      const duration = this.calculateDurationOfTimes(data.startTime, data.endTime);
      const combinedDay = DateTime.now()
        .setZone('UTC')
        .set({
          day: new Date(data.startDate).getDate(),
          month: new Date(data.startDate).getMonth() + 1,
          year: new Date(data.startDate).getFullYear(),
          hour: TimeOperations.stringToTime(data.startTime).hours,
          minute: TimeOperations.stringToTime(data.startTime).minutes,
          second: 0,
          millisecond: 0
        })
        .toJSDate();
      
     this.subscriptions.add(this.coursesHttpService.updateCourseAppointmentById(this.appointmentIdFromParam, {
       ...data,
       date: combinedDay,
       duration
     }).subscribe(() => {
        this.matSnackBar.open("Successfully edited area!");
        this.navigateToCoursePreview();
        this.isWaitingForEditAppointmentResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForEditAppointmentResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.editFailedNoConnection) = true;
  }

  onInternalServerError(): void {
    this.editFailedInternalServer = true;
  }

}
