import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '../../pipes';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { CourseSearchListItemCardAdComponent } from './course-search-list-item-card-ad.component';
import { DfSharedButtonModule } from '../buttons';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [CourseSearchListItemCardAdComponent],
  exports: [CourseSearchListItemCardAdComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatChipsModule,
    RouterModule,
    DfSharedButtonModule,
    TranslocoDirective,
  ]
})
export class CourseSearchListItemAdModule {}
