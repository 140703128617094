import { Component, Input } from '@angular/core';
import { CourseInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-dance-manager-course-list',
  templateUrl: './dance-manager-course-list.component.html',
  styleUrls: ['./dance-manager-course-list.component.scss']
})
export class DanceManagerCourseListComponent {
  @Input() courses: CourseInterface[];
}
