import { OpenHoursDialogComponent } from './open-hours-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PipesModule } from '../../pipes';
import { DfSharedButtonModule } from '../buttons';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [OpenHoursDialogComponent],
  exports: [OpenHoursDialogComponent],
    imports: [
      CommonModule,
      FormsModule,
      MatSlideToggleModule,
      MatInputModule,
      MatIconModule,
      MatButtonModule,
      PipesModule,
      MatTooltipModule,
      DfSharedButtonModule,
      MatFormFieldModule,
      TranslocoDirective,
    ],
})

export class OpenHoursModule {}
