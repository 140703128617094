import { Directive, HostListener, Input } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[imageFallback]',})
export class ImageFallbackDirective  {
  @Input() fallbackSrc: string;
  @HostListener('error', ['$event'])
  onProfileImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    
    if (imgElement.src !== this.fallbackSrc) {
      imgElement.src = this.fallbackSrc;
      return;
    }
  }
  
}
