import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserProfileStateService } from '@platri/dfx-angular-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-profile-picture-dialog',
  templateUrl: './profile-picture-dialog.component.html',
  styleUrls: ['./profile-picture-dialog.component.scss']
})
export class ProfilePictureDialogComponent {
  subscription: Subscription = new Subscription();
  loading = false;

  constructor(private readonly userProfileService: UserProfileStateService, private readonly matDialogRef: MatDialogRef<ProfilePictureDialogComponent>) {}

  updateProfilePicture(formData: FormData): void {
    this.loading = true;
    this.subscription.add(
      this.userProfileService.updateProfilePicture(formData).subscribe((updatedUser) => {
        if (updatedUser) {
          this.matDialogRef.close(updatedUser);
          this.loading = false;
        }
      })
    );
  }
}
