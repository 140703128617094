import { Pipe, PipeTransform } from '@angular/core';
import { TicketSaleQuantityInformationInterface } from '@platri/df-common-core';

@Pipe({
  name: 'userTicketCollapsedSaleInformation',
})
export class UserTicketCollapsedSaleInformationPipe implements PipeTransform {
  transform(value: TicketSaleQuantityInformationInterface[]): string {
    if (!value) {
      return null;
    }
    let output = '';
    value.forEach((data, index) => {
      output += `${data.ticketName} (${data.checkedInQuantity}/${data.boughtQuantity})`;
      if (value.length > 1 && index !== value.length - 1) {
        output += ', ';
      }
    });
    return output;
  }
}
