import {NgControl} from '@angular/forms';
import {AfterViewInit, Directive, HostListener, Input} from "@angular/core";

@Directive({
  selector: '[formatNumber]'
})
export class FormatNumberDirective implements AfterViewInit {

  fractionDigits = 2;

  @Input() set formatNumber(fractionDigits: number) {
    this.fractionDigits = fractionDigits;
    if (this.ngControl.control) {
      this.ngControl.control.patchValue(this.ngControl.control.value.toFixed(this.fractionDigits));
    }
  }

  ngAfterViewInit() {
    if (this.ngControl.control) {
      this.ngControl.control.patchValue(this.ngControl.control.value?.toFixed(this.fractionDigits));
    }
  }

  @HostListener('blur') onBlur() {
    this.ngControl.control.patchValue(this.ngControl.control.value?.toFixed(this.fractionDigits));
  }

  @HostListener('focusin') onFocusIn() {
    if (this.ngControl.control.value) {
      this.ngControl.control.patchValue(+this.ngControl.control.value);
    }
  }

  constructor(private ngControl: NgControl) {
  }

}
