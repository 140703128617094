import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PassCheckoutListItemComponent } from './pass-checkout-list-item.component';
import { PipesModule } from '../../pipes';
import { MatListModule } from '@angular/material/list';
import {DfSharedButtonModule} from "../buttons";

@NgModule({
  declarations: [PassCheckoutListItemComponent],
  exports: [PassCheckoutListItemComponent],
    imports: [
      CommonModule,
      FormsModule,
      PipesModule,
      MatFormFieldModule,
      MatSelectModule,
      ReactiveFormsModule,
      TranslocoModule,
      MatIconModule,
      MatTooltipModule,
      MatListModule,
      DfSharedButtonModule,
    ]
})
export class PassCheckoutListItemModule {}
