import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DfmNotificationPageComponent } from './pages';

const routes: Routes = [
  { 
    path: '' ,
    component: DfmNotificationPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfmNotificationAppRoutingModule {
  constructor() {
    console.log('FrontendDfmNotificationAppRoutingModule initialized');
  }
}
