import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { danceManagerUserHasRoles, DanceManagerUserRoleEnum, LocationInterface } from '@platri/df-common-core';
import { AuthStateService, DanceSchoolStateService, LocationsStateService } from '../../services';
import { DanceflavorsDanceManagerDetailUrlNameRouteHelper } from '../../shared';

@Component({
  selector: 'df-monorepo-locations-wrapper',
  templateUrl: './dfm-locations-wrapper.page.html',
  styleUrls: ['./dfm-locations-wrapper.page.scss'],
})
export class DfmLocationsWrapperPage implements OnInit, OnDestroy {
  @Input() isStudioPage = false;
  @Input() isDmPreview = false;
  
  locations: LocationInterface[];
  isOwnerOrAdmin = false;
  
  private subscription = new Subscription();
  
  constructor(
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly router: Router,
    private readonly authService: AuthStateService,
    public readonly locationsStateService: LocationsStateService,
  ) {}

  ngOnInit(): void {
    this.getLocationsChanges();
    this.loadLocations();
    this.checkICurrentUserIsOwnerOrAdmin();
    console.log(this.isDmPreview);
  }

  loadLocations(): void {
    this.locationsStateService.clear();
    this.locationsStateService.loadAllLocationsByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.locationsStateService.clear();
  }

  routeToCreatePage(): void {
    this.router.navigate([DanceflavorsDanceManagerDetailUrlNameRouteHelper(this.danceSchoolService.getSyncCurrentDanceSchool().id) + '/locations/create']);
  }

  checkICurrentUserIsOwnerOrAdmin(): void {
    this.subscription.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        if (user) {
          this.isOwnerOrAdmin = danceManagerUserHasRoles(user.id, this.danceSchoolService.getSyncCurrentDanceSchool().danceSchoolToUsers, [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]);
        }
      })
    );
  }

  getLocationsChanges(): void {
    this.subscription.add(
      this.locationsStateService.getAsyncAvailableLocations().subscribe((locations) => {
        if (locations) {
          this.locations = locations;
        }
      })
    );
  }
}
