export enum UserPassStatusEnum {
  CREATED = "CREATED",
  PAID = "PAID",
  INVALID = "INVALID",
  FREE = "FREE",
  TRIAL = "TRIAL",
  SCANNED = "SCANNED",
  REFUND = "REFUND"
}

