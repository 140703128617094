export const DEFAULT_PROFILE_PICTURE_PLACEHOLDER = 'assets/images/profile_picture_placeholder.svg';
export const DEFAULT_GROUP_MESSAGE_PLACEHOLDER = 'assets/images/group_picture.png';

export const DEFAULT_EVENT_PICTURE_PLACEHOLDER = 'assets/svg/default_event_picture.svg';
export const DEFAULT_COURSE_PICTURE_PLACEHOLDER = 'assets/svg/default_course_picture.svg';
export const DEFAULT_STUDIO_PICTURE_PLACEHOLDER = 'assets/svg/default_studio_picture.svg';
export const DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = 'assets/images/studio_logo.png';

export const DF_SEARCH_EVENT_AD_PICTURE = 'assets/images/df-search-event-ad.png';
export const DF_SEARCH_COURSE_AD_PICTURE = 'assets/images/df-search-course-ad.png';
export const DF_SEARCH_DM_AD_PICTURE = 'assets/images/df-search-dance-manager-ad.png';
