import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmStripeOnboardingComponent } from './dfm-stripe-onboarding.component';

@NgModule({
  declarations: [DfmStripeOnboardingComponent],
  imports: [
    CommonModule
  ],
  exports: [DfmStripeOnboardingComponent],
})
export class DfmStripeOnboardingModule {}
