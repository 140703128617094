import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { RouterLink } from '@angular/router';
import { LinkifyPipe } from '../../helpers';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ListPictureChatModule } from '../list-picture';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ChatComponent, LinkifyPipe],
    imports: [
      CommonModule,
      ListPictureChatModule,
      MatIconModule,
      MatMenuModule,
      MatChipsModule,
      RouterLink,
      MatButtonModule,
      MatFormFieldModule,
      MatProgressSpinner,
      TranslocoDirective,
    ],
  exports: [ChatComponent]
})
export class ChatModule { }
