import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UserDto, UserPassStatusEnum, UserPassSubscriptionInterface } from '@platri/df-common-core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DfmStateService, UserHttpService, UserPassHttpService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { FreePassInviteDialogComponent } from '../../dialogs';
import { getSubscription } from '@platri/elab-angular-core';

interface PassSubscriptionGuestListTableInterface {
  userId: string;
  email: string;
  userPasses: UserPassSubscriptionInterface[];
}

@Component({
  selector: 'dfm-pass-subscription-guest-list-table',
  templateUrl: './pass-subscription-guest-list-table.component.html',
  styleUrls: ['./pass-subscription-guest-list-table.component.scss']
})
export class PassSubscriptionGuestListTableComponent implements OnInit, OnDestroy {
  @Input() passSubscriptionId: string;
  @Output() hasSoldPassSubscriptionsEmitter = new EventEmitter<boolean>();

  data: PassSubscriptionGuestListTableInterface[] = [];
  dataSource: MatTableDataSource<PassSubscriptionGuestListTableInterface>;
  
  displayedColumns: string[] = ['user', 'passType', 'stamps', 'validUntil', 'openChat'];
  activeChipIndex = 0;

  loadedUsers: { [key: string]: UserDto } = {};
  users: UserDto[] = [];
  userIds: string[] = [];
  userPassSubscriptions: UserPassSubscriptionInterface[] = [];
  
  isInitialized = false;

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  private subscriptions: Subscription = new Subscription();
  
  constructor(
    private router: Router,
    private userPassHttpService: UserPassHttpService,
    private dfmStateService: DfmStateService,
    private userHttpService: UserHttpService,
    private matDialog: MatDialog
  ) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  initializeSubscriptions(): void {
    // this.subscriptions.add(getSubscription(this.userPassHttpService.getAllByPassId(this.passSubscriptionId, this.activeChipIndex === 0), this.loadData.bind(this)));
  }

  loadData(userPasses: UserPassSubscriptionInterface[]): void {
    // this.data = [];
    // for (const obj of userPasses) {
    //   let index;
    //   if (obj.userId) {
    //     index = this.data.findIndex((a) => a.userId === obj.userId);
    //   } else {
    //     index = this.data.findIndex((a) => a.userId === null && a.email === obj.guestUserEmail);
    //   }
    //   if (index === -1) {
    //     this.data.push({
    //       userId : obj.userId ?? null,
    //       email: obj.guestUserEmail ?? null,
    //       userPasses: [obj]
    //     });
    //   } else {
    //     this.data[index].userPasses.push(obj);
    //   }
    // }
    //
    // if (userPasses.length > 0) {
    //   this.hasSoldPassSubscriptionsEmitter.emit(true);
    // }
    //
    // this.userPassSubscriptions = userPasses;
    // this.userIds = [...new Set(userPasses.filter((userPass) => userPass.userId !== null).map((userPass) => userPass.userId))];
    // this.loadUsers();
    // this.updateDataSource();
  }
  
  loadUsers(): void {
    this.subscriptions.add(
      this.userHttpService.getUsersByIds(this.userIds, false, true).subscribe({
        next: (users) => {
          this.users = users;
          users.forEach((user) => {
            this.loadedUsers[user.id] = user;
          });
          this.isInitialized = true;
        }
      })
    );
  }

  // passesColumnForUser(userId: string, userEmail: string): string {
  //   const getPassLabel = (userPass: UserPassSubscriptionInterface): string | null => {
  //     const freeLabel = userPass.status === UserPassStatusEnum.FREE || userPass.pass.price === 0 ? ` (Free)` : ``;
  //     return `${userPass.userPassScans.length}/${userPass.pass.usageCount ?? userPass.userPassScans.length}${freeLabel}`;
  //   };
  //
  //   const data = this.data.find((a) => userId ? a.userId === userId : a.email === userEmail);
  //
  //   return data.userPasses.map(getPassLabel).filter((label): label is string => label !== null).join(",<br>");
  // }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openChat(userId: string): void {
    if (userId) {
      const newUrl = `/dance-manager/${this.dfmStateService.selectedDm.value.id}/messages/${userId}?technicalUserId=${this.dfmStateService.selectedDm.value.id}`;
      this.router.navigateByUrl(newUrl);
    }
  }

  toggleChip(value: number): void {
    this.activeChipIndex = value;
    // this.loadData();
  }

  openFreePassInviteDialog(): void {
    const dialogRef = this.matDialog.open(FreePassInviteDialogComponent, {
      minWidth: '75vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      // this.loadData();
    });
  }
  
  // getNumberOfPaidPasses(): number {
  //   return this.userPassSubscriptions.filter((obj) => obj.status === UserPassStatusEnum.PAID).length;
  // }

  protected readonly UserPassStatusEnum = UserPassStatusEnum;
}
