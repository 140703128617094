import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmojisComponent } from './emojis.component';

@NgModule({
  declarations: [EmojisComponent],
  exports: [EmojisComponent],
  imports: [
    CommonModule,
  ],
})
export class EmojisModule {}
