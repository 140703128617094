import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SocialLinkInterface, UsersInterface, UserSocialLinkTypeEnum } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-user-social-links-edit',
  templateUrl: './user-social-links-edit.component.html',
  styleUrls: ['./user-social-links-edit.component.scss']
})
export class UserSocialLinksEditComponent implements OnInit, OnChanges {
  @Input() currentUser: UsersInterface;
  @Output() saveClick = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  public userSocialLinkTypeEnum = UserSocialLinkTypeEnum;

  public availableUserSocialLinkTypeEnum = [];

  formGroup: UntypedFormGroup;
  isSubmitting = true;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  get urlControl(): AbstractControl {
    return this.formGroup.get('url');
  }

  get typeControl(): AbstractControl {
    return this.formGroup.get('type');
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser) {
      this.checkAvailableEnumSelections();
    }
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [null, Validators.required],
      url: [null, [Validators.required, this.urlValidator.bind(this)]]
    });
  }

  updateUrlControlValidators(): void {
    const urlControl = this.formGroup.get('url');
    urlControl.updateValueAndValidity();
  }

  urlValidator(control: AbstractControl): { [key: string]: any } | null {
    const url = control.value;
    const type = this.formGroup?.get('type')?.value;
  
    if (!url || !type) {
      return null; 
    }
    let regex: RegExp;

    switch (type) {
      case 'FACEBOOK':
        regex = /^(https?:\/\/)?(www\.)?(m\.)?facebook.com\//;
        break;
      case 'INSTAGRAM':
        regex = /^(https?:\/\/)?(www\.)?instagram.com\//;
        break;
      case 'YOUTUBE':
        regex = /^(https?:\/\/)?(www\.)?(m\.)?youtube.com\//;
        break;
      case 'TIKTOK':
        regex = /^(https?:\/\/)?(www\.)?tiktok.com\//;
        break;
      case 'TWITTER':
        regex = /^(https?:\/\/)?(www\.)?twitter.com\//;
        break;
      default:
        return null;
    }
    return regex.test(url) ? null : { invalidUrl: true };
  }
  
  checkAvailableEnumSelections(): void {
    this.availableUserSocialLinkTypeEnum = [];
    Object.keys(this.userSocialLinkTypeEnum).forEach((obj) => {
      if (!this.currentUser.socialLinks?.find((socialLink) => socialLink.type === obj)) {
        this.availableUserSocialLinkTypeEnum.push(obj);
      }
    });
  }

  saveSocialLink(): void {
    const index = this.currentUser?.socialLinks?.findIndex((socialLink) => socialLink.type === this.formGroup.value.type);
    if (!index || index === -1) {
      const newSocialLink: SocialLinkInterface = {
        type: this.formGroup.value.type,
        url: this.formGroup.value.url
      };
      if (this.currentUser?.socialLinks?.length > 0) {
        this.currentUser?.socialLinks.push(newSocialLink);
      } else {
        this.currentUser.socialLinks = [newSocialLink];
      }
      this.saveClick.emit(this.currentUser);
      this.formGroup.reset();
    }
    this.closeEditMode.emit();
    this.checkAvailableEnumSelections();
  }
}
