import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DanceEventResolver, dfDanceEventIdRouterParam } from '@platri/dfx-angular-core';
import { DanceEventPage, EventTicketsCheckoutCompletePage, EventTicketsCheckoutPage, EventTicketsComponent } from './pages';

const routes: Routes = [
  { 
    path: ':' + dfDanceEventIdRouterParam,
    data: { breadcrumb: 'Home' },
    children: [
      {
        path: '',
        component: DanceEventPage,
      },
      {
        path: 'tickets',
        component: EventTicketsComponent,
        resolve: {
          targetObject: DanceEventResolver,
        },
      },
      {
        path: 'tickets/checkout',
        component: EventTicketsCheckoutPage,
        resolve: {
          targetObject: DanceEventResolver,
        },
      },
      {
        path: 'tickets/checkout-complete/:ticketOrderId',
        component: EventTicketsCheckoutCompletePage,
        resolve: {
          targetObject: DanceEventResolver,
        },
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfDanceEventAppRoutingModule {
  constructor() {
    console.log('FrontendDfDanceEventAppRoutingModule initialized');
  }
}
