import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-description-form-settings',
  templateUrl: './description-form-settings.component.html',
  styleUrls: ['./description-form-settings.component.scss'],
})
export class DescriptionFormSettingsComponent implements OnInit, OnDestroy {
  @Input() currentUser: UsersInterface;
  @Output() descriptionSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  isSubmitting = true;
  formGroup: UntypedFormGroup;

  private subscriptions: Subscription = new Subscription();

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      description: [
        this.currentUser.description,
        [
          this.maxLengthValidator(2000),
          this.notEmptyValidator
        ],
      ],
    });
  }

  maxLengthValidator(maxLength: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputValue = control.value || '';
      return inputValue.length > maxLength ? { 'maxLength': { value: maxLength } } : null;
    };
  }

  notEmptyValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && typeof control.value === 'string' && control.value.trim().length === 0) {
      return { 'notEmpty': true };
    }
    return null;
  }

  get descriptionControl(): AbstractControl {
    return this.formGroup.get('description');
  }

  saveDescriptionData(): void {
    if (this.formGroup.valid) {
      const user = { description: this.formGroup.value.description ? this.formGroup.value.description.replace('<div>','').replace('</div>', '') : ''};
      this.descriptionSaved.emit(user);
      this.isSubmitting = true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
