import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddNewDmLocationDialogComponent } from '../add-new-dm-location-dialog';

@Component({
  selector: 'df-shared-lib-add-locations',
  templateUrl: './df-add-locations.component.html',
  styleUrls: ['./df-add-locations.component.scss']
})
export class DfAddLocationsComponent {
  @Input() formGroup: UntypedFormGroup;

  constructor(private readonly matDialog: MatDialog) {}

  openAddLocationDialog(index: number | null): void {
    const dialogRef = this.matDialog.open(AddNewDmLocationDialogComponent, {
      minWidth: '350px',
      width: '700px',
      data: {
        title: index != null ? 'EDIT_LOCATION' : 'ADD_LOCATION',
        location: this.locations.value[index] ?? null
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (index !== null) {
          const locations = this.locations.value;
          locations[index] = data;
          this.formGroup.patchValue({
            locations
          });
        } else {
          this.formGroup.patchValue({
            locations: [...this.locations.value, data]
          });
        }
      }
    });
  }

  deleteLocation(index: number): void {
    const locations = this.locations.value;
    locations.splice(index, 1);
    this.formGroup.patchValue({
      locations
    });
  }

  setPublic(index: number): void {
    const locations = this.locations.value;
    locations[index].isPublic = true;
    this.formGroup.patchValue({
      locations
    });
  }

  setPrivate(index: number): void {
    const locations = this.locations.value;
    locations[index].isPublic = false;
    this.formGroup.patchValue({
      locations
    });
  }

  get locations() {
    return this.formGroup.get('locations');
  }
}
