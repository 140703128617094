import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-job-form-settings',
  templateUrl: './job-form-settings.component.html',
  styleUrls: ['./job-form-settings.component.scss'],
})
export class JobFormSettingsComponent implements OnInit {

  @Input() currentUser: UsersInterface;
  @Output() jobSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  formGroup: UntypedFormGroup;
  isSubmitting = true;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      job: [this.currentUser.jobTitle, Validators.required],
    });
  }

  saveJobData(): void {
    if (!this.formGroup.invalid) {
      const user = {jobTitle: this.formGroup.value.job};
      this.jobSaved.emit(user);
      this.isSubmitting = true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
