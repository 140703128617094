<ng-container *transloco="let t">
  <strong>{{ t('GENERIC_WRAPPER.NEW_CHAT') }}</strong>
  <br />
  <br />
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ t('GENERIC_WRAPPER.SEARCH') }}</mat-label>
    <input
      autocomplete="off"
      matInput
      placeholder="{{
        t('GENERIC_WRAPPER.NAME_OR_USERNAME')
      }}"
      type="text"
      class="message_username"
      #conversationUsernameInput
      (keyup)="findPartners()"
      [(ngModel)]="username"
      name="username"
      autocomplete="off"
    />
  </mat-form-field>
  <br />
  <ng-container *ngIf="partners.length > 0 || toPartners.length > 0">
    <b
      *ngIf="toPartners.length > 0"
      (click)="openConversation()"
      class="button-open-conversation"
    >
      {{ t('GENERIC_WRAPPER.NEXT') }}
    </b>
  
    <div style="min-height: 20px; padding-bottom: 5px">
      <div class="selected-to-partner" *ngFor="let toPartner of toPartners">
        <img imageFallback [fallbackSrc]="toPartner.id.startsWith('user') ? DEFAULT_PROFILE_PICTURE_PLACEHOLDER : DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER" class="avatar" [src]="toPartner.imageUrl"/>
        {{ toPartner.username }}
        <div class="remove">
          <mat-icon (click)="removeToPartner(toPartner)" fontSize="12px">clear</mat-icon>
        </div>
      </div>
      <div style="clear: left"></div>
    </div>
    <div *ngIf="error" style="color: red">{{ error }}<br /><br /></div>
  </ng-container>
  <ng-container *ngIf="partners.length > 0">
    <div *ngFor="let partner of partners; let last = last">
      <div
        class="list-element"
        [ngClass]="{ 'list-element-border-bottom': !last }"
      >
        <df-search-conversation-partners-entry
          [partner]="partner"
          [hasToPartner]="hasToPartner(partner)"
          (addToPartner)="addToPartner($event)"
        ></df-search-conversation-partners-entry>
      </div>
    </div>
  </ng-container>
</ng-container>
