import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProfileWrapperPage } from './profile-wrapper.page';
import { ProfileModule } from '@platri/df-angular-user';
import { DanceManagerPublicPageModule } from '@platri/df-angular-dance-manager';
@NgModule({
  declarations: [ProfileWrapperPage],
  imports: [
    CommonModule,
    DanceManagerPublicPageModule,
    ProfileModule,
  ],
})
export class ProfileWrapperModule {}
