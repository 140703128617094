import { DancePartnerGenderEnum, DanceStyleRoleEnum, DpsPostingInterface, RadiusUnitEnum, SearchPostingStatusEnum, SearchPostingTypeEnum } from '../../..';
import { ImageDto } from '../../..';
import { PlaceDto } from './place.dto';
import { DancePartnerDanceStyleDto } from './dance-partner-dance-style.dto';
import { DancePartnerSearchPostingUserDto } from './dance-partner-search-posting-user.dto';

export class DancePartnerSearchPostingDto implements DpsPostingInterface {
  id: string;
  createdAt: Date;
  title: string;
  description: string;
  searchPostingType: SearchPostingTypeEnum;
  searchPostingStatus: SearchPostingStatusEnum;
  ageFrom?: number;
  ageTo?: number;
  dancePartnerDanceRole?: DanceStyleRoleEnum;
  dancePartnerSearchPostingsToDanceStyles?: DancePartnerDanceStyleDto[];
  dancePartnerGender?: DancePartnerGenderEnum;
  heightFrom?: number;
  heightTo?: number;
  mainImage?: ImageDto;
  images?: ImageDto[];
  place: PlaceDto;
  user: DancePartnerSearchPostingUserDto;
  distance: number;
  radiusUnit: RadiusUnitEnum;
}
