import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ReactiveFormsModule} from "@angular/forms";
import { TeacherSelectionComponent } from './teacher-selection.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
    declarations: [
        TeacherSelectionComponent
    ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslocoDirective
  ],
    exports: [
        TeacherSelectionComponent
    ]
})
export class TeacherSelectionModule {
}
