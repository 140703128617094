import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SearchConversationPartnersEntryComponent } from './search-conversation-partners-entry.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes';

@NgModule({
  declarations: [
    SearchConversationPartnersEntryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    PipesModule,
    MatCheckboxModule,
    TranslocoModule,
  ],
  exports: [
    SearchConversationPartnersEntryComponent,
  ],
})
export class SearchConversationPartnersEntryModule {}
