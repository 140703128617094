import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { dfAppRoute, DfmDanceManagerPassSubscriptionIdRouterParam } from '../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CourseDmOverviewInterface,
  CourseInterface,
  DfmCoursePassesTableInterface,
  DmPassSubscriptionDto,
  DmPassSubscriptionInterface,
  FeesPaidByEnum,
  PassSubscriptionTargetTypeEnum,
  UpdateDmPassSubscriptionRequestDto
} from '@platri/df-common-core';
import { CoursesStateService, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService, DmPassSubscriptionHttpService } from '../../services';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { optionalMinMaxValidator, quillCharacterCountValidator } from '../../validators';
import { map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../components';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-dfm-pass-subscription-detail',
  templateUrl: './dfm-pass-subscription-detail.page.html',
  styleUrls: ['./dfm-pass-subscription-detail.page.scss']
})
export class DfmPassSubscriptionDetailPage implements OnInit, OnDestroy, AfterContentInit {
  infoFormGroup: FormGroup;
  priceFormGroup: FormGroup;
  
  subscriptions: Subscription =  new Subscription();

  isInitialized = false;
  currenTabIndex = 0;
  
  isLoadingPass = false;
  hasFailedLoadingPassNoConnection = false;
  
  isWaitingForUpdateResponse = false;
  updateFailedNoConnection = false;
  updateFailedInternalServer = false;
  updateFailedServerNotAvailable = false;
  
  routerParams!: Params;
  passSubscriptionIdFromParam: string;

  loadedPassSubscription: Readonly<DmPassSubscriptionDto>;
  assignedTargetIds: string[];
  courses: CourseDmOverviewInterface[];
  selectedCourses: DfmCoursePassesTableInterface[];
  selectedCoursesChanged = false;
  taxPercentage : number;
  isVatChanged = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private coursesService: CoursesStateService,
    private danceSchoolService: DanceSchoolStateService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private dmPassSubscriptionHttpService: DmPassSubscriptionHttpService,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private readonly translocoService: TranslocoService,
  ) {}
  
  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeFormGroup(): void {
    this.initInfoFormGroup();
    this.initPriceFormGroup();
  }

  initInfoFormGroup(): void {
    this.infoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      duration: this.fb.group({
        value: [null, [Validators.required, optionalMinMaxValidator(1, 12)]],
        interval: [null, [Validators.required]]
      }),
      weeklyUsage: [null, [optionalMinMaxValidator(1, 100)]],
      description: [null, [quillCharacterCountValidator(1000)]]
    });
  }

  initPriceFormGroup(): void {
    this.priceFormGroup = this.fb.group({
      price: [null, [Validators.required, Validators.min(0), Validators.max(10000)]],
      isPaidMonthly: [false, [Validators.required]],
      taxPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      feesPaidBy: [FeesPaidByEnum.CREATOR]
    });
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.coursesService.coursesSubject$, this.onCourseChange.bind(this)));
    this.isInitialized = true;
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.passSubscriptionIdFromParam = this.routerParams[DfmDanceManagerPassSubscriptionIdRouterParam];
    if (!this.passSubscriptionIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }
    this.loadData();
    this.coursesService.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 100);
  }

  loadData(): void {
    this.isLoadingPass = true;
    this.hasFailedLoadingPassNoConnection = false;
    this.subscriptions.add(
      this.dmPassSubscriptionHttpService.getPassSubscriptionById(this.passSubscriptionIdFromParam).subscribe({
        next: value => {
          if (value) {
            this.loadedPassSubscription = value;
            this.assignedTargetIds = value.targets?.map((target) => target.targetId);
            this.breadcrumbService.set('@passSubscriptionName', this.loadedPassSubscription.name);
            this.patchEditPassFormGroups(value);
            this.isLoadingPass = false;
            this.infoFormGroup.enable();
            this.priceFormGroup.enable();
          }
        },
        error: err => {
          console.log(err);
          this.isLoadingPass = false;
          if (err.status === 0) {
            this.onLoadPassConnectionLost();
          }
        }
      })
    );
  }

  patchEditPassFormGroups(passSubscription: DmPassSubscriptionInterface): void {
    this.infoFormGroup.patchValue({
      name: passSubscription.name,
      duration: {
        value: passSubscription.durationValue,
        interval: passSubscription.durationUnit
      },
      weeklyUsage: passSubscription.weeklyUsage,
      description: passSubscription.description
    });
    this.priceFormGroup.patchValue({
      price: passSubscription.price / 100,
      isPaidMonthly: passSubscription.isPaidMonthly,
      taxPercentage: passSubscription.taxPercentage
    });
  }

  onCourseChange(courses: CourseInterface[]): void {
    if (courses) {
      this.courses = courses;
      this.selectedCourses = this.courses?.filter((course) => this.loadedPassSubscription?.targets?.map((target) => target.targetId).includes(course.id));
    }
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currenTabIndex = event.index;
  }

  selectedTargets(data: DfmCoursePassesTableInterface[]): void {
    this.selectedCourses = data;
    this.selectedCoursesChanged = true;
  }

  onSoldPasses(): void {
    // TODO: fix wenn Verkäufe eingebaut sind
    // this.infoFormGroup.controls.name.disable();
    // this.infoFormGroup.controls.duration.controls.value.disable();
    // this.infoFormGroup.controls.duration.controls.interval.disable();
    // this.infoFormGroup.controls.weeklyUsage.disable();
    // this.infoFormGroup.controls.description.disable();
    // this.priceFormGroup.disable();
  }
  
  onSubmit(): void {
    if (this.infoFormGroup.valid && this.priceFormGroup.valid) {
      this.isWaitingForUpdateResponse = true;
      this.updateFailedNoConnection = false;
      this.updateFailedInternalServer = false;
      if(this.loadedPassSubscription.numberOfSales > 0) {
        this.matDialog
          .open(ConfirmationDialogComponent, {
            data: {
              cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.NO'),
              submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.YES'),
              text: this.translocoService.translate('GENERIC_WRAPPER.OVERWRITE_CURRENT_SUBSCRIPTIONS'),
            }
          })
          .afterClosed()
          .subscribe((result: boolean) => {

            this.sendUpdatedPassSubscription(result);
          });
      } else {
        this.sendUpdatedPassSubscription();
      }
    }
  }
  
  sendUpdatedPassSubscription(updateActiveSubscription: boolean = false): void {
    const {name, weeklyUsage, description} = this.infoFormGroup.getRawValue();
    const {value, interval} = this.infoFormGroup.get('duration').getRawValue();
    const {price, isPaidMonthly, taxPercentage} = this.priceFormGroup.getRawValue();

    const subscriptionTargets = [];
    if (this.selectedCourses && this.selectedCourses.length > 0) {
      for (const course of this.selectedCourses) {
        subscriptionTargets.push({targetId: course.id, targetType: PassSubscriptionTargetTypeEnum.COURSE});
      }
    }
    
    const updateDmPassSubscriptionRequestDto: UpdateDmPassSubscriptionRequestDto = new UpdateDmPassSubscriptionRequestDto({
      name: name!,
      price: Math.round(+price! * 100),
      durationUnit: interval!,
      durationValue: value!,
      isPaidMonthly: isPaidMonthly!,
      weeklyUsage: weeklyUsage!,
      description: description!,
      taxPercentage: +taxPercentage!,
      targets: subscriptionTargets!,
      updateActiveSubscription: updateActiveSubscription
    });
  
    this.subscriptions.add(getSubscription(
      this.dmPassSubscriptionHttpService.updatePassSubscriptionById(this.passSubscriptionIdFromParam, updateDmPassSubscriptionRequestDto),
      this.onUpdatePassSubscriptionSuccessful.bind(this),
      this.onUpdatePassSubscriptionFailed.bind(this)
    ));
  }
  
  onUpdatePassSubscriptionSuccessful(res: DmPassSubscriptionInterface): void {
    this.isWaitingForUpdateResponse = false;
    if (this.isVatChanged) {
      this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id, ({vat: this.taxPercentage})).pipe(
        map((result) => {
          this.danceSchoolService.sendCurrentDanceSchool(result);
          this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
            map((danceSchools) => danceSchools),
            map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
          ).subscribe();
        })
      ).subscribe();
    }
    this.matSnackBar.open('Successfully updated subscription!');
  }
  
  onUpdatePassSubscriptionFailed(error): void {
    console.log(error);
    if (error.status === 0) {
      this.onConnectionLost();
    }
    if (error.status === 500) {
      this.onInternalServerError();
    }
    if (error.status === 504) {
      this.onServiceNotAvailable();
    }
    // TODO: add when needed
    // if (error.error.error === 'update_pass_error_price_tax_at_least_one_sold') {
    //   this.matSnackBar.open('Update failed! Price or tax is not updatable because at least one pass is sold.');
    // }
    // if (error.error.error === 'update_pass_error_pass_targets_at_least_one_sold') {
    //   this.matSnackBar.open('Update failed! At least one target needs to stay assigned because at least one pass is sold.');
    // }
    this.isWaitingForUpdateResponse = false;
  }

  onLoadPassConnectionLost(): void {
    this.hasFailedLoadingPassNoConnection = true;
  }

  onConnectionLost(): void {
    this.updateFailedNoConnection = true;
  }

  onInternalServerError(): void {
    this.updateFailedInternalServer = true;
  }

  onServiceNotAvailable(): void {
    this.updateFailedServerNotAvailable = true;
  }
}
