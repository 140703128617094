import {Directive, ElementRef, HostListener, Input, Renderer2} from "@angular/core";

@Directive({
  selector: '[sharedMaterialElevationDirective]'
})
export class MaterialElevationDirective {
  @Input() defaultElevation = 1;
  @Input() raisedElevation = 3;

  constructor(private element: ElementRef,
              private renderer: Renderer2) {
    this.setElevation(this.defaultElevation);
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.setElevation(this.raisedElevation);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.setElevation(this.defaultElevation);
  }

  setElevation(elevation: number) {
    const classesToRemove = Array.from(
      (<HTMLElement>this.element.nativeElement)
        .classList)
      .filter(c => c.startsWith('mat-elevation-z'));
    classesToRemove.forEach((c) => {
      this.renderer.removeClass(this.element.nativeElement, c);
    });

    const newClass = `mat-elevation-z${elevation}`;
    this.renderer.addClass(this.element.nativeElement, newClass);
  }
}
