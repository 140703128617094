import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterFormInterface } from './register-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getSubscription, Match } from '@platri/elab-angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStateService, dfAppRoute, dfLoginRoute, noWhitespaceValidator, UserHttpService } from '@platri/dfx-angular-core';
import { emailRegex, UserAppLangEnum, wait } from '@platri/df-common-core';

@Component({
  selector: 'elab-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Input() showSocialButtons = true;
  
  @Output() successfulRegisterEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  registerFormGroup!: FormGroup<RegisterFormInterface>;

  subscriptions: Subscription = new Subscription();

  isInitialized = false;
  isWaitingForRegisterResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  signUpFailedWrongData = false;
  signUpFailedNoConnection = false;
  signUpFailedInternalServer = false;

  hidePassword = true;
  
  hasSuccessfullyRegistered = false;
  
  routerParams!: Params;
  returnUrlFromParam: string;

  constructor(
    private fb: FormBuilder,
    private userHttpService: UserHttpService,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private authService: AuthStateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.registerFormGroup = this.createRegisterFormGroup();
  }

  createRegisterFormGroup(): FormGroup<RegisterFormInterface> {
    return this.fb.group<RegisterFormInterface>({
      firstname: this.fb.control('', [Validators.required, Validators.maxLength(30)]),
      lastname: this.fb.control('', [Validators.required, Validators.maxLength(30)]),
      email: this.fb.control('', [Validators.required, Validators.email, noWhitespaceValidator(), Validators.pattern(emailRegex), Validators.maxLength(60)]),
      password: this.fb.control('', [Validators.required]),
      confirmPassword: this.fb.control('', [Validators.required])
      },
      {
        validators: [Match('password', 'confirmPassword')]
      }
    );
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.registerFormGroup.valueChanges.subscribe(res => {
      this.signUpFailedWrongData = false;
      this.signUpFailedNoConnection = false;
      this.signUpFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }

  onSubmit(): void {
    if (this.registerFormGroup.valid || (this.registerFormGroup.controls.email.hasError('password_wrong') || this.registerFormGroup.controls.password.hasError('password_wrong'))) {
      this.isWaitingForRegisterResponse = true;
      this.signUpFailedWrongData = false;
      this.signUpFailedNoConnection = false;
      this.signUpFailedInternalServer = false;
      const {email, password, firstname, lastname} = this.registerFormGroup.getRawValue();
      // const signUpDto: SignUpRequestDto = {email: email!, password: password!, firstName: firstname!, lastName: lastname!, username: '', appLang: UserAppLangEnum.DE};
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.subscriptions.add(this.userHttpService.signUp({username: firstname + '_' + lastname, email: email!, password: password!, firstName: firstname!, lastName: lastname!, appLang: UserAppLangEnum.DE}).subscribe(res => {
        if (this.returnUrlFromParam) {
          wait(1500).then(() => {
            this.hasSuccessfullyRegistered = true;
            this.isWaitingForRegisterResponse = false;
            this.matSnackbar.open("Successfully registered!");
            this.authService.signIn({email: email!, password: password!}).subscribe({
              next: () => {
                this.router.navigateByUrl(this.returnUrlFromParam);
              }
            });
          });
        } else {
          this.hasSuccessfullyRegistered = true;
          this.isWaitingForRegisterResponse = false;
          this.matSnackbar.open("Successfully registered!");
        }
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        } else {
          this.onWrongPasswordEntered();
        }
        this.isWaitingForRegisterResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.signUpFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.signUpFailedInternalServer = true;
  }

  onWrongPasswordEntered(): void {
    this.signUpFailedWrongData = true;
    //this.registerFormGroup.controls.email.setErrors({'password_wrong': true});
    ///this.registerFormGroup.controls.password.setErrors({'password_wrong': true});
  }
  
  routeToLogin(emitSuccessfulRegisterEmitter: boolean = false): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
    if (emitSuccessfulRegisterEmitter) {
      this.successfulRegisterEmitter.next(true);
    }
  }

  signInWithGoogle(token: string): void {
    this.authService.signInGoogle(token).subscribe({
      next: () => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        } else {
          this.router.navigate([dfAppRoute]);
        }
      }
    });
  }
  signInWithFacebook(token: string): void {
    this.authService.signInFacebook({accessToken: token}).subscribe({
      next: () => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        } else {
          this.router.navigate([dfAppRoute]);
        }
      }
    });
  }

}
