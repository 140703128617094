export enum DanceEventCategoryEnum {
  PARTY = 'PARTY',
  WORKSHOP = 'WORKSHOP',
  FESTIVAL = 'FESTIVAL',
  CONGRESS = 'CONGRESS',
  TRIP = 'TRIP',
  CONCERT = 'CONCERT',
  COMPETITION = 'COMPETITION',
  OTHER = 'OTHER'
}
