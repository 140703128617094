import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthStateService, UserHttpService } from '../../services';
import { TranslocoService } from '@jsverse/transloco';
import { DateAdapter } from '@angular/material/core';
import {
  DEFAULT_LANGUAGE,
  SystemLanguageEnum,
  UserAppLangEnum,
  UsersInterface
} from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit, OnDestroy {
  currentUser: UsersInterface;
  currentUserLanguage: string;
  languages = Object.values(SystemLanguageEnum);
  defaultLanguage = DEFAULT_LANGUAGE;

  destroy$: Subject<void> = new Subject<void>();

  subscription: Subscription = new Subscription();

  constructor(
    private userHttpService: UserHttpService,
    private authService: AuthStateService,
    public translocoService: TranslocoService,
    private dateAdapter: DateAdapter<Date>
  ) {}

  ngOnInit(): void {
    this.checkAppLanguage();
  }

  changeAndSaveLanguage(lang: string): void {
    this.translocoService.setActiveLang(lang.toLowerCase());
    this.dateAdapter.setLocale(lang);
    this.currentUserLanguage = lang.toUpperCase();
    if (this.currentUser) {
      this.userHttpService.updateUser({ appLang: lang.toUpperCase() as UserAppLangEnum }).subscribe((user) => {
        this.authService.sendCurrentUserChanges({...this.authService.getSyncCurrentUser(), appLang: user.appLang});
        this.authService.refreshTokens().subscribe(() => {
          // appLang in tokens need to be updated
        });
      });
    }
  }

  checkAppLanguage(): void {
    this.authService
      .getAsyncCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentUser) => {
        if (currentUser) {
          this.currentUser = currentUser;
          this.currentUserLanguage = currentUser.appLang.toUpperCase();
          this.dateAdapter.setLocale(this.currentUserLanguage);
        } else {
          this.currentUserLanguage = this.defaultLanguage;
          this.translocoService.setActiveLang(this.defaultLanguage.toLowerCase());
          this.dateAdapter.setLocale(this.defaultLanguage);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
}
