<ng-container *transloco="let t">
  <div [ngClass]="{ 'hide-toolbar': disableRichText, 'error-outline': hasError }">
    <quill-editor
      style="width: 100%"
      matInput
      id="quill-editor"
      [id]="elementId"
      [placeholder]="t(placeholder) + (isRequired ? '*' : '')"
      [formControlName]="customFormControlName"
      [modules]="modules"
      [formats]="disableRichText ? formats : undefined"
    >
    </quill-editor>
    <div *ngIf="enableCounter" class="w-100 align-end" style="display: flex;">
      <div id="counter"></div>
    </div>
  </div>
</ng-container>
