import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddImageComponent } from './add-image.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImageUploadCropperModule } from '../image-cropper';
import { DfSharedButtonModule } from '../buttons';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ImageCropperDialogModule } from '../image-cropper-dialog';
import { AddImageDialogModule } from '../add-image-dialog';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  exports: [AddImageComponent],
  declarations: [AddImageComponent],
    imports: [
      CommonModule,
      MatButtonModule,
      MatDialogModule,
      MatIconModule,
      ImageUploadCropperModule,
      DfSharedButtonModule,
      ImageCropperDialogModule,
      AddImageDialogModule,
      MatTooltipModule,
      TranslocoDirective,
    ],
})
export class AddImageModule {}
