import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { EventTicketPricingStepComponent } from './event-ticket-pricing-step.component';
import { PipesModule } from '../../pipes';
import {FormatNumberModule, NoScrollDirective} from '../../directives';

@NgModule({
  declarations: [EventTicketPricingStepComponent],
  exports: [EventTicketPricingStepComponent],
  providers: [
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CdkStepperModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatRadioModule,
        MatTooltipModule,
        PipesModule,
        FormatNumberModule,
        TranslocoModule,
        NoScrollDirective,
    ],
})
export class EventTicketPricingStepModule {}
