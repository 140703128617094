<ng-container *transloco="let t">
  <div class="align-center pl3 pr3">
    <div class="flex-column pt2 w-100" style="min-height: calc(100vh - 56px); max-width: 1140px;">
      <div class="w-100 flex-column" style="flex: 0 0 70%">
        <div class="breadcrumb-container">
          <xng-breadcrumb [separator]="iconTemplate">
            <ng-container *xngBreadcrumbItem="let breadcrumb">
              <ng-container>{{ t(breadcrumb) }}</ng-container>
            </ng-container>
          </xng-breadcrumb>
          <ng-template #iconTemplate>
            <mat-icon>chevron_right</mat-icon>
          </ng-template>
        </div>
        <div class="flex-row">
          <mat-card
            class="round-corners mat-elevation-z3 mt2 event-show event-show-respon w-100"
          >
            <div class="px3 pt3">
              <div class="flex-row" style="align-items: center;">
                <span class="course-title">{{ course?.name }}</span>
                <button mat-icon-button (click)="routeToCourse()" [matTooltip]="(t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.OPEN_COURSE_PAGE'))">
                  <mat-icon>open_in_new</mat-icon>
                </button>
              </div>
              <mat-tab-group
                [selectedIndex]="activeTabIndex"
                (selectedIndexChange)="changeHashTag($event)"
              >
                <mat-tab label="{{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.PREVIEW')}}">
                  <ng-template matTabContent>
                    <div style="overflow: hidden; margin-top: 15px;" class="mb2">
                      <df-monorepo-course-page-content
                        *ngIf="course"
                        [course]="course"
                        [isPreview]="true"
                      >
                      </df-monorepo-course-page-content>
                    </div>
                  </ng-template>
                </mat-tab>
  
                <mat-tab label="{{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.INFO')}}">
                  <ng-template matTabContent>
                    <div class="my3 ml1">
                      <!-- new component here -->
                      <df-edit-course-info-form
                        [infoFormGroup]="infoFormGroup"
                        [allowOverlapping]="false"
                        (onSaveClick)="updateCourseInfo(infoFormGroup)"
                        [course]="course"
                      ></df-edit-course-info-form>
                    </div>
                  </ng-template>
                </mat-tab>
  
                <mat-tab label="{{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.RULES')}}">
                  <ng-template matTabContent>
                    <div class="flex-column w-100 h-100">
                      <mat-card class="pt3 pb3">
                        <df-edit-course-rules-form 
                          [rulesFormGroup]="rulesFormGroup" 
                          (onSaveClick)="updateCourseRules()"
                          [allowOverlapping]="false"
                        ></df-edit-course-rules-form>
                      </mat-card>
                    </div>
                  </ng-template>
                </mat-tab>
  
                <mat-tab
                  label="{{t('DANCE_MANAGER_COURSE.APPOINTMENTS.TITLE')}}"
                >
                  <ng-template matTabContent>
                    <div class="my3 flex-column gap-15">
                      <div class="w-100 h-100 mb3 flex-row align-space-between-center">
                        <div class="flex-column w-100">
                          <div class="flex-row align-start-center gap-10">
                            <mat-button-toggle-group name="appointmentFilter">
                              <mat-button-toggle value="upcoming" (change)="filterAppointments($event)">
                                {{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.FILTERS.UPCOMING')}} ({{ upcomingAppointments?.length ?? 0 }})
                              </mat-button-toggle>
                              <mat-button-toggle value="past" (change)="filterAppointments($event)">
                                {{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.FILTERS.PAST')}} ({{ pastAppointments?.length ?? 0 }})
                              </mat-button-toggle>
                              <mat-button-toggle value="cancelled" (change)="filterAppointments($event)" >
                                {{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.FILTERS.CANCELLED')}} ({{ cancelledAppointments?.length ?? 0 }})
                              </mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                          <div class="gap-15 flex-column align-center w-100 my4 py4" *ngIf="!(filteredAppointments.length > 0)">
                            <df-shared-empty-page
                              [emptyTitle]="t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.NO_APPOINTMENT_TITLE')"
                              [emptyDescription]="t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.NO_APPOINTMENT_DESCRIPTION')"
                              [imagePath]="'assets/images/dance_manager/courses/no_calendar.svg'"
                              [buttonLabel]="t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.NEW_APPOINTMENT')"
                              (buttonClicked)="navigateToCreateAppointmentsPage()"
                              [buttonId]="'add-new-appointment-for-course'"
                            ></df-shared-empty-page>
                          </div>
                        </div>
                        <df-shared-lib-button 
                          [materialButtonStyleType]="'outlined'" 
                          [minWidth]="'145px'"
                          [buttonLabel]="t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.APPOINTMENTS.NEW_APPOINTMENT')"
                          (buttonClicked)="navigateToCreateAppointmentsPage()"
                          *ngIf="filteredAppointments.length > 0"
                        ></df-shared-lib-button>
                      </div>
                      <div *ngIf="filteredAppointments.length > 0">
                        <df-course-appointments-table
                          [filteredAppointments]="filteredAppointments"
                          [course]="course"
                        ></df-course-appointments-table>
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>
                
                <mat-tab label="{{t('GENERIC_WRAPPER.PASSES')}}">
                  <ng-template matTabContent>
                    <div class="flex-column w-100 h-100">
                         <df-course-passes-table *ngIf="passesFeatureActive && relatedPasses?.length > 0" [passes]="relatedPasses" (editPassClicked)="navigateToEditPassPage($event)"></df-course-passes-table>
                         <div *ngIf="passesFeatureActive && relatedPasses?.length === 0" class="align-center mt-20 mb-40">
                          <df-shared-empty-page
                            [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_PASS')"
                            [emptyTitle]="t('GENERIC_WRAPPER.NO_PASSES')"
                            [emptyDescription]="t('GENERIC_WRAPPER.NO_PASSES_TEXT')"
                            [imagePath]="'assets/images/courses-manager/no_courses.svg'"
                            (buttonClicked)="navigateToCreatePassPage()"
                          ></df-shared-empty-page>
                        </div>
        
                        <div *ngIf="!passesFeatureActive">
                          <div class="align-center mt-20 mb-40">
                            <df-shared-empty-page
                              [buttonLabel]="t('DANCE_MANAGER_COURSE.MARKETPLACE_BUTTON_TEXT')"
                              [emptyTitle]="t('DANCE_MANAGER_COURSE.ACTIVATE_PASS_PACKET.TITLE')"
                              [emptyDescription]="t('DANCE_MANAGER_COURSE.ACTIVATE_PASS_PACKET.TEXT')"
                              [imagePath]="'assets/images/courses-manager/no_courses.svg'"
                              (buttonClicked)="navigateToMarketplacePage()"
                            ></df-shared-empty-page>
                          </div>
                        </div>
                    </div>
                  </ng-template>
                </mat-tab>
                
                <mat-tab label="{{t('DANCE_MANAGER_COURSE.COURSE_PREVIEW.ALL_PARTICIPANTS')}}">
                  <ng-template matTabContent>
                    <div class="flex-column w-100 h-100">
                      <mat-card class="pt3 pb3">
                        <df-participants-list-table
                          [course]="course"
                        ></df-participants-list-table>
                      </mat-card>
                    </div>
                  </ng-template>
                </mat-tab>
                
              </mat-tab-group>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
