import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerPassesCheckoutCompletePage } from './dance-manager-passes-checkout-complete.page';
import { DfCheckoutCompleteComponent } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceManagerPassesCheckoutCompletePage],
  imports: [
    CommonModule,
    TranslocoModule,
    DfCheckoutCompleteComponent
  ]
})
export class DanceManagerPassesCheckoutCompletePageModule {}
