<mat-sidenav-container class="sidenav-content-height">
  <mat-sidenav 
    [ngClass]="expanded && !mobileQuery.matches? 'p-0': !expanded && !mobileQuery.matches? 'p-0 collapsed': ''"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [opened]="!mobileQuery.matches"
    [disableClose]="!mobileQuery.matches"
    [fixedInViewport]="true"
    [fixedTopGap]="73"
    [@sidenavExpand]="
      !smallSidenav
        ? 'noSmallSidenav'
        : mobileQuery.matches
        ? 'noSmallSidenav'
        : expanded
        ? 'expanded'
        : 'collapsed'
    "
  >
    <div class="flex-column align-space-between h-100">
      <ng-content select="[show-sidenav]"></ng-content>
      <div
        matRipple
        class="flex-row sidenav-opened hide-on-mobile"
        (click)="collapseSidenav()"
        *ngIf="expanded"
      >
        <mat-icon>arrow_back</mat-icon>
      </div>
      <div
        matRipple
        class="flex-row sidenav-closed hide-on-mobile"
        (click)="expandSidenav()"
        *ngIf="!expanded"
      >
        <mat-icon>arrow_forward</mat-icon>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [ngStyle]="
      !expanded && !mobileQuery.matches
        ? { 'margin-left': '68px' }
        : expanded && !mobileQuery.matches
        ? { 'margin-left': '300px' }
        : {}
    "
    [@sidenavContentExpand]="
      !smallSidenav
        ? 'noSmallSidenav'
        : mobileQuery.matches
        ? 'noMarginLeft'
        : expanded
        ? 'expanded'
        : 'collapsed'
    "
  >

    <div
      matRipple
      class="flex-row sidenav-closed-content hide-on-mobile"
      (click)="expandSidenav()"
      *ngIf="!sidenav?.opened"
    >
      <mat-icon>arrow_forward</mat-icon>
    </div>
    <div class="flex-column align-space-between h-100">
      <div
        class="flex-column h-100"
        style="background-color: white; display: flex; align-items: stretch; justify-content: flex-start;">
        <ng-content></ng-content>
      </div>
<!--      <df-shared-lib-footer [sidenav]="sidenav"></df-shared-lib-footer>-->
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
