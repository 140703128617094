import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { SearchConversationPartnersComponent } from './search-conversation-partners.component';
import { SearchConversationPartnersEntryModule } from '../search-conversation-partners-entry';
import { ImageFallbackDirectiveModule } from '../../../directives';

@NgModule({
  declarations: [
    SearchConversationPartnersComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    FormsModule,
    TranslocoModule,
    SearchConversationPartnersEntryModule,
    ImageFallbackDirectiveModule
  ],
  exports: [
    SearchConversationPartnersComponent,
  ],
})
export class SearchCoversationPartnersModule {}
