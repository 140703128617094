<ng-container *transloco="let t">
  <div class="my-40">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ t('GENERIC_WRAPPER.PHONE_NUMBER') }}</mat-panel-title>
          <mat-panel-description>
            <div *ngIf="currentUser.phonePrefix && currentUser.phoneNumber">
              {{
                  currentUser.phonePrefix +
                  ' ' +
                  (currentUser.phonePrefixAfter ?? '') +
                  ' ' +
                  currentUser.phoneNumber
              }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <df-monorepo-phone-input
              [showSuffix]="false"
              [formGroup]="phoneForm"
              (phonePrefixSelected)="onPhonePrefixSelected($event)"
            ></df-monorepo-phone-input>
            <div class="flex-row align-end">
              <button mat-stroked-button color="primary" (click)="savePhoneNumber()">{{ t('GENERIC_WRAPPER.SAVE') }}</button>
            </div>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel>
        <mat-expansion-panel-header>{{ t('USER.CHANGE_EMAIL') }}</mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <form
            class="flex-column"
            [formGroup]="emailForm"
            (ngSubmit)="changeEmail()"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{ t('USER.CURRENT_EMAIL') }}</mat-label>
              <input
                type="email"
                matInput
                formControlName="currentEmail"
                autocomplete="off"
              >
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{ t('USER.NEW_EMAIL') }}</mat-label>
              <input
                type="email"
                autocomplete="off"
                matInput
                formControlName="newEmail"
                (focusout)="onEmailTyped()"
              >
              <mat-error *ngIf="newEmail.hasError('required')">
                {{ t('USER.NEW_EMAIL_FIELD_IS_REQUIRED') }}
              </mat-error>
              <mat-error *ngIf="newEmail.hasError('email') || newEmail.hasError('pattern')">
                {{ t('USER.NEW_EMAIL_FIELD_IS_INVALID') }}
              </mat-error>
              <mat-error *ngIf="newEmail.hasError('mustMatch')">
                {{ t('USER.NEW_EMAIL_FIELD_IS_MUST_MATCH') }}
              </mat-error>
              <mat-error *ngIf="newEmail.hasError('emailUsed')">
                {{ t('USER.NEW_EMAIL_FIELD_IS_EMAIL_ALREADY_TAKEN') }}
              </mat-error>
              <mat-error *ngIf="newEmail.hasError('maxlength')">
                {{ t('USER.NEW_EMAIL_FIELD_MAX_LENGTH') }}
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{ t('USER.CONFIRM_EMAIL') }}</mat-label>
              <input
                type="email"
                matInput
                formControlName="confirmNewEmail"
                autocomplete="off"
              >
              <mat-error *ngIf="confirmNewEmail.hasError('required')">
                {{ t('USER.CONFIRM_EMAIL_FIELD_IS_REQUIRED') }}
              </mat-error>
              <mat-error *ngIf="confirmNewEmail.hasError('email') || confirmNewEmail.hasError('pattern')">
                {{ t('USER.CONFIRM_EMAIL_FIELD_IS_INVALID') }}
              </mat-error>
              <mat-error *ngIf="confirmNewEmail.hasError('mustMatch')">
                {{ t('USER.NEW_EMAIL_FIELD_IS_MUST_MATCH') }}
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{ t('USER.CURRENT_PASSWORD') }}</mat-label>
              <input
                type="password"
                matInput
                formControlName="confirmPassword"
                autocomplete="off"
              >
              <mat-error *ngIf="confirmPassword.hasError('required')">
                {{ t('USER.CURRENT_PASSWORD_FIELD_REQUIRED') }}
              </mat-error>
            </mat-form-field>
  
            <div class="flex-row align-end">
              <button mat-raised-button type="submit" [disabled]="emailForm.invalid">
                {{ t('USER.CHANGE_EMAIL') }}
              </button>
            </div>
          </form>
        </ng-template>
      </mat-expansion-panel>
      
      <mat-expansion-panel>
        <mat-expansion-panel-header>{{ t('USER.CHANGE_PASSWORD') }}</mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <form
            [formGroup]="passwordForm"
            (ngSubmit)="currentUser.hasPassword ? changePassword() : createPassword()"
          >
            <mat-form-field appearance="outline" class="w-100" *ngIf="currentUser.hasPassword">
              <mat-label>{{ t('USER.CURRENT_PASSWORD') }}</mat-label>
              <input
                type="password"
                matInput
                formControlName="currentPassword"
                autocomplete="off"
              >
              <mat-error *ngIf="!errorForPasswordChange">
                {{ t('USER.CURRENT_PASSWORD_ERROR_1') }}
              </mat-error>
              <mat-error *ngIf="errorForPasswordChange">
                {{errorForPasswordChange}}
              </mat-error>
            </mat-form-field>
  
            <mat-form-field
              appearance="outline"
              class="w-100"
              *ngIf="currentUser.hasPassword"
            >
              <mat-label>{{ t('USER.NEW_PASSWORD') }}</mat-label>
              <input
                type="password"
                matInput
                formControlName="newPassword"
                autocomplete="off"
              />
              <mat-error>
                {{ t('USER.NEW_PASSWORD_ERROR') }}</mat-error
              >
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="w-100" *ngIf="currentUser.hasPassword">
              <mat-label>{{ t('USER.REPEAT_PASSWORD') }}</mat-label>
              <input
                type="password"
                matInput
                formControlName="confirmPassword"
                autocomplete="off"
              >
              <mat-error>
                {{ t('USER.REPEAT_PASSWORD_ERROR') }}
              </mat-error>
            </mat-form-field>
            <div *ngIf="sendStatus === 'success'">
              <div class="flex-column align-start">
                <p>{{ t('USER.EMAIL_SENT') }}</p>
              </div>
            </div>
            <div *ngIf="sendStatus === 'error'">
              <div class="flex-column align-start">
                <mat-icon style="color: rosybrown">warning</mat-icon>
                <p>{{ t('GENERIC_WRAPPER.UNSPECIFIED_ERROR') }}</p>
              </div>
            </div>
  
            <div *ngIf="currentUser.hasPassword" class="flex-row align-end">
              <button mat-raised-button type="submit" [disabled]="passwordForm.invalid">
                {{ t('GENERIC_WRAPPER.SAVE') }}
              </button>
            </div>
            <div *ngIf="!currentUser.hasPassword" class="flex-row align-end">
              <button [disabled]="loading" mat-raised-button type="submit">
                <ng-container *ngIf="loading">
                  <mat-spinner style="margin: auto" [diameter]="35"></mat-spinner>
                </ng-container>
                <ng-container *ngIf="!loading">
                  {{ t('USER.SEND_RESET_LINK') }}
                </ng-container>
              </button>
            </div>
          </form>
        </ng-template>
      </mat-expansion-panel>
  
      <mat-expansion-panel>
        <mat-expansion-panel-header>{{ t('USER.DELETE_OR_DEACTIVATE') }}</mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-radio-group
            aria-label="Select an option"
            [(ngModel)]="deleteOrDeactivate"
          >
            <mat-card style="margin-bottom: 10px">
              <mat-radio-button [value]="deleteOrDeactivateEnum.DEACTIVATE">
                {{ t('GENERIC_WRAPPER.DEACTIVATE') }}
              </mat-radio-button>
            </mat-card>
            <mat-card>
              <mat-radio-button [value]="deleteOrDeactivateEnum.DELETE">
                {{t('GENERIC_WRAPPER.DELETE')}}
              </mat-radio-button>
            </mat-card>
          </mat-radio-group>
  
          <form [formGroup]="deleteDeactivateForm" (ngSubmit)="deleteAccount()">
            <div style="margin-top: 15px">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ t('USER.CURRENT_PASSWORD') }}</mat-label>
                <input
                  type="password"
                  matInput
                  formControlName="confirmPassword"
                  autocomplete="off"
                >
                <mat-error *ngIf="!errorForAccountDeletion">
                  {{ t('USER.CURRENT_PASSWORD_ERROR_2') }}
                </mat-error>
                <mat-error *ngIf="errorForAccountDeletion">
                  {{errorForAccountDeletion}}
                </mat-error>
              </mat-form-field>
            </div>
            
            <div class="flex-row align-end mt-20">
              <button mat-raised-button type="submit" color="warn" [disabled]="deleteDeactivateForm.invalid">
                {{
                  deleteOrDeactivate === deleteOrDeactivateEnum.DELETE
                    ? t('USER.DELETE_ACCOUNT')
                    : t('USER.DEACTIVATE_ACCOUNT')
                }}
              </button>
            </div>
            
            <ng-container *ngIf="loading">
              <mat-spinner style="margin: auto" [diameter]="55"></mat-spinner>
            </ng-container>
          </form>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
