<ng-container *transloco="let t">
  <div class="flex-column gap-15 w-100">
    <span class="title">{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.MY_TEAM') }} ({{ danceSchoolToUsers?.length }})</span>
      <table *ngIf="(danceSchoolToUserService.getAsyncIsLoadingDanceManagerToUsers() | async) === false" mat-table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.USERNAME') }}</th>
          <td mat-cell *matCellDef="let element">
            <a [href]="'/app/@' + element.user.username">
              <div class="flex-row-start-center gap-10">
                <img class="circle-icon" [src]="element.user.imageUrl && element.user.imageUrl !== '' ? element.user.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER" alt="" imageFallback [fallbackSrc]="DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
                <span>{{ element.user.username }}</span>
              </div>
            </a>
          </td> 
        </ng-container>
        
        <ng-container matColumnDef="userFirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.FIRSTNAME') }}</th>
          <td mat-cell *matCellDef="let element">{{ element.user.firstName ?? t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.NOT_PUBLIC') }}</td>
        </ng-container>
  
        <ng-container matColumnDef="userLastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.LASTNAME') }}</th>
          <td mat-cell *matCellDef="let element">{{ element.user.lastName ?? t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.NOT_PUBLIC') }}</td>
        </ng-container>
  
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> {{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.ROLE') }}</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ element.danceSchoolUserRole | titlecase }}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="isPublic">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('DANCE_MANAGER_ADMINISTRATION.MANAGE_TEAM.PUBLIC_VISIBLE') }} </th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [disabled]="!isOwnerOrAdmin" [checked]="element.isPublic" #toggle (click)="$event.stopPropagation()" [matTooltip]="toggle.checked ? (t('GENERIC_WRAPPER.DEACTIVATE')) : (t('GENERIC_WRAPPER.PUBLISH'))" (change)="onIsPublicToggle($event,element)" style="padding-left: 15%;">
            </mat-slide-toggle>
          </td>
        </ng-container>
        
        <ng-container *ngIf="isOwnerOrAdmin" matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-column-center-end pr2">
              <button mat-icon-button  id= "team_more_vert_icon" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="editDanceSchoolToUser(element)" mat-menu-item>
                  <span>{{ t('GENERIC_WRAPPER.EDIT') }}</span>
                </button>
                <button (click)="deleteDanceSchoolToUser(element)" mat-menu-item>
                  <span>{{ t('GENERIC_WRAPPER.REMOVE') }}</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="loading-box w-100" *ngIf="(danceSchoolToUserService.getAsyncIsLoadingDanceManagerToUsers() | async) === true" data-cy="loading-box">
          <mat-spinner></mat-spinner>
      </div>
  </div>
</ng-container>
