import { MessagePartnerInterface } from './message-partner.interface';
import { ConversationEntityInterface } from './conversation.interface';

export interface MessageInterface {
  id?: string;
  text?: string;
  createdAt?: Date;
  fromPartner?: MessagePartnerInterface;
  fromPartnerId?: string;
  toPartner?: MessagePartnerInterface;
  toPartnerId?: string;
  conversation?: ConversationEntityInterface;
  conversationId?: string;
}
