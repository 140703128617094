import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfLinkClipboardComponent } from './df-link-clipboard.component';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule } from '../buttons';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from "@angular/material/tooltip";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfLinkClipboardComponent],
    imports: [
      CommonModule,
      MatIconModule,
      DfSharedButtonModule,
      MatButtonModule,
      MatTooltipModule,
      TranslocoDirective,
    ],
  exports: [DfLinkClipboardComponent],
})
export class DfLinkClipboardModule {}
