import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'df-cancel-dialog',
  templateUrl: 'cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
})
export class SharedCancelDialogComponent {
  cancel: string;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  constructor(
    public cancelRef: MatDialogRef<SharedCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      subtitle: string;
      infoText: string;
      cancelText: string;
    }
  ) {}

  close(reasonStay = true): void {
    this.cancelRef.close(reasonStay);
  }
}
