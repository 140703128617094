import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainContentComponent } from './main-content.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../footer';

@NgModule({
  declarations: [
    MainContentComponent
  ],
    imports: [
      CommonModule,
      MatSidenavModule,
      MatIconModule,
      MatButtonModule,
      MatRippleModule,
      RouterModule,
      FooterModule,
    ],
  exports: [
    MainContentComponent
  ]
})
export class MainContentModule {
}
