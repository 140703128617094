import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'df-cancel-dialog',
  templateUrl: 'cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
})
export class CancelDialogComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() buttonLeft: string;
  @Input() buttonRight: string;
  cancel: string;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  constructor(public cancelRef: MatDialogRef<CancelDialogComponent>) {}

  close(): void {
    this.cancelRef.close();
  }
}
