import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DelayDialogInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-free-pass-invite-dialog',
  templateUrl: './delay-dialog.component.html',
  styleUrls: ['./delay-dialog.component.scss'],
})
export class DelayDialogComponent implements OnInit, OnDestroy {
  progress = 0;
  timerId: number;
  intervalId: number;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: DelayDialogInterface,
    private readonly dialogRef: MatDialogRef<DelayDialogComponent>
  ) {}
  
  ngOnInit(): void {
    this.intervalId = window.setInterval(() => {
      this.progress += 100 / (this.data.delayTime / 50);
    }, 50);

    this.timerId = window.setTimeout(() => {
      this.dialogRef.close(true);
    }, this.data.delayTime);
  }
  
  ngOnDestroy(): void {
    clearTimeout(this.timerId);
    clearInterval(this.intervalId);
  }

  onCancel(): void {
    clearTimeout(this.timerId);
    clearInterval(this.intervalId);
    this.dialogRef.close(false);
  }
}
