import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DanceEventCategoryEnum } from '@platri/df-common-shared-graphql';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getSubscription } from '@platri/elab-angular-core';

@Component({
  selector: 'df-shared-lib-search-category',
  templateUrl: './search-category.component.html',
  styleUrls: ['./search-category.component.scss'],
})
export class SearchCategoryComponent implements OnInit, OnChanges {
  @Input() showChips = false;
  @Input() selectedFilters: DanceEventCategoryEnum[]= [];
  @Input() required = false;
  
  @Output() selectedFiltersChanged = new EventEmitter<DanceEventCategoryEnum[]>();

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('categoryInput') categoryInput: ElementRef;

  searchCategoryInput: UntypedFormControl;
  filteredCategoriesList: Observable<DanceEventCategoryEnum[]>;
  clonedCategoriesList: DanceEventCategoryEnum[];
  
  categoriesList = structuredClone(Object.values(DanceEventCategoryEnum));
  subscription: Subscription = new Subscription();
  
  constructor(
    private formBuilder: UntypedFormBuilder, 
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.searchCategoryInput = this.formBuilder.control('');
    this.getCategories();
    this.getFilteredCategories();

    if (this.required) {
      this.searchCategoryInput.addValidators(Validators.required);
    }
  }

  initializeSubscriptions(): void {
    this.subscription.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteQueryParamsChanges.bind(this)));
  }

  onActivatedRouteQueryParamsChanges(params: Params): void {
    if (params['category']) {
      const translationKeys = params['category'].split(',').map((category) => category.toUpperCase());
      this.selectedFilters = this.clonedCategoriesList.filter((category) => translationKeys.includes(category));
      this.selectedFiltersChanged.emit(this.selectedFilters);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedFilters?.currentValue !==
      changes.selectedFilters?.previousValue
    ) {
      this.selectedFilters = this.selectedFilters|| [];
    }
    if (this.searchCategoryInput) {
      this.validateCategories();
    }
  }

  validateCategories(): void {
    if (
      (this.selectedFilters?.length === 0 ||
        this.selectedFilters === null) &&
      this.required
    ) {
      this.searchCategoryInput.setErrors({ required: true });
    } else {
      this.searchCategoryInput.setErrors(null);
    }
  }

  getFilteredCategories(): void {
    this.searchCategoryInput.valueChanges
      .pipe(debounceTime(250))
      .subscribe((input: string | DanceEventCategoryEnum) => {
        this.filteredCategoriesList = of(
          this.clonedCategoriesList.filter((name) => name.toLowerCase().includes(typeof input === 'string' ? input.toLowerCase() : input))
        );
        this.cd.detectChanges();
      });
  }

  getCategories(): void {
    this.clonedCategoriesList = structuredClone(Object.values(DanceEventCategoryEnum));
    this.filteredCategoriesList = of(this.clonedCategoriesList);
    this.initializeSubscriptions();
  }

  selectCategory(event: MatAutocompleteSelectedEvent): void {
    const category = event.option.value;
    if (this.selectedFilters && !this.selectedFilters.includes(category as DanceEventCategoryEnum)) {
      this.selectedFilters =this.selectedFilters?.concat([category as DanceEventCategoryEnum]);
    } else if (this.selectedFilters === null || this.selectedFilters?.length === 0) {
      this.selectedFilters = [category as DanceEventCategoryEnum];
    }
    this.getFilteredCategories();
    this.selectedFiltersChanged.emit(this.selectedFilters);
    this.updateQueryParams();
  }

  removeSelectedCategory(category: string): void {
    const index = this.selectedFilters.indexOf(category as DanceEventCategoryEnum);
    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
      this.selectedFilters = [...this.selectedFilters];
      if (this.selectedFilters.length > 0) {
        this.selectedFiltersChanged.emit(this.selectedFilters);
      } else {
        this.selectedFiltersChanged.emit([]);
      }
      this.updateQueryParams();
    }
  }

  checkCategoryClosed(): void {
    this.searchCategoryInput.setValue('');
    this.categoryInput.nativeElement.value='';
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        category: this.selectedFilters?.length > 0 ? this.selectedFilters.map((category) => category.toLowerCase()).join(',') : null
      },
      queryParamsHandling: 'merge'
    });
  }
 
}
