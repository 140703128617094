import { CurrencyTypeEnum, DanceManagerPayoutDetailElementInterface } from '../../..';

export class DanceManagerPayoutDetailElementResponseDto implements DanceManagerPayoutDetailElementInterface {
  amount: number;
  currency: CurrencyTypeEnum;
  date: Date;
  productsWithQuantity: string[];
  eventId?: string;
  eventName?: string;
  eventDate?: Date;
  eventTimezone?: string;
  username?: string;
  userId?: string;
}
