import { DanceManagerPayoutInterface, DanceManagerPayoutStatusEnum } from '../../..';
import { CurrencyDto } from '../../..';
import { DanceManagerPayoutTransactionDto } from './dance-manager-payout-transaction.dto';

export class DanceManagerPayoutDto implements DanceManagerPayoutInterface {
  id: string;
  stripePayoutId: string;
  amount: number;
  stripeCreatedAt: Date;
  stripeArrivalAt: Date;
  currency: CurrencyDto;
  status: DanceManagerPayoutStatusEnum;
  transactions: DanceManagerPayoutTransactionDto[];
}
