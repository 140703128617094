import { CurrencyInterface } from './currency.interface';
import { DmPassSubscriptionIntervalEnum } from '../enums';
import { DmPassSubscriptionTargetInterface } from './dm-pass-subscription-target.interface';

export interface DmPassSubscriptionInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  currency?: CurrencyInterface;
  taxPercentage?: number;
  price?: number;
  name?: string;
  description?: string;
  createdByUserId?: string;
  danceManagerId?: string;
  sequenceNumber?: number;
  deletedAt?: Date;
  durationValue?: number;
  durationUnit?: DmPassSubscriptionIntervalEnum;
  isPaidMonthly?: boolean;
  weeklyUsage?: number;
  targets?: DmPassSubscriptionTargetInterface[];
  numberOfSales?: number;
  updateActiveSubscription?: boolean;
}
