import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { TicketCheckoutPaymentComponent } from './ticket-checkout-payment.component';
import { TicketCheckoutStripePaymentModule } from '../ticket-checkout-stripe-payment';
import { TicketCheckoutPaypalPaymentModule } from '../ticket-checkout-paypal-payment';

@NgModule({
  exports: [TicketCheckoutPaymentComponent],
  declarations: [TicketCheckoutPaymentComponent],
  imports: [
    CommonModule,
    TicketCheckoutStripePaymentModule,
    MatRadioModule,
    TicketCheckoutPaypalPaymentModule,
  ],
})
export class TicketCheckoutPaymentModule {}
