import { Component } from '@angular/core';
import { dfAppRoute, dfCreateDanceManagerRoute, dfLoginRoute, dfRegisterRoute } from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'df-header-before-logged-in',
  templateUrl: './header-before-logged-in.component.html',
  styleUrls: ['./header-before-logged-in.component.scss'],
})
export class HeaderBeforeLoggedInComponent {
  isMenuOpen = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
  
  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }
  routeToRegister(): void {
    this.router.navigate(['/' + dfAppRoute, dfRegisterRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }

  routeToImprint(): void {
    this.router.navigate(['./legal/imprint']);
  }

  toggleMenuIcon(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

    protected readonly dfCreateDanceManagerRoute = dfCreateDanceManagerRoute;
}
