import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSearchAutocompleteComponent } from './user-search-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ImageFallbackDirectiveModule } from '../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [UserSearchAutocompleteComponent],
  exports: [UserSearchAutocompleteComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ImageFallbackDirectiveModule,
    TranslocoDirective
  ]
})
export class UserSearchAutocompleteModule {}
