<ng-container *transloco="let t">
  <div *ngIf="danceEvent$ | async as danceEventAsync; else searchResult" [ngClass]="small ? 'small' : ''">
    <ng-container [ngTemplateOutlet]="preview" [ngTemplateOutletContext]="{ danceEvent: danceEventAsync }"></ng-container>
  </div>

  <ng-template #searchResult>
    <div style="position: relative;" [ngClass]="small ? 'small' : ''">
      <div *ngIf="danceEvent" class="flex-row-center-center">
        <ng-container [ngTemplateOutlet]="preview" [ngTemplateOutletContext]="{ danceEvent: danceEvent }"></ng-container>
      </div>
      <div style="display: flex; justify-content: center;">
      <div [ngClass]="loading ? 'loading' : 'notLoading'" class="dance-event-preview-card preview-card preview preview-skeleton w-100" [ngStyle]="loading ? {} : { position: 'absolute', top: 0 }">
        <div class="dance-event-preview-card-skeleton-container flex-column h-100" id="skeleton-left-column">
          <div class="preview-image preview-image-skeleton h-100 mb0 block">
            <ngx-skeleton-loader  [theme]="{ width: '100%', height: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
        </div>

        <div class="dance-event-preview-card-skeleton-container flex-column h-100" [ngStyle]="small ? {width: '100%'} : {width: '64%', margin: '20px'}" id="skeleton-right-column">
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '50%', height: '18px', 'margin-bottom': '5px' }"></ngx-skeleton-loader>
          </div>
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '35%', height: '18px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '90%', height: '63px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '100%', height: '20px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #preview let-danceEvent="danceEvent">
    <div [ngClass]="!clickable ? 'preview' : 'preview clickable opacityAnimation'" class="flex-row preview-card w-100">
      <a [target]="clickOpensNewWindow ? '_blank' : '_self'" [routerLink]="routerLink ?? ['/', 'dance-event', danceEvent.id]" style="flex: 1;">
        <div [ngClass]="small ? 'flex-column' : 'flex-row'" class="container-flex-row-responsive-col w-100 h-100" style="gap: 1rem;">
          <div class="flex-column-center-center" id="left-column">
            <div class="flex-column preview-image-container">
              <img [src]="danceEvent.mainImage?.url ?? DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER" alt="" class="preview-image w-100 mb0 block" />
            </div>
          </div>

          <div class="flex-column align-start w-100" id="right-column">
            <div class="flex-row align-space-between" id="appointment-row">
              <span class="appointment-text left-align" *ngIf="danceEvent.appointments && danceEvent.appointments.length > 0">
                {{ danceEvent.appointments[0].startDate | dateRange : danceEvent.appointments[0].duration : currentUser?.appLang ?? null}}
                {{danceEvent.appointments[0].address.timezoneId |timezoneAbbreviation:danceEvent.appointments[0].startDate}}
              </span>
              <div *ngIf="userTicketPage && this.userTicketCollapsed?.isCanceled" class="cancelled-tickets-button align-center">
                <div class="flex-row align-center">
                  <mat-icon class="distance-icon material-icons-round vSub" style="color: #B00020; margin-right: 8px;">info</mat-icon>
                  <span>{{t('GENERIC_WRAPPER.CANCELLED')}}</span>
                </div>
              </div>
            </div>
            
            <div class="flex-row w-100">
              <div class="flex-column preview-header">
                <a [href]="'/app/dance-event/' + danceEvent.id">
                  <span
                    class="dance-event name left-align ellipsis-1"
                    #eventName
                    [matTooltip]="eventName.offsetHeight < eventName.scrollHeight ? danceEvent.name : null"
                    [matTooltipClass]="'fontSize14'"
                    (click)="$event.stopPropagation();"
                  >
                    {{ danceEvent.name }}
                  </span>
                </a>
                <span
                  *ngIf="danceEvent.danceSchool"
                  class="dance-school name left-align ellipsis-1"
                  #danceSchoolName
                  [matTooltip]="danceSchoolName.offsetHeight < danceSchoolName.scrollHeight ? danceEvent.danceSchool.name : null"
                  [matTooltipClass]="'fontSize14'"
                >
                  {{ danceEvent.danceSchool.name }}
                </span>
              </div>
            </div>

            <div class="flex-row eventDescription w-100 h-100 mb5px">
              <div [class.preview-event-description]="!isDmCard" [class.dm-card-preview-event-description]="isDmCard">
                <span class="ellipsis-2" [innerHTML]="danceEvent.description | stripHtmlTags | truncate: (isDmCard ? 100 : 200)"></span>
              </div>
            </div>

            <div *ngIf="!hideSeeEventPageButton" class="flex-row align-start-center mb5px w-100">
              <a [href]="'/app/dance-event/' + danceEvent.id" style="color: #00a1df">
                <span class="flex-row align-start-center span-with-icon" (click)="$event.stopPropagation();">
                  <mat-icon style="padding-right: 30px">open_in_new</mat-icon>
                  {{ t('GENERIC_WRAPPER.SEE_EVENT_PAGE') }}
                </span>
              </a>
            </div>

            <div class="flex-row end-row-distance w-100">
              <div class="flex-column w-100">
                <span *ngIf="danceEvent.cheapestPrice !== null && danceEvent.cheapestPrice > -1">
                  <b>{{ t('GENERIC_WRAPPER.TICKETS_START_AT') }}</b>
                  {{ danceEvent.cheapestPrice | centsToCurrencyString: 'EUR' }}
                </span>
              </div>
              <div class="flex-column distance-space w-100">
                <span *ngIf="!hideDistance && danceEvent.distance !== null && danceEvent.distance >= 0">
                  <mat-icon class="distance-icon material-icons-round vSub">place</mat-icon>
                  {{ danceEvent.distance | roundDistancePipe }}
                  {{ danceEvent.radiusUnit }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </a>
      <div *ngIf="userTicketCollapsed && !this.userTicketCollapsed?.isCanceled" class="p2 download-tickets-button">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
          <mat-icon class="material-icons-round">more_vert</mat-icon>
        </button>
      </div>
      <mat-menu #menu="matMenu" xPosition="before">
        <button *ngFor="let ticketOrderId of userTicketCollapsed?.ticketOrderIds; let index = index" mat-menu-item (click)="downloadTickets.emit({ danceEventId: danceEvent.id, ticketOrderId })">
          <mat-icon class="material-icons-round">file_download</mat-icon>
          <span *ngIf="userTicketCollapsed?.ticketOrderIds.length > 1">Tickets {{ index + 1 }}</span>
          <span *ngIf="userTicketCollapsed?.ticketOrderIds.length === 1">Ticket</span>
        </button>
        <button *ngIf="userTicketCollapsed?.freeUserTicketIds?.length > 0" mat-menu-item (click)="downloadFreeTickets.emit({ danceEventId: danceEvent.id, freeUserTicketIds: userTicketCollapsed.freeUserTicketIds })">
          <mat-icon class="material-icons-round">file_download</mat-icon>
          <span *ngIf="userTicketCollapsed?.freeUserTicketIds.length > 1">Free Tickets</span>
          <span *ngIf="userTicketCollapsed?.freeUserTicketIds.length === 1">Free Ticket</span>
        </button>
      </mat-menu>
    </div>
  </ng-template>
</ng-container>
