import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserHttpService } from '../../services';
import { tap } from 'rxjs/operators';
import {
  CourseAppointmentInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  UsersInterface
} from '@platri/df-common-core';
import { UserAppLangEnum } from '@platri/elab-common-auth-plug-play';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-transfer-dialog',
  templateUrl: './trial-lesson-invite-dialog.component.html',
  styleUrls: ['./trial-lesson-invite-dialog.component.scss'],
})
export class TrialLessonInviteDialogComponent implements OnInit, OnDestroy {
  currentLang: string;
  appointments: CourseAppointmentInterface[];
  
  searchInputChangeSubject = new Subject<string>();
  showUserSearchLoadingSpinner = false;

  userOptions: any[] = [];
  selectedUser: UsersInterface;
  selectedUsername: string;
  selectedCourseAppointment: CourseAppointmentInterface;
  selectedGuestEmail: string;

  subscriptions: Subscription = new Subscription();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { appointments: CourseAppointmentInterface[] },
    private readonly userHttpService: UserHttpService,
    private readonly dialogRef: MatDialogRef<TrialLessonInviteDialogComponent>,
    private readonly translocoService: TranslocoService
  ) {
    this.currentLang = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.appointments = this.data.appointments;
    this.subscribeToSearch();
  }

  onUserSelected($event: any): void {
    this.selectedUser = $event;
    this.selectedUsername = $event.data.username;
  }

  onInputChange($event: string): void {
    this.searchInputChangeSubject.next($event);
  }
  
  onRemovingSelectedUser(): void {
    this.selectedUser = null;
    this.selectedUsername = null;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSendClick(): void {
    this.dialogRef.close({
      selectedUser: this.selectedUser,
      appointment: this.selectedCourseAppointment,
      email: this.selectedGuestEmail
    });
  }
  
  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject.pipe(
        tap(() => (this.showUserSearchLoadingSpinner = true)),
        debounceTime(600)
      ).subscribe((inputText: string) => {
        if (inputText) {
          this.subscriptions.add(
            this.userHttpService.searchUsersByKey(inputText).pipe(tap(() => {
              this.showUserSearchLoadingSpinner = false;
            })).subscribe((users: UsersInterface[]) => {
              this.userOptions = this.mapToOptions(users);
            })
          );
        } else {
          this.userOptions = [];
          this.showUserSearchLoadingSpinner = false;
        }
      })
    );
  }

  private mapToOptions(users: UsersInterface[]): {id: string; value: string; data: UsersInterface; displayValue: string; info: string; imageUrl: string}[] {
    return users.map((user) => ({
      id: user.id,
      value: user.username,
      data: user,
      displayValue: user.username,
      info: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null,
      imageUrl: user.imageUrl ?? null,
    }));
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  protected readonly UserAppLangEnum = UserAppLangEnum;
}
