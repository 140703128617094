import { PaymentProviderEnum, TransactionTypeEnum } from '../enums';

export interface TicketOrderTransactionInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  amount?: number;
  transactionType?: TransactionTypeEnum;
  paymentProvider?: PaymentProviderEnum;
  paymentProviderOrderId?: string;
  paymentProviderData?: object;
  paymentProviderAccountId?: string;
  ticketOrderId?: string;
}
