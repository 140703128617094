import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { skipWhile, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {
  CourseInterface,
  danceManagerUserHasRoles,
  DanceManagerUserRoleEnum,
  DanceSchoolToUserInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER
} from '@platri/df-common-core';

import { MatDialog } from '@angular/material/dialog';
import {
  AuthStateService,
  CoursesStateService,
  DanceManagerHttpService
} from '../../services';

@Component({
  selector: 'df-monorepo-course-page-content',
  templateUrl: './dance-course-page-content.component.html',
  styleUrls: ['./dance-course-page-content.component.scss']
})
export class CoursePageContentComponent implements OnInit, OnDestroy {
  @Input() course: CourseInterface;
  @Input() isPreview = false;
  @Input() passMinPrice = -1;
  isUserEnrolled = false;

  maxAppointmentsDisplayed = 1;
  appointmentsDisplayed = 0;

  isOwnerOrAdmin: boolean;
  danceSchoolToUsers: DanceSchoolToUserInterface[];

  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  googleMapsOptions: google.maps.MapOptions = {
    zoom: 14,
    disableDoubleClickZoom: true,
    clickableIcons: false,
    disableDefaultUI: true,
    draggable: true,
    draggableCursor: 'pointer'
  };

  private subscriptions = new Subscription();
  constructor(
    private readonly matDialog: MatDialog, 
    private readonly authService: AuthStateService, 
    private readonly danceManagerHttpService: DanceManagerHttpService, 
    private readonly router: Router, 
    private readonly coursesService: CoursesStateService,
  ) {}

  ngOnInit(): void {
    this.getDanceSchoolForCourse();
    this.getDanceSchoolToUsersByDanceSchoolId();
  }

  updateDisplayed(): void {
    this.appointmentsDisplayed += +1;
  }

  loadMore(): void {
    this.maxAppointmentsDisplayed += 2;
  }

  checkICurrentUserIsOwnerOrAdmin(): void  {
    this.subscriptions.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        if (user) {
          this.isOwnerOrAdmin = danceManagerUserHasRoles(
            user.id,
            this.danceSchoolToUsers,
            [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN, DanceManagerUserRoleEnum.TEACHER]
          );
        }
      })
    );
  }

  getDanceSchoolForCourse(): void {
    this.subscriptions.add(
      this.danceManagerHttpService
        .getDanceManagerById(this.course.danceManagerId)
        .pipe(skipWhile((val) => val === null))
        .subscribe((result) => {
          this.course.danceManager = result;
        })
    );
  }

  getDanceSchoolToUsersByDanceSchoolId(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceSchoolToUsersByDanceSchoolId(this.course.danceManagerId).subscribe((result) => {
        this.danceSchoolToUsers = result;
        this.checkICurrentUserIsOwnerOrAdmin();
      })
    );
  }

  openGoogleMapsInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  openChat(): void  {
    this.router.navigateByUrl(`messages/${this.course.danceManagerId}`);
  }

  ngOnDestroy(): void {
    if (!this.isPreview) {
      this.coursesService.clear();
      this.subscriptions.unsubscribe();
    }
  }
}
