@if (userPassSubscription) {
  <df-checkout-complete
    [confirmationText]="confirmationText"
    [errorText]="errorText"
    [danceManagerId]="course?.danceManagerId"
    [currentState]="userPassSubscription.status"
    [stateInit]="[userPassSubscriptionStatusEnum.INITIALIZED]"
    [stateSuccess]="[userPassSubscriptionStatusEnum.PAID, userPassSubscriptionStatusEnum.PAYMENT_PENDING]"
    [stateError]="[userPassSubscriptionStatusEnum.PAYMENT_FAILED]"
  />
}
