import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'df-pass-subscription-price-form',
  templateUrl: './pass-subscription-price-form.component.html',
  styleUrls: ['./pass-subscription-price-form.component.scss']
})
export class PassSubscriptionPriceFormComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() numberOfSales: number;
  
  // feesPaidByEnum = FeesPaidByEnum;
  ngOnInit(): void {
    if (this.numberOfSales > 0) {
      this.formGroup.get('isPaidMonthly').disable();
      this.taxPercentage.disable();
    }
  }
  
  get price(): AbstractControl {
    return this.formGroup.get('price');
  }
  get taxPercentage(): AbstractControl {
    return this.formGroup.get('taxPercentage');
  }
}
