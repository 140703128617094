
/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */
export enum AmenitiesTypeEnum {
    LOCATION = "LOCATION",
    ROOM = "ROOM"
}

export enum AppointmentAvailabilityEnum {
    NO_APPOINTMENTS = "NO_APPOINTMENTS",
    NO_FUTURE_APPOINTMENTS = "NO_FUTURE_APPOINTMENTS",
    APPOINTMENTS_AVAILABLE = "APPOINTMENTS_AVAILABLE"
}

export enum AppointmentToUserTypeEnum {
    TEACHER = "TEACHER",
    STUDENT = "STUDENT"
}

export enum SearchPostingTypeEnum {
    DANCE_PARTNER = "DANCE_PARTNER",
    DANCE_GROUP = "DANCE_GROUP"
}

export enum SearchPostingStatusEnum {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    DELETED = "DELETED"
}

export enum FeesPaidByEnum {
    CUSTOMER = "CUSTOMER",
    CREATOR = "CREATOR"
}

export enum DanceClassToUserTypeEnum {
    STUDENT = "STUDENT",
    TEACHER = "TEACHER",
    FAVORITE = "FAVORITE"
}

export enum DanceClassRulesEnum {
    MUST_CONFIRM_ATTENDEE = "MUST_CONFIRM_ATTENDEE",
    FIXED_PAIRS = "FIXED_PAIRS",
    QUEUE_ACTIVE = "QUEUE_ACTIVE",
    LEADER_FOLLOWER_OPTION = "LEADER_FOLLOWER_OPTION",
    ALLOW_SELF_CHECKIN = "ALLOW_SELF_CHECKIN",
    GENDER_RESTRICTION = "GENDER_RESTRICTION",
    AGE_RESTRICTION = "AGE_RESTRICTION"
}

export enum DanceClassGenderEnum {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIVERSE = "DIVERSE"
}

export enum DanceClassCommentEnum {
    NONE = "NONE",
    ALL = "ALL",
    FOLLOWER = "FOLLOWER",
    ATTENDEES = "ATTENDEES"
}

export enum DanceEventCoHostStatus {
    ACCEPTED = "ACCEPTED",
    PENDING = "PENDING"
}

export enum DanceEventTimePhaseEnum {
    UPCOMING = "UPCOMING",
    PAST = "PAST",
    DRAFT = "DRAFT"
}

export enum DanceEventCreationErrorEnum {
    DATE_PAST = "DATE_PAST"
}

export enum DanceEventCategoryEnum {
    PARTY = "PARTY",
    WORKSHOP = "WORKSHOP",
    FESTIVAL = "FESTIVAL",
    CONGRESS = "CONGRESS",
    TRIP = "TRIP",
    CONCERT = "CONCERT",
    COMPETITION = 'COMPETITION',
    OTHER = "OTHER"
}

export enum DanceManagerFeatureName {
    DANCE_EVENTS = "DANCE_EVENTS",
    DANCE_EVENTS_TICKETS = "DANCE_EVENTS_TICKETS",
    COURSES = "COURSES"
}

export enum DanceManagerSubscriptionStatus {
    INITIALIZED = "INITIALIZED",
    ACTIVE = "ACTIVE",
    TERMINATED_PERIOD_END = "TERMINATED_PERIOD_END",
    TERMINATED = "TERMINATED"
}

export enum DanceManagerSubscriptionPaymentStatus {
    PAID = "PAID",
    UNPAID = "UNPAID"
}

export enum DancePartnerGenderEnum {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIVERSE = "DIVERSE",
    NOT_IMPORTANT = "NOT_IMPORTANT"
}

export enum DanceSchoolUserRoleEnum {
    ADMIN = "ADMIN",
    OWNER = "OWNER",
    MANAGER = "MANAGER",
    TEACHER = "TEACHER",
    SALES = "SALES",
    SCANNER = "SCANNER",
    BARKEEPER = "BARKEEPER"
}

export enum DanceManagerSocialLinkTypeEnum {
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    INSTAGRAM = "INSTAGRAM",
    LINKEDIN = "LINKEDIN",
    XING = "XING",
    WEBSITE = "WEBSITE",
    TIKTOK = "TIKTOK",
    YOUTUBE = "YOUTUBE"
}

export enum DanceSchoolTypeEnum {
    DANCESCHOOL = "DANCESCHOOL",
    DANCEORGANISATION = "DANCEORGANISATION"
}

export enum DanceManagerCategoryEnum {
    DANCE_STUDIO = "DANCE_STUDIO",
    DANCER = "DANCER",
    DANCE_GROUP = "DANCE_GROUP",
    TEACHER = "TEACHER",
    CHOREOGRAPHER = "CHOREOGRAPHER",
    ORGANIZER = "ORGANIZER",
    CLUB = "CLUB",
    DISCO = "DISCO",
    DJ = "DJ",
    EVENT_LOCATION = "EVENT_LOCATION"
}

export enum DanceSchoolStatusEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    BANNED = "BANNED"
}

export enum DanceManagerSubscriptionType {
    FREE = "FREE",
    UNLIMITED = "UNLIMITED",
    BRONZE = "BRONZE"
}

export enum DanceStylesEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export enum DanceStyleRoleEnum {
    FOLLOWER = "FOLLOWER",
    LEADER = "LEADER",
    NOT_IMPORTANT = "NOT_IMPORTANT"
}

export enum FollowTargetTypeEnum {
    DANCE_CLASS = "DANCE_CLASS",
    DANCE_EVENT = "DANCE_EVENT",
    DANCE_SCHOOL = "DANCE_SCHOOL",
    ROOM_RENTAL = "ROOM_RENTAL",
    LOCATION = "LOCATION",
    USER = "USER"
}

export enum DanceSchoolRolesEnum {
    ADMIN = "ADMIN",
    OWNER = "OWNER",
    MANAGER = "MANAGER",
    TEACHER = "TEACHER",
    SALES = "SALES",
    SCANNER = "SCANNER",
    BARKEEPER = "BARKEEPER"
}

export enum LocationAddressEnum {
    LOCATION = "LOCATION",
    ADDRESS = "ADDRESS"
}

export enum MusicStyleStatusEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export enum NotificationTypeEnum {
    EMAIL = "EMAIL",
    SMS = "SMS",
    PUSH = "PUSH",
    PERMANENT = "PERMANENT"
}

export enum NotificationStateEnum {
    CREATED = "CREATED",
    PROCESSING = "PROCESSING",
    SENT = "SENT",
    FAILED = "FAILED"
}

export enum NotificationSendingPriorityEnum {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH"
}

export enum NotificationUseCaseEnum {
    NEW_MESSAGE = "NEW_MESSAGE",
    NEW_EVENT_CO_HOST_REQUEST = "NEW_EVENT_CO_HOST_REQUEST",
    NEW_FOLLOW = "NEW_FOLLOW",
    GUEST_LIST_INVITATION = "GUEST_LIST_INVITATION"
}

export enum NotificationTargetTypeEnum {
    DANCE_CLASS = "DANCE_CLASS",
    DANCE_EVENT = "DANCE_EVENT",
    DANCE_SCHOOL = "DANCE_SCHOOL",
    ROOM_RENTAL = "ROOM_RENTAL",
    LOCATION = "LOCATION",
    USER = "USER",
    DANCE_MANAGER = "DANCE_MANAGER"
}

export enum OrderStatusEnum {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    PAID = "PAID",
    PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS",
    REFUND = "REFUND",
    REFUND_PARTIAL = "REFUND_PARTIAL",
    CLOSED = "CLOSED",
    ERROR = "ERROR",
    INVALID = "INVALID"
}

export enum ProductTypeEnum {
    ROOM_RENTAL = "ROOM_RENTAL",
    TICKET = "TICKET"
}

export enum SortDirectionEnum {
    ASC = "ASC",
    DESC = "DESC"
}

export enum LanguageLevelEnum {
    FIRST_LANGUAGE = "FIRST_LANGUAGE",
    FLUENT = "FLUENT",
    MODERATE = "MODERATE",
    BEGINNER = "BEGINNER"
}

export enum RadiusUnitEnum {
    KM = "KM",
    MI = "MI"
}

export enum DanceSchoolRoomTypeEnum {
    BASE = "BASE",
    COMPOSITE = "COMPOSITE"
}

export enum SchedulerTypeEnum {
    SINGLE = "SINGLE",
    SERIES = "SERIES"
}

export enum AppointmentRecurrenceTypeEnum {
    DAILY = "DAILY",
    WEEKLY_NTH = "WEEKLY_NTH",
    MONTHLY_NTH = "MONTHLY_NTH",
    YEARLY = "YEARLY",
    EVERY_MONDAY_TO_FRIDAY = "EVERY_MONDAY_TO_FRIDAY",
    CUSTOM = "CUSTOM"
}

export enum CustomRecurrenceTypeEnum {
    DAY = "DAY",
    WEEK = "WEEK"
}

export enum CustomRecurrenceWeekDaysTypeEnum {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}

export enum CustomRecurrenceEndTypeEnum {
    NEVER = "NEVER",
    DATE = "DATE",
    NUMBER_OF_APPOINTMENTS = "NUMBER_OF_APPOINTMENTS"
}

export enum SocialProviderEnum {
    FACEBOOK = "FACEBOOK",
    GOOGLE = "GOOGLE"
}

export enum TransactionTypeEnum {
    CAPTURE = "CAPTURE",
    REFUND = "REFUND"
}

export enum PaymentProviderEnum {
    STRIPE = "STRIPE",
    PAYPAL = "PAYPAL"
}

export enum CurrencyTypeEnum {
    EUR = "EUR",
    USD = "USD"
}

export enum TicketOrderStatusEnum {
    OPEN = "OPEN",
    PAYMENT_INITIALIZED = "PAYMENT_INITIALIZED",
    PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS",
    PAYMENT_ERROR = "PAYMENT_ERROR",
    PAYMENT_ERROR_REFUND = "PAYMENT_ERROR_REFUND",
    PAYMENT_PENDING = "PAYMENT_PENDING",
    PAID = "PAID",
    PAYMENT_OVERDUE = "PAYMENT_OVERDUE",
    REFUND = "REFUND",
    REFUND_PARTIAL = "REFUND_PARTIAL"
}

export enum TicketOrderErrorEnum {
    ORDER_NOT_DELETABLE = "ORDER_NOT_DELETABLE",
    PAYMENT_FAILED = "PAYMENT_FAILED",
    PAYMENT_FAILED_REFUNDED = "PAYMENT_FAILED_REFUNDED"
}

export enum TicketTypeEnum {
    PAID = "PAID",
    FREE = "FREE"
}

export enum TicketSaleStatusEnum {
    ON_SALE = "ON_SALE",
    SOLD_OUT = "SOLD_OUT",
    UPCOMING_SALE = "UPCOMING_SALE",
    PAST_SALE = "PAST_SALE"
}

export enum ItemUnitTypeEnum {
    DURATION = "DURATION",
    QUANTITY = "QUANTITY"
}

export enum UserRoomRentalStatusEnum {
    CREATED = "CREATED",
    RESERVED = "RESERVED",
    PAID = "PAID",
    CANCELLED = "CANCELLED",
    INVALID = "INVALID"
}

export enum UserRoomRentalErrorEnum {
    ROOM_RENTAL_NOT_FREE = "ROOM_RENTAL_NOT_FREE",
    ROOM_RENTAL_NOT_OPEN = "ROOM_RENTAL_NOT_OPEN",
    ROOM_RENTAL_DATE_PAST = "ROOM_RENTAL_DATE_PAST",
    ROOM_RENTAL_MINIMUM_BOOKING_TIME = "ROOM_RENTAL_MINIMUM_BOOKING_TIME"
}

export enum UserTicketStatusEnum {
    CREATED = "CREATED",
    RESERVED = "RESERVED",
    PAID = "PAID",
    CANCELLED = "CANCELLED",
    INVALID = "INVALID",
    FREE = "FREE",
    SCANNED = "SCANNED"
}

export enum UserTicketErrorEnum {
    USER_TICKET_DATE_PAST = "USER_TICKET_DATE_PAST",
    USER_TICKET_SOLD_OUT = "USER_TICKET_SOLD_OUT",
    USER_TICKET_CREATION_FAILED = "USER_TICKET_CREATION_FAILED",
    USER_TICKET_NOT_AVAILABLE = "USER_TICKET_NOT_AVAILABLE"
}

export enum UserTicketDanceRoleTypeEnum {
    LEADER = "LEADER",
    FOLLOWER = "FOLLOWER"
}

export enum UserStatusEnum {
    STANDARD = "STANDARD",
    FIRST_TIME = "FIRST_TIME",
    PASSWORD_RESET = "PASSWORD_RESET",
    BANNED = "BANNED",
    DEACTIVATED = "DEACTIVATED",
    DELETED = "DELETED",
    GUEST = "GUEST"
}

export enum GenderEnum {
    MALE = "MALE",
    FEMALE = "FEMALE",
    DIVERSE = "DIVERSE"
}

export enum UserRoleEnum {
    USER = "USER",
    SERVICE = "SERVICE",
    ADMIN = "ADMIN"
}

export enum DanceIntervalEnum {
    I_DONT_DANCE = "I_DONT_DANCE",
    EVERY_DAY = "EVERY_DAY",
    TWO_THREE_TIMES_A_WEEK = "TWO_THREE_TIMES_A_WEEK",
    ONCE_A_WEEK = "ONCE_A_WEEK",
    ONCE_A_MONTH = "ONCE_A_MONTH"
}

export enum UserSocialLinkTypeEnum {
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    TIKTOK = "TIKTOK",
    YOUTUBE = "YOUTUBE"
}

export class CoordinatesInput {
    latitude?: Nullable<number>;
    longitude?: Nullable<number>;
    location?: Nullable<string>;
}

export class PaginationInput {
    page?: Nullable<number>;
    limit?: Nullable<number>;
    sortDirection?: Nullable<SortDirectionEnum>;
    sortColumn?: Nullable<string>;
}

export class SearchDanceClassesFilterInput {
    search?: Nullable<string>;
    danceStyles?: Nullable<Nullable<string>[]>;
    startDifficulty?: Nullable<number>;
    endDifficulty?: Nullable<number>;
    longitude?: Nullable<number>;
    latitude?: Nullable<number>;
    radius?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
}

export class SearchDanceEventsFilterInput {
    danceStyles?: Nullable<Nullable<string>[]>;
    musicStyles?: Nullable<Nullable<string>[]>;
    categories?: Nullable<Nullable<DanceEventCategoryEnum>[]>;
    longitude?: Nullable<number>;
    latitude?: Nullable<number>;
    radius?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    startDate?: Nullable<Date>;
    endDate?: Nullable<Date>;
    clientDateUtc?: Nullable<Date>;
}

export class SearchDancePartnerSearchPostingsFilterInput {
    searchPostingTypes?: Nullable<Nullable<SearchPostingTypeEnum>[]>;
    longitude?: Nullable<number>;
    latitude?: Nullable<number>;
    radius?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
}

export class SearchDanceSchoolsFilterInput {
    search?: Nullable<string>;
    danceStyles?: Nullable<Nullable<string>[]>;
    longitude?: Nullable<number>;
    latitude?: Nullable<number>;
    radius?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    categories?: Nullable<Nullable<DanceManagerCategoryEnum>[]>;
}

export class SearchOrdersFilterInput {
    search?: Nullable<string>;
    productType?: Nullable<ProductTypeEnum>;
    status?: Nullable<OrderStatusEnum>;
    danceSchoolId?: Nullable<string>;
}

export class SearchRoomRentalsFilterInput {
    roomSizeFrom?: Nullable<number>;
    roomSizeTo?: Nullable<number>;
    priceFrom?: Nullable<number>;
    priceTo?: Nullable<number>;
    date?: Nullable<Date>;
    timeFrom?: Nullable<TimeInput>;
    timeTo?: Nullable<TimeInput>;
    radius?: Nullable<number>;
    longitude?: Nullable<number>;
    latitude?: Nullable<number>;
}

export class TimeInput {
    hours?: Nullable<number>;
    minutes?: Nullable<number>;
}

export interface SearchResultsInterface1 {
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export interface BaseSearchPosting {
    deletedAt?: Nullable<Date>;
    title: string;
    description: string;
    searchPostingType: SearchPostingTypeEnum;
    searchPostingStatus?: Nullable<SearchPostingStatusEnum>;
    mainImage?: Nullable<Image>;
    images?: Nullable<Nullable<Image>[]>;
    user?: Nullable<User>;
    place?: Nullable<Place>;
}

export interface BaseSearchResult {
    totalElements: number;
    totalPages?: Nullable<number>;
    pageSize?: Nullable<number>;
    pageNumber?: Nullable<number>;
    sortColumn: string;
    sortDirection: string;
}

export interface SearchResultsInterface {
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export interface SysInfos {
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
}

export class Address {
    __typename?: 'Address';
    googleMapsPlaceId?: Nullable<string>;
    street?: Nullable<string>;
    streetNumber?: Nullable<string>;
    city?: Nullable<string>;
    country?: Nullable<string>;
    zipCode?: Nullable<string>;
    formattedAddress?: Nullable<string>;
    latitude?: Nullable<number>;
    longitude?: Nullable<number>;
    googleMapsUrl?: Nullable<string>;
    state?: Nullable<string>;
    timezoneOffsetMinutes?: Nullable<number>;
    isDefault?: Nullable<boolean>;
    addressLine1?: Nullable<string>;
    timezoneId?: Nullable<string>;
    name?: Nullable<string>;
}

export class Amenity {
    __typename?: 'Amenity';
    id?: Nullable<string>;
    iconKey?: Nullable<string>;
    type?: Nullable<AmenitiesTypeEnum>;
}

export class AppointmentComment {
    __typename?: 'AppointmentComment';
    id?: Nullable<string>;
    content?: Nullable<string>;
    appointment?: Nullable<Appointment>;
    user?: Nullable<User>;
}

export class AppointmentSearchResults implements SearchResultsInterface1 {
    __typename?: 'AppointmentSearchResults';
    results?: Nullable<Nullable<Appointment>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class AppointmentToUser {
    __typename?: 'AppointmentToUser';
    appointmentToUserType?: Nullable<AppointmentToUserTypeEnum>;
    appointment?: Nullable<Appointment>;
    user?: Nullable<User>;
}

export class Appointment {
    __typename?: 'Appointment';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    name?: Nullable<string>;
    startDate?: Nullable<Date>;
    duration?: Nullable<number>;
    location?: Nullable<Location>;
    room?: Nullable<Room>;
    address?: Nullable<Address>;
    additionalInformation?: Nullable<string>;
    scheduler?: Nullable<Scheduler>;
    appointmentToUsers?: Nullable<Nullable<AppointmentToUser>[]>;
    content?: Nullable<string>;
    cancelled?: Nullable<boolean>;
    cancellationReason?: Nullable<string>;
    teachers?: Nullable<Nullable<User>[]>;
    students?: Nullable<Nullable<User>[]>;
    comments?: Nullable<Nullable<AppointmentComment>[]>;
    danceStyles?: Nullable<Nullable<DanceStyle>[]>;
    danceClass?: Nullable<DanceClass>;
    clientTimezone?: Nullable<string>;
}

export class AuthDanceSchoolUserRoleJwt {
    __typename?: 'AuthDanceSchoolUserRoleJwt';
    danceSchool?: Nullable<AuthDanceSchoolUserRoleJwtEntry>;
}

export class AuthDanceSchoolUserRoleJwtEntry {
    __typename?: 'AuthDanceSchoolUserRoleJwtEntry';
    ADMIN?: Nullable<Nullable<string>[]>;
    OWNER?: Nullable<Nullable<string>[]>;
    MANAGER?: Nullable<Nullable<string>[]>;
    TEACHER?: Nullable<Nullable<string>[]>;
    SALES?: Nullable<Nullable<string>[]>;
    SCANNER?: Nullable<Nullable<string>[]>;
    BARKEEPER?: Nullable<Nullable<string>[]>;
}

export class AuthDanceSchoolUserRole {
    __typename?: 'AuthDanceSchoolUserRole';
    user?: Nullable<User>;
    danceSchoolUserRole?: Nullable<DanceSchoolUserRoleEnum>;
    validFrom?: Nullable<Date>;
    validUntil?: Nullable<Date>;
    danceSchoolId?: Nullable<string>;
    danceSchoolUrlName?: Nullable<string>;
}

export class AuthMagicLinkToken {
    __typename?: 'AuthMagicLinkToken';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    user?: Nullable<User>;
    magicLinkToken?: Nullable<string>;
}

export class AuthToken {
    __typename?: 'AuthToken';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    user?: Nullable<User>;
    refreshTokenHashed?: Nullable<string>;
}

export class BillingOrShippingAddress {
    __typename?: 'BillingOrShippingAddress';
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    street?: Nullable<string>;
    streetNumber?: Nullable<string>;
    city?: Nullable<string>;
    country?: Nullable<string>;
    zipCode?: Nullable<string>;
    state?: Nullable<string>;
    isDefault?: Nullable<boolean>;
    addressLine1?: Nullable<string>;
}

export class BraintreePay {
    __typename?: 'BraintreePay';
    nonce?: Nullable<string>;
    deviceData?: Nullable<string>;
}

export class Conversation {
    __typename?: 'Conversation';
    id?: Nullable<string>;
    fromPartnerId?: Nullable<string>;
    partnerIds?: Nullable<string>;
    partners?: Nullable<Nullable<MessagePartner>[]>;
    messages?: Nullable<Nullable<Message>[]>;
    readAt?: Nullable<Date>;
}

export class ConversationsWithUnreadMessagesCount {
    __typename?: 'ConversationsWithUnreadMessagesCount';
    unreadMessagesCount?: Nullable<number>;
    conversations?: Nullable<Nullable<Conversation>[]>;
}

export class CreateAppointment {
    __typename?: 'CreateAppointment';
    name?: Nullable<string>;
    startDate?: Nullable<Date>;
    duration?: Nullable<number>;
    locationId?: Nullable<string>;
    roomId?: Nullable<string>;
    address?: Nullable<Address>;
    additionalInformation?: Nullable<string>;
    schedulerId?: Nullable<string>;
    appointmentToUsers?: Nullable<Nullable<AppointmentToUser>[]>;
    content?: Nullable<string>;
    danceClassId?: Nullable<string>;
    danceEventId?: Nullable<string>;
    clientTimezone?: Nullable<string>;
}

export class CreateDanceClass {
    __typename?: 'CreateDanceClass';
    name?: Nullable<string>;
    danceSchoolId?: Nullable<string>;
    description?: Nullable<string>;
    startDifficulty?: Nullable<number>;
    endDifficulty?: Nullable<number>;
    tags?: Nullable<Nullable<string>[]>;
    danceStyles?: Nullable<Nullable<DanceStyle>[]>;
    danceClassToUsers?: Nullable<Nullable<DanceClassToUser>[]>;
    clientTimezone?: Nullable<string>;
    images?: Nullable<Nullable<Image>[]>;
    previewImage?: Nullable<Image>;
    titleImage?: Nullable<Image>;
    restrictions?: Nullable<DanceClassRestrictions>;
    teachers?: Nullable<Nullable<User>[]>;
}

export class CreateDanceEvent {
    __typename?: 'CreateDanceEvent';
    name?: Nullable<string>;
    danceStyleIds?: Nullable<Nullable<string>[]>;
    musicStyleIds?: Nullable<Nullable<string>[]>;
    description?: Nullable<string>;
    restrictions?: Nullable<DanceEventRestrictions>;
    rules?: Nullable<DanceEventRules>;
    mainImage?: Nullable<Image>;
    url?: Nullable<string>;
    tags?: Nullable<Nullable<string>[]>;
    danceSchoolId?: Nullable<string>;
    isActive?: Nullable<boolean>;
    startDate?: Nullable<Date>;
    duration?: Nullable<number>;
    locationId?: Nullable<string>;
    address?: Nullable<Address>;
    coHosts?: Nullable<Nullable<string>[]>;
}

export class Currency {
    __typename?: 'Currency';
    id?: Nullable<string>;
    short?: Nullable<string>;
    detailed?: Nullable<string>;
    sign?: Nullable<string>;
}

export class DanceClassRestrictions {
    __typename?: 'DanceClassRestrictions';
    clothingRestriction?: Nullable<Restriction>;
}

export class Restriction {
    __typename?: 'Restriction';
    isRestricted?: Nullable<boolean>;
    restriction?: Nullable<string>;
}

export class DanceClassRules {
    __typename?: 'DanceClassRules';
    participationRestriction?: Nullable<ParticipationRestriction>;
}

export class ParticipationRestriction {
    __typename?: 'ParticipationRestriction';
    isAgeRestricted?: Nullable<boolean>;
    isGenderRestricted?: Nullable<boolean>;
    ageRestriction?: Nullable<AgeRestriction>;
    genderRestriction?: Nullable<GenderRestriction>;
}

export class AgeRestriction {
    __typename?: 'AgeRestriction';
    fromAge?: Nullable<number>;
    toAge?: Nullable<number>;
}

export class GenderRestriction {
    __typename?: 'GenderRestriction';
    isMaleRestricted?: Nullable<boolean>;
    isFemaleRestricted?: Nullable<boolean>;
    isDiverseRestricted?: Nullable<boolean>;
}

export class DanceClassToUser implements SysInfos {
    __typename?: 'DanceClassToUser';
    id?: Nullable<string>;
    danceClassToUserType?: Nullable<DanceClassToUserTypeEnum>;
    danceClass?: Nullable<DanceClass>;
    user?: Nullable<User>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
}

export class DanceClass implements SysInfos {
    __typename?: 'DanceClass';
    id?: Nullable<string>;
    name?: Nullable<string>;
    danceSchool?: Nullable<DanceSchool>;
    description?: Nullable<string>;
    startDifficulty?: Nullable<number>;
    endDifficulty?: Nullable<number>;
    tags?: Nullable<Nullable<string>[]>;
    rules?: Nullable<DanceClassRules>;
    genderRules?: Nullable<Nullable<DanceClassGenderEnum>[]>;
    commentOption?: Nullable<DanceClassCommentEnum>;
    fromAge?: Nullable<number>;
    toAge?: Nullable<number>;
    danceStyles?: Nullable<Nullable<DanceStyle>[]>;
    schedulers?: Nullable<Nullable<Scheduler>[]>;
    danceClassToUsers?: Nullable<Nullable<DanceClassToUser>[]>;
    clientTimezone?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    images?: Nullable<Nullable<Image>[]>;
    previewImage?: Nullable<Image>;
    titleImage?: Nullable<Image>;
    restrictions?: Nullable<DanceClassRestrictions>;
    teachers?: Nullable<Nullable<User>[]>;
    students?: Nullable<Nullable<User>[]>;
    appointments?: Nullable<Nullable<Appointment>[]>;
    maxParticipants?: Nullable<number>;
}

export class SearchResultDanceClasses implements BaseSearchResult {    __typename?: 'SearchResultDanceClassesDto';
    elements?: Nullable<Nullable<SearchResultDanceClass>[]>;
    totalElements: number;
    totalPages?: Nullable<number>;
    pageSize?: Nullable<number>;
    pageNumber?: Nullable<number>;
    sortColumn: string;
    sortDirection: string;
}

export class SearchResultDanceClass {
    __typename?: 'SearchResultDanceClass';
    danceClassToUser?: Nullable<Nullable<DanceClassToUser>[]>;
    id?: Nullable<string>;
    danceClassDescription?: Nullable<string>;
    danceClassName?: Nullable<string>;
    startDifficulty?: Nullable<number>;
    endDifficulty?: Nullable<number>;
    formattedAddress?: Nullable<string>;
    danceClassRules?: Nullable<DanceClassRulesEnum>;
    danceClassGenderRules?: Nullable<DanceClassGenderEnum>;
    fromAge?: Nullable<number>;
    toAge?: Nullable<number>;
    danceManagerId?: Nullable<string>;
    danceManagerName?: Nullable<string>;
    danceManagerPhonePrefix?: Nullable<string>;
    danceSchoolType?: Nullable<DanceSchoolTypeEnum>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    teachers?: Nullable<Nullable<User>[]>;
    students?: Nullable<Nullable<User>[]>;
    favoriteByUsers?: Nullable<Nullable<User>[]>;
    danceStyles?: Nullable<Nullable<string>[]>;
}

export class DanceEventCoHost {
    __typename?: 'DanceEventCoHost';
    danceManager?: Nullable<DanceSchool>;
    danceEvent?: Nullable<DanceEvent>;
    danceManagerId?: Nullable<string>;
    danceEventId?: Nullable<string>;
    status?: Nullable<DanceEventCoHostStatus>;
}

export class DanceEventRestrictions {
    __typename?: 'DanceEventRestrictions';
    clothingRestriction?: Nullable<Restriction>;
}

export class DanceEventRules {
    __typename?: 'DanceEventRules';
    participationRestriction?: Nullable<ParticipationRestriction>;
}

export class DanceEventUserTicketStatistics {
    __typename?: 'DanceEventUserTicketStatistics';
    totalStatistics?: Nullable<DanceEventTicketStatistics>;
    ticketStatistics?: Nullable<Nullable<DanceEventTicketStatistics>[]>;
    userTicketsCollapsedByUser?: Nullable<Nullable<UserTicketCollapsedByUser>[]>;
}

export class DanceEventTicketStatistics {
    __typename?: 'DanceEventTicketStatistics';
    ticketId?: Nullable<string>;
    ticketName?: Nullable<string>;
    quantity?: Nullable<number>;
    quantityWithoutFreeTickets?: Nullable<number>;
    soldQuantity?: Nullable<number>;
    checkedInQuantity?: Nullable<number>;
    checkedInQuantityWithoutFreeTickets?: Nullable<number>;
    soldQuantityWithoutFreeTickets?: Nullable<number>;
    soldLeaderQuantity?: Nullable<number>;
    soldFollowerQuantity?: Nullable<number>;
    checkedInLeaderQuantity?: Nullable<number>;
    checkedInFollowerQuantity?: Nullable<number>;
}

export class DanceEvent implements SysInfos {
    __typename?: 'DanceEvent';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    name?: Nullable<string>;
    danceStyles?: Nullable<Nullable<DanceStyle>[]>;
    musicStyles?: Nullable<Nullable<MusicStyle>[]>;
    description?: Nullable<string>;
    restrictions?: Nullable<DanceEventRestrictions>;
    rules?: Nullable<DanceEventRules>;
    mainImage?: Nullable<Image>;
    url?: Nullable<string>;
    tags?: Nullable<Nullable<string>[]>;
    danceSchool?: Nullable<DanceSchool>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    appointments?: Nullable<Nullable<Appointment>[]>;
    isActive?: Nullable<boolean>;
    isSoldOut?: Nullable<boolean>;
    cheapestPrice?: Nullable<number>;
    hasSoldTickets?: Nullable<boolean>;
    coHosts?: Nullable<Nullable<DanceEventCoHost>[]>;
    categories?: Nullable<Nullable<DanceEventCategoryEnum>[]>;
}

export class DanceEventWithTickets implements SysInfos {
    __typename?: 'DanceEventWithTickets';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    name?: Nullable<string>;
    danceStyles?: Nullable<Nullable<DanceStyle>[]>;
    description?: Nullable<string>;
    restrictions?: Nullable<DanceEventRestrictions>;
    rules?: Nullable<DanceEventRules>;
    mainImage?: Nullable<Image>;
    url?: Nullable<string>;
    tags?: Nullable<Nullable<string>[]>;
    danceSchool?: Nullable<DanceSchool>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    appointments?: Nullable<Nullable<Appointment>[]>;
    tickets?: Nullable<Nullable<Ticket>[]>;
}

export class DanceManagerFeature {
    __typename?: 'DanceManagerFeature';
    id?: Nullable<string>;
    name?: Nullable<DanceManagerFeatureName>;
    description?: Nullable<string>;
}

export class DanceManagerSubscriptionInvoice implements SysInfos {
    __typename?: 'DanceManagerSubscriptionInvoice';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    danceManagerSubscription?: Nullable<DanceManagerSubscription>;
    stripeInvoiceId?: Nullable<string>;
    stripeInvoicePdfDownloadUrl?: Nullable<string>;
}

export class DanceManagerSubscription implements SysInfos {
    __typename?: 'DanceManagerSubscription';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    danceSchool?: Nullable<DanceSchool>;
    subscriptionStatus?: Nullable<DanceManagerSubscriptionStatus>;
    subscriptionPaymentStatus?: Nullable<DanceManagerSubscriptionPaymentStatus>;
    stripePriceId?: Nullable<string>;
    danceManagerSubscriptionInvoices?: Nullable<Nullable<DanceManagerSubscriptionInvoice>[]>;
    stripeSubscriptionId?: Nullable<string>;
    subscriptionPlan?: Nullable<string>;
    subscriptionInterval?: Nullable<string>;
    subscriptionPaymentMethod?: Nullable<string>;
    startedAt?: Nullable<Date>;
    currentPeriodStartAt?: Nullable<Date>;
    currentPeriodEndAt?: Nullable<Date>;
    endedAt?: Nullable<Date>;
    canceledAt?: Nullable<Date>;
    totalPrice?: Nullable<number>;
}

export class DancePartnerSearchPostingSearchResults {
    __typename?: 'DancePartnerSearchPostingSearchResults';
    results?: Nullable<Nullable<DancePartnerSearchPosting>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class DancePartnerSearchPosting implements BaseSearchPosting, SysInfos {
    __typename?: 'DancePartnerSearchPosting';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    deletedAt?: Nullable<Date>;
    title: string;
    description: string;
    searchPostingType: SearchPostingTypeEnum;
    searchPostingStatus?: Nullable<SearchPostingStatusEnum>;
    mainImage?: Nullable<Image>;
    images?: Nullable<Nullable<Image>[]>;
    user?: Nullable<User>;
    dancePartnerGender?: Nullable<DancePartnerGenderEnum>;
    heightFrom?: Nullable<number>;
    heightTo?: Nullable<number>;
    ageFrom?: Nullable<number>;
    ageTo?: Nullable<number>;
    dancePartnerDanceRole?: Nullable<DanceStyleRoleEnum>;
    dancePartnerSearchPostingsToDanceStyles?: Nullable<Nullable<DancePartnerSearchPostingsToDanceStyles>[]>;
    place?: Nullable<Place>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
}

export class DancePartnerSearchPostingsToDanceStyles {
    __typename?: 'DancePartnerSearchPostingsToDanceStyles';
    danceStyle?: Nullable<DanceStyle>;
    experienceFrom?: Nullable<number>;
    experienceTo?: Nullable<number>;
}

export class DanceSchoolFaq {
    __typename?: 'DanceSchoolFaq';
    id?: Nullable<string>;
    created?: Nullable<Date>;
    updated?: Nullable<Date>;
    deletedDate?: Nullable<Date>;
    question?: Nullable<string>;
    answer?: Nullable<string>;
    danceSchool?: Nullable<DanceSchool>;
    sequenceNumber?: Nullable<number>;
}

export class DanceSchoolToUser implements SysInfos {
    __typename?: 'DanceSchoolToUser';
    id?: Nullable<string>;
    userId?: Nullable<string>;
    danceSchoolUserRole?: Nullable<DanceSchoolUserRoleEnum>;
    danceSchool?: Nullable<DanceSchool>;
    danceSchoolId?: Nullable<string>;
    user?: Nullable<User>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    isPublic?: Nullable<boolean>;
}

export class DanceSchool {
    __typename?: 'DanceSchool';
    id?: Nullable<string>;
    name?: Nullable<string>;
    phonePrefix?: Nullable<string>;
    phonePrefixAfter?: Nullable<string>;
    phoneNumber?: Nullable<string>;
    urlName?: Nullable<string>;
    danceSchoolType?: Nullable<DanceSchoolTypeEnum>;
    imageUrl?: Nullable<string>;
    imageGalleryUrls?: Nullable<Nullable<string>[]>;
    danceClasses?: Nullable<Nullable<DanceClass>[]>;
    danceSchoolToUsers?: Nullable<Nullable<DanceSchoolToUser>[]>;
    email?: Nullable<string>;
    socialLinks?: Nullable<Nullable<DanceManagerSocialLink>[]>;
    danceSchoolStatus?: Nullable<DanceSchoolStatusEnum>;
    description?: Nullable<string>;
    teamDescription?: Nullable<string>;
    locations?: Nullable<Nullable<Location>[]>;
    faqs?: Nullable<Nullable<DanceSchoolFaq>[]>;
    titleImage?: Nullable<Image>;
    danceManagerCategory?: Nullable<Nullable<DanceManagerCategoryEnum>[]>;
    danceManagerSubscriptionType?: Nullable<DanceManagerSubscriptionType>;
    danceManagerSubscriptionId?: Nullable<string>;
    danceManagerSubscriptions?: Nullable<Nullable<DanceManagerSubscription>[]>;
    stripePayoutsEnabled?: Nullable<boolean>;
    stripeTotalSaldo?: Nullable<number>;
    businessAddress?: Nullable<Address>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    danceStyles?: Nullable<Nullable<DanceStyle>[]>;
}

export class DanceManagerSocialLink {
    __typename?: 'DanceManagerSocialLink';
    type?: Nullable<DanceManagerSocialLinkTypeEnum>;
    url?: Nullable<string>;
}

export class DanceStyle {
    __typename?: 'DanceStyle';
    id?: Nullable<string>;
    translationKey?: Nullable<string>;
    danceStyleStatus?: Nullable<DanceStylesEnum>;
    isPairDance?: Nullable<boolean>;
}

export class FollowCount {
    __typename?: 'FollowCount';
    followCount?: Nullable<number>;
    followerCount?: Nullable<number>;
    isCurrentUserFollowing?: Nullable<boolean>;
    followId?: Nullable<string>;
}

export class FollowSearchResults {
    __typename?: 'FollowSearchResults';
    results?: Nullable<Nullable<Follow>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class FollowUser {
    __typename?: 'FollowUser';
    follow?: Nullable<Follow>;
    user?: Nullable<FollowedUser>;
    isCurrentUserFollowing?: Nullable<boolean>;
    currentUserFollowId?: Nullable<string>;
    danceSchool?: Nullable<FollowedDanceSchool>;
}

export class FollowedUser {
    __typename?: 'FollowedUser';
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    imageUrl?: Nullable<string>;
    username?: Nullable<string>;
    id?: Nullable<string>;
}

export class FollowedDanceSchool {
    __typename?: 'FollowedDanceSchool';
    id?: Nullable<string>;
    name?: Nullable<string>;
    imageUrl?: Nullable<string>;
    urlName?: Nullable<string>;
}

export class Follow implements SysInfos {
    __typename?: 'Follow';
    id: string;
    userId: string;
    danceSchoolId?: Nullable<string>;
    danceClassId?: Nullable<string>;
    danceEventId?: Nullable<string>;
    roomRentalId?: Nullable<string>;
    locationId?: Nullable<string>;
    followedUserId?: Nullable<string>;
    targetType: FollowTargetTypeEnum;
    isFollowing: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export class Image {
    __typename?: 'Image';
    url?: Nullable<string>;
    title?: Nullable<string>;
    alt?: Nullable<string>;
    deleted?: Nullable<boolean>;
}

export class JwtPayload {
    __typename?: 'JwtPayload';
    id?: Nullable<string>;
    email?: Nullable<string>;
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    username?: Nullable<string>;
    status?: Nullable<UserStatusEnum>;
    appLang?: Nullable<string>;
    password?: Nullable<string>;
    userRole?: Nullable<UserRoleEnum>;
    roles?: Nullable<Roles>;
}

export class Roles {
    __typename?: 'Roles';
    danceSchool?: Nullable<DanceSchoolRoles>;
}

export class DanceSchoolRoles {
    __typename?: 'DanceSchoolRoles';
    ADMIN?: Nullable<Nullable<string>[]>;
    OWNER?: Nullable<Nullable<string>[]>;
    MANAGER?: Nullable<Nullable<string>[]>;
    TEACHER?: Nullable<Nullable<string>[]>;
    SALES?: Nullable<Nullable<string>[]>;
    SCANNER?: Nullable<Nullable<string>[]>;
    BARKEEPER?: Nullable<Nullable<string>[]>;
}

export class Language implements SysInfos {
    __typename?: 'Language';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    key?: Nullable<string>;
    nativeName?: Nullable<string>;
    settingsLanguage?: Nullable<boolean>;
}

export class Location {
    __typename?: 'Location';
    id?: Nullable<string>;
    name?: Nullable<string>;
    address?: Nullable<Address>;
    phonePrefix?: Nullable<string>;
    phonePrefixAfter?: Nullable<string>;
    phoneNumber?: Nullable<string>;
    email?: Nullable<string>;
    amenities?: Nullable<Nullable<Amenity>[]>;
    openingHours?: Nullable<OpeningHoursInterface>;
    showOpeningHours?: Nullable<boolean>;
    rooms?: Nullable<Nullable<Room>[]>;
    danceSchool?: Nullable<DanceSchool>;
    images?: Nullable<Nullable<Image>[]>;
    previewImage?: Nullable<Image>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    isPublic?: Nullable<boolean>;
}

export class LoginTokenResponse {
    __typename?: 'LoginTokenResponse';
    accessToken: string;
    refreshToken?: Nullable<string>;
}

export class LoginUser {
    __typename?: 'LoginUser';
    email?: Nullable<string>;
    username?: Nullable<string>;
    password: string;
    adminApp?: Nullable<boolean>;
}

export class MessagePartner {
    __typename?: 'MessagePartner';
    id?: Nullable<string>;
    username?: Nullable<string>;
    userId?: Nullable<string>;
    info?: Nullable<string>;
    imageUrl?: Nullable<string>;
}

export class Message {
    __typename?: 'Message';
    id?: Nullable<string>;
    text?: Nullable<string>;
    createdAt?: Nullable<Date>;
    fromPartnerId?: Nullable<string>;
}

export class MusicStyle {
    __typename?: 'MusicStyle';
    id?: Nullable<string>;
    translationKey?: Nullable<string>;
    musicStyleStatus?: Nullable<MusicStyleStatusEnum>;
}

export class Notification implements SysInfos {
    __typename?: 'Notification';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    type?: Nullable<NotificationTypeEnum>;
    useCase?: Nullable<NotificationUseCaseEnum>;
    state?: Nullable<NotificationStateEnum>;
    sendingPriority?: Nullable<NotificationSendingPriorityEnum>;
    data?: Nullable<Object>;
}

export class OpeningHoursInterface {
    __typename?: 'OpeningHoursInterface';
    monday?: Nullable<Nullable<TimeRange>[]>;
    tuesday?: Nullable<Nullable<TimeRange>[]>;
    wednesday?: Nullable<Nullable<TimeRange>[]>;
    thursday?: Nullable<Nullable<TimeRange>[]>;
    friday?: Nullable<Nullable<TimeRange>[]>;
    saturday?: Nullable<Nullable<TimeRange>[]>;
    sunday?: Nullable<Nullable<TimeRange>[]>;
}

export class OrderItem implements SysInfos {
    __typename?: 'OrderItem';
    id?: Nullable<string>;
    priceNet?: Nullable<number>;
    priceGross?: Nullable<number>;
    priceTax?: Nullable<number>;
    currency?: Nullable<Currency>;
    taxPercentage?: Nullable<number>;
    status?: Nullable<OrderStatusEnum>;
    order?: Nullable<Order>;
    productType?: Nullable<ProductTypeEnum>;
    productSpecificId?: Nullable<string>;
    productSpecificData?: Nullable<Object>;
    unit?: Nullable<Unit>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    roomRentalOrder?: Nullable<RoomRentalOrder>;
    errors?: Nullable<Nullable<Object>[]>;
}

export class OrderItemAddUpdate {
    __typename?: 'OrderItemAddUpdate';
    orderId?: Nullable<string>;
    productSpecificId?: Nullable<string>;
    productSpecificData?: Nullable<Object>;
    unit?: Nullable<Unit>;
    productType?: Nullable<ProductTypeEnum>;
}

export class OrderItemsSearchResult {
    __typename?: 'OrderItemsSearchResult';
    results?: Nullable<Nullable<OrderItem>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class OrderSearchResults implements SearchResultsInterface {
    __typename?: 'OrderSearchResults';
    results?: Nullable<Nullable<Order>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class Order implements SysInfos {
    __typename?: 'Order';
    totalNet?: Nullable<number>;
    totalGross?: Nullable<number>;
    totalTax?: Nullable<number>;
    currency?: Nullable<Currency>;
    status?: Nullable<OrderStatusEnum>;
    billingAddress?: Nullable<BillingOrShippingAddress>;
    shippingAddress?: Nullable<BillingOrShippingAddress>;
    useBillingAsShipping?: Nullable<boolean>;
    user?: Nullable<User>;
    userId?: Nullable<string>;
    orderItems?: Nullable<Nullable<OrderItem>[]>;
    orderItemsCollapsed?: Nullable<Nullable<OrderItemsCollapsed>[]>;
    id?: Nullable<string>;
    updatedAt?: Nullable<Date>;
    createdAt?: Nullable<Date>;
    orderId?: Nullable<string>;
    invoiceId?: Nullable<string>;
}

export class OrderItemsCollapsed {
    __typename?: 'OrderItemsCollapsed';
    productSpecificId?: Nullable<string>;
    productSpecificData?: Nullable<Object>;
    productType?: Nullable<ProductTypeEnum>;
    elements?: Nullable<Nullable<OrderItem>[]>;
    totalNet?: Nullable<number>;
    totalGross?: Nullable<number>;
    totalTax?: Nullable<number>;
}

export class OrderAddressUpdate {
    __typename?: 'OrderAddressUpdate';
    billingAddress?: Nullable<BillingOrShippingAddress>;
    shippingAddress?: Nullable<BillingOrShippingAddress>;
    useBillingAsShipping?: Nullable<boolean>;
}

export class Pagination {
    __typename?: 'Pagination';
    page?: Nullable<number>;
    limit?: Nullable<number>;
    sortDirection?: Nullable<SortDirectionEnum>;
    sortColumn?: Nullable<string>;
}

export class Place {
    __typename?: 'Place';
    city?: Nullable<string>;
    country?: Nullable<string>;
    latitude?: Nullable<number>;
    longitude?: Nullable<number>;
    state?: Nullable<string>;
}

export class Profile implements SysInfos {
    __typename?: 'Profile';
    id?: Nullable<string>;
    username?: Nullable<string>;
    email?: Nullable<string>;
    salt?: Nullable<string>;
    password?: Nullable<string>;
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    description?: Nullable<string>;
    providerId?: Nullable<string>;
    provider?: Nullable<string>;
    imageUrl?: Nullable<string>;
    emailVerified?: Nullable<boolean>;
    status?: Nullable<UserStatusEnum>;
    jobTitle?: Nullable<string>;
    birthday?: Nullable<Date>;
    birthPlace?: Nullable<string>;
    birthName?: Nullable<string>;
    formerPlace?: Nullable<string>;
    dancingArea?: Nullable<string>;
    height?: Nullable<number>;
    gender?: Nullable<GenderEnum>;
    appLang?: Nullable<string>;
    userRole?: Nullable<UserRoleEnum>;
    currentHashedRefreshToken?: Nullable<string>;
    socialProviders?: Nullable<Nullable<SocialProvider>[]>;
    addresses?: Nullable<Nullable<BillingOrShippingAddress>[]>;
    lookingFor?: Nullable<Nullable<string>[]>;
    socialLinks?: Nullable<Nullable<UserSocialLink>[]>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    monthsDancing?: Nullable<number>;
    danceInterval?: Nullable<DanceIntervalEnum>;
    danceStyles?: Nullable<Nullable<ProfileDanceStyle>[]>;
    wants?: Nullable<Nullable<string>[]>;
    languageSkills?: Nullable<Nullable<LanguageSkill>[]>;
    phonePrefix?: Nullable<string>;
    phonePrefixAfter?: Nullable<string>;
    phoneNumber?: Nullable<string>;
    isAgePublic?: Nullable<boolean>;
    isNamePublic?: Nullable<boolean>;
}

export class ProfileDanceStyle {
    __typename?: 'ProfileDanceStyle';
    translationKey?: Nullable<string>;
    experience?: Nullable<number>;
    isPairDance?: Nullable<boolean>;
    isLeader?: Nullable<boolean>;
    isFollower?: Nullable<boolean>;
}

export class LanguageSkill {
    __typename?: 'LanguageSkill';
    key?: Nullable<string>;
    languageLevel?: Nullable<LanguageLevelEnum>;
}

export class RoomRentalOrder {
    __typename?: 'RoomRentalOrder';
    roomRental?: Nullable<RoomRental>;
    room?: Nullable<Room>;
    rentalDate?: Nullable<Date>;
    timeFrom?: Nullable<Time>;
    timeTo?: Nullable<Time>;
    status?: Nullable<OrderStatusEnum>;
    orderItem?: Nullable<OrderItem>;
    user?: Nullable<User>;
    danceSchool?: Nullable<DanceSchool>;
}

export class RoomRental implements SysInfos {
    __typename?: 'RoomRental';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    maxParticipants?: Nullable<number>;
    bookingHours?: Nullable<OpeningHoursInterface>;
    pricePerHour?: Nullable<number>;
    image?: Nullable<Image>;
    description?: Nullable<string>;
    minimumBookingTime?: Nullable<number>;
    status?: Nullable<boolean>;
    room?: Nullable<Room>;
    roomRentalOrders?: Nullable<Nullable<RoomRentalOrder>[]>;
    currency?: Nullable<Currency>;
    taxPercentage?: Nullable<number>;
    deletedAt?: Nullable<Date>;
    danceSchool?: Nullable<DanceSchool>;
    distance?: Nullable<number>;
    radiusUnit?: Nullable<RadiusUnitEnum>;
    equipmentUsable?: Nullable<boolean>;
    feesPaidBy?: Nullable<FeesPaidByEnum>;
}

export class Room {
    __typename?: 'Room';
    id?: Nullable<string>;
    name?: Nullable<string>;
    size?: Nullable<number>;
    amenities?: Nullable<Nullable<Amenity>[]>;
    images?: Nullable<Nullable<Image>[]>;
    type?: Nullable<DanceSchoolRoomTypeEnum>;
    location?: Nullable<Location>;
    locationId?: Nullable<string>;
    rooms?: Nullable<Nullable<Room>[]>;
}

export class Scheduler implements SysInfos {
    __typename?: 'Scheduler';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    startDate?: Nullable<Date>;
    endDate?: Nullable<Date>;
    appointmentDuration?: Nullable<number>;
    additionalInformation?: Nullable<string>;
    schedulerType?: Nullable<SchedulerTypeEnum>;
    recurrenceType?: Nullable<AppointmentRecurrenceTypeEnum>;
    customRecurrenceType?: Nullable<CustomRecurrenceTypeEnum>;
    customRecurrenceWeekDaysTypes?: Nullable<Nullable<CustomRecurrenceWeekDaysTypeEnum>[]>;
    customRecurrenceEndType?: Nullable<CustomRecurrenceEndTypeEnum>;
    customRecurrenceNumber?: Nullable<number>;
    numberOfAppointments?: Nullable<number>;
    appointments?: Nullable<Nullable<Appointment>[]>;
    address?: Nullable<Address>;
    danceClass?: Nullable<DanceClass>;
    location?: Nullable<Location>;
    room?: Nullable<Room>;
    appointmentToUsers?: Nullable<Nullable<AppointmentToUser>[]>;
}

export class SearchResults {
    __typename?: 'SearchResults';
    results?: Nullable<Nullable<SearchResult>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class SocialLoginProfile {
    __typename?: 'SocialLoginProfile';
    id?: Nullable<string>;
    name?: Nullable<SocialLoginName>;
    emails?: Nullable<Nullable<SocialLoginEmail>[]>;
    photos?: Nullable<Nullable<SocialLoginPhoto>[]>;
    isVerified?: Nullable<boolean>;
}

export class SocialLoginName {
    __typename?: 'SocialLoginName';
    givenName?: Nullable<string>;
    familyName?: Nullable<string>;
}

export class SocialLoginEmail {
    __typename?: 'SocialLoginEmail';
    value?: Nullable<string>;
}

export class SocialLoginPhoto {
    __typename?: 'SocialLoginPhoto';
    value?: Nullable<string>;
}

export class SocialProvider {
    __typename?: 'SocialProvider';
    providerId?: Nullable<string>;
    provider?: Nullable<string>;
}

export class TicketOrderTransaction implements SysInfos {
    __typename?: 'TicketOrderTransaction';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    amount?: Nullable<number>;
    transactionType?: Nullable<TransactionTypeEnum>;
    paymentProvider?: Nullable<PaymentProviderEnum>;
    paymentProviderOrderId?: Nullable<string>;
    paymentProviderData?: Nullable<Object>;
    paymentProviderAccountId?: Nullable<string>;
    ticketOrderId?: Nullable<string>;
}

export class TicketOrder implements SysInfos {
    __typename?: 'TicketOrder';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    invoiceId?: Nullable<string>;
    totalPrice?: Nullable<number>;
    totalTax?: Nullable<number>;
    totalRefund?: Nullable<number>;
    currencyType?: Nullable<CurrencyTypeEnum>;
    status?: Nullable<TicketOrderStatusEnum>;
    billingAddress?: Nullable<BillingOrShippingAddress>;
    shippingAddress?: Nullable<BillingOrShippingAddress>;
    userId?: Nullable<string>;
    userTickets?: Nullable<Nullable<UserTicket>[]>;
    ticketOrderTransactions?: Nullable<Nullable<TicketOrderTransaction>[]>;
    confirmationMailSent?: Nullable<boolean>;
}

export class CreateTicketOrderItem {
    __typename?: 'CreateTicketOrderItem';
    ticketId?: Nullable<string>;
    quantity?: Nullable<number>;
    ticketDanceRole?: Nullable<string>;
}

export class CreateTicketOrder {
    __typename?: 'CreateTicketOrder';
    ticketOrderItems?: Nullable<Nullable<CreateTicketOrderItem>[]>;
}

export class TicketSaleQuantityInformation {
    __typename?: 'TicketSaleQuantityInformation';
    ticketId?: Nullable<string>;
    ticketName?: Nullable<string>;
    boughtQuantity?: Nullable<number>;
    checkedInQuantity?: Nullable<number>;
    danceRoleType?: Nullable<UserTicketDanceRoleTypeEnum>;
}

export class TicketSearchResults {
    __typename?: 'TicketSearchResults';
    results?: Nullable<Nullable<Ticket>[]>;
    pagination?: Nullable<Pagination>;
    totalElements?: Nullable<number>;
}

export class Ticket implements SysInfos {
    __typename?: 'Ticket';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    danceEventId?: Nullable<string>;
    currency?: Nullable<Currency>;
    taxPercentage?: Nullable<number>;
    price?: Nullable<number>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    quantity?: Nullable<number>;
    soldQuantity?: Nullable<number>;
    availableQuantity?: Nullable<number>;
    saleStart?: Nullable<Date>;
    saleEnd?: Nullable<Date>;
    clientTimezone?: Nullable<string>;
    feesPaidBy?: Nullable<FeesPaidByEnum>;
    sequenceNumber?: Nullable<number>;
    eventTimezone?: Nullable<string>;
    eventStart?: Nullable<Date>;
    eventDuration?: Nullable<number>;
    ticketType?: Nullable<TicketTypeEnum>;
    ticketSaleStatus?: Nullable<TicketSaleStatusEnum>;
    sellAsFollowerLeader?: Nullable<boolean>;
    sellAsFollowerActive?: Nullable<boolean>;
    sellAsLeaderActive?: Nullable<boolean>;
}

export class TimeRange {
    __typename?: 'TimeRange';
    timeStart?: Nullable<Time>;
    timeEnd?: Nullable<Time>;
}

export class Time {
    __typename?: 'Time';
    hours?: Nullable<number>;
    minutes?: Nullable<number>;
}

export class Unit {
    __typename?: 'Unit';
    unitType?: Nullable<ItemUnitTypeEnum>;
    unitAmount?: Nullable<number>;
}

export class UpdateDanceEventAppointmentLocation {
    __typename?: 'UpdateDanceEventAppointmentLocation';
    startDate?: Nullable<Date>;
    duration?: Nullable<number>;
    locationId?: Nullable<string>;
    address?: Nullable<Address>;
    clientTimezone?: Nullable<string>;
}

export class UpdateDanceEventGeneralInfo {
    __typename?: 'UpdateDanceEventGeneralInfo';
    name?: Nullable<string>;
    description?: Nullable<string>;
    categories?: Nullable<Nullable<string>[]>;
    danceStyleIds?: Nullable<Nullable<string>[]>;
    musicStyleIds?: Nullable<Nullable<string>[]>;
    url?: Nullable<string>;
    tags?: Nullable<Nullable<string>[]>;
    coHosts?: Nullable<Nullable<string>[]>;
}

export class UpdateDanceEventRules {
    __typename?: 'UpdateDanceEventRules';
    restrictions?: Nullable<DanceEventRestrictions>;
    rules?: Nullable<DanceEventRules>;
}

export class UpdateDanceEvent {
    __typename?: 'UpdateDanceEvent';
    name?: Nullable<string>;
    danceStyleIds?: Nullable<Nullable<string>[]>;
    musicStyleIds?: Nullable<Nullable<string>[]>;
    description?: Nullable<string>;
    restrictions?: Nullable<DanceEventRestrictions>;
    rules?: Nullable<DanceEventRules>;
    mainImage?: Nullable<Image>;
    link?: Nullable<string>;
    tags?: Nullable<Nullable<string>[]>;
    coHosts?: Nullable<Nullable<string>[]>;
}

export class UserRoomRentalBookedTimes {
    __typename?: 'UserRoomRentalBookedTimes';
    userRoomRentalId?: Nullable<string>;
    timeFrom?: Nullable<Time>;
    timeTo?: Nullable<Time>;
}

export class UserRoomRental implements SysInfos {
    __typename?: 'UserRoomRental';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    roomRental?: Nullable<RoomRental>;
    rentalDate?: Nullable<Date>;
    timeFrom?: Nullable<Time>;
    timeTo?: Nullable<Time>;
    status?: Nullable<UserRoomRentalStatusEnum>;
    orderId?: Nullable<string>;
    userId?: Nullable<string>;
    danceSchoolId?: Nullable<string>;
}

export class UserTicketCollapsedByEventAndOrder {
    __typename?: 'UserTicketCollapsedByEventAndOrder';
    eventId?: Nullable<string>;
    eventStart?: Nullable<Date>;
    eventTimezone?: Nullable<string>;
    orderIds?: Nullable<Nullable<string>[]>;
    ticketOrderIds?: Nullable<Nullable<string>[]>;
    freeUserTicketIds?: Nullable<Nullable<string>[]>;
    event?: Nullable<DanceEvent>;
    numberOfTickets?: Nullable<number>;
}

export class UserTicketCollapsedByUser {
    __typename?: 'UserTicketCollapsedByUser';
    user?: Nullable<User>;
    ticketSaleInformation?: Nullable<Nullable<TicketSaleQuantityInformation>[]>;
}

export class UserTicketCollapsedUpcomingPastFiltered {
    __typename?: 'UserTicketCollapsedUpcomingPastFiltered';
    upcoming?: Nullable<Nullable<UserTicketCollapsedByEventAndOrder>[]>;
    past?: Nullable<Nullable<UserTicketCollapsedByEventAndOrder>[]>;
}

export class UserTicketSaleInformationWithTotal {
    __typename?: 'UserTicketSaleInformationWithTotal';
    total?: Nullable<number>;
    totalCheckedIn?: Nullable<number>;
}

export class UserTicketUpcomingPastFiltered {
    __typename?: 'UserTicketUpcomingPastFiltered';
    upcoming?: Nullable<Nullable<UserTicket>[]>;
    past?: Nullable<Nullable<UserTicket>[]>;
}

export class UserTicket implements SysInfos {
    __typename?: 'UserTicket';
    id?: Nullable<string>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    ticket?: Nullable<Ticket>;
    userId?: Nullable<string>;
    orderId?: Nullable<string>;
    quantity?: Nullable<number>;
    status?: Nullable<UserTicketStatusEnum>;
    ticketOrder?: Nullable<TicketOrder>;
    userTicketDanceRoleType?: Nullable<UserTicketDanceRoleTypeEnum>;
}

export class UserToDanceStyle {
    __typename?: 'UserToDanceStyle';
    userId?: Nullable<string>;
    danceStyleId?: Nullable<string>;
    translationKey?: Nullable<string>;
    experience?: Nullable<number>;
    user?: Nullable<User>;
    danceStyle?: Nullable<DanceStyle>;
    isFollower?: Nullable<boolean>;
    isLeader?: Nullable<boolean>;
}

export class UserToLanguage {
    __typename?: 'UserToLanguage';
    userId?: Nullable<string>;
    key?: Nullable<string>;
    languageLevel?: Nullable<LanguageLevelEnum>;
    user?: Nullable<User>;
}

export class User implements SysInfos {
    __typename?: 'User';
    id?: Nullable<string>;
    username?: Nullable<string>;
    email?: Nullable<string>;
    salt?: Nullable<string>;
    password?: Nullable<string>;
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    description?: Nullable<string>;
    phonePrefix?: Nullable<string>;
    phonePrefixAfter?: Nullable<string>;
    phoneNumber?: Nullable<string>;
    providerId?: Nullable<string>;
    provider?: Nullable<string>;
    imageUrl?: Nullable<string>;
    emailVerified?: Nullable<boolean>;
    status?: Nullable<UserStatusEnum>;
    jobTitle?: Nullable<string>;
    birthday?: Nullable<Date>;
    birthPlace?: Nullable<string>;
    birthName?: Nullable<string>;
    formerPlace?: Nullable<string>;
    height?: Nullable<number>;
    gender?: Nullable<GenderEnum>;
    appLang?: Nullable<string>;
    userRole?: Nullable<UserRoleEnum>;
    currentHashedRefreshToken?: Nullable<string>;
    socialProviders?: Nullable<Nullable<SocialProvider>[]>;
    addresses?: Nullable<Nullable<BillingOrShippingAddress>[]>;
    lookingFor?: Nullable<Nullable<string>[]>;
    socialLinks?: Nullable<Nullable<UserSocialLink>[]>;
    createdAt?: Nullable<Date>;
    updatedAt?: Nullable<Date>;
    monthsDancing?: Nullable<number>;
    danceInterval?: Nullable<DanceIntervalEnum>;
    roles?: Nullable<AuthDanceSchoolUserRoleJwt>;
    languages?: Nullable<Nullable<UserToLanguage>[]>;
    danceStyles?: Nullable<Nullable<UserToDanceStyle>[]>;
    isAgePublic?: Nullable<boolean>;
    isNamePublic?: Nullable<boolean>;
    hasPassword?: Nullable<boolean>;
    dancingArea?: Nullable<string>;
}

export class UserSocialLink {
    __typename?: 'UserSocialLink';
    type?: Nullable<UserSocialLinkTypeEnum>;
    url?: Nullable<string>;
}

export type Object = any;
export type SearchResult = DanceClass | Location | RoomRental | DanceEvent | DanceSchool;
type Nullable<T> = T | null;
