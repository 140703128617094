import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AppointmentParticipantInterface,
  AppointmentParticipantStatusEnum, ConfirmationDialogInterface,
  CourseAppointmentInterface,
  CourseInterface,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER, DeleteOrDeactivateEnum,
  ScanPassResponseInterface,
  ScanPassResultEnum,
  UserAppLangEnum,
  UserDto,
  UserPassDto, UserPassInterface
} from '@platri/df-common-core';
import {
  ConfirmationDialogComponent,
  CoursesHttpService,
  CoursesStateService,
  DateTimezonedPipe,
  dfAppointmentIdRouterParam,
  dfAppRoute,
  dfCourseIdRouterParam,
  getSubscription,
  UserHttpService,
  UserPassHttpService
} from '@platri/dfx-angular-core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'df-course-appointment-participants-page',
  templateUrl: './appointment-participants-page.component.html',
  styleUrls: ['./appointment-participants-page.component.scss']
})
export class AppointmentParticipantsPageComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  public readonly displayedColumns: string[] = [
    'username_email',
    'firstName',
    'lastName',
    'status',
    'passes',
    'openChat',
  ];

  danceCourse: CourseInterface;
  appointment: CourseAppointmentInterface;
  currentLang: UserAppLangEnum;
  
  userPasses: UserPassDto[];
  userIds: string[] = [];
  users: UserDto[] = [];
  isInitialized = false;
  appointmentTitle = "";

  loadedUsers: { [key: string]: UserDto } = {};
  loadedUserPasses: { [key: string]: UserPassDto } = {};

  participantListDataSource = new MatTableDataSource<AppointmentParticipantInterface>();
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  routerParams!: Params;
  courseIdFromParam: string;
  appointmentIdFromParam: string;

  constructor(
    private readonly coursesService: CoursesStateService,
    private readonly userPassService: UserPassHttpService,
    private readonly userService: UserHttpService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly translocoService: TranslocoService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly coursesHttpService: CoursesHttpService,
    private readonly matSnackBar: MatSnackBar,
    private readonly matDialog: MatDialog
    ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.paramMap, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.coursesService.courseSubject$, this.onCourseChange.bind(this)));
    // this.subscriptions.add(getSubscription(this.coursesService.appointmentSubject$, this.onAppointmentChange.bind(this)));
  }

  onActivatedRouteChanges(paramMap: ParamMap): void {
    this.courseIdFromParam = paramMap.get(dfCourseIdRouterParam);
    this.appointmentIdFromParam = paramMap.get(dfAppointmentIdRouterParam);
    if (!this.appointmentIdFromParam) {
      this.router.navigate([dfAppRoute]);
    }

    this.loadData();
  }
  
  loadData(): void {
    this.coursesService.getById(this.courseIdFromParam);
    this.loadAppointment();
  }

  onCourseChange(course: CourseInterface): void {
    this.danceCourse = course;
    if(course !== null) {
      this.breadcrumbService.set('@courseName', course.name);
    }
  }
  
  loadAppointment(): void {
    this.subscriptions.add(
      this.coursesHttpService.getAppointmentById(this.appointmentIdFromParam).subscribe({
        next: (appointment) => {
          this.appointment = appointment;
          this.participantListDataSource = new MatTableDataSource(appointment.participants);
          this.loadUsers(appointment.participants);
          this.loadUserPasses(appointment.participants);

          const dateTimezonedPipe = new DateTimezonedPipe(this.currentLang);
          this.appointmentTitle = dateTimezonedPipe.transform(appointment.date, 'UTC', this.currentLang, null, false);
          this.breadcrumbService.set('@appointmentDate', this.appointmentTitle);
        }
      })
    );
  }

  loadUsers(participants: AppointmentParticipantInterface[]): void {
    const userIds = participants.map((obj) => obj.userId);
    this.subscriptions.add(
      this.userService.getUsersByIds(userIds, false, true).subscribe({
        next: (users) => {
          users.forEach((dto) => {
            this.loadedUsers[dto.id] = dto;
          });
          this.isInitialized = true;
        }
      })
    );
  }

  loadUserPasses(participants: AppointmentParticipantInterface[]): void {
    const userIds = participants.map((obj) => obj.userId);
    this.subscriptions.add(
      this.userPassService.getAllByDmIdAndTargetIdAndUserIds(this.danceCourse.danceManagerId, this.danceCourse.id, userIds, this.appointmentIdFromParam).subscribe({
        next: (passes) => {
          userIds.forEach((userId) => {
            this.loadedUserPasses[userId] = passes[userId];
          });
          this.isInitialized = true;
        }
      })
    );
  }
  
  scan(element: AppointmentParticipantInterface, force = false): void {
    const user = this.loadedUsers[element.userId];
    const data: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.SCAN_DIALOG_TITLE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.SCAN_DIALOG_TEXT', {username: user.username}),
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: 'primary',
      cancelButtonColor: 'warn',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.YES')
    };
    this.subscriptions.add(
      this.matDialog
      .open(ConfirmationDialogComponent, {
        data
      })
      .afterClosed()
      .subscribe({
        next: (result: boolean) => {
          if (result) {
            const userPass = this.loadedUserPasses[element.userId];
            this.subscriptions.add(
              this.userPassService.scan(userPass.id, this.danceCourse.id, force, this.appointment.id).subscribe({
                next: (scanResponse: ScanPassResponseInterface) => {
                  if (scanResponse.result === ScanPassResultEnum.SUCCESS) {
                    this.matSnackBar.open('Scan successful');
                    this.loadData();
                  } else {
                    if (scanResponse.result === ScanPassResultEnum.ALREADY_SCANNED_TODAY) {
                      // open dialog to ask if he still wants to scan
                      this.openSubmitDialogForAlreadyScanned(element, scanResponse);
                    } else {
                      this.matSnackBar.open('Scan failed');
                    }
                  }
                }
              })
            );
          }}
      })
        );
  }

  openSubmitDialogForAlreadyScanned(element: AppointmentParticipantInterface, scanPassResponseInterface: ScanPassResponseInterface): void {
    const data: ConfirmationDialogInterface = {
      title: this.translocoService.translate(scanPassResponseInterface.isUserPassSubscription ? 'GENERIC_WRAPPER.SUBSCRIPTION_SCANNED_ALREADY_TODAY_TITLE' : 'GENERIC_WRAPPER.PASS_SCANNED_ALREADY_TODAY_TITLE'),
      text: this.translocoService.translate(scanPassResponseInterface.isUserPassSubscription ? 'GENERIC_WRAPPER.SUBSCRIPTION_SCANNED_ALREADY_TODAY_TEXT' : 'GENERIC_WRAPPER.PASS_SCANNED_ALREADY_TODAY_TEXT'),
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: 'primary',
      cancelButtonColor: 'warn',
      submitButtonText: this.translocoService.translate('YES')
    };
    this.subscriptions.add(
      this.matDialog.open(ConfirmationDialogComponent, {minWidth: '300px',data})
        .afterClosed().subscribe(result => {
        if (result) this.scan(element, true);
      })
    );
  }

  protected readonly AppointmentParticipantStatusEnum = AppointmentParticipantStatusEnum;
}
