import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function onlyNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value === '') {
      return null;
    }

    const valueAsString = String(control.value);
    const onlyNumbers = /^\d+$/.test(valueAsString);

    return onlyNumbers ? null : { 'onlyNumbers': true };
  };
}
