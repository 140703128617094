<ng-container *transloco="let t">
  <div [ngClass]="isMobile ? 'mobile-full-view' : ''" style="position: relative">
    
    <div *ngIf="!recipientIdFromParam && queryParamsLoaded && false" style="display: flex; flex-direction: column; width: 100%; min-height: calc(100dvh - 105px); z-index: 0; text-align: center; justify-content: center; margin-top: -75px; position: absolute">
      <p class="mat-headline-6">{{ t('GENERIC_WRAPPER.YOUR_CHAT_APPEARS_HERE') }}</p>
      <p class="mat-body-2 gray-text">{{ t('GENERIC_WRAPPER.AFTER_CLICK_ON_PARTNER') }}</p>
    </div>
    
    <div class="chats-container" *ngIf="currentUser">
      <div *ngIf="!isMobile || checkRouterUrl() || router.url === '/messages'" style="background-color: white; height: calc(100dvh - 133px);">
        <div class="chats-box">
          <div class="search-container">
            <df-shared-lib-user-search-autocomplete
              style="flex: 1 1 auto;"
              (optionSelected)="setSelectedUser($event);"
              (inputChange)="onSearchUserInputChange($event)"
              [showUserSearchLoading]="searchUserLoading"
              [userOptions]="searchUsersFound"
              [resetFormAfterSelection]="true"
            ></df-shared-lib-user-search-autocomplete>
          </div>
          
          <div style="overflow-y: auto">
            <div routerLink="{{chat.user?.user ? chat.user.userId : chat.user?.danceSchoolId}}" queryParamsHandling="preserve" routerLinkActive="active-chat" (click)="openChat(chat.user?.id)" class="chat-box chat-selectable with-hover" *ngFor="let chat of shownChats">
              <elab-list-picture-chat
                [isProfilePicture]="true"
                [imageUrl]="chat && chat.user && chat.user.user ? chat.user?.user.imageUrl! : chat.user?.imageUrl!"
              ></elab-list-picture-chat>
              <div style="width: 200px">
                <p *ngIf="chat.user?.user" class="sub-title-1 ellipsis-1">{{chat.user.user.username}}</p>
                <p *ngIf="chat.user?.danceSchool" class="sub-title-1 ellipsis-1">{{chat.user.danceSchool.urlName}}</p>
                <div style="display: flex; flex-direction: row">
                  <p class="description" [innerHTML]="chat.lastMessage.type !== MessageTypeEnum.MEDIA ? (chat.lastMessage?.value! | linkify) : t('GENERIC_WRAPPER.FILE')"></p>
                  <mat-icon
                    [ngStyle]="{'color': chat.lastMessage.status === MessageStatusEnum.IS_READ ? '#FF7A00': 'rgb(128, 128, 128)'}"
                    style="font-size: 18px; width: 18px; height: 14px; line-height: 16px; padding-left: 4px"
                    *ngIf="currentUser?.id === chat.lastMessage.senderId && chat.lastMessage.status !== MessageStatusEnum.IS_SYS && !chat.lastMessage.senderDeleted"
                  >
                    {{chat.lastMessage.status === MessageStatusEnum.IS_READ ? 'done_all' : chat.lastMessage.status === MessageStatusEnum.IS_RECEIVED ? 'done_all' : 'done'}}
                  </mat-icon>
                </div>
              </div>
              <div class="flex-column align-space-between-end" style="height: 66px; width: 100%">
                <div [ngStyle]="{'background-color': chat.lastMessage.senderId !== currentUser?.id && chat.lastMessage.status !== MessageStatusEnum.IS_READ && recipientIdFromParam !== chat.lastMessage.senderId ? '#FF7A00' : 'transparent' }" style="background-color: #FF7A00; height: 24px; width: 16px; border-radius: 0px 0px 500px 500px; align-self: flex-end; padding-top: 12px; margin-top: -8px">
                  <mat-icon *ngIf="chat.lastMessage.senderId !== currentUser?.id && chat.lastMessage.status !== MessageStatusEnum.IS_READ && recipientIdFromParam !== chat.lastMessage.senderId"  style="color: white; width: 16px; height: 14px; font-size: 14px; line-height: 15px; margin-top: -12px; margin-bottom: 8px; padding-left: 1px">mail</mat-icon>
                </div>
                <p style="text-align: end; min-width: 70px" class=" gray-text mat-body-2">{{chat.lastMessage.date! | relativeTimeShort}}</p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="flex-row align-center chats-footer" style="gap: 10px; padding: 10px;">
          <button mat-icon-button (click)="previousPage()" [disabled]="!hasPreviousPage"><mat-icon>arrow_back</mat-icon></button>
          <span>{{currentPage}}</span>
          <button mat-icon-button (click)="nextPage()" [disabled]="!hasNextPage"><mat-icon>arrow_forward</mat-icon></button>
        </div>
      </div>
    
      <div style="display: flex; flex-direction: column; width: 100%">
        <ng-container *ngIf="showChat">
          <router-outlet></router-outlet>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
