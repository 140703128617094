import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DmNotificationsInterface, NotificationInterface, UnreadDmNotificationInterface } from '@platri/df-common-core';
import { NotificationHttpService } from '../http-services/notification-http.service';
import { getSubscription } from '../../helpers';

@Injectable({providedIn: 'root'})
export class NotificationDanceManagerStateService implements OnDestroy {
  isLoadingNotifications = new BehaviorSubject<boolean>(false);
  availableUnreadNotifications = new BehaviorSubject<NotificationInterface[]>([]);
  availableReadNotifications = new BehaviorSubject<NotificationInterface[]>([]);
  
  availableUnreadCountNotifications = new BehaviorSubject<UnreadDmNotificationInterface[]>([]);

  subscriptions = new Subscription();

  constructor(private readonly notificationHttpService: NotificationHttpService) {
    console.log('Initializing NotificationDanceManagerStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying NotificationDanceManagerStateService');
    this.subscriptions.unsubscribe();
    this.isLoadingNotifications.unsubscribe();
    this.availableUnreadNotifications.unsubscribe();
    this.availableReadNotifications.unsubscribe();
    this.availableUnreadCountNotifications.unsubscribe();
  }

  getAsyncIsLoadingNotifications(): Observable<boolean> {
    return this.isLoadingNotifications.asObservable();
  }

  getAsyncAvailableUnreadNotifications(): Observable<NotificationInterface[]> {
    return this.availableUnreadNotifications.asObservable();
  }

  getAsyncAvailableReadNotifications(): Observable<NotificationInterface[]> {
    return this.availableReadNotifications.asObservable();
  }
  
  getAsyncAvailableUnreadNotificationsCount(): Observable<UnreadDmNotificationInterface[]> {
    return this.availableUnreadCountNotifications.asObservable();
  }

  loadAllNotificationsByDanceManagerId(danceManagerId: string): void {
    this.sendIsLoadingNotifications(true);
    this.subscriptions.add(getSubscription(this.notificationHttpService.getAllByDanceManagerId(danceManagerId), this.onLoadedAllNotifications.bind(this), () => {
      console.log(`Couldn't load Notifications`);
      this.sendIsLoadingNotifications(false);
    }));
  }

  onLoadedAllNotifications(dmNotificationsInterface: DmNotificationsInterface): void {
    this.sendIsLoadingNotifications(false);
    this.sendAvailableUnreadNotifications(dmNotificationsInterface.unreadNotifications);
    this.sendAvailableReadNotifications(dmNotificationsInterface.readNotifications);
  }

  loadUnreadNotificationsCountsByDanceManagerIds(danceManagerIds: string[]): void {
    this.subscriptions.add(getSubscription(this.notificationHttpService.getCountOfUnreadNotificationsOfDancemanagers(danceManagerIds), this.onLoadedUnreadNotificationsCountsByDanceManagerIds.bind(this), () => {
      console.log(`Couldn't load Notifications count`);
    }));
  }

  onLoadedUnreadNotificationsCountsByDanceManagerIds(unreadDmNotificationInterfaces: UnreadDmNotificationInterface[]): void {
    this.sendUnreadCountNotifications(unreadDmNotificationInterfaces);
  }

  clear(): void {
    this.availableUnreadNotifications.next([]);
    this.availableReadNotifications.next([]);
    this.isLoadingNotifications.next(false);

  }

  private sendAvailableUnreadNotifications(notifications: NotificationInterface[]): void {
    this.availableUnreadNotifications.next(notifications);
  }

  private sendAvailableReadNotifications(notifications: NotificationInterface[]): void {
    this.availableReadNotifications.next(notifications);
  }

  private sendIsLoadingNotifications(isLoading: boolean): void {
    this.isLoadingNotifications.next(isLoading);
  }
  
  private sendUnreadCountNotifications(data: UnreadDmNotificationInterface[]): void {
    this.availableUnreadCountNotifications.next(data);
  }

}
