<ng-container *ngIf="danceSchools">
  <button
    *ngIf="danceSchools.length === 1"
    mat-raised-button
    color="primary"
    class="dance-manager-button border-radius-20"
    [routerLinkActive]="'active-link'"
    [routerLink]="['dance-manager', danceSchools[0].id]"
    [matBadge]="unreadMessagesCountTotal + unreadNotificationsCountTotal"
    [matBadgeHidden]="(unreadMessagesCountTotal + unreadNotificationsCountTotal) === 0"
    matBadgeColor="accent">
    <span class="dance-manager-button-text">DANCEMANAGER</span>
  </button>
  <button
    *ngIf="danceSchools.length > 1"
    class="dance-manager-button-dropdown border-radius-20"
    mat-raised-button
    color="primary"
    [matMenuTriggerFor]="multipleManagerButtonBox"
    [matBadge]="unreadMessagesCountTotal + unreadNotificationsCountTotal"
    [matBadgeHidden]="(unreadMessagesCountTotal + unreadNotificationsCountTotal) === 0"
    matBadgeColor="accent"
    >
    <span class="dance-manager-button-text-with-icon">DANCEMANAGER</span>
    <mat-icon *ngIf="danceSchools.length > 1" class="material-icons-round drop-down-icon" iconPositionEnd>arrow_drop_down</mat-icon>
  </button>
</ng-container>

<mat-menu
  #multipleManagerButtonBox="matMenu"
  [xPosition]="'after'"
  class="mat-menu-panel"
  style="max-width: 500px;"
  [hasBackdrop]="true"
>
  <ng-container *ngFor="let dm of danceSchools | orderByPipe:'name'; let i = index">
    <a class="dm-link flex-row" [routerLinkActive]="'active-link'" [routerLink]="['dance-manager', dm.id]" mat-menu-item>
      <div class="badge-container" matMenuItemIcon>
        <img class="dm-icon" style="align-self: center; display: flex;" [src]="dm.imageUrl && dm.imageUrl !== '' ? dm.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"/>
      </div>
      <div
        [matBadge]="dm | unreadCountDmPipe : unreadMessagesInterface : unreadNotificationsCountsByDanceManager"
        [matBadgeHidden]="(dm | unreadCountDmPipe : unreadMessagesInterface : unreadNotificationsCountsByDanceManager) === 0"
        matBadgePosition="after"
        matBadgeOverlap="false"
        matBadgeColor="accent" class="pr-10 pl-20"
      >
        {{ dm.name }}
      </div>
    </a>
  </ng-container>
</mat-menu>
