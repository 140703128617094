import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTicketsPage } from './event-tickets.page';
import { CreateEventTicketPage } from './create-event-ticket/create-event-ticket.page';
import { EditEventTicketComponent } from './edit-event-ticket/edit-event-ticket.component';
import { EventTicketDetailsComponent } from './event-ticket-details/event-ticket-details.component';
import { EventTicketsRoutingModule } from './event-tickets-routing.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CustomStepperModule } from '@platri/dfx-angular-core';
import { EventTicketPricingStepModule } from '@platri/dfx-angular-core';
import { EventTicketSettingsStepModule } from '@platri/dfx-angular-core';
import { EventTicketSalesSettingsStepModule } from '@platri/dfx-angular-core';
import { EventTicketSubmitStepModule } from '@platri/dfx-angular-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '@platri/dfx-angular-core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [
    EventTicketsPage,
    CreateEventTicketPage,
    EditEventTicketComponent,
    EventTicketDetailsComponent,
  ],
  providers: [
  ],
  exports: [EventTicketsPage],
  imports: [
    CommonModule,
    EventTicketsRoutingModule,
    CdkStepperModule,
    CustomStepperModule,
    EventTicketPricingStepModule,
    EventTicketSettingsStepModule,
    EventTicketSalesSettingsStepModule,
    EventTicketSubmitStepModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatExpansionModule,
    MatDividerModule,
    PipesModule,
    MatTooltipModule,
    DragDropModule,
    MatRippleModule,
    MatSlideToggleModule,
    TranslocoModule,
  ],
})
export class EventTicketsModule {}
