import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { environmentForWeb } from '@platri/df-common-core';
import { CurrencyTypeEnum, PaymentProviderEnum } from '@platri/df-common-shared-graphql';
import { TicketOrderService } from '../../services/state-services/ticket-order.service';
import { TranslocoService } from '@jsverse/transloco';


@Component({
  selector: 'df-monorepo-ticket-checkout-paypal',
  templateUrl: './ticket-checkout-paypal-payment.component.html',
  styleUrls: ['./ticket-checkout-paypal-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCheckoutPaypalPaymentComponent implements OnInit, OnDestroy {
  @Input() ticketOrderId: string;

  paypalOrderId: string;
  paypalClientId = environmentForWeb.paypalClientId;
  currency: CurrencyTypeEnum;
  returnUrl: string;

  constructor(
    public readonly translocoService: TranslocoService,
    private readonly _ticketOrderService: TicketOrderService,
    private readonly _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.createPaypalOrderToTicketOrder();
  }

  createPaypalOrderToTicketOrder(): void {
    this._ticketOrderService.getAsyncTicketOrder().subscribe({
      next: (ticketOrder) => {
        if (
          ticketOrder !== null &&
          // @ts-ignore
          ticketOrder.ticketOrderTransactions?.length > 0 &&
          // @ts-ignore
          ticketOrder.ticketOrderTransactions[0]?.paymentProvider ===
            PaymentProviderEnum.PAYPAL
        ) {
          this.paypalOrderId =
            // @ts-ignore
            ticketOrder.ticketOrderTransactions[0]?.paymentProviderOrderId!;
          this.currency =
            // @ts-ignore
            ticketOrder.currencyType!;
          this.initReturnUrl();
          this._cd.detectChanges();
        }
      },
    });

    this._ticketOrderService.updateTicketOrderPaypalOrder(this.ticketOrderId);
  }

  initReturnUrl(): void {
    this.returnUrl = window.location.href + '-complete/' + this.ticketOrderId;
  }

  capturePaymentEvent(): void {
    this._ticketOrderService.updateTicketOrderPaypalOrderCapture(
      this.ticketOrderId,
      this.returnUrl
    );
  }

  ngOnDestroy(): void {
    /**
     * TODO: Ticket Order Transaction entfernen inklusive Stripe Payment Intent
     */
  }
}
