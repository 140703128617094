import { Pipe, PipeTransform } from '@angular/core';
import { startOfDay, addHours, addMinutes, isBefore } from "date-fns";
import { TimeRange } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'isPastDate',
})
export class IsPastDatePipe implements PipeTransform {
  transform(date: Date, timeRange: TimeRange, comparisonDate?: Date ): boolean {
    if (!date) {
      throw new ReferenceError('DATE_NOT_DEFINED');
    }

    if (!timeRange) {
      throw new ReferenceError('TIME_RANGE_NOT_DEFINED');
    }

    let queryDate = startOfDay(new Date(date));
    queryDate = addHours(queryDate, timeRange.timeEnd.hours);
    queryDate = addMinutes(queryDate, timeRange.timeEnd.minutes);

    return isBefore(queryDate, comparisonDate ? new Date(comparisonDate) : new Date());
  }
}
