import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentParticipantsPageComponent } from './appointment-participants-page.component';
import { MatIconModule } from '@angular/material/icon';
import { ParticipantsListTableModule } from '../../components/participants-list/participants-list-table.module';
import { MatCardModule } from '@angular/material/card';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { MatIconButton } from '@angular/material/button';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatChip } from '@angular/material/chips';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { SharedEmptyPageModule } from '@platri/dfx-angular-core';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [AppointmentParticipantsPageComponent],
    imports: [
        CommonModule,
        MatIconModule,
        ParticipantsListTableModule,
        MatCardModule,
        BreadcrumbComponent, BreadcrumbItemDirective, MatIconButton, MatCell, MatCellDef, MatChip, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef, MatProgressSpinner, MatRow, MatRowDef, MatSort, MatSortHeader, MatTable, SharedEmptyPageModule, MatHeaderCellDef, TranslocoDirective
    ]
})
export class AppointmentParticipantsPageModule {}
