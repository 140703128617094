import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserSearchAutocompleteModule } from '../../components/user-search-autocomplete';
import { MatInputModule } from '@angular/material/input';
import { DfSharedButtonModule } from '../../components';
import { DelayDialogComponent } from './delay-dialog.component';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [DelayDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        UserSearchAutocompleteModule,
        MatInputModule,
        DfSharedButtonModule,
        MatOption,
        MatSelect,
        FormsModule,
        MatProgressBar,
        TranslocoDirective
    ]
})
export class DelayDialogModule {}
