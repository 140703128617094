import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStateService, DanceManagerHttpService, dfAppRoute, dfLoginRoute, FollowInfoDialogComponent, UserFollowStateService, UserProfileStateService, UserService } from '@platri/dfx-angular-core';
import { filter, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DanceIntervalEnum, DanceSchoolDto, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, FollowInterface, FollowTargetTypeEnum, UsersInterface } from '@platri/df-common-core';
import { CompleteProfileDialogComponent, ProfilePictureDialogComponent } from '../../../dialogs';

@Component({
  selector: 'df-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() profile: UsersInterface;
  @Input() isCurrentUser: boolean;
  @ViewChild('profileImage') profileImage: ElementRef;

  showDetail: boolean;
  showAboutMe: boolean;
  age: number;
  currentUser: UsersInterface;
  subscription: Subscription = new Subscription();
    
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  danceIntervalEnum = DanceIntervalEnum;
  
  fullDescription: string;
  truncatedDescription: string;
  displayDescription: string;
  showMoreDescription = false;

  followInfoDialogRef;

  buttonLabel = 'USER.EDIT_PROFILE';
  
  constructor(
    public dialog: MatDialog, 
    private readonly router: Router, 
    private authService: AuthStateService, 
    private matSnackBar: MatSnackBar, 
    public userFollowStateService: UserFollowStateService, 
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.listenOnAuthChanges();
    if (this.currentUser.id === this.profile.id) {
      this.checkProfileCompletion();
    }
  }

  listenOnAuthChanges(): void  {
    this.subscription.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        if (user) {
          this.currentUser = user;
        }
      })
    );
  }
  
  checkProfileCompletion(): void {
    if (this.currentUser) {
      const showProfileCompletionDialog: boolean = this.currentUser.showCompleteProfileDialog;
      if (showProfileCompletionDialog) {
        const completionRate: number = this.getProfileCompletionRate();
        if (completionRate <= 10) {
          this.dialog.open(CompleteProfileDialogComponent, {
            height: '500px',
            maxWidth: '350px',
            data: {
              completionRate: completionRate
            },
            autoFocus: false
          });
        }
      }
    }
  }
  
  loadFollowCount(): void {
    this.userFollowStateService.loadFollowCountByTargetIdAndTargetType(this.profile.id, FollowTargetTypeEnum.USER);
  }
  
  loadFollowsAndFollowers(): void {
    this.userFollowStateService.loadFollowsByTargetIdAndTargetType(this.profile.id, FollowTargetTypeEnum.USER);
    this.userFollowStateService.loadFollowersByTargetIdAndTargetType(this.profile.id, FollowTargetTypeEnum.USER);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.followInfoDialogRef?.close();
    this.checkShowDetail();
    this.checkShowAboutMe();
    this.age = this.calculateAge(this.profile.birthday);
    this.fullDescription = this.profile?.description ?? null;
    this.truncatedDescription = this.profile?.description?.length > 150 ? this.profile?.description?.substr(0, 150) + '...' : this.profile?.description;
    this.displayDescription = this.truncatedDescription;
    
    this.userFollowStateService.clear();
    this.loadFollowCount();
    if (this.isCurrentUser) {
      this.checkProfileCompletion();
    }
  }

  toggleDescription(): void {
    this.showMoreDescription = !this.showMoreDescription;
    this.displayDescription = this.showMoreDescription ? this.fullDescription : this.truncatedDescription;
  }
  
  checkShowAboutMe(): void {
    this.showAboutMe = !!(
      this.profile.description &&
      this.profile.description.trim().length
    );
  }

  checkShowDetail(): void {
    this.showDetail = !!(
      this.profile.dancingArea ||
      this.profile.height ||
      (this.profile.monthsDancing && this.profile.monthsDancing !== 0) ||
      (this.profile.danceInterval && this.profile.dancingArea !== this.danceIntervalEnum.I_DONT_DANCE) ||
      this.profile.lookingFor?.length > 0 ||
      this.profile.jobTitle ||
      this.profile.formerPlace ||
      this.profile.dancingArea
    );
  }
  
  getProfileCompletionRate(): number {
    let completion = 0;
    const currentUser = this.authService.getSyncCurrentUser();
    if (currentUser.isAgePublic && currentUser.birthday) {
      completion++;
    } else if (!currentUser.isAgePublic) {
      completion++;
    }
    if (currentUser.gender) {
      completion++;
    }
    if (currentUser.height) {
      completion++;
    }
    if (currentUser.formerPlace) {
      completion++;
    }
    if (currentUser.jobTitle) {
      completion++;
    }
    if (currentUser.description) {
      completion++;
    }
    if (currentUser.dancingArea) {
      completion++;
    }
    if (currentUser.danceStyles) {
      completion++;
    }
    if (currentUser.lookingFor) {
      completion++;
    }
    if (currentUser.languageSkills) {
      completion++;
    }
    if (currentUser.socialLinks) {
      completion++;
    }
    return completion;
  }

  calculateAge(birthday: Date): number {
    if (birthday) {
      const birthDate = new Date(birthday);
      const ageDifMs = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    return 0;
  }

  openProfilePictureDialog(): void {
    if (this.isCurrentUser) {
      const dialogRef = this.dialog.open(ProfilePictureDialogComponent, {
        width: '100%',
        maxWidth: '1140px',
        data: {
          imageUrl: this.profile.imageUrl
        }
      });
    }
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
  }
  
  openFollowInfoDialog(tabIndex: number = 0): void {
    if (this.currentUser) {
      this.loadFollowsAndFollowers();
      this.followInfoDialogRef = this.dialog.open(FollowInfoDialogComponent, {
        maxHeight: '80vh',
        minWidth: '300px',
        width: '50vw',
        id: 'follow-info-dialog-container',
        panelClass: 'follow-info-dialog-container',
        data: {
          tabIndex: tabIndex
        }
      });
      
      this.followInfoDialogRef.afterClosed().subscribe(() => {
        this.loadFollowCount();
      });
    } else {
      this.routeToLogin();
    }
  }

  followUser(): void {
    if (this.currentUser) {
      if (this.userFollowStateService.getSyncAvailableFollowCount().isCurrentUserFollowing) {
        this.userFollowStateService
          .unfollowUser(this.userFollowStateService.getSyncAvailableFollowCount().followId)
          .pipe(filter((v) => v !== null))
          .subscribe((data: any) => {
            if (data) {
              this.matSnackBar.open('Unfollowed');
              this.loadFollowCount();
            }
          });
      } else {
        this.userFollowStateService
          .followUser(this.profile.id, FollowTargetTypeEnum.USER)
          .pipe(filter((v) => v !== null))
          .subscribe((data: FollowInterface) => {
            if (data) {
              this.matSnackBar.open('Followed');
              this.loadFollowCount();
            }
          });
      }
    } else {
      this.routeToLogin();
    }
  }

  openChat(): void {
    this.router.navigateByUrl(`messages/${this.profile.id}`);
  }

  openReportUserConfirmDialog(): void {
    // TODO: replace with ngx-translate
    // const whyReportUserConfirmationTranslation$ = this.translocoService
    //   .selectTranslateObject(
    //     'why-report-user-confirmation',
    //     {
    //       text: { username: this.profile.username }
    //     },
    //     TRANSLOCO_SCOPE_PROFILE
    //   )
    //   .pipe(take(1));
    // const successReportUserConfirmationTranslation$ = this.translocoService
    //   .selectTranslateObject(
    //     'success-report-user-confirmation',
    //     {
    //       text: { username: this.profile.username }
    //     },
    //     TRANSLOCO_SCOPE_PROFILE
    //   )
    //   .pipe(take(1));

    // this.subscription.add(
    //   combineLatest([whyReportUserConfirmationTranslation$, successReportUserConfirmationTranslation$]).subscribe(([whyReportUserConfirmationTranslation, successReportUserConfirmationTranslation]) => {
    //     const confirmationDialogData: ConfirmationDialogInterface = {
    //       cancelButtonColor: '',
    //       cancelButtonText: whyReportUserConfirmationTranslation.cancel,
    //       submitButtonColor: 'primary',
    //       submitButtonText: whyReportUserConfirmationTranslation.report,
    //       title: whyReportUserConfirmationTranslation.title,
    //       text: whyReportUserConfirmationTranslation.text,
    //       requiredText: whyReportUserConfirmationTranslation.requiredText,
    //       placeholder: whyReportUserConfirmationTranslation.placeholder,
    //       withTextArea: true
    //     };
    //
    //     this.dialog
    //       .open(ConfirmationDialogComponent, {
    //         panelClass: 'br-20',
    //         minWidth: '400px',
    //         data: confirmationDialogData
    //       })
    //       .afterClosed()
    //       .subscribe((data) => {
    //         if (data?.result) {
    //           this.userService.reportUser(this.profile.id, data.textAreaInput).subscribe(() => {
    //             const confirmationDialogData2: ConfirmationDialogInterface = {
    //               submitButtonColor: 'primary',
    //               submitButtonText: successReportUserConfirmationTranslation.close,
    //               title: successReportUserConfirmationTranslation.title,
    //               text: successReportUserConfirmationTranslation.text
    //             };
    //             this.dialog.open(ConfirmationDialogComponent, {
    //               panelClass: 'br-20',
    //               minWidth: '300px',
    //               data: confirmationDialogData2
    //             });
    //           });
    //         }
    //       });
    //   })
    // );
  }

  deleteProfilePicture(): void {
    // TODO: replace with ngx-translate
    // this.subscription.add(
    //   this.translocoService
    //     .selectTranslateObject('delete-profile-picture-confirmation', {}, TRANSLOCO_SCOPE_PROFILE)
    //     .pipe(take(1))
    //     .subscribe((translation) => {
    //       const confirmationDialogData: ConfirmationDialogInterface = {
    //         cancelButtonColor: '',
    //         cancelButtonText: translation.cancel,
    //         submitButtonColor: 'primary',
    //         submitButtonText: translation.delete,
    //         text: translation.text
    //       };
    //       const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //         minWidth: '300px',
    //         data: confirmationDialogData
    //       });
    //       this.subscription.add(
    //         dialogRef.afterClosed().subscribe((confirmationRes) => {
    //           if (confirmationRes) {
    //             this.userProfileService.deleteProfilePicture();
    //           }
    //         })
    //       );
    //     })
    // );
  }
  
  routeToSettings(): void {
    this.router.navigate(['/settings']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.userFollowStateService.clear();
  }
  
}
