import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomStepperComponent } from './custom-stepper.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@jsverse/transloco';
import { DfSharedButtonModule } from '../buttons';

@NgModule({
  declarations: [CustomStepperComponent],
  providers: [],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatStepperModule,
    CdkStepperModule,
    DfSharedButtonModule,
    TranslocoModule,
  ],
  exports: [CustomStepperComponent],
})
export class CustomStepperModule {}
