<div class="pass-list-item-card-container">
  <df-monorepo-dance-manager-passes-list-item
    *ngFor="let pass of passes; let first = first"
    [pass]="pass"
    [isOwnerOrAdmin]="isOwnerOrAdmin"
    [formGroup]="formGroup"
    [loadedCourses]="loadedCourses"
    [isLoading]="isLoading"
    [currentUser]="currentUser"
    [danceManagerUrlName]="danceManager.urlName"
    [isCreatingTicketOrder]="isCreatingTicketOrder"
    (routeToPassBuyout)="routeToPassBuyout($event)"
    (routeToPassEdit)="routeToPassEdit($event)"
  ></df-monorepo-dance-manager-passes-list-item>
</div>
