import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ChatsComponent } from '../components';
import { ChatAppChatRouterParam } from '../constants';
import { AuctionChat2Component } from '../pages';

const routes: Routes = [
  {
    path: '', component: ChatsComponent, children: [
      {path: ':' + ChatAppChatRouterParam, component: AuctionChat2Component},
    ]
 },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MessagesRoutingModule {
  constructor() {
    console.log('MessagesRoutingModule initialized');
  }
}
