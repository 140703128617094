<ng-container *transloco="let t">
  <ng-container [formGroup]="searchAddressForm">
    <div (click)="getPacContainer()">
      <div *ngIf="alternativeDesign" class="search-input">
        <input
          id="google-autocomplete-input-alternative"
          data-cy="google-autocomplete-input-alternative"
          style="-webkit-appearance:none; background-color: white;"
          #addressAlternative
          elabGoogleAutocomplete
          #placesRef="elab-google-autocomplete"
          [options]="options"
          type="text"
          [disableControl]="disableCondition"
          placeholder="{{
            customPlaceholder
              ? customPlaceholder
              : isCity
              ? (t('GENERIC_WRAPPER.GOOGLE_ADDRESS.PLACEHOLDER_CITY'))
              : (t('GENERIC_WRAPPER.GOOGLE_ADDRESS.PLACEHOLDER'))
          }}"
          matInput
          autocomplete="off"
          formControlName="address"
          (onAddressChange)="handleAddressChange($event)"
          (keyup)="onKeyUp($event)"
        />
        <mat-icon
          matTooltip="{{ t('GENERIC_WRAPPER.CURRENT_POSITION').toLowerCase() }}"
          matTooltipPosition="below"
          (click)="getBrowserPermissionForLocationOfUserManual()"
          style="cursor: pointer;"
        > 
          location_searching
        </mat-icon>
      </div>
      <mat-form-field *ngIf="!alternativeDesign" class="w-100" appearance="outline">
        <mat-label
          >{{ label ? label : (t('GENERIC_WRAPPER.GOOGLE_ADDRESS.ADDRESS')) }}
        </mat-label>
        <input
          id="google-autocomplete-input"
          #address
          elabGoogleAutocomplete
          #placesRef="elab-google-autocomplete"
          [options]="options"
          type="search"
          [disableControl]="disableCondition"
          placeholder="{{
            isCity
              ? (t('GENERIC_WRAPPER.GOOGLE_ADDRESS.PLACEHOLDER_CITY'))
              : (t('GENERIC_WRAPPER.GOOGLE_ADDRESS.PLACEHOLDER'))
          }}
          "
          matInput
          autocomplete="off"
          formControlName="address"
          (onAddressChange)="handleAddressChange($event)"
          (keyup)="onKeyUp($event)"
        />
        <mat-error *ngIf="addressControl.hasError('noStreetNumber')">{{t('GENERIC_WRAPPER.GOOGLE_ADDRESS.TYPE_HOUSE_NUMBER') }}</mat-error>
        <mat-error *ngIf="addressControl.hasError('required')"
          >{{
            errorMessageTranslationKey
              ? t(errorMessageTranslationKey)
              : 'invalid value'
          }}
        </mat-error>
        <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
