export const searchResolver = {
  SearchResult: {
    // @ts-ignore
    __resolveType(obj, context, info): string {
      switch (info.path.prev.key) {
        case 'searchDanceSchoolsV2':
          return 'DanceSchool';
        case 'searchDanceSchools':
          return 'Location';
        case 'searchDanceEvents':
        case 'danceEventsByDanceSchoolIdPaged':
          return 'DanceEvent';
        case 'searchRoomRentals':
          return 'RoomRental';
        case 'searchDanceClasses':
        case 'searchDanceClassesWithoutUser':
          return 'DanceClass';
        default:
          console.error(
            'Resolver needs to be extended for search result type ' +
              info.path.prev.key
          );
          // @ts-ignore
          return null;
      }
    },
  },
};
