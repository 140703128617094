import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  AuthStateService,
  CanDeactivateComponent,
  DanceManagerService,
  DanceManagerUserInviteDialogComponent,
  DanceSchoolHttpService,
  DanceSchoolToUserStateService,
  DfmStateService,
  UserHttpService,
  UserSearchAutocompleteComponent
} from '@platri/dfx-angular-core';
import { debounceTime, Subject, Subscription, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  DanceManagerUserRoleEnum,
  DanceSchoolInterface,
  DanceSchoolToUserInterface,
  hasAccessToDanceManagerFromTokenRolesByIdAndRoles,
  UsersInterface
} from '@platri/df-common-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DfmRolesTableComponent } from '../dfm-roles-table';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-team',
  templateUrl: './dfm-team.component.html',
  styleUrls: ['./dfm-team.component.scss'],
})
export class DfmTeamComponent extends CanDeactivateComponent implements OnInit, OnDestroy {
  searchInputChangeSubject = new Subject<string>();
  subscriptions: Subscription = new Subscription();
  userSearchLoading = false;
  userOptions: any[] = [];

  roleForm: UntypedFormGroup;
  descriptionForm: UntypedFormGroup;
  danceSchoolUserRoleEnum = DanceManagerUserRoleEnum;

  danceSchool: DanceSchoolInterface;
  danceSchoolToUsers: DanceSchoolToUserInterface[];

  @ViewChild(DfmRolesTableComponent) dfRolesTable: DfmRolesTableComponent;
  @ViewChild(UserSearchAutocompleteComponent)
  userSearchAutoComplete: UserSearchAutocompleteComponent;
  
  isOwnerOrAdmin = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private descriptionFormBuilder: UntypedFormBuilder,
    private danceSchoolToUserService: DanceSchoolToUserStateService,
    private userHttpService: UserHttpService,
    private translocoService: TranslocoService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private dfmStateService: DfmStateService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private danceManagerService: DanceManagerService,
    private authStateService: AuthStateService
  ) {
    super();
  }

  get descriptionControl(): AbstractControl {
    return this.descriptionForm.get('description');
  }

  canDeactivate(): boolean {
    return !this.roleForm.dirty;
  }

  ngOnInit(): void {
    this.initRoleForm();
    this.initDescriptionForm();
    this.getDanceSchool();
    this.subscribeToSearch();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initRoleForm(): void {
    this.roleForm = this.formBuilder.group({
      user: [null, Validators.required],
      danceSchoolUserRole: [null, Validators.required]
    });
  }

  initDescriptionForm(): void {
    this.descriptionForm = this.descriptionFormBuilder.group({
      description: [null]
    });
  }

  getDanceSchool(): void {
    this.dfmStateService
      .getAsyncSelectedDm()
      .subscribe((danceSchool) => {
        if (danceSchool) {
          this.danceSchool = danceSchool;
          this.getAllDanceSchoolToUsersByDanceSchoolId(danceSchool.id);

          this.descriptionForm.patchValue({
            description: this.danceSchool.teamDescription
          });

          this.isOwnerOrAdmin = hasAccessToDanceManagerFromTokenRolesByIdAndRoles(danceSchool.id, this.authStateService.getSyncCurrentUser().roles.danceSchool, [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]);
        }
      });
  }

  getAllDanceSchoolToUsersByDanceSchoolId(danceSchoolId: string): void {
    this.danceSchoolToUserService
      .getAsyncCurrentDanceSchoolToUsers()
      .subscribe((danceSchoolToUsers) => {
        this.danceSchoolToUsers = danceSchoolToUsers;
      });

    this.danceSchoolToUserService.getAllDanceSchoolToUsersByDanceSchoolId(
      danceSchoolId
    );
    
  }

  save(): void {
    if (this.roleForm.valid) {
      this.danceSchoolToUserService
        .createDanceSchoolToUser(
          this.danceSchool.id,
          this.roleForm.getRawValue().user.data.id,
          this.roleForm.getRawValue().danceSchoolUserRole
        )
        .subscribe({
          next: () => {
            this.roleForm.reset();
            this.userSearchAutoComplete.initForm();
            this.userSearchAutoComplete.subscribeToSearchUser();
          },
          error: (err) => {
            const message = this.translocoService.translate(`DANCE_MANAGER_ADMINISTRATION.DANCE_SCHOOL_TO_USER_ERROR_ENUM.${err.error.message}`);
            this.danceSchoolToUserService.openConfirmationDialogForError(message);
            this.danceSchoolToUserService.sendIsLoadingDanceManagerToUsers(false);
          },
        });
    } else {
      this.userSearchAutoComplete.searchUserForm.markAllAsTouched();
      this.roleForm.markAllAsTouched();
    }
  }

  saveTeamDescription(): void {
    this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchool.id, {teamDescription: this.descriptionControl.value}).subscribe({
      next: (danceManager) => {
        this.danceManagerService.getDanceManagerById(this.danceSchool.id);
        this.dfmStateService.loadAvailableData();
        this.showSnackBarChangesMadeSuccess();
      },
      error: (err) => {
        const message = this.translocoService.translate('GENERIC_WRAPPER.SOMETHING_WENT_WRONG_TRY_LATER_AGAIN');
        this.matSnackBar.open(message);
      }
    });
  }

  showSnackBarChangesMadeSuccess(): void {
    const message = this.translocoService.translate('GENERIC_WRAPPER.SNACKBAR.SUCCESSFULLY_SAVED');
    this.matSnackBar.open(message);
  }
  
  setSelectedUser(user: any): void {
    this.roleForm.controls.user.patchValue(user);
    this.roleForm.controls.user.markAsDirty();
  }

  onSearchInputChange(input: string): void {
    this.searchInputChangeSubject.next(input);
  }

  subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject
        .pipe(
          tap(() => (this.userSearchLoading = true)),
          debounceTime(500)
        )
        .subscribe((inputText) => {
          if (inputText) {
            this.userHttpService
              .searchUsersByKey(inputText)
              .pipe(
                tap(() => {
                  this.userSearchLoading = false;
                })
              )
              .subscribe((users) => {
                if (this.danceSchoolToUsers) {
                  const usersFiltered = users.filter((user) => (
                      this.danceSchoolToUsers.findIndex(
                        (dmRole) => dmRole.user.id === user.id
                      ) === -1
                    ));
                  this.userOptions = this.mapToOptions(usersFiltered);
                } else {
                  this.userOptions = this.mapToOptions(users);
                }
              });
          } else {
            this.userOptions = [];
            this.userSearchLoading = false;
          }
        })
    );
  }
  
  openDmUserInviteDialog(): void {
    const dialogRef = this.matDialog.open(DanceManagerUserInviteDialogComponent, {
      panelClass: 'br-20',
      maxWidth: '450px',
      autoFocus: false
    });
  }

  private mapToOptions(users: UsersInterface[]): {
    value: string;
    data: UsersInterface;
    displayValue: string;
    info: string;
    imageUrl?: string;
  }[] {
    return users.map((user: UsersInterface) => ({
        value: user.username,
        data: user,
        displayValue: user.username,
        info: user.firstName + ' ' + user.lastName,
        imageUrl: user.imageUrl ?? null
      }));
  }
}
