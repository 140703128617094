import { NotificationDataInterface, NotificationUseCaseEnum } from '../../..';

export class UpcomingEventOneHourBeforeNotificationDataDto implements NotificationDataInterface {
  public readonly useCase = NotificationUseCaseEnum.UPCOMING_EVENT_ONE_HOUR_BEFORE;
  
  constructor(
    public readonly useCaseSpecificData: {
      toUserId: string;
      fromDanceEventId: string;
      danceEventName?: string;
      danceManagerName?: string;
    }) {}
}
