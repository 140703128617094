import { Component, Input } from '@angular/core';
import {
  DanceSchoolStateService,
  dfmDanceManagerRoute,
  dfmMarketPlaceRoute,
  DmPassSubscriptionHttpService,
  PassSubscriptionStateService
} from '@platri/dfx-angular-core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'dfm-course-subscriptions-page',
  templateUrl: './dfm-course-subscriptions.page.html',
  styleUrls: ['./dfm-course-subscriptions.page.scss']
})
export class DfmCourseSubscriptionsPage {
  @Input() subscriptionFeatureActive = false;
  
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly danceSchoolStateService: DanceSchoolStateService,
    private readonly dmPassSubscriptionHttpService: DmPassSubscriptionHttpService,
    private readonly passSubscriptionStateService: PassSubscriptionStateService
  ) {}
  
  navigateToCreatePassSubscriptionPage(id?: string): void {
    if (id) {
      this.dmPassSubscriptionHttpService.getPassSubscriptionById(id).subscribe({
        next: (sub) => {
          this.passSubscriptionStateService.sendPassSubscriptionToDuplicateSubject(sub);
          this.router.navigate(['create-pass-subscription'], {relativeTo: this.route});
        }
      });
    } else {
      this.router.navigate(['create-pass-subscription'], {relativeTo: this.route});
    }
  }

  navigateToEditPassSubscriptionPage(passSubscriptionId: string): void {
    this.router.navigate(['pass-subscription', passSubscriptionId], { relativeTo: this.route });
  }

  navigateToMarketplacePage(): void {
    this.router.navigate([dfmDanceManagerRoute, this.danceSchoolStateService.getSyncCurrentDanceSchool().id, dfmMarketPlaceRoute]);
  }
}
