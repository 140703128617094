<ng-container *transloco="let t">
  <div class="flex-column" style="padding: 20px; max-height: 90vh; overflow-y: auto;">
    <span class="title-text">{{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.DIALOG_TITLE')}}</span>
    <span class="description-text mt1">{{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.DIALOG_DESCRIPTION')}}</span>
    <ng-container>
      <div class="flex-column">
        <div *ngFor="let dayName of objectKeys(openingHoursData); let i = index">
          <div class="flex-row align-start mt2 w-100">
            <div class="weekday-container">
              <span>{{ t('GENERIC_WRAPPER.WEEKDAYS.FULL.' + dayName.toUpperCase()) }}</span>
            </div>
            <div class="toggle-container">
              <mat-slide-toggle
                color="primary"
                (change)="toggleOpeningHoursDay($event, dayName)"
                [checked]="openingHoursData[dayName].length !== 0">
              </mat-slide-toggle>
            </div>
            
            <div class="show-open-container">
              <span>{{ openingHoursData[dayName].length === 0 ? (t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.CLOSED')) : (t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.OPEN')) }}</span>
            </div>
  
            <div class="opening-hours-container w-100">
              <ng-container *ngIf="!!openingHoursData[dayName]">
                <div class="flex-column align-start w-100">
                  <ng-container *ngFor="let openingHoursDay of openingHoursData[dayName]; let i = index">
                    <div class="flex-row form-fields-container w-100 mb1">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ t('GENERIC_WRAPPER.FROM') }}</mat-label>
                        <input 
                          matInput 
                          type="time" 
                          style="margin-left: 7px;"
                          autocomplete="off"
                          (ngModelChange)="setOpeningHoursDataFormat(openingHoursData[dayName][i], $event, true)"
                          [ngModel]="openingHoursData[dayName][i].timeStart | timeInformationToHoursMinutes"
                        />
                      </mat-form-field>
                      <span class="input-divider"> - </span>
                      <mat-form-field appearance="outline">
                        <mat-label>{{ t('GENERIC_WRAPPER.TO') }}</mat-label>
                        <input
                          matInput
                          type="time"
                          style="margin-left: 7px;"
                          autocomplete="off"
                          [ngModel]="openingHoursData[dayName][i].timeEnd | timeInformationToHoursMinutes"
                          (ngModelChange)="setOpeningHoursDataFormat(openingHoursData[dayName][i], $event, false)"
                        />
                      </mat-form-field>
                      <div>
                        <button *ngIf="openingHoursData[dayName].length === 1" mat-icon-button color="primary" class="w-100" (click)="addOpeningHoursForDay(dayName)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button *ngIf="openingHoursData[dayName].length > 1" mat-icon-button color="accent" (click)="deleteOpeningHoursForDay(dayName, i)">
                          <mat-icon [matTooltip]="t('GENERIC_WRAPPER.DELETE')">delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <p class="mt0 opening-hours-error" *ngIf="!valid && errorKey === dayName">
                    {{ t('GENERIC_WRAPPER.STUDIO_OPENING_HOURS.' + this.errorTranslationKey.toUpperCase()) }}
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  
    <div class="button-container flex-row mt2">
      <df-shared-lib-button class="new-locations-button mr1" [materialButtonStyleType]="'outlined'" [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')" (buttonClicked)="closeDialog()"></df-shared-lib-button>
      <df-shared-lib-button class="new-locations-button" [buttonLabel]="t('GENERIC_WRAPPER.SAVE')" (buttonClicked)="sendOpeningHoursData()"></df-shared-lib-button>
    </div>
  
  </div>
</ng-container>
