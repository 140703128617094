import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmEditUserGroupPageComponent } from './dfm-edit-user-group-page.component';
import { RouterModule, Routes } from '@angular/router';
import { DfmCreateUserGroupFormModule } from '../../components/dfm-create-user-group-form/dfm-create-user-group-form.module';
import { DfmEditUserGroupFormModule } from '../../components/dfm-edit-user-group-form/dfm-edit-user-group-form.module';

const routes: Routes = [

  {
    path: '',
    component: DfmEditUserGroupPageComponent,
  },
];

@NgModule({
  declarations: [DfmEditUserGroupPageComponent],
  imports: [
    CommonModule,
    DfmCreateUserGroupFormModule,
    RouterModule.forChild(routes),
    DfmEditUserGroupFormModule
  ],
  exports: [DfmEditUserGroupPageComponent]
})
export class DfmEditUserGroupPageModule {}
