<ng-container *transloco="let t">
  <div [formGroup]="infoFormGroup" class="flex-column gap-40 h-100" style="max-width: 720px;">
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_NAME') }}</span>
      <mat-form-field appearance="outline" style="max-width: 375px;">
        <mat-label>{{t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_NAME')}}</mat-label>
        <input matInput type="text" formControlName="name" required id="pass-subscription-info-name-input">
        <mat-hint [class.hint-disabled]="name?.disabled" align="end"> {{ name.value?.length ?? 0 }} / 64 </mat-hint>
        <mat-error *ngIf="name.hasError('required')">{{ t('GENERIC_WRAPPER.NAME_REQUIRED') }}</mat-error>
        <mat-error *ngIf="name.hasError('maxlength')">{{ t('GENERIC_WRAPPER.NAME_TOO_LONG') }}</mat-error>
      </mat-form-field>
    </div>
    
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION_DESC') }}</span>
      <div [formGroupName]="'duration'" class="flex-row gap-10">
        <mat-form-field appearance="outline" style="max-width: 150px;">
          <mat-label>{{t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION_VALUE')}}</mat-label>
          <input dfNoScroll matInput type="number" step="1" formControlName="value">
          <mat-error *ngIf="duration.get('value').hasError('required')">{{ t('GENERIC_WRAPPER.SUB_MANAGER.VALUE_REQUIRED') }}</mat-error>
          <mat-error *ngIf="duration.get('value').hasError('min')">{{ t('GENERIC_WRAPPER.MINIMUM_1') }}</mat-error>
          <mat-error *ngIf="duration.get('value').hasError('max')">{{ t('GENERIC_WRAPPER.MAXIMUM_12') }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" style="max-width: 150px;">
          <mat-label>{{t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_DURATION_INTERVAL')}}</mat-label>
          <mat-select formControlName="interval" (selectionChange)="selectedYearAsInterval($event)">
            <mat-option *ngFor="let interval of (passSubscriptionDurationIntervalEnum | keyvalue)" [value]="interval.value">{{ t(interval.value + '_PLURAL') }}</mat-option>
            <mat-option [value]="'YEAR'">{{ t('GENERIC_WRAPPER.YEAR_SINGULAR') }}</mat-option>
          </mat-select>
          <mat-error *ngIf="duration.get('interval').hasError('required')">{{ t('GENERIC_WRAPPER.SUB_MANAGER.INTERVAL_REQUIRED') }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div class="flex-column gap-12">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_WEEKLY_USAGE') }}</span>
      <span class="mat-label-subtext">{{ t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_WEEKLY_USAGE_DESC') }}</span>
      <mat-form-field appearance="outline" style="max-width: 125px;">
        <mat-label>{{t('GENERIC_WRAPPER.SUB_MANAGER.SUBSCRIPTION_WEEKLY_USAGE_VALUE')}}</mat-label>
        <input dfNoScroll matInput type="number" formControlName="weeklyUsage" id="pass-subscription-info-id-input">
        <mat-error *ngIf="weeklyUsage.hasError('min')">{{ t('GENERIC_WRAPPER.MINIMUM_1') }}</mat-error>
        <mat-error *ngIf="weeklyUsage.hasError('max')">{{ t('GENERIC_WRAPPER.MAXIMUM_100') }}</mat-error>
      </mat-form-field>
    </div>
    
    <div class="flex-column gap-12 pb2">
      <span class="mat-label-header">{{ t('GENERIC_WRAPPER.DESCRIPTION_OPTIONAL') }}</span>
      <df-shared-lib-quill-editor
        [hasError]="description.hasError('characterCountExceeded') || (description.hasError('required') && description.touched)"
        [enableMentions]="true"
        [enableCounter]="true"
        [maxLength]="1000"
        [id]="'description'"
        [placeholder]="'GENERIC_WRAPPER.SUBSCRIPTION_DESCRIPTION_DESC'"
        [customFormControlName]="'description'"
      ></df-shared-lib-quill-editor>
    </div>
  </div>
</ng-container>
