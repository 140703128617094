import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchEventsListItemComponent } from './search-events-list-item.component';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [SearchEventsListItemComponent],
    imports: [
        CommonModule,
        RouterLink,
        MatIcon,
        PipesModule,
        TranslocoDirective
    ],
  exports: [SearchEventsListItemComponent]
})
export class SearchEventsListItemModule {}
