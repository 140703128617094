import { Injectable } from '@angular/core';
import { DanceSchoolInterface, PartnerType, UsersInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  getCurrentPartnerId(user: UsersInterface, danceSchool?: DanceSchoolInterface): string {
    if (!danceSchool) {
      return PartnerType.user + '-' + user.id;
    } else {
      return PartnerType.danceSchool + '-' + danceSchool.id;
    }
  }
}
