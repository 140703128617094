<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveBirthDateData()">
    <mat-form-field appearance="outline" (click)="picker.open()">
      <mat-label>{{t('USER.DATE_OF_BIRTH') }}</mat-label>

      <input
        matInput
        autocomplete="off"
        [matDatepicker]="picker"
        [max]="currentDate"
        readonly
        formControlName="birthDate"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

    </mat-form-field>
    <div class="flex-row gap-5">
      <span>{{t('USER.SHOW_AGE_PUBLIC') }}</span>
      <mat-slide-toggle
        formControlName="isAgePublic"
        [disabled]="formGroup.controls.birthDate.value === null"
        [checked]="currentUser.isAgePublic"
      >
      </mat-slide-toggle>
    </div>
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveBirthDateData()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
        class="profile-save-button"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
