import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { DfFabButtonComponent } from './df-fab-button.component';

@NgModule({
  exports: [DfFabButtonComponent],
  declarations: [DfFabButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
})
export class DfFabButtonModule {}
