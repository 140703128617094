import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'currencyPosition'
})
export class CurrencyPositionPipe implements PipeTransform {

  constructor(private translocoService: TranslocoService) {}

  transform(value: number): string {
    const currentLang = this.translocoService.getActiveLang().toUpperCase() || this.translocoService.getDefaultLang().toUpperCase();
    if (currentLang === 'EN') {
      return `€${value}`;
    } else if (currentLang === 'DE') {
      return `${value} €`;
    } else {
      return `€${value}`;
    }
  }
}
