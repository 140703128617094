import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationItemInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent {
  minutes: number;

  @Input() notification: NotificationItemInterface;
  @Output() openNotification = new EventEmitter<NotificationItemInterface>();

  open() {
    this.openNotification.emit(this.notification);
  }
}
