import { LocationInterface } from '../../location.interface';
import { RoomInterface } from '../../room.interface';
import { AddressInterface } from '../../address.interface';
import { DanceStyleInterface } from '../../dance-style.interface';
import { SchedulersInterface } from '../../schedulers/schedulers.interface';
import { AppointmentCommentsInterface } from '../../appointments';
import { UsersInterface } from '../../users';

export interface DanceEventAppointmentsInterface {
  additionalInformation: string;
  address: AddressInterface;
  appointmentToUsers?: any;
  cancellationReason?: string;
  cancelled?: boolean;
  comments?: AppointmentCommentsInterface[];
  content?: string;
  createdAt: Date;
  danceStyles?: DanceStyleInterface[];
  duration: number;
  id: string;
  location?: LocationInterface;
  name: string;
  room?: RoomInterface;
  scheduler?: SchedulersInterface;
  startDate: Date;
  students?: UsersInterface[];
  teachers?: UsersInterface[];
  updatedAt: Date;
}
