<ng-container *transloco="let t">
  <div class="flex-column my3">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>{{t('USER.CHANGE_LANGUAGE')}}</mat-expansion-panel-header>
        <df-shared-lib-language-selection></df-shared-lib-language-selection>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
