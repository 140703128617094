import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUrlDirective } from './contact-url.directive';

@NgModule({
  declarations: [ContactUrlDirective],
  imports: [CommonModule],
  exports: [ContactUrlDirective],
})
export class ContactUrlDirectiveModule {}
