<div>
  <div *ngIf="!mobile; else mobileView">
    <p-galleria
      #galleria
      [(value)]="imageUrls"
      [(activeIndex)]="imageUrlsActiveIndex"
      [numVisible]="4"
      [containerStyle]="{ 'max-width': maxWidth, 'max-height': '400px;' }"
      [containerClass]="galleriaClass()"
      [showThumbnails]="showThumbnails"
      [responsiveOptions]="responsiveOptions"
      (touchstart)="swipeStart($event)"
      (touchend)="swipeEnd($event)"
      [showItemNavigators]="true"
      [showItemNavigatorsOnHover]="true"
      [circular]="true"
      [transitionInterval]="3000"
    >
      <ng-template pTemplate="item" let-item>
        <img
          [src]="item"
          [ngStyle]="{
            display: !fullscreen ? 'block' : '',
            'max-height': !fullscreen ? '400px' : '1033px'
          }"
        />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="p-grid p-nogutter p-justify-center">
          <img
            [src]="item"
            style="width: inherit; max-width: 100px; max-height: 100px"
          />
        </div>
      </ng-template>
      <ng-template pTemplate="footer" let-item>
        <div class="custom-galleria-footer">
          <button
            type="button"
            mat-icon-button
            (click)="onThumbnailButtonClick()"
          >
            <mat-icon>list</mat-icon>
          </button>
          <span *ngIf="imageUrls" class="title-container">
            <span
            >{{ imageUrlsActiveIndex + 1 }}/{{
              imageUrls.length
              }}</span
            >
          </span>
          <div class="p-grid p-nogutter p-justify-center">
            <img [src]="item" />
          </div>
          <button
            type="button"
            mat-icon-button
            (click)="toggleFullScreen()"
            class="fullscreen-button"
          >
            <mat-icon>fullscreen</mat-icon>
          </button>
        </div>
      </ng-template>
    </p-galleria>
  </div>
</div>


<ng-template #mobileView>
  <p-galleria
    [(value)]="imageUrls"
    [numVisible]="5"
    [activeIndex]="imageUrlsActiveIndex"
    [showThumbnails]="false"
    [showIndicators]="true"
    [showIndicatorsOnItem]="true"
    indicatorsPosition="bottom"
    (touchstart)="swipeStart($event)"
    (touchend)="swipeEnd($event)"
  >
    <ng-template pTemplate="item" let-item>
      <img
        [src]="item"
        style="
          width: inherit;
          max-width: 640px;
          max-height: 480px;
          display: block;
        "
      />
    </ng-template>
  </p-galleria>
</ng-template>
