import { AddressInterface, AmenityInterface, LocationInterface, OpeningHoursInterface } from '../interfaces';

export class UpdateLocationRequestDto implements LocationInterface {
  address: AddressInterface;
  amenities: AmenityInterface[];
  email: string;
  isPublic: boolean;
  name: string;
  openingHours: OpeningHoursInterface;
  phoneNumber: string;
  phonePrefix: string;
  phonePrefixAfter: string;
  showOpeningHours: boolean;
}
