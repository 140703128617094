import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmLocationsWrapperPage } from './dfm-locations-wrapper.page';
import { Routes } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { PaginatorModule } from '../paginator';
import { DfSharedButtonModule } from '../buttons';
import { DfLocationsCardModule } from '../dm-locations-card';
import { SharedEmptyPageModule } from '../shared-empty-page';
import { OpenHoursModule } from '../open-hours-dialog';
import { TranslocoDirective } from '@jsverse/transloco';

const routes: Routes = [
  {
    path: '',
    component: DfmLocationsWrapperPage
  },
];


@NgModule({
  declarations: [DfmLocationsWrapperPage],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    PaginatorModule,
    MatTooltipModule,
    MatSlideToggleModule,
    DfSharedButtonModule,
    MatDividerModule,
    DfLocationsCardModule,
    MatProgressSpinnerModule,
    OpenHoursModule,
    SharedEmptyPageModule,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    TranslocoDirective,
  ],
  exports: [DfmLocationsWrapperPage],
})
export class DfmLocationsWrapperPageModule {}
