import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, race, Subject, Subscription, tap } from 'rxjs';
import { CoursesHttpService } from '../http-services';
import { CoordinatesStateService } from './coordinates-state.service';
import { DanceSchoolStateService } from './dance-school-state.service';
import { Router } from '@angular/router';
import { AppointmentsInterface, CourseDto, CourseInterface, CourseRulesInterface, CourseStatusEnum, PageInterface, PassStatusEnum, SortDirectionEnum } from '@platri/df-common-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PassesStateService } from './passes-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class CoursesStateService {
  #courseSubject$ = new BehaviorSubject<CourseInterface>(null);
  courseSubject$ = this.#courseSubject$.asObservable();

  #coursesSubject$ = new BehaviorSubject<CourseInterface[]>(null);
  coursesSubject$ = this.#coursesSubject$.asObservable();

  #appointmentSubject$ = new BehaviorSubject<AppointmentsInterface>(null);
  appointmentSubject$ = this.#appointmentSubject$.asObservable();

  #coursesPageSubject$ = new BehaviorSubject<PageInterface<CourseInterface>>(null);
  coursesPageSubject$ = this.#coursesPageSubject$.asObservable();

  #loadingSubject$ = new BehaviorSubject<boolean>(false);
  loadingSubject$ = this.#loadingSubject$.asObservable();

  #cancelled$ = new Subject<void>();

  subscriptions = new Subscription();

  constructor(
    private readonly _coordinatesService: CoordinatesStateService, 
    private readonly coursesHttpService: CoursesHttpService, 
    private readonly danceSchoolService: DanceSchoolStateService, 
    private readonly router: Router, 
    private readonly translocoService: TranslocoService, 
    private readonly matSnackBar: MatSnackBar,
    public readonly passesService: PassesStateService
  ) {}

  sendCourse(data: CourseInterface): void {
    this.#courseSubject$.next(data);
  }

  sendCourses(data: CourseInterface[]): void {
    this.#coursesSubject$.next(data);
  }

  sendAppointment(data: AppointmentsInterface): void {
    this.#appointmentSubject$.next(data);
  }

  sendCoursesPage(data: PageInterface<CourseInterface>): void {
    this.#coursesPageSubject$.next(data);
  }

  getSyncCoursesPage(): PageInterface<CourseInterface> {
    return this.#coursesPageSubject$.value;
  }
  
  getSyncCourse(): CourseInterface {
    return this.#courseSubject$.value;
  }

  sendLoading(data: boolean): void {
    this.#loadingSubject$.next(data);
  }
  
  getAsyncAvailableAppointment(): Observable<AppointmentsInterface> {
    return this.#appointmentSubject$.asObservable();
  }

  sendCoursePaginationResultChangesExtend(data: PageInterface<CourseInterface>): void {
    const existingResult = this.#coursesPageSubject$.getValue();
    let mergedResult: CourseInterface[] = [];

    if (existingResult?.results?.length > 0) {
      mergedResult = existingResult.results.slice();
      data.results.forEach(course => {
        if (!mergedResult.some(existingCourse => existingCourse.id === course.id)) {
          mergedResult.push(course);
        }
      });
    } else {
      mergedResult = data.results;
    }

    this.#coursesPageSubject$.next({
      results: mergedResult,
      pagination: data.pagination,
      totalElements: data.totalElements
    });
  }

  getAllByDanceManagerIdPagination(danceManagerId: string, page = 1, limit = 10, withAppointments = false): void {
    this.sendLoading(true);

    // race(this.coursesHttpService.getAllByFilter(page, limit, withAppointments, +this._coordinatesService.getSyncCurrentCoordinates()?.latitude ?? null, +this._coordinatesService.getSyncCurrentCoordinates()?.longitude ?? null, 'distance',0, 100, SortDirectionEnum.ASC, null, danceManagerId), this.#cancelled$.pipe(map(() => null))).subscribe({
    //   next: (res): void => {
    //     this.sendCoursePaginationResultChangesExtend(res);
    //     this.sendLoading(false);
    //   },
    //   error: (err): void => {
    //     this.sendLoading(false);
    //   }
    // });
  }

  getAllByDanceManagerIdForDmOverview(danceManagerId: string, page = 1, limit = 100, status: CourseStatusEnum = CourseStatusEnum.ACTIVE): void {
    this.sendLoading(true);
    this.coursesHttpService.getAllByDanceManagerForDmOverview(page, limit, danceManagerId, status).subscribe({
      next: (res: PageInterface<CourseInterface>): void => {
        this.sendCourses(res.results);
        this.sendLoading(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoading(false);
      }
    });
  }
  
  getById(id: string, withAppointments = false): void {
    this.sendLoading(true);
    this.coursesHttpService.getById(id, withAppointments).subscribe({
      next: (data: CourseInterface): void => {
        this.sendCourse(data);
        this.sendLoading(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoading(false);
      }
    });
  }

  updateMainImageById(id: string, formData: FormData): Observable<CourseInterface | CourseDto> {
    //this.addLoadingSubject(true);
    return this.coursesHttpService.updateMainImageById(id, formData).pipe(tap((course) => this.getById(id, true)));
  }

  deleteMainImageById(id: string): Observable<void> {
    return this.coursesHttpService.deleteMainImageById(id).pipe(tap((course) => this.getById(id, true)));
  }

  navigateToCoursesPage(): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().urlName, 'courses']);
  }

  updateCourse(id: string, formData: any): void {
    this.sendLoading(true);
    this.coursesHttpService.updateCourseGeneralInfo(id, formData).subscribe({
      next: (data: CourseInterface) => {
        this.openSnackBarUpdateSuccess();
        this.sendCourse(data);
        this.sendLoading(false);
      },
      error: (err) => {
        this.openSnackBarUpdateFailed();
        console.error(err);
        this.sendLoading(false);
      }
    });
  }
  
  updateCourseRules(id: string, formData: CourseRulesInterface): void {
    this.sendLoading(true);
    this.coursesHttpService.updateCourseRules(id, formData).subscribe({
      next: (data: CourseInterface): void => {
        this.openSnackBarUpdateSuccess();
        this.sendCourse(data);
        this.sendLoading(false);
      },
      error: (err) => {
        this.openSnackBarUpdateFailed();
        console.error(err);
        this.sendLoading(false);
      }
    });
  }

  deleteCourseAppointmentById(id: string, courseId: string): void {
    this.coursesHttpService.deleteCourseAppointmentById(id).subscribe({
      next: () => {
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETED'));
        this.getById(courseId, true);
      },
      error: (err) => {
        console.error(err);
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.SOMETHING_WENT_WRONG'));
      }
    });
  }

  openSnackBarUpdateSuccess(): void {
    this.translocoService.selectTranslate('GENERIC_WRAPPER.COURSE_SNACKBAR.UPDATED_SUCCESSFUL').subscribe((translation) => {
      this.matSnackBar.open(translation);
    });
  }

  openSnackBarUpdateFailed(): void {
    this.translocoService.selectTranslate('GENERIC_WRAPPER.COURSE_SNACKBAR.UPDATE_FAILED').subscribe((translation) => {
      this.matSnackBar.open(translation);
    });
  }

  clear(): void {
    this.#courseSubject$.next(null);
    this.#coursesSubject$.next(null);
    this.#loadingSubject$.next(false);
    this.#coursesPageSubject$.next(null);
  }

  duplicateCourseById(id: string, name: string, danceStyleIds: string[], startDifficulty: number, endDifficulty: number, takeoverPasses: boolean): void {
    this.coursesHttpService.duplicateCourseById(id, name, danceStyleIds, startDifficulty, endDifficulty, takeoverPasses).subscribe(() => {
      this.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 0);
      this.passesService.loadAllDfmCoursePassesByDanceManagerId(this.danceSchoolService.getSyncCurrentDanceSchool().id, PassStatusEnum.ACTIVE);
    });
  }
}
