import { Component, Input, OnChanges, OnDestroy, Optional } from '@angular/core';
import { skipWhile, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Ticket } from '@platri/df-common-shared-graphql';
import { AuthStateService, DanceEventStateService, DanceManagerHttpService, DanceSchoolStateService, ShareComponent } from '@platri/dfx-angular-core';
import { MatDialog } from '@angular/material/dialog';
import { addMinutes } from 'date-fns';
import { AppointmentsInterface, DanceEventsInterface, danceManagerUserHasRoles, DanceManagerUserRoleEnum, DanceSchoolInterface, DanceSchoolToUserInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UserAppLangEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss']
})
export class EventPageComponent implements OnDestroy, OnChanges{
  @Input() danceEvent: DanceEventsInterface;

  appointment: AppointmentsInterface | null;
  danceSchool: DanceSchoolInterface;
  danceSchoolToUsers: DanceSchoolToUserInterface[];
  isOwnerOrAdmin: boolean;
  isEventActive: boolean;
  tickets: Ticket[] = [];
  currentLang: UserAppLangEnum;
  endDate: Date;
  startDateTrimmed: string;
  endDateTrimmed: string;

  googleMapsOptions: google.maps.MapOptions = {
    zoom: 14,
    disableDoubleClickZoom: true,
    clickableIcons: false,
    disableDefaultUI: true,
    draggable: true,
    draggableCursor: 'pointer'
  };

  private subscriptions: Subscription = new Subscription(); 
  
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  
  constructor(
    private readonly router: Router,
    private readonly authService: AuthStateService,
    @Optional() private dialog: MatDialog,
    private readonly translocoService: TranslocoService,
    private readonly danceEventService: DanceEventStateService,
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly danceManagerHttpService:DanceManagerHttpService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnChanges(): void {
    if (this.danceEvent) {
      this.danceSchool = this.danceSchoolService.getSyncCurrentDanceSchool();
      this.appointment = this.danceEvent.appointments?.length! > 0 ? this.danceEvent.appointments![0] : null;
      this.getDanceEventData();
    }
  }

  getDanceEventData(): void {
    this.calculateEndDateOfDanceEvent();
    this.listenOnDanceEventChanges();
    this.getDanceSchoolOfEvent();
    this.getDanceSchoolToUsersByDanceSchoolId();
  }

  listenOnDanceEventChanges(): void {
    this.subscriptions.add(
      this.danceEventService.danceEventSubject$.pipe(skipWhile((val) => val === null)).subscribe((danceEvent) => {
        this.danceEvent = danceEvent;
        this.appointment = danceEvent.appointments?.length > 0 ? danceEvent.appointments[0] : null;

        this.calculateEndDateOfDanceEvent();
      })
    );
  }

  getDanceSchoolOfEvent(): void {
    this.subscriptions.add(
      this.danceManagerHttpService
        .getDanceManagerById(this.danceEvent?.danceSchool!.id!)
        .pipe(skipWhile((val) => val === null))
        .subscribe((result) => {
          this.danceSchool = result;
        })
    );
  }

  getDanceSchoolToUsersByDanceSchoolId(): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceSchoolToUsersByDanceSchoolId(this.danceEvent?.danceSchool!.id!).subscribe((result) => {
        this.danceSchoolToUsers = result;
        this.checkICurrentUserIsOwnerOrAdmin();
      })
    );
  }

  checkICurrentUserIsOwnerOrAdmin(): void {
    this.subscriptions.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        if (user) {
          this.isOwnerOrAdmin = danceManagerUserHasRoles(
            user.id!,
            // @ts-ignore
            this.danceSchoolToUsers,
            [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]
          );
        }
      })
    );
  }

  openGoogleMapsInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  calculateEndDateOfDanceEvent(): void {
    if (this.appointment?.duration! > 0) {
      this.endDate = addMinutes(new Date(this.appointment?.startDate!), this.appointment?.duration!);
      this.endDateTrimmed = this.endDate.toISOString().substring(0, this.endDate.toISOString().indexOf('T'));

      this.startDateTrimmed = this.appointment!.startDate!.toString().substring(0, this.appointment?.startDate!.toString().indexOf('T'));
    }
  }

  onClick(): void {
    this.router.navigate(['dance-event', this.danceEvent.id, 'tickets']);
  }

  openShareComponentDialog(): void {
    this.dialog.open(ShareComponent, {
      autoFocus: false,
      panelClass: ['br-20', 'bg-glassmorph'],
      maxHeight: '100vh',
      maxWidth: '311px',
      minWidth: '310px',
      width: '25vw'
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


 
}
