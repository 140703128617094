import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DanceEventHttpService } from '../http-services';
import { DanceSchoolStateService } from './dance-school-state.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateDanceEventRequestDto, DanceEventsInterface, DanceEventTimePhaseEnum, UpdateDanceEventAppointmentAndLocationRequestDto, UpdateDanceEventRulesRequestDto } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class DanceEventStateService {
  #danceEventSubject$ = new BehaviorSubject<DanceEventsInterface>(null);
  danceEventSubject$ = this.#danceEventSubject$.asObservable();

  #danceEventsSubject$ = new BehaviorSubject<DanceEventsInterface[]>([]);
  danceEventsSubject$ = this.#danceEventsSubject$.asObservable();

  #loadingSubject$ = new BehaviorSubject<boolean>(false);
  loadingSubject$ = this.#loadingSubject$.asObservable();

  constructor(
    private readonly danceEventHttpService: DanceEventHttpService, 
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly router: Router,
    private readonly translocoService: TranslocoService,
    private readonly _matSnackBar: MatSnackBar,
  ) {}

  sendDanceEvent(data: DanceEventsInterface): void {
    this.#danceEventSubject$.next(data);
  }

  sendDanceEvents(data: DanceEventsInterface[]): void {
    this.#danceEventsSubject$.next(data);
  }

  sendLoading(data: boolean): void {
    this.#loadingSubject$.next(data);
  }

  getById(id: string, checkHasSoldTickets?: boolean): void {
    this.danceEventHttpService.getById(id , checkHasSoldTickets).subscribe((result) => {
      this.sendDanceEvent(result);
    });
  }

  getAllByDanceSchool(danceSchoolId: string, onlyWithAppointments: boolean = false, onlyUpcoming: boolean = false): void {
    this.danceEventHttpService.getAllByDanceManagerId(danceSchoolId, onlyWithAppointments, onlyUpcoming).subscribe((result) => {
      this.sendDanceEvents(result);
    });
  }

  createDanceEventNew(data: CreateDanceEventRequestDto, active: boolean, formData: FormData | null): void {
    this.sendLoading(true);
    this.danceEventHttpService.createDanceEvent(data).subscribe({
      next: (result) => {
        if (formData !== null) {
          this.updateMainImageById(result.id, formData).subscribe({
            next: () => {
              this.finalizeDanceEventCreation(active);
            },
            error: () => {
              this.showSnackBarEventCreateSuccessButImageCouldNotBeUploaded(active);
            }
          });
        } else {
          this.finalizeDanceEventCreation(active);
        }
      },
      error: () => {
        this.sendLoading(false);
        this.showSnackBarEventCreateError();
      }
    });
  }

  private finalizeDanceEventCreation(active: boolean): void {
    this.sendLoading(false);
    this.getAllByDanceStudioIdAndTimePhase(this.danceSchoolService.getSyncCurrentDanceSchool().id, DanceEventTimePhaseEnum.UPCOMING);
    this.navigateToEventsOverViewPage();
    this.showSnackBarEventCreateSuccess(active);
  }

  duplicateDanceEventById(id: string, name: string, startDate: Date, duration: number, danceEventTimePhaseEnum:DanceEventTimePhaseEnum, duplicateTickets: boolean): void {
    this.danceEventHttpService.duplicateDanceEventById(id, name, startDate, duration, duplicateTickets).subscribe(() => {
      this.getAllByDanceStudioIdAndTimePhase(
        this.danceSchoolService.getSyncCurrentDanceSchool().id,
        danceEventTimePhaseEnum === DanceEventTimePhaseEnum.DRAFT ?
        DanceEventTimePhaseEnum.DRAFT :
        DanceEventTimePhaseEnum.UPCOMING
      );
      if (danceEventTimePhaseEnum === DanceEventTimePhaseEnum.UPCOMING || danceEventTimePhaseEnum === DanceEventTimePhaseEnum.PAST){
        this.navigateToEventsOverViewPage();
      }
     
    });
  }

  updateDanceEvent(id: string, updateDanceEvent: CreateDanceEventRequestDto, formData?: FormData): void {
    this.danceEventHttpService.updateDanceEvent(id, updateDanceEvent).subscribe((danceEvent) => {
      if (formData) {
        this.danceEventHttpService.updateMainImageById(danceEvent.id, formData).subscribe(() => {
          this.getById(id);
        });
      } else {
        this.sendDanceEvent(danceEvent);
      }
    });
  }

  updateGeneralInfoDanceEventById(id: string, formData: FormData): void {
    this.danceEventHttpService.updateGeneralInfoDanceEventById(id, formData).subscribe({
      next: (danceEvent) => {
        this.sendDanceEvent(danceEvent);
        this.showSnackBarEventUpdateSuccess();
      },
      error: (error) => {
        this.showSnackBarEventUpdateError();
      }
    });
  }

  updateAppointmentAndLocationDanceEventById(id: string, data: UpdateDanceEventAppointmentAndLocationRequestDto): void {
    this.danceEventHttpService.updateAppointmentAndLocationDanceEventById(id, data).subscribe({
      next: (danceEvent) => {
        this.sendDanceEvent(danceEvent);
        this.showSnackBarEventUpdateSuccess();
      },
      error: (error) => {
        this.showSnackBarEventUpdateError();
      }
    });
  }

  updateRulesDanceEventById(id: string, data: UpdateDanceEventRulesRequestDto): void {
    this.danceEventHttpService.updateRulesDanceEventById(id, data).subscribe({
      next: (danceEvent) => {
        this.sendDanceEvent(danceEvent);
        this.showSnackBarEventUpdateSuccess();
      },
      error: (error) => {
        this.showSnackBarEventUpdateError();
      }
    });
  }

  deleteById(id: string): void {
    this.danceEventHttpService.deleteById(id).subscribe(() => {
      this.getAllByDanceSchool(this.danceSchoolService.getSyncCurrentDanceSchool().id, false, false);
    });
  }

  updateMainImageById(id: string, title: FormData): Observable<DanceEventsInterface> {
    return this.danceEventHttpService.updateMainImageById(id, title).pipe(tap(() => this.getById(id)));
  }

  deleteMainImageById(id: string): Observable<void> {
    return this.danceEventHttpService.deleteMainImageById(id).pipe(tap(() => this.getById(id)));
  }

  navigateToEventsOverViewPage(): void {
    this.router.navigate(['/', 'dance-manager', this.danceSchoolService.getSyncCurrentDanceSchool().id, 'events']);
  }

  getAllByDanceStudioIdAndTimePhase(danceStudioId: string, timePhase: DanceEventTimePhaseEnum): void {
    this.sendLoading(true);
    this.sendDanceEvents([]);
    this.danceEventHttpService.getAllByDanceManagerIdAndTimePhase(danceStudioId, timePhase).subscribe({
      next: (result) => {
        this.sendDanceEvents(result);
        this.sendLoading(false);
      },
      error: (err) => {
        console.error(err);
        this.sendLoading(false);
      }
    });
  }

  clear(): void {
    this.#danceEventSubject$.next(null);
    this.#danceEventsSubject$.next([]);
    this.#loadingSubject$.next(false);
  }

  showSnackBarEventCreateSuccessButImageCouldNotBeUploaded(active: boolean): void {
    if (active) {
      this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.CREATED_SUCCESS_IMAGE_FAILED').subscribe((translation) => {
        this._matSnackBar.open(translation);
      });
    } else {
      this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.CREATED_DRAFT').subscribe((translation) => {
        this._matSnackBar.open(translation);
      });
    }
  }

  showSnackBarEventCreateSuccess(active: boolean): void {
    if (active) {
      this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.CREATED_SUCCESS').subscribe((translation) => {
        this._matSnackBar.open(translation);
      });
    } else {
      this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.CREATED_DRAFT').subscribe((translation) => {
        this._matSnackBar.open(translation);
      });
    }
  }

  showSnackBarEventCreateError(): void {
    this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.CREATE_ERROR').subscribe((translation) => {
      this._matSnackBar.open(translation);
    });
  }

  showSnackBarEventUpdateSuccess(): void {
    this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.UPDATE_EVENT').subscribe((translation) => {
      this._matSnackBar.open(translation);
    });
  }

  showSnackBarEventUpdateError(): void {
    this.translocoService.selectTranslate('GENERIC_WRAPPER.EVENT_SNACKBAR.UPDATE_EVENT_ERROR').subscribe((translation) => {
      this._matSnackBar.open(translation);
    });
  }
}
