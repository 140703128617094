<ng-container *transloco="let t">
  <div style="height: 100vh;" class="background-fade w-100 h-100 flex-row-center">
    <div class="invite-container align-center">
      <ng-container *ngIf="!loading; else loadingSpinner">
        <ng-container *ngIf="!error; then inviteAccepted else errorContainer"></ng-container>
      </ng-container>
    </div>
  </div>
  
  <ng-template #loadingSpinner>
    <mat-spinner [diameter]="35"></mat-spinner>
  </ng-template>
  
  <ng-template #inviteAccepted>
    <div class="dialog-container flex-column" [ngSwitch]="error">
      <div class="header-row flex-row">
        <span>{{ t('GENERIC_WRAPPER.USER_INVITE_ACCEPTED_TITLE')}}</span>
        <span class="close-btn flex-hide" (click)="close()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
      <div class="dialog-description">
        {{ t('GENERIC_WRAPPER.USER_INVITE_ACCEPTED')}}
      </div>
      
      <div class="button-row">
        <df-shared-lib-button
          class="w-100"
          [buttonLabel]="t('GENERIC_WRAPPER.OK')"
          (buttonClicked)="close()"
        >
        </df-shared-lib-button>
      </div>
    </div>
  </ng-template>
  
  <ng-template #errorContainer>
    <div class="dialog-container flex-column" [ngSwitch]="error">
      <div class="header-row flex-row">
        <ng-container *ngSwitchCase="DmAddUserErrorEnum.USER_ALREADY_EXISTS_AT_DANCE_MANAGER">
          <span style="width: 90%;">{{ t('GENERIC_WRAPPER.USER_ALREADY_EXISTS_AT_DANCE_MANAGER')}}</span>
        </ng-container>
        <ng-container *ngSwitchCase="DmAddUserErrorEnum.USER_INVITE_TOKEN_NOT_FOUND || DmAddUserErrorEnum.USER_INVITE_TOKEN_EXPIRED">
          {{ t('GENERIC_WRAPPER.INVALID_LINK')}}
        </ng-container>
        <span class="close-btn flex-hide" (click)="close()"><mat-icon>close</mat-icon></span>
      </div>
      <div class="dialog-description">
        <ng-container *ngSwitchCase="DmAddUserErrorEnum.USER_INVITE_TOKEN_NOT_FOUND || DmAddUserErrorEnum.USER_INVITE_TOKEN_EXPIRED">
          {{ t('GENERIC_WRAPPER.USER_INVITE_TOKEN_NOT_FOUND')}}
        </ng-container>
      </div>
    
      <div class="button-row">
        <df-shared-lib-button
          class="w-100"
          [buttonLabel]="t('GENERIC_WRAPPER.OK')"
          (buttonClicked)="close()"
        >
        </df-shared-lib-button>
      </div>
    </div>
  </ng-template>
</ng-container>
