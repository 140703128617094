<ng-container *transloco="let t">
  <div class="pt2 flex-column align-center">
    <div class="loading-box"  [class.spinner-big]="true" *ngIf="isLoadingUserTickets"></div>
    <mat-card style="padding: 16px;" class="tickets-container w-100 flex-column" *ngIf="!isLoadingUserTickets">
      <div class="px1 header-title-container">
        <div class="flex-row align-start-center">
          <button mat-icon-button (click)="onBackButtonClick()" style="margin-right: 15px;">
            <mat-icon class="back-btn-icon">arrow_back_rounded</mat-icon>
          </button>
          <span class="event-title">{{userTickets[0].ticket.danceEvent.name}}</span>
        </div>
          <button *ngIf="ticketOrderIds?.length > 0 || freeTicketIds?.length > 0" mat-icon-button [matMenuTriggerFor]="menu1" >
            <mat-icon class="material-icons-round">more_vert</mat-icon>
          </button>
          <mat-menu #menu1="matMenu" xPosition="before">
            <button *ngFor="let ticketOrderId of ticketOrderIds; let index = index" mat-menu-item (click)="downloadTickets(ticketOrderId)">
              <mat-icon class="material-icons-round">file_download</mat-icon>
              <span *ngIf="ticketOrderIds.length > 1">Tickets {{ index + 1 }}</span>
              <span *ngIf="ticketOrderIds.length === 1">Ticket</span>
            </button>
            <button *ngIf="freeTicketIds.length > 0" mat-menu-item (click)="downloadFreeUserTickets()">
              <mat-icon class="material-icons-round">file_download</mat-icon>
              <span *ngIf="freeTicketIds.length > 1">Free Tickets</span>
              <span *ngIf="freeTicketIds.length === 1">Free Ticket</span>
            </button>
          </mat-menu>
        </div>
      <div class="p2">
        <mat-divider style="position: initial"></mat-divider>
      </div>
      <div style="overflow: auto;" class="p2 flex-row-center-center">
        <div *ngIf="currentUserTicket.status === userTicketStatusEnum.INVALID">
          <div class="cancelled-tickets-button align-center w-100">
            <div class="flex-row align-center">
              <div>
                <mat-icon class="distance-icon material-icons-round vSub" style="color: #B00020; margin-right: 8px;">info</mat-icon>
              </div>
              <div>
                <span>{{ t('USER.EVENT_CANCELLED_EXTENDED') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="user-ticket-box p3 flex-column">
          <button *ngIf="currentUserTicket.status === userTicketStatusEnum.PAID" mat-icon-button [matMenuTriggerFor]="menu2" (click)="$event.stopPropagation()">
            <mat-icon class="material-icons-round">more_vert</mat-icon>
          </button>
          <mat-menu #menu2="matMenu" xPosition="before">
            <button mat-menu-item (click)="openTransferTicketDialog()">
              <mat-icon class="material-icons-round">swap_horiz</mat-icon>
              <span>{{ t('USER.TRANSFER_TICKET') }}</span>
            </button>
          </mat-menu>
          <div class="flex-row align-center" [ngStyle]="currentUserTicket.status === userTicketStatusEnum.FREE ? {'padding-top': '40px'} : {}">
            <button *ngIf="userTickets.length > 1" [disabled]="currentUserTicketIndex === 0" mat-icon-button (click)="onLeftButtonClick()"><mat-icon>arrow_back_rounded</mat-icon></button>
            
            
            <div class="flex-column align-center">
              <span>{{currentUserTicketIndex + 1}}/{{userTickets.length}}</span>
  
              <div *ngIf="!(currentUserTicket.status === userTicketStatusEnum.INVALID); else invalidBlock">
                <qrcode [qrdata]="currentUserTicket.id" [width]="150"></qrcode>
              </div>
              <ng-template #invalidBlock>
                <div class="flex-column align-center mt2 mb2">
                  <mat-icon class="mb2">
                    close
                  </mat-icon>
                  <span class="invalid-text">
                    {{ t('USER.INVALID_TICKET') }}
                  </span>
                </div>
              </ng-template>
  
            </div>
  
            
            <button *ngIf="userTickets.length > 1" [disabled]="currentUserTicketIndex === userTickets.length - 1" mat-icon-button (click)="onRightButtonClick()"><mat-icon>arrow_forward_rounded</mat-icon></button>
          </div>
          <div class="mt1 flex-column align-start">
            <span class="ticket-detail-label">{{ t('GENERIC_WRAPPER.NAME') }}</span>
            <span class="ticket-detail-info">{{currentUser.firstName + ' ' + currentUser.lastName}}</span>
          </div>
          <div class="mt1 flex-column">
            <span class="ticket-detail-label">{{ t('USER.TICKET') }}</span>
            <span class="ticket-detail-info">{{currentUserTicket.ticket.name}}</span>
          </div>
          <div class="mt1 flex-row align-space-between">
            <div *ngIf="currentUserTicket.userTicketDanceRoleType" class="flex-column">
              <span class="ticket-detail-label">{{ t('USER.DANCE_ROLE') }}</span>
              <span class="ticket-detail-info">{{currentUserTicket.userTicketDanceRoleType | titlecase}}</span>
            </div>
            <div class="mb1 flex-column">
              <span class="ticket-detail-label">{{ t('GENERIC_WRAPPER.PRICE') }}</span>
              <span class="ticket-detail-info">{{ currentUserTicket.ticket.price | centsToCurrencyString: 'EUR' }}</span>
            </div>
          </div>
          <mat-divider class="dashed-divider"></mat-divider>
          <div class="mt1 flex-column">
            <span class="ticket-detail-label">{{ t('GENERIC_WRAPPER.ORGANIZER') }}</span>
            <a class="ticket-detail-info cursor-pointer" [href]="'/app/@' + currentUserTicket.ticket.danceEvent.danceSchool.urlName">
              {{currentUserTicket.ticket.danceEvent.danceSchool.name}}
            </a>
          </div>
          <div class="mt1 flex-column">
            <span class="ticket-detail-label">{{ t('GENERIC_WRAPPER.EVENT') }}</span>
            <a class="ticket-detail-info cursor-pointer" [href]="'/app/dance-event/' + currentUserTicket.ticket.danceEvent.id">
              {{currentUserTicket.ticket.danceEvent.name}}
            </a>
          </div>
          <div class="mt1 align-space-between flex-row">
            <div class="flex-column" style="width: 45%;">
              <span class="ticket-detail-label">{{ t('GENERIC_WRAPPER.ADDRESS') }}</span>
              <span class="ticket-detail-info">{{currentUserTicket.ticket.danceEvent.appointments[0].address.formattedAddress}}</span>
            </div>
            <div class="mt1 flex-column" style="width: 45%;">
              <span class="ticket-detail-label">{{ t('GENERIC_WRAPPER.DATE') }}</span>
              <span class="ticket-detail-info">{{ currentUserTicket.ticket.danceEvent.appointments[0].startDate
                      | dateTimezoned: 'UTC':'DE':currentUserTicket.ticket.danceEvent.appointments[0].duration:true }} <br> {{ currentUserTicket.ticket.danceEvent.appointments[0].startDate
                      | dateTimezoned: 'UTC':'DE':currentUserTicket.ticket.danceEvent.appointments[0].duration:false: true }}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
