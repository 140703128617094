<ng-container *transloco="let t">
  <div class="align-center">
    <ng-container *ngIf="!hasDanceSchool">
      <mat-card class="mb2 w-100 container-item-card">
          <div class="content-container-flex w-100">
            <div class="left-column flex-column-center">
              <div class="preview-image-container flex-column">
                <img class="preview-image w-100 mb0 block" [src]="DF_SEARCH_DM_AD_PICTURE" [alt]="">
              </div>
            </div>
            <div class="w-100 right-column flex-column align-start gap-15" style="flex: 1;">
              <div class="flex-column gap-4">
                <span class="title">{{t('GENERIC_WRAPPER.DM_SEARCH_AD_TITLE')}}</span>
                <span class="subtitle">{{t('GENERIC_WRAPPER.DM_SEARCH_AD_SUBTITLE')}}</span>
              </div>
              <df-shared-lib-button [buttonLabel]="t('GENERIC_WRAPPER.ENTER_NOW_FOR_FREE')" [borderRadius]="'24px'" [minWidth]="'50%'" (buttonClicked)="onButtonClicked()"></df-shared-lib-button>
            </div>
          </div>
      </mat-card>
    </ng-container>
  </div>
</ng-container>
