import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareComponent } from './share.component';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { MatButtonModule } from '@angular/material/button';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [ShareComponent],
  imports: [
    CommonModule,
    ShareButtonModule,
    MatButtonModule,
    ShareIconsModule,
    ShareButtonsModule,
    MatIconModule,
    MatDialogModule,
    TranslocoDirective
  ],
  exports: [ShareComponent],
})
export class ShareModule {
  constructor() {}
}
