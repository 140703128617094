<ng-container *transloco="let t">
  <div class="title-div">
    <h2 class="">{{ t('DANCE_MANAGER_EVENT.EVENT_TICKETS') }} / {{ event.name }}</h2>
    <button mat-stroked-button [routerLink]="'create'">
      <mat-icon [svgIcon]="'df-add'" style="vertical-align: middle"></mat-icon>
      <span style="vertical-align: middle">{{t('DANCE_MANAGER_EVENT.NEW_TICKET')}}</span>
    </button>
  </div>
  <div class="ticket-list-container" cdkScrollable>
    <div
      class="w-100 flex-column align-center"
      style="padding: 40px 10%"
    >
      <div
        cdkDropList
        class="ticket-list flex-column mb1"
        (cdkDropListDropped)="mouseDrop($event)"
      >
        <mat-card
          *ngFor="let ticket of tickets"
          class="box-shadow mb1 flex-row"
          cdkDrag
        >
          <div
            cdkDragHandle
            class="drag-and-drop-indicator align-center"
            style="width: 5%;"
            [matTooltip]="t('DANCE_MANAGER_EVENT.DRAG_AND_DROP')"
          >
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <div>
            <div class="ml1 flex-column">
              <div class="align-space-between flex-row">
                <span
                  ><strong>{{ ticket.name }}</strong></span
                >
                <span
                  ><strong>
                  {{ticket.ticketType === ticketTypeEnum.FREE ? '' : ticket.price | centsToCurrencyString: 'EUR'}}</strong></span
                >
              </div>
              <div class="flex-row align-space-between">
                <div class="flex-column mb1">
                  <span *ngIf="ticket.saleStart !== null && currentDate !== null && (ticket.saleStart?.getTime() > currentDate?.getTime())">{{t('DANCE_MANAGER_EVENT.SALE_STARTS')}}: {{ticket.saleStart | dateTimezoned :  'UTC' : currentLang: ticket.eventDuration}}</span>
                  <span *ngIf="ticket.saleEnd !== null && currentDate !== null && ((ticket.saleEnd?.getTime() >= currentDate?.getTime()) || (ticket.saleStart !== null && (ticket.saleStart?.getTime() <= currentDate?.getTime())))">{{t('DANCE_MANAGER_EVENT.ON_SALE')}}: {{ticket.saleEnd | dateTimezoned : 'UTC' : currentLang: ticket.eventDuration}}</span>
                  <span
                    >{{ticket.ticketType === ticketTypeEnum.FREE ?
                    ticket.quantity + ' ' + ('DANCE_MANAGER_EVENT.INVITED')
                    : ticket.soldQuantity + '/' + ticket.quantity + ' ' + ('DANCE_MANAGER_EVENT.SOLD')}}</span
                  >
                </div>
                <div class="flex-column">
                  <span *ngIf="ticket.feesPaidBy === FeesPaidByEnum.CUSTOMER">
                    + 3.11 € {{ t('DANCE_MANAGER_EVENT.FFEES_AND_TAXES') }}
                  </span>
                </div>
              </div>
              <p [innerHTML]="ticket.description"></p>
              <div class="flex-row align-end">
                <button mat-icon-button [disabled]="ticket.ticketType === ticketTypeEnum.FREE" [matTooltip]="t('mat-icons.edit')" (click)="navigateToEditTicketPage(ticket.id)">
                  <mat-icon
                    class="material-icons-round"
                    >edit</mat-icon
                  >
                </button>
                <button
                  mat-icon-button
                  [disabled]="ticket.ticketType === ticketTypeEnum.FREE || ticket.soldQuantity > 0"
                  (click)="deleteTicket(ticket)"
                >
                  <mat-icon
                    [matTooltip]="
                      ticket.soldQuantity > 0
                        ? t('DANCE_MANAGER_EVENT.DISABLED_DELETE')
                        : t('mat-icons.delete')
                    "
                    class="material-icons-round"
                    >delete
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</ng-container>
