import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { AppointmentCardComponent } from './appointment-card.component';

@NgModule({
  declarations: [AppointmentCardComponent],
  imports: [CommonModule, MatCardModule],
  exports: [AppointmentCardComponent],
})
export class AppointmentCardModule {}
