import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfQuillEditorComponent } from './df-quill-editor.component';
import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../pipes';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfQuillEditorComponent],
    imports: [
      CommonModule,
      FormsModule,
      QuillModule,
      PipesModule,
      ReactiveFormsModule,
      MatInputModule,
      TranslocoDirective,
    ],
  exports: [DfQuillEditorComponent],
})
export class DfQuillEditorModule {}
