import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@platri/dfx-angular-core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CourseAppointmentsTableComponent } from './course-appointments-table.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CourseAppointmentsTableComponent],
  exports: [CourseAppointmentsTableComponent],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatMenuModule,
        RouterModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatTooltipModule,
        TranslocoDirective
    ]
})
export class CourseAppointmentsTableModule {}
