import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelBarComponent } from './level-bar.component';

@NgModule({
  declarations: [LevelBarComponent],
  imports: [CommonModule],
  exports: [LevelBarComponent],
})
export class LevelBarModule {}
