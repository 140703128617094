import { RadiusUnitEnum, SortDirectionEnum } from '../../../enums';
import { TimeInterface } from '../../time.interface';

export interface SearchRoomsQueryInterface {
  pageNumber?: number;
  pageSize?: number;
  sortCol?: string;
  sortDir?: SortDirectionEnum;
  search?: string;
  longitude?: number;
  latitude?: number;
  radius?: number;
  radiusUnit?: RadiusUnitEnum;
  roomSizeFrom?: number;
  roomSizeTo?: number;
  priceFrom?: number;
  priceTo?: number;
  date?: Date;
  timeFrom?: TimeInterface;
  timeTo?: TimeInterface;
}
