import { CourseRulesRequestDto } from './course-rules-request.dto';

export class UpdateCourseRequestDto {
  name: string;
  description: string;
  startDifficulty: number;
  endDifficulty: number;
  danceStyleIds: string[];
  tags?: string[];
  rules?: CourseRulesRequestDto;
  isCheckInActive: boolean;
}
