import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'df-info-dialog',
  templateUrl: 'info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  
  constructor(public cancelRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { icon: string; title: string; description: string; buttonText: string }) {}

  close(confirmed = true): void {
    this.cancelRef.close(confirmed);
  }
}
