import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FollowHttpService } from '../http-services/follow.http.service';
import { FollowCountsInterface, FollowInterface, FollowTargetTypeEnum, FollowUserInterface } from '@platri/df-common-core';
import { getSubscription } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class UserFollowStateService implements OnDestroy {
  isLoadingFollow = new BehaviorSubject<boolean>(false);
  availableFollowCount = new BehaviorSubject<FollowCountsInterface | null>(null);
  availableFollows = new BehaviorSubject<FollowUserInterface[]>([]);
  availableFollowers = new BehaviorSubject<FollowUserInterface[]>([]);
  
  subscriptions = new Subscription();

  constructor(private readonly followHttpService: FollowHttpService) {
    console.log('Initializing UserFollowStateService');
  }

  ngOnDestroy(): void {
    console.log('Destroying UserFollowStateService');
    this.subscriptions.unsubscribe();
    this.isLoadingFollow.unsubscribe();
    this.availableFollowCount.unsubscribe();
  }

  getAsyncIsLoadingFollow(): Observable<boolean> {
    return this.isLoadingFollow.asObservable();
  }

  getAsyncAvailableFollowCount(): Observable<FollowCountsInterface> {
    return this.availableFollowCount.asObservable();
  }

  getAsyncAvailableFollows(): Observable<FollowUserInterface[]> {
    return this.availableFollows.asObservable();
  }

  getAsyncAvailableFollowers(): Observable<FollowUserInterface[]> {
    return this.availableFollowers.asObservable();
  }
  
  getSyncAvailableFollowCount(): FollowCountsInterface {
    return this.availableFollowCount.getValue();
  }

  loadFollowsByTargetIdAndTargetType(targetId: string, targetType: FollowTargetTypeEnum):void {
    this.isLoadingFollow.next(true);
    this.subscriptions.add(getSubscription(this.followHttpService.getFollowsByTargetIdAndTargetType(targetId, targetType), this.onLoadedFollows.bind(this), () => {console.log(`Couldn't load Follows`); this.sendIsLoadingFollow(false);}));
  }

  loadFollowersByTargetIdAndTargetType(targetId: string, targetType: FollowTargetTypeEnum):void {
    this.isLoadingFollow.next(true);
    this.subscriptions.add(getSubscription(this.followHttpService.getFollowersByTargetIdAndTargetType(targetId, targetType), this.onLoadedFollowers.bind(this), () => {console.log(`Couldn't load Followers`); this.sendIsLoadingFollow(false);}));
  }

  loadFollowCountByTargetIdAndTargetType(targetId: string, targetType: FollowTargetTypeEnum) : void {
    this.sendIsLoadingFollow(true);
    this.subscriptions.add(getSubscription(this.followHttpService.getFollowCountsByTargetIdAndTargetType(targetId, targetType), this.onLoadedFollowCount.bind(this), () => {console.log(`Couldn't load Follow Count`); this.sendIsLoadingFollow(false);}));
  }

  onLoadedFollows(follows: FollowUserInterface[]): void {
    this.sendIsLoadingFollow(false);
    this.sendAvailableFollows(follows);
  }

  onLoadedFollowers(followers: FollowUserInterface[]): void {
    this.sendIsLoadingFollow(false);
    this.sendAvailableFollowers(followers);
  }

  onLoadedFollowCount(followCount: FollowCountsInterface): void {
    this.sendIsLoadingFollow(false);
    this.sendAvailableFollowCount(followCount);
  }

  private sendAvailableFollowCount(followCount: FollowCountsInterface): void {
    this.availableFollowCount.next(followCount);
  }

  private sendAvailableFollows(follows: FollowUserInterface[]): void {
    this.availableFollows.next(follows);
  }

  private sendAvailableFollowers(followers: FollowUserInterface[]): void {
    this.availableFollowers.next(followers);
  }

  private sendIsLoadingFollow(isLoading: boolean): void {
    this.isLoadingFollow.next(isLoading);
  }

  clear(): void {
    this.isLoadingFollow.next(false);
    this.availableFollowCount.next(null);
    this.availableFollows.next([]);
    this.availableFollowers.next([]);
  }

  followUser(targetId: string, targetType = FollowTargetTypeEnum.USER): Observable<FollowInterface> {
    return this.followHttpService.addFollowToUser({ targetId, targetType });
  }

  unfollowUser(followId: string): Observable<void> {
    return this.followHttpService.deleteFollowFromUser(followId);
  }
  
  
}
