import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentForWeb, PlaceDto } from '@platri/df-common-core';

@Injectable({providedIn: 'root'})
export class SearchHttpService {
  private SERVICE_URL = `${environmentForWeb.apiAdministrationUrl}/google-autocomplete`;

  constructor(private http: HttpClient) {}

  getAddressFromCoordinate(placeDto: PlaceDto): Observable<any> {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${placeDto.latitude.toString()},${placeDto.longitude.toString()}&key=${environmentForWeb.googleMapsApiKey}`) as Observable<any>;
  }

  getTimezoneFromCoordinate(placeDto: PlaceDto): Observable<any> {
    return this.http.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${placeDto.latitude.toString()}%2C${placeDto.longitude.toString()}&timestamp=1632999309&key=${environmentForWeb.googleMapsApiKey}`) as Observable<any>;
  }
  
  getAddressFromSearchKey(key: string): Observable<any> {
    return this.http.get<any>(this.SERVICE_URL + '/' + key);
  }

  getAddressDetailsFromPlaceId(id: string): Observable<any> {
    return this.http.get<any>(this.SERVICE_URL + '/detail/' + id);
  }
}
