<ng-container *transloco="let t">
  <div class="flex-column w-100" style="gap: 1rem;">
    <div class="flex-row" style="padding-top: 10px;">
      <mat-chip (click)="togglePassChip(0)" [class]="this.activePassChipIndex === 0 ? 'active-chip' : ''" style="margin-right: 10px;">
        {{t('GENERIC_WRAPPER.ACTIVE')}}
      </mat-chip>
      <mat-chip (click)="togglePassChip(1)" [class]="this.activePassChipIndex === 1 ? 'active-chip' : ''">
        {{t('GENERIC_WRAPPER.ARCHIVED')}}
      </mat-chip>
    </div>
    <table 
      *ngIf="passes?.length > 0; else noPasses" 
      mat-table 
      [dataSource]="dataSource" 
      multiTemplateDataRows 
      class="w-100" 
      cdkDropList 
      cdkDropListData="dataSource" 
      [cdkDropListDisabled]="dragDisabled" 
      (cdkDropListDropped)="drop($event)"
    >
      <ng-container matColumnDef="position" sticky>
        <mat-header-cell *matHeaderCellDef class="no-dividers"></mat-header-cell>
        <mat-cell *matCellDef="let element" class="no-dividers">
          <mat-icon class="dragCursor" (mousedown)="dragDisabled = false;">drag_indicator</mat-icon>
          <span>{{element.sequenceNumber}}</span>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="no-dividers">{{ t('GENERIC_WRAPPER.PASSES_TABLE.NAME') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="no-dividers">
          <div class="flex-row align-start-center">
            <span>{{ element.name }}</span>
          </div>
        </mat-cell> 
      </ng-container>
      
      <ng-container matColumnDef="accessTargetNames">
        <mat-header-cell *matHeaderCellDef class="no-dividers">{{ t('GENERIC_WRAPPER.PASSES_TABLE.COURSES') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="no-dividers">
          <ng-container *ngIf="element.accessTargetNames && element.accessTargetNames.length > 0; else noCoursesTargets">
            <div [ngClass]="{'ellipsis-1': element !== expendedCoursesElement}" class="gap-4" style="margin: 16px 0 16px 0" [matTooltip]="element.accessTargetNames.join(', ')">
              @for (target of element.accessTargetNames; track target; let last = $last) {
                {{ target | titlecase }}
                {{ last ? '' : ', ' }}
              }
            </div>
          </ng-container>
          <ng-template #noCoursesTargets>
            -
          </ng-template>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="stamps">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.PASSES_TABLE.STAMPS') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
            {{ element.usageCount !== 0 ? element.usageCount : '-'}}
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="validity">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.PASSES_TABLE.VALIDITY') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
            {{ element.validity ? (element.validity.value + ' ' + t('GENERIC_WRAPPER.' + element.validity.interval +  (element.validity.value > 1 ? '_PLURAL' : '_SINGULAR'))) : '-'}}
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="sold">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.PASSES_TABLE.SOLD') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
          {{ element.quantity !== null ? element.soldQuantity + '/' + element.quantity : element.soldQuantity}}
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef class="center-content no-dividers">{{ t('GENERIC_WRAPPER.PASSES_TABLE.PRICE') }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="center-content no-dividers">
            {{ element.price | centsToCurrencyString: 'EUR' }}
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef class="no-dividers"></mat-header-cell>
  
        <mat-cell *matCellDef="let element" class="no-dividers">
          <div class="flex-row" style="justify-content: flex-end !important;">
            <button mat-icon-button (click)="expandItem(element); $event.stopPropagation()">
             <mat-icon>{{ expendedCoursesElement === element ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="flex-row-end-center">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editPassById(element)">
              <span>{{t('GENERIC_WRAPPER.EDIT') }}</span>
            </button>
            <button mat-menu-item (click)="duplicatePassById(element)">
              <span>{{t('GENERIC_WRAPPER.DUPLICATE') }}</span>
            </button>
            <button *ngIf="element.status === PassStatusEnum.ACTIVE" mat-menu-item (click)="updatePassStatusById(element, PassStatusEnum.ARCHIVED)">
              <span>{{t('GENERIC_WRAPPER.ARCHIVE') }}</span>
            </button>
            <button *ngIf="element.status !== PassStatusEnum.ACTIVE" mat-menu-item (click)="updatePassStatusById(element, PassStatusEnum.ACTIVE)">
              <span>{{t('GENERIC_WRAPPER.ACTIVATE') }}</span>
            </button>
            <button mat-menu-item (click)="deletePassById(element)">
              <span>{{t('GENERIC_WRAPPER.DELETE') }}</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="expandedPass">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div *ngIf="element === expandedElement">
            <div class="pass-description"  [innerHTML]="element.description"></div>
          </div>
        </td>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row 
        cdkDrag 
        [cdkDragData]="row" 
        class="clickable-row" 
        [ngClass]="{'expanded-row': row === expandedElement}" 
        mat-row 
        *matRowDef="let row; columns: displayedColumns" 
        (click)="editPassById(row)"
      ></mat-row>
      <mat-row 
        *matRowDef="let row; columns: ['expandedPass']" 
        [ngClass]="row === expandedElement ? 'expanded-pass-description' :  'unexpanded-pass-description'" 
        class="clickable-row" 
        (click)="editPassById(row)"
      ></mat-row>
    </table>
  </div>

  <ng-template #noPasses>
    <div class="align-center" style="padding-top: 113px;">
      <df-shared-empty-page
        [buttonLabel]="t('GENERIC_WRAPPER.CREATE_NEW_PASS')"
        [emptyTitle]="t('GENERIC_WRAPPER.NO_PASSES')"
        [emptyDescription]="t('GENERIC_WRAPPER.NO_PASSES_TEXT')"
        [imagePath]="'assets/images/courses-manager/no_courses.svg'"
        (buttonClicked)="navigateToCreatePassPage()"></df-shared-empty-page>
    </div>
  </ng-template>
</ng-container>
