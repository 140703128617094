<ng-container *transloco="let t">
  <div class="w-100 flex-column">
      <div class="flex-row" style="gap: 10px;">
          <mat-icon style="cursor: pointer" (click)="showPaymentHistoryClick.emit(false)">arrow_back</mat-icon>
          <span>{{ t('GENERIC_WRAPPER.SETTINGS') }}</span>
      </div>
      <table mat-table [dataSource]="dataSource" matSort class="w-100 pt2">
          <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef scope="col">{{ t('GENERIC_WRAPPER.DATE') }}</th>
              <td mat-cell *matCellDef="let element">{{ element.createdAt | dateTimezoned:  'UTC' : currentLang : null : true }}</td>
          </ng-container>
  
          <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef scope="col">{{ t('GENERIC_WRAPPER.TOTAL')}}</th>
              <td mat-cell *matCellDef="let element">{{ danceManagerSubscription.totalPrice | centsToCurrencyString: 'EUR' }}</td>
          </ng-container>
  
          <ng-container matColumnDef="downloadInvoice">
              <th mat-header-cell *matHeaderCellDef scope="col"></th>
              <td mat-cell *matCellDef="let element">
                  <a
                          style="color: #00a1df; cursor: pointer"
                          target="_blank"
                          [href]="
              element.stripeInvoicePdfDownloadUrl
            "
                  >
                      {{ t('GENERIC_WRAPPER.DOWNLOAD_INVOICE') }}
                  </a>
              </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
  </div>
</ng-container>
