import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DanceEvent } from '@platri/df-common-shared-graphql';
import { ConfirmationDialogInterface, DanceEventTimePhaseEnum, UpdateEventStatusErrorEnum, UsersInterface } from '@platri/df-common-core';
import { AuthStateService, DanceEventHttpService, DanceEventStateService } from '../../../services';
import { ConfirmationDialogComponent } from '../../confirmation-dialog';
import { DuplicateDanceEventDialogComponent } from '../../duplicate-dance-event-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dm-event-preview-card',
  templateUrl: './dm-event-preview-card.component.html',
  styleUrls: ['./dm-event-preview-card.component.scss']
})
export class DmEventPreviewCardComponent implements OnInit, OnDestroy {
  @Input() danceEvent: DanceEvent;
  @Input() danceEventListTab: DanceEventTimePhaseEnum;

  @Output() danceEventDeleted = new EventEmitter<void>();
  @Output() danceEventDuplicated = new EventEmitter<void>();
  @Output() danceEventPublished = new EventEmitter<void>();

  endDate: Date;
  currentUser: UsersInterface;
  startDate: Date;
  currentDanceManager: string;
  

  danceEventListTabEnum = DanceEventTimePhaseEnum;

  subscriptions: Subscription = new Subscription();

  currentLang: string;

  constructor(
    private readonly authService: AuthStateService,
    private readonly dialog: MatDialog,
    private readonly danceEventService: DanceEventStateService,
    private readonly translocoService: TranslocoService,
    private readonly danceEventHttpService: DanceEventHttpService,
    private readonly matSnackBar: MatSnackBar) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
    this.currentDanceManager = this.danceEvent.danceSchool.name;
    
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  deleteDanceEvent(): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_DIALOG.TITLE'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_DIALOG.DESCRIPTION')
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.danceEventHttpService.deleteById(this.danceEvent.id).subscribe({
            next: () => {
              this.danceEventDeleted.emit();
            },
            error: (err) => {
              if (err.error.error === 'dance_event_tickets_sold') {
                this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_ERROR_TICKET_SOLD'));
              } else {
                this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_ERROR'));
              }
            }
          });
        }
      });
  }

  duplicateDanceEvent(): void {
    const dialogRef = this.dialog.open(DuplicateDanceEventDialogComponent, {
      data: this.danceEvent,
      panelClass: 'br-20',
      maxHeight: '100vh',
      maxWidth: '390px',
      minWidth: '390px',
      width: '25vw',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.danceEventService.duplicateDanceEventById(this.danceEvent.id, result.name, result.startDate, result.duration, this.danceEventListTab, result.duplicateTickets);
        this.danceEventDuplicated.emit();
        this.danceEventService.clear();
      }
    });
  }

  publishDanceEvent(): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.PUBLISH_EVENT'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.PUBLISH'),
      text: this.translocoService.translate('GENERIC_WRAPPER.PUBLISH_DIALOG_TEXT')
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.danceEventHttpService.updateStatusDanceEventById(this.danceEvent.id!, true).subscribe({
            next: () => {
              this.danceEventPublished.emit();
            },
            error: (err) => {
              if (err.error.error) {
                this.handlePublishDanceEventError(err.error.error);
              }
              throw err;
            }
          });
        }
      });
  }

  handlePublishDanceEventError(error: UpdateEventStatusErrorEnum): void {
    switch (error) {
      case UpdateEventStatusErrorEnum.UPDATE_STATUS_EVENT_FAILED_DATE_PAST:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_PUBLISH_FAILED_DATE_PAST'));
        break;
      case UpdateEventStatusErrorEnum.UPDATE_STATUS_EVENT_FAILED_HAS_TICKETS:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_PUBLISH_FAILED_HAS_TICKETS'));
        break;
      case UpdateEventStatusErrorEnum.UPDATE_STATUS_EVENT_FAILED:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_PUBLISH_EVENT_FAILED'));
        break;
      default:
        break;
    }
  }
}
