import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfmEventGuestListTableComponent } from './dfm-event-guest-list-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipesModule } from '@platri/dfx-angular-core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { StatisticsCardModule } from '@platri/dfx-angular-core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import { AddFreeTicketsDialogModule } from '../../dialogs';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmEventGuestListTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    PipesModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    StatisticsCardModule,
    AddFreeTicketsDialogModule,
    MatProgressSpinnerModule,
    DfSharedButtonModule,
    TranslocoDirective
  ],
  exports: [DfmEventGuestListTableComponent],
})
export class DfmEventGuestListTableModule {}
