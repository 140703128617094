import { NotificationDataInterface, NotificationUseCaseEnum } from '../../..';

export class UpcomingEventNotificationDataDto implements NotificationDataInterface {
  public readonly useCase = NotificationUseCaseEnum.UPCOMING_EVENT;
  
  constructor(
    public readonly useCaseSpecificData: {
      toUserId: string;
      fromDanceEventId: string;
      danceEventName?: string;
      danceManagerName?: string;
    }) {}
}
