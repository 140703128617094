import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { StatusInfoDialogComponent } from './status-info-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [StatusInfoDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        DfSharedButtonModule,
        TranslocoDirective,
    ],
  exports: [StatusInfoDialogComponent],
})
export class StatusInfoDialogModule {}
