import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  
  transform(value: string, words: number): string {
    if (value === null) {
      return null;
    }
    const wordsArray = value?.split(' ');
    const truncatedWords = wordsArray?.slice(0, words);
    return truncatedWords?.join(' ') + `${wordsArray?.length > words ? '...' : ''}`;
  }

}
