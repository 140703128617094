import { FeesPaidByEnum, TicketInterface, TicketSaleStatusEnum, TicketTypeEnum } from '../../..';
import { CurrencyDto } from './currency.dto';
import { DanceEventsDto } from './dance-events.dto';

export class TicketDto implements TicketInterface {
  id?: string;
  danceEventId?: string;
  danceEvent?: DanceEventsDto;
  name?: string;
  description?: string;
  price?: number;
  currency?: CurrencyDto;
  ticketType?: TicketTypeEnum;
  soldQuantity?: number;
  quantity?: number;
  availableQuantity?: number;
  saleStart?: Date;
  saleEnd?: Date;
  feesPaidBy?: FeesPaidByEnum;
  sequenceNumber?: number;
  ticketSaleStatus?: TicketSaleStatusEnum;
  sellAsFollowerActive?: boolean;
  sellAsFollowerLeader?: boolean;
  sellAsLeaderActive?: boolean;
  taxPercentage?: number;
  danceManagerUserGroupIds?: string[];
}
