import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AddAppointmentDialogComponent,
  CoursesHttpService,
  CoursesStateService,
  DanceSchoolStateService,
  quillCharacterCountValidator,
  SharedCancelDialogComponent
} from '@platri/dfx-angular-core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  CourseInterface,
  CreateCourseInterface,
  CreateCourseRequestDto
} from '@platri/df-common-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-create-course-page',
  templateUrl: './create-course-stepper.page.html',
  styleUrls: ['./create-course-stepper.page.scss']
})
export class CreateCourseStepperPage implements OnInit, OnDestroy {
  infoFormGroup: UntypedFormGroup;
  rulesFormGroup: UntypedFormGroup;

  loading: boolean;

  constructor(
    private readonly matDialog: MatDialog, 
    private readonly translocoService: TranslocoService, 
    private readonly fb: UntypedFormBuilder, 
    private readonly coursesService: CoursesStateService, 
    private readonly danceSchoolService: DanceSchoolStateService, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly coursesHttpService: CoursesHttpService, 
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initFormGroups();
    this.coursesService.loadingSubject$.subscribe((loading: boolean) => {
      this.loading = loading;
    });
  }

  initFormGroups(): void {
    this.initInfoFormGroup();
    this.initRulesFormGroup();
  }

  initInfoFormGroup(): void {
    this.infoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      description: [null, [Validators.required, quillCharacterCountValidator(2000)],],
      danceStyles: [null, [Validators.required]],
      startDifficulty: [0],
      endDifficulty: [100],
      tags: [[]],
      mainImage: [null]
    });
  }
  
  initRulesFormGroup(): void {
    this.rulesFormGroup = this.fb.group({
      participantRestriction: this.fb.group({
        isAgeRestricted: [false],
        ageRestriction: this.fb.group({ fromAge: [0], toAge: [100] }),
        isGenderRestricted: [false],
        genderRestriction: this.fb.group({
          isMaleRestricted: [false],
          isFemaleRestricted: [false],
          isDiverseRestricted: [false]
        })
      }),
      clothingRestriction: this.fb.group({
        isRestricted: [false],
        restriction: [null, [Validators.maxLength(1000)]]
      }),
      isCheckInActive: [false]
    });
  }

  checkInfoFormGroupIsDirtyAndShowCancelDialog(): void {
    if (this.infoFormGroup.dirty) {
      this.matDialog
        .open(SharedCancelDialogComponent, {
          panelClass: ['br-20', 'bg-glassmorph'],
          maxWidth: '400px',
          disableClose: true,
          data: {
            title: this.translocoService.translate('DANCE_MANAGER_COURSE.COURSE_CANCEL_DIALOG.TITLE'),
            subtitle: this.translocoService.translate('DANCE_MANAGER_COURSE.COURSE_CANCEL_DIALOG.SUBTITLE'),
            infoText: this.translocoService.translate('DANCE_MANAGER_COURSE.COURSE_CANCEL_DIALOG.STAY'),
            cancelText: this.translocoService.translate('GENERIC_WRAPPER.OK')
          }
        })
        .afterClosed()
        .subscribe((reason) => {
          if (!reason) {
            this.coursesService.navigateToCoursesPage();
          }
        });
    } else {
      this.coursesService.navigateToCoursesPage();
    }
  }

  submit(): void {
    const infoForm = this.infoFormGroup.getRawValue();
    const rulesForm = this.rulesFormGroup.getRawValue();
    const submitFormData = new FormData();
    const courseToCreate: CreateCourseRequestDto = {
      name: infoForm.name,
      description: infoForm.description,
      danceStyleIds: infoForm.danceStyles.map((danceStyle) => danceStyle.id),
      startDifficulty: infoForm.startDifficulty,
      endDifficulty: infoForm.endDifficulty,
      danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id,
      rules: rulesForm,
      isCheckInActive: rulesForm.isCheckInActive
    };
    if (infoForm.tags.length > 0) {
      courseToCreate.tags = infoForm.tags;
    }
    // submitFormData.append('mainImage', this.infoFormGroup.get('mainImage')?.value != null ? this.infoFormGroup.get('mainImage')?.value.formData.get('file') : null);
    // submitFormData.append('body', JSON.stringify(courseToCreate));
    // TODO:Serdar - genau wie bei kluge wahl das bild getrennt hochladen
    this.coursesService.sendLoading(true);
    this.coursesHttpService.createCourse(courseToCreate).subscribe({
      next: (course) => {
        this.coursesService.sendLoading(false);
        this.navigateAndShowDialog(course);
        this.openSnackBarCreateSuccess();
      },
      error: (err) => {
        this.coursesService.sendLoading(false);
        this.openSnackBarCreateFailed();
      }
    });
  }

  openSnackBarCreateSuccess(): void {
    const message = this.translocoService.translate('DANCE_MANAGER_COURSE.SNACKBAR.CREATED_SUCCESSFUL');
    this.matSnackBar.open(message);
  }

  openSnackBarCreateFailed(): void {
    const message = this.translocoService.translate('DANCE_MANAGER_COURSE.SNACKBAR.CREATE_FAILED');
    this.matSnackBar.open(message);
  }

  navigateAndShowDialog(course: CourseInterface): void {
    const dialogData = {
      title: this.translocoService.translate("DANCE_MANAGER_COURSE.DIALOG.DIALOG_TITLE"),
      infoText: this.translocoService.translate("DANCE_MANAGER_COURSE.DIALOG.DIALOG_TEXT"),
      cancelText: this.translocoService.translate("DANCE_MANAGER_COURSE.DIALOG.DIALOG_CANCEL"),
      confirmText: this.translocoService.translate("DANCE_MANAGER_COURSE.DIALOG.DIALOG_CONFIRM"),
    };
     this.router.navigate(['../preview', course.id], { relativeTo: this.route });
     this.matDialog.open(AddAppointmentDialogComponent, {
      maxWidth: '500px',
      data: dialogData,
    });
  }
  
  ngOnDestroy(): void {
    this.coursesService.clear();
  }
}
