import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { filter, Subscription, take } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FollowInterface, FollowTargetTypeEnum, UsersInterface } from '@platri/df-common-core';
import { AuthStateService, UserFollowStateService } from '../../services';
import { dfAppRoute, dfLoginRoute } from '../../shared';
import { FollowInfoDialogComponent } from '../follow-info-dialog';

@Component({
  selector: 'df-shared-lib-follow',
  templateUrl: './df-follow.component.html',
  styleUrls: ['./df-follow.component.scss']
})
export class DfFollowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() profileId: string;
  @Input() targetType: FollowTargetTypeEnum;
  @Input() hideFollowers = false;
  @Input() hideFollowing = false;
  @Input() pageViews: number | null = null;

  currentUser: UsersInterface;
  isCurrentUser: boolean;

  subscriptions: Subscription = new Subscription();
  editorFormControl = new UntypedFormControl();

  constructor(
    private readonly authService: AuthStateService,
    private readonly router: Router,
    private readonly matSnackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public readonly userFollowStateService: UserFollowStateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.authService.getAsyncCurrentUser().subscribe((data) => {
      if (data) {
        this.currentUser = data;
        this.isCurrentUser = this.profileId === this.currentUser?.id;
      }
      this.loadFollowCount();
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profileId) {
      this.loadFollowCount();
    }
  }

  loadFollowCount(): void {
    this.userFollowStateService.loadFollowCountByTargetIdAndTargetType(this.profileId, this.targetType);
  }

  loadFollowsAndFollowers(): void {
    this.userFollowStateService.loadFollowsByTargetIdAndTargetType(this.profileId, this.targetType);
    this.userFollowStateService.loadFollowersByTargetIdAndTargetType(this.profileId, this.targetType);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.userFollowStateService.clear();
  }
  openFollowInfoDialog(tabIndex: number = 0): void {
    if (this.currentUser) {
      this.loadFollowsAndFollowers();
      const dialogRef = this.dialog.open(FollowInfoDialogComponent, {
        maxHeight: '80vh',
        minWidth: '300px',
        width: '50vw',
        id: 'follow-info-dialog-container',
        panelClass: 'follow-info-dialog-container',
        data: {
          tabIndex: tabIndex,
          hideFollowing: this.hideFollowing,
          hideFollowers: this.hideFollowers
        }
      });

      this.router.events
        .pipe(
          take(1),
          filter((event) => event instanceof NavigationStart)
        )
        .subscribe(() => {
          dialogRef.close();
        });

      dialogRef.afterClosed().subscribe(() => {
       
      });
    } else {
      this.routeToLogin();
    }
  }

  followTarget(): void {
    if (this.currentUser) {
      if (this.userFollowStateService.getSyncAvailableFollowCount().isCurrentUserFollowing) {
        this.userFollowStateService
          .unfollowUser(this.userFollowStateService.getSyncAvailableFollowCount().followId)
          .pipe(filter((v) => v !== null))
          .subscribe((data: any) => {
            if (data) {
              this.matSnackBar.open('Unfollowed');
              this.loadFollowCount();
            }
          });
      } else {
        this.userFollowStateService
          .followUser(this.profileId, this.targetType)
          .pipe(filter((v) => v !== null))
          .subscribe((data: FollowInterface) => {
            if (data) {
              this.matSnackBar.open('Followed');
              this.loadFollowCount();
            }
          });
      }
    } else {
      this.routeToLogin();
    }
  }

  openChat(): void {
    if (this.currentUser) {
      this.router.navigateByUrl(`messages/${this.profileId}`);
    } else {
      this.routeToLogin();
    }
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
  }
}
