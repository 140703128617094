import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfSharedButtonModule } from '../buttons';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MultiSwitchCasesModule } from '../../directives';
import { PurchaseConfirmationComponent, PurchaseConfirmationTextInterface } from '../purchase-confirmation';
import { TicketOrderInterface } from '@platri/df-common-core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

export interface CheckoutCompleteErrorTextInterface {
  title: string;
  text: string;
  navButton?: { label: string; navUrl: string };
}

@Component({
  selector: 'df-checkout-complete',
  templateUrl: './df-checkout-complete.component.html',
  styleUrls: ['./df-checkout-complete.component.scss'],
  standalone: true,
  imports: [CommonModule, DfSharedButtonModule, MatProgressSpinner, MultiSwitchCasesModule, PurchaseConfirmationComponent, TranslocoModule]
})
export class DfCheckoutCompleteComponent {
  @Input({ required: true }) confirmationText: PurchaseConfirmationTextInterface;
  @Input({ required: true }) errorText: CheckoutCompleteErrorTextInterface;
  @Input() ticketOrder: TicketOrderInterface | null = null;
  @Input() danceManagerId?: string;

  @Input() currentState: any;
  @Input() stateInit = [];
  @Input() stateSuccess = [];
  @Input() stateError = [];

  constructor(private readonly router: Router) {}

  navigateToEventPage(url: string): void {
    this.router.navigateByUrl(url);
  }
}
