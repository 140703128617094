import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoursesHttpService, DanceManagerHttpService, UserPassHttpService } from '@platri/dfx-angular-core';
import { Router } from '@angular/router';
import { CourseInterface, DanceSchoolInterface, PassTargetInterface, UserPassInterface, UserPassScannedFilterEnum } from '@platri/df-common-core';
import { SearchCategoryEnum } from '@platri/df-angular-search';

@Component({
  selector: 'df-user-inventory-subscription-list',
  templateUrl: './inventory-subscription-list.component.html',
  styleUrls: ['./inventory-subscription-list.component.scss']
})
export class InventorySubscriptionListComponent implements OnInit, OnDestroy {
  activeChipIndex = 0;
  
  userPasses: UserPassInterface[];

  userPassesDanceManagers: DanceSchoolInterface[] = [];
  userPassesCourses: CourseInterface[] = [];
  
  private subscriptions = new Subscription();

  constructor(
    private userPassHttpService: UserPassHttpService,
    private router: Router,
    private danceManagerHttpService: DanceManagerHttpService,
    private coursesHttpService: CoursesHttpService,
  ) {}

  ngOnInit(): void {
    this.loadUserPassSubscriptionPassesOfCurrentUser(UserPassScannedFilterEnum.ACTIVE);
  }
  
  loadUserPassSubscriptionPassesOfCurrentUser(status: UserPassScannedFilterEnum): void {
    this.subscriptions.add(
      this.userPassHttpService.getAllUserPassSubscriptionPassesByCurrentUser(status)
        .subscribe({
          next: (result) => {
            const danceManagerIds: string[] = Array.from(new Set(result?.map(pt => pt.userPassSubscription?.danceManagerId) ?? []));
            this.getUserPassDanceManagers(danceManagerIds);

            const targets = result?.flatMap(pt => pt.userPassSubscription ? pt.userPassSubscription.targets : []);

            const courseIds: string[] = Array.from(new Set(targets.map(target => target.targetId)) ?? []);
            this.getUserPassCourses(courseIds);
            
            this.userPasses = result;
          }
        })
    );
  }

  getUserPassDanceManagers(danceManagerIds: string[]): void {
    this.subscriptions.add(
      this.danceManagerHttpService.getDanceManagersBulkById(danceManagerIds).subscribe( danceManagers => {
        this.userPassesDanceManagers = danceManagers;
      }, error => {
        console.log(error);
      })
    );
  }

  getUserPassCourses(courseIds: string[]): void {
    this.subscriptions.add(
      this.coursesHttpService.getCoursesBulkById(courseIds).subscribe(courses => {
        this.userPassesCourses = courses;
      }, error => {
        console.log(error);
      })
    );
  }
  
  // download(data: { danceEventId: string; ticketOrderId: string }): void {
  //  
  // }
  //
  // downloadTickets(data: { danceEventId: string; ticketOrderId?: string; freeUserTicketIds?: string[] }): void {
  //   const ticketWindow = window.open();
  //   ticketWindow.document.write(`${this.translateService.instant('TICKETS_LOADING')}...`);
  //
  //   this.userTicketHttpService
  //     .downloadTickets(data.danceEventId, data.ticketOrderId, data.freeUserTicketIds)
  //     .subscribe({
  //       next: (blob) => {
  //         const url = URL.createObjectURL(blob);
  //         ticketWindow.location.href = url;
  //       },
  //       error: (err) => {
  //         console.error(err);
  //         ticketWindow.close();
  //       },
  //     });
  // }

  filterUserPassDanceManagersByPassId(danceManagerId: string): DanceSchoolInterface {
    return this.userPassesDanceManagers.find(dm => dm.id === danceManagerId);
  }

  filterUserPassCoursesByPassId(passTargets: PassTargetInterface[]): CourseInterface[] {
    const targetIds: string[] = Array.from(new Set(passTargets.map(target => target.targetId) ?? []));
    return this.userPassesCourses.filter(course => targetIds.includes(course.id));
  }

  navigateToCoursesSearch(): void {
    this.router.navigate(['/', 'search'], {queryParams: {search_type: SearchCategoryEnum.COURSES}});
  }

  toggleChip(value: number): void {
    this.userPasses = [];
    this.activeChipIndex = value;
    this.loadUserPassSubscriptionPassesOfCurrentUser(value === 0 ? UserPassScannedFilterEnum.ACTIVE : UserPassScannedFilterEnum.USED);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
}
