import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { DfSharedButtonModule } from '../buttons';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ConfirmationDialogComponent],
    imports: [
      CommonModule,
      MatButtonModule,
      MatDialogModule,
      MatIconModule,
      FormsModule,
      MatFormFieldModule,
      MatInputModule,
      ReactiveFormsModule,
      DfSharedButtonModule,
      TranslocoDirective,
    ],
  exports: [ConfirmationDialogComponent]
})
export class ConfirmationDialogModule {}
