import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes';
import { PassTargetsTableComponent } from './pass-targets-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserNotificationItemModule } from '../user-notificiation-item-component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [PassTargetsTableComponent],
    imports: [
      CommonModule,
      PipesModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      MatSortModule,
      MatTableModule,
      MatCheckboxModule,
      MatDividerModule,
      MatProgressSpinnerModule,
      UserNotificationItemModule,
      MatFormFieldModule,
      MatInputModule,
      TranslocoDirective
    ],
  exports: [PassTargetsTableComponent],
})
export class PassTargetsTableModule {
}
