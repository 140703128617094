import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTicketSalesSettingsStepComponent } from './event-ticket-sales-settings-step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslocoModule } from '@jsverse/transloco';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DfTimePickerModule } from '../df-time-picker';
import { MatOption, MatSelect } from '@angular/material/select';

@NgModule({
  declarations: [EventTicketSalesSettingsStepComponent],
  exports: [EventTicketSalesSettingsStepComponent],
  providers: [
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    TranslocoModule,
    DfTimePickerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelect,
    MatOption
  ]
})
export class EventTicketSalesSettingsStepModule {}
