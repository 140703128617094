<ng-container *transloco="let t">
  <div class="flex-column p1 w-100">
    <div class="flex-row align-space-between-center w-100" style="margin-bottom: -10px">
      <span style="padding-left: 14px;">
        <strong>{{ t('GENERIC_WRAPPER.SHARE_DIALOG.SHARE') }}</strong>
      </span>
      <div>
        <button [disableRipple]="true" mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    
    <div class="flex-row-start-center w-100">
      <button mat-button class="flex-row-start-center w-100" shareButton="facebook" #fbBtn="shareButton" [style.color]="'#4d4d4d'" [disableRipple]="true"
      >
        <mat-icon class="mr1" *ngIf="fbBtn" [svgIcon]="'df_facebook_button'" size="lg"></mat-icon>
        <span class="share-text">{{ t('GENERIC_WRAPPER.SHARE_DIALOG.SHARE_TO_FACEBOOK') }}</span>
      </button>
    </div>
    
    <div class="flex-row-start-center w-100">
         <button mat-button class="flex-row-start-center w-100" shareButton="twitter" #fbBtn="shareButton" [style.color]="'#4d4d4d'" [disableRipple]="true"
         >
          <mat-icon
            class="mr1"
            *ngIf="fbBtn"
            [svgIcon]="'df_x_button'"
          ></mat-icon>
        <span class="share-text">{{ t('GENERIC_WRAPPER.SHARE_DIALOG.SHARE_TO_X') }}</span>
      </button>
    </div>
  
    <div class="flex-row-start-center w-100">
      <button mat-button class="flex-row-start-center w-100" shareButton="whatsapp" #fbBtn="shareButton" [style.color]="'#4d4d4d'" [disableRipple]="true"
      >
          <mat-icon
            class="mr1"
            *ngIf="fbBtn"
            [svgIcon]="'df_whatsapp_button'"
          ></mat-icon>
        <span class="share-text">{{ t('GENERIC_WRAPPER.SHARE_DIALOG.SHARE_VIA_WHATSAPP') }}</span>
      </button>
    </div>
    
    <div class="flex-row-start-center w-100">
      <button mat-button class="flex-row-start-center w-100" shareButton="email" #fbBtn="shareButton" [style.color]="'#4d4d4d'" [disableRipple]="true"
      >
          <mat-icon
            class="mr1"
            *ngIf="fbBtn"
            [svgIcon]="'df_mail_button'"
          ></mat-icon>
        <span class="share-text">{{ t('GENERIC_WRAPPER.SHARE_DIALOG.SHARE_VIA_EMAIL') }}</span>
      </button>
    </div>
    
    <div class="flex-row-start-center w-100 mb-10">
      <button mat-button class="flex-row-start-center w-100" shareButton="copy" #fbBtn="shareButton" [style.color]="'#4d4d4d'" [disableRipple]="true"
      >
          <mat-icon
            class="mr1"
            *ngIf="fbBtn"
            [svgIcon]="'df_link_button'"
          ></mat-icon>
        <span class="share-text">{{ t('GENERIC_WRAPPER.SHARE_DIALOG.COPY_LINK') }}</span>
      </button>
    </div>
  </div>
</ng-container>
