import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagePartnerInterface, PartnerType } from '@platri/df-common-core';


@Component({
  templateUrl: './profile-wrapper.page.html',
  styleUrls: ['./profile-wrapper.page.scss'],
})
export class ProfileWrapperPage implements OnInit {
  public readonly partnerTypeEnum = PartnerType;

  public currentType: PartnerType;
  public urlName = '';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.checkTypeFromData();
    this.checkParamChanges();
  }

  private checkParamChanges(): void {
    this.activatedRoute.params.subscribe(() => {
      this.checkTypeFromData();
    });
  }

  private checkTypeFromData(): void {
    const targetObject: MessagePartnerInterface =
      this.activatedRoute.snapshot.data.targetObject;
    this.urlName = targetObject.username;

    switch (true) {
      case !!targetObject.id.match(PartnerType.user): {
        this.currentType = PartnerType.user;
        break;
      }
      case !!targetObject.id.match(PartnerType.danceSchool): {
        this.currentType = PartnerType.danceSchool;
        break;
      }
    }
  }
}
