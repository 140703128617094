import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { AuthStateService } from '../../services';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-shared-lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() sidenav: MatSidenav;

  currentUser: UsersInterface;
  year = new Date().getFullYear();
  // version = VERSION.version;

  subscriptions = new Subscription();

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly authService: AuthStateService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.getAsyncCurrentUser()
        .subscribe(user => {
          this.currentUser = user;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
