export interface UnreadDmMessageDetailInterface {
  danceManagerId: string;
  amountOfUnreadMessages: number;
}

export interface UnreadMessagesInterface {
  amountOfUnreadUserMessages: number;
  amountOfUnreadDmMessages: number;

  amountOfUnreadDmMessageDetails: UnreadDmMessageDetailInterface[];
}
