<ng-container *transloco="let t">
  <a
    mat-list-item
    routerLink="courses"
    routerLinkActive="active-link"
    matTooltipClass="tooltip"
    [matTooltip]="!isExpanded ? t('DFM_APP.DFM_SIDENAV.COURSES') : null">
      <mat-icon matListItemIcon class="material-icons-outlined mr2">school</mat-icon>
      <span *ngIf="isExpanded" matListItemTitle routerLink="courses" routerLinkActive="active-link">
        {{ t('DFM_APP.DFM_SIDENAV.COURSES') }}
      </span>
  </a>
</ng-container>
