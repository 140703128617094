import { Component, Input } from '@angular/core';

@Component({
  selector: 'df-shared-lib-statistics-box',
  templateUrl: './statistics-box.component.html',
  styleUrls: ['./statistics-box.component.scss'],
})
export class StatisticsBoxComponent {
  @Input() icon: string;
  @Input() StatBoxTitle: string;
  @Input() StatBoxMainStats: { left: number; right: number };
  @Input() StatBoxSubTitle: string;
  @Input() StatBoxSubStats: { left: number; right: number };

  @Input() indicatorTitles: { left: string; right: string };

  @Input() showSubStats = false;
}
