import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatsComponent } from './chats.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule, RelativeTimePipe, RelativeTimeShortPipe, SearchCoversationPartnersModule, UserSearchAutocompleteModule } from '@platri/dfx-angular-core';
import { ListPictureChatModule } from '../list-picture';
import { MatError } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import {MatIconButton} from "@angular/material/button";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ChatsComponent],
    imports: [
      CommonModule,
      RouterOutlet,
      RouterLink,
      RouterLinkActive,
      MatIconModule,
      RelativeTimePipe,
      RelativeTimeShortPipe,
      ListPictureChatModule,
      MatError,
      ReactiveFormsModule,
      SearchCoversationPartnersModule,
      UserSearchAutocompleteModule,
      PipesModule,
      MatIconButton,
      TranslocoDirective,
    ],
  exports: [ChatsComponent]
})
export class ChatsModule { }
