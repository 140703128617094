import { PassInterface } from './pass.interface';
import { UserPassStatusEnum } from '../../enums';
import { UserPassSubscriptionInterface } from '../user-pass-subscription.interface';

export class UserPassCollapsedByDanceSchoolInterface {
  passId?: string;
  orderId?: string;
  userPassId?: string;
  isCanceled?: boolean;
  usageCount?: number;
  usedCount?: number;
  pass?: PassInterface;
  validUntil?: Date;
  status?: UserPassStatusEnum;
  userPassSubscription?: UserPassSubscriptionInterface;
}
