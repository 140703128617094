import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { ConversationListEntryComponent } from './conversation-list-entry.component';
import { PipesModule } from '../../../pipes';
import { ImageFallbackDirectiveModule } from '../../../directives';

@NgModule({
  declarations: [
    ConversationListEntryComponent,
  ],
    imports: [
      CommonModule,
      MatIconModule,
      MatInputModule,
      MatButtonModule,
      RouterModule,
      PipesModule,
      TranslocoModule,
      ImageFallbackDirectiveModule,
    ],
  exports: [
    ConversationListEntryComponent,
  ],
})
export class ConversationListEntryModule {}
