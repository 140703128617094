<ng-container *transloco="let t">
  <a [routerLink]="['/', '@' + danceSchool.urlName]" class="preview-card flex-column w-100">
    <img [src]="danceSchool.titleImage?.url ?? DEFAULT_DANCE_MANAGER_TITLE_PICTURE_PLACEHOLDER" alt="" class="preview-image"/>
    <div class="card-info-box flex-column">
      <div class="flex-row align-start-center" style="gap: 8px;">
        <img class="logo-image" [src]="danceSchool.imageUrl ?? DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER" [alt]="t('GENERIC_WRAPPER.DANCE_SCHOOL_PICTURE_ALT')"/>
        <div class="flex-column">
          <span class="card-title"><strong>{{danceSchool?.name}}</strong></span>
          <div class="flex-row-wrap align-center-start" style="color: #424655">
            <span *ngFor="let category of danceSchool.danceManagerCategory; let isLast = last;" class="card-description">
              {{ t('GENERIC_WRAPPER.DANCE_MANAGER_CATEGORIES.' + category) + (isLast ? '' : ',&nbsp;') }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex-row align-start-center align-space-between">
        <div>
          <span class="card-description" *ngFor="let style of danceSchool.danceStyles; let isLast = last;">
            {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + style.translationKey.toUpperCase()) + (isLast ? '' : ', ') }}
          </span>
        </div>
        <span class="card-description">{{ danceSchool.businessAddress?.city ?? '' }}</span>
      </div>
    </div>
  </a>
</ng-container>
