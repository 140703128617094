import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfFaqsCardComponent } from './df-faqs-card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipesModule } from '../../pipes';
import { MatButtonModule } from '@angular/material/button';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfFaqsCardComponent],
    imports: [
      CommonModule,
      MatIconModule,
      MatCardModule,
      MatExpansionModule,
      PipesModule,
      MatButtonModule,
      TranslocoDirective,
    ],
  exports: [DfFaqsCardComponent],
})
export class DfFaqsCardModule {}
