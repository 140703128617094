<ng-container *transloco="let t">
  <div class="w-100">
    <mat-card class="main-content-card br-10 bg-glassmorph flex-column">
      <df-dance-course-header 
        [showEditButton]="!isPreview" 
        [danceCourse]="course" 
        [isOwnerOrAdmin]="isOwnerOrAdmin" 
        [isUserEnrolled]="isUserEnrolled"
        [passMinPrice]="passMinPrice"
        style="padding-bottom: 0; margin-bottom: 0;"
      ></df-dance-course-header>
      
      <div class="w-100 content-group gap-20">
        <div id="main-column" class="flex-column gap-30 main-column">
          <div *ngIf="course.description">
            <span class="title-sections">{{ course.name }}</span>
            <div class="pt2 ql-container" style="border-width: 0;">
              <div style="padding: 0;" class="ql-editor description-text" [innerHTML]="course.description | dynamicTag"></div>
            </div>
          </div>
          <div *ngIf="course.rules?.clothingRestriction.restriction">
            <span class="title-sections">{{ t('GENERIC_WRAPPER.DRESSCODE') }}</span>
            <span class="description-text" [innerHTML]="course.rules.clothingRestriction.restriction"></span>
          </div>
          <div *ngIf="(course.rules?.participantRestriction.genderRestriction.isMaleRestricted || course.rules?.participantRestriction.genderRestriction.isFemaleRestricted || course.rules?.participantRestriction.genderRestriction.isDiverseRestricted) || course.rules?.participantRestriction.isAgeRestricted && (course.rules.participantRestriction.ageRestriction.toAge || course.rules.participantRestriction.ageRestriction.fromAge)">
            <div class="mat-subtitle-1">
              {{ t('GENERIC_WRAPPER.OTHER_RESTRICTIONS') }}
            </div>
            <div class="other-restrictions">
              <div *ngIf="course.rules.participantRestriction.isAgeRestricted && (course.rules.participantRestriction.ageRestriction.toAge || course.rules.participantRestriction.ageRestriction.fromAge) && course.rules.participantRestriction.ageRestriction as restriction"
                class="age-restriction-display flex-row gap-10 align-center"
              >
                <mat-icon>check_circle</mat-icon>
                <div class="gap-5">
                  <span>
                    {{ t('GENERIC_WRAPPER.AGE') }}
                  </span>
                  <span *ngIf="restriction.fromAge && restriction.toAge">
                    {{restriction.fromAge}} - {{restriction.toAge}}
                  </span>
                  <span *ngIf="restriction.fromAge && !restriction.toAge">
                    {{t('GENERIC_WRAPPER.OVER')}} {{restriction.fromAge}}
                  </span>
                  <span *ngIf="!restriction.fromAge && restriction.toAge">
                    {{t('GENERIC_WRAPPER.UNDER')}} {{restriction.toAge}}
                  </span>                
                </div>
              </div>
              <div
                *ngIf="(course.rules.participantRestriction
                    .genderRestriction.isMaleRestricted || course.rules.participantRestriction
                    .genderRestriction.isFemaleRestricted || course.rules.participantRestriction
                    .genderRestriction.isDiverseRestricted) &&
                  course.rules.participantRestriction
                    .genderRestriction as restriction
                "
                class="gender-restriction-display flex-row gap-10 align-center">
                <div class="flex-row align-center">
                  <mat-icon *ngIf="restriction?.isMaleRestricted">male</mat-icon>
  
                  <mat-icon *ngIf="restriction?.isFemaleRestricted">female</mat-icon>
  
                  <mat-icon *ngIf="restriction?.isDiverseRestricted">more_horiz</mat-icon>
                </div>
  
                <div class="flex-row align-center gap-5">
                  <span>{{ t('GENERIC_WRAPPER.ONLY') }}</span>
                  <span *ngIf="restriction.isMaleRestricted">
                    {{ t('GENERIC_WRAPPER.MALE') }}
                    {{ restriction.isMaleRestricted && (restriction.isFemaleRestricted || restriction.isDiverseRestricted ? ',' : '') }}
                  </span>
                  <span *ngIf="restriction.isFemaleRestricted">
                    {{ t('GENERIC_WRAPPER.FEMALE') }}
                    {{ restriction.isFemaleRestricted && restriction.isDiverseRestricted ? ',' : '' }}
                  </span>
                  <span *ngIf="restriction.isDiverseRestricted">{{ t('GENERIC_WRAPPER.OTHER') }}</span>
                </div>
              </div>
            </div>
          </div>
  
          <div *ngIf="course.appointments?.length > 0" class="flex-column gap-20">
            <div class="mat-subtitle-1">
              {{ t('GENERIC_WRAPPER.NEXT_APPOINTMENTS') }}
            </div>
            <ng-container *ngFor="let appointment of course.appointments; let i = index">
              <df-dance-course-appointment-card
                *ngIf="i < maxAppointmentsDisplayed"
                (cardLoaded)="updateDisplayed()"
                [appointment]="appointment"
                [courseId]="course.id"
                [danceManagerId]="course.danceManagerId"
                [isCheckInActive]="course.isCheckInActive"
                [isUserEnrolled]="isUserEnrolled"
                [isOwnerOrAdmin]="isOwnerOrAdmin"
              ></df-dance-course-appointment-card>
            </ng-container>
  
            <button
              type="button"
              class="load-appointments-btn"
              mat-button
              (click)="loadMore()"
              *ngIf="appointmentsDisplayed < course.appointments.length"
            >
              {{ t('GENERIC_WRAPPER.LOAD_MORE') }}
            </button>
          </div>
        </div>
  
        <div class="info-column flex-column gap-40 group-item">
  <!--        Dance Styles-->
          <div>
            <div class="mat-subtitle-1">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</div>
            <mat-chip-listbox [selectable]="false">
              <mat-chip-option
                [disableRipple]="true"
                [disabled]="true"
                *ngFor="let danceStyle of course.danceStyles"
              >
                {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          
  <!--        Organizer-->
          <div
            *ngIf="course.danceManager"
            class="flex-column"
          >
            <div class="mat-subtitle-1">
              {{ t('GENERIC_WRAPPER.ORGANIZER') }}
            </div>
            <div class="flex-row mb1">
              <div
                [routerLink]="['/', '@' + course.danceManager.urlName]"
                class="link flex-column align-start"
                data-cy="dance-manager-name"
              >
                <mat-list>
                  <div class="link organizer flex-row">
                    <img
                      class="circle"
                      style="width: 35px; height: 35px"
                      [src]="course.danceManager.imageUrl && course.danceManager.imageUrl !== '' ? course.danceManager.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
                    />
                    <mat-list-item><b style="font-size: 15px;">{{ course.danceManager.name }}</b></mat-list-item>
                  </div>
                </mat-list>
              </div>
            </div>
            <span style="color: #3377FF; cursor: pointer" (click)="openChat()" data-cy="contact-organizer">
              {{ t('GENERIC_WRAPPER.CONTACT_ORGANIZER') }}
            </span>
          </div>
  
  <!--        Teacher-->
          <div
            *ngIf="
              course.appointments &&
              course.appointments[0] as appointment
            "
          >
            <div class="mat-subtitle-1">{{ t('GENERIC_WRAPPER.TEACHERS') }}</div>
            <div class="flex-row gap-10">
              <div
                *ngFor="let teacher of appointment.teachers"
                [routerLink]="['/', '@' + teacher.username]"
                style="cursor: pointer; color: #4d4d4d;"
                class="link flex-column align-start gap-10"
              >
                <img
                  class="circle"
                  style="max-width: 70px"
                  [src]="teacher.imageUrl && teacher.imageUrl !== '' ? teacher.imageUrl : DEFAULT_PROFILE_PICTURE_PLACEHOLDER"
                />
                <div class="flex-row align-center gap-5">
                  <span *ngIf="teacher.firstName">{{ teacher.firstName }}</span>
                  <span *ngIf="teacher.lastName">{{ teacher.lastName }}</span>
                  <span *ngIf="!teacher.firstName && !teacher.lastName">{{
                    teacher.username
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          
  <!--        Location-->
          <div
            *ngIf="
              course.appointments &&
              course.appointments[0] &&
              course.appointments[0].location &&
              course.appointments[0].address &&
              course.appointments[0] as appointment
            "
          >
            <div class="mat-subtitle-1">{{ t('GENERIC_WRAPPER.COURSE_LOCATION') }}</div>
            <div class="flex-row gap-5">
              <div class="w-100">
                <span
                  class="link"
                  target="_blank"
                  style="white-space: pre-line; color: #4d4d4d;"
                  (click)="
                    openGoogleMapsInNewTab(appointment.address.googleMapsUrl)
                  "
                >
                  {{ appointment | googleAddressReadable }}
                </span>
              </div>
            </div>
            <div
              id="google-maps-container"
              class="h-100 w-100 mt-20 align-start"
            >
              <google-map
                [height]="200"
                width="100%"
                [options]="googleMapsOptions"
                [center]="
                  [appointment.address.latitude, appointment.address.longitude]
                    | googleLatLngLiteralPipe
                "
                (mapClick)="
                  openGoogleMapsInNewTab(appointment.address.googleMapsUrl)
                "
              >
                <map-marker
                  #markerElement="mapMarker"
                  [position]="
                    [appointment.address.latitude, appointment.address.longitude]
                      | googleLatLngLiteralPipe
                  "
                  [clickable]="false"
                >
                </map-marker>
              </google-map>
            </div>
          </div>
          
  <!--        Tags-->
          <div *ngIf="course.tags?.length > 0">
            <div class="mat-subtitle-1">{{ t('GENERIC_WRAPPER.TAGS') }}</div>
            <mat-chip-listbox [selectable]="false">
              <mat-chip-option
                [disableRipple]="true"
                [disabled]="true"
                *ngFor="let tag of course.tags"
              >
                {{ tag }}
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
