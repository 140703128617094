<ng-container *transloco="let t">
  <div *ngIf="!loading; else spinner" class="flex-column">
    <mat-accordion hideToggle *ngIf="faqs?.length > 0; else noFaqs">
      <div class="flex-row align-space-between align-center">
        <span class="administrator-manager-title" >FAQ ({{faqs?.length}})</span>
        <div class="p2 df-administration-faqs-new-faq-btn">
        <df-shared-lib-button
          [buttonLabel]="t('DANCE_MANAGER_ADMINISTRATION.FAQ.NEW_FAQ')"
          [materialButtonStyleType]="'outlined'"
          [matIconName]="'add'"
          (buttonClicked)="showNewFaq = true"
        ></df-shared-lib-button>
        </div>
      </div>
      <ng-container *ngIf="!showNewFaq; else createFaq">
      </ng-container>
      <div cdkDropList [cdkDropListData]="faqs" (cdkDropListDropped)="onMouseDropEvent($event)">
        <mat-expansion-panel *ngFor="let faq of faqs; let index = index" class="created-faqs-bottom-margin" #mep="matExpansionPanel" cdkDrag>
          <mat-expansion-panel-header class="df-administration-faqs-panel-header">
            <mat-panel-title
              class="df-administration-faqs-panel-title">
              <div class="drag-and-drop-indicator drag-handle">
                <mat-icon class="drag-and-drop-icon">drag_indicator</mat-icon>
              </div>
              <div *ngIf="!mep.expanded" class="df-administration-faqs-panel-question h-100">
                <span class="bold w-100" >{{ faq.question | truncate:80 }} </span>
                <span style="font-size: 14px;">{{ faq.answer | truncate:80}}</span>
              </div>
            </mat-panel-title>
            <mat-panel-description class="df-administration-faqs-panel-delete" (click)="$event.stopPropagation();">
              <button mat-icon-button (click)="deleteFaqConfirmation(faq)" id="delete-faq-button">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <dfm-add-edit-faq
            [danceSchoolId]="danceSchool.id"
            (reloadNewFaqArray)="refreshFaqList()"
            (cancel)="mep.expanded = !mep.expanded"
            [faq]="faq"
          ></dfm-add-edit-faq>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
  
  <ng-template #noFaqs>
    <div *ngIf="!showNewFaq; else createFaq" class="pt4 flex-column align-center">
      <img src="assets/images/no_faqs.png">
      <h3 class="align-center">{{ t('DANCE_MANAGER_ADMINISTRATION.FAQ.NO_FAQ') }}</h3>
      <p class="align-center" style="text-align: center;">{{ t('DANCE_MANAGER_ADMINISTRATION.FAQ.START_FAQ') }}</p>
      <div class="align-center pb2">
        <df-shared-lib-button
          [buttonLabel]="t('DANCE_MANAGER_ADMINISTRATION.FAQ.CREATE_FAQ')"
          (buttonClicked)="showNewFaq = true"
        ></df-shared-lib-button>
      </div>
    </div>
  </ng-template>
  
  <ng-template #createFaq>
    <dfm-add-edit-faq [danceSchoolId]="danceSchool.id" (cancel)="showNewFaq = false" (reloadNewFaqArray)="refreshFaqList()"></dfm-add-edit-faq>
  </ng-template>
  
  <ng-template #spinner>
    <div class="center mt3">
      <mat-spinner
        style="margin: auto"
        diameter="40"
      ></mat-spinner>
    </div>
  </ng-template>
</ng-container>
