<div class="div-style">
  <span class="material-icons-round">
sentiment_dissatisfied
</span>
  <h1>Error 500</h1>
  <p>
    Sorry,this time it's our fault we embarrassed
    <br />
   ourselves in front of the whole  dance floor,please.
    <br />
    <br />
    <a href="https://www.danceflavors.com">Go back to Danceflavors.com</a>
  </p>
</div>
