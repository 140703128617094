import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DfDanceStyleRoleComponent } from './df-dance-style-role.component';
import { SliderGradientModule } from '../slider-gradient';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfDanceStyleRoleComponent],
    imports: [
      CommonModule,
      SliderGradientModule,
      MatCheckboxModule,
      ReactiveFormsModule,
      FormsModule,
      TranslocoDirective,
    ],
  exports: [DfDanceStyleRoleComponent],
})
export class DfDanceStyleRoleModule {}
