import { DanceManagerUserRoleEnum } from '../../enums';

export interface DanceManagerUserInviteTokenInterface {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  invitedByUserId: string;
  danceManagerId: string;
  email: string;
  role: DanceManagerUserRoleEnum;
  expiresAt: Date;
  token: string;
}
