<ng-container *transloco="let t">
  <h2 mat-dialog-title>{{ t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.TITLE') }}</h2>
  
  <mat-dialog-content class="mat-typography">
    <p>{{t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.CHOOSE')}}</p>
    <mat-form-field appearance="outline" class="pt1">
        <mat-label>{{t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.SELECT_PASS')}}</mat-label>
        <mat-select [(ngModel)]="selectedPass" disableOptionCentering required>
          <mat-option *ngFor="let pass of passes" [value]="pass.id">
            {{pass.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    
    <p>
      {{ t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.ENTER_NAME') }}<br/>
    </p>
  
    <df-shared-lib-user-search-autocomplete
    (optionSelected)="onUserSelected($event)"
    (inputChange)="onInputChange($event)"
    [label]="t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.NAME_LABEL')"
    [resetFormAfterSelection]="true"
    [showUserSearchLoading]="showUserSearchLoadingSpinner"
    [userOptions]="userOptions">
    </df-shared-lib-user-search-autocomplete>
  
    <p class="pt1">{{ t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.EMAIL') }}</p>
  
    <div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{ t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.ENTER_MAIL') }}</mat-label>
        <input matInput placeholder="pat@example.com" [formControl]="emailInput" (keyup.enter)="onEmailSelected()">
        <mat-error *ngIf="emailInput.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
    
    <p class="mt-10">{{ t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.SELECT_USERS') }}</p>
    <div class="chip-box">
      <mat-chip-listbox aria-label="Selected Users">
        <mat-chip-option
          *ngFor="let user of selectedUsers" 
          (removed)="removeSelectedUser(user)">
          <mat-chip-avatar *ngIf="user.imageurl">
            <img src="{{user.imageUrl}}" alt="Photo of {{user.displayValue}}"/>
          </mat-chip-avatar>
          {{user.value}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
        <mat-chip-option 
          *ngFor="let email of selectedEmails" 
          (removed)="removeSelectedEmail(email)">
          {{email}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
      </mat-chip-listbox>
  
    </div>
  
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <div class="invite-button-container">
      <df-shared-lib-button
        [buttonLabel]= "t('GENERIC_WRAPPER.CANCEL')"
        [materialButtonStyleType]="'outlined'"
        [minWidth]="'100%'"
        mat-dialog-close>
      </df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]= "t('GENERIC_WRAPPER.FREE_PASS_INVITE_DIALOG.INVITE') | titlecase"
        [materialButtonStyleType]="'flat'"
        [minWidth]="'100%'"
        cdkFocusInitial
        [isDisabled]="isInviteDisabled" 
        (click)="invite()">
      </df-shared-lib-button>
    </div>
  </mat-dialog-actions>
</ng-container>
