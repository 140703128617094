import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { PassInfoFormComponent } from './pass-info-form.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '../../pipes';
import { DfSharedButtonModule } from '../buttons';
import { DfQuillEditorModule } from '../df-quill-editor';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { TranslocoModule } from '@jsverse/transloco';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { IntegerOnlyDirective, NoScrollDirective } from '../../directives';

@NgModule({
  declarations: [PassInfoFormComponent, IntegerOnlyDirective],
  exports: [PassInfoFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
    RouterModule,
    MatCardModule,
    MatDividerModule,
    DfSharedButtonModule,
    MatFormFieldModule,
    MatInputModule,
    DfQuillEditorModule,
    ReactiveFormsModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatOption,
    MatSelect,
    MatCheckbox,
    NoScrollDirective
  ]
})
export class PassInfoFormModule {}
