import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceCourseAppointmentCardComponent } from './dance-course-appointment-card.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from '../../pipes';
import { DfSharedButtonModule } from '../buttons';
import {RouterLink} from "@angular/router";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceCourseAppointmentCardComponent],
    imports: [
      CommonModule,
      MatDividerModule,
      MatIconModule,
      MatButtonModule,
      PipesModule,
      DfSharedButtonModule,
      RouterLink,
      TranslocoDirective,
    ],
  exports: [DanceCourseAppointmentCardComponent],
})
export class DanceCourseAppointmentCardModule {}
