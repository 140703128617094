import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateTicketOrderRequestDto, DanceManagerPayoutDetailElementResponseDto, environmentForWeb, TicketOrderDto } from '@platri/df-common-core';

@Injectable({ providedIn: 'root' })
export class TicketOrderHttpService {
  private SERVICE_URL = `${environmentForWeb.apiTicketUrl}/ticket-orders`;

  constructor(private http: HttpClient) {}

  download(ticketOrderId: string): Observable<Blob> {
    return this.http.get(`${this.SERVICE_URL}/download/${ticketOrderId}`, { responseType: 'blob' });
  }
  
  create(createTicketOrder: CreateTicketOrderRequestDto): Observable<TicketOrderDto> {
    return this.http.post<TicketOrderDto>(this.SERVICE_URL, {
      ...createTicketOrder,
      isWeb: true
    });
  }

  getById(id: string): Observable<TicketOrderDto> {
    return this.http.get<TicketOrderDto>(`${this.SERVICE_URL}/${id}`);
  }

  updateStripePaymentIntentById(id: string): Observable<TicketOrderDto> {
    return this.http.put<TicketOrderDto>(
      `${this.SERVICE_URL}/${id}/stripe-payment-intent`,
      {}
    );
  }

  updatePaypalOrderById(id: string): Observable<TicketOrderDto> {
    return this.http.put<TicketOrderDto>(
      `${this.SERVICE_URL}/${id}/paypal/create-order`,
      {}
    );
  }

  updatePaypalOrderCaptureById(id: string): Observable<TicketOrderDto> {
    return this.http.put<TicketOrderDto>(
      `${this.SERVICE_URL}/${id}/paypal/capture-order`,
      {}
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.SERVICE_URL}/${id}`);
  }
  
  getDetailsByStripePaymentIntentIds(stripePaymentIntentIds: string[]): Observable<DanceManagerPayoutDetailElementResponseDto[]> {
    return this.http.post<DanceManagerPayoutDetailElementResponseDto[]>(`${this.SERVICE_URL}/payout-detail-summary/v2`, {stripePaymentIntentIds}, {
      headers: {
        'X-API-KEY': environmentForWeb.apiKey,
      }
    });
  }
}
