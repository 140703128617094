import { Directive, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DanceManagerFeatureInterface, DanceManagerFeatureNameEnum, DanceManagerUserRoleEnum, hasAccessToDanceManagerFromTokenRolesByIdAndRoles } from '@platri/df-common-core';
import { AuthStateService } from '../../services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[securedDanceSchoolElement]',
})
export class SecuredDanceSchoolElementDirective implements OnInit, OnChanges {
  @HostBinding('hidden') isHidden = false;
  @Input() danceManagerId: string;
  @Input() allowedRoles: DanceManagerUserRoleEnum[];
  @Input() danceManagerFeatureName: DanceManagerFeatureNameEnum;
  @Input() danceManagerFeatures: DanceManagerFeatureInterface[];

  isAllowed = false;
  isFeatureAllowed = false;

  constructor(private readonly authService: AuthStateService) {}

  ngOnInit(): void {
    this.checkIsUserAllowedToSee();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.danceManagerId || changes.danceManagerFeatures) {
      this.checkIfFeatureIsAvailable();
      this.checkIsUserAllowedToSee();
    }
  }

  private checkIfFeatureIsAvailable(): void {
    if (this.danceManagerFeatureName) {
      this.isFeatureAllowed = !!this.danceManagerFeatures?.find(
        (danceManagerFeature) =>
          danceManagerFeature.name === this.danceManagerFeatureName
      );
    }
  }

  private checkIsUserAllowedToSee(): void {
    if (this.allowedRoles?.length > 0) {
      this.isAllowed = hasAccessToDanceManagerFromTokenRolesByIdAndRoles(this.danceManagerId, this.authService.getSyncCurrentUser().roles.danceSchool, this.allowedRoles);
      this.isHidden = !this.isAllowed && !this.isFeatureAllowed;
    } else {
      this.isHidden = !this.isFeatureAllowed;
    }
  }
}
