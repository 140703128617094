import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CourseDmOverviewInterface, CourseInterface, CreateDmPassSubscriptionRequestDto, DfmCoursePassesTableInterface, DmPassSubscriptionInterface, FeesPaidByEnum, PassSubscriptionTargetTypeEnum } from '@platri/df-common-core';
import { CoursesStateService, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService, DmPassSubscriptionHttpService, optionalMinMaxValidator, PassesStateService, PassSubscriptionStateService, quillCharacterCountValidator } from '@platri/dfx-angular-core';
import { getSubscription } from '@platri/elab-angular-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-create-pass-subscription-page',
  templateUrl: './create-pass-subscription-stepper.page.html',
  styleUrls: ['./create-pass-subscription-stepper.page.scss']
})
export class CreatePassSubscriptionStepperPage implements OnInit, OnDestroy {
  @Output() successfulCreateEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  infoFormGroup: UntypedFormGroup;
  priceFormGroup: UntypedFormGroup;
  
  courses: CourseDmOverviewInterface[];
  selectedCourses: DfmCoursePassesTableInterface[];

  isInitialized = false;
  isWaitingForCreateResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  createFailedNoConnection = false;
  createFailedInternalServer = false;
  createFailedServerNotAvailable = false;
  
  taxPercentage : number;
  isVatChanged = false;
  
  subscriptions = new Subscription(); 
  
  constructor(
    private readonly fb: FormBuilder, 
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly dmPassSubscriptionHttpService: DmPassSubscriptionHttpService,
    private readonly coursesService: CoursesStateService, 
    private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly passesStateService: PassesStateService,
    private readonly passSubscriptionStateService: PassSubscriptionStateService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.initFormGroups();
    this.subscriptions.add(getSubscription(this.coursesService.coursesSubject$, this.onCourseChange.bind(this)));
    this.coursesService.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 100);
    if (this.passSubscriptionStateService.getSyncPassSubscriptionToDuplicateSubject() !== null) {
      this.injectDataToDuplicate(this.passSubscriptionStateService.getSyncPassSubscriptionToDuplicateSubject());
    } else {
      this.injectData();
    }
  }
  
  ngOnDestroy(): void {
    this.passesStateService.clear();
    this.subscriptions.unsubscribe();
  }

  onCourseChange(courses: CourseInterface[]): void {
    if (courses) {
      this.courses = courses;
    }
  }

  initFormGroups(): void {
    this.initInfoFormGroup();
    this.initPriceFormGroup();
  }
  
  injectData(): void {
    this.taxPercentage = this.danceSchoolService.getSyncCurrentDanceSchool().vat ?? null;
    this.priceFormGroup.get('taxPercentage')?.valueChanges.subscribe(value => {
      if (value !==this.taxPercentage){
        this.taxPercentage = value;
        this.isVatChanged = true;
      }
    });
    this.priceFormGroup.patchValue({
      taxPercentage: this.taxPercentage ?? null
    });
  }
  
  injectDataToDuplicate(passSubscription: DmPassSubscriptionInterface): void {    
    this.infoFormGroup.patchValue({
      name: passSubscription.name,
      duration: {
        value: passSubscription.durationValue,
        interval: passSubscription.durationUnit
      },
      weeklyUsage: passSubscription.weeklyUsage,
      description: passSubscription.description
    });
    this.priceFormGroup.patchValue({
      price: passSubscription.price / 100,
      isPaidMonthly: passSubscription.isPaidMonthly,
      taxPercentage: passSubscription.taxPercentage
    });
    this.passSubscriptionStateService.sendPassSubscriptionToDuplicateSubject(null);
  }

  initInfoFormGroup(): void {
    this.infoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      duration: this.fb.group({
        value: [null, [Validators.required, optionalMinMaxValidator(1, 12)]],
        interval: [null, [Validators.required]]
      }),
      weeklyUsage: [null, [optionalMinMaxValidator(1, 100)]],
      description: [null, [quillCharacterCountValidator(1000)]]
    });
  }

  initPriceFormGroup(): void {
    this.priceFormGroup = this.fb.group({
      price: [null, [Validators.required, Validators.min(0), Validators.max(10000)]],
      isPaidMonthly: [false, [Validators.required]],
      taxPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      feesPaidBy: [FeesPaidByEnum.CREATOR]
    });
  }
    
  selectedTargets(data: DfmCoursePassesTableInterface[]): void {
    this.selectedCourses = data;
  }
  
  navigateToCoursePassesOverview(): void {
    this.router.navigate(['../'], { relativeTo: this.route, fragment: 'subscriptions' });
  }
  
  onSubmit(): void {
    if (this.infoFormGroup.valid && this.priceFormGroup.valid && this.selectedCourses?.length > 0) {
      this.isWaitingForCreateResponse = true;
      this.createFailedNoConnection = false;
      this.createFailedInternalServer = false;

      const { name, weeklyUsage, description } = this.infoFormGroup.getRawValue();
      const { value, interval } = this.infoFormGroup.get('duration').getRawValue();
      const { price, isPaidMonthly, taxPercentage } = this.priceFormGroup.getRawValue();

      const subscriptionTargets = [];
      if (this.selectedCourses && this.selectedCourses.length > 0) {
        for (const course of this.selectedCourses) {
          subscriptionTargets.push({targetId: course.id, targetType: PassSubscriptionTargetTypeEnum.COURSE});
        }
      }
      
      const createDmPassSubscriptionRequestDto: CreateDmPassSubscriptionRequestDto = new CreateDmPassSubscriptionRequestDto({
        danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id,
        name: name!,
        price: Math.round(+price! * 100),
        durationUnit: interval!,
        durationValue: value!,
        isPaidMonthly: isPaidMonthly!,
        weeklyUsage: weeklyUsage!,
        description: description!,
        taxPercentage: +taxPercentage!,
        targets: subscriptionTargets!
      });
      
      this.subscriptions.add(getSubscription(
        this.dmPassSubscriptionHttpService.createPassSubscription(createDmPassSubscriptionRequestDto),
        this.onCreatePassSubscriptionSuccessful.bind(this),
        this.onCreatePassSubscriptionFailed.bind(this)
      ));
    }
  }
  
  onCreatePassSubscriptionSuccessful(sub: number): void {
    if (this.isVatChanged) {
      this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id, ({vat: this.taxPercentage})).pipe(
        map((result) => {
          this.danceSchoolService.sendCurrentDanceSchool(result);
          this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
            map((danceSchools) => danceSchools),
            map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
          ).subscribe();
        })
      ).subscribe();
    }
    this.successfulCreateEmitter.emit(true);
    this.isWaitingForCreateResponse = false;
    this.navigateToCoursePassesOverview();
    this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_COURSE.SUCCESSFULLY_CREATED_PASS'));
  }
  
  onCreatePassSubscriptionFailed(error): void {
    console.error(error);
    if (error.status === 0) {
      this.onConnectionLost();
    }
    if (error.status === 500) {
      this.onInternalServerError();
    }
    if (error.status === 504) {
      this.onServiceNotAvailable();
    }
    this.isWaitingForCreateResponse = false;
  }

  onConnectionLost(): void {
    this.createFailedNoConnection = true;
  }

  onInternalServerError(): void {
    this.createFailedInternalServer = true;
  }

  onServiceNotAvailable(): void {
    this.createFailedServerNotAvailable = true;
  }
}
