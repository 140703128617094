/**
 * Calculates based on the config values the application fee for ticket order
 * @param total total of ticket order in cents ex 1000
 // * @param numberOfTickets number of tickets ex 5
 * @param ticketFeePercentage fee in percentage ex. 4.5
 * @param ticketFeePerTicket fee per ticket in cents ex 45
 */
export function calculateApplicationFee(
  total: number,
  numberOfTickets: number,
  ticketFeePercentage: number,
  ticketFeePerTicket: number
): number {
  return (Math.round(total * (ticketFeePercentage / 100) + Number.EPSILON) +
    numberOfTickets * ticketFeePerTicket);
}

/**
 * Calculates 19% tax on the given application fee
 * @param applicationFee application fee in cents
 */
export function calculateTaxOnApplicationFee(applicationFee: number): number {
  return Math.round(applicationFee * 1.19 + Number.EPSILON);
}
