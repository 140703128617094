<ng-container *transloco="let t">
  <form
    [formGroup]="faqForm"
    (ngSubmit)="saveFaq()"
    class="flex-column p2 border"
    style="gap: 15px;"
  >
    <span class="bold" style="padding-top: 10px;">{{ t('DANCE_MANAGER_ADMINISTRATION.FAQ.QUESTION') }}</span>
    <mat-form-field
      class="w-100"
      appearance="outline"
      id="question-mat-form-field"
    >
      <mat-label>{{ t('DANCE_MANAGER_ADMINISTRATION.FAQ.QUESTION_PLACEHOLDER') }}</mat-label>
      <input 
        matInput 
        type="text" 
        formControlName="question" 
        maxlength="250" 
        data-cy="faq-label-input"
      />
      <mat-hint align="end">{{ faqForm.controls.question.value.length }} / 250</mat-hint>
    </mat-form-field>
  
    <span class="bold">{{ t('DANCE_MANAGER_ADMINISTRATION.FAQ.ANSWER') }}</span>
    <mat-form-field
      class="w-100"
      appearance="outline"
      id="answer-mat-form-field"
    >
      <mat-label>{{ t('DANCE_MANAGER_ADMINISTRATION.FAQ.ANSWER_PLACEHOLDER') }}</mat-label>
      <textarea
        rows="8"
        autocomplete="off"
        matInput
        formControlName="answer"
        maxlength="500"
      ></textarea>
      <mat-hint align="end">{{ faqForm.controls.answer.value.length }} / 500</mat-hint>
    </mat-form-field>
  
    <div class="flex-row-end-center" style="gap: 10px;">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel.emit()"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [isDisabled]="faqForm.invalid"
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveFaq()"
        id="faq-save-button"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
