<ng-container *transloco="let t">
  <div class="flex-column w-100" style="gap: 1rem;">
    <mat-form-field appearance="outline" style="max-width: 350px;">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" [placeholder]="t('GENERIC_WRAPPER.SEARCH')">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="masterToggle()" [checked]="isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (change)="onSelectionChange($event, row)" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row align-start-center">
            <span>
              {{ element.name }}
            </span>
          </div>
        </td> 
      </ng-container>
      <ng-container matColumnDef="danceStyles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.DANCE_STYLES_TEXT') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row align-start-center">
            <span *ngFor="let danceStyle of element.danceStyles; let last = last;">{{ t('GENERIC_WRAPPER.DANCE_STYLES.' + danceStyle.translationKey.toUpperCase()) }}{{ last ? '' : ', ' }}</span>
          </div>
        </td> 
      </ng-container>
      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Level</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row align-start-center">
            {{ t('GENERIC_WRAPPER.' + (element.startDifficulty | skillLevelTranslation)) }} - {{ t('GENERIC_WRAPPER.' + (element.endDifficulty | skillLevelTranslation)) }}
          </div>
        </td> 
      </ng-container>
      <ng-container matColumnDef="nextAppointment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">{{ t('GENERIC_WRAPPER.NEXT_APPOINTMENT') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex-row align-start-center">
            <ng-container *ngIf="element.nextAppointmentDate; else noAppointments">
              {{ element.nextAppointmentDate | dateTimezoned : 'UTC' : currentLang : element.nextAppointmentDuration ?? null }}
            </ng-container>
          
            <ng-template #noAppointments>
              <span>-</span>
            </ng-template>
          </div>
        </td> 
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="clickable-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
