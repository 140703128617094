import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DfmCreateUserGroupFormComponent } from './dfm-create-user-group-form.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import {
  AmenitiesModule,
  DfSharedButtonModule,
  GoogleAddressSearchAutocompleteModule,
  OpenHoursModule,
  PhoneInputModule,
  PipesModule,
  UserSearchAutocompleteModule
} from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatTableModule } from '@angular/material/table';
import {
  MatChipGrid,
  MatChipListbox,
  MatChipOption,
  MatChipRemove,
  MatChipRow
} from '@angular/material/chips';

@NgModule({
  declarations: [DfmCreateUserGroupFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    AmenitiesModule,
    DfSharedButtonModule,
    GoogleAddressSearchAutocompleteModule,
    MatDividerModule,
    PhoneInputModule,
    PipesModule,
    MatRadioModule,
    MatCardModule,
    OpenHoursModule,
    TranslocoDirective,
    UserSearchAutocompleteModule,
    MatTableModule,
    MatChipGrid,
    MatChipRow,
    MatChipOption,
    MatChipRemove,
    MatChipListbox
  ],
  exports: [DfmCreateUserGroupFormComponent]
})
export class DfmCreateUserGroupFormModule { }
