import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DfmCoursesComponent } from './pages/app';
import { AppointmentResolver, CourseResolver, dfAppointmentIdRouterParam, dfCourseIdRouterParam } from '@platri/dfx-angular-core';
import { CoursePreviewComponent } from './pages/course-preview/course-preview.component';
import { AppointmentParticipantsPageComponent } from './pages/appointment-participants/appointment-participants-page.component';
import { CreateCourseStepperPage } from './pages/create-course-stepper-page/create-course-stepper.page';

const routes: Routes = [
  {
    path: '',
    component: DfmCoursesComponent,
  },
  {
    path: 'create',
    component: CreateCourseStepperPage,
  },
  {
    path: 'create-pass',
    loadChildren: () => import('./pages/create-pass-stepper-page/create-pass-stepper-page.module').then((m) => m.CreatePassStepperPageModule),
  },
  {
    path: 'create-pass-subscription',
    loadChildren: () => import('./pages/create-pass-subscription-stepper-page/create-pass-subscription-stepper-page.module').then((m) => m.CreatePassSubscriptionStepperPageModule),
  },
  {
    path: 'pass/:passId',
    data: { breadcrumb: { alias: 'passName' } },
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.DfmPassDetailPageModule)
  },
  {
    path: 'pass-subscription/:passSubscriptionId',
    data: { breadcrumb: { alias: 'passSubscriptionName' } },
    loadChildren: () => import('@platri/dfx-angular-core').then((m) => m.DfmPassSubscriptionDetailPageModule)
  },
  {
    path: 'preview/:' + dfCourseIdRouterParam,
    data: { breadcrumb: { alias: 'courseName' } },
    resolve: {
      targetObject: CourseResolver,
    },
    children: [
      {
        path: '',
        component: CoursePreviewComponent,
      },
      {
        path: 'appointment/:' + dfAppointmentIdRouterParam,
        component: AppointmentParticipantsPageComponent,
        data: { breadcrumb: { alias: 'appointmentDate' } },
        resolve: {
          targetObject: AppointmentResolver,
        },
      },
    ]
  },
  {
    path: 'preview/:' + dfCourseIdRouterParam + '/create-appointments',
    data: { breadcrumb: { alias: 'courseName' } },
    resolve: {
      targetObject: CourseResolver,
    },
    loadChildren: () => import('./pages/course-create-appointments/course-create-appointments-page.module').then((m) => m.CourseCreateAppointmentsPageModule),
  },
  {
    path: 'preview/:' + dfCourseIdRouterParam + '/edit-appointments/:appointmentId',
    data: { breadcrumb: { alias: 'courseName' } },
    resolve: {
      targetObject: CourseResolver,
    },
    loadChildren: () => import('./pages/course-edit-appointment/course-edit-appointments-page.module').then((m) => m.CourseEditAppointmentsPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendDfmCourseAppRoutingModule {
  constructor() {
    console.log('FrontendDfmCourseAppRoutingModule initialized');
  }
}
