import { Pipe, PipeTransform } from '@angular/core';
import { LanguageLevelEnum } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'languageLevelAsNumberPipe',
})
export class LanguageLevelAsNumberPipe implements PipeTransform {
  transform(value: LanguageLevelEnum): number {
    if (value === null) {
      return null;
    }
    switch (value) {
      case 'BEGINNER':
        return 25;
      case 'MODERATE':
        return 50;
      case 'FLUENT':
        return 75;
      case 'FIRST_LANGUAGE':
        return 100;
      default:
        return 0;
    }
  }
}
