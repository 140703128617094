import {DanceManagerSubscriptionInvoiceInterface} from './dance-manager-subscription-invoice.interface';
import {DanceManagerSubscriptionPaymentStatusEnum, DanceManagerSubscriptionStatusEnum} from "../enums";
import {DanceManagerInterface} from "./dance-manager";

export interface DanceManagerSubscriptionInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  danceSchool?: DanceManagerInterface;
  subscriptionStatus?: DanceManagerSubscriptionStatusEnum;
  subscriptionPaymentStatus?: DanceManagerSubscriptionPaymentStatusEnum;
  stripePriceId?: string;
  danceManagerSubscriptionInvoices?: DanceManagerSubscriptionInvoiceInterface[];
  stripeSubscriptionId?: string;
  subscriptionPlan?: string;
  subscriptionInterval?: string;
  subscriptionPaymentMethod?: string;
  startedAt?: Date;
  currentPeriodStartAt?: Date | null;
  currentPeriodEndAt?: Date | null;
  endedAt?: Date | null;
  canceledAt?: Date | null;
  totalPrice?: number;
}
