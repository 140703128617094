<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveUserNameData()">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{t('USER.USERNAME') }}</mat-label>
      <input matInput formControlName="username" required #username autocomplete="off"/>
      <span matSuffix *ngIf="showCheckIcon" style="padding-right: 20px;">
        <mat-icon style="color: lightgreen">check</mat-icon>
      </span>
      <div matSuffix *ngIf="loadingCheckUsername" style="padding-right: 20px;">
        <mat-spinner [diameter]="20" color="primary"></mat-spinner>
      </div>
      <mat-hint align="end">
        {{username.value.length}} / 30
      </mat-hint>
      <mat-error *ngIf="usernameControl.hasError('unavailable')">
        {{t('USER.USERNAME_ERROR_UNAVAILABLE') }}
      </mat-error>
      <mat-error *ngIf="usernameControl.hasError('whitespace') && !usernameControl.hasError('maxlength')">
        {{t('USER.WHITESPACE_NOT_ALLOWED') }}
      </mat-error>
      <mat-error *ngIf="usernameControl.hasError('invalid')">
        {{t('USER.USERNAME_ERROR_INVALID') }}
      </mat-error>
      <mat-error *ngIf="usernameControl.hasError('maxlength')" class="errorTextSpaceBetween">
        <div>
          {{t('USER.USERNAME_ERROR_MAX_LENGTH') }}
        </div>
        <div style="margin-left: auto">
          {{usernameControl.errors?.maxlength.actualLength}} / 30
        </div>
      </mat-error>
    </mat-form-field>
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveUserNameData()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
