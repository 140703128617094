import { DanceManagerCategoryEnum, DanceManagerInterface } from '../../..';
import { DanceManagerFeatureDto, DanceManagerSocialLinkDto, DanceStyleDto, ImageDto } from '../../..';

export class DanceManagerPublicViewDto implements DanceManagerInterface {
  id: string;
  name: string;
  danceStyles: DanceStyleDto[];
  urlName: string;
  imageUrl: string;
  imageGalleryUrls: string [];
  socialLinks: DanceManagerSocialLinkDto[];
  description: string;
  teamDescription: string;
  titleImage: ImageDto;
  danceManagerCategory?: DanceManagerCategoryEnum[];
  danceManagerFeatures: DanceManagerFeatureDto[];
}
