<ng-container *transloco="let t">
  <div class="flex-row align-space-between p2 w-100">
    <div class="flex-column gap-20">
      <div class="flex-row-center-center gap-10">
        <mat-icon style="color: #3377FF">info_rounded</mat-icon>
        <span class="info-text">{{t('GENERIC_WRAPPER.WORDPRESS_REDIRECT_INFO.TITLE') }}</span>
      </div>
      <div class="flex-row-center-center gap-10 w-100">
        <mat-icon style="color: #3377FF">open_in_new_rounded</mat-icon>
        <span class="redirect-text">{{ t('GENERIC_WRAPPER.WORDPRESS_REDIRECT_INFO.LINK.PREFIX') }}
          <a style="cursor: pointer; color: #3377FF; text-decoration: underline" href="https://www.danceflavors.com/?noRedirect=true">{{ t('GENERIC_WRAPPER.WORDPRESS_REDIRECT_INFO.LINK.SUFFIX') }}</a>
        </span>
      </div>
    </div>
  </div>
</ng-container>
