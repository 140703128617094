<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveJobData()">
    <mat-form-field appearance="outline">
      <mat-label>{{ t('USER.JOB') }}</mat-label>
      <input matInput formControlName="job" autocomplete="off"/>
    </mat-form-field>
  
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveJobData()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
