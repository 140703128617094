import { LocationInterface, RadiusUnitEnum } from '../../..';
import { ImageDto } from './image.dto';
import { RoomDto } from './room.dto';
import { AmenityDto } from './amenity.dto';
import { OpeningHoursDto } from './opening-hours.dto';
import { DanceSchoolDto } from './dance-school.dto';
import { AddressDto } from './address.dto';

export class LocationsDto implements LocationInterface {
  id?: string;
  name?: string;
  address?: AddressDto;
  phonePrefix?: string;
  phonePrefixAfter?: string;
  phoneNumber?: string;
  email?: string;
  amenities?: AmenityDto[];
  openingHours?: OpeningHoursDto;
  showOpeningHours?: boolean;
  rooms?: RoomDto[];
  danceSchool?: DanceSchoolDto;
  images?: ImageDto[];
  previewImage?: ImageDto;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  isPublic?: boolean;
}
