import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByPipe',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, field: string): any[] {
    array.sort((a: any, b: any) => {
      const lowerA = a[field].toLowerCase();
      const lowerB = b[field].toLowerCase();

      if (lowerA < lowerB) {
        return -1;
      } else if (lowerA > lowerB) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
