import { FollowUserInterface } from '../../..';
import { FollowDto } from './follow.dto';
import { FollowedUserDto } from './followed-user.dto';
import { FollowedDanceSchoolDto } from './followed-dance-school.dto';

export class FollowUserDto implements FollowUserInterface {
  currentUserFollowId: string;
  danceSchool?: FollowedDanceSchoolDto;
  follow: FollowDto;
  isCurrentUserFollowing: boolean;
  user?: FollowedUserDto;
}
