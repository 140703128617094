import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerHeaderComponent } from './dance-manager-header.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DfFollowModule, DfmLocationsWrapperPageModule, ImageUploadCropperModule, PipesModule, ShareModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import {MatTooltip} from "@angular/material/tooltip";
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceManagerHeaderComponent],
  exports: [DanceManagerHeaderComponent],
    imports: [
      CommonModule,
      MatCardModule, 
      MatIconModule, 
      MatButtonModule, 
      ShareModule, 
      ImageUploadCropperModule,
      MatDividerModule,
      DfFollowModule, 
      MatChipsModule, 
      PipesModule,
      RouterModule,
      MatTooltip,
      DfmLocationsWrapperPageModule,
      TranslocoDirective
    ]
})
export class DanceManagerHeaderModule {}
