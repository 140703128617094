import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { PassSubscriptionDurationIntervalEnum } from '@platri/df-common-core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'df-pass-subscription-info-form',
  templateUrl: './pass-subscription-info-form.component.html',
  styleUrls: ['./pass-subscription-info-form.component.scss']
})
export class PassSubscriptionInfoFormComponent implements OnInit {
  @Input() infoFormGroup: UntypedFormGroup;
  @Input() priceFormGroup: UntypedFormGroup;
  @Input() numberOfSales: number;

  passSubscriptionDurationIntervalEnum = PassSubscriptionDurationIntervalEnum;
  
  ngOnInit(): void {
    if (this.numberOfSales > 0) {
      this.duration.get('value').disable();
      this.duration.get('interval').disable();
      this.weeklyUsage.disable();
    }
    if (this.duration.get('interval').value === 'YEAR') {
      this.duration.get('value').patchValue(1);
      this.duration.get('value').disable();
    }
  }

  selectedYearAsInterval(event: MatSelectChange): void {
    if (event.value === 'YEAR') {
      this.duration.get('value').patchValue(1);
      this.duration.get('value').disable();
    }
    else if (this.numberOfSales === 0) {
      this.duration.get('value').enable();
    }
  }
  
  get name(): AbstractControl {
    return this.infoFormGroup.get('name');
  }
  get duration(): AbstractControl {
    return this.infoFormGroup.get('duration');
  }
  get weeklyUsage(): AbstractControl {
    return this.infoFormGroup.get('weeklyUsage');
  }
  get description(): AbstractControl {
    return this.infoFormGroup.get('description');
  }
}
