import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { DfSharedButtonModule } from '@platri/dfx-angular-core';
import { AddEditEventAppointmentLocationComponent } from './add-edit-event-appointment-location.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleAddressSearchAutocompleteModule } from '@platri/dfx-angular-core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DisableControlModule } from '@platri/dfx-angular-core';
import { DfTimePickerModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [AddEditEventAppointmentLocationComponent],
  exports: [AddEditEventAppointmentLocationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    DfSharedButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    GoogleAddressSearchAutocompleteModule,
    MatRadioModule,
    MatSelectModule,
    DisableControlModule,
    DfTimePickerModule,
    TranslocoDirective,
  ],
})
export class AddEditEventAppointmentLocationModule {}
