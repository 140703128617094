import { CourseRulesInterface } from './course-rules.interface';
import { ImageInterface } from '../image.interface';
import { CourseAppointmentInterface } from '../course-appointment';
import { DanceStyleInterface } from '../dance-style.interface';
import { DanceSchoolInterface } from '../dance-school.interface';
import { CourseStatusEnum, RadiusUnitEnum } from '../../enums';
import { PageViewsInterface } from '../page-views.interface';

export interface CourseInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  name?: string;
  description?: string;
  startDifficulty?: number;
  endDifficulty?: number;
  tags?: string[];
  rules?: CourseRulesInterface;
  mainImage?: ImageInterface;
  images?: ImageInterface[];
  danceStyles?: DanceStyleInterface[];
  danceManager?: DanceSchoolInterface;
  danceManagerId?: string;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  appointments?: CourseAppointmentInterface[];
  nextAppointmentDate?: Date;
  nextAppointmentDuration?: number;
  status?: CourseStatusEnum;
  pageViews?: PageViewsInterface;
  cheapestPrice?: number;
  isCheckInActive?: boolean;
}
