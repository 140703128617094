import { UserTicketCollapsedByEventAndOrderInterface } from '../../..';
import { DanceEventsDto } from '../../..';
import { UserTicketDto } from './user-ticket.dto';

export class UserTicketCollapsedByEventAndOrderResponseDto implements UserTicketCollapsedByEventAndOrderInterface {
  eventId?: string;
  eventStart?: Date;
  eventTimezone?: string;
  orderIds?: string[];
  ticketOrderIds?: string[];
  freeUserTicketIds?: string[];
  event?: DanceEventsDto;
  numberOfTickets: number;
  isCanceled: boolean;
  userTickets?: UserTicketDto[];
}
