import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Galleria} from "primeng/galleria";
import { Image } from '@platri/df-common-shared-graphql';


@Component({
  selector: 'df-shared-lib-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit, OnDestroy {
  @ViewChild('galleria') galleria: Galleria;

  @Input() images: Image[];
  @Input() mobile = false;
  @Input() maxWidth = '640px';

  imageUrls: string[] = [];

  fullscreen = false;

  showThumbnails: boolean;

  onFullScreenListener: any;

  imageUrlsActiveIndex = 0;
  imageGalleriaTouchCoordX: number;

  responsiveOptions: { breakpoint: string; numVisible: number }[] = [
    {
      breakpoint: '1024px',
      numVisible: 4,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  ngOnInit(): void {
    this.images.forEach((image) => {
      this.imageUrls.push(image.url);
    });
    this.bindDocumentListeners();
  }

  swipeStart(event: TouchEvent): void {
    this.imageGalleriaTouchCoordX = event.changedTouches[0].clientX;
  }

  swipeEnd(event: TouchEvent): void {
    const newTouchCoordX: number = event.changedTouches[0].clientX;
    const indexMin = 0;
    const indexMax = this.imageUrls.length - 1;

    if (newTouchCoordX < this.imageGalleriaTouchCoordX) {
      // Swipe left
      this.imageUrlsActiveIndex =
        this.imageUrlsActiveIndex === indexMax
          ? indexMin
          : ++this.imageUrlsActiveIndex;
    } else if (newTouchCoordX > this.imageGalleriaTouchCoordX) {
      // Swipe Right
      this.imageUrlsActiveIndex =
        this.imageUrlsActiveIndex === indexMin
          ? indexMax
          : --this.imageUrlsActiveIndex;
    }
  }

  galleriaClass(): string {
    return `custom-galleria ${this.fullscreen ? 'fullscreen' : ''}`;
  }

  onThumbnailButtonClick(): void {
    this.showThumbnails = !this.showThumbnails;
  }

  toggleFullScreen(): void {
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    } else {
      this.openPreviewFullScreen();
    }
  }

  private closePreviewFullScreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  onFullScreenChange(): void {
    this.fullscreen = !this.fullscreen;
  }

  private openPreviewFullScreen(): void {
    const elem = this.galleria.element.nativeElement.querySelector(
      '.p-galleria'
    );
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem['mozRequestFullScreen']) {
      elem['mozRequestFullScreen']();
    } /* Firefox */ else if (elem['webkitRequestFullscreen']) {
      elem['webkitRequestFullscreen']();
    } /* Chrome, Safari & Opera */ else if (elem['msRequestFullscreen']) {
      elem['msRequestFullscreen']();
    } /* IE/Edge */
  }

  bindDocumentListeners(): void {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullScreenListener);
    document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.addEventListener('msfullscreenchange', this.onFullScreenListener);
  }

  unbindDocumentListeners(): void {
    document.removeEventListener('fullscreenchange', this.onFullScreenListener);
    document.removeEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('msfullscreenchange', this.onFullScreenListener);
    this.onFullScreenListener = null;
  }

  ngOnDestroy(): void {
    this.unbindDocumentListeners();
  }
}
