<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveDescriptionData()">
    <elab-tagging-text-area [customFormGroup]="formGroup" [customFormControlName]="'description'"></elab-tagging-text-area>
    
    <div *ngIf="formGroup.get('description').hasError('maxLength')" class="w-100 flex-column align-start">
      <div id="enableMaxLengthError" class="max-length-error">
        <span>
          {{ t('USER.DESCRIPTION_ERROR_MAX_LENGTH') }}
        </span>
      </div>
    </div>
    <div *ngIf="formGroup.get('description').hasError('notEmpty')" class="w-100 flex-column align-start">
      <div id="enableNotEmptyError" class="not-empty-error">
        <span>
          {{ t('USER.DESCRIPTION_ERROR_NOT_EMPTY') }}
        </span>
      </div>
    </div>
    
    <div class="flex-row align-end-center gap-20 action-section">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="cancel()"
        [isDisabled]="isSubmitting && !formGroup.dirty"
      ></df-shared-lib-button>
      <df-shared-lib-button
        [buttonLabel]="t('GENERIC_WRAPPER.SAVE')"
        (buttonClicked)="saveDescriptionData()"
        [isDisabled]="(isSubmitting && !formGroup.dirty) || formGroup.invalid"
      ></df-shared-lib-button>
    </div>
  </form>
</ng-container>
