import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddFreeTicketsDialogComponent } from './add-free-tickets-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DfSharedButtonModule, UserSearchAutocompleteModule } from '@platri/dfx-angular-core';
import { MatInputModule } from '@angular/material/input';
import {TranslocoDirective} from "@jsverse/transloco";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";

@NgModule({
  declarations: [AddFreeTicketsDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        UserSearchAutocompleteModule,
        MatInputModule,
        DfSharedButtonModule,
        TranslocoDirective,
        MatOption,
        MatSelect
    ]
})
export class AddFreeTicketsDialogModule {}
