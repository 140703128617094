import { DanceManagerSubscriptionInterface } from './dance-manager-subscription.interface';

export interface DanceManagerSubscriptionInvoiceInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  danceManagerSubscription?: DanceManagerSubscriptionInterface;
  stripeInvoiceId?: string;
  stripeInvoicePdfDownloadUrl?: string;
}
