import { Component, Input } from '@angular/core';
import { ConversationInterface, DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER, DEFAULT_GROUP_MESSAGE_PLACEHOLDER, DEFAULT_PROFILE_PICTURE_PLACEHOLDER } from '@platri/df-common-core';

@Component({
  selector: 'df-conversation-list-entry',
  templateUrl: './conversation-list-entry.component.html',
  styleUrls: ['./conversation-list-entry.component.scss'],
})
export class ConversationListEntryComponent {
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER = DEFAULT_DANCE_MANAGER_LOGO_PICTURE_PLACEHOLDER;
  DEFAULT_GROUP_MESSAGE_PLACEHOLDER = DEFAULT_GROUP_MESSAGE_PLACEHOLDER;

  @Input() conversation: ConversationInterface;
  @Input() currentPartnerId: string;
  @Input() active: boolean;

}
