import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthStateService, DfmStateService } from '../../services';
import { danceManagerRolesWithAccessOnDm, DanceSchoolInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, getDanceManagerIdsFromTokenRolesByRoles } from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'df-select-dm-dialog',
  templateUrl: './select-dm-dialog.component.html',
  styleUrls: ['./select-dm-dialog.component.scss']
})
export class SelectDmDialogComponent  {
  protected readonly DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  selectedDanceManagerId: string;
  form: FormGroup;
  danceSchools: DanceSchoolInterface[];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private authService: AuthStateService,
    private readonly dialogRef: MatDialogRef<SelectDmDialogComponent>,
    private dfmStateService: DfmStateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      linkType: string;
    },
  ) {
    this.loadDanceManagersCurrentUser();
    this.initForm();
  }

  loadDanceManagersCurrentUser(): void {
    const danceManagerIdsWithEnoughRights = getDanceManagerIdsFromTokenRolesByRoles(this.authService.getSyncCurrentUser().roles?.danceSchool, danceManagerRolesWithAccessOnDm);
    if (danceManagerIdsWithEnoughRights.length > 0) {
      this.subscriptions.add(
        this.dfmStateService.getAsyncAvailableData().subscribe({
          next: (danceManagers) => {
            this.danceSchools = danceManagers.filter((danceManager) => danceManagerIdsWithEnoughRights.includes(danceManager.id));
          }
        })
      );
    }
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      enableContinue: false,
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public get continueIsEnabled(): AbstractControl<boolean> {
    return this.form.controls['enableContinue'];
  }

  continue(): void {
    this.dialogRef.close();
    this.router.navigate(['dance-manager', this.selectedDanceManagerId, this.data.linkType, 'create']);
  }
  
  handleRadioClick(event: any): void {
    this.form.get('enableContinue').setValue(true);
    this.selectedDanceManagerId = event.target.value;
  }
}
