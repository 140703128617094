import { Component } from '@angular/core';
import { danceManagerRolesWithAccessOnDm, DF_SEARCH_DM_AD_PICTURE, getDanceManagerIdsFromTokenRolesByRoles } from '@platri/df-common-core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStateService } from '../../services';
import { dfAppRoute, dfCreateDanceManagerRoute, dfLoginRoute } from '../../shared';

@Component({
  selector: 'df-dance-studio-overview-ad',
  templateUrl: './dance-studio-overview-ad.component.html',
  styleUrls: ['./dance-studio-overview-ad.component.scss']
})
export class DanceStudioOverviewAdComponent {
  protected readonly DF_SEARCH_DM_AD_PICTURE = DF_SEARCH_DM_AD_PICTURE;
  hasDanceSchool: boolean;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthStateService,
  ) {
    if (this.authService.getSyncCurrentUser()) {
      const danceManagerIdsWithEnoughRights = getDanceManagerIdsFromTokenRolesByRoles(this.authService.getSyncCurrentUser().roles?.danceSchool, danceManagerRolesWithAccessOnDm);
      this.hasDanceSchool = danceManagerIdsWithEnoughRights.length > 0;
    }
  }

  onButtonClicked(): void {
    if (!this.authService.getSyncCurrentUser()) {
      this.routeToLogin();
    }
    this.router.navigate(['/' + dfAppRoute, dfCreateDanceManagerRoute]);
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: dfCreateDanceManagerRoute}});
  }
}
