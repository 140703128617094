import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatBadgeIconDirective } from './mat-badge-icon.directive';

@NgModule({
  declarations: [MatBadgeIconDirective],
  imports: [CommonModule],
  exports: [MatBadgeIconDirective],
})
export class MatBadgeIconModule {
}
