import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, Subscription } from 'rxjs';
import { AuthStateService, DanceSchoolStateService, TaggingService } from '../../services';
import { MessageHttpService, PartnerService } from '../messages';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import Quill from 'quill';
import Counter from '../../helpers/quill-counter-module';
import { TranslocoService } from '@jsverse/transloco';

// import { Mention, MentionBlot } from 'quill-mention';

// Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });
Quill.register('modules/counter', Counter);

@Component({
  selector: 'df-shared-lib-quill-mention-messenger',
  templateUrl: './df-quill-mention-messenger.component.html',
  styleUrls: ['./df-quill-mention-messenger.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class DfQuillMentionMessengerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent | undefined;

  //quill stuff
  // @ViewChild(QuillEditorComponent, { static: false })
  // editor: QuillEditorComponent;

  @Input() resetMessengerField = false;
  @Input() setEmojis: string;
  @Input() elementId: string;
  @Input() placeholder: string;
  @Input() maxLength = 9999;
  @Input() minLength = 0;

  @Output() typedMessengerContent = new EventEmitter<string>();
  @Output() keyEnterPressed = new EventEmitter<KeyboardEvent>();
  
  userOptions: any[] = [];
  searchInputChangeSubject = new Subject<string>();
  currentSearchTerm: string;
  currentPartnerId: string;
  
  subscriptions: Subscription = new Subscription();

  messengerContent: string;

  mentionConfig = {
    mentionListClass: 'mention-menu',
    listItemClass: 'mention-item',
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@'],
    showDenotationChar: true,
    spaceAfterInsert: true,
    onSelect: (item): void => {
      const editor = this.editor.quillEditor;
      //delete entered search term:
      editor.deleteText(
        editor.getSelection().index - this.currentSearchTerm.length,
        this.currentSearchTerm.length,
        'user' // necessary because quill-mention triggers changes as 'api' instead of 'user'
      );
      //insert selected item value as plain text:
      editor.insertText(editor.getSelection().index, item.value + ' ', 'user');
      //set text cursor after inserted item:
      editor.setSelection(editor.getSelection().index + item.value.length, 'user');
    },
    source: (searchTerm, renderList): void => {
      this.searchInputChangeSubject.next(searchTerm);
      if (searchTerm.length === 0) {
        renderList(this.userOptions, searchTerm);
      } else {
        const matches = [];

        this.userOptions.forEach((entry) => {
          if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
            matches.push(entry);
          }
        });
        const translatedTypes = {
          studio: this.translocoService.translate('GENERIC_WRAPPER.DANCE_MANAGER'),
          user: this.translocoService.translate('GENERIC_WRAPPER.USER')
        };
        renderList(matches, translatedTypes);
      }
    },
    renderItem: function renderItem(item, translatedTypes): string {
      const icon = item.imageUrl ? '<img class="material-icons-round" style="border-radius:25px" src="' + item.imageUrl + '">' : '<span class="material-icons-round">account_circle</span>';

      const type = item.isDanceSchool ? translatedTypes['studio'] : translatedTypes['user'];
      const text = '<div style="display:flex; flex-direction:column;">' + '<span>' + item.info + '</span>' + '<span style="color:#959595; font-size:11px">' + type + '</span>' + '</div>';
      return icon + text;
    }
  };

  counterConfig = {
    container: '#counter',
    unit: 'char',
    limit: this.maxLength
  };
  
  constructor(private readonly danceSchoolService: DanceSchoolStateService, private readonly messageHttpService: MessageHttpService, private authService: AuthStateService, private readonly taggingService: TaggingService, private partnerService: PartnerService, private readonly translocoService: TranslocoService) {}
  
  removeColor = function (node, delta) {
    delta.forEach((e) => {
      if (e.attributes) {
        e.attributes.color = '';
        e.attributes.background = '';
      }
    });
    return delta;
  };

  /**
   * Prevents, that a user can drag and drop images into the editor
   */
  dragAndDropImageHandler = function (imageDataUrl, type, imageData) {};

  /**
   * Prevents, that a user can copy paste images into the editor
   */
  copyPasteImageHandler = function (node, delta) {
    const deltaImport = Quill.import('delta');
    return new deltaImport().insert('');
  };

  formats = [];

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ align: [] }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      ['clean'] // remove formatting button
    ],
    clipboard: {
      matchVisual: false,
      //this option is removed in later versions,
      //not sure if that will reintroduce empty line above lists when we update
      matchers: [
        [Node.TEXT_NODE, this.removeColor],
        ['IMG', this.copyPasteImageHandler],
        ['PICTURE', this.copyPasteImageHandler]
      ]
    },
    imageDropAndPaste: {
      // add an custom image handler
      handler: this.dragAndDropImageHandler
    }
  };

  // end quill stuff

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resetMessengerField) {
      this.editor.quillEditor.setContents([]);
      this.setEmojis = null;
    }
    if (this.setEmojis) {
      const cursorPosition = this.editor.quillEditor.getLength();
      this.editor.quillEditor.setSelection(cursorPosition);
      const emoji = changes?.setEmojis?.currentValue;
      this.editor.quillEditor.insertText(this.editor.quillEditor.getSelection().index, emoji);
    }
  }

  onContentChanged(event): void {
    this.messengerContent = event.text;
    this.typedMessengerContent.emit(this.messengerContent);
  }

  setFocus(event): void {
    event.focus();
  }

  onKeyEnterPressed(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.keyEnterPressed.emit(event);
    }
  }

  ngOnInit(): void {
    // Quill.register('modules/counter', Counter);
    // Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
    this.subscribeToSearch();
    this.setCurrentPartner();
    this.modules['mention'] = this.mentionConfig;

    // const Block = Quill.import('blots/block');
    // Block.tagName = 'DIV';
    // Quill.register(Block, true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  //**mentions-stuff**
  setCurrentPartner(): void {
    this.currentPartnerId = this.partnerService.getCurrentPartnerId(this.authService.getSyncCurrentUser(), this.danceSchoolService.getSyncCurrentDanceSchool());
  }

  private subscribeToSearch(): void {
    this.subscriptions.add(
      this.searchInputChangeSubject.subscribe((inputText: string) => {
        if (inputText) {
          this.currentSearchTerm = inputText;
          this.subscriptions.add(
            this.messageHttpService.searchPartners(inputText).subscribe({
              next: (result) => {
                this.userOptions = this.taggingService.mapToOptions(result.filter((obj) => obj.id !== this.currentPartnerId));
              }
            })
          );
        } else {
          this.userOptions = [];
        }
      })
    );
  }
  //**end mentions-stuff**
}
