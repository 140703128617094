import { CurrencyInterface } from '../currency.interface';
import { PassTargetInterface } from './pass-target.interface';
import { PassStatusEnum } from '../../enums';
import { PassValidityInterface } from './pass-validity.interface';

export interface PassSaleSelectionPageInterface {
  id?: string;
  name?: string;
  usageCount?: number;
  availableQuantity?: number;
  price?: number;
  currency?: CurrencyInterface;
  description?: string;
  passTargets?: PassTargetInterface[];
  status?: PassStatusEnum;
  validity?: PassValidityInterface;
  trial: boolean;
}
