import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { UserNotificationItemComponent } from './user-notification-item.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PipesModule } from '../../pipes';
import { ImageFallbackDirectiveModule } from '../../directives';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [UserNotificationItemComponent],
  exports: [UserNotificationItemComponent],
    imports: [
      CommonModule, 
      RouterLinkActive, 
      RouterLink, 
      PipesModule,
      ImageFallbackDirectiveModule,
      TranslocoDirective,
    ],
  providers: [TitleCasePipe]
})
export class UserNotificationItemModule {}
