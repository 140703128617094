import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoordinatesInterface } from '@platri/df-common-core';

@Injectable({
  providedIn: 'platform',
})
export class CoordinatesStateService {
  private currentCoordinates = new BehaviorSubject<CoordinatesInterface>(null);

  getAsyncCurrentCoordinates(): Observable<CoordinatesInterface> {
    return this.currentCoordinates.asObservable();
  }

  getSyncCurrentCoordinates(): CoordinatesInterface {
    return this.currentCoordinates.getValue();
  }

  sendCoordinates(data: CoordinatesInterface): void {
    this.currentCoordinates.next(data);
  }

  clear(): void {
    this.currentCoordinates.next(null);
  }
}
