<ng-container *transloco="let t">
  <div class="full-view">
    <div class="flex-container">
      <div class="loading-box" *ngIf="isWaitingForMagicLinkLoginResponse">
        <mat-spinner></mat-spinner>
      </div>
      
      <div *ngIf="!magicLinkLoginFailedNoConnection && !isWaitingForMagicLinkLoginResponse && hasSuccessfullyMagicLinkLogin">
        {{ t('GENERIC_WRAPPER.MAGIC_LINK_LOGIN_SUCCESSFUL') }}
      </div>
      
      <div *ngIf="!magicLinkLoginFailedNoConnection && !isWaitingForMagicLinkLoginResponse && !hasSuccessfullyMagicLinkLogin">
        {{ t('GENERIC_WRAPPER.SOMETHING_WENT_WRONG') }}
      </div>
      
      <div *ngIf="magicLinkLoginFailedNoConnection">
        {{ t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED') }}
      </div>
    </div>
  </div>
</ng-container>
