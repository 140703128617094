<ng-container *transloco="let t">
  <div class="mt2 flex-column" style="gap: 10px;">
    <div class="flex-row-wrap" style="gap: 20px;">
      <mat-chip-listbox>
        <div class="flex-column">
          <ng-container *ngIf="amenities">
            <div class="flex-row-wrap">
              <mat-chip-option
                style="background-color: #FFFFFF; border: 1px solid #A8AFBF;"
                *ngFor="let amenity of amenities"
                [removable]="false"
              >
                <mat-icon [ngClass]="amenity.iconKey | amenityIcon: true" fontSet="material-icons-round">{{ amenity.iconKey }}</mat-icon>
                <span class="ml1">
                  {{ t('GENERIC_WRAPPER.AMENITIES.KEYS.' + amenity.iconKey.toUpperCase()) }}
                </span>
              </mat-chip-option>
            </div>
          </ng-container>
        </div>
      </mat-chip-listbox>
    </div>
  </div>
</ng-container>
