import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ChatDataInterface } from '../../mock-data';
import { BehaviorSubject } from 'rxjs';
import { ChatStateService } from '../../services';
import { getTimeHelper } from '../../helpers';
import { MessagePartnerInterface } from '@platri/df-common-core';
import { MessageDto, MessageReactionEnum, MessageStatusEnum, MessageTypeEnum } from '@platri/df-common-chat-plug-play';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input({required: true}) chatData: ChatDataInterface[];
  @Input({required: true}) isSendingMessage: BehaviorSubject<boolean>;
  @Input({required: true}) senderId: string;
  @Input({required: true}) recipientId: string;
  @Input({required: true}) recipientUser: MessagePartnerInterface;
  @Input({required: true}) allMessagesLoaded: boolean;
  @Input({required: true}) isLoadingMoreMessages: boolean;
  
  @Output() chatMessageSentEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() chatMessageReadEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() chatMessageReactedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() chatMessageDeletedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() chatScrolledToTopEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() chatUploadedFileEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteChatEmitter: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('chatInput') chatInput: ElementRef;
  @ViewChild('chatContent') chatContent: ElementRef;
  @ViewChildren('observableMessages') observableMessages: QueryList<ElementRef>;
  
  private observers: IntersectionObserver[] = [];
  private alreadyVisible = new Set<Element>();
  private monthNames = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
  
  isInReactionMenu = false;
  
  showMenu = false;

  isMobile = false;
  isDmPreview = false;
  danceManagerId: string | null;
  
  selectedMessageForReply: MessageDto | null;
  
  unreadLabelRendered = false;
  unreadMessageId = null;
  unreadCounter = 0;
  showUnreadCounter = true;
  unreadMessageSeenAlready = false;
  
  uploadedFile: any;
  
  scrollToMessage = false;
  scrollToMessageId : number | null;
  
  constructor(
    private cdr: ChangeDetectorRef,
    private chatStateService: ChatStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.isDmPreview = this.router.url.includes('/dance-manager/');
    this.danceManagerId = this.getDanceManagerId();
    this.checkResponsiveView();
  }

  ngAfterViewInit(): void {
    this.isSendingMessage.subscribe(isSendingMessage => {
      if (!isSendingMessage) {
        this.chatInput.nativeElement.value = '';
        this.selectedMessageForReply = null;
        this.adjustTextareaHeight();
        setTimeout(() => this.chatInput.nativeElement.focus(), 1);
      }
    });
    
    this.observableMessages.changes.subscribe((res) => {
      this.setupObservers();
    });
    //this.setupObservers();
    if (!this.unreadMessageId) {
      // @ts-ignore
      this.unreadMessageId = -1;
    }
    
  }

  ngOnDestroy(): void {
    this.alreadyVisible.clear();
    this.observers.forEach(observer => observer.disconnect());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.scrollToMessage && !!changes['chatData']) {
      setTimeout(() => {
        this.scrollMessageIntoView(this.scrollToMessageId);
      }, 1);
    }
  }

  @HostListener('window:resize', ['$event'])
  checkResponsiveView(): void {
    this.isMobile = window.matchMedia('(max-width: 700px)').matches;
  }

  getDanceManagerId(): string | null {
    const segments = this.router.url.split('/');
    const danceManagerIndex = segments.indexOf('dance-manager');
    if (danceManagerIndex !== -1 && segments[danceManagerIndex + 1]) {
      return segments[danceManagerIndex + 1];
    }
    return null;
  }
  
  private setupObservers(): void {
    this.observers.forEach(observer => observer.disconnect());
    this.observers = [];
    
    setTimeout(() => {
    this.observableMessages.forEach((item, index) => {
      if (item.nativeElement.classList[1] === "message-received-unread") {
        this.observeItem(item);
      }
    });
    }, 200);
  }

  private observeItem(item: ElementRef): void {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.alreadyVisible.has(entry.target)) {
          this.chatMessageReadEmitter.emit(+(entry.target.id.split('message-')[1]));
          this.onItemVisible(entry.target);
          this.alreadyVisible.add(entry.target); // Markiere das Element als sichtbar
          if (+(entry.target.id.split('message-')[1]) === this.unreadMessageId) {
            setTimeout(() => {
              // @ts-ignore
              this.unreadMessageId = -1;
              this.showUnreadCounter = false;
              this.unreadMessageSeenAlready = true;
            }, 10000);
          }
        }
      });
    }, { threshold: 0.1 });

    observer.observe(item.nativeElement);
    this.observers.push(observer);
  }

  private onItemVisible(target: Element): void {
    target.classList.add('visible');
  }
  
  onInput(): void {
    this.adjustTextareaHeight();
  }

  private adjustTextareaHeight(): void {
    const textarea: HTMLTextAreaElement = this.chatInput.nativeElement;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${(textarea.scrollHeight + 2)}px`; // Set the height to scroll height
  }
  
  formatDate(datum: Date): string {
    const heute = new Date();
    const gestern = new Date(heute);
    gestern.setDate(gestern.getDate() - 1);

    // Datum zurücksetzen, um nur das Datum ohne Zeit zu vergleichen
    heute.setHours(0, 0, 0, 0);
    datum.setHours(0, 0, 0, 0);
    gestern.setHours(0, 0, 0, 0);
    
    
    if (datum.getTime() === heute.getTime()) {
      return this.translocoService.translate('GENERIC_WRAPPER.TODAY');
    } else if (datum.getTime() === gestern.getTime()) {
      return this.translocoService.translate('GENERIC_WRAPPER.YESTERDAY');
    } else {
      const month = this.translocoService.translate('GENERIC_WRAPPER.MONTHS.' + this.monthNames[datum.getMonth()]);
      return `${datum.getDate()} ${month} ${datum.getFullYear()}`;
    }
  }
  
  sendMessage(event: any, chatInput: HTMLTextAreaElement): void {
    if (!this.isSendingMessage.getValue() && chatInput.value) {
      this.isSendingMessage.next(true);
      event.preventDefault();
      chatInput.value = chatInput.value.replace(/\n/g, '<br>');
      this.chatMessageSentEmitter.emit({value: chatInput.value, repliedToMessageId: this.selectedMessageForReply ? this.selectedMessageForReply.id : null});
    }
  }

  trackByChatMessage(index: number, chatMessage: MessageDto): number {
    return chatMessage.id;
  }
  
  reactToMessage(chatMessage: MessageDto, messageReaction: MessageReactionEnum): void {
    chatMessage.recipientReaction = messageReaction;
    this.chatMessageReactedEmitter.emit({messageId: chatMessage.id, messageReaction});
  }

  replyToMessage(chatMessage: MessageDto | null): void {
    this.selectedMessageForReply = chatMessage;
  }
  
  scrollMessageIntoViewViaUnreadCounter(id: number | null): void {
    this.showUnreadCounter = false;
    this.scrollMessageIntoView(id);
  }

  scrollMessageIntoView(id: number | null): void {
    // @ts-ignore
    const element = document.getElementById('message-' + id);
    if (element) {
      this.scrollToMessageId = null;
      this.scrollToMessage = false;
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });

      element.classList.add('highlighted');
      
      
      setTimeout(() => {
        element.classList.remove('highlighted');
      }, 3000);
    } else {
      this.chatScrolledToTopEmitter.emit(true);
      this.scrollToMessage = true;
      this.scrollToMessageId = id!;
    //  this.cdr.markForCheck();
    //  this.cdr.detectChanges();
    }
  }

  deleteMessage(chatMessage: MessageDto): void {
    this.chatMessageDeletedEmitter.emit(chatMessage);
  }
  
  openReactionMenu(): void {
    this.isInReactionMenu = true;
  }

  onOtherMessageMouseLeave(): void {
    this.showMenu = false;
    this.isInReactionMenu = false;
  }

  onChatScroll(event: any): void {
    const element = event.target;
    const scrollPosition = element.scrollTop;
    const threshold = (element.scrollHeight - element.offsetHeight) * 0.001; // 20% des Scroll-Containers
    if (scrollPosition <= ((element.scrollHeight - element.offsetHeight) * -1) + threshold) {
      this.chatScrolledToTopEmitter.emit(true);
    }
  }
  
  deleteChat(): void {
    this.deleteChatEmitter.emit(true);
  }

  checkIsRendered(message: any): boolean {
    if (!this.unreadMessageId || this.unreadMessageId === message.id) {
      this.unreadLabelRendered = true;
      this.unreadMessageId = message.id;
      this.unreadCounter = 1;
      return true;
    } else if (!this.unreadMessageSeenAlready && this.unreadMessageId !== -1) {
      this.unreadCounter++;
      return false;
    }
    return false;
  }

  onFileSelected(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.uploadedFile = fileList[0];
    }
    this.chatUploadedFileEmitter.emit(this.uploadedFile);
  }
  
  openFile(url: string): void {
    // @ts-ignore
    window.open(url, '_blank').focus();
  }
  
  routeToChatOverview(): void {
    if (this.isDmPreview) {
      this.router.navigate([`/dance-manager/${this.danceManagerId}/messages`], { queryParams: { technicalUserId: this.danceManagerId }, relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['/messages']);
    }
  }

  protected readonly MessageStatusEnum = MessageStatusEnum;
  protected readonly MessageReactionEnum = MessageReactionEnum;
  protected readonly MessageTypeEnum = MessageTypeEnum;
  protected readonly getTimeHelper = getTimeHelper;
}
