import { FeesPaidByEnum, PassInterface } from '../../..';
import { CreatePassTargetRequestDto } from './create-pass-target-request.dto';
import { PassValidityDto } from './pass-validity.dto';

export class CreatePassRequestDto implements PassInterface {
  name: string;
  description: string;
  price: number;
  taxPercentage: number;
  quantity: number;
  feesPaidBy: FeesPaidByEnum;
  passTargets: CreatePassTargetRequestDto[];
  danceManagerId: string;
  usageCount: number;
  validity: PassValidityDto;
  trial: boolean;

  constructor(args: CreatePassRequestDto) {
    if (args) {
      this.name = args.name;
      this.description = args.description;
      this.price = args.price;
      this.taxPercentage = args.taxPercentage;
      this.quantity = args.quantity;
      this.feesPaidBy = args.feesPaidBy;
      this.passTargets = args.passTargets;
      this.danceManagerId = args.danceManagerId;
      this.usageCount = args.usageCount;
      this.validity = args.validity;
      this.trial = args.trial;
    }
  }
}
