import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService, UserProfileStateService } from '@platri/dfx-angular-core';
import { DEFAULT_PROFILE_PICTURE_PLACEHOLDER, UsersInterface } from '@platri/df-common-core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-complete-profile-dialog',
  templateUrl: './complete-profile-dialog.component.html',
  styleUrls: ['./complete-profile-dialog.component.scss']
})
export class CompleteProfileDialogComponent implements OnInit {

  currentProfileImage: string;
  completionRate: number;
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;

  currentUser: UsersInterface;
  subscription: Subscription = new Subscription();

  constructor(
    private authService: AuthStateService,
    private userProfileService: UserProfileStateService,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      completionRate: number;
    },
  ) {
    this.completionRate = 100 / 12 * this.data.completionRate;
  }

  ngOnInit(): void {
    this.listenOnAuthChanges();
    this.getCurrentUserProfileImage();
  }

  listenOnAuthChanges(): void  {
    this.subscription.add(
      this.authService.getAsyncCurrentUser().subscribe((user) => {
        if (user) {
          this.currentUser = user;
        }
      })
    );
  }

  changeShowCompleteProfileDialog(): void {
    this.currentUser.showCompleteProfileDialog = !this.currentUser.showCompleteProfileDialog;
    this.userProfileService.updateShowCompleteProfileDialog({
      showCompleteProfileDialog: this.currentUser.showCompleteProfileDialog
    });
  }

  getCurrentUserProfileImage(): void {
    const imageUrl = this.userProfileService.getSyncCurrentUserProfile().imageUrl;
    this.currentProfileImage = imageUrl !== null && imageUrl !== '' ? imageUrl : null;
  }

  routeToProfileSettings(): void {
    this.router.navigate(['/settings'], {fragment: 'profile'});
  }

}
