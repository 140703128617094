import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerHeaderImageComponent } from './dance-manager-header-image.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ImageUploadCropperModule } from '@platri/dfx-angular-core';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [DanceManagerHeaderImageComponent],
  exports: [DanceManagerHeaderImageComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, TranslocoModule, ImageUploadCropperModule,],
})
export class DanceManagerHeaderImageModule {}
