import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogInterface,
  DanceEventsInterface,
  DanceEventTimePhaseEnum,
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER,
  UpdateEventStatusErrorEnum,
  UserAppLangEnum
} from '@platri/df-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DanceEventHttpService, DanceEventStateService } from '../../../services';
import { ConfirmationDialogComponent } from '../../confirmation-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DuplicateDanceEventDialogComponent } from '../../duplicate-dance-event-dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-event-list-table',
  templateUrl: './dance-event-list-table.component.html',
  styleUrls: ['./dance-event-list-table.component.scss'],
})
export class DanceEventListTableComponent implements OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() danceEventListTab: DanceEventTimePhaseEnum;
  @Input() danceEvents: DanceEventsInterface[] = [];

  @Output() danceEventPublished = new EventEmitter<void>();
  @Output() danceEventDeleted = new EventEmitter<void>();
  @Output() danceEventDuplicated = new EventEmitter<void>();

  subscriptions: Subscription = new Subscription();
  danceEventListTabEnum = DanceEventTimePhaseEnum;
  displayedColumns = ['name', 'from', 'to', 'danceStyles', 'pageViews', 'options'];
  dataSource: MatTableDataSource<DanceEventsInterface>;
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  subscription = new Subscription();
  currentLang: UserAppLangEnum;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly translocoService: TranslocoService,
    private readonly danceEventHttpService: DanceEventHttpService,
    private readonly danceEventService: DanceEventStateService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly matSnackBar: MatSnackBar
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.danceEvents) {
      this.updateDataSource();
    }
  }

  updateDataSource(): void {
    this.dataSource = new MatTableDataSource(this.danceEvents);
    this.dataSource.sort = this.sort;
  }

  deleteDanceEvent(danceEvent: DanceEventsInterface): void {
    const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_DIALOG.TITLE'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      text: this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_DIALOG.DESCRIPTION')
    };
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.danceEventHttpService.deleteById(danceEvent.id).subscribe({
            next: () => {
              this.danceEventDeleted.emit();
            }, 
            error: (err) => {
            if (err.error.error === 'dance_event_tickets_sold') {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_ERROR_TICKET_SOLD'));
            } else {
              this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.DELETE_EVENT_ERROR'));
            }
          }
          });
        }
      });
  }

  editDanceEvent(danceEvent: DanceEventsInterface): void {
    const route = `preview/${danceEvent.id}`;
    this.router.navigate([route], { relativeTo: this.route });
  }

  duplicateDanceEvent(danceEvent: DanceEventsInterface): void {
    const dialogRef = this.matDialog.open(DuplicateDanceEventDialogComponent, {
      data: danceEvent,
      panelClass: 'br-20',
      maxHeight: '100vh',
      maxWidth: '390px',
      minWidth: '390px',
      width: '25vw',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.danceEventService.duplicateDanceEventById(danceEvent.id, result.name, result.startDate, result.duration, this.danceEventListTab, result.duplicateTickets);
        this.danceEventDuplicated.emit();
        this.danceEventService.clear();
      }
    });
  }
  
  navigateToGuestList(danceEvent: DanceEventsInterface): void {
    const route = `preview/${danceEvent.id}`;
    this.router.navigate([route], { relativeTo: this.route, fragment: 'guestlist' });
  }

  publishDanceEvent(danceEvent: DanceEventsInterface): void {
   const confirmationDialogData: ConfirmationDialogInterface = {
      title: this.translocoService.translate('GENERIC_WRAPPER.PUBLISH_EVENT'),
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: '',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.PUBLISH'),
      text: this.translocoService.translate('GENERIC_WRAPPER.PUBLISH_DIALOG_TEXT')
    };
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: confirmationDialogData,
        panelClass: 'dialog_radius'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.danceEventHttpService.updateStatusDanceEventById(danceEvent.id!, true).subscribe({
            next: () => {
              this.danceEventPublished.emit();
            },
            error: (err) => {
              if (err.error.error) {
                this.handlePublishDanceEventError(err.error.error);
              }
              throw err;
            }
          });
        }
      });
  }

  handlePublishDanceEventError(error: UpdateEventStatusErrorEnum): void {
    switch (error) {
      case UpdateEventStatusErrorEnum.UPDATE_STATUS_EVENT_FAILED_DATE_PAST:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_PUBLISH_FAILED_DATE_PAST'));
        break;
      case UpdateEventStatusErrorEnum.UPDATE_STATUS_EVENT_FAILED_HAS_TICKETS:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_PUBLISH_FAILED_HAS_TICKETS'));
        break;
      case UpdateEventStatusErrorEnum.UPDATE_STATUS_EVENT_FAILED:
        this.matSnackBar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_PUBLISH_EVENT_FAILED'));
        break;
      default:
        break;
    }
  }
  
}
