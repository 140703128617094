import { AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogInterface, danceManagerUserHasRoles, DanceManagerUserRoleEnum, DanceSchoolInterface } from '@platri/df-common-core';
import { forkJoin, map, Observable, of, Subscription, tap } from 'rxjs';
import { AuthStateService, CanComponentDeactivate, ConfirmationDialogComponent, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService } from '@platri/dfx-angular-core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DfmGeneralSettingsFormInterface } from './dfm-general-settings-form.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GenericEditFormComponent } from '@platri/elab-angular-core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dfm-general-settings',
  templateUrl: './dfm-general-settings.component.html',
  styleUrls: ['./dfm-general-settings.component.scss'],
})
export class DfmGeneralSettingsComponent extends GenericEditFormComponent<DfmGeneralSettingsFormInterface> implements OnInit, AfterViewInit, AfterContentInit, CanComponentDeactivate, OnDestroy  {
  @ViewChild('unsavedChangesBar', { static: false }) unsavedChangesBar: ElementRef;
  
  loadedDanceManager: DanceSchoolInterface;
  isReset = false;
  
  hasDanceManagerLoaded = false;
  isOwnerOrAdmin = false;
  
  isVatTouched = false;
  isVatChanged = false;
  
  savedChanges = false;
  
  constructor(
    private dialog: MatDialog,
    private danceSchoolService: DanceSchoolStateService,
    private danceSchoolHttpService: DanceSchoolHttpService,
    private danceManagerHttpService: DanceManagerHttpService,
    private translocoService: TranslocoService,
    private authService: AuthStateService,
    fb: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    matSnackbar: MatSnackBar
  ) {
    super(fb, router, activatedRoute, matSnackbar);
  }

  canDeactivate(): boolean {
    if (this.isVatChanged) {
      return this.triggerAnimation();
    }
    return true;
  }

  ngAfterViewInit(): void {
    this.initializeSubscriptions();
    this.loadData();
  }

  loadData(): void {
    this.onLoadDataStart();
    this.loadedDanceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
    this.checkIfCurrentUserIsOwnerOrAdmin();
    this.patchEditFormGroup(this.loadedDanceManager);
    this.hasDanceManagerLoaded = true;
    this.onLoadDataSuccessful();
    this.isReset = false;
  }

  onSubmit(): void {
    console.log(this.editFormGroup);
  }

  createEditFormGroup(): FormGroup<DfmGeneralSettingsFormInterface> {
    return this.fb.group<DfmGeneralSettingsFormInterface>({
      vat: this.fb.control(null, [Validators.min(0), Validators.max(100)])
    });
  }

  initializeFormGroupVatSubscriptions(): void {
    if (!this.isVatTouched) {
      this.isVatTouched = true; 
      this.subscriptions.add(this.editFormGroup.controls.vat.valueChanges.subscribe(res => {
        this.isVatChanged = true;
      }));
    }
  }

  saveChanges(): void {
    const observables = [];
    if (this.isVatChanged) observables.push(this.saveVat());
    forkJoin(observables).subscribe(res => {
      this.savedChanges = true;
      this.danceSchoolHttpService.getDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id);
    }, error => {
      this.matSnackbar.open(this.translocoService.translate('GENERIC_WRAPPER.ERROR_SAVE'));
    });
  }
  saveVat(): Observable<void> {
    const vatFormControl = this.editFormGroup.controls.vat;
    if (vatFormControl.valid) {
      return this.danceSchoolHttpService.updateDanceSchoolById(this.loadedDanceManager.id, ({vat: vatFormControl.value})).pipe(
        map((res) => {
          this.danceSchoolService.sendCurrentDanceSchool(res);
          this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
            map((danceSchools) => danceSchools),
            map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
          ).subscribe();
        })
      ).pipe(map(res => {
        this.matSnackbar.open(this.translocoService.translate('GENERIC_WRAPPER.CHANGES_SAVED'));
        this.isVatChanged = false;
      }));
    }
    return of();
  }

  checkIfCurrentUserIsOwnerOrAdmin(): void {
    this.isOwnerOrAdmin = danceManagerUserHasRoles(this.authService.getSyncCurrentUser().id, this.loadedDanceManager.danceSchoolToUsers, [DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]);
  }

  delete(): void {
    const dialogData: ConfirmationDialogInterface = {
      cancelButtonColor: '',
      cancelButtonText: this.translocoService.translate('GENERIC_WRAPPER.CANCEL'),
      submitButtonColor: 'primary',
      submitButtonText: this.translocoService.translate('GENERIC_WRAPPER.DELETE'),
      title: this.translocoService.translate('DANCE_MANAGER_SETTINGS.DELETE_MANAGER_TITLE'),
      text:
        this.loadedDanceManager.danceManagerSubscriptionType === 'FREE'
          ? this.translocoService.translate('DANCE_MANAGER_SETTINGS.DELETE_MANAGER_WITHOUT_SUBSCRIPTION_TEXT')
          : this.translocoService.translate('DANCE_MANAGER_SETTINGS.DELETE_MANAGER_WITH_SUBSCRIPTION_TEXT'),
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '450px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.danceSchoolHttpService.deleteDanceManager(this.loadedDanceManager.id).pipe(
          tap(() => this.router.navigate(['/'])),
          tap(() => this.getAllDanceSchoolsByCurrentUser())
        ).subscribe({
          next: () => {
            this.authService.refreshTokens().subscribe({
              next: () => {
                this.matSnackbar.open(this.translocoService.translate('DANCE_MANAGER_SETTINGS.DANCE_MANAGER_DELETE_SUCCESS'));
              }
            });
          },
          error: (err) => {
            switch (err.error.error) {
              case 'subscription_not_cancelable':
                this.matSnackbar.open(this.translocoService.translate('DANCE_MANAGER_SETTINGS.DELETE_DM_ERROR_SUBSCRIPTION_ACTIVE'));
                break;
              case 'event_tickets_sold':
                this.matSnackbar.open(this.translocoService.translate('DANCE_MANAGER_SETTINGS.DELETE_DM_ERROR_EVENT_TICKETS_SOLD'));
                break;
              default:
                this.matSnackbar.open(this.translocoService.translate('DANCE_MANAGER_SETTINGS.DELETE_DM_ERROR'));
                break;
            }
          }
        });
      }
    });
  }

  getAllDanceSchoolsByCurrentUser(): void {
    this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
      map((danceSchools) => danceSchools),
      map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
    ).subscribe();
  }

  triggerAnimation(): boolean {
    const element = this.unsavedChangesBar.nativeElement;
    element.classList.add('shake');

    // Optionale Logik, um die Klassen nach der Animation zu entfernen
    const animationDuration = 1000; // Dauer der längsten Animation in Millisekunden
    setTimeout(() => {
      element.classList.remove('shake');
    }, animationDuration);
    return false;
  }

  resetChanges(): void {
    this.isVatChanged = false;
    this.isVatTouched = false;
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.isReset = true;
    this.loadData();
  }
}
