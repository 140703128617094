import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtmlTags',
})
export class StripHtmlTagsPipe implements PipeTransform {
  transform(
    input: string
  ): string {
    if (!input || input === '') {
      return null;
    }
    return input?.replace('<p></p>', ' ').replace(/<[^>]*>?/gm, '').replace(/\.([^\s])/g, '. $1');
  }
}
