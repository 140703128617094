<ng-container *transloco="let t">
 <div style="padding: 16px; height: 100dvh">
    <div class="flex-row mb2 align-center w-100">
      <div style="display: flex; width: 100%;">
        <mat-form-field appearance="outline" class="px2 pt2 w-100">
          <mat-select [formControl]="danceManagerControl" disableOptionCentering [compareWith]="compareById">
            <mat-option [value]="danceManager" *ngFor="let danceManager of danceManagers | orderByPipe:'name'" [routerLink]="['/dance-manager', danceManager.id]">
              {{ danceManager.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  
    <div *ngIf="danceManagerControl.value">
      <div style="padding-left: 16px;">
  
        <div class="flex-row mb3 align-center">
          <div class="w-100 flex-row">  
            <a mat-list-item [routerLink]="['/dance-manager', danceManagerControl.value?.id, 'notifications']" routerLinkActive="active-link" (click)="closeMenu.emit()">  
              <div class="flex-row" style="align-items: center;">
                <mat-icon matListItemIcon class="material-icons-outlined mr2">notifications_out</mat-icon>
                <span>{{t('GENERIC_WRAPPER.NOTIFICATIONS')}}</span>
              </div>
            </a>
          </div>
        </div>
        
        <div
          securedDanceSchoolElement
          [danceManagerId]="danceManagerControl.value?.id"
          [allowedRoles]="[DanceManagerUserRoleEnum.OWNER, DanceManagerUserRoleEnum.ADMIN]"
        >
          <div class="flex-row mb3 align-center" >
            <div class="w-100" >
              <a mat-list-item [routerLink]="['/dance-manager', danceManagerControl.value?.id, 'locations']" routerLinkActive="active-link" (click)="closeMenu.emit()">  
                <div class="flex-row" style="align-items: center;">
                  <mat-icon matListItemIcon class="material-icons-outlined mr2">home</mat-icon>
                  <span>{{t('GENERIC_WRAPPER.LOCATIONS')}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        
        <div
          securedDanceSchoolElement
          [danceManagerId]="danceManagerControl.value?.id"
          [danceManagerFeatureName]="danceManagerFeatureName.COURSES"
          [danceManagerFeatures]="danceManagerFeatures"
        >
          <div class="flex-row mb3 align-center">
            <div class="w-100">  
              <a mat-list-item [routerLink]="['/dance-manager', danceManagerControl.value?.id, 'courses']" routerLinkActive="active-link" (click)="closeMenu.emit()">
                <div class="flex-row" style="align-items: center;">
                  <mat-icon matListItemIcon class="material-icons-outlined mr2">school</mat-icon>
                  <span>{{t('GENERIC_WRAPPER.COURSES')}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
  
        <div
          securedDanceSchoolElement
          [danceManagerId]="danceManagerControl.value?.id"
          [danceManagerFeatureName]="danceManagerFeatureName.DANCE_EVENTS"
          [danceManagerFeatures]="danceManagerFeatures"
        >
          <div class="flex-row mb3 align-center">
            <div class="w-100">  
              <a mat-list-item  [routerLink]="['/dance-manager', danceManagerControl.value?.id, 'events']" routerLinkActive="active-link" (click)="closeMenu.emit()">
                <div class="flex-row" style="align-items: center;">
                  <mat-icon matListItemIcon class="material-icons-outlined mr2">celebration</mat-icon>
                  <span>{{t('GENERIC_WRAPPER.EVENTS')}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        
        <div class="flex-row mb3 align-center">
          <div class="w-100">  
            <a mat-list-item routerLinkActive="active-link" (click)="routeToChats()">
              <div class="flex-row" style="align-items: center;">
                <mat-icon matListItemIcon class="material-icons-outlined mr2" [matBadge]="unreadMessagesCount" [matBadgeHidden]="unreadMessagesCount === 0" matBadgeColor="accent">chat_bubble_outline</mat-icon>
                <span>{{t('GENERIC_WRAPPER.MESSAGES')}}</span>
              </div>
            </a>
          </div>
        </div>
  
        <div
          securedDanceSchoolElement
          [danceManagerId]="danceManagerControl.value?.id"
          [allowedRoles]="[
            DanceManagerUserRoleEnum.MANAGER,
            DanceManagerUserRoleEnum.ADMIN,
            DanceManagerUserRoleEnum.OWNER
          ]"
        >
          <div class="flex-row mb3 align-center">
            <div class="w-100">  
              <a mat-list-item [routerLink]="['/dance-manager', danceManagerControl.value?.id, dfmAdministrationRoute]" routerLinkActive="active-link" (click)="closeMenu.emit()">
                <div class="flex-row" style="align-items: center;">
                  <mat-icon matListItemIcon class="material-icons-outlined mr2">admin_panel_settings</mat-icon>
                  <span>{{t('GENERIC_WRAPPER.ADMINISTRATION')}}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
                
        <div 
        class="flex-row mb3 align-center"
          securedDanceSchoolElement
          [danceManagerId]="danceManagerControl.value?.id"
          [allowedRoles]="[DanceManagerUserRoleEnum.OWNER]"
        >
        <div class="w-100"> 
          <a
            mat-list-item
            [routerLink]="['/dance-manager', danceManagerControl.value?.id, 'payouts']"
            [routerLinkActive]="'active-link'"
            (click)="closeMenu.emit()"
          >
            <div class="flex-row" style="align-items: center;">
              <mat-icon matListItemIcon class="material-icons-outlined mr2">payments</mat-icon>
              <span>{{t('GENERIC_WRAPPER.PAYOUTS') }}</span>
            </div>
          </a>
        </div>
        </div>
  
        <div 
          class="flex-row mb3 align-center"
            securedDanceSchoolElement
            [danceManagerId]="danceManagerControl.value?.id"
            [allowedRoles]="[
              DanceManagerUserRoleEnum.MANAGER,
              DanceManagerUserRoleEnum.ADMIN,
              DanceManagerUserRoleEnum.OWNER
            ]"
          >
          <div class="w-100"> 
            <a
              mat-list-item
              [routerLink]="['/dance-manager', danceManagerControl.value?.id, 'settings']"
              [routerLinkActive]="'active-link'"
              (click)="closeMenu.emit()"
            >
              <div class="flex-row" style="align-items: center;">
                <mat-icon matListItemIcon class="material-icons-outlined mr2">settings</mat-icon>
                <span>{{t('GENERIC_WRAPPER.SETTINGS')}}</span>
              </div>
            </a>
          </div>
        </div>
  
        <div 
          class="flex-row mb3 align-center"
          securedDanceSchoolElement
          [danceManagerId]="danceManagerControl.value?.id"
          [allowedRoles]="[
            DanceManagerUserRoleEnum.MANAGER,
            DanceManagerUserRoleEnum.ADMIN,
            DanceManagerUserRoleEnum.OWNER
          ]"
        >
          <div class="w-100"> 
            <a
              mat-list-item
              [routerLink]="['/dance-manager', danceManagerControl.value?.id, dfmMarketPlaceRoute]"
              [routerLinkActive]="'active-link'"
              (click)="closeMenu.emit()"
            >
              <div class="flex-row" style="align-items: center;">
                <mat-icon matListItemIcon class="material-icons-outlined mr2">grid_view</mat-icon>
                <span>{{t('GENERIC_WRAPPER.MARKET_PLACE')}}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
