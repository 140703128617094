import { CourseRulesInterface } from './course-rules.interface';

export interface UpdateCourseInterface {
  name: string;
  description: string;
  danceStyleIds: string[];
  startDifficulty: number;
  endDifficulty: number;
  tags?: string[];
  rules?: CourseRulesInterface;
  mainImage?: string;
}
