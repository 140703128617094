import { CourseInterface, CourseStatusEnum, RadiusUnitEnum } from '../../..';
import { DanceStyleDto, ImageDto } from '../../..';
import { CourseRulesRequestDto } from './course-rules-request.dto';
import { CourseAppointmentDto } from './course-appointment.dto';
import { DanceManagerDto } from './dance-manager.dto';
import { AppointmentParticipantDto } from './appointment-participant.dto';

export class CourseDto implements CourseInterface {
  id: string;
  name: string;
  description: string;
  startDifficulty: number;
  endDifficulty: number;
  danceStyles: DanceStyleDto[];
  tags?: string[];
  danceManagerId: string;
  rules?: CourseRulesRequestDto;
  mainImage?: ImageDto;
  distance?: number;
  radiusUnit?: RadiusUnitEnum;
  appointments?: CourseAppointmentDto[];
  nextAppointmentDate?: Date;
  nextAppointmentDuration?: number;
  nextAppointmentCity?: number;
  danceManager?: DanceManagerDto;
  status: CourseStatusEnum;
  cheapestPrice?: number;
  participants: AppointmentParticipantDto[];
  isCheckInActive: boolean;
}
