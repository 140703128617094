<ng-container *transloco="let t">
  <ng-container>
    <div class="align-center flex-column content-container w-100">
      <div class="flex-column align-center" style="height: 100px; gap: 8px;">
        <div class="align-center">
          <span class="title">{{ t('GENERIC_WRAPPER.UNAVAILABLE_FOR_WEB.TITLE') }}</span>
        </div>
        <div class="align-center">
          <span class="description">{{ t('GENERIC_WRAPPER.UNAVAILABLE_FOR_WEB.TEXT') }}</span>
        </div>
      </div>
      
      <div class="align-center" style="height: 40px; gap: 12px;">
        <div style="flex: 1 1 100%;">
          <a href="https://play.google.com/store/apps/details?id=com.danceflavors_app" target="_blank">
            <img src="assets/svg/google-play-store.svg" alt="" width="100%" />
          </a>
        </div>
        <div style="flex: 1 1 100%;">
          <a href="https://apps.apple.com/app/danceflavors/id1583746248" target="_blank">
            <img src="assets/svg/apple-store.svg" alt="" width="100%" />
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
