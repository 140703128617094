<div class="flex-column gap-10 align-space-between">
  <span class="align-start-center">
    <img
      class="profilePicture hide-on-mobile"
      [src]="
        partner.imageUrl && partner.imageUrl !== '' ? partner.imageUrl :
        (partner.id.includes('dance-school-')
          ? 'assets/images/studio_logo.png'
          : DEFAULT_PROFILE_PICTURE_PLACEHOLDER)
      "
      [alt]="'PROFILE_PICTURE'"
    />
    <strong class="username" (click)="addToPartnerWithTrue(partner)"
      >{{ partner.username }}
    </strong>
  </span>
  <span class="align-end-center">
    <mat-checkbox
      [(ngModel)]="hasToPartner"
      (change)="onAddToPartner(partner, $event.checked)"
    ></mat-checkbox>
  </span>
</div>
