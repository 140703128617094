import { Component, Input } from '@angular/core';
import {dfmAdministrationRoute} from "@platri/dfx-angular-core";

@Component({
  selector: 'dfm-sidenav-administration',
  templateUrl: './dfm-sidenav-administration.component.html',
  styleUrls: ['./dfm-sidenav-administration.component.scss'],
})
export class DfmSidenavAdministrationComponent {

  @Input() isExpanded: boolean;

    protected readonly dfmAdministrationRoute = dfmAdministrationRoute;
}
