import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@platri/dfx-angular-core';
import { FormsModule } from '@angular/forms';
import { EventTicketListSummaryComponent } from './event-ticket-list-summary.component';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [EventTicketListSummaryComponent],
  exports: [EventTicketListSummaryComponent],
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        MatIconModule,
        MatDividerModule,
        TranslocoDirective,
    ],
})
export class EventTicketListSummaryModule {}
