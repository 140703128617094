import { Component, Input } from '@angular/core';
import { CourseDto, DEFAULT_COURSE_PICTURE_PLACEHOLDER, UserAppLangEnum } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-search-courses-list-item',
  templateUrl: './search-courses-list-item.component.html',
  styleUrls: ['./search-courses-list-item.component.scss']
})
export class SearchCoursesListItemComponent {
  @Input() course: CourseDto;
  
  currentLang: UserAppLangEnum;
  
  constructor(
    private translocoService: TranslocoService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  protected readonly DEFAULT_COURSE_PICTURE_PLACEHOLDER = DEFAULT_COURSE_PICTURE_PLACEHOLDER;
}
