<ng-container *transloco="let t">
  <form [formGroup]="formGroup" (ngSubmit)="saveSocialLink()">
      <div class="flex-row flex-container" style="gap: 25px;">
          <mat-form-field appearance="outline">
              <mat-select formControlName="type" [placeholder]="t('SELECT')" disableOptionCentering (selectionChange)="updateUrlControlValidators()" required>
                  <ng-container *ngFor="let socialLink of availableDanceSchoolSocialLinkTypeEnum | keyvalue">
                      <mat-option [value]="socialLink.value">
                          {{ socialLink.value | titlecase }}
                      </mat-option>
                  </ng-container>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100" appearance="outline">
            <input size="500" formControlName="url" autocomplete="off" matInput id="contact-url-mat-input" [placeholder]="'Url'"/>
            <mat-error *ngIf="urlControl.errors?.invalidUrl">
              {{t('DANCE_MANAGER_ADMINISTRATION.CONTACT.INVALID_URL')}}
            </mat-error>
          </mat-form-field>
          <div class="align-start">
              <df-shared-lib-button
                  [height]="'60px'"
                  [buttonLabel]="t('ADD')"
                  [materialButtonStyleType]="'flat'"
                  (buttonClicked)="saveSocialLink()"
                  [isDisabled]="formGroup.invalid">
              </df-shared-lib-button>
          </div>
      </div>
  </form>
</ng-container>
