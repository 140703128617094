<ng-container *transloco="let t">
  <div *ngIf="followUser && followUser.user" class="follower-card-container align-space-between gap-10">
    <div class="follow-card">
      <img *ngIf="followUser.user.imageUrl; else userNoImage" class="profilePicture circle" src="{{ followUser.user.imageUrl }}" alt="profilePicture" routerLink="/@{{ followUser.user.username }}"/>
      <div
        class="pt-10-gt-xs flex-column-center-center username-container"
        routerLink="/@{{ followUser.user.username }}"
        style="cursor: pointer"
      >
        <span><strong>{{ followUser.user.username }}</strong></span>
      </div>
      <div
        *ngIf="followUser.user.firstName && followUser.user.lastName"
        class="flex-column-center-center name-container hide-on-mobile"
        routerLink="/@{{ followUser.user.username }}"
        style="cursor: pointer">
        <span>{{ followUser.user.firstName + ' ' + followUser.user.lastName }}</span>
      </div>
    </div>
    <div class="follow-card">
      <button
        *ngIf="!(this.currentUser.id === followUser.user.id)"
        mat-raised-button
        class="follow-button"
        [ngClass]="localFollowStatus ? 'outline' : ''"
        color="primary"
        (click)="follow()"
      >
      <span [ngClass]="localFollowStatus ? 'follow' : ''">
        {{
          localFollowStatus
            ? (t('GENERIC_WRAPPER.FOLLOWING'))
            : (t('GENERIC_WRAPPER.FOLLOW'))
        }}
      </span>
        <span
          *ngIf="localFollowStatus"
          [ngClass]="localFollowStatus ? 'unfollow' : ''"
        >
        {{ t('GENERIC_WRAPPER.UNFOLLOW') }}
      </span>
      </button>
      <button
        *ngIf="this.currentUser.id === followUser.user.id"
        mat-raised-button
        class="follow-button outline"
        style="cursor: default"
        color="primary"
      >
        <span> You </span>
      </button>
    </div>
  </div>
  
  <div *ngIf="followUser && followUser.danceSchool" class="follower-card-container align-space-between gap-10">
    <div class="follow-card">
      <img
        *ngIf="followUser.danceSchool.imageUrl; else danceManagerNoImage"
        class="profilePicture circle"
        src="{{ followUser.danceSchool.imageUrl }}"
        alt="profilePicture"
        routerLink="/@{{ followUser.danceSchool.urlName }}"
        style="cursor: pointer"
      />
      <div
        class="pt-10-gt-xs flex-column-center-center username-container"
        routerLink="/@{{ followUser.danceSchool.urlName }}"
        style="cursor: pointer"
      >
        <span><strong>{{ followUser.danceSchool.name }}</strong></span>
      </div>
    </div>
    <div class="follow-card">
      <button
        mat-raised-button
        class="follow-button"
        [ngClass]="localFollowStatus ? 'outline' : ''"
        color="primary"
        (click)="follow()"
      >
      <span [ngClass]="localFollowStatus ? 'follow' : ''">
        {{
          localFollowStatus
            ? (t('GENERIC_WRAPPER.FOLLOWING'))
            : (t('GENERIC_WRAPPER.FOLLOW'))
        }}
      </span>
        <span
          *ngIf="localFollowStatus"
          [ngClass]="localFollowStatus ? 'unfollow' : ''"
        >
        {{ t('GENERIC_WRAPPER.UNFOLLOW') }}
      </span>
      </button>
    </div>
  </div>
  
  
  <ng-template #userNoImage>
    <div
      *ngIf="!followUser.user.imageUrl"
      class="flex-column-center-center profilePicture circle"
      routerLink="/@{{ followUser.user.username }}"
    ><mat-icon class="placeholder-icon">account_circle</mat-icon></div>
  </ng-template>
  
  <ng-template #danceManagerNoImage>
    <div
      *ngIf="!followUser.danceSchool.imageUrl"
      class="flex-column-center-center profilePicture circle"
      routerLink="/{{ followUser.danceSchool.urlName }}"
    ><mat-icon class="placeholder-icon">account_circle</mat-icon></div>
  </ng-template>
</ng-container>
