import { Pipe, PipeTransform } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'dayOfMonthPipe',
})
export class DayOfMonthPipe implements PipeTransform {
  transform(value: Date, timezone: string): string {
    if (value === null) {
      return null;
    }
    const date = new Date(value);
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const zonedDate = utcToZonedTime(value, timezone ?? 'Europe/Berlin');
    return zonedDate.getDate().toString();
  }
}
