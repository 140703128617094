import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, debounceTime, Observable, Subscription } from 'rxjs';
import { AuthStateService, DfmStateService, getSubscription, MessageStateService, NotificationDanceManagerStateService, NotificationStateService, UserPassSubscriptionHttpService, WebsocketService } from '@platri/dfx-angular-core';
import { BUG_REPORT_FORM_DE, BUG_REPORT_FORM_EN, danceManagerRolesWithAccessOnDm, DanceSchoolInterface, DEFAULT_PROFILE_PICTURE_PLACEHOLDER, FEATURE_REQUEST_FORM_DE, FEATURE_REQUEST_FORM_EN, getDanceManagerIdsFromTokenRolesByRoles, NotificationInterface, NotificationOverviewInterface, UnreadDmNotificationInterface, UnreadMessagesInterface, UsersInterface } from '@platri/df-common-core';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { MessageCountStateService } from '@platri/df-angular-chat';
import { DmMessageCountInterface } from '@platri/df-common-chat-plug-play';
import { TranslocoService } from '@jsverse/transloco';

declare const FB: any;

@Component({
  selector: 'df-header-after-logged-in',
  templateUrl: './header-after-logged-in.component.html',
  styleUrls: ['./header-after-logged-in.component.scss']
})
export class HeaderAfterLoggedInComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('responsiveMenuTrigger') trigger;
  
  @Input() currentUser: UsersInterface;

  unreadMessagesCount = 0;
  countUnreadMessageDmSubject = new BehaviorSubject<DmMessageCountInterface[]>([]);
  
  unreadMessagesCount$: Observable<UnreadMessagesInterface>;
  unreadNotificationsCountsByDanceManager$: Observable<UnreadDmNotificationInterface[]>;
  
  isMenuOpen = false;
  isDmSidenavOpen = false;

  danceSchools: DanceSchoolInterface[];
  
  unreadUserNotificationsCount: Observable<NotificationOverviewInterface>;
  userNotifications: Observable<NotificationInterface[]>;
  
  private subscriptions: Subscription = new Subscription();
  DEFAULT_PROFILE_PICTURE_PLACEHOLDER = DEFAULT_PROFILE_PICTURE_PLACEHOLDER;
  
  readonly onUnreadMessagesCountUpdated = (): void => {
    this.messageService.loadAllMessagesCountOfCurrentUser();
  };

  readonly onUnreadNotificationCountUpdated = (): void => {
    this.notificationStateService.getUnreadNotificationCountOfCurrentUser();
  };
  
  constructor(
    private readonly router: Router, 
    private readonly authService: AuthStateService, 
    private readonly websocketService: WebsocketService, 
    private readonly translocoService: TranslocoService,
    private readonly messageService: MessageStateService, 
    private readonly notificationStateService: NotificationStateService,
    private readonly notificationDanceManagerStateService: NotificationDanceManagerStateService,
    private readonly socialAuthService: SocialAuthService,
    private dfmStateService: DfmStateService,
    private messageCountStateService: MessageCountStateService,
    private userPassSubscriptionHttpService: UserPassSubscriptionHttpService
  ) {
  }
  
  switchToggle(): void {
    this.isDmSidenavOpen = !this.isDmSidenavOpen;
  }
  
  listenOnCountChanges(): void {
    this.unreadUserNotificationsCount = this.notificationStateService.getAsyncAvailableNotificationsCountForCurrentUser();
    this.userNotifications = this.notificationStateService.getAsyncAvailableNotificationsForCurrentUser();
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.listenOnCountChanges();
    this.loadDataOfCurrentUser();
    this.initAndListenOnEventsFromWebsocket();
    this.loadDanceManagersCurrentUser();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.messageCountStateService.getAsyncDmUnreadMessageCountData().pipe(debounceTime(500)), this.onDmUnreadMessageCountDataChanged.bind(this)));
    this.subscriptions.add(getSubscription(this.messageCountStateService.getAsyncUserUnreadMessageCountData().pipe(debounceTime(500)), this.onUserUnreadMessageCountDataChanged.bind(this)));
  }
  
  onDmUnreadMessageCountDataChanged(data: DmMessageCountInterface[]): void {
    this.countUnreadMessageDmSubject.next(data);
  }
  
  onUserUnreadMessageCountDataChanged(data: number): void {
    this.unreadMessagesCount = data;
    this.changeTitleToDisplayNotifications(data);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser) {
      if (JSON.stringify(changes.currentUser?.previousValue?.roles) !== (JSON.stringify(changes.currentUser?.currentValue?.roles))) {
        this.loadDanceManagersCurrentUser();
      }
    } else {
      this.danceSchools = [];
    }
  }

  loadDataOfCurrentUser(): void {
    this.notificationStateService.getUnreadNotificationCountOfCurrentUser();
    this.messageService.loadAllMessagesCountOfCurrentUser();
  }

  loadUnreadNotificationsCounts(): void {
    this.unreadNotificationsCountsByDanceManager$ = this.notificationDanceManagerStateService.getAsyncAvailableUnreadNotificationsCount();
    if (this.danceSchools?.length > 0) {
      this.notificationDanceManagerStateService.loadUnreadNotificationsCountsByDanceManagerIds(this.danceSchools.map((obj) => obj.id));
    }
  }
  
  initAndListenOnEventsFromWebsocket(): void {
    const socket = this.websocketService.getSocket();
    if (socket) {
      socket.on('unreadNotificationsCountUpdated', () => {
        this.onUnreadNotificationCountUpdated();
        if (Object.keys(this.currentUser.roles.danceSchool).length > 0) {
          this.loadUnreadNotificationsCounts();
        }
      });
    }
  }

  changeTitleToDisplayNotifications(count: number): void {
    this.resetTitle();
    if (count > 0) {
      document.title = '(' + count.toString() + ') ' + document.title;
    }
  }

  resetTitle(): void {
    document.title = document.title
      .replace('(', '')
      .replace(')', '')
      .replace(/([0-9])+/g, '');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.resetTitle();
  }

  logout():void {
    console.log('hier');
    // console.log(FB);
    // if (FB !== undefined) {
    //   FB?.getLoginStatus((loginStatus) => {
    //     if (loginStatus.status === 'connected') {
    //       FB?.logout();
    //     }
    //   });
    // }
    this.socialAuthService.signOut();
    this.authService.logout();
    this.router.navigate(['']);
  }
  
  loadDanceManagersCurrentUser(): void {
    const danceManagerIdsWithEnoughRights = getDanceManagerIdsFromTokenRolesByRoles(this.authService.getSyncCurrentUser().roles?.danceSchool, danceManagerRolesWithAccessOnDm);
    if (danceManagerIdsWithEnoughRights.length > 0) {
      this.subscriptions.add(
        this.dfmStateService.getAsyncAvailableData().subscribe({
          next: (danceManagers) => {
            this.danceSchools = danceManagers.filter((danceManager) => danceManagerIdsWithEnoughRights.includes(danceManager.id));
            this.loadUnreadNotificationsCounts();
          }
        })
      );
      this.dfmStateService.loadAvailableData();
    }
  }
  
  openStripeBillingPortalSession(): void {
    this.userPassSubscriptionHttpService.getBillingPortalSessionUrlByUserPassSubscriptionIdAndCurrentUser().subscribe({
      next: (url) => {
        if (url !== null) {
          window.open(url, '_blank');
        }
      }
    });
  }

  openBugReport():void {
    const url = this.translocoService.getActiveLang().toUpperCase() === 'DE' ? BUG_REPORT_FORM_DE : BUG_REPORT_FORM_EN;
    window.open(url, '_blank');
  }

  openFeatureRequest():void  {
    const url = this.translocoService.getActiveLang().toUpperCase() === 'DE' ? FEATURE_REQUEST_FORM_DE : FEATURE_REQUEST_FORM_EN;
    window.open(url, '_blank');
  }
  
  openImprint():void  {
    window.open('https://www.danceflavors.com/impressum/', '_blank');
  }

  toggleMenuIcon(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

}
