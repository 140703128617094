<ng-container *transloco="let t">
  <div 
    (click)="copy()" 
    class="link-container w-100 p2 flex-row"
    [class]="!copyClicked ? 'align-space-between-center' : 'align-center'"
  >
    <ng-container *ngIf="!copyClicked; else copyClickedText">
      <span class="ellipsis-1">{{textToCopy}}</span>
      <button
        mat-icon-button
        color="primary">
        <mat-icon>content_copy</mat-icon>
      </button>
    </ng-container>
  </div>
  
  <ng-template #copyClickedText>
    <span>{{t(successMessage)}}!</span>
  </ng-template>
</ng-container>
