import { CurrencyInterface, DmPassSubscriptionIntervalEnum, UserPassSubscriptionStatusEnum } from '@platri/df-common-core';
import { DmPassSubscriptionTargetInterface } from './dm-pass-subscription-target.interface';

export interface UserPassSubscriptionInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  startedAt?: Date;
  currentPeriodStartAt?: Date;
  currentPeriodEndAt?: Date;
  endedAt?: Date;
  canceledAt?: Date;
  currency?: CurrencyInterface;
  taxPercentage?: number;
  price?: number;
  name?: string;
  description?: string;
  createdByUserId?: string;
  danceManagerId?: string;
  dmPassSubscriptionId?: string;
  stripeSubscriptionId?: string; 
  durationValue?: number;
  durationUnit?: DmPassSubscriptionIntervalEnum;
  isPaidMonthly?: boolean;
  weeklyUsage?: number;
  targets?: DmPassSubscriptionTargetInterface[];
  status?: UserPassSubscriptionStatusEnum;
  savedCurrentStatus?: UserPassSubscriptionStatusEnum;
  userId?: string;
  newPrice?: number;
  priceChangeDate?: Date;
  priceAcceptanceDate?: Date;
  priceAccepted?: boolean;
}
