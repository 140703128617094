import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MusicStyle } from '@platri/df-common-shared-graphql';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getSubscription } from '@platri/elab-angular-core';
import { MUSIC_STYLES } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-shared-lib-search-music-style',
  templateUrl: './search-music-style.component.html',
  styleUrls: ['./search-music-style.component.scss'],
})
export class SearchMusicStyleComponent implements OnInit, OnDestroy, OnChanges {
  musicStylesList: MusicStyle[];
  selectedMusicStyles: MusicStyle[] = [];
  clonedMusicStylesList: MusicStyle[];
  filteredMusicStylesList: Observable<MusicStyle[]>;
  loading = true;
  searchMusicStyleInput: UntypedFormControl;

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('musicStyleInput') musicStyleInput: ElementRef;

  @Input() endpointUrl: string;
  @Input() graphQl = false;
  @Input() previouslySelectedOptions: MusicStyle[];
  @Input() selectedFilters: string[];
  @Input() required = false;
  @Input() showChips = false;
  @Input() returnStrings = false;
  @Output() selectedOption = new EventEmitter<MusicStyle>();
  @Output() selectedFiltersChanged = new EventEmitter<string[]>();

  destroy$: Subject<void> = new Subject<void>();
  subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.searchMusicStyleInput = this.fb.control({ value: '', disabled: true });
    this.getAllMusicStyles();
    if (this.selectedFilters) {
      this.selectedMusicStyles = this.musicStylesList.filter((style) =>
        this.selectedFilters.includes(style.translationKey)
      );
    }
    this.getFilteredMusicStyles();
  }

  initializeSubscriptions(): void {
    this.subscription.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteQueryParamsChanges.bind(this)));
  }

  onActivatedRouteQueryParamsChanges(params: Params): void {
    if (params['musicstyles']) {
      const translationKeys = params['musicstyles'].split(',');
      this.selectedMusicStyles = this.clonedMusicStylesList.filter((musicStyle) => translationKeys.includes(musicStyle.translationKey));
      this.selectedFiltersChanged.emit(
        this.selectedMusicStyles.map(
          (selectedMusicStyle) => selectedMusicStyle.translationKey
        )
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedFilters?.currentValue !==
      changes.selectedFilters?.previousValue
    ) {
      this.selectedMusicStyles =
        this.musicStylesList?.filter((style) =>
          this.selectedFilters?.includes(style.translationKey)
        ) || [];
    }
    if (this.searchMusicStyleInput) {
      this.validateMusicStyles();
    }
  }

  getAllMusicStyles(): void {
    const musicStylesTranslations = this.translocoService
      .getTranslation('GENERIC_WRAPPER.MUSIC_STYLES');
    this.musicStylesList = MUSIC_STYLES.sort(
      (firstMusicStyle, secondMusicStyle) =>
        musicStylesTranslations[firstMusicStyle.translationKey.toUpperCase()] <
        musicStylesTranslations[secondMusicStyle.translationKey.toUpperCase()]
        ? -1
        : 1
    );
    this.clonedMusicStylesList = JSON.parse(
      JSON.stringify(this.musicStylesList)
    );
    this.filteredMusicStylesList = of(this.clonedMusicStylesList);
    this.searchMusicStyleInput.enable();
    this.loading = false;
    this.initializeSubscriptions();
  }

  getFilteredMusicStyles(): void {
    this.searchMusicStyleInput.valueChanges
      .pipe(debounceTime(250))
      .subscribe((input: string | MusicStyle) => {
        this.filteredMusicStylesList = of(
          this.clonedMusicStylesList.filter((name) =>
            name.translationKey
              .toLowerCase()
              .includes(
                typeof input === 'string'
                  ? input.toLowerCase()
                  : input?.translationKey.toLowerCase()
              )
          )
        );
      });
  }

  removeSelectedMusicStyle(musicStyle: MusicStyle) {
    const index = this.selectedMusicStyles.indexOf(musicStyle);
    if (index >= 0) {
      this.selectedMusicStyles.splice(index, 1);
      this.selectedMusicStyles = [...this.selectedMusicStyles];
      if (this.selectedMusicStyles.length > 0) {
        this.selectedFiltersChanged.emit(
          this.selectedMusicStyles.map(
            (selectedMusicStyle) => selectedMusicStyle.translationKey
          )
        );
      } else {
        this.selectedFiltersChanged.emit(null);
      }
      this.updateQueryParams();
    }
  }

  selectOption(musicStyle: MusicStyle) {
    if (!this.previouslySelectedOptions?.includes(musicStyle)) {
      this.selectedOption.emit(musicStyle);
      this.selectedMusicStyles = this.selectedMusicStyles.concat([musicStyle]);
      this.searchMusicStyleInput.patchValue('');
      this.musicStyleInput.nativeElement.value = '';
      this.getFilteredMusicStyles();
      this.selectedFiltersChanged.emit(
        this.selectedMusicStyles.map(
          (selectedMusicStyle) => selectedMusicStyle.translationKey
        )
      );
      this.updateQueryParams();
    }
  }

  resetFormControlValue() {
    this.searchMusicStyleInput.patchValue('');
    this.validateMusicStyles();
  }

  validateMusicStyles(): void {
    if (this.previouslySelectedOptions?.length === 0 && this.required) {
      this.searchMusicStyleInput.setErrors({ required: true });
    } else {
      this.searchMusicStyleInput.setErrors(null);
    }
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        musicstyles: this.selectedMusicStyles?.length > 0 ? this.selectedMusicStyles.map(
          (musicStyle) => musicStyle.translationKey
        ).join(',') : null
      },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
}
