import { DanceSchoolLocationOpeningHoursDto } from './dance-school-location-opening-hours.dto';
import { LocationInterface } from '../../..';
import { DanceSchoolAddressDto } from './dance-school-address.dto';
import { AmenityDto } from './amenity.dto';
import { RoomDto } from './room.dto';
import { DanceSchoolDto } from './dance-school.dto';

export class DanceSchoolLocationDto implements LocationInterface {
  id: string;
  name: string;
  address: DanceSchoolAddressDto;
  phonePrefix: string;
  phonePrefixAfter: string;
  phoneNumber: string;
  email: string;
  amenities: AmenityDto[];
  openingHours: DanceSchoolLocationOpeningHoursDto;
  showOpeningHours: boolean;
  rooms: RoomDto[];
  danceSchool: DanceSchoolDto;
  isPublic: boolean;
}
