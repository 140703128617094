import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { EventTicketsComponent } from './event-tickets.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactUrlDirectiveModule, DfSharedButtonModule, DisableControlModule, PaymentMethodsViewModule, PipesModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { EventTicketListItemModule } from '../../components/event-ticket-list-item/event-ticket-list-item.module';
import { EventTicketListSummaryModule } from '../../components/event-ticket-list-summary/event-ticket-list-summary.module';
import { EventTicketEmptyListModule } from '../../components/event-ticket-empty-list/event-ticket-empty-list.module';
import {MatInput} from "@angular/material/input";
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
  declarations: [EventTicketsComponent],
  exports: [EventTicketsComponent],
  imports: [
    CommonModule,
    TranslocoModule, 
    FormsModule, 
    MatDialogModule,
    MatCardModule, 
    PipesModule, 
    MatDividerModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    ReactiveFormsModule,
    MatButtonModule, 
    MatIconModule, 
    RouterModule,
    DisableControlModule,
    MatExpansionModule, 
    EventTicketListItemModule,
    EventTicketListSummaryModule, 
    EventTicketEmptyListModule, 
    ContactUrlDirectiveModule, 
    DfSharedButtonModule,
    PaymentMethodsViewModule,
    NgOptimizedImage, 
    MatInput,
  ]
})
export class EventTicketsModule {}
