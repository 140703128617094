import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoAndTitleUploadDialogComponent } from './logo-and-title-upload-dialog.component';
import { ImageUploadCropperModule } from '../image-cropper';


@NgModule({
  declarations: [LogoAndTitleUploadDialogComponent],
  imports: [
    CommonModule, 
    ImageUploadCropperModule
  ],
  exports: [LogoAndTitleUploadDialogComponent],
})
export class LogoAndTitleUploadDialogModule {}
