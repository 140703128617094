import { UserPassStatusEnum } from '../../enums';
import { UserPassScanInterface } from './user-pass-scan.interface';
import { PassInterface } from './pass.interface';
import { UserPassSubscriptionInterface } from '../user-pass-subscription.interface';
import { CurrencyInterface } from '../currency.interface';
import { PassTargetInterface } from './pass-target.interface';
import { PassValidityInterface } from './pass-validity.interface';

export interface UserPassInterface {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  passId?: string;
  userId?: string;
  orderId?: string;
  quantity?: number;
  currency?: CurrencyInterface;
  taxPercentage?: number;
  price?: number;
  name?: string;
  description?: string;
  status?: UserPassStatusEnum;
  userPassScans?: UserPassScanInterface[];
  pass?: PassInterface;
  validUntil?: Date;
  userPassSubscription?: UserPassSubscriptionInterface;
  targets?: PassTargetInterface[];
  danceManagerId?: string;
  usageCount?: number;
  validity?: PassValidityInterface;
  trial?: boolean;
}
