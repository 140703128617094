import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TicketInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-dance-event-ticket-list-summary',
  templateUrl: './event-ticket-list-summary.component.html',
  styleUrls: ['./event-ticket-list-summary.component.scss'],
})
export class EventTicketListSummaryComponent {
  @Input() public selectedTickets: TicketInterface[] = [];
  @Input() public form: UntypedFormGroup;
  @Input() public subtotal = 0;
  @Input() public fees = 0;
  @Output() public closeSummary = new EventEmitter<void>();
  constructor() {}

  public close(): void {
    this.closeSummary.emit();
  }
}
