import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralComponent } from './general.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { LanguageSelectionModule } from '@platri/dfx-angular-core';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [GeneralComponent],
  exports: [GeneralComponent],
    imports: [
      CommonModule,
      MatSlideToggleModule,
      MatExpansionModule,
      LanguageSelectionModule,
      TranslocoDirective
    ],
})
export class GeneralModule {}
