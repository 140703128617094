import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CourseDmOverviewInterface, CourseInterface, CreatePassRequestDto, DfmCoursePassesTableInterface, FeesPaidByEnum, PassInterface, PassTargetTypeEnum, PassValidityDto } from '@platri/df-common-core';
import { atLeastOneOrAllInGroupRequiredValidator, CoursesStateService, DanceManagerHttpService, DanceSchoolHttpService, DanceSchoolStateService, optionalMinMaxValidator, PassesStateService, PassHttpService, quillCharacterCountValidator } from '@platri/dfx-angular-core';
import { getSubscription } from '@platri/elab-angular-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-create-pass-page',
  templateUrl: './create-pass-stepper.page.html',
  styleUrls: ['./create-pass-stepper.page.scss']
})
export class CreatePassStepperPage implements OnInit, OnDestroy {
  @Output() successfulCreateEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  infoFormGroup: UntypedFormGroup;
  priceFormGroup: UntypedFormGroup;
  
  enabledUsesOption = false;
  enabledValidDateOption = false;
  
  pass: PassInterface;
  courses: CourseDmOverviewInterface[];
  selectedCourses: DfmCoursePassesTableInterface[];

  isInitialized = false;
  isWaitingForCreateResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  createFailedNoConnection = false;
  createFailedInternalServer = false;
  createFailedServerNotAvailable = false;
  taxPercentage : number;
  isVatChanged = false;
  
  subscriptions = new Subscription(); 
  
  constructor(
    private readonly fb: FormBuilder, 
    private readonly danceSchoolService: DanceSchoolStateService,
    private readonly danceSchoolHttpService: DanceSchoolHttpService,
    private readonly danceManagerHttpService: DanceManagerHttpService,
    private readonly passHttpService: PassHttpService, 
    private readonly coursesService: CoursesStateService, 
    private readonly router: Router, 
    private readonly route: ActivatedRoute, 
    private readonly matSnackBar: MatSnackBar, 
    private readonly passesStateService: PassesStateService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.initFormGroups();
    this.subscriptions.add(getSubscription(this.coursesService.coursesSubject$, this.onCourseChange.bind(this)));
    this.coursesService.getAllByDanceManagerIdForDmOverview(this.danceSchoolService.getSyncCurrentDanceSchool().id, 1, 100);
    if (this.passesStateService.passToDuplicateSubject.value !== null) {
      this.injectDataToDuplicate(this.passesStateService.passToDuplicateSubject.value);
    } else {
      this.injectData();
    }
  }
  
  ngOnDestroy(): void {
    this.passesStateService.clear();
  }

  onCourseChange(courses: CourseInterface[]): void {
    if (courses) {
      this.courses = courses;
    }
  }

  initFormGroups(): void {
    this.initInfoFormGroup();
    this.initPriceFormGroup();
  }
  
  injectData(): void {
    this.taxPercentage = this.danceSchoolService.getSyncCurrentDanceSchool().vat ?? null;
    this.priceFormGroup.get('taxPercentage')?.valueChanges.subscribe(value => {
      if (value !==this.taxPercentage){
        this.taxPercentage = value;
        this.isVatChanged = true;
      }
    });
    this.priceFormGroup.patchValue({
      taxPercentage: this.taxPercentage ?? null
    });
  }
  
  injectDataToDuplicate(pass: PassInterface): void {
    this.infoFormGroup.patchValue({
      name: pass.name,
      quantity: pass.quantity,
      usageCount: pass.usageCount,
      description: pass.description,
      trial: pass.trial
    });
    this.priceFormGroup.patchValue({
      price: pass.price / 100,
      taxPercentage: pass.taxPercentage
    });
  }

  initInfoFormGroup(): void {
    this.infoFormGroup = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      quantity: [null, [optionalMinMaxValidator(0, 1000)]],
      usageCount: [null, [optionalMinMaxValidator(1, 100)]],
      description: [null, [quillCharacterCountValidator(1000)]],
      trial: [false],
      validity: this.fb.group({
        value: [null, [optionalMinMaxValidator(1, 100)]],
        interval: [null]
      })
    }, {
      validators: atLeastOneOrAllInGroupRequiredValidator(['validity.value', 'validity.interval'], ['usageCount'])
    });
  }

  initPriceFormGroup(): void {
    this.priceFormGroup = this.fb.group({
      price: [null, [Validators.required, Validators.min(0), Validators.max(10000)]],
      taxPercentage: [null, [Validators.required, Validators.min(0), Validators.max(100)]],
      feesPaidBy: [FeesPaidByEnum.CREATOR]
    });
  }
    
  selectedTargets(data: DfmCoursePassesTableInterface[]): void {
    this.selectedCourses = data;
  }
  
  navigateToCoursePassesOverview(): void {
    this.router.navigate(['../'], { relativeTo: this.route, fragment: 'passes' });
  }
  
  onSubmit(): void {
    if (this.infoFormGroup.valid && this.priceFormGroup.valid) {
      this.isWaitingForCreateResponse = true;
      this.createFailedNoConnection = false;
      this.createFailedInternalServer = false;
      
      const { name, description, quantity, usageCount, trial } = this.infoFormGroup.getRawValue();
      const { value, interval } = this.infoFormGroup.get('validity').getRawValue();
      const { price, taxPercentage, feesPaidBy } = this.priceFormGroup.getRawValue();
      
      const passTargets = [];
      if (this.selectedCourses && this.selectedCourses.length > 0) {
        for (const course of this.selectedCourses) {
          passTargets.push({targetId: course.id, targetType: PassTargetTypeEnum.COURSE});
        }
      }

      let createUsageCount = null;
      if (this.enabledUsesOption) {
        createUsageCount = usageCount;
      }
      
      let createValidity: PassValidityDto = null;
      if (this.enabledValidDateOption && value && interval) {
        createValidity = {
          value,
          interval
        };
      }
      
      const createPassRequestDto: CreatePassRequestDto = new CreatePassRequestDto({name: name!, description: description!, trial: trial!, quantity: quantity!, usageCount: createUsageCount!, price: Math.round(+price! * 100), taxPercentage: +taxPercentage!, feesPaidBy: feesPaidBy!, danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id, passTargets, validity: createValidity!});      
      this.subscriptions.add(this.passHttpService.create(createPassRequestDto).subscribe(createdId => {
        console.log("---Got Here---");
        if (this.isVatChanged) {
          this.danceSchoolHttpService.updateDanceSchoolById(this.danceSchoolService.getSyncCurrentDanceSchool().id, ({vat: this.taxPercentage})).pipe(
            map((result) => {
              this.danceSchoolService.sendCurrentDanceSchool(result);
              this.danceManagerHttpService.getAllDanceManagersByCurrentUser().pipe(
                map((danceSchools) => danceSchools),
                map((danceSchools) => this.danceSchoolService.sendCurrentDanceSchools(danceSchools))
              ).subscribe();
            })
          ).subscribe();
        }
        this.successfulCreateEmitter.emit(true);
        this.isWaitingForCreateResponse = false;
        this.navigateToCoursePassesOverview();
        this.matSnackBar.open(this.translocoService.translate('DANCE_MANAGER_COURSE.SUCCESSFULLY_CREATED_PASS'));
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        if (error.status === 504) {
          this.onServiceNotAvailable();
        }
        this.isWaitingForCreateResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    this.createFailedNoConnection = true;
  }

  onInternalServerError(): void {
    this.createFailedInternalServer = true;
  }

  onServiceNotAvailable(): void {
    this.createFailedServerNotAvailable = true;
  }
}
