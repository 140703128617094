import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'df-add-edit-event-rules',
  templateUrl: './add-edit-event-rules.component.html',
  styleUrls: ['./add-edit-event-rules.component.scss'],
})
export class AddEditEventRulesComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() creationStepper = false;
  @Input() loading = false;

  @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSubmitClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscriptions: Subscription = new Subscription();

  publishButtonClicked = false;
  draftButtonClicked = false;

  get participationFormGroup(): UntypedFormGroup {
    return <UntypedFormGroup>this.formGroup.get('participationRestriction');
  }

  get ageRestrictionFormGroup(): UntypedFormGroup {
    return <UntypedFormGroup>this.participationFormGroup.get('ageRestriction');
  }

  get genderRestrictionFormGroup(): UntypedFormGroup {
    return <UntypedFormGroup>this.participationFormGroup.get('genderRestriction');
  }
  get clothingRestrictionFormGroup(): UntypedFormGroup {
    return <UntypedFormGroup>this.formGroup.get('clothingRestriction');
  }

  setAgeLevel(event: { fromLevel: number; toLevel: number }) {
    this.ageRestrictionFormGroup.patchValue({
      fromAge: event.fromLevel,
      toAge: event.toLevel,
    });
  }
}
