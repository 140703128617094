<ng-container *transloco="let t">
  <div class="flex-column-center-center w-100 background-color background-container">
    <div class="flex-column w-100 stepper-container">
      <mat-horizontal-stepper class="background-color" linear #stepper>
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>
  
        <mat-step [label]="t('DANCE_MANAGER_COURSE.BASIC_INFO')" class="pb1" [completed]="infoFormGroup.valid">
          <ng-template matStepContent>
            <div class="flex-column w-100 h-100">
              <mat-card class="flex-column align-space-between" style="min-height: calc(89vh - 65px);">
                <div class="flex-column">
                  <div class="flex-row align-start-center">
                    <span class="title">{{t('GENERIC_WRAPPER.CREATE_NEW_SUBSCRIPTION')}}</span>
                  </div>
                  <mat-divider style="margin-top: 20px; margin-bottom: 40px;"></mat-divider>
                  <df-pass-subscription-info-form
                    [infoFormGroup]="infoFormGroup"
                    [priceFormGroup]="priceFormGroup"
                  ></df-pass-subscription-info-form>
                </div>
                <div class="flex-row align-space-between w-100">
                  <df-shared-lib-button 
                    [materialButtonStyleType]="'outlined'" 
                    [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
                    (buttonClicked)="navigateToCoursePassesOverview()"
                  ></df-shared-lib-button>
                  <df-shared-lib-button 
                    [isDisabled]="infoFormGroup.invalid" 
                    [buttonLabel]="t('GENERIC_WRAPPER.CONTINUE')" 
                    (buttonClicked)="stepper.next()"
                  ></df-shared-lib-button>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </mat-step>
        <mat-step [label]="t('GENERIC_WRAPPER.PRICING')" [completed]="priceFormGroup.valid">
          <ng-template matStepContent>
            <div class="flex-column w-100 h-100">
              <mat-card class="flex-column align-space-between" style="min-height: calc(89vh - 65px);">
                <div class="flex-column">
                  <div class="flex-row align-start-center">
                    <span class="title">{{t('GENERIC_WRAPPER.CREATE_NEW_SUBSCRIPTION')}}</span>
                  </div>
                  <mat-divider style="margin-top: 20px; margin-bottom: 40px;"></mat-divider>
                  <df-pass-subscription-price-form
                    [formGroup]="priceFormGroup"
                  ></df-pass-subscription-price-form>
                </div>
                <div class="flex-row align-space-between w-100">
                  <df-shared-lib-button 
                    [materialButtonStyleType]="'outlined'" 
                    [buttonLabel]="t('GENERIC_WRAPPER.BACK')"
                    (buttonClicked)="stepper.previous()"
                  ></df-shared-lib-button>
                  <df-shared-lib-button 
                    [isDisabled]="priceFormGroup.invalid" 
                    [buttonLabel]="t('GENERIC_WRAPPER.CONTINUE')"
                    (buttonClicked)="stepper.next()"
                  ></df-shared-lib-button>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </mat-step>
        <mat-step [label]="t('GENERIC_WRAPPER.COURSES')" [completed]="infoFormGroup.valid && priceFormGroup.valid">
          <ng-template matStepContent>
            <div class="flex-column w-100 h-100">
              <mat-card class="flex-column align-space-between" style="min-height: calc(89vh - 65px);">
                <div class="flex-column">
                  <div class="flex-row align-start-center">
                    <span class="title">{{t('GENERIC_WRAPPER.CREATE_NEW_SUBSCRIPTION')}}</span>
                  </div>
                  <mat-divider style="margin-top: 20px; margin-bottom: 40px;"></mat-divider>
                  <div class="flex-column gap-5">
                    <span class="pass-description-title">{{t('GENERIC_WRAPPER.COURSES')}}</span>
                    <span class="pass-description-text">{{t('DANCE_MANAGER_COURSE.SUB_USES_DESC')}}</span>
                  </div>
                  <df-shared-lib-pass-subscription-targets-table
                    class="pt2" 
                    [courses]="courses" 
                    (selectedCourses)="selectedTargets($event)"
                  ></df-shared-lib-pass-subscription-targets-table>
                </div>
                <div>
                  <div class="flex-row align-space-between w-100">
                    <df-shared-lib-button 
                      [materialButtonStyleType]="'outlined'" 
                      [buttonLabel]="t('GENERIC_WRAPPER.BACK')"
                      (buttonClicked)="stepper.previous()" 
                      [isDisabled]="isWaitingForCreateResponse" 
                      [loading]="isWaitingForCreateResponse"
                    ></df-shared-lib-button>
                    <df-shared-lib-button 
                      [buttonLabel]="t('GENERIC_WRAPPER.CREATE')"
                      [isDisabled]="(infoFormGroup.invalid && priceFormGroup.invalid) || !selectedCourses || selectedCourses.length === 0 || isWaitingForCreateResponse" 
                      (buttonClicked)="onSubmit()" 
                      [loading]="isWaitingForCreateResponse"
                    ></df-shared-lib-button>
                  </div>
                  <div *ngIf="createFailedNoConnection" class="flex-row-center-center w-100" style="position: absolute; left: 0;bottom: 10px;">
                    <p class="login-error" data-cy="connection-error">{{t('GENERIC_WRAPPER.SERVER_ERROR_CONNECTION_NOT_ESTABLISHED')}}</p>
                  </div>
                  <div *ngIf="createFailedInternalServer" class="flex-row-center-center w-100" style="position: absolute; left: 0;bottom: 10px;">
                    <p class="login-error" data-cy="internal-server-error">{{t('GENERIC_WRAPPER.SERVER_ERROR')}}</p>
                  </div>
                  <div *ngIf="createFailedServerNotAvailable" class="flex-row-center-center w-100" style="position: absolute; left: 0;bottom: 10px;">
                    <p class="login-error" data-cy="internal-server-error">{{t('GENERIC_WRAPPER.SERVER_ERROR_NOT_AVAILABLE')}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</ng-container>
