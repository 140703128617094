import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'df-shared-lib-dance-style-role',
  templateUrl: './df-dance-style-role.component.html',
  styleUrls: ['./df-dance-style-role.component.scss'],
})
export class DfDanceStyleRoleComponent {
  @Input() formGroup: UntypedFormGroup;
}
