<div *ngIf="queryParams && (data || matTableDataSource)">
  <mat-paginator
    [pageIndex]="queryParams.offset"
    [length]="entries || data?.length || matTableDataSource?.data?.length"
    [pageSize]="queryParams.limit"
    [hidePageSize]="entries < 10"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</div>
