import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import { User } from '@platri/df-common-shared-graphql';

@Component({
  selector: 'df-shared-lib-teacher-selection',
  templateUrl: './teacher-selection.component.html',
  styleUrls: ['./teacher-selection.component.scss'],
})
export class TeacherSelectionComponent {

  @Input() formGroup: UntypedFormGroup;
  @Input() teachers: User[];

  compareTeachers(availableTeacher: User, teacher: User): boolean {
    return availableTeacher.id === teacher?.id;
  }

}
