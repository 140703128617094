<ng-container *transloco="let t">
  <form [formGroup]="formGroup" class="flex-column">
      <ng-container *ngIf="paymentIntentClientSecret && elementsOptions">
          <ngx-stripe-payment
                  *ngIf="connectAccountId"
                  [stripe]="stripe"
                  [clientSecret]="paymentIntentClientSecret"
                  [elementsOptions]="elementsOptions"
                  (change)="stripeFormChanged($event)"
                  (ready)="stripeElementReady()">
          </ngx-stripe-payment>
          <ngx-stripe-payment
                  *ngIf="!connectAccountId"
                  [clientSecret]="paymentIntentClientSecret"
                  [elementsOptions]="elementsOptions"
                  (change)="stripeFormChanged($event)"
                  (ready)="stripeElementReady()">
          </ngx-stripe-payment>
      </ng-container>
      <mat-form-field *ngIf="isNameRequired && isStripeReady" appearance="outline" class="pt2">
          <mat-label>{{ firstNameLabel }}</mat-label>
          <input matInput formControlName="firstName"/>
      </mat-form-field>
      <mat-form-field *ngIf="isNameRequired && isStripeReady" appearance="outline">
          <mat-label>{{ lastNameLabel }}</mat-label>
          <input matInput formControlName="lastName"/>
      </mat-form-field>
      <mat-form-field *ngIf="!currentUser && isEmailRequired && isStripeReady" [ngClass]="isNameRequired ? '' : 'pt1'" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email"/>
      </mat-form-field>
      <mat-form-field *ngIf="!currentUser && isEmailRequired && isStripeReady" appearance="outline">
          <mat-label>Email bestätigen</mat-label>
          <input matInput formControlName="confirmEmail"/>
      </mat-form-field>
      <div *ngIf="isStripeReady" class="flex-row">
        <mat-checkbox style="color: #808080" formControlName="acceptInfoText"></mat-checkbox>
        <span class="checkout-info pt1">{{ infoText }}</span>
      </div>
      
  </form>
  <div *ngIf="!isProcessing && isStripeReady" class="mt1">
    <df-shared-lib-button class="mat-btn-round mat-form-btn-padding"
        [buttonLabel]="payNowButtonLabel"
        [isDisabled]="formGroup.invalid || !isValid"
        (buttonClicked)="confirmPayment()">
    </df-shared-lib-button>
  </div>
  <div *ngIf="isProcessing" class="flex-column-center-center gap-10 mt1">
    <mat-spinner [diameter]="35"></mat-spinner>
    <span>{{ paymentInProgressLabel }}</span>
  </div>
  <mat-error *ngIf="isFailed" style="align-self: center; display: flex;
      flex-direction: row;
      margin-top: 10px;">
    {{t('GENERIC_WRAPPER.PAYMENT_FAILED_ERROR')}}
  </mat-error>
</ng-container>
