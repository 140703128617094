<ng-container *transloco="let t">
  <div class="preview-card flex-column w-100">
    <img [src]="imageSourceKey" alt="" class="preview-image"/>
    <div class="card-info-box flex-column">
      <div class="card-content">
        <span class="card-title"><strong>{{ t(titleTranslateKey) }}</strong></span>
        <span class="card-description">{{ t(descriptionTranslateKey) }}</span>
      </div>
      <div (click)="onButtonClicked()" class="enter-text-box">
        <span class="enter-text">{{ t('GENERIC_WRAPPER.ENTER_NOW_FOR_FREE') }}</span>
      </div>
    </div>
  </div>
</ng-container>
