<ng-container *transloco="let t">
  <ng-container *ngIf="stripePaymentIntentClientSecret && returnUrl">
    <df-shared-lib-stripe-payment
      firstNameLabel="{{ t('GENERIC_WRAPPER.FIRST_NAME') }}"
      lastNameLabel="{{ t('GENERIC_WRAPPER.LAST_NAME') }}"
      payNowButtonLabel="{{ t('GENERIC_WRAPPER.PAY_NOW')}}"
      paymentInProgressLabel="{{ t('GENERIC_WRAPPER.PAYMENT_IN_PROGRESS')}}"
      lang="{{ translocoService.getActiveLang().toLowerCase() }}"
      [returnUrl]="returnUrl"
      [paymentIntentClientSecret]="stripePaymentIntentClientSecret"
      [infoText]="ticketOrder.userTickets?.length > 0 ? (t('GENERIC_WRAPPER.EVENT_CHECKOUT_INFO')) : (t('GENERIC_WRAPPER.PASSES_INFO_TEXT_WITHDRAWAL'))"
    ></df-shared-lib-stripe-payment>
  </ng-container>
  <div *ngIf="!(stripePaymentIntentClientSecret && returnUrl)" class="mt2 flex-column-center-center" style="gap: 10px;">
    <mat-spinner [diameter]="35"></mat-spinner>
    <span>{{ t('GENERIC_WRAPPER.LOADING') }}</span>
  </div>
</ng-container>
