import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-name-form-settings',
  templateUrl: './name-form-settings.component.html',
  styleUrls: ['./name-form-settings.component.scss'],
})
export class NameFormSettingsComponent implements OnInit {
  @Input() currentUser: UsersInterface;
  @Output() nameSaved: EventEmitter<UsersInterface> = new EventEmitter<UsersInterface>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  formGroup: UntypedFormGroup;
  isSubmitting = true;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formGroup = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(30)]],
      lastName: ['', [Validators.required, Validators.maxLength(30)]],
      isNamePublic:[this.currentUser.isNamePublic ?? false]
    });
    
    if (this.currentUser) {
      this.formGroup.patchValue({
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        isNamePublic: this.currentUser.isNamePublic
      });
    }
  }

  saveNameData(): void {
    if (this.formGroup.valid) {
      const user: UsersInterface = {
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        isNamePublic:this.formGroup.value.isNamePublic ?? false
      };
      this.nameSaved.emit(user);
      this.isSubmitting = true;
      this.formGroup.markAsPristine();
      this.closeEditMode.emit();
    }
  }

  cancel(): void {
    this.formGroup.reset(this.currentUser);
    this.closeEditMode.emit();
  }
}
