import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthStateService } from '../../../services';
import { FollowTargetTypeEnum, Ticket } from '@platri/df-common-shared-graphql';
import { CardFollowInterface, DanceEventsInterface, DEFAULT_EVENT_PICTURE_PLACEHOLDER, UsersInterface, UserTicketCollapsedByEventAndOrderInterface } from '@platri/df-common-core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-dance-event-lib-dance-event-preview-card',
  templateUrl: './dance-event-preview-card.component.html',
  styleUrls: ['./dance-event-preview-card.component.scss']
})
export class DanceEventPreviewCardComponent implements OnInit {
  @Input() danceEvent$: Observable<DanceEventsInterface>;
  @Input() danceEvent: DanceEventsInterface;
  @Input() isFavorite = false;
  @Input() clickable = false;
  @Input() loading: boolean;
  @Input() small = false;
  @Input() showExtraInfoOverride = true;
  @Input() hideFavorites = false;
  @Input() hideDistance = false;
  @Input() hideSeeEventPageButton = true;
  @Input() routerLink;
  @Input() userTicketCollapsed: UserTicketCollapsedByEventAndOrderInterface;
  @Input() isDmCard = false;
  @Input() clickOpensNewWindow = false;
  @Input() userTicketPage = false;

  @Output() downloadTickets = new EventEmitter<{
    danceEventId: string;
    ticketOrderId: string;
  }>();
  @Output() downloadFreeTickets = new EventEmitter<{
    danceEventId: string;
    freeUserTicketIds: string[];
  }>();

  @Output() addToFollowsClicked: EventEmitter<CardFollowInterface> = new EventEmitter<CardFollowInterface>();
  @Output() removeFromFollowsClicked: EventEmitter<CardFollowInterface> = new EventEmitter<CardFollowInterface>();

  tickets: Ticket[];
  currentUser: UsersInterface;
  subscriptions: Subscription = new Subscription();

  currentLang: string;
  DEFAULT_DANCE_EVENT_PICTURE_PLACEHOLDER = DEFAULT_EVENT_PICTURE_PLACEHOLDER;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthStateService,
    private readonly translocoService: TranslocoService,
    ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase();
  }

  ngOnInit(): void {
    this.currentUser = this.authService.getSyncCurrentUser();
  }

  addToFollows(): void {
    this.addToFollowsClicked.emit({
      targetType: FollowTargetTypeEnum.DANCE_EVENT,
      targetId: this.danceEvent.id
    });
  }

  removeFromFollows(): void {
    this.removeFromFollowsClicked.emit({
      targetType: FollowTargetTypeEnum.DANCE_EVENT,
      targetId: this.danceEvent.id
    });
  }
}
