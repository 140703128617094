import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { dfAppRoute } from '@platri/dfx-angular-core';
import { AuthStateService } from '@platri/dfx-angular-core';


@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard  {
  
    constructor(private router: Router, private authService: AuthStateService ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authService.getSyncCurrentUser()) {
            return true;
        } else {
            const returnUrlFromParam = route.queryParams['returnUrl'] ?? null;
            if (returnUrlFromParam) {
              this.router.navigateByUrl(returnUrlFromParam);
              return false;
            } else {
              this.router.navigate([dfAppRoute]);
              return false;
            }
        }
    }
}
