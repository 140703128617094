import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSettingsComponent } from './profile-settings.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { NameFormSettingsModule } from './name-form-settings';
import { UsernameFormSettingsModule } from './username-form-settings';
import { BirthDateFormSettingsModule } from './birth-date-form-settings';
import { DescriptionFormSettingsModule } from './description-form-settings';
import { DanceIntervalFormSettingsModule } from './dance-interval-form-settings';
import { JobFormSettingsModule } from './job-form-settings';
import { FromFormSettingsModule } from './from-form-settings';
import { GenderFormSettingsModule } from './gender-form-settings';
import { HeightFormSettingsModule } from './height-form-settings';
import { LookingForEditModule } from '../../profile';
import { ConfirmationDialogModule, DfDanceStyleEditModule, GoogleAddressSearchAutocompleteModule, LanguageEditModule, NumberInputModule, PipesModule, UserSocialLinksEditModule, UserSocialLinksPreviewModule } from '@platri/dfx-angular-core';
import { MonthsDancingFormSettingsModule } from './months-dancing-form-settings';
import { DanceAreaFormSettingsModule } from './dance-area-form-settings';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [ProfileSettingsComponent],
  exports: [ProfileSettingsComponent],
    imports: [
      CommonModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatInputModule,
      MatDatepickerModule,
      MatButtonModule,
      MatIconModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      GoogleAddressSearchAutocompleteModule,
      MatCardModule,
      ConfirmationDialogModule,
      NumberInputModule,
      MatRadioModule,
      MatDividerModule,
      NameFormSettingsModule,
      UsernameFormSettingsModule,
      BirthDateFormSettingsModule,
      DescriptionFormSettingsModule,
      MonthsDancingFormSettingsModule,
      DanceIntervalFormSettingsModule,
      JobFormSettingsModule,
      FromFormSettingsModule,
      GenderFormSettingsModule,
      HeightFormSettingsModule,
      DanceAreaFormSettingsModule,
      LanguageEditModule,
      LookingForEditModule,
      PipesModule,
      DfDanceStyleEditModule,
      UserSocialLinksPreviewModule,
      UserSocialLinksEditModule,
      TranslocoDirective,
    ],
  providers: [],
})
export class ProfileSettingsModule {}
