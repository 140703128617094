import { NotificationDataInterface, NotificationUseCaseEnum } from '../../..';

export class CoHostRequestNotificationDataDto implements NotificationDataInterface {
  public readonly useCase = NotificationUseCaseEnum.NEW_EVENT_CO_HOST_REQUEST;
  
  constructor(
    public readonly useCaseSpecificData: {
      fromDanceManagerId: string;
      toDanceManagerId: string;
      danceEventId: string;
      danceEventCoHostId: string;
    }) {}
}
