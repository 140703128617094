<ng-container *transloco="let t">
  <div class="w-100 h-100 p2 mt4-lt-sm align-items">
    <!-- No Selection -->
    <div *ngIf="selectedPasses?.length === 0" class="w-100 h-100 flex-column">
      <div class="pt1 align-space-between hide-on-web" style="font-size: 20px">
        <span>{{ t('GENERIC_WRAPPER.ORDER_SUMMARY') }}</span>
        <span class="close-btn" (click)="close()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
      <div class="w-100 h-100 flex-column-center-center">
        <span><mat-icon class="shopping-cart-icon">shopping_cart</mat-icon></span>
        <span>{{ t('GENERIC_WRAPPER.YOUR_ORDER_SUMMARY') }}</span>
        <span>{{ t('GENERIC_WRAPPER.WILL_APPEAR_HERE') }}</span>
      </div>
    </div>
  
    <!-- More Then 1 Select -->
    <div *ngIf="selectedPasses.length > 0" class="w-100 flex-column" style="gap: 0.5rem;">
      <!-- Header -->
      <div class="pt1 align-space-between" style="font-size: 20px">
        <span>{{ t('GENERIC_WRAPPER.ORDER_SUMMARY') }}</span>
        <span class="close-btn hide-on-web" (click)="close()"><mat-icon>close</mat-icon></span>
      </div>
  
      <!-- Content -->
      <div *ngFor="let pass of selectedPasses" class="w-100 flex-row align-space-between" style="flex: 1 1 100%;">
        <div class="ticket-name-container-summary">
          <span >{{ pass.name }}</span>
        </div>
        <span>
          {{ pass.id ? form.controls[pass.id].value : 'unknown' }} x {{ pass.price | centsToCurrencyString: 'EUR' }}
        </span>
      </div>
      <mat-divider></mat-divider>
      <div class="flex-row align-space-between">
        <strong>{{ t('GENERIC_WRAPPER.TOTAL') }}</strong>
        <strong>{{ this.subtotal | centsToCurrencyString: 'EUR' }}</strong>
      </div>
    </div>
  </div>
</ng-container>
