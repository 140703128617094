import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AppointmentParticipantsHttpService,
  AuthStateService, InfoDialogComponent
} from '@platri/dfx-angular-core';
import {
  AppointmentParticipantDto, CourseAppointmentStatusEnum, DmAddUserErrorEnum,
  UsersInterface
} from '@platri/df-common-core';
import { getSubscription } from '@platri/elab-angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'df-user-inventory',
  templateUrl: './appointments.page.html',
  styleUrls: ['./appointments.page.scss']
})
export class AppointmentsPage implements OnInit, OnDestroy {
  currentUser: UsersInterface;
  appointments: AppointmentParticipantDto[];
  selectedCourseStatus: CourseAppointmentStatusEnum = CourseAppointmentStatusEnum.UPCOMING;
  
  isLoading = false;
  failedLoading = false;
  
  private subscriptions = new Subscription();
  
  constructor(
    private readonly authStateService: AuthStateService,
    private readonly appointmentParticipantsHttpService: AppointmentParticipantsHttpService,
    private readonly matSnackBar: MatSnackBar,
    private readonly translocoService: TranslocoService,
    private readonly matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(
      this.authStateService.getAsyncCurrentUser(),
      this.getAsyncCurrentUserSuccessful.bind(this)
    ));
  }

  getAsyncCurrentUserSuccessful(data: UsersInterface): void {
    this.currentUser = data;
    this.loadData();
  }
  
  loadData(): void {
    this.isLoading = true;
    this.failedLoading = false;
    this.subscriptions.add(
      this.appointmentParticipantsHttpService.getAllByCurrentUser({upcomingAppointments: this.selectedCourseStatus === CourseAppointmentStatusEnum.UPCOMING}).subscribe(res => {
        this.appointments = res;
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.failedLoading = true;
        this.isLoading = false;
      })
    );
  }
  
  onFilterChange(event: CourseAppointmentStatusEnum): void {
    this.selectedCourseStatus = event;
    this.loadData();
  }
  
  onCheckOut(event: string): void {
    this.isLoading = true;
    this.failedLoading = false;
    this.subscriptions.add(
      this.appointmentParticipantsHttpService.deleteHttp(event).subscribe(() => {
        this.appointments = this.appointments.filter(appointment => appointment.id !== event);
        this.isLoading = false;
        this.matSnackBar.open(this.translocoService.translate('USER.APPOINTMENTS.CHECKOUT_SUCCESSFUL'));
      }, error => {
        if (error.status === 409) {
          this.matDialog.open(InfoDialogComponent, {
            width: '400px',
            data: {
              title: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.APPOINTMENT_UNDER_3_HOURS.TITLE'),
              description: this.translocoService.translate('GENERIC_WRAPPER.COURSE_APPOINTMENT_PARTICIPATE.ERROR.APPOINTMENT_UNDER_3_HOURS.TEXT'),
              buttonText: this.translocoService.translate('GENERIC_WRAPPER.CONTINUE'),
            }
          });
        } else {
          this.matSnackBar.open(this.translocoService.translate('USER.APPOINTMENTS.CHECKOUT_FAILED'));
        }
        this.failedLoading = true;
        this.isLoading = false;
      })
    );
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
