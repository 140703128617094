<ng-container *transloco="let t">
  <div *ngIf="link === ''; else tokenLinkGenerated" [formGroup]="form" class="flex-column" style="gap: 15px; padding: 16px">
    <div class="flex-column">
      <span class="title">{{ t('GENERIC_WRAPPER.ADD_MEMBER') }}</span>
      <span class="description pt1">{{ t('GENERIC_WRAPPER.ADD_MEMBER_DESCRIPTION') }}</span>
    </div>
    <mat-form-field class="" appearance="outline">
            <mat-label> {{ t('GENERIC_WRAPPER.SELECT_ROLE') }}</mat-label>
            <mat-select
              formControlName="role"
              disableOptionCentering
            >
              <mat-option
                *ngFor="let roleOption of DanceSchoolRolesEnum | keyvalue"
                [value]="roleOption.value"
              >{{ roleOption.value | titlecase }}</mat-option>
              </mat-select>
              <mat-error> {{ t('GENERIC_WRAPPER.ERROR_SELECT_ROLE') }}</mat-error>
          </mat-form-field>
    <mat-radio-group class="flex-column" formControlName="type" color="primary" style="gap: 1rem">
      <mat-radio-button [value]="UserInviteTypeEnum.EMAIL">{{ t('GENERIC_WRAPPER.SEND_INVITATION_WITH_EMAIL') }}</mat-radio-button>
      <ng-container *ngIf="type.value === UserInviteTypeEnum.EMAIL">
      <mat-form-field appearance="outline" class="w-100" style="padding: 0">
          <mat-label>E-Mail</mat-label>
          <input matInput formControlName="email" autocomplete="off"/>
          <mat-error>{{ t('GENERIC_WRAPPER.EMAIL_IS_REQUIRED') }}</mat-error>
        </mat-form-field>
      </ng-container>
      <mat-radio-button [value]="UserInviteTypeEnum.LINK">{{ t('GENERIC_WRAPPER.ADMINISTRATION.MANAGE_TEAM.GENERATE_LINK') }}</mat-radio-button>
    </mat-radio-group>
    <div class="flex-row align-end" style="gap: 12px">
      <df-shared-lib-button
        [materialButtonStyleType]="'outlined'"
        [buttonLabel]="t('GENERIC_WRAPPER.CANCEL')"
        (buttonClicked)="matDialogRef.close()"
      >
      </df-shared-lib-button>
      <df-shared-lib-button
        [loading]="loading"
        (buttonClicked)="submit()"
        [isDisabled]="form.invalid"
        [buttonLabel]="t('GENERIC_WRAPPER.' + (type.value === UserInviteTypeEnum.EMAIL ? 'SEND' : 'GENERATE'))">
      </df-shared-lib-button>
    </div>
  </div>
  
  <ng-template #tokenLinkGenerated>
    <div class="flex-column" style="gap: 10px">
      <span class="title">{{t('GENERIC_WRAPPER.LINK_GENERATED')}}</span>
      <span class="description">{{ t('GENERIC_WRAPPER.COPY_LINK_TO_SHARE')}}</span>
      <elab-shared-link-clipboard [textToCopy]="link"></elab-shared-link-clipboard>
    </div>
  </ng-template>
</ng-container>
