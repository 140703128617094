import { Injectable, OnDestroy } from '@angular/core';
import { getSubscription } from '@platri/elab-angular-core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environmentForWeb, OmniWebsocketDataInterface, UsersInterface } from '@platri/df-common-core';
import { AuthStateService } from '../state-services';

@Injectable({
  providedIn: 'root'
})
export class OmniWebsocketService implements OnDestroy {
  websocketUrl = environmentForWeb.apiOmniWs + '/websocket';
  
  eventBus: Subject<any> = new Subject();
  
  public socket: WebSocket;
  public isAuthenticated: boolean;
  private isSocketOpen: boolean = false;
  subscriptions: Subscription =  new Subscription();

  userAccessToken: string;
  
  isConnected = false;

  audio = new Audio();
  
  constructor(
    private authStateService: AuthStateService,
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar
  ) {
    console.log("Initialized OmniWebsocketService");
    this.audio.src = "assets/sounds/ding.mp3";
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    console.log("Destroying OmniWebsocketService");
    if (this.socket) {
      this.socket.close();
    }
    this.subscriptions.unsubscribe();
  }
  
  resetState(): void {
    console.log("Resetting OmniWebsocketService");
    this.isConnected = false;
    this.userAccessToken = '';
    if (this.socket) {
      this.socket.close();
    }
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.authStateService.getAsyncAccessToken(), this.onAccessTokenChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.authStateService.getAsyncCurrentUser(), this.onCurrentUserChanges.bind(this)));
  }

  getAsyncEventBus(): Observable<any> {
    return this.eventBus.asObservable();
  }

  onAccessTokenChanges(accessToken: string): void {
    if (accessToken) {
      this.userAccessToken = accessToken;
    } else if (this.userAccessToken && !accessToken) {
      this.resetState();
    }
  }

  onCurrentUserChanges(currentUser: UsersInterface): void {
    if (currentUser && !this.isConnected) {
      this.connect();
      this.isConnected = true;
    }
  }

  connect(): void {
    console.log("Trying to connect");
    this.socket = new WebSocket(this.websocketUrl);
    
    this.setupEventListeners();
    

    this.socket.onclose = (e): void => {
      console.log('WebSocket disconnected', e.reason);
      if (this.isConnected) {
        setTimeout(() => this.connect(), 3000);
      }
    };

    this.socket.onerror = (error): void => {
      console.error('WebSocket error', error);
      this.socket.close(); // Close the websocket on error
    };
    
  }

  private setupEventListeners(): void {
    this.socket.addEventListener('open', (event) => {
      this.isSocketOpen = true;
      console.log('Connected to Omni WebSocket server');
      this.authenticate();
      this.addMessageListener();
    });
  }

  private addMessageListener(): void {
    this.socket.addEventListener('message', (event) => {
      const messageData: OmniWebsocketDataInterface = JSON.parse(event.data);
      switch (messageData.event) {
        case "authenticated":
          this.isAuthenticated = true;
          console.log("Successfully authenticated Omni");
          this.socket.send(JSON.stringify({event: 'pong'}));
          break;
        case 'ping':
          this.socket.send(JSON.stringify({event: 'pong'}));
          break;
        case 'authRolesChanged':
          this.eventBus.next({event: "authRolesChanged", data: null});
          break;
        case 'unreadMessageCountChanged':
          this.eventBus.next({event: "unreadMessageCountChanged", data: null});
          break;
        default:
          break;
      }
    });
  }

  authenticate(): void {
    console.log("Trying to authenticate");
    const message = {
      event: 'authenticate',
      data: {
        jwt: this.authStateService.getSyncAccessToken()
      }
    };
    this.socket.send(JSON.stringify(message));
  }
  
  sendMessage(message: {event: string; data: any}): void {
    if (this.isSocketOpen) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open.');
    }
  }

}
