<form class="w-100" [formGroup]="passedFormGroup">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input data-cy="time-picker-input" matInput [ngxTimepicker]="editableDial" readonly [format]="24" [formControlName]="passedFormControlName">
    <ngx-material-timepicker #editableDial [enableKeyboardInput]="true" [editableHintTmpl]="dialHint"></ngx-material-timepicker>
    <ng-template #dialHint><!--<p>Custom hint</p>--></ng-template>
    <mat-error *ngIf="passedFormGroup.hasError('timeInvalid')">{{orderErrorMessage}}</mat-error>
    <mat-error *ngIf="passedFormGroup.get(passedFormControlName).hasError('required')">{{requiredErrorMessage}}</mat-error>
  </mat-form-field>
</form>
