export interface AuthDanceSchoolUserRoleJwtInterface {
  danceSchool?: AuthDanceSchoolUserRoleJwtEntryInterface;
}

export interface AuthDanceSchoolUserRoleJwtEntryInterface {
  ADMIN?: string[];
  OWNER?: string[];
  MANAGER?: string[];
  TEACHER?: string[];
  SALES?: string[];
  SCANNER?: string[];
  BARKEEPER?: string[];
}
