import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SearchComponent } from './search.component';
import { FrontendGoogleAutocompleteModule } from '@platri/elab-angular-google-autocomplete';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SearchEventsListItemModule } from '../search-events-list-item';
import { SearchListAdModule } from '../search-list-ad';
import { SearchCoursesListItemModule } from '../search-courses-list-item';
import { DisableControlModule, SharedEmptyPageModule, UnavailableForWebModule } from '@platri/dfx-angular-core';
import { SearchStudiosListItemModule } from '../search-studios-list-item';
import {TranslocoDirective} from "@jsverse/transloco";
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption
} from '@angular/material/autocomplete';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FrontendGoogleAutocompleteModule,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatButton,
    InfiniteScrollModule,
    NgOptimizedImage,
    SearchEventsListItemModule,
    SearchListAdModule,
    SearchCoursesListItemModule,
    UnavailableForWebModule,
    SharedEmptyPageModule,
    SearchStudiosListItemModule,
    DisableControlModule,
    MatIconButton,
    TranslocoDirective,
    MatAutocomplete,
    MatOption,
    MatAutocompleteTrigger
  ],
  exports: [SearchComponent],
})
export class SearchModule {}
