export const BUG_REPORT_FORM_DE =
  'https://forms.clickup.com/f/6a5bp-7362/Q0TL4HC4MVJBRU8BB9';
export const BUG_REPORT_FORM_EN =
  'https://forms.clickup.com/f/6a5bp-6961/YMXLIWGYIO1K1N4CXC';
export const FEATURE_REQUEST_FORM_DE =
  'https://forms.clickup.com/6624630/f/6a5bp-12021/KHOQEGGFKO2VHVN99Z';
export const FEATURE_REQUEST_FORM_EN =
  'https://forms.clickup.com/f/6a5bp-7022/66PXJM70D7KKXTJ7P8';
export const DANCE_MANAGER_CATEGORY_REQUEST_FORM_DE =
  'https://forms.clickup.com/6624630/f/6a5bp-19121/LTTKSC5L5F6GX9KMFE';
export const DANCE_MANAGER_CATEGORY_REQUEST_FORM_EN =
  'https://forms.clickup.com/6624630/f/6a5bp-19101/BORIFFQXZXD0SR4JFY';
export const DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_DE =
  'https://forms.clickup.com/6624630/f/6a5bp-19081/2TE8HDRHQYL1Q0QZSK';
export const DANCE_MANAGER_DANCE_STYLE_REQUEST_FORM_EN =
  'https://forms.clickup.com/6624630/f/6a5bp-19141/4PBJBVLEQCBM9DCLOP';
export const DANCE_MANAGER_MUSIC_STYLE_REQUEST_FORM_DE =
  'https://forms.clickup.com/6624630/f/6a5bp-26381/R6MVQMLHU7GSWM4V2J';
export const DANCE_MANAGER_MUSIC_STYLE_REQUEST_FORM_EN =
'https://forms.clickup.com/6624630/f/6a5bp-26361/WBATNQVQNPW2G5JTNN';
