import {
  AfterContentInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DfmCreateLocationFormInterface } from './dfm-create-location-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DanceSchoolStateService,
  dfmDanceManagerRoute,
  DfmDanceManagerUrlNameRouterParam,
  dfmLocationRoute,
  getSubscription,
  LocationHttpService,
  OpenHoursDialogComponent
} from '@platri/dfx-angular-core';
import {
  AddressInterface,
  AmenityInterface,
  CreateLocationRequestDto,
  DanceSchoolInterface,
  emailRegex,
  OpeningHoursInterface,
  TimeRangeInterface,
  UserAppLangEnum
} from '@platri/df-common-core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'df-monorepo-dfm-create-location-form',
  templateUrl: './dfm-create-location-form.component.html',
  styleUrls: ['./dfm-create-location-form.component.scss']
})
export class DfmCreateLocationFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulCreateLocationEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  createLocationFormGroup!: FormGroup<DfmCreateLocationFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForCreateLocationResponse = false;
  isAddressEmptyOnSubmit = false;
  
  createFailedNoConnection = false;

  routerParams!: Params;
  danceManagerUrlNameFromParam: string;
  danceManager: DanceSchoolInterface;
  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',];
  currentLang = UserAppLangEnum.DE;
  openingHoursArray: { key: string; value: TimeRangeInterface[] }[] = [];
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationHttpService: LocationHttpService,
    private danceSchoolService: DanceSchoolStateService,
    private matDialog: MatDialog,
    private translocoService: TranslocoService
  ) {
    this.currentLang = this.translocoService.getActiveLang().toUpperCase() as UserAppLangEnum;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.loadCurrentDanceManager();
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.createLocationFormGroup = this.createCreateLocationFormGroup();
  }

  createCreateLocationFormGroup(): FormGroup<DfmCreateLocationFormInterface> {
    return this.fb.group<DfmCreateLocationFormInterface>({
      name: this.fb.control('',  [Validators.required, Validators.maxLength(50)]),
      address: this.fb.control(null,  Validators.required),
      phonePrefix: this.fb.control('+49'),
      phonePrefixAfter: this.fb.control(null),
      phoneNumber: this.fb.control(null),
      email: this.fb.control(null, [Validators.pattern(emailRegex)]),
      openingHours: this.fb.control(null),
      showOpeningHours: this.fb.control(false),
      amenities: this.fb.control(null),
      isPublic: this.fb.control(true),
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.createLocationFormGroup.valueChanges.subscribe(res => {
      this.createFailedNoConnection = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.danceManagerUrlNameFromParam = this.routerParams[DfmDanceManagerUrlNameRouterParam];
  }
  
  loadCurrentDanceManager(): void {
    this.danceManager = this.danceSchoolService.getSyncCurrentDanceSchool();
  }

  onSubmit(): void {
    if (this.createLocationFormGroup.valid) {
      this.isWaitingForCreateLocationResponse = true;
      this.createFailedNoConnection = false;
      const {address, name, openingHours, amenities, showOpeningHours, isPublic, email, phoneNumber, phonePrefix, phonePrefixAfter} = this.createLocationFormGroup.getRawValue();
      const createLocationRequestDto: CreateLocationRequestDto = {address, name, openingHours, amenities, showOpeningHours, isPublic, email, phoneNumber, phonePrefix, phonePrefixAfter, danceManagerId: this.danceSchoolService.getSyncCurrentDanceSchool().id};
      this.subscriptions.add(this.locationHttpService.createLocation(createLocationRequestDto).subscribe({
        next: () => {
          this.successfulCreateLocationEmitter.next(true);
          this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmLocationRoute]);
          this.isWaitingForCreateLocationResponse = false;
        },
        error: (err) => {
          console.log(err);
          if (err.status === 0) {
            this.onConnectionLost();
          }
          this.isWaitingForCreateLocationResponse = false;
        }
      }));
    }else{
      this.createLocationFormGroup.controls.name.markAsTouched();
      this.isAddressEmptyOnSubmit = true;
    }
  }

  onConnectionLost(): void {
    this.createFailedNoConnection = true;
  }

  onCancel(): void {
    this.router.navigate(['/', dfmDanceManagerRoute, this.danceSchoolService.getSyncCurrentDanceSchool().id, dfmLocationRoute]);
  }

  setSelectedAddress(address?: AddressInterface): void {
    this.createLocationFormGroup.controls.address.setValue(address ?? null);
  }

  onChangeOpeningHours(openingHours: OpeningHoursInterface): void {
    this.createLocationFormGroup.controls.openingHours.patchValue(openingHours);
  }

  openHoursDialog(): void {
    const dialogRef = this.matDialog.open(OpenHoursDialogComponent, {
      panelClass: ['br-20', 'bg-glassmorph'],
      minHeight: '500px',
      minWidth: '650px',
      data: {
        openingHoursData: this.createLocationFormGroup.controls.openingHours.value ?? null
      }
    });
    dialogRef.afterClosed().subscribe((result: OpeningHoursInterface) => {
      if (result) {
        this.onChangeOpeningHours(result);
        this.createLocationFormGroup.controls.showOpeningHours.setValue(true);
        this.openingHoursArray = [];
        for (const key in this.createLocationFormGroup.controls.openingHours.value) {
          if (this.createLocationFormGroup.controls.openingHours.value[key]) {
            this.openingHoursArray.push({ key: key, value: this.createLocationFormGroup.controls.openingHours.value[key as keyof OpeningHoursInterface] });
          }
        }
      }
    });
  }

  onChangeAmenities(amenities: AmenityInterface[]): void {
    this.createLocationFormGroup.controls.amenities.patchValue(amenities);
  }
  
  onEmailLinkTextClick(): void {
    this.createLocationFormGroup.patchValue({email: this.danceSchoolService.getSyncCurrentDanceSchool().email});
  }
}
