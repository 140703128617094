import {Pipe, PipeTransform} from "@angular/core";
import { DanceSchoolRoomTypeEnum, Image, RoomRental } from '@platri/df-common-shared-graphql';

@Pipe({
  name: 'getImagesOfRoom',
})
export class GetImagesOfRoomPipe implements PipeTransform {

  transform(roomRental: RoomRental): Image[] {
    let images = [];

    if (roomRental) {
      images = roomRental.room?.images;
      if (roomRental?.room?.type === DanceSchoolRoomTypeEnum.COMPOSITE) {
        roomRental.room?.rooms.forEach(room => {
          images = images.concat(room.images);
        });
      }
    }
    return images;
  }
}
