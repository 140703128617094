import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CoursePassesTableModule,
  CourseSubscriptionTableModule,
  DfSharedButtonModule,
  PassPriceFormModule,
  SharedEmptyPageModule
} from '@platri/dfx-angular-core';
import { DfmCoursePassesPage } from './dfm-course-passes.page';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfmCoursePassesPage],
  exports: [
    DfmCoursePassesPage
  ],
  imports: [
    CommonModule,
    PassPriceFormModule,
    CourseSubscriptionTableModule,
    SharedEmptyPageModule,
    DfSharedButtonModule,
    TranslocoDirective,
    CoursePassesTableModule
  ]
})
export class DfmCoursePassesPageModule {}
