import { DanceManagerUserInviteTokenInterface, DanceManagerUserRoleEnum } from '../../..';

export class DanceManagerUserInviteTokenDto implements DanceManagerUserInviteTokenInterface {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  expiresAt: Date;
  invitedByUserId: string;
  danceManagerId: string;
  email: string;
  token: string;
  role: DanceManagerUserRoleEnum;
}
