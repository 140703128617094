<ng-container *transloco="let t">
  <a [routerLink]="['/', 'dance-class', course.id]" class="preview-card flex-column w-100">
    <img [src]="course.mainImage?.url ?? DEFAULT_COURSE_PICTURE_PLACEHOLDER" alt="" class="preview-image"/>
    <div class="card-info-box flex-column">
      <div class="flex-row align-space-between w-100" style="gap: 0.5em">
        <div class="flex-column w-100">
          <span class="card-title"><strong>{{course.name}}</strong></span>
          <div class="card-content">
            <span class="card-description">{{course.danceManager?.name}}</span>
            <span class="card-description">{{(course.nextAppointmentDate ? (course.nextAppointmentDate | dateTimeStringDanceEvent : course.nextAppointmentDuration) : '')}}</span>
          </div>
        </div>
  <!--      <div class="card-icon align-center">-->
  <!--        <img [src]="'assets/icons/search-card-icons/new_logo.svg'" alt="" />-->
  <!--      </div>-->
      </div>
      <div class="flex-row align-start-center align-space-between">
        <span class="card-description" *ngIf="course.cheapestPrice">{{t('GENERIC_WRAPPER.SALE_START_AT') + ' '}}<strong>{{ course.cheapestPrice | centsToCurrencyString: 'EUR' }}</strong></span>
        <span class="card-description" *ngIf="!course.cheapestPrice"></span>
        <span class="card-description" *ngIf="course.appointments">{{course.appointments[0]?.address?.city ?? ''}}</span>
      </div>
    </div>
  </a>
</ng-container>
