import { CurrencyTypeEnum } from '../enums';

export interface DanceManagerPayoutDetailInterface {
  id: string;
  totalAmount: number;
  totalFee: number;
  totalNetAmount: number;
  stripePaymentIntentIds?: string[];
  elements?: DanceManagerPayoutDetailElementInterface[];
}

export interface DanceManagerPayoutDetailElementInterface {
  amount: number;
  currency: CurrencyTypeEnum;
  date: Date;
  productsWithQuantity: string[];
  eventId?: string;
  eventName?: string;
  eventDate?: Date;
  eventTimezone?: string;
  username?: string;
  userId?: string;
  userEmail?: string;
}
