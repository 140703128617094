import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'df-error-page-forbidden',
  templateUrl: './error-page-forbidden.component.html',
  styleUrls: ['./error-page-forbidden.component.scss']
})
export class ErrorPageForbiddenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
