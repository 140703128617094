import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { DateTime, Interval } from 'luxon';

export function dateRangeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startDate = control.get('startDate')?.value;
    const endDate = control.get('endDate')?.value;

    if (startDate && endDate) {
      const startDateTime = parseDateTime(startDate);
      const endDateTime = parseDateTime(endDate);
      const diffInMinutes = Interval.fromDateTimes(startDateTime, endDateTime).length('minute');
      
      if (Number.isNaN(diffInMinutes) || diffInMinutes === 0) {
        return { dateRangeInvalid: true};
      } else {
        return null;
      }
    }

    return null;
  };
  
}

function parseDateTime(dateTimeString: string): DateTime {
  const [datePart, timePart] = dateTimeString.split('T');

  const [year, month, day] = datePart.split('-').map(Number);

  const [hour, minute] = timePart.split(':').map(Number);

  const dateTime = DateTime.now()
    .setZone('UTC')
    .set({
      day: day,
      month: month,
      year: year,
      hour: hour,
      minute: minute,
      second: 0,
      millisecond: 0
    });

  return dateTime;
}
