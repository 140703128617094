/* eslint-disable @nx/enforce-module-boundaries */

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStateService, dfAppRoute, dfLoginRoute } from '@platri/dfx-angular-core';
import { DanceEventsInterface, DanceSchoolInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-dance-event-ticket-empty-list',
  templateUrl: './event-ticket-empty-list.component.html',
  styleUrls: ['./event-ticket-empty-list.component.scss']
})
export class EventTicketEmptyListComponent {
  @Input() danceSchool: DanceSchoolInterface;
  @Input() danceEvent: DanceEventsInterface;

  constructor(private readonly router: Router, private readonly authService: AuthStateService, private readonly activatedRoute: ActivatedRoute) {}

  openChatWithDanceSchool(): void {
    if (!this.authService.getSyncCurrentUser()) {
      this.routeToLogin();
    } else {
      this.router.navigateByUrl(`messages/${this.danceSchool.id}`);
    }
  }
  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge', queryParams: { returnUrl: this.router.url}});
  }
  
}
