import { Component } from '@angular/core';
import { danceManagerRolesWithAccessOnDm, DF_SEARCH_COURSE_AD_PICTURE, getDanceManagerIdsFromTokenRolesByRoles } from '@platri/df-common-core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStateService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { SelectDmDialogComponent } from '../select-dm-dialog';
import { dfAppRoute, dfCreateDanceManagerRoute, dfLoginRoute } from '../../shared';

@Component({
  selector: 'df-course-search-ad',
  templateUrl: './course-search-list-item-card-ad.component.html',
  styleUrls: ['./course-search-list-item-card-ad.component.scss']
})
export class CourseSearchListItemCardAdComponent {
  DF_SEARCH_COURSE_AD_PICTURE = DF_SEARCH_COURSE_AD_PICTURE;
  danceManagerIds: string[];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthStateService,
    private dialog: MatDialog,
  ) {
    if( this.authService.getSyncCurrentUser()) {
      this.danceManagerIds = getDanceManagerIdsFromTokenRolesByRoles(this.authService.getSyncCurrentUser().roles?.danceSchool, danceManagerRolesWithAccessOnDm);
    }
  }

  onButtonClicked(): void {
    if(!this.authService.getSyncCurrentUser()) {
      this.routeToLogin();
    }
    if(this.danceManagerIds.length > 1) {
      this.dialog.open(SelectDmDialogComponent, {
        maxHeight: '512px',
        minWidth: '304px',
        data: {
          linkType: 'courses'
        },
        autoFocus: false
      });
    } else if(this.danceManagerIds.length === 1) {
      this.router.navigate(['dance-manager', this.danceManagerIds[0], 'courses', 'create']);
    } else {
      this.router.navigate(['/' + dfAppRoute, dfCreateDanceManagerRoute]);
    }
  }

  routeToLogin(): void {
    this.router.navigate(['/' + dfAppRoute, dfLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
  }
}
