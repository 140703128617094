<ng-container *transloco="let t">
  <div class="flex-row" style="padding-top: 10px;">
    <mat-chip (click)="activeChipIndex = 0" [class]="activeChipIndex === 0 ? 'active-chip' : ''" style="margin-right: 10px;">
      {{ t('USER.INVENTORY.UPCOMING') }}
    </mat-chip>
    <mat-chip (click)="activeChipIndex = 1" [class]="activeChipIndex === 1 ? 'active-chip' : ''">
      {{ t('USER.INVENTORY.PAST') }}
    </mat-chip>
  </div>
  <div *ngIf="activeChipIndex === 0">
    <div *ngIf="upcomingUserTicketsCollapsed?.length > 0" class="flex-column-start-center gap-5 mt2">
      <df-user-inventory-list-ticket-item *ngFor="let upcomingUserTicket of upcomingUserTicketsCollapsed" [userTicketCollapsed]="upcomingUserTicket" (download)="downloadTickets($event)"></df-user-inventory-list-ticket-item>
    </div>
    <div *ngIf="!(upcomingUserTicketsCollapsed?.length > 0)">
      <div class="flex-column-center-center gap-15" style="padding-top: 10vh;">
        <img style="display: block; width: 174px; height: 174px" [src]="'assets/images/tickets-icon.png'" [alt]="">
        <span class="no-tickets-and-passes-title">{{ t('USER.NO_UPCOMING_TICKETS') }}</span>
        <span class="no-tickets-and-passes-sub-title">{{ t('USER.NO_UPCOMING_TICKETS_SUBTITLE') }}</span>
        <df-shared-lib-button
          buttonLabel="{{t('USER.SEARCH_EVENTS')}}"
          (buttonClicked)="navigateToDanceEventsSearch()">
        </df-shared-lib-button>
      </div>
    </div>
  </div>
  <div *ngIf="activeChipIndex === 1">
    <div class="pt2 pb2">
      <div *ngIf="pastUserTicketsCollapsed?.length > 0" class="flex-column-start-center gap-5 mb2">
        <df-user-inventory-list-ticket-item *ngFor="let pastUserTickets of pastUserTicketsCollapsed" [userTicketCollapsed]="pastUserTickets"></df-user-inventory-list-ticket-item>
      </div>
      <div *ngIf="!(pastUserTicketsCollapsed?.length > 0)">
        <div class="flex-column-center-center gap-15" style="padding-top: 10vh;">
          <img style="display: block; width: 174px; height: 174px" [src]="'assets/images/tickets-icon.png'" [alt]="">
          <span class="no-tickets-and-passes-title">{{ t('USER.NO_PAST_TICKETS') }}</span>
          <span class="no-tickets-and-passes-sub-title">{{ t('USER.NO_PAST_TICKETS_SUBTITLE') }}</span>
          <df-shared-lib-button
            buttonLabel="{{t('USER.SEARCH_EVENTS')}}"
            (buttonClicked)="navigateToDanceEventsSearch()">
          </df-shared-lib-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
