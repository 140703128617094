import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription, take, takeWhile } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DanceManagerSubscriptionInterface } from '@platri/df-common-core';
import { DanceManagerService } from '../../services';

@Component({
  selector: 'df-monorepo-process-subscription', 
  templateUrl: './process-subscription.page.html',
  styleUrls: ['./process-subscription.page.scss'],
})
export class ProcessSubscriptionPage implements OnInit, OnDestroy {
  isIntervalActive = false;

  danceManagerSubscription: DanceManagerSubscriptionInterface | null;

  stripeCheckoutSessionId: string;

  private subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly danceManagerService: DanceManagerService
  ) {}

  ngOnInit(): void {
    this.getCheckoutSessionIdFromParams();
  }

  private getCheckoutSessionIdFromParams(): void {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params['checkout_session_id']) {
          this.stripeCheckoutSessionId = params['checkout_session_id'];
          if (this.stripeCheckoutSessionId !== '') {
            this.checkSubscriptionByCheckoutSessionId();
          }
        }
      })
    );
  }

  private checkSubscriptionByCheckoutSessionId(): void {
    this.subscription.add(
      this.danceManagerService.latestDanceManagerSubscription$.subscribe(
        (danceManagerSubscription) => {
          this.danceManagerSubscription = danceManagerSubscription;
          if (this.danceManagerSubscription === null) {
            this.activateIntervalCheckingSubscriptionByCheckoutSessionId();
          } else {
            const urlName = this.danceManagerSubscription!.danceSchool!.id;
            setTimeout(() => {
              this.router.navigate([`/dance-manager/${urlName}/settings`], {
                fragment: 'subscription',
              });
            }, 3000);
          }
        }
      )
    );

    this.danceManagerService.getSubscriptionByCheckoutSessionId(this.stripeCheckoutSessionId);
  }

  private activateIntervalCheckingSubscriptionByCheckoutSessionId(): void {
    if (!this.isIntervalActive) {
      this.isIntervalActive = true;
      const timer = interval(3000);

      const takeTwelve = timer.pipe(take(10));

      this.subscription.add(
        takeTwelve
          .pipe(takeWhile(() => this.danceManagerSubscription === null))
          .subscribe(() => {
            this.danceManagerService.getSubscriptionByCheckoutSessionId(
              this.stripeCheckoutSessionId
            );
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
