import { UserRoleEnum, UserStatusEnum } from '../enums';
import { AuthDanceSchoolUserRoleJwtInterface } from './auth-dance-school-user-role-jwt.interface';

export interface JwtPayloadInterface {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  status?: UserStatusEnum;
  appLang?: string;
  password?: string;
  userRole?: UserRoleEnum;
  roles?: AuthDanceSchoolUserRoleJwtInterface;
}
