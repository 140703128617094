import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassCheckoutEmptyListComponent } from './pass-checkout-empty-list.component';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [PassCheckoutEmptyListComponent],
  exports: [PassCheckoutEmptyListComponent],
  imports: [CommonModule, TranslocoDirective]
})
export class PassCheckoutEmptyListModule {}
