<ng-container *transloco="let t">
  <div *ngIf="danceManager$ | async as danceManagerAsync; else searchResult" [ngClass]="small ? 'small' : ''">
    <ng-container [ngTemplateOutlet]="preview" [ngTemplateOutletContext]="{ danceManager: danceManagerAsync }"></ng-container>
  </div>

  <ng-template #searchResult>
    <div style="position: relative;" [ngClass]="small ? 'small' : ''">
      <div *ngIf="danceManager" class="flex-row-center-center">
        <ng-container [ngTemplateOutlet]="preview" [ngTemplateOutletContext]="{ danceManager: danceManager }"></ng-container>
      </div>
      <div 
        [ngClass]="small ? 'column' : 'row'" 
        [ngClass]="loading ? 'loading' : 'notLoading'"
        [ngStyle]="loading ? {} : { position: 'absolute', top: 0 }"
        class="dance-course-preview-card preview preview-skeleton w-100" 
      >
        <div class="dance-course-preview-card-skeleton-container flex-column" style="width: 36%; height: 100%" id="skeleton-left-column">
          <div class="preview-image w-100 h-100 mb0 block">
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '100%', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
        </div>

        <div class="dance-course-preview-card-skeleton-container flex-column" style="width: 64%; height: 100%" id="skeleton-right-column">
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '50%', height: '18px', 'margin-bottom': '5px' }"></ngx-skeleton-loader>
          </div>
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '35%', height: '18px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '90%', height: '63px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
          <div class="flex-row">
            <ngx-skeleton-loader class="w-100" [theme]="{ width: '100%', height: '20px', 'margin-bottom': 0 }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #preview>
    <div class="flex-row preview-card w-100 preview clickable opacityAnimation">
      <a [target]="clickOpensNewWindow ? '_blank' : '_self'" [routerLink]="routerLink ?? ['/', 'app', '@' + danceManager.id]" style="flex: 1;">
        <div [ngClass]="small ? 'flex-column' : ''" class="container-flex-row-responsive-col w-100 h-100">
          <div class="flex-column-center-center" id="left-column">
            <div class="flex-column preview-image-container">
              <img [src]="danceManager.titleImage?.url ?? 'assets/images/studio_header.png' " alt="" class="preview-image w-100 mb0 block" />
            </div>
          </div>

          <div class="flex-column align-start" id="right-column">
            <div class="flex-direction-swap-row-to-column" style="width: 100%; height: 100%;">
              <div class="flex-column preview-header left-container">
                <span *ngIf="userPassCollapsed" class="titleText name left-align ellipsis-1">
                  {{ userPassCollapsed.userPassSubscription.name }}
                </span>
                <a [href]="'/app/@' + danceManager.urlName" style="margin-top: 10px;">
                  <span class="subText name left-align ellipsis-1" (click)="$event.stopPropagation();">
                    {{ danceManager.name }}
                  </span>
                </a>
                <div class="flex-row" [ngClass]="userPassCollapsed.validUntil ? 'ellipsis-2': 'ellipsis-3'" style="width: 100%; margin-top: 10px; word-break: normal !important;">
                  <a [href]="'/app/dance-class/' + course.id" *ngFor="let course of courses; let isLast = last">
                    <span class="subText name" (click)="$event.stopPropagation();">
                      {{ course.name }}<span *ngIf="!isLast">, </span>
                    </span>
                  </a>
                </div>
                <span *ngIf="userPassCollapsed.validUntil" class="name left-align ellipsis-1" style="margin-top: 10px;">
                    {{t('GENERIC_WRAPPER.VALID_UNTIL')}}: {{ userPassCollapsed.validUntil | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}
                </span>
              </div>
                
              <mat-divider *ngIf="userPassCollapsed.userPassSubscription?.weeklyUsage !== null" class="hide-on-mobile" [vertical]="true" style="margin-left: 20px; margin-right: 20px;"></mat-divider>
              <mat-divider *ngIf="userPassCollapsed.userPassSubscription?.weeklyUsage !== null" class="hide-on-web" style="margin-top: 10px; margin-bottom: 10px;"></mat-divider>
              
              <div *ngIf="userPassCollapsed.userPassSubscription?.weeklyUsage !== null" class="flex-column right-container">
                <span *ngIf="userPassCollapsed" class="titleText name left-align ellipsis-1">
                  {{  t('GENERIC_WRAPPER.' + (userPassCollapsed.userPassSubscription?.weeklyUsage ? 'STAMPS' : 'UNLIMITED_STAMPS')) }}
                </span>
                <div *ngIf="userPassCollapsed.userPassSubscription?.weeklyUsage" class="subText name ellipsis-1">
                  <mat-grid-list cols="5" rowHeight="40px" gutterSize="10px" style="margin-top: 10px; margin-bottom: 10px;">
                    <mat-grid-tile *ngFor="let stamp of shownStamps" class="stamp-container">
                      <div [class]="stamp <= userPassCollapsed?.usedCount ? 'align-center stamp used' : 'align-center stamp unused'">
                        <mat-icon *ngIf="stamp <= userPassCollapsed?.usedCount" style="color: #00A1DF">check</mat-icon>
                        <span *ngIf="stamp > userPassCollapsed?.usedCount">{{ stamp }}</span>
                      </div>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
                <div *ngIf="!userPassCollapsed.userPassSubscription?.weeklyUsage" class="subText name ellipsis-1">
                  <div class="align-center stamp used" style="margin-top: 10px; margin-bottom: 10px;">
                    <mat-icon style="color: #00A1DF">all_inclusive</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
<!--      ### Download Passes Button-->

<!--      <div *ngIf="userPassCollapsed && !this.userPassCollapsed?.isCanceled" class="p2 download-passes-button">-->
<!--        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">-->
<!--          <mat-icon class="material-icons-round">more_vert</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--      <mat-menu #menu="matMenu" xPosition="before">-->
<!--        <button *ngFor="let passOrderId of userPassCollapsed?.passOrderIds; let index = index" mat-menu-item (click)="downloadPasses.emit({ danceCourseId: danceCourse.id, passOrderId:passOrderIds })">-->
<!--          <mat-icon class="material-icons-round">file_download</mat-icon>-->
<!--          <span *ngIf="userPassCollapsed?.passOrderIds.length > 1">Passes {{ index + 1 }}</span>-->
<!--          <span *ngIf="userPassCollapsed?.passOrderIds.length === 1">Pass</span>-->
<!--        </button>-->
<!--      </mat-menu>-->
    </div>
  </ng-template>
</ng-container>
