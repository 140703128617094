import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DfEventSearchAdComponent } from './df-event-search-ad.component';
import { DfSharedButtonModule } from '../../buttons';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DfEventSearchAdComponent],
    imports: [
      CommonModule,
      DfSharedButtonModule,
      TranslocoDirective,
    ],
  exports: [DfEventSearchAdComponent],
})
export class DfEventSearchAdModule {}
