import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DanceManagerPassesCheckoutPage } from './dance-manager-passes-checkout.page';
import { MatIconModule } from '@angular/material/icon';
import { ContactUrlDirectiveModule, EventTicketOrderSummaryModule, PipesModule, TicketCheckoutPaymentModule } from '@platri/dfx-angular-core';
import { MatDividerModule } from '@angular/material/divider';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [DanceManagerPassesCheckoutPage],
    imports: [
      CommonModule,
      MatIconModule,
      PipesModule,
      MatDividerModule,
      TicketCheckoutPaymentModule,
      EventTicketOrderSummaryModule,
      ContactUrlDirectiveModule,
      TranslocoDirective
    ]
})
export class DanceManagerPassesCheckoutPageModule {}
