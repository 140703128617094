import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserProfileStateService } from '@platri/dfx-angular-core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UsersInterface } from '@platri/df-common-core';

@Component({
  selector: 'df-monorepo-looking-for-edit',
  templateUrl: './looking-for-edit.component.html',
  styleUrls: ['./looking-for-edit.component.scss']
})
export class LookingForEditComponent implements OnInit {
  @Input() user: UsersInterface;
  @Output() updateData = new EventEmitter<any>();
  @Output() closeEditMode: EventEmitter<void> = new EventEmitter<void>();

  temporaryLookingForList: string[] = [];
  editModeClosed = true;
  lookingFor: UntypedFormControl;
  
  constructor(private readonly userProfileService: UserProfileStateService) {}

  ngOnInit(): void {
    if (this.user.lookingFor) {
      this.temporaryLookingForList = [...this.user.lookingFor];
    } else {
      this.temporaryLookingForList = [];
    }
    this.initForm();
  }

  initForm(): void {
    this.lookingFor = new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]);
  }

  add(): void {
    if (this.lookingFor.valid && this.lookingFor.dirty) {
      this.temporaryLookingForList = [...this.temporaryLookingForList, this.lookingFor.value];
      this.lookingFor.reset();
      this.editModeClosed = false;
      this.lookingFor.setErrors(null);
    }
  }

  addStandardExamples(example: string): void {
    console.log(example);
    if (example) {
      this.temporaryLookingForList = [...this.temporaryLookingForList, example];

      this.lookingFor.reset();
      this.editModeClosed = false;
      this.lookingFor.setErrors(null);
    }
  }

  standardExampleInList(example: string): boolean {
    return this.temporaryLookingForList.includes(example);
  }

  delete(index: number): void {
    this.editModeClosed = false;
    this.temporaryLookingForList.splice(index, 1);
  }

  save(): void {
    this.user.lookingFor = [...this.temporaryLookingForList];
    this.userProfileService.updateUserProfileLookingFor(this.temporaryLookingForList);

    if (this.lookingFor.valid || !this.lookingFor.dirty) {
      this.editModeClosed = true;
      this.closeEditMode.emit();
    }
  }

  cancel(): void {
    this.editModeClosed = true;
    this.lookingFor.reset();
    if (this.user.lookingFor) {
      this.temporaryLookingForList = [...this.user.lookingFor];
    } else {
      this.temporaryLookingForList = [];
    }
    this.closeEditMode.emit();
  }
}
