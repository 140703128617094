<ng-container *transloco="let t">
  <div 
      *ngIf="!showPaymentHistory" 
      class="px2 py3 align-center lt-md-column" 
      [class]="(danceManagerService.latestDanceManagerSubscription$ | async) !== null ? 'flex-column' : 'flex-row'" 
      >
      <df-monorepo-dm-subscription-info-card
          *ngIf="(danceManagerService.latestDanceManagerSubscription$ | async) !== null"
          class="w-100"
          [danceManager]="danceManager"
          [danceManagerSubscription]="(danceManagerService.latestDanceManagerSubscription$ | async)!"
          [loading]="danceManagerService.danceManagerSubscriptionLoading$ | async"
          (onCancelSubscriptionClick)="onCancelSubscriptionClick($event)"
          (showPaymentHistoryClick)="onShowPaymentHistoryClick($event)">
      </df-monorepo-dm-subscription-info-card>
      <div class="pt2 flex-row">
          <df-monorepo-dm-subscription-price-table-card
                  *ngIf="danceManager && ((danceManagerService.latestDanceManagerSubscription$ | async) === null || (danceManagerService.latestDanceManagerSubscription$ | async).subscriptionStatus === danceManagerSubscriptionStatus.TERMINATED)"
                  style="min-width: 40%;"
                  [stripeClientReferenceId]="danceManager.id"
                  [stripePublishableKey]="stripePublishableKey"
                  [stripePricingTableId]="stripePricingTableId">
              </df-monorepo-dm-subscription-price-table-card>
          <div class="pt3 w-100">
              <div class="subscription-terms-and-conditions-container p3 flex-column">
                  <span class="terms-and-conditions-title">{{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.TITLE')}}</span>
                  <span class="terms-and-conditions-text"><strong>{{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.SUBSCRIPTION_TITLE')}}</strong> {{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.SUBSCRIPTION_INFO')}}</span>
                  <span class="terms-and-conditions-text"><strong>{{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.SERVICE_TITLE')}}</strong> {{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.SERVICE_INFO')}}</span>
                  <span class="terms-and-conditions-text"><strong>{{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.PRICE_TITLE')}}</strong> {{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.PRICE_INFO')}}</span>
                  <span class="terms-and-conditions-text"><strong>{{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.LEGAL_TITLE')}}</strong> {{t('GENERIC_WRAPPER.TERMS_AND_CONDITIONS.LEGAL_INFO')}}</span>
              </div>
          </div>
      </div>
  </div>
  <div *ngIf="showPaymentHistory" class="px2 py3">
      <df-monorepo-dm-subscription-payment-history-card
          class="w-100"
          [danceManagerSubscription]="(danceManagerService.latestDanceManagerSubscription$ | async)!"
          (showPaymentHistoryClick)="onShowPaymentHistoryClick(false)">
      </df-monorepo-dm-subscription-payment-history-card>
  </div>
</ng-container>
