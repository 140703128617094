<ng-container *transloco="let t">
  <div class="flex-column">
      <div *ngIf="isDetailOpened" style="cursor: pointer;" class="flex-row-end" (click)="isDetailOpened = !isDetailOpened">
          <mat-icon style="color: #3377FF;">arrow_drop_up</mat-icon>
      </div>
    
      <div style="cursor: pointer;" class="flex-row align-space-between" (click)="isDetailOpened = !isDetailOpened">
          <span class="dance-style-name">
            {{ t('GENERIC_WRAPPER.DANCE_STYLES.' + profileDanceStyle.translationKey.toUpperCase()) }}
          </span>
          <span *ngIf="isDetailOpened">
            {{ experience | danceSkillLevelPipe }}
          </span>
          <mat-icon *ngIf="!isDetailOpened">arrow_drop_down</mat-icon>
      </div>
    
      <df-shared-lib-slider-gradient
        *ngIf="isDetailOpened"
        [minValue]="1"
        [styleClass]="'w-100'" [currentStep]="profileDanceStyle.experience"
        (currentStepChanged)="onSkillLevelChange($event)"
      ></df-shared-lib-slider-gradient>
        
      <div *ngIf="isDetailOpened" class="flex-row" [class]="formGroup?.get('isPairDance')?.value ? 'space-between' : 'end'">
          <df-shared-lib-dance-style-role
            *ngIf="formGroup?.get('isPairDance')?.value"
            [formGroup]="formGroup"
          ></df-shared-lib-dance-style-role>
          <mat-icon style="cursor: pointer;" (click)="onDeleteClick()">delete</mat-icon>
      </div>
  </div>
</ng-container>
