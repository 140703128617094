<ng-container *transloco="let t">
  <a
    mat-list-item
    cdkOverlayOrigin
    [routerLink]="dfmAdministrationRoute"
    [routerLinkActive]="'active-link'"
    matTooltipClass="tooltip"
    [matTooltip]="!isExpanded ? t('DFM_APP.DFM_SIDENAV.ADMINISTRATION') : null">
    <mat-icon matListItemIcon class="material-icons-outlined mr2">admin_panel_settings</mat-icon>
    <span *ngIf="isExpanded" matListItemTitle [routerLink]="dfmAdministrationRoute" [routerLinkActive]="'active-link'">
      {{ t('DFM_APP.DFM_SIDENAV.ADMINISTRATION') }}
    </span>
  </a>
</ng-container>
