<ng-container *transloco="let t">
  <div class="pt2 flex-column align-center">
    <div class="loading-box" [class.spinner-big]="true" *ngIf="isLoading"></div>
    <mat-card style="padding: 16px;" class="passes-container w-100 flex-column" *ngIf="!isLoading">
      <div class="px1 header-title-container">
        <div class="flex-row align-start-center">
          <button mat-icon-button (click)="onBackButtonClick()" style="margin-right: 5px;">
            <mat-icon class="back-btn-icon">arrow_back_rounded</mat-icon>
          </button>
          <span class="course-title">{{currentUserPass.name}} {{currentUserPass?.status === userPassStatusEnum.FREE && !currentUserPass?.trial ? ' (Free)' : ''}}</span>
        </div>
      </div>
      
      <div class="p2">
        <mat-divider style="position: initial"></mat-divider>
      </div>
      
      <div style="overflow: auto;" class="p2 flex-row-center-center">
        <div class="user-pass-box p3 flex-column">
          <button *ngIf="canBeTransferred() || canBeWithdrawn()" mat-icon-button [matMenuTriggerFor]="menu2" (click)="$event.stopPropagation()">
            <mat-icon class="material-icons-round">more_vert</mat-icon>
          </button>
          
          <mat-menu #menu2="matMenu" xPosition="before">
            <button *ngIf="canBeTransferred()" mat-menu-item (click)="openTransferPassDialog()">
              <mat-icon class="material-icons-round">swap_horiz</mat-icon>
              <span>{{ t('GENERIC_WRAPPER.TRANSFER_PASS.TITLE') }}</span>
            </button>
            <button *ngIf="canBeWithdrawn()" mat-menu-item (click)="openRefundPassDialog()">
              <mat-icon class="material-icons-round">undo</mat-icon>
              <span>{{ t('USER.WITHDRAWAL_ORDER') }}</span>
            </button>
          </mat-menu>
          
          <div *ngIf="currentUserPass.status !== userPassStatusEnum.REFUND" class="flex-row align-center">
            <qrcode [qrdata]="currentUserPass?.id" [width]="150"></qrcode>
          </div>
          <df-shared-lib-button 
            *ngIf="!currentUserPass?.trial && currentUserPass.pass.status === passStatusEnum.ACTIVE"
            class="flex-row buy-again-button" 
            buttonLabel="{{ t('USER.BUY_AGAIN') }}" 
            (buttonClicked)="routeToPassBuyout()"
          ></df-shared-lib-button>
          <div *ngIf="currentUserPass?.usageCount !== null && currentUserPass.status !== userPassStatusEnum.REFUND" class="mt1 flex-column" style="justify-content: flex-start">
            <div class="align-space-between-center">
              <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.STAMPS') }}</span>
              <span>{{currentUserPass?.userPassScans?.length + '/' + currentUserPass?.usageCount}}</span>
            </div>
            <mat-grid-list cols="5" rowHeight="40px" gutterSize="10px" style="margin-top: 10px; margin-bottom: 10px;">
              <mat-grid-tile *ngFor="let stamp of shownStamps" class="stamp-container">
                <div [class]="stamp <= currentUserPass?.userPassScans?.length ? 'align-center stamp used' : 'align-center stamp unused'">
                  <mat-icon *ngIf="stamp <= currentUserPass?.userPassScans?.length">check</mat-icon>
                  <span *ngIf="stamp > currentUserPass?.userPassScans?.length">{{ stamp }}</span>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
          
          <div class="mt1 flex-column">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.NAME') }}</span>
            <span class="pass-detail-info">{{currentUser?.firstName + ' ' + currentUser?.lastName}}</span>
          </div>
          
          <div class="mt1 flex-column">
            <span class="pass-detail-label">{{ t('USER.PASS') }}</span>
            <span class="pass-detail-info">{{currentUserPass?.name}}{{currentUserPass?.status === userPassStatusEnum.FREE && !currentUserPass?.trial ? ' (Free)' : ''}}</span>
          </div>
          
          <div class="mt1 flex-column" style="margin-bottom: 8px;" *ngIf="!currentUserPass?.trial">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.PRICE') }}</span>
            <span class="pass-detail-info">{{ currentUserPass?.status === userPassStatusEnum.FREE ? 0 : currentUserPass?.price | centsToCurrencyString: 'EUR' }}</span>
          </div>
          
          <div *ngIf="currentUserPass.status !== userPassStatusEnum.REFUND" class="mt1 flex-column" style="margin-bottom: 8px;">
            <span class="pass-detail-label">{{ t('USER.BUY_DATE') }}</span>
            <span class="pass-detail-info">{{ currentUserPass?.createdAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
          </div>
          
          <div *ngIf="currentUserPass.status === userPassStatusEnum.REFUND" class="mt1 flex-column" style="margin-bottom: 8px;">
            <span class="pass-detail-label">{{ t('USER.REFUND_DATE') }}</span>
            <span class="pass-detail-info">{{ currentUserPass?.updatedAt | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
          </div>
          
          <div *ngIf="currentUserPass?.validUntil" class="mt1 flex-column" style="margin-bottom: 8px;">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.VALID_TILL') }}</span>
            <span class="pass-detail-info">{{ currentUserPass?.validUntil | dateTimezoned: 'UTC' : currentUser?.appLang : null : true : false : 'longDate' }}</span>
          </div>
          
          <div *ngIf="currentUserPass?.userPassSubscription" class="mt1 flex-column" style="margin-bottom: 8px;">
            <span class="pass-detail-label">Abonnement Status</span>
            <span class="pass-detail-info">{{ t('USER.SUBSCRIPTION_STATUS.' + currentUserPass?.status) }}</span>
          </div>
          
          <mat-divider class="dashed-divider"></mat-divider>
  
          <div class="mt1 flex-column">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.ORGANIZER') }}</span>
            <a class="pass-detail-info" [href]="'/app/@' + currentPassOrganizer?.urlName">{{ currentPassOrganizer?.name }}</a>
          </div>
          
          <div *ngIf="currentUserPass?.description && currentUserPass?.description.length > 0" class="mb2 flex-column">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.DESCRIPTION') }}</span>
            <div class="description" [innerHTML]="(!isDescriptionTruncated ? (currentUserPass?.description | dynamicTag | truncate: descriptionTruncateLength) : (currentUserPass.description | dynamicTag)) | stripHtmlTags"></div>
                <span *ngIf="hasTruncateDescription && !isDescriptionTruncated" class="read-more-text" (click)="isDescriptionTruncated = true">{{ t('GENERIC_WRAPPER.READ_MORE') }}</span>
            <span *ngIf="hasTruncateDescription && isDescriptionTruncated" class="read-more-text" (click)="isDescriptionTruncated = false">{{ t('GENERIC_WRAPPER.READ_LESS') }}</span>
          </div>
          
          <div class="mt1 flex-column" *ngIf="activePassCourses.length > 0">
            <span class="pass-detail-label">{{ t('GENERIC_WRAPPER.COURSES') }}</span>
            <ng-container *ngIf="!isCoursesTruncated">
              <a *ngFor="let course of activePassCourses | slice:0:coursesTruncateLength" class="pass-detail-info" [href]="'/app/dance-class/' + course?.id">{{ course?.name }}</a>
            </ng-container>
            <ng-container *ngIf="isCoursesTruncated">
              <a *ngFor="let course of activePassCourses" class="pass-detail-info"[href]="'/app/dance-class/' + course?.id">{{ course?.name }}</a>
            </ng-container>
            <span *ngIf="hasTruncateCourses && !isCoursesTruncated" class="read-more-text" style="margin-top: 12px;" (click)="isCoursesTruncated = true">{{ t('GENERIC_WRAPPER.SHOW_MORE') }}</span>
            <span *ngIf="hasTruncateCourses && isCoursesTruncated" class="read-more-text" style="margin-top: 12px;" (click)="isCoursesTruncated = false">{{ t('GENERIC_WRAPPER.SHOW_LESS') }}</span>
          </div>        
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>
