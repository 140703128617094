import {
  DanceEventCategoryEnum,
  DanceEventSearchStatusEnum,
  DanceEventsInterface,
  PageViewsDto,
  RadiusUnitEnum
} from '../../..';
import { ImageDto } from './image.dto';
import { DanceEventRulesDto } from './dance-event-rules.dto';
import { DanceEventRestrictionsDto } from './dance-event-restrictions.dto';
import { DanceSchoolDto } from './dance-school.dto';
import { AppointmentsDto } from './appointments.dto';
import { DanceEventCoHostsDto } from './dance-event-co-hosts.dto';
import { DanceStyleDto } from './dance-style.dto';
import { MusicStylesDto } from './music-styles.dto';

export class DanceEventsDto implements DanceEventsInterface{
  appointments: AppointmentsDto[];
  categories?: DanceEventCategoryEnum[];
  cheapestPrice?: number;
  coHosts?: DanceEventCoHostsDto[];
  danceSchool: DanceSchoolDto;
  danceStyles?: DanceStyleDto[];
  description?: string;
  distance: number;
  hasSoldTickets?: boolean;
  id: string;
  isActive: boolean;
  isSoldOut?: boolean;
  mainImage?: ImageDto;
  musicStyles?: MusicStylesDto[];
  name: string;
  radiusUnit?: RadiusUnitEnum;
  restrictions?: DanceEventRestrictionsDto;
  rules?: DanceEventRulesDto;
  searchStatus?: DanceEventSearchStatusEnum;
  tags?: string[];
  url?: string;
  pageViews?: PageViewsDto;
}
