import { NgModule } from '@angular/core';
import { SearchCategoryComponent } from './search-category.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoDirective } from '@jsverse/transloco';

@NgModule({
  declarations: [SearchCategoryComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    PipesModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    TranslocoDirective
  ],
  exports: [SearchCategoryComponent],
})
export class SearchCategoryModule {}
