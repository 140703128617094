import { Injectable, OnDestroy } from '@angular/core';
import { getSubscription } from '@platri/elab-angular-core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment, environmentForWeb, UsersInterface } from '@platri/df-common-core';
import { AuthStateService } from '@platri/dfx-angular-core';
import { ChatAppChatsTechnicalUserRouterQueryParam } from '../constants';
import { AuthTechnicalUserStateService } from './auth-technical-user-state.service';
import { ChatsComponent } from '../components';


@Injectable({
  providedIn: ChatsComponent
})
export class ChatWebsocketService implements OnDestroy {
  websocketUrl = environmentForWeb.apiChatUrlWS + '';
  
  eventBus: ReplaySubject<any> = new ReplaySubject();
  
  public socket: WebSocket;
  public isAuthenticated: boolean;
  private isSocketOpen: boolean = false;
  subscriptions: Subscription =  new Subscription();
  
  userAccessToken: string;
  technicalUserAccessToken: string;
  
  hasTechnicalUserInQueryParam: boolean;

  isConnected = false;
  
  
  constructor(
    private authStateService: AuthStateService,
    private authTechnicalStateService: AuthTechnicalUserStateService,
    private activatedRoute: ActivatedRoute
  ) {
    console.log("Initializing " + ChatWebsocketService.name);
    this.getRecipientIdAndTechnicalUserIdFromRouterUrl();
    this.initializeSubscriptions();
  }

  getRecipientIdAndTechnicalUserIdFromRouterUrl(): void {
    this.subscriptions.add(this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams[ChatAppChatsTechnicalUserRouterQueryParam]) {
        this.hasTechnicalUserInQueryParam = !!queryParams[ChatAppChatsTechnicalUserRouterQueryParam];
      }
    }));
    
  }

  ngOnDestroy(): void {
    this.isConnected = false;
    if (this.socket) {
      this.socket.close();
    }
    this.subscriptions.unsubscribe();
    console.log("Destroying " + ChatWebsocketService.name);
  }

  initializeSubscriptions(): void {
    if (!this.hasTechnicalUserInQueryParam) {
      this.subscriptions.add(getSubscription(this.authStateService.getAsyncAccessToken(), this.onAccessTokenChanges.bind(this)));
      this.subscriptions.add(getSubscription(this.authStateService.getAsyncCurrentUser(), this.onCurrentUserChanges.bind(this)));
    }
    if (this.hasTechnicalUserInQueryParam) {
      this.subscriptions.add(getSubscription(this.authTechnicalStateService.getAsyncAccessToken(), this.onTechnicalUserAccessTokenChanges.bind(this)));
    }
  }

  getAsyncEventBus(): Observable<any> {
    return this.eventBus.asObservable();
  }

  onAccessTokenChanges(accessToken: string): void {
    if (accessToken) {
      this.userAccessToken = accessToken;
    } else if (this.userAccessToken && !accessToken) {
      this.resetState();
    }
  }

  onCurrentUserChanges(currentUser: UsersInterface): void {
    if (currentUser && !this.isConnected) {
      this.connect();
      this.isConnected = true;
    } 
  }
  

  resetState(): void {
    console.log("Resetting ChatWebsocketService");
    this.isConnected = false;
    this.userAccessToken = '';
    if (this.socket) {
      this.socket.close();
    }
  }

  onTechnicalUserAccessTokenChanges(accessToken: string): void {
    if (accessToken && !this.isConnected) {
      this.technicalUserAccessToken = accessToken;
      this.connect();
      this.isConnected = true;
    } else if (this.technicalUserAccessToken && !accessToken) {
      this.resetState();
    }
  }

  connect(): void {
    console.log("Trying to connect");
    this.socket = new WebSocket(this.websocketUrl);

    this.setupEventListeners();
    
    this.socket.onclose = (e): void => {
      console.log('WebSocket disconnected', e.reason);
      if (this.isConnected) {
        setTimeout(() => this.connect(), 3000);
      }
    };

    this.socket.onerror = (error): void => {
      console.error('WebSocket error', error);
      this.socket.close(); // Close the websocket on error
    };
    
  }

  private setupEventListeners(): void {
    this.socket.addEventListener('open', (event) => {
      this.isSocketOpen = true;
      console.log('Connected to WebSocket server');
      this.authenticate();
      this.addMessageListener();
    });
  }

  private addMessageListener(): void {
    this.socket.addEventListener('message', (event1) => {
      const messageData = JSON.parse(event1.data);
      switch (messageData.event) {
        case "authenticated":
          this.isAuthenticated = true;
          console.log("Successfully authenticated");
          this.socket.send(JSON.stringify({event: 'pong'}));
          break;
        case "receivedMessage":
          this.eventBus.next({event: "receivedMessage", data: messageData.data});
          break;
        case "messageStatusChanged":
          this.eventBus.next({event: "messageStatusChanged", data: messageData.data});
          break;
        case "messageReactionChanged":
          this.eventBus.next({event: "messageReactionChanged", data: messageData.data});
          break;
        case "messageDeletedChanged":
          this.eventBus.next({event: "messageDeletedChanged", data: messageData.data});
          break;
        case 'ping':
          this.socket.send(JSON.stringify({event: 'pong'}));
          break;
        default:
          break;
      }
    });
  }

  authenticate(): void {
    const message = {
      event: 'authenticate',
      data: {
        jwt: this.hasTechnicalUserInQueryParam ? this.authTechnicalStateService.getSyncAccessToken() : this.authStateService.getSyncAccessToken()
      }
    };
    this.socket.send(JSON.stringify(message));
  }
  
  
}
