<ng-container *transloco="let t">
  <div class="package-container" style="gap: 15px;">
    <df-monorepo-dm-packages-card-item
      title="{{ t('GENERIC_WRAPPER.EVENTS')}}"
      description="{{ t('GENERIC_WRAPPER.PACKAGES.EVENT_DESCRIPTION')}}"
      imgUrl="assets/images/packages/events.png"
      [showToggleForAction]="true"
      [isFeatureActive]="isFeatureEventActive"
      [danceManagerSubscriptionType]="danceManagerSubscriptionType.FREE"
      (toggleClickedEvent)="onDanceManagerFeatureSlide($event, danceManagerFeatureName.DANCE_EVENTS)"
    ></df-monorepo-dm-packages-card-item>
    <df-monorepo-dm-packages-card-item
      title="{{ t('GENERIC_WRAPPER.PACKAGES.EVENT_TICKETS.TITLE')}}"
      description="{{ t('GENERIC_WRAPPER.PACKAGES.EVENT_TICKETS.DESCRIPTION')}}"
      imgUrl="assets/images/packages/event_tickets.png"
      [showToggleForAction]="true"
      [isFeatureActive]="isFeatureEventTicketsActive"
      [danceManagerSubscriptionType]="danceManagerSubscriptionType.FREE"
      (toggleClickedEvent)="onDanceManagerFeatureSlide($event, danceManagerFeatureName.DANCE_EVENTS_TICKETS)"
    ></df-monorepo-dm-packages-card-item>
    <df-monorepo-dm-packages-card-item
      title="{{ t('GENERIC_WRAPPER.PACKAGES.COURSES.TITLE')}}"
      description="{{ t('GENERIC_WRAPPER.PACKAGES.COURSES.DESCRIPTION')}}"
      imgUrl="assets/images/packages/courses.png"
      [showToggleForAction]="true"
      [isFeatureActive]="isFeatureCoursesActive"
      (toggleClickedEvent)="onDanceManagerFeatureSlide($event, danceManagerFeatureName.COURSES)"
    ></df-monorepo-dm-packages-card-item>
    <df-monorepo-dm-packages-card-item
      title="{{ t('GENERIC_WRAPPER.PACKAGES.COURSE_PASSES.TITLE')}}"
      description="{{ t('GENERIC_WRAPPER.PACKAGES.COURSE_PASSES.DESCRIPTION')}}"
      imgUrl="assets/images/packages/courses.png"
      [showToggleForAction]="true"
      [isFeatureActive]="isFeatureCoursePassesActive"
      [danceManagerSubscriptionType]="danceManagerSubscriptionType.FREE"
      (toggleClickedEvent)="onDanceManagerFeatureSlide($event, danceManagerFeatureName.COURSE_PASSES)"
    ></df-monorepo-dm-packages-card-item>
    <df-monorepo-dm-packages-card-item
      *ngIf="dmPassSubscriptionFeature"
      title="{{ t('GENERIC_WRAPPER.PACKAGES.COURSE_SUBSCRIPTION.TITLE')}}"
      description="{{ t('GENERIC_WRAPPER.PACKAGES.COURSE_SUBSCRIPTION.DESCRIPTION')}}"
      imgUrl="assets/images/packages/courses.png"
      [showToggleForAction]="true"
      [isFeatureActive]="isFeatureCourseSubscriptionActive"
      [danceManagerSubscriptionType]="danceManagerSubscriptionType.FREE"
      (toggleClickedEvent)="onDanceManagerFeatureSlide($event, danceManagerFeatureName.COURSE_SUBSCRIPTION)"
    ></df-monorepo-dm-packages-card-item>
  </div>
</ng-container>
