import {Pipe, PipeTransform} from '@angular/core';
import { GERMAN_LANGUAGE, WeekdaysStringEnum } from '@platri/df-common-core';

@Pipe({
  name: 'dayNameAbbreviation',
})
export class DayNameAbbreviationPipe implements PipeTransform {
  transform(dayName: string, language?: string): string | null {
    // @ts-ignore
    switch (language) {
      case GERMAN_LANGUAGE:
        switch (dayName) {
          case WeekdaysStringEnum.MONDAY.toLowerCase():
            return 'Mo';
          case WeekdaysStringEnum.TUESDAY.toLowerCase():
            return 'Di';
          case WeekdaysStringEnum.WEDNESDAY.toLowerCase():
            return 'Mi';
          case WeekdaysStringEnum.THURSDAY.toLowerCase():
            return 'Do';
          case WeekdaysStringEnum.FRIDAY.toLowerCase():
            return 'Fr';
          case WeekdaysStringEnum.SATURDAY.toLowerCase():
            return 'Sa';
          case WeekdaysStringEnum.SUNDAY.toLowerCase():
            return 'So';
          default:
            return null;
        }
      default:
        switch (dayName) {
          case WeekdaysStringEnum.MONDAY.toLowerCase():
            return 'Mon';
          case WeekdaysStringEnum.TUESDAY.toLowerCase():
            return 'Tue';
          case WeekdaysStringEnum.WEDNESDAY.toLowerCase():
            return 'Wed';
          case WeekdaysStringEnum.THURSDAY.toLowerCase():
            return 'Thu';
          case WeekdaysStringEnum.FRIDAY.toLowerCase():
            return 'Fri';
          case WeekdaysStringEnum.SATURDAY.toLowerCase():
            return 'Sat';
          case WeekdaysStringEnum.SUNDAY.toLowerCase():
            return 'Sun';
          default:
            return null;
        }
    }
  }
}
