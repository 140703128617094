import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseSubscriptionTableComponent } from './course-subscription-table.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../../pipes';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatChip} from "@angular/material/chips";
import { SharedEmptyPageModule } from '../shared-empty-page';
import {TranslocoDirective} from "@jsverse/transloco";

@NgModule({
  declarations: [CourseSubscriptionTableComponent],
  exports: [CourseSubscriptionTableComponent],
    imports: [
      CommonModule,
      MatTableModule,
      PipesModule,
      MatMenuModule,
      MatButtonModule,
      MatIconModule,
      CdkDropList,
      CdkDrag,
      MatTooltipModule,
      MatChip,
      SharedEmptyPageModule,
      TranslocoDirective,
    ]
})
export class CourseSubscriptionTableModule {}
