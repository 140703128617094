export interface SocialLoginProfileInterface {
  id?: string;
  name?: SocialLoginNameInterface;
  emails?: SocialLoginEmailInterface[];
  photos?: SocialLoginPhotoInterface[];
  isVerified?: boolean;
}

export interface SocialLoginEmailInterface {
  value?: string;
}

export interface SocialLoginPhotoInterface {
  value?: string;
}

export interface SocialLoginNameInterface {
  givenName?: string;
  familyName?: string;
}
