import { AddressInterface, AmenityInterface, LocationInterface, OpeningHoursInterface } from '../interfaces';

export class LocationDto implements LocationInterface {
  id: string;
  address: AddressInterface;
  amenities: AmenityInterface[];
  email: string;
  isPublic: boolean;
  name: string;
  openingHours: OpeningHoursInterface;
  phoneNumber: string;
  phonePrefix: string;
  phonePrefixAfter: string;
  showOpeningHours: boolean;
  
  constructor(args: LocationDto) {
    if (args) { 
      this.id = args.id;
      this.address = args.address;
      this.amenities = args.amenities;
      this.email = args.email;
      this.isPublic = args.isPublic;
      this.name = args.name;
      this.openingHours = args.openingHours;
      this.phoneNumber = args.phoneNumber;
      this.phonePrefix = args.phonePrefix;
      this.phonePrefixAfter = args.phonePrefixAfter;
      this.showOpeningHours = args.showOpeningHours;
    } 
  }
}
