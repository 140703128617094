import { Pipe, PipeTransform } from '@angular/core';



const abbreviationMappings: { [abbreviation: string]: string[] } = {
  'CET': ['Europe/Berlin', 'Europe/Paris', 'Europe/Andorra', ' Europe/Vienna', ' Europe/Zurich', ' Europe/Busingen', ' Europe/Gibraltar', ' Europe/Rome', ' Europe/Vaduz', ' Europe/Luxembourg', 'Europe/Monaco', ' Europe/Malta', ' Europe/Amsterdam', 'Europe/Oslo', ' Europe/Stockholm', ' Arctic/Longyearbyen', ' Atlantic/Jan_Mayen', ' Europe/San_Marino', ' Europe/Vatican', 'Europe/Budapest', 'Europe/Tirane', 'Europe/Prague', 'Europe/Podgorica', 'Europe/Belgrade', 'Europe/Ljubljana', 'Europe/Bratislava', 'MET', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Africa/Ceuta', 'Europe/Warsaw', 'Poland', 'Europe/Sarajevo', 'Europe/Zagreb', 'Europe/Skopje', 'Africa/Algiers', 'Africa/Tunis'],
  'EET':['Athens', 'Bucharest', 'Europe/Kyiv', 'Europe/Mariehamn', 'Europe/Sofia', 'Europe/Tallinn', 'Europe/Helsinki', 'Europe/Vilnius', 'Europe/Riga', 'Europe/Uzhgorod', 'Europe/Zaporozhye',],
  'EST': ['America/New_York'],
  'CST': ['America/Chicago', 'Asia/Shanghai', 'Asia/Taipei', 'Asia/Hong_Kong', 'Asia/Urumqi'],
  'MST': ['America/Denver'],
  'PST': ['America/Los_Angeles', 'America/Vancouver', 'America/Dawson', 'America/Whitehorse', 'Canada/Pacific', 'Canada/Yukon'],
  'AKT': ['America/Anchorage'],
  'HST': ['Pacific/Honolulu'],
  'JST': ['Asia/Tokyo'],
  'AEST': ['Australia/Sydney'],
  'WAT':['Africa/Lagos', 'Africa/Luanda', 'Africa/Porto-Novo', 'Africa/Kinshasa', 'Africa/Bangui', 'Africa/Brazzaville', 'Africa/Douala', 'Africa/Libreville', 'Africa/Malabo', 'Africa/Niamey', 'Africa/Ndjamena',],
  'BRT': ['America/Sao_Paulo'],
  'ART': ['America/Argentina/Buenos_Aires'],
  'CLT': ['America/Santiago'],
  'VET': ['America/Caracas'],
  'AMT': ['America/Manaus'],
  'COT': ['America/Bogota'],
  'IST': ['Asia/Kolkata'],
  'KST': ['Asia/Seoul'],
  'SGT': ['Asia/Singapore'],
  'WIB': ['Asia/Jakarta'],
  'IRKT': ['Asia/Irkutsk'],
  'GST': ['Asia/Dubai'],
  'AST': ['Asia/Riyadh'],
  'GMT': ['Europe/London'],
  'TRT': ['Europe/Istanbul'],
};

const abbreviationMappingsDST: { [abbreviation: string]: string[] } = {
  'CEST': ['Europe/Berlin','Europe/Paris', 'Africa/Tunis','Europe/Andorra', ' Europe/Vienna', ' Europe/Zurich', ' Europe/Busingen', ' Europe/Gibraltar', ' Europe/Rome', ' Europe/Vaduz', ' Europe/Luxembourg', 'Europe/Monaco', ' Europe/Malta', ' Europe/Amsterdam', 'Europe/Oslo', ' Europe/Stockholm', ' Arctic/Longyearbyen', ' Atlantic/Jan_Mayen', ' Europe/San_Marino', ' Europe/Vatican', 'Europe/Budapest', 'Europe/Tirane', 'Europe/Prague', 'Europe/Podgorica', 'Europe/Belgrade', 'Europe/Ljubljana', 'Europe/Bratislava', 'MET', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Africa/Ceuta', 'Europe/Warsaw', 'Poland', 'Europe/Sarajevo', 'Europe/Zagreb', 'Europe/Skopje'],
  'EEST':['Athens', 'Bucharest', 'Europe/Kyiv', 'Europe/Mariehamn', 'Europe/Sofia', 'Europe/Tallinn', 'Europe/Helsinki', 'Europe/Vilnius', 'Europe/Riga', 'Europe/Uzhgorod', 'Europe/Zaporozhye', 'Europe/Istanbul'],
  'EDT': ['America/New_York'],
  'CDT': ['America/Chicago'],
  'MDT': ['America/Denver'],
  'PDT': ['America/Los_Angeles', 'America/Vancouver', 'America/Dawson', 'America/Whitehorse', 'Canada/Pacific', 'Canada/Yukon'],
  'AKDT': ['America/Anchorage'],
  'HDT': ['Pacific/Honolulu'],
  'JST': ['Asia/Tokyo'],
  'AEDT': ['Australia/Sydney'],
  'HST': ['Pacific/Honolulu'],
  'WEST ': ['Africa/Algiers'],
  'WAT':['Africa/Lagos', 'Africa/Luanda', 'Africa/Porto-Novo', 'Africa/Kinshasa', 'Africa/Bangui', 'Africa/Brazzaville', 'Africa/Douala', 'Africa/Libreville', 'Africa/Malabo', 'Africa/Niamey', 'Africa/Ndjamena',],
  'BRT': ['America/Sao_Paulo'],
  'ART': ['America/Argentina/Buenos_Aires'],
  'CLT': ['America/Santiago'],
  'VET': ['America/Caracas'],
  'AMT': ['America/Manaus'],
  'COT': ['America/Bogota'],
  'IDT': ['Asia/Jerusalem'],
  'JDT': ['Asia/Tokyo'],
  'CST': ['Asia/Shanghai', 'Asia/Taipei', 'Asia/Hong_Kong', 'Asia/Urumqi'],
  'KDT': ['Asia/Seoul'],
  'SGT': ['Asia/Singapore'],
  'WIT': ['Asia/Jakarta'],
  'IRKST': ['Asia/Irkutsk'],
  'GDT': ['Asia/Dubai'],
  'ADT': ['Asia/Riyadh'],
  'BST': ['Europe/London'],
};

@Pipe({
  name: 'timezoneAbbreviation',
})
export class TimezoneAbbreviationPipe implements PipeTransform {
  transform(timezoneId: string, dateString: string | Date): string {
    
    if(this.isDST(timezoneId, dateString)){
      for (const [abbreviation, timezones] of Object.entries(abbreviationMappingsDST)) {
        if (timezones.includes(timezoneId)) {
          return abbreviation;
        }
      }
    }else{
      for (const [abbreviation, timezones] of Object.entries(abbreviationMappings)) {
        if (timezones.includes(timezoneId)) {
          return abbreviation;
        }
      }
    }

    return '';
  }

  private isDST( timezoneId: string, dateString: string | Date): boolean {
    const date = new Date(dateString);
    const offset = new Intl.DateTimeFormat('en-US', { timeZone: timezoneId, timeZoneName: 'long' }).formatToParts(date).find(part => part.type === 'timeZoneName').value;
    return offset.includes('Daylight') || offset.includes('Summer Time') || offset.includes('Irish Standard Time');
  }
  
}


