import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CreateDanceManagerDto, CreateUserGroupRequestDto,
  DanceManagerFaqDto,
  DanceManagerFeatureDto,
  DanceManagerFeatureNameEnum,
  DanceManagerSubscriptionDto,
  DanceManagerUserGroupsInterface,
  DanceManagerUserInviteGenerateTokenResponseDto,
  DanceManagerUserInviteRequestDto,
  DanceManagerUserInviteRequestInterface,
  DanceSchoolDto,
  DanceSchoolToUserDto, EditUserGroupRequestDto,
  environmentForWeb, GetAllUserGroupsByFilterRequestDto,
  PageDto
} from '@platri/df-common-core';

@Injectable({
  providedIn: 'root',
})
export class DanceManagerHttpService {
  private readonly SERVICE_URL = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager`;
  private readonly SERVICE_URL_DANCESCHOOLS = `${environmentForWeb.apiDanceSchoolUrl}/dance-schools`;
  private readonly SERVICE_URL_DANCEMANAGERS = `${environmentForWeb.apiDanceSchoolUrl}/dance-managers`;
  private readonly SERVICE_URL_DANCEMANAGER_IMAGES = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-images`;
  private readonly SERVICE_URL_STRIPE = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-stripe-account`;
  private readonly SERVICE_URL_SUBSCRIPTION = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-subscription`;
  private readonly SERVICE_URL_USER_INVITE = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-user-invite`;
  private readonly SERVICE_URL_DM_USER_GROUPS = `${environmentForWeb.apiDanceSchoolUrl}/dance-manager-user-groups`;

  constructor(private httpClient: HttpClient) {}
  
  postDanceManager(request: CreateDanceManagerDto): Observable<DanceSchoolDto> {
    return this.httpClient.post<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGERS}`, request);
  }
  
  createDmUserGroups(request: CreateUserGroupRequestDto): Observable<string> {
    // @ts-ignore
    return this.httpClient.post<string>(`${this.SERVICE_URL_DM_USER_GROUPS}`, request, {responseType: 'text'});
  }
  
  editDmUserGroups(id: string, request: EditUserGroupRequestDto): Observable<DanceManagerUserGroupsInterface> {
    return this.httpClient.patch<DanceManagerUserGroupsInterface>(`${this.SERVICE_URL_DM_USER_GROUPS}/${id}`, request);
  }
  
  deleteDmUserGroupById(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.SERVICE_URL_DM_USER_GROUPS}/${id}`);
  }

  getDmUserGroupById(id: string): Observable<DanceManagerUserGroupsInterface> {
    return this.httpClient.get<DanceManagerUserGroupsInterface>(`${this.SERVICE_URL_DM_USER_GROUPS}/${id}`);
  }

  getDmUserGroupsByDanceManagerId(request: GetAllUserGroupsByFilterRequestDto): Observable<DanceManagerUserGroupsInterface[]> {
    return this.httpClient.post<DanceManagerUserGroupsInterface[]>(`${this.SERVICE_URL_DM_USER_GROUPS}/filter`, request);
  }

  uploadFile(formData: FormData): Observable<string> {
    // @ts-ignore
    return this.httpClient.post<string>(`${this.SERVICE_URL_DANCEMANAGER_IMAGES}`, formData, { responseType: 'text' });
  }

  uploadLogoByDanceManagerId(id: string, formData: FormData): Observable<DanceSchoolDto> {
    return this.httpClient.post<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGER_IMAGES}/dance-manager/${id}/logo-image`, formData);
  }

  deleteLogoByDanceManagerId(id: string): Observable<DanceSchoolDto> {
    return this.httpClient.delete<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGER_IMAGES}/dance-manager/${id}/logo-image`);
  }

  uploadTitleImageDanceManagerId(id: string, formData: FormData): Observable<DanceSchoolDto> {
    return this.httpClient.post<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGER_IMAGES}/dance-manager/${id}/title-image`, formData);
  }

  deleteTitleImageDanceManagerId(id: string): Observable<DanceSchoolDto> {
    return this.httpClient.delete<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGER_IMAGES}/dance-manager/${id}/title-image`);
  }
  
  getFaqsByDanceManagerId(id: string): Observable<DanceManagerFaqDto []>{
    return this.httpClient.get<DanceManagerFaqDto[]>(`${this.SERVICE_URL_DANCESCHOOLS}/${id}/faqs`);
  }

  activateFeature(danceSchoolId: string, features: DanceManagerFeatureNameEnum[]): Observable<void> {
    return this.httpClient.put<void>(
      `${this.SERVICE_URL}/${danceSchoolId}/features/activate`,
      {
        features,
      }
    );
  }

  deactivateFeature(danceSchoolId: string, features: DanceManagerFeatureNameEnum[], force = false): Observable<void> {
    return this.httpClient.put<void>(`${this.SERVICE_URL}/${danceSchoolId}/features/disable`, {features, force});
  }

  cancelSubscriptionById(
    danceManagerId: string,
    danceManagerSubscriptionId: string
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${this.SERVICE_URL_SUBSCRIPTION}/dance-manager/${danceManagerId}/subscription/${danceManagerSubscriptionId}/cancel`,
      {}
    );
  }

  reactivateSubscriptionByDanceManagerId(
    danceManagerId: string,
  ): Observable<void> {
    return this.httpClient.put<void>(`${this.SERVICE_URL_SUBSCRIPTION}/dance-manager/${danceManagerId}/subscription/reactivate`, {});
  }

  getSubscriptionByCheckoutSessionId(
    checkoutSessionId: string
  ): Observable<DanceManagerSubscriptionDto> {
    return this.httpClient.get<DanceManagerSubscriptionDto>(`${this.SERVICE_URL_SUBSCRIPTION}/checkout-session/${checkoutSessionId}`);
  }

  getSubscriptionByDanceManagerId(danceManagerId: string): Observable<DanceManagerSubscriptionDto> {
    return this.httpClient.get<DanceManagerSubscriptionDto>(`${this.SERVICE_URL_SUBSCRIPTION}/dance-manager/${danceManagerId}/latest`);
  }

  getStripeAccountOnboardingSession(danceManagerId: string): Observable<{clientSecret: string}> {
    return this.httpClient.get<{clientSecret: string}>(`${this.SERVICE_URL_STRIPE}/onboarding-session/${danceManagerId}`, {});
  }
  
  userInviteEmail(
    danceManagerId: string,
    data: DanceManagerUserInviteRequestInterface
  ): Observable<void> {
    return this.httpClient.post<void>(`${this.SERVICE_URL_USER_INVITE}/${danceManagerId}/invite-mail`, {
      ...data
    });
  }

  requestUserInviteToken(
    danceManagerId: string,
    data: DanceManagerUserInviteRequestDto
  ): Observable<DanceManagerUserInviteGenerateTokenResponseDto> {
    return this.httpClient.post<DanceManagerUserInviteGenerateTokenResponseDto>(`${this.SERVICE_URL_USER_INVITE}/${danceManagerId}/generate-token`, {
      ...data
    });
  }
  
  validateUserInviteToken(
    token: string
  ): Observable<void> {
    return this.httpClient.post<void>(`${this.SERVICE_URL_USER_INVITE}/check-token`, {
      token
    });
  }

  getDanceManagerByUrlName(urlName: string): Observable<DanceSchoolDto> {
    return this.httpClient.get<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGERS}/urlName/${urlName}`);
  }
  
  getDanceManagerByUrlNameForPublicView(urlName: string): Observable<DanceSchoolDto> {
    return this.httpClient.get<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGERS}/urlName/${urlName}/public-view`);
  }
  
  getDanceManagerByIdForPublicView(id: string): Observable<DanceSchoolDto> {
    return this.httpClient.get<DanceSchoolDto>(`${this.SERVICE_URL_DANCEMANAGERS}/id/${id}/public-view`);
  }

  getDanceManagerFeaturesById(id: string): Observable<DanceManagerFeatureDto[]> {
    return this.httpClient.get<DanceManagerFeatureDto[]>(`${this.SERVICE_URL_DANCEMANAGERS}/features/${id}`);
  }
  
  getAllDanceManagersByIds(ids: string[]):Observable<PageDto<DanceSchoolDto>> {
    let params = new HttpParams();
    if (ids?.length > 0) {
      params = params.set('danceManagerIds', ids.join(','));
    }
    return this.httpClient.get<PageDto<DanceSchoolDto>>(`${this.SERVICE_URL_DANCEMANAGERS}`, {
      params
    });
  }

  getDanceManagerById(id: string): Observable<DanceSchoolDto> {
    return this.httpClient.get<DanceSchoolDto>(`${this.SERVICE_URL_DANCESCHOOLS}/${id}`);
  }

  getDanceManagersByUserIdWhereActiveAt(userId: string): Observable<DanceSchoolDto[]> {
    return this.httpClient.get<DanceSchoolDto[]>(`${this.SERVICE_URL_DANCEMANAGERS}/users/${userId}`);
  }
  
  getDanceManagersBulkById(danceManagerIds: string[]): Observable<DanceSchoolDto[]> {
    const ids = { ids: danceManagerIds };
    return this.httpClient.post<DanceSchoolDto[]>(`${this.SERVICE_URL_DANCEMANAGERS}/bulk`, ids);
  }
  
  getDanceSchoolToUsersByDanceSchoolId(id: string): Observable<DanceSchoolToUserDto[]> {
    return this.httpClient.get<DanceSchoolToUserDto[]>(`${this.SERVICE_URL_DANCESCHOOLS}/${id}/users`);
  }
  
  getAllByFilter(args: {page: number; limit: number; sortColumn?: string; sortDirection?: string; latitude?: number; longitude?: number; radius?: number;  danceStyles?: string[]; danceManagerIds?: string[]; categories?: string[]; search?: string; startDate?: Date; endDate?: Date; clientDateUtc?: Date}): Observable<PageDto<DanceSchoolDto>> {
    
    let params = new HttpParams();

    if (args.latitude) {
      params = params.set('latitude', args.latitude);
    }

    if (args.longitude) {
      params = params.set('longitude', args.longitude);
    }

    if (args.longitude && args.latitude) {
      if (args.radius) {
        params = params.set('radius', args.radius);
        params = params.set('radiusUnit', 'KM');
      }else {
        params = params.set('radius', 250);
        params = params.set('radiusUnit', 'KM');
      }
    }

    params = params.set('page', args.page);
    params = params.set('limit', args.limit);
    
    if(args.sortColumn){
      params = params.set('sortColumn', args.sortColumn);
    }
    
    if (args.search) {
      params = params.set('search', args.search);
    }
 
    if (args.danceStyles?.length > 0) {
      params = params.set('danceStyles', args.danceStyles.toString());
    }

    if (args.danceManagerIds?.length > 0) {
      params = params.set('danceManagerIds', args.danceManagerIds.toString());
    }

    if (args.categories?.length > 0) {
      params = params.set('categories', args.categories.toString());
    }

    if (args.startDate) {
      params = params.set('startDate', args.startDate.toISOString());
    }

    if (args.endDate) {
      params = params.set('endDate', args.endDate.toISOString());
    }

    if (args.clientDateUtc) {
      params = params.set('clientDateUtc', args.clientDateUtc.toISOString());
    }
    
    return this.httpClient.get<PageDto<DanceSchoolDto>>(this.SERVICE_URL_DANCEMANAGERS, { params });
  }

  getAllDanceManagersByCurrentUser(): Observable<DanceSchoolDto[]> {
    return this.httpClient.get<DanceSchoolDto[]>(`${this.SERVICE_URL_DANCEMANAGERS}/users`);
  }

  getPageViews(urlName: string): Observable<number> {
    return this.httpClient.get<number>(`${this.SERVICE_URL_DANCESCHOOLS}/${urlName}/page-views`);
  }
}
